import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { GlobaldataService } from '../../../common/globaldata.service';
import { Router, ActivatedRoute } from "@angular/router";
declare var $: any;
@Component({
  selector: 'app-setup-season-preview',
  templateUrl: './setup-season-preview.component.html',
  styleUrls: ['./setup-season-preview.component.css']
})
export class SetupSeasonPreviewComponent implements OnInit {
  package_data;
  numbers;
  otas = [];
  package_dates: any;
  sleeps = 0;
  nights;
  dropDown;
  current_currency;
  setup_rate;

  bHeight;
  preview_image;
  hide8: boolean = false
  colors2 = ['green_text', 'purple_text', 'orange_text', 'yellow_text', 'red_dark_text', 'last_text'];
  selected: any = undefined;
  darkMode: boolean = false;
  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public service: ManageRatesPackagesService,
    public router: Router,
    public Aroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currency();
    this.modalfooter()
    this.checkDarkMode();

    if (GlobaldataService.savedSeason != undefined) {
      this.get_package();
    } else if (GlobaldataService.editSeason != undefined) {
      this.get_package2();
    }
    $("#SS").mouseup(function () {
      $("#grab-2").css({ "cursor": "grab" })
    });
    $("#SS").mousedown(function () {
      $("#grab-2").css({ "cursor": "grabbing" })
    });

  }

  get_package() {
    this.api.get('api/season_package/show/' + GlobaldataService.savedSeason.id, true).subscribe((res: any) => {
      this.package_data = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  get_package2() {
    this.api.get('api/season_package/show/' + GlobaldataService.editSeason.id, true).subscribe((res: any) => {
      this.package_data = res.body.data;
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  onClose() {
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    $('#settings2').modal('hide');
  }

  editArr(arr) {
    let w = [
      { name: 'Monday', status: false },
      { name: 'Tuesday', status: false },
      { name: 'Wednesday', status: false },
      { name: 'Thursday', status: false },
      { name: 'Friday', status: false },
      { name: 'Saturday', status: false },
      { name: 'Sunday', status: false }
    ];
    w.map((val) => {
      arr.map((val2) => {
        if (val2 == val.name) {
          val.status = true;
        }
      })
    })
    return w;
  }

  changecol() {
    if (this.hide8 == true) {
      this.hide8 = false
    }
    else {
      this.hide8 = true
    }
  }

  modalfooter() {
    setTimeout(() => {
      let f = document.getElementById("ModalFooter")
      let h = document.getElementById("SS");
      this.bHeight = h.offsetHeight;

      if (this.bHeight > 700) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      }
      else if (this.bHeight < 700) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    }, 100)
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  chngedrop(id) {
    let a = (<HTMLInputElement>document.getElementById(id))
    if (a.classList.contains('trans180')) {
      a.classList.remove('trans180')
    }
    else {
      a.classList.add('trans180')
    }
  }

  currency() {
    this.current_currency = localStorage.getItem('current_currency');
  }

}
