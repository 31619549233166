import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-permission',
  templateUrl: './promo-permission.component.html',
  styleUrls: ['./promo-permission.component.css']
})
export class PromoPermissionComponent implements OnInit {
  darkMode: boolean = false;
  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  changeclass(id: any) {
    let ids = <HTMLElement>document.getElementById(id)
    console.log(ids);

    if (ids.classList.contains("rotate")) {
      ids.classList.remove("rotate")
    } else {
      ids.classList.add("rotate")
    }
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
}
