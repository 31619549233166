import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { ToastrService } from 'ngx-toastr';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BookingDetailsByRoom, BookingsByRoomType, RoomBooking } from 'src/app/common/Inventory/BookingDetailsByRoom';
import { BookingDetailsByRoomTypes } from "src/app/common/Inventory/BookingDetailsByRoomTypes";
import { CalendarCellModel } from 'src/app/common/Inventory/CalendarCellModel';
import { DateRoomId } from 'src/app/common/Inventory/DateRoomTypePair';
import { InventoryCalendarColors } from 'src/app/common/Inventory/InventoryCalendarColors';
import { RoomInfo, RoomNumberInfo, Season, SeasonData } from 'src/app/common/Inventory/TotalRoomsInSeasonRespose';
import { InventoryCalendarService } from 'src/app/services/inventory-calendar.service';
import { BulkReservationSelectionRange } from 'src/app/common/Inventory/Reservation/BulkReservationSelectionRange';
import { ReservationService } from 'src/app/services/reservation.service';

const moment = extendMoment(Moment);


@Component({
  selector: 'app-inventory-calender',
  templateUrl: './inventory-calender.component.html',
  styleUrls: ['./inventory-calender.component.css']
})
export class InventoryCalenderComponent implements OnInit, AfterViewInit {

  darkMode: boolean = false;
  colors = InventoryCalendarColors
  calendarRange: Moment.Moment[] = []

  firstCall = true;

  detailsRowToggleObj: { roomId: number, toggle: boolean }[];
  //bookingDetailsMap: Map<number, Map<string,any>> = new Map(); // room_id -> date -> bookingDetails
  // bookingDetailsMap: Map<number, Map<number,Map<string,any>>> = new Map(); // room_id -> room_no -> date -> bookingDetails
  today: Date = new Date();

  @Input('roomSet') roomInformationSet: RoomInfo[]

  bookingDetailsByRoomTypes: BookingDetailsByRoomTypes = new BookingDetailsByRoomTypes();
  currency_format = localStorage.getItem("currency");

  res_obj = {
    close_rates: false,
    stop_sell: false,
    cta: false,
    ctd: false,
    min_stay: {
      allowed: false,
      value: 1,
    },
    max_stay: {
      allowed: false,
      value: 1,
    },
  };

  constructor(
    private inventoryService: InventoryCalendarService,
    private toasterService: ToastrService,
    private reservationService: ReservationService
  ) {

  }

  private roomBookings$ = this.inventoryService.roomBookings$
  private totalRoomsInSeason$ = this.inventoryService.totalRoomsInSeason$.pipe(
    map((data) => {
      return data.seasons;
    }),
    tap((data) => {
      this.detailsRowToggleObj = []
      this.roomInformationSet = data[0].room_info;
      data[0].room_info.forEach((roomInfo) => {
        this.detailsRowToggleObj.push({ roomId: roomInfo.room_id, toggle: false })
      })
    }
    ))

  //this is being used in HTML 
  public bookingsInRange$: Observable<{ dateRoomMapping: Map<string, Map<number, number>>, seasons: Season[] }> =
    combineLatest([this.roomBookings$, this.totalRoomsInSeason$])
      .pipe(map(values => {
        let roomsInSeason = values[1][0].room_info //extracting all the rooms
        let bookings = values[0] //extracting all the bookings
        let roomAvailablilityMapping: Map<string, Map<number, number>> = new Map()  // date -> room_id -> count

        this.calendarRange.forEach((date) => {
          let dateStr = date.format("YYYY-MM-DD")
          //First we fill it all with max room capacity
          let roomsCounts = new Map<number, number>()
          roomsInSeason.forEach((room) => {
            roomsCounts.set(room.room_id, Number(room.room_count))
          })
          roomAvailablilityMapping.set(dateStr, roomsCounts)

          //then we subtract the booked rooms
          let bookingForDate = bookings.find(x => x.date == dateStr)
          if (bookingForDate !== undefined) {
            bookingForDate.booking.forEach((bookingInfo) => {
              let roomCount = roomAvailablilityMapping.get(dateStr).get(bookingInfo.room_id)
              if (roomCount < 0) {
                console.log("ERROR: room count is less than 0")
              }
              roomAvailablilityMapping.get(dateStr).set(bookingInfo.room_id, roomCount - 1)
            })
          }
        })
        return { dateRoomMapping: roomAvailablilityMapping, seasons: values[1] };
      }))

  calendarDateRange$ = this.inventoryService.calendarDateRange$;

  bulkReservationRanges: BulkReservationSelectionRange[] = []
  bulkReservationSelectionRange: BulkReservationSelectionRange = {}

  mouseDown = false;
  startX: any
  scrollLeft: any;
  slider: HTMLElement;
  daysInterval: number = 14;


  ngOnInit() {
    this.checkDarkMode()
    this.inventoryService.setCalendarDateRange
    this.calendarDateRange$.subscribe({
      next: (date) => {
        if (date === undefined) return;

        // let interval = Math.round(moment(date.endDate).diff(moment(date.startDate), "days") / 2);
        // let start = moment().subtract(interval, "days");
        // let end = moment().add(interval, "days");
        //create an array for all dates in range and fill it in calendar_range
        let range = moment.range(moment(date.startDate), moment(date.endDate));
        this.calendarRange = []
        for (let day of range.by("days")) {
          this.calendarRange.push(day);
        }
      },
      error: (err) => {
        console.log(err)
      }
    })

    this.slider = document.querySelector('#grid-cal') as HTMLElement;
    console.log(this.slider, 'SLIDER')
    this.registerScroll()


    this.inventoryService.bookingDetailsByRoom$.subscribe({
      next: (val) => {
        val.forEach((bookingsOfRoomType, roomTypeId) => {
          const bookingDetailsByRooms: BookingsByRoomType = {
            roomTypeId,
            roomNumBookings: bookingsOfRoomType.room_bookings
          };
          const roomType = this.bookingDetailsByRoomTypes.getByRoomTypeId(roomTypeId);
          if (roomType === undefined) {
            this.bookingDetailsByRoomTypes.bookingsByRoomTypes.push(bookingDetailsByRooms);
          } else {
            roomType.roomNumBookings = bookingsOfRoomType.room_bookings;
          }
        });
      }, error: (err) => { console.log(err) }
    })

    this.inventoryService.calendarCellClicked$.subscribe({
      next: (res) => {
        this.handleBulkReservationCellSelect(res);
      }
    })

    // calling this so that it resets the bulk reservation selection range  
    this.reservationService.pushBulkReservationSelectionRange(undefined);

    this.reservationService.onResetReservationForm$.subscribe({
      next: () => {
        this.bulkReservationRanges = [];
        this.bulkReservationSelectionRange = {};
        this.reservationService.pushBulkReservationSelectionRange(undefined);
      }
    })
  }


  ngAfterViewInit() {
    this.scrollSliderToCenter();
  }

  private scrollSliderToCenter(offset = 0) {
    const boxWidth = document.querySelector('#header-box-width').clientWidth;
    this.slider.scrollLeft = boxWidth * (this.daysInterval + offset);
  }

  getRoomCount(dateRoomMapping: Map<string, Map<number, number>>, date: Moment.Moment, roomId: number): number {
    let dateStr = date.format("YYYY-MM-DD")
    return dateRoomMapping?.get(dateStr)?.get(roomId)
  }

  toggleDetailsRow(roomId: string) {
    const roomRow = this.detailsRowToggleObj.find(x => x.roomId == +roomId)
    roomRow.toggle = !roomRow.toggle
    if (roomRow.toggle == true) {
      this.inventoryService.setSelectedRoom(+roomId)
    }
    console.log('roomset', this.roomInformationSet)
  }

  getRowToggle(roomId: number) {
    const roomRow = this.detailsRowToggleObj.find(x => x.roomId == roomId)
    if (roomRow) {
      return roomRow.toggle
    }
  }

  getRoomSetbyRoomType(roomId): RoomNumberInfo[] {
    const roomSet = this.roomInformationSet.find(x => x.room_id == roomId)
    return roomSet.room_numbers
  }

  onBoxClick(data: CalendarCellModel) {
    this.inventoryService.onCalendarCellClicked(data);
  }

  getRoomRateByDate(roomInfo: RoomInfo, date): number {
    //check moment date is weekday or weekend
    let dayOfWeek = date.day()
    let rate = dayOfWeek > 0 && dayOfWeek < 6 ? roomInfo.week_days_rate : roomInfo.weekend_days_rate
    return rate
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  getBoxColor(data: CalendarCellModel) {

    if (data.booking) {
      return "box-booked"
    }

    let className = "";
    this.bulkReservationRanges.forEach(selectionRange => {
      if (selectionRange.start.roomNo == data.roomNo) {
        if (data.date.isSameOrAfter(selectionRange.start.date) && data.date.isSameOrBefore(selectionRange.end.date)) {
          className = "box-range-selected"
        }
      }
    });

    if (data.roomNo == this.bulkReservationSelectionRange.start?.roomNo && data.date.isSame(this.bulkReservationSelectionRange.start?.date)) {
      className = "box-selected"
    }

    if (className === "") {
      className = "box-available";
    }
    return className;
  }

  updateCalendarRange(action: "add" | "subtract", dateRange) {
    if (action == "add") {
      this.inventoryService.setCalendarDateRange(moment(dateRange.startDate).add(this.daysInterval, "days"), moment(dateRange.endDate).add(this.daysInterval, "days"))
      this.scrollSliderToCenter(-6);
    }
    else if (action == "subtract") {
      this.inventoryService.setCalendarDateRange(moment(dateRange.startDate).subtract(this.daysInterval, "days"), moment(dateRange.endDate).subtract(this.daysInterval, "days"))
      this.scrollSliderToCenter(-0.1);
    }
  }


  //scrolls the calendar left or right
  registerScroll() {
    let mouseDown = this.mouseDown
    let startX = this.startX
    let scrollLeft = this.scrollLeft
    let slider = this.slider

    let startDragging = (e) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    let stopDragging = (event) => {
      mouseDown = false;
      let maxScroll = slider.scrollWidth - slider.clientWidth;
      let scrollPercent = slider.scrollLeft / maxScroll;
      let scrollPercentRounded = Math.round(scrollPercent * 100);
      //console.log(scrollPercentRounded)

      let dateRange = this.inventoryService.getCalendarDateRange();
      if (scrollPercentRounded > 99) {
        this.updateCalendarRange("add", dateRange)
      } else if (scrollPercentRounded < 1) {
        this.updateCalendarRange("subtract", dateRange)
      }
    };
    slider.addEventListener('mousemove', (e) => {
      //e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;


    });

    // Add the event listeners
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }
  //Scroll logic ends here


  alert(s) {
    window.alert(s)
  }
  handleBulkReservationCellSelect(res: CalendarCellModel) {

    if (this.bulkReservationSelectionRange.start === undefined) {
      this.bulkReservationSelectionRange.start = res;
    } else if (this.bulkReservationSelectionRange.start !== undefined && this.bulkReservationSelectionRange.end === undefined) {
      this.bulkReservationSelectionRange.end = res;
      const isValid = this.inventoryService.isValidBulkReservationRange(this.bulkReservationSelectionRange);
      if (!isValid) {
        this.bulkReservationSelectionRange = { ...this.bulkReservationSelectionRange, end: undefined };
        this.toasterService.error("Invalid range selected");
        this.bulkReservationSelectionRange = { start: undefined, end: undefined };
        return;
      }

      const noBookingBetweenRange = this.inventoryService.ensureNoBookingsBetweenRange(this.bulkReservationSelectionRange, this.bookingDetailsByRoomTypes);
      if (!noBookingBetweenRange) {
        this.bulkReservationSelectionRange = { ...this.bulkReservationSelectionRange, end: undefined };
        this.toasterService.error("Bookings exist between selected range");
        this.bulkReservationSelectionRange = { start: undefined, end: undefined };
        return;
      }

      const noMultiRangeBookingOnSameRoomType = this.inventoryService.ensureNoMultiRangeBookingOnSameRoomType(this.bulkReservationSelectionRange, this.bulkReservationRanges);
      if (!noMultiRangeBookingOnSameRoomType) {
        this.bulkReservationSelectionRange = { ...this.bulkReservationSelectionRange, end: undefined };
        this.toasterService.error("Invalid Selection : Multiple date ranges selected on same room");
        this.bulkReservationSelectionRange = { start: undefined, end: undefined };
        return;
      }

      //if everything is valid, then we can proceed
      this.toasterService.success("Valid range selected");

      let datesWithinRange = moment.range(moment(this.bulkReservationSelectionRange.start.date), moment(this.bulkReservationSelectionRange.end.date));
      this.bulkReservationSelectionRange.range = []
      for (let day of datesWithinRange.by("days")) {
        this.bulkReservationSelectionRange.range.push(day);
      }

      this.bulkReservationRanges.push(this.bulkReservationSelectionRange);
      this.reservationService.pushBulkReservationSelectionRange(this.bulkReservationSelectionRange);
      //reset the selection range
      this.bulkReservationSelectionRange = { start: undefined, end: undefined };
    }

  }

  splitGuestName(guestName: string) {
    return `${guestName.split(",")[0]} ${guestName.split(",")[1]}`;
  }

  input_focus($event) {
    $event.target.focus();
  }

  getEmptyElements(count: number) {
    return new Array(count).map(x => '');
  }

}
