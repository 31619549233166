import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.css']
})
export class ContactSupportComponent implements OnInit {
  selectedTime: any;
  submitted = false;
  base_url;
  email;
  inlineDateTime;
  zoneTime;
  countryByTime
  city;
  date: any;
  times: any;
  properties;
  contact_support_form: FormGroup;
  contact_support_form2: FormGroup;

  // duaration:any;
  darkMode: boolean = false;
  // selectedDateTime = [
  //   {
  //     startTime:'09:00',
  //     endTime:'10:00',
  //     date:'Tue Jul 14 2020'
  //   },
  //   {
  //     startTime:'11:00',
  //     endTime:'12:00',
  //     date:'Tue Jul 14 2020'
  //   },
  //   {
  //     startTime:'12:00',
  //     endTime:'01:00',
  //     date:'Tue Jul 14 2020'
  //   },
  //   {
  //     startTime:'02:00',
  //     endTime:'03:00',
  //     date:'Tue Jul 14 2020'
  //   }
  // ];

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getPropertyIndex();
    let id = JSON.parse(localStorage.getItem('current_property'));
    this.contact_support_form = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      user_id: ['', Validators.required],
    });
    this.base_url = this.api.base_url;
    // this.getTimeZone();
  }

  get f() { return this.contact_support_form.controls; }

  // onSubmit(){
  //   this.submitted = true;
  //   let id = JSON.parse(localStorage.getItem('user')).id;
  //   this.contact_support_form.get('user_id').setValue(id);
  //   if (this.contact_support_form.invalid) {
  //     return;
  //   }
  //   this.api.post('api/online_help/store', this.contact_support_form.value,true).subscribe((res:any) => {
  //     this.helper.alert_success("Request submitted successfully");
  //     this.contact_support_form.reset()
  //     this.submitted = false;
  //   }, err => this.error_handling.handle_error(err.status));
  // }

  // subletmit2(){
  //   let obj ={
  //     user_id: JSON.parse(localStorage.getItem('user')).id,
  //     call_duration: this.duaration,
  //     call_date: this.date.toDateString(),
  //     timezone: this.city,
  //     call_time: this.times
  //   }
  //   this.api.post('api/helper/timeslot_store', obj,true).subscribe((res:any) => {
  //     this.helper.alert_success("Request submitted successfully");
  //     this.submitted = false;
  //   }, err => this.error_handling.handle_error(err.status));
  // }

  resetform() {
    this.contact_support_form.reset();
    this.contact_support_form2.reset();
  }

  // eventClicked(e){
  //   let d = e.startDate._d;
  //   this.date = d;
  //   let b = document.getElementById("redCard").style.display = 'block';
  // }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  // getTime(timess){
  //   this.times = timess.startTime + '-' + timess.endTime;
  // }

  // getTimeZone(){
  //  let a = new Date().toString().split(" ");
  //  this.city =Intl.DateTimeFormat().resolvedOptions().timeZone
  //  this.countryByTime = a[6].split("(")
  //  this.zoneTime = a[5]
  // }

  getPropertyIndex() {
    let user = JSON.parse(localStorage.getItem('user'));
    let obj = {
      id: user.id,
      email: user.email,
      arr_room_empty: false
    }
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.api.post('api/property/index', obj, true).subscribe((res: any) => {
      this.properties = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

}
