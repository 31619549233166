import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
@Injectable()
export class ApiService {

  base_url = 'https://uat.cgslhcm.com/pmsbackend_8/';
  // base_url='http://localhost:8000/';
  base_url2 = 'https://cmtest.pointclickintegrate.com/public/';
  //  base_url2 = 'http://172.16.16.242/channelmanager/public/';
  // https://cmtest.pointclickintegrate.com/public/api/helper/upload_image

  imgUrl: string = 'https://cmtest.pointclickintegrate.com/public/app/images/';

  response;
  hotelLogo;
  notification = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  get(endpoint: String, token) {

    var header;
    if (token == true) {
      let auth_token = localStorage.getItem('pms_auth_token');
      let bearer_token = 'Bearer ' + auth_token;

      header = new HttpHeaders();
      header = header.set("Authorization", bearer_token);
    }

    return this.http.request('get', this.base_url + endpoint, { headers: header, observe: 'response' });
  }

  getApi(endpoint: string) {
    return this.http.request('get', endpoint);
  }

  post(endpoint: String, data: any, token) {

    var header;
    if (token == true) {
      let auth_token = localStorage.getItem('pms_auth_token');
      let bearer_token = 'Bearer ' + auth_token;

      header = new HttpHeaders();
      header = header.set("Authorization", bearer_token);
    }
    // console.log(data);

    return this.http.request('post', this.base_url + endpoint, { body: data, headers: header, observe: 'response' })
  }
  post2(endpoint: String, data: any, token) {

    var header;
    if (token == true) {
      let auth_token = localStorage.getItem('pms_auth_token');
      let bearer_token = 'Bearer ' + auth_token;

      header = new HttpHeaders();
      header = header.set("Authorization", bearer_token);
    }
    // console.log(data);

    return this.http.request('post', this.base_url2 + endpoint, { body: data, headers: header, observe: 'response' })
  }

  get2(endpoint: String, token) {

    var header;
    if (token == true) {
      let auth_token = localStorage.getItem('pms_auth_token');
      let bearer_token = 'Bearer ' + auth_token;

      header = new HttpHeaders();
      header = header.set("Authorization", bearer_token);
    }

    return this.http.request('get', this.base_url2 + endpoint, { headers: header, observe: 'response' });
  }


  put(endpoint: String, data: any) {
    return this.http.put(this.base_url + endpoint, data);
  }

  delete(endpoint: String, data: any) {
    return this.http.delete(this.base_url + endpoint, data);
  }

}
