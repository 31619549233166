import { Component, OnInit } from '@angular/core';
import { RmsService } from 'src/app/services/rms.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {
  darkMode: boolean = false;

  constructor(private rmsService: RmsService) { }

  rest_orderCard: Array<any> = [
    {
      id: '1',
      itemImage: "../../../../assets/img/back-office/rms_img/chicken-biryani.png",
      title: 'Chicken Biryani',
      count: 0,
      price: 300
    },
    {
      id: '2',
      itemImage: "../../../../assets/img/back-office/rms_img/coffee-press.png",
      title: 'Coffee Presses',
      count: 0,
      price: 950
    },
    {
      id: '3',
      itemImage: "../../../../assets/img/back-office/rms_img/pan-cake.png",
      title: 'Pan Cake',
      count: 0,
      price: 550
    },
    {
      id: '4',
      itemImage: "../../../../assets/img/back-office/rms_img/fresh-lime.png",
      title: 'Fresh Lime',
      count: 0,
      price: 150
    },
    {
      id: '5',
      itemImage: "../../../../assets/img/back-office/rms_img/iced-mocha.png",
      title: 'Iced Mocha',
      count: 0,
      price: 250
    },
    {
      id: '6',
      itemImage: "../../../../assets/img/back-office/rms_img/beef-burger.png",
      title: 'Beef Burger',
      count: 0,
      price: 550
    },
    {
      id: '7',
      itemImage: "../../../../assets/img/back-office/rms_img/hot-chocolate.png",
      title: 'Hot Chocolate',
      count: 0,
      price: 650
    },
    {
      id: '8',
      itemImage: "../../../../assets/img/back-office/rms_img/choco-cake.png",
      title: 'Choco Cake',
      count: 0,
      price: 350
    },
    {
      id: '9',
      itemImage: "../../../../assets/img/back-office/rms_img/fries.png",
      title: 'Fench Fries',
      count: 0,
      price: 120
    },
    {
      id: '10',
      itemImage: "../../../../assets/img/back-office/rms_img/apple-pie.png",
      title: 'Apple Pie',
      count: 0,
      price: 450
    },
    {
      id: '11',
      itemImage: "../../../../assets/img/back-office/rms_img/spaghetti.png",
      title: 'Spaghetti Pasta',
      count: 0,
      price: 750
    },
    {
      id: '12',
      itemImage: "../../../../assets/img/back-office/rms_img/ham-sandwich.png",
      title: 'Ham Sandwich',
      count: 0,
      price: 650
    }

  ]

  deal_orderCard: Array<any> = [
    {
      id: '1',
      itemImage: "../../../../assets/img/back-office/rms_img/deal -1.png",
      title: 'Crazy Deal 1',
      count: 0,
      price: 500
    },
    {
      id: '2',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-2.png",
      title: 'Crazy Deal 2',
      count: 0,
      price: 600
    },
    {
      id: '3',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-3.png",
      title: 'Crazy Deal 3',
      count: 0,
      price: 700
    },
    {
      id: '4',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-4.png",
      title: 'Crazy Deal 4',
      count: 0,
      price: 800
    },
    {
      id: '5',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-5.png",
      title: 'Crazy Deal 5',
      count: 0,
      price: 900
    },
    {
      id: '6',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-6.png",
      title: 'Crazy Deal 6',
      count: 0,
      price: 550
    },
    {
      id: '7',
      itemImage: "../../../../assets/img/back-office/rms_img/deal-7.png",
      title: 'Crazy Deal 7',
      count: 0,
      price: 650
    },
    {
      id: '8',
      itemImage: "../../../../assets/img/back-office/rms_img/mid-night-d1.png",
      title: 'Mid Night Deal 1',
      count: 0,
      price: 750
    },
    {
      id: '9',
      itemImage: "../../../../assets/img/back-office/rms_img/mid-night-d2.png",
      title: 'Mid Night Deal 2',
      count: 0,
      price: 850
    },
    {
      id: '10',
      itemImage: "../../../../assets/img/back-office/rms_img/mid-night-d3.png",
      title: 'Mid Night Deal 3',
      count: 0,
      price: 950
    },
    {
      id: '11',
      itemImage: "../../../../assets/img/back-office/rms_img/mid-night-d4.png",
      title: 'Mid Night Deal 4',
      count: 0,
      price: 1000
    },
    {
      id: '12',
      itemImage: "../../../../assets/img/back-office/rms_img/mid-night-d5.png",
      title: 'Mid Night Deal 5',
      count: 0,
      price: 1050
    }

  ]
  ngOnInit() {
    this.checkDarkMode();
  }
  dealOrder(index: any) {
    this.rmsService.addOrderItem(this.deal_orderCard[index])
  }
  restOrder(index: any) {
    this.rmsService.addOrderItem(this.rest_orderCard[index])
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
