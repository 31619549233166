import { OnChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartDataset } from 'chart.js';
import * as moment from 'moment';
import { PnlService } from 'src/app/services/pnl.service';

@Component({
  selector: 'app-revenue-trends',
  templateUrl: './revenue-trends.component.html',
  styleUrls: ['./revenue-trends.component.css']
})
export class RevenueTrendsComponent implements OnInit, OnChanges {

  darkMode: boolean = false;
  constructor(private pnlService: PnlService) { }

  chart: Chart;

  @Input('choosenDate') choosen_date;
  startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  endDate = moment().format('YYYY-MM-DD');
  ngOnInit() {
    this.updateChart()
  }

  ngOnChanges() {
    this.checkDarkMode();
    this.updateChart()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  updateChart() {

    //subtract 12 hours from end date
    this.endDate = moment(this.choosen_date.endDate).subtract(12, 'hours').format('YYYY-MM-DD');
    //subtract 6 months using moment 
    this.startDate = moment(this.endDate).subtract(5, 'months').startOf('month').format('YYYY-MM-DD');

    this.pnlService.getRevenueTrendsGraph(this.startDate, this.endDate, "month").subscribe((res) => {
      let datasets: ChartDataset[] = [];
      var darkmode = this.darkMode;
      console.log(res.data.data, 'data ');

      res.data.data.forEach((d) => {

        if (d.label == 'Revenue') {
          datasets.push({
            label: d.label,
            data: d.data,
            backgroundColor: darkmode ? '#778da9' : '#000',
            order: 1,
          })
        } else if (d.label == 'Net Income') {
          datasets.push({
            label: d.label,
            data: d.data,
            borderColor: 'rgb(236, 19, 97)',
            backgroundColor: 'rgb(255,0,0)',
            borderWidth: 1,
            borderDash: [5, 5],
            type: 'line',
            order: 0,
          })
        } else if (d.label == 'COGS') {
          //make all the data negative
          let data = d.data.map((d) => {
            if (d == 0) { d = 0.01 }
            return d * -1;
          })
          datasets.push({
            label: d.label,
            data: data,
            backgroundColor: 'rgb(228, 91, 0)',
            order: 2,
          })
        } else if (d.label == 'OPEX') {
          //make all the data negative
          let data = d.data.map((d) => {
            if (d == 0) { d = 0.01 }
            return d * -1;
          })
          datasets.push({
            label: d.label,
            data: data,
            backgroundColor: 'rgba(75, 193, 203, 1)',
            order: 3,
          })
        }
      })

      const labels = res.data.labels;
      const data: any = {
        labels: labels,
        datasets: datasets
      };
      let currency = localStorage.getItem('currency');
      const config: any = {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return `${tooltipItem.dataset.label} : ${currency} ${tooltipItem.formattedValue}.00`;
                },
                title: function (tooltipItem, data) {
                  return;
                }
              }
            },
            legend: {
              display: true,
              position: 'right',
              labels: {
                boxHeight: 15,
                boxWidth: 16,
                usePointStyle: true,
                padding: 20,
                pointStyle: 'rectRounded',
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }

            }
          },
          elements: {
            point: {
              backgroundColor: "#3bbb88",
              radius: 5,
              pointStyle: 'circle',
              hoverRadius: 10,
              borderWidth: 3,
              borderColor: '#fff',

            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              ticks: {
                mirror: false,
                color: darkmode ? '#fff' : '#000',
              },
              grid: {
                display: false,
              }
            }
          },
          y: {
            stacked: true,
            ticks: {
              color: darkmode ? '#fff' : '#000',
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              // callback: function(val:number, index) {
              //   // Hide every 2nd tick label
              //   return index % 2 === 0 ? this.getLabelForValue(val) : '';
              // },
            },
            grid: {
              color: function (context) {
                //debugger;
                if (context.tick.value == 0) {
                  return '#f00';
                }
                return '#f00';
              },
            },
          },
        }
      };
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart('revenue-trends-canvas', config)

    })
  }

}
