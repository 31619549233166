import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { FnbService } from 'src/app/services/fnb.service';
import { ToastrService } from 'ngx-toastr';
import { Shops } from 'src/app/common/fnb/createShop';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { Shop } from 'src/app/common/pnl/fnb/createShopModel';
declare var $: any;

@Component({
  selector: 'app-foodandbeverages',
  templateUrl: './foodandbeverages.component.html',
  styleUrls: ['./foodandbeverages.component.css']
})

export class FoodandbeveragesComponent implements OnInit {
  showInput: boolean = true;
  showModal: boolean = false;
  darkMode: boolean = false;
  imageShow: boolean = false;
  editMode: boolean = false;
  @Input('choosenDate') choosen_date;
  createShop: Shops[] = [];
  new_shop_name: string;
  propertyId = localStorage.getItem("current_property");
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  file_name: string;
  index: number;
  selectedFile: File;
  selectedImage: string = '';
  formData: FormData = new FormData();
  tax_inclusive: boolean = true;
  tax_id: string;
  tax_per: string;
  randomColor = ['#76A200', '#EC1361', '#0179F1', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];

  constructor(
    private fnbService: FnbService,
    private toaster: ToastrService,
    private confirm: ConfirmationAlertService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getShopNames();
  }

  addShop() {
    // this.createShop.push({
    //   shop_id: 0,
    //   shop_name: this.new_shop_name,
    //   image: this.file_name,
    //   property_id: this.propertyId,
    //   is_deleted: 0
    // });

    this.new_shop_name = '';
  }

  removeShop(i: number) {
    const formData: FormData = new FormData();
    formData.append('shop_id', this.createShop[i].shop_id.toString());
    formData.append('shop_name', this.createShop[i].shop_name);
    formData.append('property_id', this.createShop[i].property_id);
    this.formData.append('tax_id', this.tax_id);
    this.formData.append('tax_per', this.tax_per);
    this.formData.append('tax_inclusive', this.tax_inclusive.toString());
    formData.append('is_deleted', '1');
    this.confirm.confirm(`Alert`, `Are you sure you want to delete this shop?`,
      [`This action can't be undone`, 'Are you sure you want to delete?'],
      () => {
        this.fnbService.createShopReq(formData).subscribe(res => {
          this.getShopNames();
          this.toaster.success(res.message)
        })
      }
      , '', ''
    );
  }

  editShop(shop, i) {
    this.editMode = true;
    this.showModal = true;
    this.new_shop_name = shop.shop_name;
    this.file_name = shop.image;
    this.tax_id = shop.tax_id;
    this.tax_per = shop.tax_per;
    this.tax_inclusive = shop.tax_inclusive;
    this.index = i;
  }

  saveShopNames() {
    let shopId = this.createShop[this.index]?.shop_id;

    let shop_deatails = {
      shop_id: this.editMode ? shopId : 0,
      shop_name: this.new_shop_name,
      image: this.selectedFile,
      tax_inclusive: this.tax_inclusive,
      tax_id: this.tax_id,
      tax_per: this.tax_per,
      property_id: this.propertyId,
      is_deleted: 0
    }

    let shopFormData: FormData = new FormData();
    shopFormData = this.fnbService.getFormData(shop_deatails, this.editMode);

    this.fnbService.createShopReq(shopFormData).subscribe(res => {
      this.getShopNames();
      this.toaster.success(res.message);
      this.showModal = false;
      this.editMode = false;
    })
  }

  getShopNames() {
    this.fnbService.getcreateShopNames().subscribe((res) => {
      console.log(res.data.shops, 'response get');
      this.createShop = res.data.shops;
    });
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  openModel() {
    this.showModal = true;
    this.new_shop_name = '';
    this.file_name = '';
    this.tax_id = '';
    this.tax_per = '';
    // this.tax_inclusive = true;
  }

  onModalClose() {
    this.showModal = false;
  }

  handleFileSelect(): void {
    const files: FileList | null = this.fileInput.nativeElement.files;
    if (files && files.length) {
      this.selectedFile = files[0];
      // Perform file upload logic here
      this.file_name = this.selectedFile.name;
    }
  }

  imageFullPre() {
    this.file_name = '';
    this.new_shop_name = ''
    this.imageShow = !this.imageShow;
  }
}

