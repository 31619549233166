<div class="HSetup">

  <!-- <div class="alert alert-info alert-dismissible show" role="alert">

    You've got 3 notifications and 2 keynotes, everything else looks smooth
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <strong id="cross">&#x2716;</strong>
    </button>
</div> -->

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">BACK OFFICE</a>
    <a href="" class="btn btn-link active">HOTEL SETUP</a>
  </div>

  <div class="scroll no_scroll">
    <form class="form-horizontal back-form hotel-setup" [formGroup]="hotel_setup_form">

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">

          <div class="col-md-4 grid_20">
            <div class="border-style bhs-1 property-shade">
              <input type="text" class="form-control" placeholder="Property Name" formControlName="name">
            </div>
            <div *ngIf="submitted && f.name.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.name.errors.required">Property Name is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-2 phone-shade">
              <input type="text" class="form-control" placeholder="Phone" formControlName="phone">
            </div>
            <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.phone.errors.required">Phone is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-3 email-shade">
              <input type="text" class="form-control" placeholder="Email Address" formControlName="email">
            </div>
            <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.email.errors.required">Email Address is required</strong>
            </div>
          </div>

        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">

          <div class="col-md-4 grid_20">
            <div class="border-style bhs-4 rooms-shade">
              <input type="text" class="form-control" placeholder="Total Rooms" formControlName="room_count">
            </div>
            <div *ngIf="submitted && f.room_count.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.room_count.errors.required">Total Rooms is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-5 address1-shade">
              <input type="text" class="form-control" placeholder="Address Line 1" formControlName="address_one">
            </div>
            <div *ngIf="submitted && f.address_one.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.address_one.errors.required">Address Line 1 is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-6 address2-shade">
              <input type="text" class="form-control" formControlName="address_two" placeholder="Address Line 2">
            </div>
          </div>

        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">

          <div class="col-md-4 grid_20">
            <!-- {{ setup_data | json }} -->
            <div class="border-style bhs-7 country-shade">
              <!-- <select class="form-control" name="bed" id="" (change)="get_states($event.target.value)">
              <option value="hide"> Select Country</option>
              <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.full_name }}</option>
            </select> -->
              <ng-select [items]="countries" bindLabel="full_name" bindValue="full_name" formControlName="country_id"
                placeholder="Select Country" (change)="get_states($event)" [(ngModel)]="country"
                [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>

            </div>
            <div *ngIf="submitted && f.country_id.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.country_id.errors.required">Country is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-8 state-shade">
              <!-- {{ setup_data | json }} -->
              <!-- <select class="form-control" name="bed" id="" (change)="get_cities($event.target.value)">
              <option value="hide">State</option>
              <option *ngFor="let state of states" value="{{ state.id }}" >{{ state.full_name }}</option>
            </select> -->
              <ng-select [items]="states" bindLabel="full_name" bindValue="full_name" formControlName="state_id"
                placeholder="Select State" (change)="get_cities($event)" [(ngModel)]="state"
                [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>

            </div>
            <div *ngIf="submitted && f.state_id.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.state_id.errors.required">State is required</strong>
            </div>
          </div>
          <div class="col-md-4 grid_20">
            <div class="border-style bhs-9 city-shade">
              <!-- <select class="form-control" name="bed" id="">
              <option value="hide"> Select City</option>
              <option *ngFor="let city of cities">{{ city.name }}</option>
            </select> -->
              <ng-select [items]="cities" bindLabel="name" bindValue="name" formControlName="city_id"
                placeholder="Select City" [(ngModel)]="city" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
            </div>
            <div *ngIf="submitted && f.city_id.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.city_id.errors.required">City is required</strong>
            </div>
          </div>

        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">

          <div class="col-md-3 grid_20">
            <div class="border-style bhs-10 post-shade">
              <input type="text" class="form-control" placeholder="Post Code" formControlName="postal_code">
            </div>
            <div *ngIf="submitted && f.postal_code.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.postal_code.errors.required">Post Code is required</strong>
            </div>
          </div>
          <div class="col-md-3 grid_20">
            <div class="border-style bhs-11 weekend-shade">
              <!-- <select class="form-control" name="bed" id="">
              <option value="hide">Weekend starts on</option>
              <option *ngFor="let d of days; let i = index">{{ d.name }}</option>
            </select> -->
              <ng-select [items]="days" bindLabel="name" bindValue="name" formControlName="weekend_starts"
                placeholder="Select Weekend Start" [(ngModel)]="weekend_starts"
                [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
            </div>
            <div *ngIf="submitted && f.weekend_starts.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.weekend_starts.errors.required">Weekend Start is required</strong>
            </div>
          </div>
          <div class="col-md-3 grid_20">
            <div class="border-style bhs-12 time-shade">
              <!-- <select class="form-control" name="bed" id="">
              <option value="hide">Time Zone</option>
              <option *ngFor="let timezone of timezones">{{ timezone.title }}</option>
            </select> -->
              <ng-select [items]="timezones" bindLabel="title" bindValue="title" formControlName="timezone_id"
                placeholder="Select Timezone" [(ngModel)]="timezone" [ngClass]="darkMode ? 'dark-drop' : 'null'">
              </ng-select>
            </div>
            <div *ngIf="submitted && f.timezone_id.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.timezone_id.errors.required">Timezone is required</strong>
            </div>
          </div>
          <div class="col-md-3 grid_20">
            <div class="border-style bhs-17 post-shade labelFlex">
              <label>Housekeeping</label>
              <input type="time" class="form-control " placeholder="Housekeeping" formControlName="housekeeping_time"
                [(ngModel)]="housekeeping_time" style="padding: 0px;margin-left: 10px;"
                [ngClass]="darkMode ? 'drker' : 'whter'">
            </div>
            <!-- <div *ngIf="submitted && f.timezone_id.errors" class="small text-danger no-margin invalid-feedback">
            <strong *ngIf="f.timezone_id.errors.required">Housekeeping</strong>
          </div> -->
          </div>

        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">


          <div class="col-md-12 pr-3">
            <div class="row">

              <!-- <div class="col-md-4 " style="padding-right: 0px">

                    <div class="col-md-12 grid_20 zero-padd-col">
            <div class="border-style bhs-13 currency-shade">
              <div class="element flex-height">
                <label class="currency_label">Currency Conversion</label>
                <div class="status_box">
                    <input type="hidden" name="user_id" formControlName="user_id" value="39">
                    <label class="control-label radio-style"><input type="radio" class="radio" name="currency_conversion" formControlName="currency_conversion" value="Enabled" >
                        <span class="radio-checkmark"></span>
                        <span class="title">Enable</span>
                    </label>
                    <label class="control-label radio-style"><input type="radio" class="radio" name="currency_conversion" formControlName="currency_conversion" value="Disabled">
                        <span class="radio-checkmark"></span>
                        <span class="title">Disable</span>
                    </label>
                </div>
              </div>
            </div>

                     </div>

            </div> -->

              <div class="col-md-2 " style="padding-right: 0px; padding-left:20px">

                <div class="col-md-12 grid_20 zero-padd-col">
                  <div class="border-style bhs-14 base-currency-shade">
                    <!-- <select class="form-control" name="bed" id="">
                        <option value="">Select Base Currency</option>
                        <option *ngFor="let currency of currencies">{{ currency.symbol }}</option>
                      </select> -->
                    <ng-select [items]="currencies" bindLabel="symbol" formControlName="currency_id" bindValue="symbol"
                      placeholder="Base Currency" [(ngModel)]="currency" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                    </ng-select>
                  </div>

                  <!---start tooltip--->
                  <span class="ispan">
                    <i class="fas fa-info-circle i faone" style="font-size: 15px; margin-right: 20px !important;">
                      <span class="tooltiptext" style="width: 238px;">
                        <p class="small" style="padding: 0px !important; margin: 0px !important;">
                          The Base Currency is the currency in which pricing is managed in the Calendar View.
                        </p>
                      </span>
                    </i>
                  </span>
                  <!---end tooltip--->



                  <div *ngIf="submitted && f.currency_id.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.currency_id.errors.required">Country is required</strong>
                  </div>
                  <!-- <p class="small text-danger">
                      <strong>The Base Currency is the currency in which pricing is managed in the Calendar View.</strong>
                    </p> -->
                </div>


              </div>

              <div class="col-md-2 " style="padding-right: 0px; padding-left:5px">

                <div class="col-md-12 grid_20 zero-padd-col">
                  <div class="border-style bhs-15 rate-shade">
                    <input type="text" class="form-control" placeholder="Min Rate" formControlName="min_rate">

                    <!---start tooltip--->
                    <span class="info">
                      <i class="fas fa-info-circle i faTwo"
                        style="font-size: 15px; margin-right: 10px !important; margin-right: 20px !important;">
                        <span class="tooltiptext1" style="width: 412px; right: -100px !important;">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            This is a “hard deck” option. It means that you cannot set a room rate below this price on
                            the
                            inventory calendar. Exception is available for “Discounts”.
                          </p>
                        </span>
                      </i>
                    </span>
                    <!---end tooltip--->


                  </div>
                  <div *ngIf="submitted && f.min_rate.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.min_rate.errors.required">Country is required</strong>
                  </div>
                  <!-- <p class="text-danger small">
                      <strong>
                          This is a “hard deck” option. It means that you cannot set a room rate below this price on the inventory calendar. Exception is available for “Discounts”.
                      </strong>
                    </p> -->

                </div>

              </div>
              <div class="col-md-4" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '"
                style="padding: 0 0px 0 5px;">
                <div class="border-style color-pt bhs-2" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                  style="display: flex; justify-content: space-around; padding-top: 0px;">
                  <div class=" bhs-17 post-shade labelFlex" style="align-items: center;">
                    <label class="clockFormat">Clock Format</label>
                  </div>

                  <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="font-size: 14px;">
                    <label data-toggle="tooltip" title="12 Hr" class="control-label radio-style chbx ellipses hovbulit "
                      style="padding-top: 11px !important;" [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                      <input type="radio" class="pull-left radio" (click)="clockformating('12hr')" name="clockformat"
                        value="12hr" formControlName="clockformat">
                      <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                        id="type12hr" style="top: 13px !important;"></span>
                      <span class="title3" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type12">12 Hr</span>
                    </label>
                  </div>
                  <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'"
                    style="padding-left: 12% ; font-size: 14px;">
                    <label data-toggle="tooltip" title="24 Hr" class="control-label radio-style chbx ellipses hovbulit"
                      style="padding-top: 11px !important;" [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                      <input type="radio" class="pull-left radio" name="clockformat" value="24hr"
                        formControlName="clockformat" (click)="clockformating('24hr')">
                      <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                        id="type24hr" style="top: 13px !important; "></span>
                      <span class="title3" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type24">24 Hr</span>
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="t4 == true" class="dFlex">
                <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                  <div class="border-style bhs-17 post-shade labelFlex">
                    <label style="margin-left:5px; padding-right:5px">ETA</label>


                    <ng-select placeholder="ETA" class="etdfield" formControlName="eta"
                      style="padding: 0px; margin-left: 0px;width: 71%; display: flex; justify-content: right;"
                      [ngClass]="darkMode ? 'dark-drop' : 'null'">
                      <ng-option class="optn" *ngFor="let item of mainarray;let i = index" value="{{item}}" style="justify-content: center;display: flex;font-size: 16px;word-spacing: 30px;word-spacing: 15px;
                margin-left: 47px;">
                        {{item}}

                      </ng-option>




                    </ng-select>
                  </div>
                </div>



                <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                  <div class="border-style bhs-18 post-shade labelFlex" style="width: 90%;">
                    <label style="margin-left:5px; padding-right:5px">ETD</label>

                    <ng-select placeholder="ETA" class="etdfieldd" formControlName="etd"
                      style="padding: 0px; margin-left: 0px;width: 71%; display: flex; justify-content: right;"
                      [ngClass]="darkMode ? 'dark-drop' : 'null'">


                      <ng-option *ngFor="let item of mainarray;let i = index" value="{{item}}" style="justify-content: center;display: flex;font-size: 16px;word-spacing: 30px;word-spacing: 15px;
                margin-left: 47px;">
                        {{item}}


                      </ng-option>




                    </ng-select>
                  </div>
                </div>
              </div>

              <div *ngIf="t4 == false">
                <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                  <div class="border-style bhs-17 post-shade labelFlex">
                    <label style="margin-left:5px; padding-right:5px">ETA</label>
                    <input type="time" class="form-control" placeholder="ETA" formControlName="eta"
                      style="padding: 0px; margin-left: 10px;" [ngClass]="darkMode ? 'drker' : 'whter'">
                  </div>
                </div>



                <div class="col-md-2" style="padding: 0px 0px 0px 5px;">
                  <div class="border-style bhs-18 post-shade labelFlex etd_field">
                    <label style="margin-left:5px; padding-right:5px">ETD</label>
                    <input type="time" class="form-control etdfielddd" placeholder="ETA" formControlName="etd"
                      style="padding: 0px; margin-left: 10px;" [ngClass]="darkMode ? 'drker' : 'whter'">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div id="enable" *ngIf="enable" > -->
      <div>
        <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
          <div class="">
            <div class="col-md-11" style="padding-left: 0px">
              <h4 style="padding: 15px 20px;">Building Setup</h4>
            </div>
            <div class="col-md-1 text-right res-1600"
              style="padding-right: 10px ; padding-top: 6px; padding-left: 60px">

              <span class="img-responsive assign-plus gola" (click)="bulding()" id="plusB">
                <p>+</p>
              </span>
              <label class="currency_label" style="padding-right: 18px">
                <div class="">
                  <div class="rotate">
                    <!-- <div class="box" id="boxt"></div> -->
                  </div>

                  <span class="" id="tooltip1" [ngClass]="darkMode ? 'tooltip_dark':'tooltip'">
                    <p style="margin: 0px;"> <b> You will be able to control multiple buidings on your property </b></p>
                  </span>
                </div>
              </label>
            </div>

          </div>
        </div>
        <div class="card3" id="card2b" style="padding-bottom: 0px !important"
          [ngClass]="darkMode ? 'card_dark' : 'card3'">
          <div class="row" id="b-data" *ngFor="let ob of current_building;">
            <div class="col-md-12 buildingCol" style="margin-bottom: 5px">
              <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; ">
                <div class="capsul" [style.border-color]="ob.colorbuilding"
                  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                  <input type="text" placeholder="Building Name" class="form-control" [value]="ob.name">

                </div>
              </div>
              <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%;">
                <div class="capsul" [style.border-color]="ob.colorFloor" [ngClass]="darkMode ? 'card_dark' : 'card2'">

                  <div class="input-group">
                    <input type="text" placeholder="No of Floor" class="form-control" [value]="ob.floor">
                    <div class="input-group-btn" style="z-index: 0">
                      <a class="btn btn-default btn-del" [ngClass]="darkMode ? 'dark_f' : 'null'">
                        <!-- <i class="glyphicon glyphicon-remove" id="removeP" ></i> -->
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <span class="tooltip " id="tooltip2" >Delete this building</span>
          <div class="rotate r2">
              <div class="box " id="boxt2" ></div>
          </div> -->

              </div>
              <div class="col-md-2 zero-padding" style="    padding-left: 5px;width: 4.5%;">
                <a class="btn btn-md del-btn hove" [ngClass]="darkMode ? 'hove_dark' : 'null'"
                  (click)="delete(ob.id,ob.name)" data-toggle="modal" data-target="#confirmationModal-2"
                  [ngClass]="darkMode ? 'dark_bredcrum' : 'null'"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>

          <form #formRef="ngForm">

            <div class="row" id="b-data" *ngFor="let Buildings of building_value; let u = index;">
              <div class="col-md-12 " style="margin-bottom: 5px">
                <div class="col-md-5" style="padding:0px;padding-right: 2px;width: 47.7%; "
                  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                  <div class="capsul " [style.border-color]="Buildings.colorbuilding">
                    <input type="text" placeholder="Building Name" class="form-control"
                      [(ngModel)]="building_value[u].name" name="name{{u}}" #name="ngModel" required>

                  </div>
                </div>
                <div class="col-md-5" style="padding:0px;padding-left: 3px;width: 47.8%; "
                  [ngClass]="darkMode ? 'card_dark' : 'card2'">
                  <div class="capsul" [style.border-color]="Buildings.colorFloor">

                    <div class="input-group">
                      <input type="text" placeholder="No of Floor" class="form-control"
                        [(ngModel)]="building_value[u].floor" name="name{{u}}" #name="ngModel" required>
                      <div class="input-group-btn" style="z-index: 0">
                        <a class="btn btn-default btn-del" [ngClass]="darkMode ? 'dark_f' : 'null'">
                          <!-- <i class="glyphicon glyphicon-remove" id="removeP" ></i> -->
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                  <a class="btn btn-md del-btn" data-toggle="modal" data-target="#confirmationModal-2"
                    (click)="removeB(u)"><i class="fa fa-times"></i></a>
                </div>
              </div>
            </div>


          </form>
        </div>
        <!-- <div class="col-md-2 zero-padding" style="    padding-left: 5px;width: 4.5%;">
              <a class="btn btn-md del-btn hove" [ngClass]="darkMode ? 'hove_dark' : 'null'"
                (click)="delete(ob.id,ob.name)" data-toggle="modal" data-target="#confirmationModal-2"
                [ngClass]="darkMode ? 'dark_bredcrum' : 'null'"><i class="fa fa-times"></i>
              </a>
            </div> -->
      </div>
      <!-- </div> -->
      <!-- </form>

            </div>
        </div> -->

      <div class="expenceCard">
        <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
          <div class="">
            <h4>Pricing Pack
              <i class="fas fa-info-circle fa-info-circle_light "
                style="margin-left: 10px !important; font-size: 15px; "
                [ngClass]="darkMode ? 'fa-info-circle':'fa-info-circle_light'">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width:227px; padding:0px 0px 0px 0px !important;left: 11%;top: 73%;">
                  <p class="smalll text-danger"
                    style="padding: 5px 8px !important; margin-bottom: 0px !important; font-size: 12px !important;">
                    <strong style="font-size: 12px;">
                      Pricing pack will be displayed at the time of setting up rate plan and rate multiplier
                    </strong>
                  </p>
                </span>
              </i>
            </h4>
          </div>
        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
        <div class="row">

          <div class="col-md-12" [ngClass]="darkMode ? 'card_dark-side-1' : ' card-null '">

            <div class="border-style color-pt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
              style="justify-content: center;">
              <div class="" [ngClass]="darkMode?'radio-box_dark one':'radio-box one'">
                <label data-toggle="tooltip" title="Per Day Pricing"
                  class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                  <input type="radio" class="pull-left radio" name="pricingpack" value="PerDayPricing"
                    formControlName="pricingpack">
                  <span class="radio-checkmark  rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type1"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type1T">Per Day Pricing</span>
                </label>
              </div>


              <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'">
                <label data-toggle="tooltip" title="Occupancy Base Pricing"
                  class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                  <input type="radio" class="pull-left radio" name="pricingpack" value="occupancy"
                    formControlName="pricingpack">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type2"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">Occupancy Base
                    Pricing</span>
                </label>
              </div>

              <!-- <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'">
              <label data-toggle="tooltip" title="Both"
                class="control-label radio-style chbx ellipses hovbulit"
                [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                <input type="radio" class="pull-left radio" name="pricingpack" value="Both"
                  formControlName="pricingpack">
                <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                  id="type3"></span>
                <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">Both</span>
              </label>
            </div> -->
            </div>

            <div *ngIf="submitted && f.pricingpack.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.pricingpack.errors.required">Pricing pack Required</strong>
            </div>

          </div>
        </div>



      </div>

      <div class="expenceCard">
        <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
          <div class="">
            <h4>Tax Setup
              <i class="fas fa-info-circle" style="margin-left: 13px; font-size: 15px; ">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width:227px; padding:0px 0px 0px 0px;left: 80%;top: 57%;">
                  <p class="smalll text-danger"
                    style="padding: 5px 8px !important; margin-bottom: 0px !important; font-size: 12px !important;">

                    <strong style="font-size: 12px;">Tax amount is what the hotelier list as home country taxes. Which
                      may include Federal, state/provincial, VAT etc.</strong>
                  </p>
                </span>
              </i>
            </h4>

          </div>
        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card-dark' : ' card '">
        <div class="row">

          <div class="col-md-3 grid_20" [ngClass]="darkMode ? 'card_dark' : 'card2'">

            <div class="border-style bhs-15 post-shade">
              <input type="numnber" class="form-control" placeholder="Tax ID" formControlName="taxID">
            </div>
          </div>

          <div class="col-md-2 grid_20 pr-0" [ngClass]="darkMode ? 'card_dark' : 'card2'">

            <div class="border-style bhs-14 post-shade" [ngClass]="darkMode ? 'card_dark' : 'card2'">
              <input type="number" class="form-control" placeholder="Tax for property" formControlName="taxper">
              <i class="fas fa-percent"></i>
            </div>
            <div *ngIf="submitted && f.taxper.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.taxper.errors.required">Tax Percentage Required</strong>
            </div>
          </div>

          <div class="col-md-2 grid_20 pr-0" [ngClass]="darkMode ? 'card_dark' : 'card2'">
            <div class="border-style bhs-14 post-shade" [ngClass]="darkMode ? 'card_dark' : 'card2'">
              <input type="number" class="form-control" placeholder="Tax for Food"
                formControlName="food_tax_percentage">
              <i class="fas fa-percent"></i>
            </div>
            <div *ngIf="submitted && f.food_tax_percentage.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.food_tax_percentage.errors.required">Tax Percentage Required</strong>
            </div>
          </div>

          <div class="col-md-5" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '"
            style="padding: 0 15px 0 5px;">
            <div class="border-style color-pt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
              style="display: flex; justify-content: space-around;">
              <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'" style="padding: 0px;">
                <label data-toggle="tooltip" title="Tax Inclusive"
                  class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                  <input type="radio" class="pull-left radio" name="taxInclusive" value="true"
                    formControlName="taxInclusive">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type2"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">Inclusive</span>
                </label>
              </div>
              <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'" style="padding-left: 8% ;">
                <label data-toggle="tooltip" title="Tax exclusive"
                  class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'">
                  <input type="radio" class="pull-left radio" name="taxInclusive" value="false"
                    formControlName="taxInclusive">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type3"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">Exclusive</span>
                </label>
              </div>
            </div>
            <div *ngIf="submitted && f.taxInclusive.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.taxInclusive.errors.required">Tax Required</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="expenceCard">
        <section class="addons-section">
          <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
            <div class="">
              <h4>Services Add-On</h4>
            </div>
          </div>
          <div class="card" [ngClass]="darkMode ? 'card_dark' : ' card '">
            <div class="row" formArrayName="extras">
              <ng-container *ngFor="let expense of hotel_setup_form.get('extras')['controls']; let i = index">
                <div class="col-md-12 pb-2" *ngIf="chkForm(expense,i,'service')" [formGroupName]="i">
                  <div class="col-md-10 grid_20 service-addons d-flex p-0" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                    <div class="border-style bhs-1 post-shade">
                      <input type="text" placeholder="Services" class="form-control" formControlName="name">
                    </div>
                    <div class="border-style bhs-2 post-shade">
                      <input type="text" placeholder="Tax" class="form-control" formControlName="tax_percentage"
                        readonly>
                      <i class="fas fa-percent"></i>
                    </div>
                    <div class="border-style bhs-3 post-shade">
                      <input type="text" placeholder="Price" class="form-control" formControlName="price">
                    </div>
                    <div class="border-style color-pt bhs-8 mr-0"
                      [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                      style="display: flex; align-items: center;">
                      <input class="form-control" type="text" placeholder="Price" formControlName="price_after_tax"
                        readonly style="background: transparent;">
                      <div class="tax-type">
                        {{hotel_setup_form.controls.taxInclusive.value
                        ==
                        "true" ? 'Inclusive' : 'Exclusive'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                    <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-12">
                <div class="col-md-10 grid_20 service-addons d-flex p-0" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                  <div class="border-style bhs-1 post-shade">
                    <input type="text" placeholder="Services" class="form-control" [(ngModel)]="servicesName"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="border-style bhs-2 post-shade">
                    <input type="text" placeholder="Tax" class="form-control"
                      [value]="hotel_setup_form.controls.taxper.value" readonly>
                    <i class="fas fa-percent"></i>
                  </div>
                  <div class="border-style bhs-3 post-shade">
                    <input type="text" placeholder="Price" class="form-control" [(ngModel)]="servicesPrice"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="border-style color-pt bhs-4 mr-0"
                    [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                    style="display: flex; align-items: center;">
                    <input class="form-control" type="text" placeholder="Price"
                      [value]="getPriceAfterTax(servicesPrice,hotel_setup_form.controls.taxper.value)"
                      style="background: transparent;" readonly>
                    <div class="tax-type">
                      {{hotel_setup_form.controls.taxInclusive.value
                      ==
                      "true" ? 'Inclusive' : 'Exclusive'}}
                    </div>
                  </div>
                </div>
                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                  <a class="btn btn-md del-btn btn-primary"
                    (click)="addNewExpense(hotel_setup_form.get('extras'),servicesName,hotel_setup_form.controls.taxper.value,null,servicesPrice,'service')"><i
                      class="fa fa-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="addons-section">
          <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
            <div class="">
              <h4>Food | Mini Bar Add-On</h4>
            </div>
          </div>
          <div class="card" [ngClass]="darkMode ? 'card_dark' : ' card '">
            <div class="row" formArrayName="extras">
              <ng-container *ngFor="let expense of hotel_setup_form.get('extras')['controls']; let i = index">
                <div class="col-md-12 pb-2" *ngIf="chkForm(expense,i,'minibar')" [formGroupName]="i">
                  <div class="col-md-2 grid_20 p-0" [ngClass]="darkMode ? 'card_dark' : ''" style="width: 27%;">
                    <div class="border-style bhs-3 post-shade">
                      <input type="text" class="form-control" placeholder="Food | Mini Bar" formControlName="name">
                    </div>
                  </div>
                  <div class="col-md-4 grid_20 d-flex" [ngClass]="darkMode ? 'card_dark' : 'card2'"
                    style="width: 43.5%;">
                    <div class="border-style bhs-4 post-shade">
                      <input type="text" class="form-control" placeholder="Cost" formControlName="purchase_cost">
                    </div>
                    <div class="border-style bhs-5 post-shade mx-2">
                      <input type="text" class="form-control" placeholder="Sell Price" formControlName="price">
                    </div>
                    <div class="border-style bhs-6 post-shade" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                      <input type="text" class="form-control" placeholder="Tax" formControlName="tax_percentage"
                        readonly>
                      <i class="fas fa-percent"></i>
                    </div>
                  </div>
                  <div class="col-md-4 inl-exl" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '">
                    <div class="border-style color-pt bhs-8 mr-0"
                      [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                      style="display: flex; align-items: center;">
                      <input class="form-control" type="text" placeholder="Price" formControlName="price_after_tax"
                        style="background: transparent;" readonly>
                      <div class="tax-type">
                        {{hotel_setup_form.controls.taxInclusive.value
                        ==
                        "true" ? 'Inclusive' : 'Exclusive'}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                    <a class="btn btn-md del-btn" (click)="removeExpence(expense,i)"><i class="fa fa-times"></i></a>
                  </div>
                </div>
              </ng-container>
              <div class="col-md-12">
                <div class="col-md-2 grid_20 p-0" [ngClass]="darkMode ? 'card_dark' : ''" style="width: 27%;">
                  <div class="border-style bhs-3 post-shade">
                    <input type="text" class="form-control" placeholder="Food | Mini Bar" [(ngModel)]="Minisname"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
                <div class="col-md-4 grid_20 d-flex" [ngClass]="darkMode ? 'card_dark' : 'card2'" style="width: 43.5%;">
                  <div class="border-style bhs-4 post-shade">
                    <input type="text" class="form-control" placeholder="Cost" [(ngModel)]="MiniCostPrice"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="border-style bhs-5 post-shade mx-2">
                    <input type="text" class="form-control" placeholder="Sell Price" [(ngModel)]="MiniPrice"
                      [ngModelOptions]="{standalone: true}">
                  </div>
                  <div class="border-style bhs-6 post-shade" [ngClass]="darkMode ? 'card_dark' : 'card2'">
                    <input type="text" class="form-control" placeholder="Tax"
                      [value]="hotel_setup_form.controls.food_tax_percentage.value" readonly>
                    <i class="fas fa-percent"></i>
                  </div>
                </div>
                <div class="col-md-4 inl-exl" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '">
                  <div class="border-style color-pt bhs-8 mr-0"
                    [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                    style="display: flex; align-items: center;">
                    <input class="form-control" type="text" placeholder="Price" [(ngModel)]="miniPriceAfterTax"
                      [ngModelOptions]="{standalone: true}"
                      [value]="getPriceAfterTax(MiniPrice,hotel_setup_form.controls.food_tax_percentage.value)"
                      style="background: transparent;" readonly>
                    <div class="tax-type">
                      {{hotel_setup_form.controls.taxInclusive.value
                      ==
                      "true" ? 'Inclusive' : 'Exclusive'}}
                    </div>
                  </div>
                </div>
                <div class="col-md-2 zero-padding" style="padding-left: 5px;width: 4.5%;">
                  <a class="btn btn-md del-btn btn-primary"
                    (click)="addNewExpense(hotel_setup_form.get('extras'),Minisname,hotel_setup_form.controls.food_tax_percentage.value,MiniCostPrice,MiniPrice,'minibar')"><i
                      class="fa fa-plus"></i></a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
          <div class="">
            <h4>Hotel Images

              <!-- <i class="fas fa-info-circle " style="margin-left: 10px !important; font-size: 15px; " [ngClass]="darkMode ? 'fa-info-circle':'fa-info-circle_light'" >
            <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
              style="width:319px; padding:0px 0px 0px 0px;left: 66%;top: 73%;">
              <p class="small text-danger" style="padding: 0px 5px;">
                <strong style="font-size: 12px;">Adding the expences here will appear on checkout screen.</strong>
              </p>
            </span>
          </i> -->
              <i class="fas fa-info-circle " style="margin-left: 10px !important; font-size: 15px; "
                [ngClass]="darkMode ? 'fa-info-circle':'fa-info-circle_light'">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                  style="width:230px; padding:0px 0px 0px 5px;left: 66%;top: 73%;">
                  <p class="smalll text-danger"
                    style="padding: 5px 5px !important; margin-bottom: 0px !important; font-size: 12px !important;">
                    <strong style="font-size: 12px;"> File Size should not be larger than 2MB. <br> File Extensions:
                      .jpg .png</strong>
                  </p>
                </span>
              </i>
            </h4>
          </div>
        </div>

        <div class="card imageCard" [ngClass]="darkMode ? 'card_dark' : 'card'" style="padding-right: 0px !important;">
          <div class="row">
            <div class="col-md-12">
              <div class="row photo-box-wrapper">
                <div class="col-md-12">
                  <div class="col-md-3 imageCol" *ngFor="let img of hotel_images; let i = index"
                    style="padding: 0px;">
                    <div class="photo-box">
                      <a class="" (click)="remove_photo(i)">
                        <img width="25px" src="assets/img/manage-rooms/delete.png">
                      </a>
                      <span *ngIf="i == 1" class="pull-left main">Main</span>
                      <img class="uploadedImg" src="{{ base_url2+'app/images/'+img.image }}" width="100%">
                    </div>
                  </div>
                  <div class="col-md-3 imageCol" style="padding-right: 0px;margin-right: 0px;">
                    <label class="btn btn-primary photo-style hov-add" (mouseover)="plusHover = true"
                      (mouseout)="plusHover = false" style="height:133px;margin-bottom: 5px;border-radius: 10px;">
                      <input #photo_input type="file" name="photo" class="form-control" (change)="onFileChange($event)"
                        multiple="true">
                      <img src="assets/img/create-rooms/Asset_10.png" alt="" class="plusImg" *ngIf="plusHover == false">
                      <img src="assets/img/back-office/add-image-yellow.svg" alt="" class="plusImg"
                        *ngIf="plusHover == true">
                      <br />
                      <p> Add More Photos</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gradient-bar " [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
        <div class="">
          <h4>Popular Amenities</h4>
        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="room_amenities">
          <div *ngFor="let amenity of popular_amenities; let i = index">
            <div class="row" *ngIf="i%4 == 0 || i == 0">
              <div *ngFor="let a of [0,1,2,3]" class="width_100">
                <div class="col-md-3 amenity" *ngIf="popular_amenities[i+a] && popular_amenities[i+a].children[0]">
                  <img src="assets/img/hotel-setup/Darkmode/{{ (popular_amenities[i+a].icon).split('.')[0]}}.svg"
                    style="width: 27px; margin-left: 2px; margin-top: 5px;" *ngIf="darkMode == false ">
                  <img
                    src="assets/img/hotel-setup/Darkmode/darkmode/icons/{{ (popular_amenities[i+a].icon).split('.')[0]}}.png"
                    style="width: 27px; margin-left: 2px; margin-top: 5px;" *ngIf="darkMode == true">
                  <p><b class="customSizingAm" [ngStyle]="{'color': darkMode ? amenCol[i+a] : '#00205b'}">{{
                      popular_amenities[i+a].title }}</b></p>
                  <ul>
                    <li *ngFor="let childs of popular_amenities[i+a].children">
                      <label class="control-label checkbox-style" (mousedown)="chkchkbox('trash-'+childs.id)">
                        <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}" name="most_amenties[]"
                          value="{{ childs.id }}">
                        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                      </label>
                      <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right"><img class="" src="assets/img/hotel-setup/trash.png"></a> -->
                    </li>
                  </ul>

                  <hr class="bottom-line" *ngIf="popular_amenities[i+a].show_add == false">
                  <a class="btn add-new"
                    *ngIf="popular_amenities[i+a].show_add == false && popular_amenities[i+a].title != 'Weather'"
                    (click)="popular_amenities[i+a].show_add = true">+ Add New</a>
                  <!-- <a class="btn add-new"
                  *ngIf="popular_amenities[i+a].show_add == false && popular_amenities[i+a].title == 'Weather'"
                  (click)="popular_amenities[i+a].show_add = true">+ Add City</a> -->
                  <div class="add-new-box" *ngIf="popular_amenities[i+a].show_add == true">
                    <input type="text" class="form-control" formControlName="add_most_amenite">
                    <a class="btn btn-xs pull-right" (click)="popular_amenities[i+a].show_add = false">
                      <img src="assets/img/hotel-setup/wrong.png">
                    </a>
                    <a class="btn btn-xs pull-right" href="javascript:void(0)"
                      (click)="add_amenitie('most',popular_amenities[i+a].id)"><img
                        src="assets/img/hotel-setup/right.png">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
        <div class="">
          <h4>Other Facilities | Amenities</h4>
        </div>
      </div>

      <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="room_amenities">
          <div *ngFor="let amenity of other_amenities; let i = index">
            <div class="row" *ngIf="i%4 == 0 || i == 0">
              <div *ngFor="let a of [0,1,2,3]" class="width_100">
                <div class="col-md-3 amenity" *ngIf="other_amenities[i+a] && other_amenities[i+a].children[0]">
                  <img src="assets/img/hotel-setup/Darkmode/{{ (other_amenities[i+a].icon).split('.')[0]}}.svg"
                    style="width: 30px; height: 30px;" *ngIf="darkMode == false ">
                  <img
                    src="assets/img/hotel-setup/Darkmode/darkmode/icons/{{ (other_amenities[i+a].icon).split('.')[0]}}.png"
                    style="width: 30px; height: 30px;" *ngIf="darkMode == true">
                  <p><b class="customSizingAm" [ngStyle]="{'color': darkMode ? amenColot[i+a] : '#00205b'}">{{
                      other_amenities[i+a].title }}</b></p>
                  <ul>
                    <li *ngFor="let childs of other_amenities[i+a].children"
                      (mousedown)="chkchkbox('trash-'+childs.id)">
                      <label class="control-label checkbox-style">
                        <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}" name="most_amenties[]"
                          value="{{ childs.id }}">
                        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                        <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'">{{ childs.title }}</span>
                      </label>
                      <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right"><img class="" src="assets/img/hotel-setup/trash.png"></a> -->
                    </li>
                  </ul>
                  <hr class="bottom-line" *ngIf="other_amenities[i+a].show_add == false">
                  <a class="btn add-new" *ngIf="other_amenities[i+a].show_add == false"
                    (click)="other_amenities[i+a].show_add = true">+ Add New</a>
                  <div class="add-new-box" *ngIf="other_amenities[i+a].show_add == true">
                    <input type="text" class="form-control" formControlName="add_other_amenite">
                    <a class="btn btn-xs pull-right" (click)="other_amenities[i+a].show_add = false">
                      <img src="assets/img/hotel-setup/wrong.png">
                    </a>
                    <a class="btn btn-xs pull-right" href="javascript:void(0)"
                      (click)="add_amenitie('other',other_amenities[i+a].id)"><img
                        src="assets/img/hotel-setup/right.png"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="modal-footer" style="padding:0px 18px; bottom: -7.75%">
        <button type="submit" class="btn btn-primary btn-lg hov-btn"
          [ngClass]="darkMode ? 'btnblue_dark' : ' btn-primary'" (click)="onSubmit()">Save</button>
        <a href="#" class="btn  btn-lg hov-btn" data-dismiss="modal" aria-label="Close"
          [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Close</a>
      </div>
    </form>
  </div>

</div>
