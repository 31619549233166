import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PNLDataResponse } from '../common/pnl/PNLDataResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { data } from 'jquery';
import { ApiService } from './api.service';
import { payroleResponseData, payrollResponse } from '../common/pnl/payrole/payroleModel';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PayroleService {

  private GetPayroleEndpoint: string = '/api/payroll/add_update_payroll_employee';
  private GetPayroleEmployeeNameEndpoint: string = '/api/payroll/get_employee_names/';
  private GetPayroleDepartmentEndpoint: string = 'api/payroll/get_all_departments/';
  private GetPayroleEmployeeRoleEndpoint: string = '/api/payroll/get_employee_roles';
  private GetPayroleEmployeeListEndpoint: string = '/api/payroll/get_employee_list/';
  private GetPayroleEmployeeDetailPrintEndpoint: string = '/api/payroll/get_single_employee_details_print/';
  private GetPayroleEmployeeDetailEndpoint: string = '/api/payroll/get_single_employee_details/';

  private propertyId: string;
  public payroleEmployeeEdit$ = new Subject<payroleResponseData>();

  constructor(private api: ApiService, private http: HttpClient, private toaster: ToastrService) {
    this.propertyId = localStorage.getItem("current_property");
  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);
    return header

  }
  // get payrole data (working on saud 👇)
  getPayroleByReq(data: payroleResponseData): Observable<payrollResponse> {
    return this.http.post<payrollResponse>(this.api.base_url + this.GetPayroleEndpoint, data, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole employee names (working on saud 👇)
  getPayroleEmployeeName(): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleEmployeeNameEndpoint + this.propertyId, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole employee roles (working on saud 👇)
  getPayroleEmployeeRole(): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleEmployeeRoleEndpoint, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole departement (working on saud 👇)
  getPayroleDepartment(): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleDepartmentEndpoint + this.propertyId, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole employee list data (working on saud 👇)
  getPayroleEmployeeList(): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleEmployeeListEndpoint + this.propertyId, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole employee details print popup data (working on saud 👇)
  getPayroleEmployeeDetailsPrint(id): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleEmployeeDetailPrintEndpoint + id, {
      headers: this.prepareAuthHeaders()
    })
  }

  // get payrole employee details data (working on saud 👇)
  getPayroleEmployeeDetails(id): Observable<any> {
    return this.http.get(this.api.base_url + this.GetPayroleEmployeeDetailEndpoint + id, {
      headers: this.prepareAuthHeaders()
    })
  }


}
