import { HelperService } from './../../services/helper.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EventsService } from '../../services/events.service';
import * as moment from 'moment';
import { PnlService } from 'src/app/services/pnl.service';
import { NotificatioResponse } from '../notifications/NotificationModal';
import { Observable, Subscription, interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user;
  darkMode: boolean = false;
  base_url2;
  notifications: string;
  subscription: any;

  constructor(
    public events: EventsService,
    public helper: HelperService,
    private api: ApiService,
    private pnl: PnlService,
  ) {
    this.base_url2 = this.api.base_url2;
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    });
    this.helper.user_subject.subscribe(user => {
      this.user = user;
    });

    // Fetch initial notification
    this.pnl.notification$.subscribe(res => {
      this.notifications = res;
    });

    // Schedule subsequent notifications every 1 minute (60,000 milliseconds)
    this.subscription = timer(0, 300000)
      .pipe(
        switchMap(() => this.pnl.getNotifications())
      )
      .subscribe(response => {
        this.notifications = response.data.notification;
        console.log('Updated Notifications:', this.notifications);
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the timer when the component is destroyed
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.checkDarkMode();
  }


  checkDarkMode() {
    let a = JSON.parse(localStorage.getItem('user'));
    if (a != null) {
      let mode = a.mode;
      if (mode == 'dark') {
        this.darkMode = true;
      }
    }
  }

}



