<div class="alert alert-danger breadcrum" style="height: 52px;" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
    <div class="row">
        <div class="col-md-4">

            <a class="btn btn-link " href="" routerLink="/app/stays">STAYS</a>
            <a class="btn btn-link " href="" routerLink="/app/inventory">PROMO CALENDER</a>
            <a class="btn btn-link active" href="">GUEST LOOK UP</a>
        </div>
        <!-- <a class="btn btn-link " href="" routerLink="/app/communication/emailtemplate" *ngIf="['/app/communication'].includes(router.url)">Template</a> -->

        <div class="col-md-8" style="padding-top: 5px;">
            <div class="row opt-mar-0" style=" display:flex; justify-content:flex-end">
                <!-- <div class="col-md-1 csW0"></div> -->
                <div class="col-md-1 filters_col" style="padding: 0%; padding-right: 15px">
                    <button class="btn btn-block bread-search" (click)="query=''; selected0=null;getDirectory(1)">
                        Reset
                    </button>
                </div>
                <div class="col-md-2 zero-padding csW1">
                    <!-- <div class="input-group">
                   <input  type="text" class="form-control" name="search" placeholder="Name, Email, Phone Number & Res ID" style="height: 40px;border-right-color: white;" [(ngModel)]="query" (keyup)="param = query">
                   <span class="input-group-addon"><i class="glyphicon glyphicon-search"></i></span>
                   </div> -->
                    <div class="input-group">
                        <!-- <input type="text" class="form-control" placeholder="Name, Email, Phone Number, Confirmation id or Reservation id" name="search" [(ngModel)]="query" (keyup)="param = query" style="border: none;"> -->
                        <!-- <input type="text" class="form-control" placeholder="Guset name" name="search"
                            [(ngModel)]="query" (keyup)="param = query" style="border: none;"> -->

                        <div class="input-group  b-addon_guest">
                            <input type="text" matInput placeholder="Guest Search" class="form-control bred-search"
                                [(ngModel)]="query" (keyup.enter)="param = query" id="input0" style="text-transform: capitalize; border: none;
                                margin-top: 1px;height: auto;" />
                            <i class="fa fa-times" (click)="query='';getDirectory(1)" *ngIf="query"></i>
                        </div>
                        <div class="input-group-btn">
                            <button class="btn btn-default" type="submit"><i
                                    class="glyphicon glyphicon-search"></i></button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-2 zero-padding csW2" style="padding-right: 5px">
                    <ng-select class="form-control custom input_2 padding-holder " placeholder="Filter by month "
                        style="height: 40px;padding:0px;padding-left: 5px;padding-right: 5px;background: linear-gradient(#fff, lightgrey)"
                        [(ngModel)]="month" [items]="months" bindValue="name" bindLabel="name"
                        (change)="filterNow(month, filter)">
                    </ng-select>
                </div> -->
                <div class="col-md-2 filters_col" style="padding: 0px; padding-right: 5px; width: 185px">
                    <div class="form-control input_2 background-gray padding-5 calendar-top f-calendar" style="
                                          background: linear-gradient(#fff, lightgrey);
                                          border: none !important;">
                        <span class="input-group-addon" style="
                                      padding: inherit;
                                      background: transparent;
                                      border: none;
                                      font-size: 24px;
                                      color: #00205b;
                                      display: inline;
                                    ">
                            <img src="assets/img/reports/calendar.png" style="margin-top: 10px; margin-bottom: 10px"
                                alt="" />
                        </span>
                        <input type="hidden" name="date_to" />
                        <input type="hidden" name="date_from" />
                        <input type="text" ngxDaterangepickerMd
                            class="form-control date_range_picker arrival-lenght singMulCalen"
                            (change)="calendar_change($event)" [singleDatePicker]="false" placeholder="Date"
                            [autoApply]="true" [(ngModel)]="selected0"
                            [locale]="{ format: 'DD MMM YYYY', separator: '  To  ' }" />
                    </div>

                </div>
                <div class="col-md-2 zero-padding csW3" style="padding-right: 5px;">
                    <ng-select class="form-control custom input_2 padding-holder " placeholder="All Guests"
                        style="height: 40px;padding:0px;background: linear-gradient(#fff, lightgrey)"
                        [(ngModel)]="filter" [items]="filters" bindValue="val" bindLabel="name"
                        (change)="filterNow(month, filter)">
                    </ng-select>
                </div>
                <div class="col-md-1 filters_col" style="padding: 0%; padding-right: 5px">
                    <button class="btn btn-block bread-search" (click)="adte(selected0)">
                        Search
                    </button>
                </div>
                <div class=" col-md-2 zero-padding icons d-flex">
                    <img src="assets/img/communications/Group 5700.png" class="bread-icon hovIco" data-toggle="modal"
                        data-target="#myModal-2" style="transform: translateX(3px);" (click)="show_add = true;">
                    <!-- <img src="assets/img/communications/Group 5701.png" class="bread-icon hovIco" style="transform: translateX(5px)" (click)="makePdf()" > -->
                    <img src="assets/img/inventory/download-icon.png" style="height: 40px; padding-left: 5px;"
                        (click)="downloadCSV()" title="Download CSV">
                    <label for="fileInput" class="uploadExcelImg">
                        <i aria-hidden="true" class="fa fa-upload" style="font-size:18px; color: white;"> </i>
                    </label>
                    <input id="fileInput" (change)="onFileChange($event)" type="file" accept=".xlsx, .xls, .csv" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="capture">
    <div class="alert alert-info ">
        <ul class="nav nav-inline nav-blue">

            <li class="li-width">
                <!-- <a><img src="assets/img/newstaticicons/communication/user.svg" style="height:20px" alt=""></a> -->
                <span class="text-light">Booked By</span>
            </li>
            <li class="li-width">
                <!-- <a><img src="assets/img/newstaticicons/communication/clander2.svg" alt=""></a> -->
                <span class="text-light">Guest name</span>
            </li>
            <li class="li-width">
                <!-- <a><img src="assets/img/newstaticicons/communication/phone2.svg" alt="" style="border-radius: 6px; height: 24px;"></a> -->
                <span class="text-light">Check In</span>
            </li>
            <li class="li-width">
                <!-- <a><img src="assets/img/newstaticicons/communication/previliges3.svg" alt="" style="height:21px;"></a> -->
                <span class="text-light">Room Type</span>
            </li>
            <li class="li-width">
                <!-- <a><img src="assets/img/newstaticicons/communication/notes5.svg" alt=""></a> -->
                <span class="text-light">Channel</span>
            </li>
            <!-- <li class="li-width">
                <a><img src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/room_number.svg' : '/bed2.svg'}}" alt=""></a>
            </li> -->
        </ul>
    </div>
    <div class="card main" [ngClass]="darkMode ? 'card_dark2' : 'card'">

        <div id="drag-list" id="capture">
            <!-- communication skeleton start (working on saud 👇) -->
            <ng-container *ngIf="skeleton">
                <div style="margin-bottom: 4px" *ngFor="let x of getEmptyElements(16);index as i">
                    <div class="row field-space">
                        <div class="col-md-12">
                            <div class="border-style">
                                <div class="element skeleton-animation">
                                    <ul class="nav nav-inline d-flex" style="transform: translate(5px,-8px);">
                                        <li class="li-width-2 d-flex justify-content-center"
                                            style="padding-left: 0px;margin-left: 0px;">
                                            <div class="skeleton-box" [ngClass]="getGreyType(i)"></div>
                                        </li>
                                        <li class="li-width-2 d-flex justify-content-center"
                                            style="padding-left: 0px;margin-left: 0px;">
                                            <div class="skeleton-box" [ngClass]="getGreyType(i+2)"></div>
                                        </li>
                                        <li class="li-width-2 d-flex justify-content-center"
                                            style="padding-left: 0px;margin-left: 0px;">
                                            <div class="skeleton-box" [ngClass]="getGreyType(i+1)"></div>
                                        </li>
                                        <li class="li-width-2 d-flex justify-content-center"
                                            style="padding-left: 0px;margin-left: 0px;">
                                            <div class="skeleton-box" [ngClass]="getGreyType(i)"></div>
                                        </li>
                                        <li class="li-width-2 d-flex justify-content-center"
                                            style="padding-left: 0px;margin-left: 0px;">
                                            <div class="skeleton-box" [ngClass]="getGreyType(i+2)"></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- communication skeleton end (working on saud 👇) -->

            <!-- <div class="" style="margin-bottom: 4px"
                *ngFor="let g_directory of g_directory | search:'guest_firstname,guest_email,guest_phone':param; let i = index"
                id="{{ g_directory.id }}"> -->
            <div class="" style="margin-bottom: 4px" *ngFor="let g_directory of g_directory; let i = index"
                id="{{ g_directory.id }}">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="border-style {{ g_directory.b_color }}"
                            [ngStyle]="{'border-color': directoryBorder[i%directoryBorder.length]}">
                            <div class="element">
                                <ul class="nav nav-inline" style="transform: translate(5px,-8px);">
                                    <li class=" li-width-2" (click)="openDetails(g_directory.personal_id_value)"
                                        style="padding-left: 0px;margin-left: 0px;cursor: pointer"> {{
                                        g_directory.guest_firstname }} </li>
                                    <li class=" li-width-2" (click)="openDetails(g_directory.personal_id_value)"
                                        style="padding-left: 0px;margin-left: 0px;cursor: pointer">
                                        {{formatGuestName(g_directory.bookingitems[0]?.guest_name)}}</li>
                                    <li class=" li-width-2" (click)="openDetails(g_directory.personal_id_value)"
                                        style="cursor: pointer">{{ g_directory.date_arrival | date:'d MMM | y' }}</li>
                                    <!-- <li class=" li-width-2" (click)="openDetails(g_directory.guest_email)" style="cursor: pointer">{{ g_directory.guest_phone }}</li> -->
                                    <!-- <li class=" li-width-2" (click)="openDetails(g_directory.guest_email)" style="transform: translateX(3px);cursor: pointer"> {{ g_directory.privilege }} </li> -->
                                    <!-- <li class=" li-width-2" (click)="openDetails(g_directory.guest_email)" style="transform: translateX(5px);cursor: pointer">{{ g_directory.roomnums.length == 1 ? g_directory.roomnums[0]['room_number'] : 'N-A' }}</li> -->
                                    <li class=" li-width-2" (click)="openDetails(g_directory.personal_id_value)"
                                        style="transform: translateX(5px);cursor: pointer">{{
                                        getRoomOfGuest(g_directory) }}</li>
                                    <li class=" li-width-2" style="transform: translateX(5px);cursor: pointer"
                                        (click)="openPopup(g_directory)">
                                        <!-- {{ g_directory.channel_name }} -->
                                        <img [src]="'assets/img/svgicons/all-channels/' + g_directory.channel_image"
                                            height="40" alt="channelImage">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 action-btns">
                    </div>
                </div>
            </div>
        </div>

        <nav aria-label="Page navigation example" id="paginav" *ngIf="delay">
            <div class="pagnav_div">
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item" style="margin-right: 5px;">
                            <a class="page-link linksAnchor" aria-label="Previous" (click)="getDirectory(1)"
                                [ngClass]="paginationData.current_page == 1 ? 'disableLink' : 'null'">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link linksAnchor" aria-label="Previous"
                                (click)="getDirectory(selectedIndex-1)"
                                [ngClass]="paginationData.current_page == 1 ? 'disableLink' : 'null'">
                                <span aria-hidden="true">&lsaquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;" class="cuspagidiv" id="cuspagidiv">
                    <div class="page-item cusPagi" *ngFor="let numbers of pagi;let i = index"
                        id="active{{numbers.page_no}}" (click)="selectedIndex = i" [class.active]="selectedIndex == i+1"
                        (click)="getDirectory(numbers.page_no)"><a class="page-link">{{numbers.page_no}}</a>
                    </div>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link linksAnchor" aria-label="Next" (click)="getDirectory(selectedIndex+1)"
                                [ngClass]="paginationData.last_page == selectedIndex ? 'disableLink' : 'null'">
                                <span aria-hidden="true">&rsaquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div style="float: left;">
                    <ul class="pagination" id="myDIVActive">
                        <li class="page-item">
                            <a class="page-link linksAnchor" aria-label="Next" (click)="getDirectory(lastpage)"
                                [ngClass]="paginationData.last_page == selectedIndex ? 'disableLink' : 'null'"
                                style="margin-left: 5px;">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</div>

<!-- modals -->
<div id="myModal" class="modal fade" role="dialog" style="width:100%;" *ngIf="my_object">
    <div class="modal-dialog modal-lg" style="width: 1100px;">
        <!-- Modal content-->
        <div class="modal-content" [ngClass]="darkMode ? 'dark_popup' : 'null'">
            <div class="modal-header">
                <div class="bluheader booking-sumary dargmd" (mouseover)="dodrag('myModal')" id="mySumModalp"
                    (mouseout)="dontdrag('myModal')" (mouseout)="dontdrag('myModal-3')"
                    (mousedown)="grab('mySumModalp','do')" (mouseup)="grab('mySumModalp','dont')">
                    <h3 class="h3_blu_header bookingsummary">Booked By<button type="button" class="close"
                            data-dismiss="modal" aria-label="Close" style="color: white;line-height: 34px">
                            <strong id="cross">&#x2716;</strong>
                        </button>
                    </h3>
                </div>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="col-md-12 card"
                            style="padding-bottom: 0px !important;    margin-top: -0px !important;"
                            [ngClass]="darkMode ? 'card_dark' : 'null'">
                            <div class="row">
                                <div class="col-md-6" style="padding-right: 0px;">
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-1">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/user.svg" alt=""
                                                        class="icns im" style="height:20px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -33px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Full Name
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Full Name"
                                                [value]="my_object[0].guest_firstname" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-6">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/r-messege-red.svg"
                                                        alt="" class="icns im" style="height:20px; border-radius: 4px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -33px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Email
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Email"
                                                [value]="my_object[0].guest_email" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-3">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                                                        class="icns im" style="height:23px; border-radius: 4px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -56px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Phone Number
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Phone Number"
                                                [value]="my_object[0].guest_phone" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-8">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                                        class="icns im" style="height:20px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            ID Type
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="ID Type"
                                                [value]="my_object[0].personal_id_type" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-9">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                                        class="icns im" style="height:20px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            ID Number
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="ID Number"
                                                [value]="my_object[0].personal_id_value" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-10 addreso">
                                            <div class="addor"
                                                style="margin-left: -10px; display: flex; justify-content: center;">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/location.svg" alt=""
                                                        class="icns im" style="height:25px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -28px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Address
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </div>
                                            <div class="address">
                                                <input type="text" class="bookedByInput" placeholder="Address"
                                                    [value]="my_object[0].guest_address" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--second---->
                                <div class="col-md-6" style="padding-left:0px;">
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-11">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/City Icon.svg"
                                                        alt="" class="icns im" style="height:30px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            City
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="City" [value]=""
                                                readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-12 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-12">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/County icon.svg"
                                                        alt="" class="icns im" style="height:30px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Country
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Country" [value]=""
                                                readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-4">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Nationality.svg"
                                                        alt="" class="icns im" style="height:30px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Nationality
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Nationality"
                                                [value]="" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-5">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Gender.svg"
                                                        alt="" class="icns im" style="height:30px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Gender
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Gender" [value]=""
                                                readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-1">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Issue Date iocn.svg"
                                                        alt="" class="icns im" style="height:25px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Issue Date
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Issue Date" [value]=""
                                                readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 zero-padding" style="padding-right:2.5px;">
                                        <div class="modal-bar be-2">
                                            <label class="f-name">
                                                <!---start tooltip--->
                                                <div class="ispan1">
                                                    <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Epiry Date.svg"
                                                        alt="" class="icns im" style="height:25px; border-radius: 5px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Expiry Date
                                                        </p>
                                                    </span>
                                                </div>
                                                <!---end tooltip--->
                                            </label>
                                            <input type="text" class="bookedByInput" placeholder="Expiry Date"
                                                [value]="" readonly>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-12 zero-padding" style="padding-left:2.5px;">
                                        <div class="modal-bar be-5">
                                            <label class="f-name">
                                                -start tooltip-
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt=""
                                                        class="icns im" style="height:20px; border-radius: 4px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -44px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Privileges
                                                        </p>
                                                    </span>
                                                </div>
                                                -end tooltip-
                                            </label>
                                            <label class="name">loyalty card</label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-12 zero-padding" style="padding-left:2.5px;">
                                        <div class="modal-bar be-9">
                                            <label class="f-name">
                                                -start tooltip-
                                                <div class="ispan1">
                                                    <img src="assets/img/newstaticicons/communication/notes3.svg" alt=""
                                                        class="icns im" style="height:27px;">
                                                    <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                        style="margin-left: -28px;">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            Note
                                                        </p>
                                                    </span>
                                                </div>
                                                -end tooltip-
                                            </label>
                                            <label class="name">{{my_object[0].comments}}</label>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12">
                                        <div class="row">
                                            <!-- <div class="col-md-12 zero-padding" style="padding-left: 2.5px;">
                                 <button class="btn btn-block email-btn" data-dismiss="modal" aria-label="Close" (click)="getEmail(my_object)"> <img src="assets/img/newstaticicons/dashboard/close-envelop.svg" style="height: 23px;"></button>
                              </div> -->
                                            <div class="col-md-12 zero-padding" style="padding-left: 2.5px;">
                                                <button class="btn btn-block reservation-btn"
                                                    (click)="getDataInventory(my_object)" data-dismiss="modal"
                                                    aria-label="Close">
                                                    Quick Setup
                                                    <!-- <img src="assets/img/dashboard/darkmode/reservation.svg"
                                                        style="height: 25px;"> -->
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 card" style="margin-top: -0px !important;"
                            [ngClass]="darkMode ? 'card_dark2' : 'card'">
                            <div class="alert alert-info modal-table" style="margin-bottom: 5px;"
                                [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                                <ul class="nav nav-inline nav-blue-2">
                                    <li class="li-width-3"><a>Channel</a></li>
                                    <li class="li-width-3"><a>Guest name</a></li>
                                    <li class="li-width-3"><a>Room no</a></li>
                                    <li class="li-width-3"><a>Check In</a></li>
                                    <li class="li-width-3"><a>Check Out</a></li>
                                    <li class="li-width-3"><a>LOS</a></li>
                                    <li class="li-width-3"><a><img alt="discount" style="height: 22px"
                                                src="assets/img/newstaticicons/arrivals/discount-light.svg"></a></li>
                                    <li class="li-width-3"><a>Price</a></li>
                                </ul>
                            </div>
                            <div class="col-md-12 zero-padding"
                                *ngFor="let g_history of my_object[0].bookingitems;let i = index">
                                <div class="collapseable"
                                    [ngStyle]="{'border-color': darkMode ? 'orange' : directoryPopupBorder[i%directoryPopupBorder.length] }">
                                    <ul class="nav nav-inline nav-blue-3" data-toggle="collapse"
                                        [attr.data-target]="'#collapseExample' + g_history.id" aria-expanded="false"
                                        [attr.aria-controls]="'collapseExample' + g_history.id">
                                        <!-- <li class="li-width-3"><a style="color: #006aff !important;padding: 0;"> {{
                                                g_history.channel.name | wordLength }}</a></li> -->
                                        <li class="li-width-3"><img
                                                [src]="'assets/img/svgicons/smallicons/' + my_object[0].channel.image"
                                                height="40" alt="channelSmallImage" class="channelSmallImage"></li>
                                        <li class="li-width-3"><a style="color: #006aff !important;padding: 0;"
                                                (click)="getDataInventory(my_object)" data-dismiss="modal"
                                                aria-label="Close"> {{formatGuestName(g_history?.guest_name)}}</a></li>
                                        <!-- <li class="li-width-3"><a>{{ g_history.bookingitems.length > 0 ? g_history.room.roomtype.name  :  'Bulk Reservation' }}</a></li> -->
                                        <li class="li-width-3"><a>{{ g_history.roomnum.room_number
                                                }}</a></li>
                                        <li class="li-width-3"><a>{{ my_object[0].date_arrival | date:'d MMM | yy'
                                                }}</a>
                                        </li>
                                        <li class="li-width-3">
                                            <a>{{ g_history.check_out | date:'d MMM | yy' }}</a>
                                        </li>
                                        <li class="li-width-3"><a *ngIf="my_object"> {{
                                                getNights(my_object[0].date_arrival, my_object[0].date_departure)
                                                }}<img alt="day/night" style="height: 20px;margin-left: 2px;"
                                                    src="assets/img/newstaticicons/inventory/{{ nightCount ? 'night.svg' : 'icons-sun.png' }}"></a>
                                        </li>
                                        <li class="li-width-3"><a>{{ my_object[0].discount ? my_object[0].discount : 0
                                                }}%</a>
                                        </li>
                                        <li class="li-width-3">
                                            <a>{{currency_format}} {{ my_object[0].total | number:'1.2-3' }}<img
                                                    src="assets/img/reports/button_down.svg" alt="dropdown"
                                                    class="collapseimg" data-toggle="collapse"></a>
                                        </li>
                                    </ul>
                                    <div class="collapse" id="collapseExample{{ g_history.id }}">
                                        <div class="collapseContent">
                                            <div class="card employeeCard">
                                                <div class="row m-0 mx-2">
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar tweenty2 d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/user.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Guset Name
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name"
                                                                    [value]="formatGuestName(g_history?.guest_name)"
                                                                    placeholder="Guset Name">
                                                                <!-- <label class="name">{{ g_history.guest_name }}</label> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar two d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/user.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Father Name
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Father Name">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar three d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/idtype.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            ID Type
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name"
                                                                    [value]="my_object[0].personal_id_type"
                                                                    placeholder="ID Type">
                                                                <!-- <label class="name">{{ my_object[0].personal_id_type
                                                                    }}</label> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar five d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/idtype.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            ID Number
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name"
                                                                    [value]="my_object[0].personal_id_value"
                                                                    placeholder="ID Number">
                                                                <!-- <label for="" class="name">{{ my_object[0].personal_id_value }}</label> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding">
                                                        <div class="modal-bar four d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/Scan-data-employee/Issue Date iocn.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Issue Date
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Issue Date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar tweenty22 d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/Scan-data-employee/Epiry Date.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Expiry Date
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Expiry Date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar eight d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/Scan-data-employee/City Icon.svg"
                                                                        alt="user" class="icns im" style="height:25px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            City
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="City">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar nine d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/state.png"
                                                                        alt="user" class="icns im" style="height:25px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            State
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="State">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar tweenty12 d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/inventory/Scan-data-employee/County icon.svg"
                                                                        alt="user" class="icns im" style="height:25px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Country
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Country">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding">
                                                        <div class="modal-bar seven d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/arrivals/addpersonicon1.svg"
                                                                        alt="user" class="icns im" style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Extra Person
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name"
                                                                    [value]="my_object[0].extra_person_fee != null ? my_object[0].extra_person_fee : 0"
                                                                    placeholder="Extra Person Fee">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar eight d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/communication/Service.svg"
                                                                        alt="addons icon" class="icns im"
                                                                        style="height:25px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Service Addons
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Service" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar nine d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/communication/mini-bar.svg"
                                                                        alt="addons icon" class="icns im"
                                                                        style="height:25px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Mini bar Addons
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Mini bar" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding" style="padding-right:5px;">
                                                        <div class="modal-bar tweenty12 d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/arrivals/fnb.svg"
                                                                        alt="fnb icon" class="icns im"
                                                                        style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Food & bev
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Food & bev" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 zero-padding">
                                                        <div class="modal-bar seven d-flex">
                                                            <label class="f-name">
                                                                <!---start tooltip--->
                                                                <div class="ispan1">
                                                                    <img src="assets/img/newstaticicons/arrivals/dollar-light.svg"
                                                                        alt="total amount icon" class="icns im"
                                                                        style="height:20px;">
                                                                    <span class="toolo"
                                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                                                        style="margin-left: -33px;">
                                                                        <p class="small"
                                                                            style="padding: 0px !important; margin: 0px !important;">
                                                                            Total
                                                                        </p>
                                                                    </span>
                                                                </div>
                                                                <!---end tooltip--->
                                                            </label>
                                                            <div class="text-ellipse"><input type="text"
                                                                    class="form-control name" [value]=""
                                                                    placeholder="Total" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="row dflex" style="margin-top: 10px;">
                                                    <div class="employeeData">
                                                        <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Epiry Date.svg" alt="expired-date" class="icns im" style="height:20px;">
                                                        <span class="employeeDetail"><b>Expiry Date:</b>
                                                            16-1-2033</span>
                                                    </div>
                                                    <div class="employeeData">
                                                        <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/Date Of Birth Icon.svg" alt="date-of-birth" class="icns im" style="height:20px;"><span class="employeeDetail pt-3"><b>D.O.B:</b>18-10-2003</span>
                                                    </div>
                                                    <div class="employeeData">
                                                        <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/City Icon.svg" alt="city" class="icns im" style="height:25px; border-radius: 5px;"> <span class="employeeDetail"><b>City:</b> Ottawa</span>
                                                    </div>
                                                    <div class="employeeData">
                                                        <img src="assets/img/newstaticicons/inventory/state.png" alt="state" class="icns im" style="height:25px; border-radius: 5px;"> <span class="employeeDetail"><b>State:</b> Columbia</span>
                                                    </div>
                                                    <div class="employeeData">
                                                        <img src="../../../assets/img/newstaticicons/inventory/Scan-data-employee/County icon.svg" alt="country" class="icns im" style="height:25px;"> <span class="employeeDetail"><b>Country:</b> Canada</span>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- email popup -->
<div id="myModal-2" class="modal fade" role="dialog" style="width: 100%;">
    <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <form [formGroup]="email_form">
            <div class="modal-content" style="background-color: #f1f1f1" [ngClass]="darkMode ? 'dark_popup' : 'null'">
                <div class="modal-header">
                    <div class="bluheader booking-sumary dargmd" (mouseover)="dodrag('myModal-2')"
                        (mouseout)="dontdrag('myModal-2')">
                        <h3 class="h3_blu_header bookingsummary">Create Directory<button type="button" class="close"
                                data-dismiss="modal" aria-label="Close" style="color: white;line-height: 34px"
                                (click)="closeEmailModal()">
                                <strong id="cross">&#x2716;</strong>
                            </button>
                        </h3>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-8 zero-padding" style="padding-right: 5px;height: 461px">
                                <div class="bluheader booking-sumary shows" style="padding-right:0px;padding-top: 0px"
                                    [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                                    <h3 class="h3_blu_header bookingsummary recipts">Recipients</h3>
                                    <img src="assets/img/communications/plus-4.png" class="plus-img" width="30px"
                                        height="30px">
                                    <div class="col-md-6 plus-div" id="plus-search">
                                        <div class="col-md-12" style="padding:0px" id="plus-search-box ">
                                            <div class="col-md-12 " style="padding: 0px;">
                                                <div class="col-md-12" style=" padding-left:10px;padding-right: 0px">
                                                    <input type="text" class="input-plus" placeholder="Name, Email"
                                                        [(ngModel)]="query" [ngModelOptions]="{standalone: true}"
                                                        (keyup)="param = query">
                                                    <img src="assets/img/communications/plus-4.png" id="p-1"
                                                        class="hideplus" width="30px" height="30px"
                                                        style="cursor: pointer;margin-left: 5px;margin-top: -3px">
                                                </div>
                                                <div class="col-md-12 my-scroll-s">
                                                    <div class="col-md-12 s-result"
                                                        *ngFor="let g_directory of g_directory | search:'guest_firstname,email':param"
                                                        (click)="getEmails(g_directory)">
                                                        <div class=" col-md-7 name-s" style="padding: 0px">{{
                                                            g_directory.guest_firstname}} </div>
                                                        <div class=" col-md-5 email-s" style="padding: 0px">{{
                                                            g_directory.guest_email }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="emailCardUser">
                                    <div class="col-md-12 card zero-padding data-card"
                                        style="margin-top: -0px !important;padding-top: 0px !important;"
                                        [ngClass]="darkMode ? 'card_dark' : 'card'">
                                        <div class="col-md-12  zero-padding">
                                            <ul class="nav nav-inline nav-blue-4"
                                                *ngFor="let su of selectedUser; let i = index;">
                                                <div>
                                                    <li class="li-width-4 nm" style="text-align: left !important"><a>{{
                                                            su.guest_firstname }}</a></li>
                                                    <li class="li-width-4 em"><a>{{ su.guest_email }}</a></li>
                                                    <li class="li-width-4 tr" style="text-align: right !important"><a
                                                            (click)="removeUser(i)"><i class="fa fa-trash-alt"
                                                                [ngClass]="darkMode ? 'dark_delet' : 'null'"></i></a>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-12 zero-padding">
                                        <div class="bluheader booking-sumary shows"
                                            [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                                            <h3 class="h3_blu_header bookingsummary">Templates & Schedule </h3>
                                            <!---start tooltip--->
                                            <span class="ispan">
                                                <i class="fas fa-info-circle i" style="font-size: 15px;">
                                                    <span class="tooltiptext"
                                                        [ngClass]="darkMode ? 'tool_dark' : 'null'">
                                                        <p class="small"
                                                            style="padding: 0px !important; margin: 0px !important;">
                                                            ( It gives you opportunity to send this mail on a designated
                                                            date )
                                                        </p>
                                                    </span>
                                                </i>
                                            </span>
                                            <!---end tooltip--->
                                        </div>
                                    </div>
                                    <div class="col-md-12 card"
                                        style="    margin-top: -0px !important;    padding-bottom: 0px !important;"
                                        [ngClass]="darkMode ? 'card_dark' : 'card'">
                                        <div class="col-md-12 zero-padding">
                                            <ng-select class="form-control modal-custom input_2 padding-holder cmst"
                                                placeholder="Select Template "
                                                style="height: 40px;padding:0px;border:none;border-left: 8px solid #ffaa00;border-right: 8px solid #ffaa00;background-color: #f1f1f1"
                                                [ngClass]="darkMode ? 'dark-drop' : 'null'" [items]="e_templates"
                                                bindValue="id" bindLabel="title" (change)="setEmail($event)"
                                                formControlName="email_template_id">
                                                <!-- <ng-option>We are looking forward to meeting you</ng-option>
                                 <ng-option>Your rooms are ready and waiting for you</ng-option>
                                 <ng-option>Why did you cancelled?</ng-option> -->
                                            </ng-select>
                                        </div>
                                        <!-- <div class="col-md-12 zero-padding" style="margin-top: 5px;" >
                              <label class="container" style="" > <span class="txt-0"> ( It gives you opportunity to send this mail on a designated date ) </span>


                              </label>
                           </div> -->
                                        <div class="col-md-12 zero-padding" style="margin-top: 5px;">
                                            <div class="col-md-6 zero-padding" style="padding-right: 3px">
                                                <div class="form-control input_2 background-gray padding-5 calendar-top-0 modal-bar-4 one"
                                                    style="padding:0px;">
                                                    <!-- <input type="hidden" name="date_to">
                                    <input type="hidden" name="date_from"> -->
                                                    <input type="text" ngxDaterangepickerMd
                                                        class="form-control date_range_picker" [autoApply]="true"
                                                        placeholder="Date"
                                                        [locale]="{format: 'DD MMM YYYY', separator: ' To '}"
                                                        [singleDatePicker]="true"
                                                        style="width: 100% !important;color: #00205b; border-radius:0px;"
                                                        formControlName="send_date">
                                                    <div *ngIf="submitted && email_form.get('date_from').errors?.required"
                                                        class="small text-danger no-margin invalid-feedback">
                                                        <strong>Checkin Date is required</strong>
                                                    </div>
                                                    <div *ngIf="submitted && email_form.get('date_to').errors?.required"
                                                        class="small text-danger no-margin invalid-feedback">
                                                        <strong>Checkout Date is required</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 zero-padding" style="padding-left: 2px">
                                                <input type="text" type="time" placeholder="Time"
                                                    class="modal-bar-4 two form-control" style="color: #00205b"
                                                    formControlName="send_time">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 zero-padding " style="padding-bottom: 5px">
                                <div class="col-md-12 zero-padding">
                                    <div class="box-head">
                                        <span>Message</span>
                                    </div>
                                    <div class="box-content">
                                        <input type="text" class="form-control email-txt "
                                            style="border-radius: 0px;border: none;height: 550px;display: none">
                                    </div>
                                    <div class="box-foot" [ngxSummernote]="sConfig" formControlName="html"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer my-foot" style="height:461px">
                </div>
                <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
                    <div class="col-md-2  zero-padding" style="float: right;">
                        <!-- <div class="col-md-6 zero-padding" style="padding-right: 3px">  </div> -->
                        <div class="col-md-12 zero-padding" style="padding-left: 2px">
                            <button class="btn btn-block send-0 modal-btn"
                                [ngClass]="darkMode ? 'btnblue_dark' : 'null'" (click)="save()">Send</button>
                            <button class="btn btn-block close-0 modal-btn" data-dismiss="modal" aria-label="Close"
                                [ngClass]="darkMode ? 'btnred_dark' : 'null'" (click)="closeEmailModal()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- reservaytion popup -->
<div id="myModal-3" class="modal fade" role="dialog" [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
    <form id="formSum">
        <div class="modal-dialog modal-dialog-centered modal-lg modal-width" *ngIf="b_detail">
            <!-- Modal content-->
            <div class="modal-content bg-modal">
                <div class="modal-header">
                    <div class="bluheader booking-sumary" id="sumModalBar" (mouseover)="dodrag('myModal-3')"
                        (mouseout)="dontdrag('myModal-3')" (mousedown)="grab('sumModalBar','do')"
                        (mouseup)="grab('sumModalBar','dont')">
                        <h3 class="h3_blu_header bookingsummary">Reservation
                        </h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="    margin-top: -7px;">
                            <strong id="cross" style="font-size: 21px;">&#x2716;</strong>
                        </button>
                    </div>
                </div>
                <div class="modal-body" style="display: flex;">
                    <div class="upDiv">
                        <div class="col-md-6 zero-padding float-L">
                            <div class="col-md-12 zero-padding" style="padding-right: 2px;">
                                <div class="bluheader booking-sumary redbar">
                                    <h3 class="h3_blu_header bookingsummary">Stays</h3>
                                </div>
                            </div>
                            <div class="col-md-12  zero-padding col-12-0ne onee" style="padding-right: 2px;">
                                <div class="greyBox">
                                    <div class="modal-bar-2 one">
                                        <img alt=""
                                            src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'}}"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Check in" value="{{b_detail?.date_arrival | date:'d MMM y'}}"
                                            readonly>
                                    </div>
                                    <div class="modal-bar-2 three">
                                        <img alt=""
                                            src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg':'dashboard/departure.svg'}}"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Check Out"
                                            value="{{b_detail?.date_departure | date:'d MMM y'}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 two">
                                        <img alt="" src="assets/img/newstaticicons/inventory/ETA-clock.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="time" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="ETA" value="{{b_detail?.eta}}" readonly>
                                    </div>

                                    <div class="modal-bar-2 four">
                                        <img alt="" src="assets/img/newstaticicons/inventory/ETD-clock.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="time" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="ETD" value="{{b_detail?.etd}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 five">
                                        <img alt=""
                                            src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{totalNights}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 six">
                                        <img alt=""
                                            src="assets/img/{{ darkMode ? 'dashboard/darkmode' : 'newstaticicons/dashboard'}}/reservation.svg"
                                            style="height: 16px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Reservation Id" value="{{b_detail?.reservation_id}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 seven">
                                        <img alt="" src="assets/img/newstaticicons/arrivals/id-verified-light.svg"
                                            style="height: 16px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Confirmation ID" value="{{b_detail?.confirmation_number}}"
                                            readonly>
                                    </div>
                                    <div class="modal-bar-2 eight" id="flip">
                                        <div *ngFor="let update_weather of weather_update; let i = index">
                                            <i class='wi wi-main wi-{{ update_weather.icon }}'
                                                [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                                            <div class="weatherDiv">
                                                <span class="wh_name">{{update_weather.title}} </span>
                                                <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                                                <span class="wh_con">{{update_weather.condition}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 zero-padding float-R">
                            <div class="col-md-12 zero-padding" style="padding-left: 3px;">
                                <div class="bluheader booking-sumary redbar">
                                    <h3 class="h3_blu_header bookingsummary">Payment</h3>
                                </div>
                            </div>
                            <div class="col-md-12 zero-padding col-12-four onee" style="padding-left: 2px;">
                                <div class="greyBox">
                                    <div class="modal-bar-2 nine">
                                        <img alt="" src="assets/img/newstaticicons/inventory/cardhold.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.payee_name}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 ten">
                                        <img alt="" src="assets/img/newstaticicons/inventory/cardtype.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest_card_type}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 eleven">
                                        <img alt="" src="assets/img/newstaticicons/inventory/cardnumber.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest_card}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 twelve">
                                        <img alt="" src="assets/img/newstaticicons/inventory/channel.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.channel.name}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 thirteen">
                                        <img alt="" src="assets/img/newstaticicons/inventory/promo-code-small.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Promo" value="{{b_detail?.promo?.promo?.promo_code}}" readonly>
                                    </div>
                                    <!-- <div class="modal-bar-2 eleven">
                         <img alt="" src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/discount.svg':'/discount.svg'}}" style="height: 20px;" class="placeholder-img-modal" >
                         <input type="text" class="form-control"
                         style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px" placeholder="Discount" value="{{b_detail?.discount}}" readonly>
                      </div> -->
                                    <div class="modal-bar-2 fourteen">
                                        <img alt="" src="assets/img/newstaticicons/inventory/idtype.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Payment Status" value="{{b_detail?.payment_status}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 fifteen">
                                        <img alt="" src="assets/img/newstaticicons/inventory/idtype.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Booking Status" value="{{b_detail?.status}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 sixteen">
                                        <img alt="" src="assets/img/newstaticicons/arrivals/coins.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Currency" value="{{b_detail?.currency}}" readonly>
                                    </div>
                                    <!-- <div class="modal-bar-2 twelve">
                         <img alt="" src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/dollar.svg':'/dollar.svg'}}" style="height: 20px;" class="placeholder-img-modal" >
                         <input type="text" class="form-control"
                         style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px" placeholder="Total Nights" value="{{b_detail?.total}}" readonly>
                      </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 zero-padding float-L">
                            <div class="col-md-12 zero-padding" style="padding-right: 2px;padding-left: 3px"
                                style="padding-right: 2px;">
                                <div class="bluheader booking-sumary redbar" style="padding-right: 10px;">
                                    <h3 style="display: inline-block;" class="h3_blu_header bookingsummary">Room</h3>
                                </div>
                            </div>
                            <div class="col-md-12 zero-padding col-12-two oneeee" [ngStyle]="{'padding-right':'3px'}">
                                <div *ngFor="let guest of b_detail.bookingitems; let i = index" class="collapsableDiv ">
                                    <div>
                                        <label class="label-mr">
                                            {{guest?.room?.roomtype?.name}}
                                            <img src="{{darkMode ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg':'assets/img/reports/button_down.svg'}}"
                                                alt="dropdown" class="collapseimg"
                                                [ngClass]="i == 0 ? 'rotate collapseimg' : 'collapseimg'"
                                                data-toggle="collapse" id="drop{{i}}" [attr.data-target]="'#demo'+i"
                                                (click)="rotateDrop('drop'+i)">
                                        </label>
                                        <div [ngClass]="disabled ? 'disClick' : 'null'">
                                            <div id="demo{{i}}" class=""
                                                [ngClass]="i == 0 ? 'collapse in' : 'collapse'">
                                                <hr style="margin: 0px;margin-bottom: 5px;margin-top: 0px;">

                                                <div class="modal-bar-2 seventeen">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/guest-name-infinity0':'/forchk/full-name0'}}.svg"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights"
                                                        value="{{formatGuestName(guest?.guest_name)}}" readonly>
                                                </div>
                                                <div class="modal-bar-2 eighteen">
                                                    <img alt="" src="assets/img/dashboard/in-house2.svg"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights" value="{{guest?.status}}" readonly>
                                                </div>


                                                <div class="modal-bar-2 nineteen" *ngIf="b_detail.same_date == '0'">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'}}"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights"
                                                        value="{{guest?.check_in | date:'d MMM y'}}" readonly>
                                                </div>
                                                <div class="modal-bar-2 tweenty" *ngIf="b_detail.same_date == '0'">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg':'dashboard/departure.svg'}}"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights"
                                                        value="{{guest?.check_out | date:'d MMM y'}}" readonly>
                                                </div>

                                                <div class="gDetails"
                                                    *ngIf="guest.status == 'Checked In' || guest.status == 'Checked Out'">
                                                    <div class="modal-bar-2 tweenty1">
                                                        <img alt=""
                                                            src="assets/img/newstaticicons/inventory/cardnumber.svg"
                                                            style="height: 20px;" class="placeholder-img-modal">
                                                        <input type="text" class="form-control"
                                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                            placeholder="Guest Card Number"
                                                            value="**********{{guest.card_no.slice(guest.card_no.length - 4)}}"
                                                            readonly>
                                                    </div>
                                                    <div style="display: flex;flex-wrap: nowrap;">
                                                        <div class="modal-bar-2 tweenty2"
                                                            style="    margin-right: 5px;">
                                                            <img alt=""
                                                                src="assets/img/newstaticicons/inventory/cardnumber.svg"
                                                                style="height: 20px;" class="placeholder-img-modal">
                                                            <input type="text" class="form-control"
                                                                style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                                placeholder="Card Expiry" value="{{guest?.card_expiry}}"
                                                                readonly>
                                                        </div>
                                                        <div class="modal-bar-2 tweenty3">
                                                            <img alt=""
                                                                src="assets/img/newstaticicons/inventory/cardnumber.svg"
                                                                style="height: 20px;" class="placeholder-img-modal">
                                                            <input type="text" class="form-control"
                                                                style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                                placeholder="CVV" value="{{guest?.card_cvv}}" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="modal-bar-2 tweenty4">
                                                        <img alt="" src="assets/img/newstaticicons/inventory/idtype.svg"
                                                            style="height: 20px;" class="placeholder-img-modal">
                                                        <input type="text" class="form-control"
                                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                            placeholder="Guest Id Type" value="{{guest?.id_type}}"
                                                            readonly>
                                                    </div>
                                                    <div class="modal-bar-2 tweenty5">
                                                        <img alt=""
                                                            src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg"
                                                            style="height: 20px;" class="placeholder-img-modal">
                                                        <input type="text" class="form-control"
                                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                            placeholder="Guest Id Number" value="{{guest?.id_value}}"
                                                            readonly>
                                                    </div>
                                                    <div class="modal-bar-2 tweenty6">
                                                        <img alt=""
                                                            src="assets/img/newstaticicons/inventory/r-call-red.svg"
                                                            style="height: 20px;" class="placeholder-img-modal">
                                                        <input type="text" class="form-control"
                                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                            placeholder="Phone" value="{{guest?.phone_number}}"
                                                            readonly>
                                                    </div>
                                                    <div class="modal-bar-2 tweenty7">
                                                        <img alt=""
                                                            src="assets/img/newstaticicons/inventory/forchk/mail-infinity0.svg"
                                                            style="height: 20px;" class="placeholder-img-modal">
                                                        <input type="text" class="form-control"
                                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                            placeholder="Email" value="{{guest?.email}}" readonly>
                                                    </div>
                                                </div>

                                                <div class="modal-bar-2 tweenty8 filterAccec ">
                                                    <div class="pointer">
                                                        <img class="img-responsive hovimg"
                                                            src="assets/img/newstaticicons/back-office/{{guest.room.smoking == 'false' ? 'nosmoke' : 'smoke'}}.svg"
                                                            style="height:35px;margin-top:6px;">
                                                    </div>
                                                    <div class="pointer">
                                                        <img class="img-responsive hovimg"
                                                            src="assets/img/newstaticicons/back-office/{{guest.room.wheelchairaccessibility == 'false' ? 'nowheel' : 'wheel'}}.svg"
                                                            style="height:35px;margin-top:6px;">
                                                    </div>
                                                    <div class="pointer">
                                                        <img class="img-responsive hovimg"
                                                            src="assets/img/newstaticicons/back-office/{{guest.room.pets == 'false' ? 'nopets' : 'pets'}}.svg"
                                                            style="height:35px;margin-top:6px;">
                                                    </div>
                                                </div>
                                                <div class="modal-bar-2 tweenty9">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/room-id-infinity1dark':'forchk/room-id-infinity1'}}.svg"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights"
                                                        value="{{guest?.room?.roomtype?.name}}" readonly>
                                                </div>
                                                <div class="modal-bar-2 tweenty0">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/room_number.svg':'/bed2.svg'}}"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Total Nights"
                                                        value="{{guest?.roomnum?.room_number}}" readonly>
                                                </div>
                                                <div class="modal-bar-2 tweenty11">
                                                    <img alt=""
                                                        src="assets/img/newstaticicons/inventory/forchk/package.svg"
                                                        style="height: 20px;" class="placeholder-img-modal">
                                                    <input type="text" class="form-control"
                                                        style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                        placeholder="Package" value="{{guest?.package?.package_name}}"
                                                        readonly>
                                                </div>
                                                <div class="row" style="padding: 0px 20px;">
                                                    <div class="col-md-4 zero-padding" style="padding-right: 2px">
                                                        <div class="modal-bar-2 tweenty12">
                                                            <img alt=""
                                                                src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/adult-dark.svg' : '/forchk/adult0.svg'}}"
                                                                style="height: 20px;" class="placeholder-img-modal">
                                                            <input type="text" class="form-control"
                                                                style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                                placeholder="Total Nights"
                                                                value="{{guest?.no_of_adults}}" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 zero-padding" style="padding-left: 3px">
                                                        <div class="modal-bar-2 tweenty13">
                                                            <img alt=""
                                                                src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/child-infinity0':'/child'}}.svg"
                                                                style="height: 20px;" class="placeholder-img-modal">
                                                            <input type="text" class="form-control"
                                                                style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                                placeholder="Total Nights"
                                                                value="{{guest?.no_of_childs}}" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 zero-padding" style="padding-left: 4px;">
                                                        <div class="modal-bar-2 tweenty14">
                                                            <img alt=""
                                                                src="assets/img/newstaticicons/inventory/{{darkMode ? 'infant-yellow':'infant'}}.svg"
                                                                style="height: 20px;" class="placeholder-img-modal">
                                                            <input type="text" class="form-control"
                                                                style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                                                placeholder="Total Nights"
                                                                value="{{guest?.no_of_infants}}" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-bar-2 tweenty15" style="pointer-events: none;">
                                                    <label for="" class="pcom">
                                                        <input type="radio" id="test{{i}}" value="paid">
                                                        <label for="test{{i}}" id="paid{{i}}">Paid</label>
                                                    </label>
                                                    <label for="" class="pcom">
                                                        <input type="radio" id="test2{{i}}" value="complimentry">
                                                        <label for="test2{{i}}" id="comp{{i}}">Complimentry</label>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 zero-padding float-R">
                            <div class="col-md-12 zero-padding" style="padding-right: 0px;padding-left: 3px">

                                <div class="bluheader booking-sumary redbar">
                                    <h3 class="h3_blu_header bookingsummary">Guest</h3>
                                </div>
                            </div>
                            <div class="col-md-12 zero-padding col-12-three onee"
                                style="padding-left: 2px;padding-right: 0px;height: 285px;">
                                <div class="greyBox">
                                    <div class="modal-bar-2 tweenty15">
                                        <img alt=""
                                            src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest?.first_name}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty16">
                                        <img alt="" src="assets/img/newstaticicons/inventory/r-call-red.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail.guest.phone_number}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty17">
                                        <img alt=""
                                            src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest?.email}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty18">
                                        <img alt=""
                                            src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest_address}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty19">
                                        <img alt=""
                                            src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.guest_city}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty20">
                                        <img alt="" src="assets/img/newstaticicons/inventory/idtype.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.personal_id_type}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty21">
                                        <img alt="" src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.personal_id_value}}" readonly>
                                    </div>
                                    <div class="modal-bar-2 tweenty22">
                                        <img alt="" src="assets/img/newstaticicons/inventory/pervilige.svg"
                                            style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                                            placeholder="Total Nights" value="{{b_detail?.privilege}}" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sideDiv" id="sideDiv" style="right: 0px;" (click)="sideTray('sideDiv','caret')"
                        [ngClass]="darkMode ? 'sidedark' : ''">
                        <div id="sideTray">
                            <div class="uperSide">
                                <div [ngClass]="darkMode ? 'darkOn' : 'darkOff'"
                                    *ngFor="let perday of b_detail.bookingitems">
                                    <div class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 5px;">
                                        <h3 class="h3_blu_header bookingsummary">{{cnvertJsonName(perday).roomName}}
                                        </h3>
                                    </div>
                                    <div class="modal-bar-2 tweenty23 mt-5 dFlex rateDive"
                                        *ngFor="let rates of cnvertJsonName(perday).rates">
                                        <div>{{rates.date | date:'EEE dd MMM'}}</div>
                                        <div [ngClass]="darkMode ? 'darkDD' : 'null'">{{ b_detail.currency +
                                            rates.price}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="totalDiscount" [ngClass]="darkMode ? 'DarkDic' : 'null'">
                                <div>
                                    <div class="modal-bar-2 tweenty24" style="  height: auto; padding: 0px;"
                                        [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                                        <textarea name="" id="" cols="30" rows="5" placeholder=" Special requests"
                                            value="{{b_detail?.SpecialRequests}}" readonly></textarea>
                                    </div>


                                    <div class="modal-bar-2 tweenty26">
                                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/addpersonicon1.svg':'/addpersonicon2.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control" value="{{b_detail?.extra_person_fee}}"
                                            placeholder="Extra Person Fee"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            id="extra_person_fee" readonly>
                                    </div>

                                    <div class="modal-bar-2 tweenty26">
                                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/subtotal.svg':'/subtotal.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            value="{{sub_total(b_detail.prices,b_detail.extra_person_fee)}}"
                                            placeholder="Sub Total"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            id="discountPromo" readonly>
                                    </div>

                                    <div class="modal-bar-2 tweenty25 dFlex segmo dynamoColor">
                                        <label [ngClass]="b_detail.TaxInclusive == 'true' ? 'active' : ''">Tax
                                            Inclusive</label><label
                                            [ngClass]="b_detail.TaxInclusive == 'false' ? 'active' : ''">Tax
                                            Exclusive</label>
                                        <div class="segmon"
                                            [ngStyle]="{'left': b_detail.TaxInclusive == 'true' ? '3%' : '53%'}"></div>
                                    </div>
                                    <div class="modal-bar-2 tweenty26">
                                        <img src="assets/img/newstaticicons/inventory{{darkMode?'/discount-light.svg':'/discount-light.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control" value="{{b_detail?.discount}}"
                                            placeholder="Discount"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            id="discountPromo" readonly>
                                    </div>


                                    <div class="modal-bar-2 tweenty26">
                                        <img src="assets/img/newstaticicons/inventory{{darkMode ? 'amount-deposit.svg':'/amount-deposit.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control" value="{{b_detail?.cashDeposit}}"
                                            placeholder="Cash deposit"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            id="cashDeposit" readonly>
                                    </div>

                                    <div class="modal-bar-2 tweenty26">
                                        <img src="assets/img/newstaticicons/arrivals{{darkMode ? '/taxicon.svg':'/taxicon.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control"
                                            value="{{tax_per(b_detail.prices,b_detail.extra_person_fee,b_detail.Taxper)}}"
                                            placeholder="Discount"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            id="discountPromo" readonly>

                                        <span style="font-weight: 700;"> {{b_detail.Taxper}}</span>
                                        <span style="font-size: 10px; padding: 2px;">
                                            <i class="fa fa-percent"></i>
                                        </span>

                                    </div>
                                    <div class="modal-bar-2 tweenty27  mb-0">
                                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/dollar-light.svg':'/dollar-light.svg'}}"
                                            alt="" style="height: 20px;" class="placeholder-img-modal">
                                        <input type="text" class="form-control" value="{{b_detail?.total}}"
                                            placeholder="Total Amount"
                                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                            readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="sideBtn" [ngClass]="darkMode ? 'sidedark' : ''">
                            <i class="fa fa-caret-right" id="caret"></i>
                        </div>
                    </div>

                </div>
                <div class="modal-footer my-foot" style="padding-bottom: 5px !important">
                </div>
                <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
                    <div class="popupBtns">
                        <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- confirmation id  -->

<div class="modal" id="myModal-4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    [ngClass]="darkMode ? 'darkOn' : 'darkOff' " [ngStyle]="{ display: displayStyle }">
    <div class="modal-dialog  modal-sm">
        <div class="modal-content" style="background-color: #f3f3f3; border-radius: 15px !important;"
            [ngClass]="darkMode ? 'dark-border-div dar-modal-body modal-content-dark' : 'null'">
            <div class="modal-header" style="padding: 0; border: 0px;">
                <div class="bluheader booking-sumary" id="grab-4" style="cursor: grab;" cdkDrag>
                    <h3 class="h3_blu_header bookingsummary">Booking ID
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)=" closePopup()">
                            <strong id="strong">&#x2716;</strong>
                        </button>
                    </h3>
                </div>
            </div>

            <div class="modal-body">
                <div class="main-box2">
                    <!-- Stays Box -->
                    <div class="data-box2">
                        <div class="red-bar"
                            style="text-align: center; background: linear-gradient(rgb(115, 64, 206), rgb(80, 28, 173), rgb(91, 52, 160))!important;">
                            {{ res_pop?.channel.name}}
                        </div>
                        <div [ngClass]="darkMode ? 'datas' : 'datas-light'">
                            <ng-container *ngIf="res_pop?.confirmation_number">
                                <div class="red-bar" style="text-align:center;">Confirmation ID</div>
                                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal two0'">
                                    <div style="text-align: center;">
                                        {{ res_pop?.confirmation_number }}
                                    </div>
                                </div>
                            </ng-container>
                            <div class="red-bar" style="text-align: center;">Reservation ID</div>
                            <div [ngClass]="darkMode ? 'border-orange' : 'border-normal three0'">
                                <div style="text-align: center;">
                                    {{ res_pop?.reservation_id }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- download excel sheet file template -->
<div style="height: 1px;overflow: hidden;">
    <table id="excel-table" class="table table-bordered excel-table">
        <thead class="thead-dark">
            <tr class="table-primary">
                <th>Booked By</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>City</th>
                <th>Country</th>
                <th>Nationality</th>
                <th>Gender</th>
                <th>Issue Date</th>
                <th>Expiry Date</th>
                <th>Id Type</th>
                <th>Id Number</th>
                <th>Check-In Date</th>
                <th>Check-Out Date</th>
                <th>LOS</th>
                <th>Room Type</th>
                <th>Room No.</th>
                <th>Channel</th>
                <th>Extra Person</th>
                <th>Discount</th>
                <th>Amount</th>
            </tr>
        </thead>
    </table>
</div>
