<!-- <div  class="notification" style="margin-left: 5px">
    <div  class="row">
        <div  class="col-md-12">
            <div  class="greetings" style="font-weight: 600;">
                You've got 3 notifications and 2 keynotes, everything else looks smooth
            <button aria-label="Close" class="close" data-dismiss="modal" type="button" style="float: right;color: #fff"><strong id="cross">&#x2716;</strong></button></div>
        </div>
    </div>
</div> -->

<div class="alert alert-danger breadcrum" style="margin-left: 5px" [ngClass]="darkMode ? 'dark_bredcrum':'breadcrum'">
    <a class="btn btn-link" data-dismiss="modal">BACK OFFICE</a>
    <a class="btn btn-link active">SELECT PROPERTY</a>
</div>

<div class="clearfix"></div>

<div class="scroll no_scroll">

    <div class="card m-b-0" *ngFor="let property of properties; let i = index" style="margin-left: 5px"
        [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-4 pr-12" style="width: 49%;">
                <!-- <img class="property_img" *ngIf="property?.images.length == 0" src="assets/img/select_hotel/img1.png">
            <img class="property_img" *ngIf="property?.images.length > 0" src="{{ base_url2 + 'app/images/' + property?.images[0].image }}"> -->

                <div *ngIf="property.images.length > 0" id="myCarousel{{i}}" class="carousel slide"
                    data-ride="carousel">


                    <!-- Wrapper for slides -->
                    <div class="carousel-inner">

                        <div class="item " *ngFor="let img of property.images;let imgIndex = index"
                            [ngClass]="imgIndex == 0 ? 'active' : 'null'">
                            <img class="property_img" src="{{ base_url2 + 'app/images/' + img.image }}">
                        </div>

                    </div>

                    <!-- Left and right controls -->
                    <a class="left carousel-control adjus1" data-slide="prev" href="#myCarousel{{i}}"
                        *ngIf="property?.images.length > 1">
                        <i class="fas fa-chevron-left ico" style="padding:6px;"></i>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control adjus" data-slide="next" href="#myCarousel{{i}}"
                        *ngIf="property?.images.length > 1">
                        <i class="fas fa-chevron-right ico" style="padding:9px;"></i>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
                <div *ngIf="property.images.length == 0 ">
                    <img class="property_img" src="assets/img/select_hotel/img1.png" style="border-radius: 10px; ">
                </div>

            </div>
            <div class="col-md-4" style="width: 49%;padding-left: 5px;">
                <div class="imageK">
                    <img src="https://maps.googleapis.com/maps/api/staticmap?center=24.81{{i}}{{i}},67.03{{i}}{{i}}&zoom=18&size=600x300&maptype=roadmap &markers=color:red%7Clabel:S%7C24.81{{i}}{{i}},67.03{{i}}{{i}}&key=AIzaSyBtGPJeKV8bZQuM73Yr97Q_FNKBqEnkDJ4"
                        alt="" class="property_img hovin">
                    <div class="barAdd">
                        <input type="text" disabled value="{{property.address_one}}">
                    </div>
                    <div class="barAdd1">
                        <button class="btn btn-primary Hovbtn" (click)="switchproperty(property?.id)"
                            [disabled]="current_property == property.id">{{property.name}}</button>
                    </div>
                </div>
                <!-- <div  class="red-notification alert-danger">
                <div  class="greetings red_heading">{{ property?.name }} <span> {{ current_property == property.id ? "(You're currently managing this property)" : "" }}</span></div>
            </div>

            <div class="row">
                <div class="col-md-9 mt-10">
                    <p class="border-style row_cols address-shade"> <span class="p_heading">Address</span>{{ property?.address_one }} <span *ngIf="address_two !== null">{{ property?.address_two }}</span> </p>
                    <p class="border-style row_cols email-shade"> <span class="p_heading">Email</span>{{ property?.email }}</p>
                    <p class="border-style row_cols website-shade" style="color:rgb(190, 127, 47);"><span class="p_heading">Website</span> {{ property?.website }}</p>
                    <p class="border-style row_cols users-shade"> <span class="p_heading">Users</span> {{ property?.users_count }}</p>
                    <p class="border-style row_cols total-rooms-shade"><span class="p_heading">Total Rooms</span> {{ property?.room_count }}</p>
                    <div class="border-style row_cols active-channels-shade mb-0 p-0"><div class="active_heading pl-pt">Active Channels</div>
                        <div class="display-flow">
                            <div class="border-left">
                                <img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/caa_small.png"><img src="assets/img/manage_rates/hotelbeds_small.png"><img src="assets/img/manage_rates/booking_small.png"><img src="assets/img/manage_rates/ctrip_small.png"><img src="assets/img/manage_rates/tripadvisor_small.png"><img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/expedia_small.png"><img src="assets/img/manage_rates/facebook_small.png"><img src="assets/img/manage_rates/hotwire_small.png"><img src="assets/img/manage_rates/goibibo_small.png"><img src="assets/img/manage_rates/traveloka_small.png"><img src="assets/img/manage_rates/facebook_small.png"><img src="assets/img/manage_rates/hotwire_small.png">
                            </div>
                        </div>

                    </div>
                </div>
            </div> -->
            </div>
            <!-- <div class="col-md-4 btn-col align-centre" style="width: 32.6%; padding-left: 5px; padding-right: 5px;">
            <div class="cardiolight" [ngClass]="darkMode ? 'card_inner_dark' : 'cardiolight'">

                    <button  class="btn btn-primary Hovbtn" (click)="switchproperty(property?.id)" [disabled]="current_property == property.id">{{property.name}}</button>

                    <img src="assets/img/newstaticicons/back-office/amenity-01.svg" alt="amenity icon" height="30px" style="cursor: pointer;" (click)="amenityPro(property.with_parent_amenities)">
                    <img src="assets/img/newstaticicons/back-office/await.svg" alt="details icon" height="30px" style="margin-left: 5px;cursor: pointer;" (click)="DetPro(property)">

            </div>
        </div>  -->
        </div>
    </div>

    <div id="amenityModal" class="modalForm" *ngIf="showAminModal == true"
        [ngClass]="darkMode ? 'dark_popup' : 'null' ">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-56"
            (mouseover)="dodrag('amenityModal')" (mouseout)="stopDrag('amenityModal')" (mouseup)="grab('grab-56')"
            (mousedown)="grabbing('grab-56')" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
            Amenities
            <button type="button" class="closeBtnAm" id="cross" (click)="showAminModal = false">
                <strong>✖</strong>
            </button>
        </div>
        <div class="cardBox " [ngClass]="darkMode ? 'dark_popup' : 'null' ">
            <div class="row" style=" padding: 0px 10px 0px 15px;">
                <div class="col-md-6 zero-padding">
                    <div class="col-md-12 AmiCard-C4 ng-star-inserted">
                        <ng-container *ngFor="let ami of amenityData;let pi = index">
                            <div class="cardAmenity" *ngIf="pi%2 == 0" [ngClass]="darkMode ? 'card_dark' : 'null'">
                                <div class="row">
                                    <div class="col-md-12 pr20">
                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12 amenityColumn12">
                                                    <div class="icoAmeni">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/hotel-setup/Darkmode/{{ami.icon.split('.')[0]}}.svg"
                                                            *ngIf="darkMode == false ">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/hotel-setup/Darkmode/darkmode/icons/{{ami.icon.split('.')[0]}}.png"
                                                            *ngIf="darkMode == true ">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <label class="aminityTitle"
                                                            [ngStyle]="{'color': darkMode ? amenCol[pi] : '#00205b'}">
                                                            {{ami.title}}
                                                        </label>
                                                        <a data-toggle="collapse" href="#collapse{{pi}}">
                                                            <img alt="" class="drop"
                                                                src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown1">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{pi}}">
                                        <p *ngFor="let ch of ami.children;let chI = index">{{ch.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-6 zero-padding">
                    <div class="col-md-12 AmiCard-C4 ng-star-inserted">
                        <ng-container *ngFor="let ami of amenityData;let pi = index">
                            <div class="cardAmenity" *ngIf="pi%2 != 0" [ngClass]="darkMode ? 'card_dark' : 'null'">
                                <div class="row">
                                    <div class="col-md-12 pr20">
                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12 amenityColumn12">
                                                    <div class="icoAmeni">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/hotel-setup/Darkmode/{{ami.icon.split('.')[0]}}.svg"
                                                            *ngIf="darkMode == false ">
                                                        <img alt="ameniti image" class="amiIcon"
                                                            src="assets/img/hotel-setup/Darkmode/darkmode/icons/{{ami.icon.split('.')[0]}}.png"
                                                            *ngIf="darkMode == true ">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <label class="aminityTitle"
                                                            [ngStyle]="{'color': darkMode ? amenColot[pi] : '#00205b'}">
                                                            {{ami.title}}
                                                        </label>
                                                        <a data-toggle="collapse" href="#collapse{{pi}}">
                                                            <img alt="" class="drop"
                                                                src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown1">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 AmicChild text panel-collapse collapse " id="collapse{{pi}}">
                                        <p *ngFor="let ch of ami.children;let chI = index">{{ch.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="DetailModal" class="modalForm" *ngIf="showDetaModal == true">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-56"
            (mouseover)="dodrag('DetailModal')" (mouseout)="stopDrag('DetailModal')">
            Details
            <button type="button" class="closeBtnAm" id="cross" (click)="showDetaModal = false">
                <strong>✖</strong>
            </button>
        </div>
        <div class="cardBox ">
            <div class="row" style=" padding: 0px 20px;padding-left: 15px;">
                <div class="col-md-12 zero-padding">
                    <div class="bar1">
                        <label class="labelPro">name</label>
                        <label class="labeldata">{{detaildata.name}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">phone</label>
                        <label class="labeldata">{{detaildata.phone}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">email</label>
                        <label class="labeldata">{{detaildata.email}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">currency</label>
                        <label class="labeldata">{{detaildata.currency}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">country</label>
                        <label class="labeldata">{{detaildata.country}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">city</label>
                        <label class="labeldata">{{detaildata.city}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">state</label>
                        <label class="labeldata">{{detaildata.state}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">postal code</label>
                        <label class="labeldata">{{detaildata.postal_code}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">min rate</label>
                        <label class="labeldata">{{detaildata.min_rate}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">timezone</label>
                        <label class="labeldata">{{detaildata.timezone}}</label>
                    </div>
                    <div class="bar1">
                        <label class="labelPro">room_count</label>
                        <label class="labeldata">{{detaildata.room_count}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="card mb-0">
        <div class="row" >
            <div class="col-md-3">
                <img class="property_img" src="assets/img/select_hotel/img1.png">
            </div>
            <div class="col-md-9 pl-0">

                <div  class="red-notification alert-danger">
                    <div  class="greetings red_heading">Lesisurex Resort <span>You're currently managing this property</span></div>
                </div>

                <div class="row">
                    <div class="col-md-9 mt-10">
                        <p class="border-style row_cols address-shade"> <span class="p_heading">Address</span> Lorem ipsum dolor sit amet</p>
                        <p class="border-style row_cols email-shade"> <span class="p_heading">Email</span>Lorem ipsum dolor sit amet</p>
                        <p class="border-style row_cols website-shade"><span class="p_heading">Website</span> Lorem ipsum dolor sit amet</p>
                        <p class="border-style row_cols users-shade"> <span class="p_heading">Users</span>Lorem ipsum dolor sit amet</p>
                        <p class="border-style row_cols total-rooms-shade"><span class="p_heading">Total Rooms</span> Lorem ipsum dolor sit amet</p>
                        <p class="border-style row_cols active-channels-shade mb-0 p-0"><span class="active_heading pl-pt">Active Channels</span>
                            <span class="display-flow">
                                <img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/caa_small.png"><img src="assets/img/manage_rates/hotelbeds_small.png"><img src="assets/img/manage_rates/booking_small.png"><img src="assets/img/manage_rates/ctrip_small.png"><img src="assets/img/manage_rates/tripadvisor_small.png"><img src="assets/img/manage_rates/priceline_small.png"><img src="assets/img/manage_rates/expedia_small.png">
                            </span>

                        </p>
                    </div>
                    <div class="col-md-3 btn-col">
                            <button  class="btn btn-primary" (click)="switchproperty()">Select this Property</button>
                            <button  class="btn btn-danger btn-delete" (click)="delete()" data-toggle="modal" data-target="#confirmationModal">Delete</button>
                            <p class="text-danger mb-0">To delete this property please contact our support team.</p>
                    </div>
                </div>

            </div>

        </div>
    </div> -->

<div class="modal-footer overridee">
    <a class="btn btn-primary btn-lg hov" [routerLink]="['/app/back_office/hotel_setup']"
        [queryParams]="{add_new: true}" [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">+ Add Property</a>
    <a class="btn btn-danger btn-lg hov" data-dismiss="modal" aria-label="Close"
        [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Close</a>
</div>