<!-- Food and Beverages (create-menu-Page) work start -->

<div class="main-div">
  <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
    <div class="row d-flex">
      <div class="column-wrapper d-flex col-md-9">
        <ul class="nav navbar-nav tabsBar" id="tabsBarCreateMenu">
          <li *ngFor="let shop of shopNames">
            <a class="btn btn-link" (click)="switchShop(shop.shop_id)"
              [ngClass]="{ 'active': shop.shop_id === activeShopId }">{{shop?.shop_name}}</a>
          </li>
        </ul>
      </div>
      <div class="btn-group" role="group" aria-label="dropdown">
        <button class="btn btnCategory1" (click)="select_product()" [ngClass]="active ? 'btnActive' : 'null'">+
          create</button>
        <button class="btn btnCategory2" (click)="create_product()" *ngIf="btn1">+ product
          <div class="borderBottom"></div>
        </button>
        <button class="btn btnCategory3" (click)="create_deal()" *ngIf="btn2">+ Deal</button>
      </div>

    </div>
  </div>
  <div class="col-md-12 blueHedaer tabBarBlue" [ngClass]="darkMode ? 'dark_blueBar' : ''">
    <div class="row">
      <ul class="nav nav-tabs nav-tabs-neutral justify-content-center" role="tablist" data-background-color="orange">
        <li class="nav-item active">
          <a class="nav-link active" data-toggle="tab" href="#product" role="tab">Product</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#deal" role="tab">Deal</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="col-md-12 blueHedaer" [ngClass]="darkMode ? 'dark_blueBar' : ''">
    <div class="row">
      <div class="category">Image</div>
      <div class="category">Category</div>
      <div class="category">Item</div>
      <div class="category">Cost Price</div>
      <div class="category">Selling Price</div>
      <div class="action">Action</div>
    </div>
  </div>
  <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">
    <div class="tab-content">
      <div class="tab-pane active" id="product" role="tabpanel">
        <ng-container *ngFor="let menuItem of createMenuItems;index as i">
          <div class="image" *ngIf="selectedImage">
            <i class="fa fa-times" aria-hidden="true" (click)="selectedImage = ''"></i>
            <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/' + selectedImage" [alt]="selectedImage">
          </div>
          <div class="row">
            <div class="col-md-12 inputCat">
              <div class="col-md-12 first d-flex">
                <div class="show-img" (click)="selectedImage = menuItem.image">
                  <img class="" [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+menuItem.image" alt="images" class="btn">
                </div>
                <div class="modal-bar" [ngStyle]="{'border-color' : randomColor[i%randomColor.length]}">
                  <div class="row d-flex" style="width: 100%;">
                    <div class="col-md-9 addItemCat">
                      <div class="form-control borderRight">{{ menuItem.category.category_name }}
                      </div>
                      <div class="form-control borderRight">{{ menuItem.item.item_name }}</div>
                      <div class="form-control borderRight">{{ currency_format }}
                        {{menuItem.cost_price |
                        number:'1.2-3'}}
                      </div>
                      <div class="form-control borderRight">
                        {{ currency_format }} {{ menuItem.selling_price | number:'1.2-3' }}
                      </div>
                    </div>
                    <div class="col-md-3 actions">
                      <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit" class="edit"
                        data-toggle="tooltip" data-placement="bottom" title="Edit" (click)="editProductMenu(i)">
                      <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="edit"
                        data-toggle="tooltip" data-placement="bottom" title="Delete" data-toggle="modal"
                        data-target="#confirmationModal-3" (click)="deleteProductMenu(i)">
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-container>
      </div>
      <div class="tab-pane" id="deal" role="tabpanel">
        <ng-container *ngFor="let dealItem of dealItems;index as d">
          <div class="image" *ngIf="selectedImage">
            <i class="fa fa-times" aria-hidden="true" (click)="selectedImage = ''"></i>
            <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/' + selectedImage" [alt]="selectedImage">
          </div>
          <div class="row">
            <div class="col-md-12 inputCat">
              <div class="col-md-12 first d-flex">
                <div class="show-img" (click)="selectedImage = dealItem.image">
                  <img class="" [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+dealItem.image" alt="images" class="btn">
                </div>
                <div class="modal-bar" [ngStyle]="{'border-color' : randomColor[d%randomColor.length]}">
                  <div class="row d-flex" style="width: 100%;">
                    <div class="col-md-9 addItemCat">
                      <div class="form-control borderRight">{{ dealItem.deal_name }}
                      </div>
                      <div class="form-control borderRight">
                        <div class="d-flex align-items-center justify-content-center">
                          <div class="deal-item-name">
                            {{ dealItem.deal_items[0].item.item_name }}
                          </div>
                          <!---start tooltip--->
                          <div class="ispan1">
                            <img aria-expanded="false" aria-haspopup="true" class="btn-hov"
                              src="assets/img/manage_rates/dots.png">
                            <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                              <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                {{ getTooltipText(dealItem) }}
                              </p>
                            </span>
                          </div>
                          <!---end tooltip--->
                        </div>
                      </div>
                      <div class="form-control borderRight">{{ currency_format }}
                        {{dealItem.deal_cost_price | number:'1.2-3'}}
                      </div>
                      <div class="form-control borderRight">
                        {{ currency_format }} {{ dealItem.deal_selling_price | number:'1.2-3' }}
                      </div>
                    </div>
                    <div class="col-md-3 actions">
                      <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit" class="edit"
                        data-toggle="tooltip" data-placement="bottom" title="Edit" (click)="editDealMenu(d)">
                      <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="edit"
                        data-toggle="tooltip" data-placement="bottom" title="Delete" data-toggle="modal"
                        data-target="#confirmationModal-3" (click)="deleteDealMenu(d)">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>


<!-- Modal Product -->
<div class="modal fade" id="productModal" *ngIf="productModalShow" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" cdkDrag>
  <div class="modal-dialog">
    <div class="modal-content" [ngClass]="darkMode ? 'card_dark_grey' : ''">
      <div class="redHedaer" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
        <h5 class="modal-title" id="exampleModalLabel">Add New Product</h5>
        <button type="button" class="close" data-dismiss="modal" aria-labelledby="" (click)="onModalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form action="">
        <div class="modal-body">
          <div class="row">
            <div class="left col-md-4">
              <div class="modal-bar border-5">
                <ng-select class="form-control" placeholder="Select Category" name="category"
                  [(ngModel)]="category_name" (change)="onCategoryChange()">
                  <ng-option *ngFor="let category of categories"
                    [value]="category.category_id">{{category.category_name}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="priceLeft col-md-4">
              <div class="modal-bar border-3">
                <input class="form-control text-left" placeholder="Cost Price" type="text" name="cost_price"
                  [(ngModel)]="cost_price">
              </div>
            </div>
            <div class="right col-md-4">
              <div class="modal-bar border-7">
                <input class="form-control text-left" placeholder="Selling Price" type="text" name="selling_price"
                  [(ngModel)]="selling_price">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="left col-md-7">
              <div class="modal-bar border-6">
                <ng-select class="form-control" placeholder="Item name" name="items" [(ngModel)]="item_name">
                  <ng-option *ngFor="let item of filteredItems" [value]="item.item_id">{{item.item_name}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="right col-md-5">
              <div class="modal-bar border-2">
                <div class="input-group">
                  <div class="custom-file">
                    <div class="image-add">
                      <span class="input-value">{{ file_name || 'Select File' }}</span>
                    </div>
                    <input type="file" class="custom-file-input" #fileInput name="file" (change)="handleFileSelect()">
                    <label class="custom-file-label" for="inputGroupFile">
                      <button class="btn uploadBtn" (click)="fileInput.click()">
                        <img src="assets/img/back-office/upload_img_icon.svg" alt="upload">
                      </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="onSaveCategory()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal Deal -->
<div class="modal fade" id="dealModal" *ngIf="dealModalShow" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" cdkDrag>
  <div class="modal-dialog">
    <div class="modal-content" [ngClass]="darkMode ? 'card_dark_grey' : ''">
      <div class="redHedaer" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
        <h5 class="modal-title" id="exampleModalLabel">Add New Deals</h5>
        <button type="button" class="close" data-dismiss="modal" aria-labelledby="" (click)="onModalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form action="">
        <div class="modal-body">
          <div class="row">
            <div class="left col-md-4">
              <div class="modal-bar border-5">
                <input class="form-control text-left" placeholder="Deal name" type="text" [(ngModel)]="deal_name"
                  name="deal_name">
              </div>
            </div>
            <div class="priceLeft col-md-4">
              <div class="modal-bar border-3">
                <input class="form-control text-left" placeholder="Cost Price" type="text" [(ngModel)]="deal_cost_price"
                  name="deal_cost_price">
              </div>
            </div>
            <div class="right col-md-4">
              <div class="modal-bar border-7">
                <input class="form-control text-left" placeholder="Selling Price" type="text"
                  [(ngModel)]="deal_selling_price" name="deal_selling_price">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="left col-md-7">
              <div class="modal-bar border-6">
                <ng-select class="form-control" placeholder="Item name" name="items" [(ngModel)]="deal_item_name"
                  [multiple]="true">
                  <ng-option *ngFor="let item of items" [value]="item.item_id">{{item.item_name}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="right col-md-5">
              <div class="modal-bar border-2">
                <div class="input-group">
                  <div class="custom-file">
                    <div class="image-add">
                      <span class="input-value">{{ file_name || 'Select File' }}</span>
                    </div>
                    <input type="file" class="custom-file-input" #fileInput name="file" (change)="handleFileSelect()">
                    <label class="custom-file-label" for="inputGroupFile">
                      <button class="btn uploadBtn" (click)="fileInput.click()">
                        <img src="assets/img/back-office/upload_img_icon.svg" alt="upload">
                      </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="onSaveDeal()">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>