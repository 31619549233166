<div class="row no-gutters">
  <div id="drag_box" class="col-md-12">
    <div class="col-md-4 drag_item">
      <a class="block-btn modal-btn" routerLink="invoice">
        <div class="jumbotron blue-shade">
          <div class="jumbo-icon">
            <img src="assets/img/billing/list.png" class="img-responsive hov">
            <div class="jumbo-text">
              <p>Invoices</p>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-4 drag_item">
      <a class="block-btn modal-btn" routerLink="manage_how_you_pay">
        <div class="jumbotron purple-shade">
          <div class="jumbo-icon">
            <img src="assets/img/billing/money.png" class="img-responsive hov">
            <div class="jumbo-text">
              <p>Manage How You Pay</p>
            </div>
          </div>

        </div>
      </a>
    </div>
    <div class="col-md-4 drag_item">
      <a class="block-btn" routerLink="subscription">
        <div class="jumbotron maroon-shade">
          <div class="jumbo-icon">
            <img src="assets/img/billing/analytics.png" class="img-responsive hov">
            <div class="jumbo-text">
              <p>Subscription Plan</p>
            </div>
          </div>

        </div>
      </a>
    </div>
  </div>
</div>