import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
declare var $: any;

@Component({
  selector: "app-hotel-setup",
  templateUrl: "./hotel-setup.component.html",
  styleUrls: ["./hotel-setup.component.css"],
})
export class HotelSetupComponent implements OnInit {
  extras: any;

  popular_amenities;
  other_amenities;
  days;
  t4 = false;
  t12 = false;
  countries;
  housekeeping_time;
  country_md;
  country;
  states;
  state;
  cities;
  mainarray;
  city;
  timezones;
  currencies;
  setup_data;
  weekend_starts;
  enable: boolean = true;
  disable;
  timezone;
  currency;
  hotel_setup_form: FormGroup;
  submitted = false;
  building_value = [];
  current_building = [];
  users;
  hotel_images = [];
  photo_list = [];
  plusHover: boolean = false;
  edit: boolean = false;
  hrs;

  amenCol = [
    "#7ae997",
    "#de888d",
    "#6c6ce7",
    "#da8639",
    "#f1c1f8",
    "#d4575a",
    "#e7d67c",
    "#4cdbbf",
    "#f1c1f8",
    "#81a2ff",
  ];

  amenColot = [
    "#5f5fc0",
    "#51f3d6",
    "#e59196",
    "#ce7219",
    "#daca78",
    "#ba585b",
    "#72df8b",
    "#eabdf5",
    "#e59196",
  ];
  base_url;
  base_url2;
  darkMode: boolean = false;
  Upsellname;
  UpsellPrice;
  Extrasname;
  Extrasprice;
  UpsellPriceYes: boolean = false;
  randomColors = [];

  // serice addons
  servicesName: string;
  servicesTax: string;
  servicesPrice: string;
  servicePriceAfterTax: string;
  // food | mini bar addons
  Minisname: string;
  MiniPrice: string;
  MiniCostPrice: string;
  MiniTax: string;
  miniPriceAfterTax: string;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    private router: Router,
    private Aroute: ActivatedRoute,
    private confirm: ConfirmationAlertService
  ) {
    //this.building_value.push({name:'', floor:'', colorFloor: this.getRandomColor(), colorbuilding: this.getRandomColor()})
  }

  ngOnInit() {
    this.checkDarkMode();
    this.getproperty();
    this.get_popular_amenities();
    this.get_other_amenities();
    this.get_days();
    this.get_countries();
    this.get_timezones();
    this.get_currencies();
    this.base_url = this.api.base_url;
    this.base_url2 = this.api.base_url2;
    this.getRandomColors();
    this.clockFormat();
    // this.findInvalidControls();
    // setTimeout(() => {
    //   this.setchecked();
    // }, 2000);

    this.hotel_setup_form = this.fb.group({
      name: ["", Validators.required],
      phone: [""],
      email: [""],
      room_count: ["", Validators.required],
      address_one: ["", Validators.required],
      address_two: "",
      country_id: ["", Validators.required],
      state_id: ["", Validators.required],
      city_id: ["", Validators.required],
      postal_code: [""],
      weekend_starts: [""],
      timezone_id: ["", Validators.required],
      currency_id: [null, Validators.required],
      min_rate: [null, Validators.required],
      user_id: ["", Validators.required],
      taxper: ["", Validators.required],
      food_tax_percentage: ["", Validators.required],
      taxInclusive: ["", Validators.required],
      clockformat: [""],
      pricingpack: ["", Validators.required],
      taxID: "",
      building_section: "",
      most_amenties: "",
      add_most_amenite: "",
      add_other_amenite: "",
      add_building: null,
      buildings: [],
      images: "",
      currency_conversion: "Enabled",
      eta: null,
      housekeeping_time: "",
      etd: null,
      extras: this.fb.array([]),
      logo: null,
    });

    $(document).ready(function () {
      $("#plusB").hover(
        function () {
          $("#tooltip1").css("opacity", "1");
        },
        function () {
          $("#tooltip1").css("opacity", "0");
        }
      );
      $("#plusB").hover(
        function () {
          $("#boxt").css("opacity", "1");
        },
        function () {
          $("#boxt").css("opacity", "0");
        }
      );
      // $('#removeP').hover(function(){
      //   $('#tooltip2').css("opacity", "1");
      //   }, function(){
      //   $('#tooltip2').css("opacity", "0");
      // });
      // $('#removeP').hover(function(){
      //   $('#boxt2').css("opacity", "1");
      //   }, function(){
      //   $('#boxt2').css("opacity", "0");
      // });
    });
  }
  get f() {

    return this.hotel_setup_form.controls;
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  get_popular_amenities() {
    this.api
      .post(
        "api/amenitie/index",
        { order: "most_popular", type: "hotel", parent_id: "Null" },
        true
      )
      .subscribe(
        (res: any) => {
          this.popular_amenities = res.body.data;
          this.popular_amenities.forEach((amenity) => {
            amenity.show_add = false;
          });
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_other_amenities() {
    this.api
      .post(
        "api/amenitie/index",
        { order: "other", type: "hotel", parent_id: "Null" },
        true
      )
      .subscribe(
        (res: any) => {
          this.other_amenities = res.body.data;
          this.other_amenities.forEach((amenity) => {
            amenity.show_add = false;
          });
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_days() {
    this.api.get("api/helper/days", true).subscribe(
      (res: any) => {
        this.days = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  get_countries() {
    this.states = [];
    this.cities = [];
    this.api.get("api/helper/countries", true).subscribe(
      (res: any) => {
        this.countries = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }
  get_states($event) {
    this.cities = [];
    var id;
    if (typeof $event == "object") {
      id = $event.id;
    }
    this.api.get("api/helper/states/" + id, true).subscribe(
      (res: any) => {
        this.states = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }
  get_cities($event) {
    var state_id;
    if (typeof $event == "object") {
      state_id = $event.id;
    }
    this.api.get("api/helper/cities/" + state_id, true).subscribe(
      (res: any) => {
        this.cities = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  get_timezones() {
    this.api.get("api/helper/timezones", true).subscribe(
      (res: any) => {
        this.timezones = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  get_currencies() {
    this.api.get("api/helper/currency", true).subscribe(
      (res: any) => {
        this.currencies = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );

  }

  onSubmit() {

    this.findInvalidControls();
    this.submitted = true;
    this.hotel_setup_form.value.currency_conversion = "Enabled";
    let id = JSON.parse(localStorage.getItem("user")).id;
    let current_property = localStorage.getItem("current_property");
    this.hotel_setup_form.get("user_id").setValue(id);


    // Object.keys(this.hotel_setup_form.controls).forEach(key => {
    //   const controlErrors: ValidationErrors = this.hotel_setup_form.get(key).errors;
    //   if (controlErrors != null) {
    //         Object.keys(controlErrors).forEach(keyError => {
    //
    //         });
    //       }
    //     });
    // var qs = this.questions.filter(x => x.id===this.account_settings_form.value.question);
    // this.account_settings_form.patchValue({question: qs[0].question});
    // var country_id = this.countries.filter(x => x.full_name===this.hotel_setup_form.value.country_id);
    // var state_id = this.states.filter(x => x.full_name===this.hotel_setup_form.value.state_id);
    // var city_id = this.cities.filter(x => x.name===this.hotel_setup_form.value.city_id);
    var most_arr = [];
    if (!this.Aroute.snapshot.queryParams["add_new"]) {
      var most_amenties = document.querySelectorAll(
        "input[name='most_amenties[]'][type=checkbox]:checked"
      );
      Object.keys(most_amenties).forEach((key) => {
        most_arr.push({
          amenitie_id: most_amenties[key].value,
          property_id: current_property,
        });
      });
    }

    this.hotel_setup_form.patchValue({
      most_amenties: most_arr,
      images: this.hotel_images,
    });

    if (this.hotel_setup_form.invalid) {

      return;
    }
    localStorage.setItem("eta", this.hotel_setup_form.value.eta);
    localStorage.setItem("etd", this.hotel_setup_form.value.etd);
    localStorage.setItem(
      "housekeeping",
      this.hotel_setup_form.value.housekeeping_time
    );
    let a = [];
    this.building_value.map((val) => {
      a.push({ name: val.name, floors: parseInt(val.floor) });
    });
    this.hotel_setup_form.value.buildings = a;
    if (this.hotel_setup_form.value.building_section == "") {
      this.hotel_setup_form.value.building_section = "Enabled";
    }
    this.hotel_setup_form.value.currency_conversion = "Enabled";

    if (this.hotel_setup_form.invalid) {
      return this.helper.alert_error("Please fill all required fields");
    }

    this.hotel_setup_form.value.extras = this.hotel_setup_form.controls.extras.value.filter(x => x.name != "");

    console.log(this.hotel_setup_form.value, "submit form");

    this.api
      .post("api/property/store", this.hotel_setup_form.value, true)
      .subscribe(
        (res: any) => {
          localStorage.setItem("property", JSON.stringify(res.body.data));
          this.hotel_setup_form.value.extras.length != 0;
          if (this.Aroute.snapshot.queryParams["add_new"]) {
            var most_arr = [];
            var most_amenties = document.querySelectorAll(
              "input[name='most_amenties[]'][type=checkbox]:checked"
            );
            Object.keys(most_amenties).forEach((key) => {
              most_arr.push({
                amenitie_id: most_amenties[key].value,
                property_id: res.body.data.id,
              });
            });
            this.hotel_setup_form.patchValue({ most_amenties: most_arr });

            this.api
              .post("api/property/store", this.hotel_setup_form.value, true)
              .subscribe(
                (res: any) => {
                  localStorage.setItem("current_property", res.body.data.id);
                  localStorage.setItem(
                    "property",
                    JSON.stringify(res.body.data)
                  );
                  this.helper.alert_success("New Hotel has been setup!");
                },
                (err) => this.error_handling.handle_error(err.status)
              );
          }

          this.helper.alert_success("Changes has been saved !");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  setchecked() {
    if (this.setup_data.amenities == undefined) {
      return;
    }
    console.log(this.setup_data.amenities);
    var checked_data = this.setup_data.amenities;
    checked_data.forEach((element) => {
      try {
        (<HTMLInputElement>(
          document.getElementById("trash-" + element.amenitie_id)
        )).checked = true;
      } catch (error) { }
    });
  }
  uncheck(id) {
    var del = (<HTMLInputElement>document.getElementById(id)).value;
    try {
      this.api
        .get(
          "api/hotel_amenitie/destory/" + del + "/" + this.setup_data.id,
          true
        )
        .subscribe(
          (res: any) => { },
          (err) => this.error_handling.handle_error(err.status)
        );
    } catch (error) { }

    return ((<HTMLInputElement>document.getElementById(id)).checked = false);
  }
  getproperty() {
    let user = JSON.parse(localStorage.getItem("user"));
    let obj = {
      id: user.id,
      email: user.email,
      arr_room_empty: false,
    };
    if (!this.Aroute.snapshot.queryParams["add_new"]) {
      let current_property = localStorage.getItem("current_property");

      this.api.post("api/property/index", obj, true).subscribe(
        (res: any) => {
          res.body.data.map((val) => {
            if (val.id == current_property) {
              this.setup_data = val;
              //for showing amenities checked on first load
              setTimeout(() => {
                if (val.amenities == undefined) {
                  return;
                }

                var checked_data = val.amenities;
                checked_data.forEach((element) => {
                  try {
                    (<HTMLInputElement>(
                      document.getElementById("trash-" + element.amenitie_id)
                    )).checked = true;
                  } catch (error) { }
                });
              }, 2000);
            }
          });

          this.hotel_setup_form.patchValue(this.setup_data);
          this.country_md = this.setup_data.country_id;
          this.hrs = this.setup_data.clockformat;
          this.clockformating(this.setup_data.clockformat);
          this.country = this.setup_data.country;
          this.city = this.setup_data.city;
          this.state = this.setup_data.state;
          this.weekend_starts = this.setup_data.weekend_starts;
          this.timezone = this.setup_data.timezone;
          this.currency = this.setup_data.currency;
          this.housekeeping_time = this.setup_data.housekeeping_time;
          this.hotel_images = this.setup_data.images;
          localStorage.setItem("current_property", this.setup_data.id);
          // this.setchecked();
          this.edit = true;
          this.setup_data.buildings.map((val) => {
            this.current_building.push({
              name: val.name,
              floor: val.floors.length,
              colorFloor: this.getRandomColor(),
              colorbuilding: this.getRandomColor(),
              id: val.id,
            });
          });
          this.setup_data.extras.forEach((element) => {
            this.addNewExpense(
              this.hotel_setup_form.controls.extras,
              element.name,
              element.tax_percentage,
              element.purchase_cost,
              element.price,
              element.type,
              element.id
            );
          });

          if (this.setup_data.building_section == "Disabled") {
            this.enabled(false);
          } else if (this.setup_data.building_section == "") {
            this.enabled(true);
          } else {
            this.enabled(true);
          }
          // this.setchecked();
        },
        (err) => this.error_handling.handle_error(err.status)
      );
    }
  }
  add_amenitie(order, parent_id) {
    var type = "Hotel";
    var status = "Enabled";
    var title = "";
    if (order == "other") {
      title = this.hotel_setup_form.value.add_other_amenite;
    } else if (order == "most") {
      title = this.hotel_setup_form.value.add_most_amenite;
    }
    var data = {
      title: title,
      order: order,
      status: status,
      type: type,
      parent_id: parent_id,
      user_id: JSON.parse(localStorage.getItem("user")).id
    };
    this.api.post("api/amenitie/store", data, true).subscribe(
      (res: any) => {
        this.helper.alert_success("Changes has been saved !");
        setTimeout(() => {
          this.get_popular_amenities();
          this.get_other_amenities();
        }, 1000);
        setTimeout(() => {
          this.setchecked();
        }, 2000);
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  enabled(bool) {
    this.enable = bool;
  }

  bulding() {
    this.building_value.push({
      name: "",
      floor: "",
      colorFloor: this.getRandomColor(),
      colorbuilding: this.getRandomColor(),
    });
  }

  removeB(i) {
    this.building_value.splice(i, 1);
  }

  disabled1() {
    this.building_value.length = 1;
  }

  getRandomColor() {
    var letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  delete(id, name) {
    let that = this;
    var user_data = this.current_building.find((x) => x.id === id);
    this.confirm.confirm(
      "Delete Building (" + name + ")",
      "Are your sure you want to DELETE this Building?",
      [
        "This action can not be undone",
        "Deleting this will completely delete this Building & Its Details.",
      ],
      function () {
        that.api.get("api/helper/delete_building/" + id, true).subscribe(
          (res: any) => {
            that.helper.alert_success("property Deleted Successfully....");
            that.current_building = [];
            that.getproperty();
          },
          (err) => that.error_handling.handle_error(err.status, err.message)
        );
      },
      user_data.permissions,
      user_data.createdAt
    );
  }

  borderR() {
    let r = <HTMLInputElement>document.getElementById("card2b");
    r.style.borderRadius = "8px 8px 0px 0px";
  }

  async onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      for (var i = 0; i < event.target.files.length; i++) {
        var r = null;
        var f = event.target.files[i];
        var data = new FormData();
        data.append("image", event.target.files[i], event.target.files[i].name);
        await this.api.post2("api/helper/upload_image", data, false).subscribe(
          (img_res: any) => {
            this.hotel_images.push({ image: img_res.body.data.image });
          },
          (err) => this.error_handling.handle_error(err.status)
        );

        var reader = new FileReader();
        reader.onload = (eve: any) => {
          this.photo_list.push({ url: eve.target.result, file: f });
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
    //this.phIn.nativeElement.value = '';
  }

  remove_photo(index) {
    if (this.hotel_images[index].id == undefined) {
      this.hotel_images.splice(index, 1);
    } else if (this.edit) {
      try {
        this.api
          .get(
            "api/properties_gallery/destory/" + this.hotel_images[index].id,
            true
          )
          .subscribe(
            (res: any) => {
              this.hotel_images.splice(index, 1);
            },
            (err) => this.error_handling.handle_error(err.status)
          );
      } catch (error) {
        this.error_handling.handle_error(error);
      }
    }

    this.photo_list.splice(index, 1);
  }

  chkchkbox(id) {
    if (!this.Aroute.snapshot.queryParams["add_new"]) {
      let Cid = <HTMLInputElement>document.getElementById(id);
      let rmmId = localStorage.getItem("current_property");
      let chId = id.split("-")[1];
      setTimeout(() => {
        if (!Cid.checked) {
          this.api
            .get("api/hotel_amenitie/destory/" + chId + "/" + rmmId, true)
            .subscribe(
              (res: any) => { },
              (err) => this.error_handling.handle_error(err.status)
            );
        }
      }, 1000);
    }
  }

  chngeDrop(id) {
    let dropdown = <HTMLInputElement>document.getElementById(id);
    dropdown.classList.contains("rotate180")
      ? dropdown.classList.remove("rotate180")
      : dropdown.classList.add("rotate180");
  }

  sendExpenses() {
    let a = { extras: [] };
    this.hotel_setup_form.value.extras.map((val) => {
      a.extras.push(val);
    });

    this.api.post("api/property/extras", a, true).subscribe((res: any) => { });
  }

  removeExpence(data, i) {

    let langArr = <FormArray>this.hotel_setup_form.controls["extras"];
    langArr.removeAt(i);
    if (data.value.id != null) {
      this.api
        .post("api/property/delete_extras/" + data.value.id, {}, true)
        .subscribe(
          (res: any) => {
            this.helper.alert_success(
              `${data.value.type} ${data.value.name} successfully deleted.`
            );
          },
          (err) => this.error_handling.handle_error(err)
        );
    }
  }

  priceInp(con) {
    con == "price"
      ? ((this.UpsellPriceYes = true), (this.UpsellPrice = null))
      : (this.UpsellPriceYes = false);
  }

  getRandomColors() {
    for (let i = 0; i <= 100; i++) {
      this.randomColors.push(
        `#${Math.floor(Math.random() * 16777215).toString(16)}`
      );
    }
  }

  addNewExpense(controls, name: string, tax: string, cost: string, price: string, type: string, id: number = 0) {
    controls.push(
      this.fb.group({
        name: name,
        tax_percentage: tax,
        purchase_cost: cost,
        price: price,
        price_after_tax: this.getPriceAfterTax(price, tax),
        property_id: localStorage.getItem("current_property"),
        type: type,
        id: id,
      })
    );

    console.log(this.hotel_setup_form.controls.taxper.value, "forms hotel setup");


    this.Upsellname = null;
    this.UpsellPrice = null;
    this.Extrasname = null;
    this.Extrasprice = null;
    // services addons
    this.servicesName = null;
    this.servicesTax = null;
    this.servicesPrice = null;
    this.servicePriceAfterTax = null;
    // food | mini bar addons
    this.Minisname = null;
    this.MiniCostPrice = null;
    this.MiniPrice = null;
    this.MiniTax = null;
    this.miniPriceAfterTax = null;

  }

  chkForm(data, i, type) {

    if (data.value.type == type) {
      return true;
    } else {
      return false;
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.hotel_setup_form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }
  clockFormat() {
    var ar24 = [
      "01 : 00",
      "01 : 30",
      "02 : 00",
      "02 : 30",
      "03 : 00",
      "03 : 30",
      "04 : 00",
      "04 : 30",
      "05 : 00",
      "05 : 30",
      "06 : 00",
      "06 : 30",
      "07 : 00",
      "07 : 30",
      "08 : 00",
      "08 : 30",
      "09 : 00",
      "09 : 30",
      "10 : 00",
      "10 : 30",
      "11 : 00",
      "11 : 30",
      "12 : 00",
      "12 : 30",
      "13 : 00",
      "13 : 30",
      "14 : 00",
      "14 : 30",
      "15 : 00",
      "15 : 30",
      "16 : 00",
      "16 : 30",
      "17 : 00",
      "17 : 30",
      "18 : 00",
      "18 : 30",
      "19 : 00",
      "19 : 30",
      "20 : 00",
      "20 : 30",
      "21 : 00",
      "21 : 30",
      "22 : 00",
      "22 : 30",
      "23 : 00",
      "23 : 30",
      "00 : 00",
      "00 : 30",
    ];

    var ar12 = [
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
    ];
    this.mainarray = ar24;
  }
  clockformating(a: any) {
    if (a == "24hr") {
      this.t4 = true;
      this.t12 = false;

    }
    if (a == "12hr") {
      this.t4 = false;
      this.t12 = true;

    }
  }

  getPriceAfterTax(price: string, tax: string): string | number {
    const taxType = this.hotel_setup_form.controls.taxInclusive.value;

    if (!price || price.toLowerCase() === "free") {
      return price;
    }

    const taxPercentage = parseInt(tax);

    if (isNaN(taxPercentage)) {
      return price;
    }

    const totalPrice = parseFloat(price) * (1 + taxPercentage / 100);

    return taxType === "true" ? parseFloat(price) : Math.round(totalPrice);
  }



}
