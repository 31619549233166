import { Injectable } from '@angular/core';
import { ActiveChannel } from './Channel/active-channel';

@Injectable({
  providedIn: 'root'
})
export class GlobaldataService {

  constructor() { }

  public static savedSeason: any = undefined;
  public static editSeason: any = undefined;
  public static holidays: any;
  public static previewPercent: any = undefined;

  private static _activeChannels: ActiveChannel[]
  public static set activeChannels(channels: ActiveChannel[]) {
    this._activeChannels = channels
  }
  public static get activeChannels(): ActiveChannel[] {
    return this._activeChannels
  }

}