import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comm-skeleton',
  templateUrl: './comm-skeleton.component.html',
  styleUrls: ['./comm-skeleton.component.css']
})
export class CommSkeletonComponent implements OnInit {

  darkMode: boolean = false;
  @Input('template') template: boolean;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
    console.log(this.template, "true or false");
  }

  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }

  getGreyType(i) {

    //convert to if else
    if (i % 3 == 0) {
      return 'bg-darkgrey';
    } else if (i % 3 == 1) {
      return 'bg-grey';
    } else if (i % 3 == 2) {
      return 'bg-lightgrey';
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

}
