<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>Arrivals</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button3"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-arrivals" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button3')">
    </a>
  </div>
  <div class="collapse" id="collapse-arrivals" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <!-----1stchildCollapse-->
    <div class="col-md-6 pr-0 pl-0">
      <div class="flex text space-between child-bar1">
        <div>Arrivals</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button10"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-arriv" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button10')">
        </a>
      </div>
      <div class="collapse" id="collapse-arriv">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty A-1">
            <label>Arrivals</label>

            <div class="d-flex pdf-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>Edit</span>
              <!-- <label class="control-label checkbox-style">
                  <input type="checkbox" class="checkbox" name="checkbox" value="" >
                  <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                  <span class="permission-title-2">Edit</span>
                </label> -->
            </div>
            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>View</span>
              <!-- <label class="control-label checkbox-style">
                  <input type="checkbox" class="checkbox" name="checkbox" value="" >
                  <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                <span class="permission-title-2">View</span>
              </label> -->
            </div>
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty A-2">
            <label>Download</label>
            <div class="d-flex pdf-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>PDF</span>
            </div>
            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>Excel</span>
            </div>
          </div>

        </div>

      </div>
      <!-----subchildCollapse-->
      <div class="flex text space-between child-bar2">
        <div>Checked Out</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button12"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-chk-out" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button12')">
        </a>
      </div>
      <div class="collapse" id="collapse-chk-out">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty C-1">
            <label>Invoice</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty C-2">
            <label>Download</label>
            <div class="d-flex pdf-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>PDF</span>
            </div>
            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>Excel</span>
            </div>
          </div>

        </div>

      </div>
      <!---------------->
    </div>

    <!----- 2ndchildCollapse-->
    <div class="col-md-6 pr-0 pl-2">
      <div class="flex text space-between child-bar3">
        <div>Today's Arrival</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button11"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-todays" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button11')">
        </a>
      </div>
      <div class="collapse" id="collapse-todays">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty T-1">
            <label>Today's Arrival</label>
            <div class="d-flex pdf-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>Edit</span>
            </div>
            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>View</span>
            </div>
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty T-2">
            <label> Download</label>
            <div class="d-flex pdf-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>PDF</span>
            </div>
            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>Excel</span>
            </div>
          </div>

        </div>
      </div>
      <!-----subchildCollapse-->

      <div class="flex text space-between child-bar4">
        <div>In House</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button13"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-inhouse" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button13')">
        </a>
      </div>
      <div class="collapse" id="collapse-inhouse">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty H-1">
            <label>In House</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty H-2">
            <label>Print</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>

      </div>

      <!------------------->
    </div>


  </div>
</div>