import { formatPercent } from '@angular/common';
import { Component, OnInit, Inject, LOCALE_ID, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { PayroleService } from 'src/app/services/payrole.service';
import { GovtDeduction, PaidTimeOff, TutionAndTraining, UnpaidTimeOff, VoluntryDeduction, payroleResponseData } from 'src/app/common/pnl/payrole/payroleModel';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { parse } from 'querystring';
import { HelperService } from 'src/app/services/helper.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { ApiService } from 'src/app/services/api.service';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css']
})

export class PayrollComponent implements OnInit, OnChanges {

  govtDeductions: GovtDeduction[] = [];
  voluntryDeductions: VoluntryDeduction[] = [];
  tuitionTraining: TutionAndTraining[] = [];
  paidTimeOff: PaidTimeOff[] = [];
  unpaidTimeOff: UnpaidTimeOff[] = [];
  scrollTab;
  amount;
  currency_format = localStorage.getItem('currency');
  @ViewChild('payroleForm', { static: false }) payroleForm: NgForm;
  darkMode: boolean = false;
  randomColor = ['#76A200', '#EC1361', '#0179F1', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];
  randomColor2 = ['#00DCFF', '#FAD418', '#FF00BB', '#76A200', '#0179F1', '#CA8D07', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#915000', '#EC1361', '#06A535'];
  // payrole data payload
  payroleData: payroleResponseData;
  shopName;
  department: string;
  role;
  emp_name: string;
  pay_period_amount: string;
  empId: string;
  date: string;
  employee_type: string;
  voluntry_deduction_type: string;
  employee_id: number;
  // start add dummy input field on button click ngmodel property 👇
  newName: string;
  newPer;
  newAmount;
  newVolName: string;
  newVolPer;
  newVolAmount;
  newTuitionName: string;
  newTuitionCost;
  newPaidName: string;
  newExitAward: string;
  newExitDate: string;
  newUnPaidDate: string;
  newUnPaidReason: string;
  newUnPaidDay: number;
  // end add dummy input field on button click ngmodel property 👇
  custom_department;
  customDep_name: boolean = false
  role_names = [];
  employee_names = [];
  shop_names = [
    { id: 1, name: "Pizza Point" },
    { id: 2, name: "Burger Lab" },
    { id: 3, name: "Biryani Center" }
  ];

  department_names = [];

  pay_period = [
    { name: "Weekly" },
    { name: "Bi-weekly" },
    { name: "Semi monthly" },
    { name: "Monthly" },
    { name: "Custom Date" }
  ];

  propertyId = localStorage.getItem("current_property");

  constructor(
    private event: EventsService,
    @Inject(LOCALE_ID) public locale: string,
    private payroleService: PayroleService,
    private toastr: ToastrService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private confirm: ConfirmationAlertService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getDepartmentName()
    this.sumTotalDeductions();
    this.sumTotalAfterDeductions();
  }

  ngOnInit() {

    this.payroleService.getPayroleEmployeeName().subscribe(res => {
      res.data.employee.map(x => {
        this.employee_names.push({ id: x.id, name: `${x.first_name} ${x.last_name}` });
      })
    })

    this.payroleService.getPayroleEmployeeRole().subscribe(res => {
      res.data.roles.map(x => {
        this.role_names.push({ id: x.id, name: x.name });
      })
    })

    this.getDepartmentName()
    this.checkDarkMode();
    this.payroleDataUpdate()
  }

  scrollView(scroll) {
    this.event.sendDataToOtherComponent(scroll);
  }

  //! active tab on scroll

  scrollViewContent(event) {
    if (event.target.scrollTop == 0) {
      this.event.sendDataToOtherComponent('scroll1');
    }
    if (event.target.scrollTop == 100) {
      this.event.sendDataToOtherComponent('scroll2');
    }
    if (event.target.scrollTop >= 150) {
      this.event.sendDataToOtherComponent('scroll3');
    }
  }

  addDeductions(deduction) {
    if (deduction == 'govt_deductions') {
      let percentage = Math.max(0, Math.min(100, this.newPer))
      this.newAmount = (this.amount * percentage / 100).toFixed(2);

      this.govtDeductions.push({
        id: 0,
        name: this.newName,
        percentage: this.newPer + '%',
        amount: this.newAmount,
        is_deleted: 0
      });

      this.newName = '';
      this.newPer = '';
      this.newAmount = 0;
    }
    else if (deduction == 'voluntry_deductions') {
      let per = Math.max(0, Math.min(100, this.newVolPer))
      this.newVolAmount = (this.amount * per / 100).toFixed(2);

      this.voluntryDeductions.push({
        id: 0,
        name: this.newVolName,
        percentage: this.newVolPer + '%',
        amount: this.newVolAmount,
        is_deleted: 0
      });

      this.newVolName = '';
      this.newVolPer = '';
      this.newVolAmount = 0;

    } else if (deduction == 'tuition_deductions') {
      this.tuitionTraining.push({
        id: 0,
        name: this.newTuitionName,
        amount: this.newTuitionCost,
        is_deleted: 0
      });

      this.newTuitionName = '';
      this.newTuitionCost = 0;

    } else if (deduction == 'paid_deductions') {
      this.paidTimeOff.push({
        id: 0,
        name: this.newPaidName,
        existing_award: this.newExitAward,
        existing_date: this.newExitDate,
        is_deleted: 0
      });

      this.newPaidName = '';
      this.newExitAward = '';
      this.newExitDate = '';

    } else if (deduction == 'unpaid_deductions') {
      this.unpaidTimeOff.push({
        id: 0,
        date: this.newUnPaidDate,
        reason: this.newUnPaidReason,
        days: this.newUnPaidDay,
        is_deleted: 0
      });

      this.newUnPaidDate = '';
      this.newUnPaidReason = '';
      this.newUnPaidDay = null;

    }
  }

  addAmount(ev) {
    let val = Math.max(0, Math.min(100, ev.target.value));
    return this.newAmount = (this.amount * val / 100).toFixed(2);
  }

  addVolAmount(ev) {
    let value = Math.max(0, Math.min(100, ev.target.value));
    return this.newVolAmount = (this.amount * value / 100).toFixed(2);
  }


  removeGovtDeductions(index, deduction) {
    const confirm = `Are you sure you want to delete this deduction?`;
    const message = [`This action can't be undone`, 'Are you sure you want to delete?'];

    if (deduction == 'govt') {
      this.confirm.confirm(`Alert`, confirm, message,
        () => {
          this.govtDeductions.splice(index, 1);
        }
        , '', ''
      );
    } else if (deduction == 'vol') {
      this.confirm.confirm(`Alert`, confirm, message,
        () => {
          this.voluntryDeductions.splice(index, 1);
        }
        , '', ''
      );
    } else if (deduction == 'tuition') {
      this.confirm.confirm(`Alert`, confirm, message,
        () => {
          this.tuitionTraining.splice(index, 1);
        }
        , '', ''
      );
    } else if (deduction == 'paid') {
      this.confirm.confirm(`Alert`, confirm, message,
        () => {
          this.paidTimeOff.splice(index, 1);
        }
        , '', ''
      );
    } else if (deduction == 'unpiad') {
      this.confirm.confirm(`Alert`, confirm, message,
        () => {
          this.unpaidTimeOff.splice(index, 1);
        }
        , '', ''
      );
    }
  }


  //*input field add 2 decimal number in  end of the number?
  formatExpenseAmount(ev: any) {
    if (ev.target.value != '') {
      ev.target.value = parseFloat(ev.target.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1').toString();
    }
  }

  //* input field remove 2 decimal number in end of the number on focus?
  formatExpenseAmountFocus(ev: any) {
    if (ev.target.value != '') {
      ev.target.value = parseFloat(ev.target.value)
    };
  }

  //* input field add percentage sign (%) in end of the number?
  formatPercent(percent) {
    let val = percent.target.value;
    val = val.replace(/[^0-9]/g, ''); // remove any non-numeric characters
    if (val > 100) val = 100; // cap value at 100
    percent.target.value = val + '%';
  }

  //* input field remove percentage sign (%) in end of the number on focus?
  formatPercentFocus(percent) {
    var thisVal = percent.target.value.replace(/\D/g, '');
    percent.target.value = thisVal + '';
  }


  // validateNo(event: KeyboardEvent) {
  //   const keyCode = event.keyCode;
  //   const keyValue = String.fromCharCode(keyCode);

  //   const validKeys = /[0-9%]|Backspace|ArrowLeft|ArrowRight/;
  //   if (!validKeys.test(keyValue)) {
  //     event.preventDefault();
  //   } else if (keyValue === '%') {
  //     const inputEl = event.target as HTMLInputElement;
  //     const inputValue = inputEl.value + keyValue;
  //     const percentageValue = parseInt(inputValue.slice(0, -1));
  //     if (percentageValue < 0 || percentageValue > 100) {
  //       event.preventDefault();
  //     }
  //   } else {
  //     const inputEl = event.target as HTMLInputElement;
  //     const inputValue = inputEl.value + keyValue;
  //     const numericValue = parseInt(inputValue);
  //     if (numericValue < 0 || numericValue > 100) {
  //       event.preventDefault();
  //     }
  //   }
  // }


  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // & save payroll data on employee (working on saud 👇)
  payroleDataSave(formdata: NgForm) {
    let value = formdata.value;
    // let shop_id = JSON.stringify(value.shopName);

    this.payroleData = {
      "employee_id": this.employee_id ? this.employee_id : 0,
      "shop_id": this.shopName,
      "department": this.department,
      "role_id": this.role,
      "employee_name": this.emp_name,
      "employee_code": this.empId,
      "starting_date": this.date,
      "pay_period": this.pay_period_amount,
      "pay": this.amount,
      "employee_type": this.employee_type,
      "voluntry_deduction_type": this.voluntry_deduction_type,
      "property_id": this.propertyId,
      "total_deductions": this.sumTotalDeductions(),
      "pay_after_deductions": this.sumTotalAfterDeductions(),
      "is_deleted": 0,
      "govt_deduction": this.govtDeductions,
      "voluntry_deduction": this.voluntryDeductions,
      "tution_and_training": this.tuitionTraining,
      "paid_time_off": this.paidTimeOff,
      "unpaid_time_off": this.unpaidTimeOff,
    }

    this.payroleService.getPayroleByReq(this.payroleData).subscribe((res) => {
      formdata.resetForm();
      this.toastr.success(res.message)
    }, err => {
      // this.toastr.error(err.message)
    })

  }
  // * Edit mode to update payroll data on employee (working on saud 👇)
  payroleDataUpdate() {
    this.payroleService.payroleEmployeeEdit$.subscribe((res) => {
      console.log(res[0], 'data edit');
      let shop = JSON.parse(res[0].shop_id)
      let sumtotal = this.sumTotalDeductions();
      let sumAFtertotal = this.sumTotalAfterDeductions();
      this.employee_id = res[0].employee_id;
      this.shopName = shop;
      this.department = res[0].department;
      this.role = res[0].role_id;
      this.emp_name = res[0].employee_name;
      this.empId = res[0].employee_code;
      this.date = res[0].starting_date;
      this.pay_period_amount = res[0].pay_period;
      this.amount = res[0].pay;
      this.employee_type = res[0].employee_type;
      this.voluntry_deduction_type = res[0].voluntry_deduction_type;
      sumtotal = res[0].total_deductions;
      sumAFtertotal = res[0].pay_after_deductions;
      this.govtDeductions = res[0].govt_deduction;
      this.voluntryDeductions = res[0].voluntry_deduction;
      this.tuitionTraining = res[0].tution_and_training;
      this.paidTimeOff = res[0].paid_time_off;
      this.unpaidTimeOff = res[0].unpaid_time_off;
    })
  }

  // * sum total deduction (working on saud 👇)
  sumTotalDeductions() {
    const sumGovtDeduction = this.govtDeductions.reduce((accumulator, object) => { return accumulator + parseInt(object.amount) }, 0);
    const sumVolDeduction = this.voluntryDeductions.reduce((accumulator, object) => { return accumulator + parseInt(object.amount) }, 0);
    const sumTuitionDeduction = this.tuitionTraining.reduce((accumulator, object) => { return accumulator + parseInt(object.amount) }, 0);

    let sum = sumGovtDeduction + sumVolDeduction;
    let total_sum = sum + sumTuitionDeduction;

    return total_sum;
  }

  // * sum total after deduction (working on saud 👇)
  sumTotalAfterDeductions() {
    let subtractTotal = this.amount - this.sumTotalDeductions();
    if (this.sumTotalDeductions() > this.amount) {
      this.toastr.error("sorry your deduction amount is greater than pay amount");
    } else {
      return subtractTotal ? subtractTotal : 0;
    }
  }

  // get departmant names (working on saud 👇)
  getDepartmentName() {
    this.payroleService.getPayroleDepartment().subscribe(res => {
      res.data.departments.map(x => {
        this.department_names.push({ id: x.id, name: x.name });
      })
      this.department_names.push({
        id: 'custom',
        name: "Custom Departmant"
      })
      console.log(res.data.departments, 'res.data.departments');
    })
    console.log(this.department_names, 'this.department_names');
  }

  // * custom departmant names field show (working on saud 👇)
  customDepartmant(role) {
    if (role == 'custom') {
      this.customDep_name = true;
    }
  }

  // * add custom departmant names (working on saud 👇)
  addCustomDepartment(role) {
    let department = {
      property_id: this.propertyId,
      name: role,
      type: 'custom',
      is_deleted: 0
    }

    this.api.post('/api/payroll/add_new_department', department, true).subscribe((res: any) => {
      this.customDep_name = false;
      this.department_names = []
      this.getDepartmentName();
      role = null;
      this.department = null;
      this.helper.alert_success('Department added successfully');
    }, err => this.error_handling.handle_error(err.status));
  }

  // * hide custom departmant names field (working on saud 👇)
  clearSelection() {
    this.customDep_name = false;
    this.department = null;
  }
}
