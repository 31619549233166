<!-- <p>service-agreement works!</p> -->


<div class="container" id="serviceagreement" [ngClass]="darkMode ? 'container_dark' : 'container'">
    <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
            [ngClass]="darkMode ? 'service_s':'null'">S</span>ervice <span
            [ngClass]="darkMode ? 'service_a':'null'">A</span>greement
    </div>
    <div class="w-container">
        <div class="translation panel-group" id="terms_of_use">
            <div class="panel panel-default">
                <div class="abc" [ngClass]="darkMode ? 'abc_dark' : 'abc'">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse1" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        1. Terms of Hosting Service
                        <img id="col_10" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_10')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse1" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                    <div class="service_content">
                        <p class="indent bullet-points">
                            These Terms govern the principles and procedures that apply to Properties which build a
                            Property website on the Platform in order to use the Service provided by Point Click
                            Integrate by uploading their content on the Property website, as well as the rights
                            and obligations of the parties with regards to hosting.
                        </p>

                        <p class="indent bullet-points">
                            As part of the Service, the Property may use the ready-to-use templates and content offered
                            on the Platform while building the website provided that it complies with the Platform Terms
                            & Conditions. Alternatively, it may purchase an additional Service
                            to upload customized templates and content, developed by or on behalf of the Property, on
                            Point Click Integrate. The Property may also request Point Click Integrate to design a
                            customized website. Once the Property
                            notifies Point Click Integrate of such a request, the parties will separately agree on the
                            terms and conditions governing such website design. Use of the website shall be limited to
                            the scope of the Service. Activities,
                            products and services of the Property that do not fall within the scope of the Service may
                            only be uploaded subject to the consent of Point Click Integrate.
                        </p>

                        <p class="indent bullet-points">
                            The Property shall obtain the domain name of the web site and implement the settings and
                            parameters instructed by Point Click Integrate in order to redirect the domain name to Point
                            Click Integrate. Alternatively, it may request Point Click Integrate
                            to obtain and redirect the domain name by purchasing an additional Point Click Integrate
                            service. In case domain name registration and redirection is Point Click Integrate's
                            responsibility, Point Click Integrate
                            will rent the domain name for a period of 1 (one) year and perform domain name redirection.
                            Unless the Property renews the Service by the end of such 1 (one) year period, Point Click
                            Integrate will not be responsible
                            for renewing relevant domain name rental and will reserve the right to end the Service.
                        </p>

                        <p class="indent bullet-points">
                            The Property may create and translate website content in one of the supported languages. If
                            the Property decides to use the professional translation services provided by Point Click
                            Integrate, the parties will separately agree on the conditions governing
                            such service.
                        </p>

                        <p class="indent bullet-points">
                            Point Click Integrate enjoys no control over the content provided by the Property on the
                            website and all the rights and responsibilities with regards to such content will rest with
                            the Property. The Property will benefit from the Service provided that
                            it publishes its own content, which holds necessary licenses and consents and/or is obtained
                            in accordance with law, on the website that will be developed as part of the Service. In
                            cases where Point Click Integrate
                            has determined that the Property, the Customer or other third persons have or may have
                            published on the website the content that contradicts facts, codes of conduct or laws; or
                            have or may have used content without
                            necessary consents and licenses; and/or that such content violates or may violate the rights
                            of real or legal persons (including personal and intellectual property rights), Point Click
                            Integrate may ask the Property
                            to remove of such content within a certain time limit, personally remove the content and/or
                            suspend the access to the Property website until such content is removed. In case of repeat
                            of such violations, Point Click
                            Integrate may suspend in parts or whole and/or terminate the Service.
                        </p>

                        <p class="indent bullet-points">
                            Under the scope of the Service, Point Click Integrate reserves the right to use its own
                            servers or the servers of its third party business partners and outsourcers. The Property
                            will be responsible for ensuring that the transactions performed and content
                            provided on its website are in accordance with the terms of use and of limitations as
                            prescribed under the Service, as well as for preventing extraordinary overload on the
                            Platform, servers and systems due to such
                            actions. As part of the Service, the Property may not transfer, retransfer or store the
                            materials that may harm the systems. In cases where external service providers end or
                            interrupt their services or where Point
                            Click Integrate replace external service providers, Point Click Integrate will have the
                            right to suspend the Service temporarily, amend the terms and conditions of the Service, and
                            change the physical areas on which
                            the Service is provided.
                        </p>

                        <p class="indent bullet-points">
                            The Property will be solely responsible for taking security, protection and backup measures,
                            including use of encryption technologies, to prevent unauthorized access to, and archiving
                            of, website content.
                        </p>

                        <p class="indent bullet-points">
                            The Property will ensure that Customers’ use of the website complies with the law, Platform
                            Terms & Conditions and the terms specified herein, as well as with any recommendations for
                            use provided to the Property by Point Click Integrate as part of any
                            online or offline user manuals and support materials, and the Property will be solely liable
                            to and loss or damages arising from Customers’ use of the website (either incurred by the
                            Customers or third persons).
                        </p>

                        <p class="indent bullet-points">
                            Point Click Integrate reserves the right to audit compliance with the terms herein, and may
                            request from the Property the information and relevant materials regarding the website
                            content as part of such audit. The Property agrees to not obstruct or interrupt
                            such audits, and to provide any facilities required for such audits. The Property will
                            collaborate with Point Click Integrate for resolution of the problems that may arise from
                            the content or the Property-controlled
                            materials.
                        </p>

                        <p class="indent bullet-points">
                            When and where deemed necessary, Point Click Integrate may perform updates, additions,
                            adjustments and other similar maintenance works on the website, or make modifications on the
                            existing infrastructure of the website to an extent that do not substantially
                            affect the Property’s use of the Service. For the purposes of such actions, the Property
                            agrees to act in accordance with the maintenance terms and conditions requested by Point
                            Click Integrate, and to provide the
                            support required for performance of the actions requested by Point Click Integrate.
                        </p>

                        <p class="indent bullet-points">
                            The fees payable to Point Click Integrate by the Property in consideration of the Service
                            governed by the terms herein will be determined in line with the scope of the Service
                            provided. Point Click Integrate may suspend use of the website in case of any
                            delayed or interrupted payment of such fees.
                        </p>

                        <p class="indent bullet-points">
                            The Property agrees that all the rights, ownership and interests on the software, design and
                            derivative works thereof developed as part of the Service are retained by Point Click
                            Integrate and that the Property is granted a non-assignable and non-exclusive
                            right to use the website that is limited to the scope and term of the contractual
                            relationship between the parties. No provision herein will be construed to transfer relevant
                            rights, ownership and interests either
                            in parts or as a whole to the Property. The Property may not rent, sublicense, resell or
                            share the rights granted herein, and neither will it authorize third persons to use such
                            rights or transfer the same.
                        </p>

                        <p class="indent bullet-points">
                            The Property shall under no circumstances use the trade name, trademark, service mark, logo,
                            domain name etc. of Point Click Integrate (or its subsidiaries) on its website, as part of
                            its marketing materials or in other channels.
                        </p>

                        <p class="indent bullet-points">
                            All the services related to the Property’s website, except customized services developed by
                            Point Click Integrate as described under Article 2, are provided “AS IS” and Point Click
                            Integrate assumes no responsibility or commitment whatsoever with regards
                            to the accuracy, completeness and reliability thereof.
                        </p>

                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse2" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        2. Terms of Domain Name Registration and Administration
                        <img id="col_11" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_11')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse2" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                    <div class="service_content">
                        <p class="indent bullet-points bullet-points">
                            Domain Name Registration and Administration Service refers to the service whereby the
                            Property purchases a domain name for the website through Point Click Integrate, authorizing
                            Point Click Integrate to register and redirect domain names.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            In cases where Domain Name Registration and Administration Service is engaged, Point Click
                            Integrate will rent the domain name for a period of 1 (one) year and perform domain name
                            redirection.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            Unless the Property renews the Domain Name Registration and Administration Service by the
                            end of such 1 (one) year period, Point Click Integrate will not be responsible for renewing
                            relevant domain name rental and will reserve the right to end the Service.
                            Once the Service term for the Property ends, Point Click Integrate will not be responsible
                            for redeeming the domain name in cases where the rights to Point Click Integrate Service,
                            domain name renewal and domain
                            name use are transferred to third parties.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            As part of the Domain Name Registration and Administration Service, Point Click Integrate is
                            solely responsible for settings and redirections that will allow the website, which will be
                            redirected to relevant domain name, to be published through the Platform.
                            The Property will be responsible for all the domain name settings and redirections to be
                            made for other purposes.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            For the domain name to be registered and administered, Point Click Integrate may use the
                            services of external service providers as part of the Domain Name Registration and
                            Administration Service. Therefore, the Property agrees and acknowledges that the
                            data it provides for domain name registration and administration may be disclosed to such
                            service providers.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            Point Click Integrate disclaims any control over the domain name registered and displayed on
                            the Platform, and the content provided through such domain name as part of the Domain Name
                            Registration and Administration Service, and all the rights to, and
                            related responsibilities on such domain name and content shall be assumed by the Property.
                            The Property will benefit from such service provided that it provides the content that holds
                            necessary licenses and consents
                            and/or is obtained in accordance with law. In cases where Point Click Integrate is
                            determined that the Property, the Client or other third persons have or may have published
                            on the website content that contradicts
                            facts, codes of conduct or laws or have or may have used the content without necessary
                            consents and licenses; and/or that such content violates or may violate the rights of real
                            or legal persons (including personal
                            and intellectual property rights), Point Click Integrate may ask the Property to remove of
                            such content within a certain time limit, personally remove the content, discontinue the
                            domain name service and/or suspend
                            the access to the Property website until such content is removed or the domain name service
                            is discontinued. In case of repeat of such violations, Point Click Integrate may suspend in
                            parts or whole and/or terminate
                            the Service.
                        </p>

                        <p class="indent bullet-points bullet-points">
                            Point Click Integrate will not be held liable for errors, problems or interruptions that may
                            arise from the modifications made by the Property in domain name administrative settings
                            through the Platform, or for the permanent damages that may arise from
                            the errors due to the modifications made by external service providers of domain name
                            registration and administration service on their systems.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse3" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        3. Terms of Payment System
                        <img id="col_12" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_12')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse3" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">

                    <div class="service_content">

                        <p class="indent bullet-points">
                            By selecting the options provided on the Platform, the Property will be able to receive
                            Customer’ payments via different methods supported by the Platform. Depending on the method
                            selected by the Property, Point Click Integrate will charge the Service
                            fees, which are displayed either on the Platform or on the Website, in consideration of the
                            technical integration services to be executed by Point Click Integrate.
                        </p>
                        <p class="indent bullet-points">
                            Regardless of the payment method selected by the Property, the following rules shall apply
                            in cases where Customers’ credit card information is requested to be kept on the Platform:
                        </p>


                        <div class="cardcolor">
                            <div class="head">
                                <div class="service_contentt">

                                    <p class="indent sub-bullet-points">The Property will use the credit card
                                        information in accordance with law and only for the purposes of collecting and
                                        provisioning the fees of the services it provides. It will not use and disclose
                                        such information
                                        in processes other than those described in the preceding sentence, and not keep
                                        the same after the completion of relevant transaction.
                                    </p>
                                    <p class="indent sub-bullet-points">
                                        Point Click Integrate will under no circumstances have access to Customers’
                                        credit card information, and Point Click Integrate will only allow the Property,
                                        upon the request of the Property, to host the credit card information on
                                        electronic medium.
                                    </p>
                                    <p class="indent sub-bullet-points">
                                        Point Click Integrate does not warrant that the credit card information stored
                                        is accurate or updated, nor does it guarantee that it belongs to a real person,
                                        it is a real card or it has sufficient amount of balance. Point Click Integrate
                                        does not assume
                                        any responsibility with regards to the accuracy and up-to-dateness of such data.
                                    </p>
                                    <p class="indent sub-bullet-points">
                                        Storing by the Property such credit card information on the Platform does not
                                        fall under the scope of any collection services, and the Property will be
                                        responsible for receiving the payment through the credit card, management
                                        thereof and transmitting
                                        the same to third persons. Point Click Integrate will not be a party to any
                                        disputes between the Property and Customers with regards to credit card
                                        information.
                                    </p>
                                    <p class="indent sub-bullet-points">
                                        For the purposes of storing credit card information, Point Click Integrate
                                        offers a secure storing environment either internally or through an external
                                        service provider; however, Point Click Integrate does not warrant the security
                                        of the information stored.
                                    </p>

                                    <p class="indent sub-bullet-points">
                                        Point Click Integrate does not store credit card details nor does it share
                                        customer details with any 3rd parties.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <p class="indent bullet-points">
                            The Property will be liable for taxation and invoicing requirements arising from sales of
                            the services provided by the Property. No provision herein will be construed in a way that
                            such liabilities will be assumed by Point Click Integrate.
                        </p>

                        <p class="indent bullet-points">
                            Point Click Integrate will not act as the intermediary, agency or insurer of the Property in
                            commercial relationships the Property will establish by using the Platform with including
                            but not limited to payment, collection and financial transaction providers,
                            financial institutions, banks and all the other organizations and institutions from which
                            the Property will receive support services for receiving payments and following up
                            collections (“Payment Providers”). Point
                            Click Integrate will be solely responsible for providing the Service under the Agreement.
                            The Property declares and agrees that Point Click Integrate is not a party to the
                            relationship between the Property and Clients
                            and/or Payment Providers, that Point Click Integrate's sole liability is to bring together
                            technically the related parties, and that Point Click Integrate will not be held liable for
                            any contractual or regulatory
                            non-compliance on the side of the Customers and/or Payment Providers. Any requests by the
                            Property with regards to Customers or Payment Providers shall be directly communicated to
                            the party in question.
                        </p>

                        <p class="indent bullet-points">
                            The Property acknowledges, agrees and declares that Point Click Integrate is not a party in
                            the transactions performed by using the Platform and that Point Click Integrate will not be
                            held responsible and the entire liability will belong to the Property,
                            in cases of, including but not limited to, service fees not transferred to the account due
                            to failure of collection, Customers’ objections against the transaction or requests of
                            returning the service, and claims
                            of defective services, as well as disputes with regards to the Law on Protection of
                            Consumers, Law on Liabilities and related legislation, and any other claims, objections,
                            questions, problems, complaints and penalties
                            or sanctions of third persons and/or authorities. Point Click Integrate will redirect any
                            claims, objections, questions, problems, complaints and other similar notifications it
                            receives to the Property. The Property
                            will respond in writing to Point Click Integrate in no later than maximum 2 (two) days after
                            being notified of such claim, question, problem, complaint and similar situation, and unless
                            otherwise is specified, find
                            a resolution to such claim, question, problem, complaint etc. in no later than 2 (two) days
                            after notification. Without prejudice to its right to collect the existing or future
                            receivables, the Property agrees,
                            declares and warrants that it will indemnify Point Click Integrate in cash and advance upon
                            Point Click Integrate's first demand for any penalties, penal obligations, and all the other
                            fees and losses that Point
                            Click Integrate incurs against third parties or authorities. The Property agrees, declares
                            and warrants that it will not claim anything on, or object, its liability described above.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse4" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        4. Terms of Channel Management Service
                        <img id="col_13" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_13')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse4" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">

                    <div class="service_content">
                        <p class="indent bullet-points">
                            As part of the Channel Management Service, the Property will be able to connect through the
                            Platform-contracted online tourism portals (“Channel”) including but not limited to
                            expedia.com, booking.com and traveleurope.com , where Customers can access
                            Property information and complete booking transactions, manage sales channels via
                            bidirectional channel manager and update the same concurrently. The Property will share the
                            Channel and room information on the Platform
                            and be able to access booking info from the Channels through the Platform.
                        </p>
                        <p class="indent bullet-points">
                            The list of the channels Point Click Integrate contracts as part of the Channel Management
                            Service is displayed and updated on the Website. Channels are determined at sole discretion
                            of Point Click Integrate. Furthermore, Point Click Integrate may at
                            any time add or remove channels in and from the list of contracted Channels.
                        </p>

                        <p class="indent bullet-points">
                            Activation of the Chanel Management through Contracted Channels requires configuration with
                            the Channel. At the configuration phase, and depending on the scope of the Service received
                            by the Property, Point Click Integrate will support the Property. However,
                            the Property will be responsible for Channel settings, determination of the rooms to be
                            displayed on the channel, and relevant adjustments. The Property agrees and declares that
                            the room information uploaded through
                            the Platform for publication on the Channel is complete, accurate and truthful. Moreover,
                            the Property may purchase an additional Service to request Point Click Integrate to do
                            Channel settings, determine the rooms
                            to be displayed on the Channel and handle the relevant adjustments, in accordance with the
                            instructions and consents of the Property. Once such a Service is purchased, the Property
                            will convey such instructions
                            verbally (via phone) or in written (e-mail) and be solely responsible for the actions to be
                            executed by Point Click Integrate.
                        </p>
                        <p class="indent bullet-points">
                            The Property agrees that the Channel Management Service is not retroactive and that Point
                            Click Integrate does not warrant, as part of the Service, the transfer of the data
                            previously obtained or uploaded on the Channels.
                        </p>
                        <p class="indent bullet-points">
                            In cases where the Property changes some data, including but not limited to room types and
                            rates, to be published on the Channels, it will be responsible for verifying whether such
                            changes are reflected on the Channel. Moreover, the Property will notify
                            Point Click Integrate promptly of possible errors and interruptions that may arise due to
                            such changes. The Property agrees that Point Click Integrate is not responsible for the
                            interruptions that arise from such
                            changes.
                        </p>
                        <p class="indent bullet-points">
                            In order to synchronize the information to be uploaded on the Channels, Point Click
                            Integrate may use the services of external service providers from time to time, as part of
                            the Channel Management Service. Therefore, the Property agrees and acknowledges
                            that the data it provides for synchronization between Platform and the Channel may be
                            disclosed to such service providers.
                        </p>
                        <p class="indent bullet-points">
                            Point Click Integrate agrees to notify the Property of the errors it may diagnose during
                            synchronization with the Channels.
                        </p>
                        <p class="indent bullet-points">
                            Point Click Integrate will not be held liable for errors, problems and interruptions that
                            arise from changes the Property carries out in its system or for errors arising from the
                            changes made by the Channels on their systems or for the permanent damages
                            arising from interruptions in the integration technology of the Channels. The Property
                            agrees that such changes may require re-integration that may incur additional service fees,
                            in which case Point Click Integrate
                            will not be held liable for failure to synchronize or convey some information due to
                            integration problems.
                        </p>
                        <p class="indent bullet-points">
                            The Property agrees that some information may not be accessible (e.g. booking prices
                            displayed in lump sum instead of daily rates) due to technical specifications of the
                            Channels’ integration systems, or depending on their integration with Point Click
                            Integrate technologies; that some information may not be updated; and that Point Click
                            Integrate may not be held liable for interruptions arising from integration systems or from
                            failure to integrate certain information
                            with Point Click Integrate technology.
                        </p>
                        <p class="indent bullet-points">
                            Point Click Integrate will not be held liable for errors and omissions that arise from
                            Channels’ own adjustments without Point Click Integrate's involvement. Point Click Integrate
                            will notify the Property of the recognized channel errors within a reasonable
                            time period.
                        </p>
                        <p class="indent bullet-points">
                            As part of the Channel Management Service, Point Click Integrate may also provide access to
                            some free of charge channels including the websites of local agencies. The responsibility
                            for any interruptions arising from use of such Channels will be borne
                            by the Property, and Point Click Integrate will not indemnify the losses arising from such
                            interruptions.
                        </p>


                        <p class="indent bullet-points">
                            In cases where the Services are interrupted by maintenance works or similar situations,
                            Point Click Integrate will make necessary updates as soon as possible. In consideration of
                            the bookings received from the Channels, the Property will not pay fees
                            to Point Click Integrate other than those determined for the Service. Channels reserve the
                            right to charge a commission rate.
                        </p>

                        <p class="indent bullet-points">
                            Point Click Integrate reserves the right to record and log all the information, requests and
                            adjustments provided by the Property to the Channels or vice versa, in addition to its right
                            to put forth the same as legal proof in case of disputes among the
                            Property, Point Click Integrate and relevant Channels. The Property expressly authorizes
                            Point Click Integrate to record and use such data when deemed necessary.
                        </p>

                        <p class="indent bullet-points">
                            Service provider name or title of Point Click Integrate on the Channels will be viewed as
                            Point Click Integrate, Point Click Integrate Distribution Network (HDN), HDN or HDN Global.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse5" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        5. Terms of Content Management Service
                        <img id="col_14" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_14')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse5" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">

                    <div class="service_content">
                        <p class="indent bullet-points">
                            Content Management Service refers to the service which allows the Property to upload on
                            Point Click Integrate the templates and content developed by or on behalf of the Property
                            and to make the same available on the Platform.
                        </p>
                        <p class="indent bullet-points">
                            The Property will provide image- and text-based documentation for development of the website
                            (“Customized Website”) to be published on the Platform that will be developed by using the
                            Content Management Service.
                        </p>

                        <p class="indent bullet-points">
                            The Property will be responsible for development of the Customized Website, necessary
                            adjustments and settings on the Platform, and creating, uploading and management of relevant
                            content. Point Click Integrate will be only responsible for providing the
                            technology service that allows the Customized Website to be uploaded and published on the
                            Platform.
                        </p>
                        <p class="indent bullet-points">
                            The Property will receive necessary permissions and consents with regards to visual and
                            written materials uploaded on the Platform while developing the Customized Website. In case
                            of failure to satisfy this requirement, the Property will be held liable
                            for all the losses incurred by Point Click Integrate which will be indemnified by the
                            Property in accordance with Point Click Integrate's requests. Point Click Integrate has no
                            control whatsoever over content registered
                            and published on the Platform as part of the Content Management Service, and all rights and
                            liabilities on such domain name and content shall rest with the Property. The Property will
                            benefit from such service provided
                            that it provides the content that holds necessary licenses and consents and/or is obtained
                            in accordance with law. In cases where Point Click Integrate is determined that the
                            Property, the Customer or other third
                            persons have or may have published on the website content that contradicts facts, codes of
                            conduct or laws or have or may have used the content without necessary consents and
                            licenses; and/or that such content violates
                            or may violate the rights of real or legal persons (including personal and intellectual
                            property rights), Point Click Integrate may ask the Property to remove of such content
                            within a certain time limit, personally
                            remove the content and/or suspend the access to the Property website until such content is
                            removed. In case of repeat of such violations, Point Click Integrate may suspend in parts or
                            whole and/or terminate the
                            Service.
                        </p>
                        <p class="indent bullet-points">
                            The Property may also ask Point Click Integrate to develop the Customized Website or request
                            Point Click Integrate's assistance while developing the same. Once the Property notifies
                            Point Click Integrate of such a request, the parties will separately
                            agree on the conditions governing website development. Use of the website shall be limited
                            to the scope of the Service. Activities, products and services of the Property that do not
                            fall within the scope of the
                            Service may only be uploaded subject to the consent of Point Click Integrate.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse6" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        6. Terms of Promotion Service
                        <img id="col_15" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_15')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse6" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">
                    <div class="service_content">
                        <p class="indent bullet-points">
                            The Promotion Service refers to the service that is integrated with the online booking
                            module of the Platform and allows the Property, during online sales, to carry out promotions
                            listed on the Platform.
                        </p>

                        <p class="indent bullet-points">
                            Also available for use on social media, the Promotion Service covers a service that is
                            integrated with the online booking module of the Platform and allows the Clients to enjoy
                            discounts provided that they share their booking information of Facebook before
                            closing the purchasing screen or website. The post that shows the Customers’ booking
                            information includes a link to the Property website which will enable other Clients
                            redirected to the website to receive discounts
                            offered by the Property.
                        </p>

                        <p class="indent bullet-points">
                            As part of the Promotion Service, the Property will create fixed or percentage-based
                            discounts and enable Clients visiting the website to receive promotional discounts, thanks
                            to Point Click Integrate's option that requires Customers to share their booking
                            info on Facebook. Accordingly, the Property will determine the intervals, frequency and
                            applicable room types for the “Social Media Promotions” offered to Clients. The Property
                            will be responsible for ensuring that
                            the “Social Media Coupons” are used by Customers in accordance with the manner agreed by the
                            Property.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse7" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        7. Terms of Search Engine Optimization Service
                        <img id="col_16" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_16')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse7" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">

                    <div class="service_content">
                        <p class="indent bullet-points">
                            Point Click Integrate Search Engine Optimization Service includes an online booking engine
                            service which involves basic-level, embedded Search Engine Optimization (“SEO”).
                        </p>

                        <p class="indent bullet-points">
                            By using SEO, Point Click Integrate may channel traffic from Google, Yandex, Bing and Yahoo
                            to the Property website. However, Point Click Integrate does not warrant that traffic for
                            the Property website or sales will increase as a consequence of this
                            Service.
                        </p>

                        <p class="indent bullet-points">
                            Point Click Integrate website employs methods including H1, headline and meta tags, while
                            automatically creating sitemaps.xml files to quickly display the changes in the Property
                            services or on the website in search engines. Point Click Integrate reserves
                            the right to change from time to time the techniques employed at its discretion.
                        </p>

                        <p class="indent bullet-points">
                            In line with the integrated analytic reporting service provided as part of the SEO Service,
                            Point Click Integrate will partially or wholly share with the Property the information
                            including how the Customers access the Property website, their countries
                            of origin, URL addresses that link to the Property website, key words that ensure access to
                            the Property website, number of page views and number of individual visitors, as well as
                            statistics that list the best-selling
                            or most profitable services.
                        </p>
                    </div>
                </div>
            </div>

            <div class="panel panel-default">
                <div class="abc">
                    <a data-toggle="collapse" data-parent="#terms_of_use" href="#servicecollapse8" class="collapsed abc"
                        [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false" (click)="chngedrop('col_1')">
                        8. Miscellaneous Terms Regarding Services
                        <img id="col_17" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                            class="imagedrop" alt="" (click)="chngedrop('col_17')">
                    </a>
                </div>
                <hr class="hr-pd">
                <div id="servicecollapse8" class="text panel-collapse collapse"
                    [ngClass]="darkMode ? 'text_dark' : 'text'">

                    <div class="service_content">
                        <p class="indent bullet-points">
                            The fees payable to Point Click Integrate by the Property in consideration of the Services
                            governed by the Terms herein are specified on the Website or Platform in line with the
                            Service provided. Point Click Integrate may suspend use of the Services in
                            case of any delayed or interrupted payment of such fees.
                        </p>

                        <p class="indent bullet-points">
                            The Services governed by these Terms and Conditions are additional services provided through
                            the Platform and they shall terminate in cases where the Property ends its use of the
                            Platform for whatever reason or where the integration required for the Services
                            cannot be performed/completed due to issues on the side of Property. In this case, Point
                            Click Integrate will not be held liable to refund the fees already paid by the Property for
                            the Services.
                        </p>

                        <p class="indent bullet-points">
                            The entire liability of Point Click Integrate as part of the Services will be limited to
                            direct damages and losses arising from issues on the side of Point Click Integrate, and the
                            issues leading to such damages and losses will be notified to Point Click
                            Integrate in no later than 5 (five) days following occurrence of the same. However, the
                            compensation payable by Point Click Integrate will under no circumstances exceed the amount
                            paid by the Property for the Service
                            which caused such damages and losses.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>