import { Injectable, Injector } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, FormArray, FormGroupDirective } from "@angular/forms";
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { ApiService } from './api.service';
import { GlobaldataService } from '../common/globaldata.service';
import { PromotionRow } from '../common/Inventory/Reservation/PromotionGrid';
import { ActiveChannel } from '../common/Channel/active-channel';
import { BookingModel } from '../common/Inventory/Reservation/Booking';
import { BookingItemModel } from '../common/Inventory/Reservation/BookingItemModel';
import { ExtraPersonInfo } from '../common/Inventory/ExtraPersonInfo';
import { BookingExtras } from '../common/Inventory/Reservation/BookingExtras';
import { ActiveChannelResponse } from '../common/Channel/active-channel-response';
import { ExtrasResponse } from '../common/Inventory/Reservation/ExtrasAddons';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import moment from 'moment';
import { BulkReservationSelectionRange } from '../common/Inventory/Reservation/BulkReservationSelectionRange';

@Injectable({
  providedIn: 'root'
})

export class ReservationService {
  endpoints = {
    getAvailableRooms: "api/booking/reservation_room_types",
    getRatesByCheckinCheckout: "api/booking/get_rates_by_checkin_checkout",
    createReservation: "api/booking/store_bulk",
    updateReservation: "api/booking/update/",
    getReservationInformation: "api/booking/get_single_arrival_data/",
    getChannels: "api/property/active_channels",
    getPromotions: "api/package/get_package_by_date",
    getAddOns: "api/property/get_extras/"
  }

  property: any = JSON.parse(localStorage.getItem("property"));

  onReservationFormPrepared$: Observable<FormGroup> = new Subject();
  isEditReservation$ = new Subject<{ isEdit: boolean, reservationId: number }>();

  private bulkReservationSelectionRangeSubject$ = new BehaviorSubject<BulkReservationSelectionRange>(undefined);
  bulkReservationSelectionRange$ = this.bulkReservationSelectionRangeSubject$.asObservable();

  private reservationPopupOpened = new Subject<boolean>();
  onReservationPopupOpened$ = this.reservationPopupOpened.asObservable();

  private resetReservationForm$ = new Subject();  
  onResetReservationForm$ = this.resetReservationForm$.asObservable();

  constructor(
    private _httpService: ApiService,
    private http: HttpClient,
    private fb: FormBuilder,
    private injector: Injector
  ) {

  }

  get httpService(): ApiService {
    if (!this._httpService) {
      this._httpService = this.injector.get(ApiService)
    }
    return this._httpService
  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set('Authorization', bearer_token);
    return header;
  }

  getGuestStatuses = () =>
    [
      {
        name: "Checked In",
        value: "Checked In"
      },
      {
        name: "Arriving Soon",
        value: "Pending"
      },
      {
        name: "No Show",
        value: "No Show"
      },
    ]


  getBookingStatuses = () =>
    [
      {
        name: "Hold",
        value: "hold",
      },
      {
        name: "Cancelled",
        value: "cancelled",
      },
      {
        name: "Confirmed",
        value: "confirm",
      },
      {
        name: "Modified",
        value: "modified",
      },
    ];


  getPaymentStatuses = () =>
    [
      {
        name: "Paid",
        value: "paid",
      },
      {
        name: "Due",
        value: "unpaid",
      },
      // {
      //   name: "Deposit",
      //   value: "deposite",
      // },
    ]

  getAvailableRooms$(checkinDate, checkoutDate): Observable<any> {
    let data = {
      property_id: this.property.id ? this.property.id : 0,
      start_date: checkinDate,
      end_date: checkoutDate
    };

    return this.http.post(this.httpService.base_url + this.endpoints.getAvailableRooms, data, {
      headers: this.prepareAuthHeaders()
    });
  }

  getAvailablePromotions$(checkinDate, checkoutDate): Observable<any> {
    let data = {
      property_id: this.property.id ? this.property.id : 0,
      date_from: checkinDate,
      date_to: checkoutDate
    }

    return this.http.post(this.httpService.base_url + this.endpoints.getPromotions, data, {
      headers: this.prepareAuthHeaders()
    });
  }

  getRatesByCheckinCheckout$(checkinDate, checkoutDate, roomId, packageId = 0) {
    let data = {
      property_id: this.property.id ? this.property.id : 0,
      start_date: checkinDate,
      end_date: checkoutDate,
      room_id: roomId,
      package_id: packageId ? packageId : 0,
    }
    return this.http.post(this.httpService.base_url + this.endpoints.getRatesByCheckinCheckout, data, {
      headers: this.prepareAuthHeaders()
    });
  }

  getActiveChannels(): Observable<ActiveChannel[]> {
    let channels = GlobaldataService.activeChannels;

    if (channels && channels.length > 0) {
      // If channels are already available, return an observable from them
      return from(Promise.resolve(channels));
    }

    // If channels are not available, fetch them and return an observable
    return new Observable<ActiveChannel[]>(observer => {
      this.getActiveChannels$().subscribe(res => {
        GlobaldataService.activeChannels = res.data;
        observer.next(res.data);
        observer.complete();
      })
    }).pipe(
      switchMap(data => from(Promise.resolve(data)))
    );
  }

  async getCustomPricingGrid(startDate, endDate, roomId): Promise<PromotionRow[]> {
    let data = {
      property_id: this.property.id ? this.property.id : 0,
      start_date: startDate,
      end_date: endDate,
      room_id: roomId
    }
    let res = await this.httpService.post(this.endpoints.getPromotions, data, {
      headers: this.prepareAuthHeaders()
    })
    let promotions = (res as any).data as PromotionRow[]
    return promotions
  }

  createReservation$(data): Observable<any> {
    return this.http.post<any>(this.httpService.base_url + this.endpoints.createReservation, data, {
      headers: this.prepareAuthHeaders()
    });
  }

  updateReservation$(data): Observable<any> {
    return this.http.post<any>(this.httpService.base_url + this.endpoints.updateReservation + data.booking_id, data, {
      headers: this.prepareAuthHeaders()
    });
  }

  intializeReservationForm(): FormGroup {
    let cleanForm = this.fb.group({
      sperateRoomDates: [false],
      property_id: this.property.id,
      channel_id: [null, Validators.required],
      room_id: "",
      package_id: null,
      reservation_id: "",
      date_arrival: [null, Validators.required],
      date_departure: [null, Validators.required],
      count_adult: "",
      count_child: "",
      total: "",
      prices: "",
      guest_firstname: ["", Validators.required],
      guest_lastname: ["", Validators.required],
      payment_status: [null, Validators.required],
      status: [null, Validators.required],
      bedType: '',
      roomView: '',
      currency: this.property.currency,
      child_ages: "",
      guest_phone: "",
      confirmation_number: "",
      guest_address: "",
      guest_email: "",
      comments: "clean",
      room_number: "",
      guest_city: "",

      booking_items: this.fb.array([this.fb.group(
        {
          id: null,
          guest_name: null,
          guest_first_name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
          guest_last_name: [null, Validators.compose([Validators.required, Validators.minLength(3)])],
          guest_status: [null, Validators.required],
          booking_id: null,
          room_id: [null, Validators.compose([Validators.required])],
          room_no_id: [null, Validators.compose([Validators.required])],
          guest_id: null,
          package_id: null,
          promotion_id: null,
          no_of_adults: "",
          no_of_childs: "",
          no_of_infants: "",
          payee_namee: null,
          guest_card_typee: null,
          bedType: '',
          roomView: '',
          isPetAllowed: true,
          isSmokingAllowed: true,
          isWheelChairAccessible: true,
          guest_cardd: [
            null,
            [
              Validators.pattern(
                "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
              ),
            ],
          ],
          guest_card_expiryy: null,
          guest_card_series_codee: null,
          breakfast: 0,
          extra_persons: this.fb.array([]),
          booking_extras: this.fb.array([]),
          check_in: null,
          check_out: null,
          check_in_time: null,
          check_out_time: null,
          prices: null,
          status: null,
          pricesjson: "",
          id_type: null,
          id_value: null,
          email: [null, Validators.compose([Validators.email])],
          phone_number: null,
        })
      ]),
      guest_card: [
        null,
        [
          Validators.pattern(
            "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
          ),
        ],
      ],
      guest_card_type: null,
      payee_name: null,
      guest_card_expiry: null,
      guest_card_series_code: null,
      privilege: null,
      marketing_email: "",
      eta: [this.property.eta],
      etd: [this.property.etd],
      personal_id_type: [null, Validators.required],
      personal_id_value: [null, Validators.required],
      discount: null,
      discount_amount: null,
      discount_package: null,
      company_name: "",
      promo: null,
      same_date: null,
      SpecialRequests: null,
      TaxInclusive: this.property.taxInclusive,
      extra_person_fee: null,
      cashDeposit: null,
      Tax: null,
      Taxper: this.property.taxper,
      subtotal: null,

    });
    return cleanForm;

  }

  async getReservationInformation(reservationId: number): Promise<any> {
    return await this.httpService.get(this.endpoints.getReservationInformation + reservationId, {
      headers: this.prepareAuthHeaders()
    })
  }

  prepareEditForm$(reservationId: number): Observable<FormGroup> {
    return this.http.get(this.httpService.base_url + this.endpoints.getReservationInformation + reservationId, {
      headers: this.prepareAuthHeaders(),
    }).pipe(
      map((res: any) => {
        const reservationInfo = res.data[0] as BookingModel;
        return this.prepareFromWithValues(reservationInfo);
      })
    );
  }

  prepareFromWithValues(reservationInfo: BookingModel): FormGroup {

    // debugger;
        
    let bookingItems = this.extractBookingItems(reservationInfo.bookingitems);
    let form = this.fb.group({
      property_id: this.property.id,
      channel_id: [reservationInfo.channel_id, Validators.required],
      room_id: reservationInfo.room_id,
      package_id: reservationInfo.package_id,
      reservation_id: reservationInfo.reservation_id,
      date_arrival: reservationInfo.date_arrival,
      date_departure: reservationInfo.date_departure,
      count_adult: reservationInfo.count_adult,
      count_child: reservationInfo.count_child,
      total: [reservationInfo.total, Validators.required],
      prices: [reservationInfo.prices, Validators.required],
      guest_firstname: [reservationInfo.guest_firstname, Validators.required],
      guest_lastname: reservationInfo.guest_lastname,
      payment_status: [reservationInfo.payment_status, Validators.required],
      status: [reservationInfo.status, Validators.required],
      currency: reservationInfo.currency,
      child_ages: reservationInfo.child_ages,
      guest_phone: reservationInfo.guest_phone,
      confirmation_number: reservationInfo.confirmation_number,
      guest_address: reservationInfo.guest_address,
      guest_email: [reservationInfo.guest_email, Validators.compose([Validators.email])],
      comments: reservationInfo.comments,
      room_number: reservationInfo.room_number,
      guest_city: reservationInfo.guest_city,
      booking_items: bookingItems,
      guest_card: [
        reservationInfo.guest_card,
        [
          Validators.pattern(
            "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
          ),
        ],
      ],
      guest_card_type: reservationInfo.guest_card_type,
      payee_name: reservationInfo.payee_name,
      guest_card_expiry: reservationInfo.guest_card_expiry,
      guest_card_series_code: reservationInfo.guest_card_series_code,
      privilege: reservationInfo.privilege,
      marketing_email: reservationInfo.marketing_email,
      eta: [this.property.eta, Validators.required],
      etd: [this.property.etd, Validators.required],
      personal_id_type: reservationInfo.personal_id_type,
      personal_id_value: reservationInfo.personal_id_value,
      discount: reservationInfo.discount,
      discount_amount: null,
      discount_package: reservationInfo.discount_package,
      company_name: reservationInfo.company_name,
      promo: reservationInfo.promo,
      same_date: reservationInfo.same_date,
      SpecialRequests: reservationInfo.SpecialRequests,
      TaxInclusive: reservationInfo.TaxInclusive,
      extra_person_fee: reservationInfo.extra_person_fee != 0 ? reservationInfo.extra_person_fee : null,
      cashDeposit: reservationInfo.cashDeposit,
      Tax: reservationInfo.Tax,
      Taxper: reservationInfo.Taxper,
      subtotal: reservationInfo.subtotal,
    });

    return form;
  }

  createBookingItem(bookingItem: BookingItemModel): FormGroup {
    let bi: BookingItemModel = bookingItem;

    if (!bookingItem) {
      bi = this.getEmptyBookingItem()
    }
    
    //debugger;
    let extraPerson = this.extractExtraPersons(bi.extra_persons);
    let bookingExtras = this.extractBookingExtras(bi.booking_extras);

    return this.fb.group(
      {
        id: bi.id,
        guest_name: [bi.guest_name],
        guest_first_name: [(bi.guest_name && bi.guest_name.includes(',')) ? bi.guest_name.split(',')[0] : bi.guest_name, Validators.compose([Validators.required, Validators.minLength(3)])],
        guest_last_name: [(bi.guest_name && bi.guest_name.includes(',')) ? bi.guest_name.split(',')[1] : bi.guest_name, Validators.compose([Validators.required, Validators.minLength(3)])],
        guest_status: bi.guest_status,
        booking_id: bi.booking_id,
        room_id: [bi.room_id, Validators.compose([Validators.required])],
        room_no_id: [bi.room_no_id, Validators.compose([Validators.required])],
        guest_id: bi.guest_id,
        package_id: bi.package_id,
        season_id: bi.season_id,
        promotion_id: bi.promotion_id,
        no_of_adults: [bi.no_of_adults, Validators.compose([Validators.required, Validators.min(1)])],
        no_of_childs: [bi.no_of_childs, Validators.compose([Validators.required, Validators.min(0)])],
        no_of_infants: [bi.no_of_infants, Validators.compose([Validators.required, Validators.min(0)])],
        payee_namee: bi.payee_namee,
        guest_card_typee: bi.guest_card_typee,
        bedType: '',
        roomView: '',
        isPetAllowed: true,
        isSmokingAllowed: true,
        isWheelChairAccessible: true,
        guest_cardd: [
          bi.guest_cardd != '0' ? bi.guest_cardd : null,
          [
            Validators.pattern(
              "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
            ),
          ],
        ],
        guest_card_expiryy: bi.guest_card_expiryy,
        guest_card_series_codee: bi.guest_card_series_codee,
        breakfast: bi.breakfast,
        extra_persons: extraPerson,
        booking_extras: bookingExtras,
        check_in: bi.check_in,
        check_out: bi.check_out,
        check_in_time: bi.check_in_time,
        check_out_time: bi.check_out_time,
        prices: bi.prices,
        status: bi.status,
        pricesjson: bi.pricesjson,
        id_type: bi.id_type,
        id_value: bi.id_value,
        email: [bi.email, Validators.compose([Validators.email])],
        phone_number: bi.phone_number,
      })
  }

  getEmptyBookingItem(): BookingItemModel {
    return {
      id: null,
      guest_name: null,
      guest_first_name: null,
      guest_last_name: null,
      guest_status: "",
      booking_id: null,
      room_id: null,
      room_no_id: null,
      guest_id: null,
      package_id: null,
      season_id: null,
      promotion_id: null,
      no_of_adults: null,
      no_of_childs: null,
      no_of_infants: null,
      payee_namee: null,
      guest_card_typee: null,
      guest_cardd: null,
      guest_card_expiryy: null,
      guest_card_series_codee: null,
      breakfast: 0,
      extra_persons: [],
      booking_extras: [],
      bedType: '',
      roomView: '',
      check_in: null,
      check_out: null,
      check_in_time: null,
      check_out_time: null,
      prices: null,
      status: null,
      pricesjson: "",
      id_type: null,
      id_value: null,
      email: null,
      phone_number: null,
      isPetAllowed: true,
      isSmokingAllowed: true,
      isWheelChairAccessible: true,
    }
  }

  extractExtraPersons(extraPersons: ExtraPersonInfo[]): FormArray {
    //debugger;
    /// TODO: Add this functionality here
    let formArray: FormArray = this.fb.array([])
    if (extraPersons && extraPersons.length > 0) {
      extraPersons.forEach((ep) => formArray.push(this.createExtraPerson(ep)))
    }
    return formArray
  }

  extractBookingExtras(bookingExtras: BookingExtras[]): FormArray {
    let formArray: FormArray = this.fb.array([])
    if (bookingExtras && bookingExtras.length > 0) {
      bookingExtras.forEach((be) => formArray.push(this.createBookingExtras(be)))
    }
    return formArray
  }

  createBookingExtras(bookingExtras: any): FormGroup {
    let ep = bookingExtras
    if (!bookingExtras) {
      ep = {
        property_id: null,
        extras_item_id: null,
        item_count: null,
      }
    }
    return this.fb.group({
      property_id: ep.property_id,
      extras_item_id: ep.extras_item_id ? ep.extras_item_id : ep.property_extras_item_id,
      item_count: ep.item_count,
    })
  }

  extractBookingItems(bookingItems: BookingItemModel[]): FormArray {
    let formArray:FormArray = this.fb.array([])
    if (bookingItems && bookingItems.length > 0) {
      bookingItems.forEach((bi) => formArray.push(this.createBookingItem(bi)))
    }
    return formArray
  }

  createExtraPerson(extraPerson: ExtraPersonInfo): FormGroup {
    let ep = extraPerson
    if (!extraPerson) {
      ep = {
        name: null,
        id_type: null,
        id_number: null
      }
    }
    return this.fb.group({
      name: ep.name,
      id_type: [ep.id_type, Validators.compose([Validators.required])],
      id_number: [ep.id_number, Validators.compose([Validators.required, Validators.minLength(3)])],
    })
  }

  private getActiveChannels$(): Observable<ActiveChannelResponse> {
    let data = {
      property_id: this.property.id
    }
    let result = this.http.post<ActiveChannelResponse>(this.httpService.base_url + this.endpoints.getChannels, data, {
      headers: this.prepareAuthHeaders()
    });

    return result
  }

  getAddOns$(): Observable<ExtrasResponse> {
    return this.http.get<ExtrasResponse>(this.httpService.base_url + this.endpoints.getAddOns + this.property.id, {
      headers: this.prepareAuthHeaders()
    });
  }

  pushBulkReservationSelectionRange(range: BulkReservationSelectionRange) {
    this.bulkReservationSelectionRangeSubject$.next(range);
  }

  resetReservationForm() {
    this.resetReservationForm$.next();
  }

  reservationPopupOpen() {
    this.reservationPopupOpened.next(true);
  }
}
