<div class="filters_row" [ngClass]="darkMode ? 'darkOn' : 'null'">
  <div class="row">
    <div class="column-wrapper display-flex space-between">
      <!-- <div class="col-md-4" style="width: 30.7%;">
            <div class="flex-grid">
                <button type="button" class="btn btn-primary next-week-button" (click)="week_move('prev')">
                    <i class="fa fa-arrow-left"></i>
                </button>
                <div class="inventory-calendar">
                    <span class="pointclick_date left_input_date form-control date-first" style="border: none;">
                        <input type="text" ngxDaterangepickerMd class="form-control date_range_picker " (change)="calendar_change($event)" placeholder="Date" [(ngModel)]="calendar_date" [locale]="{format: 'DD MMMM', separator: '            To            '}" style="display: inline-block; padding: 10px; height:40px; text-align: center;font-size: 14px;" [dateLimit]="7">
                    </span>
                </div>
                <button type="button" class="btn btn-primary prev-week-button" (click)="week_move('next')">
                    <i class="fa fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <div class="col-md-7 flx-col-5">
            <div class="">
                <button type="button" data-target="#bulk_update" data-toggle="modal" class="form-control bulk-update" name="" style="border-radius: 8px;float:right" (click)="fliBox()" >+ Reservation</button>
            </div>
        </div> -->
      <div>
        <ul class="nav navbar-nav tabsBar">
          <li class="active">
            <a class="btn btn-link active">INVENTORY</a>
          </li>
          <li class="hover">
            <a class="btn btn-link active" routerLink="/app/communication">GUEST LOOK UP</a>
          </li>
        </ul>
      </div>
      <div class="display-flex dfTopCal">
        <div class="flex-grid " style="width: 90%;margin: 5px 5px 5px 0px; justify-content: end;">
          <button type="button" class="btn btn-primary next-week-button">
            <i class="fa fa-arrow-left"></i>
          </button>
          <div class="inventory-calendar">
            <span class="pointclick_date left_input_date form-control date-first" style="border: none;">
              <!-- <input type="text" ngxDaterangepickerMd class="form-control date_range_picker " placeholder="Date"
                style="display: inline-block; padding: 10px; height:40px; text-align: center;font-size: 14px;"> -->
              <!-- <mat-form-field>
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" [(ngModel)]="calendarWeekDateRange.startDate">
                    <input matEndDate placeholder="End date" [(ngModel)]="calendarWeekDateRange.endDate">
                  </mat-date-range-input>
                  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                 -->

              <!-- <input type="text" ngxDaterangepickerMd class="form-control date_range_picker "
                 placeholder="Date" [(ngModel)]="calendarWeekDateRange"
                [locale]="{format: 'DD  MMM  YYYY', separator: '            To            '}"
                style="display: inline-block; padding: 10px; height:40px; text-align: center;font-size: 14px;"
                [dateLimit]="7" (change)="calendarDateChange($event)"> -->
              <mat-form-field class="w-100" appearance="fill">
                <mat-date-range-input matInput [rangePicker]="picker" (click)="picker.open()">
                  <input matStartDate placeholder="Start date" [(ngModel)]="calendarWeekDateRange.startDate">
                  <input matEndDate placeholder="End date" [(ngModel)]="calendarWeekDateRange.endDate">
                </mat-date-range-input>
                <!-- <mat-hint>DD MMM YYYY TO DD MMM YYYY</mat-hint> -->
                <mat-date-range-picker #picker class="range-picker"></mat-date-range-picker>
              </mat-form-field>

            </span>
          </div>
          <button type="button" class="btn btn-primary prev-week-button">
            <i class="fa fa-arrow-right"></i>
          </button>
          <img class="go_icon btn-hov_popup" src="assets/img/other/go.png" (click)="updateDate()" />
        </div>
        <div class="">

          <button type="button" (click)="reservationPopupOpened()" class="form-control bulk-update" name=""
            data-target="#reservation-popup" data-toggle="modal"
            style="border-radius: 8px;float:right;margin: 5px 5px 0px 0px;">+
            Reservation</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <ng-container *ngIf="roomBookings$ | async as roomBookings">
  <ul>
    <li *ngFor="let item of roomBookings"> {{item.date}}</li>
  </ul>
</ng-container>

<ng-container *ngIf="totalRoomsInSeason$ | async as totalRoomsInSeason">
  {{totalRoomsInSeason | json}}
</ng-container> -->

<app-inventory-calender [roomSet]="roomsSet"></app-inventory-calender>

<!-- <div id="reservation-popup" class="modal fade bulk_updatemodal" role="dialog" style="width:100%;"> -->
<app-reservation-modal></app-reservation-modal>
<!-- </div> -->
