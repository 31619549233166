import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-agreement',
  templateUrl: './service-agreement.component.html',
  styleUrls: ['./service-agreement.component.css']
})
export class ServiceAgreementComponent implements OnInit {
  darkMode: boolean = false
  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
  chngedrop(id) {
    let a = (<HTMLInputElement>document.getElementById(id))
    if (a.classList.contains('trans180')) {
      a.classList.remove('trans180')
    }
    else {
      a.classList.add('trans180')
    }
  }
}
