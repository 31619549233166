<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>Channel Manager</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck6" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button6"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-cm" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button6')">
    </a>
  </div>
  <div class="collapse" id="collapse-cm" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <!-- <div class="card">


        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim
        keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
      </div> -->
  </div>
</div>
