<div class="main">
    <app-pnl-breadcrum (dateChanged)="onDateChanged()"></app-pnl-breadcrum>
    <div class="rev-main">
        <div class="col-md-12 RM-col-1">
            <details class="RoomRevenue">
                <summary class="show2 " (click)="changeArrow()">
                    Revenue
                    <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" id="dropDown1"
                        [ngClass]="arrowUpDown ? 'rotat' : 'drp'">
                </summary>
                <details [ngClass]="darkMode ? 'card_dark' : 'card'"
                    style="margin-bottom: 2px; padding: 0; box-shadow: none;">
                    <summary class="showDanger null">
                        <div class="col-md-12 RM-col-2">
                            <div class="revenue card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                                <!-- <div class="flex text space-between blue-bar">Room Revenue</div> -->
                                <div class="scrollo">

                                    <div class="flex heads space-around ">
                                        <div>Earnings From Rooms</div>
                                        <div>{{currency_format}} {{earningFromRooms | number : '1.2-2'}}</div>
                                    </div>
                                    <div class="flex heads space-around">
                                        <div> Cancellation Charges</div>
                                        <div>00.00</div>
                                    </div>

                                    <!-- <div class="flex heads space-around">
                                        <div>F & B Revenue</div>
                                        <div>{{currency_format}} {{fnbRevenue | number : '1.2-2'}}</div>
                                    </div> -->

                                    <div class="flex heads space-around">
                                        <div> Mini Bar</div>
                                        <div>00.00</div>
                                    </div>
                                </div>
                                <!-----------scrolling div------>
                            </div>
                        </div>
                    </summary>

                </details>

            </details>
            <div class="flex text space-between red-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'red-bar'">
                <div>Total</div>
                <div>{{currency_format}} {{earningFromRooms | number : '1.2-2'}}</div>
            </div>
        </div>

        <div class="col-md-12 RM-col-2">
            <details class="">
                <summary class="show2" (click)="changeArrow2()">
                    F & B Revenue
                    <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" id="dropDown2"
                        [ngClass]="arrowUpDown2 ? 'rotat' : 'drp'">
                </summary>
                <details>
                    <summary class="showDanger null">
                        <div class="col-md-12 RM-col-1">
                            <div class="FBrevenue card" [ngClass]="darkMode ? 'card_dark' : ''">

                                <div class="scrollo0">
                                    <div class="flex heads space-around" *ngFor="let item of shopBreakDown">
                                        <div>{{item.shop_name}}</div>
                                        <div>{{currency_format}} {{item.amount | number : '1.2-2'}}</div>
                                    </div>
                                    <!-- <div class="flex heads space-around">
                                        <div>Cigar Lounge</div>
                                        <div>00.00</div>
                                    </div>
                                    <div class="flex heads space-around">
                                        <div>Bakery</div>
                                        <div>00.00</div>
                                    </div>

                                    <div class="flex heads space-around">
                                        <div>Pizza Parlour</div>
                                        <div>00.00</div>
                                    </div> -->
                                </div>

                            </div>
                        </div>
                    </summary>
                </details>
            </details>
            <div class="flex text space-between red-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'red-bar'">
                <div>Total</div>
                <div>{{currency_format}} {{fnbRevenue | number : '1.2-2'}}</div>
            </div>
        </div>
        <div class="flex text space-between Total-bar">
            <div> Total Revenue</div>
            <div>{{currency_format}} {{ calculateTotal() | number : '1.2-2' }}</div>
        </div>
    </div>