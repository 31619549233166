<!-- <p>privacy-policy works!</p> -->


<div class="container containerr" id="privacypolicy" [ngClass]="darkMode ? 'container_darkk' : 'containerr'">
    <div class="text-block-12 head-2 color" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
            [ngClass]="darkMode ? 'privacy_p':'null'">P</span>rivacy <span
            [ngClass]="darkMode ? 'privacy2_p':'null'">P</span>olicy
    </div>
    <div class="w-container">
        <div class="policy_content">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy1" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    1. Purpose
                    <img id="privacy1" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy1')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy1"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    The purpose of this Privacy Policy is to lay out the terms and conditions governing use of
                    information and data belonging to Platform users and the Customer. This Privacy Policy constitutes
                    an annex to and an integral part of the Platform Terms & Conditions
                    signed with the Properties.
                </p>
            </div>
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy2" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    2. Disclosure of Information
                    <img id="privacy2" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy2')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy2"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Except for the purposes and within the scope as agreed in the Platform Terms & Conditions signed
                    with the Property, Point Click Integrate shall not disclose to any third party or use without
                    consent of the Property any information which Point Click Integrate
                    receives through the Platform electronically (including, name-surname, company, phone number or
                    e-mail address details, any personally-identifiable information about the Property, user, the
                    Customer, invoice or payment
                    option details). Accordingly, Point Click Integrate undertakes to keep confidential information
                    strictly private and confidential, consider this requirement a secret-keeping obligation, ensure and
                    sustain confidentiality
                    of such information, and take any measure and show diligence as required to prevent disclosure to
                    public, unauthorized use, or disclosure to any third party, of whole or part of the confidential
                    information.
                </p>

                <p class="indent bullet-points">
                    In addition to the circumstances as set forth in the Platform Terms & Conditions, such personal
                    information may also be used to contact the Property, User or Customer and improve Platform
                    experience of the Property (such as improving existing services,
                    develop new services or offer tailored services) as well as performing various statistical
                    assessments, building databases and conducting market research without compromising identifiable
                    information of the User and the
                    Customer. Such information may also be processed, retained, or relayed to third parties for the
                    purposes of direct marketing by Point Click Integrate (or any person collaborating with Point Click
                    Integrate, subject to the
                    Platform’s approval), and may be used to contact the User to promote applications and services, and
                    deliver notices about maintenance and support activities.
                </p>
            </div>
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy3" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    3. Property’s Transactions
                    <img id="privacy3" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy3')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy3"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Point Click Integrate may turn details of the Property’s transactions on the Platform into anonymous
                    data, and retain, process, or relay to its partners, such data for such period as required to use
                    them in statistical assessments, performance reviews,
                    marketing campaigns by Point Click Integrate and its business partners, annual and similar reports
                </p>
            </div>


            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy4" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    4. Disclosure of Third Party
                    <img id="privacy4" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy4')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy4"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Point Click Integrate shall also be entitled to disclose the Property information to third parties
                    in cases where
                </p>

                <div class="cardcolorr">
                    <div class="headd">
                        <p class="indent sub-bullet-points">
                            Disclosure is mandatory for performance by Point Click Integrate of its obligations under
                            the Platform Terms & Conditions,
                        </p>

                        <p class="indent sub-bullet-points">
                            User related information is demanded by a competent or judicial authority as part of an
                            investigation or probe duly executed under the relevant regulations,
                        </p>
                        <p class="indent sub-bullet-points">
                            Relay of information is required to protect or assure the security of the Property’s rights.
                        </p>
                    </div>
                </div>
            </div>

            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy5" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    5. System Related Issues
                    <img id="privacy5" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy5')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy5"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Point Click Integrate identifies and uses user IP addresses if needed to identify system-related
                    issues and swiftly resolve any potential issue with the Platform. IP addresses may also be used to
                    draw an overall user profile and gather comprehensive demographic
                    data.
                </p>
            </div>
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy6" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    6. Transfer of Data
                    <img id="privacy6" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy6')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy6"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Point Click Integrate is entitled to transfer any data related to the above-mentioned purposes to
                    its servers which are located in any country of the world except the one the Property is resident
                    (such servers may be owned by Point Click Integrate, its
                    affiliates or subcontractors)
                </p>
            </div>


            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy7" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    7. Website & Application
                    <img id="privacy7" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy7')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy7"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    The Platform may contain links to other website and applications. Point Click Integrate is not
                    liable in any way whatsoever for privacy policies or contents thereof.</p>
            </div>

            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy8" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    8. Amendments
                    <img id="privacy8" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy8')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy8"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    Point Click Integrate may amend from time to time this Privacy Policy through publication on the
                    Platform. Any amendment to the provisions of this Privacy Policy by Point Click Integrate shall be
                    effective on the date of publication on the Platform.</p>
            </div>

            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapsePrivacy9" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abcc'" aria-expanded="false" (click)="chngedrop('col_4')">
                    9. Compliance
                    <img id="privacy9" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_downn'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('privacy9')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapsePrivacy9"
                [ngClass]="darkMode ? 'text_dark' : 'textt'">
                <p class="indent bullet-points">
                    The Property agrees, represents and undertakes to ensure that any agreement to which it is a party
                    will comply with this Privacy Policy, and to obtain such consents from the Customers as may be
                    required by this Privacy Policy.
                </p>
            </div>


        </div>
    </div>
</div>