<form class="form-horizontal back-form" [formGroup]="create_package_form" (ngSubmit)="onSubmit()">
  <div class="" style="padding-right: 5px;" id="SP">
    <div class="alert alert-danger breadcrum mb-breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
      <a [routerLink]="setup_season" class="btn btn-link active"> <span class="abc">SETUP RATE DISTRIBUTION</span> </a>
    </div>
    <div>
      <div>
        <!-- Allocation Section -->
        <div class="no_scroll scroll">
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
                <div class="package-name2 margin-col-1 col-md-4"
                  style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;padding-left: 20px"
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                  Rate Distribution &nbsp;
                  <i class="fas fa-info-circle">
                    <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                      <p>In this section you will be able to set a certain price point on your allocation range of
                        inventory.</p>
                      <p style="margin-bottom: 0px;margin-top: -10px;">You can select between % and $ amount. Input $
                        amount or % will be added on the set rates.</p>
                    </span>
                  </i>
                </div>
                <div class="package-name2 margin-col-1 col-md-4 text-center" style="border-radius: 0px;"
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                  <span *ngIf="pdetails != undefined">{{pdetails.roomttype.name}} : {{pdetails.room.room_count}}</span>
                </div>
                <div class="package-name2 margin-col-1 col-md-4"
                  style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;padding-right: 20px"
                  [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
                  <div style="float: right">
                    <div class="" style="float: left;margin-right: 30px">
                      <!-- <input type="radio" [ngClass]="darkMode ? 'box':'box_light'" id="{{'test1'+i}}" name="radio-group" (click)="selectAllocationPrice(i, '$')" [checked]="create_package_form.rate_type == '$'"> -->
                      <label for="{{'test1'+i}}" id="paid">$</label>
                    </div>
                    <div class="" style="float: left">
                      <!-- <input type="radio" [ngClass]="darkMode ? 'box':'box_light'" id="{{'test2'+i}}" name="radio-group" (click)="selectAllocationPrice(i, '%')" [checked]="create_package_form.rate_type == '%'"> -->
                      <label for="{{'test2'+i}}" id="comp">%</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
                  <!-- Allocation 1st row -->
                  <div class="row " style="padding: 0px 15px ">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px;">
                      <div class="border-style color-4 bc1">
                        <input type="text" class="input form-control" placeholder="Start | High Rate"
                          style="outline: none;" formControlName="allocation_high_1"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px;">
                      <div class="border-style color-4 bc2">
                        <input type="text" class="input form-control" placeholder="Close | High Rate"
                          style="outline: none;" formControlName="allocation_high_2"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px;">
                      <div class="border-style color-4 bc3">
                        <input type="text" class="input form-control" placeholder="Rate (- | +)" style="outline: none;"
                          formControlName="allocation_high_rate"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                  </div>
                  <!-- Allocation 2nd row -->
                  <div class="row mt-10" style="padding: 0px 15px ">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px;">
                      <div class="border-style color-4 bc4">
                        <input type="text" class="input form-control" placeholder="Start | Medium Rate"
                          style="outline: none;" formControlName="allocation_medium_1"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px;">
                      <div class="border-style color-4 bc5">
                        <input type="text" class="input form-control" placeholder="Close | Medium Rate"
                          style="outline: none;" formControlName="allocation_medium_2"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px;">
                      <div class="border-style color-4 bc6">
                        <input type="text" class="input form-control" placeholder="Rate (- | +)" style="outline: none;"
                          formControlName="allocation_medium_rate"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                  </div>
                  <!-- Allocation 3rd row -->
                  <div class="row mt-10" style="padding: 0px 15px ">
                    <div class="col-md-4" style="padding: 0px 3px 0px 5px;">
                      <div class="border-style color-4 bc7">
                        <input type="text" class="input form-control" placeholder="Start | Low Rate"
                          style="outline: none;" formControlName="allocation_low_1"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 3px 0px 2px;">
                      <div class="border-style color-4 bc8">
                        <input type="text" class="input form-control" placeholder="Close | Low Rate"
                          style="outline: none;" formControlName="allocation_low_2"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                    <div class="col-md-4" style="padding: 0px 5px 0px 2px;">
                      <div class="border-style color-4 bc9">
                        <input type="text" class="input form-control" placeholder="Rate (- | +)" style="outline: none;"
                          formControlName="allocation_low_rate"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Allocation Section END-->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-right" id="ModalFooterP" style="    bottom: -50px;right: 10px!important">
    <button class="btn btn-info btn-lg btn-hov " [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">Save</button>
    <button class="btn btn-danger btn-lg btn-hov" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '"
      aria-label="Close" data-dismiss="modal" type="button"
      [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">Cancel</button>
  </div>
</form>