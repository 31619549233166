<!-- TOP Bar Filter -->
<div class="filters_row"
  [ngClass]="darkMode && !showCalendar ? 'darkOn posAb' : !darkMode && !showCalendar ? 'null posAb' : darkMode ? 'darkOn' : 'null'">
  <div class="row">
    <div class="column-wrapper display-flex space-between">
      <!-- <div class="col-md-4" style="width: 30.7%;">
                <div class="flex-grid">
                    <button type="button" class="btn btn-primary next-week-button" (click)="week_move('prev')">
                        <i class="fa fa-arrow-left"></i>
                    </button>
                    <div class="inventory-calendar">
                        <span class="pointclick_date left_input_date form-control date-first" style="border: none;">
                            <input type="text" ngxDaterangepickerMd class="form-control date_range_picker " (change)="calendar_change($event)" placeholder="Date" [(ngModel)]="calendar_date" [locale]="{format: 'DD MMMM', separator: '            To            '}" style="display: inline-block; padding: 10px; height:40px; text-align: center;font-size: 14px;" [dateLimit]="7">
                        </span>
                    </div>
                    <button type="button" class="btn btn-primary prev-week-button" (click)="week_move('next')">
                        <i class="fa fa-arrow-right"></i>
                    </button>
                </div>
            </div>
            <div class="col-md-7 flx-col-5">
                <div class="">
                    <button type="button" data-target="#bulk_update" data-toggle="modal" class="form-control bulk-update" name="" style="border-radius: 8px;float:right" (click)="fliBox()" >+ Reservation</button>
                </div>
            </div> -->
      <div>
        <ul class="nav navbar-nav tabsBar">
          <li class="active">
            <a class="btn btn-link active">STAYS</a>
          </li>
          <li class="hover">
            <a class="btn btn-link active" routerLink="/app/inventory">PROMO CALENDER</a>
          </li>
          <li class="hover">
            <a class="btn btn-link active" routerLink="/app/communication">GUEST LOOK UP</a>
          </li>
        </ul>
      </div>
      <div class="display-flex dfTopCal" *ngIf="showCalendar">
        <div class="flex-grid " style="width: 90%;margin: 5px 5px 5px 0px; justify-content: end;">
          <button type="button" class="btn btn-primary next-week-button" (click)="week_move('prev')">
            <i class="fa fa-arrow-left"></i>
          </button>
          <div class="inventory-calendar">
            <span class="pointclick_date left_input_date form-control date-first" style="border: none;">
              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker "
                (change)="calendar_change($event)" placeholder="Date" [(ngModel)]="calendar_date"
                [locale]="{format: 'DD  MMM  YYYY', separator: '            To            '}"
                style="display: inline-block; padding: 10px; height:40px; text-align: center;font-size: 14px;"
                [dateLimit]="7">
            </span>
          </div>
          <button type="button" class="btn btn-primary prev-week-button" (click)="week_move('next')">
            <i class="fa fa-arrow-right"></i>
          </button>
          <img class="go_icon btn-hov_popup" [ngClass]="darkMode ? 'btn-hov_dark': 'btn-hov_popup'"
            src="assets/img/other/go.png" (click)="trigger_click()" />
        </div>
        <div class="">

          <button type="button" data-target="#bulk_update" data-toggle="modal" class="form-control bulk-update" name=""
            style="border-radius: 8px;float:right;margin: 5px 5px 0px 0px;"
            (click)="fliBox();RoomIconFilter(true,false);roomFilter = true; ResetCheckInCheckoutDates()" id="fliboxw">+
            Reservation</button>
        </div>
      </div>
      <div *ngIf="!showCalendar">

      </div>
    </div>
  </div>
</div>
<!-- TOP Bar Filter END-->

<!-- Boxes -->

<!-- Content Load -->
<ng-template #skeleton>
  <div [ngClass]="darkMode ? 'darkOn' : 'null'">
    <table class="table">
      <thead>
        <tr>
          <th>
            <div class="basic_block inventory_grid mb-0">
              <div class="inventory_wrapper">
                <h4 class="inventory_text">ROOM&nbsp;TYPE</h4>
              </div>
              <h4 class="room_type_text">AVAILABILITY</h4>
            </div>
          </th>

          <th *ngFor="let range of getEmptyElements(8); let i = index">
            <div class="basic_block days mb-0">
              <h3 class="days_month">{{ today | date:'MMMM' }}</h3>
              <h3 class="day_number">{{ today | date:'dd' }}</h3>
              <h3 class="days_day">{{ today | date:'EEEE' }}</h3>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="drag_item">
          <td>
            <div class="type"
              [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}">
              <div class="type-text" style="flex: 1;justify-content: space-between;">
                <span>Room Type</span>
                <img src="assets/img/reports/button_down.svg" style="width: 15px;" />
              </div>
            </div>
          </td>
          <td *ngFor="let range of getEmptyElements(8)">
            <div class="room_type"
              [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}">
              <img src="assets/img/inventory/available.png" alt="" class="image-113">
              <h4 class="availability"> 0 </h4>
              <h4 class="available_text">Available</h4>
            </div>
          </td>
        </tr>
      </tbody>
      <ng-container>
        <tr *ngFor="let range of getEmptyElements(6)">
          <td>
            <div class="box" style="background: linear-gradient(to right, #f98282ba 3%, #f98282 15%);">
              <div class="box_left">&nbsp;</div>
              <div class="box_right">&nbsp;</div>
              <hr>
              <div class="box_lines">
                <div class="upper"></div><br>
                <div class="lower"></div>
              </div>
              <hr>
              <div class="box_bar"></div>
            </div>
          </td>
          <td *ngFor="let range of getEmptyElements(8)">
            <div class="box">
              <div class="box_left">&nbsp;</div>
              <div class="box_right">&nbsp;</div>
              <hr>
              <div class="box_lines">
                <div class="upper"></div><br>
                <div class="lower"></div>
              </div>
              <hr>
              <div class="box_bar"></div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-template>
<!-- Content Load  End -->

<div (scroll)="onTableScroll($event)" id="scroll_wrapper" class="scroll" [ngClass]="darkMode ? 'darkOn' : 'null'">
  <!-- Real Content  -->
  <div class="" *ngIf="inventory_calendar && inventory_calendar.length != 0; else skeleton">
    <div class="grid_calendar capture">
      <table class="table">
        <thead>
          <tr>
            <th>
              <div class="basic_block inventory_grid">
                <div class="inventory_wrapper">
                  <h4 class="inventory_text">ROOM&nbsp;TYPE</h4>
                </div>
                <h4 class="room_type_text">AVAILABILITY</h4>
              </div>
            </th>

            <th *ngFor="let range of calendar_range; let i = index">
              <div class="basic_block days"
                *ngIf="range.isBetween(current_view.startDate, current_view.endDate, null , '[]')">
                <h3 class="days_month">{{ range.format('MMMM') }}</h3>
                <h3 class="day_number">{{ range.format('DD') }}</h3>
                <h3 class="days_day">{{ range.format('dddd') }}</h3>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let inv of inventory_calendar;trackBy: trackByInv; let i = index">
          <tr class="drag_item">
            <td (click)="selectShow(i); scrollNow();">
              <div class="type"
                [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}">
                <div class="type-text" style="flex: 1;justify-content: space-between;">
                  <span style="width: 149px;display: block;overflow: hidden; text-overflow: ellipsis; height: 22px;white-space: nowrap;
                                    overflow: hidden; text-overflow: ellipsis;text-transform: capitalize;">{{
                    inv[0]?.roomtype }}</span>
                  <img src="assets/img/reports/button_down.svg" style="width: 15px;" />
                </div>
              </div>
            </td>
            <td *ngFor="let range of calendar_range">
              <div class="room_type"
                [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}"
                *ngIf="range.isBetween(current_view.startDate, current_view.endDate, null , '[]')">
                <img src="assets/img/inventory/available.png" alt="" class="image-113">
                <h4 class="availability">
                  <!-- {{ count_availability(range.format('YYYY-MM-DD'), inv) | number: '2.0-0' }} -->
                  {{invCount(inv[0]?.inventory_counts, range.format("YYYY-MM-DD"))}}
                  <!-- ava -->
                </h4>
                <h4 class="available_text">Available</h4>
              </div>
            </td>
          </tr>

          <!-- package boxes -->
          <ng-container *ngIf="this.calendarDropDowns[i] == true">
            <tr class="drag_item" *ngFor="let ven of inv;trackBy: trackByVen">
              <td>
                <div class="package w-clearfix" [ngStyle]="{'background-color': colors[i%colors.length]}">
                  <!-- <div class="package w-clearfix" id="{{ven.room.images[0].image}}" [ngStyle]="{ 'background-size': 'cover', 'background-color': 'black', 'background-image': 'url('+ api.imgUrl + ven.room.images[0].image + ')'}"> -->
                  <div class="RN">
                    <span class="rm-font">&nbsp;</span>
                    <div class="restriction_panel bed-type-text">
                      <div> <img src="assets/img/inventory/bed-black.svg" style="width: 17px; margin-bottom: 5px;">
                        <span style="padding-left:5px;">{{ven.bed_type}}</span>
                      </div>
                      <!-- <img src="assets/img/back-office/{{ven?.wheelchairaccessibility == 'false' ? 'wheelno' : 'wheelyes'}}.svg" class="restriction-padding fl-r" style="width: 25px;"/>
                                            <img src="assets/img/back-office/{{ven?.pets == 'false' ? 'dogno' : 'dogyes'}}.svg" class="restriction-padding fl-r" style="width: 25px;"/>
                                            <img src="assets/img/back-office/{{ven?.smoking == 'false' ? 'cigno' : 'cigyes'}}.svg" class="restriction-padding fl-r" style="width: 25px;"/> -->
                      <div style="margin-bottom: 5px;">
                        <img src="assets/img/newstaticicons/inventory/allowwheel.svg" alt="allaowdog"
                          class="restriction-padding fl-r" style="width: 17px;"
                          *ngIf="ven?.wheelchairaccessibility == 'true'">
                        <img src="assets/img/newstaticicons/inventory/allaowdog.svg" alt="allaowdog"
                          class="restriction-padding fl-r" style="width: 25px;" *ngIf="ven?.pets == 'true'">
                        <img src="assets/img/newstaticicons/inventory/allowsmoke.svg" alt="allaowdog"
                          class="restriction-padding fl-r" style="width: 25px;" *ngIf="ven?.smoking == 'true'">
                      </div>
                    </div>
                  </div>
                  <div class="div-block-5 w-clearfix">
                    <!-- <h4 class="package_title_main package-styling">
                                              {{ven?.roomtype}}
                                              RV:  {{ven?.Roomview}}


                                          </h4> -->
                    <h4 class="package_title_main package-styling">
                      <!-- {{ven?.roomtype}} -->
                      {{ven?.room_number}}
                    </h4>
                  </div>
                  <h4 class="package_room_code over-ride-wit">
                    <!-- <span class="rm-font">FL {{ven?.floor_no}}</span> -->
                    <span class="rm-font"><img src="assets/img/newstaticicons/inventory/view-room.svg" style="width: 17px;"> {{ven?.Roomview}}</span>
                    <!-- <span class="rm-font">{{ven?.building}}</span> -->
                  </h4>
                </div>
              </td>

              <td class="" *ngFor="let range of calendar_range;trackBy: trackByBox.bind(ven.id)">
                <div *ngIf="range.isBetween(current_view.startDate, current_view.endDate, null , '[]'); else blankBox">
                  <div
                    *ngIf="is_box_shown(ven?.boxes, range) != false && is_box_shown(ven?.boxes, range) as box; else placeBox">

                    <div *ngIf="box?.maintenance == false" class="box_wrapper">
                      <!-- <input class="input-class package_box" name="{{ box.id }}" type="checkbox" value="{{ box.date }}" (click)="check_pkg(box.id, box.date, $event)" [checked]="is_pkg_checked(box.date, box.id)" [disabled]="is_pkg_disabled(box.room_type_id)" /> -->
                      <div class="package_details"
                        [ngStyle]="{'background': box?.status == 'Booked' ? '#25bb43' : '#21b9e1'}"
                        *ngIf="box?.status == 'Available'">
                        <h4 class="package_price">
                          <span *ngIf="box?.status == 'Available'"
                            [ngStyle]="{'color': box?.color == true ? 'red' : '#000'}"> {{
                            currency_format}} {{box?.rate}}</span>
                          <span *ngIf="box?.status == 'Booked'">
                            <img src="assets/img/svgicons/smallicons/{{ box?.bookingInfo?.channel_image }}"
                              style="width: 25px;" />
                          </span>
                        </h4>

                        <div class="restriction_panel restrict">
                          <img #restriction_field src="assets/img/newstaticicons/inventory/retriction.svg"
                            class="restriction-padding fl-r" id="restrictionId" data-target="#ReModal"
                            data-toggle="modal" width="20px" #input (click)="
                              open_restriction_box2(box, box.date, ven)" style="margin-bottom: 2px" />
                        </div>
                        <div class="title_container w-clearfix">
                          <a class="package_price my_title">
                            <span *ngIf="box?.status == 'Booked'" data-target="#showBulkUpdate" data-toggle="modal"
                              (click)="fliBox();getBookingDetails(box?.bookingInfo);getRandomColor()">{{box?.bookingInfo?.guest_firstname}}
                            </span>
                            <span *ngIf="box?.status == 'Available'">{{box?.status}} </span>
                          </a>
                        </div>

                        <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden;"
                          *ngIf="box?.status == 'Available'">
                          <a class="menu-text">
                            <img *ngFor="let ota of box.otas" src="assets/img/svgicons/smallicons/{{ota.image}}"
                              id="channel-menu" data-toggle="dropdown" alt="" class="ota"
                              (click)="open_channel_box(box.otas)">
                          </a>
                          <a class="menu-text">
                            <img *ngIf="box.otas.length > 6"
                              style="position: absolute;bottom: 2px;right: 10px;height: 25px;width: 16px;"
                              src="assets/img/manage_rates/dots.png" class="ota" (click)="open_channel_box(box.otas)">
                          </a>
                          <p style="margin-top: 4px; font-weight: bold; cursor: pointer;" *ngIf="box.otas.length == 0"
                            data-toggle="dropdown" (click)="open_channel_box(box.otas)">No Channel Connected</p>
                        </div>

                        <h4 class="otas_connected override-bottom" *ngIf="box?.status == 'Booked'">
                          <img src="assets/img/inventory/person.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_adult}}</span>
                          <img src="assets/img/inventory/twoperson.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_child}}</span>
                          <img src="assets/img/inventory/baby.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_infants}}</span>
                          <span
                            style="float:right;font-weight: bold;font-size: 15px;padding: 3px 0px 0px 3px;">{{currency_format}}
                            {{box?.rate}}</span>
                        </h4>
                      </div>

                      <div class="package_details"
                        [ngStyle]="{'background': box?.status == 'Booked' ? '#25bb43' : '#21b9e1'}"
                        *ngIf="box?.status == 'Booked'">
                        <h4 class="package_price">
                          <span *ngIf="box?.status == 'Available'">{{currency_format}}
                            {{box?.rate}}</span>
                          <span *ngIf="box?.status == 'Booked'">
                            <img src="assets/img/svgicons/smallicons/{{ box?.bookingInfo?.channel_image }}"
                              style="width: 25px;" />
                          </span>
                        </h4>

                        <div class="title_container w-clearfix">
                          <a class="package_price my_title">
                            <span *ngIf="box?.status == 'Booked'" data-target="#showBulkUpdate" data-toggle="modal"
                              (click)="fliBox();getBookingDetails(box?.bookingInfo);getRandomColor()">{{box?.bookingInfo?.guest_firstname}}
                            </span>
                            <span *ngIf="box?.status == 'Available'">{{box?.status}} </span>
                          </a>
                        </div>

                        <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden;"
                          *ngIf="box?.status == 'Available'">
                          <a class="menu-text">
                            <img *ngFor="let ota of box.otas" src="assets/img/svgicons/smallicons/{{ota.image}}"
                              id="channel-menu" data-toggle="dropdown" alt="" class="ota"
                              (click)="open_channel_box(box.otas)">
                          </a>
                          <a class="menu-text">
                            <img *ngIf="box.otas.length > 6"
                              style="position: absolute;bottom: 2px;right: 10px;height: 25px;width: 16px;"
                              src="assets/img/manage_rates/dots.png" class="ota" (click)="open_channel_box(box.otas)">
                          </a>
                          <p style="margin-top: 4px; font-weight: bold; cursor: pointer;" *ngIf="box.otas.length == 0"
                            data-toggle="dropdown" (click)="open_channel_box(box.otas)">No Channel Connected</p>
                        </div>

                        <h4 class="otas_connected override-bottom" *ngIf="box?.status == 'Booked'">
                          <img src="assets/img/inventory/person.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_adult}}</span>
                          <img src="assets/img/inventory/twoperson.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_child}}</span>
                          <img src="assets/img/inventory/baby.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_infants}}</span>
                          <span class="curr"
                            style="float:right;font-weight: bold;font-size: 15px;padding: 3px 0px 0px 3px;">{{currency_format}}
                            {{box?.rate}}</span>
                        </h4>
                      </div>

                      <div class="package_details"
                        [ngStyle]="{'background': box?.status == 'hold' ? 'orange' : '#21b9e1'}"
                        *ngIf="box?.status == 'hold'">
                        <h4 class="package_price">
                          <!-- <span *ngIf="box?.status == 'Available'">$ {{box?.rate}}</span> -->
                          <span *ngIf="box?.status == 'hold'">
                            <img src="assets/img/svgicons/smallicons/{{ box?.bookingInfo?.channel_image }}"
                              style="width: 25px;" />
                          </span>
                        </h4>

                        <div class="title_container w-clearfix">
                          <a class="package_price my_title">
                            <span *ngIf="box?.status == 'hold'" data-target="#showBulkUpdate" data-toggle="modal"
                              (click)="fliBox();getBookingDetails(box?.bookingInfo);getRandomColor()">{{box?.bookingInfo?.guest_firstname}}
                            </span>
                            <span *ngIf="box?.status == 'Hold'">{{box?.status}} </span>
                          </a>
                        </div>

                        <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden;"
                          *ngIf="box?.status == 'Available'">
                          <a class="menu-text">
                            <img *ngFor="let ota of box.otas" src="assets/img/svgicons/smallicons/{{ota.image}}"
                              id="channel-menu" data-toggle="dropdown" alt="" class="ota"
                              (click)="open_channel_box(box.otas)">
                          </a>
                          <a class="menu-text">
                            <img *ngIf="box.otas.length > 6"
                              style="position: absolute;bottom: 2px;right: 10px;height: 25px;width: 16px;"
                              src="assets/img/manage_rates/dots.png" class="ota" (click)="open_channel_box(box.otas)">
                          </a>
                          <p style="margin-top: 4px; font-weight: bold; cursor: pointer;" *ngIf="box.otas.length == 0"
                            data-toggle="dropdown" (click)="open_channel_box(box.otas)">No Channel Connected</p>
                        </div>

                        <h4 class="otas_connected override-bottom" *ngIf="box?.status == 'hold'">
                          <img src="assets/img/inventory/person.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_adult}}</span>
                          <img src="assets/img/inventory/twoperson.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_child}}</span>
                          <img src="assets/img/inventory/baby.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_infants}}</span>
                          <span
                            style="float:right;font-weight: bold;font-size: 15px;padding: 3px 0px 0px 3px;">{{currency_format}}
                            {{box?.rate}}</span>
                        </h4>
                      </div>
                      <div class="package_details"
                        [ngStyle]="{'background': box?.status == 'modified' ? 'rgb(228 91 0)' : '#21b9e1'}"
                        *ngIf="box?.status == 'modified'">
                        <h4 class="package_price">
                          <!-- <span *ngIf="box?.status == 'Available'">$ {{box?.rate}}</span> -->
                          <span *ngIf="box?.status == 'modified'">
                            <img src="assets/img/svgicons/smallicons/{{ box?.bookingInfo?.channel_image }}"
                              style="width: 25px;" />
                          </span>
                        </h4>

                        <div class="title_container w-clearfix">
                          <a class="package_price my_title">
                            <span *ngIf="box?.status == 'modified'" data-target="#showBulkUpdate" data-toggle="modal"
                              (click)="fliBox();getBookingDetails(box?.bookingInfo);getRandomColor()">{{box?.bookingInfo?.guest_firstname}}
                            </span>
                            <!-- <span *ngIf="box?.status == 'modified'">{{box?.status}} </span> -->
                          </a>
                        </div>

                        <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden;"
                          *ngIf="box?.status == 'Available'">
                          <a class="menu-text">
                            <img *ngFor="let ota of box.otas" src="assets/img/svgicons/smallicons/{{ota.image}}"
                              id="channel-menu" data-toggle="dropdown" alt="" class="ota"
                              (click)="open_channel_box(box.otas)">
                          </a>
                          <a class="menu-text">
                            <img *ngIf="box.otas.length > 6"
                              style="position: absolute;bottom: 2px;right: 10px;height: 25px;width: 16px;"
                              src="assets/img/manage_rates/dots.png" class="ota" (click)="open_channel_box(box.otas)">
                          </a>
                          <p style="margin-top: 4px; font-weight: bold; cursor: pointer;" *ngIf="box.otas.length == 0"
                            data-toggle="dropdown" (click)="open_channel_box(box.otas)">No Channel Connected</p>
                        </div>

                        <h4 class="otas_connected override-bottom" *ngIf="box?.status == 'modified'">
                          <img src="assets/img/inventory/person.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_adult}}</span>
                          <img src="assets/img/inventory/twoperson.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_child}}</span>
                          <img src="assets/img/inventory/baby.png" alt="" style="margin-right: 5px;">
                          <span class="num_person">{{box?.bookingInfo?.count_infants}}</span>
                          <span
                            style="float:right;font-weight: bold;font-size: 15px;padding: 3px 0px 0px 3px;">{{currency_format}}
                            {{box?.rate}}</span>
                        </h4>
                      </div>
                    </div>

                    <!-- Close For Box -->

                    <div *ngIf="box?.maintenance == true" class="box_wrapper">
                      <div class="package_details width-adj w-clearfix"
                        [ngClass]="darkMode? 'restrictedDark':'restricted'">
                        <h4 class="restricted_text_reason">Closed for</h4>
                        <img #restriction_field src="assets/img/inventory/restriction.png"
                          class="restriction-padding fl-r restricted_closed_icon" data-toggle="dropdown" width="20px" />
                        <div class="restricted_container w-clearfix">
                          <ul class="nav pull-left">
                            <li>
                              <h4 class="restricted_text_type">Maintenence</h4>
                            </li>
                            <!-- <li>
                                                            <h4 *ngIf="box.restrictions.indexOf('ctd') != -1" class="restricted_text_type">Departure <br></h4>
                                                            <h4 *ngIf="box.restrictions.ctd == true" class="restricted_text_type">Departure <br></h4>
                                                        </li> -->
                          </ul>
                          <img src="assets/img/inventory/maintainance-for-close.svg" alt="" class="image-114">
                        </div>
                      </div>
                    </div>

                    <!-- Close For Box END-->


                  </div>
                  <ng-template #placeBox>
                    <div class="box">
                      <div class="box_left">&nbsp;</div>
                      <div class="box_right">&nbsp;</div>
                      <hr>
                      <div class="box_lines">
                        <div class="upper"></div><br>
                        <div class="lower"></div>
                      </div>
                      <hr>
                      <div class="box_bar"></div>
                    </div>
                  </ng-template>
                </div>
                <ng-template #blankBox>
                  <div class="blankBox">&nbsp;</div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>

      </table>
    </div>
  </div>
  <!-- Real Content ENd  -->
</div>
<!-- Boxes END-->

<!-- Reservation Pupop -->
<div id="bulk_update" class="modal fade bulk_updatemodal" role="dialog" style="width:100%;"
  [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
  <form [formGroup]="reservation_form" *ngIf="reservation_form">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="bluheader booking-sumary" (mouseover)="dodrag('bulk_update')"
            (mouseout)="dontdrag('bulk_update')">
            <h3 class="h3_blu_header bookingsummary">Reservation
            </h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              style="color: white;line-height: 34px" (click)="resetForm(reservation_form)">
              <strong id="cross">&#x2716;</strong>
            </button>
          </div>
        </div>
        <div class="modal-body dFlex">
          <div class="leftD">
            <div class="col-md-6 zero-padding float-L">
              <div class="col-md-12 zero-padding" style="padding-right: 2px;">
                <div class="bluheader booking-sumary redbar" style="display: flex;justify-content: space-between;"
                  (mouseover)="dodrag('bulk_update')" (mouseout)="dontdrag('bulk_update')">
                  <h3 class="h3_blu_header bookingsummary">Stays
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width:48%" [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                        {{toolChkData}}
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" class="applyAll2 chk" (change)="getValA($event)" [(ngModel)]="seperA"
                    [ngModelOptions]="{standalone: true}" id="checkedForSep">
                </div>
              </div>
              <div class="col-md-12  zero-padding col-12-0ne onee sty"
                style="padding-right: 0px;height: 250px; overflow: inherit;">
                <!-- <label class="label-mr"> Check In </label> -->
                <div class="greyBox">
                  <div class="modal-bar-2 one">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px;border: none;padding-left: 0px">
                      <span class="input-group-addon"
                        style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                        <!-- <i class="fa fa-calendar-alt"></i> -->
                        <img src="assets/img/newstaticicons/dashboard/check-in.svg" style="height: 24px;" lt="">
                      </span>
                      <input type="hidden" name="date_to">
                      <input type="hidden" name="date_from">
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check In"
                        [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                        [(ngModel)]="date_arrival" [minDate]="minDate" [maxDate]="maxDate"
                        formControlName="date_arrival" (change)="getnigths(date_arrival,date_departure)">
                    </div>
                  </div>
                  <div *ngIf="submitted && f.date_arrival.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.date_arrival.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 two">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px;border: none;padding-left: 0px">
                      <span class="input-group-addon"
                        style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                        <img
                          src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg':'dashboard/departure.svg'}}"
                          style="margin-left: 6px;    height: 24px;" alt="">
                      </span>
                      <input type="hidden" name="date_to">
                      <input type="hidden" name="date_from">
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check Out"
                        [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                        [(ngModel)]="date_departure" formControlName="date_departure" [minDate]="minDate_dep"
                        [maxDate]="maxDate" (change)="getnigths(date_arrival,date_departure)">
                    </div>
                  </div>
                  <!-- <div *ngIf="submitted && f.date_departure.errors" class="small text-danger no-margin invalid-feedback">
                                          <strong *ngIf="f.date_departure.errors.required">Required</strong>
                                      </div> -->
                  <div class="modal-bar-2 three timePickerEta">
                    <img src="assets/img/newstaticicons/inventory/ETA-clock.svg" alt="" class="placeholder-img-modal">
                    <input type="time" class="form-control "
                      style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                      placeholder="ETA" formControlName="eta">
                  </div>
                  <div *ngIf="submitted && f.eta.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.eta.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 four timePickerEtd">
                    <img src="assets/img/newstaticicons/inventory/ETD-clock.svg" alt="" class="placeholder-img-modal">
                    <input type="time" class="form-control "
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      placeholder="ETD" formControlName="etd">
                  </div>
                  <div *ngIf="submitted && f.etd.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.etd.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 fourteen">
                    <div class="my-icon-0 ovi-rid">

                      <img alt="" src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}"
                        *ngIf="showDayNight" style="height: 20px;margin-left: 2px;">

                      <img alt="" src="assets/img/newstaticicons/inventory/icons-sun.png" *ngIf="!showDayNight"
                        style="height: 20px; width:20px; margin-left: 2px;">

                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                      <input class="form-control" placeholder="Total Nights/Days" value=""
                        style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                        type="text" value="{{totalnights}}" readonly>

                    </div>
                  </div>
                  <div class="modal-bar-2 fourteenoo" style="    margin-bottom: 5px;">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="" src="assets/img/newstaticicons/inventory/return.svg"
                        style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                      <input class="form-control" placeholder="Total Nights" value=""
                        style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                        type="text" value="{{returnee}}" readonly>
                    </div>
                  </div>
                  <div class="modal-bar-2 dynamoColor" *ngIf="confirmation_yes == true">
                    <img src="assets/img/newstaticicons/arrivals/id-verified-light.svg" alt=""
                      class="placeholder-img-modal channel-img" style="margin-top: 4px;">
                    <input type="text" class="form-control" placeholder="Confirmation Id No"
                      formControlName="confirmation_number"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;"
                      maxlength="16">
                  </div>
                  <div class="modal-bar-2 twenty55" id="flip">
                    <div *ngFor="let update_weather of weather_update; let i = index">
                      <i class='wi wi-main wi-{{ update_weather.icon }}'
                        [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                      <div class="weatherDiv">
                        <span class="wh_name">{{update_weather.title}}</span>
                        <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                        <span class="wh_con">{{update_weather.condition}} </span>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div class="col-md-6 zero-padding float-R">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">
                <div class="bluheader booking-sumary redbar" (mouseover)="dodrag('bulk_update')"
                  (mouseout)="dontdrag('bulk_update')">
                  <h3 class="h3_blu_header bookingsummary">Payment</h3>
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-four onee pay"
                style="padding-left: 1px;height: 250px; overflow:inherit"
                [ngStyle]="{'overflow' : (submitted && f.channel_id.errors || submitted && f.payment_status.errors || submitted && f.status.errors) ? 'scroll' : 'inherit' }">
                <!--add scroll on increasing height---->
                <!-- <label class="label-mr"> Card Type </label> -->
                <div class="greyBox" style="margin-bottom: 3px;">
                  <div class="modal-bar-2 twenty1">
                    <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt="" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Cardholder Name"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      formControlName="payee_name">
                  </div>
                  <div *ngIf="submitted && f.payee_name.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.payee_name.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 twenty2">
                    <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt="" class="placeholder-img-modal">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder=" Card Type " style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                      formControlName="guest_card_type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                      <ng-option value="Discover">Discover</ng-option>
                      <ng-option value="AX">American Express</ng-option>
                      <ng-option value="CB">Carte Blanche</ng-option>
                      <ng-option value="DN">Diners Club</ng-option>
                      <ng-option value="DS">Discover Card</ng-option>
                      <ng-option value="JC">Japan Credit Bureau</ng-option>
                      <ng-option value="MC">MasterCard</ng-option>
                      <ng-option value="UP">China Union Pay</ng-option>
                      <ng-option value="VI">Visa</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.guest_card_type.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.guest_card_type.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 twenty3">
                    <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt="" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Card Number"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      formControlName="guest_card" maxlength="16" id="maskCard">
                  </div>
                  <div *ngIf="submitted && f.guest_card.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.guest_card.errors.required">Required</strong>
                    <strong *ngIf="f.guest_card.errors.pattern">Card no invalid</strong>
                  </div>

                  <!-- card input fields  -->

                  <!-- card input fields  -->

                  <!-- card input fiedls  -->

                  <div class="col-md-6 zero-padding float-L" style="padding-right: 2px">
                    <div class="modal-bar-2 twenty3" [ngStyle]="{'border-color': randomColors[20%randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal">
                      <input class="form-control" name=x size=5 maxlength=5
                        onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        formControlName="guest_card_expiry" placeholder="MM / YYYY">
                    </div>
                    <div
                      *ngIf="submittedUpodated && b.booking_items['controls'][i]['controls'].guest_card_expiry.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong
                        *ngIf="b.booking_items['controls'][i]['controls'].guest_card_expiry.errors.required">Required</strong>
                    </div>
                  </div>
                  <div class="col-md-6 zero-padding float-R" style="padding-left: 3px">
                    <div class="modal-bar-2 twenty3" [ngStyle]="{'border-color': randomColors[21%randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="CVV"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        pattern="/^\d+$/" onKeyPress="if(this.value.length==4) return false;"
                        formControlName="guest_card_series_code">
                    </div>
                    <div
                      *ngIf="submittedUpodated && b.booking_items['controls'][i]['controls'].guest_card_series_code.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong
                        *ngIf="b.booking_items['controls'][i]['controls'].guest_card_series_code.errors.required">Required</strong>
                    </div>
                  </div>

                  <!-- card input fiedls  -->

                  <div class="modal-bar-2 twenty4 uii">
                    <img src="assets/img/newstaticicons/inventory/channel.svg" alt=""
                      class="placeholder-img-modal channel-img">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Channel"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px" [items]="allChannels"
                      bindLabel="name" bindValue="id" formControlName="channel_id" (change)="getChannelId($event);">
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.channel_id.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.channel_id.errors.required">Required</strong>
                  </div>
                  <!-- <div class="modal-bar-2 twenty44 ">
                                        <img src="assets/img/newstaticicons/inventory/promo-code-small.svg" alt="" style="height: 24px;" class="placeholder-img-modal">
                                        <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Promocode" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                            style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px" [items]="allPromo" bindLabel="promo.promo_code" bindValue="promo_id" formControlName="promo" (change)="getPromo($event)" >
                                        </ng-select>
                                    </div> -->
                  <div class="modal-bar-2 eighteen uii">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Payment Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                      formControlName="payment_status" (change)="checkPaymentStatus($event)">
                      <ng-option value="paid">Paid</ng-option>
                      <ng-option value="unpaid">Due</ng-option>
                      <ng-option value="Deposite">Deposit</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.payment_status.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.payment_status.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 eighteen uii bk">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom input-c"
                      placeholder="Booking Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px" formControlName="status"
                      [items]="b_status" bindLabel="name" bindValue="value">
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.status.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="f.status.errors.required">Required</strong>
                  </div>
                  <!-- <div class="modal-bar-2 fourteenoo" style="margin-top: 5px;">
                                          <div class="my-icon-0 ovi-rid">
                                              <img alt=""
                                                  src="assets/img/newstaticicons/arrivals/{{darkMode ? 'coins' : 'coins-light'}}.svg"
                                                  style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                                          </div>
                                          <div class="text-block-18 text calander caljj"
                                              style="float: left;padding-top: 4px;width:90%">
                                              <input class="form-control" placeholder="Currency" value=""
                                                  style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                                                  type="text" formControlName="currency" readonly>
                                          </div>
                                      </div> -->
                </div>

                <!-- card input fiedls  -->
                <!-- <div class="modal-bar-2 fourteenoo" style="margin-top: 5px;">
                                        <div class="my-icon-0 ovi-rid">
                                            <img alt=""
                                                src="assets/img/newstaticicons/arrivals/{{darkMode ? 'coins' : 'coins-light'}}.svg"
                                                style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                                        </div>
                                        <div class="text-block-18 text calander caljj"
                                            style="float: left;padding-top: 4px;width:90%">
                                            <input class="form-control" placeholder="Currency" value=""
                                                style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                                                type="text" formControlName="currency" readonly>
                                        </div>
                                    </div> -->
              </div>
            </div>
            <div class="col-md-6 zero-padding float-L L4">
              <div class="col-md-12 zero-padding" style="padding-right: 2px">
                <div class="bluheader booking-sumary redbar" (mouseover)="dodrag('bulk_update')"
                  (mouseout)="dontdrag('bulk_update')" style="padding-right: 10px;    margin-top: 5px;">
                  <h3 style="display: inline-block;" class="h3_blu_header bookingsummary">Who's Checking In
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width:63% !important; top: -32px !important; "
                        [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">If the guest
                        checking in is not the same as payee: Guest will be required to give
                        credit card information for In hotel expenses
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" class="applyAll2" style="top: 27% ; right: 11% ;"
                    id="updateBookedByInfo-checkbox" (change)="updateBookedByInfo($event)">
                  <img title="Add Room" src="assets/img/communications/plus-4.png" alt="" class="img-plus"
                    (click)="addNewGuest(reservation_form.controls.booking_items, null)">
                </div>
              </div>
              <div class="scrollo">
                <div class="col-md-12 zero-padding col-12-two oneeee romm" formArrayName="booking_items"
                  [ngStyle]="{'padding-right':'2px','padding-left': '1px','overflow':'inherit','max-height':'290px'}">
                  <ng-container
                    *ngFor="let rooms of reservation_form['controls'].booking_items['controls']; let i = index">
                    <div class="collapsableDiv colps">
                      <div [formGroupName]="i">
                        <label class="label-mr">
                          {{roomNamesInreservationForm[i]}}
                          <i class="fa fa-trash-alt " (click)="removeRoom(i)" *ngIf="i != 0"></i>
                          <img
                            src="{{darkMode ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg':'assets/img/reports/button_down.svg'}}"
                            alt="dropdown" class="collapseimg" data-toggle="collapse" id="drop{{i}}"
                            [attr.data-target]="'#demo'+i" (click)="rotateDrop('drop'+i)">
                        </label>
                        <div>
                          <div id="demo{{i}}" class="" [ngClass]="i == 0 ? 'collapse in' : 'collapse'">
                            <hr style="margin: 0px;margin-bottom: 5px;margin-top: 5px;">
                            <!-- <div class="L4-scroll"> -->
                            <div class="row">
                              <div class="col-md-6">
                                <div class="modal-bar-2 nine">

                                  <!-- <img src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/guest-name-infinity0':'/forchk/full-name0'}}.svg"
                                                                    alt="" class="placeholder-img-modal" style="height: 20px;"> -->
                                  <i class="fa fa-users"
                                    style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                  <input type="text" class="form-control" placeholder="Guest First Name"
                                    style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                    formControlName="guest_name" (input)="changeGuestName($event,i)">
                                </div>
                                <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].guest_name.errors"
                                  class="small text-danger no-margin invalid-feedback">
                                  <strong
                                    *ngIf="f.booking_items['controls'][i]['controls'].guest_name.errors.required">Required</strong>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="modal-bar-2 nine">
                                  <i class="fa fa-users"
                                    style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                  <input type="text" class="form-control" placeholder="Guest Last Name"
                                    style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                    formControlName="guest_name">
                                  <div (click)="addguest(i)" style="display: flex; cursor:pointer">
                                    <i class="fa fa-plus"
                                      style="padding-left:4px; color: #00205b; padding-right: 10px; "></i>
                                  </div>
                                </div>
                                <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].guest_name.errors"
                                  class="small text-danger no-margin invalid-feedback">
                                  <strong
                                    *ngIf="f.booking_items['controls'][i]['controls'].guest_name.errors.required">Required</strong>
                                </div>
                              </div>
                            </div>
                            <!-- <div class="modal-bar-2 nine" id="{{bi+'input'+fi}}" formControlName="guest_name_second" >
                                                        <img src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/guest-name-infinity0':'/forchk/full-name0'}}.svg"
                                                              alt="" class="placeholder-img-modal" style="height: 20px;">
                                                        <input type="text" class="form-control" placeholder="Guest Name" id="{{bi+'null'+fi}}" formContrlName="guest_name" >

                                                    </div> -->
                            <div class="modal-bar-2 one" *ngIf="!applyChkOutDate">
                              <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                                style="max-height: 30px;border: none;padding-left: 0px">
                                <span class="input-group-addon"
                                  style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                  <!-- <i class="fa fa-calendar-alt"></i> -->
                                  <img
                                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'}}"
                                    style="margin-left: 6px;height: 24px;" lt="">
                                </span>
                                <input type="hidden" name="date_to">
                                <input type="hidden" name="date_from">
                                <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                  [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check In"
                                  [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                  [minDate]="minDate" [maxDate]="maxDate" formControlName="check_in">
                              </div>
                            </div>
                            <div class="modal-bar-2 two" *ngIf="!applyChkOutDate">
                              <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                                style="max-height: 30px;border: none;padding-left: 0px">
                                <span class="input-group-addon"
                                  style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                  <img
                                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg':'dashboard/departure.svg'}}"
                                    style="margin-left: 6px;    height: 24px;" alt="">
                                </span>
                                <input type="hidden" name="date_to">
                                <input type="hidden" name="date_from">
                                <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                  [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check Out"
                                  [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                  [minDate]="minDate" [maxDate]="maxDate" formControlName="check_out">
                              </div>
                            </div>
                            <div class="modal-bar-2 six Pnone"
                              [ngStyle]="{'border-color': randomColors[18 % randomColors.length]}">
                              <img src="assets/img/dashboard/in-house2.svg" alt="" style="
                                                        height: 22px;
                                                        width: 30px;
                                                        margin-top: 2px;
                                                        margin-left: -3px;
                                                      " class="placeholder-img-modal" id="status" />
                              <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Guest Status"
                                [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                        height: 30px;
                                                        border: none;
                                                        padding-left: 15px;
                                                        padding-right: 7px;
                                                      " formControlName="guest_status">
                                <ng-option value="Pending">Arriving Soon</ng-option>
                                <ng-option value="Checked In">Check In</ng-option>
                                <!-- <ng-option value="Checked Out">Checked Out</ng-option> -->
                                <ng-option value="No Show">No Show</ng-option>
                              </ng-select>
                            </div>
                            <div *ngIf="submitted && f.status.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="f.status.errors.required">Required</strong>
                            </div>
                            <div class="modal-bar-2 eight filterAccec" *ngIf="!roomFilter">
                              <div class="pointer">
                                <img class="img-responsive hovimg"
                                  src="assets/img/newstaticicons/back-office/nosmoke.svg"
                                  style="height:35px;margin-top:6px;" id="nosmoke{{i}}">
                                <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/smoke.svg"
                                  style="height:35px;margin-top:6px;" id="smoke{{i}}">
                              </div>
                              <div class="pointer">
                                <img class="img-responsive hovimg"
                                  src="assets/img/newstaticicons/back-office/nowheel.svg"
                                  style="height:35px;margin-top:6px;" id="nowheel{{i}}">
                                <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/wheel.svg"
                                  style="height:35px;margin-top:6px;" id="wheel{{i}}">
                              </div>
                              <div class="pointer">
                                <img class="img-responsive hovimg"
                                  src="assets/img/newstaticicons/back-office/nopets.svg"
                                  style="height:35px;margin-top:6px;" id="nopets{{i}}">
                                <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/pets.svg"
                                  style="height:35px;margin-top:6px;" id="pets{{i}}">
                              </div>
                            </div>
                            <div class="modal-bar-2 eight filterAccec" *ngIf="roomFilter">
                              <div class="pointer">
                                <img class="img-responsive hovimg "
                                  src="assets/img/newstaticicons/back-office/{{showsmoke == true ? 'smoke' : 'nosmoke'}}.svg"
                                  style="height:35px;margin-top:6px;"
                                  (click)="chnageicon1();RoomIconFilter(false,false)">
                              </div>
                              <div class="pointer">
                                <img class="img-responsive hovimg "
                                  src="assets/img/newstaticicons/back-office/{{showwheel == true ? 'wheel' : 'nowheel'}}.svg"
                                  style="height:35px;margin-top:6px;"
                                  (click)="chnageicon2();RoomIconFilter(false,false)">
                              </div>
                              <div class="pointer">
                                <img class="img-responsive hovimg "
                                  src="assets/img/newstaticicons/back-office/{{showpets == true ? 'pets' : 'nopets'}}.svg"
                                  style="height:35px;margin-top:6px;"
                                  (click)="chnageicon3();RoomIconFilter(false,false)">
                              </div>
                            </div>
                            <div class="modal-bar-2 five">
                              <img
                                src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/room-id-infinity1dark':'forchk/room-id-infinity1'}}.svg"
                                alt="" class="placeholder-img-modal-1"
                                [ngClass]="darkMode ? 'dark-room-Id' : 'light-room-Id'">
                              <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Room Type"
                                style=" height: 30px;border:none ;padding-left: 15px;padding-right: 7px"
                                [ngClass]="darkMode ? 'bg_back' : 'bg_white'" [items]="room_name" bindLabel="name"
                                bindValue="room_id" formControlName="room_id"
                                (change)="chkPackage($event) ;getroomnumber($event,i); updateRoomCardLabel($event,i)">
                              </ng-select>
                            </div>
                            <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].room_id.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong
                                *ngIf="f.booking_items['controls'][i]['controls'].room_id.errors.required">Required</strong>
                            </div>
                            <div class="modal-bar-2 six">
                              <img
                                src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/room_number.svg':'/bed2.svg'}}"
                                alt="" style=" height: 22px;width: 30px;margin-top: 2px;margin-left: -3px;"
                                class="placeholder-img-modal">
                              <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Room No"
                                [ngClass]="darkMode ? 'bg_back' : 'bg_white'" [items]="room_number"
                                bindLabel="room_number" bindValue="id"
                                style=" height: 30px;border:none ;padding-left: 15px;padding-right: 7px"
                                formControlName="room_no_id" (change)="changeRoomNumber($event,i)">
                              </ng-select>
                            </div>
                            <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].room_no_id.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong
                                *ngIf="f.booking_items['controls'][i]['controls'].room_no_id.errors.required">Required</strong>
                            </div>
                            <div class="row">
                              <div class="col-md-8 pr-1">
                                <div class="modal-bar-2 eleven" style="margin-bottom: 5px">
                                  <img src="assets/img/newstaticicons/inventory/forchk/package.svg" alt=""
                                    style="    height: 26px; width: 24px;" class="placeholder-img-modal imggg">
                                  <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                    [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="Promotion Name"
                                    style=" height: 30px;border:none ;padding-left: 45px;padding-right: 7px"
                                    formControlName="package_id" [items]="pack" bindLabel="package_name" bindValue="id"
                                    (change)="pro(selection)" [(ngModel)]="selection">
                                  </ng-select>
                                </div>
                              </div>
                              <div class="col-md-4 pl-0">
                                <div class="modal-bar-2 eleven pr-4 pt-1 justify-content-center">
                                  <img src="assets/img/newstaticicons/inventory/addon-icon.svg" alt="addons-icon"
                                    style="width: 35px; cursor: pointer;" class="h-auto placeholder-img-modal imggg"
                                    (click)="openAddOnsModal(i)">
                                </div>
                              </div>
                            </div>
                            <!-- <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].package_id.errors" class="small text-danger no-margin invalid-feedback">
                                                          <strong *ngIf="f.booking_items['controls'][i]['controls'].package_id.errors.required">Required</strong>
                                                      </div> -->
                            <div class="row rom-r" style="padding: 0px 20px;">
                              <div class="col-md-4 zero-padding" style="padding-right: 2px">
                                <div class="modal-bar-2 nine">
                                  <img
                                    src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/adult-dark.svg' : '/forchk/adult0.svg'}}"
                                    alt="" style="height: 20px;" class="placeholder-img-modal">
                                  <input type="number" min="0"
                                    oninput="this.value =
                                                                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                    class="form-control inp adultPadding"
                                    placeholder="{{resWidth == 1366 ? 'Ad..' : resWidth == 1440 ? 'Adu..' : resWidth == 1600 ? 'Adult.' : 'Adult'}}"
                                    formControlName="no_of_adults">
                                </div>
                                <!-- <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].no_of_adults.errors" class="small text-danger no-margin invalid-feedback">
                                                                  <strong *ngIf="f.booking_items['controls'][i]['controls'].no_of_adults.errors.required">Required</strong>
                                                              </div> -->
                              </div>

                              <div class="col-md-4 zero-padding" style="padding-left: 3px">
                                <div class="modal-bar-2 ten">
                                  <img
                                    src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/child-infinity0':'/child'}}.svg"
                                    alt="" style="height: 20px;" class="placeholder-img-modal">
                                  <input type="number" min="0"
                                    oninput="this.value =
                                                                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                    class="form-control inp childPadding"
                                    placeholder="{{resWidth <= 1366 ? 'Ch..' : resWidth <= 1440 ? 'Chi..' : resWidth <= 1600 ? 'Child.' : 'Children'}}"
                                    formControlName="no_of_childs">
                                </div>
                                <!-- <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].no_of_childs.errors" class="small text-danger no-margin invalid-feedback">
                                                                  <strong *ngIf="f.booking_items['controls'][i]['controls'].no_of_childs.errors.required">Required</strong>
                                                              </div> -->
                              </div>

                              <div class="col-md-4 zero-padding" style="padding-left: 4px;">
                                <div class="modal-bar-2 nine">
                                  <img
                                    src="assets/img/newstaticicons/inventory/{{darkMode ? 'infant-yellow':'infant'}}.svg"
                                    alt="" style="height: 20px;" class="placeholder-img-modal">
                                  <input type="number" min="0"
                                    oninput="this.value =
                                                                  !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                    class="form-control inp infantsPadding"
                                    placeholder="{{resWidth == 1366 ? 'In..' : resWidth == 1440 ? 'Inf..' : resWidth == 1600 ? 'Infan.' : 'Infant'}}"
                                    formControlName="no_of_infants">
                                </div>
                                <!-- <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].no_of_infants.errors" class="small text-danger no-margin invalid-feedback">
                                                                  <strong *ngIf="f.booking_items['controls'][i]['controls'].no_of_infants.errors.required">Required</strong>
                                                              </div> -->
                              </div>
                            </div>
                            <!-- EPP -->
                            <div formArrayName="extra_persons">
                              <div *ngFor="let extraPerson of getExtraPersons(i).controls; let extraPersoni=index">

                                <div [formGroupName]="extraPersoni" class="row" style="padding: 0px 20px;">
                                  <div class="modal-bar-2 nine">
                                    <i class="fa fa-users"
                                      style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                    <input type="text" class="form-control" placeholder="Guest Name"
                                      style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                      formControlName="name">
                                    <div (click)="removeGuest(i,extraPersoni)" style="display: flex; cursor:pointer">
                                      <i class="fa fa-times "
                                        style="padding-left:4px; color: #a00; padding-right: 10px; "></i>

                                    </div>
                                  </div>
                                  <div class="modal-bar-2 eighteen uii">
                                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                      class="placeholder-img-modal" style="height: 20px;">
                                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                      placeholder="ID Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                                      formControlName="id_type">
                                      <ng-option value="State ID">State ID</ng-option>
                                      <ng-option value="Driving License">Driving License
                                      </ng-option>
                                      <ng-option value="Passport">Passport</ng-option>
                                    </ng-select>
                                  </div>
                                  <div class="modal-bar-2 nineteen">
                                    <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                                      class="placeholder-img-modal id-type" style="height: 20px;">
                                    <input type="text" class="form-control" value="" placeholder="ID Number"
                                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                      formControlName="id_number">
                                  </div>
                                  <!-- </div>-----L4-scroll end-- -->
                                </div>
                              </div>
                            </div>

                            <!-- <div class="modal-bar-2 thirteen" style="margin-bottom: 0px">
                                                          <label for="" class="pcom"
                                                              (click)="chkRadio('test1'+i,'paid','test2'+i,'comp')">
                                                              <input type="radio" id="test1{{i}}" value="paid"
                                                                  formControlName="breakfast">
                                                              <label for="test1{{i}}" id="paid">Paid</label>
                                                          </label>
                                                          <label for="" class="pcom" (click)="chkRadio('test1'+i,'paid','test2'+i,'comp')">
                                                              <input type="radio" id="test2{{i}}" value="complimentry"
                                                                  formControlName="breakfast">
                                                              <label for="test2{{i}}" id="comp">Complimentry</label>
                                                          </label>
                                                      </div>
                                                      <div *ngIf="submitted && f.booking_items['controls'][i]['controls'].breakfast.errors"
                                                          class="small text-danger no-margin invalid-feedback">
                                                          <strong
                                                              *ngIf="f.booking_items['controls'][i]['controls'].breakfast.errors.required">Required</strong>
                                                      </div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <!-- ? addOns modal section start (working on saud 😊) -->
                <ng-container
                  *ngFor="let rooms of reservation_form['controls'].booking_items['controls']; let i = index">
                  <div class="rightD addOns-sideTray" id="addOnsDetail" [ngClass]="darkMode ? 'dark' : 'light'"
                    *ngIf="addOnsModal[i]" cdkDrag>
                    <div class="bluheader booking-sumary redbar mt-2">
                      <h3 class="h3_blu_header bookingsummary">
                        {{guestNamesInreservationForm[i] || 'Guest Name'}}
                      </h3>
                      <button type="button" class="close" (click)="addOnsModal[i] = false">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="bluheader booking-sumary mb-0">
                      <h3 class="h3_blu_header bookingsummary">
                        {{roomNamesInreservationForm[i] || 'Room'}}
                      </h3>
                      <h3 class="h3_blu_header bookingsummary">
                        {{ roomNoInreservationForm[i] || 'Room No'}}
                      </h3>
                    </div>
                    <div class="addons-section">
                      <ng-container *ngFor="let addOn of addOnsDisplayMap.get(i); index as j">
                        <div class="modal-bar-2 mt-2" [ngStyle]="{'border-color': WHcolors[j % WHcolors.length]}">
                          <div class="d-flex w-100 justify-content-between pr-2">
                            <div class="addon-items d-flex w-100 justify-content-between align-items-center pr-3">
                              <span class="text-left">{{ addOn.name }}</span>
                              <span>
                                <button class="btn-add">
                                  <i class="fa fa-minus pr-1" (click)="updateCount(addOn, -1, i)"></i>
                                  {{getBookingExtraQuantity(addOn, i)}}
                                  <i class="fa fa-plus pl-1" (click)="updateCount(addOn, 1, i)"></i>
                                </button>
                              </span>
                              <span>{{ currency_format }}{{ (+addOn.price_after_tax * getBookingExtraQuantity(addOn, i))
                                | number:'1.2-3' }}</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#ff6f00'}">
                      <div class="d-flex align-items-center">
                        <img src="assets/img/newstaticicons/arrivals/tax-icon.svg" alt="" class="placeholder-img-modal"
                          style="height: 20px;">
                      </div>
                      <h3 class="h3_blu_header bookingsummary text-dark">
                        {{addOnsExtras[0].tax_percentage}}%
                      </h3>
                    </div>
                    <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#00a8ff'}">
                      <div class="d-flex align-items-center">
                        <img src="assets/img/newstaticicons/inventory/sigma-icon.svg" alt=""
                          class="placeholder-img-modal" style="height: 20px;">
                      </div>
                      <h3 class="h3_blu_header bookingsummary text-dark">
                        {{ currency_format }}{{calculateAddonsPriceByRoomIndex(i) | number:'1.2-3'}}
                      </h3>
                    </div>
                  </div>
                </ng-container>
                <!-- ? addOns modal section end (working on saud 😊) -->
              </div>
            </div>
            <div class="col-md-6 zero-padding float-R R4">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">

                <div class="bluheader booking-sumary redbar " style=" margin-top: 5px;"
                  (mouseover)="dodrag('bulk_update')" (mouseout)="dontdrag('bulk_update')">
                  <h3 class="h3_blu_header bookingsummary">Booked By
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width:68% !important; top: -29px;"
                        [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'"> Check if the room
                        payee is same as guest payment information will be auto polpulated
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" class="applyAll2" style="top: 27% !important;" id="checkedForSep"
                    (change)="guestInfo($event)">
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-three onee bk-by"
                style="padding-left: 1px;padding-right: 0px; overflow: inherit;">
                <div class="greyBox g-b">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="modal-bar-2 twelve">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                          alt="" class="placeholder-img-modal" style="height: 20px;">
                        <input type="text" class="form-control" value="" placeholder="First Name"
                          style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                          formControlName="guest_firstname">
                      </div>
                      <div *ngIf="submitted && f.guest_firstname.errors"
                        class="small text-danger no-margin invalid-feedback">
                        <!-- <strong *ngIf="f.guest_firstname.errors.required">Required</strong> -->
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="modal-bar-2 twelve">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                          alt="" class="placeholder-img-modal" style="height: 20px;">
                        <input type="text" class="form-control" value="" placeholder="Last Name"
                          style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                          formControlName="guest_lastname">
                      </div>
                      <div *ngIf="submitted && f.guest_lastname.errors"
                        class="small text-danger no-margin invalid-feedback">
                      </div>
                    </div>
                  </div>
                  <div class="modal-bar-2 fourteen">
                    <div class="my-icon-0" style="padding-top: 3px;">
                      <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory/r-call-red.svg"
                        alt="">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <input type="text" class="form-control" value="" placeholder="Cell No"
                        style="height: 30px;border: none;padding: 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        formControlName="guest_phone">
                    </div>
                  </div>
                  <!-- <div *ngIf="submitted && f.guest_phone.errors"
                                                                          class="small text-danger no-margin invalid-feedback">
                                                                          <strong *ngIf="f.guest_phone.errors.required">Required</strong>
                                                                      </div> -->
                  <div class="modal-bar-2 fifteen">
                    <div class="my-icon-0" style="padding-top: 4px;">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                        alt="">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <input type="text" class="form-control" value="" placeholder="Email"
                        style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        formControlName="guest_email">
                    </div>
                  </div>
                  <div *ngIf="submitted && f.guest_email.errors" class="small text-danger no-margin invalid-feedback">
                    <!-- <strong *ngIf="f.guest_email.errors.required">Required</strong> -->
                    <strong *ngIf="f.guest_email.errors.email">Email not valid</strong>
                  </div>
                  <div class="modal-bar-2 sixteen">
                    <!-- <div class="my-icon-0"><i class="fa fa-map-marker-alt"></i> </div> -->
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                        style="height:20px" alt="">
                      <input type="text" class="form-control" value="" placeholder="Address"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        formControlName="guest_address">
                    </div>
                  </div>
                  <!-- <div *ngIf="submitted && f.guest_address.errors"
                                                                          class="small text-danger no-margin invalid-feedback">
                                                                          <strong *ngIf="f.guest_address.errors.required">Required</strong>
                                                                      </div> -->
                  <div class="modal-bar-2 seventeen">
                    <!-- <div class="my-icon-0"><i class="fa fa-map-marker-alt"></i> </div> -->
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                        style="height:20px" alt="">
                      <input type="text" class="form-control" value="" placeholder="City"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        formControlName="guest_city">
                    </div>
                  </div>
                  <!-- <div *ngIf="submitted && f.guest_city.errors"
                                                                          class="small text-danger no-margin invalid-feedback">
                                                                          <strong *ngIf="f.guest_city.errors.required">Required</strong>
                                                                      </div> -->
                  <!-- <div class="modal-bar-2 eighteen">
                                                                        <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal id-type" style="height: 20px;">
                                                                        <input type="text" class="form-control" value="" placeholder="ID Type"
                                                                            style="height: 30px;border: none;padding: 10px 35px;color: #00205b;font-size: 14px" formControlName="personal_id_type" >
                                                                    </div> -->
                  <div class="modal-bar-2 eighteen uii">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="ID Type"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                      formControlName="personal_id_type">
                      <ng-option value="State ID">State ID</ng-option>
                      <ng-option value="Driving License">Driving License</ng-option>
                      <ng-option value="Passport">Passport</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.personal_id_type.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <!-- <strong *ngIf="f.personal_id_type.errors.required">Required</strong> -->
                  </div>
                  <div class="modal-bar-2 nineteen">
                    <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                      class="placeholder-img-modal id-type" style="height: 20px;">
                    <input type="text" class="form-control" value="" placeholder="ID Number"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      formControlName="personal_id_value">
                  </div>
                  <div *ngIf="submitted && f.personal_id_value.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <!-- <strong *ngIf="f.personal_id_value.errors.required">Required</strong> -->
                  </div>
                  <!-- <label class="label-mr"> Priviliges </label> -->
                  <div class="modal-bar-2 twenty mb-0">
                    <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Priviliges" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                      formControlName="privilege">
                      <ng-option value="Loyalty Card Holder">Loyality Card Holder</ng-option>
                      <ng-option value="Discount Offers">Discount Offers</ng-option>
                      <ng-option value="None">None</ng-option>
                    </ng-select>
                  </div>
                  <!-- <div *ngIf="submitted && f.privilege.errors"
                                                                          class="small text-danger no-margin invalid-feedback">
                                                                          <strong *ngIf="f.privilege.errors.required">Required</strong>
                                                                      </div> -->

                </div>
              </div>
            </div>
          </div>
          <div class="rightD" style="right: 0px;z-index: -1;" id="sideBarDetail" [ngClass]="darkMode ? 'dark':'light'">
            <div class="overFlow-scroll">
              <div class="dFlex dataPrevDiv" *ngFor="let data of perDayData">
                <div class="data">
                  <div>
                    <div class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">
                      <h3 class="h3_blu_header bookingsummary">{{data.roomName}}</h3>
                    </div>
                    <div *ngIf="data.package_name != '' || data.package != null || data.package != undefined "
                      class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">
                      <h3 class="h3_blu_header bookingsummary">{{data.package_name}}</h3>
                    </div>
                    <div class="scroll_rate">

                      <div class="modal-bar-2 twenty6 dFlex rateDive s-tray" *ngFor="let rateDiv of data.rates">
                        <div>{{rateDiv.date | date:'EEE dd MMM'}}</div>
                        <div [ngClass]="darkMode ? 'darkDD' : 'null'">{{currency_format}} {{rateDiv.price}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="totalDiscount" [ngClass]="perDayData.length == 0 ? 'lenzero' : 'null'"
                  [ngStyle]="{'bottom': TaxInclusive == 'true' ? '-319px' : '-354px'}">
                  <div>
                    <div [ngClass]="darkMode ? 'DarkDic' : 'null'">

                      <div class="modal-bar-2 twenty60" style="  height: auto; padding: 0px;"
                        [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                        <textarea name="" id="" cols="30" rows="5" placeholder=" Special requests"
                          formControlName="SpecialRequests"></textarea>
                      </div>

                      <div class="modal-bar-2 twenty5" id="extraPerson">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/addpersonicon1.svg':'/addpersonicon2.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="number" min="0" oninput="this.value =
                                              !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                          class="form-control" value="" placeholder="Extra Person Fees"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                          formControlName="extra_person_fee" [(ngModel)]="extra_person_fee" (keyup)="calculateprice()">
                      </div>

                      <div class="modal-bar-2 two">
                        <img src="assets/img/newstaticicons/arrivals{{
                        darkMode ? '/Add-Ons.svg' : '/Add-Ons.svg'
                      }}" alt="Add-Ons" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" placeholder="Add-Ons"
                          style="height: 30px; border: none;padding: 10px 0px 10px 35px; color: #00205b;font-size: 14px;"
                          [value]="calculateAddOnsPrice() | number:'1.2-3'" readonly />
                      </div>

                      <div class="modal-bar-2 twenty5" id="">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode/subtotal.svg':'/sigma-icon.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="number" min="0" oninput="this.value =
                                              !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                          class="form-control" value="" placeholder="Sub total"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                          formControlName="subtotal" [(ngModel)]="subtotal" readonly>
                      </div>

                      <div class="modal-bar-2 twenty57 dFlex segmo">
                        <label [ngClass]="TaxInclusive == 'true' ? 'active' : 'null'">Tax
                          Inclusive</label>
                        <label [ngClass]="TaxInclusive == 'false' ? 'active' : 'null'">Tax
                          Exclusive</label>
                        <div class="segmon" id="segmon" [ngStyle]="{'left': TaxInclusive == 'true' ? '3%' : '53%'}">
                        </div>
                      </div>

                      <div class="modal-bar-2 twenty2 ">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/discount.svg':'/discount.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <div *ngIf="user.role == 'SuperAdmin' || Discount == 'true'"
                          style="display: flex; justify-content: space-between; width: 100%;">
                          <input type="number" min="0"
                            oninput="this.value = !!this.value && this.value >= 0 ? this.value.toFixed(3) : null"
                            *ngIf="data.package_name == '' || data.package_name == null || data.package_name == undefined "
                            class="form-control" value="" placeholder="Discount"
                            style="width:55%;height: 30px;border: none;padding: 10px 0px 10px 40px;color: #00205b;font-size: 14px"
                            formControlName="discount" [max]="100" [min]="0" (keyup)="calculateprice()"
                            id="discountPromo" />
                          <div
                            style="display:flex;align-items: center;border-left: 2px solid #80808061;height: 20px; margin-top: 5px;">
                            <!-- <img src="assets/img/newstaticicons/inventory{{
                                                          darkMode ? '/darkmode_arrival/dollar.svg' : '/dollar.svg'
                                                        }}" style="height: 20px;" /> -->
                            <input type="number" class="form-control"
                              oninput="this.value =!!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                              placeholder="DiscountedAmt" [(ngModel)]="discountedAmountReservationPopup"
                              [ngModelOptions]="{standalone: true}" (keyup)="updateDiscountPercentage()"
                              style="height: 30px;border: none;padding: 10px 0px 10px 10px;color: #00205b;font-size: 13px">
                          </div>

                          <!-- <span style="font-size: 10px; padding: 8px 0px 0px 2px;">
                                                            <i class="fa fa-percent" ></i>
                                                      </span> -->


                          <input type="number" min="0"
                            oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                            *ngIf="data.package_name != '' || data.package != null || data.package != undefined "
                            class="form-control" value="" placeholder="Discount"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                            formControlName="discount_package" readonly id="discountPromo">
                        </div>

                        <div *ngIf="user.role != 'SuperAdmin' &&  Discount == 'false'">
                          <input type="text" class="form-control" value="" placeholder="Discount"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                            formControlName="discount" [disabled]="true" id="discountPromo">
                        </div>
                      </div>

                      <div class="modal-bar-2 twenty1 ">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/amount-deposit.svg':'/amount-deposit.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="number" min="0" oninput="this.value =
                                              !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                          class="form-control" value="" placeholder="Cash Deposit"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                          formControlName="cashDeposit">
                      </div>

                      <div class="modal-bar-2 twenty2  " *ngIf="this.TaxInclusive == 'false'">
                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/tax-icon.svg':'/tax-icon.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="number" min="0" oninput="this.value =
                                              !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                          class="form-control" placeholder="Tax"
                          style="height: 30px;border: none;padding: 10px 0px 10px 45px;color: #00205b;font-size: 14px"
                          formControlName="Tax" [(ngModel)]="Tax" readonly>
                        <span style="font-weight: 700;">{{taxper}}</span>
                        <span style="font-size: 10px; padding: 2px;">
                          <i class="fa fa-percent"></i>
                        </span>
                      </div>

                      <div class="modal-bar-2 twenty5  mb-0">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/dollar.svg':'/dollar.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="text" class="form-control" value="" placeholder="Total Amount"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                          formControlName="total" readonly>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="roundCurve" (click)="sideMenu('sideBarDetail')">
                  <i class="fa fa-caret-right"></i>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer my-foot" style="padding-bottom: 5px !important;display: none;">
        </div>
        <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
          <div class="popupBtns">
            <button class="btn btn-block send-0" (click)="submitReserv()" id="btnSaveEv">Save</button>
            <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close"
              (click)="resetForm(reservation_form)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Reservation Pupop END-->

<!-- bulkupdateViewOnly -->
<div id="showBulkUpdate" class="modal fade bulk_updatemodal" role="dialog" [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
  <div class="modal-dialog modal-lg" *ngIf="onlyShowBooking">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <div class="bluheader booking-sumary" (mouseover)="dodrag('showBulkUpdate')"
          (mouseout)="dontdrag('showBulkUpdate')">
          <h3 class="h3_blu_header bookingsummary">Reservation
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            style="color: white;line-height: 34px">
            <strong id="cross">&#x2716;</strong>
          </button>
        </div>
      </div>

      <div class="modal-body dFlex">
        <div class="leftD">

          <div class="col-md-6 zero-padding">
            <div class="col-md-12 zero-padding" style="padding-right: 2px;">
              <div class="bluheader booking-sumary redbar" style="display: flex;justify-content: space-between;">
                <h3 class="h3_blu_header bookingsummary">Stays
                  <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                    <div class="tooltip" style="width:90%" [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                      {{toolChkData}}
                    </div>
                  </i>
                </h3>
              </div>
            </div>
            <div class="col-md-12  zero-padding col-12-0ne onee"
              style="padding-right: 2px;padding-bottom: 5px;height: 290px;">
              <div class="greyBox">
                <div class="modal-bar-2 dynamoColor" [ngStyle]="{'border-color': randomColors[0%randomColors.length]}">
                  <img
                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg' : 'dashboard/check-in.svg'}}"
                    alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control "
                    style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                    placeholder="Check In" value="{{onlyShowBooking?.date_arrival | date:'d MMMM y'}}" readonly>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img
                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg':'dashboard/departure.svg'}}"
                    alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control "
                    style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                    placeholder="Check Out" value="{{onlyShowBooking?.date_departure | date:'d MMMM y'}}" readonly>
                </div>
                <div class="modal-bar-2  timePickerEta dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/ETA-clock.svg" alt="" class="placeholder-img-modal">
                  <input type="time" class="form-control "
                    style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                    placeholder="ETA" value="{{onlyShowBooking?.eta}}" readonly>
                </div>
                <div class="modal-bar-2  timePickerEtd dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/ETD-clock.svg" alt="" class="placeholder-img-modal">
                  <input type="time" class="form-control "
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    placeholder="ETD" value="{{onlyShowBooking?.etd}}" readonly>
                </div>

                <div class="modal-bar-2 dynamoColor">
                  <div class="my-icon-0 ovi-rid">
                    <img alt="" *ngIf="onlyShowBooking.date_arrival!=onlyShowBooking.date_departure"
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}"
                      style="height: 20px;margin-left: 2px;">
                    <img alt="" *ngIf="onlyShowBooking.date_arrival==onlyShowBooking.date_departure"
                      src="assets/img/newstaticicons/inventory/icons-sun.png"
                      style="height: 20px; width:20px; margin-left: 2px;">
                  </div>
                  <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                    <input class="form-control" placeholder="Total Nights"
                      style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                      type="text" value="{{countDaysAndNights(onlyShowBooking)}}" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor" style="margin-bottom: 5px;">
                  <div class="my-icon-0 ovi-rid">
                    <img alt="" src="assets/img/newstaticicons/inventory/return.svg"
                      style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                  </div>
                  <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                    <input class="form-control" placeholder="Total Nights"
                      style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                      type="text" value="New" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/arrivals/id-verified-light.svg" alt=""
                    class="placeholder-img-modal channel-img" style="margin-top: 4px;">
                  <input type="text" class="form-control" value="{{onlyShowBooking.confirmation_number}}"
                    placeholder="Confirmation Id No"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    maxlength="16" readonly>
                </div>
                <div class="modal-bar-2 dynamoColor" id="flip2" style="margin-bottom: 0px;">
                  <div *ngFor="let update_weather of weather_update; let i = index">
                    <i class='wi wi-main wi-{{ update_weather.icon }}'
                      [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                    <div class="weatherDiv">
                      <span class="wh_name">{{update_weather.title}} </span>
                      <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                      <span class="wh_con">{{update_weather.condition}} </span>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div class="col-md-6 zero-padding">
            <div class="col-md-12 zero-padding" style="padding-left: 0px">
              <div class="bluheader booking-sumary redbar">
                <h3 class="h3_blu_header bookingsummary">Payment</h3>
              </div>
            </div>
            <div class="col-md-12 zero-padding col-12-four onee "
              style="padding-left: 1px;padding-bottom: 5px;height: 290px;">
              <div class="greyBox">
                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Cardholder Name"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    value="{{onlyShowBooking?.payee_name}}" readonly>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Card Type"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    value="{{onlyShowBooking?.guest_card_type}}" readonly>

                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Card Number"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    value="{{onlyShowBooking?.guest_card}}" readonly>
                </div>


                <div class="col-md-6 zero-padding" style="padding-right: 2px">
                  <div class="modal-bar-2 twenty3" [ngStyle]="{'border-color': randomColors[20%randomColors.length]}">
                    <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt="" class="placeholder-img-modal">
                    <input class="form-control" name=x size=5 maxlength=5
                      onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{onlyShowBooking?.guest_card_expiry}}" placeholder="MM / YY">
                  </div>
                  <div *ngIf="submittedUpodated && b.booking_items['controls'][i]['controls'].card_expiry.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong
                      *ngIf="b.booking_items['controls'][i]['controls'].card_expiry.errors.required">Required</strong>
                  </div>
                </div>


                <div class="col-md-6 zero-padding" style="padding-left: 3px">
                  <div class="modal-bar-2 twenty3" [ngStyle]="{'border-color': randomColors[21%randomColors.length]}">
                    <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt="" class="placeholder-img-modal">
                    <input type="number" class="form-control" value="" placeholder="CVV"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      pattern="/^\d+$/" onKeyPress="if(this.value.length==4) return false;"
                      value="{{onlyShowBooking?.guest_card_series_code}}">
                  </div>
                  <div *ngIf="submittedUpodated && b.booking_items['controls'][i]['controls'].card_cvv.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong
                      *ngIf="b.booking_items['controls'][i]['controls'].card_cvv.errors.required">Required</strong>
                  </div>
                </div>

                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/channel.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Channel"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    value="{{onlyShowBooking?.channel.name}}" readonly>
                </div>

                <!-- <div class="modal-bar-2  dynamoColor">
                                <img src="assets/img/newstaticicons/inventory/promo-code-small.svg" alt="" class="placeholder-img-modal">
                                <input type="text" class="form-control" value="" placeholder="Promocode"
                                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px" value="{{onlyShowBooking?.promo}}" readonly>
                            </div> -->
                <div class="modal-bar-2  uii dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Payment Status"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;text-transform: capitalize;"
                    value="{{onlyShowBooking?.payment_status}}" readonly>
                </div>
                <div class="modal-bar-2  uii dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control" value="" placeholder="Booking Status"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;text-transform: capitalize;"
                    value="{{onlyShowBooking?.status == 'confirm' ? 'confirmed' : onlyShowBooking?.status}}" readonly>
                </div>
                <div class="modal-bar-2 dynamoColor" style="margin-top: 5px;margin-bottom: 0px;">
                  <div class="my-icon-0 ovi-rid">
                    <img alt="" src="assets/img/newstaticicons/arrivals/{{darkMode ? 'coins' : 'coins-light'}}.svg"
                      style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                  </div>
                  <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                    <input class="form-control" placeholder="Currency" value=""
                      style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                      type="text" value="{{onlyShowBooking?.currency}}" readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 zero-padding">
            <div class="col-md-12 zero-padding" style="padding-right: 2px">
              <div class="bluheader booking-sumary redbar " style="padding-right: 10px;">
                <h3 style="display: inline-block;" class="h3_blu_header bookingsummary">Who's Checking In
                  <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                    <div class="tooltip" style="width:91% !important; top: -32px !important; "
                      [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">If the guest
                      checking in is not the same as payee: Guest will be required to give
                      credit card information for In hotel expenses</div>
                  </i>
                </h3>
              </div>
            </div>
            <div class="col-md-12 zero-padding col-12-two oneeee"
              [ngStyle]="{'padding-right':'2px','padding-left': '1px'}" style="max-height: 293px;">
              <div class="collapsableDiv " *ngFor="let guests of onlyShowBooking.bookingitems; let i = index">

                <div>
                  <label class="label-mr" *ngIf="guests.room.display_name == null  || guests.room.display_name == '' ">
                    {{guests.room.roomtype.name}}
                    <img
                      src="{{darkMode ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg':'assets/img/reports/button_down.svg'}}"
                      alt="dropdown" class="collapseimg" data-toggle="collapse" id="droop{{i}}"
                      [attr.data-target]="'#demoo'+i" (click)="rotateDrop('droop'+i)">
                  </label>
                  <!-- <label class="label-mr" *ngIf="guests.room.display_name != ''  ">
                                          {{guests.room.display_name}}
                                          <img src="{{darkMode ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg':'assets/img/reports/button_down.svg'}}"
                                              alt="dropdown" class="collapseimg" data-toggle="collapse" id="droop{{i}}"
                                              [attr.data-target]="'#demoo'+i" (click)="rotateDrop('droop'+i)">
                                      </label> -->
                  <div>
                    <div id="demoo{{i}}" class="collapse in">
                      <!-- <hr style="margin: 0px;margin-bottom: 5px;margin-top: 5px;"> -->
                      <div class="row">
                        <div class="col-md-6">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[3%randomColors.length]}">
                            <img
                              src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/guest-name-infinity0':'/forchk/full-name0'}}.svg"
                              alt="" class="placeholder-img-modal" style="height: 20px;">
                            <input type="text" class="form-control" placeholder="Guest First Name"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="{{guests.guest_name}}" readonly>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[3%randomColors.length]}">
                            <img
                              src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/guest-name-infinity0':'/forchk/full-name0'}}.svg"
                              alt="" class="placeholder-img-modal" style="height: 20px;">
                            <input type="text" class="form-control" placeholder="Guest Last Name"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="{{guests.guest_name}}" readonly>
                          </div>
                        </div>
                      </div>

                      <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[5%randomColors.length]}">
                        <img src="assets/img/dashboard/in-house2.svg" alt="" class="placeholder-img-modal"
                          style="height: 20px;">
                        <input type="text" class="form-control" placeholder="Booking Status"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                          value="{{guests.status == 'Pending' ? 'Arriving Soon' : guests.status}}" readonly>
                      </div>

                      <div class="guestCheckedIncard"
                        *ngIf="guests.status == 'Checked In' || guests.status == 'Checked Out'">
                        <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[8%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" placeholder="Card Number"
                            style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                            value="{{guests.card_no}}" readonly>
                        </div>
                        <div class="col-md-6 zero-padding" style="padding-right: 2px">
                          <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[9%randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" placeholder="Card Expiry"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="{{guests.card_expiry}}" readonly>
                          </div>
                        </div>
                        <div class="col-md-6 zero-padding" style="padding-left: 3px">
                          <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[2%randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" placeholder="CVV"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="{{guests.card_cvv}}" readonly>
                          </div>
                        </div>


                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[15%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="{{guests.id_type}}" placeholder="Personal Id"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[16%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="{{guests.id_value}}" placeholder="Id Number"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[20%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="{{guests.phone_number}}"
                            placeholder="Phone Number"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[29%randomColors.length]}">
                          <img
                            src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                            alt="" class="placeholder-img-modal">
                          <input type="text" class="form-control" value="{{guests.email}}" placeholder="Email"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                      </div>

                      <div class="modal-bar-2  filterAccec"
                        [ngStyle]="{'border-color':randomColors[1%randomColors.length]}">
                        <div class="pointer">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/nosmoke.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.smoking == 'false'">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/smoke.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.smoking == 'true'">
                        </div>
                        <div class="pointer">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/nowheel.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.wheelchairaccessibility == 'false'">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/wheel.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.wheelchairaccessibility == 'true'">
                        </div>
                        <div class="pointer">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/nopets.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.pets == 'false'">
                          <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/pets.svg"
                            style="height:35px;margin-top:6px;" *ngIf="guests.room.pets == 'true'">
                        </div>
                      </div>



                      <div *ngIf="guests.room.display_name == '' || guests.room.display_name == null"
                        class="modal-bar-2" [ngStyle]="{'border-color':randomColors[7%randomColors.length]}">
                        <img
                          src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/room-id-infinity1dark':'forchk/room-id-infinity1'}}.svg"
                          alt="" class="placeholder-img-modal-1"
                          [ngClass]="darkMode ? 'dark-room-Id' : 'light-room-Id'">
                        <input type="text" class="form-control" placeholder="Room Type"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                          value="{{guests.room.roomtype.name}}" readonly>
                      </div>
                      <div *ngIf="guests.room.display_name != '' " class="modal-bar-2"
                        [ngStyle]="{'border-color':randomColors[7%randomColors.length]}">
                        <img
                          src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/room-id-infinity1dark':'forchk/room-id-infinity1'}}.svg"
                          alt="" class="placeholder-img-modal-1"
                          [ngClass]="darkMode ? 'dark-room-Id' : 'light-room-Id'">
                        <input type="text" class="form-control" placeholder="Room Type"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                          value="{{guests.room.display_name}}" readonly>
                      </div>


                      <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[10%randomColors.length]}">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/room_number.svg':'/bed2.svg'}}"
                          alt="" style=" height: 22px;width: 30px;margin-top: 2px;margin-left: -3px;"
                          class="placeholder-img-modal">
                        <input type="text" class="form-control" placeholder="Room No"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                          value="{{guests.roomnum.room_number}}" readonly>
                      </div>
                      <div class="row">
                        <div class="col-md-8 pr-1">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[i%randomColors.length]}"
                            style="margin-bottom: 5px">
                            <img src="assets/img/newstaticicons/inventory/forchk/package.svg" alt=""
                              style="    height: 26px; width: 24px;" class="placeholder-img-modal imggg">
                            <input type="text" class="form-control" placeholder="Promotion Name"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="{{guests.package == null ? null : guests.package?.package_name }}" readonly>
                          </div>
                        </div>
                        <div class="col-md-4 pl-0">
                          <div class="modal-bar-2 eleven pr-4 pt-1 justify-content-center">
                            <img src="assets/img/newstaticicons/inventory/addon-icon.svg" alt="addons-icon"
                              style="width: 35px; cursor: pointer;" class="h-auto placeholder-img-modal imggg"
                              (click)="openAddOnsModal(i)">
                          </div>
                        </div>
                      </div>
                      <div class="row" style="padding: 0px 20px;">
                        <div class="col-md-4 zero-padding" style="padding-right: 2px">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[32%randomColors.length]}">
                            <img
                              src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/adult-dark.svg' : '/forchk/adult0.svg'}}"
                              alt="" style="height: 20px;" class="placeholder-img-modal">
                            <input type="number" class="form-control inp adultPadding"
                              placeholder="{{resWidth == 1366 ? 'Ad..' : resWidth == 1440 ? 'Adu..' : resWidth == 1600 ? 'Adult.' : 'Adult'}}"
                              value="{{guests.no_of_adults}}" readonly>
                          </div>
                        </div>

                        <div class="col-md-4 zero-padding" style="padding-left: 3px;padding-right: 2px">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[47%randomColors.length]}">
                            <img
                              src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/child-infinity0':'/child'}}.svg"
                              alt="" style="height: 20px;" class="placeholder-img-modal">
                            <input type="number" class="form-control inp childPadding"
                              placeholder="{{resWidth <= 1366 ? 'Ch..' : resWidth <= 1440 ? 'Chi..' : resWidth <= 1600 ? 'Child.' : 'Children'}}"
                              value="{{guests.no_of_childs}}" readonly>
                          </div>
                        </div>

                        <div class="col-md-4 zero-padding" style="padding-left: 3px;">
                          <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[30%randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/{{darkMode ? 'infant-yellow':'infant'}}.svg"
                              alt="" style="height: 20px;" class="placeholder-img-modal">
                            <input type="number" class="form-control inp infantsPadding"
                              placeholder="{{resWidth == 1366 ? 'In..' : resWidth == 1440 ? 'Inf..' : resWidth == 1600 ? 'Infan.' : 'Infant'}}"
                              value="{{guests.no_of_infants}}" readonly>
                          </div>
                        </div>
                      </div>
                      <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[20%randomColors.length]}"
                        style="margin-bottom: 0px;display: flex;justify-content: space-around;">

                        <div class="fakeBoxMain " [ngClass]="guests.breakfast == 'Complimentry' ? '' : 'active'">
                          <div class="fakeChkBox"></div>
                          <label for="">Paid</label>
                        </div>
                        <div class="fakeBoxMain " [ngClass]="guests.breakfast == 'Complimentry' ? 'active' : ''">
                          <div class="fakeChkBox"></div>
                          <label for="">Complimentry</label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- ? addOns modal section start (working on saud 😊) -->
            <ng-container *ngFor="let guests of onlyShowBooking.bookingitems; let i = index">
              <div class="rightD addOns-sideTray" id="addOnsDetail" [ngClass]="darkMode ? 'dark' : 'light'"
                *ngIf="addOnsModal[i] && guests.booking_extras" cdkDrag style="width: 300px">
                <div class="bluheader booking-sumary redbar mt-2">
                  <h3 class="h3_blu_header bookingsummary">
                    {{guests.guest_name}}
                  </h3>
                  <button type="button" class="close" (click)="addOnsModal[i] = false">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="bluheader booking-sumary mb-0">
                  <h3 class="h3_blu_header bookingsummary">
                    {{guests.room.display_name}}
                  </h3>
                  <h3 class="h3_blu_header bookingsummary">
                    {{guests.roomnum.room_number}}
                  </h3>
                </div>
                <div class="addons-section">
                  <ng-container *ngFor="let addOn of addOnsExtrasDisplay(guests.booking_extras); index as j">
                    <div class="modal-bar-2 mt-2" [ngStyle]="{'border-color': WHcolors[j % WHcolors.length]}">
                      <div class="d-flex w-100 justify-content-between pr-2">
                        <div class="addon-items d-flex w-100 justify-content-between align-items-center pr-3">
                          <span class="text-left">{{addOn.name}}</span>
                          <span>
                            <button class="btn-add">
                              {{ addOn.count }}
                            </button>
                          </span>
                          <span>
                            {{ currency_format }}{{(+addOn.price * addOn.count) | number:'1.2-3'}}
                          </span>
                        </div>
                        <!-- <input type="checkbox" name="addons" id="addons-readonly" checked /> -->
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#ff6f00'}">
                  <div class="d-flex align-items-center">
                    <img src="assets/img/newstaticicons/arrivals/tax-icon.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                  </div>
                  <h3 class="h3_blu_header bookingsummary text-dark">
                    13%
                  </h3>
                </div>
                <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#00a8ff'}">
                  <div class="d-flex align-items-center">
                    <img src="assets/img/newstaticicons/inventory/sigma-icon.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                  </div>
                  <h3 class="h3_blu_header bookingsummary text-dark">
                    {{ currency_format
                    }}{{calculateAddonsPriceByRoom(guests.booking_extras) |
                    number:'1.2-3'}}
                  </h3>
                </div>
              </div>
            </ng-container>
            <!-- ? addOns modal section end (working on saud 😊) -->
          </div>
          <div class="col-md-6 zero-padding">
            <div class="col-md-12 zero-padding" style="padding-left: 0px">
              <div class="bluheader booking-sumary redbar">
                <h3 class="h3_blu_header bookingsummary">Booked By
                  <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                    <div class="tooltip" style="width:68% !important; top: -29px;"
                      [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'"> Check if the room
                      payee is same as guest payment information will be auto polpulated
                    </div>
                  </i>
                </h3>
              </div>
            </div>
            <div class="col-md-12 zero-padding col-12-three onee "
              style="padding-left: 1px;height:293px; padding-right: 0px;">
              <div class="greyBox" style="position: relative; top:2px;">
                <div class="row">
                  <div class="col-md-6">
                    <div class="modal-bar-2 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                        alt="" class="placeholder-img-modal" style="height: 20px;">
                      <input type="text" class="form-control" value="" placeholder="First Name"
                        style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                        value="{{onlyShowBooking?.guest_firstname}}" readonly>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="modal-bar-2 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                        alt="" class="placeholder-img-modal" style="height: 20px;">
                      <input type="text" class="form-control" value="" placeholder="Last Name"
                        style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                        value="{{onlyShowBooking?.guest_lastname}}" readonly>
                    </div>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <div class="my-icon-0" style="padding-top: 3px;">
                    <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory/r-call-red.svg" alt="">
                  </div>
                  <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                    <input type="text" class="form-control" value="" placeholder="Cell No"
                      style="height: 30px;border: none;padding: 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                      value="{{onlyShowBooking?.guest_phone}}" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <div class="my-icon-0" style="padding-top: 4px;">
                    <img class="placeholder-img-modal"
                      src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                      alt="">
                  </div>
                  <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                    <input type="text" class="form-control" value="" placeholder="Email"
                      style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                      value="{{onlyShowBooking?.guest_email}}" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                    <img class="placeholder-img-modal"
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                      style="height:20px" alt="">
                    <input type="text" class="form-control" value="" placeholder="Address"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                      value="{{onlyShowBooking?.guest_address}}" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                    <img class="placeholder-img-modal"
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                      style="height:20px" alt="">
                    <input type="text" class="form-control" value="" placeholder="City"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                      value="{{onlyShowBooking?.guest_city}}" readonly>
                  </div>
                </div>
                <div class="modal-bar-2 uii dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                    style="height: 20px;">
                  <input type="text" class="form-control" value="" placeholder="ID Type"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: 1px;"
                    value="{{onlyShowBooking?.personal_id_type}}" readonly>
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                    class="placeholder-img-modal id-type" style="height: 20px;">
                  <input type="text" class="form-control" value="" placeholder="ID Number"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                    value="{{onlyShowBooking?.personal_id_value}}" readonly>
                </div>
                <div class="modal-bar-2  mb-0 dynamoColor">
                  <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt="" class="placeholder-img-modal"
                    style="height: 20px;">
                  <input type="text" class="form-control" value="" placeholder="ID Type"
                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: 1px;"
                    value="{{onlyShowBooking?.privilege}}" readonly>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="rightD" style="right: 0px;z-index: -1;" id="sideBarDetailShow"
          [ngClass]="darkMode ? 'dark':'light'">

          <div class="dFlex dataPrevDiv">

            <div class="data">
              <div class="scroll_rate">
                <div *ngFor="let perday of onlyShowBooking.bookingitems">
                  <div class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">

                    <h3 *ngIf="perday.room.display_name == '' || perday.room.display_name == null "
                      class="h3_blu_header bookingsummary">{{perday.room.roomtype.name}}</h3>
                    <h3 *ngIf="perday.room.display_name != ''" class="h3_blu_header bookingsummary">
                      {{perday.room.display_name}}</h3>

                  </div>

                  <div class="modal-bar-2 twenty6  dFlex rateDive " *ngFor="let rates of cnvertJsonName(perday).rates">
                    <div> {{rates.date | date:'EEE dd MMM'}} </div>
                    <div [ngClass]="darkMode ? 'darkDD' : 'null'">{{ onlyShowBooking.currency + rates.price}}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="totalDiscount"
              [ngStyle]="{'bottom': onlyShowBooking.TaxInclusive == 'true' ? '-319px' : '-354px'}">
              <div>
                <div [ngClass]="darkMode ? 'DarkDic' : 'null'">
                  <div class="modal-bar-2 twenty60 dynamoColor" style="  height: auto; padding: 0px;"
                    [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                    <textarea name="" id="" cols="30" rows="5" placeholder=" Special requests"
                      value="{{onlyShowBooking.SpecialRequests}}" readonly></textarea>
                  </div>

                  <div class="modal-bar-2 twenty5   dynamoColor">
                    <img
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/addpersonicon1.svg':'/addpersonicon2.svg'}}"
                      alt="" style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Extra Person Fees"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{onlyShowBooking.extra_person_fee}}" readonly>
                  </div>

                  <div class="modal-bar-2 two">
                    <img src="assets/img/newstaticicons/arrivals{{
                    darkMode ? '/Add-Ons.svg' : '/Add-Ons.svg'
                  }}" alt="Add-Ons" style="height: 20px" class="placeholder-img-modal" />
                    <input type="text" class="form-control" placeholder="Add-Ons"
                      style="height: 30px; border: none;padding: 10px 0px 10px 35px; color: #00205b;font-size: 14px;"
                      [value]="calculateAllAddOnsPrice() | number:'1.2-3'" readonly />
                  </div>

                  <div class="modal-bar-2 twenty5   dynamoColor">
                    <img
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode/subtotal.svg':'/subtotal-sigma.svg'}}"
                      alt="" style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Sub Total"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{sub_total(onlyShowBooking.prices,onlyShowBooking.extra_person_fee)}}" readonly>
                  </div>


                  <!-- <div class="modal-bar-2 twenty57 dFlex segmo dynamoColor">
                                              <label [ngClass]="TaxInclusive == 'true' ? 'active' : 'null'">{{TaxInclusive}}</label>
                                              <label [ngClass]="TaxInclusive == 'false' ? 'active' : 'null'">Tax Exclusive</label>
                                              <div class="segmon" [ngStyle]="{'left': TaxInclusive == 'false' ? '3%' : '53%'}"></div>
                                          </div> -->

                  <div class="modal-bar-2 twenty57 dFlex segmo">
                    <label [ngClass]="onlyShowBooking.TaxInclusive  == 'true' ? 'active' : 'null'">Tax
                      Inclusive</label>
                    <label [ngClass]="onlyShowBooking.TaxInclusive  == 'false' ? 'active' : 'null'">Tax
                      Exclusive</label>
                    <div class="segmon" id="segmon"
                      [ngStyle]="{'left': onlyShowBooking.TaxInclusive == 'true' ? '3%' : '53%'}">
                    </div>
                  </div>

                  <div class="modal-bar-2 twenty6 dynamoColor">
                    <img
                      src="assets/img/newstaticicons/inventory{{darkMode?'/discount-light.svg':'/discount-light.svg'}}"
                      alt="" style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Discount"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{onlyShowBooking.discount == null ? '0' : onlyShowBooking.discount}}" readonly>
                  </div>

                  <div class="modal-bar-2 twenty5   dynamoColor">
                    <img
                      src="assets/img/newstaticicons/inventory{{darkMode ? '/amount-deposit.svg':'/amount-deposit.svg'}}"
                      alt="" style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Cash deposit"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{onlyShowBooking.cashDeposit}}" readonly>
                  </div>

                  <div class="modal-bar-2 twenty5  dynamoColor" *ngIf="onlyShowBooking.TaxInclusive == 'false'">
                    <img src="assets/img/newstaticicons/arrivals{{darkMode ? '/taxicon.svg':'/taxicon.svg'}}" alt=""
                      style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Tax"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{tax_per(onlyShowBooking.prices,onlyShowBooking.extra_person_fee,onlyShowBooking.Taxper)}}"
                      readonly>
                    <!-- <span>
                                              {{onlyShowBooking.Taxper}}</span>
                                              <span>
                                            <i class="fa fa-percent" style="padding-right: 5px;"></i>
                                          </span> -->
                    <span style="font-weight: 700;"> {{onlyShowBooking.Taxper}}</span>
                    <span style="font-size: 10px; padding: 2px;">
                      <i class="fa fa-percent"></i>
                    </span>
                  </div>

                  <div class="modal-bar-2 twenty5  mb-0 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory{{darkMode ? '/dollar-light.svg':'/dollar-light.svg'}}"
                      alt="" style="height: 20px;" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Total Amount"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      value="{{onlyShowBooking.total | number:'1.2-3'}}" readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="roundCurve" (click)="sideMenu('sideBarDetailShow')">
            <i class="fa fa-caret-right"></i>
          </div>
        </div>
      </div>

      <div class="modal-footer my-foot" style="padding-bottom: 5px !important;display: none;">
      </div>
      <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
        <div class="popupBtns">
          <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">Close</button>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- bulkupdateViewOnly -->

<!-- housekeeping  popup -->
<div id="housekeeping" class="modal fade" role="dialog" style="width:100%;" [ngClass]="darkMode ? 'darkOn' : 'null'">
  <div class="modal-dialog modal-lg modal-width">
    <div class="modal-content" (mouseover)="dodrag('housekeeping')" (mouseout)="dontdrag('housekeeping')">
      <form [formGroup]="maintenence_form" (ngSubmit)="saveCloseMaintenance()">
        <div class="modal-header border" style="background-color: #f1f1f1">
          <div class="bluheader booking-sumary">
            <h3 class="h3_blu_header bookingsummary">{{selectedVen?.room_number}}</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              style="color: white;line-height: 34px">
              <strong id="cross">&#x2716;</strong>
            </button>
          </div>
        </div>
        <div class="modal-body" style="background-color: #f1f1f1">
          <div class="col-md-12" style="padding-left: 0px;padding-right:0px;">
            <div class="" style="padding-left: 0px;padding-right:2.5px;">
              <div class="modal-bar-2 five six-pd">
                <input type="text" class="form-control issues" placeholder="Issue" formControlName="issue">
                <div *ngIf="mSubmit && mf.issue.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="mf.issue.errors.required">Issue Required</strong>
                </div>
              </div>
              <div class="modal-bar-2 six six-pd">
                <!-- <img src="assets/img/reports/bedicon.png" alt="" class="placeholder-img-modal">-->
                <ng-select class="form-control input_2 padding-holder custom-0 cus-0" placeholder="Select Vendor"
                  style="height: 30px;" [items]="vendorList" bindLabel="first_name" bindValue="id"
                  formControlName="vendor_id" [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                </ng-select>
                <div *ngIf="mSubmit && mf.vendor_id.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="mf.vendor_id.errors.required">Vendor Required</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer my-foot border"
          style="background-color: #f1f1f1;padding-bottom: 5px !important; border-radius: 10px;">
        </div>
        <div class="col-md-12" style="padding-right:0px ; margin-top: 5px">
          <div class="col-md-3  zero-padding" style="float: right;margin-right: 0px">
            <div class="col-md-6 zero-padding" style="padding-right: 3px">
              <button class="btn btn-block close-0 close-height" data-dismiss="modal" aria-label="Close">
                Close
              </button>
            </div>
            <div class="col-md-6 zero-padding" style="padding-left: 2px;width:44.5%">
              <button class="btn btn-block send-0 send-height snd_ht" type="submit">
                Assign
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Channel popup start -->
<div id="channel" class="modal fade" role="dialog" [ngClass]="darkMode ? 'darkOn' : 'null'">
  <div class="modal-dialog modal-lg modal-width">
    <div class="modal-content card-null-null" (mouseover)="dodrag('channel')" (mouseout)="dontdrag('channel')">
      <div class="alert alert-box alert-dismissible show mb-0 border-bread card-null-null" id="grab-5" style="
          height: 45px;
          cursor: grab;
          width: 100.4%;
          margin-left: -1px;
          margin-top: -1px;
          padding-top: 11px;
          font-size: 16px;
        ">
        Connections
        <button aria-label="Close" class="close" data-dismiss="modal" type="button" style="margin-top: 4px">
          <strong id="strong">✖</strong>
        </button>
      </div>
      <div class="scroll" style="height: 296px">
        <div class="col-md-12 editable-column pr-0" style="padding-right: 7px">
          <div class="col-md-6 pl-0 pr-0">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <label for="allS">
                    <div class="BbarChannel kk">OTA's</div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" *ngFor="let ec of box_channels_extarnal">
                <div class="div-block-channels-grid card-null-null">
                  <label>
                    <span class="con-chan card-null-null" style="display: flex; justify-content: center">
                      <img alt="" class="image-137 pr-5 pl-5" src="assets/img/svgicons/otas/{{ ec?.image }}" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 pl-0 pr-0">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <label for="allSC">
                    <div class="BbarChannel kk blue-bbr">Channels</div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12" *ngFor="let ic of box_channels_internal">
                <div class="div-block-channels-grid card-null-null">
                  <label>
                    <span class="con-chan card-null-null channel-width" style="display: flex; justify-content: center">
                      <img alt="" class="image-137 pr-5 pl-5" src="assets/img/svgicons/channels/{{ ic?.image }}" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- bulk update for tesing start start -->

<div id="bulk_updateTest" class="modal fade bulk_updatemodal" role="dialog" style="width: 100%"
  [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
  <form [formGroup]="reservation_bulk" *ngIf="this.reservation_bulk">
    <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <div class="bluheader booking-sumary" (mouseover)="dodrag('bulk_updateTest')"
            (mouseout)="dontdrag('bulk_updateTest')">
            <h3 class="h3_blu_header bookingsummary">Reservation Testing</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              style="color: white; line-height: 34px">
              <strong id="cross">&#x2716;</strong>
            </button>
          </div>
        </div>

        <div class="modal-body dFlex">
          <div class="leftD">
            <div class="col-md-6 zero-padding">
              <div class="col-md-12 zero-padding" style="padding-right: 2px">
                <div class="bluheader booking-sumary redbar" style="display: flex;justify-content: space-between;">
                  <h3 class="h3_blu_header bookingsummary">Stays
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width:90%" [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                        {{toolChkData}}
                      </div>
                    </i>
                  </h3>
                  <!-- <input type="checkbox" class="applyAll2" (change)="getValA($event)" [(ngModel)]="seperA"
                    [ngModelOptions]="{standalone: true}" id="checkedForSep"> -->
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-0ne onee"
                style="padding-right: 2px; padding-bottom: 5px; height: 255px">
                <div class="greyBox">
                  <!-- <div class="modal-bar-2 dynamoColor" [ngStyle]="{'border-color': randomColors[0%randomColors.length]}">
                  <img
                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg' : 'dashboard/check-in.svg'}}"
                    alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control "
                    style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                    placeholder="Check In" value="">
                </div>
                <div class="modal-bar-2 dynamoColor">
                  <img
                    src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg':'dashboard/departure.svg'}}"
                    alt="" class="placeholder-img-modal">
                  <input type="text" class="form-control "
                    style="height:30px; border:none; padding:10px 0px 10px 35px; color:#00205b; font-size:14px"
                    placeholder="Check Out" value="">
                </div> -->

                  <div class="modal-bar-2 one">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px; border: none; padding-left: 0px">
                      <span class="input-group-addon" style="
                          background: transparent;
                          border: none;
                          font-size: 24px;
                          color: #00205b;
                          display: inline;
                          padding-right: 1px;
                        ">
                        <!-- <i class="fa fa-calendar-alt"></i> -->
                        <img src="assets/img/newstaticicons/dashboard/check-in.svg" style="height: 24px" lt="" />
                      </span>
                      <input type="hidden" name="date_to" />
                      <input type="hidden" name="date_from" />
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" placeholder="{{
                          current_arrival?.date_booked
                        }} Check In" [locale]="{ format: 'DD MMM YYYY', separator: 'To ' }" [singleDatePicker]="true"
                        [(ngModel)]="date_arrival_bulk" formControlName="date_arrival_bulk" [minDate]="minDate"
                        [maxDate]="maxDate" />
                    </div>
                  </div>
                  <div *ngIf="submitted && m.date_arrival_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.date_arrival_bulk.errors.required">Required</strong>
                  </div>
                  <div class="modal-bar-2 two">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px; border: none; padding-left: 0px">
                      <span class="input-group-addon" style="
                          background: transparent;
                          border: none;
                          font-size: 24px;
                          color: #00205b;
                          display: inline;
                          padding-right: 1px;
                        ">
                        <img src="assets/img/newstaticicons/{{
                            darkMode
                              ? 'inventory/forchk/check-in.svg'
                              : 'dashboard/departure.svg'
                          }}" style="margin-left: 6px; height: 24px" alt="" />
                      </span>
                      <input type="hidden" name="date_to" />
                      <input type="hidden" name="date_from" />
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" placeholder="{{
                          current_arrival?.date_booked
                        }} Check Out" [locale]="{ format: 'DD MMM YYYY', separator: ' To ' }" [singleDatePicker]="true"
                        [(ngModel)]="date_departure_bulk" formControlName="date_departure_bulk" [minDate]="minDate"
                        [maxDate]="maxDate" />
                    </div>
                  </div>
                  <div *ngIf="submitted && m.date_departure_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.date_departure_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 timePickerEta dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/ETA-clock.svg" alt="" class="placeholder-img-modal" />
                    <input type="time" class="form-control" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " placeholder="ETA" value="" formControlName="eta_bulk" />
                  </div>
                  <div class="modal-bar-2 timePickerEtd dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/ETD-clock.svg" alt="" class="placeholder-img-modal" />
                    <input type="time" class="form-control" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " placeholder="ETD" value="" formControlName="etd_bulk" />
                  </div>
                  <div class="modal-bar-2 dynamoColor">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="" src="assets/img/newstaticicons/inventory{{
                          darkMode ? '/night-old.svg' : '/night.svg'
                        }}" style="height: 20px; margin-left: 2px" />
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px; width: 90%">
                      <input class="form-control" placeholder="Total Nights"
                        style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px;"
                        type="text" value="" [(ngModel)]="totalnights_bulk" formControlName="totalnights_bulk" />
                    </div>
                  </div>
                  <div class="modal-bar-2 dynamoColor" style="margin-bottom: 5px">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="" src="assets/img/newstaticicons/inventory/return.svg"
                        style="height: 20px; margin-left: 0px; margin-top: 2px" />
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px; width: 90%">
                      <input class="form-control" placeholder="Total Nights" style="
                          height: 30px;
                          border: none;
                          padding: 0px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                          padding-left: 0px;
                        " type="text" value="New" />
                    </div>
                  </div>
                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/arrivals/id-verified-light.svg" alt=""
                      class="placeholder-img-modal channel-img" style="margin-top: 4px" />
                    <input type="text" class="form-control" value="" placeholder="Confirmation Id No" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " maxlength="16" />
                  </div>
                  <div class="modal-bar-2 dynamoColor" id="flip3" style="margin-bottom: 0px">
                    <div *ngFor="
                        let update_weather of weather_update;
                        let i = index
                      ">
                      <i class="wi wi-main wi-{{ update_weather.icon }}"
                        [ngStyle]="{ color: WHcolors[i % WHcolors.length] }"></i>
                      <div class="weatherDiv">
                        <span class="wh_name">{{ update_weather.title }} </span>
                        <span class="wh_temp">{{ update_weather.min_temp + "°" }}
                        </span>
                        <span class="wh_con">{{ update_weather.condition }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 zero-padding">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">
                <div class="bluheader booking-sumary redbar">
                  <h3 class="h3_blu_header bookingsummary">Payment</h3>
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-four onee "
                style="padding-left: 1px; padding-bottom: 5px; height: 255px">
                <div class="greyBox">
                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt="" class="placeholder-img-modal" />
                    <input type="text" class="form-control" value="" placeholder="Cardholder Name" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " value="" formControlName="payee_name_bulk" />
                  </div>

                  <div *ngIf="submitted && m.payee_name_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.payee_name_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt="" class="placeholder-img-modal" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder=" Card Type " style="
                        height: 30px;
                        border: none;
                        padding-left: 35px;
                        padding-right: 7px;
                      " formControlName="guest_card_type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                      <ng-option value="Discover">Discover</ng-option>
                      <ng-option value="AX">American Express</ng-option>
                      <ng-option value="CB">Carte Blanche</ng-option>
                      <ng-option value="DN">Diners Club</ng-option>
                      <ng-option value="DS">Discover Card</ng-option>
                      <ng-option value="JC">Japan Credit Bureau</ng-option>
                      <ng-option value="MC">MasterCard</ng-option>
                      <ng-option value="UP">China Union Pay</ng-option>
                      <ng-option value="VI">Visa</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && m.guest_card_type_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.guest_card_type_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                      class="placeholder-img-modal" />
                    <input type="text" class="form-control" value="" placeholder="Card Number" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " value="" formControlName="guest_card_bulk" />
                  </div>

                  <div *ngIf="submitted && m.guest_card_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.guest_card_bulk.errors.required">Required</strong>

                    <strong *ngIf="m.guest_card_bulk.errors.pattern">Card no invalid</strong>


                  </div>

                  <div class="col-md-6 zero-padding" style="padding-right: 2px">
                    <div class="modal-bar-2 twenty3" [ngStyle]="{
                        'border-color': randomColors[20 % randomColors.length]
                      }">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal" />
                      <input class="form-control" name="x" size="5" maxlength="5"
                        onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                        style="
                          height: 30px;
                          border: none;
                          padding: 10px 0px 10px 35px;
                          color: #00205b;
                          font-size: 14px;
                        " value="" formControlName="guest_card_expiry_bulk" placeholder="MM / YY" />
                    </div>

                    <div *ngIf="submitted && m.guest_card_expiry_bulk.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="m.guest_card_expiry_bulk.errors.required">Required</strong>
                    </div>

                    <!-- <div *ngIf="submittedUpodated && m.booking_items['controls'][i]['controls'].card_expiry.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong
                        *ngIf="m.booking_items_bulk['controls'][i]['controls'].card_expiry_bulk.errors.required">Required</strong>
                    </div> -->
                  </div>

                  <div class="col-md-6 zero-padding" style="padding-left: 3px">
                    <div class="modal-bar-2 twenty3" [ngStyle]="{
                        'border-color': randomColors[21 % randomColors.length]
                      }">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal" />
                      <input type="number" class="form-control" value="" placeholder="CVV" style="
                          height: 30px;
                          border: none;
                          padding: 10px 0px 10px 35px;
                          color: #00205b;
                          font-size: 14px;
                        " pattern="/^\d+$/" onKeyPress="if(this.value.length==4) return false;" value=""
                        formControlName="guest_card_series_code_bulk" />
                    </div>
                    <!-- <div *ngIf="submittedUpodated && m.booking_items_bulk['controls'][i]['controls'].card_cvv.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong
                        *ngIf="m.booking_items_bulk['controls'][i]['controls'].card_cvv.errors.required">Required</strong>
                    </div> -->

                    <div *ngIf="submitted && m.guest_card_series_code_bulk.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="m.guest_card_series_code_bulk.errors.required">Required</strong>
                    </div>
                  </div>

                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/channel.svg" alt="" class="placeholder-img-modal" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Channel"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                      height: 30px;
                      border: none;
                      padding-left: 35px;
                      padding-right: 7px;
                    " [items]="allChannels" bindLabel="name" bindValue="id" formControlName="channel_id_bulk"
                      (change)="getChannelId($event)">
                    </ng-select>
                  </div>

                  <div *ngIf="submitted && m.channel_id_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.channel_id_bulk.errors.required">Required</strong>
                  </div>

                  <!-- <div class="modal-bar-2  dynamoColor">
                                  <img src="assets/img/newstaticicons/inventory/promo-code-small.svg" alt="" class="placeholder-img-modal">
                                  <input type="text" class="form-control" value="" placeholder="Promocode"
                                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px" value="{{onlyShowBooking?.promo}}" readonly>
                              </div> -->
                  <div class="modal-bar-2 uii dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal" />
                    <!-- <input type="text" class="form-control" value="" placeholder="Payment Status"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;text-transform: capitalize;"
                      value="" formControlName="payment_status_bulk" >
                      <ng-option value="paid">Paid</ng-option>
                      <ng-option value="unpaid">Unpaid</ng-option> -->

                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Payment Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                        height: 30px;
                        border: none;
                        padding-left: 35px;
                        padding-right: 7px;
                      " formControlName="payment_status_bulk">
                      <ng-option value="paid">Paid</ng-option>
                      <ng-option value="unpaid">Unpaid</ng-option>
                    </ng-select>
                  </div>

                  <div *ngIf="submitted && m.payment_status_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.payment_status_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 uii dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal" />
                    <!-- <input type="text" class="form-control" value="" placeholder="Booking Status"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;text-transform: capitalize;"
                      value="" formControlName="status_bulk" >
                      <ng-option value="confirm">Confirmed</ng-option>
                      <ng-option value="hold">Hold</ng-option> -->
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Booking Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                        height: 30px;
                        border: none;
                        padding-left: 35px;
                        padding-right: 7px;
                      " value="" formControlName="status_bulk">
                      <ng-option value="confirm">Confirmed</ng-option>
                      <ng-option value="hold">Hold</ng-option>
                    </ng-select>
                  </div>

                  <div *ngIf="submitted && m.status_bulk.errors" class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.status_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 dynamoColor" style="margin-top: 5px; margin-bottom: 0px">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="" src="assets/img/newstaticicons/arrivals/{{
                          darkMode ? 'coins' : 'coins-light'
                        }}.svg" style="height: 20px; margin-left: 0px; margin-top: 2px" />
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px; width: 90%">
                      <input class="form-control" placeholder="Currency" value="" style="
                          height: 30px;
                          border: none;
                          padding: 0px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                          padding-left: 0px;
                        " type="text" value="" formControlName="currency_bulk" readonly />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 zero-padding">
              <div class="col-md-12 zero-padding" style="padding-right: 2px">
                <div class="bluheader booking-sumary redbar " style="padding-right: 10px; margin-top: 5px">
                  <h3 style="display: inline-block" class="h3_blu_header bookingsummary">
                    Who's Checking In
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width: 91% !important; top: -32px !important"
                        [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                        If the guest checking in is not the same as payee: Guest
                        will be required to give credit card information for In
                        hotel expenses
                      </div>
                    </i>
                  </h3>
                </div>
              </div>

              <div class="col-md-12 zero-padding col-12-two oneeee"
                [ngStyle]="{ 'padding-right': '2px', 'padding-left': '1px' }">
                <div *ngFor="let m of bulkreservation; let i = index">
                  <div class="collapsableDiv">
                    <div>
                      <label class="label-mr" data-toggle="collapse" [attr.data-target]="'#demoo' + i">
                        {{ m.room_name }}
                        <img src="{{
                            darkMode
                              ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg'
                              : 'assets/img/reports/button_down.svg'
                          }}" alt="dropdown" class="collapseimg" (click)="rotateDrop('droop' + i)" />
                      </label>

                      <div class="collapse" [ngClass]="i == 0 ? 'in' : ' '" id="demoo{{ i }}">
                        <div class="cardD card-bodyY">
                          <hr style="
                              margin: 0px;
                              margin-bottom: 5px;
                              margin-top: 5px;
                            " />
                          <div class="modal-bar-2" [ngStyle]="{
                              'border-color':
                                randomColors[3 % randomColors.length]
                            }">
                            <img src="assets/img/newstaticicons/inventory{{
                                darkMode
                                  ? '/forchk/guest-name-infinity0'
                                  : '/forchk/full-name0'
                              }}.svg" alt="" class="placeholder-img-modal" style="height: 20px" />
                            <input type="text" class="form-control" placeholder="Guest Name" style="
                                height: 30px;
                                border: none;
                                padding: 10px 30px;
                                color: #00205b;
                                font-size: 14px;
                              " value="" formControlName="guest_firstname_bulk" />
                          </div>

                          <div class="modal-bar-2 one">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                              style="max-height: 30px;border: none;padding-left: 0px">
                              <span class="input-group-addon"
                                style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                <!-- <i class="fa fa-calendar-alt"></i> -->
                                <img
                                  src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'}}"
                                  style="margin-left: 6px;height: 24px;" lt="">
                              </span>
                              <input type="hidden" name="date_to">
                              <input type="hidden" name="date_from">
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check In"
                                [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                [minDate]="minDate" [maxDate]="maxDate" formControlName="check_in_bulk">
                            </div>
                          </div>

                          <div class="modal-bar-2 two">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                              style="max-height: 30px;border: none;padding-left: 0px">
                              <span class="input-group-addon"
                                style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                <img
                                  src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg':'dashboard/departure.svg'}}"
                                  style="margin-left: 6px;    height: 24px;" alt="">
                              </span>
                              <input type="hidden" name="date_to">
                              <input type="hidden" name="date_from">
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check Out"
                                [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                [minDate]="minDate" [maxDate]="maxDate" formControlName="check_out_bulk">
                            </div>
                          </div>


                          <!-- <div *ngIf="submitted && r.guest_firstname_bulk.errors"
                          class="small text-danger no-margin invalid-feedback">
                          <strong *ngIf="r.guest_firstname_bulk.errors.required">Required</strong>
                        </div> -->
                          <!-- <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[5%randomColors.length]}">
                        <img src="assets/img/dashboard/in-house2.svg" alt="" class="placeholder-img-modal"
                          style="height: 20px;">
                        <input type="text" class="form-control" placeholder="Booking Status"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px" value="">
                      </div> -->

                          <!-- <div class="guestCheckedIncard">
                        <div class="modal-bar-2" [ngStyle]="{'border-color':randomColors[8%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" placeholder="Card Number"
                            style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                            value="">
                        </div>
                        <div class="col-md-6 zero-padding" style="padding-right: 2px">
                          <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[9%randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" placeholder="Card Expiry"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="">
                          </div>
                        </div>
                        <div class="col-md-6 zero-padding" style="padding-left: 3px">
                          <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[2%randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" placeholder="CVV"
                              style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px"
                              value="">
                          </div>
                        </div>


                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[15%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="" placeholder="Personal Id"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[16%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="" placeholder="Id Number"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[20%randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                            class="placeholder-img-modal">
                          <input type="text" class="form-control" value="" placeholder="Phone Number"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                        <div class="modal-bar-2 " [ngStyle]="{'border-color':randomColors[29%randomColors.length]}">
                          <img
                            src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                            alt="" class="placeholder-img-modal">
                          <input type="text" class="form-control" value="" placeholder="Email"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                        </div>

                      </div> -->

                          <div class="modal-bar-2 filterAccec" [ngStyle]="{
                              'border-color':
                                randomColors[1 % randomColors.length]
                            }">
                            <div class="pointer">
                              <img class="img-responsive hovimg"
                                src="assets/img/newstaticicons/back-office/nosmoke.svg "
                                style="height: 35px; margin-top: 6px" *ngIf="m.smoking == 'false'" />
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/smoke.svg"
                                style="height: 35px; margin-top: 6px" *ngIf="m.smoking == 'true'" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/nowheel.svg"
                                style="height: 35px; margin-top: 6px" *ngIf="m.wheelchairaccessibility == 'false'" />
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/wheel.svg"
                                style="height: 35px; margin-top: 6px" *ngIf="m.wheelchairaccessibility == 'true'" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/nopets.svg"
                                style="height: 35px; margin-top: 6px" *ngIf="m.pets == 'false'" />
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/pets.svg"
                                style="height: 35px; margin-top: 6px" *ngIf="m.pets == 'true'" />
                            </div>
                          </div>

                          <div class="modal-bar-2" [ngStyle]="{
                              'border-color':
                                randomColors[7 % randomColors.length]
                            }">
                            <img src="assets/img/newstaticicons/inventory/{{
                                darkMode
                                  ? 'forchk/room-id-infinity1dark'
                                  : 'forchk/room-id-infinity1'
                              }}.svg" alt="" class="placeholder-img-modal-1" [ngClass]="
                                darkMode ? 'dark-room-Id' : 'light-room-Id'
                              " />
                            <input type="text" class="form-control" placeholder="Room Type" style="
                                height: 30px;
                                border: none;
                                padding: 10px 30px;
                                color: #00205b;
                                font-size: 14px;
                              " value="{{ m.room_name }}" />
                          </div>

                          <div class="modal-bar-2" [ngStyle]="{
                              'border-color':
                                randomColors[10 % randomColors.length]
                            }">
                            <img src="assets/img/newstaticicons/inventory{{
                                darkMode
                                  ? '/forchk/room_number.svg'
                                  : '/bed2.svg'
                              }}" alt="" style="
                                height: 22px;
                                width: 30px;
                                margin-top: 2px;
                                margin-left: -3px;
                              " class="placeholder-img-modal" />
                            <input type="text" class="form-control" placeholder="Room No" style="
                                height: 30px;
                                border: none;
                                padding: 10px 30px;
                                color: #00205b;
                                font-size: 14px;
                              " value="{{ m.room_number }}" />
                          </div>
                          <div class="modal-bar-2" [ngStyle]="{
                              'border-color':
                                randomColors[i % randomColors.length]
                            }" style="margin-bottom: 5px">
                            <img src="assets/img/newstaticicons/inventory/forchk/package.svg" alt=""
                              style="height: 26px; width: 24px" class="placeholder-img-modal imggg" />
                            <!-- <input type="text" class="form-control" placeholder="Promotion Name"
                          style="height: 30px;border: none;padding: 10px 30px;color: #00205b;font-size: 14px" value=""> -->

                            <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                              [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="Promotion Name" style="
                                height: 30px;
                                border: none;
                                padding-left: 45px;
                                padding-right: 7px;
                              " formControlName="package_id" [items]="pack" bindLabel="package_name" bindValue="id"
                              (change)="pro(selection)" [(ngModel)]="selection">
                            </ng-select>
                          </div>

                          <div class="row" style="padding: 0px 20px">
                            <div class="col-md-4 zero-padding" style="padding-right: 2px">
                              <div class="modal-bar-2" [ngStyle]="{
                                  'border-color':
                                    randomColors[32 % randomColors.length]
                                }">
                                <img src="assets/img/newstaticicons/inventory{{
                                    darkMode
                                      ? '/forchk/adult-dark.svg'
                                      : '/forchk/adult0.svg'
                                  }}" alt="" style="height: 20px" class="placeholder-img-modal" />
                                <input type="number" class="form-control inp adultPadding" placeholder="{{
                                    resWidth == 1366
                                      ? 'Ad..'
                                      : resWidth == 1440
                                      ? 'Adu..'
                                      : resWidth == 1600
                                      ? 'Adult.'
                                      : 'Adult'
                                  }}" value="" />
                              </div>
                            </div>

                            <div class="col-md-4 zero-padding" style="padding-left: 3px; padding-right: 2px">
                              <div class="modal-bar-2" [ngStyle]="{
                                  'border-color':
                                    randomColors[47 % randomColors.length]
                                }">
                                <img src="assets/img/newstaticicons/inventory{{
                                    darkMode
                                      ? '/forchk/child-infinity0'
                                      : '/child'
                                  }}.svg" alt="" style="height: 20px" class="placeholder-img-modal" />
                                <input type="number" class="form-control inp childPadding" placeholder="{{
                                    resWidth <= 1366
                                      ? 'Ch..'
                                      : resWidth <= 1440
                                      ? 'Chi..'
                                      : resWidth <= 1600
                                      ? 'Child.'
                                      : 'Children'
                                  }}" value="" />
                              </div>
                            </div>

                            <div class="col-md-4 zero-padding" style="padding-left: 3px">
                              <div class="modal-bar-2" [ngStyle]="{
                                  'border-color':
                                    randomColors[30 % randomColors.length]
                                }">
                                <img src="assets/img/newstaticicons/inventory/{{
                                    darkMode ? 'infant-yellow' : 'infant'
                                  }}.svg" alt="" style="height: 20px" class="placeholder-img-modal" />
                                <input type="number" class="form-control inp infantsPadding" placeholder="{{
                                    resWidth == 1366
                                      ? 'In..'
                                      : resWidth == 1440
                                      ? 'Inf..'
                                      : resWidth == 1600
                                      ? 'Infan.'
                                      : 'Infant'
                                  }}" value="" />
                              </div>
                            </div>
                          </div>
                          <div class="modal-bar-2" [ngStyle]="{
                              'border-color':
                                randomColors[20 % randomColors.length]
                            }" style="
                              margin-bottom: 0px;
                              display: flex;
                              justify-content: space-around;
                            ">
                            <label for="" class="pcom" (click)="
                            chkRadio('test1' + i, 'paid', 'test2' + i, 'comp')
                          ">
                              <input type="radio" id="test1{{ i }}" value="paid" formControlName="breakfast" />
                              <label for="test1{{ i }}" id="paid">Paid</label>
                            </label>
                            <label for="" class="pcom" (click)="
                            chkRadio('test1' + i, 'paid', 'test2' + i, 'comp')
                          ">
                              <input type="radio" id="test2{{ i }}" value="complimentry" formControlName="breakfast" />
                              <label for="test2{{ i }}" id="comp">Complimentry</label>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 zero-padding">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">
                <div class="bluheader booking-sumary redbar" style="margin-top: 5px">
                  <h3 class="h3_blu_header bookingsummary">
                    Booked By
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                      <div class="tooltip" style="width: 68% !important; top: -29px"
                        [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                        Check if the room payee is same as guest payment
                        information will be auto polpulated
                      </div>
                    </i>
                  </h3>
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-three onee "
                style="padding-left: 1px; height: 255px; padding-right: 0px">
                <div class="greyBox">
                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory{{
                        darkMode
                          ? '/forchk/full-name-infinity0'
                          : '/forchk/guest-name0'
                      }}.svg" alt="" class="placeholder-img-modal" style="height: 20px" />
                    <input type="text" class="form-control" value="" formControlName="payee_name_bulk"
                      placeholder="Full Name" style="
                        height: 30px;
                        border: none;
                        padding: 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " value="" />
                  </div>

                  <div *ngIf="submitted && m.payee_name_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.payee_name_bulk.errors.required">Required</strong>
                  </div>

                  <!-- <div class="modal-bar-2 dynamoColor">
                                          <div class="my-icon-0" style="padding-top: 3px">
                                              <img class="placeholder-img-modal"
                                                  src="assets/img/newstaticicons/inventory/r-call-red.svg" alt="" />
                                          </div>
                                          <div class="text-block-18 text calander caljj"
                                              formControlName="guest_phone_bulk"
                                              style="float: left; padding-top: 4px; width: 90%">
                                              <input type="text" class="form-control" value="" placeholder="Cell No"
                                                  style="
                          height: 30px;
                          border: none;
                          padding: 10px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                        " value="" />
                                          </div>
                                      </div>

                                      <div *ngIf="submitted && m.guest_phone_bulk.errors"
                                          class="small text-danger no-margin invalid-feedback">
                                          <strong *ngIf="m.guest_phone_bulk.errors.required">Required</strong>
                                      </div> -->

                  <div class="modal-bar-2 dynamoColor">
                    <div class="my-icon-0" style="padding-top: 4px">
                      <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory/{{
                          darkMode ? 'forchk/mail-infinity0' : '/r-messege-red'
                        }}.svg" alt="" />
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px; width: 90%">
                      <input type="text" class="form-control" value="" placeholder="Email" style="
                          height: 30px;
                          border: none;
                          padding: 10px 10px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                        " value="" formControlName="guest_email_bulk" />
                    </div>
                  </div>

                  <div *ngIf="submitted && m.guest_email_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.guest_email_bulk.errors.required">Required</strong>
                  </div>

                  <!-- <div class="modal-bar-2 dynamoColor">
                                          <div class="text-block-18 text calander caljj"
                                              style="float: left; padding-top: 4px; width: 90%">
                                              <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory{{
                          darkMode
                            ? '/darkmode_arrival/location.svg'
                            : '/location.svg'
                        }}" style="height: 20px" alt="" />
                                              <input type="text" class="form-control" value="" placeholder="Address"
                                                  style="
                          height: 30px;
                          border: none;
                          padding: 10px 0px 10px 35px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                        " value="" formControlName="guest_phone_bulk" />
                                          </div>
                                      </div>

                                      <div *ngIf="submitted && m.guest_phone_bulk.errors"
                                          class="small text-danger no-margin invalid-feedback">
                                          <strong *ngIf="m.guest_phone_bulk.errors.required">Required</strong>
                                      </div> -->

                  <div class="modal-bar-2 dynamoColor">
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px; width: 90%">
                      <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory{{
                          darkMode
                            ? '/darkmode_arrival/location.svg'
                            : '/location.svg'
                        }}" style="height: 20px" alt="" />
                      <input type="text" class="form-control" value="" placeholder="City" style="
                          height: 30px;
                          border: none;
                          padding: 10px 0px 10px 35px;
                          color: #00205b;
                          font-size: 14px;
                          margin-top: -4px;
                        " value="" formControlName="guest_address_bulk" />
                    </div>
                  </div>

                  <div *ngIf="submitted && m.guest_address_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.guest_address_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 uii dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="ID Type"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                        height: 30px;
                        border: none;
                        padding-left: 35px;
                        padding-right: 7px;
                      " formControlName="personal_id_type_bulk">
                      <ng-option value="State ID">State ID</ng-option>
                      <ng-option value="Driving License">Driving License</ng-option>
                      <ng-option value="Passport">Passport</ng-option>
                    </ng-select>
                  </div>

                  <div *ngIf="submitted && m.personal_id_type_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.personal_id_type_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                      class="placeholder-img-modal id-type" style="height: 20px" />
                    <input type="text" class="form-control" value="" placeholder="ID Number" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " value="" formControlName="personal_id_value_bulk" />
                  </div>

                  <div *ngIf="submitted && m.personal_id_value_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.personal_id_value_bulk.errors.required">Required</strong>
                  </div>

                  <div class="modal-bar-2 mb-0 dynamoColor">
                    <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Priviliges" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                        height: 30px;
                        border: none;
                        padding-left: 35px;
                        padding-right: 7px;
                      " formControlName="privilege_bulk">
                      <ng-option value="Loyalty Card Holder">Loyality Card Holder</ng-option>
                      <ng-option value="Discount Offers">Discount Offers</ng-option>
                      <ng-option value="None">None</ng-option>
                    </ng-select>
                  </div>

                  <div *ngIf="submitted && m.privilege_bulk.errors"
                    class="small text-danger no-margin invalid-feedback">
                    <strong *ngIf="m.privilege_bulk.errors.required">Required</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightD" style="right: 0px;z-index: -1;" id="sideBarDetailShow"
            [ngClass]="darkMode ? 'dark':'light'">
            <div class="dFlex dataPrevDiv">
              <div class="data">
                <div>
                  <div class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">
                    <h3 class="h3_blu_header bookingsummary"></h3>
                  </div>
                  <div class="bluheader booking-sumary" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">
                    <h3 class="h3_blu_header bookingsummary">Promotion Name</h3>
                  </div>

                  <div class="modal-bar-2 twenty6 mt-5 dFlex rateDive"
                    *ngFor="let price of prices_bulk_Array; let i = index">
                    <div>{{price.date | date: "EEE dd MMM" }}</div>
                    <div [ngClass]="darkMode ? 'darkDD' : 'null'">{{currency_format}} {{price.price}}
                    </div>


                  </div>
                </div>
              </div>

              <div class="totalDiscount">
                <div>
                  <div [ngClass]="darkMode ? 'DarkDic' : 'null'">
                    <div class="modal-bar-2 twenty60 dynamoColor" style="  height: auto; padding: 0px;"
                      [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                      <textarea name="" id="" cols="30" rows="5" placeholder=" Special requests" value=""></textarea>
                    </div>
                    <div class="modal-bar-2 twenty57 dFlex segmo">
                      <label (click)="changeSegment(taxInclusivee,'segmon')"
                        [ngClass]="segmonLeft ? 'active' : 'null'">Tax Inclusive</label><label
                        (click)="changeSegment(taxInclusivee,'segmon')" [ngClass]="!segmonLeft ? 'active' : 'null'">Tax
                        Exclusive</label>
                      <div class="segmon" id="segmon"></div>
                    </div>
                    <div class="modal-bar-2 twenty6 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/arrivals{{  darkMode ? '/addpersonicon1.svg' : '/addpersonicon1.svg'  }}"
                        alt="" style="height: 20px;" class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="Extra Person"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        value="">
                    </div>

                    <div class="modal-bar-2 twenty6 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/arrivals{{darkMode ? '/amount-deposite.svg' : '/amount-deposite.svg' }}"
                        alt="" style="height: 20px;" class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="Cash Deposite"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        value="">
                    </div>

                    <div class="modal-bar-2 twenty6 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/arrivals{{darkMode ? '/discount-light.svg' : '/discount-light.svg' }}"
                        alt="" style="height: 20px;" class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="Discount"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        value="">
                    </div>



                    <div class="modal-bar-2 twenty5  mb-0 dynamoColor">
                      <img
                        src="assets/img/newstaticicons/arrivals{{ darkMode ? '/dollar-light.svg': '/dollar-light.svg' }}"
                        alt="" style="height: 20px;" class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="Total Amount"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        value="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="roundCurve" (click)="sideMenu('sideBarDetailShow')">
              <i class="fa fa-caret-right"></i>
            </div>
          </div>
        </div>



        <div class="modal-footer my-foot" style="padding-bottom: 5px !important; display: none"></div>
        <div class="col-md-12" style="padding-right: 0px; margin-top: 5px">
          <div class="popupBtns">
            <button class="btn btn-block send-0" *ngIf="!disabled" (click)="onSubmit()">
              Save
            </button>
            <button class="btn btn-block send-0" (click)="viewBtn()" *ngIf="disabled">
              Edit
            </button>

            <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- bulk update for tesing start end -->

<!-- Restriction Modal -->
<div class="modal fade" id="ReModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="col-md-10 pl-0 pr-0">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" (mouseover)="dodrag('ReModal')"
          (mouseout)="dontdrag('ReModal')" id="grab-6" style="cursor: grab; height: 45px"
          [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
          Select Restriction
          <button type="button" class="close" data-dismiss="modal">
            <strong>&#x2716;</strong>
          </button>
        </div>
        <div class="col-md-12 editable-column height-99 pr-0" style="background-color: #fff" [ngClass]="
            darkMode ? 'card-dark-modal-body_restriction' : ' card-null '">
          <div class="col-md-4 pl-25 br-bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.ctd" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/ctd.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    CTD
                    <!-- {{ "inventory.table.ctd" | translate }} -->
                  </p>
                </label>
              </label>
            </div>
          </div>

          <div class="col-md-4 pl-25 br-bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" [(ngModel)]="res_obj.stop_sell" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/stop-sell.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    Stop sell
                    <!-- {{ "inventory.table.stop_sell" | translate }} -->
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 pl-25 bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" [(ngModel)]="res_obj.cta" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/cta.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    CTA
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12 editable-column pr-0" style="background-color: #fff; border-radius: 0px 0px 10px 10px"
          [ngClass]="
            darkMode ? 'card-dark-modal-body_restriction' : ' card-null '
          ">
          <div class="col-md-6 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #ms_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.min_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!ms_el.checked"
                    [(ngModel)]="res_obj.min_stay.value" min="1" (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-bottom: 0px">
                    Min Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-6 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #mx_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.max_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!mx_el.checked"
                    [(ngModel)]="res_obj.max_stay.value" min="1" (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-bottom: 0px">
                    Max Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div class="actions">
          <a class="pull-right btn btn-info btn-hov-save" data-dismiss="modal"
            [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov'">save</a>
        </div>
      </div>
    </div>
  </div>
</div>
