<!-- Food and Beverages (Setup_Shop-Page) work start -->

<div class="main-div">
    <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum': ''">
        <div class="row">
            <div class="column-wrapper display-flex space-between">
                <div>
                    <ul class="nav navbar-nav tabsBar">
                        <a class="btn btn-link" routerLink="/app/back_office/manage_menu"
                            routerLinkActive="active">MANAGE MENU</a>
                        <a class="btn btn-link" routerLink="/app/back_office/manage_shop"
                            routerLinkActive="active">MANAGE SHOP</a>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="card inputCard" [ngClass]="darkMode ? 'card_dark': ''">
        <form action="">
            <ng-container *ngFor="let name of shop_names;index as i">
                <div class="row">
                    <div class="col-md-11 inputCat">
                        <div class="modal-bar border-7">
                            <input class="form-control shopname" placeholder="First Name" type="text"
                                [value]="name.shop_name" name="shop-name{{i}}" [(ngModel)]="name.shop_name"
                                id="shop{{name.shop_id}}" #shopNameInput readonly>
                        </div>
                    </div>
                    <div class="col-md-1 inputCat2">
                        <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit"
                            (click)="editShop(name.shop_id)">
                        <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" data-toggle="modal"
                            data-target="#confirmationModal-3" (click)="deleteShop(i)">
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" [ngClass]="darkMode ? 'btnDark': ''" (click)="saveShopEdit()">Save</button>
    </div>
</div>