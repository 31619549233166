<div class="container-fluid pt-5" id="componentID">
  <div class="pt_merchant_info">
    <div id="draggable2" class="pt_merchant_info " style="width: 400px;height: 337px;" cdkDrag>

      <div class="scroll" [ngClass]="darkMode ? 'dark_popup' : 'null' ">

        <div class="pt_container_inner invoice-head">
          <div style="display: flex; justify-content: space-between; width: 95%; align-items: center;">
            <span class="property_name">
              {{ property?.name }}
            </span>
            <img style="border-radius: 50%;height: 50px;"
              src="{{ property ? base_url2+'/app/images/' + property.images[0].image : 'assets/img/nav/user.png' }}"
              alt="logo" class="">
            <!-- <img style="border-radius: 50%;"
               src="{{ user ? base_url2+'/app/images/' + user : 'assets/img/nav/user.png' }}" alt="logo"
               class="pt_merch_logo mt-4"> -->
          </div>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <strong id="cross" style="font-size: 21px; font-weight: 400; color:#8aee9e;">&#x2716;</strong>
          </button>
        </div>
        <!-- <div class="text-center tax_id">
                       <span>Tax ID: 12348567815</span>
                     </div> -->

        <div class="main">
          <div class="bluheader booking-sumary "
            style="display: block;   margin-bottom: 5px !important; border-radius: 8px; height: 40px; margin-bottom: 0px; margin-top: 5px; padding: 0px 10px;">
            <div class="div">
              <span class="">{{currentDate | date: "dd-MMM-yyyy "}}</span>
              <h4 class="">Invoice receipt</h4>
              <span class="">{{currentDate | date: "hh:mm"}}</span>
            </div>
          </div>

          <div class="show2">
            <div class="mdiv" style=" display: flex; padding-left: 5px; width: 100%;">
              <div class="div2">
                <span style="color:white;">
                  {{invoice_arrival.isMaster ? 'Bulk Booking' : 'Booked By'}}
                </span>
              </div>
            </div>
          </div>

          <div class="card" style="padding: 5px 5px 1px 5px !important">

            <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/inventory/forchk/guest-name0.svg" width="20" height="16">
              </div>
              <div class="mdiv">
                <div class="div2">
                  <span class="">
                    {{formatGuestName(invoice_arrival?.guest_name)}}</span>
                </div>
              </div>
            </div>

            <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/dashboard/darkmode/reservation.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2">
                  <span class="">{{invoice_arrival.booking_id}}</span>
                </div>
              </div>
            </div>

            <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/inventory/channel.svg" style="height: 20px ; width: 25px;">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;align-items: center; width: 100%; color:#00205b">
                <span class="">{{invoice_arrival.booking.channel_name}}</span>
                <img src="assets/img/svgicons/smallicons/{{invoice_arrival.booking.channel_image}}"
                  style="height: 25px ; width: 25px;">
              </div>
            </div>

            <div class="bar one0" *ngIf="invoice_arrival.email"
              style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/inventory//r-messege-red.svg" style="height: 20px ; width: 25px;">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2">
                  <span class="">{{invoice_arrival.email}}</span>
                </div>
              </div>
            </div>

            <div class="bar one0" *ngIf="invoice_arrival.booking.privilege"
              style="padding: 0px; display:flex; align-items: center;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/inventory/pervilige.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2">
                  <span class="">{{invoice_arrival.booking.privilege}}</span>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!invoice_arrival.isMaster">
            <div class="show2 bluheader" style="margin-top: 5px;">
              <div class="mdiv" style=" display: flex;padding-left: 5px;width: 100%;">
                <div class="div2">
                  <span style="color:white;"> {{invoice_arrival.room.display_name && invoice_arrival.room.display_name
                    !=''?
                    invoice_arrival.room.display_name : invoice_arrival.room.roomtype.name}}</span>
                </div>
              </div>
            </div>

            <div class="card " style="padding: 5px 5px 1px 5px !important">

              <div class="date-box">
                <div class="season-date">
                  <!-- <span>{{convert_month(invoice_arrival.check_in)}}</span> -->
                  <img alt="" class="svgico capSvg" src="assets/img/invoices/check-in-black.svg">
                  <span> {{convert_date(invoice_arrival.check_in)}}</span>
                </div>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <span class="sname" style="color: #C318DE !important; font-weight: 700;" [ngStyle]="">
                  {{formatGuestName(invoice_arrival?.guest_name)}}
                </span>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <div class="season-date">
                  <!-- <span>{{convert_month(invoice_arrival.check_out)}}</span> -->
                  <img alt="" class="svgico capSvg" src="assets/img/invoices/check-out-black.svg">
                  <span> {{convert_date(invoice_arrival.check_out)}}</span>
                </div>
              </div>

              <div class="rateScroll">
                <!-- <div class="modal-bar-2 twenty60 dFlex rateDive"
     style="background-color: #f1f1f1; padding: 5px 10px !important; justify-content: left; margin-top: 5px;"
     *ngFor="let ratediv of roomRate();let i = index">
     <div style="align-items: left; "> {{date_type(ratediv.date)}}</div>
     <div [ngClass]="darkMode ? 'darkDD' : 'null'" style="align-items: right; ">
       {{currency}} {{ratediv.price}}
     </div>
   </div> -->
                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                  <div class="div1" style="padding: 0  10px;">
                    <img src="assets/img/newstaticicons/inventory/bed2.svg" style="height: 20px ; width: 25px;">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                    <div class="div2" style="display: flex; align-items: center;justify-content: space-between;">
                      <span class="">{{invoice_arrival.roomnum.room_number}}</span>
                    </div>

                  </div>
                </div>
                <div class="modal-bar-2 one0 dFlex rateDive">
                  <div style="align-items: left; ">
                    <img alt=" " class="image-5" src="assets/img/newstaticicons/inventory/night.svg "
                      style="height: 22px;">
                    {{roomNightStay(invoice_arrival.booking.date_departure,invoice_arrival.booking.date_arrival)}}
                  </div>
                </div>
                <div class="modal-bar-2 one0 dFlex">
                  <div class="cash-amount">
                    <div [ngClass]="darkMode ? 'darkDD' : 'cash-amount'">
                      <img alt=" " class="image-5" src="assets/img/inventory/cash-green.svg"
                        style="height: 22px;padding-right: 8px;">
                      {{currency}} {{calculateNightPrice(invoice_arrival.pricesjson) | number : '1.2-2'}}
                    </div>
                  </div>
                  <button class="btn gold-btn greenBtn">{{invoice_arrival.booking.payment_status}}</button>
                </div>
              </div>
            </div>

            <div class="room-service" *ngIf="(+invoice_arrival.fb_price) !== 0">
              <div class="show2" style="margin-top: 5px;">
                <div class="mdiv" style=" display: flex; padding-left: 5px; width: 100%;">
                  <div class="div2 discount">
                    <span style="color:white;">Room Service</span>
                    <a class="no-print btn-block mt-1 icon-btn">
                      <img src="assets/img/reports/share_dark.svg" style="height: 14px; width: 14px;">
                    </a>
                  </div>
                </div>
              </div>

              <div class="card " style="padding: 5px 5px !important; margin-bottom: 5px">

                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                  <div class="div1" style="padding: 0  10px;">
                    <!-- <span style="color: #00205b; font-weight: 700; ">Sub</span> -->
                    <img src="assets/img/invoices/fnb-icon.svg" width="25" height="20">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;align-items: center; width: 100%; color:#00205b">
                    <div class="div2">
                      <!-- <input type="number" class="form-control" [(ngModel)]="fbPrice" (input)="calculateTotalWithFb($event)"
                     placeholder="Price" id="price"
                     style="height: 30px; border: none;color: #00205b;font-size: 14px; background-color: transparent; " /> -->
                      <span>{{currency}} {{invoice_arrival.fb_price | number : '1.2-2'}}</span>
                    </div>
                    <button class="btn gold-btn orangeBtn">Due</button>
                  </div>
                </div>

                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                  <div class="div1" style="padding: 0  10px;">

                    <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" width="25" height="20">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                    <div class="div2">
                      <span>{{invoice_arrival.fb_tax}}%</span>
                    </div>
                    <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                      'Exclusive'}}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-ons">
              <div class="bluheader booking-sumary"
                style="margin: 5px 0; align-items: center; border-radius: 8px; height: 35px; padding: 0px 15px;">
                <h3 class="h3_blu_header bookingsummary">Service Add-Ons</h3>
                <button type="button" (click)="addExtra('service', extrasRecord)" class="addBtnExpensis"
                  [disabled]="invoice_arrival.status == 'Checked Out'">
                  <span id="cross">&#x2B;</span>
                </button>
              </div>

              <div class="addons-extras" *ngIf="displayAddonsNotEmpty('service', extrasRecord)">
                <ng-container *ngFor="let extras of extrasRecord; index as e">
                  <div class="card" *ngIf="extras.type == 'service'" style="margin-bottom: 5px;">
                    <div class="row m-0">
                      <div class="col-md-8 zero-padding d-flex">
                        <div class="bar one0 p-0 mr-1">
                          <select class="form-control extrasDropdown padding-holder custom-0 input-custom"
                            placeholder="Expense" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                            [(ngModel)]="extras.name" (ngModelChange)="updatePrice(extras, 'service')">
                            <ng-container *ngFor="let ext of getExtras('service')">
                              <option [value]="ext.name">{{ ext.name }}</option>
                            </ng-container>
                          </select>
                        </div>
                        <button class="btn-add">
                          <i class="fa fa-minus pr-1" (click)="updateCount(extras, -1, 'service')"></i>
                          {{extras.count}}
                          <i class="fa fa-plus pl-1" (click)="updateCount(extras, 1, 'service')"></i>
                        </button>
                      </div>
                      <div class="col-md-4 zero-padding">
                        <div class="removeExpense">
                          <div class="bar one0 p-0">
                            <!-- Display the price for the selected extra -->
                            <input type="number" class="form-control extrasAmount" placeholder="Price"
                              [value]="+extras.price_after_tax * extras.count">
                          </div>
                          <button class="redbar" (click)="removeExtra(extrasRecord,e)"><strong
                              id="cross">&#x2716;</strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="card">
                  <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                    <div class="div1" style="padding: 0  10px;">
                      <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" height="20" width="25">
                    </div>
                    <div class="mdiv"
                      style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                      <div class="div2">
                        <span>{{property.taxper}}%</span>
                      </div>
                      <div class="d-flex">
                        <span class="pr-2" *ngIf="getTaxPrice('service', extrasRecord)">{{currency}}{{
                          getTaxPrice('service', extrasRecord) |
                          number: '1.2-3' }}</span>
                        <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                          'Exclusive'}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bluheader booking-sumary"
                style="margin: 5px 0; align-items: center; border-radius: 8px; height: 35px; padding: 0px 15px;">
                <h3 class="h3_blu_header bookingsummary">Food | Mini Bar Add-Ons</h3>
                <button type="button" (click)="addExtra('minibar', extrasRecord)" class="addBtnExpensis"
                  [disabled]="invoice_arrival.status == 'Checked Out'">
                  <span id="cross">&#x2B;</span>
                </button>
              </div>

              <div class="addons-extras" *ngIf="displayAddonsNotEmpty('minibar', extrasRecord)">
                <ng-container *ngFor="let extras of extrasRecord; index as e">
                  <div class="card" *ngIf="extras.type == 'minibar'" style="margin-bottom: 5px;">
                    <div class="row m-0">
                      <div class="col-md-8 zero-padding d-flex">
                        <div class="bar one0 p-0 mr-1">
                          <select class="form-control extrasDropdown padding-holder custom-0 input-custom"
                            placeholder="Expense" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                            [(ngModel)]="extras.name" (ngModelChange)="updatePrice(extras,'minibar')">
                            <ng-container *ngFor="let ext of getExtras('minibar')">
                              <option [value]="ext.name">{{ ext.name }}</option>
                            </ng-container>
                          </select>
                        </div>
                        <button class="btn-add">
                          <i class="fa fa-minus pr-1" (click)="updateCount(extras, -1,'minibar')"></i>
                          {{extras.count}}
                          <i class="fa fa-plus pl-1" (click)="updateCount(extras, 1,'minibar')"></i>
                        </button>
                      </div>
                      <div class="col-md-4 zero-padding">
                        <div class="removeExpense">
                          <div class="bar one0 p-0">
                            <!-- Display the price for the selected extra -->
                            <input type="number" class="form-control extrasAmount" placeholder="Price"
                              [value]="+extras.price_after_tax * extras.count">
                          </div>
                          <button class="redbar" (click)="removeExtra(extrasRecord,e)"><strong
                              id="cross">&#x2716;</strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="card">
                  <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                    <div class="div1" style="padding: 0  10px;">
                      <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" height="20" width="25">
                    </div>
                    <div class="mdiv"
                      style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                      <div class="div2">
                        <span>{{property.food_tax_percentage}}%</span>
                      </div>
                      <div class="d-flex">
                        <span class="pr-2" *ngIf="getTaxPrice('minibar', extrasRecord)">{{currency}}{{
                          getTaxPrice('minibar', extrasRecord) |
                          number: '1.2-3' }}</span>
                        <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                          'Exclusive'}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="show2" style="margin-top: 5px;">
            <div class="mdiv" style=" display: flex; padding-left: 5px; width: 100%;">
              <div class="div2">
                <span style="color:white;">Payment Due</span>
              </div>
            </div>
          </div>

          <div class="card " style="padding: 5px 5px !important;">
            <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <!-- <span style="color: #00205b; font-weight: 700; ">Sub</span> -->
                <img src="assets/img/inventory/cash-red.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;align-items: center;width: 100%; color:#00205b">
                <!-- <span class="">{{currency}} {{invoice_arrival.subtotal}} </span> -->
                <span class="">{{currency}} {{calculateTotalNightsPrice() | number :
                  '1.2-2'}}</span>
                <button class="btn gold-btn"
                  [ngClass]="addActiveClass(invoice_arrival)">{{invoice_arrival.booking.payment_status}}</button>
              </div>
            </div>

            <div class="bar one0" *ngIf="(+invoice_arrival.fb_price) !== 0"
              style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/invoices/fnb-icon.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;align-items: center; width: 100%; color:#00205b">
                <div class="div2">
                  <span>{{currency}} {{invoice_arrival.fb_price | number : '1.2-2'}}</span>
                </div>
                <button class="btn gold-btn orangeBtn">Due</button>

              </div>
            </div>

            <div class="bar one0" *ngIf="calculateExtras() != 0"
              style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px; ">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/arrivals/extras_icon.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2" style="display: flex; align-items: center;">
                  <span class=""> {{currency}} {{calculateExtras() || 0 | number : '1.2-2'}}</span>
                </div>
                <div class="div3">
                  <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                    'Exclusive'}}</button>
                </div>
              </div>
            </div>

            <div class="bar one0"
              *ngIf="!(invoice_arrival.booking.extra_person_fee == 0 || invoice_arrival.booking.extra_person_fee == null )"
              style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
              <div class="div1" style="padding: 0  10px;">
                <img src="assets/img/newstaticicons/arrivals/addpersonicon1.svg" width="25" height="20">
              </div>
              <div class="mdiv">
                <div class="div2">
                  <span class="">{{currency}} {{invoice_arrival.booking.extra_person_fee | number : '1.2-2'}}</span>
                </div>
              </div>
            </div>

            <div class="bar one0 discount" *ngIf="invoice_arrival.booking.discount">
              <img src="assets/img/newstaticicons/arrivals/discount-light.svg" width="25" height="20">
              <div class="discount-per">
                <span type="number" class="form-control inputStyle">{{invoice_arrival.booking.discount}}%</span>
              </div>
              <div class="discount-per">
                <span type="number" class="form-control inputStyle">{{currency}} {{
                  ((calculateNightPrice(invoice_arrival.pricesjson) *
                  invoice_arrival.booking.discount) / 100) | number :
                  '1.2-2'}}</span>
              </div>
            </div>

            <div class="bar one0"
              *ngIf="(+invoice_arrival.booking.cashDeposit) !== 0 && invoice_arrival.booking.cashDeposit !== null"
              style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px; ">
              <div class="div1" style="padding: 0  10px;">

                <img src="assets/img/newstaticicons/arrivals/amount-deposite.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2" style="display: flex; align-items: center;">
                  <span class=""> {{currency}} {{invoice_arrival.booking.cashDeposit | number : '1.2-2'}}</span>
                </div>
              </div>
            </div>

            <div class="bar one0" *ngIf="invoice_arrival.booking.TaxInclusive == 'false'"
              style="padding: 0px; display:flex; align-items: center;">
              <div class="div1" style="padding: 0  10px;">

                <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" width="25" height="20">
              </div>
              <div class="mdiv"
                style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                <div class="div2">
                  <!-- <span class="">{{currency}} {{calculateTaxValue() | number : '1.2-2'}}</span> -->
                  <span class="">{{invoice_arrival.booking.Taxper | number : '1.2-2'}}</span>
                </div>
                <div class="div3">
                  <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                    'Exclusive'}}</button>
                  <!-- <span style="display: flex; align-items: center; font-weight: 700; padding-right: 5px;">
                   <span [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{invoice_arrival.booking.Taxper | number : '1.2-2'}}
                     %</span>
                   <img src="assets/img/newstaticicons/rates-packages/discount.svg"
           style="height: 35px; width: 35px; padding: 2px;" alt="">
                 </span> -->
                </div>
              </div>
            </div>

          </div>

          <div class="bluheader booking-sumary"
            style="display: block;   margin-bottom: 5px !important; border-radius: 8px; height: 35px; margin-bottom: 0px; margin-left: 0px; padding: 0px 10px;">
            <div class="div total">
              <ng-container>
                <span *ngIf="invoice_arrival.isMaster">Total</span>
                <button *ngIf="!invoice_arrival.isMaster" class="btn gold-btn orangeBtn">Due</button>
              </ng-container>

              <span>{{currency}} {{calculateTotal() | number : '1.2-2' }}</span>
            </div>
          </div>
        </div>

        <div
          style="display:flex; justify-content: space-between; margin-bottom:8px; margin-right: 5px; margin-left: 5px;">
          <a class="no-print btn btn-block mt-1 icon-btn">
            <img src="assets/img/reports/share_dark.svg" style="    height: 14px; width: 14px;">
            <!-- <span style="padding: 5px;">GO GREEN</span>  -->
          </a>

          <a class="no-print  btn-block mt-1">
            <!-- <i class="fa fa-print" aria-hidden="true" style="color: black; padding-right: 10px;"></i> -->
            <!-- <span style="padding: 5px;"> Print</span> -->
            <button class="no-print btn btn-block mt-1 checkoutBtn" *ngIf="invoice_arrival.status != 'Checked Out'"
              (click)="checkoutGuest()">Checkout</button>
            <button class="no-print btn btn-block mt-1 checkoutBtn" *ngIf="invoice_arrival.status == 'Checked Out'">
              Checked Out
            </button>
          </a>
          <a class="no-print btn-block mt-1 icon-btn">

            <!-- <span style="padding: 5px;"> Print</span> -->
            <!-- <button class="no-print  btn-block mt-1"
             style="background:#8aee9e !important;color:#fff;height: 30px; font-weight: 700;border-radius: 20px; border:none; display: flex; align-items: center; justify-content: center; margin-top: 0;"
             (click)="printDiv('printableArea')">Print</button> -->
            <button class="no-print btn-block mt-1 icon-btn" (click)="printDiv('printableArea')"><i class="fa fa-print"
                aria-hidden="true" style="color: black;"></i></button>
          </a>

        </div>
      </div>
      <!-- right side tray show only Bulk Booking (working on saud 👇) -->
      <div class="right-side" [ngClass]="darkMode ? 'dark_popup' : 'null' " id="side-tray"
        *ngIf="invoice_arrival.isMaster">
        <div class="roundCurve" (click)="toggleSideTray()"><i class="fa fa-caret-right"></i></div>
        <div class="scroll-booking">
          <div *ngFor="let booking of bulk_booking; let i = index">
            <div class="show2 bluheader" style="margin-top: 5px;">
              <div class="mdiv" style=" display: flex;padding-left: 5px;width: 100%;">
                <div class="div2">
                  <span style="color:white;"> {{booking.room.display_name && booking.room.display_name
                    !=''? booking.room.display_name : booking.room.roomtype.name}}</span>
                </div>
              </div>
            </div>
            <div class="card " style="padding: 5px 5px 1px 5px !important">
              <div class="date-box">
                <div class="season-date">
                  <img alt="" class="svgico capSvg" src="assets/img/invoices/check-in-black.svg">
                  <span> {{convert_date(booking.check_in)}}</span>
                </div>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <span class="sname" style="color: #C318DE !important; font-weight: 700;" [ngStyle]="">
                  {{formatGuestName(booking.guest_name)}}
                </span>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <div class="season-date">
                  <!-- <span>{{convert_month(invoice_arrival.check_out)}}</span> -->
                  <img alt="" class="svgico capSvg" src="assets/img/invoices/check-out-black.svg">
                  <span> {{convert_date(booking.check_out)}}</span>
                </div>
              </div>

              <div class="rateScroll">
                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                  <div class="div1" style="padding: 0  10px;">
                    <img src="assets/img/newstaticicons/inventory/bed2.svg" style="height: 20px ; width: 25px;">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                    <div class="div2" style="display: flex; align-items: center;justify-content: space-between;">
                      <span class="">{{booking.roomnum.room_number}}</span>
                    </div>

                  </div>
                </div>
                <div class="modal-bar-2 one0 dFlex rateDive">
                  <div style="align-items: left; ">
                    <img alt=" " class="image-5" src="assets/img/newstaticicons/inventory/night.svg "
                      style="height: 22px;">
                    {{roomNightStay(booking.check_out,booking.check_in)}}
                  </div>
                </div>
                <div class="modal-bar-2 one0 dFlex">
                  <div class="cash-amount" style="width: 100%;">
                    <div [ngClass]="darkMode ? 'darkDD' : 'cash-amount'">
                      <img alt=" " class="image-5" src="assets/img/inventory/cash-green.svg"
                        style="height: 22px;padding-right: 8px;">
                      {{currency}} {{calculateNightPrice(booking.pricesjson) | number : '1.2-2'}}
                    </div>
                    <button class="btn gold-btn greenBtn">{{invoice_arrival.booking.payment_status}}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="room-service" *ngIf="booking.fb_price != 0">
              <div class="show2" style="margin-top: 5px;">
                <div class="mdiv" style=" display: flex; padding-left: 5px; width: 100%;">
                  <div class="div2 discount">
                    <span style="color:white;">Room Service</span>
                    <a class="no-print btn-block mt-1 icon-btn">
                      <img src="assets/img/reports/share_dark.svg" style="height: 14px; width: 14px;">
                    </a>
                  </div>
                </div>
              </div>
              <div class="card " style="padding: 5px 5px !important; margin-bottom: 5px">

                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                  <div class="div1" style="padding: 0  10px;">
                    <img src="assets/img/invoices/fnb-icon.svg" width="25" height="20">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;align-items: center; width: 100%; color:#00205b">
                    <div class="div2">
                      <span>{{currency}} {{booking.fb_price | number : '1.2-2'}}</span>
                    </div>
                    <button class="btn gold-btn orangeBtn">Due</button>
                  </div>
                </div>

                <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                  <div class="div1" style="padding: 0  10px;">

                    <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" width="25" height="20">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                    <div class="div2">
                      <span>{{booking.fb_tax}}%</span>
                    </div>
                    <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                      'Exclusive'}}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="add-ons">
              <div class="bluheader booking-sumary"
                style="margin: 5px 0; align-items: center; border-radius: 8px; height: 35px; padding: 0px 15px;">
                <h3 class="h3_blu_header bookingsummary">Service Add-Ons</h3>
                <button type="button" (click)="addExtra('service', bulkBookingExtras[i])" class="addBtnExpensis"
                  [disabled]="invoice_arrival.status == 'Checked Out'">
                  <span id="cross">&#x2B;</span>
                </button>
              </div>
              <!-- Assuming you have the *ngFor loop to generate the cards as shown in the previous code -->
              <div class="addons-extras" *ngIf="displayAddonsNotEmpty('service', bulkBookingExtras[i])">
                <ng-container *ngFor="let extras of bulkBookingExtras[i]; index as e">
                  <div class="card" *ngIf="extras.type == 'service'" style="margin-bottom: 5px;">
                    <div class="row m-0">
                      <div class="col-md-8 zero-padding d-flex">
                        <div class="bar one0 p-0 mr-1">
                          <select class="form-control extrasDropdown padding-holder custom-0 input-custom"
                            placeholder="Expense" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                            [(ngModel)]="extras.name" (ngModelChange)="updatePrice(extras, 'service')">
                            <ng-container *ngFor="let ext of getExtras('service')">
                              <option [value]="ext.name">{{ ext.name }}</option>
                            </ng-container>
                          </select>
                        </div>
                        <button class="btn-add">
                          <i class="fa fa-minus pr-1" (click)="updateCount(extras, -1, 'service')"></i>
                          {{extras.count}}
                          <i class="fa fa-plus pl-1" (click)="updateCount(extras, 1, 'service')"></i>
                        </button>
                      </div>
                      <div class="col-md-4 zero-padding">
                        <div class="removeExpense">
                          <div class="bar one0 p-0">
                            <!-- Display the price for the selected extra -->
                            <input type="number" class="form-control extrasAmount" placeholder="Price"
                              [value]="+extras.price_after_tax * extras.count">
                          </div>
                          <button class="redbar" (click)="removeExtra(bulkBookingExtras[i],e)"><strong
                              id="cross">&#x2716;</strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="card">
                  <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                    <div class="div1" style="padding: 0  10px;">
                      <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" height="20" width="25">
                    </div>
                    <div class="mdiv"
                      style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                      <div class="div2">
                        <span>{{property.taxper}}%</span>
                      </div>
                      <div class="d-flex">
                        <span class="pr-2" *ngIf="getTaxPrice('service', bulkBookingExtras[i])">{{currency}}{{
                          getTaxPrice('service' ,bulkBookingExtras[i]) |
                          number: '1.2-3' }}</span>
                        <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                          'Exclusive'}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bluheader booking-sumary"
                style="margin: 5px 0; align-items: center; border-radius: 8px; height: 35px; padding: 0px 15px;">
                <h3 class="h3_blu_header bookingsummary">Food | Mini Bar Add-Ons</h3>
                <button type="button" (click)="addExtra('minibar', bulkBookingExtras[i])" class="addBtnExpensis"
                  [disabled]="invoice_arrival.status == 'Checked Out'">
                  <span id="cross">&#x2B;</span>
                </button>
              </div>

              <div class="addons-extras" *ngIf="displayAddonsNotEmpty('minibar', bulkBookingExtras[i])">
                <ng-container *ngFor="let extras of bulkBookingExtras[i]; index as e">
                  <div class="card" *ngIf="extras.type == 'minibar'" style="margin-bottom: 5px;">
                    <div class="row m-0">
                      <div class="col-md-8 zero-padding d-flex">
                        <div class="bar one0 p-0 mr-1">
                          <select class="form-control extrasDropdown padding-holder custom-0 input-custom"
                            placeholder="Expense" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                            [(ngModel)]="extras.name" (ngModelChange)="updatePrice(extras,'minibar')">
                            <ng-container *ngFor="let ext of getExtras('minibar')">
                              <option [value]="ext.name">{{ ext.name }}</option>
                            </ng-container>
                          </select>
                        </div>
                        <button class="btn-add">
                          <i class="fa fa-minus pr-1" (click)="updateCount(extras, -1,'minibar')"></i>
                          {{extras.count}}
                          <i class="fa fa-plus pl-1" (click)="updateCount(extras, 1,'minibar')"></i>
                        </button>
                      </div>
                      <div class="col-md-4 zero-padding">
                        <div class="removeExpense">
                          <div class="bar one0 p-0">
                            <!-- Display the price for the selected extra -->
                            <input type="number" class="form-control extrasAmount" placeholder="Price"
                              [value]="+extras.price_after_tax * extras.count">
                          </div>
                          <button class="redbar" (click)="removeExtra(bulkBookingExtras[i],e)"><strong
                              id="cross">&#x2716;</strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="card">
                  <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                    <div class="div1" style="padding: 0  10px;">
                      <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" height="20" width="25">
                    </div>
                    <div class="mdiv"
                      style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                      <div class="div2">
                        <span>{{property.food_tax_percentage}}%</span>
                      </div>
                      <div class="d-flex">
                        <span class="pr-2" *ngIf="getTaxPrice('minibar', bulkBookingExtras[i])">{{currency}}{{
                          getTaxPrice('minibar' ,bulkBookingExtras[i]) |
                          number: '1.2-3' }}</span>
                        <button class="btn gold-btn yellowBtn">{{invoice_arrival.booking.TaxInclusive ? 'Inclusive' :
                          'Exclusive'}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- right side tray show only Bulk Booking (working on saud 👆) -->
    </div>
  </div>
</div>

<div id="printableArea" style="display: none">
  <!-- <div class="main">
    <div class="box" style="display: block;">
      <div class="header flex space-between">
        <div class="logoHeader"><img
            src="{{ logo != 'null' && logo != null ? base_url2+'../app/images/' + logo : '../../../assets/img/nav/logo.png' }}" />
          <div>
            <span class="">
              {{ property?.name }}
            </span>
          </div>
        </div>
      </div>
      <hr />
      <div class="flex space-between hotel-text">
        <div>{{ property?.address_one }}</div>
        <div>{{ property?.email }}</div>
        <div>{{ property?.phone }}</div>
      </div>
      <hr />
      <div class="red-bar flex space-between">
        <div class="flex space-between"> Reservation ID : {{invoice_arrival.booking_id}}</div>
        <div class="flex space-between" style="margin-right:10px">Paid Voucher:(Hotel Name)</div>
      </div>
      <div class="flex room-details">
        <div class="flex flex-coluum first">
          <div class="date flex">
            {{convert_month(invoice_arrival.check_in)}}
            <br /> {{convert_date(invoice_arrival.check_in)}}
          </div>
          <div class="dots">
            <img src="../../../assets/imgs/invoice/dots.svg" />
          </div>
          <div class="date flex">
            {{convert_month(invoice_arrival.check_out)}}<br />
            {{convert_date(invoice_arrival.check_out)}}
          </div>
        </div>
        <div class="flex flex-coluum sec">
          <div class="main-con">
            <div class="head">
              <div class="heads">
                <h4 class="hds">Room Name</h4>
              </div>
              <div class="heads">
                <h4 class="hds">Room No</h4>
              </div>
              <div class="heads">
                <h4 class="hds">LOS</h4>
              </div>
              <div class="heads">
                <h4 class="hds">Rates</h4>
              </div>
            </div>
            <hr>
            <div class="flex" *ngFor="let ratediv of roomRate();let i = index">
              <div class="dataRoom">
                {{invoice_arrival.room.display_name && invoice_arrival.room.display_name !=''?
                invoice_arrival.room.display_name :
                invoice_arrival.room.roomtype.name}}
              </div>

              <div class="dataRoom">
                <span class="datas">{{invoice_arrival.roomnum.room_number}}</span>

              </div>
              <div class="dataRoom">
                <span class="datas">
                  {{roomNightStay(invoice_arrival.booking.date_departure,invoice_arrival.booking.date_arrival)}}</span>

              </div>

              <div class="dataRoom">
                <span class="datas"> {{currency}} {{ratediv.price}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="fourth">
          <div class="flex flex-coluum time">
            <div class="date4 flexx">
              <img src="../../../assets/imgs/invoice/time2.svg" style="width:50px" />
              <p style="font-size:12px;margin-bottom:0px;">{{invoice_arrival.check_in_time}}</p>
            </div>
            <div class="dotss">
              <img src="../../../assets/imgs/invoice/dots.svg" />
            </div>
            <div class="date4 flexx">
              <img src="../../../assets/imgs/invoice/time1.svg" style="width:50px" />
              <p style="font-size:12px; margin-bottom:0px">{{invoice_arrival.check_out_time}}</p>
            </div>
          </div>
        </div>
        <div class="third">
          <div class="main3">
            <h4 style="color:#270EA7; margin: 0%;">Guest Info</h4>
            <hr />
            <div>
              <div class="guestDetail"> {{invoice_arrival.booking.guest_firstname}}</div>
              <div class="guestDetail" *ngIf="invoice_arrival.booking.guest_email != 'null'">
                {{invoice_arrival.booking.guest_email}}
              </div>
              <div class="guestDetail" *ngIf="invoice_arrival.booking.privilage != 'null'">
                {{invoice_arrival.booking.privilege}}</div>
            </div>

          </div>
        </div>
      </div>
      <hr />
      <div class="bills">
        <div class="des">Description</div>
        <div *ngFor="let extras of Printextras();let i = index">
          <div class="flex space-between bill">
            <div>{{extras.expense_name}}</div>
            <div> --------------------------------------------------------------------------------------------- </div>
            <div>{{currency}}{{extras.price}}</div>
          </div>
        </div>
        <div class="flex space-between bill" *ngIf="extra_person_fee ">
          <div>Extra Person</div>
          <div> --------------------------------------------------------------------------------------------- </div>
          <div>{{currency}}{{invoice_arrival.booking.extra_person_fee}}</div>
        </div>
        <div class="flex space-between bill" *ngIf="invoice_arrival.booking.discount">
          <div>Discount</div>
          <div> --------------------------------------------------------------------------------------------- </div>
          <div>{{invoice_arrival.booking.discount | number : '1.2-2'}}%</div>
        </div>
        <div class="flex space-between bill">
          <div>Sub Total</div>
          <div> --------------------------------------------------------------------------------------------- </div>
          <div>{{currency}}{{subTotal | number : '1.2-2'}}</div>
        </div>
        <div class="flex space-between bill" *ngIf="invoice_arrival.booking.TaxInclusive == 'false'">
          <div>Tax</div>
          <div> --------------------------------------------------------------------------------------------- </div>
          <div>{{currency}} {{calculateTaxValue() | number : '1.2-2'}}</div>
        </div>
        <div class="total">
          <span>Total</span>
          <span>{{currency}} {{calculateTotal() | number : '1.2-2' }}</span>
        </div>
      </div>
      <div class="bills">
        <div class="des">Desclaimer</div>
        <div class="claims">- Non-Refundable</div>
        <div class="claims">- It is a long established fact that a reader will be distracted by the readable content of
          a page when looking at its layout.</div>
        <div class="claims">- Contrary to popular belief, Lorem Ipsum is not simply random text.</div>
      </div>
    </div>
  </div> -->
  <app-print-invoice [printInvoice]="invoice_arrival" [bulk_booking]="bulk_booking"></app-print-invoice>
</div>