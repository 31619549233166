<div class="card">
  <!-- <p>comm-skeleton works!</p> -->
  <div *ngFor="let x of getEmptyElements(15); let i = index" class="col-md-12 border-div"
    style="page-break-before: always; padding: 0px;">
    <ul class="grey-ul skeleton-animation p-0" id="ko" [ngClass]="darkMode ? 'dark-border-div' : 'null'">
      <li style="width: 39%" [ngStyle]="{width: template ? '53%' : '39%'}">
        <div class="skeleton-box " [ngClass]="getGreyType(i)"></div>
      </li>
      <li style="width: 16%"  [ngStyle]="{width: template ? '25%' : '16%'}">
        <div class="skeleton-box" [ngClass]="getGreyType(i+2)"></div>
      </li>
      <li style="width: 15%"  [ngStyle]="{width: template ? '22%' : '15%'}">
        <div class="skeleton-box " [ngClass]="getGreyType(i+1)"></div>
      </li>
      <ng-container *ngIf="!template">
        <li style="width: 15%">
          <div class="skeleton-box" [ngClass]="getGreyType(i)"></div>
        </li>
        <li style="width: 15%">
          <div class="skeleton-box " [ngClass]="getGreyType(i+2)"></div>
        </li>
      </ng-container>
    </ul>
  </div>

</div>
