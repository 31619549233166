<div [ngClass]="{'dark-mode': layout_service.dark_mode}">
  <div class="container-fluid">
    <div class="row" [ngClass]="{'sidebar-collapse': layout_service.collapse}">
      <div class="col-sm-12 col-xs-12 sidebar_wrapper">
        <aside>
          <app-sidebar></app-sidebar>
        </aside>
      </div>
      <div class="col-sm-12 col-xs-12 content_wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="httpLoader" [ngClass]="{'hide': !layout_service.spinner}">
    <!-- <span class="glyphicon glyphicon-refresh icon spinning"></span> -->
    <!-- <img src="assets/img/other/loader.gif"> -->
    <div class="wrapper">
      <div class="loading-circle"></div>
      <div class="loading-circle"></div>
      <div class="loading-circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span>Loading</span>
    </div>
  </div>
  <app-confirmation-alert></app-confirmation-alert>
</div>