import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router, ActivatedRoute } from "@angular/router";
declare var $: any;
import * as moment from 'moment';
import { GlobaldataService } from '../../../common/globaldata.service';
import { ManageRatesPackagesComponent } from '../manage-rates-packages.component';

@Component({ 
  selector: 'app-setup-season',
  templateUrl: './setup-season.component.html',
  styleUrls: ['./setup-season.component.css']
})
export class SetupSeasonComponent implements OnInit {

  create_package_form: FormGroup;
  date;
  date_to;
  date_from;
  submitted = false;
  selectedRoom;
  dropDown;
  service;
  roomtypes: any;
  langCode: any;
  in = 0;
  selected: any;
  setup_package;
  addRooms = [];
  season_room = []
  showErr: boolean = false;
  edit = false;
  editRooms = [];
  bHeight;
  ratehead;
  channel_list;
  darkMode: boolean = false;
  room_select_type: any;
  dateChange: boolean = false;
  dateChangeExecuted: boolean = false;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private router: Router,
    private Aroute: ActivatedRoute,
    private rp: ManageRatesPackagesComponent
  ) {
    this.Aroute.params.subscribe(val => {
      this.getEdit();
    });
  }

  ngOnInit() {
    this.get_rooms();
    this.checkDarkMode();
    this.getLangCode();
    if (GlobaldataService.editSeason == undefined) {
      this.create_package_form = this.fb.group({
        property_id: [localStorage.getItem('current_property')],
        season_name: ['', Validators.required],
        date: ['', Validators.required],
        date_from: ['', Validators.required],
        date_to: ['', Validators.required],
        demand_type: ['', Validators.required],

      });
      this.ratehead = 'Create Rate Plan'
    }
    this.modalfooter();

    $("#SS").mouseup(function () {
      $("#grab-7").css({ "cursor": "grab" })
    });
    $("#SS").mousedown(function () {
      $("#grab-7").css({ "cursor": "grabbing" })
    });

  }

  getEdit() {
    if (GlobaldataService.editSeason != undefined) {
      var end = moment(GlobaldataService.editSeason.date_to);
      var start = moment(GlobaldataService.editSeason.date_from);
      this.edit = true;
      this.dateChange = false;
      this.date = { startDate: end, endDate: start };
      GlobaldataService.editSeason.season_room_types.map((val) => {
        this.editRooms.push(val.room);
      })
      console.log(GlobaldataService.editSeason)
      this.create_package_form = this.fb.group({
        property_id: GlobaldataService.editSeason.property_id,
        season_name: GlobaldataService.editSeason.season_name,
        date: ['', Validators.required],
        date_from: GlobaldataService.editSeason.date_from,
        date_to: GlobaldataService.editSeason.date_to,
        demand_type: GlobaldataService.editSeason.demand_type,
      });
      this.ratehead = 'Edit Rate Plan'
    }
  }

  choose_date(e) {
    if (e.startDate != null && e.endDate != null) {
      this.dateChange = true;
      this.dateChangeExecuted = false;
      this.date_to = e.startDate.format('YYYY-MM-DD');
      this.date_from = e.endDate.format('YYYY-MM-DD');
    }
  }

  get_rooms() {
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/room/rate_room_index?per_page=500', { property_id: property_id }, true).subscribe((res: any) => {

      this.roomtypes = res.body.data;

      if (GlobaldataService.editSeason == undefined) {
        this.addRooms = res.body.data;

      }
      else {
        this.edit = true;


        // GlobaldataService.editSeason.expedia_status != 'Active' ? this.addRooms = res.body.data  : [];
        var arr = this.addRooms;
        let x = arr.sort((a, b) => (a.roomtype > b.roomtype ? 1 : -1));
        console.log(x);
      }


    }, err => this.error_handling.handle_error(err.status));
  }

  getSelectedRoom(obj) {
    this.selected = obj;
  }

  addRoom() {


    if (this.selected == undefined) {
      return false;
    }
    this.addRooms.push(this.selected);
    console.log(this.addRooms);
    this.showErr = false;
    this.room_select_type = undefined;

    this.roomtypes = this.roomtypes.filter(el => {
      return this.selected != el;

    });

    this.selected = undefined;
  }

  removeType(si) {
    this.addRooms.splice(si, 1)
  }

  removeEditType(ei) {
    this.editRooms.splice(ei, 1);
  }

  getLangCode() {
    var value = localStorage.getItem("code");
    this.langCode = value
  }

  onClose() {
    this.service = undefined;
    this.selectedRoom = '';
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    this.create_package_form.reset();
    this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    $('#settings2').modal('hide');
  }

  get f() { return this.create_package_form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.edit == false) {
      if (this.addRooms.length <= 0) {
        this.showErr = true;
        return false;
      }
      Object.keys(this.create_package_form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.create_package_form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
      if (this.create_package_form.invalid) {
        return;
      }
      console.log(this.create_package_form.value);

      this.api.post('api/season_package/store', this.create_package_form.value, true).subscribe((res: any) => {
        GlobaldataService.savedSeason = res.body.data;
        console.log(res.body.data);
        this.rp.getRatePlan();
        this.helper.alert_success('Season has been Created successfully !');
      }, err => this.error_handling.handle_error(err.status));

    } else {

      if (this.addRooms.length <= 0 && this.editRooms.length <= 0) {
        this.showErr = true;
        return false;
      }
      Object.keys(this.create_package_form.controls).forEach(key => {
        const controlErrors: ValidationErrors = this.create_package_form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            //  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
      if (this.create_package_form.invalid) {
        return;
      }
      let rooms = [];
      this.addRooms.map((val) => {
        rooms.push(val.id);
      })

      this.editRooms.map((val2) => {
        rooms.push(val2.id);
      })

      let uniq = [...new Set(rooms)];
      // if(){}
      GlobaldataService.editSeason.expedia_status != 'Active' ? this.create_package_form.value.room_types = uniq : this.create_package_form.value.room_types = [GlobaldataService.editSeason.season_room_types[0]['room_id']]
      //date was being sent 1 day back, sorted this
      if (this.dateChange) {
        // Only run this code block once
        if (!this.dateChangeExecuted) {
          const date = this.date;
          console.log(this.dateChange, "change date from");
          date.endDate = moment(this.date.endDate);
          date.startDate = moment(this.date.startDate).add(1, "day");
          this.dateChangeExecuted = true;
        }
      }
      else {
        console.log(this.dateChange, "change date from");
        const date = this.date;
        date.endDate = moment(this.date.endDate).add(1, "day");
        date.startDate = moment(this.date.startDate).add(1, "day");
        this.dateChange = true;
        this.dateChangeExecuted = true;
      }

      this.api.post('api/season_package/update/' + GlobaldataService.editSeason.id, this.create_package_form.value, true).subscribe((res: any) => {
        GlobaldataService.editSeason.editAllocation = res.body.data;
        this.rp.getRatePlan();
        this.helper.alert_success('Season has been Updated successfully !');
      }, err => this.error_handling.handle_error(err.status));
    }

  }

  modalfooter() {
    setTimeout(() => {
      let f = document.getElementById("ModalFooter")
      let h = document.getElementById("SS");

      this.bHeight = h.offsetHeight;

      if (this.bHeight > 700) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      }
      else if (this.bHeight < 700) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    }, 100)

  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  colorRadio(id, idt) {
    var a = document.getElementById(id)
    var b = document.getElementById(idt)
    a.classList.contains('radioByJs') ? a.classList.remove('radioByJs') : a.classList.add('radioByJs')
    b.classList.contains('radioByJs') ? b.classList.remove('radioByJs') : b.classList.add('radioByJs')
  }

  checkAllChannel($event) {

    if ($event.target.checked) {
      // console.log($event.target.value)
      // this.roomtypes.push($event.target.value);
      this.season_room.push($event.target.value)
      this.create_package_form.value.room_types = this.season_room;
      console.log(this.create_package_form.value.room_types);

    } else {

      // this.channel_list.forEach((element) => {
      //   if (element.type == "Internal") {
      //     element.is_binded = false;
      //   }
      // });
    }
  }


}
