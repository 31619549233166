<div class="" id="setupRes">


  <div>
    <div class="alert alert-info alert-dismissible show" role="alert">
      {{ service.is_edit == true ? "Edit Internal Package" : "Create Internal Package" }}
      <button type="button" class="close op-adj" data-dismiss="modal" aria-label="Close">
        <strong id="cross">&#x2716;</strong>
      </button>
    </div>

    <div class="alert alert-danger breadcrum">
      <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
      <a routerLink="/app/rates_packages/setup_restriction" class="btn btn-link active"> <span class="abc">{{ service.is_edit ? "EDIT" :
          "SETUP" }} RESTRICTIONS</span></a>
    </div>
    <div class="gradient-bar adj">
      <h4>Stay Restrictions</h4>
    </div>

    <form [formGroup]="setup_restrictions" (ngSubmit)="onSubmit()">
      <div class="">
        <div class="">
          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 grid_20 padding-rg" style="padding-right: 0px;">
                    <div class="incremental">
                      <div class="border-style minimum-stay-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" placeholder="Enter Minimum Stay" min="1"
                            name="min_stay" formControlName="min_stay" />
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="increment('min_stay')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="decrement('min_stay')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 grid_20 padding-lg">
                    <div class="incremental">
                      <div class="border-style maximum-stay-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" placeholder="Enter Maximum Stay" name="max_stay"
                            formControlName="max_stay" />
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="increment('max_stay')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="decrement('max_stay')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="gradient-bar adj">
            <h4>Children Restrictions</h4>
          </div>

          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 grid_20 padding-rg">
                    <div class="incremental">
                      <div class="border-style minimum-age-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" placeholder="Enter Minimum Age" min="1"
                            name="minimum_age" formControlName="minimum_age" />
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="increment('minimum_age')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="decrement('minimum_age')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="gradient-bar adj">
            <h4>Pet Restrictions</h4>
          </div>

          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 check padding-rg" style="padding-right: 0px;">
                    <div class="border-style policy-shade">
                      <ng-select bindLabel="label" bindValue="label" placeholder="Select Restriction" name="pets"
                        formControlName="pets">
                        <ng-option value="Allowed">Allowed</ng-option>
                        <ng-option value="Not Allowed">Not Allowed</ng-option>
                        <ng-option value="Extra Amount">Extra Amount</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && setup_restrictions.get('pets').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>
                  <div class="col-md-6 check padding-lg" *ngIf="setup_restrictions.value.pets == 'Extra Amount'">
                    <div class="border-style extra-charge-shade">
                      <input type="number" class="form-control" placeholder="Enter Extra Charge Amount" maxlength="10"
                        required name="pets_extra_amount" formControlName="pets_extra_amount" min="1" />
                    </div>
                    <div *ngIf="submitted && setup_restrictions.get('pets_extra_amount').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="gradient-bar adj">
            <h4>Hotel Cancellation Policy</h4>
          </div>

          <div class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 check padding-rg" style="padding-right: 0px;">
                    <div class="border-style days-check-shade">
                      <input type="number" class="form-control" min="1" placeholder="Days Before Check In"
                        maxlength="10" required name="cancellation_days" formControlName="cancellation_days" />
                    </div>
                    <div *ngIf="submitted && setup_restrictions.get('cancellation_days').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>

                  <div class="col-md-3 check padding-rg" style="padding-right: 0px;">
                    <div class="border-style penalty-shade">
                      <ng-select [(ngModel)]="cancellaction_type" name="cancellaction_type" bindLabel="label"
                        bindValue="label" placeholder="Select Method" name="cancellaction_type"
                        formControlName="cancellaction_type">
                        <ng-option value="percentage">Percentage</ng-option>
                        <ng-option value="fixed">Fixed Amount</ng-option>
                        <ng-option value="no_refund">No Refund</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && setup_restrictions.get('cancellaction_type').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>

                  <div class="col-md-3 check padding-rg"
                    *ngIf="setup_restrictions.value.cancellaction_type != 'no_refund'">
                    <div class="border-style" style="border-color:darkcyan">
                      <input type="number" class="form-control" min="1" placeholder="Enter Value" required
                        name="cancellation_value" formControlName="cancellation_value" />
                    </div>
                    <div *ngIf="submitted && setup_restrictions.get('cancellation_value').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="gradient-bar adj">
            <h4>Apply to Channels</h4>
          </div>

          <div class="white_box channels w-clearfix channel_padding">

            <div class="col-md-12" style="padding-right: 0px">
              <div *ngFor="let channel of channels; let i=index" class="col-md-4"
                style="padding-left: 0px;padding-right: 5px !important">
                <div class="channel_container clr_{{ channel?.image.split('.')[0] }} w-clearfix">
                  <div class="form_container">
                    <div class="form-block w-clearfix w-form">
                      <div class="form">
                        <label class="">
                          <input type="checkbox" name="cancelation_channels" value="{{ channel?.id }}"
                            (change)="setChannel(channel?.id, $event)" [checked]="channel?.is_checked">
                        </label>
                      </div>
                    </div>
                  </div>
                  <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer setup-res-0" style="bottom: -55px !important;left: -12px!important;">
        <a class="btn btn-primary btn-lg" (click)="onSubmit()">Save</a>
        <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
      </div>
    </form>


  </div>