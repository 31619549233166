import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from "@angular/common";
import { PackagesData } from './ratesandpackages/SeasonRateIndexModel';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {
    // if (!term) return value;
    // return (value || []).filter(item => {
    //   return keys.split(',').some(key => {
    //     return item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]);
    //   });
    // });
    // let rr =  `^${term}$`
    if (!term || !value) return value;
    return (value || []).filter(item => {
      return keys.split(',').some(key => {
        return item.hasOwnProperty(key) && new RegExp(`^${term}$`).test(item[key]);
      });
    });
  }



}

