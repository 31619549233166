<div class="dash_window no_margin w-clearfix dashboard-card " [ngClass]="darkMode ? 'darkDashboard' : 'null'">
    <div class="win_header ">
        <h4 class="heading ">Reservations</h4>
    </div>
    <div class="scroll no_scroll ">
        <div *ngFor="let reserve of reservations_res;let i=index "
            [ngStyle]="{ 'cursor': reserve.read_status=='read' ? 'default' : 'pointer'} ">
            <div class="notifications _0 w-clearfix "
                [ngStyle]="{'border-color':darkMode? color_borders_dark[i%color_borders_dark.length] : colors[i%colors.length]}"
                (click)="readNotification(reserve.booking_id) ">
                <!-- <div class="notifications _0 w-clearfix " [ngStyle]="{'border-color': color_borders_dark[i%color_borders_dark.length]}" (click)="readNotification(reserve.booking_id) "> -->


                <img src="assets/img/newstaticicons/dashboard/{{reserve.read_status=='read' ? 'open-new' : 'close-new'}}-envelop.svg "
                    alt=" " class="{{ reserve.read_status=='read' ? 'read_icon' : 'unread_icon' }} "
                    style=" height:30px ">
                <div class="noti_content w-clearfix ">
                    <div class="noti_text w-clearfix ">
                        <p class="paragraph "
                            [ngStyle]="{ 'color': darkMode? colors[i%colors.length] : color_border[i%color_border.length]} ">
                            {{reserve.text}}</p>
                    </div>
                    <div class="noti_sec_left w-clearfix ">
                        <img src="{{darkMode ? 'assets/img/dashboard/darkmode/person_dark.png' : 'assets/img/newstaticicons/dashboard/person.svg'}} "
                            alt=" " class="image-4 ">
                        <!-- <img src="assets/img/dashboard/user_1user.png " alt=" " class="image-4 "> -->
                        <h6 class="heading-3 ">{{reserve.persons}} Persons</h6>
                    </div>
                    <div class="noti_sec_right w-clearfix ">
                        <img src="{{darkMode ? 'assets/img/dashboard/darkmode/month_dark.png' : 'assets/img/newstaticicons/dashboard/month.svg'}} "
                            alt=" " class="image-7 ">
                        <h6 class="heading-5 ">
                            {{reserve.time}}
                        </h6>
                    </div>
                    <div class="noti_sec_mid w-clearfix " *ngIf="reserve.nights>0">
                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg': '/night.svg'}} "
                            alt=" " class="image-5 ">
                        <h6 class="heading-4 ">{{reserve.nights}} Nights</h6>
                    </div>
                    <div class="noti_sec_mid w-clearfix " *ngIf="reserve.nights==0">
                        <img src="assets/img/newstaticicons/inventory/icons-sun.png" style="height:20px; width:20px"
                            alt=" " class="image-5 ">
                        <h6 class="heading-4 ">{{reserve.days}} Day</h6>
                    </div>

                </div>


                <!-- </div> -->
            </div>

        </div>
    </div>
</div>