<!-- <p>termand-condition works!</p> -->


<div class="container" id="termscondition" [ngClass]="darkMode ? 'container_dark' : 'container'">
    <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'card-dark' : 'null'"><span
            [ngClass]="darkMode ? 'service_s':'null'">T</span>erms <span
            [ngClass]="darkMode ? 'terms_a':'null'">&</span>&nbsp;<span
            [ngClass]="darkMode ? 'service_a':'null'">C</span>ondition
    </div>

    <div class="translation panel-group" id="terms_of_use">
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse1" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    1. Parties
                    <img id="col_1" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_1')">
                </a>

            </div>
            <hr class="hr-pd">
            <div id="collapse1" class="text panel-collapse collapse" [ngClass]="darkMode ? 'text_dark' : 'text'">


                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    This Terms & Conditions set forth the terms and conditions between Point Click Integrate and the
                    Property which agrees to these Terms & Conditions to use the Point Click Integrate's service.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse2" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    2. Definitions
                    <img id="col_2" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_2')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse2" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Service”: The technological services which are provided by Point Click Integrate, detailed within
                    the scope of the Platform, and are delivered on mobile phones, social media or other media as may be
                    determined by Hotel Platform;
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Terms & Conditions”: These Platform Terms & Conditions;
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Customer(s)”: Any natural or legal person who will receive accommodation etc. services from the
                    Property;</p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Property (Properties)”: The Property (Properties) who use the Service under these Terms &
                    Conditions;</p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Agency (Agencies)”: The online agency (agencies) who established a relationship with the Property
                    through the services offered by Point Click Integrate to agencies or via the Channel Manager
                    service;
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Platform”: The Point Click Integrate software service of which Point Click Integrate holds all the
                    rights and which offers the Property the capabilities to develop and manage the website in various
                    languages, receive online booking, integrate with online
                    agencies and social media websites etc.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Website”: The website, located at the domain name https://pointclickintegrate.com, where the
                    features of the Point Click Integrate-owned Services and these Platform Terms & Conditions;
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    “Commercial Conditions”: The instrument which may be executed by and between Point Click Integrate
                    and the Property to lay out the commercial terms and conditions governing use of the Services by the
                    Property.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse3" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    3. Subject of the Terms & Conditions
                    <img id="col_3" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_3')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse3" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    These Terms & Conditions document is executed so as to govern the terms and conditions applicable to
                    the Property’s use of the Platform and the Point Click Integrate-provided Service as well as the
                    minimum conditions which must be included in any contact
                    between the Property and the Customers.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Additional or different conditions may be included relating to use of the Service or Platform in
                    various areas of the Platform, and shall be considered an annex to and an integral part of these
                    Terms & Conditions. In case of discrepancy between these
                    Terms & Conditions and any addition condition applicable to a certain area of the Platform, such
                    additional conditions shall prevail.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse4" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    4. Right and Obligations of the Parties
                    <img id="col_4" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_4')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse4" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property agrees and undertakes that it is authorized to use the Service and offer the Customers
                    accommodation etc. Services under the Service used by the Property, and that it holds and will
                    continue to hold during the Service any permit or license
                    which the company and the authorized property are legally required to hold in this regard.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Platform can start using the Service by selecting one of the Service categories or types listed
                    in the Platform. Point Click Integrate may from time to time expand or contract the scope of the
                    Service which it offers through the Platform. Each Service
                    used by the Property through the Platform shall be subject to these Terms & Conditions and any annex
                    thereof as well as other Service-specific terms & conditions communicated to the Property through
                    the Platform.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The scope of the products and services to be offered by Point Click Integrate under each category
                    are defined in the Platform, relevant parts of the Website, this agreement and any annex thereto,
                    and Point Click Integrate may from time to time amend such
                    categories as well as the scope of any product and service offered under such categories.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property wishing to use the Service must create an account on the Platform. The Property shall
                    create only one account, and in the event of discovery that multiple accounts have been created
                    without a justifiable reason, all of such accounts shall
                    be terminated and the Property shall not be refunded. The Property is responsible to ensure security
                    of the username and password used for login to the account, and shall be liable for any operation
                    performed using this
                    username and password. The Property shall immediately notify Point Click Integrate of any use or
                    disclosure of said username and password by or to unauthorized parties. The Property is obliged to
                    keep the account information
                    complete, accurate and up-to-date, and Point Click Integrate may suspend or terminate use of the
                    Service by the Property in case of doubt about completeness, accuracy or correctness of such
                    information.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In the event of launch by the Property of a campaign targeting Customers, the Property acknowledges
                    that it shall have all the required permits and authorizations, and that Point Click Integrate shall
                    not be liable in any way to the Property or the Customers
                    relating to scope and content of such campaigns.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property shall be personally responsible for regulatory and public moral compliance of the
                    website which it creates using the Service or integrates the Service into, the content thereof, its
                    activities falling within and/or out of the scope of the
                    Service, and the products and service it offers to the Customer.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property is obliged to keep complete, accurate and up-to-date any information and data it
                    provides to Point Click Integrate (Customer details, payment details, room details etc.) so that the
                    Property can use the Service fully and as intended. Point
                    Click Integrate shall not be liable to any damage arising from any incomplete, inaccurate or
                    obsolete information provided to Point Click Integrate.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    At all times, the Property shall protect and refrain from disclosing to third parties, and shall not
                    use in contradiction with laws, any Customer information delivered to the Property by Point Click
                    Integrate. Any communication by the Property using such
                    information shall observe the applicable regulations.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate may from time to time collaborate with third parties and its business partners
                    as part of the Service, and in this regard, the Property consents to dissemination of certain
                    information about the Property and the Customers to such
                    third parties and business partners. The Property also agrees that the data available on the
                    Platform and related contents may be disclosed to the Agencies engaging various Point Click
                    Integrate services, and that Point
                    Click Integrate has hereby been granted such usage rights as required in this regard.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property agrees that use by the Property of the Point Click Integrate-provided Service does not
                    in any way constitute endorsement of its products and services, and in this regard, it shall not use
                    in promotions or advertisements the name or logo of
                    Point Click Integrate or any of its products and services in such a way that may be interpreted
                    beyond the scope as laid out in these Terms & Conditions.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property is obliged to ensure that any agreement that it shall sign with the Customer(s) will at
                    minimum include the following:
                </p>
                <div class="cardcolor">
                    <div class="head">
                        <p class="indent sub-bullet-points"> The information provided by the Customer to the Property
                            may be relayed to Point Click Integrate for the purposes of the Customer’s accommodation and
                            use of other benefits offered by the Property, and may also be relayed
                            by Point Click Integrate to its business partners as limited by this scope and purpose;
                        </p>
                        <p class="indent sub-bullet-points"> Point Click Integrate may use, classify and retain on a
                            database the Customer information provided to Point Click Integrate by the Property, in
                            order to satisfy the demands of administrative and/or judicial authorities,
                            ensure security of such information, fulfill its obligations and conduct various statistical
                            assessments;
                        </p>
                        <p class="indent sub-bullet-points"> The terms of booking cancellation by the Customer and
                            Property;
                        </p>
                        <p class="indent sub-bullet-points"> During the booking process; any banking and transaction
                            fee, including credit card pre-provisioning for (i) relevant charges (room charge, extra
                            charges if any, such as security deposit), (ii) charging the credit card
                            a nominal amount for card verification, or (iii) consent to collection of relevant charges,
                            exchange rate commissions in case of booking cancellation, credit/debit card commission
                            fees; and use of third party payment
                            providers to make the payment is subject to the terms of service of such providers; and
                        </p>
                        <p class="indent sub-bullet-points"> The Property is solely responsible for any accommodation
                            and other services provided by the Property to the Customer.
                        </p>
                    </div>
                </div>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Any Customer and third party claim that may arise from non-compliance by the Property with these
                    Terms & Conditions, in particular Article 4, or the applicable regulations, and any related damages
                    (including legal, administrative and criminal enforcements),
                    shall be recovered, together with any secondary claims, from the Property, payable upon first
                    demand.
                </p>
            </div>

        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse5" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    5. Platform Terms & Conditions
                    <img id="col_5" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_5')">
                </a>

            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse5" [ngClass]="darkMode ? 'text_dark' : 'text'">

                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The features which the Property may use on the Platform are limited to the scope of the Service
                    categories or types selected, and details of the Services are provided on the Website. Features of
                    the Platform, and of the software and designs under the
                    Platform are limited to the scope defined on the Website, and Point Click Integrate does not
                    guarantee in any way that the Platform and Services will meet the Platform’s specific needs.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The service to be provide to the Property under the Platform Terms & Conditions may be priced under
                    one of the following methods, as decided by the Property:
                </p>
                <div class="cardcolor">
                    <div class="head">

                        <p class="indent sub-bullet-points"> Percentage Service Fee: The Percentage Service Fee rate,
                            charged over the fee paid by the Customer for the bookings on the Platform, and laid out
                            under the relevant service definition and scope on the Website, plus
                            applicable taxes (Value Added Tax, Sales Tax, City Taxes etc.) will be paid as Service Fee
                            to Point Click Integrate either online through credit card or to the bank account to be
                            communicated by Point Click Integrate.
                            The Service Fee will be determined based on Point Click Integrate records, in accordance
                            with the agreement between the parties. The Percentage Service Fee is calculated based on
                            the total costs for all stayed (including
                            imported) bookings. Percentage Service Fee-based Point Click Integrate subscriptions may
                            have minimum fees. You can unsubscribe from the subscriptions anytime. When you unsubscribe,
                            the Percentage Service Fees related
                            to the future reservations you have received from the channels and recorded to your Point
                            Click Integrate account will be due and payable immediately, whether the reservations are
                            realized or not.
                        </p>
                        <p class="indent sub-bullet-points"> Fixed Service Fee: The fixed fees relating to the services
                            offered to the Property through the Platform are laid out on the Website under the relevant
                            service description and scope. Applicable taxes (Value Added Tax,
                            Sales Tax, City Taxes etc.) will be added to these fees. The Property can change the service
                            packages it obtains hereunder, in accordance with the Platform Terms & Conditions and
                            through the Platform as defined
                            under the Website or Platform Terms & Conditions. Payments relating to the Service Fee are
                            non-refundable in cases of cancellation of the Service by the Property or switch to a
                            lower-fee Service category. Switch
                            to a Service category with a lower Service Fee will be performed at the relevant period-end,
                            and switch to a Service category with a higher Service Fee will be performed immediately at
                            the request of the Property
                            and the fee difference will be charged separately to the Property.
                        </p>
                    </div>
                </div>
                <div class="textarea text-mr">
                </div>


                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    For the website that it will develop under the Services, the Property may develop its own website
                    using templates and contents available on the Platform, and may upload its own content to the
                    website observing the Platform’s technical requirements. Use
                    by the Property of the designs, images and contents provided to it under the Platform shall be
                    limited to delivery of the Services and development of its own website, and the Platform shall
                    immediately cease use of such
                    designs, images and contents in case of termination of this agreement or the website related
                    Services.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The templates and images available on the Platform, and the website covered under the Services, are
                    compatible only with the browsers and mobile devices listed on the Website, and Point Click
                    Integrate provides no guarantee as to compatibility, integration
                    and operability of the developed website and the Services with any non-listed other device.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In no way or for no reason is the Property entitled to copy, alter, reproduce, reverse engineer,
                    decompile the Platform or any content thereunder, otherwise access the Platform software’s source
                    code, or create derivative work from the Platform or use
                    the Platform for any purpose other than set forth under these Terms & Conditions. The Property shall
                    not interfere in any way to the Platform, including but not limited to use of viruses, cancel bots,
                    Trojan horses, malwares,
                    flood pings, DOS attacks, spoofing, misdirection, e-mail information or other methods and uses of
                    technology, and shall act in accordance with the security measures of Point Click Integrate and
                    security providers. It is
                    strictly forbidden to alter the Platform-related browsers and contents in any way, or provide links
                    to or from the Platform without explicit consent of Point Click Integrate.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property agrees and undertakes that any information, content, correspondence and announcement
                    uploaded by the Property (if any) or provided to the Customers is accurate and legal, shall not be
                    of illegal or immoral nature such as defamation, smearing,
                    discrediting statement, insult, slander, threat or harassment, and shall not infringe any right. The
                    Property also represents that such content will not contain any malicious materials such as virus,
                    spyware, malware, Trojan
                    horse etc., and that the Platform will not be used for such purposes as data mining.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate shall store and use any information or data provided to it in the course of
                    its relationship with the Property in accordance with the “Privacy Policy”, which is an annex to
                    these Terms & Conditions. The Property agrees that Point
                    Click Integrate may disclose the Property-related information in Point Click Integrate's possession
                    to relevant authorities if so demanded by such authorities under the applicable regulations, and to
                    any Customer which
                    is in a dispute with the Property so that such Customer may use its legal rights. In addition, the
                    information relating to the Property, Customers or transactions performed by the Property through
                    the Platform may be used
                    for security, performance by Point Click Integrate of its obligations, or certain statistical
                    assessments. Such information may also be classified and retained on a database.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Access to the Platform may be temporarily disabled to implement improvements and other modifications
                    to the Platform. In this case, Point Click Integrate shall show every effort to keep such
                    interruptions as short as possible to ensure a minimal impact
                    on Platform use and to perform the same at the hours where Platform use is at lowest intensity.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In the event that the Property requests Property-specific services other than those described on the
                    Website and provided “AS IS”, and/or wishes to engage consulting activities available on the
                    Website, the conditions governing such services shall be
                    determined and priced separately.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In case the Property, makes the payment of Point Click Integrate services fee via credit card; and
                    there is no credit card information saved on the payment provider / wallet previously, the payment
                    provider / wallet keeps/saves the credit card information
                    for further automatic payments. When the due date has arrived, Point Click Integrate may charge the
                    service fee via saved credit card through payment provider / wallet automatically. Property may
                    delete and/or change the
                    saved credit card information and/or add new credit card information to the payment provider /
                    wallet at any time. However, Property shall keep at least one credit card information saved at the
                    payment provider / wallet
                    permanently for automated payments.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property can also make payments for Service Fees via their own Paypal account. The Property
                    pre-authorizes Point Click Integrate for recurring payments using “Paypal Reference Transactions”.
                    The Property can cancel their pre-authorized Reference Transactions
                    anytime visiting their own Paypal account -> “User Profile” -> “My Money” -> ”My Pre-authorized
                    Payments” -> “Update” -> “Cancel”.
                </p>

            </div>

        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse6" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    6. Intellectual Property Right
                    <img id="col_6" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_6')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse6" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate owns any right, property and interest on the Platform, the Service and any
                    software, design etc. works produced as part of the Services. No provision under this Terms &
                    Conditions may be interpreted as partial or complete transfer
                    of any related right, property and interest to the Property. The Property is hereby granted a
                    non-exclusive, term-limited, non-transferable right on said Service, software, design etc. content.
                    On the other hand, the Property
                    owns any right on the content provided by the Property through the Platform, and Point Click
                    Integrate is granted a global, free, non-transferable and non-exclusive license to use such content
                    (right to use), subject to
                    these Terms & Conditions.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property shall not in any way use the trade name, brand, service brand, logo, domain name etc.
                    of Point Click Integrate (or any of its affiliates), nor shall it infringe any intellectual and
                    industrial property, privacy etc. rights of any person or
                    organization.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In using the Platform and the Service, the Property shall observe any third party rights, including
                    copyrights and personal rights. Point Click Integrate shall be entitled to suspend temporarily or
                    terminate the Service in the event of discovery that
                    any third party right has been infringed.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse7" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    7. Limitation of Liabilities
                    <img id="col_7" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_7')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse7" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property agrees to defend, indemnify and hold harmless Point Click Integrate and its directors,
                    managers, employees and agencies against any claim, liability, damage and expense, including but not
                    limited to reasonable legal and accounting fees, arising
                    from or in connection with violation of these Terms & Conditions including its annexes or the
                    regulations or use of the Service or the Platform.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate is not a mediator, agency or insurer vis-à-vis the Property. Point Click
                    Integrate is only obliged to provide the Service hereunder, and the Property agrees and represents
                    that Point Click Integrate is not a party to the relationship
                    between the Customers and/or the Agencies and the Property, that the only responsibility of Point
                    Click Integrate under the Service is to technically bring the relevant parties together, and that
                    Point Click Integrate in
                    this regard may not be held liable for any contractual or regulatory violation on part of the
                    Customers or the Agencies. Any request by the Property from the Customer or the Agencies shall be
                    directly communicated to the
                    relevant party.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property is solely liable for any content that may be uploaded to the Platform, and any claim or
                    damages arising from such contents shall be communicated to or recovered from the Property. The
                    Property agrees and represents that Point Click Integrate
                    is under no obligation to check authenticity, originality, security or accuracy of any product or
                    service offered through the Service, including any content provided by other Properties, or to
                    identify whether or not the
                    sales or online sales thereof is legal, and that Point Click Integrate is not liable for any damage
                    arising from or in connection with such products, services and contents.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property agrees and represents that the Platform may contain lings to other websites and/or
                    platforms, files or contents not under the control of Point Click Integrate, such links do not
                    constitute any representation or guarantee so as to support
                    the redirected website or the operator of such website or concerning such website or any information
                    contained therein, and Point Click Integrate is not liable for any platform, website, file, content,
                    service or product
                    or the content thereof accessed via such links.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Platform, the Service under the Platform and other contents are offered “AS IS”, and Point Click
                    Integrate is not liable for or does not provide any guarantee as to accuracy, completeness or
                    reliability thereof.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Property is personally responsible for procurement of such hardware, software and connection as
                    required to access the Service, and for any cost arising therefrom. The Property also agrees that
                    the access to the Service and contents offered through
                    the Platform, and the quality thereof, are to a large extend dependent on the quality of service
                    provided by the internet service provider, and that Point Click Integrate is not responsible in any
                    way for the issues stemming
                    from said quality of service.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse8" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    8. Duration &Termination of Terms & Conditions
                    <img id="col_8" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_8')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse8" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    These Terms & Conditions shall be effective upon start of the Service and remain so as long as the
                    Property receives the Service. In case where the Service is offered as a free trial, the Service
                    free trial period will automatically terminate if the Property
                    fails to communicate payment details to Point Click Integrate despite the notification from Point
                    Click Integrate towards end of the period. In case where the Parties also execute a Commercial
                    Conditions agreement, the
                    conditions relating to the period and renewal may be set forth separately under such Commercial
                    Conditions agreement.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Continuity of the Platform and the Service is at the discretion of Point Click Integrate, who may
                    suspend or terminate them anytime it wishes. Each Party has the right to terminate the Service(s)
                    and/or these Terms & Conditions any time. In such an event,
                    neither Party may claim anything from the other party except their receivables accruing during the
                    Service.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Termination by the Property of the Service(s) provided hereunder shall either be through delivery of
                    notice to support@pointclickintegrate.com or performance of relevant Service termination actions on
                    the Platform. Termination shall be effective upon
                    communication by Point Click Integrate of the confirmation of termination through the Platform or
                    e-mail.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In the event that the Property acts in breach of these Terms & Conditions, its annexes or its
                    regulatory obligations and fails to remedy such breach within 5 (five) days from receipt of the
                    related notice, Point Click Integrate shall become entitled to
                    terminate the Service partially or completely. At its discretion, Point Click Integrate may
                    partially or completely suspend the Service within said period or until remedy of said breach.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate may terminate these Terms & Conditions and the Service in the event that the
                    Property loses its operating license, becomes insolvent voluntarily or involuntarily, becomes
                    bankrupt or postpones bankruptcy, or becomes subject to assignment
                    of trustee, composition with creditors or similar procedures, or ceases its operations permanently
                    or switches to a materially different area of operation, or disposes of its assets wholly or
                    substantially.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Termination by the Property or Point Click Integrate of any Service used by the Property through the
                    Platform shall not affect applicability of these Terms & Conditions to other services; while
                    termination of all of the Services or these Terms & Conditions
                    shall cause the integration of the Property with the Service and the Platform to be terminated
                    immediately and the Property-owned content shall be erased permanently.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    In case where the Service has been suspended or terminated as set forth under the Terms &
                    Conditions, the Property may not claim any damages, such as indemnity, loss of profit, loss of
                    customers etc. In the event that the Service or the Terms & Conditions
                    are terminated for a reason attributable to the Property, no amount paid in advance by the Property
                    shall be refundable.
                </p>
            </div>
        </div>
        <div class="panel panel-default">
            <div class="abc">
                <a data-toggle="collapse" data-parent="#terms_of_use" href="#collapse9" class="collapsed abc"
                    [ngClass]="darkMode ? 'abc_dark' : 'abc'" aria-expanded="false">
                    9. Miscellaneous
                    <img id="col_9" src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                        class="imagedrop" alt="" (click)="chngedrop('col_9')">
                </a>
            </div>
            <hr class="hr-pd">
            <div class="text panel-collapse collapse" id="collapse9" [ngClass]="darkMode ? 'text_dark' : 'text'">
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Any event which is not present or foreseeable on the effective date of this Agreement, occurs beyond
                    the control of the parties in such a way and at such a degree to impair either or both parties’
                    operational capability partially or completely, temporarily
                    or permanently, such as human or natural disasters, war, mobilization, fire, strike, lockout,
                    Internet outage, infrastructure-related issues etc. shall be considered a force majeure event. The
                    party suffering the force
                    majeure event shall promptly notify the other party, and contractual performances of the parties
                    shall be suspended during the force majeure event. Once the force majeure event ends, the Service
                    shall resume. The obligations
                    of the party whose rights hereunder are not delivered during the force majeure shall be suspended as
                    well. In the event that the force majeure event continues more than 90 (ninety) days, the party
                    whose rights are not delivered
                    may demand termination of the Service without compensation.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Point Click Integrate has the right to unilaterally amend the scope of these Terms & Conditions, the
                    Service or the Platform any time it wishes. Unless set forth otherwise under this agreement or the
                    Website, such amendments shall be effective upon publication
                    on the Platform or the Website without the need to notify the Property separately.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Communication with the Property shall be through the e-mail provided to Point Click Integrate as
                    part of registration or using the Property’s general information available on the Platform.
                    Communication through e-mail shall be deemed written correspondence.
                    It is the Property’s responsibility to keep its e-mail address up-to-date and regularly check the
                    Platform for notifications.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Nullity, illegality or unenforceability of any provision of these Terms & Conditions shall not
                    affect enforceability or validity of the remaining provisions of these Terms & Conditions.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    Non-exercise by Point Click Integrate of any of its rights under these Terms & Conditions or the
                    application regulations shall not constitute waiver of future exercise of such right. Waiver from
                    such a right shall only be effective if in written and signed
                    by the Point Click Integrate authorized representative. Except those circumstances explicitly set
                    forth under these Terms & Conditions, non-exercise by either party of any of its remedies under
                    these Terms & Conditions
                    shall be without prejudice to any other remedy arising from these Terms & Conditions or any other
                    basis.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    This Terms & Conditions agreement is complete with its annexes (Annex-1 Privacy Police, Annex-2
                    Service Agreements), constitutes the whole understanding between the parties and supersedes any
                    previous agreement, oral or written, about the subject of this
                    agreement. In case of discrepancy between these Terms & Conditions agreement and any of its annexes,
                    the provisions prescribed in the relevant annex shall prevail.
                </p>
                <p class="indent bullet-points" [ngClass]="darkMode ? 'bullet-points_dark' : 'bullet-points'">
                    The Parties agree that their books, records and computer entries shall constitute final evidence in
                    disputes arising from the Service and these Terms & Conditions under the Civil Procedural Law of
                    Canada.
                </p>
            </div>
        </div>
    </div>
</div>