<div class="" id="manageU">
  <!-- <button (click)="maximizeScreen()">Maximize</button>
  <button (click)="minimizeScreen()">Minimize</button> -->
  <!-- <div class="alert alert-info alert-dismissible show" role="alert">

        You've got 3 notifications and 2 keynotes, everything else looks smooth
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <strong id="cross">&#x2716;</strong>
        </button>
    </div> -->

  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum' ">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">BACK OFFICE</a>
    <a href="" class="btn btn-link active">MANAGE USERS</a>
  </div>

  <div class="alert alert-info2">
    <ul class="nav nav-inline">
      <!-- <li style="width: 8%"><a href="">&nbsp;</a></li> -->
      <li style="width: 10%"><a href="">Image</a></li>
      <li style="width: 25%"><a href="">Name</a></li>
      <li style="width: 20%"><a href="">Role</a></li>
      <li style="width: 20%"><a href="">Created On</a></li>
      <!-- <li style="width: 16%"><a href="">Permissions</a></li>
            <li style="width: 15%"><a href="">Status</a></li> -->
      <li style="width: 25%"><a href="">Actions</a></li>
    </ul>
  </div>

  <div class="card scroll no_scroll" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <!-- <div class="container-fluid">
            <div class="row field-space" *ngFor="let color of colors">
                <div class="col-md-8">
                    <div class="border-style" [ngStyle]="{'border-color': color}">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li style="width: 10%"><img src="assets/img/logo.jpg" class="img-responsive img-circle"></li>
                                <li style="width: 22%;">Jane Doe</li>
                                <li style="width: 50%; text-align: center;">janedoe1990@liesurex.com</li>
                                <li style="width: 18%;" class="text-center">
                                    <ul class="nav sub-nav">
                                        <li>Inventory</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                    <a href="#" class="btn btn-primary">Unlock</a>
                    <a href="#" class="btn btn-primary">Edit</a>
                    <a href="#" class="btn btn-danger">Delete</a>
                </div>
            </div>
        </div> -->

    <div class="">
      <div class="row field-space flex-container" *ngFor="let user of users; let i=index">
        <div class="col-md-12">
          <div class="border-style" [style.border-color]="colors[i%5]">
            <div class="element">
              <ul class="nav nav-inline flex_box">
                <li style="width: 10%"><img
                    src="{{ user.profile_picture ? base_url2+'app/images/'+user.profile_picture : 'assets/img/logo.jpg' }}"
                    class="img-circle"></li>
                <li style="width: 25%; border-left: 1px solid darkgrey;">{{ user.first_name+' '+user.last_name }}</li>
                <!-- <li style="width: 18%; border-left: 1px solid darkgrey; border-right: 1px solid darkgrey; text-align: center !important; word-break: break-all;text-transform: lowercase;">{{ user.email }}</li> -->
                <li style="width: 20%; text-align: center !important; border-left: 1px solid darkgrey;">{{ user.role }}
                </li>
                <li
                  style="width: 20%; text-align: center !important; border-left: 1px solid darkgrey;border-right: 1px solid darkgray;">
                  {{ user.createdAt | date: "EEE, d MMM | h:mm aa" }}</li>
                <li style="width: 25%;text-align: center" class="flex_box">
                  <a href="javascript:void(0)" (click)="openPermissionModal(user)" data-toggle="tooltip"
                    title="permission">
                    <img src="assets/img/manage_users/new_icon/eye_icon.svg" alt="view">
                  </a>
                  <a (click)="change_status(user.id)" data-toggle="tooltip" title="active" class="pending">
                    <!-- <img *ngIf="user.status=='pending' || user.status=='active'" src="assets/img/manage_users/lock.svg" class="pendingImg" /> -->
                    <!-- <img *ngIf="user.status=='block'" src="assets/img/manage_users/unlock.svg" />
                                        {{ user.status=='pending' ? "Pending" : user.status=='active' ? 'Lock' : user.status=='block' ? "Unlock" :"Pending" }} -->
                    <img *ngIf="user.status=='pending' || user.status=='active'" (click)="toggleActiveIndex(i)"
                      data-toggle="modal" data-target="#confirmationModal-3"
                      src="assets/img/manage_users/new_icon/lock-open-{{activeIndices.includes(i) ? 'gray' : 'green'}}.svg"
                      alt="pending">
                    <!-- <img *ngIf="user.status=='pending' || user.status=='active'" (click)="changeIsActive(user.id,1)" data-toggle="modal" data-target="#confirmationModal-3" src="assets/img/manage_users/new_icon/lock-open-{{isActive ? 'gray' : 'green'}}.svg" alt="pending"> -->
                  </a>

                  <a (click)="change_status(user.id)" data-toggle="tooltip" title="inactive" class="pending">
                    <!-- <img *ngIf="user.status=='pending' || user.status=='active'" src="assets/img/manage_users/lock.svg" class="pendingImg" /> -->
                    <!-- <img *ngIf="user.status=='block'" src="assets/img/manage_users/unlock.svg" />
                                        {{ user.status=='pending' ? "Pending" : user.status=='active' ? 'Lock' : user.status=='block' ? "Unlock" :"Pending" }} -->
                    <img *ngIf="user.status=='pending' || user.status=='active'" (click)="toggleActiveIndex(i)"
                      data-toggle="modal" data-target="#confirmationModal-3"
                      src="assets/img/manage_users/new_icon/lock-close-{{activeIndices.includes(i) ? 'red' : 'gray'}}.svg"
                      alt="pending">
                  </a>
                  <a (click)="edit(user.id, user.first_name+' '+user.last_name)" data-toggle="tooltip" title="edit">
                    <img src="assets/img/manage_users/new_icon/edit_icon.svg" alt="edit">
                  </a>
                  <!-- <a (click)="delete(user.id,user.first_name+' '+user.last_name)" data-toggle="tooltip" title="delete" data-toggle="modal" data-target="#confirmationModal-2">
                                        <img src="assets/img/manage_users/new_icon/delete_icon.svg" alt="delete">
                                    </a> -->
                </li>
              </ul>
            </div>
            <!-- <div class="action-btns">
                            <a class="btn btn_pad  hov-btn {{ user.status=='pending' ? 'btn-warning pending'  : user.status=='active' ? 'btn-danger'  :  user.status=='block' ? 'btn-primary' :'btn-primary' }} mar1" (click)="change_status(user.id)" [ngClass]="darkMode ? 'dark_pan' : 'null' ">
                                <img *ngIf="user.status=='pending' || user.status=='active'" src="assets/img/manage_users/lock.svg" class="pendingImg" />
                                <img *ngIf="user.status=='block'" src="assets/img/manage_users/unlock.svg" />{{ user.status=='pending' ? "Pending" : user.status=='active' ? 'Lock' : user.status=='block' ? "Unlock" :"Pending" }}
                            </a>
                            <a *ngIf="user.status=='pending' " class="btn " style="text-transform:capitalize;padding: 8px 5px 6px 0px;"><img src="assets/img/manage_users/lock.png" />>Pending</a>
                            <a *ngIf="user.status=='active' " class="btn " style="text-transform:capitalize;padding: 8px 5px 6px 0px;"><img src="assets/img/manage_users/lock.png" />Lock</a>
                            <a *ngIf="user.status=='block' " class="btn " style="text-transform:capitalize;padding: 8px 5px 6px 0px;"><img src="assets/img/manage_users/unlock.png" />Unlock</a>
                            <a class="btn btn-primary hov-btn mar2" [ngClass]="darkMode ? 'btnblue_dark' : ' btn-primary' " (click)="edit(user.id, user.first_name+' '+user.last_name)" style="padding: 8px 5px 6px 0px;"><img src="assets/img/manage_users/edit.png" style="margin-bottom: 3px; width: 17px;">Edit</a>
                            <a class="btn btn-danger hov-btn mar3" [ngClass]="darkMode ? 'btnred_dark' : ' btn-danger' " (click)="delete(user.id,user.first_name+' '+user.last_name)" style="padding: 8px 5px 6px 0px;" data-toggle="modal" data-target="#confirmationModal-2"><img src="assets/img/manage_users/trash.svg" style="margin-bottom: 3px; width: 14px;">Delete</a>
                        </div> -->
          </div>
        </div>
      </div>
      <div *ngIf="users?.length == 0">
        <p>
           Sorry, no users found...
        </p>
      </div>
    </div>
  </div>

  <!-- <div class="create-new">
        <a [routerLink]="['/app/back_office/create_users']" routerLinkActive="router-link-active"  class="btn btn-lg btn-primary pull-right" style="height: 46px;">
            &nbsp;&nbsp;&nbsp;&nbsp; + Create New User &nbsp;&nbsp;&nbsp;&nbsp;
        </a>
    </div> -->

  <div class="modal-footer" style="padding: 0px 18px;">

    <a class="btn btn-danger btn-lg hov-btn" data-dismiss="modal" aria-label="Close"
      [ngClass]="darkMode ? 'btnred_dark' : ' btn-danger' ">Close</a>
  </div>
</div>

<!-- /* permission modal (working on saud) 👇 */ -->

<div id="draggable" *ngIf="showBoxExtra == true" [ngClass]="darkMode ? 'modal_dark' : null">
  <div class="border-bread" id="grab-5" (mouseover)="drag('do')" (mouseout)="drag('dont')">
    PERMISSIONS
    <button aria-label="Close" class="close hor" type="button" (click)="showBoxExtra = !showBoxExtra">
      <strong>&#x2716;</strong>
    </button>
  </div>
  <div class="scroll-card no_scroll">
    <div class="col-md-12 pl-0 pr-0">
      <!-- <label class="allLabel" for="allSelectChannel">
                <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
           </label> -->
      <ng-container *ngFor="let permission of permissions;index as i">
        <div class="div-block-channels-grid">
          <span class="con-chan">{{ permission.app_permission.name }} </span>
        </div>
      </ng-container>
      <label *ngIf="this.permissions.length < 1">No Permission</label>
    </div>
  </div>
</div>
