import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payroll-beardcrum',
  templateUrl: './payroll-beardcrum.component.html',
  styleUrls: ['./payroll-beardcrum.component.css']
})
export class PayrollBeardcrumComponent implements OnInit {

  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
