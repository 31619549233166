<!-- Food and Beverages (Setup_Shop-Page) work start -->

<div class="main-div">
    <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
        <div class="row">
            <div class="column-wrapper display-flex space-between">
                <div>
                    <ul class="nav navbar-nav tabsBar">
                        <a class="btn btn-link" routerLink="/app/back_office/fnb_create_menu"
                            routerLinkActive="active">CREATE MENU</a>
                        <a class="btn btn-link" routerLink="/app/back_office/fnb_create_users_fnb"
                            routerLinkActive="active">CREATE USERS</a>
                    </ul>
                </div>
            </div>

        </div>
    </div>

    <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">
        <form [formGroup]="userForm">
            <div class="row">
                <div class="col-md-12 inputCat">
                    <div class="col-md-6 first">
                        <div class="modal-bar border-1">
                            <input class="form-control" placeholder="First Name" type="text" formControlName="fname"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6 first">
                        <div class="modal-bar border-2">
                            <input class="form-control" placeholder="Last Name" type="text" formControlName="lname"
                                required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 inputCat">
                    <div class="col-md-6 first">
                        <div class="modal-bar border-3">
                            <input class="form-control" placeholder="Email" type="email" formControlName="email"
                                [email]="true" required>
                            <!-- <small *ngIf="email.invalid" class="text-danger">Please Enter Valid Email</small> -->
                        </div>
                    </div>
                    <div class="col-md-6 first">
                        <div class="modal-bar border-4">
                            <input class="form-control" placeholder="Phone" type="number" formControlName="phone"
                                required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 inputCat">
                    <div class="col-md-12 first">
                        <div class="modal-bar border-5">
                            <ng-select class="ng-select" placeholder="Role" [items]="selectRole" bindLabel="name"
                                role="listbox" formControlName="role" [clearable]="true" [closeOnSelect]="true"
                                groupBy="type" required>
                                <!-- <ng-option [value]="selectRole.name" *ngFor="let role of selectRole">
                                    {{role.name}}
                                </ng-option> -->
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-11 inputCat2">
                    <div class="modal-bar border-6">
                        <ng-select class="ng-select" [items]="shopName" bindLabel="name" placeholder="Shop name"
                            role="listbox" formControlName="shopname" [multiple]="true" [clearable]="true"
                            [closeOnSelect]="true" groupBy="type" required>
                            <!-- <ng-option [value]="shopName.name" *ngFor="let shopname of shopName">
                                {{shopname.name}}
                            </ng-option> -->
                        </ng-select>
                    </div>
                    <div class="col-md-1 btnCol">
                        <button class="addBtn" *ngIf="editMode == false && viewMode == false" type="submit"
                            role="button" [disabled]="userForm.invalid" (click)="saveUserData()"><i
                                class="fa fa-plus"></i></button>
                        <button class="edit_img"><img
                                src="../../../../assets/img/create_user_fnb/{{editMode == true ? 'edit-orange' : viewMode == true ? 'view-purple' : 'null'}}.svg"
                                *ngIf="editMode == true || viewMode == true" role="button" alt="view"
                                (click)="saveUserData()"></button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="blueBar" [ngClass]="darkMode ? 'dark_blueBar' : ''">
        <ul class="nav nav-inline">
            <li>USER</li>
            <li>ROLES</li>
            <li>SHOP</li>
            <li>Action</li>
        </ul>
    </div>
    <div class="card userRecord" [ngClass]="darkMode ? 'card_dark' : ''">
        <ng-container *ngFor="let userDetail of userDetails;index as u">
            <div class="row">
                <div class="col-md-10 inputCat">
                    <div class="modal-bar border-7">
                        <span>{{userDetail.fname}} {{ userDetail.lname}}</span>
                        <span>{{userDetail.role.name}}</span>
                        <div class="shop-name">
                            <ul>
                                <li *ngFor="let shop of userDetail.shopname">{{shop.name}}</li>
                                <!-- <li>Resturant</li>
                                <li>Cafe</li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <img src="../../../../assets/img/create_user_fnb/view-purple.svg" role="button" alt="view"
                        (click)="onViewUserData(u)">
                    <img src="../../../../assets/img/create_user_fnb/edit-orange.svg" role="button" alt="edit"
                        (click)="onEditUserData(u)">
                    <img src="../../../../assets/img/create_user_fnb/delete-red.svg" role="button" alt="delete"
                        (click)="onDeleteUserData(u)">
                </div>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" [ngClass]="darkMode ? 'btnDark' : ''">Save</button>
    </div>
</div>