import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CategoriesAndItemsResponse } from 'src/app/common/fnb/CategoriesAndItemsModel';
import { Shops } from 'src/app/common/fnb/createShop';
import { FnbService } from 'src/app/services/fnb.service';
import { RmsService } from 'src/app/services/rms.service';

@Component({
  selector: 'app-rms-breadcrum',
  templateUrl: './rms-breadcrum.component.html',
  styleUrls: ['./rms-breadcrum.component.css']
})
export class RmsBreadcrumComponent implements OnInit {

  darkMode: boolean = false;
  shopNames: Shops[] = [];
  activeShopId: number;
  @Output() shopSwitched = new EventEmitter<number>();

  constructor(
    private rmsService: RmsService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.xAxisScrollTab();
    this.isActiveShopId();
    this.getShopNames();
  }

  xAxisScrollTab() {
    const tabsBar = document.getElementById('shopNames');
    // x axis mouse wheel scroll breadcrumb (working on saud 👇)
    tabsBar.addEventListener('wheel', (event) => {
      event.preventDefault();
      tabsBar.scrollLeft += event.deltaY;
    });
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // get active shop id
  isActiveShopId() {
    this.rmsService.activeShopId$.subscribe((res: number) => {
      this.activeShopId = res;
    });
  }

  // get shop name (working on saud 👇)
  getShopNames() {
    this.rmsService.shopNames$.subscribe(shopNames => {
      this.shopNames = shopNames;
      this.switchShop(this.shopNames[0].shop_id);
    });
  }

  //  switch shop button click to get data (working on saud 👇)
  switchShop(shopId: number) {
    this.activeShopId = shopId;
    this.rmsService.matTabIndex$.next(0)
    this.rmsService.activeShopId$.next(this.activeShopId);
    this.rmsService.fetchDealMenuItems(shopId);
    this.rmsService.fetchProductMenuItems(shopId);
  }
}
