<div [ngClass]="{'dark-mode': layout_service.dark_mode}">
  <div class="container-fluid">
    <div class="row" [ngClass]="{'sidebar-collapse': layout_service.collapse}">
      <div class="sidebar_wrapper">
        <aside>
          <app-sidebar></app-sidebar>
        </aside>
      </div>
      <div class="content_wrapper content_scroll" [ngClass]="darkMode ? 'dark_content_wrapper' : 'content_wrapper'">
        <div class="app_grid">
          <app-header></app-header>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <div class="httpLoader" [class.hide]="!spinner">
    <!-- <span class="glyphicon glyphicon-refresh icon spinning"></span> -->
    <!-- <img src="assets/img/other/loader.gif"> -->
    <!-- <div class="wrapper">
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="loading-circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Loading</span>
    </div> -->
    <ngx-spinner name="sp6" [fullScreen]="false" type="ball-atom" size="large" bdColor="rgb(128, 128, 128, 0)">
    </ngx-spinner>
    <!-- <div class="lds-heart"><div> -->
    <!-- <div class="loadingio-spinner-bean-eater-56bynttwg7m"><div class="ldio-tcr5xlurhn">
      <div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
      </div>
    </div> -->
  </div>
  <app-confirmation-alert></app-confirmation-alert>
</div>