import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Decimation } from 'chart.js';
import { data } from 'jquery';
import { DecimalPipe } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
import { PnlService } from 'src/app/services/pnl.service';
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";

import * as moment from 'moment';
// import { Expenses } from './expenses';
import { v4 as uuidv4 } from 'uuid';
import { X } from '@angular/cdk/keycodes';
import { NavigationStart, Router } from '@angular/router';
import { ExpenseModel } from 'src/app/common/pnl/ExpenseModel';
import { ExpenseTypeModel } from 'src/app/common/pnl/ExpenseTypeModel';
declare var $: any;



@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {
  a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1,];

  // Expenses: ExpenseModel[] = [];

  Expenses: ExpenseModel[] = [];
  originalExpenses: ExpenseModel[] = [];
  selectedMonth: string;
  start_date: string;
  end_date: string;
  isEdited: boolean = false; //tracks if expenses are edited
  currency_format = localStorage.getItem('currency');
  expenseTypes: ExpenseTypeModel[];
  property = JSON.parse(localStorage.getItem('property'))

  shortLink: string = "";
  loading: boolean = false; // Flag variable
  file: File = null; // Variable to store file
  darkMode: boolean = false;
  arrowUpDown: boolean = false;

  constructor(
    private pnlApi: PnlService,
    private helperService: HelperService,
    private confirm: ConfirmationAlertService,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private router: Router,

  ) {
    let currentUrl = router.url;

    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {

        //todo: write your own condition here

        if (PnlService.isChangesUnsaved) {
          this.router.navigate([currentUrl]);
        }
      }
    });

  }

  ngOnInit() {
    this.checkDarkMode();
    this.pnlApi.getExpenseTypes().subscribe((res) => {
      console.log(res, "expense types");
      this.expenseTypes = res.data.expense_types;
      console.log(this.expenseTypes, 'expenses data');
    });

    this.onDateChanged();
    // this.fetchExpenses(this.selectedMonth);
    this.fetExpensesByDate(this.start_date, this.end_date)

  }

  // private fetchExpenses(month: string) {
  //   this.pnlApi.getExpensesByMonth(month).subscribe((res) => {
  //     // debugger;
  //     this.Expenses = res.data.expenses.data.filter(x => x.is_deleted == false) || [];
  //     this.Expenses.forEach(x => x.amountFormatted = this.decimalPipe.transform(x.amount, '1.2-2'))
  //     this.originalExpenses = this.Expenses;

  //   });
  // }

  private fetExpensesByDate(start: string, end: string) {
    this.pnlApi.getExpenses(start, end).subscribe((res) => {
      // debugger;
      this.Expenses = res.data.expenses.data.filter(x => x.is_deleted == false) || [];
      this.Expenses.forEach(x => x.amountFormatted = this.decimalPipe.transform(x.amount, '1.2-2'))
      this.originalExpenses = this.Expenses;
    });
  }

  onDateChanged() {
    // console.log(event);
    let val = localStorage.getItem('pnl_selected_Date');

    let dateRange = JSON.parse(val);

    if (dateRange) {
      let month = dateRange.startDate.slice(0, 7);
      this.selectedMonth = month;

      this.start_date = dateRange.startDate.split('T')[0]
      this.end_date = dateRange.endDate.split('T')[0]

      this.fetExpensesByDate(this.start_date, this.end_date)
      //this.fetchExpenses(month);
    } else {
      this.selectedMonth = moment().format('YYYY-MM');

      this.start_date = new Date().toJSON().slice(0, 10);
      this.end_date = new Date().toJSON().slice(0, 10)

    }
  }


  // save all expenses?

  onSaveExpensesData() {
    console.log('save expenses data');
    //filter out expense that has no amount
    let filteredList = this.Expenses.filter(x => x.amount && x.name !== '' && x.name !== null && x.name !== undefined);
    console.log(filteredList)
    this.pnlApi.saveExpenses(filteredList).subscribe(result => {
      if (result.status == 200) {
        this.helperService.alert_success(result.message);
      } else {
        this.helperService.alert_error(result.message || "Failed to save changes.");
      }
      //this.fetchExpenses(this.selectedMonth);
      this.fetExpensesByDate(this.start_date, this.end_date)
    });
    this.isEdited = false;
  }


  // sum total all expenses?

  sumTotalExpenses() {
    return this.Expenses.filter(x => x.amount != null && x.is_deleted == false).map(x => x.amount).reduce((a, b) => Number(a) + Number(b), 0).toFixed(2);
  }

  setExpenseAmount(ev: any, expense: ExpenseModel) {

    if (ev.includes(',')) {
      expense.amount = parseFloat(ev.replace(/,/g, ''));
    } else {
      expense.amount = ev
    }
  }



  filteredExpenseList(expenseTypeId: any) {
    let expenseList = this.Expenses.filter(x => x.expense_type_id == expenseTypeId && x.is_deleted == false);
    return expenseList;
  }


  isExpenseListEdited(): boolean {
    let isEdited = false;

    let filteredExpense = this.Expenses.filter(x => x.expense_id !== null && x.expense_id !== 0);
    this.originalExpenses.forEach(element => {
      let comparisionObj = filteredExpense.find(x => x.expense_id == element.expense_id);
      if (comparisionObj) {
        if (comparisionObj.name.includes(element.name)) {
          isEdited = true;
        }
        if (comparisionObj.is_deleted !== element.is_deleted) {
          isEdited = true;
        }
        if (comparisionObj.amount !== element.amount) {
          isEdited = true;
        }
        if (comparisionObj.is_fixed !== element.is_fixed) {
          isEdited = true;
        }
      }
    });
    return isEdited;
  }


  OnExpenseUpdated(expense: ExpenseModel) {
    let index = this.Expenses.indexOf(expense)
    this.Expenses[index] = expense
    PnlService.isChangesUnsaved = this.isExpenseListEdited()
  }

  OnExpenseAdded(expense) {
    this.Expenses.push(expense);
    // PnlService.isChangesUnsaved = this.isExpenseListEdited()
  }

  OnUploadExpenseExcel() {
    this.pnlApi.uploadExpenseExcel(this.file).subscribe(
      (event: any) => {
        if (typeof (event) === 'object') {

          console.log(event, 'event')
          // Short link via api response
          // this.shortLink = event.link;

          // this.loading = false; // Flag variable
        }
      }
    );
  }

  onChange(event) {
    this.file = event.target.files[0];
  }


  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  changeArrow(id: string, i: number) {
    // this.arrowUpDown = !this.arrowUpDown;

    let ids = <HTMLElement>document.getElementById(id + i);
    console.log(ids);

    if (ids.classList.contains('rotat')) {
      ids.classList.remove('rotat')
    } else {
      ids.classList.add('rotat')
    }
  }

}



