<div class="" id="createPackageDiv">


  <form class="form-horizontal back-form" [formGroup]="create_package_form" (ngSubmit)="onSubmit()">
    <div class="">
      <div class="alert alert-info alert-dismissible show pl-2" role="alert">
        <p>
          {{ service.is_edit == true ? "Edit Internal Package" : "Create Internal Package" }}
        </p>
        <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button" (click)="onClose()">
          <strong id="cross">&#x2716;</strong>
        </button>
      </div>

      <div class="alert alert-danger breadcrum mb-breadcrum">
        <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
        <a routerLink="/app/rates_packages/setup_package" class="btn btn-link active"> <span class="abc">{{ service.is_edit ? "EDIT" :
            "SETUP" }} PACKAGE</span></a>
        <!-- <a href="" class="btn btn-link active"> <span class="abc"> HONEY MOON PACKAGE</span></a> -->
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="package-name margin-col-1">
              Package Name
            </div>
          </div>
          <div class="col-md-6">
            <div class="package-name margin-col-2">
              Dates
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="card margin-bottom">
              <div class="border-style color-5">
                <input type="text" class="input form-control"
                  placeholder="Eg. Penthouse 2 King 4 Queen 360 Panoramic View" style="outline: none;"
                  formControlName="package_name">
              </div>
              <div *ngIf="submitted && f.package_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.package_name.errors.required">Package Name is required</strong>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card margin-bottom">
              <div class="border-style color2" style="padding: 0px">
                <!-- <input type="text" class="input" placeholder="Select Your Packages Dates" style="outline: none;" formControlName="date_from">  -->
                <!-- <span class="pointclick_date left_input_date"
                  style="outline: none !important;background: transparent;border: none;">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <input matInput [matDatepicker]="picker" [min]="minDate" placeholder="Checkin date"
                    formControlName="date_from" style="outline: none !important;background: transparent;border: none;">
                  <mat-datepicker #picker panelClass="setup_package_date"></mat-datepicker>

                </span>
                <span class="pointclick_date right_input_date"
                  style="outline: none !important;background: transparent;border: none;position: relative;">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <input matInput [matDatepicker]="picker2" [min]="minDate" placeholder="Checkout date"
                    formControlName="date_to" style="outline: none !important;background: transparent;border: none;">
                  <mat-datepicker #picker2 panelClass="setup_package_date"></mat-datepicker>
                </span> -->
                <span class="input-group-addon"
                  style="background: transparent; border: none;padding-left: 20px;font-size: 24px;color: #00205b; display: inline">
                  <i class="fa fa-calendar-alt"></i>
                </span>
                <input type="hidden" formControlName="date_to" name="date_to" [(ngModel)]="date_to">
                <input type="hidden" formControlName="date_from" name="date_from" [(ngModel)]="date_from">
                <input type="text" ngxDaterangepickerMd class="form-control date_range_picker" [autoApply]="true"
                  (change)="choose_date($event)" placeholder="Package Life" formControlName="date" [(ngModel)]="date"
                  [locale]="{format: 'DD, MMMM YYYY', separator: ' To '}"
                  style="display: inline-block; width: 80%; padding: 5px;">
              </div>
              <div *ngIf="submitted && f.date_from.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.date_from.errors.required">Checkin Date is required</strong>
              </div>
              <div *ngIf="submitted && f.date_to.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.date_to.errors.required">Checkout Date is required</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="package-name margin-col-1">
              Room
            </div>
          </div>
          <div class="col-md-6">
            <div class="package-name margin-col-2">
              Breakfast
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-6">
            <div class="card margin-bottom">
              <div class="border-style country-shade dropdown-pd">
                <ng-select class="form-control input_2" id="select_room_type" placeholder="Select Room"
                  class="pkg_rooms" [items]="roomtypes" bindLabel="name" formControlName="room_type_id" bindValue="id"
                  (change)="getSelectedRoom($event)"></ng-select>
              </div>
              <div *ngIf="submitted && f.room_type_id.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.room_type_id.errors.required">Room Selection is required</strong>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card margin-bottom">
              <div class="border-style color-jj">

                <div class="radio-box">
                  <label class="control-label radio-style chbx">
                    <input type="radio" class="pull-left radio" name="breakfast_include" value="Paid"
                      formControlName="breakfast_include">
                    <span class="radio-checkmark rd"></span>
                    <span class="title">Paid</span>
                  </label>
                </div>


                <div class="radio-box">
                  <label class="control-label radio-style chbx">
                    <input type="radio" class="pull-left radio" name="breakfast_include" value="Complimentry"
                      formControlName="breakfast_include">
                    <span class="radio-checkmark rd"></span>
                    <span class="title">Complimentry</span>
                  </label>
                </div>


                <div class="radio-box">
                  <label class="control-label radio-style chbx">
                    <input type="radio" class="pull-left radio" name="breakfast_include" value="Exclude"
                      formControlName="breakfast_include">
                    <span class="radio-checkmark rd"></span>
                    <span class="title">Exclude</span>
                  </label>
                </div>

              </div>
              <div *ngIf="submitted && f.breakfast_include.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.breakfast_include.errors.required">Breakfast is required</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="package-name margin-col-2">
              Description
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <!-- <div class="col-md-12"> -->
        <div class="col-md-12">
          <div class="card margin-bottom pl">
            <div class="border-style color3">
              <textarea class="form-control" formControlName="description" placeholder="Package Description"></textarea>
            </div>
            <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.description.errors.required">Description is required</strong>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="package-name margin-col-2">
              Upsell
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card margin-bottom pl">

            <div class="row" *ngFor="let upsel of upsells; let i=index" style="padding-bottom: 5px;">
              <div class="col-md-12">
                <div class="col-md-5" style="padding-right: 3px;width:47.6%">
                  <div class="border-style" [ngStyle]="{'border-color': colors[i%colors.length]}">
                    <!-- <div class="border-style" [style.border-color]="getRandomColor()"> -->
                    <input type="text" class="form-control input" placeholder="Select things to upsell"
                      value="{{ upsel.name.replace('_', ' ') | titlecase }}" disabled>
                  </div>
                </div>
                <div class="col-md-5" style="padding: 0px;padding-left: 2px;padding-right: 5px;width:47.6%">
                  <div class="border-style" [ngStyle]="{'border-color': colors2[i%colors2.length]}">
                    <!-- <div class="border-style" [style.border-color]="getRandomColor()"> -->
                    <input type="number" class="form-control input" placeholder="Amount"
                      value="{{ upsel.value !=null ? upsel.value : ''}}" disabled>
                  </div>
                  <!-- <a class="btn btn-danger" style="margin-left: 10px" (click)="remove_upsell(upsel.name)"><i class="fa fa-times"></i></a> -->
                </div>
                <div class="col-md-2" style="width: 4.2%;padding: 0px;">
                  <a class="btn btn-danger" (click)="remove_upsell(upsel.name)"><i class="fa fa-times"></i></a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="col-md-5" style="padding-right: 3px;width:47.6%">

                  <div class="border-style" style="border-color: brown">
                    <div *ngIf="open_custom == true">
                      <input type="text" name="sec_custom_room_type" class="form-control input"
                        id="sec_custom_room_type" placeholder="Enter your custom upsell" formControlName="upsel_item"
                        [(ngModel)]="upsel_item">
                      <a class="btn btn-xs custom_que_close" (click)="resetOptions()"
                        style="padding: 2px 22px !important;">&#x2716;</a>
                    </div>
                    <!-- <ng-select placeholder="Select things to Upsell" [(ngModel)]="upsel_item" formControlName="upsel_item" appendTo="body">
                        <ng-option value="parking">Parking</ng-option>
                        <ng-option value="pool">Pool</ng-option>
                        <ng-option value="spa">Spa Services</ng-option>
                        <ng-option value="room_upgrade">Room Upgrade</ng-option>
                        <ng-option value="tennis_court">Tennis Court</ng-option>
                        <ng-option value="gym">Gym</ng-option>
                        <ng-option value="sauna">Sauna</ng-option>
                      </ng-select> -->
                    <div *ngIf="open_custom == false">
                      <ng-select placeholder="Select things to Upsell" [(ngModel)]="upsel_item"
                        formControlName="upsel_item" [items]="upsell_items" bindLabel="name"
                        bindValue="value"></ng-select>
                    </div>
                  </div>
                </div>
                <div class="col-md-5" style="padding: 0px;padding-left: 2px;padding-right: 5px;width:47.6%">
                  <div class="border-style" style=" border-color:olive">
                    <input type="number" class="form-control input" placeholder="Amount" [(ngModel)]="upsel_value"
                      formControlName="upsel_value">
                  </div>
                  <!-- <a class="btn btn-primary" style="margin-left: 10px" (click)="add_upsell(upsel_item, upsel_value)"><i class="fa fa-plus"></i></a> -->
                </div>
                <div class="col-md-2" style="width: 4.2%;padding: 0px;">
                  <a class="btn btn-primary" style="width: 97%;" (click)="add_upsell(upsel_item, upsel_value)"><i
                      class="fa fa-plus"></i></a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-md-12">
          <!-- <div class="col-md-12"> -->
          <div class="card margin-bottom pl" style="margin-top: 5px !important;margin-bottom: 5px !important;">
            <div class="row" *ngIf="selectedRoom">
              <div class="col-md-12">
                <div class="col-md-3">
                  <!-- <img class="img-responsive" src="assets/img/manage-rooms/Mask Group 63.png"> -->
                  <img class="img-responsive"
                    src="{{ selectedRoom.images[0]?.image ? base_url+'app/images/'+selectedRoom.images[0].image : 'assets/img/other/no-room.jpg' }}"
                    style="border-radius: 10px;">
                </div>
                <div class="col-md-7">
                  <h3 class="card-title">
                    {{ selectedRoom.roomtype.name }}
                  </h3>
                  <p class="room-content">
                    {{ selectedRoom.description }}
                  </p>
                  <div class="room-details">
                    <ul class="nav navbar-nav pull-left">
                      <li class="bad-1"><a><img height="20px" src="assets/img/manage-rooms/bed.png" class="pd-footer">
                          {{ selectedRoom.bedtype.name }}</a></li>
                      <li class="bad-1"><a><img height="20px" src="assets/img/manage-rooms/sleep.png" class="pd-footer">
                          Sleeps {{ selectedRoom.adults+selectedRoom.children+selectedRoom.infants }}</a></li>
                      <li class="bad-1"><a><img height="20px" src="assets/img/manage-rooms/size.png" class="pd-footer">
                          {{ selectedRoom.room_size }} Sq ft</a></li>
                      <li class="bad-1"><a>
                          <img *ngIf="selectedRoom.pets == 'Allowed'" height="25px"
                            src="assets/img/manage-rooms/Allowed.png" class="pd-footer">
                          <img *ngIf="selectedRoom.pets == 'Not_Allowed'" height="25px"
                            src="assets/img/manage-rooms/Not_Allowed.png" class="pd-footer">
                          Pets
                          {{ selectedRoom.pets.includes('_') ? selectedRoom.pets.split("_").join(" ") :
                          selectedRoom.pets }}
                        </a></li>
                    </ul>
                  </div>

                </div>
              </div>
              <!-- <div class="col-md-2">
                                  <div class="text-right">
                                      <div class="btn-wrapper">
                                          <a href="javascript:void(0)" class="" (click)="clone(room.id)"><img width="40px" src="assets/img/manage-rooms/duplicate.png"></a>
                                          <a routerLink="/app/back_office/create_rooms/{{ room.id }}"><img width="40px" src="assets/img/manage-rooms/edit.png"></a>
                                          <a href="javascript:void(0)" (click)="delete(room.id,room.roomtype)" class=""  data-toggle="modal" data-target="#confirmationModal"><img width="40px" src="assets/img/manage-rooms/delete.png"></a>
                                      </div>
                                      <br><br><br><br>
                                      <h5 class="room_count_wrapper">Room Count <span class="room_count"></span></h5>
                                  </div>
                              </div> -->
            </div>
            <div class="row" *ngIf="!selectedRoom" style="text-align: center;">
              No Room Selected
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="modal-footer text-right setup-p-0" style="bottom: -55px !important;left: -12px!important;">
      <button class="btn btn-info btn-lg">Save</button>
      <button class="btn btn-danger btn-lg" aria-label="Close" data-dismiss="modal" type="button"
        (click)="onClose()">Cancel</button>
    </div>
  </form>


</div>