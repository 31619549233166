import { EventEmitter, Injectable } from '@angular/core';
import { CategoriesAndItems } from '../common/fnb/CategoriesAndItemsModel';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MenuDealItemResponse, ShopDealItem } from '../common/fnb/MenuDealItem';
import { MenuProductItems, MenuProductResponse } from '../common/fnb/MenuProductModel';
import { FnbService } from './fnb.service';
import { Shops } from '../common/fnb/createShop';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { OrderItemModel } from '../common/rms/OrderItemModel';
import { ViewOrderDataResponse } from '../common/rms/ViewOrderModel';
import { EditOrderItemResponse } from '../common/rms/editOrderItem';
import { RoomGuestResponse } from '../common/rms/RoomNoGuest';
import { PlaceOrderItem } from '../common/rms/PlaceOrderItem';

@Injectable({
  providedIn: 'root'
})
export class RmsService {

  public static OnOrderItemAdded: EventEmitter<object> = new EventEmitter();
  public OnOrderListEmpty: Subject<boolean> = new BehaviorSubject(true);
  public orderTrayEmpty: Subject<boolean> = new Subject();
  private dealCategoryItems$ = new Subject<ShopDealItem[]>();
  private productCategoryItems$ = new Subject<MenuProductItems>();
  private shopNamesSubject: Subject<Shops[]> = new Subject<Shops[]>();
  public shopNames$ = this.shopNamesSubject.asObservable();
  public activeShopId$ = new Subject();
  public matTabIndex$ = new Subject<number>();
  public orderListItem$ = new Subject();
  public readonly roomGuestList$: Observable<RoomGuestResponse>;


  private placeOrderEndpoint: string = '/api/fnb/place_order';
  private GetViewOrderEndpoint: string = '/api/fnb/view_orders';
  private updateOrderEndpoint: string = '/api/fnb/update_order';
  private editOrderEndpoint: string = '/api/fnb/get_single_order/';
  private getRoomGuestListEndpoint: string = '/api/fnb/get_booking_room_list/property/';
  private propertyId: string;

  constructor(private fnbService: FnbService, private api: ApiService, private http: HttpClient) {
    this.propertyId = localStorage.getItem("current_property");
    this.roomGuestList$ = this.http.get<RoomGuestResponse>(this.api.base_url + this.getRoomGuestListEndpoint + this.propertyId,
      {
        headers: this.fnbService.prepareAuthHeaders()
      })
  }

  addOrderItem(orderItem) {
    RmsService.OnOrderItemAdded.emit(orderItem)
  }

  setShopNames(shopNames: Shops[]) {
    this.shopNamesSubject.next(shopNames);
  }

  // get deal data from service (working on saud 👇)
  getDealCategoryItems() {
    return this.dealCategoryItems$.asObservable();
  }

  // get product data from service (working on saud 👇)
  getProductCategoryItems() {
    return this.productCategoryItems$.asObservable();
  }

  // fetch deal menu item according shop id from service (working on saud 👇)
  fetchDealMenuItems(shopId: number) {
    this.fnbService.getDealsItem(shopId).subscribe((res: MenuDealItemResponse) => this.dealCategoryItems$.next(res.data.shops));
  }

  // fetch product menu item according shop id from service (working on saud 👇)
  fetchProductMenuItems(shopId: number) {
    this.fnbService.getMenuProductItems(shopId).subscribe((res: MenuProductResponse) => this.productCategoryItems$.next(res.data));
  }

  placeOrderMenuItemReq(menuItem: OrderItemModel) {
    return this.http.post<OrderItemModel>(this.api.base_url + this.placeOrderEndpoint, menuItem, {
      headers: this.fnbService.prepareAuthHeaders()
    })
  }

  getOrderItemsReq() {
    return this.http.post<ViewOrderDataResponse>(this.api.base_url + this.GetViewOrderEndpoint, { property_id: this.propertyId },
      {
        headers: this.fnbService.prepareAuthHeaders()
      })
  }

  updateOrderItemsReq(orderCancel) {
    return this.http.post(this.api.base_url + this.updateOrderEndpoint, orderCancel,
      {
        headers: this.fnbService.prepareAuthHeaders()
      })
  }

  editOrderItemsReq(orderId: number) {
    return this.http.get<EditOrderItemResponse>(this.api.base_url + this.editOrderEndpoint + orderId,
      {
        headers: this.fnbService.prepareAuthHeaders()
      })
  }


}
