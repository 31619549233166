<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
    <a class="btn btn-link" routerLink="/app/back_office">BACK OFFICE</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">BILLING</a>
    <a class="btn btn-link active">INVOICES</a>
    <div class="col-md-1 " style="float:right;padding:0px">
        <img src="assets/img/inventory/download-icon.png"
            style="height: 40px; margin-top:2px; margin-right:5px; float:right; cursor:pointer" (click)="makePdf()">
    </div>
</div>



<div id="drag-list">
    <div class="card" style="padding: 5px;cursor: default" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="alert alert-info" style="height: 45px; padding: 12px;"
            [ngClass]="darkMode ? 'dark_bg_blue' : 'null'">
            <ul class="nav nav-inline">
                <li class="li-width"><a href="">Invoice ID</a></li>
                <li class="li-width"><a href="">Subscription Plan</a></li>
                <li class="li-width"><a href="">Amount Due</a></li>
                <li class="li-width"><a href="">Due Date</a></li>
                <li class="li-width"><a href="">Status</a></li>
                <li class="li-width" style="padding-left: 8px"><a href="">Payment</a></li>
            </ul>
        </div>





        <div class="" id="drag">

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-green" data-toggle="modal" data-target="#summary">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice greytext">3215655</li>
                                <li class="li-subscription greytext">Basic</li>
                                <li class="li-amount greytext">$ 175.99</li>
                                <li class="li-date" [ngClass]="darkMode ? 'paidtex_dark' : 'null'">14 Aug 2019</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-paid hov-btn">Paid</button>
                                <li class="paid-by" [ngClass]="darkMode ? 'paidtex_dark' : 'null'">John Doe</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-red" data-toggle="modal" data-target="#summary">
                        <div class="element">
                            <ul class="nav nav-inline greytext">
                                <li class="li-invoice greytext">3215687</li>
                                <li class="li-subscription greytext">Premium</li>
                                <li class="li-amount greytext">$ 200.99</li>
                                <li class="li-date" [ngClass]="darkMode ? 'canceltex_dark' : 'null'">07 Dec 2018</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-pending btn-adj hov-btn hov-btn">Cancelled</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj hov-btn">Pay
                                        Now</button>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-green">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice greytext">3215621</li>
                                <li class="li-subscription greytext">Standard</li>
                                <li class="li-amount greytext">$ 120.31</li>
                                <li class="li-date" [ngClass]="darkMode ? 'paidtex_dark' : 'null'">13 Feb 2019</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-paid hov-btn">Paid</button>
                                <li class="paid-by" [ngClass]="darkMode ? 'paidtex_dark' : 'null'">Mr Clark</li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-orange">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice greytext">3215649</li>
                                <li class="li-subscription greytext">Premium</li>
                                <li class="li-amount greytext">$ 149.99</li>
                                <li class="li-date" [ngClass]="darkMode ? 'pendingtext' : 'null'">07 Aug 2019</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-danger btn-adj hov-btn">Pending</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj hov-btn">Pay
                                        Now</button>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-red">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice greytext">3215687</li>
                                <li class="li-subscription greytext">Premium</li>
                                <li class="li-amount greytext">$ 200.99</li>
                                <li class="li-date" [ngClass]="darkMode ? 'canceltex_dark' : 'null'">07 Dec 2018</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-pending btn-adj hov-btn">Cancelled</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj hov-btn">Pay
                                        Now</button>
                                    <!-- <li style="width: 11.5%; padding-left: 40px;">
                                            <img src="assets/img/invoices/download.png" alt="">
                                        </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>

            <div class="row field-space">
                <div class="col-md-12">
                    <div class="border-style border-color-orange">
                        <div class="element">
                            <ul class="nav nav-inline">
                                <li class="li-invoice greytext">3215655</li>
                                <li class="li-subscription greytext">Basic</li>
                                <li class="li-amount greytext">$ 143.99</li>
                                <li class="li-date" [ngClass]="darkMode ? 'pendingtext' : 'null'">14 Aug 2019</li>
                                <li class="li-payment-status"> <button
                                        class="btn btn-status btn-danger hov-btn">Pending</button>
                                <li class="li-pay-now"> <button class="btn btn-status btn-primary btn-adj  hov-btn">Pay
                                        Now</button>
                                    <!-- <li style="width: 11.5%; padding-left: 40px;">
                                            <img src="assets/img/invoices/download.png" alt="">
                                        </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 action-btns">
                </div>
            </div>


        </div>

        <!--  <div class="col-md-12bg-info" style="padding-left: 10px">
                    
                       <span> <i class="fa fa-square sq-1"></i> </span> &nbsp; <span class="cancel-box"> Due | Cancelled </span> 
                       <span> <i class="fa fa-square sq-2"></i> </span> &nbsp; <span class="cancel-box"> Paid </span>
                    
                </div> -->

    </div>
</div>


<!-- <div class="create-new" style="height: 50px;margin-top: 20px;">    
        <a class="btn btn-lg btn-primary pull-right" style="height: 46px; width: 176px; border-radius: 25px;" data-target="#summary" data-toggle="modal">
            Pay Now 
        </a>
    </div> -->

<!-- 
<div class="alert alert-danger breadcrum" style="height: 50px; padding: 20px;">
    <span class="bread_text">Billing History</span>
</div>
<div class="alert alert-info">
    <ul class="nav nav-inline">
        <li style="width: 16.66%"><a href="">Invoice ID</a></li>
        <li style="width: 16.66%"><a href="">Subscription Plan</a></li>
        <li style="width: 16.66%"><a href="">Date</a></li>
        <li style="width: 16.66%"><a href="">Billed</a></li>
        <li style="width: 16.66%"><a href="">Status</a></li>
        <li style="width: 16.66%"><a href="">Paid By</a></li>
      
    </ul>
</div>

<div class="card">
    <div class="">
        <div class="row field-space">
            <div class="col-md-12">
                <div class="border-style">
                    <div class="element">
                        <ul class="nav nav-inline">
                            <li style="width: 16.66%">3215649</li>
                            <li style="width: 16.66%;">Premuim</li>
                            <li style="width: 16.66%;">07 Aug 2019</li>
                            <li style="width: 16.66%;">$ 0.00</li>
                            <li style="width: 16.66%;"><button class="btn btn-paid btn-status green btn-size">Paid</button></li>
                            <li style="width: 16.66%;">Jane Doe</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 action-btns">
                
            </div>
        </div>
       
    </div>
</div>

<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-body">
            <div class="row"> 
                <div class="col-md-12">
                    <app-plan-swtich></app-plan-swtich>
                </div>  
            </div>
        </div>
        </div>
    </div>
</div>-->

<div id="summary" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="summaryModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 5px 5px 0 5px !important;"
                [ngClass]="darkMode ? 'darkpoP' : ' null '">
                <div class="alert alert-info" style=" height: 40px !important; padding-top: 10px !important;"
                    [ngClass]="darkMode ? 'smooth-dark' : ' null '">
                    <h4 class="no-margin">
                        <strong>Billing summary</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <strong id="cross">&#x2716;</strong>
                        </button>
                    </h4>
                </div>

                <div class="alert alert-info"
                    style="background: linear-gradient(#f96061, #860425);  height: 40px !important; padding-top: 10px !important;"
                    [ngClass]="darkMode ? 'dark_bredcrum' : ' null '">
                    <h4 class="no-margin">
                        <strong>Invoices</strong>
                    </h4>
                </div>

                <div class="card" style="padding: 5px;" [ngClass]="darkMode ? 'card-dark' : 'card'">
                    <div class="border-style bar_color1">
                        <div class="element" style="height: 40px;padding:3px 0px;margin-bottom: 5px">
                            <div class="row" style="margin-top: 5px">
                                <div class="col-md-6 zer0-padding text-center modal-col"
                                    style="padding-left: 15px;border-right: 1px solid lightgrey;"><label
                                        class="bsTxt">Invoices ID</label></div>
                                <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;">
                                    <label class="bsNm">3215642</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-style bar_color2">
                        <div class="element" style="height: 40px;padding:3px 0px;margin-bottom: 5px">
                            <div class="row" style="margin-top: 5px">
                                <div class="col-md-6 zer0-padding text-center modal-col"
                                    style="padding-left: 15px;border-right: 1px solid lightgrey;"><label
                                        class="bsTxt">Status</label></div>
                                <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;">
                                    <label class="bsNm">Unpaid</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-style bar_color3">
                        <div class="element" style="height: 40px;padding:3px 0px;margin-bottom: 5px">
                            <div class="row" style="margin-top: 5px">
                                <div class="col-md-6 zer0-padding text-center modal-col"
                                    style="padding-left: 15px;border-right: 1px solid lightgrey;"><label
                                        class="bsTxt">Billing Amount</label></div>
                                <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;">
                                    <label class="bsNm">$450.00</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-style bar_color4">
                        <div class="element" style="height: 40px;padding:3px 0px;margin-bottom: 5px">
                            <div class="row" style="margin-top: 5px">
                                <div class="col-md-6 zer0-padding text-center modal-col"
                                    style="padding-left: 15px;border-right: 1px solid lightgrey;"><label
                                        class="bsTxt">Paid</label></div>
                                <div class="col-md-6 zer0-padding text-center modal-col" style="padding-right: 15px;">
                                    <label class="bsNm">$00.00</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center m-t-5">
                        <a class="btn btn-primary btn-adj hov"
                            style="height: 30px; width: 100px; border-radius: 50px; padding: 4px; font-weight: 700;">Pay
                            Now</a><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>