import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { MenuDealItemResponse, ShopDealItem } from 'src/app/common/fnb/MenuDealItem';
import { Category, MenuProductResponse, Product } from 'src/app/common/fnb/MenuProductModel';
import { Shops } from 'src/app/common/fnb/createShop';
import { FnbService } from 'src/app/services/fnb.service';
import { MenuDealModel, dealItems } from '../create-menu/CreateMenuModel';
import { CreateMenuDealItemResponse } from 'src/app/common/fnb/createMenuDealItemModel';
declare var $: any;


@Component({
  selector: 'app-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.css']
})

export class ManageMenuComponent implements OnInit {
  showText: boolean = false;
  darkMode: boolean = false;
  manageDealMenuItems: ShopDealItem[] = [];
  manageProductMenuItems: Product[] = [];
  categories: Category[] = []
  shopNames: Shops[] = [];
  activeShopId: number = 0;
  currency_format = localStorage.getItem('currency');
  filteredCategoryItems: Product[] = [];
  selectedIndex: number;

  constructor(
    private fnbService: FnbService,
    private confirm: ConfirmationAlertService,
    private toaster: ToastrService,
  ) {
    this.getShopNames();
  }


  ngOnInit() {
    this.checkDarkMode();
    const tabsBar = document.getElementById('tabsBarManageMenu');
    // x axis mouse wheel scroll breadcrumb (working on saud 👇)
    tabsBar.addEventListener('wheel', (event) => {
      event.preventDefault();
      tabsBar.scrollLeft += event.deltaY;
    });
  }

  outOfStock(index: number, items: Product[]) {
    // add class to slide stop on update data
    let tab = document.getElementById('material-tab');
    tab.classList.add('scrollable-tabs');

    const upadteStockData = {
      shop_menu_id: items[index].shop_menu_id,
      item_id: items[index].item.item_id,
      category_id: items[index].category.category_id,
      shop_id: items[index].shop_id,
      cost_price: items[index].cost_price,
      selling_price: items[index].selling_price,
      image: items[index].image,
      stock_status: items[index].stock_status ? 0 : 1,
      is_deleted: 0
    };

    let deleteFormData = this.fnbService.getFormData(upadteStockData, false);
    this.sendFormDataPayload(deleteFormData);

  }

  isActiveDeal(index: number) {
    const activeItem: MenuDealModel = {
      shop_deal_id: this.manageDealMenuItems[index].shop_deal_id,
      deal_items: JSON.stringify(this.manageDealMenuItems[index].deal_items),
      shop_id: this.manageDealMenuItems[index].shop_id,
      deal_name: this.manageDealMenuItems[index].deal_name,
      deal_cost_price: this.manageDealMenuItems[index].deal_cost_price,
      deal_selling_price: this.manageDealMenuItems[index].deal_selling_price,
      image: this.manageDealMenuItems[index].image,
      property_id: this.manageDealMenuItems[index].property_id,
      is_active: this.manageDealMenuItems[index].is_active ? 0 : 1,
      is_deleted: 0
    };

    let deleteFormData = this.fnbService.getFormData(activeItem, false);
    deleteFormData.forEach(ele => console.log(ele))
    this.menuDealItemReq(deleteFormData);
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // get shop name (working on saud 👇)
  getShopNames() {
    this.fnbService.getcreateShopNames().subscribe(res => {
      this.shopNames = res.data.shops;
      this.switchShop(this.shopNames[0].shop_id);
    });
  }

  //  switch shop button click to get data (working on saud 👇)
  switchShop(shop_id: number) {
    this.activeShopId = shop_id;
    this.getDealMenuItems(shop_id);
    this.getMenuProductsItemsSelectShop(shop_id);
  }

  //  selected shop fetch data deal menu list (working on saud 👇)
  getDealMenuItems(shopId: number) {
    this.fnbService.getDealsItem(shopId).subscribe((res: MenuDealItemResponse) => {
      this.manageDealMenuItems = res.data.shops;
      console.log(this.manageDealMenuItems, 'manage menu');
    })
  }

  //  selected shop fetch data product menu (working on saud 👇)
  getMenuProductsItemsSelectShop(shop_id: number) {
    this.fnbService.getMenuProductItems(shop_id).subscribe((res: MenuProductResponse) => {
      this.categories = res.data.categories;
      this.manageProductMenuItems = res.data.products;
      console.log(this.manageProductMenuItems, 'product menu');
    });
  }

  // fetch data selected tab according category id (working on saud 👇)
  fetchMenuItem(event: MatTabChangeEvent) {
    // remove class to slide stop
    let tab = document.getElementById('material-tab');
    tab.classList.remove('scrollable-tabs');

    this.selectedIndex = (event.index - 1);
    const selectedCategory = this.categories[this.selectedIndex];

    if (selectedCategory) {
      this.filteredCategoryItems = this.manageProductMenuItems.filter(item => item.category_id === selectedCategory.category_id);
      console.log(this.filteredCategoryItems, 'filter data');
    } else {
      this.filteredCategoryItems = [];
    }
  }

  deleteMenuItem(index: number, items: Product[]) {
    // add class to slide stop on delete data
    let tab = document.getElementById('material-tab');
    tab.classList.add('scrollable-tabs');

    const deleteData = {
      shop_menu_id: items[index].shop_menu_id,
      item_id: items[index].item.item_id,
      category_id: items[index].category.category_id,
      shop_id: items[index].shop_id,
      cost_price: items[index].cost_price,
      selling_price: items[index].selling_price,
      image: items[index].image,
      stock_status: 0,
      is_deleted: 1
    };

    let deleteFormData = this.fnbService.getFormData(deleteData, false);

    this.confirm.confirm(
      `Alert`,
      `Are you sure you want to delete this menu item?`,
      [`This action can't be undone`, 'Are you sure you want to delete?'],
      () => {
        this.sendFormDataPayload(deleteFormData);
        // Update the filtered category items immediately
        const updatedCategoryItems = [...this.filteredCategoryItems];
        updatedCategoryItems.splice(index, 1);
        this.filteredCategoryItems = updatedCategoryItems;
      },
      '',
      ''
    );
  }

  deleteDealItem(index: number) {
    const deleteData: MenuDealModel = {
      shop_deal_id: this.manageDealMenuItems[index].shop_deal_id,
      deal_items: JSON.stringify(this.manageDealMenuItems[index].deal_items),
      shop_id: this.manageDealMenuItems[index].shop_id,
      deal_name: this.manageDealMenuItems[index].deal_name,
      deal_cost_price: this.manageDealMenuItems[index].deal_cost_price,
      deal_selling_price: this.manageDealMenuItems[index].deal_selling_price,
      image: this.manageDealMenuItems[index].image,
      property_id: this.manageDealMenuItems[index].property_id,
      is_active: 0,
      is_deleted: 1
    };

    let deleteFormData = this.fnbService.getFormData(deleteData, false);

    this.confirm.confirm(`Alert`, `Are you sure you want to delete this menu item?`,
      [`This action can't be undone`, 'Are you sure you want to delete?'],
      () => {
        this.menuDealItemReq(deleteFormData);
      }
      , '', ''
    );
  }

  menuDealItemReq(dealFromData: FormData) {
    this.fnbService.createMenuDealItemReq(dealFromData).subscribe((res: CreateMenuDealItemResponse) => {
      this.toaster.success(res.message);
      this.getDealMenuItems(this.activeShopId);
    });
  }

  sendFormDataPayload(data: FormData) {
    this.fnbService.createMenuProductItemReq(data).subscribe((res: any) => {
      this.toaster.success(res.message);
      this.getMenuProductsItemsSelectShop(this.activeShopId);
    });
  }



}
