<div class="" id="colShrt" style="padding-right: 3px; height: 58vh;" [ngClass]="darkMode ? 'card_dark' : 'null'">

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '"
        style="height: 52px;margin-bottom: 0px;">
        <a class="btn btn-link" data-dismiss="modal" aria-label="Close">Back office</a>
        <a class="btn btn-link active" routerLink="../preview-hotel">PREVIEW HOTEL</a>

        <!-- <div class="searchLeft">
        <div class="row" style="    margin-top: 5px; margin-right: -14px;">
          <div class="col-md-7 sear">
            <input type="text" class="form-control keyword" placeholder="{{'back_office.manage_rooms.search_by_keyword' | translate}}" [(ngModel)]="query" />
          </div>
          <div class="col-md-1 btngo" >
            <img src="assets/img/other/go.png" alt="" class=" gobtn" (click)="param =query" style="height:35px !important; margin-top:3px; margin-left: 3px;" (click)="conPadd()">
          </div>
          <div class=" col-md-4 btncreate" >
            <a [routerLink]="['/app/back_office/create_rooms']" class="btn btn-danger btn-block btn-lg hov " [ngClass]="darkMode ? 'darkBtn' : 'null'">+ {{ "back_office.manage_rooms.create_new_room" | translate }}</a>
          </div>
        </div>
      </div> -->
    </div>
    <div class="scroll">

        <div [ngClass]="darkMode ? 'card-dark' : 'card'"
            [ngStyle]="{'padding': darkMode ? '5px' : '', 'border-radius': darkMode ? '10px' : ''}">
            <div class="row">
                <div class="col-md-3" [ngClass]="darkMode ? 'borrightdARKk' : 'borrightLightt'">
                    <!-- assets/img/select_hotel/img1.png -->
                    <div *ngIf="property?.images.length > 0" id="myCarousel1" class="carousel slide myCarousel"
                        data-ride="carousel" data-interval="false">
                        <div class="carousel-inner">
                            <div class="item" *ngFor="let img of property?.images;let imgIndex = index"
                                [ngClass]="imgIndex == 0  ? 'active' : null">
                                <img [src]="api.imgUrl + img.image" alt="room images">
                            </div>
                        </div>

                        <a class="left carousel-control" href="#myCarousel1" data-slide="prev">
                            <span class="glyphicon "><i class="fa fa-chevron-left"></i></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="right carousel-control" href="#myCarousel1" data-slide="next">
                            <span class="glyphicon "><i class="fa fa-chevron-right"></i></span>
                            <span class="sr-only">Next</span>
                        </a>

                    </div>
                    <div *ngIf="property?.images.length == 0 " style="padding-left: 10px;">
                        <img src="assets/img/select_hotel/img1.png "
                            style="border-radius: 10px; width:100%; height:14vh">
                    </div>

                </div>

                <div class="col-md-7" [ngClass]="darkMode ? 'borrightdARK' : 'borrightLight'">
                    <div style="padding: 0px 5px 0 10px;">
                        <div class="row">
                            <div class="col-md-4 grid_20">
                                <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp one'" class="tooltiptext">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.name}}
                                        <span class="toolo" style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Name
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                    <!-- <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'"> Name </span> -->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp two'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.phone}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Phone
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div [ngClass]="darkMode ? 'orange inp-dark' : 'inp three'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.email}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Email
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp four'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.room_count}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Total Rooms
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp five'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.address_one}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;margin-top: -7px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                1st Address
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">
                                    <!---start tooltip--->
                                    <div class="ispan1 {{property?.address_two == '' ? 'grey-text' : ''}}">
                                        {{property?.address_two == '' ? 'Address 2' : property?.address_two }}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                2nd Address
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.country}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Country
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp eight'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.state}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                State
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp nine'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.city}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                City
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-2 col26">
                    <div class="neomocard" [ngClass]="darkMode ? 'neomocarddark' : 'neomocardlight'">
                        <div>
                            <a class="btn btn-channel link_to_ota_btn btn-hov"
                                [ngClass]="darkMode ?  'btn-hov_dark' : 'btn-hov'">Push</a>
                            <!-- <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="darkMode && room.expedia_status == 'Active' ?  'btn-hov_dark live-btn' : 'btn-hov live-btn'" style="cursor: default;">LIVE</a> -->
                        </div>
                        <div class="card_ui">
                            <span style="float: right;margin-top: 2px;">

                                <a href="javascript:void(0)" class=""
                                    (click)="amenittyModal(property.with_parent_amenities)" style=" margin-right: 5px;">
                                    <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/amenities-01.svg' : 'assets/img/newstaticicons/back-office/amenities-01.svg'}}"
                                        alt="" class="ichove" style="width: 29px;">
                                </a>

                                <!-- OTAs Modal -->
                                <a href="javascript:void(0)" class="" (click)="openOTAsModal()"
                                    style=" margin-right: 5px;">
                                    <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/connetivity.svg' : 'assets/img/newstaticicons/back-office/connetivity.svg'}}"
                                        alt="" class="ichove" style="width: 29px;">
                                </a>

                                <!-- Channels Modal -->
                                <a href="javascript:void(0)" class="" (click)="openChannelsModal()"
                                    style=" margin-right: 5px;">
                                    <img src="{{darkMode ? 'assets/img/newstaticicons/back-office/await.svg' : 'assets/img/newstaticicons/back-office/await.svg'}}"
                                        alt="" class="ichove" style="width: 29px;">
                                </a>


                                <a routerLink="/app/back_office/hotel_setup" style="margin-right: 5px;">
                                    <img width="30px" src="assets/img/newstaticicons/back-office/edit.svg"
                                        class="ichove" />
                                </a>

                            </span>
                        </div>
                    </div>
                </div>


            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="ten-16 pb-2">
                        <div class="row">
                            <div class="col-md-3 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp ten'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.postal_code}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Postal Code
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp eleven'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.weekend_starts}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Weekday
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twelve'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.timezone}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Timezone
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3  grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twenty'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.pricingpack}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Pricing Pack
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp twenty'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.currency}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Currency
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3 grid_20">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp fourteen'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.min_rate}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                Min Rate
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3" style="padding-left: 5px;">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp fifteen'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.eta}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                ETA
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                            <div class="col-md-3" style="padding-left: 5px;">
                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp sixteen'">
                                    <!---start tooltip--->
                                    <div class="ispan1">
                                        {{property?.etd}}
                                        <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'"
                                            style="margin-left: -40px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                ETD
                                            </p>
                                        </span>
                                    </div>
                                    <!---end tooltip--->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <!------serviceAddons collapse -->
                    <details [open]="serviceAddons?.length > 0">
                        <summary class="show2 pb-2 mb-1"
                            (click)="btn2.classList.contains('rotat') ? btn2.classList.remove('rotat') : btn2.classList.add('rotat')">
                            Service Add-Ons
                            <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" #btn2>
                        </summary>
                        <details>
                            <summary>
                                <div class="col-md-12 " style="padding-left:0px;padding-right:0px;">

                                    <div class="">

                                        <div *ngFor="let ex of serviceAddons">
                                            <div class="col-md-6" style="padding-right:7px; padding-left: 0px;">
                                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp three'">
                                                    {{ex.name}}
                                                </div>
                                            </div>

                                            <div class="col-md-6 " style="padding-left: 0px; padding-right: 0px;">
                                                <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp four'">
                                                    {{ex.price}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-----------scrolling div------>
                                </div>
                            </summary>

                        </details>

                    </details>
                    <!------end----->
                </div>
                <div class="col-md-6 pl-2">
                    <!------ minibarAddons collapse -->
                    <details [open]="minibarAddons?.length > 0">
                        <summary class="show2"
                            (click)="btn3.classList.contains('rotat') ? btn3.classList.remove('rotat') : btn3.classList.add('rotat')">
                            Food | Mini Bar Add-Ons
                            <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" #btn3>
                        </summary>
                        <details>
                            <summary>
                                <div class="col-md-12 " style="padding-left:0px;padding-right:0px;">

                                    <div class="">

                                        <div *ngFor="let mini of minibarAddons">
                                            <div class="col-md-6 " style="padding-right: 5px; padding-left: 0px;">
                                                <div class="inp" [ngClass]="darkMode ? 'inp-dark orange' : 'inp five'">
                                                    {{mini.name}}
                                                </div>
                                            </div>

                                            <div class="col-md-6" style="padding: 0px;">
                                                <div class="inp" [ngClass]="darkMode ? 'orange inp-dark' : 'inp seven'">
                                                    {{mini.price}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-----------scrolling div------>
                                </div>
                            </summary>

                        </details>

                    </details>
                    <!------end----->

                </div>
            </div>
        </div>

        <div class=" mymodal0  modal-footer" [ngClass]="darkMode ? 'dark-btn' : 'null'"
            style="bottom: -8%; padding: 0 18px;">
            <a (click)="hideModal()" class="btn btn-lg hov-btn btn-danger btn-manage-hotel" data-dismiss="modal"
                aria-label="Close"> Close</a>
        </div>
    </div>



    <div id="draggable" *ngIf="showBoxOta == true"
        [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">

        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
            [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
            style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;font-size: 17px;"
            (mouseover)="drag('do')" (mouseout)="drag('dont')">
            OTA's
            <button aria-label="Close" class="close hor" type="button" (click)="showBoxOta = !showBoxOta">
                <strong style="font-size: 17px;font-weight: 100;">&#x2716;</strong>
            </button>
        </div>

        <div class="scroll"
            [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}"
            style="height:21vh;">
            <div class=" col-md-12 pl-0 pr-0" style="position: relative; bottom:15px;">
                <label class="allLabel" for="allSelectOta">
                    <!-- <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">OTA's</div> -->
                    <!-- <input class="checkbox" name="booked_date" id="allSelectOta" type="checkbox" (click)="allSelectOta($event)"/> -->
                    <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
                </label>
                <ng-container *ngFor="let ota of otas">
                    <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null">
                        <label>
                            <span class="con-chan" [ngClass]="darkMode ? 'con-chan-dark' : 'con-chan'">
                                <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota?.image : ota?.image}}"
                                    alt="" class="image-ota" />
                            </span>
                            <!-- <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}"
                (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" /> -->
                            <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
                        </label>
                    </div>
                </ng-container>
                <label *ngIf="this.otas.length<1">No OTAs Connected</label>
            </div>


        </div>

        <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
      {{ "inventory.promo.save" | translate }}
    </button> -->
    </div>





    <div id="draggable3" *ngIf="showBoxExtra == true"
        [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}">

        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
            [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
            style="color: #fff;font-weight:bold;font-size:17px; height: 40px;padding-top: 10px;"
            (mouseover)="drag('do')" (mouseout)="drag('dont')">
            Channels
            <button aria-label="Close" class="close hor" type="button" (click)="showBoxExtra = !showBoxExtra">
                <strong style="font-size: 17px;font-weight: 100;">&#x2716;</strong>
            </button>
        </div>

        <!-- <div class=" col-md-6 pl-0 pr-0" >
        <label class="allLabel" for="allSelectChannel" style="padding-right: 5px;">
          <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
            <input class="checkbox" name="booked_date" id="allSelectChannel" type="checkbox" (click)="selectAllCh($event)"/>
          <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
        </label>
        <ng-container *ngFor="let ota of channels">
          <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null" *ngIf="ota.type == 'Internal'">
            <label>
            <span class="con-chan">
              <img src="assets/img/svgicons/channels/{{ota?.image}}" alt="" class="image-137 pr-5 pl-5" style="width: 96%;" />
            </span>
              <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}" (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
              <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
            </label>
          </div>
        </ng-container>
      </div> -->

        <div class="scroll"
            [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#fff'}"
            style="height:21vh;">
            <div class=" col-md-12 pl-0 pr-0" style="position: relative; bottom:15px;">
                <label class="allLabel" for="allSelectChannel">
                    <!-- <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div> -->
                    <!-- <input class="checkbox" name="booked_date" id="allSelectOta" type="checkbox" (click)="selectAllChannels($event)"/> -->
                    <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
                </label>

                <ng-container *ngFor="let channel of channels">
                    <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null">
                        <label>
                            <span class="con-chan">
                                <img src="assets/img/svgicons/channels/width-190/{{channel?.image}}" alt=""
                                    class="image-ch " />
                            </span>
                            <input class="checkbox" name="booked_date" type="checkbox" value="{{ channel?.name }}"
                                (click)="add_bulk_channels($event, channel.id)" [checked]="channel?.isBinded" />
                            <!-- <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div> -->
                        </label>
                    </div>
                </ng-container>
                <label *ngIf="this.channels.length<1">No Channels Connected </label>
            </div>
        </div>

        <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
      {{ "inventory.promo.save" | translate }}
    </button> -->
    </div>
    <!-- <div class=" col-md-6 pl-0 pr-0" >
        <label class="allLabel" for="allSelectChannel" style="padding-right: 5px;">
          <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Channels</div>
            <input class="checkbox" name="booked_date" id="allSelectChannel" type="checkbox" (click)="selectAllCh($event)"/>
          <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
        </label>
        <ng-container *ngFor="let ota of channels">
          <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : null" *ngIf="ota.type == 'Internal'">
            <label>
            <span class="con-chan">
              <img src="assets/img/svgicons/channels/{{ota?.image}}" alt="" class="image-137 pr-5 pl-5" style="width: 96%;" />
            </span>
              <input class="checkbox" name="booked_date" type="checkbox" value="{{ ota?.name }}" (click)="add_bulk_channels($event, ota.id)" [checked]="ota?.isBinded" />
              <div class="button red btn-hov_popup_icon" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"></div>
            </label>
          </div>
        </ng-container>
      </div> -->





    <!-- <div id="draggable3" *ngIf="showBoxExtra == true" [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#f0f0f0'}">

  <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5" [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '" style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px;" (mouseover)="drag('do')" (mouseout)="drag('dont')" >
    {{ "inventory.table.channels" | translate }}
    <button aria-label="Close" class="close hor" type="button" (click)="showBoxExtra = !showBoxExtra">
      <strong>&#x2716;</strong>
    </button>
  </div>

    <div class="scroll" [ngStyle]="darkMode ? {'background': 'linear-gradient(#2d2f41,#0e0f14)' } : {'background': '#f0f0f0'}">
      <div class=" col-md-12 pl-0 pr-0" >
        <ng-container *ngFor="let e of extra">
          <label class="allLabel" for="allSelectOta">
            <div class="blueH" [ngClass]="darkMode ? 'blueHD' : 'blueH'">Extras</div>
          </label>
          <ng-container *ngFor="let ota of channels">
            <div style="display: flex;">
              <div>{{e.name}}</div>
              <div>{{e.price}}</div>
            </div>
          </ng-container>
        </ng-container>
      </div>

    </div>

    <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" (click)="saveChannels()">
      {{ "inventory.promo.save" | translate }}
    </button>
  </div> -->

    <div id="draggable2" *ngIf="showAmeBox == true" [ngClass]="darkMode ? 'AmiBack' : 'null'">

        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
            [ngClass]="darkMode ? 'card-dark-top-nav' : 'card-null-null '"
            style="color: #fff;font-weight: bold;height: 40px;padding-top: 10px; font-size: 14px;">
            Amenities
            <button aria-label="Close" class="close hor" type="button" (click)="showAmeBox = !showAmeBox">
                <strong>&#x2716;</strong>
            </button>
        </div>

        <div class="cardBox" [ngClass]="darkMode ? 'AmiBack' : 'null'">

            <div class="row" style="padding: 0px 15px 0px 20px;">
                <div class="col-md-6 zero-padding">
                    <ng-container *ngFor="let ami of aminity;let i = index">
                        <div class="col-md-12 AmiCard-C4" *ngIf="i%2 != 0">
                            <div class="cardAmenity">
                                <div class="row">
                                    <div class="col-md-12 pr20">

                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="icoAmeni">
                                                        <img src="assets/img/hotel-setup/{{ darkMode ? 'Darkmode/darkmode/'+ ami.icon  : ami.icon}}"
                                                            alt="{{ami.icon}}" class="amiIcon">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <span
                                                            [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                                                        <a data-toggle="collapse" href="#collapse{{i}}">
                                                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown{{i}}" class="drop" alt=""
                                                                (click)="changeDrop('dropDown'+i)">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 AmicChild text panel-collapse collapse in" id="collapse{{i}}">
                                        <p *ngFor="let child of ami.children">{{child.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>


                <div class="col-md-6 zero-padding">
                    <ng-container *ngFor="let ami of aminity;let i = index">
                        <div class="col-md-12 AmiCard-C4" *ngIf="i%2 == 0">
                            <div class="cardAmenity">
                                <div class="row">
                                    <div class="col-md-12 pr20">
                                        <div class="line">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="icoAmeni">
                                                        <img src="assets/img/hotel-setup/{{ darkMode ? 'Darkmode/darkmode/'+ ami.icon  : ami.icon}}"
                                                            alt="{{ami.icon}}" class="amiIcon">
                                                    </div>
                                                    <div class="nameAmeni">
                                                        <span
                                                            [ngStyle]="{'color': darkMode ? amenColor[i%amenColor.length] : '#00205b'}">{{ami.title}}</span>
                                                        <a data-toggle="collapse" href="#collapse{{i}}">
                                                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                id="dropDown{{i}}" class="drop" alt=""
                                                                (click)="changeDrop('dropDown'+i)">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12 AmicChild text panel-collapse collapse in" id="collapse{{i}}">
                                        <p *ngFor="let child of ami.children">{{child.title}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

        </div>

    </div>


</div>
