<div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
    <div class="row">
        <div class="column-wrapper display-flex space-between">
            <div class="col-md-10">
                <ul class="nav navbar-nav tabsBar">
                    <a class="btn btn-link" routerLink="/app/back_office/payroll" routerLinkActive="active">SALARIES</a>
                    <a class="btn btn-link">HOURLY</a>
                    <a class="btn btn-link" routerLink="/app/back_office/payroll_employee_lists"
                        routerLinkActive="active">EMPLOYEE LISTS</a>
                </ul>
            </div>
            <div class="col-md-2">
                <img src="../../../../assets/img/inventory/download-icon.png" alt="" srcset="" class="pdf-btn">
                <!-- <div class="pri-btn"></div> -->
            </div>
        </div>
    </div>
</div>