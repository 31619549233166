<!-- <p>expenses works!</p> -->
<app-pnl-breadcrum (dateChanged)="onDateChanged()"></app-pnl-breadcrum>
<!-- <div class="dropdown">
  <a  id="imageDropdown" data-toggle="dropdown" (click)="OnUploadExpenseExcel()"  >
    <img src="assets/img/inventory/download-icon.png" style="height:32px">
  </a>

</div> -->
<div class="dropdown">
  <label>
    <input class="uploadexcelInput" type="file" (change)="onChange($event)" size="60">
    <!-- <a class="uploadExcelImg"> -->
    <!-- <img src="assets/img/inventory/download-icon.png" style="height:32px"> -->
    <!-- <i class="fa fa-upload" aria-hidden="true" style="font-size:16px; padding: 7px; color: white;"></i> -->
    <!-- </a> -->
  </label>

  <!-- <button (click)="OnUploadExpenseExcel()"
      class="btn btn-success">
      Upload
  </button> -->
  <!-- <a  class="uploadExcelImg"  (click)="OnUploadExpenseExcel()" >
    <img src="assets/img/inventory/download-icon.png" style="height:32px">
  </a> -->
</div>



<div class="main-div">
  <div class="expense">
    <div class="col-md-12" style="padding:10px 0 0 0 ;">
      <summary class="flex text space-between show2" [ngClass]="darkMode ? 'dark_bredcrum ' : ''"> OPEX </summary>
      <details *ngFor="let expenseType of expenseTypes;index as i">
        <summary class="flex text space-between red-bar" [ngClass]="darkMode ? 'dark_bredcrum ' : ''"
          (click)="changeArrow('dropDown',i)"> {{expenseType.expense_main_type}}
          <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" [id]="'dropDown' + i">
        </summary>
        <ng-container *ngFor="let type of expenseType.expense_sub_type">
          <app-operating-expense [placeholderText]="type.expense_type" [label]="type.expense_type"
            [expenseTypeId]="type.expense_type_id" [Expenses]="filteredExpenseList(type.expense_type_id)"
            (ExpenseUpdated)="OnExpenseUpdated($event)" (ExpenseAdded)="OnExpenseAdded($event)"></app-operating-expense>
        </ng-container>
      </details>
    </div>
  </div>
  <div class="flex text space-between Total-bar">Total Operating Expense
    <div>{{currency_format}} {{sumTotalExpenses() | number : '1.2-2'}}</div>
  </div>
</div>



<div class="col-md-12 btnSave" [ngClass]="darkMode ? 'darkBtn' : ''">
  <button class="btn saveBtn" (click)="onSaveExpensesData()" (click)="OnUploadExpenseExcel()"
    [ngClass]="isEdited ? 'flashBtn' : ''">Save</button>
</div>