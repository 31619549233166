<div class="d-flex align-items-center justify-content-center" style="height: 407px;">
  <div class="vr w-50" style="flex: 1 1 auto;">
    <!-- <canvas id="opex-breakdown-canvas" class="chartjs-render-monitor opex-breakdown-canvas"></canvas>
    <div id="legend-container" style="margin-left: -100px;"></div> -->
    <div id="opex-breakdown-canvas"></div>
  </div>
  <div class="w-50 expense-chart">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="radio d-flex">
        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip" title="Var">
          <input class="pull-left radio" name="var" id="var" type="checkbox" (click)="var_fix_cost('var')"
            [(ngModel)]="var_cost">
          <span class="radio-checkmark rd"></span>
          <span class="title">Var</span></label>
        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip" title="Fix">
          <input class="pull-left radio" name="fix" id="fix" type="checkbox" (click)="var_fix_cost('fix')"
            [(ngModel)]="fix_cost">
          <span class="radio-checkmark rd"></span>
          <span class="title">Fix</span></label>
      </div>
      <div id="opex-expense-canvas"></div>
      <!-- <canvas id="expense-category-canvas" class="chartjs-render-monitor overridecanvas2"></canvas> -->
    </div>
    <!-- <div id="opex-legend"></div> -->
  </div>
</div>