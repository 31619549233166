<div class="dash_window no_margin w-clearfix dashboard-card " [ngClass]="darkMode ? 'darkDashboard' : 'null'">
    <div class="win_header ">
        <h4 class="heading ">Front Desk</h4>
        <!-- <div class="bar two-c">
                              <ng-select class="form-control custom input_2 padding-holder " placeholder=" Today "
                                  [(ngModel)]="graphDaysPfrontDesk_front" (change)="getDashboardData(graphDaysPfrontDesk_front)"
                                  [items]="graphDaysfrontDesk_front" bindValue="val" bindLabel="days"
                                  style="height: 30px;padding:0px;padding-left: 0px;padding-right: 0px ; border: none">
                              </ng-select>
                          </div> -->
    </div>
    <div class="cardfrontDesk_front " [ngClass]="darkMode ? 'card2_dark':'null'">
        <div class="row rsCard ">
            <div class="col-md-6 zero-padding pt13 pl10 pr10 ">
                <div class="mainLine col-md-12 zero-padding">
                    <div class="ico"> <img
                            src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                            alt="" width="30px" style="    margin-top: 8px;"> </div>
                    <div class="sumDe avai1" [ngClass]="darkMode ? 'purp2' : 'null'">
                        <span>Available</span>
                        <span>{{frontDesk_front?.today_available}}</span>
                    </div>
                </div>
                <div class="mainLine col-md-12 zero-padding">
                    <div class="ico"> <img
                            src="{{darkMode ? 'assets/img/dashboard/sold.svg':'assets/img/dashboard/sold.svg'}}" alt=""
                            width="30px" style="margin-top: 7px;"> </div>
                    <div class="sumDe avai2"> <span>Sold</span>
                        <span>{{frontDesk_front?.sold_today}}</span>
                    </div>
                </div>
                <div class="mainLine col-md-12 zero-padding">
                    <div class="ico"> <img src="assets/img/dashboard/in-house2.svg" alt="" width="30px"> </div>
                    <div class="sumDe avai3"> <span>In-House</span>
                        <span>{{frontDesk_front?.in_house}}</span>
                    </div>
                </div>

                <div class="mainLine col-md-12 zero-padding ndBar mt15">
                    <div class="ico"> <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" width="30px"
                            style="margin-left: -10px;"> </div>
                    <div class="sumDe arriv1"> <span>Arrivals</span>
                        <span>{{frontDesk_front?.arrival_today}}</span>
                    </div>
                </div>
                <div class="mainLine col-md-12 zero-padding ndBar">
                    <div class="ico"> <img
                            src="assets/img/{{darkMode ? 'dashboard/darkmode/departure' : 'newstaticicons/dashboard/departure' }}.svg"
                            alt="" width="20px"> </div>
                    <div class="sumDe arriv2" [ngClass]="darkMode ? 'purp' : 'null'">
                        <span>Departures</span>
                        <span>{{frontDesk_front?.departure_today}}</span>
                    </div>
                </div>
                <div class="mainLine col-md-12 zero-padding ndBar">
                    <div class="ico"> <img
                            src="assets/img/{{darkMode ? 'dashboard/darkmode/reservation' : 'newstaticicons/dashboard/reservation'}}.svg"
                            alt="" width="25px" style="    margin-top: 5px;"> </div>
                    <div class="sumDe arriv3" [ngClass]="darkMode ? 'purp1' : 'null'">
                        <span>Reservation</span>
                        <span>{{frontDesk_front?.reservation_today}}</span>
                    </div>
                </div>
                <div class="mainLine col-md-12 zero-padding ndBar">
                    <div class="ico"> <img src="assets/img/dashboard/cancellation.svg" alt="" width="23px"
                            style="    margin-top: 6px;"> </div>
                    <div class="sumDe arriv4"> <span>Cancellation</span>
                        <span>{{frontDesk_front?.cancellation_today}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6 zero-padding pl2 ">
                <div class="no-gutters col-md-12 zero-padding">
                    <div class="cardRadius">
                        <div id="occupancy_chart"></div>
                    </div>
                </div>
                <div class="no-gutters col-md-12 zero-padding" style="z-index: 9999;margin-top: -8%;">
                    <div class="cardBarsRedGreen">
                        <div class="caret_up hover_effect">
                            <div class="greenbar"><i class="fa fa-caret-up"></i> {{frontDesk_front?.percentage_arrival}}
                                %</div>
                        </div>
                        <div class="caret_up hover_effect">
                            <div class="greenbar"><i class="fa fa-caret-up"></i>
                                {{frontDesk_front?.percentage_departure}} %</div>
                        </div>
                        <div class="caret_up hover_effect">
                            <div class="greenbar"><i class="fa fa-caret-up"></i>
                                {{frontDesk_front?.percentage_reservation}} %</div>
                        </div>
                        <div class="caret_up hover_effect">
                            <div class="greenbar"><i class="fa fa-caret-down" style="margin-top: 1px;"></i>
                                {{frontDesk_front?.percentage_today}} %</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>