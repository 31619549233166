import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termand-condition',
  templateUrl: './termand-condition.component.html',
  styleUrls: ['./termand-condition.component.css']
})
export class TermandConditionComponent implements OnInit {
  darkMode: boolean = false
  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
 chngedrop(id) {
    let a = (<HTMLInputElement>document.getElementById(id))
    if (a.classList.contains('trans180')) {
      a.classList.remove('trans180')
    }
    else {
      a.classList.add('trans180')
    }
  }
}
