<!-- <p>cafe works!</p> -->
<div class="main-div">
  <!-- <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'"> -->
  <!-- <app-rms-breadcrum></app-rms-breadcrum> -->

  <div class="col-md-12 blueHedaer">
    <div class="row">
      <mat-tab-group #tabGroup [ngClass]="darkMode ? 'darkTab' : ''" disableRipple="true"
        (selectedTabChange)="fetchMenuItem($event)">
        <mat-tab class="category" label="All">
          <ng-container *ngFor="let productItem of productItems;index as i">
            <div class="col-md-3 row-1">
              <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                <div class="card-body">
                  <div class="price stock" *ngIf="productItem.stock_status">Out of Stock</div>
                  <div class="price" *ngIf="!productItem.stock_status">
                    {{currency_format}} {{ productItem.selling_price | number:'1.2-3' }}
                  </div>
                  <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+productItem.image" alt="category"
                    class="category_img">
                  <div class="card-text">
                    <h4 class="card-title">{{ productItem.item.item_name }}</h4>
                  </div>
                </div>
                <div class="card-footer">
                  <button class="btn btn-danger" [disabled]="productItem.stock_status"
                    (click)="orderNow(i,productItems)">Select</button>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <div class="center">
            <div class="pagination">
              <a href="#">&laquo;</a>
              <a href="#" class="active">1</a>
              <a href="#">2</a>
              <a href="#">3</a>
              <a href="#">4</a>
              <a href="#">5</a>
              <a href="#">6</a>
              <a href="#">&raquo;</a>
            </div>
          </div> -->
        </mat-tab>
        <mat-tab class="category" *ngFor="let category of categories" [label]="category.category_name">
          <ng-container *ngFor="let productFilterItem of filteredCategoryItems;index as i">
            <div class="col-md-3 row-1">
              <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                <div class="card-body">
                  <div class="price stock" *ngIf="productFilterItem.stock_status">Out of Stock</div>
                  <div class="price" *ngIf="!productFilterItem.stock_status">
                    {{currency_format}} {{ productFilterItem.selling_price | number:'1.2-3' }}
                  </div>
                  <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+productFilterItem.image" alt="category"
                    class="category_img">
                  <div class="card-text">
                    <h4 class="card-title">{{productFilterItem.item.item_name}}</h4>
                  </div>
                </div>
                <div class="card-footer">
                  <button class="btn btn-danger" [disabled]="productFilterItem.stock_status"
                    (click)="orderNow(i,filteredCategoryItems)">Select</button>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-tab>
        <mat-tab class="category" label="Deal">
          <ng-container *ngFor="let dealItem of dealCategoryItems;index as i">
            <div class="col-md-3 row-1">
              <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                <div class="card-body">
                  <div class="price stock" *ngIf="dealItem.is_active">Out of Stock</div>
                  <div class="price" *ngIf="!dealItem.is_active">
                    {{currency_format}} {{ dealItem.deal_selling_price | number:'1.2-3' }}
                  </div>
                  <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+dealItem.image" alt="category"
                    class="category_img">
                  <div class="card-text">
                    <h4 class="card-title">{{dealItem.deal_name}}</h4>
                  </div>
                </div>
                <div class="card-footer">
                  <button class="btn btn-danger" [disabled]="dealItem.is_active"
                    (click)="orderNow(i,dealCategoryItems)">Select</button>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
      <div class="search" style="position: absolute; display: flex;left: 665px; z-index: 1;width: 160px;top: 5px;">
        <img src="../../../../assets/img/back-office/rms-icons/search.svg"
          style="position: absolute;top: 10px;left: 137px;" />
        <input type="search" class="form-control date-pick shadow-none" name="search-item" placeholder="Search"
          style="height: 30px;" [(ngModel)]="search_item" (ngModelChange)="searchMenuItem()" />
      </div>
    </div>
  </div>
</div>

<div class="col-md-12  col-sm-12 btnSave">
  <button class="btn saveBtn" [ngClass]="darkMode ? 'btnDark' : ''" (click)="newOrderPlaced()">New Order</button>
</div>