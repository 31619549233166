<!-- Payroll work start -->

<div class="main-div">
    <app-payroll-beardcrum></app-payroll-beardcrum>
    <form #payroleForm="ngForm" (ngSubmit)="payroleDataSave(payroleForm)">
        <div class="main" (scroll)="scrollViewContent($event)">
            <div class="col-md-12 blueHedaer">
                <div class="row">
                    <div class="employee">Employee Account</div>
                    <div class="radio" style="display:flex;">
                        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip"
                            title="Salaried">
                            <input type="radio" class="pull-left radio" name="employee_type" value="Salaried"
                                [(ngModel)]="employee_type">
                            <span class="radio-checkmark rd"></span>
                            <span class="title">Salaried</span>
                        </label>
                        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip"
                            title="Hourly">
                            <input type="radio" class="pull-left radio" name="employee_type" value="Hourly"
                                [(ngModel)]="employee_type">
                            <span class="radio-checkmark rd"></span>
                            <span class="title">Hourly</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-1">
                            <ng-select placeholder="Shop" name="shop_name" [(ngModel)]="shopName">
                                <ng-option *ngFor="let shop of shop_names" [value]="shop.id">{{shop.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="modal-bar-2 border-2">
                            <ng-select placeholder="Department" name="department" [(ngModel)]="department"
                                (change)="customDepartmant(department)" *ngIf="!customDep_name">
                                <ng-option *ngFor="let departments of department_names"
                                    [value]="departments.id">{{departments.name}}</ng-option>
                            </ng-select>
                            <ng-container *ngIf="customDep_name">
                                <div class="d-flex justify-content-center align-items-center">
                                    <input type="text" class="form-control" placeholder="Custom Department"
                                        [(ngModel)]="custom_department" [ngModelOptions]="{standalone: true}">
                                    <div class="iconsCR">
                                        <i class="fa fa-check" (click)="addCustomDepartment(custom_department)"></i> <i
                                            class="fa fa-times" (click)="clearSelection()"></i>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="modal-bar-2 border-3">
                            <ng-select placeholder="Role" name="role" [(ngModel)]="role">
                                <ng-option *ngFor="let role of role_names" [value]="role.id">{{role.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="modal-bar-2 border-4">
                            <ng-select placeholder="Employee Name" name="emp-name" [(ngModel)]="emp_name">
                                <ng-option *ngFor="let employee of employee_names"
                                    [value]="employee.name">{{employee.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-5">
                            <input class="form-control addShopName" placeholder="Employee Id" type="text" name="empId"
                                [(ngModel)]="empId">
                        </div>
                        <div class="modal-bar-2 border-6">
                            <input class="form-control addShopName" placeholder="Starting Date" type="text" name="date"
                                [(ngModel)]="date">
                        </div>
                        <div class="modal-bar-2 border-7">
                            <ng-select placeholder="Pay Period" name="pay_period_amount"
                                [(ngModel)]="pay_period_amount">
                                <ng-option *ngFor="let pay of pay_period" [value]="pay.name">{{pay.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="modal-bar-2 border-8">
                            <input class="form-control addShopName" placeholder="Pay" type="number" name="amount"
                                (blur)="formatExpenseAmount($event)" (keyup.enter)="formatExpenseAmount($event)"
                                (focus)="formatExpenseAmountFocus($event)" [(ngModel)]="amount" required>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer" (click)="scrollView('scroll1')">
                <div class="row">
                    <div class="employee">Govt Deductions</div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <ng-container *ngFor="let deduction of govtDeductions;index as i">
                    <div class="row">
                        <div class="col-md-12 inputCat">
                            <div class="modal-bar-2" [ngStyle]="{'border-color' : randomColor[i%randomColor.length]}">
                                <input class="form-control addShopName" placeholder="Federal Tax" type="text"
                                    [(ngModel)]="deduction.name" name="deduction_name{{i}}">
                            </div>
                            <div class="modal-bar-2" [ngStyle]="{'border-color': randomColor[i%randomColor.length]}">
                                <input class="form-control addShopName" placeholder="Percentage" type="text"
                                    name="deduction_percentage{{i}}" [(ngModel)]="deduction.percentage"
                                    (blur)="formatPercent($event)" (keyup.enter)="formatPercent($event)"
                                    (focus)="formatPercentFocus($event)"
                                    (input)="addAmount($event); deduction.amount = newAmount" required>
                            </div>
                            <div class="modal-bar-2" [ngStyle]="{'border-color': randomColor[i%randomColor.length]}">
                                <input class="form-control addShopName" placeholder="Amount" type="text"
                                    name="deduction_amount{{i}}" [value]="deduction.amount | number: '1.2-3'"
                                    [(ngModel)]="deduction.amount" readonly>
                            </div>
                            <div class="btnCol">
                                <button class="addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                                    (click)="removeGovtDeductions(i,'govt')"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-10">
                            <input class="form-control addShopName" placeholder="State Tax" type="text"
                                [(ngModel)]="newName" name="deduction_name">
                        </div>
                        <div class="modal-bar-2 border-10">
                            <input class="form-control addShopName" placeholder="Percentage" type="text"
                                [(ngModel)]="newPer" name="deduction_percentage" (blur)="formatPercent($event)"
                                (keyup.enter)="formatPercent($event)" (focus)="formatPercentFocus($event)"
                                (input)="addAmount($event)" required>
                        </div>
                        <div class="modal-bar-2 border-10">
                            <input class="form-control addShopName" placeholder="Amount" type="text"
                                name="deduction_amount" [value]="newAmount | number:'1.2-3'" readonly>
                        </div>
                        <div class="btnCol">
                            <button class="addBtn" (click)="addDeductions('govt_deductions')"
                                [disabled]="!(newName && newPer)"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer" (click)="scrollView('scroll2')">
                <div class="row">
                    <div class="employee">Voluntry Deductions <i class="fa fa-info-circle" aria-hidden="true"></i></div>
                    <div class="radio" style="display:flex;">
                        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip"
                            title="100% Employer Paid">
                            <input type="radio" class="pull-left radio" name="voluntry_deduction_type"
                                value="Employer Paid" [(ngModel)]="voluntry_deduction_type" checked>
                            <span class="radio-checkmark rd"></span>
                            <span class="title">100% Employer Paid</span>
                        </label>
                        <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip"
                            title="Shared">
                            <input type="radio" class="pull-left radio" name="voluntry_deduction_type" value="Shared"
                                [(ngModel)]="voluntry_deduction_type">
                            <span class="radio-checkmark rd"></span>
                            <span class="title">Shared</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <ng-container *ngFor="let voldDeduction of voluntryDeductions;index as i">
                    <div class="row">
                        <div class="col-md-12 inputCat">
                            <div class="modal-bar-2"
                                [ngStyle]="{'border-color' : randomColor[i%randomColor.length + 3]}">
                                <input class="form-control addShopName" placeholder="Voluntry deduction" type="text"
                                    [(ngModel)]="voldDeduction.name" name="volDeduction_name{{i}}">
                            </div>
                            <div class="modal-bar-2"
                                [ngStyle]="{'border-color': randomColor[i%randomColor.length + 3]}">
                                <input class="form-control addShopName" placeholder="% Coverage" type="text"
                                    name="volDeduction_percentage{{i}}" [(ngModel)]="voldDeduction.percentage"
                                    (blur)="formatPercent($event)" (keyup.enter)="formatPercent($event)"
                                    (focus)="formatPercentFocus($event)"
                                    (input)="addVolAmount($event); voldDeduction.amount = newVolAmount" required>
                            </div>
                            <div class="modal-bar-2"
                                [ngStyle]="{'border-color': randomColor[i%randomColor.length + 3]}">
                                <input class="form-control addShopName" placeholder="Amount" type="text"
                                    name="volDeduction_amount{{i}}" [value]="voldDeduction.amount | number: '1.2-3'"
                                    [(ngModel)]="voldDeduction.amount" readonly>
                            </div>
                            <div class="btnCol">
                                <button class="addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                                    (click)="removeGovtDeductions(i,'vol')"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-12">
                            <input class="form-control addShopName" placeholder="Voluntry deduction" type="text"
                                [(ngModel)]="newVolName" name="volDeduction_name">
                        </div>
                        <div class="modal-bar-2 border-12">
                            <input class="form-control addShopName" placeholder="% Coverage" type="text"
                                name="volDeduction_percentage" [(ngModel)]="newVolPer" (blur)="formatPercent($event)"
                                (keyup.enter)="formatPercent($event)" (focus)="formatPercentFocus($event)"
                                (input)="addVolAmount($event)" required>
                        </div>
                        <div class="modal-bar-2 border-12">
                            <input class="form-control addShopName" placeholder="Amount" type="text"
                                name="volDeduction_amount" [value]="newVolAmount | number:'1.2-3'" readonly>
                        </div>
                        <div class="btnCol">
                            <button class="addBtn" (click)="addDeductions('voluntry_deductions')"
                                [disabled]="!(newVolName && newVolPer)"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer" (click)="scrollView('scroll3')">
                <div class="row">
                    <div class="employee">Tuition & Training</div>
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <ng-container *ngFor="let tuition of tuitionTraining;index as i">
                    <div class="row">
                        <div class="col-md-12 inputCat">
                            <div class="modal-bar-2" [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Tuition" type="text"
                                    [(ngModel)]="tuition.name" name="tuition_name{{i}}">
                            </div>
                            <div class="modal-bar-2" [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Cost" type="text"
                                    name="tuition_amount{{i}}" [(ngModel)]="tuition.amount"
                                    (blur)="formatExpenseAmount($event)" (keyup.enter)="formatExpenseAmount($event)"
                                    (focus)="formatExpenseAmountFocus($event)" required>
                            </div>
                            <div class="btnCol">
                                <button class="addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                                    (click)="removeGovtDeductions(i,'tuition')"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-11">
                            <input class="form-control addShopName" placeholder="Training" type="text"
                                [(ngModel)]="newTuitionName" name="tuition_name">
                        </div>
                        <div class="modal-bar-2 border-11">
                            <input class="form-control addShopName" placeholder="Cost" type="text"
                                [(ngModel)]="newTuitionCost" name="tuition_amount" (blur)="formatExpenseAmount($event)"
                                (keyup.enter)="formatExpenseAmount($event)" (focus)="formatExpenseAmountFocus($event)">
                        </div>
                        <div class="btnCol">
                            <button class="addBtn" (click)="addDeductions('tuition_deductions')"
                                [disabled]="!(newTuitionName && newTuitionCost)"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer">
                <div class="row">
                    <div class="employee">Paid Time Off</div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <ng-container *ngFor="let paidtimeoff of paidTimeOff;index as i">
                    <div class="row">
                        <div class="col-md-12 inputCat">
                            <div class="modal-bar-2 border-12 col-md-6"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Paid Time Off" type="text"
                                    [(ngModel)]="paidtimeoff.name" name="paidtimeoffname{{i}}">
                            </div>
                            <div class="modal-bar-2 border-12"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Existing Award" type="text"
                                    [(ngModel)]="paidtimeoff.existing_award" name="existing_award{{i}}">
                            </div>
                            <div class="modal-bar-2 border-12"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Existing Date" type="text"
                                    [(ngModel)]="paidtimeoff.existing_date" name="existing_date{{i}}">
                            </div>
                            <div class="btnCol">
                                <button class="addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                                    (click)="removeGovtDeductions(i,'paid')"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-13 col-md-6">
                            <input class="form-control addShopName" placeholder="Paid Time Off" type="text"
                                [(ngModel)]="newPaidName" name="paidtimeoffname">
                        </div>
                        <div class="modal-bar-2 border-13">
                            <input class="form-control addShopName" placeholder="Existing Award" type="text"
                                [(ngModel)]="newExitAward" name="existing_award">
                        </div>
                        <div class="modal-bar-2 border-13">
                            <input class="form-control addShopName" placeholder="Existing Date" type="text"
                                [(ngModel)]="newExitDate" name="existing_date">
                        </div>
                        <div class="btnCol">
                            <button class="addBtn" (click)="addDeductions('paid_deductions')"
                                [disabled]="!(newPaidName && newExitAward && newExitDate)"><i
                                    class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer">
                <div class="row">
                    <div class="employee">Unpaid Time Off</div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <ng-container *ngFor="let unPaidtimeoff of unpaidTimeOff;index as i">
                    <div class="row">
                        <div class="col-md-12 inputCat">
                            <div class="modal-bar-2 border-12 col-md-6"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Date" type="text"
                                    [(ngModel)]="unPaidtimeoff.date" name="unPaidtimeoffdate{{i}}">
                            </div>
                            <div class="modal-bar-2 border-12"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Reason" type="text"
                                    [(ngModel)]="unPaidtimeoff.reason" name="reason{{i}}">
                            </div>
                            <div class="modal-bar-2 border-12"
                                [ngStyle]="{'border-color' : randomColor2[i%randomColor2.length]}">
                                <input class="form-control addShopName" placeholder="Day" type="number"
                                    [(ngModel)]="unPaidtimeoff.days" name="days{{i}}">
                            </div>
                            <div class="btnCol">
                                <button class="addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                                    (click)="removeGovtDeductions(i,'unpaid')"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-21">
                            <input class="form-control addShopName" placeholder="Date" type="text"
                                [(ngModel)]="newUnPaidDate" name="unPaidtimeoffdate">
                        </div>
                        <div class="modal-bar-2 border-21">
                            <input class="form-control addShopName" placeholder="Reason" type="text"
                                [(ngModel)]="newUnPaidReason" name="reason">
                        </div>
                        <div class="modal-bar-2 border-21">
                            <input class="form-control addShopName" placeholder="Day" type="number"
                                [(ngModel)]="newUnPaidDay" name="days">
                        </div>
                        <div class="btnCol">
                            <button class="addBtn" (click)="addDeductions('unpaid_deductions')"
                                [disabled]="!(newUnPaidDate && newUnPaidReason && newUnPaidDay)"><i
                                    class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 blueHedaer">
                <div class="row">
                    <div class="employee">Pay with holding</div>
                </div>
            </div>
            <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-23">
                            <!-- <input class="form-control addShopName" placeholder="Pay" type="text"> -->
                            <span class="form-control pay">Pay</span>
                        </div>
                        <div class="modal-bar-2 border-23">
                            <span class="form-control pay">{{currency_format}} {{amount ?
                                (amount | number:'1.2-3') :
                                '0.00'}}</span>
                            <!-- <input class="form-control addShopName" placeholder="Amount" type="number" (blur)='formatExpenseAmount($event)' (keyup.enter)='formatExpenseAmount($event)' (focus)='formatExpenseAmountFocus($event)' required> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-24">
                            <span class="form-control pay">Total Deductions</span>
                            <!-- <input class="form-control addShopName" placeholder="Total Deductions" type="text"> -->
                        </div>
                        <div class="modal-bar-2 border-24">
                            <span class="form-control pay">
                                {{currency_format}} {{sumTotalDeductions() | number:'1.2-3'}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 inputCat">
                        <div class="modal-bar-2 border-25">
                            <span class="form-control pay">Pay After Deductions</span>
                        </div>
                        <div class="modal-bar-2 border-25">
                            <span class="form-control pay">
                                {{currency_format}} {{sumTotalAfterDeductions() | number:'1.2-3'}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer" [ngClass]="darkMode ? 'modal-footer-dark' : ''">
            <button class="btn btn-danger">Save</button>
        </div>
    </form>
</div>