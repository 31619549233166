import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PnlService } from 'src/app/services/pnl.service';
import { Chart, ChartConfiguration, ChartData, registerables } from 'chart.js';
import * as echarts from 'echarts';
import { OpexNestedData } from 'src/app/common/pnl/opex-nested-chart/opexNestedModel';
type EChartsOption = echarts.EChartsOption;


@Component({
  selector: 'Opex-var-N-fix',
  templateUrl: './Opex-var-N-fix.component.html',
  styleUrls: ['./Opex-var-N-fix.component.css']
})
export class OpexVarNFixComponent implements OnInit, OnChanges {

  constructor(private pnlService: PnlService) {
    JSON.parse(localStorage.getItem('date'))
  }

  opexChartData: OpexNestedData;
  var_cost: boolean = false;
  fix_cost: boolean = false;
  @Input('choosenDate') choosen_date;
  opexBreakdownColors = [
    "#FF8800",
    "#00DCFF",
    "#FF00DD",
    "#FDF001",
    "#0019ff",
    "#A200FF",
    "#66B436",
    '#5D00FF',
    '#E70E02',
    '#00afb9',
    '#ffc300',
    '#89FC00',
  ]


  private opexExpenseCategoryChart: Chart;
  private opexBreakdownChart: Chart;
  private opexBreakdownChartCanvas;

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.updateCharts();
    this.getPnlData();
  }


  getPnlData() {
    this.pnlService.getOpexNestedChart(this.choosen_date.startDate, this.choosen_date.endDate).subscribe((res) => {
      this.opexChartData = res.data;
      console.log(this.opexChartData, 'opex chart data');
      this.updateCharts();
    })
  }

  updateCharts() {
    this.pnlService.getOpexNestedChart(this.choosen_date.startDate, this.choosen_date.endDate).subscribe(
      data => {
        this.opexChartData = data.data
        if (this.opexBreakdownChart) {
          this.opexBreakdownChart.destroy();
        }
        if (this.opexExpenseCategoryChart) {
          this.opexExpenseCategoryChart.destroy();
        }
        // this.updateOpexBreakDownChart()
        // this.updateExpensecategoryChart()
        this.opexExpenseChart()
        this.opexBreakDownChart()
      })
  }

  // empty donut (working on saud 👇)
  private emptyDount() {
    return {
      id: 'emptyDoughnut',
      afterDraw(chart, args, options) {
        const { datasets } = chart.data;
        const { color, width, radiusDecrease } = options;
        let hasData = false;

        for (let i = 0; i < datasets.length; i += 1) {
          const dataset = datasets[i];
          hasData = dataset.data.length > 0;
        }

        if (!hasData) {
          const { chartArea: { left, top, right, bottom }, ctx } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || '#e7e7e7';
          ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
          ctx.stroke();
        }
      }
    };
  }

  updateExpensecategoryChart() {
    const plugin = this.emptyDount();
    let color = ['#FF595E', '#6A4C93 ', '#75934C'];
    let opexChartData = this.opexChartData;
    let labels = [];
    const htmlLegendPlugin = htmlLegend();

    opexChartData.expense_data.expense_type.map(x => x.expense_main_type_name).forEach(element => {
      labels.push(element.split(' ')[0]);
    });

    let total_amount = [];
    let total = opexChartData.expense_data.expense_type.map(x => x.total != 0 ? total_amount.push(x.total) : total_amount.push());

    const data: ChartData = {
      labels: labels,
      datasets: [
        {
          label: 'Expense Category',
          data: total_amount,
          backgroundColor: color,
          borderWidth: 0
        }
      ]
    };

    const config: any = {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
        // aspectRatio: 2,
        plugins: {

          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,

            external: function (context) {
              // Tooltip Element
              let tooltipEl: any = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = "";

                titleLines.forEach(function (title) {
                  innerHtml += "<div>" + title + "</div>";
                });
                innerHtml += "<div>";

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  let n = body[0];
                  let re = n.split(':')[0];
                  let amt = n.split(':')[1];
                  innerHtml += `<div style='background: #ffffffc7 !important;border-radius:10px;border:1px solid ${colors.backgroundColor};padding:5px'>
                  <label class="list-data-name"  style='white-space: nowrap; width: 100px;  overflow: hidden;text-overflow: ellipsis; font-size:14px;border-bottom:1px solid ${colors.backgroundColor}'>${re}</label>
                  <div style='font-weight: bold; font-size: 14px;text-align:center;padding-left:5px;'> ${(localStorage.getItem('currency') + amt)}</div>
                  </div>`;
                });
                innerHtml += "</div>";
                var tableRoot = tooltipEl.querySelector('.cus-box');
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 110,
            radiusDecrease: 55
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'opex-legend',
          },
          legend: {
            display: false,
          }
        },
      },
      plugins: [plugin, htmlLegendPlugin]
    };

    this.opexExpenseCategoryChart = new Chart('expense-category-canvas', config);

  }

  updateOpexBreakDownChart() {
    const plugin = this.emptyDount();
    let opexData = this.opexChartData;
    let color = this.opexBreakdownColors;
    let labels = [];

    const htmlLegendPlugin = htmlLegend();

    opexData.expense_data.expense_subtype.map(x => x.name).forEach((label, index) => {
      let name = label.split(' ')[0]
      labels.push(name)
    });
    let total_amount = []
    let amount = opexData.expense_data.expense_subtype.map(x => x.total_amount != 0 ? total_amount.push(x.total_amount) : total_amount.push());


    const data: ChartData = {
      labels: labels.filter(x => x !== 'Other '),

      datasets: [
        {
          label: 'Opex Breakdown',
          data: total_amount,
          backgroundColor: color,
          borderWidth: 0
        }
      ]
    };

    let currency = localStorage.getItem('currency');

    const config: any = {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
        aspectRatio: 2,

        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                console.log(tooltipItem, 'labels');

                return `${tooltipItem.label} : ${currency} ${tooltipItem.formattedValue}.00`;
              },
              title: function (tooltipItem, data) {
                return;
              }
            },
          },
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 120,
            radiusDecrease: 60
          },
          htmlLegend: {
            // * ID of the container to put the legend in
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      },
      plugins: [plugin, htmlLegendPlugin],
    };
    this.opexBreakdownChart = new Chart('opex-breakdown-canvas', config);
  }

  var_fix_cost(id: string) {
    let ids = <HTMLInputElement>document.getElementById(id);

    if (ids.checked) {
      if (id == 'var') {
        this.fix_cost = false;
        this.var_cost = true;
        let data = <Chart>this.opexExpenseCategoryChart;
        data.config.data.datasets[0].data = [];
        data.config.data.datasets[0].data = this.opexChartData.expense_data.expense_type.map(x => x.variable_cost);
        data.update();
        this.updateExpensecategoryChart();
      } else {
        this.var_cost = false;
        this.fix_cost = true;
        let data = <Chart>this.opexExpenseCategoryChart;
        data.config.data.datasets[0].data = [];
        data.config.data.datasets[0].data = this.opexChartData.expense_data.expense_type.map(x => x.fixed_cost);
        data.update();
        this.updateExpensecategoryChart();
      }
    }
    else {
      let data = <Chart>this.opexExpenseCategoryChart;
      data.config.data.datasets[0].data = [];
      data.config.data.datasets[0].data = this.opexChartData.expense_data.expense_type.map(x => x.total);
      data.update();
      this.updateExpensecategoryChart();
    }

  }

  opexExpenseChart() {

    let expense = []
    let opexData = this.opexChartData;
    opexData.expense_data.expense_subtype.map(x => x.total_amount == 0 ? expense.push() : expense.push({ value: x.total_amount, name: x.name.split(' ')[0] }))

    var dom = document.getElementById('opex-breakdown-canvas');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });
    var app = {};
    let changecolor = [
      "#FF8800",
      "#00DCFF",
      "#FF00DD",
      "#FDF001",
      "#0019ff",
      "#A200FF",
      "#66B436",
      '#5D00FF',
      '#E70E02',
      '#00afb9',
      '#ffc300',
      '#89FC00',
    ]
    var option;

    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        height: "100%",
        orient: "vertical",
        right: 10,
        top: "center"
      },

      series: [
        {
          name: '',
          type: 'pie',
          radius: '60%',
          label: {
            show: false
          },
          labelLine: {
            length: 0
          },
          data: expense,
          center: ["35%", "50%"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ],
      color: changecolor
    };

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => myChart.resize());
  }

  opexBreakDownChart() {

    let expense = []
    let opexData = this.opexChartData;
    opexData.expense_data.expense_type.map(x => x.total == 0 ? expense.push() : expense.push({ value: x.total, name: x.expense_main_type_name.split(' ')[0] }))

    var dom = document.getElementById('opex-expense-canvas');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });
    var app = {};
    let changecolor = ['#FF595E', '#6A4C93 ', '#75934C']
    var option;

    this.opexBreakdownChartCanvas = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        height: "100%",
        orient: "vertical",
        right: 0,
        top: "center"
      },

      series: [
        {
          name: '',
          type: 'pie',
          radius: '60%',
          label: {
            show: false
          },
          labelLine: {
            length: 0
          },
          data: expense,
          center: ["35%", "50%"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ],
      color: changecolor
    };
    console.log(this.opexBreakdownChartCanvas, 'canvas breeak down');

    if (this.opexBreakdownChartCanvas && typeof this.opexBreakdownChartCanvas === 'object') {
      myChart.setOption(this.opexBreakdownChartCanvas);
    }

    window.addEventListener("resize", () => myChart.resize());
  }
}

// HTml Legend plugin chart js
function htmlLegend() {
  const getOrCreateLegendList = (chart: any, id: string) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      listContainer.style.width = 'max-width';

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(item => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.alignItems = 'center';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';
        li.style.marginBottom = '5px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '12px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '12px';
        boxSpan.style.borderRadius = '3px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.fontSize = '14px';
        textContainer.style.fontFamily = 'Roboto';
        textContainer.style.fontWeight = '500';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };
  return htmlLegendPlugin;
}
