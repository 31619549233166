import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ManageRatesPackagesService } from './manage-rates-packages.service';
import { HelperService } from "../../services/helper.service";
import * as moment from 'moment';
// import { ConsoleReporter } from 'jasmine';
declare var $: any;
import { GlobaldataService } from '../../common/globaldata.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { RateChannelModelResponse } from 'src/app/common/ratesandpackages/RateChannelModel';
import { PackagesData, SeasonRateIndexResponse, SeasonsData } from 'src/app/common/ratesandpackages/SeasonRateIndexModel';


@Component({
  selector: 'app-manage-rates-packages',
  templateUrl: './manage-rates-packages.component.html',
  styleUrls: ['./manage-rates-packages.component.css']
})
export class ManageRatesPackagesComponent implements OnInit {

  // drop: boolean = true;
  searchView: boolean = false;
  darkMode: boolean = false;

  //New Work
  roomtypes;
  channels;
  current_currency = localStorage.getItem('currency');
  current_property = localStorage.getItem('property');
  filterRoom;
  filterChannel;
  filterLinked;
  base_url2
  breakfast_array = [];
  link_drop = [
    { name: "Linked Packages", val: 'pushed' },
    { name: "Unlinked Packages", val: 'pending' },
    { name: "Reset", val: 'reset' }
  ];

  langCode: any;
  ratePlan: SeasonsData[] = [];
  ratePackage: PackagesData[] = [];
  ratePackage2: PackagesData[] = [];;
  desT: boolean = false;
  currency_format = localStorage.getItem('currency');
  selectedTab = 'OTAs';
  promotion_skeleton: boolean = true;

  createView = [
    { name: "+ Rate Plan", val: 'season' },
    // {name: "+ Promo Code", val: 'promo'},s
    { name: "+ Promotions", val: 'package' }
  ];
  bHeight: number;
  modal_title = null;
  editPlans = false;
  createViewSelect: any;
  packageChannels = [];
  packageId: any;
  param;
  filteration: string;

  constructor(
    public api: ApiService,
    private error_handling: ErrorHandlingService,
    public router: Router,
    public helper: HelperService,
    public service: ManageRatesPackagesService,
    private active_route: ActivatedRoute,
    public confirm: ConfirmationAlertService,
    public ManageRatePackageService: ManageRatesPackagesService,

  ) {
    this.base_url2 = this.api.base_url2;
  }
  colors = ['pkg_title', 'green_text', 'purple_text', 'orange_text', 'last_text', 'yellow_text', 'red_dark_text'];
  colors2 = ['green_text', 'purple_text', 'orange_text', 'last_text', 'yellow_text', 'red_dark_text'];
  bdr = ['bdr1', 'bdr2', 'bdr3', 'bdr4', 'bdr5', 'bdr6', 'bdr7'];
  aminites_colors = ['#000000', '#00FF00', '#0000FF', '#FF0000', '#01FFFE', '#FFA6FE', '#FFDB66', '#006401', '#010067', '#95003A', '#007DB5', '#FF00F6', '#FFEEE8', '#774D00', '#90FB92', '#0076FF', '#D5FF00', '#FF937E', '#6A826C', '#FF029D', '#FE8900', '#7A4782', '#7E2DD2', '#85A900', '#FF0056', '#A42400', '#00AE7E', '#683D3B', '#BDC6FF', '#263400', '#BDD393', '#00B917', '#9E008E', '#001544', '#C28C9F', '#FF74A3', '#01D0FF', '#004754', '#E56FFE', '#788231', '#0E4CA1', '#91D0CB', '#BE9970', '#968AE8', '#BB8800', '#43002C', '#DEFF74', '#00FFC6', '#FFE502', '#620E00', '#008F9C', '#98FF52', '#7544B1', '#B500FF', '#00FF78', '#FF6E41', '#005F39', '#6B6882', '#5FAD4E', '#A75740', '#A5FFD2', '#FFB167', '#009BFF', '#E85EBE']


  ngOnInit() {
    this.get_channels();
    this.get_rooms();
    this.getRatePlan();
    this.checkDarkMode();

    this.service.update_new_data.subscribe((res: boolean) => {
      if (res) {
        this.getRatePlan();
      }
    })

    if (this.active_route.snapshot.queryParams['filter_channel']) {
    } else {
    }
    this.service.package_creating = undefined;
    $(() => {
      $("#settings1").on("hidden.bs.modal", () => {
        this.service.is_preview = false;
        this.service.is_edit = false;
        this.service.package_creating = undefined;
        this.router.navigateByUrl('/app/rates_packages');
      });
    });

    // drag function code -------------------------------
    $(function () {
      setTimeout(function () {
        $("#drag-list").dragsort({
          dragSelector: ".head-main",
          placeHolderTemplate: `\
                  <div class='placeHolder' style='width:100% !important; height:140px !important;'>
                    <div style='background-color: transparent !important;height: 120px;width:1660px; margin-bottom:5px;'></div>
                  </div>`,
          cursor: "grab"
        });
        $('#settings').css({ 'width': '100%' });
        $('#settings').draggable();
        $('#settings').draggable('disable');
        $('#settings1').draggable();
        $('#settings1').draggable("disable");
        $('#settings2').draggable();
        $('#settings2').draggable("disable");
      }, 1000)
    });
    // drag function code -------------------------------

    $(function () {
      $("#drag-list").sortable({
        disabled: true
      });
      // alert('asd');

      $("#drag-list").mouseup(function () {
        $(".head-main").css({ "cursor": "grab" })
      });
      $("#drag-list").mousedown(function () {
        $(".head-main").css({ "cursor": "grabbing" })
      });
    });

    $(function () {
      setTimeout(function () {
        $(".dragnDrop").dragsort({
          dragSelector: "div",
          dragBetween: true,
          dragEnd: () => {
            var data = $(".dragnDrop .dropzone").map(function () {
              return $(this).children().html();
            }).get();
            var d = [];
            var dropzone = document.getElementsByClassName('dropzone');
            for (let i = 0; i < dropzone.length; i++) {
              d.push({ "id": dropzone[i].getAttribute('title'), "key": dropzone[i].getAttribute('id') });

            }
            var settings = {
              "url": "https://uat.cgslhcm.com/pmsbackend_8/api/season_package/sorting",
              "method": "POST",
              "timeout": 0,
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('auth_token'),
              },
              "data": JSON.stringify({
                "data": d
              }),
            };

            $.ajax(settings).done(function (response) {
              console.log(response, "response");
            });


            // $("input[name=list1SortOrder]").val(data.join("|"));
          },
          placeHolderTemplate: "<div class='placeHolder' style='height:auto !important; width: 100%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='margin:3px;'>&nbsp;</div>" +
            "</div>",
          cursor: "default"
        });
      }, 1000)
    });
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  //Get APIS on Init
  get_channels() {
    this.ManageRatePackageService.getChannels().subscribe((res: RateChannelModelResponse) => {
      this.channels = res.data.filter(function (e) { return e.connected == true });
    }, err => this.error_handling.handle_error(err.status, err.message))

  }

  getLangCode() {
    var value = localStorage.getItem("code");
    this.langCode = value
  }

  get_rooms() {
    this.api.post('api/roomtype/index?per_page=50', { lang: this.langCode }, true).subscribe((res: any) => {
      this.roomtypes = res.body.data;
      // console.log(this.roomtypes);
      this.roomtypes.unshift({ id: 'A789', name: 'All' });
    }, err => this.error_handling.handle_error(err.status));
  }
  //Get APIS on Init END

  // Top bar Filters
  filterlinked(e) {
    if (e != 'reset') {
      this.api.post('api/season_package/rate_season_index', { property_id: localStorage.getItem('current_property'), push_status: e }, true).subscribe((res: any) => {
        console.log(res, "rate_season_index");

        this.ratePlan = res.body.data.seasons.data;
      }, err => this.error_handling.handle_error(err.status, err.message))
    } else {
      this.getRatePlan();
      this.filterLinked = null;
    }
  }

  filterByRoom(id) {
    if (typeof id == 'undefined' || id == null) {
      this.ratePackage = this.ratePackage2;
      return;
    }
    if (id == 'A789') {
      this.searchView = false;
      return
    }
    this.searchView = true;
    this.ratePackage = this.ratePackage2.filter((e) => e.room_type_id == id);
  }

  filterationByPrice(filteration: string) {

    if (filteration == 'all') {
      this.filterRoom = null;
    }

    let payload = {
      property_id: localStorage.getItem('current_property'),
      sort: filteration
    }
    this.ManageRatePackageService.rateSeasonIndex(payload).subscribe((res: SeasonRateIndexResponse) => {
      this.ratePlan = res.data.seasons.data;
      this.ratePackage = res.data.packages;
      this.ratePackage2 = res.data.packages;
    }, err => this.error_handling.handle_error(err.status, err.message))
  }
  // Top bar Filters END

  get logos_quantity() {
    let bar: any = localStorage.getItem("sidebar_collapse");
    if (bar == false) {
      return 7;
    } else if (window.innerWidth == 1600) {
      return 6;
    }
    else if (window.innerWidth == 1440) {
      return 4;
    }
    else if (window.innerWidth == 1366) {
      return 4;
    }
    else if (window.innerWidth == 1280) {
      return 4;
    }
    else {
      return 8;
    }
  }

  dropChnge(id) {
    let a = (<HTMLInputElement>document.getElementById(id));
    a.classList.contains('rotate180') ? a.classList.remove('rotate180') : a.classList.add('rotate180')
  }

  // Season Room Cards drag
  scrollRights(id) {
    document.getElementById("id" + id).scrollLeft += 100;
  }

  scrollLefts(id) {
    document.getElementById("id" + id).scrollLeft -= 100;
  }

  dragFunc(id) {
    const slider = <HTMLElement>document.querySelector('.cla' + id);
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e: any) => {

      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }
  // Season Room Cards drag END

  // Get Season and packages
  getRatePlan() {
    console.log("get rate plan");

    // this.promotion_skeleton = true;
    this.api.post('api/season_package/rate_season_index', { property_id: localStorage.getItem('current_property') }, true).subscribe((res: any) => {
      this.ratePlan = res.body.data.seasons.data;
      console.log(this.ratePlan, "this.ratePlan");
      console.log(res, "res");
      
      this.ratePlan.sort(function (a, b) {
        var keyA = a.layout_sequence,
          keyB = b.layout_sequence;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      this.ratePackage = res.body.data.packages;
      this.ratePackage2 = res.body.data.packages;
      this.promotion_skeleton = false;
    })
  }

  dateFunc(d) {
    let a = d.split('/');
    return moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]).format('DD MMM YY');
  }

  dateFunc2(d) {
    let a = d.split('/');
    return moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]);
  }

  checkDays(d) {
    let w = [
      { name: 'Monday', status: false },
      { name: 'Tuesday', status: false },
      { name: 'Wednesday', status: false },
      { name: 'Thursday', status: false },
      { name: 'Friday', status: false },
      { name: 'Saturday', status: false },
      { name: 'Sunday', status: false }
    ];
    w.map((val) => {
      d.map((val2) => {
        if (val2 == val.name) {
          val.status = true;
        }
      })
    })
    return w;
  }

  //To Preview Package use Edit Package Func
  edit_package(id, preview, page = 'setup_packages') {
    this.service.is_edit = true;
    this.service.package_creating = id;
    preview ? this.router.navigateByUrl('/app/rates_packages/preview_packages') : this.router.navigateByUrl('/app/rates_packages/' + page);
    this.getHeight22();
  }

  selectpercentage(p) {
    GlobaldataService.previewPercent = p;
  }

  roundPrice(pa, pb) {
    let a = Math.round(pa * 100 / pb)
    return 100 - a;
  }

  dodrag(id) {
    $("#" + id).draggable('enable');
  }

  dontdrag(id) {
    $("#" + id).draggable('disable');
  }

  downGrab(id, con) {
    let a = (<HTMLInputElement>document.getElementById(id))
    con == 'down' ? a.style.cursor = 'grabbing' : a.style.cursor = 'grab'
  }

  setClass(c, b) {
    return c + ' ' + b
  }

  showSelectedPackage(p) {
    let a = []
    a = p.filter((e) => {
      //  console.log(a);
      return e.package_type == this.selectedTab
    })
    return a;
  }

  sortPromotions(id, did) {
    let sortIcon = (<HTMLIFrameElement>document.getElementById(id)).classList;
    sortIcon.contains('fa-sort-amount-down') ? (sortIcon.remove('fa-sort-amount-down'), sortIcon.add('fa-sort-amount-up')) : (sortIcon.remove('fa-sort-amount-up'), sortIcon.add('fa-sort-amount-down'));
    this.ratePlan[did].packages.reverse();

  }

  roundPricee(a) {
    return a.toFixed(0);
  }



  getRoundPrice(pa, pb): string {

    let a: number = Math.round(pa * 100 / pb)

    if (a > 0 && a < 100) {
      let val = 100 - a;
      return `${val}`;
    }
    else {
      return ``;
    }
  }


  seeDescription() {
    this.desT = !this.desT
  }

  descripArray(data) {
    if (this.service.is_preview == true) {
      return data.split(' ').slice(0, 52).join().replace(/,/g, " ");
    }
    else {
      return data.split(' ').slice(0, 30).join().replace(/,/g, " ");
    }
  }

  descripArray2(data) {
    if (this.service.is_preview == true) {
      return data.split(' ').slice(52).join().replace(/,/g, " ");
    }
    else {
      return data.split(' ').slice(30).join().replace(/,/g, " ");
    }
  }
  //   letmecheck(a:any){
  // console.log(a.room.bedtype.name != undefined ? a.room.bedtype.name  :    'Not');
  //   }



  seasonPush(id) {
    this.api.get('/api/expedia_push/season_package/' + id, true).subscribe((res: any) => {
      this.getRatePlan();
      this.helper.alert_success('Season has been Updated successfully !');
    }, err => this.error_handling.handle_error(err.status));
  }
  value_added(b: any) {

    let a = JSON.parse("[" + b + "]"),
      c = [1, 2, 3, 4, 5, 6, 7, 8, 54, 55, 56, 57, 77],
      d = [],
      e = [];
    for (let f = 0; f < a.length; f++)c.forEach(b => { a[f] == b ? d.push(a[f]) : e.push(a[f]) });
    return 0 == d.length ? ("BreakFast Not Included") : ("BreakFast  Included")

  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ratePlan, event.previousIndex, event.currentIndex);
    for (var arr = [], i = 0; i < this.ratePlan.length; i++)arr.push({ id: this.ratePlan[i].id, index: i + 1 });
    this.api.post('api/season_package/sorting', { data: arr }, true).subscribe((res: any) => {
      this.getRatePlan();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }


  //Create Button in TOP Filter
  createSeason(createView) {
    if (createView == 'season') {
      this.modal_title = "Create";
      this.router.navigate([{ outlets: { seasonPanel: 'setup_season' } }], { relativeTo: this.active_route });
      this.editPlans = false;
      $('#settings2').modal('show');
      setTimeout(() => this.createViewSelect = null);
      this.getHeight22();
    } else if (createView == 'package') {
      this.router.navigate(['/app/rates_packages/setup_packages'], { relativeTo: this.active_route });
      $('#settings1').modal('show');
      setTimeout(() => this.createViewSelect = null);
    }
    else {
      $('#settings3').modal('show');
      setTimeout(() => this.createViewSelect = null);
    }
    this.getHeight()
  }

  getHeight() {
    setTimeout(() => {
      let a = document.getElementById("seasoneModal");
      this.bHeight = a.offsetHeight
      let di = document.getElementById("col10");
      if (this.bHeight > 700) {
        di.classList.add("widthBig");
        di.classList.remove("widthPad");
      }
      else if (this.bHeight < 700) {
        di.classList.remove("widthBig");
        di.classList.add("widthPad");
      }
    }, 1000)
  }

  onClose() {
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    //this.create_package_form.reset();
    // this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    this.router.navigate(['/app/rates_packages']);
    $('#settings2').modal('hide');
  }

  // DRAG Section
  dodrag1() {
    $('#settings1').draggable("enable")

  }

  stopDrag1() {
    $('#settings1').draggable("disable")
  }

  dodrag2() {
    $('#settings2').draggable("enable")
  }

  stopDrag2() {
    $('#settings2').draggable("disable")
  }

  getHeight22() {
    setTimeout(() => {
      let a = document.getElementById("packageModal");
      this.bHeight = a.offsetHeight
      let di = document.getElementById("col101");
      if (this.bHeight > 700) {
        di.classList.add("widthBig");
        di.classList.remove("widthPad");
      }
      else if (this.bHeight < 700) {
        di.classList.remove("widthBig");
        di.classList.add("widthPad");
      }
    }, 1000)
  }

  // ! filter channel by package
  filterChannelsByPackage(p) {
    //console.log("Name:", p.package_name)
    let x = []
    if (p.package_type == 'OTAs') {
      //console.log('All Otas', p.otas)
      x = p.otas.filter((e) => e.channel.type === "External")
      x = x.filter((e) => e.channel.type !== "Internal" && e.channel.name !== 'Loyalty Program' || e.channel.name !== "Walk-in")
    }
    else if (p.package_type == 'Channel') {
      x = p.otas.filter((e) => e.channel.type === "Internal" && e.channel.name !== 'Loyalty Program' || e.channel.name !== "Walk-in")
      x = x.filter((e) => e.channel.type !== "External")
    }
    else if (p.package_type = 'Loyalty') {
      x = p.otas.filter((e) => e.channel.name === 'Loyalty Program' || e.channel.name === "Walk-in")
      x = x.filter((e) => e.channel.type !== "Internal")
      x = x.filter((e) => e.channel.type !== "External")
    }
    //console.log('filtered x',x)
    return x;
  }

  // ~ edit Package
  editPlan(rp) {
    console.log(rp, "edit");
    
    GlobaldataService.editSeason = rp;
    this.editPlans = true;
    this.router.navigate([{ outlets: { seasonPanel: 'setup_season' } }], { relativeTo: this.active_route });
    $('#settings2').modal('show');

  }

  // & duplicate Package
  duplicate_package(id) {
    this.service.duplicate_package(id);
    setTimeout(() => {
      this.getRatePlan();
    }, 1000)
  }

  // * Delete Package
  delete(id, name, dat) {
    let that = this;
    this.confirm.confirm(
      "Delete Package (" + name + ")",
      'Are your sure you want to DELETE this Package?',
      ['This action cannot be undone', 'Deleting this will also delete / disconnect the mapped channel rates', 'Deleting this will not remove rooms / rates from your connected channels extranet.'],
      () => {
        that.getRatePlan();
        var res = that.service.delete(id);
        var db = document.getElementById('collapson0');
        db.setAttribute("class", "collapse in");
      },
      '',
      dat
    );

  }

  // ! duplicate rateplan
  duplicate_rateplan(rp) {
    var id = rp.id;
    this.api.post('api/season_package/clone', { season_id: id }, true).subscribe((res: any) => {
      this.getRatePlan();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  // ^ Delete Season
  deleteSeason(name, id, dat) {
    let that = this;
    this.confirm.confirm(
      "Delete Package (" + name + ")",
      'Are your sure you want to DELETE this Rate Plan?',
      ['This action cannot be undone', 'Deleting this will also delete / disconnect the mapped channel rates'],
      () => {
        that.deleteSeasonNow(id)
      },
      '',
      dat
    );
  }

  // * Delte season
  deleteSeasonNow(id) {
    this.api.get('api/season_package/delete/' + id, true).subscribe((res: any) => {
      this.getRatePlan();
      this.helper.alert_success('Rate Plan has been deleted successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  // Package Chennals Popup
  openPackageChannels(otas, id) {
    // if (type === '') {
    console.log("Opened")

    let a = {
      package_id: id,
      property_id: localStorage.getItem('current_property')
    };
    this.api.post('api/ota_package/get_package_otas', a, true).subscribe((res: any) => {
      this.packageChannels = res.body.data;
      console.log("Package Channels ----- ", this.packageChannels);
    }, err => this.error_handling.handle_error(err.status, err.message));
    this.packageId = id;
    $('#ChannelModal').modal('show');
  }

  selectOta($event, id) {
    if ($event.target.checked) {
      this.packageChannels.forEach(element => {
        if (element.id == id) {
          element.isBinded = true;
        }
      });
    } else {
      this.packageChannels.forEach(element => {
        if (element.id == id) {
          element.isBinded = false;
        }
      });
    }
  }

  selectAllOta($event) {
    if ($event.target.checked) {
      this.packageChannels.forEach(element => {
        if (element.type == 'External') {
          element.isBinded = true;
        }
      });
    } else {
      this.packageChannels.forEach(element => {
        if (element.type == 'External') {
          element.isBinded = false;
        }
      });
    }
  }

  selectAllChannels($event) {
    if ($event.target.checked) {
      this.packageChannels.forEach(element => {
        if (element.type == 'Internal') {
          element.isBinded = true;
        }
      });
    } else {
      this.packageChannels.forEach(element => {
        if (element.type == 'Internal') {
          element.isBinded = false;
        }
      });
    }
  }

  saveOtas() {
    let addCh = [];
    let rmCh = [];

    this.packageChannels.forEach(e => {
      if (e.isBinded == true) {
        addCh.push(e.id);
      } else {
        rmCh.push(e.id);
      }
    })
    let f = {
      package_id: this.packageId,
      channels: addCh,
      remove_channels: rmCh
    };
    this.api.post('api/ota_package/update_all', f, true).subscribe((res: any) => {
      this.getRatePlan();
    }, err => this.error_handling.handle_error(err.status, err.message));

  }

  calculteTaxAmount(price) {
    const property_data = JSON.parse(this.current_property);
    const total = (property_data.taxper / 100) * price;
    return total;
  }

  getOTAs(OTAs: PackagesData) {
    return OTAs.otas;
  }
}



