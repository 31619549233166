import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
declare var $: any;
import { EventsService } from '../../services/events.service';
import { CategoriesAndItems, CategoriesAndItemsResponse } from 'src/app/common/fnb/CategoriesAndItemsModel';
import { FnbService } from 'src/app/services/fnb.service';
import { Shops } from 'src/app/common/fnb/createShop';
import { RmsService } from 'src/app/services/rms.service';
import { MenuDealItemResponse, ShopDealItem } from 'src/app/common/fnb/MenuDealItem';
import { MenuProductItems, MenuProductResponse } from 'src/app/common/fnb/MenuProductModel';
import { PlaceOrderItem } from 'src/app/common/rms/PlaceOrderItem';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval, timer } from 'rxjs';
import { NotificatioResponse } from 'src/app/common/notifications/NotificationModal';
import { PnlService } from 'src/app/services/pnl.service';
import { switchMap } from 'rxjs/operators';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.css']
})


export class BackOfficeComponent implements OnInit, OnDestroy {
  tst = { condition: true };
  highGret: boolean = false;
  darkMode: boolean = false;
  pro_pic = null;
  upload_img = new FormData();
  base_url2;
  cardLayout;
  activeTab;
  skeleton: boolean = true;
  @Output() onModalClosed = new EventEmitter();
  activeShopId: number = 0;
  shopNames: Shops[] = [];
  dealCategoryItems: ShopDealItem[] = [];
  productCategoryItems: MenuProductItems;
  editMode: boolean = false;
  @ViewChild('confirmModal', { static: false }) confirmModal: any;
  isOrderListEmpty: boolean = true;
  notifications: string;

  constructor(
    public router: Router,
    public events: EventsService,
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private confirm: ConfirmationAlertService,
    private fnbService: FnbService,
    private rmsService: RmsService,
    private pnl: PnlService,
    private toaster: ToastrService,
    private helperService: HelperService,
  ) {
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    });
    this.base_url2 = this.api.base_url2;

    this.events.shareDataSubject.subscribe((res) => {
      // console.log(res,'share');
      // this.activeTab = res;
      var id = [1, 2, 3]
      if (document.getElementById('scroll1')) {
        this.activeTab = res;
      } else {
        this.activeTab = 'scroll2';
      }
    })

  }

  ngOnDestroy(): void {
    this.isOrderListEmpty = false;
  }

  ngOnInit() {

    this.checkDarkMode();

    $(document).ready(() => {
      $('.modal-btn').on('click', function () {
        // $('#settings').modal('show');
        $('#settings').modal({ backdrop: 'static', keyboard: true });
        $('#settings').draggable();
        $('#settings').draggable("disable")
      });
      $('#settings').on('hidden.bs.modal', () => this.router.navigateByUrl('/app/back_office'));

    });

    $(function () {
      setTimeout(function () {
        $("#drag_box").dragsort({
          dragSelector: ".jumbo-icon",
          dragBetween: false,
          dragEnd: () => {
            var data = $("#drag_box .drag_item").map(function () {
              return $(this).children().html();
            }).get();
            var drag_item = document.getElementsByClassName('drag_item');
            var d = [];
            for (let i = 0; i < 19; i++) {
              d.push({ "cardKey": drag_item[i].getAttribute('index'), cardName: drag_item[i].getAttribute('index-data') });
            }
            localStorage.setItem("BackofficeLayout", JSON.stringify(d));
            var settings = {
              "url": "https://uat.cgslhcm.com/pmsbackend_8/api/user/sorting",
              "method": "POST",
              "timeout": 0,
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('pms_auth_token'),
              },
              "data": JSON.stringify({
                "type": "BackOffice",
                "data": d
              }),
            };

            $.ajax(settings).done(function (response) {
              console.log(response);
            });
          },
          placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: transparent !important; height: 100%!important'>&nbsp;</div>" +
            "</div>",
          cursor: "move"
        });
      }, 2000)

      $("#tabpanActive").mouseup(function () {
        $(".grabCu").css({ "cursor": "grab" })
      });
      $("#tabpanActive").mousedown(function () {
        $(".grabCu").css({ "cursor": "grabbing" })
      });

    });

    this.cardLayout_drag();
    this.isActiveShopId();
    this.getShopNames();
    this.rmsService.orderListItem$.subscribe(res => {
      this.editMode = true;
    })

    this.rmsService.OnOrderListEmpty.subscribe(x => {
      this.isOrderListEmpty = x
    })

    // Fetch initial notification
    this.pnl.getNotifications().subscribe(response => {
      this.notifications = response.data.notification;
    });
  }


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  passVar() {
    let a = 10
    this.router.navigate(['/app/back_office/create_rooms'], { state: this.tst })
  }

  getHght() {
    let a = document.getElementById('tabpanActive')

    setTimeout(() => {
      if (a.offsetHeight > 700) {
        this.highGret = true

      }
      else {
        this.highGret = false

      }
    }, 500)

  }

  doDrag() {
    $('#settings').draggable("enable");
  }
  dontDrag() {
    $('#settings').draggable("disable");

  }

  updateHotelLogo(img) {
    let b = JSON.parse(localStorage.getItem('property'))
    let a = {
      name: b.name,
      phone: b.phone,
      address_one: b.address_one,
      city_id: b.city_id,
      country_id: b.country_id,
      currency_conversion: b.currency_conversion,
      currency_id: b.currency_id,
      min_rate: b.min_rate,
      postal_code: b.postal_code,
      state_id: b.state_id,
      timezone_id: b.timezone_id,
      user_id: b.user_id,
      logo: img,
      email: b.email,
      room_count: b.room_count,
      property_id: b.id
    }
    this.api.post('api/property/store', a, true).subscribe((res: any) => {
      localStorage.setItem('logo', res.body.data.logo);
      this.events.publishLogo(res.body.data.logo);
      if (img == null) {
        this.pro_pic = null
      }
    })
  }

  get show_pic() {
    if (this.pro_pic == null) {
      if (localStorage.getItem('logo') != 'null') {
        return this.base_url2 + 'app/images/' + localStorage.getItem('logo');
      }
    } else if (this.pro_pic != null) {
      return this.pro_pic;
    }
    return 'assets/img/nav/logo.png';
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.pro_pic = event.target.result;
      }
      this.upload_img.append('image', event.target.files[0], event.target.files[0].name);
      this.api.post2('api/helper/upload_image', this.upload_img, false).subscribe((img_res: any) => {
        this.updateHotelLogo(img_res.body.data.image)
      }, err => this.error_handling.handle_error(err.status));
    }
  }

  fakeClick(id) {
    (<HTMLInputElement>document.getElementById(id)).click()
  }

  cardLayout_drag() {
    this.api.get("api/user/me", true).subscribe((res: any) => {
      console.log(res.body.data.widgets_backoffice_pms);

      if (res.body.data.widgets_backoffice_pms) {
        localStorage.setItem("CardLayout", res.body.data.widgets_backoffice_pms);
        this.cardLayout = JSON.parse(res.body.data.widgets_backoffice_pms);
      }


      let CardLayoutarr = (res.body.data.widgets_backoffice_pms);
      console.log(CardLayoutarr);
      if (CardLayoutarr == undefined || CardLayoutarr == "") {
        let Accounts = { cardName: "Accounts", cardKey: 1 };
        let Hotel_setup = { cardName: "Hotel_setup", cardKey: 2 };
        let Hotel_users = { cardName: "Hotel_users", cardKey: 3 };
        let Faqs = { cardName: "Faqs", cardKey: 4 };
        let Terms_use = { cardName: "Terms_use", cardKey: 5 };
        let Billing = { cardName: "Billing", cardKey: 6 };
        let Select_hotel = { cardName: "Select_hotel", cardKey: 7 };
        let Contact = { cardName: "Contact", cardKey: 8 };
        let Logs = { cardName: "Logs", cardKey: 9 };
        let Vendor = { cardName: "Vendor", cardKey: 10 };
        let Marketing = { cardName: "Marketing", cardKey: 11 };
        let mobileApp = { cardName: "mobileApp", cardKey: 12 };
        let profitandloss = { cardName: "profitandloss", cardKey: 13 };
        let foodNbeverages = { cardName: "foodNbeverages", cardKey: 14 };
        let roomservice = { cardName: "roomservice", cardKey: 15 };
        let payroll = { cardName: "payroll", cardKey: 16 };
        let housekeeping = { cardName: "housekeeping", cardKey: 17 };
        let business_partner = { cardName: "business_partner", cardKey: 18 };
        let communication = { cardName: "communication", cardKey: 19 };
        let business_revenue = { cardName: "business_revenue", cardKey: 20 };
        let layoutList = [];

        layoutList.push(Accounts);
        layoutList.push(Hotel_setup);
        layoutList.push(Hotel_users);
        layoutList.push(Faqs);
        layoutList.push(Terms_use);
        layoutList.push(Billing);
        layoutList.push(Select_hotel);
        layoutList.push(Contact);
        layoutList.push(Logs);
        layoutList.push(Vendor);
        layoutList.push(Marketing);
        layoutList.push(mobileApp);
        layoutList.push(foodNbeverages);
        layoutList.push(profitandloss);
        layoutList.push(roomservice);
        layoutList.push(payroll);
        layoutList.push(housekeeping);
        layoutList.push(business_partner);
        layoutList.push(communication);
        layoutList.push(business_revenue);

        this.cardLayout = layoutList;
        localStorage.setItem(
          "CardLayout",
          JSON.stringify(this.cardLayout)
        );

      }
      this.skeleton = false;
    }, (err) => this.error_handling.handle_error(err.status));

  }

  data() {
    $('#settings').modal('show');
    $('#settings').modal({ backdrop: 'static', keyboard: true });
    $('#settings').draggable();
    $('#settings').draggable("disable");
    this.confirm.confirm(`Are you sure you want to Leave it?`, ``, [`This action can't be undone`, 'Are you sure you want to loss your data?'],
      function () {
        $('#settings').on('hidden.bs.modal', () => this.router.navigateByUrl('/app/back_office'));
      }
      , '', ''
    );

  }

  checkIfFoodandBevModal() {
    return this.router.url == '/app/back_office/foodandbeverges'
  }

  // skeleton
  getEmptyElements(count) {
    return new Array(count).map(x => '');
  }

  // get active shop id
  isActiveShopId() {
    this.rmsService.activeShopId$.subscribe((res: number) => {
      if (res) {
        this.activeShopId = res;
      }
    });
  }

  // get shop name (working on saud 👇)
  getShopNames() {
    this.editMode = false;
    this.fnbService.getcreateShopNames().subscribe(res => {
      this.shopNames = res.data.shops;
      this.rmsService.setShopNames(this.shopNames);
      this.switchShop(this.shopNames[0].shop_id);
    });
  }

  //  switch shop button click to get data (working on saud 👇)
  switchShop(shop_id: number) {
    this.editMode = false;
    this.activeShopId = shop_id;
    this.rmsService.matTabIndex$.next(0)
    this.rmsService.activeShopId$.next(this.activeShopId);
    this.rmsService.fetchDealMenuItems(shop_id);
    this.rmsService.fetchProductMenuItems(shop_id);
  }

  confirmModalClose() {
    if (!this.isOrderListEmpty) {
      // this.toaster.warning(
      //   'Your Cart is not empty. Exiting page without saving will lose data!', '', {
      //   positionClass: 'toast-center-center',
      //   timeOut: 8000
      // })
      this.helperService.alert_error('Your Cart is not empty. Exiting page without saving will lose data!');

    } else {
      this.activeShopId = null;
    }
  }

}








