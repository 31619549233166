<div>
  <div class="flex text space-between parent-bar">
    <div>Reports</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck5" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button5"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-reports" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button5')">
    </a>
  </div>
  <div class="collapse" id="collapse-reports" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <!-----1stchildCollapse-->
    <div class="col-md-6 pr-0 pl-0">
      <div class="flex text space-between child-bar1">
        <div>Channels</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button14"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-channel" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button14')">
        </a>
      </div>
      <div class="collapse" id="collapse-channel">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty c-d">
            <label> Download</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

      </div>
    </div>
    <!----- 2ndchildCollapse-->
    <div class="col-md-6 pr-0 pl-2">
      <div class="flex text space-between child-bar2">
        <div>Rooms</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button15"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-room" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button15')">
        </a>
      </div>
      <div class="collapse" id="collapse-room">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty kpi">
            <label>Manager KPI's</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty r-d">
            <label> Download</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
      </div>
    </div>
    <!------------------->
  </div>
</div>