<!-- <div class="alert alert-info alert-dismissible show" role="alert">
    You've got 3 notifications and 2 keynotes, everything else looks smooth
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <strong id="cross">&#x2716;</strong>
    </button>
</div> -->

<div class="alert alert-danger" style="height:45px;" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
  <a href="" class="btn btn-link">BACK OFFICE</a>
  <a href="" class="btn btn-link active">ACCOUNT SETTINGS</a>
</div>

<form class="form-horizontal back-form" [formGroup]="account_settings_form" (ngSubmit)="onSubmit()">

  <div class="card" style="padding-bottom: 0px;" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-10 topBarsInfo">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 fr-mb">
              <div class="border-style pink-shade">
                <input type="text" class="form-control" placeholder="First Name" formControlName="first_name">
              </div>
              <div *ngIf="submitted && f.first_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.first_name.errors.required">First Name is required</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-style orange-shade">
                <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name">
              </div>
              <div *ngIf="submitted && f.last_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.last_name.errors.required">Last Name is required</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 fr-mb">
              <div class="border-style green-shade">
                <input type="number" class="form-control" placeholder="Enter your Phone number" formControlName="phone">
              </div>
              <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.phone.errors.required">Phone Number is required</strong>
              </div>
            </div>
            <div class="col-md-6">
              <div class="border-style brown-shade">
                <input type="text" class="form-control" placeholder="Enter your Email Address" formControlName="email"
                  [disabled]>

                <!---start tooltip--->
                <span class="ispan">
                  <i class="fas fa-info-circle i" style="font-size: 15px;">
                    <span class="tooltiptext">
                      <p class="small" style="padding: 0px !important; margin: 0px !important;">
                        Contact "support" to change e-mail address
                      </p>
                    </span>
                  </i>
                </span>
                <!---end tooltip--->



              </div>
              <!-- <p class="small email_text no-margin"><strong>Contact support to change your e-mail address</strong>
              </p>  -->
            </div>
          </div>
        </div>

        <div class="row field-space lang-bot">
          <div class="col-md-12">
            <div class="col-md-6">
              <div class="border-style blue-shade">
                <ng-select [items]="languages" bindValue="name" bindLabel="name" formControlName="language"
                  placeholder="Select Language" [ngClass]="darkMode ? 'dark-drop' : ' null '"></ng-select>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>

      </div>

      <div class="col-md-2 col-sm-6 profile-img zero-padding">
        <div class="img-wrapper">

          <div class="upload-btn-wrapper hov-btn">
            <img class="img " src="{{ show_pic }}" id="customBtn">
            <!-- <button class="btn btn-primary hoverFile" style="width:80px; height:30px;">Upload Picture</button> -->
            <input type="hidden" formControlName="profile_picture" id="realFileBtn">
            <input type="file" name="photo" (change)="onSelectFile($event)">
          </div>

          <div class="img-icon">
            <small><strong>Delete Picture</strong></small><br>
            <img class="imges" src="assets/img/back-office/4336.svg" width="auto" height="auto" (click)="remove_file()">
          </div>
        </div>

      </div>


    </div>
  </div>

  <!-- <div class="gradient-bar">
      <div class="col-md-12">
        <h4>Theme & Layout</h4>
      </div>
  </div>

  <div class="container-fluid">
    <div class="row">
        <div class="col-md-6 grid_20">
            <div class="card mode_layout">
                <div class="row row-flex">
                    <div class="col-md-6 grid_20_inside">
                        <div class="radio-box">
                          <label class="control-label radio-style" (change)="setDarkMode()">
                            <input type="radio" class="pull-left radio" name="mode" value="light" formControlName="mode">
                            <span class="radio-checkmark"></span>
                            <span class="title">Light Mode</span>
                            <img src="assets/img/back-office/4531.png" class="img-responsive">
                          </label>
                        </div>
                    </div>
                    <div class="col-md-6 grid_20_inside">
                        <div class="radio-box">
                          <label class="control-label radio-style" (change)="setDarkMode()">
                            <input type="radio" class="pull-left radio" name="mode" value="dark" formControlName="mode">
                            <span class="radio-checkmark"></span>
                            <span class="title">Dark Mode</span>
                            <img src="assets/img/back-office/4532.png" class="img-responsive">
                          </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 grid_20">
            <div class="card">
                <div class="row row-flex">
                    <div class="col-md-6 grid_20_inside">
                        <div class="radio-box">
                          <label class="control-label radio-style">
                              <input type="radio" class="pull-left radio" name="layout" value="traditional"  formControlName="layout">
                              <span class="radio-checkmark"></span>
                              <span class="title">Traditional Layout</span>
                              <img src="assets/img/back-office/4533.png" class="img-responsive">
                          </label>
                        </div>
                    </div>
                    <div class="col-md-6 grid_20_inside">
                        <div class="radio-box">
                          <label class="control-label radio-style">
                            <input type="radio" class="pull-left radio" name="layout" value="modern" formControlName="layout">
                            <span class="radio-checkmark"></span>
                            <span class="title">Modern Layout</span>
                            <img src="assets/img/back-office/4534.png" class="img-responsive">
                          </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div> -->

  <div class="row">
    <div class="col-md-6" style="padding-right: 2px;width: 64%;">
      <div class="gradient-bar ">
        <div>
          <h4>Theme &amp; Layout</h4>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 grid_20 zero-padding">
            <div class="card mode_layout" [ngClass]="darkMode ? 'card_dark' : ' card '">
              <div class="row row-flex">
                <div class="col-md-6 grid_20_inside">
                  <div class="radio-box">
                    <label class="control-label radio-style"
                      [ngStyle]="account_settings_form.value.mode == 'light' ? {'border': '2px solid #ff8d00','border-radius': '10px','height': '18vh' } : null">
                      <input class="pull-left radio dinon" name="mode" type="radio" value="light"
                        formControlName="mode">
                      <span class="radio-checkmark dinon">
                      </span>
                      <span class="title dinon">Light Mode</span>
                      <img class="app-mode" src="assets/img/back-office/dashlightp.png">
                    </label>
                  </div>
                </div>
                <div class="col-md-6 grid_20_inside">
                  <div class="radio-box">
                    <label class="control-label radio-style"
                      [ngStyle]="account_settings_form.value.mode == 'dark' ? {'border': '2px solid #ff8d00','border-radius': '10px','height': '18vh'} : null">
                      <input class="pull-left radio dinon" name="mode" type="radio" value="dark" formControlName="mode">
                      <span class="radio-checkmark dinon">
                      </span>
                      <span class="title dinon">Dark Mode</span>
                      <img class="app-mode" src="assets/img/back-office/dashdarkp.png">
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6" style="padding-left: 3px;width: 36%;">
      <div class="gradient-bar">
        <h4>Credentials & Security</h4>
      </div>




      <div class="row">
        <div class="col-md-12">
          <div class="change_password card" [ngClass]="darkMode ? 'card_dark' : ' card '">
            <div class="row">
              <div class="col-md-12 passchngeColchay">
                <div class="row">
                  <div class="col-md-12 field-space">
                    <div class="border-style red-shade">
                      <input type="password" class="form-control" placeholder="Enter Your Current Password"
                        formControlName="current_password" [(ngModel)]="current_password">
                    </div>
                  </div>
                  <div class="col-md-12 field-space">
                    <div class="border-style sea-blue-shade">
                      <!-- <input type="password" class="form-control" placeholder="Enter New Password" formControlName="new_password" [required]="current_password ? true:null "> -->
                      <input type="password" class="form-control" placeholder="Enter New Password"
                        formControlName="new_password">
                    </div>
                    <div class="error-text"
                      *ngIf="account_settings_form.get('new_password').hasError('passwordStrength')">
                      <p class="small  no-margin">
                        <strong>{{ account_settings_form.get('new_password').errors['passwordStrength']}}</strong>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="border-style see-green-shade">
                      <input type="password" class="form-control" placeholder="Confirm New Password"
                        formControlName="confirm_password">
                    </div>
                    <div *ngIf="pass_sub && f.confirm_password.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <div *ngIf="f.confirm_password.errors.required"> Confirm password is required. </div>
                    </div>
                    <div
                      *ngIf="account_settings_form.errors?.mismatch && (account_settings_form.controls['confirm_password'].dirty || account_settings_form.controls['confirm_password'].touched)"
                      class="small text-danger no-margin invalid-feedback">
                      <div> Password & Confirm Password does not match.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 password-details">
                <div class="pass_strength field-space" *ngIf="account_settings_form.get('new_password').errors">
                  <h5 class="pass">
                    Password Strength
                    <i class="fas fa-info-circle wonka i">
                      <span class="tooltiptext2">
                        <!-- <p  class="pass1">Add uppercase letters.</p>
                          <p  class="pass1">Add numbers.</p>
                          <p  class="pass1">Add Symbols.</p> -->
                        <p>Your password can be any combination of letters, numbers, and symbols.</p>
                      </span>
                    </i>
                  </h5>
                  <div class="progress">
                    <div [style.width.%]="account_settings_form.get('new_password').errors['percent']"
                      [style.background-color]="account_settings_form.get('new_password').errors['color']"
                      class="progress-bar">
                    </div>
                  </div>
                </div>
                <div class="pass_strength field-space" *ngIf="account_settings_form.get('new_password').errors == null">
                  <h5>
                    Password Strength
                    <strong class="pull-right">Excellent</strong>
                  </h5>
                  <div class="progress">
                    <div [style.width.%]="100" [style.background-color]="'#3ff204'" class="progress-bar">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="modal-footer" style="    padding: 0px 8px;bottom: -40px !important; ">
    <button type="submit" class="btn btn-primary btn-lg hov-btn"
      [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">Save</button>
    <a class="btn btn-danger btn-lg hov-btn" data-dismiss="modal" aria-label="Close"
      [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Close</a>
  </div>
</form>