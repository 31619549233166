<div class="main_body_sec w-clearfix">
    <!-- dashboard skeleton start (working on saud 👇) -->
    <ul id="drag_box" style="list-style-type: none;" class="pl-0" *ngIf="dashboard_skeleton" [ngClass]="darkMode ? 'darkSkel' : 'null'">
        <div class="dashboard-skeleton">
            <!-- Stays -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card">
                    <div class="win_header bg-dark"></div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card_vacan skeleton-animation-stay flex-bar">
                        <div class="Flex_conatiner">
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder Imgtext_holder_boder ">
                                <div class="stay-bar-box"></div>
                            </div>
                            <div class="Imgtext_holder">
                                <div class="stay-bar-box"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Stays end -->

            <!-- Performancestart -->
            <li class="drag_item">
                <div class="dash_window_2 no_margin w-clearfix dashboard-card">
                    <!--- Rates -->
                    <div class="card-1">
                        <div class="win_header bg-dark">
                        </div>
                        <div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="big_bar skeleton-animation-performance">
                                <div class="text_wrapper">
                                    <div class="text-grey-long">
                                    </div>
                                    <div class="text-grey-short">
                                    </div>
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Performance  End-->

            <!-- Reservations -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card ">
                    <div class="win_header bg-dark">
                    </div>
                    <div class="">
                        <div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Reservations End -->

            <li class="drag_item ">
                <div class="dash_window no_margin w-clearfix dashboard-card ">
                    <div class="win_header bg-dark">
                    </div>
                    <div class="cardFrontDesk skeleton-animation-front" [ngClass]="darkMode ? 'card2_dark':'null'">
                        <div class="row rsCard ">
                            <div class="col-md-6 zero-padding pt13 pl10 pr10 frontCard">
                                <div class="mainLine-bar ">
                                </div>
                                <div class="mainLine-bar">
                                </div>
                                <div class="mainLine-bar">
                                </div>
                                <div class="mainLine-bar">
                                </div>
                                <div class="mainLine-bar">
                                </div>
                                <div class="mainLine-bar">
                                </div>
                            </div>
                            <div class="col-md-6 zero-padding pl2">
                                <div class="no-gutters col-md-12 zero-padding">
                                    <div class="cardRadius">

                                        <div class="speedbox">
                                            <div class="speedbox__score border_color">
                                            </div>
                                            <div class="speedbox__groove"></div>
                                            <div class="speedbox__odo">
                                                <div class="speedbox__down">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="speedbox__base "
                                                [ngStyle]="{'background': darkMode ? '#000213' : 'rgb(238 238 238)'}">
                                            </div>
                                        </div>
                                        <div class="occupancy"></div>
                                    </div>
                                </div>
                                <div class="no-gutters col-md-12 zero-padding" style="z-index: 9999;margin-top: -8%;">
                                    <div class="cardBarsRedGreen">
                                        <div class="caret_up">
                                            <div class="greenbar bg-grey"></div>
                                        </div>
                                        <div class="caret_up">
                                            <div class="greenbar bg-grey"></div>
                                        </div>
                                        <div class="caret_up">
                                            <div class="greenbar bg-grey"></div>
                                        </div>
                                        <div class="caret_up">
                                            <div class="greenbar bg-grey"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

            <!-- Today's Weather start -->
            <li class="drag_item ">
                <div class="dash_window dashboard-card ">
                    <div class="win_header bg-dark">
                    </div>
                    <div id="box4" class="box">
                        <div class="weather_bg w-clearfix pt-0 skeleton-animation-front">
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-8">
                                    <div class="weather-bar-line1"></div>
                                    <div class="weather-bar-line2"></div>
                                    <div class="weather-bar-line3"></div>
                                </div>
                                <div class="col-md-4">
                                    <div class="weather-sky-bar"></div>
                                </div>
                            </div>
                            <div class="row d-flex align-items-baseline m-0 weather-barline">
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                                <div class="weather-sky-bar"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Today's Weather end -->

            <!-- Today's Notifications start -->
            <li class="drag_item">
                <div class="dash_window no_margin w-clearfix dashboard-card ">
                    <div class="win_header bg-dark">
                    </div>
                    <div class="">
                        <div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                            <div class="notifications _0 w-clearfix skeleton-animation-reservation flex-bar">
                                <div class="bar-grey-short">
                                </div>
                                <div class="bar-grey-long">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <!-- Today's Notifications end -->
        </div>
    </ul>
    <!-- dashboard skeleton end (working on saud 👇) -->

    <ul id="drag_box" style="list-style-type: none;" class="pl-0" [ngClass]="darkMode ? 'darkDashboard' : 'null'"
        *ngIf="dashboard && !dashboard_skeleton">
        <ng-container *ngFor="let card of this.cardLayout; let i = index">
            <ng-container *ngFor="let card of cardLayout;">
                <!-- Stays -->

                <li class="drag_item" index="1" index-data="Stays"
                    *ngIf="card.cardName == 'Stays' &&  card.cardKey==i+1">
                    <app-stays-dashboard [all_arrivals_stays]="all_arrivals"></app-stays-dashboard>
                    <!-- <div class="dash_window no_margin w-clearfix dashboard-card">
  <div class="win_header">
      <h4 class="heading">Stays</h4>

  </div>

  <div class="card_vacan">
      <div class="mainhd">Today's</div>
      <div class="Flex_conatiner">
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                  alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
              <span class="fon vacancy1">{{all_arrivals.in_house}}</span>
              <span class="tooltiptext1">
                  <p>
                      In-House
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                  style="width: 18px;" alt="">
              <span class="fon vacancy2">{{all_arrivals.todays_departure}}</span>
              <span class="tooltiptext1">
                  <p>
                      Departure
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
              <span class="fon vacancy3">{{all_arrivals.todays_arrivals}}</span>
              <span class="tooltiptext1">
                  <p>
                      Arrivals
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder  info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                  alt="" width='31px'>
              <span class="fon vacancy4">{{all_arrivals.todays_bookings}}</span>
              <span class="tooltiptext1">
                  <p>
                      Reservation
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder info">
              <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                  alt="" width='35px' style="margin-top:10px;">
              <span class="fon vacancy5">{{all_arrivals.todays_available}}</span>
              <span class="tooltiptext1">
                  <p>
                      Available
                  </p>
              </span>
          </div>
      </div>
  </div>
  <div class="card_vacan">
      <div class="mainhd">Next 7 days</div>
      <div class="Flex_conatiner">
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                  alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
              <span class="fon vacancy1">{{all_arrivals.next_seven_d_in_house}}</span>
              <span class="tooltiptext1">
                  <p>
                      In-House
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                  style="width: 18px;" alt="">
              <span class="fon vacancy2">{{all_arrivals.next_seven_d_departure}}</span>
              <span class="tooltiptext1">
                  <p>
                      Departure
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
              <span class="fon vacancy3">{{all_arrivals.next_seven_d_arrival}}</span>
              <span class="tooltiptext1">
                  <p>
                      Arrivals
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder  info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                  alt="" width='31px'>
              <span class="fon vacancy4">{{all_arrivals.next_seven_d_bookings}}</span>
              <span class="tooltiptext1">
                  <p>
                      Reservation
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder info">
              <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                  alt="" width='35px' style="margin-top:10px;">
              <span class="fon vacancy5">{{all_arrivals.next_seven_d_available}}</span>
              <span class="tooltiptext1">
                  <p>
                      Available
                  </p>
              </span>
          </div>
      </div>
  </div>
  <div class="card_vacan">
      <div class="mainhd">Next 15 days</div>
      <div class="Flex_conatiner">
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                  alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
              <span class="fon vacancy1">{{all_arrivals.next_fifteen_d_in_house}}</span>
              <span class="tooltiptext1">
                  <p>
                      In-House
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                  style="width: 18px;" alt="">
              <span class="fon vacancy2">{{all_arrivals.next_fifteen_d_departure}}</span>
              <span class="tooltiptext1">
                  <p>
                      Departure
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder info">
              <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
              <span class="fon vacancy3">{{all_arrivals.next_fifteen_d_arrival}}</span>
              <span class="tooltiptext1">
                  <p>
                      Arrivals
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder Imgtext_holder_boder  info">
              <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                  alt="" width='31px'>
              <span class="fon vacancy4">{{all_arrivals.next_fifteen_d_bookings}}</span>
              <span class="tooltiptext1">
                  <p>
                      Reservation
                  </p>
              </span>
          </div>
          <div class="Imgtext_holder info">
              <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                  alt="" width='35px' style="margin-top:10px;">
              <span class="fon vacancy5">{{all_arrivals.next_fifteen_d_available}}</span>
              <span class="tooltiptext1">
                  <p>
                      Available
                  </p>
              </span>
          </div>
      </div>
  </div>
</div> -->
                </li>
                <!-- Stays end -->
                <!-- Performancestart -->
                <li class="drag_item" index="2" index-data="Performance"
                    *ngIf="card.cardName == 'Performance' &&  card.cardKey==i+2" style="overflow: hidden">
                    <app-perfomance [performanceData_per]="performanceData"></app-perfomance>
                </li>
                <!-- Performance  End-->
                <!-- Reservations -->
                <li class="drag_item " index="3" index-data="Reservations"
                    *ngIf="card.cardName=='Reservations' && card.cardKey==i+3 ">
                    <app-reservation [reservations_res]="reservations"></app-reservation>
                </li>

                <li class="drag_item " index="4" index-data="House Keeping"
                    *ngIf="card.cardName=='House Keeping' && card.cardKey==i+4">
                    <app-front-desk [frontDesk_front]="frontDesk"></app-front-desk>
                </li>

                <!-- Reservations -->
                <!-- <li class="drag_item " index="4" index-data="House Keeping" *ngIf="card.cardName=='House Keeping' && card.cardKey==i+4 ">
                  <div class="dash_window no_margin w-clearfix dashboard-card ">
                      <div class="win_header " style="margin: 0px; ">
                          <h4 class="heading ">House Keeping</h4>
                      </div>
                      <div class="row rsCard ">
                          <div class="col-md-6 zero-padding pr3 ">

                              <div class="occCard1 ">
                                  <div class="numsDiv ">
                                      <div class="num ">{{housekeepingData.clean_occupied}}</div>
                                      <div class="statusRS ">clean</div>
                                  </div>
                                  <div class="icon "> <img src="assets/img/dashboard/in-house2.svg " alt=" " width="30px "> </div>
                              </div>
                              <div class="occCard1 ">
                                  <div class="numsDiv ">
                                      <div class="num ">{{housekeepingData.dirty_occupied}}</div>
                                      <div class="statusRS ">dirty</div>
                                  </div>
                                  <div class="icon "> <img src="assets/img/dashboard/in-house2.svg " alt=" " width="30px "> </div>
                              </div>
                          </div>
                          <div class="col-md-6 zero-padding pl2 ">

                              <div class="occCard1 ">
                                  <div class="numsDiv ">
                                      <div class="num ">{{housekeepingData.maintenance}}</div>
                                      <div class="statusRS ">maintenance</div>
                                  </div>
                                  <div class="icon "> <img src="assets/img/dashboard/maintainance3.svg " alt=" " width="30px "> </div>
                              </div>
                              <div class="occCard1 ">
                                  <div class="numsDiv ">
                                      <div class="num ">{{housekeepingData.dirty_vacant}}</div>
                                      <div class="statusRS ">dirty</div>
                                  </div>
                                  <div class="icon "> <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg': 'assets/img/dashboard/available.svg'}} " alt=" " width="30px "> </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </li> -->
                <!-- Reservations end  -->
                <!-- Today's Weather start -->
                <li class="drag_item " index="5" index-data="Todays Weather"
                    *ngIf="card.cardName=='Todays Weather' && card.cardKey==i+5 ">
                    <app-weather></app-weather>
                </li>
                <!-- Today's Weather end -->

                <!-- Today's Notifications start -->
                <li class="drag_item " index="6" index-data="Notifications"
                    *ngIf="card.cardName=='Notifications' && card.cardKey==i+6 ">
                    <app-notifications [event_images_noti]="event_images"
                        [dashboard_noti]="dashboard"></app-notifications>
                </li>
                <!-- Today's Notifications end -->

                <!-- Performancestart copy -->

                <!-- Performance  End-->

            </ng-container>
        </ng-container>
    </ul>
    <!-- <div id="box4 "class="box">
<div class="side side1 ">1</div>
<div class="side side2 ">2</div>
<div class="side side3 ">3</div>
<div class="side side4 ">4</div>
</div> -->
</div>
