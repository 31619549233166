
<!-- <div *ngFor="let permission of permissions;index as i">
  <div class="flex text space-between parent-bar" [ngStyle]="{background: permission.color}">
    <div>{{permission.name}}</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck2" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" [id]="'button'+i"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" [attr.href]="'#collapse'+i" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button',i)">
    </a>
  </div>
  <div class="collapse" id="collapse{{i}}" [ngClass]="darkMode ? 'card_dark' : 'null'"> -->
    <!-----1stchildCollapse-->
    <!-- <ng-container *ngFor="let firstType of permission.types;index as j">
      <div class="col-md-6" *ngIf="firstType.types" style="padding: 0 2px;">
        <div class="flex text space-between child-bar1" [ngStyle]="{background: firstType.color}">
          <div>{{firstType.name}}</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" *ngIf="firstType.types.length != 0" class="mR10"
              [id]="'button8'+j" style="margin-top:-5px;height: 28px;" data-toggle="collapse"
              [attr.href]="'#'+setId(firstType.name)" role="button" aria-expanded="false" aria-controls="collapse-panel"
              (click)="changeclass('button8',j)">
          </a>
        </div>
        <div class="collapse" [id]="setId(firstType.name)">
          <div class="col-md-12 pr-0 pl-0 pb-2" *ngFor="let nestedType of firstType.types">
            <div class="border-style  first-name-shade lab-sty " [ngStyle]="{'border-color': nestedType.border_color} ">
              <label>{{nestedType.name}}</label>
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 pr-0 pl-0 pb-2" *ngIf="!firstType.types">
        <div class="border-style  first-name-shade lab-sty " [ngStyle]="{'border-color': firstType.border_color}">
          <label>{{firstType.name}}</label>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
            style="margin:0px;cursor: pointer;">
        </div>
      </div>

    </ng-container> -->
    <!----- 2ndchildCollapse-->
    <!-- <div class="col-md-6 pr-0 pl-2">
      <div class="flex text space-between child-bar2">
        <div>Guest Look Up</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button9"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-loopUp" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button9')">
        </a>
      </div>
      <div class="collapse" id="collapse-loopUp">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty G-1">
            <label> Guest Details</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty G-2">
            <label> Email</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty G-3">
            <label> Download</label>

            <div class="d-flex ex-span">
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="cursor: pointer;">
              <span>PDF</span>
            </div>
          </div>

        </div>
      </div>
    </div> -->
    <!------------------->

  <!-- </div>
</div> -->

<!-- new UI Structure -->

<!-- 1nd Column -->
<div class="col-md-4 p-0" [ngClass]="darkMode ? 'card_dark' : 'null'">

   <!--------mobile----------------->
   <!-- <div class="col-md-12 flex parent-bar cb18">
     <a>
       <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
         title="Select all" style="cursor: pointer;">
     </a>
    <div>Mobile App</div>
  </div> -->
  <div class="parent-bar cb18">

    <a>
      <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button1"
        style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-mob" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button1')">
      </a>
      <div class="d-flex" style="width:100%; justify-content: space-between;">
        <span>Mobile App</span>
        <mat-slide-toggle></mat-slide-toggle>
      </div>

</div>

<div class="collapse" id="collapse-mob" [ngClass]="darkMode ? 'card_dark' : 'null'">
<div class="col-md-12 pr-0 pl-0 pb-2">

  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Integrated Front Desk (desktop version)</span>
      </label>
      <!-- <input type="checkbox" class="form-check-input ml-3" value="" id="defaultCheck1" name="checkbox"
      style="margin:0px;cursor: pointer;">

       <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'" style="position: absolute;
       height: 15px;
       width: 15px;
       background-color: #1e74d1;
       border-radius: 15%;
       opacity: 0.3;
       left: 0;"></span>
      <label>Integrated Front Desk (desktop version)</label> -->
    </div>
  </div>

  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Property Setup</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Book Now</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Notification</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">OTA States</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Report</span>
    </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Inventory</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Promotion</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Arrivals</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Financials</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Guest Lookup</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Payroll</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Channels & OTAs</span>
      </label>
    </div>
  </div>

</div>
</div>
  <!---------dashboard----------->

  <div class="parent-bar"style="background: linear-gradient(#76a200, #435b02);">

        <a>
          <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
            title="Select all" style="cursor: pointer;"> -->
          <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button2"
            style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-dashboard" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button2')">
          </a>
          <div class="d-flex" style="width:100%; justify-content: space-between;">
            <span>Dashboard</span>
            <mat-slide-toggle></mat-slide-toggle>
          </div>

  </div>

  <div class="collapse" id="collapse-dashboard" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <div class="col-md-12 pr-0 pl-0 pb-2">

      <div class="col-md-12 pr-0">
        <div class="lab-sty">
          <label class="control-label checkbox-style" style="display: inline-flex;">
            <input type="checkbox" class="checkbox" name="" value="">
            <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
            <span class="permission-title-2 ml-2">Performance</span>
          </label>
        </div>
      </div>
    </div>
</div>
  <!---------Reports----------->

  <div class="parent-bar"style="background: linear-gradient(#ffc300, #806200);">

        <a>
          <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
            title="Select all" style="cursor: pointer;"> -->
          <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button3"
            style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-report" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button3')">
          </a>
          <div class="d-flex" style="width:100%; justify-content: space-between;">
            <span>Reports</span>
            <mat-slide-toggle></mat-slide-toggle>
          </div>

  </div>

  <div class="collapse" id="collapse-report" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <div class="col-md-12 pr-0 pl-0 pb-2">

      <div class="col-md-12 pr-0">
        <div class="lab-sty">
          <label class="control-label checkbox-style" style="display: inline-flex;">
            <input type="checkbox" class="checkbox" name="" value="">
            <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
            <span class="permission-title-2 ml-2">Performance</span>
          </label>
        </div>
      </div>
      <div class="col-md-12 pr-0">
        <div class="lab-sty">
          <label class="control-label checkbox-style" style="display: inline-flex;">
            <input type="checkbox" class="checkbox" name="" value="">
            <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
            <span class="permission-title-2 ml-2">Financials</span>
          </label>
        </div>
      </div>
      <div class="col-md-12 pr-0">
        <div class="lab-sty">
          <label class="control-label checkbox-style" style="display: inline-flex;">
            <input type="checkbox" class="checkbox" name="" value="">
            <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
            <span class="permission-title-2 ml-2">Trends</span>
          </label>
        </div>
      </div>
    </div>
</div>

</div>


<!-- 2nd Column -->
<div class="col-md-4 p-0 pl-2" [ngClass]="darkMode ? 'card_dark' : 'null'">

   <!-------cm--------->
   <!-- <div class="col-md-12 flex parent-bar cb5">
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
        title="Select all" style="cursor: pointer;">
    </a>
   <div>Channel Manager</div>
 </div> -->
 <div class="parent-bar cb5">

  <a>
    <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button4"
      style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-cm" role="button"
      aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button4')">
    </a>
    <div class="d-flex" style="width:100%; justify-content: space-between;">
      <span>Channel Manager</span>
      <mat-slide-toggle></mat-slide-toggle>
    </div>

</div>

<div class="collapse" id="collapse-cm" [ngClass]="darkMode ? 'card_dark' : 'null'">

<div class="col-md-12 pr-0 pl-0 pb-2">

<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Add | Edit Channels</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Setup Promotion</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Create Special Ofers</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Channel Performance</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Inventory Management</span>
    </label>
  </div>
</div>

</div>

</div>
  <!-------------------->

 <!---------Stays----------->

 <div class="parent-bar" style="background: linear-gradient(#00B4D8, #005A6C);">

  <a>
    <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
      title="Select all" style="cursor: pointer;"> -->
    <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button5"
      style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-stays" role="button"
      aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button5')">
    </a>
    <div class="d-flex" style="width:100%; justify-content: space-between;">
      <span>Stays</span>
      <mat-slide-toggle></mat-slide-toggle>
    </div>

</div>

<div class="collapse" id="collapse-stays" [ngClass]="darkMode ? 'card_dark' : 'null'">

<div class="col-md-12 pr-0 pl-0 pb-2">

<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Create Reservation</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Update Reservation</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">View Reservation</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Guest Lookup</span>
    </label>
  </div>
</div>

</div>

</div>
<!------------------->

 <!---------Promo----------->

 <div class="parent-bar"style=" background: linear-gradient(#b4ff00, #5a8000);">

  <a>
    <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
      title="Select all" style="cursor: pointer;"> -->
    <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button6"
      style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-promo" role="button"
      aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button6')">
    </a>
    <div class="d-flex" style="width:100%; justify-content: space-between;">
      <span>Promotion</span>
      <mat-slide-toggle></mat-slide-toggle>
    </div>

</div>

<div class="collapse" id="collapse-promo" [ngClass]="darkMode ? 'card_dark' : 'null'">

<div class="col-md-12 pr-0 pl-0 pb-2">

<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Create Rate Plan</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Update Rate Plan</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Create Promotions</span>
    </label>
  </div>
</div>
<div class="col-md-12 pr-0">
  <div class="lab-sty">
    <label class="control-label checkbox-style" style="display: inline-flex;">
      <input type="checkbox" class="checkbox" name="" value="">
      <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
      <span class="permission-title-2 ml-2">Update Promotions</span>
    </label>
  </div>
</div>

</div>

</div>
<!----------------->

</div>
<!-- 3nd Column -->
<div class="col-md-4 p-0 pl-2" [ngClass]="darkMode ? 'card_dark' : 'null'">

   <!-------backoffice----------------->
   <div class="parent-bar">

    <a>
      <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
        title="Select all" style="cursor: pointer;"> -->
      <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button7"
        style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-backoffice" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button7')">
      </a>
      <div class="d-flex" style="width:100%; justify-content: space-between;">
        <span>Back office</span>
        <mat-slide-toggle></mat-slide-toggle>
      </div>
</div>

<div class="collapse" id="collapse-backoffice" [ngClass]="darkMode ? 'card_dark' : 'null'">

<div class="col-md-12 pr-0 pl-0 pb-2">

  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Create Hotel Setup</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Update Hotel Setup</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Select Hotel</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Billing</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Room Service</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Food & Beverages</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Payroll Calculator</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Financials</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Rate Plan</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Logs</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Communication</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Business Partner</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">House Keeping</span>
      </label>
    </div>
  </div>

</div>

</div>
<!------------------------->

  <!---------arrivals----------->

  <div class="parent-bar" style="background: linear-gradient(#fe6601, #7f3301)">

    <a>
      <!-- <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
        title="Select all" style="cursor: pointer;"> -->
      <img src="assets/img/reports/drop-arrow.svg" class="mR10" id="button8"
        style="margin-right:10px; height:15px;" data-toggle="collapse" href="#collapse-arrival" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button8')">
      </a>
      <div class="d-flex" style="width:100%; justify-content: space-between;">
        <span>Arrivals</span>
        <mat-slide-toggle></mat-slide-toggle>
      </div>

  </div>

  <div class="collapse" id="collapse-arrival" [ngClass]="darkMode ? 'card_dark' : 'null'">

  <div class="col-md-12 pr-0 pl-0 pb-2">

  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">Update Reservation</span>
      </label>
    </div>
  </div>
  <div class="col-md-12 pr-0">
    <div class="lab-sty">
      <label class="control-label checkbox-style" style="display: inline-flex;">
        <input type="checkbox" class="checkbox" name="" value="">
        <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
        <span class="permission-title-2 ml-2">View Reservation</span>
      </label>
    </div>
  </div>

  </div>

  </div>
  <!----------------->
</div>
