<div class="main-div">
  <!-- <app-payroll-beardcrum></app-payroll-beardcrum> -->
  <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
    <div class="row">
      <div class="column-wrapper display-flex space-between">
        <div class="col-md-10">
          <ul class="nav navbar-nav tabsBar">
            <a class="btn btn-link" routerLink="/app/back_office/payroll_employee_lists"
              routerLinkActive="active">Hotel</a>
            <a class="btn btn-link">Room Service</a>
            <a class="btn btn-link">Resturant</a>
            <a class="btn btn-link">Cigar Lounge</a>
          </ul>
        </div>
        <div class="col-md-2">
          <img src="../../../../assets/img/inventory/download-icon.png" alt="" srcset="" (click)="makePDF()"
            class="pdf-btn">
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12 blueHedaer">
    <div class="row">
      <div class="category">Name</div>
      <div class="category">Role</div>
      <div class="category">Deductions</div>
      <div class="category">Pay</div>
      <div class="action">Action</div>
    </div>
  </div>
  <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">
    <ng-container *ngFor="let list of employeelist;index as i">
      <div class="row userRecord">
        <div class="col-md-12 inputCat p-0">
          <div class="modal-bar" [ngStyle]="{'border-color': randomColor[i%randomColor.length]}">
            <div class="row d-flex">
              <div class="col-md-9 addItemCat">
                <input class="form-control borderRight emp-name" type="text" [value]="list.employee_name" readonly>
                <input class="form-control borderRight" type="text" [value]="list.role.name" readonly>
                <input class="form-control borderRight" type="text"
                  value="{{currency_format}} {{list.total_deductions | number:'1.2-3'}}" readonly>
                <input class="form-control borderRight" type="text"
                  value="{{currency_format}} {{list.pay | number:'1.2-3'}}" readonly>
              </div>
              <div class="col-md-3 actions">
                <img src="../../../../assets/img/create_user_fnb/view.svg" alt="view" class="edit"
                  (click)="emp_details(list.employee_id)" data-toggle="tooltip" data-placement="bottom" title="View">
                <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit" class="edit" data-toggle="tooltip"
                  data-placement="bottom" title="Edit" (click)="emp_detail_edit(list.employee_id)"
                  routerLink="/app/back_office/payroll">
                <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="edit"
                  data-toggle="tooltip" data-placement="bottom" title="Delete" data-toggle="modal"
                  data-target="#confirmationModal-3" (click)="emp_detail_delete(list.employee_id)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer" [ngClass]="darkMode ? 'modal-footer-dark' : ''">
    <button class="btn btn-danger">Save</button>
  </div>
</div>

<!-- Modal Table -->
<div class="modal fade" id="productModal" *ngIf="OrderModalShow" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" cdkDrag>
  <div class="modal-dialog">
    <div class="modal-content" id="contentPrint" [ngClass]="darkMode ? 'model_dark' : ''">
      <div class="redHedaer" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
        <h5 class="modal-title" id="exampleModalLabel">{{employeeDetails[0].name}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-labelledby="" (click)="onModalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form action="">
        <div class="modal-body">
          <ng-container *ngFor="let deductions of employeeDetails[0].deductions;index as i">
            <div class="row">
              <div class="col-md-12 first">
                <div class="modal-bar" [ngStyle]="{'border-color': randomColor[i%randomColor.length]}">
                  <span class="d-flex"><input type="checkbox" name="checked{{i}}" class="checkbox"
                      [(ngModel)]="deductions.isChecked" *ngIf="printMode">{{deductions.label}}
                    {{deductions.percentage}}</span>
                  <span>{{currency_format}} {{deductions.amount | number:'1.2-3'}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row blueBarRow">
            <div class="col-md-12 blueHedaer">
              <div class="row">
                <div class="hold">Withholding</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 first">
              <div class="modal-bar border-12">
                <span>Total Deductions</span>
                <span>{{currency_format}} {{employeeDetails[0].total_deductions |
                  number:'1.2-3'}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 first">
              <div class="modal-bar border-13">
                <span>Pay After Deductions</span>
                <span>{{currency_format}} {{employeeDetails[0].pay_after_deductions |
                  number:'1.2-3'}}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-green"><img src="../../../../assets/img/order-list/Shared.svg"
          alt="share"></button>
      <button type="button" class="btn btn-green" (click)="makePDF()"><img
          src="../../../../assets/img/order-list/print.svg" alt="print"></button>
    </div>
  </div>
</div>