import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sold-item',
  templateUrl: './sold-item.component.html',
  styleUrls: ['./sold-item.component.css']
})
export class SoldItemComponent implements OnInit {
  a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
