<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>Retain Guest Data</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button38"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-data" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button38')">
    </a>
  </div>
  <div class="collapse" id="collapse-data" [ngClass]="darkMode ? 'card_dark' : 'null'">

    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-12 pr-10 pl-10">
              <div class="border-style  first-name-shade lab-sty Guest-data">
                <input type="radio" class="form-check-input ml-3" value="" name="radioBtn"
                  style="margin:0px;cursor: pointer;">
                <label> 2 days</label>
                <input type="radio" class="form-check-input ml-3" value="" name="radioBtn"
                  style="margin:0px;cursor: pointer;">
                <label> 5 days</label>
                <input type="radio" class="form-check-input ml-3" value="" name="radioBtn"
                  style="margin:0px;cursor: pointer;">
                <label>10 days</label>
                <input type="radio" class="form-check-input ml-3" value="" name="radioBtn"
                  style="margin:0px;cursor: pointer;">
                <label> 20 days</label>
                <input type="radio" class="form-check-input ml-3" value="" name="radioBtn"
                  style="margin:0px;cursor: pointer;">
                <label>30 days</label>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>