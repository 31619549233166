<!-- Reservation Pupop -->
<div id="reservation-popup" class="modal fade bulk_updatemodal" role="dialog" style="width:100%;"
  [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
  <form [formGroup]="reservationForm" (ngSubmit)="onSubmitReservation(reservationForm)" *ngIf="reservationForm">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div class="bluheader booking-sumary" cdkDrag>
            <h3 class="h3_blu_header bookingsummary">Reservation
            </h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="resetForm(reservationForm)" style="color: white;line-height: 34px">
              <strong id="cross">&#x2716;</strong>
            </button>
          </div>
        </div>
        <div class="modal-body dFlex">
          <div class="leftD">
            <div class="col-md-6 zero-padding float-L" [ngClass]="disabled ? 'disabledClick' : 'null'">
              <div class="col-md-12 zero-padding" style="padding-right: 2px;">
                <div class="bluheader booking-sumary redbar" style="display: flex;justify-content: space-between;">
                  <h3 class="h3_blu_header bookingsummary">Stays
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'"
                      *ngIf="!reservationOptions.isEdit">
                      <div class="tooltip" style="width:48%" [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">
                        {{tooltipTextForStays}}
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" class="applyAll2 chk" formControlName="sperateRoomDates" id="checkedForSep"
                    *ngIf="!reservationOptions.isEdit">
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-0ne onee sty"
                style="padding-right: 0px;height: 250px; overflow: inherit;">
                <div class="greyBox">
                  <div class="modal-bar-2 one">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px;border: none;padding-left: 0px">
                      <span class="input-group-addon"
                        style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                        <img src="assets/img/newstaticicons/dashboard/check-in.svg" style="height: 24px;" alt="image">
                      </span>
                      <input type="hidden" name="date_to">
                      <input type="hidden" name="date_from">
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" [singleDatePicker]="true"
                        [locale]="{format: 'DD MMM YYYY', separator: ' To '}"
                        [value]="formatDateForPicker(this.reservationForm.get('date_arrival').value)"
                        (change)="onCalendarDateChange($event, true)" placeholder="Check In">
                    </div>
                  </div>
                  <div *ngIf="this.reservationForm.get('date_arrival').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 two">
                    <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                      style="max-height: 30px;border: none;padding-left: 0px">
                      <span class="input-group-addon"
                        style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                        <img
                          src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg':'dashboard/departure.svg'}}"
                          style="margin-left: 6px; height: 24px;" alt="">
                      </span>
                      <input type="hidden" name="date_to">
                      <input type="hidden" name="date_from">
                      <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                        [autoApply]="true" [singleDatePicker]="true"
                        [locale]="{format: 'DD MMM YYYY', separator: ' To '}"
                        [value]="formatDateForPicker(this.reservationForm.get('date_departure').value)"
                        (change)="onCalendarDateChange($event, false)" placeholder="Check Out">
                    </div>
                  </div>
                  <div *ngIf="this.reservationForm.get('date_departure').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 three timePickerEta">
                    <img src="assets/img/newstaticicons/inventory/ETA-clock.svg" alt="" class="placeholder-img-modal">
                    <input type="time" class="form-control "
                      style="height:30px; border:none; padding:10px 0px 10px 30px; color:#00205b; font-size:14px"
                      placeholder="ETA" formControlName="eta">
                  </div>
                  <div *ngIf="this.reservationForm.get('eta').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 four timePickerEtd">
                    <img src="assets/img/newstaticicons/inventory/ETD-clock.svg" alt="" class="placeholder-img-modal">
                    <input type="time" class="form-control "
                      style="height: 30px;border: none;padding: 10px 0px 10px 30px;color: #00205b;font-size: 14px"
                      placeholder="ETD" formControlName="etd">
                  </div>
                  <div *ngIf="this.reservationForm.get('etd').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 five">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="night/day-image-icon"
                        [src]="'assets/img/newstaticicons/inventory/' + ((!showDayNight) ? 'icons-sun.png' : (darkMode ? 'night-old.svg' : 'night.svg'))"
                        style="height: 20px; margin-left: 2px;">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                      <input class="form-control" placeholder="Total Nights/Days" value=""
                        style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                        type="text" [value]="reservationOptions.durationOfStay" readonly>
                    </div>
                  </div>
                  <div class="modal-bar-2 six" style="margin-bottom: 5px;">
                    <div class="my-icon-0 ovi-rid">
                      <img alt="" src="assets/img/newstaticicons/inventory/return.svg"
                        style=" height: 20px; margin-left: 0px; margin-top: 2px;">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;padding-top: 4px;width:90%">
                      <input class="form-control" placeholder="Total Nights" value=""
                        style="height: 30px;border: none;padding: 0px;color: #00205b;font-size: 14px;margin-top: -4px;padding-left: 0px"
                        type="text" value="New Guest" readonly>
                    </div>
                  </div>
                  <div class="modal-bar-2 seven" *ngIf="reservationForm.get('confirmation_number').value">
                    <img src="assets/img/newstaticicons/arrivals/id-verified-light.svg" alt=""
                      class="placeholder-img-modal channel-img" style="margin-top: 4px;">
                    <input type="text" class="form-control" placeholder="Confirmation Id No"
                      formControlName="confirmation_number"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;"
                      maxlength="16">
                  </div>
                  <div class="modal-bar-2 eight">
                    <div *ngFor="let update_weather of weather_update; let i = index">
                      <i class='wi wi-main wi-{{ update_weather.icon }}'
                        [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                      <div class="weatherDiv">
                        <span class="wh_name">{{update_weather.title}}</span>
                        <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                        <span class="wh_con">{{update_weather.condition}} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 zero-padding float-R" [ngClass]="disabled ? 'disabledClick' : 'null'">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">
                <div class="bluheader booking-sumary redbar">
                  <h3 class="h3_blu_header bookingsummary">Payment</h3>
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-four onee pay"
                style="padding-left: 1px;height: 250px; overflow:inherit"
                [ngStyle]="{'overflow': (areFieldsInvalid() && isSubmittedOnce) ? 'scroll' : 'inherit'}">
                <div class="greyBox" style="margin-bottom: 3px;">
                  <div class="modal-bar-2 nine">
                    <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt="" class="placeholder-img-modal">
                    <input type="text" class="form-control" value="" placeholder="Cardholder Name"
                      formControlName="payee_name"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                  </div>
                  <div class="modal-bar-2 ten">
                    <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt="" class="placeholder-img-modal">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Card Type " style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px"
                      formControlName="guest_card_type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                      <ng-option value="Discover">Discover</ng-option>
                      <ng-option value="AX">American Express</ng-option>
                      <ng-option value="CB">Carte Blanche</ng-option>
                      <ng-option value="DN">Diners Club</ng-option>
                      <ng-option value="DS">Discover Card</ng-option>
                      <ng-option value="JC">Japan Credit Bureau</ng-option>
                      <ng-option value="MC">MasterCard</ng-option>
                      <ng-option value="UP">China Union Pay</ng-option>
                      <ng-option value="VI">Visa</ng-option>
                    </ng-select>
                  </div>
                  <div class="modal-bar-2 eleven">
                    <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt="" class="placeholder-img-modal">
                    <input type="number" class="form-control" value="" placeholder="Card Number"
                      style="height: 30px;border: none;padding: 10px 0px 10px 45px;color: #00205b;font-size: 14px"
                      maxlength="16" formControlName="guest_card">
                  </div>
                  <div class="col-md-6 zero-padding float-L" style="padding-right: 2px">
                    <div class="modal-bar-2 twelve">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal">
                      <input class="form-control" name=x size=5 maxlength=5
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        placeholder="MM / YYYY" formControlName="guest_card_expiry">
                    </div>
                  </div>
                  <div class="col-md-6 zero-padding float-R" style="padding-left: 3px">
                    <div class="modal-bar-2 thirteen">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal">
                      <input type="text" class="form-control" value="" placeholder="CVV"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                        pattern="/^\d+$/" formControlName="guest_card_series_code">
                    </div>
                  </div>
                  <div class="modal-bar-2 fourteen uii">
                    <img src="assets/img/newstaticicons/inventory/channel.svg" alt=""
                      class="placeholder-img-modal channel-img">
                    <ng-select aria-label="Channel" class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Channel" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" formControlName="channel_id"
                      style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px">
                      <ng-option *ngFor="let channel of activeChannels"
                        [value]="channel.id">{{channel.name}}</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf=" reservationForm.get('channel_id').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 fifteen uii">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Payment Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      formControlName="payment_status" (change)="changePaymentStatus($event)"
                      style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px">
                      <ng-option value="paid">Paid</ng-option>
                      <ng-option value="unpaid">Due</ng-option>
                      <ng-option value="Deposite">Deposit</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf=" reservationForm.get('payment_status').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 sixteen uii bk">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select *ngIf="reservationOptions.isEdit===true"
                      class="form-control input_2 padding-holder custom-0 input-custom input-c"
                      placeholder="Booking Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      formControlName="status" style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px">
                      <ng-option *ngFor="let status of bookingStatuses"
                        [value]="status.value">{{status.name}}</ng-option>
                    </ng-select>
                    <ng-select *ngIf="reservationOptions.isEdit===false"
                      class="form-control input_2 padding-holder custom-0 input-custom input-c"
                      placeholder="Booking Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      formControlName="status" style=" height: 30px;border:none ;padding-left: 30px;padding-right: 7px">
                      <ng-option value="confirm">Confirmed</ng-option>
                      <ng-option value="Hold">Hold</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf=" reservationForm.get('status').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 zero-padding float-L L4" [ngClass]="disabled ? 'disabledClick' : 'null'">
              <div class="col-md-12 zero-padding" style="padding-right: 2px">
                <div class="bluheader booking-sumary redbar" style="padding-right: 10px; margin-top: 5px;">
                  <h3 style="display: inline-block;" class="h3_blu_header bookingsummary">Who's Checking In
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'"
                      *ngIf="!reservationOptions.isEdit">
                      <div class="tooltip" style="width:63% !important; top: -32px !important;">If the guest
                        checking in is not the same as payee: Guest will be required to give
                        credit card information for In hotel expenses
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" *ngIf="!reservationOptions.isEdit" class="applyAll2"
                    style="top: 27% ; right: 11% ;" (click)="BookedByInfo($event)" id="updateBookedByInfo-checkbox">
                  <span>
                    <span class="bookingCount" *ngIf="reservationOptions.isEdit">
                      {{reservationForm['controls'].booking_items['controls'].length}}
                    </span>
                    <img title="Add Room" src="assets/img/communications/plus-4.png" alt="" class="img-plus"
                      (click)="addNewBookingItem()" *ngIf="!disabled">
                  </span>
                </div>
              </div>
              <!-- <div class="scrollo"> -->
              <div class="col-md-12 zero-padding col-12-two oneeee romm" formArrayName="booking_items"
                [ngStyle]="{'padding-right':'2px','padding-left': '1px','overflow':'scroll','max-height':'290px'}">
                <div class="collapsableDiv colps"
                  *ngFor="let rooms of reservationForm['controls'].booking_items['controls']; let i = index">
                  <div [formGroupName]="i">
                    <label class="label-mr">
                      {{ getRoomNameByIndex(rooms,i) || 'Room'}}
                      <i class="fa fa-trash-alt" (click)="removeExtraRoom(i)"
                        *ngIf="i != 0 && (!reservationOptions.isEdit)"></i>
                      <img
                        src="{{darkMode ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg':'assets/img/reports/button_down.svg'}}"
                        alt="dropdown" class="collapseimg" data-toggle="collapse" id="roomDropDown{{i}}"
                        [attr.data-target]="'#roomCardCollapse'+i" (click)="rotateDrop('roomDropDown'+i)">
                    </label>
                    <div>
                      <div id="roomCardCollapse{{i}}" class="w-100" [ngClass]="i == 0 ? 'collapse in' : 'collapse'">
                        <hr style="margin: 0px;margin-bottom: 5px;margin-top: 5px;">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="modal-bar-2 seventeen">
                              <i class="fa fa-users" style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                              <input type="text" class="form-control" placeholder="First Name"
                                formControlName="guest_first_name"
                                style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                autocomplete="off">
                            </div>
                            <div *ngIf="rooms.get('guest_first_name').hasError('required') && isSubmittedOnce"
                              class="small text-danger no-margin invalid-feedback">
                              <strong>*Required</strong>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="modal-bar-2 eighteen">
                              <i class="fa fa-users" style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                              <input type="text" class="form-control" placeholder="Last Name"
                                formControlName="guest_last_name"
                                style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                autocomplete="off">
                              <div style="display: flex; cursor:pointer">
                                <i class="fa fa-plus" style="padding-left:4px; color: #00205b; padding-right: 10px;"
                                  (click)="addExtraPerson(i)"></i>
                              </div>
                            </div>
                            <div *ngIf="rooms.get('guest_last_name').hasError('required') && isSubmittedOnce"
                              class="small text-danger no-margin invalid-feedback">
                              <strong>*Required</strong>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="reservationForm.get('sperateRoomDates').value">
                          <div class="modal-bar-2 nineteen">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                              style="max-height: 30px;border: none;padding-left: 0px">
                              <span class="input-group-addon"
                                style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                <img
                                  src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'}}"
                                  style="margin-left: 6px;height: 24px;" alt="">
                              </span>
                              <input type="hidden" name="date_to">
                              <input type="hidden" name="date_from">
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="{{ current_arrival?.date_booked }} Check In"
                                [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                (change)="onBookingItemDateChange($event,i,true)"
                                [value]="formatDateForPicker(rooms.get('check_in').value)">
                            </div>
                          </div>
                          <div class="modal-bar-2 twenty">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                              style="max-height: 30px;border: none;padding-left: 0px">
                              <span class="input-group-addon"
                                style=" background: transparent; border: none;font-size: 24px;color: #00205b; display: inline;padding-right: 1px">
                                <img
                                  src="assets/img/newstaticicons/{{darkMode ? 'inventory/forchk/check-out.svg':'dashboard/departure.svg'}}"
                                  style="margin-left: 6px; height: 24px;" alt="">
                              </span>
                              <input type="hidden" name="date_to">
                              <input type="hidden" name="date_from">
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="Check Out"
                                [locale]="{format: 'DD MMM YYYY', separator: ' To '}" [singleDatePicker]="true"
                                (change)="onBookingItemDateChange($event,i,false)"
                                [value]="formatDateForPicker(rooms.get('check_out').value)">
                            </div>
                          </div>
                        </ng-container>
                        <div class="modal-bar-2 twenty1 Pnone">
                          <img src="assets/img/dashboard/in-house2.svg" alt=""
                            style="height: 22px; width: 30px; margin-top: -2px; margin-left: -3px;"
                            class="placeholder-img-modal" />
                          <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Guest Status"
                            formControlName="guest_status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                            style="height: 30px;border: none;padding-left: 23px; padding-right: 7px;">
                            <ng-option *ngFor="let guestStatus of guestStatuses"
                              [value]="guestStatus.value">{{guestStatus.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div *ngIf="rooms.get('guest_status').hasError('required') && isSubmittedOnce"
                          class="small text-danger no-margin invalid-feedback">
                          <strong>*Required</strong>
                        </div>
                        <!-- guest status checked in -->
                        <div class="guestCheckedIncard" *ngIf="rooms.get('guest_status').value == 'Checked In'">
                          <div class="modal-bar-2 nine">
                            <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" value="" placeholder="Cardholder Name"
                              formControlName="payee_namee"
                              style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                          </div>
                          <div class="modal-bar-2 ten">
                            <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt=""
                              class="placeholder-img-modal">
                            <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                              placeholder="Card Type "
                              style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px"
                              formControlName="guest_card_typee" [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                              <ng-option value="Discover">Discover</ng-option>
                              <ng-option value="AX">American Express</ng-option>
                              <ng-option value="CB">Carte Blanche</ng-option>
                              <ng-option value="DN">Diners Club</ng-option>
                              <ng-option value="DS">Discover Card</ng-option>
                              <ng-option value="JC">Japan Credit Bureau</ng-option>
                              <ng-option value="MC">MasterCard</ng-option>
                              <ng-option value="UP">China Union Pay</ng-option>
                              <ng-option value="VI">Visa</ng-option>
                            </ng-select>
                          </div>
                          <div class="modal-bar-2 eleven">
                            <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                              class="placeholder-img-modal">
                            <input type="text" class="form-control" value="" placeholder="Card Number"
                              style="height: 30px;border: none;padding: 10px 0px 10px 45px;color: #00205b;font-size: 14px"
                              maxlength="16" formControlName="guest_cardd">
                          </div>
                          <div class="col-md-6 zero-padding float-L" style="padding-right: 2px">
                            <div class="modal-bar-2 twelve">
                              <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                                class="placeholder-img-modal">
                              <input class="form-control" name=x size=5 maxlength=5
                                style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                placeholder="MM / YYYY" formControlName="guest_card_expiryy">
                            </div>
                          </div>
                          <div class="col-md-6 zero-padding float-R" style="padding-left: 3px">
                            <div class="modal-bar-2 thirteen">
                              <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                                class="placeholder-img-modal">
                              <input type="number" class="form-control" value="" placeholder="CVV"
                                style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px" formControlName="guest_card_series_codee">
                            </div>
                          </div>
                          <div class="modal-bar-2 eighteen uii">
                            <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                              class="placeholder-img-modal" style="height: 20px;">
                            <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                              placeholder="ID Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                              formControlName="id_type"
                              style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px">
                              <ng-option value="State ID">State ID</ng-option>
                              <ng-option value="Driving License">Driving License</ng-option>
                              <ng-option value="Passport">Passport</ng-option>
                            </ng-select>
                          </div>
                          <div class="modal-bar-2 nineteen">
                            <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                              class="placeholder-img-modal id-type" style="height: 20px;">
                            <input type="text" class="form-control" value="" placeholder="ID Number"
                              formControlName="id_value"
                              style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                              autocomplete="off">
                          </div>
                          <div class="modal-bar-2 fourteen">
                            <div class="my-icon-0" style="padding-top: 3px;">
                              <img class="placeholder-img-modal"
                                src="assets/img/newstaticicons/inventory/r-call-red.svg" alt="">
                            </div>
                            <div class="text-block-18 text calander caljj"
                              style="float: left;    padding-top: 4px;width:90%">
                              <input type="number" class="form-control" value="" placeholder="Cell No"
                                formControlName="phone_number"
                                style="height: 30px;border: none;padding: 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                                autocomplete="off">
                            </div>
                          </div>
                          <div class="modal-bar-2 fifteen">
                            <div class="my-icon-0" style="padding-top: 4px;">
                              <img class="placeholder-img-modal"
                                src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                                alt="">
                            </div>
                            <div class="text-block-18 text calander caljj"
                              style="float: left; padding-top: 4px;width:90%">
                              <input type="email" class="form-control" value="" placeholder="Email"
                                formControlName="email"
                                style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                                autocomplete="off">
                            </div>
                          </div>
                        </div>
                        <!-- guest status checked in -->
                        <div class="modal-bar-2 twenty2 filterAccec">
                          <div class="pointer">
                            <img class="img-responsive hovimg"
                              src="assets/img/newstaticicons/back-office/{{getAmmenityValue(i,'smoking') == true ? 'smoke.svg' : 'nosmoke.svg' }}"
                              style="height:35px;margin-top:6px;" (click)="setAmmenityValue(i,'smoking')">
                          </div>
                          <div class="pointer">
                            <img class="img-responsive hovimg"
                              src="assets/img/newstaticicons/back-office/{{getAmmenityValue(i,'accessibility') == true ? 'wheel.svg' : 'nowheel.svg'}}"
                              style="height:35px;margin-top:6px;" (click)="setAmmenityValue(i,'accessibility')">
                          </div>
                          <div class="pointer">
                            <img class="img-responsive hovimg"
                              src="assets/img/newstaticicons/back-office/{{getAmmenityValue(i,'pets') == true ? 'pets.svg' : 'nopets.svg'}}"
                              style="height:35px;margin-top:6px;" (click)="setAmmenityValue(i,'pets')">
                          </div>
                        </div>
                        <div class="modal-bar-2 twenty3">
                          <img
                            src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/room-id-infinity1dark':'forchk/room-id-infinity1'}}.svg"
                            alt="" class="placeholder-img-modal-1"
                            [ngClass]="darkMode ? 'dark-room-Id' : 'light-room-Id'">
                          <ng-select class="form-control input_2 padding-holder custom-0" aria-label="Room Type"
                            placeholder="Room Type"
                            style=" height: 30px;border:none ;padding-left: 18px;padding-right: 7px"
                            [ngClass]="darkMode ? 'bg_back' : 'bg_white'" formControlName="room_id">
                            <ng-option *ngFor="let roomType of reservationOptions?.filteredRoomTypes?.get(i)"
                              [value]="roomType.room_id">{{roomType.name}}</ng-option>
                          </ng-select>
                        </div>
                        <div *ngIf="rooms.get('room_id').hasError('required') && isSubmittedOnce"
                          class="small text-danger no-margin invalid-feedback">
                          <strong>*Required</strong>
                        </div>
                        <div class="modal-bar-2 twenty4">
                          <img
                            src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/room_number.svg':'/bed2.svg'}}"
                            alt="" style=" height: 22px;width: 30px;margin-top: 1px;margin-left: -1px!important;"
                            class="placeholder-img-modal">
                          <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Room No"
                            formControlName="room_no_id"
                            style=" height: 30px;border:none ;padding-left: 26px;padding-right: 7px"
                            [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                            <ng-option *ngFor="let roomNum of getRoomNumberByBookingItemIndex(i)"
                              [value]="roomNum.id">{{roomNum.room_number}}</ng-option>
                          </ng-select>
                        </div>
                        <div *ngIf="rooms.get('room_no_id').hasError('required') && isSubmittedOnce"
                          class="small text-danger no-margin invalid-feedback">
                          <strong>*Required</strong>
                        </div>
                        <div class="row">
                          <div class="col-md-8 zero-padding" style="padding-left: 15px; padding-right: 5px;">
                            <div class="modal-bar-2 twenty5" style="margin-bottom: 5px">
                              <img src="assets/img/newstaticicons/inventory/forchk/package.svg" alt=""
                                style="height: 26px; width: 24px;" class="placeholder-img-modal imggg">
                              <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="Promotion Name"
                                formControlName="package_id" value=""
                                style=" height: 30px;border:none ;padding-left: 25px;padding-right: 7px">
                                <ng-option *ngFor="let promotion of reservationOptions.promotions"
                                  [value]="promotion.id">{{promotion.package_name}}</ng-option>
                              </ng-select>
                            </div>
                          </div>
                          <div class="col-md-4 pl-0">
                            <div class="modal-bar-2 twenty6 pr-4 pt-1 justify-content-center"><img alt="addons-icon"
                                class="h-auto placeholder-img-modal imggg"
                                src="assets/img/newstaticicons/arrivals/addon-icon.svg"
                                style="width: 35px; cursor: pointer;" (click)="openAddOnsModal(i)"></div>
                          </div>
                        </div>
                        <div class="row rom-r" style="padding: 0px 15px;">
                          <div class="col-md-4 zero-padding" style="padding-right: 2px">
                            <div class="modal-bar-2 twenty7">
                              <img
                                src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/adult-dark.svg' : '/forchk/adult0.svg'}}"
                                alt="" style="height: 20px;" class="placeholder-img-modal">
                              <input type="number" min="0" class="form-control inp adultPadding" placeholder="Adult"
                                formControlName="no_of_adults" autocomplete="off">
                            </div>
                            <div
                              *ngIf="rooms.get('no_of_adults').hasError('limitExceeds') || (rooms.get('no_of_adults').hasError('required') && isSubmittedOnce)"
                              class="small text-danger no-margin invalid-feedback">
                              <strong>*Required</strong>
                            </div>
                          </div>
                          <div class="col-md-4 zero-padding" style="padding-left: 3px">
                            <div class="modal-bar-2 twenty7">
                              <img
                                src="assets/img/newstaticicons/inventory{{darkMode ? '/forchk/child-infinity0':'/child'}}.svg"
                                alt="" style="height: 20px;" class="placeholder-img-modal">
                              <input type="number" min="0" formControlName="no_of_childs"
                                class="form-control inp childPadding" placeholder="Children" autocomplete="off">
                            </div>
                            <div
                              *ngIf="rooms.get('no_of_childs').hasError('limitExceeds') || (rooms.get('no_of_childs').hasError('required') && isSubmittedOnce)"
                              class="small text-danger no-margin invalid-feedback">
                              <strong>*Required</strong>
                            </div>
                          </div>
                          <div class="col-md-4 zero-padding" style="padding-left: 4px;">
                            <div class="modal-bar-2 twenty7">
                              <img src="assets/img/newstaticicons/inventory/{{darkMode ? 'infant-yellow':'infant'}}.svg"
                                alt="" style="height: 20px;" class="placeholder-img-modal">
                              <input type="number" min="0" class="form-control inp infantsPadding" placeholder="Infant"
                                formControlName="no_of_infants" autocomplete="off">
                            </div>
                            <div
                              *ngIf="rooms.get('no_of_infants').hasError('limitExceeds') || (rooms.get('no_of_infants').hasError('required') && isSubmittedOnce)"
                              class="small text-danger no-margin invalid-feedback">
                              <strong>*Required</strong>
                            </div>
                          </div>
                        </div>
                        <!-- extras person info -->
                        <div formArrayName="extra_persons">
                          <div
                            *ngFor="let extraPerson of reservationForm['controls'].booking_items['controls'][i]['controls']['extra_persons']['controls']; let epIndex = index ">
                            <div class="row" [formGroupName]="epIndex" style="padding: 0px 20px;">
                              <div class="modal-bar-2 twenty8">
                                <i class="fa fa-users" style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                <input type="text" class="form-control" placeholder="Guest Name"
                                  style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                  formControlName="name">
                                <div style="display: flex; cursor:pointer" (click)="removeExtraPerson(i,epIndex)">
                                  <i class="fa fa-times"
                                    style="padding-left:4px; color: #a00; padding-right: 10px; "></i>
                                </div>
                              </div>
                              <div class="modal-bar-2 twenty9 uii">
                                <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                  class="placeholder-img-modal" style="height: 20px;">
                                <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                  placeholder="ID Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                  style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                                  formControlName="id_type">
                                  <ng-option value="State ID">State ID</ng-option>
                                  <ng-option value="Driving License">Driving License </ng-option>
                                  <ng-option value="Passport">Passport</ng-option>
                                </ng-select>
                              </div>
                              <div class="modal-bar-2 thirteen">
                                <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                                  class="placeholder-img-modal id-type" style="height: 20px;">
                                <input type="text" class="form-control" value="" placeholder="ID Number"
                                  formControlName="id_number"
                                  style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                  autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ? addOns modal section start (working on saud 😊) -->
              <ng-container *ngFor="let rooms of reservationForm['controls'].booking_items['controls']; let i = index">
                <div class="rightD addOns-sideTray" id="addOnsDetail" [ngClass]="darkMode ? 'dark' : 'light'"
                  *ngIf="addOnsModal[i]" cdkDrag>
                  <div class="bluheader booking-sumary redbar mt-2">
                    <h3 class="h3_blu_header bookingsummary">
                      {{(rooms.get('guest_first_name').value + " " + rooms.get('guest_last_name').value) || 'Guest Name'}}
                    </h3>
                    <button type="button" class="close" (click)="addOnsModal[i] = false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="bluheader booking-sumary mb-0">
                    <h3 class="h3_blu_header bookingsummary">
                      {{ getRoomNameByIndex(rooms,i) }}
                    </h3>
                    <h3 class="h3_blu_header bookingsummary">
                      {{ getRoomNumberByIndex(rooms,i) }}
                    </h3>
                  </div>
                  <div class="addons-section">
                    <ng-container *ngFor="let addOn of addOnsDisplayMap.get(i); index as j">
                      <div class="modal-bar-2 mt-2" [ngStyle]="{'border-color': WHcolors[j % WHcolors.length]}">
                        <div class="d-flex w-100 justify-content-between pr-2">
                          <div class="addon-items d-flex w-100 justify-content-between align-items-center pr-3">
                            <span class="text-left">{{ addOn.name }}</span>
                            <span>
                              <button class="btn-add" type="button">
                                <i class="fa fa-minus pr-1" (click)="updateCount(addOn, -1, i)"></i>
                                {{getBookingExtraQuantity(addOn, i)}}
                                <i class="fa fa-plus pl-1" (click)="updateCount(addOn, 1, i)"></i>
                              </button>
                            </span>
                            <span>{{ currencySymbol }}{{ (addOn.price_after_tax * getBookingExtraQuantity(addOn, i)) |
                              number:'1.2-3' }}</span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#ff6f00'}">
                    <div class="d-flex align-items-center">
                      <img src="assets/img/newstaticicons/arrivals/tax-icon.svg" alt="" class="placeholder-img-modal"
                        style="height: 20px;">
                    </div>
                    <h3 class="h3_blu_header bookingsummary text-dark">
                      {{taxper}}%
                    </h3>
                  </div>
                  <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#00a8ff'}">
                    <div class="d-flex align-items-center">
                      <img src="assets/img/newstaticicons/arrivals/sigma-icon.svg" alt="" class="placeholder-img-modal"
                        style="height: 20px;">
                    </div>
                    <h3 class="h3_blu_header bookingsummary text-dark">
                      {{ currencySymbol }}{{calculateAddonsPriceByRoomIndex(i) | number:'1.2-3'}}
                    </h3>
                  </div>
                </div>
              </ng-container>
              <!-- ? addOns modal section end (working on saud 😊) -->
              <!-- </div> -->
            </div>
            <div class="col-md-6 zero-padding float-R R4" [ngClass]="disabled ? 'disabledClick' : 'null'">
              <div class="col-md-12 zero-padding" style="padding-left: 0px">
                <div class="bluheader booking-sumary redbar " style=" margin-top: 5px;">
                  <h3 class="h3_blu_header bookingsummary">Booked By
                    <i class="fa fa-info-circle checkInfo" [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'"
                      *ngIf="!reservationOptions.isEdit">
                      <div class="tooltip" style="width:68% !important; top: -29px;"> Check if the room
                        payee is same as guest payment information will be auto polpulated
                      </div>
                    </i>
                  </h3>
                  <input type="checkbox" *ngIf="!reservationOptions.isEdit" class="applyAll2"
                    style="top: 27% !important;" id="checkedForSep" (click)="guestInfo($event)">
                </div>
              </div>
              <div class="col-md-12 zero-padding col-12-three onee bk-by"
                style="padding-left: 1px;padding-right: 0px; overflow: inherit; height: 285px;"
                [ngStyle]="{'overflow': shouldShowScroll() ? 'scroll' : 'inherit'}">
                <div class="greyBox g-b">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="modal-bar-2 twelve">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                          alt="" class="placeholder-img-modal" style="height: 20px;">
                        <input type="text" class="form-control" value="" placeholder="First Name"
                          formControlName="guest_firstname"
                          style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                          autocomplete="off">
                      </div>
                      <div *ngIf="this.reservationForm.get('guest_firstname').hasError('required') && isSubmittedOnce"
                        class="small text-danger no-margin invalid-feedback">
                        <strong>*Required</strong>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="modal-bar-2 thirteen">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/full-name-infinity0':'/forchk/guest-name0'}}.svg"
                          alt="" class="placeholder-img-modal" style="height: 20px;">
                        <input type="text" class="form-control" value="" placeholder="Last Name"
                          formControlName="guest_lastname"
                          style="height: 30px;border: none;padding: 10px 35px;;color: #00205b;font-size: 14px"
                          autocomplete="off">
                      </div>
                      <div *ngIf="this.reservationForm.get('guest_lastname').hasError('required') && isSubmittedOnce"
                        class="small text-danger no-margin invalid-feedback">
                        <strong>*Required</strong>
                      </div>
                    </div>
                  </div>
                  <div class="modal-bar-2 fourteen">
                    <div class="my-icon-0" style="padding-top: 3px;">
                      <img class="placeholder-img-modal" src="assets/img/newstaticicons/inventory/r-call-red.svg"
                        alt="">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <input type="number" class="form-control" value="" placeholder="Cell No"
                        formControlName="guest_phone"
                        style="height: 30px;border: none;padding: 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        autocomplete="off">
                    </div>
                  </div>
                  <div class="modal-bar-2 fifteen">
                    <div class="my-icon-0" style="padding-top: 4px;">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory/{{darkMode?'forchk/mail-infinity0':'/r-messege-red'}}.svg"
                        alt="">
                    </div>
                    <div class="text-block-18 text calander caljj" style="float: left; padding-top: 4px;width:90%">
                      <input type="email" class="form-control" value="" placeholder="Email"
                        formControlName="guest_email"
                        style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        autocomplete="off">
                    </div>
                  </div>
                  <div class="modal-bar-2 sixteen">
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                        style="height:20px" alt="">
                      <input type="text" class="form-control" value="" placeholder="Address"
                        formControlName="guest_address"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        autocomplete="off">
                    </div>
                  </div>
                  <div class="modal-bar-2 seventeen">
                    <div class="text-block-18 text calander caljj" style="float: left;    padding-top: 4px;width:90%">
                      <img class="placeholder-img-modal"
                        src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/location.svg':'/location.svg'}}"
                        style="height:20px" alt="">
                      <input type="text" class="form-control" value="" placeholder="City" formControlName="guest_city"
                        style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px;margin-top: -4px;"
                        autocomplete="off">
                    </div>
                  </div>
                  <div class="modal-bar-2 eighteen uii">
                    <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="ID Type"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'" formControlName="personal_id_type"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px">
                      <ng-option value="State ID">State ID</ng-option>
                      <ng-option value="Driving License">Driving License</ng-option>
                      <ng-option value="Passport">Passport</ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="this.reservationForm.get('personal_id_type').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 nineteen">
                    <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                      class="placeholder-img-modal id-type" style="height: 20px;">
                    <input type="text" class="form-control" value="" placeholder="ID Number"
                      formControlName="personal_id_value"
                      style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                      autocomplete="off">
                  </div>
                  <div *ngIf="this.reservationForm.get('personal_id_value').hasError('required') && isSubmittedOnce"
                    class="small text-danger no-margin invalid-feedback">
                    <strong>*Required</strong>
                  </div>
                  <div class="modal-bar-2 twenty mb-0">
                    <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt="" class="placeholder-img-modal"
                      style="height: 20px;">
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Priviliges" formControlName="privilege" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                      style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px">
                      <ng-option value="Loyalty Card Holder">Loyality Card Holder</ng-option>
                      <ng-option value="Discount Offers">Discount Offers</ng-option>
                      <ng-option value="None">None</ng-option>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightD" style="right: 0px; z-index: -1;" id="sideTrayReservation"
            [ngClass]="darkMode ? 'dark':'light'">
            <div class="overFlow-scroll">
              <div class="dFlex dataPrevDiv" *ngFor="let data of perDayData">
                <div class="data">
                  <div>
                    <div class="bluheader booking-sumary mb-2" style="cursor: grab;margin-bottom: 0px;margin-top: 5px;">
                      <h3 class="h3_blu_header bookingsummary">{{data.display_name || data.roomName}}</h3>
                    </div>
                    <div class="scroll_rate">
                      <div class="modal-bar-2 twenty57 dFlex rateDive s-tray" *ngFor="let rateDiv of data.rates">
                        <div>{{rateDiv.date | date:'EEE dd MMM'}}</div>
                        <div [ngClass]="darkMode ? 'darkDD' : 'null'">{{currencySymbol}} {{rateDiv.price |
                          number:'1.2-3'}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="totalDiscount" [ngClass]="disabled ? 'disabledClick' : 'null'">
                  <div>
                    <div [ngClass]="darkMode ? 'DarkDic' : 'null'">
                      <div class="modal-bar-2 twenty60" style="  height: auto; padding: 0px;"
                        [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                        <textarea name="" id="" cols="30" rows="5" placeholder="Special requests"
                          formControlName="SpecialRequests"></textarea>
                      </div>
                      <div class="modal-bar-2 one" id="extraPerson">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/addpersonicon1.svg':'/addpersonicon2.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="text" min="0" class="form-control" value="" placeholder="Extra Person Fees"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                          formControlName="extra_person_fee" readonly>
                      </div>
                      <div class="modal-bar-2 two">
                        <img src="assets/img/newstaticicons/arrivals{{
                        darkMode ? '/Add-Ons.svg' : '/Add-Ons.svg'
                      }}" alt="Add-Ons" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" placeholder="Add-Ons"
                          style="height: 30px; border: none;padding: 10px 0px 10px 35px; color: #00205b;font-size: 14px;"
                          value="{{currencySymbol}}{{calculateAddOnsPrice() | number:'1.2-3'}}" readonly />
                      </div>
                      <div class="modal-bar-2 three">
                        <img src="assets/img/newstaticicons/arrivals/sigma-icon.svg" alt="" style="height: 20px;"
                          class="placeholder-img-modal">
                        <input type="text" min="0" class="form-control" [value]="getSubTotal()" readonly
                          placeholder="Sub total"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                      </div>
                      <div class="modal-bar-2 four dFlex segmo">
                        <label [ngClass]="property?.taxInclusive == 'true' ? 'active' : 'null'">Tax
                          Inclusive</label>
                        <label [ngClass]="property?.taxInclusive == 'false' ? 'active' : 'null'">Tax
                          Exclusive</label>
                        <div class="segmon" id="segmon"
                          [ngStyle]="{'left': property?.taxInclusive == 'true' ? '3%' : '53%'}">
                        </div>
                      </div>
                      <div class="modal-bar-2 five">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode?'/forchk/discount.svg':'/discount.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <div style="display: flex; justify-content: space-between; width: 100%;">
                          <input type="text" class="form-control" placeholder="Discount"
                            style="width:55%;height: 30px;border: none;padding: 10px 0px 10px 40px;color: #00205b;font-size: 14px"
                            formControlName="discount" />
                          <div
                            style="display:flex;align-items: center;border-left: 2px solid #80808061;height: 20px; margin-top: 5px;padding-left: 5px; position: relative;">
                            <span class="currency-symbol">{{currencySymbol}}</span>
                            <input type="text" class="form-control" placeholder="Amount"
                              style="height: 30px;border: none;padding: 10px 0px 10px 10px;color: #00205b;font-size: 13px"
                              formControlName="discount_amount" (input)="updateTotals('discountAmount')"
                              (focus)="discountAmount($event)" (blur)="discountAmount($event)" />
                          </div>
                        </div>
                      </div>
                      <div class="modal-bar-2 six" style="padding-left: 5px; position: relative;">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/amount-deposit.svg':'/amount-deposit.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <span class="currency-symbol" *ngIf="reservationForm.get('cashDeposit').value"
                          style="left: 19%;">{{currencySymbol}}</span>
                        <input type="text" min="0" class="form-control" value="" placeholder="Cash Deposit"
                          formControlName="cashDeposit" (blur)="changeCurrencyFormat($event, true)"
                          style="height: 30px;border: none;padding: 9px 0px 10px 35px;color: #00205b;font-size: 14px"
                          [ngStyle]="{'padding-left': reservationForm.get('cashDeposit').value ? '23%': '35px'}">
                      </div>
                      <div class="modal-bar-2 seven">
                        <img src="assets/img/newstaticicons/inventory{{darkMode ? '/tax-icon.svg':'/tax-icon.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="text" min="0" class="form-control"
                          value="{{currencySymbol}}{{reservationForm.get('Tax').value}}"
                          style="height: 30px;border: none;padding: 10px 0px 10px 32px;color: #00205b;font-size: 14px"
                          readonly>
                        <span class="taxPercentage">
                          {{taxper}}
                          <i class="fa fa-percent"></i>
                        </span>
                      </div>
                      <div class="modal-bar-2 eight mb-0">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode_arrival/dollar.svg':'/dollar.svg'}}"
                          alt="" style="height: 20px;" class="placeholder-img-modal">
                        <input type="text" class="form-control" value="{{getTotal()}}" placeholder="Total Amount"
                          style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="roundCurve" (click)="sideMenu('sideTrayReservation')">
                  <i class="fa fa-caret-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
          <div class="popupBtns">
            <button class="btn btn-block send-0" id="btnEdit" type="button" *ngIf="disabled"
              (click)="disabled = false">Edit</button>
            <button class="btn btn-block send-0" id="btnSave" type="submit" *ngIf="!disabled">Save</button>
            <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close" type="reset"
              (click)="resetForm(reservationForm)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Reservation Pupop END-->