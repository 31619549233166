<div class="d-flex flex-column" style="min-height: 407px" [ngClass]="darkMode ? 'opp_card_dark' : 'null'">
    <!-- Legend -->
    <div class="d-flex border-bottom-gray flex-row justify-content-around align-items-center" style="height:75px">
        <div *ngFor="let legend of legendArray" class="d-flex flex-column align-items-center flex-gap-2">
            <label>{{legend.label}}</label>
            <span class="legend-indicator" [ngStyle]="{'background-color':legend.color}"></span>
        </div>
    </div>

    <!-- Data -->
    <div class="d-flex flex-row align-items-center" style="flex: 1 1 auto">
        <div style="min-width:40%" class="d-flex flex-column flex-gap-4  vr">
            <div *ngFor="let row of monthRowArray" class="month-row d-flex flex-inline flex-gap-2"
                style="height: 3rem;">
                <div class="month-label" style="line-height: 35px; width:2.5rem;">{{row.month}}</div>
                <div style="flex: 1 1 auto;" class="d-flex">
                    <div [ngStyle]="{'width':row.countPercentage}"
                        style="line-height:30px; background-color:#ec1361; height: 100%; border-top-left-radius: 10px; border-bottom-left-radius: 10px; text-align: center; color:white;">
                        {{row.count}}
                    </div>
                    <div [ngStyle]="{'width':row.costPercentage}"
                        style="line-height:30px; background-color:#0179f1; height: 100%; border-top-right-radius: 10px; border-bottom-right-radius: 10px; text-align: center; color:white;">
                        {{row.cost}}
                    </div>
                </div>
            </div>
        </div>
        <div style="min-width:60%">
            <canvas id="stacked-bar"></canvas>
        </div>
    </div>

</div>