import { Component, EventEmitter, OnInit, Output } from '@angular/core';
// import { DateAdapter } from 'chart.js';
import * as moment from 'moment';
// import { parse } from 'querystring';

@Component({
  selector: 'app-pnl-breadcrum',
  templateUrl: './pnl-breadcrum.component.html',
  styleUrls: ['./pnl-breadcrum.component.css']
})
export class PnlBreadcrumComponent implements OnInit {
  darkMode: boolean = false;

  @Output() myData = new EventEmitter<any>();

  constructor(
    // private adapter: DateAdapter
  ) {
  }
  @Output() dateChanged = new EventEmitter();

  selectedDate = { startDate: moment(), endDate: moment() };

  ngOnInit() {
    this.checkDarkMode();
    let val = JSON.parse(localStorage.getItem('pnl_selected_Date'))
    if (val) {
      this.selectedDate = { startDate: moment(val.startDate, 'YYYY-MM-DD'), endDate: moment(val.endDate, 'YYYY-MM-DD') }
    }
    //this.selectedDate = val || this.selectedDate;
  }

  calendar_change(e) {
    // const selectedDate = this.selected;
    console.log(e, 'calender change')

    if (e.startDate != null && e.endDate != null) {

      let date = { startDate: e.startDate.add(12, 'h').format('YYYY-MM-DD'), endDate: e.endDate.format('YYYY-MM-DD'), }
      // this.selectedDate = e;
      localStorage.setItem('pnl_selected_Date', JSON.stringify(e));
      this.dateChanged.emit();
    }
    // console.log(e);
    // console.log(date)
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
