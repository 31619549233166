<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
  <a href="" class="btn btn-link">BACK OFFICE</a>
  <a href="" class="btn btn-link active">CREATE USERS</a>
</div>

<form class="form-horizontal back-form" [formGroup]="create_user_form" (ngSubmit)="onSubmit()"
  [ngClass]="darkMode ? 'card_dark' : 'card'">

  <!-- <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="col-md-6 pr-5 pr-10">
                            <div class="border-style bcu-1 first-name-shade">
                                <input type="text" class="form-control" placeholder="First Name" formControlName="first_name">
                            </div>
                            <div *ngIf="submitted && f.first_name.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.first_name.errors.required">Required</strong>
                            </div>
                        </div>
                        <div class="col-md-6 pl-5 pl-10">
                            <div class="border-style bcu-2 last-name-shade">
                                <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name">
                            </div>
                            <div *ngIf="submitted && f.last_name.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.last_name.errors.required">Required</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="col-md-6 pr-5 pr-10">
                            <div class="border-style bcu-3 email-shade">
                                <input type="email" class="form-control" placeholder="Enter your Email Address" formControlName="email">
                                <span class="ispanIo">
                  <i class="fas fa-info-circle i" style="cursor: pointer;">
                    <span  class="tooltiptext" style="width: 272px;margin-left: -138px;" >Use cooprate email address for security purpose.</span>
                                </i>
                                </span>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.email.errors.required">Required</strong>
                            </div>
                        </div>
                        <div class="col-md-6 pl-5 pl-10">
                            <div class="border-style bcu-4 contact-shade">
                                <input type="number" class="form-control" placeholder="Enter your Phone number" formControlName="phone" maxlength="10" required>
                            </div>
                            <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.phone.errors.required">Required</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12" style="padding: 0px 20px;">
                        <div class="border-style dark-green-shade dark-green-shade2 " [ngClass]="darkMode ? 'bc-select' : 'null'">
                            <input type="file" name="photo" (change)="onFileChange($event)" id="real-file" style="display:none">
                            <div class="upload-div ">
                                Upload
                            </div>
                            <div id="custom-text">Choose file</div>
                            <button id="custom-button" type="button" [ngClass]="darkMode ? 'bc-select' : 'null'">
                  Browse
                <span   class="ispan ispn">
                  <i   class="fas fa-info-circle i">
                    <span   class="tooltiptext" style=" margin-left: -162px; width: 221px;text-align: left;" >
                      File Size should not be larger than 2MB.
                      <br  >
                      File Extensions: .jpg .png
                    </span>
                  </i>
                </span>
              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="gradient-bar">
        <div class="">
            <h4>Roles & Permissions</h4>
        </div>
    </div> -->

  <!-- <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="col-md-6 pr-5 pr-10">
                            <div class="border-style bcu-8 role-shade">
                                <ng-select [items]="roles" bindLabel="label" bindValue="label" formControlName="role" placeholder="Select Role" [(ngModel)]="role" [ngClass]="darkMode ? 'dark-drop' : 'null'" (change)="customRoles(role)" *ngIf="!customRole">
                                </ng-select>
                                <ng-container *ngIf="customRole">
                                    <input type="text" class="form-control" placeholder="Custom Role" [(ngModel)]="Croles" [ngModelOptions]="{standalone: true}">
                                    <div class="iconsCR">
                                        <i class="fa fa-check" (click)="addCRoles(Croles)"></i> <i class="fa fa-times" (click)="customRole = false"></i>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="submitted && f.role.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.role.errors.required">Required</strong>
                            </div>
                        </div>
                        <div class="col-md-6 pl-5 pl-10">
                            <div class="border-style permission-shade" style="height:40px;">
                                <ng-select [items]="permis" bindLabel="name" bindValue="id" formControlName="permissions" [(ngModel)]="selectedCityIds" (change)="getPermissionArray(selectedCityIds)" form [multiple]="true" placeholder="Permissions" class="perDrop" [ngClass]="darkMode ? 'dark-drop' : 'null'"
                                    [closeOnSelect]="false">
                                </ng-select>
                            </div>
                            <div *ngIf="submitted && f.permissions.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.permissions.errors.required">Required</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

  <!-- <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="col-md-6 pr-5 pr-10">
                            <div class="border-style bcu-10 role-shade">
                                <ng-select [items]="shifts" bindLabel="label" bindValue="label" formControlName="shift" placeholder="Shift" [(ngModel)]="shift" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                            </div>
                            <div *ngIf="submitted && f.shift.errors" class="small text-danger no-margin invalid-feedback"> <strong *ngIf="f.shift.errors.required">Required</strong> </div>
                        </div>
                        <div class="col-md-6 pl-5 pl-10">
                            <div class="border-style bcu-11 permission-shade" style="height: 40px;" [ngClass]="darkMode ? 'bgColor_dark' : 'null'">
                                <label class="control-label permissions" style="margin-left: 10px; padding: 7px 10px 7px 0px; margin-right: 10px">Days</label>
                                <div class="text-center permission-wrapper fs-9">
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="monday" id="monday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Mo</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="tuesday" id="tuesday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Tu</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="wednesday" id="wednesday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">We</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="thursday" id="thursday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Th</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="friday" id="friday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Fr</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="saturday" id="saturday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Sa</span>
                  </label>
                                    <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="sunday" id="sunday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Su</span>
                  </label>
                                </div>
                            </div>
                            <div *ngIf="submitted && f.shift_days.errors" class="small text-danger no-margin invalid-feedback"> <strong *ngIf="f.shift_days.errors.required">Required</strong> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

  <!---------------------------------new create --------------------------------------------------->

  <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style bcu-1 first-name-shade">
                <input type="text" class="form-control" placeholder="First Name" formControlName="first_name">
              </div>
              <div *ngIf="submitted && f.first_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.first_name.errors.required">Required</strong>
              </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style bcu-2 role-shade">
                <ng-select [items]="roles" bindLabel="label" bindValue="label" formControlName="role"
                  placeholder="Select Role" [(ngModel)]="role" [ngClass]="darkMode ? 'dark-drop' : 'null'"
                  (change)="customRoles(role)" *ngIf="!customRole">
                </ng-select>
                <ng-container *ngIf="customRole">
                  <input type="text" class="form-control" placeholder="Custom Role" [(ngModel)]="Croles"
                    [ngModelOptions]="{standalone: true}">
                  <div class="iconsCR">
                    <i class="fa fa-check" (click)="addCRoles(Croles)"></i> <i class="fa fa-times"
                      (click)="customRole = false"></i>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="submitted && f.role.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.role.errors.required">Required</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------------------2nd row------------------------------------------------->

  <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">

            <div class="col-md-6 pr-5 pr-10 ">
              <div class="border-style bcu-3 role-shade">
                <ng-select [items]="id_types" bindLabel="label" bindValue="label" formControlName="id_type"
                  placeholder="Id Type" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                </ng-select>

              </div>
              <div *ngIf="submitted && f.id_type.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.id_type.errors.required">Required</strong>
              </div>
            </div>

            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style bcu-4 first-name-shade">
                <input type="text" class="form-control" placeholder="Id number" formControlName="id_number">
              </div>
              <div *ngIf="submitted && f.id_number.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.id_number.errors.required">Required</strong>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------------------3rd row------------------------------------------------->
  <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style bcu-5 email-shade">
                <input type="email" class="form-control" placeholder="Enter your Email Address" formControlName="email">
                <span class="ispanIo">
                  <i class="fas fa-info-circle i" style="cursor: pointer;">
                    <span class="tooltiptext" style="width: 272px;margin-left: -138px;">Use cooprate email address for
                      security purpose.</span>
                  </i>
                </span>
              </div>
              <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.email.errors.required">Required</strong>
              </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style bcu-6 contact-shade">
                <input type="number" class="form-control" placeholder="Enter your Phone number" formControlName="phone"
                  maxlength="10" required>
              </div>
              <div *ngIf="submitted && f.phone.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.phone.errors.required">Required</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!---------------------------------------4th row------------------------------------------------->

  <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="col-md-6 pr-5 pr-10">
              <div class="border-style bcu-7 role-shade">
                <ng-select [items]="shifts" bindLabel="label" bindValue="label" formControlName="shift"
                  placeholder="Shift" [(ngModel)]="shift" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
              </div>
              <div *ngIf="submitted && f.shift.errors" class="small text-danger no-margin invalid-feedback"> <strong
                  *ngIf="f.shift.errors.required">Required</strong> </div>
            </div>
            <div class="col-md-6 pl-5 pl-10">
              <div class="border-style bcu-8 permission-shade d-flex" style="height: 40px;"
                [ngClass]="darkMode ? 'bgColor_dark' : 'null'">
                <label class="control-label permissions"
                  style="margin-left: 10px; padding: 7px 10px 7px 0px; margin-right: 10px">Days</label>
                <div class="text-center permission-wrapper fs-9">
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="monday" id="monday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Mo</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="tuesday" id="tuesday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Tu</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="wednesday" id="wednesday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">We</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="thursday" id="thursday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Th</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="friday" id="friday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Fr</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="saturday" id="saturday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Sa</span>
                  </label>
                  <label class="control-label checkbox-style">
                    <input type="checkbox" class="checkbox" name="shift_days[]" value="sunday" id="sunday">
                    <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'checkbox-checkmark'"></span>
                    <span class="permission-title-2">Su</span>
                  </label>
                </div>
              </div>
              <div *ngIf="submitted && f.shift_days.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.shift_days.errors.required">Required</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------------------5nd row------------------------------------------------->

  <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <div class="col-md-12" style="padding: 0px 20px;">
            <div class="border-style dark-green-shade2 bcu-9 " [ngClass]="darkMode ? 'bc-select' : 'null'">
              <input type="file" name="photo" (change)="onFileChange($event)" id="real-file" style="display:none">
              <div class="upload-div ">
                Upload
              </div>
              <div id="custom-text">
                <!-- Choose file -->
                <i class="fa fa-camera" aria-hidden="true" style="font-size:18px"></i>
              </div>
              <button id="custom-button" type="button" [ngClass]="darkMode ? 'bc-select' : 'null'">
                Browse
                <span class="ispan ispn">
                  <i class="fas fa-info-circle i">
                    <span class="tooltiptext" style=" margin-left: -162px; width:190px;text-align: left;">
                      File Size should not be larger than 2MB.File Extensions: .jpg .png
                    </span>
                  </i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="gradient-bar">
    <div class="">
      <h4>Permissions</h4>
      <span class="per-s">
        <i class="fas fa-info-circle per-i">
          <span class="tooltiptext" style="width:191px; position: relative;left: 5px;">Assign Permissions to your
            users.</span>
        </i>
      </span>
    </div>
  </div>
  <!----------collapse-scroll-------------------->
  <div class="par-scroll"
    [ngStyle]="submitted && f.shift_days.errors ? {'max-height': '152px'} : {'max-height': '197px'}">
    <!----------Dashboard collapse-------------------->
    <!-- <app-dashboard-permission></app-dashboard-permission> -->
    <!----------housekeeping collapse-------------------->
    <!-- <app-housekeeping-permission></app-housekeeping-permission> -->
    <!----------notification collapse-------------------->
    <!-- <app-notification></app-notification> -->
    <!----------stays collapse-------------------->
    <app-stayspermission></app-stayspermission>
    <!-------------Arrivals collapse----------------->
    <!-- <app-arrivalspermission></app-arrivalspermission> -->
    <!--------------promotion---------------->
    <!-- <app-promo-permission></app-promo-permission> -->
    <!--------------reports---------------->
    <!-- <app-reports-permission></app-reports-permission> -->
    <!--------------cm---------------->
    <!-- <app-cm-permission></app-cm-permission> -->
    <!----------Backoffice collapse-------------------->
    <!-- <app-backoffice-permission></app-backoffice-permission> -->
    <!----------------->
    <!-- <app-guest-data></app-guest-data> -->
    <!----------------->

  </div>
  <!-------parScrollEnd------>

  <div class="modal-footer" style="padding: 0px 20px;">
    <button type="submit" class="btn btn-primary btn-lg hov-btn"
      [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">Save</button>
    <a class="btn btn-danger btn-lg hov-btn" data-dismiss="modal" aria-label="Close"
      [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Close</a>
  </div>
</form>
