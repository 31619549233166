import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuardService } from '../app/services/auth-guard.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { BackOfficeComponent } from './components/back-office/back-office.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TestComponent } from './components/test/test.component';

import { AccountSettingsComponent } from './components/back-office/account-settings/account-settings.component';
import { HotelSetupComponent } from "./components/back-office/hotel-setup/hotel-setup.component";
import { CreateUsersComponent } from "./components/back-office/create-users/create-users.component";
import { ManageUsersComponent } from "./components/back-office/manage-users/manage-users.component";
import { CreateRoomsComponent } from "./components/back-office/create-rooms/create-rooms.component";
import { ManageRoomsComponent } from "./components/back-office/manage-rooms/manage-rooms.component";
import { BillingComponent } from "./components/back-office/billing/billing.component";
import { OnlineHelpComponent } from './components/back-office/online-help/online-help.component';
import { TermsOfUseComponent } from './components/back-office/terms-of-use/terms-of-use.component';
import { InvoiceComponent } from './components/back-office/billing/invoice/invoice.component';
import { ManageHowYouPayComponent } from './components/back-office/billing/manage-how-you-pay/manage-how-you-pay.component';
import { SubscriptionComponent } from './components/back-office/billing/subscription/subscription.component';
import { ContactSupportComponent } from './components/back-office/contact-support/contact-support.component';
import { SelectHotelComponent } from './components/back-office/select-hotel/select-hotel.component';
import { ManageOtasComponent } from './components/manage-otas/manage-otas.component';
import { ManageRatesPackagesComponent } from './components/manage-rates-packages/manage-rates-packages.component';
import { SetupPackageComponent } from './components/manage-rates-packages/setup-package/setup-package.component';
import { SetupRateComponent } from './components/manage-rates-packages/setup-rate/setup-rate.component';
import { SetupRestrictionComponent } from './components/manage-rates-packages/setup-restriction/setup-restriction.component';
import { LinkOTAComponent } from './components/manage-rates-packages/link-ota/link-ota.component';
import { PreviewPackageComponent } from './components/manage-rates-packages/preview-package/preview-package.component';
import { ArrivalsComponent } from './components/arrivals/arrivals.component';
import { HouseKeepingComponent } from './components/house-keeping/house-keeping.component';
import { WithoutHeaderComponent } from './layouts/without-header/without-header.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LogComponent } from './components/log/log.component';
import { CommunicationComponent } from './components/communication/communication.component';
import { EmailDirectoryComponent } from './components/communication/email-directory/email-directory.component';
import { EmailTemplateComponent } from './components/communication/email-template/email-template.component';
import { PreviewRoomsComponent } from './components/back-office/preview-rooms/preview-rooms.component';
import { VendorComponent } from './components/back-office/vendor/vendor.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { PaynowInvoiceComponent } from './components/paynow-invoice/paynow-invoice.component';
import { PreviewHotelComponent } from './components/back-office/preview-hotel/preview-hotel.component';
import { ProfitandlossComponent } from './components/back-office/profitandloss/profitandloss.component';
import { FoodandbeveragesComponent } from './components/back-office/foodandbeverages/foodandbeverages.component';
import { SetupShopComponent } from './components/back-office/setup-shop/setup-shop.component';
import { CreateUsersFnbComponent } from './components/back-office/create-users-fnb/create-users-fnb.component';
import { CreateMenuComponent } from './components/back-office/create-menu/create-menu.component';
import { ManageMenuComponent } from './components/back-office/manage-menu/manage-menu.component';
import { ManageShopComponent } from './components/back-office/manage-shop/manage-shop.component';
import { ListItemComponent } from './components/back-office/list-item/list-item.component';
import { SoldItemComponent } from './components/back-office/sold-item/sold-item.component';
import { FinanceComponent } from './components/back-office/finance/finance.component';
import { ExpensesComponent } from './components/back-office/expenses/expenses.component';
import { RoomserviceComponent } from './components/back-office/roomservice/roomservice.component';
import { CafeComponent } from './components/back-office/cafe/cafe.component';
import { RestaurantComponent } from './components/back-office/restaurant/restaurant.component';
import { EventComponent } from './components/back-office/event/event.component';
import { CigarComponent } from './components/back-office/cigar/cigar.component';
import { OrderlistComponent } from './components/back-office/orderlist/orderlist.component';
import { PayrollComponent } from './components/back-office/payroll/payroll.component';
import { EmployeeListsComponent } from './components/back-office/employee-lists/employee-lists.component';
import { NewInventoryComponent } from './components/new-inventory/new-inventory.component';
import { SetupSeasonChannelsComponent } from './components/manage-rates-packages/setup-season-channels/setup-season-channels.component';
import { SetupSeasonOtaComponent } from './components/manage-rates-packages/setup-season-ota/setup-season-ota.component';
import { SetupSeasonPolicyComponent } from './components/manage-rates-packages/setup-season-policy/setup-season-policy.component';
import { SetupSeasonPreviewComponent } from './components/manage-rates-packages/setup-season-preview/setup-season-preview.component';
import { SetupSeasonRateComponent } from './components/manage-rates-packages/setup-season-rate/setup-season-rate.component';
import { SetupSeasonComponent } from './components/manage-rates-packages/setup-season/setup-season.component';
import { SetupPackagesComponent } from './components/manage-rates-packages/setup-packages/setup-packages.component';
import { SetupRatesComponent } from './components/manage-rates-packages/setup-rates/setup-rates.component';
import { SetupRestrictionsComponent } from './components/manage-rates-packages/setup-restrictions/setup-restrictions.component';
import { LinkOTASComponent } from './components/manage-rates-packages/link-otas/link-otas.component';
import { LinkChannelsComponent } from './components/manage-rates-packages/link-channels/link-channels.component';
import { PreviewPackagesComponent } from './components/manage-rates-packages/preview-packages/preview-packages.component';
import { PromotionCalenderComponent } from './components/promotion-calender/promotion-calender.component';

const routes: Routes = [

  { path: 'paynow-invoice', component: PaynowInvoiceComponent },
  { path: '', component: LoginComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'forgot_password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  {
    path: 'app', component: HomeLayoutComponent, children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] },
      {
        path: 'back_office', component: BackOfficeComponent, canActivate: [AuthGuardService], children: [
          { path: 'account_settings', component: AccountSettingsComponent, canActivate: [AuthGuardService] },
          { path: 'hotel_setup', component: HotelSetupComponent, canActivate: [AuthGuardService] },
          { path: 'create_users', component: CreateUsersComponent, canActivate: [AuthGuardService] },
          { path: 'create_users/:id', component: CreateUsersComponent, canActivate: [AuthGuardService] },
          { path: 'manage_users', component: ManageUsersComponent, canActivate: [AuthGuardService] },
          { path: 'create_rooms', component: CreateRoomsComponent, canActivate: [AuthGuardService] },
          { path: 'create_rooms/:id', component: CreateRoomsComponent, canActivate: [AuthGuardService] },
          { path: 'manage_rooms', component: ManageRoomsComponent, canActivate: [AuthGuardService] },
          { path: 'select_hotel', component: SelectHotelComponent, canActivate: [AuthGuardService] },
          { path: 'preview_rooms', component: PreviewRoomsComponent, data: {}, canActivate: [AuthGuardService] },
          { path: 'Preview-hotel', component: PreviewHotelComponent, canActivate: [AuthGuardService] },
          { path: 'vendor', component: VendorComponent, canActivate: [AuthGuardService] },
          { path: 'profitandloss', component: ProfitandlossComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_foodandbeverges', component: FoodandbeveragesComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_setup_shop', component: SetupShopComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_create_menu', component: CreateMenuComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_create_users_fnb', component: CreateUsersFnbComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_manage_menu', component: ManageMenuComponent, canActivate: [AuthGuardService] },
          { path: 'fnb_manage_shop', component: ManageShopComponent, canActivate: [AuthGuardService] },
          // maryam work
          { path: 'profitandloss', component: ProfitandlossComponent, canActivate: [AuthGuardService] },
          { path: 'pnl_list_item', component: ListItemComponent, canActivate: [AuthGuardService] },
          { path: 'pnl_sold_item', component: SoldItemComponent, canActivate: [AuthGuardService] },
          { path: 'pnl_finance', component: FinanceComponent, canActivate: [AuthGuardService] },
          { path: 'pnl_expenses', component: ExpensesComponent, canActivate: [AuthGuardService] },
          { path: 'rms_roomservice', component: RoomserviceComponent, canActivate: [AuthGuardService] },
          { path: 'rms_cafe', component: CafeComponent, canActivate: [AuthGuardService] },
          { path: 'rms_resturant', component: RestaurantComponent, canActivate: [AuthGuardService] },
          { path: 'rms_event', component: EventComponent, canActivate: [AuthGuardService] },
          { path: 'rms_cigar', component: CigarComponent, canActivate: [AuthGuardService] },
          { path: 'rms_order_list', component: OrderlistComponent, canActivate: [AuthGuardService] },
          { path: 'payroll', component: PayrollComponent, canActivate: [AuthGuardService] },
          { path: 'payroll_employee_lists', component: EmployeeListsComponent, canActivate: [AuthGuardService] },
        ]
      },
      { path: 'back_office/billing', component: BillingComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/invoice', component: InvoiceComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/manage_how_you_pay', component: ManageHowYouPayComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/subscription', component: SubscriptionComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/contact_support', component: ContactSupportComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/logs', component: LogComponent, canActivate: [AuthGuardService] },
      { path: 'manage_otas', component: ManageOtasComponent, canActivate: [AuthGuardService] },
      {
        path: 'rates_packages', component: ManageRatesPackagesComponent, canActivate: [AuthGuardService], children: [
          { path: 'setup_package', component: SetupPackageComponent, canActivate: [AuthGuardService] },
          { path: 'setup_rate', component: SetupRateComponent, canActivate: [AuthGuardService] },
          { path: 'setup_restriction', component: SetupRestrictionComponent, canActivate: [AuthGuardService] },
          { path: 'link_to_ota', component: LinkOTAComponent, canActivate: [AuthGuardService] },
          { path: 'preview_package', component: PreviewPackageComponent, canActivate: [AuthGuardService] },
          // * promotions components
          { path: 'setup_packages', component: SetupPackagesComponent, canActivate: [AuthGuardService] },
          { path: 'setup_rates', component: SetupRatesComponent, canActivate: [AuthGuardService] },
          { path: 'setup_restrictions', component: SetupRestrictionsComponent, canActivate: [AuthGuardService] },
          { path: 'link_to_otas', component: LinkOTASComponent, canActivate: [AuthGuardService] },
          { path: 'link_to_channels', component: LinkChannelsComponent, canActivate: [AuthGuardService] },
          { path: 'preview_packages', component: PreviewPackagesComponent, canActivate: [AuthGuardService] },
          // & rate plan components
          { path: 'setup_season', component: SetupSeasonComponent, canActivate: [AuthGuardService], outlet: 'seasonPanel' },
          { path: 'setup_season_rates', component: SetupSeasonRateComponent, canActivate: [AuthGuardService], outlet: 'seasonPanel' },
          { path: 'setup_season_policy', component: SetupSeasonPolicyComponent, data: {}, canActivate: [AuthGuardService], outlet: 'seasonPanel' },
          { path: 'setup_season_ota', component: SetupSeasonOtaComponent, data: {}, canActivate: [AuthGuardService], outlet: 'seasonPanel' },
          { path: 'setup_season_channel', component: SetupSeasonChannelsComponent, data: {}, canActivate: [AuthGuardService], outlet: 'seasonPanel' },
          { path: 'setup_season_preview', component: SetupSeasonPreviewComponent, data: {}, canActivate: [AuthGuardService], outlet: 'seasonPanel' },

        ]
      },
      { path: 'arrivals', component: ArrivalsComponent, canActivate: [AuthGuardService] },

      { path: 'inventory', component: PromotionCalenderComponent, canActivate: [AuthGuardService] },
      { path: 'stays', component: InventoryComponent, canActivate: [AuthGuardService] },
      { path: 'new-inventory', component: NewInventoryComponent, canActivate: [AuthGuardService] },
      { path: 'communication', component: CommunicationComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/communication/emaildirectory', component: EmailDirectoryComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/communication/emailtemplate', component: EmailTemplateComponent, canActivate: [AuthGuardService] },
      { path: 'paynow-invoice', component: PaynowInvoiceComponent },
      { path: '', component: LoginComponent },
      { path: 'registration', component: RegistrationComponent },
      { path: 'forgot_password', component: ForgotPasswordComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
      { path: 'back_office/billing', component: BillingComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/invoice', component: InvoiceComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/manage_how_you_pay', component: ManageHowYouPayComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/billing/subscription', component: SubscriptionComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/contact_support', component: ContactSupportComponent, canActivate: [AuthGuardService] },
      { path: 'back_office/logs', component: LogComponent, canActivate: [AuthGuardService] },
      { path: 'manage_otas', component: ManageOtasComponent, canActivate: [AuthGuardService] },
      {
        path: 'rates_packages', component: ManageRatesPackagesComponent, canActivate: [AuthGuardService], children: [
          { path: 'setup_package', component: SetupPackageComponent, canActivate: [AuthGuardService] },
          { path: 'setup_rate', component: SetupRateComponent, canActivate: [AuthGuardService] },
          { path: 'setup_restriction', component: SetupRestrictionComponent, canActivate: [AuthGuardService] },
          { path: 'link_to_ota', component: LinkOTAComponent, canActivate: [AuthGuardService] },
          { path: 'preview_package', component: PreviewPackageComponent, canActivate: [AuthGuardService] }
        ]
      },
      { path: 'arrivals', component: ArrivalsComponent, canActivate: [AuthGuardService] },

      { path: 'reports_analytics', component: ReportsComponent, canActivate: [AuthGuardService] },
      { path: 'test', component: TestComponent },
      { path: 'back_office/housekeeping', component: HouseKeepingComponent, canActivate: [AuthGuardService] }
    ]
  },
  {
    path: 'main', component: WithoutHeaderComponent, children: [
      { path: '', pathMatch: 'full', redirectTo: 'terms_of_use' },
      { path: 'terms_of_use', component: TermsOfUseComponent, canActivate: [AuthGuardService] },
      { path: 'online_help', component: OnlineHelpComponent, canActivate: [AuthGuardService] },
    ]
  },
  { path: '**', component: NotFoundComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
