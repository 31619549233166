import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-stays-dashboard',
  templateUrl: './stays-dashboard.component.html',
  styleUrls: ['./stays-dashboard.component.css']
})
export class StaysDashboardComponent implements OnInit {
  @Input() all_arrivals_stays;
  darkMode: boolean = false;
  cardLayout;
  graphDaysP = 'today';

  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    public weather: DashboardService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
  ) { }


  ngOnInit() {
    this.checkDarkMode();
    this.getDashboardData(this.graphDaysP);
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getDashboardData(filter) {
    this.all_arrivals_stays;
    // let dashObj = {
    //   property_id: localStorage.getItem("current_property"),
    //   date_departure_from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
    //   date_departure_to: moment().format('YYYY-MM-DD'),
    //   performance_filter: filter,
    //   graphDaysPFrontDesk: filter,
    // };
    // this.api.post('api/report/dashboard', dashObj, true).subscribe((res: any) => {
    //   this.all_arrivals = res.body.data.arrivals
    // },
    //   err => this.error_handling.handle_error(err.status, err.message));
  }

}
