<!-- Food and Beverages (Create-Shop-Page) work start -->

<div class="main-div">
  <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
    <div class="row">
      <div class="column-wrapper display-flex space-between">
        <div>
          <ul class="nav navbar-nav tabsBar">
            <li><a class="btn btn-link" data-dismiss="modal">BACK OFFICE</a></li>
            <li><a class="btn btn-link active" href="">MANAGE SHOP</a></li>
          </ul>
        </div>
        <div>
          <button class="btn btn-modal" (click)="openModel()">+ Create</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">

    <ng-container *ngFor="let shop of createShop;index as i">
      <div class="full-image" *ngIf="selectedImage">
        <i class="fa fa-times" aria-hidden="true" (click)="selectedImage = ''"></i>
        <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/' + selectedImage" alt="full-image">
      </div>
      <div class="row">
        <div class="col-md-12 inputCat">
          <div class="show-img" (click)="selectedImage = shop.image">
            <img class="" [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+shop.image" alt="images" class="btn">
          </div>
          <div class="modal-bar-2" [ngStyle]="{'border-color' : randomColor[i%randomColor.length]}">
            <input class="form-control addShopName" placeholder="Add shop name" type="text" name="shop-name{{i}}"
              [(ngModel)]="shop.shop_name">
          </div>
          <div class="btnCol">
            <button class="btn editBtn">
              <img src="../../../../assets/img/create_user_fnb/edit-icon.svg" alt="edit" (click)="editShop(shop,i)">
            </button>
            <button class="btn addBtnCross" id="removeBtn" data-toggle="modal" data-target="#confirmationModal-3"
              (click)="removeShop(i)"> <img src="../../../../assets/img/create_user_fnb/delete-icon.svg" alt="delete">
            </button>
          </div>
          <div class="btnCol">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-------------------modal---------------------->

  <div class="modal fade" id="shopModal" *ngIf="showModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" cdkDrag>
    <div class="modal-dialog">
      <div class="modal-content" [ngClass]="darkMode ? 'card_dark_grey' : ''">
        <div class="redHedaer" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
          <h5 class="modal-title" id="exampleModalLabel">Create Shop</h5>
          <button type="button" class="btn close" data-dismiss="modal" aria-labelledby="">
            <span aria-hidden="true" (click)="onModalClose()">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="left col-md-7">
              <div class="modal-bar border-1">
                <input type="text" class="form-control" placeholder="Shop name" [(ngModel)]="new_shop_name"
                  name="shop_name">
              </div>
            </div>
            <div class="right col-md-5">
              <div class="modal-bar border-2">
                <div class="input-group">
                  <div class="custom-file">
                    <div class="image-add">
                      <span class="input-value">{{ file_name || 'Select File' }}</span>
                    </div>
                    <input type="file" class="custom-file-input" #fileInput (change)="handleFileSelect()">
                    <label class="custom-file-label" for="inputGroupFile">
                      <button class="btn uploadBtn" (click)="fileInput.click()">
                        <img src="assets/img/back-office/upload_img_icon.svg" alt="upload">
                      </button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 px-2 pl-4">
              <div class="modal-bar border-3">
                <input type="text" class="form-control" placeholder="Tax ID" [(ngModel)]="tax_id" name="taxId">
              </div>
            </div>
            <div class="col-md-3 p-0">
              <div class="modal-bar border-4">
                <input type="text" class="form-control" placeholder="Tax Percentage" [(ngModel)]="tax_per"
                  name="tax_percentage">
              </div>
            </div>
            <div class="col-md-6 pl-2 pr-2">
              <div class="modal-bar border-5">
                <div class="radio" style="display:flex;">
                  <label class="control-label radio-style chbx ellipses hovbulit pt-1" data-toggle="tooltip"
                    title="Inclusive">
                    <input type="radio" class="pull-left radio" name="inclusive" value="true"
                      [(ngModel)]="tax_inclusive">
                    <span class="radio-checkmark rd"></span>
                    <span class="title">Inclusive</span>
                  </label>
                  <label class="control-label radio-style chbx ellipses hovbulit pt-1" data-toggle="tooltip"
                    title="Exculsive">
                    <input type="radio" class="pull-left radio" name="employee_type" value="false"
                      [(ngModel)]="tax_inclusive">
                    <span class="radio-checkmark rd"></span>
                    <span class="title">Exculsive</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="saveShopNames()">
            {{editMode ? 'Update' : 'Save'}}
          </button>
        </div>
      </div>
    </div>
  </div>