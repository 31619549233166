<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>Notification</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button28"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-notification" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button28')">
    </a>
  </div>
  <div class="collapse" id="collapse-notification" [ngClass]="darkMode ? 'card_dark' : 'null'">

    <!---------------collapse Event Notification--------------------->
    <div class="col-md-12 flex text space-between child-bar sub-par1">
      <div>Event Notification</div>
      <a>
        <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
          title="Select all" style="cursor: pointer;">
        <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button34"
          style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-event" role="button"
          aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button34')">
      </a>
    </div>
    <div class="collapse" id="collapse-event" [ngClass]="darkMode ? 'card_dark' : 'null'">
      <!-----1stColumnchildCollapse-->
      <div class="col-md-6 pr-0 pl-0">

        <!-----SubChildCollapse.1-->

        <div class="flex text space-between child-bar cb1">
          <div>Canada</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button16"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-canada" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button16')">
          </a>
        </div>

        <div class="collapse" id="collapse-canada">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-1">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 1,2023</label>
              </div>
              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-2">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Good Friday Apr 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-3">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Victoria Day May 22,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-4">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Canada Day Jul 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-5">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Civic Holiday Aug 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-6">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Labour Day Sep 4,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-7">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>National Day for Truth and Reconciliation Sep 30,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-8">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Thanksgiving Oct 9,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-9">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Remembrance Day Nov 11,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-10">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Victoria Day May 22,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty c-11">
              <div>
                <img src="assets/img/back-office/user-per/canada-flag.svg" alt="" style="padding-left: 10px;">
                <label>Christmas Day Dec 25,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
        </div>
        <!------------------------>
        <!-----SubChildCollapse.2-->

        <div class="flex text space-between child-bar cb2">
          <div>United Kingdom</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button17"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-UK" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button17')">
          </a>
        </div>

        <div class="collapse" id="collapse-UK">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk1">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 2,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk2">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Good Friday Apr 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk3">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Easter Monday Apr 10,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk4">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Early May Bank Holiday May 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk5">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>The Coronation of King Charles III May 6,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk6">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Bank Holiday for the Coronation of King Charles III May 8,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk7">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Spring Bank Holiday May 29,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk8">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>King's Birthday Jun 17,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk9">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Battle of the Boyne (Northern Ireland) Jul 12,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk10">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Summer Bank Holiday (Scotland) Aug 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk11">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Summer Bank Holiday (regional holiday) Aug 28,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk12">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Daylight Saving Time ends Oct 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk13">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Halloween Oct 31,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk14">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Guy Fawkes Day Nov 5,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk15">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Remembrance Sunday Nov 12,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk16">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>St Andrew's Day (Scotland) Nov 30,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk17">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Christmas Eve Dec 24,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk18">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Christmas Day Dec 25,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk19">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>Boxing Day Dec 26,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty uk20">
              <div>
                <img src="assets/img/back-office/user-per/uk-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Eve Dec 31,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>


        </div>
        <!------------------------>
        <!-----SubChildCollapse.3-->

        <div class="flex text space-between child-bar cb3">
          <div>Turkey</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button18"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-turkey" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button18')">
          </a>
        </div>

        <div class="collapse" id="collapse-turkey">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t1">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t2">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Eid-ul-Fitr Apr 21,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t3">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>National Sovereignty and Children's Day Apr 23,2023 </label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t4">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Labour Day May 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t5">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Commemoration of Ataturk, Youth and Sports Day May 19,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t6">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Eid-ul-Adha Jun 28,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t7">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Democracy and National Unity Day Jul 15,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t8">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Victory Day Aug 30,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty t9">
              <div>
                <img src="assets/img/back-office/user-per/turkey-flag.svg" alt="" style="padding-left: 10px;">
                <label>Republic Day of Turkey Oct 28,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>


        </div>
        <!------------------------>

      </div>
      <!----- 2ndColumnchildCollapse-->
      <div class="col-md-6 pr-0 pl-2">

        <!-----SubChildCollapse.1 of 2ndchild----->

        <div class="flex text space-between child-bar cb4">
          <div>United State</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button21"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-US" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button21')">
          </a>
        </div>

        <div class="collapse" id="collapse-US">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-1">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 2,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-2">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Martin Luther King Jr.Day Jan 16,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-3">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Washington's Birthday Feb 19,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-4">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Memorial Day May 29,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-5">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Juneteenth Jun 19,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-6">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Independence Day (United States) Jul 4,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-7">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Labor Day Sep 4,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-8">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Columbus Day Oct 9,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-9">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Veterans Day Nov 11,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-10">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Thanksgiving Nov 23,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty us-11">
              <div>
                <img src="assets/img/back-office/user-per/us-flag.svg" alt="" style="padding-left: 10px;">
                <label>Christmas Day Dec 25,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>

        </div>
        <!------------------------>
        <!-----SubChildCollapse.2 of 2ndchild----->

        <div class="flex text space-between child-bar cb5">
          <div>Georgia</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button22"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-geo" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button22')">
          </a>
        </div>

        <div class="collapse" id="collapse-geo">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-1">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-2">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year Holiday Jan 2,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-3">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Christmas Day Jan 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-4">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Epiphany Jan 19,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-5">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Mother's Day Mar 3,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-6">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>International Women's Day Mar 8,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-7">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Independence Restoration Day Sep 9,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-8">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Day of National Unity Apr 9,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-9">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Good Friday Apr 14,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-10">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Easter Saturday Apr 15,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-11">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Easter Sunday Apr 16,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-12">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Orthodox Easter Monday Apr 17,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-13">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Victory Day May 9,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-14">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Saint Andrew the First Called Day May 12,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-15">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Independence Day May 26,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-16">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Saint Mary's Day Aug 28,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-17">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Svetitskhovloba Oct 14,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty geo-18">
              <div>
                <img src="assets/img/back-office/user-per/georgia-flag.svg" alt="" style="padding-left: 10px;">
                <label>Saint George's Day Nov 23,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>


        </div>
        <!------------------------>
        <!-----SubChildCollapse.3 of 2ndchild----->

        <div class="flex text space-between child-bar cb6">
          <div>Germany</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button23"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-german" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button23')">
          </a>
        </div>

        <div class="collapse" id="collapse-german">

          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-1">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>New Year's Day Jan 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-2">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Good Friday Apr 7,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-3">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Easter Monday Apr 10,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-4">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Labour Day May 1,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-5">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Ascension Day May 18,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-6">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Whit Monday May 29,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-7">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>German Unity Day Oct 3,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-8">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>Christmas Day Dec 25,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty g-9">
              <div>
                <img src="assets/img/back-office/user-per/german-flag.svg" alt="" style="padding-left: 10px;">
                <label>2nd Day of Christmas Dec 26,2023</label>
              </div>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>
          </div>



        </div>
        <!------------------------>
      </div>
      <!------------------->
    </div>
    <!------------------------>

    <!-------collapse-Push Notification----------------->
    <div class="col-md-12 flex text space-between child-bar sub-par2">
      <div>Push Notification</div>
      <a>
        <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
          title="Select all" style="cursor: pointer;">
        <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button35"
          style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-push" role="button"
          aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button35')">
      </a>
    </div>

    <div class="collapse" id="collapse-push">

      <!------1st column child collapse------>
      <div class="col-md-6 pr-0 pl-0">
        <div class="flex text space-between child-bar cb7">
          <div>Blue Bar Notification</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button36"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-bar1" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button36')">
          </a>
        </div>
        <div class="collapse" id="collapse-bar1">
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty sb-1">
              <label>Room available</label>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>

          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty sb-2">
              <label>Total number of reservations</label>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>

          </div>
        </div>
      </div>
      <!------2nd column------>
      <div class="col-md-6 pr-0 pl-2">
        <div class="flex text space-between child-bar cb8">
          <div>App Notification</div>
          <a>
            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
              title="Select all" style="cursor: pointer;">
            <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button37"
              style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-bar2" role="button"
              aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button37')">
          </a>
        </div>
        <div class="collapse" id="collapse-bar2">
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty sb-3">
              <label> Status Checkin or Cancelled</label>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>

          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty sb-4">
              <label>Room No change</label>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>

          </div>
          <div class="col-md-12 pr-0 pl-0 pb-2">
            <div class="border-style  first-name-shade lab-sty sb-5">
              <label>Room upgrade</label>

              <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                style="margin:0px;cursor: pointer;">
            </div>

          </div>
        </div>
        <!-- <div class="col-md-12 pr-0 pl-0 pb-2 ">
                        <div class="border-style  first-name-shade lab-sty cb8 ">
                          <label>App Notification</label>

                          <input type="checkbox" class="form-check-input mr-3"  value="" id="defaultCheck1" name="checkbox"style="margin:0px;cursor: pointer;">
                        </div>

                      </div> -->

      </div>
      <!-------------------->

    </div>

    <!------------------------>
  </div>
  <!------------------------>
</div>