import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as jsPDF from 'jspdf';
import * as moment from 'moment';
import * as echarts from 'echarts';
import { PnlService } from 'src/app/services/pnl.service';
import { DiscountTrackingGraphModel } from 'src/app/common/pnl/DiscountTrackingGraphModel';
import { PnlDataModel } from 'src/app/common/pnl/PnlDataModel';
import { ReportByNewRoom, ReportByNewRoomResponse } from 'src/app/common/reports/ReportByNewRoomModel';
import { GraphDataResponse } from 'src/app/common/reports/GraphDataModel';
import { ReportsBarAndListResponse } from 'src/app/common/reports/ReportsBarAndListModel';
import { SortByNewRoomResponse } from 'src/app/common/reports/SortByNewRoomModel';
import { GetRoomGraphDataResponse } from 'src/app/common/reports/GetRoomGraphDataModel';
import { ReportsService } from 'src/app/services/reports.service';

//import * as html2canvas from 'html2canvas';
declare var html2canvas: any;

declare var $: any;
declare var Chart: any;

interface ChoosenDate {
  startDate: moment.Moment;
  endDate: moment.Moment;
  days: number;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnChanges {
  @ViewChild('capture', { static: true }) capture: ElementRef;

  page: number = 1;
  page2: number = 1;
  pageOfItems: Array<any>;
  chart = [
    { id: 1, name: 'Line View' },
    { id: 2, name: 'Bar View' },
    { id: 3, name: 'List View' }
  ];

  display: boolean = true;
  selectedChart = 1;
  restriction: boolean = true;
  all_data;

  channels;
  compare_channels = [];
  compare_array = [];
  page_arr = [];
  dropt: boolean = false;
  choosen_date: ChoosenDate = null;

  choosen_range = [];
  showing_dates = [];
  all_hotels = [];
  room = false;

  allRoomsInDateRange;

  graph_arr = [];
  points = [];
  hotel_sum_total = 0;
  hotel_avg_lead_total = 0;

  roomDisplayName = []
  border_colors = ['#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3'];
  RoomGraph_color = ['#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3'];

  donut_color = [
    '#06A535',
    '#FE6601',
    '#a7002d',
    '#48fff3',
    '#808000',
    '#9a00ff',
    '#0179F1',
    '#ffc107',
    '#CD3C05',
    '#09fb3d',
    '#283ed8',
    '#5D00FF',
    '#EC1361'
  ];

  donutgrey = [
    '',
    'grey',
    '#9B9C9D',
    '#bfbfbf',
    '#e7e7e7',
    '#544e4e',
    '#7b7373',
    '#707070',
    '#d0d0d0',
    '#A7A7A7',
    '#9B9C9D',
    '#9B9C9D',
    '#9B9C9D'
  ]

  stats_box = { x: 760, y: 0 };
  graph_line_switch = 0;
  stats_box_data = {
    id: 0,
    image: '',
    checkins: 0,
    date: moment(),
    revenue: 0,
    rev_par: 0,
    avg_daily_rate: 0,
    avrr: 0,
    type: ''
  }

  current_page = 1;
  pagination = [];
  total_set = {
    occupany_rate: 0,
    total_nights: 0,
    avg_nights_per_room: 0,
    avg_daily_rate: 0,
    rev_par: 0,
    avg_lead: 0,
    channel_commission: 0,
    revenue_earned: 0,
    net_revenue: 0,
    reservations: 0,
    cancellation: 0
  };

  metrics = [
    { slug: 'metrics', name: 'Metrics' },
    { slug: 'checkins', name: 'Occupancy Rate' },
    { slug: 'avg_daily_rate', name: 'Average Daily Rate' },
    { slug: 'rev_par', name: 'Revenue Per Available Room' },
  ];
  current_metric = 'metrics';

  metrics_room = [
    { name: 'By Channels' },
    { name: 'By Rooms' },
    // {name: 'By PNL'},
  ]
  current_donut = 'By Channels';

  showTool: boolean = false;
  toolColor: any = 0;

  d_data: ReportByNewRoom[] = [];
  r_data: any;

  ebr_total: any;
  occ_total: any;
  adr_total: any;
  rev_total: any;

  list_data = [];
  darkMode: boolean = false;
  exte: boolean = true;
  current_currency;
  currency_format = localStorage.getItem('currency');

  PNLdata: PnlDataModel = null
  DiscountGraphModel: DiscountTrackingGraphModel = null;
  @ViewChild('grid_background', { static: false }) grid_background: ElementRef;

  legendBoxColor: string[] = [];
  roomGraphData: any[] = [];
  room_names;
  labelIndex;
  // by room graph color
  byRoomGraphColors = [
    'rgba(8, 84, 255, 0.7)',
    'rgba(255, 110, 33, 0.7)',
    'rgba(255, 110, 228, 0.7)',
    'rgba(75, 192, 192, 0.7)',
    'rgba(255, 159, 64, 0.7)',
    'rgba(153, 102, 255, 0.7)',
    'rgba(47, 189, 55, 0.7)',
    'rgba(255, 6, 255, 0.7)',
    'rgba(255, 0, 0, 0.7)',
    'rgba(255, 159, 64, 0.7)'
  ]

  channel_colors = [
    '#CC17FF',
    '#00FFF9',
    '#6917D0',
    '#FF1791',
    '#0D41E1',
    '#FF7D00',
    '#ADFF02',
    '#FFCF00'
  ];

  todayDate = new Date();
  currentDay: boolean = true;
  public dates: moment.Moment[];
  selected_room: string = 'Rain Forest';
  selected_channel: string = 'Expedia';
  channelList = [
    'Expedia',
    'Walk-In',
    'Website',
    'Agoda',
    'Loyalty',
    'All Channels'
  ];

  roomList = [
    'Rain Forest Room',
    'Ever Green Room',
    'Deloite Room',
    'Italian Room',
    'Fancy Room',
    'Double Deluxe Room',
    'Studio Room',
    'All Rooms'
  ]

  roomsColorLight = [
    '#FF0077',
    '#193FFF',
    '#19B0FF',
    '#FFAB19',
    '#8338EC',
    '#FB5607',
    '#9A031E'
  ];

  roomsColorDark = [
    '#80003C',
    '#0D2080',
    '#0D5880',
    '#80560D',
    '#421C76',
    '#7E2B04',
    '#4D020F'
  ]

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private pnlService: PnlService,
    private reportsService: ReportsService,
  ) {

    this.choosen_date = this.getChoosenDate();
    console.log(this.choosen_date, "choosen_date");

    Chart.defaults.global.plugins.labels = {
      render: function (args) {
        return ''
      }
    };

  }

  ngOnChanges() {
    this.drag();
  }

  ngOnInit() {
    this.checkDarkMode();
    //setTimeout(()=>{this.showEBRChart('EBR')}, 1000);
    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });

    $(".input-checkbox").change(function () {
      $(".input-checkbox").prop('checked', false);
      $(".input-checkbox").prop('checked', false);
      $(this).prop('checked', true);
    });

    this.drawDoughnut()

    $("li").click(function () {
      // remove classes from all
      $("li").removeClass("active");
      // add class to the one we clicked
      $(this).addClass("active");
    });

    $("#prev").click(function () {
      $('li.active').removeClass('active').prev().addClass('active');
      if ($(this).hasClass("active"))
        $(this).removeClass('active').next().addClass('active');
    });

    $("#next").click(function () {
      $('li.active').removeClass('active').next().addClass('active');
      if ($(this).hasClass("active"))
        // next element per jao
        $(this).removeClass('active').prev().addClass('active');
    });

    //this.getChannels();
    this.get_range();
    this.get_room_graph_data();
    this.drag();
  }


  move_box(e) {
    if (e.layerX > 0 && e.layerX < 1600) {
      this.stats_box.x = e.layerX - 105;
      this.stats_box.y = e.layerY - 90;
      if (this.graph_line_switch != undefined) {
        this.box_data(e);
      }
    }
  }

  box_data(evt) {
    let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);

    // Find your root SVG element
    let svg = document.querySelector('svg');

    // Create an SVGPoint for future math
    let pt = svg.createSVGPoint();
    pt.x = evt.clientX; pt.y = evt.clientY;
    let pos = pt.matrixTransform(svg.getScreenCTM().inverse());

    if (current_line) {
      let res = current_line.data.find((cl, idx, com) => {
        if (com[idx] && com[idx + 1]) {
          let min = com[idx].width || 0;
          let max = com[idx + 1].width || 1600;
          return pos.x >= Math.ceil(min) && pos.x <= Math.ceil(max);
        }
        return false;
      });
      if (res) {
        this.stats_box_data.checkins = res.check_in;
        this.stats_box_data.revenue = res.revenue;
        this.stats_box_data.rev_par = res.rev_par;
        this.stats_box_data.avg_daily_rate = res.avg_daily_rate;
        //this.stats_box_data.avrr = res.avrr;
        this.stats_box_data.date = moment(res.date, 'YYYY-MM-DD');
      }
    }
  }

  get_range() {
    let range = [];
    let cd_start = this.choosen_date.startDate;
    let cd_end = this.choosen_date.endDate;
    let start = cd_start.toDate();
    let end = cd_end.toDate();
    let space = 0;
    let duration = moment.duration(cd_end.diff(cd_start)).asDays();
    let box_width = 1500 / duration;
    let counter = 1;
    this.showing_dates = [];
    while (start < end) {
      range.push({ date: moment(start), location: box_width * counter });
      start = new Date(start.setDate(start.getDate() + 1));
      counter++;
    }
    this.choosen_range = range;

    space = range.length > 30 ? range.length > 60 ? 6 : 4 : 3;
    let date_arr = range.map(x => x.date);
    this.showing_dates = date_arr.filter((date, index) => {
      return index % space == 0 ? date : false;
    });


    this.get_graph_data();
    this.get_room_graph_data();

    this.current_donut == 'By Channels' ? this.getRoomData() : this.getDonutData();

    this.getPnlData();

    setTimeout(() => {
      this.byRoomGraph();
      // this.loadByRoomGraphs()
      this.drag();
    }, 500);

  }

  get_points(ch) {

    // this.points = [];
    const smoothing = 0.15;
    // let arr = [
    //   [12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78],
    //   [66,45, 65, 28, 98 ,67, 58, 38,58, 23, 12, 78,12,5 ,12, 160,45, 65, 28, 98, 79, 64, 58, 23, 12, 78],
    //   [65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 64, 58, 23, 12, 78],
    //   [45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78, 12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 38, 79, 64, 58, 23, 12, 78,12,5 ,12, 66,45, 65, 28, 98 ,67, 58, 3, 79, 64, 58, 23, 12, 78]
    // ];

    // let maxRow = ch.map(function(row){ return Math.max.apply(Math, row.check_in); });
    // let max_value = Math.max.apply(null, maxRow);

    const options = {
      yMin: 0,
      yMax: 380,
      xMin: 0,
      xMax: 1500
    }

    const map = (value, inMin, inMax, outMin, outMax) => {
      return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    };

    const pointsPositionsCalc = (points, w, h, options) => points.map(e => {
      const x = map(e.width, options.xMin, options.xMax, 0, w)
      const y = map(e.height, options.yMin, options.yMax, h, 0)
      return [x, y]
      // return [e[0], e[1]]
    })

    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
      }
    }

    const controlPoint = (line, smooth) => (current, previous, next, reverse) => {
      const p = previous || current
      const n = next || current
      const l = line(p, n)

      const angle = l.angle + (reverse ? Math.PI : 0)
      const length = l.length * smooth
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (controlPoint) => (point, i, a) => {
      const cps = controlPoint(a[i - 1], a[i - 2], point)
      const cpe = controlPoint(point, a[i - 1], a[i + 1], true)
      const close = i === a.length - 1 ? ' z' : ''
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`
    }

    const svgPath = (points, command, h) => {
      const d = points.reduce((acc, e, i, a) => i === 0
        ? `M ${a[a.length - 1][0]},${h} L 0,${h} L 0,${e[1]} l0,0`
        : `${acc} ${command(e, i, a)}`
        , '')

      // return `<path d="${d}" class="svg-path" />`
      return d;
    }

    // const svgCircles = points => points.reduce((acc, point, i, a) => {
    //   return `${acc} <circle cx="${point[0]}" cy="${point[1]}" r="2.5" class="svg-circles" v-for="p in pointsPositions"/>`
    //   // return {data: acc, x: point[0], y: point[1]}
    // }, '')

    const w = 1500;
    const h = 380;

    // let imgs = ['ctrip.png', 'booking.png', 'goibibo.png', 'expedia.png'];
    // for (let i = 0; i < ch.length; i++) {
    // let points = [];

    // this.choosen_range.forEach((obj, index) => {
    //   if (index < ch[i].length) {
    //     points.push([obj.location, (360/100)*((arr[i][index]/max_value)*100), arr[i][index], index]);
    //   }
    // });

    const pointsPositions = pointsPositionsCalc(ch, w, h, options)
    const bezierCommandCalc = bezierCommand(controlPoint(line, smoothing))
    const path = svgPath(pointsPositions, bezierCommandCalc, h);
    // this.points.push({id: i, d: path, name: 'path'+i, img: imgs[i], data_arr: points});
    // }

    return path;
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  switch_paths(name) {
    this.graph_line_switch = name;
    if (this.graph_arr) {
      let current_line = this.graph_arr.find(p => p.channel_id == this.graph_line_switch);
      this.stats_box_data.id = current_line.channel_id;
      this.stats_box_data.image = current_line.channel_img;
      this.stats_box_data.type = current_line.type
    }
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range();
      //this.getChannels();
    }
  }

  getChannels() {
    // let a = {
    //   property_id: localStorage.getItem("current_property"),
    //   startDate: this.choosen_date.startDate.format('YYYY-MM-DD'),
    //   endDate: this.choosen_date.endDate.format('YYYY-MM-DD'),
    // }

    // this.pnlService.ChannelPerformance(a).subscribe((res: any) => {

    // })

    let start = this.choosen_date.startDate.format('YYYY-MM-DD');
    let end = this.choosen_date.endDate.format('YYYY-MM-DD');
    let property_id = localStorage.getItem("current_property");

    this.api.post('api/report/channel_performance', { property_id: property_id, date_departure_from: start, date_departure_to: end }, true).subscribe((res: any) => {
      this.channels = res.body.data;
      if (this.channels != undefined) {
        this.total_set.revenue_earned = 0;
        this.channels.map(c => this.total_set.revenue_earned += c.total);
        this.total_set.avg_daily_rate = this.channels.map(c => c.avg_daily_rate).reduce((a, b) => a + b, 0);
        this.total_set.total_nights = this.channels.map(c => c.total_nights).reduce((a, b) => a + b, 0);
        this.total_set.channel_commission = this.channels.map(c => c.channel_commission).reduce((a, b) => a + b, 0);
        this.total_set.avg_lead = this.channels.map(c => c.avg_lead).reduce((a, b) => a + b, 0);
        this.total_set.net_revenue = this.channels.map(c => c.net_revenue).reduce((a, b) => a + b, 0);
        this.total_set.rev_par = this.channels.map(c => c.rev_par).reduce((a, b) => a + b, 0);
        this.total_set.occupany_rate = this.channels.map(c => c.occupany_rate).reduce((a, b) => a + b, 0);
        this.total_set.avg_nights_per_room = this.channels.map(c => c.total_nights / c.room_count).reduce((a, b) => a + b, 0);
        this.total_set.cancellation = this.channels.map(c => c.cancellation).reduce((a, b) => a + b, 0);
        this.total_set.reservations = this.channels.map(c => c.reservations).reduce((a, b) => a + b, 0);

        this.channels.forEach((c, i) => {
          c.color = this.border_colors[i % this.border_colors.length];
          c.avg_nights_per_room = Math.round(c.total_nights / c.room_count);
          c.rev_percentage = Math.round(((c.net_revenue + c.channel_commission) / this.total_set.revenue_earned) * 100);
          c.per_occupany_rate = Math.round(parseInt(((c.occupany_rate / this.total_set.occupany_rate) * 100).toFixed(2))) + '%';
          c.per_rev_par = Math.round(parseInt(((c.rev_par / this.total_set.rev_par) * 100).toFixed(2))) + '%';
          c.per_total_nights = Math.round(parseInt(((c.total_nights / this.total_set.total_nights) * 100).toFixed(2))) + '%';
          c.per_avg_daily_rate = Math.round(parseInt(((c.avg_daily_rate / this.total_set.avg_daily_rate) * 100).toFixed(2))) + '%';
          c.per_avg_lead = Math.round(parseInt(((c.avg_lead / this.total_set.avg_lead) * 100).toFixed(2))) + '%';
          c.per_channel_commission = Math.round(parseInt(((c.channel_commission / this.total_set.channel_commission) * 100).toFixed(2))) + '%';
          c.per_revenue_earned = Math.round(parseInt(((c.total / this.total_set.revenue_earned) * 100).toFixed(2))) + '%';
          c.per_net_revenue = Math.round(parseInt(((c.net_revenue / this.total_set.net_revenue) * 100).toFixed(2))) + '%';
          c.per_avg_nights_per_room = Math.round(parseInt(((c.avg_nights_per_room / this.total_set.avg_nights_per_room) * 100).toFixed(2))) + '%';
        });
      } else {
      }
      this.set_compare_channels();
    })
  }

  drawDoughnut() {
    var DonutChart = function (canvas, radius, lineWidth, arraySlices, label) {
      this._radius = radius;
      this._lineWidth = lineWidth; //px
      this._arraySlices = arraySlices;
      this._label = label;
      this._x_center = canvas.width / 2;
      this._y_center = canvas.height / 2;
      this._context = canvas.getContext("2d");


      this.drawCircle = function () {
        var context = this._context;
        context.lineWidth = this._lineWidth;
        var radius = this._radius;
        var offset_radians = -0.5 * Math.PI;
        var start_radians = offset_radians;
        var counterClockwise = true;
        var self = this;
        this._arraySlices.forEach(function (slice) {
          context.beginPath();
          context.strokeStyle = slice.color;
          var end_radians = start_radians - (Math.PI * 2) * slice.percent / 100;
          context.arc(self._x_center, self._y_center, radius,
            start_radians, end_radians, counterClockwise);
          context.stroke();
          start_radians = end_radians;
        });
      };

      this.drawText = function () {
        var fontSize = this._label.font_size;
        var context = this._context;
        context.font = 'bolder 45px Roboto Black';
        context.textAlign = 'center';
        context.fillStyle = this._label.color;
        context.background_color = this._label.background_color
        var text = this._label.text;
        context.fillText(text, this._x_center, this._y_center + fontSize / 2);
      };

      this.render = function () {
        this.drawCircle();
        this.drawText();
      };

    };

    $(function () {
      //create canvas
      var canvasElem$ = $("<canvas/>");
      $("#content").append(canvasElem$);
      var canvas = $(canvasElem$).get(0);
      var sideLength = 250;
      canvas.width = canvas.height = sideLength;
      var lineWidth = 50;
      var radius = (sideLength - lineWidth) / 2;
      // var label = "Label text";

      var slices = [{
        percent: 15,
        color: "red"
      }, {
        percent: 1,
        color: "#ffffff"
      }, {
        percent: 15,
        color: "#ec13a0"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 15,
        color: "#283ed8"
      }, {
        percent: 1,
        color: "#ffffff"
      }, {
        percent: 8,
        color: "#9a00ff"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 8,
        color: "#a7002d"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 5,
        color: "#48fff3"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 6,
        color: "#e46300"
      }, {
        percent: 1,
        color: "#ffffff"
      },
      {
        percent: 22,
        color: "#09fb3d"
      },
      {
        percent: 1,
        color: "#ffffff"
      }
      ];

      var label = {
        text: "73%",
        color: "#7e7cea",
        font_size: 25,
        font_weight: 800,
        font_family: 'robotoblack',
        background_color: "#ffffff",
        verticalAlign: 'middle',
      };

      var donutChart = new DonutChart(canvas, radius, lineWidth, slices, label);
      donutChart.render();
    });
  }

  dougnuts() {

    this.compare_array.forEach((c, i) => {
      setTimeout(() => {
        const segments = document.querySelectorAll(".donut-segment" + i);
        // First segment's offset
        const firstSegmentOffset = parseInt(segments[0].getAttribute("stroke-dashoffset"));

        // Total of all preceding segments length
        // For use in stroke-dashoffset calculation
        let preSegmentsTotalLength = parseInt(segments[0].getAttribute("data-per"));

        // let preSegmentsTotalLength = 0;
        segments.forEach((segment, i) => {
          // percentage occupied by current segment
          let percent = parseInt(segment.getAttribute("data-per"));

          // current segments stroke-dasharray calculation
          let strokeDasharray = `${percent} ${100 - percent}`;
          // setting stroke-dasharray for all segments
          segment.setAttribute("stroke-dasharray", strokeDasharray);

          if (i != 0) {
            // current segments stroke-dashoffset calculation
            let strokeDashoffset = `${100 - preSegmentsTotalLength + firstSegmentOffset}`;

            // setting stroke-dasharray for all segments
            segment.setAttribute("stroke-dashoffset", strokeDashoffset);

            // Updating total of all preceding segments length
            preSegmentsTotalLength += percent;
          }
        })
      }, 1000);
    })

  }

  set_compare_channels() {
    this.compare_array = [];
    if (this.compare_channels.length > 1) {
      this.compare_array = this.channels.filter(c => this.compare_channels.indexOf(c.channel_id) != -1);
    } else {
      this.compare_array = this.channels != undefined ? JSON.parse(JSON.stringify(this.channels)) : [];
    }

    this.create_pagination();
    //this.dougnuts();
  }

  change_page(move_to) {
    if (move_to == 'forward') {
      this.current_page < this.pagination.length ? this.current_page++ : null;
    } else if (move_to == 'backward') {
      this.current_page > 1 ? this.current_page-- : null;
    } else if (move_to == 'first') {
      this.current_page = 1;
    } else if (move_to == 'last') {
      this.current_page = this.pagination.length;
    } else {
      this.current_page = move_to;
    }
  }

  create_pagination() {
    let chunk = 5;
    this.page_arr = [];
    for (let i = 0; i < this.list_data.length; i += chunk) {
      this.page_arr.push(this.list_data.slice(i, i + chunk));
    };
    this.pagination = this.page_arr.map((m, i) => i + 1);
  }

  reset_filter() {
    this.selectedChart = 1;
    this.compare_channels = [];
    this.choosen_date = { startDate: moment().subtract('15', 'days'), endDate: moment().add('15', 'days'), days: 14 };
    this.current_page = 1;
    this.get_range();
    //this.getChannels();
  }

  getChoosenDate() {
    let val = localStorage.getItem('reports-choosen-date')
    let date: ChoosenDate = null
    if (val) {
      date = JSON.parse(val)
      date = { startDate: moment(date.startDate), endDate: moment(date.endDate), days: date.days }

    } else {
      // date = { startDate: moment().subtract('15', 'days'), endDate: moment().add('15', 'days'), days: 14 };
      date = { startDate: moment().subtract('30', 'days'), endDate: moment(), days: 14 };
      console.log(date.endDate.diff(date.startDate, 'days'), "days");

    }

    return date
  }

  get_graph_data() {
    let graph = [];
    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: this.choosen_date.startDate.format('YYYY-MM-DD'),
      date_departure_to: this.choosen_date.endDate.format('YYYY-MM-DD')
    };
    if (localStorage.getItem('current_property') > '0') {
      this.reportsService.getGraphData(a).subscribe((res: GraphDataResponse) => {
        let data = res.data;
        let allRooms = data.map(x => x.room).map(x => x.display_name).filter((value, index, self) => self.indexOf(value) === index);
        this.allRoomsInDateRange = allRooms;

        let max_value = Math.max.apply(null, data.map(m => m.total_sum));
        data = data.reduce((a, b) => {
          if (b.channel != null) {
            a[b.channel.id] = [...a[b.channel.id] || [], b];
          }
          return a;
        }, [])
        data = data.sort(function (a, b) {
          return a[0].total_sum - b[0].total_sum;
        });
        data = data.reverse();
        data.forEach((ch: any) => {

          let arr = [];
          this.choosen_range.forEach((r, i) => {
            let date = r.date.format('YYYY-MM-DD');
            let already = ch.find(f => date == f.date_departure);
            if (!already) {
              arr.push({
                id: i,
                date: date,
                check_in: 0,
                revenue: 0,
                rev_par: 0,
                avg_daily_rate: 0,
                avrr: 0,
                width: r.location,
                height: (360 / 100) * ((0 / max_value) * 100),
              });
            } else {
              arr.push({
                id: i,
                date: date,
                check_in: already.check_in_count,
                revenue: already.total_sum,
                rev_par: already.rev_par,
                avg_daily_rate: already.avg_daily_rate,
                avrr: parseInt(already.avrr),
                width: r.location,
                height: (360 / 100) * ((already.total_sum / max_value) * 100),
              });
            }
          });
          arr.sort((a, b) => {
            return new Date(b.date) > new Date(a.date) ? -1 : new Date(b.date) < new Date(a.date) ? 1 : 0;
          });
          graph.push({ channel_id: ch[0].channel.id, channel_img: ch[0].channel.image, data: arr, points: this.get_points(arr), type: ch[0].channel.type })
        });

        this.graph_arr = graph;

        //this.setGraph();
        if (graph.length != 0) this.switch_paths(graph[0].channel_id);
      })
    }
  }

  date_change(e) {
    if (e) {
      this.get_range();
      //this.getChannels();
    }
  }

  change_center_date(date) {
    let days = this.choosen_date.days;
    this.choosen_date = { startDate: moment(date).subtract(days, 'days'), endDate: moment(date).add(days, 'days'), days: days };
    this.get_range();
    //this.getChannels();
  }

  // Earning by Channels
  getDonutData() {
    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: this.choosen_date.startDate.format("YYYY-MM-DD"),
      date_departure_to: this.choosen_date.endDate.format("YYYY-MM-DD")
    };
    this.room = true;
    this.reportsService.reportByNewRoom(a).subscribe((res: ReportByNewRoomResponse) => {
      console.log(res, "report_by_room_new");

      this.d_data = res.data;
      // this.d_data = res.body.data.channels.data;

      this.roomDisplayName = []
      let ar = [];
      let rtn = [];
      let sum = 0
      let los = 0;
      let sold = 0;
      let roomCount = 0;
      let cancellation = 0;
      let totalDiscount = 0;
      let totalUnpaid = 0;
      let totalHotelInventory = 0;
      this.hotel_sum_total = 0;
      this.hotel_avg_lead_total = 0;
      // debugger;
      this.d_data.forEach((element) => {

        element.total_sum !== 0 ? ar.push(element.total_sum) : ar.push();
        rtn.push(element.room_type_name)
        // this.hotel_sum_total+= element.total_sum
        //los += parseInt(element.total_nights)
        //roomCount += parseInt(element.roomCount)
        // sold += parseInt(element.reservation)
        // cancellation += parseInt(element.cancellation)
        // this.hotel_avg_lead_total+=element.avg_lead

        this.roomDisplayName.push(element.room_display_name)
        this.hotel_sum_total += Number.isNaN(element.total_sum) ? 0 : element.total_sum
        los += parseInt((element.total_nights))
        roomCount += (+element.roomCount)
        sold += (+element.reservation)
        cancellation += (+element.cancellation)
        this.hotel_avg_lead_total += element.avg_lead;
        totalDiscount += Number.isNaN(element.total_discounted_price) ? 0 : element.total_discounted_price;
        totalHotelInventory += (+element.total_room_count);
        totalUnpaid += (+element.unpaid_sum)
      });;


      let occ = ((los / totalHotelInventory) * 100).toFixed(2);
      if (occ == "NaN") {
        occ = "0";
      }


      // console.log(occ, "------OCC")
      this.all_hotels = []
      this.all_hotels.push({
        los: los,
        roomCount: Number.isNaN(roomCount) ? 0 : roomCount,
        occ: occ,
        sold: sold,
        cancellation: cancellation,
        discount: totalDiscount,
        totalHotelInventory: totalHotelInventory,
        totalUnpaid: totalUnpaid
      })

      var co = this.channel_colors;

      setTimeout(() => {
        this.d_data.map((val, i) => {
          let a = [];
          let b = [];

          val.channels.map((v) => {
            a.push(val.total_sum);
            b.push(v.channel_name.toLowerCase());
          });

          // let c = val.channels.length > 1 ? co : co[i % co.length];
          // this.legendBoxColor = co;
          var c = this.channelColors(b);

          if (i % 2 == 0) {
            this.showChart2(i, a, b, c);
          } else {
            this.showChart(i, a, b, c);
          }

          this.showChart23(ar, this.roomDisplayName, co);
        }, 250)
      })
    })

  }
  // empty donut (working on saud 👇)
  private emptyDount() {
    return {
      id: 'emptyDoughnut',
      afterDraw(chart, args, options) {
        const { datasets } = chart.data;
        const { color, width, radiusDecrease } = options;
        let hasData = false;

        for (let i = 0; i < datasets.length; i += 1) {
          const dataset = datasets[i];
          hasData = dataset.data.length > 0;
        }

        if (!hasData) {
          const { chartArea: { left, top, right, bottom }, ctx } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || '#e7e7e7';
          ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
          ctx.stroke();
        }
      }
    };
  }

  showChart(id, val, n, c) {
    let restriction = this.restriction;
    let total_per = this.hotel_sum_total;
    var ctx = document.getElementById('myChart_' + id);
    const plugin = this.emptyDount();

    var myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: n,
        legend: { position: 'bottom' },
        datasets: [{
          data: val.slice(0, 8),
          backgroundColor: c,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 20,
            radiusDecrease: 10
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            boxWidth: 8,
            boxHeight: 2,
          },
          title: {
            position: 'center',
          },
          lineWidth: 0
        },

        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,
          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);
              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });

              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let b = body[0];
                let re = b.split(':')[0].toLowerCase();
                let amt = b.split(':')[1];
                let per = (amt / total_per) * 100;
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                <div style='font-weight: bold; font-size: 16px;text-align:right;'>${restriction ? `${per.toFixed(2)}%` : (localStorage.getItem('currency') + amt)}</div>
                </div>`;

              });

              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }


            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = Math.floor(parseInt(position.left + window.pageXOffset + tooltipModel.caretX / 100) + 50) + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },
        },
      },
      plugins: [plugin]
    });

  }


  // change amount format percentage to revenue (working on saud 👇)
  changeFormat() {
    this.restriction = !this.restriction;
    // load BY Room Donut data in the convert dollar to percentage 
    this.getDonutData();
    // load BY Channel Donut data in the convert dollar to  percentage
    this.calculateEbr();
  }


  showChart2(id, val, n, c) {
    let restriction = this.restriction;
    let total_per = this.hotel_sum_total;
    var ctx = document.getElementById('myChart_1' + id);
    const plugin = this.emptyDount();

    var myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: n,
        legend: { position: 'bottom' },
        datasets: [{
          data: val.slice(0, 8),
          backgroundColor: c,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 20,
            radiusDecrease: 10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                let per = (amt / total_per) * 100;
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:right;'>${restriction ? `${per.toFixed(2)}%` : (localStorage.getItem('currency') + amt)}</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      },
      plugins: [plugin]
    });
  }

  // Earning by Channels

  chandrop(id) {
    let a = document.getElementById(id)
    if (a.classList.contains('rotat')) {
      a.classList.remove('rotat')
    }
    else {
      a.classList.add('rotat')
    }

  }

  sortCompareArr(items = []) {
    return items.sort((a, b) => b.percentage - a.percentage);
  }
  //Donut WOrk End

  //List and Bar API
  getList_barData(type) {
    if (type != null) {
      let a = {
        type: type,
        property_id: localStorage.getItem('current_property'),
        date_departure_from: this.choosen_date.startDate.format("YYYY-MM-DD"),
        date_departure_to: this.choosen_date.endDate.format("YYYY-MM-DD")
      }
      this.reportsService.reportsBarAndList(a).subscribe((res: ReportsBarAndListResponse) => {
        this.list_data = this.sortCompareArr(res.data);
        this.create_pagination();
      })
    }

    setTimeout(() => {
      this.byRoomGraph();
      // this.loadByRoomGraphs();
    }, 1000);
  }

  changeView(current_donut) {

    if (current_donut == 'By Channels') {
      this.getRoomData();
    }
    // else if(current_donut='By PNL'){
    // }
    else {
      this.current_donut = 'By Room';
      this.getDonutData();
      this.getPnlData();

    }
    this.drag();

    setTimeout(() => {
      this.byRoomGraph();
      // this.loadByRoomGraphs();
      this.get_room_graph_data();
    }, 500)

  }

  //Earning by ROOm section 
  getRoomData() {
    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: this.choosen_date.startDate.format("YYYY-MM-DD"),
      date_departure_to: this.choosen_date.endDate.format("YYYY-MM-DD")
    };
    this.room = false;
    this.reportsService.sortByNewRoom(a).subscribe((res: SortByNewRoomResponse) => {
      this.r_data = res.data;
      this.calculateEbr();
    })
    // let occ = ((los/totalHotelInventory) * 100).toFixed(2);
    // if(occ=="NaN"){
    //   occ = "0";
    // }
  }

  calculateEbr() {
    this.ebr_total = 0;
    this.occ_total = 0;
    this.adr_total = 0;
    this.rev_total = 0;

    let EbrA = [];
    let Ebrl = [];
    this.r_data.Room_earn.map((v) => {
      v.percentage != 0 ? EbrA.push(v.percentage) : EbrA.push();
      Ebrl.push(v.room_type_name)
      this.ebr_total += v.room_sum;
    });

    let occA = [];
    let occL = [];
    this.r_data.Occ.map((val) => {
      val.occ != 0 ? occA.push(val.occ) : occA.push();
      occL.push(val.channel_image.split('.')[0]);
      this.occ_total += val.occ;
    });

    let adrA = [];
    let adrL = [];
    this.r_data.ADR.map((val) => {
      val.percentage != 0 ? adrA.push(val.channel_sum) : adrA.push();
      adrL.push(val.channel_image.split('.')[0]);
      this.adr_total += val.channel_sum;
    });

    let revA = [];
    let revL = [];
    this.r_data.RevPar.map((val) => {
      val.percentage != 0 ? revA.push(val.rev_par) : revA.push();
      revL.push(val.channel_image.split('.')[0]);
      this.rev_total += val.rev_par;
    });

    setTimeout(() => {
      this.setEbr(EbrA, Ebrl);
      this.setOcc(occA, occL);
      this.setAdr(adrA, adrL);
      this.setRev(revA, revL);
    }, 250);
  }

  setEbr(d, l) {
    let ctx = document.getElementById('EBR1')
    const plugin = this.emptyDount();

    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: { position: 'bottom' },
        datasets: [{
          data: d,
          backgroundColor: this.channel_colors,
          borderWidth: 0
        }]
      },
      options: {
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 25,
            radiusDecrease: 12
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 20,
            padding: 20
          }
        },
        aspectRatio: 0,
        cutoutPercentage: 70,
        responsive: false,
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
      },
      plugins: [plugin]
    });
  }

  // set channel color according to channel label names (working on saud 👇)
  private channelColors(l: any) {

    var colors = [];
    for (var i = 0; i < l.length; i++) {
      var color;
      switch (l[i]) {
        case 'booking':
          color = "#ff9e00";
          break;
        case 'booking.com':
          color = "#ff9e00";
          break;
        case 'website':
          color = "#9b5de5";
          break;
        case 'loyalty':
          color = '#f15bb5';
          break;
        case 'loyalty program':
          color = '#f15bb5';
          break;
        case 'walk-in':
          color = '#00f5d4';
          break;
        case 'agoda':
          color = '#cc2936';
          break;
        case 'airbnb':
          color = '#FF8700';
          break;
        case 'facebook':
          color = '#FFD300';
          break;
        case 'travelocity':
          color = '#DEFF0A';
          break;
        case 'ebookers':
          color = '#A1FF0A';
          break;
        case 'hotelscom':
          color = '#0AFF99';
          break;
        case 'expedia':
          color = '#0AEFFF';
          break;
        case 'trivago':
          color = '#147DF5';
          break;
        case 'ving':
          color = '#580AFF';
          break;
        case 'wotif':
          color = '#BE0AFF';
          break;
        //etc..
      }
      colors[i] = color;
    }
    return colors;
  }

  setOcc(d, l) {
    var colors = this.channelColors(l);
    let ctx = document.getElementById('occ1');
    const plugin = this.emptyDount();

    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: { position: 'bottom' },
        datasets: [{
          data: d,
          backgroundColor: colors,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 20,
            radiusDecrease: 10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:center;'>${amt} %</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      },
      plugins: [plugin]
    });
  }

  setAdr(d, l) {
    let restriction = this.restriction;
    let adr_per = this.adr_total;
    var colors = this.channelColors(l);

    let ctx = document.getElementById('ADR1');
    const plugin = this.emptyDount();
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: { position: 'bottom' },
        datasets: [{
          data: d,
          backgroundColor: colors,
          borderWidth: 0
        }]
      },
      // options: {
      //   legend: {
      //     display: false,
      //     position: 'bottom',
      //     labels: {
      //       boxWidth: 20,
      //       padding: 20
      //     }
      //   },
      //    aspectRatio: 0,
      //   cutoutPercentage: 60,
      //   responsive: false,
      //   title: {
      //     display: false,
      //   },
      //   animation: {
      //     animateScale: true,
      //     animateRotate: true
      //   }
      // }
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 20,
            radiusDecrease: 10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                console.log(n, 'body data');
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                let per = ((amt / adr_per) * 100).toFixed(2);
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:center;'>${restriction ? `${per}%` : (localStorage.getItem('currency') + amt)}</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      },
      plugins: [plugin]
    });
  }

  setRev(d, l) {
    let restriction = this.restriction;
    let rev_per = this.rev_total;
    var colors = this.channelColors(l);
    let ctx = document.getElementById('REV1');
    const plugin = this.emptyDount();
    let myChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: l,
        legend: { position: 'bottom' },
        datasets: [{
          data: d,
          backgroundColor: colors,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 20,
            radiusDecrease: 10
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                let per = ((amt / rev_per) * 100).toFixed(2);
                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 5px'>
                  <img src='assets/img/svgicons/all-channels/${re}.svg' style='width: 70px;border-bottom:1px solid ${colors.backgroundColor}'>
                  <div style='font-weight: bold; font-size: 16px;text-align:center;'>${restriction ? `${per}%` : (localStorage.getItem('currency') + amt)}</div>
                </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      },
      plugins: [plugin]
    });
  }

  //Earning by ROOm section  End

  //Generate PDF
  makePDF() {
    this.exte = false
    setTimeout(() => {
      let HTML_Width = this.capture.nativeElement.offsetWidth;
      let HTML_Height = this.capture.nativeElement.offsetHeight;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin);
      let PDF_Height = (PDF_Width) + (top_left_margin);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height);
      html2canvas(this.capture.nativeElement, { allowTaint: true }).then(function (canvas) {
        canvas.getContext('2d');
        let imgData = canvas.toDataURL();
        let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'SVG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(imgData, 'SVG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }
        pdf.save("Reports.pdf");

      });
      this.exte = true
    }, 1000)
  };

  //Generate PDF END
  chngeEx(img) {
    let a = img.split('.')
    return a[0]
  }

  drag() {
    // drag function start----------------------------------------------
    $(function () {
      setTimeout(function () {
        $(".drag_box").dragsort({
          dragSelector: ".headingOne0",
          dragBetween: true,
          dragEnd: () => {
            var data = $(".drag_box .drag_item").map(function () {
              return $(this).children().html();
            }).get();
          },
          placeHolderTemplate: "<div class='placeHolder' style='height:auto !important; width: 100%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='margin:3px;'>&nbsp;</div>" +
            "</div>",
          cursor: "grab"
        });
      }, 1000)
    });


    // drag function end---------------------------------------------
  }

  // getDivs(a){
  //   let f = $($( "#first div" ).first()).html();
  //   let b = $("#"+a).html();
  //   $('#'+a).html(f);
  //   $($( "#first div" ).first()).html(b);
  // }
  suma(a: any) {
    var sum = 0;
    a.forEach(element => {

      sum += element.occ;

    });

  }

  showChart23(val, n, c) {

    let restriction = this.restriction;
    let total_per = this.hotel_sum_total;

    var ctx = document.getElementById('myChart_hotel');
    const plugin = this.emptyDount();

    var myChart = new Chart(ctx, {
      type: 'pie',
      data:
      {
        labels: n,
        // legend: {position: 'bottom'},
        datasets: [{
          data: val,
          backgroundColor: c,
          borderWidth: 0
        }]
      },
      options: {
        cutoutPercentage: 70,
        plugins: {
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 30,
            radiusDecrease: 15
          }
        },
        legend: {
          display: false,
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,

          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);

              var innerHtml = "";

              titleLines.forEach(function (title) {
                innerHtml += "<div>" + title + "</div>";
              });
              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {
                var colors = tooltipModel.labelColors[i];
                let n = body[0];
                let re = n.split(':')[0].toLowerCase();
                let amt = n.split(':')[1];
                let per = (amt / total_per) * 100;

                innerHtml += `<div style='background: #ffffffc7 !important; border-radius:10px;border:1px solid ${colors.backgroundColor};padding:0px 3px 3px 3px'>
                <h6 style='width: 120px;border-bottom:1px solid;  font-weight: bold;  text-transform: capitalize; ${colors.backgroundColor};padding:0px 0px 3px 0px;'> ${re}</h6>
                <div style='font-weight: bold;  font-size: 16px;text-align:center; text-transform: capitalize; align-item: center; padding:0px 0px 3px 0px;'>${restriction ? (localStorage.getItem('currency') + amt) : `${per.toFixed(2)}%`}</div>
              </div>`;
              });
              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.left = Math.floor(parseInt(position.left + window.pageXOffset + tooltipModel.caretX / 100) - 30) + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        },
      },
      plugins: [plugin]
    });
  }

  calculateDiscountPercentage(room_type_name) {
    // debugger;
    let roomData = this.d_data.filter(x => x.room_type_name == room_type_name)[0];

    let diff = roomData.total_sum - roomData.total_discounted_price;
    let percentage = this.calculatePrecentage(roomData.total_discounted_price, roomData.total_sum)
    if (percentage == "NaN%") return "0%";
    return percentage;
  }


  getTotalRevPar(element) {
    // debugger;
    // changed from hotel_sum_total to ebr_total
    const val = (this.ebr_total / element.totalHotelInventory).toFixed(1)
    if (val == "NaN") return "0%";
    return val;
  }

  getTotalAvgNightStayedPerRoom(element) {
    let result = element.los / element.sold;
    return this.ensureNoInfinityOrNaN(result)

  }

  getTotalAdr(element) {
    // console.log(element,"element....");

    // changed from hotel_sum_total to ebr_total
    let result = this.ebr_total / element.los;
    return this.ensureNoInfinityOrNaN(result)
  }

  getTotalAvgLeadTime(element) {
    let result = this.hotel_avg_lead_total / element.sold;
    return this.ensureNoInfinityOrNaN(result);
  }

  calculatePrecentage(val, total) {
    // debugger;
    if (Number.isNaN(val) || Number.isNaN(total) || val == undefined || total == undefined) {
      return '0%'
    }
    let value = ((val) / (total)) * 100;
    if (!value) {
      return '0%'
    } else if (value >= 100) {
      return '100%'
    }
    else {
      return `${value.toFixed(2)}%`
    }
    //return `${((val)/(total))*100}%`;
  }

  ensureNoInfinityOrNaN(value) {
    if (value == Infinity || Number.isNaN(value)) {
      return 0
    } else {
      return value
    }
  }


  getPnlData() {

    let startDate = this.choosen_date.startDate.format("YYYY-MM-DD")
    let endDate = this.choosen_date.endDate.format("YYYY-MM-DD")
    this.pnlService.getDiscountTrackingGraph(startDate, endDate).subscribe(data => {
      this.DiscountGraphModel = data.data
      this.pnlPopulateDiscountTrackingCharts()
    }, err => {
      this.helper.alert_error(JSON.stringify(err))
    })
  }


  pnlPopulateDiscountTrackingCharts() {
    let currency: any = this.currency_format;
    let bgColors = [this.DiscountGraphModel.total.total_revenue > 0 ? '#00295F' : "#aaa", this.DiscountGraphModel.total.total_discount > 0 ? '#1CB5F9' : "#ddd"]
    let totalRev = this.DiscountGraphModel.total.total_revenue > 0 ? this.DiscountGraphModel.total.total_revenue : 1;
    let totalDiscount = this.DiscountGraphModel.total.total_discount > 0 ? this.DiscountGraphModel.total.total_discount : 1;
    let values = [totalRev, totalDiscount]

    const piedata = {
      labels: ['Revenue', 'Discount'],
      datasets: [{
        data: values,
        backgroundColor: bgColors,
        borderWidth: 0,
      }],

    };
    const pieconfig = {
      type: 'pie',
      data: piedata,
      options: {
        responsive: false,
        legend: {
          display: false,
          position: 'bottom',
          fullSize: true,
          labels: {
            boxWidth: 16,
            boxHeight: 16,
            fontSize: 15,
            textShadow: true,
            padding: 50,
            pointStyle: "rectRounded",
            textAlign: 'center',
          },

        },

        // plugins: {
        //  labels: {
        //  render: function (args) {
        //      //return sums.filter(x=> x.amount == args.value)[0].name +": "+currencyFormat+" "+ args.value
        //      return this.currencyFormat + ' ' + args.value;
        //     },
        //     fontColor: '#fff',

        //     position: 'default',
        //     fontSize: 12,
        //   }
        // },

      }
    };

    new Chart(
      document.getElementById('discount-tracking-canvas'),
      pieconfig
    );
  }

  calculateDiscountTrackingWidth(room, isDiscounted, offset = 0): string {

    let maxRegular = Math.max(...this.DiscountGraphModel.room_data.map(x => x.regular_count))
    let maxDiscounted = Math.max(...this.DiscountGraphModel.room_data.map(x => x.discount_count))
    let total = Math.max(maxRegular, maxDiscounted)
    let width = isDiscounted ? (room.discount_count / total) * 100 : (room.regular_count / total) * 100

    if (width < 20) {
      width = 20
    }
    return Number(width + offset) + '%'

  }

  // pagination graph

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  get_room_graph_data() {

    let a = {
      property_id: localStorage.getItem('current_property'),
      date_departure_from: this.choosen_date.startDate.format('YYYY-MM-DD'),
      date_departure_to: this.choosen_date.endDate.format('YYYY-MM-DD')
    };

    if (localStorage.getItem('current_property') > '0') {
      this.reportsService.getRoomGraphData(a).subscribe((res: GetRoomGraphDataResponse) => {
        let data = res.data;
        this.roomGraphData = data;
        this.room_names = this.roomGraphData.map(x => x.room_name);
      })
    }

  }

  // By Room Graph 👇

  byRoomGraph() {
    let roomDate = this.roomGraphData[0].data.map(x => x.date);
    const room_data = this.roomGraphData.map((data, index) => (
      {
        label: data.room_name,
        data: data.data.map(x => x.sold),
        fill: index == 0 ? true : false,
        borderColor: index == 0 ? 'rgba(0, 0, 0, 0.6)' : this.byRoomGraphColors[index],
        tension: 0.4,
        pointRadius: 0,
        backgroundColor: this.byRoomGraphColors[index],
      }
    ));

    const data = {
      labels: roomDate,
      datasets: room_data,
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: -10
          }
        },
        interaction: {
          mode: 'nearest',
          axis: 'xy'
        },
        tooltips: {
          // Disable the on-canvas tooltip
          enabled: false,
          axis: 'xy',
          mode: 'nearest',
          intersect: false,
          yAlign: 'top',
          xAlign: 'center',
          custom: function (tooltipModel) {

            // Tooltip Element
            var tooltipEl: any = document.getElementById('chartjs-tooltip');
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);
              var innerHtml = "";

              // titleLines.forEach(function (title) {
              // innerHtml += "<div>" + title + "</div>";
              // console.log(title);
              // });

              const d = new Date(titleLines);
              const mydate = moment(d).format("D MMM")

              innerHtml += "<div>";

              bodyLines.forEach(function (body, i) {

                var colors = tooltipModel.labelColors[i];
                let b = body[0];
                let re = b.split(':')[0];
                let amt = b.split(':')[1];
                // tooltip html and css design custom (working on saud 👇)
                innerHtml += `<div style='background: #ffffffc7 !important;border-radius:10px;border:1px solid ${colors.backgroundColor};padding:5px 8px'>
                  <label class="list-data-name"  style='white-space: nowrap; width: 100px;  overflow: hidden;text-overflow: ellipsis; font-size:16px;border-bottom:1px solid ${colors.backgroundColor}'>${re}</label>
                  <div style="display:flex;justify-content:space-between;">
                  <div style="display:flex;">
                  <img class="pull-left" src="assets/img/reports/checkin.png" />
                  <div style='font-weight: bold; font-size: 16px;text-align:left;padding-left:5px;'> ${amt}</div>
                  </div>
                  <span style=" font-weight: 600;font-size: 14px;font-family: 'robotoblack';">${mydate}</span>
                  </div>
                  </div>`;
              });

              innerHtml += "</div>";
              var tableRoot = tooltipEl.querySelector('.cus-box');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font

            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },

          // show tooltip fill background only (working on saud with the help of Mewan Sheikh 👇)

          filter: function (tooltipItem, data) {
            let selectedIndex;
            let roomData = data.datasets.map((x, index, arr) => {
              if (x.fill) {
                selectedIndex = index;
              }
            })

            if (tooltipItem.datasetIndex == selectedIndex) {
              return true;
            }
            else {
              return false;
            }

          }
        },

        scales: {
          xAxes: [{
            ticks: {
              display: false,
            }
          }],
          yAxes: [{
            // position: 'right',
            ticks: {
              display: false,
            }
          }]
        },

        legend: {
          display: false
        },

      }
    };

    let lineChart = new Chart(document.getElementById('byRoomGraph'), config);

    // set background color fill in room name click 👇

    this.room_names.forEach((element, index, arr) => {

      const labels = document.getElementById(element);

      const data_set = lineChart.data.datasets[index];
      const not_selected = lineChart.data.datasets.filter(x => x != data_set);

      labels.addEventListener('click', () => {
        data_set.fill = true;
        data_set.borderColor = 'rgba(0, 0, 0, 0.6)';
        lineChart.update();

        not_selected.forEach((element, i, a) => {
          element.fill = false;
          element.borderColor = this.byRoomGraphColors[i];
          lineChart.update();
        });
      });


    });
  }

  updateChoosenDateInStorage($event) {
    localStorage.setItem('reports-choosen-date', JSON.stringify(this.choosen_date))
  }

  loadByRoomGraphs() {
    // this.currentDay = true;
    if (this.currentDay) {
      this.getCurrentDayRoomGraph();
    }
    this.selectedRoomGraphsLoad();
    this.selectedChannelGraphLoad();
  }

  selectedChannelGraphLoad() {
    const delay = 100;
    let graphFunction;

    if (this.currentDay) {
      graphFunction = this.currentDayChannelGraph;
    } else {
      graphFunction = this.currentWeekChannelGraph;
    }

    setTimeout(graphFunction.bind(this), delay);
  }

  selectedRoomGraphsLoad() {
    const delay = 100;
    let graphFunction;

    if (this.selected_room === 'All Rooms') {
      graphFunction = this.currentDay ? this.currentDayAllRoomGraph : this.currentWeekAllRoomGraph;
    } else {
      graphFunction = this.currentDay ? this.currentDaySingleRoomGraph : this.currentWeekSingleRoomGraph;
    }

    setTimeout(graphFunction.bind(this), delay);
  }

  loadCurrentWeekGraph() {
    this.dates = this.getPreviousDays();
    this.currentDay = false;

    console.log(this.selected_room, this.selected_channel);

    if (this.current_donut == 'By Room') {
      if (this.selected_room == 'All Rooms') {
        setTimeout(() => {
          this.currentWeekAllRoomGraph()
        }, 100)
      } else if (this.selected_room != 'All Rooms') {
        setTimeout(() => {
          this.currentWeekSingleRoomGraph();
        }, 100)
      }
    }

    if (this.current_donut == 'By Channels') {
      if (this.selected_channel != '') {
        setTimeout(() => {
          this.currentWeekChannelGraph()
        }, 100)
      }
    }

  }

  getCurrentDayRoomGraph() {
    this.currentDay = true;
    var dom = document.getElementById('currentDayRoomGraph');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};

    var option;

    option = {
      grid: {
        show: true,
        left: "5%",
        right: "5%",
        top: '1%',
        bottom: '5%',
        borderWidth: 0
      },
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: true,
          color: this.darkMode ? 'lightgrey' : '#00205B',
          fontSize: 10,
          fontFamily: 'roboto',
          fontWeight: "bold"
        }
      },
      yAxis: {
        show: false
      },
      series: [
        {
          data: [80, 90, 65, 50, 70, 95, 110],
          type: 'bar',
          barWidth: 8,
          itemStyle: {
            borderRadius: 5,
            color: this.darkMode ? "#0077FF" : "#19B0FF"
          }
        }
      ]
    };

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => myChart.resize());

  }

  // by room graphs
  currentDaySingleRoomGraph() {
    var dom = document.getElementById('room-line-chart');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var option;

    option = {
      grid: {
        show: true,
        left: '0%',
        right: '5%',
        top: '1%',
        bottom: '5%',
        borderWidth: 0
      },
      xAxis: {
        type: 'category',
        data: ['12PM', '3PM', '6PM', '9PM', '12AM', '3AM', '6AM', '9AM'],
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLabel: {
          align: 'right',
          color: this.darkMode ? '#F7FF00' : '#00205b',
          margin: 12,
          padding: [0, 35, 0, 0],
          fontFamily: 'roboto',
          fontWeight: "bold",
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        }
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitLine: {
          show: false
        },
        axisLabel: {
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontWeight: 'bold',
          formatter: function (value: number, index: any) {
            if (value < 100) {
              return 'L';
            } else if (value > 500) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        splitNumber: 2,
        min: 0,
        max: 600,
      },
      series: [
        {
          data: [200, 350, 400, 480, 350, 370, 520, 320],
          type: 'line',
          smooth: true,
          symbolSize: [40, 40],
          symbol:
            'image://assets/img/svgicons/smallicons/walk-in.svg',
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#00205B',
            width: 3
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: "bold",
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@score}'
          },
          markLine: {
            label: {
              show: true,
              formatter: 'M',
              color: this.darkMode ? '#F7FF00' : '#00205b',
              fontFamily: 'roboto',
              fontWeight: 'bold'
            },
            data: [
              {
                yAxis: 310,
                lineStyle: {
                  color: this.darkMode ? '#0077FF' : '#19B0FF',
                  width: 2
                }
              }
            ]
          },
        }
      ]
    };


    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => myChart.resize());
  }

  currentDayAllRoomGraph() {
    var dom = document.getElementById('all-rooms-scatter-chart');

    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};

    var option;

    option = {
      grid: {
        show: true,
        left: '0%',
        right: '1.2%',
        top: '2%',
        bottom: '5%',
        borderWidth: 0,
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          align: 'right',
          color: this.darkMode ? '#F7FF00' : '#00205b',
          padding: [0, 30, 0, 0],
          fontFamily: 'roboto',
          fontWeight: 'bold'
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          },
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitNumber: 6,
        min: new Date('2024-04-26 00:00').getTime(),
        max: new Date('2024-04-26 23:59:59').getTime()
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitNumber: 2,
        axisLabel: {
          show: true,
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          verticalAlignMaxLabel: 'top',
          verticalAlignMinLabel: 'bottom',
          formatter: function (value) {
            if (value <= 0) {
              return 'L';
            } else if (value >= 100) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        splitLine: {
          show: false
        }
      },
      series: []
    };

    function generateRandomData(numPoints, channels) {
      const data = [];
      const maxTime = new Date('2024-04-26 23:59').getTime();
      const minTime = new Date('2024-04-26 1:00').getTime();

      for (let i = 0; i < numPoints; i++) {
        const timestamp = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
        const bookingCount = Math.floor(Math.random() * 100) + 1;
        const channel = channels[Math.floor(Math.random() * channels.length)];
        data.push([new Date(timestamp), bookingCount, channel]);
      }

      return data;
    }

    const roomList = this.roomList.filter(x => x != 'All Rooms')

    const data = generateRandomData(250, roomList);

    function distributeDataToColumns(data, numColumns) {
      const columns = [];
      for (let i = 0; i < numColumns; i++) {
        // Create 8 columns
        columns.push([]);
      }

      data.forEach((item) => {
        const columnIndex = Math.floor(Math.random() * numColumns);
        columns[columnIndex].push(item);
      });

      return columns;
    }

    const distributedData = distributeDataToColumns(data, 8);

    distributedData.forEach((columnData, index) => {
      const channel = roomList[index % roomList.length] || 'Unassigned';
      option.series.push({
        type: 'scatter',
        name: channel,
        smooth: true,
        symbol: 'circle',
        symbolSize: 15,
        data: columnData,
        color: this.roomsColorLight[index % this.roomsColorLight.length]
      });
    });

    option.series[0].markLine = {
      label: {
        show: true,
        formatter: 'M',
        color: this.darkMode ? '#F7FF00' : '#00205b',
        fontFamily: 'roboto',
        fontWeight: 'bold'
      },
      data: [
        {
          yAxis: 50,
          lineStyle: {
            color: this.darkMode ? '#0077FF' : '#FF0000',
            width: 2
          }
        }
      ]
    }

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => myChart.resize());
  }

  currentWeekSingleRoomGraph() {
    var dom = document.getElementById('current-week-single-room');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};

    var option;

    option = {
      grid: {
        show: true,
        containLabel: true,
        top: 5,
        left: 0,
        right: 0,
        bottom: 10
      },
      xAxis: {
        type: 'time',
        boundaryGap: true,
        axisLabel: {
          formatter: function (value) {
            return '3PM';
          },
          align: 'right',
          padding: [0, 30, 0, 0],
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold'
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        min: '2024-04-01 1:00',
        max: '2024-04-07 23:59'
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitNumber: 2,
        axisLabel: {
          show: true,
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          verticalAlignMaxLabel: 'top',
          verticalAlignMinLabel: 'bottom',
          formatter: function (value) {
            if (value <= 0) {
              return 'L';
            } else if (value >= 500) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        min: 0,
        max: 600,
        splitLine: {
          show: false
        }
      },
      series: [
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/facebook.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 3:00'), 110],
            [new Date('2024-04-02 18:00'), 150],
            [new Date('2024-04-03 22:00'), 300],
            [new Date('2024-04-04 3:00'), 130],
            [new Date('2024-04-05 19:00'), 200],
            [new Date('2024-04-07 10:00'), 180]
          ],
          markLine: {
            label: {
              show: true,
              formatter: 'M',
              color: this.darkMode ? '#F7FF00' : '#00205b',
              fontFamily: 'roboto',
              fontWeight: 'bold'
            },
            data: [
              {
                yAxis: 310,
                lineStyle: {
                  color: this.darkMode ? '#0077FF' : '#FF0000',
                  width: 2
                }
              }
            ]
          }
        },
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/expedia.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 18:30'), 200],
            [new Date('2024-04-02 12:30'), 250],
            [new Date('2024-04-03 12:30'), 350],
            [new Date('2024-04-04 12:00'), 240],
            [new Date('2024-04-05 4:30'), 240],
            [new Date('2024-04-07 2:30'), 260]
          ]
        },
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/ctrip.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 8:00'), 280],
            [new Date('2024-04-02 3:00'), 320],
            [new Date('2024-04-03 16:00'), 490],
            [new Date('2024-04-04 14:00'), 360],
            [new Date('2024-04-05 13:00'), 450],
            [new Date('2024-04-07 9:00'), 320]
          ]
        },
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/agoda.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 17:00'), 350],
            [new Date('2024-04-02 16:30'), 330],
            [new Date('2024-04-06 16:30'), 580],
            [new Date('2024-04-07 18:30'), 450]
          ]
        },
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/airbnb.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 11:00'), 370],
            [new Date('2024-04-02 22:00'), 340],
            [new Date('2024-04-06 8:00'), 180]
          ]
        },
        {
          type: 'scatter',
          smooth: true,
          symbol: 'image://assets/img/svgicons/smallicons/walk-in.svg',
          symbolSize: 40,
          z: 1000,
          itemStyle: {
            opacity: 1
          },
          label: {
            show: true,
            position: 'bottom',
            fontFamily: 'roboto',
            fontWeight: 'bold',
            distance: 2,
            color: 'rgba(176, 6, 6, 1)',
            formatter: '${@[1]}'
          },
          data: [
            [new Date('2024-04-01 20:30'), 450],
            [new Date('2024-04-06 16:30'), 340]
          ]
        }
      ]
    };

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => myChart.resize);
  }

  currentWeekAllRoomGraph() {
    var dom = document.getElementById('current-week-all-room');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};

    var option;

    option = {
      grid: {
        show: true,
        containLabel: true,
        top: 5,
        left: 0,
        right: 0,
        bottom: 10
      },
      xAxis: {
        type: 'time',
        boundaryGap: true,
        axisLabel: {
          formatter: function (value) {
            return '3PM';
          },
          align: 'right',
          padding: [0, 30, 0, 0],
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold'
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        min: '2024-04-01 1:00',
        max: '2024-04-07 23:59'
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitNumber: 2,
        min: 100 / 2,
        max: 520,
        axisLabel: {
          show: true,
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          verticalAlignMaxLabel: 'top',
          verticalAlignMinLabel: 'bottom',
          formatter: function (value) {
            if (value <= 50) {
              return 'L';
            } else if (value >= 500) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        splitLine: {
          show: false
        }
      },
      series: []
    };

    const seriesData = [
      [
        [new Date('2024-04-01 19:00'), 140],
        [new Date('2024-04-01 6:00'), 185],
        [new Date('2024-04-02 12:00'), 150],
        [new Date('2024-04-02 5:00'), 185],
        [new Date('2024-04-03 12:00'), 150],
        [new Date('2024-04-03 4:00'), 185],
        [new Date('2024-04-04 15:00'), 150],
        [new Date('2024-04-04 5:00'), 185],
        [new Date('2024-04-05 6:00'), 115],
        [new Date('2024-04-05 15:00'), 190],
        [new Date('2024-04-06 8:00'), 140],
        [new Date('2024-04-06 20:00'), 220],
        [new Date('2024-04-07 4:00'), 100],
        [new Date('2024-04-07 20:00'), 120]
      ],
      [
        [new Date('2024-04-01 18:30'), 200],
        [new Date('2024-04-01 8:30'), 250],
        [new Date('2024-04-02 15:30'), 200],
        [new Date('2024-04-02 8:30'), 250],
        [new Date('2024-04-03 14:30'), 200],
        [new Date('2024-04-03 4:00'), 320],
        [new Date('2024-04-04 20:00'), 200],
        [new Date('2024-04-04 13:00'), 290],
        [new Date('2024-04-05 4:30'), 250],
        [new Date('2024-04-05 19:00'), 280],
        [new Date('2024-04-06 8:00'), 260],
        [new Date('2024-04-06 3:00'), 310],
        [new Date('2024-04-07 11:30'), 150],
        [new Date('2024-04-07 4:00'), 185]
      ],
      [
        [new Date('2024-04-01 18:00'), 260],
        [new Date('2024-04-01 11:30'), 290],
        [new Date('2024-04-02 19:00'), 260],
        [new Date('2024-04-02 5:00'), 290],
        [new Date('2024-04-03 8:00'), 360],
        [new Date('2024-04-03 16:00'), 400],
        [new Date('2024-04-04 10:00'), 320],
        [new Date('2024-04-04 6:00'), 360],
        [new Date('2024-04-05 4:00'), 360],
        [new Date('2024-04-06 17:00'), 410],
        [new Date('2024-04-06 10:00'), 470],
        [new Date('2024-04-07 16:00'), 200],
        [new Date('2024-04-07 4:00'), 250]
      ],
      [
        [new Date('2024-04-01 18:00'), 320],
        [new Date('2024-04-01 12:30'), 360],
        [new Date('2024-04-02 10:30'), 320],
        [new Date('2024-04-02 7:30'), 360],
        [new Date('2024-04-03 6:00'), 450],
        [new Date('2024-04-03 18:00'), 490],
        [new Date('2024-04-04 20:00'), 400],
        [new Date('2024-04-04 7:00'), 450],
        [new Date('2024-04-07 18:30'), 290],
        [new Date('2024-04-07 12:00'), 310]
      ],
      [
        [new Date('2024-04-01 5:00'), 400],
        [new Date('2024-04-01 15:30'), 420],
        [new Date('2024-04-02 18:00'), 400],
        [new Date('2024-04-02 6:30'), 450],
        [new Date('2024-04-04 20:00'), 470],
        [new Date('2024-04-04 6:00'), 490],
        [new Date('2024-04-07 7:00'), 320],
        [new Date('2024-04-07 10:00'), 360]
      ],
      [
        [new Date('2024-04-01 7:30'), 450],
        [new Date('2024-04-01 18:30'), 470],
        [new Date('2024-04-02 16:30'), 490],
        [new Date('2024-04-07 19:00'), 400],
        [new Date('2024-04-07 10:00'), 420]
      ],
      [
        [new Date('2024-04-01 5:30'), 490],
        [new Date('2024-04-01 16:30'), 500],
        [new Date('2024-04-07 11:00'), 470],
        [new Date('2024-04-07 20:00'), 490],
        [new Date('2024-04-07 4:00'), 500]
      ]
    ];

    const gradients = [];

    for (let i = 0; i < this.roomsColorLight.length; i++) {
      gradients.push([
        { offset: 1, color: this.roomsColorLight[i] },
        { offset: 0, color: this.roomsColorDark[i] }
      ]);
    }

    const roomList = this.roomList.filter(x => x != 'All Rooms')

    for (let i = 0; i < seriesData.length; i++) {
      option.series.push({
        name: roomList[i],
        type: 'scatter',
        smooth: true,
        symbol:
          'path://M 0,10 Q 0,0 10,0 L 40,0 Q 50,0 50,10 L 50,30 Q 50,40 40,40 L 10,40 Q 0,40 0,30 L 0,10 Z',
        symbolSize: [60, 20],
        label: {
          show: true,
          position: 'inside',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          distance: 2,
          color: '#fff',
          formatter: '${@[1]}'
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, gradients[i]),
          opacity: 1
        },
        data: seriesData[i],
        z: 5
      });
    }

    option.series[0].markLine = {
      label: {
        show: true,
        formatter: 'M',
        color: this.darkMode ? '#F7FF00' : '#00205b',
        fontFamily: 'roboto',
        fontWeight: 'bold'
      },
      data: [
        {
          yAxis: 300,
          lineStyle: {
            color: this.darkMode ? '#0077FF' : '#FF0000',
            width: 2
          }
        }
      ]
    }

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => myChart.resize);
  }

  // by channels graph
  currentDayChannelGraph() {
    var dom = document.getElementById('currentDayAllChannel');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var option;
    option = {
      grid: {
        show: true,
        containLabel: true,
        top: 5,
        left: 0,
        right: 0,
        bottom: 5
      },
      xAxis: {
        type: 'time',
        boundaryGap: true,
        axisLabel: {
          formatter: function (value) {
            return '3PM';
          },
          align: 'right',
          padding: [0, 30, 0, 0],
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold'
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        min: '2024-04-01 1:00',
        max: '2024-04-01 23:59'
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitNumber: 2,
        axisLabel: {
          show: true,
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          verticalAlignMaxLabel: 'top',
          verticalAlignMinLabel: 'bottom',
          formatter: function (value) {
            if (value <= 0) {
              return 'L';
            } else if (value > 300) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        splitLine: {
          show: false
        },
        min: 0,
        max: 600
      },
      series: []
    };

    function randomTime(start, end) {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      );
    }

    function randomPrice(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    let channels = [
      'facebook',
      'agoda',
      'caa',
      'expedia',
      'ctrip',
      'loyalty',
      'airbnb',
      'booking',
      'canada',
      'home_away',
      'gta',
      'trivago',
      'hotwire',
      'orbitz',
      'ebookers'
    ];

    if (this.selected_channel == 'All Channels') {
      channels = channels;
    } else {
      channels = [this.selected_channel.toLowerCase()];
    }

    option.series = [];

    for (let i = 0; i < channels.length; i++) {
      option.series.push({
        name: channels[i],
        type: 'scatter',
        smooth: true,
        z: 5,
        symbolSize: 40,
        symbol:
          'image://assets/img/svgicons/smallicons/' +
          channels[i] +
          '.svg',
        data: [
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(100, 600)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(100, 500)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(100, 300)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(150, 290)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(10, 100)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(30, 380)
          ],
          [
            randomTime(new Date('2024-04-01 01:00'), new Date('2024-04-01 23:59')),
            randomPrice(10, 490)
          ]
        ],
        itemStyle: {
          opacity: 1
        }
      });
    }

    // add markline only first series object
    option.series[0].markLine = {
      label: {
        show: true,
        formatter: 'M',
        color: this.darkMode ? '#F7FF00' : '#00205b',
        fontFamily: 'roboto',
        fontWeight: 'bold'
      },
      data: [
        {
          yAxis: 300,
          lineStyle: {
            color: this.darkMode ? '#0077FF' : '#FF0000',
            width: 2
          }
        }
      ]
    }

    console.log(option.series, "option series");

    myChart.clear();

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => myChart.resize());

  }

  currentWeekChannelGraph() {
    var dom = document.getElementById('currentWeekAllChannel');
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};

    var option;

    const seriesData = [
      {
        name: 'facebook',
        data: [
          [new Date('2024-04-01 3:00'), 110],
          [new Date('2024-04-02 18:00'), 150],
          [new Date('2024-04-03 22:00'), 300],
          [new Date('2024-04-04 3:00'), 130],
          [new Date('2024-04-05 19:00'), 200],
          [new Date('2024-04-07 10:00'), 180]
        ]
      },
      {
        name: 'expedia',
        data: [
          [new Date('2024-04-01 18:30'), 200],
          [new Date('2024-04-02 12:30'), 250],
          [new Date('2024-04-03 12:30'), 350],
          [new Date('2024-04-04 12:00'), 240],
          [new Date('2024-04-05 4:30'), 240],
          [new Date('2024-04-07 2:30'), 260]
        ]
      },
      {
        name: 'walk-in',
        data: [
          [new Date('2024-04-01 8:00'), 280],
          [new Date('2024-04-02 3:00'), 320],
          [new Date('2024-04-03 16:00'), 490],
          [new Date('2024-04-04 14:00'), 360],
          [new Date('2024-04-05 13:00'), 450],
          [new Date('2024-04-07 9:00'), 320]
        ]
      },
      {
        name: 'website',
        data: [
          [new Date('2024-04-01 17:00'), 350],
          [new Date('2024-04-02 16:30'), 330],
          [new Date('2024-04-06 16:30'), 580],
          [new Date('2024-04-07 18:30'), 450]
        ]
      },
      {
        name: 'agoda',
        data: [
          [new Date('2024-04-01 11:00'), 370],
          [new Date('2024-04-02 22:00'), 340],
          [new Date('2024-04-06 8:00'), 180]
        ]
      },
      {
        name: 'loyalty',
        data: [
          [new Date('2024-04-01 20:30'), 450],
          [new Date('2024-04-06 16:30'), 340]
        ]
      }
    ];

    option = {
      grid: {
        show: true,
        containLabel: true,
        top: 5,
        left: 0,
        right: 0,
        bottom: 10
      },
      xAxis: {
        type: 'time',
        boundaryGap: true,
        axisLabel: {
          formatter: function (value) {
            return '3PM';
          },
          align: 'right',
          padding: [0, 30, 0, 0],
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold'
        },
        axisTick: {
          length: 25,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        axisLine: {
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.darkMode ? '#FE6601' : '#FF6161',
            width: 2
          }
        },
        min: '2024-04-01 1:00',
        max: '2024-04-07 23:59'
      },
      yAxis: {
        type: 'value',
        position: 'right',
        splitNumber: 2,
        axisLabel: {
          show: true,
          color: this.darkMode ? '#F7FF00' : '#00205b',
          fontFamily: 'roboto',
          fontWeight: 'bold',
          verticalAlignMaxLabel: 'top',
          verticalAlignMinLabel: 'bottom',
          formatter: function (value) {
            if (value <= 0) {
              return 'L';
            } else if (value >= 500) {
              return 'H';
            } else {
              return '';
            }
          }
        },
        splitLine: {
          show: false
        },
        min: 0,
        max: 500
      },
      series: []
    };

    let filterChannel = seriesData.filter((x) => x.name === this.selected_channel.toLowerCase());
    console.log(this.selected_channel, filterChannel);

    if (this.selected_channel === 'All Channels') {
      filterChannel = seriesData;
    }

    for (const data of filterChannel) {
      option.series.push({
        name: data.name,
        type: 'scatter',
        smooth: true,
        z: 5,
        symbol: `image://assets/img/svgicons/smallicons/${data.name}.svg`,
        symbolSize: 40,
        data: data.data,
        itemStyle: {
          opacity: 1
        }
      });
    }

    option.series[0].markLine = {
      label: {
        show: true,
        formatter: 'M',
        color: this.darkMode ? '#F7FF00' : '#00205b',
        fontFamily: 'roboto',
        fontWeight: 'bold'
      },
      data: [
        {
          yAxis: 250,
          lineStyle: {
            color: this.darkMode ? '#0077FF' : '#FF0000',
            width: 2
          }
        }
      ]
    }

    myChart.clear();

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener('resize', () => myChart.resize);
  }

  getPreviousDays() {
    const currentDay = moment();
    const previousDays = [];
    for (let i = 0; i < 7; i++) {
      previousDays.unshift(currentDay.clone().subtract(i, 'days'));
    }
    return previousDays;
  }

  getClass(date: moment.Moment) {
    return {
      'redBar': moment().isSame(date, 'day'),
      'orangeBar': !moment().isSame(date, 'day')
    };
  }

  changeGraph(event: string, type: string) {
    console.log(event, 'event => ', type);
    if (type == 'room') {
      this.selected_room = event;
      this.selectedRoomGraphsLoad();
    } else if (type == 'channel') {
      this.selectedChannelGraphLoad();
    }
  }

  loadCurrentDayGraph(date: moment.Moment) {
    if (moment().isSame(date, 'day')) {
      this.currentDay = true;
      setTimeout(() => {
        // this.loadByRoomGraphs()
      }, 500);
    }
  }

}




