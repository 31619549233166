<div class="" id="SSS">
  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
    <a routerLink="setup_restriction" class="btn btn-link active resw"> <span class="abc">{{ service.is_edit ? "EDIT" :
        "SETUP" }} RESTRICTIONS</span></a>
  </div>
  <div class="no_scroll scroll">
    <form [formGroup]="setup_restrictions" (ngSubmit)="onSubmit()">
      <div class="" id="SP">
        <div class="">
          <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 grid_20 padding-rg" style="padding-right: 0px;">
                    <div class="incremental">
                      <div class="border-style minimum-stay-shade">
                        <div class="input-group">
                          <div class="input-label">
                            <input type="text" class="form-control" placeholder="Minimum Stay" min="1" name="min_stay"
                              formControlName="min_stay" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" />
                            <label>Minimum Stay</label>
                          </div>
                          <div class="input-group-addon addon-border addon-borders"
                            [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                            <a class="btn" (click)="increment('min_stay')"><i class="fa fa-plus sign_plus"
                                [ngClass]="darkMode ? 'sign_plus_dark':'sign_plus'"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders"
                            [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                            <a class="btn" (click)="decrement('min_stay')"><i class="fa fa-minus sign_plus"
                                [ngClass]="darkMode ? 'sign_plus_dark':'sign_plus'"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 grid_20 padding-lg">
                    <div class="incremental">
                      <div class="border-style maximum-stay-shade">
                        <div class="input-group">
                          <div class="input-label">
                            <input type="text" class="form-control" placeholder="Maximum Stay" name="max_stay"
                              formControlName="max_stay" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" />
                            <label>Maximum Stay</label>
                          </div>
                          <div class="input-group-addon addon-border addon-borders"
                            [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                            <a class="btn" (click)="increment('max_stay')"><i class="fa fa-plus"
                                [ngClass]="darkMode ? 'sign_plus_dark':'sign_plus'"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders"
                            [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                            <a class="btn" (click)="decrement('max_stay')"><i class="fa fa-minus"
                                [ngClass]="darkMode ? 'sign_plus_dark':'sign_plus'"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gradient-bar adj" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()"
            [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            <h4>Hotel Cancellation Policy</h4>
          </div>

          <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="row">
              <div class="col-md-12">
                <div class="row field-space">
                  <div class="col-md-6 check padding-rg" style="padding-right: 0px;">
                    <div class="border-style days-check-shade">
                      <div class="input-label">
                        <input type="text" class="form-control" min="1" placeholder="Days Before Check In"
                          maxlength="10" required name="cancellation_days" formControlName="cancellation_days"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" style="width: 73%;" />
                        <label>Days Before Check In</label>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.cancellation_days.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.cancellation_days.errors.required">Please enter days before check in</strong>
                    </div>
                  </div>

                  <div class="col-md-3 check padding-rg" style="padding-right: 0px;">
                    <div class="border-style penalty-shade">
                      <ng-select [(ngModel)]="cancellaction_type" name="cancellaction_type" bindLabel="label"
                        bindValue="label" placeholder="Select Method" name="cancellaction_type"
                        formControlName="cancellaction_type"
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                        <ng-option value="percentage">Percentage</ng-option>
                        <ng-option value="fixed">Fixed Amount</ng-option>
                        <ng-option value="no_refund">No Refund</ng-option>
                        <ng-option value="full_refund">Full Refund</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && f.cancellaction_type.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.cancellaction_type.errors.required">Please select penalty method</strong>
                    </div>
                  </div>

                  <div class="col-md-3 check padding-rg"
                    *ngIf="setup_restrictions.value.cancellaction_type == 'percentage' || setup_restrictions.value.cancellaction_type == 'fixed'">
                    <div class="border-style" style="border-color:darkcyan">
                      <input type="number" class="form-control" min="1" placeholder="Enter Value" required
                        name="cancellation_value" formControlName="cancellation_value"
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" />
                    </div>
                    <div *ngIf="submitted && f.cancellation_value.errors"
                      class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.cancellation_value.errors.required">Enter Value</strong>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            <!-- <h4>{{ 'rates_and_packages.setup_restriction.apply_to_channel' | translate }}</h4> -->
            <div class="col-md-10 zero-padding" style="width: 88%;">
              <h4 style="padding-top: 10px !important;padding-right: 0px !important;" id="SSS" (mouseover)="dodrag2()"
                (mouseout)="stopDrag2()">OTA Policy<div class="tool-policy"><i class="fas fa-info-circle">
                    <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                      style="    left: 69.7%;">
                      <p style="margin-bottom: 0px;">Cancellation and prepayment policies vary according to OTA type &
                        maybe different from your hotel Cancellation policy. Check what conditions apply to your
                        preferred OTA.</p>
                      <!-- <p style="margin-bottom: 0px;margin-top: -10px;">{{ 'rates_and_packages.link_channel.what_con' | translate }}.</p> -->
                    </span>
                  </i> <span></span></div>
              </h4>
            </div>
            <div class="col-md-2 select-all" style="width: 11%;">
              <label class="control-label checkbox-style" style="margin-left: 6px">
                <input type="checkbox" class="checkbox" id="selectAll" (change)="selectAll($event)"
                  [checked]="selectAllCheckBox">
                <span class="checkbox-checkmark"></span>
                <span class="permission-title">Select All</span>
              </label>
            </div>
          </div>

          <div class="white_box channels w-clearfix channel_padding"
            [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

            <div class="col-md-12"
              style="padding-right: 5px;padding-top: 0px;padding-left: 0px;padding-left: 0px;    margin-top: -2px;">
              <div *ngFor="let channel of channels; let i=index" class="col-md-4 csw"
                style="padding-right: 0px !important">
                <div class="channel_container clr_{{ channel?.image.split('.')[0] }} w-clearfix" style="display: flex;"
                  [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                  <div class="form_container">
                    <div class="form-block w-clearfix w-form">
                      <div class="form">
                        <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                          <input type="checkbox" name="cancelation_channels" value="{{ channel?.id }}"
                            (change)="setChannel(channel?.id, $event)" [checked]="channel?.is_checked">
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor" /> -->
                  <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + channel.image : channel.image}}" alt=""
                    class="tripadvisor ota-icon" />
                </div>
              </div>
            </div>

          </div>



          <div class="gradient-bar adj t22" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            <div class="col-md-10 zero-padding" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()"
              style="width: 88%;">
              <h4 style="padding-top: 10px !important;padding-right: 0px !important;">Channel Policy <div
                  class="tool-policy"><i class="fas fa-info-circle">
                    <span class="tooltiptext t2" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                      style="left: 62.7%;">
                      <p style="margin-bottom: 0px;">Cancellation and prepayment policies vary according to the
                        agreement you made with channels. Check what conditions apply to your preferred channel.</p>
                      <!-- <p style="margin-bottom: 0px;margin-top: -10px;">{{ 'rates_and_packages.link_channel.your_pre' | translate }}.</p> -->
                    </span>
                  </i> <span></span></div>
              </h4>
            </div>
            <div class="col-md-2 select-all" style="width: 11%;">
              <label class="control-label checkbox-style" style="margin-left: 6px">
                <input type="checkbox" class="checkbox" id="selectAll2" (change)="selectAll2($event)"
                  [checked]="selectAllCheckBox2">
                <span class="checkbox-checkmark"></span>
                <span class="permission-title">Select All</span>
              </label>
            </div>
          </div>

          <div class="white_box channels w-clearfix channel_padding"
            [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

            <div class="col-md-12" style="padding-right: 5px;padding-top: 0px;padding-left: 0px;    margin-top: -2px;">
              <div *ngFor="let channel of channels2; let i=index" class="col-md-4 csw"
                style="padding-right: 0px !important">
                <div class="channel_container clr_{{ channel?.image.split('.')[0] }} w-clearfix" style="display: flex;"
                  [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                  <div class="form_container">
                    <div class="form-block w-clearfix w-form">
                      <div class="form">
                        <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                          <input type="checkbox" name="cancelation_channels" value="{{ channel?.id }}"
                            (change)="setChannel(channel?.id, $event)" [checked]="channel?.is_checked">
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor" /> -->
                  <img src="assets/img/svgicons/channels/{{ channel.image }}" alt=""
                    class="tripadvisor ota-icon {{ channel?.name }}" />
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
      <div class="modal-footer" id="ModalFooterP" style="    bottom: -50px;right: 10px!important">
        <a class="btn btn-primary btn-lg btn-hov" type="button" (click)="onSubmit()"
          [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">Save</a>
        <a class="btn btn-danger btn-lg btn-hov" type="button" data-dismiss="modal" aria-label="Close"
          [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">Cancel</a>
      </div>
    </form>
  </div>