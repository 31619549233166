<div class="filter-box" [ngClass]="darkMode ? 'bredcurm-dark' : 'null'">
  <form class="form-inline">
    <div class="row no-gutters">
      <div class="col-md-12">
        <div class="col-md-7">
          <h1 class="page-title">Change Log</h1>
        </div>
        <div class="col-md-1 filters_col">
          <ng-select name="medium" [(ngModel)]="filter.medium" (change)="set_filter()"
            class="form-control input_2 fntsz" placeholder="By Medium">
            <ng-option value="Desktop">Desktop</ng-option>
            <ng-option value="Mobile">Mobile</ng-option>
            <ng-option value="Guest App">Guest App</ng-option>
          </ng-select>
        </div>
        <div class="col-md-1 filters_col">
          <ng-select [(ngModel)]="filter.table_name" name="byCategory" bindLabel="name" bindValue="id"
            class="form-control input_2 fntsz" placeholder="By Category" (change)="set_filter()">
            <ng-option value="reservations,reservation_payments,bookings,guests,invoices">Reservation</ng-option>
            <ng-option value="rooms,room_galleries,room_types">Rooms</ng-option>
            <ng-option value="users,user_keynotes,user_notifications,user_security_questions,role_user">User</ng-option>
            <ng-option value="room_amenities,hotel_amenities,amenities">Amenities</ng-option>
            <ng-option value="subscriptions,subscription_plans">Subscription</ng-option>
            <ng-option value="room_rates,channels">Rate Changes</ng-option>
            <ng-option value="users">User Login</ng-option>
            <ng-option value="notifications,user_notifications">Notifications</ng-option>
          </ng-select>
        </div>
        <div class="col-md-1 filters_col">
          <ng-select [(ngModel)]="filter.userId" name="byUser" class="form-control input_2 fntsz" placeholder="By User"
            [items]="users" bindLabel="first_name" bindValue="id" (change)="set_filter()"></ng-select>
        </div>
        <div class="col-md-1 filters_col">
          <input name="date" type="text" [(ngModel)]="filter._date" ngxDaterangepickerMd
            class="form-control date_range_picker" [autoApply]="true" placeholder="By Date"
            [locale]="{format: 'DD MMM YYYY'}" [singleDatePicker]="true" (change)="set_filter()"
            style="padding: 0; text-align:center">
        </div>
        <div class="col-md-1 filters_col">
          <button class="btn btn-block resetBtn" (click)="reset()">Reset</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card card-table table-responsive" [ngClass]="darkMode ? 'card-dark' : 'card'">
  <ul class="blue-ul">
    <li><a>Category</a></li>
    <li style="width:24%"><a>Change Type</a></li>
    <li><a>Before</a></li>
    <li><a>After</a></li>
    <li><a>User</a></li>
    <li><a>Medium</a></li>
    <li style="border-right: none; width: 14%;"><a>Date Time</a></li>
  </ul>
  <div class="overflow">
    <ng-container *ngIf="table_data.length == 0">
      <ul class="body-ul skeleton-flex skeleton-animation" *ngFor="let x of getEmptyElements(16); let i = index">
        <li style="width: 12%;">
          <div class="skeleton-box" [ngClass]="getGreyType(i)" style="width: 12%;"></div>
        </li>
        <li style="width: 24%;">
          <div class="skeleton-box" [ngClass]="getGreyType(i+1)" style="width: 75%!important;"></div>
        </li>
        <li style="width: 12%">
          <div class="skeleton-box" [ngClass]="getGreyType(i+2)" style="width: 12%;"></div>
        </li>
        <li style="width: 12%">
          <div class="skeleton-box" [ngClass]="getGreyType(i)" style="width: 12%;"></div>
        </li>
        <li style="width: 12%">
          <div class="skeleton-box" [ngClass]="getGreyType(i+1)" style="width: 12%;"></div>
        </li>
        <li style="width: 12%">
          <div class="skeleton-box" [ngClass]="getGreyType(i+2)" style="width: 12%;"></div>
        </li>
        <li style="width: 14%">
          <div class="skeleton-box" [ngClass]="getGreyType(i+2)" style="width: 14%;"></div>
        </li>
      </ul>
    </ng-container>

    <ul class="body-ul dark-log" *ngFor="let row of table_data?.data; let i=index"
      [ngStyle]="{'border-color': colors[i%colors.length]}" [ngClass]="darkMode ? 'orangeHov' : 'greyHov'">
      <li style="width: 12%;word-break: break-all;"><a>{{ tc(row.table_name) | titlecase }}</a></li>
      <li style="width: 24%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding: 0px 17px;"
        data-toggle="tooltip" title="{{ row.description }}"><a>{{ row.description }}</a></li>

      <li class="new-booking" data-toggle="tooltip" title="{{ row.before_change }}"><a>{{ row.before_change }}</a>
      </li>
      <li class="after-change" data-toggle="tooltip" title="{{ row.after_change }}"><a> {{ row.after_change }}</a>
      </li>
      <li style="width: 12.4%"><a>{{ row.user.first_name }}</a></li>
      <li style="width: 12%"><a>{{ row.medium }}</a></li>
      <li style="width: 14%"><a>{{ row.created_date }} | {{ row.created_time }}</a></li>
    </ul>
    <nav aria-label="Page navigation example" style="text-align: center;" id="paginav" *ngIf="page_arr.length > 1">
      <div style="margin-left: 45%;">
        <div style="float: left;">
          <ul class="pagination" id="myDIVActive">
            <li class="page-item">
              <a class="page-link" aria-label="Previous"
                style="font-size: 20px; height: 32px; padding: 1px; padding-right: 10px; padding-left:10px; margin-right:5px"
                (click)="switch_page('firstPage')">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
          </ul>
        </div>
        <div style="float: left;">
          <ul class="pagination" id="myDIVActive">
            <li class="page-item">
              <a class="page-link" aria-label="Previous"
                style="font-size: 20px; height: 32px; padding: 1px; padding-right: 10px; padding-left: 10px; margin-right:5px"
                (click)="switch_page('back')">
                <span aria-hidden="true">&lsaquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
          </ul>
        </div>

        <div style="float: left;" class="cuspagidiv" id="cuspagidiv1">
          <div class="page-item cusPagi" *ngFor="let current_page of page_arr" (click)="switch_page(current_page+1)"
            [ngClass]="{'active': page == current_page+1}"><a class="page-link">{{ current_page+1 }}</a></div>
        </div>

        <div style="float: left;">
          <ul class="pagination" id="myDIVActive">
            <li class="page-item">
              <a class="page-link" aria-label="Next"
                style="font-size: 20px; height: 32px; padding: 1px; padding-left: 10px; padding-right: 10px;margin-left:5px"
                (click)="switch_page('forward')">
                <span aria-hidden="true">&rsaquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </div>
        <div style="float: left;">
          <ul class="pagination" id="myDIVActive">
            <li class="page-item">
              <a class="page-link" aria-label="Next"
                style="font-size: 20px; height: 32px; padding: 1px; padding-left: 10px; padding-right: 10px; margin-left:5px"
                (click)="switch_page('lastPage')">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <p *ngIf="page_arr.length == 0" class="no-record">No Record Found.</p>
  </div>
</div>
