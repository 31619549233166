import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from './dashboard.service';
import { first } from 'rxjs/operators';
// import { Observable, Subscription} from 'rxjs';
import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/observable/forkJoin';
import { forkJoin } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
declare var $: any;
import * as moment from 'moment';
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import * as CanvasJS from '../../../assets/js/canvasjs.min';
import { ToastrService } from 'ngx-toastr';
import { PnlService } from 'src/app/services/pnl.service';
import { NotificatioResponse } from 'src/app/common/notifications/NotificationModal';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  darkMode: boolean = false;
  user = JSON.parse(localStorage.getItem('user'));
  general = false;
  temp: number;
  city: string;
  state: string;
  capitals = [];
  selectedCity;
  cardCity;
  showNote = false;
  followedCM = false;
  sub1;
  reservations;
  hum: number;
  wind: number;
  today: string;
  daysForecast: Object;
  cityIllustrationPath: string;
  sub2: Subscription;
  errorMessage: string;
  tweets$: Observable<any>;
  todayDate;
  DayState;
  req;
  forcast;
  currency_format = localStorage.getItem('currency');

  Performance = 'false';
  notifications;
  booking_revenue;
  all_arrivals;
  all_channels;
  event_images;
  dashboard: any;
  graphDays = [
    { val: 'today', days: 'Today' },
    { val: 'last_30_days', days: 'This Month' },
    { val: 'last_15_days', days: 'Previous Month' },
  ];
  graphDaysFrontDesk = [
    { val: 'today', days: 'Today' },
    { val: 'last_30_days', days: 'This Month' },
    { val: 'next_15_days', days: 'Next Month' },
    { val: 'previous_month', days: 'Previous Month' },
    { val: 'today', days: 'Today' },
    { val: 'last_30_days', days: 'This Month' },
    { val: 'next_15_days', days: 'Next Month' },
  ];
  graphDaysP = 'today';
  frontDesk: any;
  speedoColor = '7ea5ff'
  housekeepingData;
  weather_update = [];
  weather_classes = ['txt-orangered', 'txt-purple', 'txt-skyblue', 'txt-green', 'txt-blue', 'txt-orange', 'txt-aqua'];
  colors2 = ['#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  border_colors = ['#f53232', '#0fdc96', 'green', 'yellow', 'purple', 'orange', 'blue', '#00a6ff'];
  colors = ['#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  color_border = ['#f53232', '#000000', 'green', 'purple', 'orange', 'blue', 'olive'];
  color_borders_dark = ['#ff5e57', '#a07cc6', '#6af686', '#48fff3', '#b3bf00'];
  performanceData;
  cardLayout;
  graphDaysPFrontDesk;
  dashboard_skeleton: boolean = true;
  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    public weather: DashboardService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private pnl: PnlService,
  ) { }

  ngOnInit() {

    this.checkDarkMode();
    this.getWH();
    this.randomWeather();
    // this.permissions();
    this.getDashboardData(this.graphDaysP);

    // drap and drop function ---------------------------------------
    $(function () {
      setTimeout(function () {
        $("#drag_box").dragsort({
          dragSelector: ".win_header",
          dragBetween: true,
          cursor: "grab",
          dragEnd: () => {
            var data = $("#drag_box .drag_item").map(function () {
              return $(this).children().html();
            }).get();
            var drag_item = document.getElementsByClassName('drag_item');
            var d = [];
            for (let i = 0; i < 6; i++) {
              d.push({ "cardKey": drag_item[i].getAttribute('index'), cardName: drag_item[i].getAttribute('index-data') });
            }

            localStorage.setItem("CardLayout", JSON.stringify(d));
            var settings = {
              "url": " https://uat.cgslhcm.com/pmsbackend_8/api/user/sorting",
              "method": "POST",
              "timeout": 0,
              "headers": {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('pms_auth_token'),
              },
              "data": JSON.stringify({
                "type": "Dashboard",
                "data": d
              }),
            };

            $.ajax(settings).done(function (response) {
              // console.log(response);
            });
          },
          placeHolderTemplate: "<li class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: transparent !important; height: 100%!important'>&nbsp;</div>" +
            "</li>",

        });
        $('#box4').flipbox({
          vertical: false,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 5000,
          autoplayPauseOnHover: true
        });
      }, 1000)

      $(".drag_item").mouseup(function () {
        $(".win_header").css({ "cursor": "grab" })
      });
      $(".drag_item").mousedown(function () {
        $(".win_header").css({ "cursor": "grabbing" })
      });

    });

    this.cardLayout_drag()

    // drag function end -------------------------------------------------
    // Fetch initial notification
    this.pnl.getNotifications().subscribe(response => {
      this.pnl.notification$.next(response.data.notification);
    });

  }

  randomWeather() {
    // $.ajax({
    //   url: "https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=32.800870,-96.830803&radius=400&key=AIzaSyCk_GW7007XrPNapWIy5Ix7x8YtNlyF3eU",
    //   context: document.body,
    //   crossDomain: true,
    // }).done(function (res) {
    //   console.log(res);
    // })
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
      this.speedoColor = 'E2125D'
    }
  }

  getWH() {
    var cities: any = '';
    var prop; cities = null != JSON.parse(localStorage.getItem("property")) ? [(prop = JSON.parse(localStorage.getItem("property"))).city] : ["Toronto"];
    cities.forEach(city => {
      let req = 'https://api.openweathermap.org/data/2.5/weather?q=' + city + '&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1';
      let forcast = 'https://api.openweathermap.org/data/2.5/forecast/daily?q=' + city + '&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4';
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split('.')[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString('en-us', { weekday: 'short' }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: []
        }

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach(element => {
            var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', { weekday: 'short' });
            var icon = element.weather[0].icon.split('.')[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });

    // this.req = $.getJSON('https://api.openweathermap.org/data/2.5/weather?q='+ user.city_name +'&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1');
    // this.forcast = $.getJSON('https://api.openweathermap.org/data/2.5/forecast/daily?q='+ user.city_name +'&cnt=7&lang=en&units=metric&APPID=c0c4a4b4047b97ebc5948ac9c48c0559');
    // // console.log(forcast);
    // this.req.then(function (resp) {
    //   $(".weather-title").html(resp.name);
    //   // console.log(resp);
    //   var prefix = 'wi wi-';
    //   var code = resp.weather[0].id;
    //   //   var icon = weatherIcons[code].icon;
    //   // var icon ="https://api.openweathermap.org/img/w/"+resp.weather[0].icon+".png";
    //   var icon = resp.weather[0].icon.split('.')[0];
    //   // console.log(icon)
    //   // $(".sky").append("<img src="+icon+'  class="responsive" style="height: 50px;"/>')
    //   // $(".main_body_sec").append("<style type='text/css'> .wi-current:before { content: '\\f" + icon + "'; } </style>");
    //   $(".weather-sky").append("<i class='wi wi-main wi-"+icon+"'></i>");
    //   $(".weather_con .con_d").html(resp.weather[0].main)
    //   $(".weather-wind").html(" Wind: " + resp.wind.speed + ' m/s');
    //   // $(".weather").append("<br/> Temperature " + resp.main.temp + ' m/s');
    //   $(".weather-tm").html(Math.ceil(Number(resp.main.temp)))
    //   var options = { weekday: 'short' };
    //   var day = new Date().toLocaleTimeString('en-us', options);
    //   var setActive = day.split(" ")[0].toLowerCase();
    //   // console.log(setActive)
    //   $("." + setActive + '_min').html(resp.main.temp_min)
    //   $("." + setActive + '_max').html(resp.main.temp_max)
    //   // If we are not in the ranges mentioned above, add a day/night prefix.
    //   if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
    //     icon = 'day-' + icon;
    //   }

    //   // Finally tack on the prefix.
    //   icon = prefix + icon;
    // });

    // setTimeout(() => {
    //   var options = { weekday: 'short' };
    //   var day = new Date().toLocaleTimeString('en-us', options);
    //   var setActive = day.split(" ")[0].toLowerCase();
    //   $("." + setActive).addClass("active");
    //   this.forcast.then(function (res) {
    //     var data = [];
    //     // res.list.forEach(element => {
    //     //     element.dt_txt= element.dt_txt.split(" ")[0];
    //     //     data.push(element)
    //     // // });

    //     // var barr = data.filter((v,i,a)=>a.findIndex(t=>(t.dt_txt === v.dt_txt))===i);
    //     res.list.forEach(element => {
    //       var options = { weekday: 'short' };
    //       var day = new Date(element.dt * 1000).toLocaleTimeString('en-us', options);
    //       var icon = element.weather[0].icon.split('.')[0];
    //       $("." + day.split(" ")[0].toLowerCase()).removeClass('hide');
    //       $("." + day.split(" ")[0].toLowerCase() + '_icon').html("<i class='wi wi-" + icon + "'></i>").removeClass('hide');
    //       $("." + day.split(" ")[0].toLowerCase() + '_min').html(element.temp.min + '°').removeClass('hide');
    //       $("." + day.split(" ")[0].toLowerCase() + '_max').html(element.temp.max + '°').removeClass('hide');
    //       // console.log(day.split(" ")[0].toLowerCase())
    //     });
    //   });
    // }, 1000);

  }

  getDashboardData(filter) {
    let dashObj = {
      property_id: localStorage.getItem("current_property"),
      date_departure_from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      date_departure_to: moment().format('YYYY-MM-DD'),
      performance_filter: filter,
      graphDaysPFrontDesk: filter,
    };

    this.api.post('api/report/dashboard', dashObj, true).subscribe((res: any) => {
      this.dashboard = res.body.data;
      this.api.notification.next(this.dashboard)
      this.reservations = res.body.data.reservations
      this.notifications = res.body.data.user_keynotes
      this.booking_revenue = res.body.data.booking_revenue
      this.all_arrivals = res.body.data.arrivals
      this.all_channels = res.body.data.channels
      this.housekeepingData = res.body.data.housekeeping
      this.frontDesk = res.body.data.front_desk;
      this.performanceData = res.body.data.performance
      this.event_images = res.body.data.Event_images
      this.dashboard_skeleton = false;
      console.log('this is frontdeskData', this.dashboard)
    },
      err => this.error_handling.handle_error(err.status, err.message));
  }

  getchartVreport() {
    let chart = new CanvasJS.Chart("graphone", {
      animationEnabled: true,
      title: {

      },
      axisY: {

        includeZero: false
      },
      toolTip: {
        shared: false,
        //content: '<div>Name: {name}<br/>Revenue: {y}</div>',
        content:
          ` <div class="graph-box">

          <div class="uper-box" style='\"'height: 78px;width: 157px;background-color: white;border-radius: 8px;padding: 5px;padding-top: 0px;box-shadow: 1px 2px 6px #00000040;'\"'>
            <div class="g-date" style='\"'border-bottom: 1px solid lightgrey;font-size: 13px;color: #000654;font-weight: bold;text-align: center;padding: 2px;'\"'> 15 Nov 2019 </div>
            <div class="g-details">

            <div class="g-1" style='\"'cursor: move;float: left;width: 50%;border-right: 1px solid lightgrey;color: #d10000;font-weight: bold;font-size: 14px;text-align: center;margin-top: -1px'\"'> Arrivals <br> <img src="assets/img/dashboard/5dd3b92c61d1e3f3ee0801ce_checkOuts.png" style='\"'height: 22px;width: 17px;margin-right: 5px'"\'>  <span class="f8" style='\"'color: #d10000;font-weight: bold;font-size: 17px;'"\'>48</span> </div>
              <div class="g-2" style='\"'margin-left: 50%;;cursor: move;width: 50%;text-align: center;color: #00205b;font-weight: bold;font-size: 14px;margin-top: 5px'\"'> RES <br> <span class="s3" style='\"'color: #00205b;font-weight: bold;font-size: 17px;'"\'>73%</span> </div>



            </div>
          </div>


        </div>`


        ,
        cornerRadius: '8'
      },

      data: [
        {
          type: "spline",
          name: "arrivals",
          showInLegend: false,
          lineColor: "#ffc31d",
          color: "#ffc31d",
          dataPoints: [
            { y: 10, label: "1" },
            { y: 50, label: "2" },
            { y: 72, label: "3" },
            { y: 100, label: "4" },
            { y: 42, label: "5" },
            { y: 90, label: "6" },
            { y: 146, label: "7" },
            { y: 109, label: "8" },
            { y: 143, label: "9" },
            { y: 158, label: "10" },
            { y: 154, label: "11" },
            { y: 150, label: "12" },
            { y: 146, label: "13" },
            { y: 109, label: "14" },
            { y: 143, label: "15" },
          ]
        },
        {
          type: "spline",
          name: "checked_in",
          showInLegend: false,
          lineColor: "#00edff",
          color: "#00edff",
          dataPoints: [
            { y: 5, label: "1" },
            { y: 20, label: "2" },
            { y: 50, label: "3" },
            { y: 72, label: "4" },
            { y: 62, label: "5" },
            { y: 65, label: "6" },
            { y: 52, label: "7" },
            { y: 128, label: "8" },
            { y: 105, label: "9" },
            { y: 70, label: "10" },
            { y: 95, label: "11" },
            { y: 19, label: "12" },
            { y: 50, label: "13" },
            { y: 72, label: "14" },
            { y: 62, label: "15" },
          ]
        },
        {
          type: "spline",
          name: "reservations",
          showInLegend: false,
          lineColor: "#ff1d1d",
          color: "#ff1d1d",
          dataPoints: [
            { y: 20, label: "1" },
            { y: 60, label: "2" },
            { y: 32, label: "3" },
            { y: 50, label: "4" },
            { y: 120, label: "5" },
            { y: 150, label: "6" },
            { y: 16, label: "7" },
            { y: 69, label: "8" },
            { y: 73, label: "9" },
            { y: 78, label: "10" },
            { y: 154, label: "11" },
            { y: 120, label: "12" },
            { y: 32, label: "13" },
            { y: 50, label: "14" },
            { y: 120, label: "15" },
          ]
        },
        {
          type: "spline",
          name: "departure",
          showInLegend: false,
          lineColor: "#1dff43",
          color: "#1dff43",
          dataPoints: [
            { y: 5, label: "1" },
            { y: 83, label: "2" },
            { y: 15, label: "3" },
            { y: 82, label: "4" },
            { y: 32, label: "5" },
            { y: 25, label: "6" },
            { y: 52, label: "7" },
            { y: 78, label: "8" },
            { y: 55, label: "9" },
            { y: 40, label: "10" },
            { y: 195, label: "1" },
            { y: 139, label: "12" },
            { y: 15, label: "13" },
            { y: 82, label: "14" },
            { y: 32, label: "15" },
          ]
        },

      ]
    });

    chart.render();
  }

  getchartroomstatus() {
    let chart = new CanvasJS.Chart("graphone2", {
      animationEnabled: true,
      title: {

      },
      axisY: {

        includeZero: false
      },
      toolTip: {
        shared: false,
        //content: '<div>Name: {name}<br/>Revenue: {y}</div>',
        content:
          ` <div class="graph-box">

            <div class="uper-box" style='\"'height: 78px;width: 157px;background-color: white;border-radius: 8px;padding: 5px;padding-top: 0px;box-shadow: 1px 2px 6px #00000040;'\"'>
              <div class="g-date" style='\"'border-bottom: 1px solid lightgrey;font-size: 13px;color: #000654;font-weight: bold;text-align: center;padding: 2px;'\"'> 15 Nov 2019 </div>
              <div class="g-details">

              <div class="g-1" style='\"'cursor: move;float: left;width: 50%;border-right: 1px solid lightgrey;color: #017517;font-weight: bold;font-size: 14px;text-align: center;margin-top: -1px'\"'> Availible <br> <img src="assets/img/dashboard/gate-5.png" style='\"'height: 22px;width: 17px;margin-right: 5px'"\'>  <span class="f8" style='\"'color: #017517;font-weight: bold;font-size: 17px;'"\'>48</span> </div>
                <div class="g-2" style='\"'margin-left: 50%;;cursor: move;width: 50%;text-align: center;color: #00205b;font-weight: bold;font-size: 14px;margin-top: 5px'\"'> AVR <br> <span class="s3" style='\"'color: #00205b;font-weight: bold;font-size: 17px;'"\'>73%</span> </div>



              </div>
            </div>


          </div>`


        ,
        cornerRadius: '8'
      },

      data: [
        {
          type: "spline",
          name: "clean_rooms",
          showInLegend: false,
          lineColor: "#00ff55",
          color: "#00ff55",
          dataPoints: [
            { y: 10, label: "1" },
            { y: 50, label: "2" },
            { y: 72, label: "3" },
            { y: 100, label: "4" },
            { y: 42, label: "5" },
            { y: 90, label: "6" },
            { y: 146, label: "7" },
            { y: 109, label: "8" },
            { y: 143, label: "9" },
            { y: 158, label: "10" },
            { y: 154, label: "11" },
            { y: 150, label: "12" },
            { y: 146, label: "13" },
            { y: 109, label: "14" },
            { y: 143, label: "15" },
          ]
        },
        {
          type: "spline",
          name: "dirty_rooms",
          showInLegend: false,
          lineColor: "#ff961d",
          color: "#ff961d",
          dataPoints: [
            { y: 5, label: "1" },
            { y: 20, label: "2" },
            { y: 50, label: "3" },
            { y: 72, label: "4" },
            { y: 62, label: "5" },
            { y: 65, label: "6" },
            { y: 52, label: "7" },
            { y: 128, label: "8" },
            { y: 105, label: "9" },
            { y: 70, label: "10" },
            { y: 95, label: "11" },
            { y: 19, label: "12" },
            { y: 50, label: "13" },
            { y: 72, label: "14" },
            { y: 62, label: "15" },
          ]
        },
        {
          type: "spline",
          name: "close_for_maintanance",
          showInLegend: false,
          lineColor: "#1d61ff",
          color: "#1d61ff",
          dataPoints: [
            { y: 20, label: "1" },
            { y: 60, label: "2" },
            { y: 32, label: "3" },
            { y: 50, label: "4" },
            { y: 120, label: "5" },
            { y: 150, label: "6" },
            { y: 16, label: "7" },
            { y: 69, label: "8" },
            { y: 73, label: "9" },
            { y: 78, label: "10" },
            { y: 154, label: "11" },
            { y: 120, label: "12" },
            { y: 32, label: "13" },
            { y: 50, label: "14" },
            { y: 120, label: "15" },
          ]
        },


      ]
    });

    chart.render();
  }

  speedometter() {
    var canvasId = "myCanvas";

    function speedometer(value, text) {
      var c = <HTMLCanvasElement>document.getElementById(canvasId);
      var ctx = c.getContext("2d");
      var width = 200;
      var x = 130;
      var y = 80;
      var radius = 70;
      var minRad = 0.75 * Math.PI;
      var maxRad = 2.25 * Math.PI;
      var valueRad = (maxRad - minRad) * value + minRad;
      var toolTipWidth = 0;
      var toolTipHeight = 0;
      var toolTipArrowHeight = 0;

      // Create gradient
      var grd = ctx.createLinearGradient(x - radius, 0, x - radius + width, 0);
      // this.darkMode ? grd.addColorStop(0,"#7ea5ff") : grd.addColorStop(0,"#E2125D")
      grd.addColorStop(0, "#7ea5ff");
      // grd.addColorStop(0.25,"#E2125D");
      // grd.addColorStop(0.5, "#E2125D");
      // grd.addColorStop(0.75,"#E2125D");
      // grd.addColorStop(1,"#E2125D");

      var grd2 = ctx.createLinearGradient(0, 0, toolTipWidth, 0);
      grd2.addColorStop(0, "white");
      grd2.addColorStop(1, "#E2125D");

      //background
      ctx.beginPath();
      ctx.arc(x, y, radius, minRad, maxRad);
      ctx.lineWidth = 20;
      ctx.lineCap = "round";
      ctx.strokeStyle = "#dbe5fe";
      ctx.stroke();

      //foreground
      ctx.beginPath();
      ctx.arc(x, y, radius, minRad, valueRad);
      ctx.lineWidth = 20;
      ctx.lineCap = "round";
      ctx.strokeStyle = grd;
      ctx.stroke();

      ctx.beginPath();
      ctx.translate(x, y);
      ctx.rotate(valueRad);
      ctx.rect(radius + toolTipArrowHeight, -toolTipWidth / 2, toolTipHeight, toolTipWidth);
      ctx.moveTo(radius + toolTipArrowHeight, -toolTipWidth / 2);
      ctx.lineTo(radius, 0);
      ctx.lineTo(radius + toolTipArrowHeight, toolTipWidth / 2);
      ctx.fillStyle = grd2;
      ctx.fill();

      ctx.rotate(0.5 * Math.PI);
      ctx.textAlign = "center";
      ctx.font = "30px Helvetica";
      ctx.fillStyle = "#dbe5fe";
      ctx.fillText(text, 0, -radius - toolTipArrowHeight - (toolTipHeight / 4));
    }

    speedometer(0.72, "");
  }

  per(value, total) {
    // let fv = value
    // return fv > 20 ? fv : 50;
    let totlo = total == null ? 100 : total;
    let valo = value == null ? 10 : value
    let tot = valo * 100 / totlo
    return tot > 20 ? tot : tot > 100 ? 100 : tot + 10
    // return tot > 20 ? tot : tot+10
  }

  readNotification(id) {
    // console.log(this.reservations)
    this.reservations.map((val) => {
      if (val.booking_id == id) {
        if (val.read_status == 'read') {
          return
        } else if (val.read_status == 'unread') {
          this.api.get('api/booking/mark_as_read/' + id, true).subscribe((res: any) => {
            this.getDashboardData(this.graphDaysP);
          })
        }
      }
    })
  }

  cardLayout_drag() {
    this.api.get("api/user/me", true).subscribe((res: any) => {
      // console.log(res.body.data.widgets_dashboard_pms);
      // this.dashboard_skeleton = false;

      if (res.body.data.widgets_dashboard_pms) {
        localStorage.setItem("CardLayout", res.body.data.widgets_dashboard_pms);
        this.cardLayout = JSON.parse(res.body.data.widgets_dashboard_pms);
      }


      let CardLayoutarr = (res.body.data.widgets_dashboard_pms);
      //   console.log(CardLayoutarr);
      if (CardLayoutarr == undefined || CardLayoutarr == "") {
        // this.dashboard_skeleton = true;
        let Stays = { cardName: "Stays", cardKey: 1 };
        let Performance = { cardName: "Performance", cardKey: 2 };
        let Reservations = { cardName: "Reservations", cardKey: 3 };
        let House = { cardName: "House Keeping", cardKey: 4 };
        let Todays = { cardName: "Todays Weather", cardKey: 5 };
        let Notifications = { cardName: "Notifications", cardKey: 6 };
        let layoutList = [];

        layoutList.push(Stays);
        layoutList.push(Performance);
        layoutList.push(Reservations);
        layoutList.push(House);
        layoutList.push(Todays);
        layoutList.push(Notifications);


        this.cardLayout = layoutList;
        localStorage.setItem(
          "CardLayout",
          JSON.stringify(this.cardLayout)
        );
      }

    }, (err) => this.error_handling.handle_error(err.status));


  }

  showerror() {
    this.toastr.error('Access Denied');
  }

  assign(cards) {
    console.clear();
    console.log(cards)
  }

  permissions() {
    var data = this.user.permissions;
    for (let i = 0; i < data.length; i++) {

      if (this.user.permissions[i]['app_permission']['name'] == 'Dashboard > Performance') { this.Performance = 'true' }
    }
  }

  meterCount(p) {
    return Math.round(p * 180 / 100) - 45;
  }


}

