<!-- <div class="row newTab">
  <div class="col-md-4" style="padding: 0px 40px 0px 20px">
    <div class="row">

        <div class="alert alert-danger breadcrum awaiting-setup drag-item nav nav-tabs" >
          <ul class="nav navbar-nav full-width" [ngClass]="{'opac': opac !== 3 ? true : false}">
            <li class="awaiting-li" (click)="opac = 3">
              <a class="btn btn-link awaiting-anchor elisis" data-toggle="tooltip" title="OTAs Awaiting Setup" >
                OTAs Awaiting Setup
              </a>
            </li>
            <li class="add-setup" [ngClass]="{'not-allowed': opac !== 3 ? true : false}">
              <a class="btn btn-link btn-hover chj" data-target="#add_setup_ota" data-toggle="modal" 
              style="border-radius: 0px; box-shadow: none; text-align: inherit; padding-left: 6px; margin-right: 20px;
              border-radius: 0px 30px 30px 0px;
              box-shadow: 5px 0px 12px -5px #00000082; color: #d76b78;" 
              [ngClass]="{'disable': opac !== 3 ? true : false}">
                  + Add New 
              </a>
            </li>
            <li [ngClass]="" style="width: 46%;">
              <a class="btn btn-link" style="cursor: context-menu; border-radius: 0px; box-shadow: none; text-align: inherit; padding-left: 6px; padding-top: 35px;">
              </a>
            </li>
          </ul>
        </div>
        
        <div class="addNewOta">
          <div class="col-md-12 ota_tabs scroll-class no_scroll" [ngClass]="{'opac not-allowed height-adj': opac !== 3 ? true : false}" style="padding-left: 0px;">
            <div class="row grid_20" [ngClass]="{'disable': opac !== 3 ? true : false}">
              <div id="drag-box-ota"style="padding-left: 0px;">
                <div id="drag-item-ota" *ngFor="let awaiting of awaiting_channels" style="padding-left: 1px; padding-right: 0px;">
                    <div class="shade" 
                    [ngClass]="awaiting.image!==null ? 'shade-' + awaiting.image.split('.')[0] : 'no-shade'">
                        <div class="border-height">
                          <div class="uper ">
                            <a 
                            *ngIf="awaiting.type !== 'Custom' && awaiting.status == 'Awaiting' && awaiting.image!==null">
                            <div *ngIf="awaiting.image">
                                
                                <img *ngIf="awaiting.type == 'External'" src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(awaiting.image) : imgr(awaiting.image) }}.svg" alt="" class="ota-icon">
                                <img *ngIf="awaiting.type == 'Internal'" src="assets/img/svgicons/channels/{{ imgr(awaiting.image) }}.svg" alt="" class="ota-icon">
                            </div>
                        </a>
                        <a *ngIf="awaiting.type !== 'Custom' && awaiting.status !== 'Awaiting'"
                        (click)="toggle_change($event, awaiting.connected, awaiting.id)">
                        <div *ngIf="awaiting.image">
                            
                            <img *ngIf="awaiting.type == 'External'"
                                src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(awaiting.image) : imgr(awaiting.image) }}.svg" alt=""
                                style="cursor: text;margin-top: 5px; height: 70px; width:170px;">
                            <img *ngIf="awaiting.type == 'Internal'"
                                src="assets/img/svgicons/channels/{{ imgr(awaiting.image) }}.svg" alt=""
                                style="cursor: text;margin-top: 5px; height: 70px; width:170px;">
                        </div>
                    </a>
                            <a *ngIf="awaiting.type === 'Custom'">
                            <div *ngIf="!awaiting.image" class="name-display">
                                <p class="external-name">
                                    {{ awaiting.name }}
                                  </p>
                            </div>
                            </a>
                            
                            <div class="pull-right toggle-btn toggle-adj" [ngClass]="awaiting.image==null ? 'toggle-fix' : ''">
                                <mat-slide-toggle [disabled]="disabled" [color]="accent"></mat-slide-toggle>
                            </div>
                          </div>
                          <div class="between customo">
                            <div class="leftOta" style="    display: flex;align-items: center;">
                              <p class="p2 margin0 text-align coMM">Commision</p>
                            </div>
                            <div class="RightOta"  >
                              <p class="margin0 text-right percentT" style="    line-height: 48px;">{{ awaiting.commission }}<span class="">%</span></p>
                            </div>

                          </div>
                          <div class="lower">
                              <a 
                              *ngIf="awaiting.status !== 'Awaiting'" 
                              (click)="toggle_change_2($event, awaiting.connected, awaiting.id)">
                            <span class="span-weight" style="text-decoration: none !important;">
                              Click to setup
                            </span>
                            </a>
                              <a *ngIf="awaiting.status == 'Awaiting'">
                            <span class="span-weight" style="text-decoration: none !important;cursor: pointer;">
                              Awaiting
                            </span>
                          </a>
                            
                          </div>
                        </div>
                      </div>
                    </div>
        
              </div>
            </div>
          
        </div>
        </div>
      
    </div>
  </div>
  <div class="col-md-4" style="padding: 0px 17px 0px 5px;">

        <div class="row nav nav-tabs" style="padding-right: 6px;">
          <li class=" drag-item" [ngClass]="{'opac': opac !== 1 ? true : false}" (click)="opac = 1"
            style="padding-left: 0px !important;padding-right: 0px !important;width: 100%;">
            <a>Linked OTAs</a>
          </li>
        </div>

        <div class="linkedOta">
          <div class="col-md-12 drag-list ota_tabs scroll-class no_scroll" [ngClass]="{'opac not-allowed height-adj': opac !== 1 ? true : false}" style="padding-left: 0px;padding-right: 0px">
            <div [ngClass]="{'disable': opac !== 1 ? true : false}">
              <div class="row grid_20">
                <div id="drag-box1-ota" class="external-grid drag-list">
                    <div id="drag-item1-ota" *ngFor="let external of external_channels">
                        <div class="shade shade-{{ external.image.split('.')[0]  }} ">
              
                          <div class="border-height">
                            <div class="uper">
              
                              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(external.image) : imgr(external.image) }}.svg" alt="" class="ota-icon">
                           
                              <div class="toggle-btn pull-right">
                                  <mat-slide-toggle 
                                  (change)="mat_change($event , external.status , external.id)"
                                  [color]="accent" [checked]="external.status== 'Active' ? true : false"></mat-slide-toggle>
                              </div>
                            </div>
                            <div class="between customo">
                              <div class="leftOta">
                                <p class="pi margin0 text-align chS" (click)="ota_setting_modal(external.property_channel_id,external.connected)">Channel Setting</p>
                                <p class="p2 margin0 text-align coMM">Commision</p>
                              </div>
                              <div class="RightOta">
                                <p class="margin0 text-right cogI" (click)="ota_setting_modal(external.property_channel_id,external.connected)"> <i class="fa fa-cog"></i> </p>
                                <p class="margin0 text-right percentT">{{ external.commission }}<span class="">%</span></p>
                              </div>

                            </div>
                            <div class="lower">
                              <a [routerLink]="['/app/rates_packages']" [queryParams]="{filter_channel: external.id}">Packages Linked <span class="num"> {{ external.package_count }}</span></a>
                            </div>
                          </div>
                        </div>
                      </div>
          
                </div>
              </div>
            </div>
      
            <div class="subscribe-card">
              <p class="activation-p">To activate this section subscribe to <a href="#"><u>CHANNEL MANAGER</u></a></p>
            </div>
        
                
          </div>
        </div>

  </div>
  <div class="col-md-4" style="padding: 0px 0px;">

        <div class="row nav nav-tabs" style="padding: 0px 15px 0px 20px;">
          <li class=" drag-item" [ngClass]="{'opac': opac !== 2 ? true : false}" (click)="opac = 2"
                style="padding-left: 0px !important;padding-right: 0px !important;width: 100%;">
                <a >Internal Channels</a>
        </li>
        </div>

        <div class="internalOta">
          <div class="col-md-12 ota_tabs scroll-class no_scroll" [ngClass]="{'opac not-allowed height-adj': opac !== 2 ? true : false}" style="padding-left: 0px;padding-right: 0px">
            <div class="row grid_20" [ngClass]="{'disable': opac !== 2 ? true : false}" style="margin-left: 0px !important;margin-right: -10px">
              <div id="drag-box2-ota" style="padding-left: 0px; padding-right: 7px">
                  <div id="drag-item2-ota" *ngFor="let internal of internal_channels">
                      <div class="shade shade-{{ internal.image.split('.')[0]  }}">
                        <div class="border-height">
                            
                          <div class="uper">
                            <img src="assets/img/svgicons/channels/{{ imgr(internal.image) }}.svg" alt="" class="ota-icon">
                            <div class="toggle-btn pull-right">
                              <mat-slide-toggle [color]="accent" 
                              (change)="mat_change($event , internal.status , internal.id)" [checked]="internal.status== 'Active' ? true : false"></mat-slide-toggle>
                            </div>
                          </div>
                          <div class="between customo">
                            <div class="leftOta">
                              <p class="pi margin0 text-align chS" (click)="ota_setting_modal(internal.property_channel_id,internal.connected)">Channel Setting</p>
                              <p class="p2 margin0 text-align coMM">Commision</p>
                            </div>
                            <div class="RightOta">
                              <p class="margin0 text-right cogI" (click)="ota_setting_modal(internal.property_channel_id,internal.connected)"> <i class="fa fa-cog"></i> </p>
                              <p class="margin0 text-right percentT">{{ internal.commission }}<span class="">%</span></p>
                            </div>

                          </div>
                          <div class="lower">
                            <a [routerLink]="['/app/rates_packages']" [queryParams]="{filter_channel: internal.id}">Packages Linked <span class="num"> {{ internal.package_count  }}</span></a>
                          </div>
                      
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          
    </div>
        </div>

  </div>
</div> -->



<!-- <div class="row grid_20 ">
  <ul id="drag-box-bar" class="nav nav-tabs col-md-12" style="    padding-left: 20px;padding-right: 12px;" >
    <li class="alert alert-danger breadcrum awaiting-setup drag-item">
      <ul class="nav navbar-nav full-width" [ngClass]="{'opac': opac !== 3 ? true : false}">
        <li class="awaiting-li" (click)="opac = 3">
          <a class="btn btn-link awaiting-anchor" style="border-radius: 0px 30px 30px 0px; ">
             Awaiting Setup
          </a>
        </li>
        <li class="add-setup" [ngClass]="{'not-allowed': opac !== 3 ? true : false}">
          <a class="btn btn-link btn-hover chj" data-target="#add_setup_ota" data-toggle="modal" 
          style="border-radius: 0px; box-shadow: none; text-align: inherit; padding-left: 6px; margin-right: 20px;
          border-radius: 0px 30px 30px 0px;
          box-shadow: 5px 0px 12px -5px #00000082; color: #d76b78;" 
          [ngClass]="{'disable': opac !== 3 ? true : false}">
              + Add New OTA
          </a>
        </li>
        <li [ngClass]="" style="width: 46%;">
          <a class="btn btn-link" style="cursor: context-menu; border-radius: 0px; box-shadow: none; text-align: inherit; padding-left: 6px; padding-top: 35px;">
          </a>
        </li>
      </ul>
    </li> 
    <li class="col-md-4 drag-item" [ngClass]="{'opac': opac !== 1 ? true : false}" (click)="opac = 1"
    style="padding-left: 5px !important;padding-right: 2.5px !important;">
      <a>Linked OTAs</a>
    </li>
    <li class="col-md-4 drag-item" [ngClass]="{'opac': opac !== 2 ? true : false}" (click)="opac = 2"
    style="padding-left: 3px !important;padding-right: 0px !important;">
      <a >Internal Channels</a>
    </li>
    
  </ul>
</div> -->


<!-- <div id="ota_settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" [formGroup]="settings_update">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content c-1">
      <div class="modal-body" style="padding: 5px">

          <div class="alert alert-info alert-dismissible show" role="alert">
              Manage OTA
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <strong id="cross">&#x2716;</strong>
              </button>
            </div>

            <div class="alert alert-danger breadcrum channel-settings">
              <a class="button btn-link al-lin" href="" style="box-shadow: none; color: #ffffff">Channel Settings</a>
            </div>
        <div class="card">

          <div class="row field-space">
            <div class="col-md-12">
              <div class="col-md-6" style="padding-right: 2.5px">
                <div class="border-style blue-shade">
                  <div class="element">
                    <label style="color: #00205b;">Channel Status</label>
                    <div class="pull-right flex">
                      <div class="radio-box">
                        <label class="control-label radio-style">
                          <input type="radio"  name="status"  class="pull-left radio channel_status_{{ updateData ? updateData.id:'' }}"  [value]="updateData ? (updateData.status==='Active' ? updateData.status: 'Enabled') :'Enabled'" [(ngModel)]="checked"  formControlName="status">
                          <span class="radio-checkmark"></span>
                          <span class="title">Enabled</span>
                        </label>
                      </div>
                      <div class="radio-box">
                        <label class="control-label radio-style" >
                          <input type="radio" name="status" class="pull-left radio channel_status_{{ updateData ? updateData.id:'' }}" [value]="updateData ? (updateData.status !=='Active' ? updateData.status: 'Disabled') :'Disabled'"  [(ngModel)]="checked" formControlName="status">
                          <span class="radio-checkmark"></span>
                          <span class="title">Disabled</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6" style="padding-left: 2.5px">
                <div class="border-style green-shade">
                  <input type="text" class="form-control" placeholder="Enter username" value="{{ updateData ?  updateData.username:'' }}" formControlName="username">
                </div>
               
              </div>
            </div>
          </div>

          <div class="row field-space">
            <div class="col-md-12">
              <div class="col-md-6" style="padding-right: 2.5px">
                <div class="border-style yellow-shade">
                  <ng-select placeholder="Select default currency" name="default_currency" [items]="currency" bindLabel="country" bindValue="id" [(ngModel)]="default_currency"  formControlName="default_currency"></ng-select>
                </div>
              </div>
              <div class="col-md-6" style="padding-left: 2.5px">
                <div class="border-style purple-shade">
                  <input type="password" class="form-control" placeholder="Enter password" autocomplete="off" formControlName="password">
                </div>
              </div>
            </div>
          </div>

          <div class="row field-space">
            <div class="col-md-12">
              <div class="col-md-6" style="padding-right: 2.5px">
                <div class="border-style orange-shade">
                  <input type="text" class="form-control" placeholder="Enter rate multiplier" value="{{ updateData ?  updateData.rate:'' }}" formControlName="rate">
                </div>
                <p class="small text-danger" style="margin-top: 6px;">
                  <strong>Decimal number cannot be lower than
                    0.5</strong>
                </p>
              </div>
              <div class="col-md-6" style="padding-left: 2.5px">
                <div class="border-style grey-shade">
                  <input type="text" class="form-control" placeholder="Enter hotel code" value="{{ updateData ?  updateData.hotel_code : ''}}" formControlName="hotel_code">
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="alert alert-danger red-notification" style="padding-left: 15px; background: linear-gradient(#e06c6d, #0c1f44);">
         Channel Currency Conversion
        </div>

        <div class="card" style="margin-bottom: 0px!important">
          <div class="row field-space">
            <div class="col-md-12">
              <div class="col-md-6" style="padding-right: 2.5px">
               
                <div class="border-style feroze-shade">
                  <ng-select placeholder="Select currency to convert from" name="currency_from" [items]="currency" bindLabel="country" bindValue="id" [(ngModel)]="currency_from" formControlName="currency_from"></ng-select>
                </div>
              </div>
              <div class="col-md-6" style="padding-left: 2.5px">
                <div class="border-style berries-shade">
                  <ng-select placeholder="Select currency to convert to" name="currency_to" [items]="currency" bindLabel="country" bindValue="id" [(ngModel)]="currency_to" formControlName="currency_to"></ng-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
     <div class="modal-footer my-foot-0">
        <div class="col-md-12" style="padding-bottom: 15px;padding-right: 10px">
            <a class="btn btn-info btn-lg" (click)="updateSettings()" style="margin-right: 0px;">Save</a>
            <a aria-label="Close" class="close" data-dismiss="modal" class="btn btn-danger btn-lg">Cancel</a>
          </div>
     </div>
    </div>
  </div>
</div> -->

<!-- <div [formGroup]="settings_form">
<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body" style="padding: 5px!important">

        <div class="row">
          <div class="col-md-2 popup-nav-padd">
            <ul class="nav flex-column">
              <li class="nav-item active">
                <a class="nav-link" data-toggle="tab" href="#setupchannel" id="setup_channelli">
                  <img src="assets/img/manage_otaz/male.png">
                  <span class="tb-adj">Setup<br><strong>Channel</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#setupmultiplier" id="multiplierli">
                  <img src="assets/img/manage_otaz/money_in_hand.png">
                  <span class="tb-adj">Setup<br><strong>Multiplier</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#preview" id="previewli">
                  <img src="assets/img/manage_otaz/search.png">
                  <span class="tb-adj">Setup<br><strong>Preview</strong></span>
                </a>
              </li>
              
            </ul>
          </div>
          
          <div class="col-md-10 pl-0">
            <div class="tab-content scrollo">
              <div class="tab-pane active" id="setupchannel">
                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>
    
                  <div class="alert alert-danger my-head-1 breadcrum">
                    Setup Channel
                  </div>
    
    
                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div *ngIf="settings?.image !== null">
                            <img src="assets/img/svgicons/otas/{{ settings?.image }}" class="img-set">
                          </div>
                          <div style="visibility: hidden" *ngIf="settings?.image == null">
                            <img src="assets/img/svgicons/otas/{{ settings?.image }}" class="img-set">
                          </div>
                          <div class="thr-tbs">
                            <div class="border-style pink-shade thr-tbs-one ul-div element">
                              <ul class="ul-up">
    
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  In
                                  order
                                  to
                                  proceed
                                  with
                                  connecting
                                  to
                                  the
                                  selected
                                  Channel
                                  ,
                                  you
                                  will
                                  need
                                  to
                                  have
                                  an
                                  established
                                  account
                                  with
                                  that
                                  channel.
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  If
                                  you
                                  already
                                  have
                                  an
                                  established
                                  account
                                  ,
                                  please
                                  continue
                                  on
                                  to
                                  the
                                  next
                                  steps
                                  ,
                                  otherwise
                                  please
                                  contact
                                  the
                                  channel
                                  directly
                                  to
                                  sign-up
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  You
                                  will
                                  need
                                  information
                                  from
                                  that
                                  channel
                                  such
                                  as
                                  log-in
                                  credentials
                                  &
                                  hotel
                                  code
                                  to
                                  complete
                                  the
                                  remaining
                                  steps
                                  in
                                  this
                                  process
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive" src="assets/img/manage_otaz/buttler.png">
                          </div>
                          <div class="upload-btn-wrapper">
    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="alert alert-danger" style="background: linear-gradient(#e06c6d, #0c1f44);">
                    <a href="" class="btn btn-link active c-inf" style="color: white">Channel
                      Credentials</a>
                  </div>
    
                  <div class="card">
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-4">
                            <input class="form-control in-adj" placeholder="User Name" type="text" [(ngModel)]="username" formControlName="username" id="username">
                          </div>
                        </div>
                        <div class="col-md-6 pl-5">
                          <div class="border-style br-col-5">
                            <input class="form-control in-adj" placeholder="Password" type="password" [(ngModel)]="password" formControlName="password" id="password">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-6">
                            <input class="form-control in-adj" placeholder="Hotel Code" type="text" [(ngModel)]="hotel_code" formControlName="hotel_code" id="hotel_code">
                          </div>
                        </div>
                        <div class="col-md-6">
    
                        </div>
                      </div>
                    </div>
    
                  </div>
                </div>
                <div class="modal-footer my-foot f-1" style="padding-bottom: 10px !important">
                  <a class="btn btn-primary btn-lg" data-toggle="tab" (click)="activeMultiplier()">Next</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
              <div class="tab-pane" id="setupmultiplier">
    
                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>
    
                  <div class="alert alert-danger my-head-1 breadcrum">
                    Set Rate Multiplier
                  </div>
    
    
                  <div class="card crd-mt">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div class="thr-tbs field-space">
                            <div>
                              <div>
                                <div class="border-style pink-shade tbs-br thr-tbs-one element pd-10">
    
                                  <p class="" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                  </p>
                                  <p class="" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                    repellat
                                    alias
                                    magni
                                    eos
                                    ducimus
                                    iste
                                    eum.
                                  </p>
                                  <p class="no-margin" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                    repellat
                                    alias
                                    magni
                                    eos
                                    ducimus
                                    iste
                                    eum
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                  </p>
    
    
                                </div>
                              </div>
                            </div>
    
                          </div>
                          <div class="row">
                            <div class="">
                              <div class="">
    
                                <div class="">
                                  <div class="col-md-5">
                                    <div class="border-style br-col-4 enter-br">
                                      <input class="form-control in-adj" placeholder="Enter rate if required"
                                        type="number" [(ngModel)]="rate" formControlName="rate">
                                    </div>
                                  </div>
    
                                </div>
                              </div>
    
                            </div>
                          </div>
    
                          <p class="small text-danger sp-sp-txt">
                            <strong>
                              Decimal
                              number
                              cannot
                              be
                              lower
                              than
                              0.5
                            </strong>
                          </p>
    
                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive" src="assets/img/manage_otaz/buttler.png">
    
                          </div>
    
                        </div>
                      </div>
                    </div>
                  </div>
    
    
    
                  <div class="card crd-mt">
                    <div class="row">
                      <div class="">
                        <div class="field-space">
                          <div class="col-md-12 col-pd">
                            <div class="col-md-12">
                              <div class="border-style currency-shade al-br">
                                <div class="element pd-10">
                                  <img class="al-img" src="assets/img/manage_otaz/red_warning.png" alt="">
                                  <div class="al-mar">
                                    <span class="al-span-one">
                                      <strong>
                                        <span class="change-bor">
                                          Please
                                          Note
                                          :</span></strong>
                                      It
                                      is
                                      important
                                      to
                                      understand
                                      that
                                      all
                                      rates
                                      entered
                                      for
                                      this
                                      channel
                                      will
                                      be
                                      multiplied
                                      by
                                      the
                                      value
                                      entered
                                      in
                                      the
                                      Rate
                                      Multiplier
                                      field
                                      before
                                      being
                                      sent
                                      to
                                      the
                                      booking
                                      channel
                                      .
                                      An
                                      incorrect
                                      Rate
                                      Multiplier
                                      means
                                      that
                                      you
                                      will
                                      be
                                      selling
    
                                      your
                                      rooms
                                      at
                                      the
                                      wrong
                                      price
    
                                    </span>
                                  </div>
    
                                </div>
                              </div>
                            </div>
    
    
                          </div>
                        </div>
    
                      </div>
    
                    </div>
                  </div>
                </div>
    
                <div class="modal-footer f-2" style="padding-bottom: 10px !important">
                  <a class="btn btn-primary btn-lg" data-toggle="tab" href="#preview" (click)="activePreview()">Next</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
    
              </div>
              <div class="tab-pane" id="preview">

                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>

                  <div class="alert alert-danger breadcrum">
                    <a class="btn btn-link al-lin">Manage OTAs</a>
                    <a class="btn btn-link active al-lin align-ch" style="color: white">Preview Setup</a>
                  </div>


                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div class="thr-tbs">
                            <div>
                              <div class="border-style element pd-10 br-col-6 thr-tbs-one ">

                                <p class="">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>
                                <p class="">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>
                                <p class="no-margin">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>


                              </div>
                            </div>

                          </div>
                          <p class="small text-danger sp-sp-txt">
                            <strong>
                              Review
                              information
                              entered
                              in
                              the
                              previous
                              steps
                            </strong>
                          </p>

                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive" src="assets/img/manage_otaz/buttler.png">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="card crd-mg">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="row field-space">

                          <div class="col-md-12">
                            <div class="col-md-12">
                              <div class="col-md-6 pr-5">
                                <div class="border-style currency-shade br-col-one">
                                  <div class="element">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="col-md-6">
                                          <label class="currency_label lbl-col">
                                            Channel
                                            to
                                            Connect
                                          </label>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="status_box_img">
                                            <img class="im-adj" src="assets/img/manage_otaz/{{ settings?.image }}">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 pl-5">
                                <div class="border-style brown-shade br-col-2">
                                  <div class="element">
                                    <div class="row">
                                      <div class="col-md-12">
                                          <a data-toggle="tab" href="#setupmultiplier" (click)="activeMultiplier()">
                                        <div class="col-md-6">
                                          <label class="currency_label lbl-col rt-pad">
                                            Rate
                                            Multiplier
                                          </label>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="status_box">
                                            <span class="sp-adj">{{ rate }}</span>

                                          </div>
                                        </div>
                                      </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        

                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer my-foot2 f-3" style="    padding: 0px 18px;
                bottom: -60px; padding-bottom: 10px !important">
                  <a class="btn btn-primary btn-lg" (click)="onSubmit()">Connect</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div> 
</div> -->

<!-- <div [formGroup]="add_setup_ota" [hidden]="isModelShow">
<div id="add_setup_ota" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <div class="row">
          <div class="col-md-2 popup-nav-padd">
            <ul class="nav flex-column">
              <li class="nav-item active">
                <a class="nav-link" data-toggle="tab" href="#setup_ota" id="setupchannel_li">
                  <img src="assets/img/manage_otaz/male.png">
                  <span class="tb-adj">Setup<br><strong>Channel</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#setup_multiplier" id="multiplier_li">
                  <img src="assets/img/manage_otaz/money_in_hand.png">
                  <span class="tb-adj">Setup<br><strong>Multiplier</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#preview_setup" id="preview_li">
                  <img src="assets/img/manage_otaz/search.png">
                  <span class="tb-adj">Setup<br><strong>Preview</strong></span>
                </a>
              </li>

            </ul>
          </div>
          
          <div class="col-md-10 pl-0">
            <div class="tab-content scrollo">
              <div class="tab-pane active" id="setup_ota">
                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>
    
                  <div class="alert alert-danger breadcrum">
                    <a class="btn btn-link active al-lin" data-toggle="tab" href="#setup_ota" style="color: white">Setup OTAs</a>
                    <a class="btn btn-link al-lin" data-toggle="tab" href="#setup_channel">Setup Channel</a>
                  </div>
    
                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">

                          <div class="thr-tbs">
                            <div class="border-style pink-shade thr-tbs-one ul-div element">
                              <ul class="ul-up">
    
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  In
                                  order
                                  to
                                  proceed
                                  with
                                  connecting
                                  to
                                  the
                                  selected
                                  Channel
                                  ,
                                  you
                                  will
                                  need
                                  to
                                  have
                                  an
                                  established
                                  account
                                  with
                                  that
                                  channel.
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  If
                                  you
                                  already
                                  have
                                  an
                                  established
                                  account
                                  ,
                                  please
                                  continue
                                  on
                                  to
                                  the
                                  next
                                  steps
                                  ,
                                  otherwise
                                  please
                                  contact
                                  the
                                  channel
                                  directly
                                  to
                                  sign-up
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  You
                                  will
                                  need
                                  information
                                  from
                                  that
                                  channel
                                  such
                                  as
                                  log-in
                                  credentials
                                  &
                                  hotel
                                  code
                                  to
                                  complete
                                  the
                                  remaining
                                  steps
                                  in
                                  this
                                  process
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive img-class" src="assets/img/manage_otaz/buttler.png">
                          </div>
                          <div class="upload-btn-wrapper">
    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="alert alert-danger" style="background: linear-gradient(#e06c6d, #0c1f44);">
                    <a href="" class="btn btn-link active c-inf"  style="color: white;">Channel
                      Credentials</a>
                  </div>
    
                  <div class="card">
                      <div class="row field-space">
                          <div class="col-md-12">
                              <div class="col-md-6 pr-5">
                                  <div class="border-style channel_shade">
                                      <input class="form-control in-adj" placeholder="Channel Name" type="text" [(ngModel)]="name" formControlName="name" id="channel_name">
                                    </div>
                              </div>
                            <div class="col-md-6 pl-5">
                              <div class="border-style website_shade">
                                <input class="form-control in-adj" placeholder="Channel URL" type="text" [(ngModel)]="website" formControlName="website">
                              </div>
                            </div>
                            
                          </div>
                        </div>
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-4">
                            <input class="form-control in-adj" placeholder="User Name" type="text" [(ngModel)]="username" formControlName="username">
                          </div>
                        </div>
                        <div class="col-md-6 pl-5">
                          <div class="border-style br-col-5">
                            <input class="form-control in-adj" placeholder="Password" type="password" [(ngModel)]="password" formControlName="password">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-6">
                            <input class="form-control in-adj" placeholder="Hotel Code" type="text" [(ngModel)]="hotel_code" formControlName="hotel_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                                
                              </div>
                      </div>
                    </div>
                    
    
                  </div>
                  
                </div>
                <div class="modal-footer f-4">
                  <a class="btn btn-primary btn-lg" data-toggle="tab" (click)="activeSetupMultiplier()">Next</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
              <div class="tab-pane" id="setup_channel">
                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>
    
                  <div class="alert alert-danger breadcrum">
                    <a class="btn btn-link  al-lin" data-toggle="tab" href="#setup_ota" >Setup OTAs</a>
                    <a class="btn btn-link active al-lin" href="" style="color: white">Setup Channel</a>
                  </div>
    
                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div class="thr-tbs">
                            <div class="border-style pink-shade thr-tbs-one ul-div element">
                              <ul class="ul-up">
    
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  In
                                  order
                                  to
                                  proceed
                                  with
                                  connecting
                                  to
                                  the
                                  selected
                                  Channel
                                  ,
                                  you
                                  will
                                  need
                                  to
                                  have
                                  an
                                  established
                                  account
                                  with
                                  that
                                  channel.
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  If
                                  you
                                  already
                                  have
                                  an
                                  established
                                  account
                                  ,
                                  please
                                  continue
                                  on
                                  to
                                  the
                                  next
                                  steps
                                  ,
                                  otherwise
                                  please
                                  contact
                                  the
                                  channel
                                  directly
                                  to
                                  sign-up
                                </li>
                                <li class="ng-untouched ng-pristine ng-valid tx-col cl-cls" type="text">
                                  You
                                  will
                                  need
                                  information
                                  from
                                  that
                                  channel
                                  such
                                  as
                                  log-in
                                  credentials
                                  &
                                  hotel
                                  code
                                  to
                                  complete
                                  the
                                  remaining
                                  steps
                                  in
                                  this
                                  process
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive img-class" src="assets/img/manage_otaz/buttler.png">
                          </div>
                          <div class="upload-btn-wrapper">
    
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="alert alert-danger" style="background: linear-gradient(#e06c6d, #0c1f44);">
                    <a href="" class="btn btn-link active c-inf"  style="color: white;">Channel
                      Credentials</a>
                  </div>
    
                  <div class="card">
                      <div class="row field-space">
                          <div class="col-md-12">
                              <div class="col-md-6 pr-5">
                                  <div class="border-style channel_shade">
                                      <input class="form-control in-adj" placeholder="Channel Name" type="text" [(ngModel)]="name" formControlName="name" id="channel_name">
                                    </div>
                              </div>
                            <div class="col-md-6 pl-5">
                              <div class="border-style website_shade">
                                <input class="form-control in-adj" placeholder="Channel URL" type="text" [(ngModel)]="website" formControlName="website">
                              </div>
                            </div>
                            
                          </div>
                        </div>
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-4">
                            <input class="form-control in-adj" placeholder="User Name" type="text" [(ngModel)]="username" formControlName="username">
                          </div>
                        </div>
                        <div class="col-md-6 pl-5">
                          <div class="border-style br-col-5">
                            <input class="form-control in-adj" placeholder="Password" type="password" [(ngModel)]="password" formControlName="password">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row field-space">
                      <div class="col-md-12">
                        <div class="col-md-6 pr-5">
                          <div class="border-style br-col-6">
                            <input class="form-control in-adj" placeholder="Hotel Code" type="text" [(ngModel)]="hotel_code" formControlName="hotel_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                                
                              </div>
                      </div>
                    </div>
                    
    
                  </div>
    
                
                  
                </div>
                <div class="modal-footer f-4">
                  <a class="btn btn-primary btn-lg" data-toggle="tab" (click)="activeSetupMultiplier()">Next</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
              <div class="tab-pane" id="setup_multiplier">
    
                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>
    
                  <div class="alert alert-danger breadcrum">
                    <a class="btn btn-link al-lin" [routerLink]="manage_otas">Manage OTAs</a>
                    <a class="btn btn-link active al-lin" style="color: white">Set Rate Multiplier</a>
                  </div>
    
    
                  <div class="card crd-mt">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div class="thr-tbs field-space">
                            <div>
                              <div>
                                <div class="border-style pink-shade tbs-br thr-tbs-one element pd-10">
    
                                  <p class="" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                  </p>
                                  <p class="" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                    repellat
                                    alias
                                    magni
                                    eos
                                    ducimus
                                    iste
                                    eum.
                                  </p>
                                  <p class="no-margin" placeholder="First Name" type="text">
                                    Lorem,
                                    ipsum
                                    dolor
                                    sit
                                    amet
                                    consectetur
                                    adipisicing
                                    elit.
                                    Molestias,
                                    nesciunt
                                    quam
                                    minus
                                    maiores
                                    nostrum
                                    consectetur
                                    illo
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                    repellat
                                    alias
                                    magni
                                    eos
                                    ducimus
                                    iste
                                    eum
                                    distinctio
                                    odio
                                    sunt
                                    adipisci
                                    officiis
                                    sit
                                    tempora
                                  </p>
    
    
                                </div>
                              </div>
                            </div>
    
                          </div>
                          <div class="row">
                            <div class="">
                              <div class="">
    
                                <div class="">
                                  <div class="col-md-5">
                                    <div class="border-style br-col-4 enter-br">
                                      <input class="form-control in-adj" placeholder="Enter rate if required"
                                        type="number" [(ngModel)]="rate" formControlName="rate">
                                    </div>
                                  </div>
    
                                </div>
                              </div>
    
                            </div>
                          </div>
    
                          <p class="small text-danger sp-sp-txt">
                            <strong>
                              Decimal
                              number
                              cannot
                              be
                              lower
                              than
                              0.5
                            </strong>
                          </p>
    
                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive" src="assets/img/manage_otaz/buttler.png">
    
                          </div>
    
                        </div>
                      </div>
                    </div>
                  </div>
    
    
    
                  <div class="card crd-mt">
                    <div class="row">
                      <div class="">
                        <div class="field-space">
                          <div class="col-md-12 col-pd">
                            <div class="col-md-12">
                              <div class="border-style currency-shade al-br">
                                <div class="element pd-10">
                                  <img class="al-img" src="assets/img/manage_otaz/red_warning.png" alt="">
                                  <div class="al-mar">
                                    <span class="al-span-one">
                                      <strong>
                                        <span class="change-bor">
                                          Please
                                          Note
                                          :</span></strong>
                                      It
                                      is
                                      important
                                      to
                                      understand
                                      that
                                      all
                                      rates
                                      entered
                                      for
                                      this
                                      channel
                                      will
                                      be
                                      multiplied
                                      by
                                      the
                                      value
                                      entered
                                      in
                                      the
                                      Rate
                                      Multiplier
                                      field
                                      before
                                      being
                                      sent
                                      to
                                      the
                                      booking
                                      channel
                                      .
                                      An
                                      incorrect
                                      Rate
                                      Multiplier
                                      means
                                      that
                                      you
                                      will
                                      be
                                      selling
    
                                      your
                                      rooms
                                      at
                                      the
                                      wrong
                                      price
    
                                    </span>
                                  </div>
    
                                </div>
                              </div>
                            </div>
    
    
                          </div>
                        </div>
    
                      </div>
    
                    </div>
                  </div>
                </div>
    
                <div class="modal-footer f-5">
                  <a class="btn btn-primary btn-lg" data-toggle="tab" href="#preview_setup" (click)="activePreviewSetup()">Next</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
    
              </div>
              <div class="tab-pane" id="preview_setup">

                <div class="pane-content">
                  <div class="alert alert-info alert-dismissible show" role="alert">
                    Channel Butler Setup
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                      <strong id="cross">&#x2716;</strong>
                    </button>
                  </div>

                  <div class="alert alert-danger breadcrum">
                    <a class="btn btn-link al-lin" href="">Manage OTAs</a>
                    <a class="btn btn-link active al-lin align-ch" href="" style="color: white" >Preview Setup</a>
                  </div>


                  <div class="card">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-10">
                          <div class="thr-tbs">
                            <div>
                              <div class="border-style element pd-10 br-col-6 thr-tbs-one ">

                                <p class="">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>
                                <p class="">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>
                                <p class="no-margin">
                                  Lorem,
                                  ipsum
                                  dolor
                                  sit
                                  amet
                                  consectetur
                                  adipisicing
                                  elit.
                                  Molestias,
                                  nesciunt
                                  quam
                                  minus
                                  maiores
                                  nostrum
                                  consectetur
                                  illo
                                  distinctio
                                  odio
                                  sunt
                                  adipisci
                                  officiis
                                  sit
                                  tempora
                                  repellat
                                  alias
                                  magni
                                  eos
                                  ducimus
                                  iste
                                  eum.
                                </p>


                              </div>
                            </div>

                          </div>
                          <p class="small text-danger sp-sp-txt">
                            <strong>
                              Review
                              information
                              entered
                              in
                              the
                              previous
                              steps
                            </strong>
                          </p>

                        </div>
                        <div class="col-md-2 profile-img">
                          <div class="img-wrapper">
                            <img class="img img-responsive" src="assets/img/manage_otaz/buttler.png">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div class="card crd-mg">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="row field-space">

                          <div class="col-md-12">
                            <div class="col-md-12">
                              <div class="col-md-6 pr-5">
                                <div class="border-style currency-shade br-col-one">
                                  <div class="element">
                                    <div class="row">
                                      <div class="col-md-12">
                                          <a data-toggle="tab" href="#setup_channel" (click)="activeChannelSetup()">
                                        <div class="col-md-6">
                                          <label class="currency_label lbl-col">
                                            Channel
                                            to
                                            Connect
                                          </label>
                                        </div>
                                        <div class="col-md-6">

                                          <div class="status_box">
                                            <span class="sp-adj" *ngIf="name">{{ name }}</span>
                                            <span class="sp-adj" *ngIf="!name">Enter Channel Name</span>
  
                                            </div>
                                        </div>
                                      </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6 pl-5">
                                <div class="border-style brown-shade br-col-2">
                                  <div class="element">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <a data-toggle="tab" (click)="activeSetupMultiplier()">
                                        <div class="col-md-6">
                                          <label class="currency_label lbl-col rt-pad">
                                            Rate
                                            Multiplier
                                          </label>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="status_box">
                                            <span class="sp-adj" *ngIf="rate">{{ rate }}</span>
                                            <span class="sp-adj" *ngIf="!rate">Enter Rate</span>
                                          </div>
                                        </div>
                                      </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row field-space">
                          <div class="col-md-12">
                            <div class="col-md-12">
                              <div class="col-md-6 pr-5">
                                <div class="border-style blue-shade br-col-3">
                                  <div class="element">
                                    <div class="row">
                                      <div class="col-md-12">
                                          <a data-toggle="tab" href="#setup_channel" (click)="activeChannelSetup()">
                                        <div class="col-md-6">
                                          <label class="lbl-col" style="padding-right: 77px;">Hotel
                                            Code</label>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="status_box">
                                            
                                            <span class="sp-adj" *ngIf="hotel_code">{{ hotel_code }}</span>
                                            <span class="sp-adj" *ngIf="!hotel_code">Enter Hotel Code</span>
                                          </div>
                                        </div>
                                        </a>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <form [formGroup]="add_setup_ota">
                                  <input type="hidden" value="{{ rate }}" name="rate" formControlName="rate">
                                  <input type="hidden" value="{{ name }}" name="name" formControlName="name">
                                  <input type="hidden" value="{{ website }}" name="website" formControlName="website">
                                  <input type="hidden" value="{{ username }}" name="username" formControlName="username">
                                  <input type="hidden" value="{{ password }}" name="password" formControlName="password">
                                  <input type="hidden" value="{{ hotel_code }}" name="hotel_code" formControlName="hotel_code">
                                  
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer f-6">
                  <a class="btn btn-primary btn-lg" (click)="onSetup()">Connect</a>
                  <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div> 
</div> -->


<div class="subscribe-card">
  <p class="activation-p">To activate this section subscribe to <a><u>CHANNEL MANAGER</u></a></p>
</div>