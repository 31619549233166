<!-- <p>pnl-breadcrum works!</p> -->
<div class="alert alert-danger" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
    <div class="row">
        <div class="col-md-8 col-sm-8 breadcrum-ul">
            <ul>
                <li class="li-1">

                    <a href="#" class="btn btn-link active" data-dismiss="modal" aria-label="Close">HOTEL</a>
                </li>
                <!-- <li>

                    <a href="" class="btn btn-link">ROOF TOP</a>
                </li>
                <li>

                    <a href="" class="btn btn-link">CIGARS LOUNGE</a>
                </li>
                <li>

                    <a href="" class="btn btn-link">BAKERY</a>
                </li> -->
                <!-- <li>

          <a href="" class="btn btn-link">SHOP</a>
        </li> -->
            </ul>
        </div>

        <div class="col-md-4 col-sm-4">
            <div class="row ">
                <div class="col-md-2 col-sm-2">
                    <!-- <div class="pri-btn">

                    </div> -->
                </div>
                <div class="col-md-8 col-sm-8 cal-div">
                    <div class="input  calender-div">

                        <input type="hidden" name="date_to" />
                        <input type="hidden" name="date_from" />
                        <!-- <input type="text" ngxDaterangepickerMd class="form-control date_range_picker arrival-lenght singMulCalen" (change)="calendar_change($event.target.value)" placeholder="Date"  [locale]="{ format: 'DD, MMMM YYYY', separator: '  To  ' }"
                      /> -->
                        <input type="text" class="form-control date-pick shadow-none cal-input"
                            [ngClass]="darkMode ? 'calender_dark' : ''" (change)="calendar_change($event)"
                            ngxDaterangepickerMd [(ngModel)]="selectedDate" placeholder="Select Date"
                            [singleDatePicker]="false" [autoApply]="true"
                            [locale]="{format: 'DD MMM', separator: '  to  '}" [ngModelOptions]="{standalone: true}">
                        <!-- <input type="text" class="form-control date-pick shadow-none cal-input" ngxDaterangepickerMd [(ngModel)]="selected.startDate" placeholder="Select Date" [singleDatePicker]="false" [autoApply]="true" [locale]="{format: 'DD MMMM', separator: '  To  '}" [ngModelOptions]="{standalone: true}"> -->
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>