import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {
  graphDaysP = 'today';
  darkMode: boolean = false;
  colors = ['#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  color_border = ['#f53232', '#000000', 'green', 'purple', 'orange', 'blue', 'olive'];
  color_borders_dark = ['#ff5e57', '#a07cc6', '#6af686', '#48fff3', '#b3bf00'];
  @Input() reservations_res;

  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    public weather: DashboardService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
  ) { }

  ngOnInit() {
    this.reservations_res;
    this.checkDarkMode();
  }

  getDashboardData(filter) {
    let dashObj = {
      property_id: localStorage.getItem("current_property"),
      date_departure_from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      date_departure_to: moment().format('YYYY-MM-DD'),
      performance_filter: filter,
      graphDaysPFrontDesk: filter,
    };
    this.api.post('api/report/dashboard', dashObj, true).subscribe((res: any) => {
      this.reservations_res = res.body.data.reservations
    },
      err => this.error_handling.handle_error(err.status, err.message));
  }

  readNotification(id) {
    // console.log(this.reservations)
    this.reservations_res.map((val) => {
      if (val.booking_id == id) {
        if (val.read_status == 'read') {
          return
        } else if (val.read_status == 'unread') {
          this.api.get('api/booking/mark_as_read/' + id, true).subscribe((res: any) => {
            this.getDashboardData(this.graphDaysP);
          })
        }
      }
    })
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;

    }
  }
}
