<div id="otaDiv">


  <div class="alert alert-info alert-dismissible show" role="alert">
    {{ service.is_edit == true ? "Edit Internal Package" : "Create Internal Package" }}
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <strong id="cross">&#x2716;</strong>
    </button>
  </div>
  <div class="alert alert-danger breadcrum">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
    <a href="" class="btn btn-link active" routerLink="link_to_ota">LINK TO OTAs</a>
  </div>
  <form [formGroup]="create_package_form" class="form-horizontal back-form pr">
    <div class="white_box channels w-clearfix padding-adj">

      <div class="col-md-12" style="padding-left: 5px;padding-right:0px">
        <div class="col-md-4" *ngFor="let channel of channels" style="padding-left: 0px">
          <div class="channel_container {{ channel.name }} w-clearfix">
            <div class="form_container">
              <div class="form-block w-clearfix">
                <div class="form"><label class=""><input type="checkbox" id="check-{{ channel.id }}"
                      value="{{ channel.id }}" name="channel" data-name="Channel Icon"
                      class="channels_checkbox w-checkbox-input checkbox"></label></div>
                <div class="w-form-done"></div>
                <div class="w-form-fail"></div>
              </div>
            </div>
            <img src="assets/img/manage_rates/adjusted_logos/{{ channel.image }}" alt="" class="tripadvisor">
          </div>
        </div>
      </div>

    </div>

    <div class="modal-footer link-ota-0" style="bottom: -55px !important;left: -12px!important;">
      <a class="btn btn-primary btn-lg" (click)="onSubmit()">Save</a>
      <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</a>
    </div>
  </form>


</div>