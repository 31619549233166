<div class="alert alert-danger breadcrum mar-adj" [ngClass]="darkMode ? 'alert-danger_dark':'alert-danger'">
    <a href="" class="btn btn-link" routerLink="/app/back_office">
        Back Office
    </a>

    <a href="" class="btn btn-link active" style="padding-left: 23px;">Contact Support</a>
</div>

<!-- <div class="bg-white-one"> -->


<!-- cmnt -->
<!-- <div class="row">
                <div class="col-md-12">
                <div class="flex">
                    <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-left: 0px;
                    padding-right: 0px;
                    margin-right: 0px;">
                        <div class="bg-colr">
                            <img src="assets/img/contact_support/new_male.png" alt="" class="pd-img">
                            <h3 class="red-text">We are here for help</h3>
                            <h2 class="f-s">Our world-wide team of specialist have got you covered</h2>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-right: 0px;
                    padding-left: 0px;
                    margin-left: 5px;">
                        <div class="bg-colr">
                            <img src="assets/img/contact_support/new_female.png" alt="" class="pd-img">
                            <h3 class="red-text">Call Support</h3>
                            <h2 class="f-s">XX - XXXX -X XXXXX</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row one-card c-div">
            <div class="col-md-12 text-center cr-txt">
                <div class="border-style one-card-text">
                    <p class="f-fam">Fill in this form we'll get back you</p>
                </div>
            </div>
        </div> -->
<!-- cmnt -->




<!-- <div class="row">
            <div class="col-md-12">
            <div class="flex">
                <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-left: 0px;
                padding-right: 0px;
                margin-right: 0px;background-color: #021355;height: 100px;">
                    <div class="bg-colr">
                        <div class="row">
                            <div class="col-md-2">
                                    <img src="assets/img/contact_support/4388.png" alt="" class="pd-img" style="float: left;">

                            </div>
                            <div class="col-md-10" style="padding-left: 0;position: relative;margin-left: -10px;margin-top: 10px;">
                                    <h3 class="red-text">{{ 'back_office.customer_support.help_text' | translate }}</h3>
                                    <h2 class="f-s">{{ 'back_office.customer_support.team_text' | translate }}</h2>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6 col-xs-3 text-center border-style br-col" style="padding-right: 0px;
                padding-left: 0px;
                margin-left: 5px;background-color: #021355;height: 100px;">
                    <div class="bg-colr">
                        <div class="row">
                            <div class="col-md-2">
                                    <img src="assets/img/contact_support/3368.png" alt="" class="pd-img" style="float: left;">
                            </div>
                            <div class="col-md-10" style="padding-left: 0;position: relative;margin-left: -10px;margin-top: 10px;">
                                    <h3 class="red-text">{{ 'back_office.customer_support.call_support' | translate }}</h3>
                                    <h2 class="f-s">XX - XXXX -X XXXXX</h2>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row one-card c-div">
        <div class="col-md-12 text-center cr-txt">
            <div class="border-style one-card-text">
                <p class="f-fam">{{ 'back_office.customer_support.fill_form' | translate }}</p>
            </div>
        </div>
    </div>

        <form [formGroup]="contact_support_form">
            <div class="row row-pd">
                <div>
                    <div class="col-md-4 text-center i-col-1" style="padding-right: 0px;">
                        <input type="email" class="border-style form-control web-one" placeholder="{{ 'back_office.customer_support.web_email' | translate }}" formControlName="web_email" required/>
                        <div *ngIf="submitted && f.web_email.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.web_email.errors.email">{{ 'back_office.customer_support.enter_valid_email' | translate }}</strong>

                                <strong *ngIf="f.web_email.errors.required">{{ 'back_office.customer_support.email_required' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-4 text-center i-col-2" style="padding-right: 0px;">
                        <input type="text" class="border-style form-control web-two" placeholder="{{ 'back_office.customer_support.property_name' | translate }}" formControlName="property_name"/>
                        <div *ngIf="submitted && f.property_name.errors" class="small text-danger no-margin invalid-feedback text-left">
                            <strong *ngIf="f.property_name.errors.required">{{ 'back_office.customer_support.property_name_required' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="col-md-4 text-center i-col-3">
                        <input type="text" class="border-style form-control web-three" placeholder="{{ 'back_office.customer_support.subject' | translate }}" formControlName="subject"/>
                        <div *ngIf="submitted && f.subject.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.subject.errors.required">{{ 'back_office.customer_support.subject_is_req' | translate }}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-pd">
                <div class="col-md-4 text-center i-col-1" style="padding-right: 0px;">
                    <div>
                        <div class="border-style country-shade web-four"> -->


<!-- cmnt -->
<!-- <ng-select bindvalue="full_name" formControlName="whichproduct" placeholder="What product are you using ?">
                                <div class="ng-select-container">
                                    <div class="ng-value-container">
                                        <ng-option>Volvo</ng-option>
                                    </div>
                                </div>
                            </ng-select> -->
<!-- cmnt -->


<!-- <ng-select formControlName="product_using" placeholder="{{ 'back_office.customer_support.what_product' | translate }} ?" [clearable]="false">
                                    <ng-option value="channel_manager">{{ 'back_office.customer_support.channel_manager' | translate }}</ng-option>
                                    <ng-option value="pms">{{ 'back_office.customer_support.property_mgmt_sys' | translate }}</ng-option>
                                    <ng-option value="test1">Test 1</ng-option>
                                    <ng-option value="test2">Test 2</ng-option>
                                    <ng-option value="test2">Test 2</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && f.product_using.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.product_using.errors.required">{{ 'back_office.customer_support.select_one_product' | translate }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center i-col-2" style="padding-right: 0px;">
                        <div>
                            <div class="border-style country-shade web-five">
                                <ng-select formControlName="request_related_to" placeholder="{{ 'back_office.customer_support.my_req_related' | translate }}" [clearable]="false">
                                        <ng-option value="property_inventory">{{ 'back_office.customer_support.property_inventory' | translate }}</ng-option>
                                        <ng-option value="using_the_interface">{{ 'back_office.customer_support.using_the_interface' | translate }}</ng-option>
                                        <ng-option value="channels">{{ 'back_office.customer_support.channels' | translate }}</ng-option>
                                        <ng-option value="users_and_passwords">{{ 'back_office.customer_support.users_and_passwords' | translate }}</ng-option>
                                        <ng-option value="bookings_and_Reservation">{{ 'back_office.customer_support.booking_reservation' | translate }}</ng-option>
                                        <ng-option value="property_rates">{{ 'back_office.customer_support.property_rates' | translate }}</ng-option>
                                </ng-select>
                            </div>
                            <div *ngIf="submitted && f.request_related_to.errors" class="small text-danger no-margin invalid-feedback text-left">
                                    <strong *ngIf="f.request_related_to.errors.required" style="padding-right: 8px;">{{ 'back_office.customer_support.request_related_is_required' | translate }}</strong>
                            </div>
                        </div>
                </div>
                <div class="col-md-4 text-center i-col-3">
                    <div>
                        <div class="border-style country-shade web-six">
                            <ng-select formControlName="need_assistance_with" placeholder="{{ 'back_office.customer_support.need_assistance' | translate }}"  [clearable]="false">
                                    <ng-option value="adding_updating_changing">{{ 'back_office.customer_support.adding_updating_or_changing' | translate }}</ng-option>
                                    <ng-option value="configuring_or_mapping_my_property">{{ 'back_office.customer_support.configure_my_property' | translate }}</ng-option>
                                    <ng-option value="being_shown_how_to_do_something">{{ 'back_office.customer_support.being_shown_something' | translate }}</ng-option>
                                    <ng-option value="an_error_message_from_the_product">{{ 'back_office.customer_support.an_error_message_from_the_product' | translate }}</ng-option>
                                    <ng-option value="logging_in_or_access">{{ 'back_office.customer_support.logging_access' | translate }}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && f.need_assistance_with.errors" class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.need_assistance_with.errors.required" style="padding-right: 14px;">{{ 'back_office.customer_support.please_select_one_option' | translate }}</strong>
                        </div>
                    </div>
                </div>
                    <input type="hidden" name="user_id" formControlName="user_id">
                </div>
                <div class="row row-pd desc-pd-adj">
                    <div>
                        <div class="col-md-8 text-center pd-lft">
                                <textarea type="textarea" class="border-style form-control web-desc" id="" cols="8" rows="4" placeholder="{{ 'back_office.customer_support.description' | translate }}" formControlName="description"></textarea>
                                <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback text-left">
                                    <strong *ngIf="f.description.errors.required">{{ 'back_office.customer_support.desc_req' | translate }}</strong>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="pull-right"> -->



<!-- cmnt -->
<!-- <div class="row">
                        <div class="col-md-3 text-center btn-sty">
                                <button type="submit" class="form-control btn-sty-one">Submit</button>
                        </div>
                        <div class="col-md-3 text-center btn-sty-three">
                                <button type="button" class="form-control btn-sty-four" (click)="resetform()">Reset</button>
                        </div>
                    </div> -->
<!-- cmnt -->



<!-- <div class="modal-footer text-right modal-border" style="padding-right: 0px;
                    margin-right: -20px;">
                        <button type="button" class="btn btn-primary btn-lg btn-set-one submit-button" (click)="onSubmit()">{{ 'back_office.customer_support.submit' | translate }}</button> -->


<!-- cmnt -->
<!-- <button type="button" class="btn btn-danger btn-lg btn-set-two reset-form" (click)="resetform()">{{ 'back_office.customer_support.cancel' | translate }}</button> -->
<!-- cmnt -->



<!-- </div>
                </div>

    </form>

    </div> -->

<div class="row main-row">
    <app-message></app-message>
    <app-book-call></app-book-call>

</div>