import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DashboardService } from '../dashboard.service';
import * as echarts from 'echarts';
@Component({
  selector: 'app-front-desk',
  templateUrl: './front-desk.component.html',
  styleUrls: ['./front-desk.component.css']
})
export class FrontDeskComponent implements OnInit {
  graphDaysPFrontDesk;
  darkMode: boolean = false;
  graphDaysP;
  @Input() frontDesk_front;

  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    public weather: DashboardService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
  ) { }

  ngOnInit() {
    this.frontDesk_front;
    this.checkDarkMode()
    this.getMeterChart()
  }

  meterCount(p) {
    return Math.round(p * 180 / 100) - 45;
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getMeterChart() {
    var dom = document.getElementById('occupancy_chart');
    let percentage_today = this.frontDesk_front.percentage_today;
    var myChart = echarts.init(dom, null, {
      renderer: 'canvas',
      useDirtyRect: false
    });
    var app = {};

    var option;

    option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return (
            `${params.name} : ${params.value}%`
          );
        },
      },
      legend: {
        top: '5%',
        left: 'center',
        show: false,
        // doesn't perfectly work with our tricks, disable it
        selectedMode: false
      },

      series: [

        {
          name: 'Occupancy',
          type: "gauge",
          center: ["50%", "65%"],
          radius: "120%",
          startAngle: 200,
          endAngle: -20,
          axisLine: {
            show: true,
            lineStyle: {
              color: [
                [0.25, "#DA462C"],//0-50%
                [0.50, "#FF9618"],//51%-70%
                [0.75, "#FFED44"],//70%-90%
                [1, "#20AE51"]//90%-100%
              ],
              width: 20
            }
          },
          splitLine: {
            length: 0,
            lineStyle: {
              width: 0
            }
          },
          axisTick: {
            length: 0
          },
          axisLabel: {
            show: false,
            color: "black",
            distance: 40 - 10
          },
          pointer: {
            showAbove: true,
            length: "50%",
            width: 5
          },
          detail: {
            formatter: "{score|{value}%}",
            valueAnimation: true,
            offsetCenter: [0, "40%"],
            height: 30,
            rich: {
              score: {
                fontSize: 20
              }
            }
          },
          data: [{
            value: percentage_today,
            label: {
              textStyle: {
                fontSize: 12
              }
            }
          }]
        }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      emptyCircleStyle: {
        color: "rgba(232, 231, 231, 1)"
      },
      showEmptyCircle: true
    };


    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }

    window.addEventListener("resize", () => myChart.resize());
  }
}
