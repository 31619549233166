import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { HelperService } from "../../../services/helper.service";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
import { GlobaldataService } from '../../../common/globaldata.service';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-preview-hotel',
  templateUrl: './preview-hotel.component.html',
  styleUrls: ['./preview-hotel.component.css']
})
export class PreviewHotelComponent implements OnInit {
  property: any;
  user: any;
  upsells = [];
  extrass = [];
  minibars = [];
  showAmeBox: boolean = false;
  aminity: any;
  showBoxOta: boolean = false;
  channels: any = [];
  otas: any = [];
  darkMode: boolean = false;

  showBoxExtra: boolean = false;

  amenColor = ['#a9aae0', '#d24f51', '#bf4eeb', '#efab20', '#32d04a', '#468fab'];

  serviceAddons = [];
  minibarAddons = [];
  arrowUpDown: boolean = false;

  constructor(
    public api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private confirm: ConfirmationAlertService,
    public router: Router,
    public active_route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getPropertyIndex();
    this.checkDarkMode();
    this.getPropertyChannels();
    this.getPropertyOTAs();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getPropertyIndex() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (localStorage.getItem('current_property') > '0') {
      this.api.post('api/property/index', { user_id: this.user.id, email: user.email }, true).subscribe((res: any) => {

        let cp = localStorage.getItem('current_property');
        res.body.data.map((val) => {
          if (val.id == cp) {
            this.property = val;
          }
        });



        this.property.extras.map((e) => {
          if (e.type == "service") {
            this.serviceAddons.push(e);
          } else if (e.type == "minibar") {
            this.minibarAddons.push(e);
          }
        })
      }, err => this.error_handling.handle_error(err.status));
    }
  }

  openChannelsModal() {
    this.showBoxOta = true;
    setTimeout(() => {
      $("#draggable").draggable();
    }, 300);
  }




  amenittyModal(data) {

    this.aminity = data;
    this.showAmeBox = true;
    setTimeout(() => {
      $("#draggable2").draggable();
    }, 300);
  }

  openOTAsModal() {
    // console.log('extra popup')
    this.showBoxExtra = true;
    setTimeout(() => {
      $("#draggable3").draggable();
    }, 300);
  }

  changeDrop(id) {
    let a = document.getElementById(id)
    if (a.classList.contains('rotate180')) {
      a.classList.remove('rotate180')
    }
    else {
      a.classList.add('rotate180')
    }
  }

  drag(con) {
    con == 'do' ? $("#draggable").draggable("enable") : $("#draggable").draggable("disable")
  }

  getPropertyChannels() {
    // this.api.post('api/property/property_channels', { property_id: localStorage.getItem('current_property')}, true).subscribe((res:any) => {
    //   console.log('for channels', res.body.data);
    //   this.channels = res.body.data;
    // }, err => this.error_handling.handle_error(err.status));
    if (localStorage.getItem('current_property') > '0') {
      this.api.post('api/property/property_channels_otas', { property_id: localStorage.getItem('current_property'), type: 'internal' }, true).subscribe((res: any) => {

        this.channels = res.body.data;
        if (this.channels === undefined) {
          this.channels = [];
        }
      }, err => this.error_handling.handle_error(err.status));
    }
  }

  selectAllOta(e) {
    if (e.target.checked) {
      this.channels.forEach(element => {
        if (element.type == 'External') {
          element.isBinded = true;
        }
      });
    } else {
      this.channels.forEach(element => {
        if (element.type == 'External') {
          element.isBinded = false;
        }
      });
    }
  }


  getPropertyOTAs() {
    //    this.api.post('api/ota_package/index', { property_id: localStorage.getItem('current_property')}, true).subscribe((res:any) => {
    //      console.log('for otas', res.body.data);
    //      this.otas = res.body.data;
    //    }, err  => this.error_handling.handle_error(err.status));
    if (localStorage.getItem('current_property') > '0') {
      this.api.post('api/property/property_channels_otas', { property_id: localStorage.getItem('current_property'), type: 'external' }, true).subscribe((res: any) => {

        this.otas = res.body.data;
        console.log(this.otas, 'otas');

        if (this.otas === undefined) {
          this.otas = [];
        }

      }, err => this.error_handling.handle_error(err.status));
    }
  }

  add_bulk_channels(e, id) {
    if (e.target.checked) {
      this.channels.forEach(element => {
        if (element.id == id) {
          element.isBinded = true;
        }
      });
    } else {
      this.channels.forEach(element => {
        if (element.id == id) {
          element.isBinded = false;
        }
      });
    }
  }

  hideModal() {
    let modal = document.getElementById('settings')
    modal.classList.remove('show');
    modal.classList.add('hide');
  }

  saveChannels() {
    let addCh = [];
    this.channels.forEach(e => {
      if (e.isBinded == true) {
        addCh.push(e.id);
      }
    });
    let final = [];
    addCh.map((val) => {
      final.push({
        property_id: localStorage.getItem('current_property'),
        channel_id: val
      })
    });
    this.api.post('api/property/push_property', { otas: final }, true).subscribe((res: any) => {
      this.helper.alert_success(res.body.message);
    }, err => this.error_handling.handle_error(err.status));
  }
  // changeArrow() {
  //   this.arrowUpDown = !this.arrowUpDown;
  // }

}
