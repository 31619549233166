import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { AddOnsExtras } from 'src/app/common/arrivals/response/AddOns';
import { BookingExtra, guestDetails } from 'src/app/common/arrivals/response/GuestDetails';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css']
})
export class PrintInvoiceComponent implements OnInit, OnChanges {

  @Input('printInvoice') printInvoice: guestDetails;
  @Input('bulk_booking') bulk_booking: any[];
  property: any;
  currency = localStorage.getItem("currency");
  serviceAddonsTotal: number = 0;
  minibarAddonsTotal: number = 0;
  bulkBookingExtras: AddOnsExtras[] = []
  perRoomPrice: number = 0;

  constructor() { }

  ngOnInit() {
    this.property = JSON.parse(localStorage.getItem("property"));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bulk_booking && changes.bulk_booking.currentValue) {
      this.getBulkBooking();
    }
  }

  roomNightStay(en: moment.MomentInput, st: moment.MomentInput) {
    let a = moment(en);
    let b = moment(st);
    let c = a.diff(b, "days");
    let days: string;
    c == 1 ? (days = "night") : (days = "nights");
    return c >= 1 ? c + " " + days : "1 day";
  }

  roomRate(bookingPrice: string) {
    let rates = JSON.parse(bookingPrice).rates;
    let sum = rates.reduce((accumulator: any, currentValue: { price: any; }) => {
      return accumulator + currentValue.price
    }, 0);
    return sum;
  }

  perNightPrice(bookingPrice: string) {
    let rates = JSON.parse(bookingPrice).rates;
    return this.roomRate(bookingPrice) / rates.length;
  }

  calculateNightPrice(price: string) {
    let prices = JSON.parse(price);
    let prices_sum: number = 0;

    prices.rates.forEach((element: { price: number; }) => {
      prices_sum += element.price;
    });

    return prices_sum;
  }

  calculateAllRoomRate() {
    let total = 0
    this.bulk_booking.forEach((booking: guestDetails) => {
      const pricesJson = JSON.parse(booking.pricesjson);
      const ratesSum = pricesJson.rates.reduce((sum, rate) => sum + rate.price, 0);
      total += ratesSum;
    });
    return total;
  }


  calculateTotal() {
    const booking = this.printInvoice.booking;
    const nightTotal = this.calculateAllRoomRate();
    const discount = (nightTotal * booking.discount) / 100;
    const tax = booking.TaxInclusive === "false" ? (nightTotal * booking.Taxper) / 100 : 0;
    const extraPersonFee = Number(booking.extra_person_fee);
    const deposit = Number(booking.cashDeposit);
    const totalAmount = Number(nightTotal) - discount;
    const finalTotal = (totalAmount + extraPersonFee + Number(this.printInvoice.fb_price) + Number(tax) + this.serviceAddonsTotal + this.minibarAddonsTotal);
    return finalTotal - deposit;
  }

  printExtras(bookingExtra: BookingExtra[]): AddOnsExtras[] {
    let propertyExtras = this.property.extras;

    const filteredExtras = propertyExtras.filter(extra => {
      const matchingPropertyExtra = bookingExtra.find(addonsExtras => extra.id === addonsExtras.property_extras_item_id);
      if (matchingPropertyExtra) {
        extra.count = matchingPropertyExtra.item_count;
      }
      return matchingPropertyExtra !== undefined;
    });

    return filteredExtras;
  }

  calculateExtrasPrice(type?: string): number {
    const tax = this.printInvoice.booking;
    const extras = (this.printInvoice.isMaster ? this.bulkBookingExtras : this.printExtras(this.printInvoice.booking_extras));

    if (extras) {
      let extrasPrice = extras
        .filter(x => x.type === type && x.name !== "")
        .reduce((accum: number, object: any) => accum + parseInt(object.price) * object.count, 0);

      if (type === "service") {
        this.serviceAddonsTotal = extrasPrice;
      } else if (type === "minibar") {
        this.minibarAddonsTotal = extrasPrice;
      }
      return tax.TaxInclusive === "false" ? (extrasPrice * tax.Taxper) / 100 : extrasPrice;
    } else {
      return 0;
    }

  }

  getTaxPrice(type: string): number {
    let taxPercentage = (type === 'service') ? this.property.taxper : this.property.food_tax_percentage;
    const extras = (this.printInvoice.isMaster ? this.bulkBookingExtras : this.printExtras(this.printInvoice.booking_extras));
    const totalAddonsPrice = extras.reduce((acc, item) => {
      if (item.type === type) {
        acc += parseInt(item.price_after_tax) * item.count;
      }
      return acc;
    }, 0);

    const taxPrice = (totalAddonsPrice * taxPercentage) / 100;

    return taxPrice;
  }

  getBulkBooking() {
    if (this.printInvoice.isMaster) {
      let filteredExtras = this.bulk_booking.map((bookingItem: any) => {
        return this.extrasFind(bookingItem.booking_extras);
      });

      const flattenedData = [].concat.apply([], filteredExtras);

      const result = flattenedData.reduce((acc, obj) => {
        const existingObj = acc.find(item => item.id === obj.id);

        if (existingObj) {
          existingObj.count += obj.count;
        } else {
          acc.push({ ...obj });
        }

        return acc;
      }, []);

      this.bulkBookingExtras = result;
    }
  }

  public extrasFind(addOnsExtras: BookingExtra[]): AddOnsExtras[] {
    let extras = addOnsExtras;
    let propertyExtras = this.property.extras;

    const filteredExtras = propertyExtras.filter((extra: { id: any; count: any; }) => {
      const matchingPropertyExtra = extras.find((addonsExtras: { property_extras_item_id: any; }) => extra.id === addonsExtras.property_extras_item_id);
      if (matchingPropertyExtra) {
        extra.count = matchingPropertyExtra.item_count;
      }

      return matchingPropertyExtra !== undefined;
    });

    return filteredExtras;
  }

}
