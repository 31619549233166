import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  constructor() { }

  ngOnInit() {


    // drag funcationality code--------------------------------------
    $(function () {
      setTimeout(function () {
        $("#drag_box").dragsort({
          dragSelector: ".jumbo-icon",
          dragBetween: true,
          dragEnd: () => {
            var data = $("#drag_box .drag_item").map(function () {
              return $(this).children().html();
            }).get();
            // $("input[name=list1SortOrder]").val(data.join("|"));
          },
          placeHolderTemplate: "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: transparent !important; height: 100%!important'>&nbsp;</div>" +
            "</div>",
          cursor: "move"
        });
      }, 1000)
    });
    // drag functionality end--------------------------------------------

  }

}
