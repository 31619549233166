<!-- <p>book-call works!</p> -->
<div class="col-md-6 zero-padding" style="padding-left: 2px">

    <div class="col-md-12 zero-padding">
        <div class="card mycar" [ngClass]="darkMode ? 'card_dark' : 'card'">

            <div class="row main-row">
                <!-- <div class="col-md-12 zero-padding direct">Direct lines</div> -->

                <div class="col-md-12 zero-padding red-bar">
                    <div class="col-md-4 zero-padding text-left">
                        <img src="assets/img/back-office/c-receiver.svg" alt="messege" class="resever">
                        Phon No 1
                    </div>

                    <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                </div>
                <div class="col-md-12 zero-padding red-bar2">
                    <div class="col-md-4 zero-padding text-left">
                        <img src="assets/img/back-office/c-receiver.svg" alt="messege" class="resever">
                        Phon No 2
                    </div>
                    <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                </div>
                <div class="col-md-12 zero-padding red-bar3">
                    <div class="col-md-4 zero-padding text-left">
                        <img src="assets/img/back-office/c-receiver.svg" alt="messege" class="resever">
                        Phon No 3
                    </div>
                    <div class="col-md-8 zero-padding text-right">XX-XXXX-XXXXXX</div>
                </div>
            </div>

        </div>
    </div>


    <div class="col-md-12 zero-padding" style="margin-top: 5px">
        <div class="row main-row">
            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row main-row">
                    <div class="col-md-12 zero-padding" style="border-bottom: 2px solid lightgrey;padding-bottom: 5px;">
                        <div class="col-md-6 zero-padding text-left direct"
                            [ngClass]="darkMode ? 'direct_dark' : 'direct'">
                            Book a Call
                        </div>
                        <div class="col-md-6 zero-padding text-right orag" style="transform: translateY(3px);">
                            <span style="margin-right: 15px;border-right: 1px solid red;padding-right: 15px;">
                                <input type="radio" id="test1" name="radio-group" value="15 min"
                                    [(ngModel)]="duaration">
                                <label for="test1">15 Min</label>
                            </span>
                            <span>
                                <input type="radio" id="test2" name="radio-group" value="30 min"
                                    [(ngModel)]="duaration">
                                <label for="test2">30 Min</label>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 zero-padding">
                        <div class="empCard" [ngClass]="darkMode ?'empCard_dark':'empCard'">
                            <div class="calendarCard" [ngClass]="darkMode ?'calendarCard_dark':'calendarCard'">
                                <div class="col-md-12 calendar-top f-calendar shown"
                                    [ngClass]="darkMode ?'f-calendar_dark':'f-calendar'"
                                    style="padding: 0px 5px 0px 2px;">
                                    <div class=" color-4 bc6">

                                        <ngx-daterangepicker-material [locale]="{ applyLabel: 'Done', firstDay: 1 }"
                                            name="inline-daterangepicker" (startDateChanged)="eventClicked($event)"
                                            [singleDatePicker]="true" [alwaysShowCalendars]="true">
                                        </ngx-daterangepicker-material>

                                        <div>Chosen date: {{ inlineDate | json }}</div>
                                        <!-- <input type="text" class="input form-control"
                                          placeholder="Duration (Days)" style="outline: none;"
                                          formControlName="duration"
                                          > -->
                                        <!-- <div class="input form-control">
                                                      <input type="hidden" name="date_to">
                                                      <input type="hidden" name="date_from">
                                                      <input type="text"
                                                      ngxDaterangepickerMd
                                                      class="form-control date_range_picker arrival-lenght"
                                                      [(ngModel)]="durationDate"
                                                      placeholder="Duration (Days)"
                                                      [singleDatePicker]="true"
                                                      [autoApply]="true"
                                                      (change)="calendar_change(durationDate)"
                                                      [ngModelOptions]="{standalone: true}"
                                                      [locale]="{format: 'DD, MMMM YYYY', separator: '  To  '}"
                                                      style="width: 100% !important; padding: 2px;float: right;"
                                                      [dateLimit]="12"
                                                      shown

                                                      >
                                              </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 redCardCalendar zero-padding" id="redCard">
                            <div class="col-md-12 ">
                                <div class="selectTimeCalendar">
                                    <div class="mainSelectTime"
                                        [ngClass]="darkMode ? 'mainSelectTime_dark':'mainSelectTime'"><span
                                            style="color:white;margin-right: 3px;">Select</span> <span>Time</span>
                                    </div>
                                    <div class="timeZoneCalendar"> {{city}}, {{countryByTime[1]}} ({{zoneTime}})
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="timesCalendar">

                                    <div class="timeChip" *ngFor="let timess of selectedDateTime; let i = index"
                                        (click)="selectedTime = i; getTime(timess)"
                                        [ngClass]="[selectedTime == i ? 'colorRedCard' : 'w1']">
                                        <div class="leftTime">{{ timess.startTime }} pm</div>
                                        <div class="rightTime">{{ timess.endTime }} pm</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 zero-padding">
                            <button class="submit hov-btn" [ngClass]="darkMode ? 'submit_dark' : 'submit'"
                                (click)="subletmit2()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>