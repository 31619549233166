<div class="main-div">
    <app-pnl-breadcrum></app-pnl-breadcrum>

    <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="flex text space-between blu-bar col-md-12">Cost of Items</div>
        <div class="scrollo0">
            <div class="row">
                <div class="col-md-5 col-sm-5 row-div1">
                    <div class="modal-bar-2 border-1">
                        <div class="form-control costinput">Item name</div>
                        <!-- <input class="form-control costinput" placeholder="Item name" type="text"> -->
                    </div>
                </div>
                <div class="col-md-2 col-sm-2 btnCol">
                    <div class="addBtnCross">0</div>

                </div>
                <div class="col-md-5 col-sm-5 row-div3">
                    <div class="modal-bar-2 border-1">
                        <div class="form-control costinput">Price</div>
                        <!-- <input class="form-control costinput" placeholder="Price" type="text"> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 col-sm-5 row-div1">
                    <div class="modal-bar-2 border-1">
                        <div class="form-control costinput">Item name</div>
                        <!-- <input class="form-control costinput" placeholder="Item name" type="text"> -->
                    </div>
                </div>
                <div class="col-md-2 col-sm-2 btnCol">
                    <button class="addBtnCross">0</button>
                </div>

                <div class="col-md-5 col-sm-5 row-div3">
                    <div class="modal-bar-2 border-1">
                        <div class="form-control costinput">Price</div>
                        <!-- <input class="form-control costinput" placeholder="Price" type="text"> -->
                    </div>
                </div>
            </div>
        </div>
        <!---------scrolling div-------->
        <div class="bot-div">
            <div class="flex text space-between Total-bar" [ngClass]="darkMode ? 'dark_bredcrum ' : ''">
                <div>
                    Total Cost of Sold
                </div>
                <div>PKR 00.00</div>
            </div>
        </div>
    </div>
</div>