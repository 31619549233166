import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FinancialPerformanceData, FinancialPerformanceModel } from 'src/app/common/pnl/financial-performance/FinancialPerformanceModels';
import { PnlService } from 'src/app/services/pnl.service';

@Component({
  selector: 'app-financial-performance',
  templateUrl: './financial-performance.component.html',
  styleUrls: ['./financial-performance.component.css']
})
export class FinancialPerformanceComponent implements OnInit, OnChanges {

  financialPerformanceModel: FinancialPerformanceModel;

  rowData: FinancialPerformanceData[] = []
  darkMode: boolean = false;


  @Input('choosen-date') choosenDate: any;
  constructor(private pnlService: PnlService) {

  }

  ngOnInit() {
    this.updateData()
    this.checkDarkMode();
  }

  ngOnChanges() {
    this.updateData()
  }

  updateData() {
    let startDate = this.choosenDate.startDate.format("YYYY-MM-DD")
    let endDate = this.choosenDate.endDate.format("YYYY-MM-DD")
    this.pnlService.getFinancialPerformanceGraph(startDate, endDate).subscribe((data) => {
      this.financialPerformanceModel = data.data
      let keys = Object.keys(this.financialPerformanceModel)
      this.rowData = keys.map((key) => {
        return {
          label: this.financialPerformanceModel[key].label,
          current: this.financialPerformanceModel[key].current,
          previous: this.financialPerformanceModel[key].previous,
          change: this.calculateChangePercentage(this.financialPerformanceModel[key].current, this.financialPerformanceModel[key].previous)
        }
      })
    })
  }

  calculateChangePercentage(currentValue, previousValue) {
    if (currentValue == 0) {
      return 0;
    }
    let percentage = ((currentValue - previousValue) / previousValue) * 100;
    if (previousValue < 0 && currentValue > 0) {
      return percentage * -1;
    }
    return percentage;
  }
  /*
    calculateBarWidth(type:'revenue' | 'opex' | 'cogs' | 'net_income'){
      // if(!this.financialPerformanceModel) return 10;
      let current = this.financialPerformanceModel.current_data[type];
      let previous = this.financialPerformanceModel.previous_days_data[type];
  
      let max = Math.max(current, previous);
  
      let maxVal = max + (max * 0.15);
  
      let barWidth = (current / maxVal) * 100;
      console.log(barWidth)
  
      if(barWidth > 100){
        barWidth = 100;
      }
      if(barWidth < 10){
        barWidth = 10;
      }
      return barWidth;
      }
  
    calculateIndicatorAdjustment(type:'revenue' | 'opex' | 'cogs' | 'net_income'){
   
      if(!this.financialPerformanceModel) return 0;
      let current = this.financialPerformanceModel.current_data[type];
      let previous = this.financialPerformanceModel.previous_days_data[type];
  
      let max = Math.max(current, previous);
  
      let maxVal = max + (max * 0.15);
  
      let previousIndicator = (previous / maxVal) * 100;
  
      if(previousIndicator > 100){
        previousIndicator = 100;
      }
      if(previousIndicator < 10){
        previousIndicator = 10;
      }
      return previousIndicator;
      
    }
    */


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

}
