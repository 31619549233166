import { Component, OnInit } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
declare var $: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
    // drag function code--------------------
    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate: "<div class='placeHolder' style=''>" +
          "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div>" +
          "</div>",
        cursor: "move"
      });

    });
    // drag function end-------------------
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }


  makePdf() {
    const options = {
      background: '#f1f1f1',
      scale: 1
    };
    html2canvas(document.querySelector(".capture"), options).then((canvas) => {
      var img = canvas.toDataURL('image/png');
      var doc = new jsPDF('p', 'mm', 'a4');
      const bufferX = 3;
      const bufferY = 3;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      doc.save('Invoice.pdf');
    });
  }






}
