import { Component, OnInit, Injectable, HostBinding } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { HelperService } from "../../../services/helper.service";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
declare var $: any;
// @Injectable()
@HostBinding('edit')

@Component({
  selector: 'app-manage-rooms',
  templateUrl: './manage-rooms.component.html',
  styleUrls: ['./manage-rooms.component.css']
})
export class ManageRoomsComponent implements OnInit {
  rooms;
  darkMode: boolean = false;
  base_url;
  base_url2;
  def_id;
  desshow
  showBox: boolean = false;
  showBox2: boolean = false;
  modaldata: any;
  channelShow: boolean = false;
  ametinShow: boolean = false;
  amenityByRoom = [];
  chnnelByRoom = [];
  amenColor = ['#a9aae0', '#d24f51', '#bf4eeb', '#efab20', '#32d04a', '#468fab']
  param;
  query;
  internalChannels: any[] = [];
  externalChannels: any[] = [];
  
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private confirm: ConfirmationAlertService,
    private router: Router,
  ) {
    this.base_url = this.api.base_url;
    this.base_url2 = this.api.base_url2;
  }
  tst = [
    { name: false }
  ]
  ngOnInit() {
    this.get_rooms();
    this.checkDarkMode();


    $(function () {

    });

  }


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;

    }
  }
  get_rooms() {
    var property_id: any = localStorage.getItem("current_property");
    if (property_id > 0) {
      this.api.post('api/room/index?per_page=500', { property_id: property_id }, true).subscribe((res: any) => {
        this.rooms = res.body.data;
        this.getHi()

      }, err => this.error_handling.handle_error(err.status));
    }
  }
  edit(id) {
    this.def_id = id;
    this.helper.change.subscribe(def_id => {
      this.def_id = def_id;
    });
    this.helper.gotoEdit();
    $("#create_rooms_li a").click();
  }
  delete(id, name) {
    let that = this;
    this.confirm.confirm(
      "Delete Room (" + name + ")",
      'Are your sure you want to Delete this Room?',
      ['This action can not be undone', 'Deleting this will completely delete this Room & Its Details.'],
      function () {
        that.api.get('api/room/destory/' + id, true).subscribe((res: any) => {
          that.helper.alert_success('Room Deleted Successfully....');
          that.get_rooms();
        }, err => that.error_handling.handle_error(err.status, err.message));
      }
    );

  }
  jump() {
    $("#create_rooms_li a").click();
  }
  clone(id) {
    this.api.get('api/room/clone/' + id, true).subscribe((res: any) => {
      this.helper.alert_success('Room Cloned Successfully....');
      this.get_rooms();
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  passVar() {
    // this.router.navigate(['/app/back_office/create_rooms'],  { state: this.tst })
  }


  getHi() {

    setTimeout(() => {
      var a = document.getElementById("manageR")
      if (a.offsetHeight > 700) {
        a.style.marginRight = '3px'
      }
      else {
        a.style.marginRight = '0px'
      }
    },)

  }


  setDes(des) {
    return des.split(" ").slice(0, 60).join().replace(/,/g, " ");
  }
  setDes2(des) {
    return des.split(" ").slice(60).join().replace(/,/g, " ");
  }



  desc(i) {
    this.desshow = i
  }

  descs(i) {
    this.desshow = 999999
  }

  openchModal2(data) {
    // console.log(data);
    this.showBox2 = true;


    setTimeout(() => {
      $("#draggable2").draggable();
      $("#draggable2").draggable("disable");
    }, 300);

    this.modaldata = data;
  }


  closeModal() {
    this.showBox = false;
    this.showBox2 = false;
  }

  chandrop(id) {
    let a = document.getElementById(id);

    if (a.classList.contains('rotat')) {

      a.classList.remove('rotat');
    }
    else {
      a.classList.add('rotat');
    }

  }

  closModal(con) {
    setTimeout(() => {
      $("#channelspopup").draggable();
      $("#channelspopup").draggable("disable");
    }, 300);
    con == 'true' ? this.channelShow = true : this.channelShow = false;
  }
  amenModal(con) {
    setTimeout(() => {
      $("#amenityModal").draggable();
      $("#amenityModal").draggable("disable");
    }, 300);
    con == 'true' ? this.ametinShow = true : this.ametinShow = false;
  }


  dodrag(id) {
    id == 'grab-5' ? $('#channelspopup').draggable("enable") : id == 'grab-56' ? $('#amenityModal').draggable("enable") : id == 'rfId' ? $('#draggable2').draggable("enable") : 'nul'
  }
  stopDrag(id) {
    id == 'grab-5' ? $('#channelspopup').draggable("disable") : id == 'grab-56' ? $('#amenityModal').draggable("disable") : id == 'rfId' ? $('#draggable2').draggable("disable") : 'nul'
  }

  // dodrag2(id){
  //   $('#amenityModal').draggable( "enable" )
  // }

  // stopDrag2(id){
  //   $('#amenityModal').draggable( "disable" );
  // }

  cGrab(id) {
    document.getElementById(id).style.cursor = 'grab'
  }
  cDGrab(id) {
    document.getElementById(id).style.cursor = 'grabbing'
  } sqwaqwazswaszx

  amenityData(data) {
    this.amenityByRoom = data
  }
  chnnelpop(data) {
    this.internalChannels = data.filter(channel => channel.channel.type === 'Internal');
    this.externalChannels = data.filter(channel => channel.channel.type === 'External');
    console.log(this.internalChannels, "internalChannels");
    console.log(this.externalChannels, "externalChannels");
  }


  changeDrop(id) {
    let a = document.getElementById(id)
    if (a.classList.contains('rotate180')) {
      a.classList.remove('rotate180')
    }
    else {
      a.classList.add('rotate180')
    }
  }

}


