<div class="row">
  <!-- Filter Bar -->
  <div class="col-md-12" style="position: sticky; z-index: 999; top: 0%;">
    <div class="filter-box" [ngClass]="darkMode ?'card-dark-top-nav' : 'null' ">
      <form class="form-inline">
        <div class="row no-gutters">
          <div class="col-md-11" style="width: 91%">
            <div class="col-md-2 filters_col">
              <ng-select [(ngModel)]="filterLinked" name="filterLinked" [items]="link_drop" bindLabel="name"
                bindValue="val" data-toggle="tooltip" title="Filter By Linked | Unlinked"
                class="form-control input_2 orangSelect" placeholder="Filter By Linked | Unlinked"
                (change)="filterlinked(filterLinked)">
              </ng-select>
            </div>
            <div class="col-md-2 filters_col">
              <ng-select [(ngModel)]="filterRoom" name="filterRoom" class="form-control input_2 orangSelect"
                placeholder="Filter By Room" (change)="filterByRoom(filterRoom)" [items]="roomtypes" bindLabel="name"
                bindValue="id">
              </ng-select>
            </div>
            <div class="col-md-2 filters_col">
              <ng-select [(ngModel)]="filteration" name="filterChannel" (change)="filterationByPrice(filteration)"
                class="form-control input_2 orangSelect" placeholder="Filter By">
                <ng-option value="low_to_high">High to Low</ng-option>
                <ng-option value="high_to_low">Low to High</ng-option>
                <ng-option value="all">Reset</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-md-1 create-rate-plan" style="width: 9%">
            <ng-select id="specific-ng-select" [(ngModel)]="createViewSelect" (change)="createSeason(createViewSelect)"
              name="createView" [items]="createView" bindLabel="name" bindValue="val"
              class="form-control input_2 card-dark-btn-g_place orangSelect" placeholder="+ Create"
              style="padding:0!important;background: linear-gradient(#30319a, #062460);border: none;cursor: pointer;height:40px;"
              [ngClass]=" darkMode? 'card-dark-btn-g_place_dark' : 'card-dark-btn-g_place' ">
            </ng-select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Filter Bar END -->

  <div class="col-md-12">
    <div *ngIf="searchView == true">
      <div class="card"
        *ngFor="let package of ratePackage?.reverse() | search : 'package_name,roomttype.name,room_rate,is_linked,room_id,otas.channel_id' : param;let i = index"
        style="    padding-bottom: 0px; padding-right: 4px;padding-top: 5px;"
        [ngClass]="darkMode ? 'card-darkP' : ' card-null '">
        <div class="row">
          <div class="col-md-3 pr-10 packageCol1 width-18">
            <div style="height: 65px;">
              <h1 (click)="service.is_preview = true; edit_package(package, true);selectpercentage(package.percentage)"
                data-target="#settings" data-toggle="modal" class="{{ colors[i % 7] }} pkg_title hover_shade mr-00z"
                style="text-transform: capitalize;font-size: 18px;line-height: 1 !important;transform: translateY(-2px);">
                {{ package.package_name }}
              </h1>
            </div>
            <p class="linked lilnkedl" id="linked4" [ngClass]="darkMode ? 'dark-red' : ' card-null '"> {{
              dateFunc(package.date_to) | date:"MMM dd" | uppercase }} | {{ dateFunc(package.date_from) |
              date:"MMM dd" | uppercase }} </p>
            <h4 class="linked" id="linked5" *ngIf="package.otas.length == 0">
              No Connected Channels
            </h4>
            <div #logoContainer class="icon-group-{{ package.id }} ico1 channelsLine">
              <ul #logoList class="nav nav-pills otas-list">
                <li *ngFor="let logo of getOTAs(package) | slice: 0:logos_quantity;let logosi = index"
                  class="sp-{{ logo.id }}">
                  <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                    src="assets/img/svgicons/smallicons/{{logo.channel.image}}" style="height: 32px;" />
                </li>
                <li *ngIf="package.otas.length > logos_quantity" class="pull-right">
                  <img class="dropdown-toggle last-img" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="false" src="assets/img/manage_rates/dots.png" class="btn-hov"
                    [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' "
                    style="height: 32px; border-radius: 100px;cursor: pointer;" />
                  <!-- <ul class="dropdown-menu" style="right: auto;">
										<li *ngFor="let logo of package.otas | slice: 8" class="sp-{{ logo.id }} btn-hov" [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' ">
											<a href="#">
												<img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{logo.channel.image}}" style="height: 32px;" />
											</a>
										</li>
									</ul> -->
                  <ul class="dropdown-menu" style="right: auto;">
                    <li *ngFor="let logo of getOTAs(package) | slice: 7" class="sp-{{ logo.id }} btn-hov"
                      [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' ">
                      <a>
                        <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                          src="assets/img/svgicons/smallicons/{{logo.channel.image}}" style="height: 32px;" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-5 pl-10 pr-10 width-col-md-5">
            <div class="row">
              <div class="img-box col-md-4 pr-10 width-col-img">
                <a (click)="service.is_preview = true; edit_package(package, true);selectpercentage(package.percentage)"
                  data-target="#settings" data-toggle="modal">
                  <img class="img-responsive hover_shade"
                    style="border-radius: 10px;width: 230px;height: 138px;cursor: pointer; "
                    src="{{ package.room?.images[0]?.image ? base_url2+'app/images/'+package.room.images[0]?.image : 'assets/img/other/no-room.jpg'}}">
                </a>
              </div>
              <div class="text-box col-md-8 pl-10 width-col-md-8">
                <span
                  (click)="service.is_preview = true; edit_package(package, true);selectpercentage(package.percentage)"
                  data-target="#settings" data-toggle="modal" style="cursor: pointer; text-transform: capitalize;"
                  class="description pkg_title hover_shade {{ colors2[i%7] }}">{{package.roomttype.name}}</span>
                <span class="duplicate_text" *ngIf="package.is_duplicated == 1">Duplicated</span>
                <p class="desc_text hover_shade"
                  (click)="service.is_preview = true; edit_package(package, true);selectpercentage(package.percentage)"
                  data-target="#settings" data-toggle="modal" [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '">
                  {{ package.description }}
                </p>
                <div class="room-details">
                  <ul class="nav navbar-nav pull-left">
                    <li>
                      <a class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '40px'} : {}"
                          style="margin: -10px 5px -5px 0px;height: 40px;" />
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="margin: 0px;">{{ package.room?.bedtype.name }}</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}" />
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="margin: 0px;">Sleeps {{ package.room?.adults +
                            package.room?.children + package.room?.infants }}</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}" />
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="margin: 0px;">{{ package.room?.room_size }}
                            {{package.room?.room_size_type == 'meters' ? 'm' : 'Sq ft'}}</p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.pets != ''">
                      <a class="totlo">
                        <img *ngIf="package?.room?.pets == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}"
                          class="image-adj" style="height: 20px;" />
                        <img *ngIf="package?.room?.pets == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}"
                          class="image-adj" style="height: 20px;" />
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="    margin: 0px;">{{ package?.room?.pets == 'true' ?
                            'Allowed' : 'Not Allowed' }}</p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.wheelchairaccessibility != ''">
                      <a class="totlo">
                        <img *ngIf="package?.room?.wheelchairaccessibility == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}" alt=""
                          class="image-adj" style="height: 25px; margin-top: -5px;">
                        <img *ngIf="package?.room?.wheelchairaccessibility == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}" alt=""
                          class="image-adj" style="height: 25px; margin-top: -5px;;">
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="margin: 0px;">{{package?.room?.smoking == 'true' ?
                            'Available' : 'Not Available'}}</p>
                        </span>
                      </a>
                    </li>
                    <li *ngIf="package?.room?.smoking != ''">
                      <a class="totlo">
                        <img *ngIf="package?.room?.smoking == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}"
                          class="image-adj" style="height: 20px; margin-top: 0px;">
                        <img *ngIf="package?.room?.smoking == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '40px'} : {}"
                          class="image-adj" style="height: 20px; margin-top: 0px;">
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                          <p style="margin: 0px;">{{package?.room?.smoking == 'true' ?
                            'Allowed' : 'Not Allowed'}}</p>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 width-col-md-4">
            <div class="row" style="display: flex;">
              <div class="season-box2 new-box" [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
                <div style="text-indent: 10px;">

                  <div class="topMargNig" style="font-size: 15px;color: #707070;padding-top: 44px;">
                    <b>{{((dateFunc2(package.date_from)).diff((dateFunc2(package.date_to)),
                      "days"))}}</b> Nights
                    <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old':'/night'}}.svg"
                      style="margin-right: 10px;width: 20px" />
                  </div>




                  <!-- <span class="breakfast" *ngIf=" package.breakfast_include == 'Complimentry'">
											<i class="fa fa-utensils"></i> Breakfast Includedasdasdasdas
										</span>
										<span class="breakfast" *ngIf=" package.breakfast_include == 'Paid'">
											<i class="fa fa-utensils"></i> Breakfast Not Includedadasdasdasd
										</span> -->
                  <div style="color: red;"><img src="assets/img/newstaticicons/rates-packages/promotion.svg"
                      style="width: 90px; position: absolute;top: 4px;left: 4px;"></div>
                </div>
                <div class="DicsDiv">
                  <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="">
                  <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{
                    roundPrice(package.sum_price , package.seasonRoomPrice) }}%</p>
                </div>
              </div>
              <div class="season-box color-change" style="width: 41%; transform: translateY(0px) !important;"
                [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
                <div class="new" style="height: 120px;padding-right:3px">
                  <div class="rate-up">
                    <div class="rate-1 hei1"
                      [ngClass]="[package?.breakfast_include == 'Complimentry' ? 'null' : 'hei1']"
                      style="transform: translateY(2px);">
                      <span
                        style="font-size: 16px;font-weight: bold;text-transform: uppercase;font-family: robotoblack;"
                        class="total pkg_title pkg_title">Total</span>
                      <div class="rate-3">
                        <a class="btn btn-channel link_to_ota_btn btn-hov"
                          [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov'">
                          Push
                        </a>
                      </div>
                    </div>
                    <div class="rate-2" [ngClass]="[package?.breakfast_include == 'Complimentry' ? 'null0' : 'hei2']">
                      <span [ngClass]="darkMode ? 'lineytextdark' : 'lineytext'">{{
                        currency_format}}{{package.seasonRoomPrice | number:'1.2-3'}}</span>
                      <!-- <br> -->
                      <span style="width: 494px;font-size: 24px;color: #ff3a3a;font-weight: 700;"
                        class="price pkg_title {{ colors[i%7] }}">
                        {{ currency_format }}{{package.sum_price | number:'1.2-3'}}
                      </span>

                      <span class="tax_cl">+tax</span>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SEASON SKELETON Start -->
  <div class="col-md-12 drag_box saeson-skeleton" *ngIf="promotion_skeleton" [ngClass]="darkMode ? 'promodarkSkel' : ''">
    <div class="mainS drag_item" *ngFor="let rp of getEmptyElements(4)">
      <div class="season card">
        <div class="row">
          <!-- promotion view started -->
          <div class="col-md-2" style="padding-right: 5px;padding-top: 7px;">
            <div class="promotion-skeleton-animation season-card">
              <h4 class="head-main-bar"></h4>
              <div class="data-new_light-bar"></div>
              <div>
                <p class="head-main-bar"></p>
                <div class="icon-group-bar"></div>
              </div>
            </div>
            <span class="vertical-bar"></span>
          </div>

          <!-- promotion view end -->
          <div class="promo-flex">
            <div class="col-md-10 proWidth" style="padding-left: 10px;">
              <div class="box-flex">
                <div class="season-box" style="width: 20.4%;" *ngFor="let r of getEmptyElements(5); let x = index">
                  <div class="new promotion-skeleton-animation">
                    <div class="top-cal">
                      <div class="d-flex justify-content-between align-items-center">
                        <h5 class="head-main-bar m-0"></h5>
                        <div class="head-main-bar m-0"></div>
                      </div>
                    </div>
                    <div class="days-1 icon-group-bar"></div>
                    <hr class="myhr">
                    <div class="days-1 icon-group-bar"></div>
                    <div style="margin-top: 5px;" class="head-main-bar float-right"></div>
                  </div>
                </div>
              </div>
              <span class="vertical-bar" style="left: 98%;"></span>
            </div>
            <!-- <div class="col-md-1" style=" padding-right: 10px; width: 6%;margin-left: 13px;">
                        <div style="height: 130px;">
                        </div>
                        </div> -->
          </div>
        </div>
      </div>
      <div class="details d-flex justify-content-between align-items-center p-3">
        <div class="head-main-bar"></div>
        <div class="data-new_light-bar m-0"></div>
      </div>
    </div>
  </div>
  <!-- SEASON SKELETON END -->

  <!-- <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
	<div class="example-box" *ngFor="let movie of movies" cdkDrag>
	  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
	  {{movie.text}}
	</div>
  </div> -->

  <!-- SEASON Start -->
  <div cdkDropList id="drag-list" class="col-md-12 dragnDrop" (cdkDropListDropped)="drop($event)"
    *ngIf="!promotion_skeleton">
    <div class="mainS" *ngFor="let rp of ratePlan; let did = index;" id="willsee" cdkDrag>
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <div class="season card dropzone" style="box-shadow: none;" [ngClass]="darkMode ? 'card-darkS' : ' card '">
        <div class="row">

          <!-- promotion view started -->

          <!-- <div class="col-md-2" style="padding-right: 2px; width: 17.4%;"> -->
          <div class="col-md-2" style="padding-right: 2px;">
            <h4 style="text-transform: uppercase; font-size: 22px;" class="head-main {{ colors2[did % 7] }}">
              {{rp.season_name}} </h4>
            <div class="room-names" style="padding-left: 3px;"><span class="data-new_light"
                [ngClass]="darkMode ? 'data-new' : 'data-new_light' ">{{rp.demand_type}}</span></div>
            <div>
              <p class="linked" [ngClass]="darkMode ? 'dark-red' : ' card-null '"> {{ rp.date_to |
                date:"dd MMM YYYY" | uppercase }} | {{ rp.date_from | date:"dd MMM YYYY" | uppercase }} </p>
              <div class="icon-group icon-group-262">
                <ul #logoList class="nav nav-pills otas-list">
                  <li *ngIf="rp.season_otas.length == 0" class="sp-0">No Channel Connected</li>
                  <li *ngFor="let logo of rp.season_otas | slice: 0:7;let logosi = index" class="sp-{{ logo.id }}">
                    <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                      src="assets/img/svgicons/smallicons/{{logo.channel.image}}" style="height: 32px;" />
                  </li>
                  <li *ngIf="rp.season_otas.length >7" class="pull-right">
                    <img class="dropdown-toggle last-img" data-toggle="dropdown" role="button" aria-haspopup="true"
                      aria-expanded="false" src="assets/img/manage_rates/dots.png" class="btn-hov"
                      [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' "
                      style="height: 32px; border-radius:100px; cursor: pointer;" />
                    <ul class="dropdown-menu" style="right: auto;">
                      <li *ngFor="let logo of rp.season_otas | slice: 7" class="sp-{{ logo.id }} btn-hov"
                        [ngClass]="darkMode ?'btn-hov_dark' : 'btn-hov' ">
                        <a>
                          <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                            src="assets/img/svgicons/smallicons/{{logo.channel.image}}" style="height: 32px;" />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <span *ngIf="rp.season_room_types.length >= 5"
              style="position: absolute; top: 7px; right: -10px; z-index: 1;" (click)="scrollLefts(did)">
              <img src="assets/img/manage_rates/arrowleft.png" style="cursor: pointer;" class="btn-hov">
            </span>
          </div>

          <div class="div" *ngIf="rp.season_room_types.length <= 1">
            <div class="col-md-6" *ngFor="let r of rp.season_room_types; let x= index">
              <div class="row">
                <div class="img-box col-md-4 pr-10">
                  <a>
                    <img class="img-responsive hover_shade" style="border-radius:10px; width: 100%;
                                            height: 133px;
                                            " src="assets/img/other/no-room.jpg" />

                  </a>
                </div>
                <div class="text-box col-md-8 pl-10">
                  <span class="description {{ colors2[did % 7] }} hover_shade" style="
                                          text-transform: capitalize">{{r.room.roomtype.name }}</span>
                  <!-- <span  class="duplicate_text">
                        {{ "rates_and_packages.list.duplicated" | translate }}
                      </span> -->
                  <!-- <p  class="desc_text hover_shade" [ngClass]="darkMode ?
                                          'card-dark-Date' : ' card-null '">
                                          {{r.description}}

                  </p> -->




                  <!-- <div *ngIf="!r.room.amenities.amenitie" style="margin: 15px 0 8px 0; height: 89px; text-overflow:ellipsis;overflow:hidden; "> -->

                  <!-- <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(0, 0, 0);">  room emergency pull cord in bathroom  </span>
                    </span>

                    <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(85, 18, 18);">  Room Hulu  </span>
                    </span>

                    <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(62, 221, 70);">  Shower  </span>
                    </span>

                    <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(9, 45, 206);">  Room Desk  </span>
                    </span>

                    <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(88, 224, 206);">  Room Towel  </span>
                    </span>

                    <span  class="label" style="padding: 0 5px 0 0;" id="0">
                      <span  class="inner-ame " style="border: 2px solid rgb(202, 51, 240);">  Socket near Bed </span>
                    </span> -->




                  <!-- </div> -->



                  <!-- <div class="outer-box" style="margin: 15px 0 8px 0; height: 89px; text-overflow:ellipsis; "
                    *ngIf="r.room.amenities">
                    <span class="label" style="padding: 0 5px 0 0;" *ngFor="let ame of r.room.amenities; let a = index"
                      id="{{a}}">
                      <span class="inner-ame" [ngClass]="darkMode ? 'amenitie_dark' : 'inner-ame'"
                        [ngStyle]="{'border':'2px solid '+aminites_colors[a]}" *ngIf="a <= 8">
                        {{ ame.amenitie.name}}
                      </span>
                    </span>
                    <img src="assets/img/manage_rates/dots.png" class="dota" *ngIf="r.room.amenities.length > 12">
                    old comment
                    <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == false && ame?.title.split(' ').length > 30">
                      <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()" (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;" *ngIf="service.is_preview == true && ame?.title.split(' ').length > 52">

                    <div class="inner-box" *ngIf="r.room.amenities" [ngClass]="darkMode ? 'card-darkS':''">
                      <span class="label" style="padding: 0 5px 0 0;"
                        *ngFor="let ame of r.room.amenities; let a = index" id="{{a}}">
                        <span class="" [ngClass]="darkMode ? 'amenitie_dark' : 'inner-ame'"
                          [ngStyle]=" {'border':'2px solid '+aminites_colors[a]}" *ngIf="a > 8">{{ ame.amenitie.name
                          }}</span>
                      </span>
                    </div>
                  </div> -->

                  <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                    <ul class="nav navbar-nav pull-left">
                      <li>
                        <a class="totlo">
                          <!-- <img src="assets/img/newstaticicons/rates-packages/{{ darkMode ? 'darkmode' : 'lightmode' }}/bed.svg" [ngStyle]="darkMode ? {  margin: '-10px 5px -5px 0px',  height: '40px'  }   : {}" style="margin: -10px 5px -5px 0px; height: 40px" /> -->
                          <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                            <!-- <p style="margin: 0px">
                                {{r.room?.bedtype.name}}

                              </p> -->
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="totlo">
                          <img src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/sleep.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" />
                          <span class="tooltiptext" [ngClass]="darkMode ?
                                                    'tooltiptextdark' : 'tooltiptext'">
                            <p style="margin: 0px">
                              <!-- {{
                                    "rates_and_packages.list.sleeps" | translate
                                    }} -->
                              {{
                              r.room?.adults +
                              r.room?.children +
                              r.room?.infants
                              }}
                            </p>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a class="totlo">
                          <img src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/area.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" />
                          <span class="tooltiptext" [ngClass]="darkMode ?
                                                    'tooltiptextdark' : 'tooltiptext'">
                            <p style="margin: 0px">
                              {{r.room.room_size }}
                              {{
                              r.room?.room_size == "meters"
                              ? "m"
                              : "Sq ft"
                              }}
                            </p>
                          </span>
                        </a>
                      </li>
                      <li *ngIf="r?.room?.pets != ''">
                        <a class="totlo">
                          <img *ngIf="r?.room?.pets == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/pets.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" class="image-adj" style="height: 20px" />
                          <img *ngIf="r?.room?.pets == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/nopets.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" class="image-adj" style="height: 20px" />
                          <span class="tooltiptext" [ngClass]="darkMode ?
                                                    'tooltiptextdark' : 'tooltiptext'">
                            <p style="margin: 0px">
                              {{
                              r?.room?.pets == "true"
                              ? "Allowed"
                              : "Not Allowed"
                              }}
                            </p>
                          </span>
                        </a>
                      </li>
                      <li *ngIf="r?.room?.wheelchairaccessibility !=
                                                ''">
                        <a class="totlo">
                          <img *ngIf="r?.room?.wheelchairaccessibility
                                                    ==
                                                    'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/wheel.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" alt="" class="image-adj" style="height:
                                                    25px; margin-top: -5px" />
                          <img *ngIf="r?.room?.wheelchairaccessibility
                                                    ==
                                                    'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/nowheel.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" alt="" class="image-adj" style="height:
                                                    25px; margin-top: -5px ;" />
                          <span class="tooltiptext" [ngClass]="darkMode ?
                                                    'tooltiptextdark' : 'tooltiptext'">
                            <p style="margin: 0px">
                              {{
                              r?.room?.wheelchairaccessibility ==
                              "true"
                              ? "Available"
                              : "Not Available"
                              }}
                            </p>
                          </span>
                        </a>
                      </li>
                      <li *ngIf="r?.room?.smoking != ''">
                        <a class="totlo">
                          <img *ngIf="r?.room?.smoking == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/smoke.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" class="image-adj" style="height: 20px;
                                                    margin-top: 0px" />
                          <img *ngIf="r?.room?.smoking == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                                                    darkMode ? 'darkmode' : 'lightmode'
                                                    }}/nosmoke.svg" [ngStyle]="darkMode
                                                    ? {
                                                    margin: '-10px 0px -5px 0px',
                                                    height: '40px'
                                                    }
                                                    : {}" class="image-adj" style="height: 20px;
                                                    margin-top: 0px" />
                          <span class="tooltiptext" [ngClass]="darkMode ?
                                                    'tooltiptextdark' : 'tooltiptext'">
                            <p style="margin: 0px">
                              {{
                              r?.room?.smoking == "true"
                              ? "Allowed"
                              : "Not Allowed"
                              }}
                            </p>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-2 clndr-box" style="padding-right: 7px;">
              <div class="season-box box-100" style="width: 100%!important"
                *ngFor="let r of rp.season_room_types; let x= index"
                [ngClass]="darkMode ? 'card-dark-seasone' : 'card-null '">
                <div class="new" id="n3" [ngClass]="darkMode ?
                                  'card-dark-seasone-new' : ' card-null '">
                  <div class="top-cal">
                    <div class="left">
                      <h5 class="season-roomname" style="font-size: 16px"
                        [ngStyle]="{ color: darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                        {{ r.room.roomtype.name }}
                      </h5>
                    </div>
                    <div class="right">
                      <div class="weekprice"
                        [ngStyle]="{ color: darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code            }">
                        {{ r.week_days_rate | currency: current_currency | number:'1.2-3'}}
                      </div>
                    </div>
                  </div>
                  <div class="days-1">
                    <span class="days-name" *ngFor="let wn of
                                      checkDays(r.week_day)" [ngStyle]="{ opacity: wn.status ?
                                      '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                  </div>
                  <hr class="myhr" />
                  <div class="days-1">
                    <span class="days-name" *ngFor="let wn of
                                      checkDays(r.weekend_day)" [ngStyle]="{ opacity:
                                      wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0) }}</span>
                  </div>
                  <div class="weekprice" style="margin-top: 5px" [ngStyle]="{
                                    color: darkMode  ? r.room.roomtype.dark_mode  : r.room.roomtype.color_code }">
                    {{ r.weekend_days_rate | currency: current_currency | number:'1.2-3'}}
                  </div>
                </div>
              </div>


            </div>

            <div class="col-md-2" style="width: 14.666667%; padding-left: 0px;">
              <!-- <div style="height: 130px;">
                <span *ngIf="rp.season_room_types.length >= 5"
                  style="position: absolute; left: -10px; top: 7px;" (click)="scrollRights(did)">
                  <img src="assets/img/manage_rates/arrowright.png" style="cursor: pointer;"
                    class="btn-hov">
                </span>
              </div> -->
              <div style="height: 130px; width: 100%!important;" class="season-box"
                [ngClass]="darkMode ? 'card-dark-seasone' : 'card-null '">
                <span *ngIf="rp.season_room_types.length >= 5" style="position:
                  absolute; left: -10px; top: 7px" (click)="scrollRights(did)">
                  <img src="assets/img/manage_rates/arrowright.png" class="btn-hov"
                    [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="cursor: pointer; border-radius: 10px" />
                </span>
                <div class="divv" style="display: flex; justify-content: end;">
                  <div class="push-div " style="padding-top: 0px;">
                    <div class="">
                      <a class="btn btn-channel blink_me push-btn btn-hov"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" (click)="seasonPush(rp.id)">
                        Push
                      </a>
                    </div>

                    <!-- <a class="btn btn-channel push-btn btn-hov" [ngClass]="darkMode
                      && rp.push_status == 'pushed'
                      ? 'btn-hov_dark live-btn'
                      : 'btn-hov live-btn'" *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                      {{ "rates_and_packages.menu.live" | translate }}
                    </a> -->
                  </div>
                  <div class="push-div" style="padding-top: 0px; padding-right:0px;">
                    <a class="btn btn-channel push-btn btn-hov" style="padding-right: 5px;"
                      [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" *ngIf="rp.push_status == 'pending'"
                      (click)="seasonPush(rp.id)">
                      Push
                    </a>
                    <a class="btn btn-channel push-btn btn-hov" style="padding-right: 0px; " [ngClass]="darkMode
                      && rp.push_status == 'pushed'
                      ? 'btn-hov_dark live-btn'
                      : 'btn-hov live-btn'" *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                      Live
                    </a>
                  </div>
                </div>

                <!-- <span class="breakfast" *ngIf="
                    package.breakfast_include == 'Paid' ||  package.breakfast_include == 'Exclude '   ">
                  <i class="fa fa-utensils"></i> {{ "rates_and_packages.menu.BreakfastNotIncluded" | translate }}

                </span> -->

                <div class="edit-delete" style="right: 5px; bottom: 5px; " *ngFor="let r of rp.season_room_types">

                  <span class="breakfast">
                    <i class="fa fa-utensils"></i>
                    {{value_added(r.room.value_add_inclusions)}}
                  </span>


                  <!-- <a (click)="duplicate_rateplan(rp)" style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small" [ngClass]="darkMode ?
                        'btn-hov-small_dark' : 'btn-hov-small'" width="25px" height="25px" style="border-radius: 10px; cursor: pointer;" />
                    </a>
                    <a style="margin-right: 4px">
                      <img src="assets/img/newstaticicons/rates-packages/edit.svg" class="btn-hov"
                        [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" width="25px" height="25px"
                        (click)="editPlan(rp)" (click)="getHeight()" style="cursor: pointer; border-radius: 10px" />
                    </a>
                    <a>
                      <img data-target="#confirmationModal" data-toggle="modal"
                        src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small" [ngClass]="darkMode ?
                        'btn-hov-small_dark' : 'btn-hov-small'" width="25px" height="25px" width="25px" height="25px"
                        (click)="deleteSeason(rp.season_name,
                        rp.id, rp.createdAt)" style="cursor: pointer;
                        border-radius: 10px" />
                    </a> -->
                </div>

              </div>
            </div>
          </div>


          <!-- promotion view end -->
          <div class="row m-0 d-flex align-items-center" *ngIf="rp.season_room_types.length >= 2">

            <div class="col-md-11 proWidth" style="padding: 0 0 0 10px; width: 90%;">
              <div class="box-flex {{ 'cla' + did }}  pb-2" id="{{ 'id' + did }}" (click)="dragFunc(did)">
                <div class="season-box" style="width: 20.4%;"
                  *ngFor="let r of rp.season_room_types.reverse(); let x = index"
                  [ngClass]="darkMode ? 'card-dark-seasone' : ' card-null '">
                  <div class="new" [ngClass]="darkMode ? 'card-dark-seasone-new' : ' card-null '">
                    <div class="top-cal">
                      <div class="left">
                        <h5 *ngIf="r.room.display_name == null || r.room.display_name == '' " style="font-size: 16px;"
                          class="season-roomname"
                          [ngStyle]="{'color': darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                          {{ r.room.roomtype.name }} </h5>
                        <h5 *ngIf="r.room.display_name != null || r.room.display_name != '' " style="font-size: 16px;"
                          class="season-roomname"
                          [ngStyle]="{'color': darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                          {{ r.room.display_name }} </h5>

                      </div>
                      <div class="right">
                        <div class="weekprice"
                          [ngStyle]="{'color': darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                          {{ currency_format}}{{ r.week_days_rate | number:'1.2-3'}} </div>
                      </div>
                    </div>
                    <div class="days-1">
                      <span class="days-name" *ngFor="let wn of checkDays(r.week_day)"
                        [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0)
                        }}</span>
                    </div>
                    <hr class="myhr">
                    <div class="days-1">
                      <span class="days-name" *ngFor="let wn of checkDays(r.weekend_day)"
                        [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">{{ wn.name.charAt(0)
                        }}</span>
                    </div>
                    <div style="margin-top: 10px;" class="weekprice"
                      [ngStyle]="{'color': darkMode ? r.room.roomtype.dark_mode : r.room.roomtype.color_code}">
                      {{ currency_format }}{{ r.weekend_days_rate | number:'1.2-3' }} </div>
                  </div>
                </div>
              </div>
              <!-- <span *ngIf="rp.season_room_types.length >= 5" style="position: absolute; left: 98%; top: 7px;"
                (click)="scrollRights(did)">
                <img src="assets/img/manage_rates/arrowright.png" style="cursor: pointer;" class="btn-hov">
              </span> -->
            </div>
            <div class="col-md-1 seasoneActionCol">
              <div style="height: 130px">
                <span *ngIf="rp.season_room_types.length >= 5" style="position: absolute; left: -10px; top: 7px"
                  (click)="scrollRights(did)">
                  <img src="assets/img/manage_rates/arrowright.png" class="btn-hov"
                    [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="cursor: pointer; border-radius: 10px" />
                </span>
                <div class="push-div" style="padding-top: 5px">
                  <a class="btn btn-channel push-btn btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"
                    *ngIf="rp.push_status == 'pending'" (click)="seasonPush(rp.id)">Push</a>
                  <a class="btn btn-channel push-btn btn-hov" [ngClass]="
        darkMode && rp.push_status == 'pushed'
          ? 'btn-hov_dark live-btn'
          : 'btn-hov live-btn'
      " *ngIf="rp.push_status == 'pushed'" style="cursor: default">
                    Live
                  </a>
                </div>

                <div class="edit-delete">
                  <a (click)="duplicate_rateplan(rp)" style="margin-right: 4px">
                    <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small" [ngClass]="
          darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
        " width="25px" height="25px" style="border-radius: 10px" />
                  </a>
                  <a style="margin-right: 4px">
                    <img src="assets/img/newstaticicons/rates-packages/edit.svg " class="btn-hov"
                      [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" width="25px" height="25px"
                      (click)="editPlan(rp)" (click)="getHeight()" style="cursor: pointer; border-radius: 10px" />
                  </a>
                  <a>
                    <img data-toggle="modal" data-target="#confirmationModal-3"
                      src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small" [ngClass]="
          darkMode ? 'btn-hov-small_dark' : 'btn-hov-small'
        " width="25px" height="25px" width="25px" height="25px" (click)="
          deleteSeason(rp.season_name, rp.id, rp.created_at)
        " style="cursor: pointer; border-radius: 10px" />
                  </a>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="details" [ngClass]="darkMode ? 'card-darkS' : ' card-null '">
        <div>
          <span class="evt-name {{ colors2[did % 7] }}">
            Promotions
          </span>
          <i class="fa fa-sort-amount-down {{ colors2[did % 7] }}"
            style="margin-left: 10px; font-size: 16px; cursor: pointer" id="sortArrow{{ did }}"
            (click)="sortPromotions('sortArrow' + did, did)"></i>
          <!-- tool tip  starts-->
          <!-- style="top: 31%; right: 2.5%;" -->
          <span class="ispan">
            <i class="fas fa-info-circle i" [ngClass]="darkMode ? 'i_dark' : 'i'">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'"
                style="width: 155px; top: 190px; left: 180px; padding: 7px">Sorting Promotion by Date</span>
            </i>
          </span>

          <!-- tool tip ends -->
          <span style="float: right; margin-top: -2px" data-toggle="collapse" attr.data-target="#collapson{{ did }}">
            <img src="assets/img/reports/{{
      darkMode ? 'button_down_dark' : 'button_down'
    }}.svg" class="btn-hov rotate180" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="
      width: 18px;
      cursor: pointer;
      transition: 0.2s all ease-in-out;
    " id="dropDownCaret{{ did }}" (click)="dropChnge('dropDownCaret' + did)" />
          </span>
        </div>

        <div id="collapson{{ did }}" class="collapse">
          <div class="tabs">
            <div (click)="selectedTab = 'OTAs'">
              <div style="width: 50%; margin: 0 auto" [ngStyle]="{
        'border-bottom': selectedTab == 'OTAs' ? '3px solid' : ''
      }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">
                OTA's
              </div>
            </div>
            <div (click)="selectedTab = 'Channel'">
              <div style="width: 50%; margin: 0 auto" [ngStyle]="{
        'border-bottom': selectedTab == 'Channel' ? '3px solid' : ''
      }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">
                channels
              </div>
            </div>
            <div (click)="selectedTab = 'Loyalty'">
              <div style="width: 50%; margin: 0 auto" [ngStyle]="{
        'border-bottom': selectedTab == 'Loyalty' ? '3px solid' : ''
      }" class="{{ setClass(colors2[did % 7], bdr[did % 7]) }}">
                Loyalty
              </div>
            </div>
          </div>

          <div *ngIf="showSelectedPackage(rp.packages).length == 0"
            style="font-size: 16px; font-weight: bold; text-align: center">
            No package Found
            <br />Please Create An Event
          </div>
          <!-- Season Events -->
          <div class="card"
            *ngFor="let package of showSelectedPackage(rp.packages) | search : 'package_name,roomttype.name,room_rate,is_linked,room_id' : param;let i = index"
            style="padding-bottom: 1px!important; padding-right: 0!important; padding-top: 5px"
            [ngClass]="darkMode ? 'card-darkP': ' card-null '" id="package_id_{{package.id}}">
            <div class="row">
              <div class="col-md-3 pr-10 packageCol1">
                <div style="height: 65px">
                  <h1 (click)="
            service.is_preview = true;
            edit_package(package.id, true);
            selectpercentage(package.percentage)
          " data-target="#settings1" data-toggle="modal" class="{{ colors2[did % 7] }} pkg_title hover_shade" style="
            text-transform: capitalize;
            font-size: 18px;
            line-height: 1 !important;
            transform: translateY(-2px);
          ">
                    {{ package.package_name }}
                  </h1>
                </div>

                <p class="linked" id="linked7" [ngStyle]="{
          'margin-top':
            package?.package_type != 'otas' ? '7%' : '18%'
        }" [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '">
                  {{ dateFunc(package.date_to) | date: "dd MMM YYYY" | uppercase }} | {{ dateFunc(package.date_from) |
                  date:
                  "dd MMM YYYY" | uppercase }}
                </p>

                <h4 class="linked" id="linked8" *ngIf="package.otas.length == 0 &&  package?.package_type != 'otas'"
                  [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '"
                  (click)="openPackageChannels(package.otas, package.id)">
                  {{ package.package_type == "OTAs" ? "No OTAs Connected" : package.package_type == "Channel" ? "No
                  Channels Connected" : package.package_type == "Loyalty" ? "No Loyalties Connected" : "Not Connected"
                  }}
                </h4>

                <!-- "rates_and_packages.list.no_channels_connected" | translate -->
                <div #logoContainer class="icon-group-{{ package.id }} ico1 channelsLine"
                  *ngIf="package?.package_type != 'otas'">
                  <ul #logoList class="nav nav-pills otas-list">
                    <li *ngFor="let logo of getOTAs(package) | slice: 0:logos_quantity; let logosi = index"
                      class="sp-{{ logo.id }}" (click)="openPackageChannels(package.otas, package.id)">
                      <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}" src="assets/img/svgicons/smallicons/{{
                logo.channel.image
              }}" style="height: 32px" />
                    </li>
                    <li *ngIf="package.otas.length > logos_quantity" class="pull-right">
                      <img class="dropdown-toggle last-img" src="assets/img/manage_rates/dots.png" (click)="
                openPackageChannels(package.otas, package.id)
              " class="btn-hov" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov_dark'" style="
                height: 32px;
                border-radius: 100px;
                cursor: pointer;
              " />
                      <ul class="dropdown-menu" style="right: auto">
                        <li *ngFor="let logo of getOTAs(package) | slice: logos_quantity"
                          class="sp-{{ logo.id }} btn-hov">
                          <a href="#">
                            <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                              src="assets/img/svgicons/smallicons/{{ logo.channel.image }}" style="height: 32px" />
                          </a>
                        </li>

                        <li *ngFor="let logo of filterChannelsByPackage(package) | slice: logos_quantity"
                          class="sp-{{ logo.id }} btn-hov">
                          <a href="#">
                            <img data-toggle="tooltip" data-placement="top" title="{{ logo.channel.name }}"
                              src="assets/img/svgicons/smallicons/{{ logo.channel.image }}" style="height: 32px" />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-5 pl-10 pr-10 packageCol2">
                <div class="row">
                  <div class="img-box col-md-4 pr-10">
                    <a (click)="service.is_preview = true; edit_package(package.id, true);   selectpercentage(package.percentage)
            " data-target="#settings1" data-toggle="modal">
                      <img class="img-responsive hover_shade" style="
                border-radius: 10px;
                width: 100%;
                height: 133px;
                cursor: pointer;
              " src="{{
                (package.room?.images)[0]?.image
                  ? api.imgUrl + package.room.images[0]?.image
                  : 'assets/img/other/no-room.jpg'
              }}" />
                    </a>
                  </div>
                  <div class="text-box col-md-8 pl-10">
                    <a class="totlo">
                      <span (click)="service.is_preview = true; edit_package(package.id, true); selectpercentage(package.percentage)
            " data-target="#settings1" data-toggle="modal" class="description {{ colors2[did % 7] }} hover_shade"
                        style="cursor: pointer; text-transform: capitalize">
                        {{ package.roomttype.name }}</span>
                      <!-- <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" style="top: 10%;" *ngIf="package.roomttype.display_name == null"  >

              {{package.roomttype.display_name}}
            </span> -->
                    </a>
                    <span *ngIf="package.is_duplicated == 1" class="duplicate_text">
                      Duplicated
                    </span>
                    <p (click)="  service.is_preview = true;
              edit_package(package.id, true);
              selectpercentage(package.percentage)
            " data-target="#settings1" data-toggle="modal" class="desc_text hover_shade" *ngIf="package.room != null"
                      [ngClass]="darkMode ? 'card-dark-Date' : ' card-null '">
                      {{ package.room.description }}
                    </p>
                    <div class="room-details" [ngClass]="darkMode ? 'roomDR' : 'null'">
                      <ul class="nav navbar-nav pull-left">
                        <li>
                          <a class="totlo">
                            <img src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/bed.svg" [ngStyle]=" darkMode ? {
                            margin: '-10px 5px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " style="margin: -10px 5px -5px 0px; height: 40px" />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                {{ package.room?.bedtype.name }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a class="totlo">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{ darkMode ? 'darkmode' : 'lightmode' }}/sleep.svg"
                              [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                Sleeps
                                {{
                                package.room?.adults +
                                package.room?.children +
                                package.room?.infants
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a class="totlo">
                            <img src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/area.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                {{ package.room?.room_size }}
                                {{
                                package.room?.room_size_type == "meters"
                                ? "m"
                                : "Sq ft"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="package?.room?.pets != ''">
                          <a class="totlo">
                            <img *ngIf="package?.room?.pets == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/pets.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " class="image-adj" style="height: 20px" />
                            <img *ngIf="package?.room?.pets == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/nopets.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " class="image-adj" style="height: 20px" />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                {{
                                package?.room?.pets == "true"
                                ? "Allowed"
                                : "Not Allowed"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="package?.room?.wheelchairaccessibility != ''">
                          <a class="totlo">
                            <img *ngIf="
                      package?.room?.wheelchairaccessibility ==
                      'true'
                    " src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/wheel.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " alt="" class="image-adj" style="height: 25px; margin-top: -5px" />
                            <img *ngIf="
                      package?.room?.wheelchairaccessibility ==
                      'false'
                    " src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/nowheel.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " alt="" class="image-adj" style="height: 25px; margin-top: -5px ;" />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                {{
                                package?.room?.wheelchairaccessibility ==
                                "true"
                                ? "Available"
                                : "Not Available"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li *ngIf="package?.room?.smoking != ''">
                          <a class="totlo">
                            <img *ngIf="package?.room?.smoking == 'true'" src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/smoke.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " class="image-adj" style="height: 20px; margin-top: 0px" />
                            <img *ngIf="package?.room?.smoking == 'false'" src="assets/img/newstaticicons/rates-packages/{{
                      darkMode ? 'darkmode' : 'lightmode'
                    }}/nosmoke.svg" [ngStyle]="
                      darkMode
                        ? {
                            margin: '-10px 0px -5px 0px',
                            height: '40px'
                          }
                        : {}
                    " class="image-adj" style="height: 20px; margin-top: 0px" />
                            <span class="tooltiptext" [ngClass]="
                      darkMode ? 'tooltiptextdark' : 'tooltiptext'
                    ">
                              <p style="margin: 0px">
                                {{
                                package?.room?.smoking == "true"
                                ? "Allowed"
                                : "Not Allowed"
                                }}
                              </p>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a class="totlo">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/room-icon.svg"
                              [ngStyle]=" darkMode ? { margin: '-10px 0px -5px 0px', height: '40px' }: {height: '23px'} " />
                            <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                              <p style="margin: 0px">
                                {{ package.room?.display_name }}
                              </p>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 packageCol3">
                <div class="row">
                  <div class="season-box2 color-change"
                    style="transform: translateY(0px) !important; height: 133px;padding: 0 0 0 5px;"
                    [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null'">
                    <div class="new" id="n1" style="height: 100%">
                      <div class="d-flex flex-column justify-content-between h-100">
                        <div class="d-flex justify-content-between align-items-center">
                          <img src="assets/img/newstaticicons/rates-packages/promotion.svg" width="90px"
                            alt="promotion image" class="promoTionImg" />
                          <div class="d-flex align-items-center">
                            <p class="pr-2" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
                              {{ getRoundPrice( package.sum_price, package.seasonRoomPrice ) }}
                            </p>
                            <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="" *ngIf="
                          getRoundPrice( package.sum_price, package.seasonRoomPrice).length > 0 &&
                          getRoundPrice( package.sum_price,package.seasonRoomPrice ).charAt(0) !== '-'" />
                          </div>
                        </div>
                        <div class="pkg-off" *ngIf="package?.percentage != 0">
                          {{ package?.percentage }}% Off
                        </div>
                        <div style="margin-top: 7px">
                          <span class="breakfast" style="color: #192a7a;">
                            {{package.room.adults}} Adults <span>/{{package.min_stay}} nights min stay</span>
                          </span>
                          <div class="py-1" style="font-size: 14px; color: #707070">
                            <span class="price {{ colors[i % 7] }}" *ngIf="dateFunc2(package.date_from).diff(dateFunc2(package.date_to),
                          'days' ) >= 1" style="font-size: 16px !important">
                              {{current_currency}}{{roundPricee( package?.sum_price /
                              dateFunc2(package.date_from).diff( dateFunc2(package.date_to),
                              "days") ) | number:'1.2-3'}}
                            </span>
                            <span class="price {{ colors[i % 7] }}" *ngIf="dateFunc2(package.date_from).diff(
                          dateFunc2(package.date_to), 'days') == 0 " style="font-size: 15px !important">
                              {{current_currency}}{{ package?.sum_price | number:'1.2-3'}}
                            </span>
                            <b>/per night</b>
                          </div>
                          <span class="breakfast">
                            <i class="fa fa-utensils"></i> Breakfast {{package.breakfast_include == 'Yes' ? '' : 'Not'}}
                            Included
                          </span>
                          <!-- <span class="breakfast"
                            *ngIf="package.breakfast_include == 'Paid' ||  package.breakfast_include == 'Exclude'">
                            <i class="fa fa-utensils"></i> Breakfast Not Included
                          </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="season-box color-change"
                    style=" width: 48%; transform: translateY(0px) !important; height: 133px;"
                    [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null'">
                    <div class="new" id="n5" style="height: 100%">
                      <div class="rate-up">
                        <div class="rate-1 hei1" style="transform: translateY(2px)">
                          <span class="total pkg_title {{ colors[i % 7] }}"
                            style="font-size: 16px;font-weight: bold;text-transform: uppercase;font-family: robotoblack;">
                            Total
                          </span>
                        </div>
                        <div style="color: #707070; font-size: 13px;">
                          <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old':'/night'}}.svg"
                            style="width: 17px;margin: 0 5px 2px 0;" />
                          <b>{{ dateFunc2(package.date_from).diff(dateFunc2(package.date_to),"days" )}} nights</b>
                        </div>

                        <div class="rate-2 hei2">
                          <span class="tax_cl pr-1" style="text-decoration: line-through 1px black;font-weight: bold;">
                            {{current_currency}}{{ package?.seasonRoomPrice | number:'1.2-3' }}
                          </span>
                          <br>
                          <span class="price {{ colors[i % 7] }}">
                            {{current_currency}}{{package?.sum_price | number:'1.2-3' }}
                          </span>

                        </div>
                        <div class="tax_cl">
                          <span class="breakfast"> +{{current_currency}}{{calculteTaxAmount(package?.seasonRoomPrice) |
                            number:'1.2-3'}}
                            taxes </span>
                          <!-- Includes tax -->
                        </div>
                      </div>
                      <div class="rate-down">
                        <div class="rate-3" *ngIf="package?.package_type != 'Loyalty'">
                          <a class="btn btn-channel link_to_ota_btn btn-hov"
                            [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"
                            *ngIf="package.push_status == 'pending'">Push</a>
                          <a class="btn btn-channel link_to_ota_btn btn-hov" [ngClass]="
                    darkMode && package.push_status == 'pushed'
                      ? 'btn-hov_dark live-btn'
                      : 'btn-hov live-btn'
                  " *ngIf="package.push_status == 'pushed'" style="cursor: default">Live</a>
                        </div>

                        <div class="rate-3" *ngIf="package?.package_type == 'Loyalty'">
                          <a class="btn btn-channel link_to_ota_btn btn-hov"
                            [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'" style="width: 74px !important">
                            Send Link
                          </a>
                        </div>
                        <div class="rate-4">
                          <a (click)="duplicate_package(package.id)">
                            <img src="assets/img/newstaticicons/rates-packages/duplicate.svg" class="btn-hov-small"
                              [ngClass]="
                      darkMode
                        ? 'btn-hov-small_dark'
                        : 'btn-hov-small'
                    " width="25px" height="25px" style="border-radius: 10px" />
                          </a>
                          <a (click)="
                    edit_package(package.id, false);
                    selectpercentage(package.percentage)
                  " data-target="#settings1" data-toggle="modal">
                            <img src="assets/img/newstaticicons/rates-packages/edit.svg" class="btn-hov-small"
                              [ngClass]="
                      darkMode
                        ? 'btn-hov-small_dark'
                        : 'btn-hov-small'
                    " width="25px" height="25px" style="border-radius: 10px" />
                          </a>
                          <a class="" data-toggle="modal" data-target="#confirmationModal-3" (click)="
                    delete(package.id,package.package_name, package.created_at)">
                            <img src="assets/img/newstaticicons/rates-packages/delete.svg" class="btn-hov-small"
                              [ngClass]="
                      darkMode
                        ? 'btn-hov-small_dark'
                        : 'btn-hov-small'
                    " width="25px" height="25px" style="border-radius: 10px" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Season Events -->
        </div>
      </div>

    </div>
  </div>
  <!-- SEASON END -->
</div>

<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
        <div class="row">

          <div class="pr-10 pl-0" [ngClass]="{'col-md-10': !service.is_preview, 'pl-2': service.is_preview}"
            id="col0100" style="padding-right: 20px;">
            <div class="tab-content scrollo pd-scroll" style="padding-right: 5px">
              <div class="tab-pane active">
                <div class="alert alert-info alert-dismissible show" role="alert"
                  [ngClass]="darkMode ? 'alert-info-dark' : ' card-null '" style="margin-bottom: 5px;cursor: grab;"
                  id="grabingo" (mousedown)="downGrab('grabingo','down')" (mouseup)="downGrab('grabingo','up')"
                  (mouseover)="dodrag('settings')" (mouseout)="dontdrag('settings')">
                  Preview
                  <button type="button" class="close op-adj" data-dismiss="modal" aria-label="Close">
                    <strong id="cross">&#x2716;</strong>
                  </button>
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Package Modal -->
<div id="settings1" class="modal" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg {{service.is_preview !== true ? '' : 'preview-modal'}}"
    role="document">
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="modal-body" style="padding-right: 0px" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
        <div class="row dis-flex">
          <div *ngIf="service.is_preview !== true" class="popup-nav popup-nav-padd">
            <ul class="nav flex-column" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <li class="nav-item setup_package active" routerLinkActive="active"
                routerLink="/app/rates_packages/setup_packages" id="setup_package_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-package.svg" style="width: 30px" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ !service.is_edit ? "Setup" : "Edit" }}<br />
                    <strong>
                      Promotion
                    </strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/setup_rates"
                id="setup_rate_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/rate-dis.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    {{ !service.is_edit ? "" : "" }}
                    <strong>Rate Distribution</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/setup_restrictions"
                id="setup_restrictions_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-policy.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">{{ !service.is_edit ?
                    "Setup" : "Edit" }}<br />
                    <strong>Policy</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/link_to_otas"
                id="link_to_ota_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">Select<br />
                    <strong>OTAs</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" routerLink="/app/rates_packages/link_to_channels"
                id="link_to_ota_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Select<br />
                    <strong>Channels</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item cg-btn-blank" routerLinkActive="active"
                routerLink="/app/rates_packages/preview_packages" id="preview_package_open" (click)="getHeight22()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/search.svg" />
                  <span data-toggle="tootip" title="Preview" class="ellipses txt_color"
                    [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">Preview<br />
                    <strong>Package</strong>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="pr-0 pl-0 wid-100" [ngClass]="{ '': !service.is_preview, 'pl-20': service.is_preview }"
            id="col101">
            <div class="tab-content scrollo pd-scroll">
              <div class="tab-pane active" id="packageModal">
                <div class="alert alert-info alert-dismissible show ModalBarBlue" role="alert" id="grab-10"
                  [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '" (mouseover)="dodrag1()"
                  (mouseout)="stopDrag1()">
                  <p *ngIf="
                    !['/app/rates_packages/preview_packages'].includes(
                      router.url
                    )
                  " style="transform: translateY(2px)">
                    {{ service.is_edit == true ? "Edit Promotion" : "Create Promotion" }}
                  </p>
                  <p *ngIf="
                    ['/app/rates_packages/preview_packages'].includes(
                      router.url
                    )
                  " style="transform: translateY(2px); font-size: 14px;">
                    Preview Event
                  </p>
                  <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button" (click)="onClose()">
                    <strong id="strong">&#x2716;</strong>
                  </button>
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Package Modal  End-->

<!-- Season modal -->
<div id="settings2" class="modal" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="modal-body" style="padding-right: 0px" [ngClass]="darkMode ? 'card-dark-modal-body' : ' card-null '">
        <div class="row dis-flex">
          <div *ngIf="service.is_preview !== true" class="popup-nav popup-nav-padd">
            <ul class="nav flex-column" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <li class="nav-item setup_package active" routerLinkActive="active"
                [routerLink]="[{ outlets: { seasonPanel: 'setup_season' } }]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-package.svg" style="width: 40px" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Setup
                    <br />
                    <strong>
                      Plan
                    </strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                { outlets: { seasonPanel: 'setup_season_rates' } }
              ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/rate-dis.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Rate
                    <strong>Distribution</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                { outlets: { seasonPanel: 'setup_season_policy' } }
              ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/setup-policy.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Setup
                    <br />
                    <strong>Policy</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[ { outlets: { seasonPanel: 'setup_season_ota' } }
              ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/link-ota.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Setup
                    <br />
                    <strong>OTAs</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item" routerLinkActive="active" [routerLink]="[
                { outlets: { seasonPanel: 'setup_season_channel' } }
              ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/select-channel.svg" />
                  <span class="txt_color" [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Select
                    <br />
                    <strong>Channels</strong>
                  </span>
                </a>
              </li>
              <li class="nav-item cg-btn-blank" routerLinkActive="active" [routerLink]="[
                { outlets: { seasonPanel: 'setup_season_preview' } }
              ]" (click)="getHeight()">
                <a class="nav-link">
                  <img src="assets/img/newstaticicons/rates-packages/search.svg" />
                  <span data-toggle="tootip" title="" class="ellipses txt_color"
                    [ngClass]="darkMode ? 'txt_color_dark' : 'txt_color'">
                    Preview
                    <br />
                    <strong>Rate Plan</strong>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="pr-0 pl-0 wid-100" [ngClass]="{ '': !service.is_preview, 'pl-2': service.is_preview }" id="col10">
            <div class="tab-content scrollo pd-scroll">
              <div class="tab-pane active" id="seasoneModal">
                <div class="alert alert-info alert-dismissible show ModalBarBlue" role="alert" id="grab-10"
                  [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '" (mouseover)="dodrag2()"
                  (mouseout)="stopDrag2()">
                  <p *ngIf="
                    ![
                      '/app/rates_packages/(seasonPanel:setup_season_preview)'
                    ].includes(router.url)
                  " style="transform: translateY(2px)">
                    {{ editPlans === true ? "Edit Rate Plan" : " Create Rate Plan" }}
                  </p>
                  <p *ngIf="
                    [
                      '/app/rates_packages/(seasonPanel:setup_season_preview)'
                    ].includes(router.url)
                  ">
                    Preview Rate Plan
                  </p>
                  <button type="button" aria-label="Close" class="close mr-to" data-dismiss="modal" (click)="onClose()">
                    <strong id="strong">&#x2716;</strong>
                  </button>
                </div>
                <router-outlet name="seasonPanel"></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Season modal End-->

<!-- Channel Modal -->
<div class="modal fade" id="ChannelModal" role="dialog">
  <div class="modal-dialog" style="width: 300px">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="
      darkMode
        ? 'card-dark-modal-body modal-content-dark'
        : ' card-null-null '
    ">
      <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
        [ngClass]="darkMode ? 'card-dark-top-nav ' : ' card-null-null '">
        CONNECTIVITY
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <strong id="strong">&#x2716;</strong>
        </button>
      </div>
      <div class="mainScollor">
        <div class="scrollor">
          <div class="row" style="padding: 0px 5px 0px 20px">
            <div class="col-md-12" style="padding: 0px; padding-right: 20px">
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'OTAs'">
                <div class="row" style="padding-left: 5px">
                  <div class="mt-2">
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      OTAs
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllOta($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otpd of packageChannels; let i = index">
                  <div class="row">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otpd?.type == 'External'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/otas/{{
                            darkMode ? 'ota_dark/' + otpd?.image : otpd?.image
                          }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otpd.isBinded" (change)="selectOta($event, otpd?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- *ngIf="this.selectedTab==='Channel'" -->
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'Channel'">
                <div class="row" style="padding-left: 5px">
                  <div>
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      Channels
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllChannels($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otob of packageChannels; let i = index">
                  <div class="row" *ngIf="
                    otob.name !== 'Loyalty Program' && otob.name !== 'Walk-in'
                  ">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otob?.type == 'Internal'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/channels/{{ otob?.image }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otob?.isBinded" (change)="selectOta($event, otob?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 zer0-padding" [hidden]="this.selectedTab !== 'Loyalty'">
                <div class="row" style="padding-left: 5px">
                  <div>
                    <div class="AllC" [ngClass]="darkMode ? 'BlueHD' : 'BlueHL'" style="text-align: center">
                      Loyalty
                    </div>
                    <div class="AllC">
                      <input class="checkbox" type="checkbox" id="triggerO" (change)="selectAllChannels($event)" />
                    </div>
                  </div>
                </div>
                <div class="chaneelDiv" *ngFor="let otob of packageChannels; let i = index">
                  <div class="row" *ngIf="
                    otob.name === 'Loyalty Program' || otob.name === 'Walk-in'
                  ">
                    <div class="col-md-12" style="padding: 0px" *ngIf="otob?.type == 'Internal'">
                      <label for="trigger{{ i }}">
                        <div class="channelimg">
                          <img alt="" class="image-137" src="assets/img/svgicons/channels/{{ otob?.image }}" />
                        </div>
                        <div class="channelBtn">
                          <input class="checkbox" name="trigger{{ i }}" type="checkbox" id="trigger{{ i }}"
                            [checked]="otob?.isBinded" (change)="selectOta($event, otob?.id)" />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="form-control save-buton" [ngClass]="darkMode ? 'save-btn-dark' : 'save-btn-light'"
        data-dismiss="modal" id="closechannel" (click)="saveOtas()">
        Save
      </button>
    </div>
  </div>
</div>
<!-- Channel Modal END-->
