import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoriesAndItems, CategoriesAndItemsResponse, CategoryItem, ShopCategoriesAndItem } from 'src/app/common/fnb/CategoriesAndItemsModel';
import { Item, MenuProductItems, MenuProductResponse, Product } from 'src/app/common/fnb/MenuProductModel';
import { Shops } from 'src/app/common/fnb/createShop';
import { FnbService } from 'src/app/services/fnb.service';
import { CreateMenuModel, MenuDealModel, dealItems } from './CreateMenuModel';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { CreateMenuDealItemResponse, ShopDeal } from 'src/app/common/fnb/createMenuDealItemModel';
import { MenuDealItemResponse, ShopDealItem } from 'src/app/common/fnb/MenuDealItem';
declare var $: any;
interface FilteredItem {
  item_id: number;
  item_name: string;
}
@Component({
  selector: 'app-create-menu',
  templateUrl: './create-menu.component.html',
  styleUrls: ['./create-menu.component.css']
})
export class CreateMenuComponent implements OnInit {

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('fileDealInput', { static: false }) fileDealInput!: ElementRef;
  productModalShow = false;
  dealModalShow = false;
  active = false;
  btn1 = false;
  btn2 = false;
  imageShow = false;
  darkMode: boolean = false;
  shopNames: Shops[] = [];
  activeShopId: number = 0;
  category_name: number;
  cost_price: number;
  selling_price: number;
  item_name: any;
  file_name: string;
  selectedFile: File;
  createMenuItems: Product[] = [];
  categories: CategoriesAndItems[] = [];
  items: CategoryItem[] = [];
  filteredItems: FilteredItem[] = [];
  selectedImage: string = '';
  currency_format = localStorage.getItem('currency');
  property_id = localStorage.getItem("current_property")
  editMode: boolean = false;
  formData: FormData = new FormData();
  editIndex: number;
  formDataValues: CreateMenuModel;
  // deal popup properties
  deal_name: string;
  deal_cost_price: number
  deal_selling_price: number
  deal_item_name;
  deal_image: string
  dealItems: ShopDealItem[] = [];
  randomColor = ['#76A200', '#EC1361', '#0179F1', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];
  item_id: number;
  deal_item_id

  constructor(
    private fnbService: FnbService,
    private toaster: ToastrService,
    private confirm: ConfirmationAlertService,
  ) {
    this.getShopNames();
  }

  ngOnInit() {
    this.checkDarkMode();

    const tabsBar = document.getElementById('tabsBarCreateMenu');
    // x axis mouse wheel scroll breadcrumb (working on saud 👇)
    tabsBar.addEventListener('wheel', (event) => {
      event.preventDefault();
      tabsBar.scrollLeft += event.deltaY;
    });

  }

  // get shop name (working on saud 👇)
  getShopNames() {
    this.fnbService.getcreateShopNames().subscribe(res => {
      this.shopNames = res.data.shops;
      this.switchShop(this.shopNames[0].shop_id);
    });
  }

  select_product() {
    this.active = !this.active;
    this.btn1 = !this.btn1;
    this.btn2 = !this.btn2;
  }

  create_product() {
    this.active = !this.active;
    this.btn1 = !this.btn1;
    this.btn2 = !this.btn2;
    this.productModalShow = true;
    this.category_name = null;
    this.cost_price = null;
    this.selling_price = null;
    this.item_name = null;
    this.file_name = null;
  }

  onSaveCategory() {
    this.productModalShow = false;
    this.formDataValues = {
      shop_menu_id: this.editMode ? this.createMenuItems[this.editIndex].shop_menu_id : 0,
      item_id: this.editMode ? this.item_id : this.item_name,
      category_id: this.category_name,
      shop_id: this.activeShopId,
      cost_price: this.cost_price,
      selling_price: this.selling_price,
      image: this.selectedFile,
      stock_status: 0,
      is_deleted: 0
    };

    this.formData = this.fnbService.getFormData(this.formDataValues, this.editMode);

    this.sendFormDataPayload(this.formData);
    this.editMode = false;
  }

  sendFormDataPayload(data: FormData) {
    this.fnbService.createMenuProductItemReq(data).subscribe((res: any) => {
      this.toaster.success(res.message);
      this.getMenuProductsItemsSelectShop(this.activeShopId);
    });
  }

  create_deal() {
    this.active = !this.active;
    this.btn1 = !this.btn1;
    this.btn2 = !this.btn2;
    this.dealModalShow = true;
    this.deal_name = null;
    this.deal_cost_price = null;
    this.deal_selling_price = null;
    this.deal_item_name = null;
    this.file_name = null;
  }

  onSaveDeal() {
    this.dealModalShow = false;
    let dealFromData = new FormData();

    const dealItemsArray: dealItems[] = this.deal_item_name.map(item => ({
      deal_item_id: 0,
      item_id: item,
      is_deleted: 0
    }));

    let dealItemsId: dealItems[] = [];
    if (this.deal_item_id) {
      dealItemsId = this.deal_item_id.map(item => ({
        deal_item_id: 0,
        item_id: item,
        is_deleted: 0
      }));
    }

    const dealItems = this.editMode ? JSON.stringify(dealItemsId) : JSON.stringify(dealItemsArray);

    const createDeal: MenuDealModel = {
      shop_deal_id: this.editMode ? this.dealItems[this.editIndex].shop_deal_id : 0,
      deal_items: dealItems,
      shop_id: this.activeShopId,
      deal_name: this.deal_name,
      deal_cost_price: this.deal_cost_price,
      deal_selling_price: this.deal_selling_price,
      image: this.selectedFile,
      property_id: this.property_id,
      is_active: 0,
      is_deleted: 0
    };


    dealFromData = this.fnbService.getFormData(createDeal, this.editMode);

    dealFromData.forEach(ele => {
      console.log(ele);
    })

    this.menuDealItemReq(dealFromData);

    this.editMode = false;
  }

  menuDealItemReq(dealFromData: FormData) {
    this.fnbService.createMenuDealItemReq(dealFromData).subscribe((res: CreateMenuDealItemResponse) => {
      this.toaster.success(res.message);
      this.getDealMenuItems(this.activeShopId);
    });
  }

  onModalClose() {
    this.productModalShow = false;
    this.dealModalShow = false;
  }

  imageFullPre() {
    this.imageShow = !this.imageShow;
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  handleFileSelect(): void {
    const files: FileList | null = this.fileInput.nativeElement.files;
    if (files && files.length) {
      this.selectedFile = files[0];
      // Perform file upload logic here
      this.file_name = this.selectedFile.name;
      this.deal_image = this.selectedFile.name;
    }
  }

  //  switch shop button click to get data (working on saud 👇)
  switchShop(shop_id: number) {
    this.activeShopId = shop_id;

    this.fnbService.getMenuCategoriesAndItems(shop_id).subscribe((res: CategoriesAndItemsResponse) => {
      this.categories = res.data.shops;
      this.items = this.categories.reduce((acc, shop) => acc.concat(shop.category_items), []);
    });

    this.getMenuProductsItemsSelectShop(shop_id);
    this.getDealMenuItems(shop_id);
  }

  //  selected shop fetch data product menu (working on saud 👇)
  getMenuProductsItemsSelectShop(shop_id: number) {
    this.fnbService.getMenuProductItems(shop_id).subscribe((res: MenuProductResponse) => {
      this.createMenuItems = res.data.products;
    });
  }

  //  change category to fetch related items get (working on saud 👇)
  onCategoryChange() {
    const selectedCategory = this.categories.find(category => category.category_id === this.category_name);
    if (selectedCategory) {
      this.filteredItems = selectedCategory.category_items.map(item => ({
        item_id: item.item_id,
        item_name: item.item_name
      }));
    } else {
      this.filteredItems = [];
    }
  }

  // edit button click to open popup and filled out filled according index to edit and update data (working on saud 👇)
  editProductMenu(index: number) {
    this.productModalShow = true;
    this.editMode = true;
    this.editIndex = index;
    this.category_name = this.createMenuItems[index].category.category_id;
    this.cost_price = this.createMenuItems[index].cost_price;
    this.selling_price = this.createMenuItems[index].selling_price;
    this.item_name = this.createMenuItems[index].item.item_name;
    this.item_id = this.createMenuItems[index].item_id;
    this.file_name = this.createMenuItems[index].image;
    this.onCategoryChange()
  }

  // delet button click to delete specific record (working on saud 👇)
  deleteProductMenu(index: number) {

    const deleteData = {
      shop_menu_id: this.createMenuItems[index].shop_menu_id,
      item_id: this.createMenuItems[index].item.item_id,
      category_id: this.createMenuItems[index].category.category_id,
      shop_id: this.createMenuItems[index].shop_id,
      cost_price: this.createMenuItems[index].cost_price,
      selling_price: this.createMenuItems[index].selling_price,
      image: this.createMenuItems[index].image,
      stock_status: 0,
      is_deleted: 1
    };

    let deleteFormData = this.fnbService.getFormData(deleteData, false);

    this.confirm.confirm(`Alert`, `Are you sure you want to delete this product menu?`,
      [`This action can't be undone`, 'Are you sure you want to delete?'],
      () => {
        this.sendFormDataPayload(deleteFormData);
      }
      , '', ''
    );
  }

  //  selected shop fetch data deal menu (working on saud 👇)
  getDealMenuItems(shopId: number) {
    this.fnbService.getDealsItem(shopId).subscribe((res: MenuDealItemResponse) => {
      this.dealItems = res.data.shops;
    })
  }

  deleteDealMenu(index: number) {
    const deleteData: MenuDealModel = {
      shop_deal_id: this.dealItems[index].shop_deal_id,
      deal_items: JSON.stringify(this.dealItems[index].deal_items),
      shop_id: this.dealItems[index].shop_id,
      deal_name: this.dealItems[index].deal_name,
      deal_cost_price: this.dealItems[index].deal_cost_price,
      deal_selling_price: this.dealItems[index].deal_selling_price,
      image: this.dealItems[index].image,
      property_id: this.dealItems[index].property_id,
      is_active: 0,
      is_deleted: 1
    };

    let deleteDealItem = this.fnbService.getFormData(deleteData, false);

    this.confirm.confirm(`Alert`, `Are you sure you want to delete this deal menu?`,
      [`This action can't be undone`, 'Are you sure you want to delte?'],
      () => {
        this.menuDealItemReq(deleteDealItem);
      }
      , '', ''
    );
  }

  editDealMenu(index: number) {
    this.dealModalShow = true;
    this.editMode = true;
    this.editIndex = index;
    this.deal_name = this.dealItems[index].deal_name;
    this.deal_cost_price = this.dealItems[index].deal_cost_price;
    this.deal_selling_price = this.dealItems[index].deal_selling_price;
    this.deal_item_name = this.dealItems[index].deal_items.map(item => item.item.item_name);
    this.deal_item_id = this.dealItems[index].deal_items.map(item => item.item.item_id);
    this.file_name = this.dealItems[index].image;
    console.log(this.deal_item_id, 'deal id');
  }

  // icon hover show deal remaining item in tooltip (working on saud 👇)
  getTooltipText(dealItem: ShopDealItem): string {
    const hiddenItems = dealItem.deal_items.slice(1).map(item => item.item.item_name);
    if (hiddenItems.length > 0) {
      return hiddenItems.join(', ');
    } else {
      return '';
    }
  }

}
