import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from "@angular/common";

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  public transform(value, keys: string, term: string) {

    if (term) return value;
    return (value || []).filter(item => {
      return keys.split(',').some(key => {
        return item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key]);
      });
    });
  }




}