import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { GlobaldataService } from '../../../common/globaldata.service';

@Component({
  selector: 'app-preview-package',
  templateUrl: './preview-package.component.html',
  styleUrls: ['./preview-package.component.css']
})
export class PreviewPackageComponent implements OnInit {
  package_data;
  numbers;
  otas = [];
  base_url;
  base_url2;
  package_dates: any;
  sleeps = 0;
  nights;
  desT: boolean = false;
  preview_image;
  modalImg: any;
  showImg: boolean = false;
  showdata: boolean = false;
  darkMode: boolean = false;
  percentage: any;
  seasonRoomPrice = 0
  amenCol = ['#a9aae0', '#d24f51', '#bf4eeb', '#efab20', '#32d04a', '#519eb9']
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    public service: ManageRatesPackagesService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.base_url = 'http://cm.com/cmlocal/public/';
    this.base_url2 = this.api.base_url2;
    this.get_package();
    this.checkDarkMode();
    this.percentage = GlobaldataService.previewPercent;
    if (this.service.package_creating == undefined) {
      this.router.navigate(['/app/rates_packages/'])
    }

  }

  get_package() {
    if (this.service.package_creating !== undefined) {
      this.api.get('api/package/show/' + this.service.package_creating.id, true).subscribe((res: any) => {
        this.package_data = res.body.data;
        this.seasonRoomPrice = this.service.package_creating.seasonRoomPrice
        this.otas = res.body.data.otas;
        this.package_dates = new Date(res.body.data.date_from).toLocaleString('default', { month: 'short', day: 'numeric' }) + ' - ' + new Date(res.body.data.date_to).toLocaleString('default', { month: 'short', day: 'numeric' });
        let a = res.body.data.date_to.split('/');
        let b = res.body.data.date_from.split('/')
        let start = moment(a[1] + '/' + a[0] + '/' + a[2]);
        let end = moment(b[1] + '/' + b[0] + '/' + b[2]);
        this.package_dates = {
          start: a[1] + '/' + a[0] + '/' + a[2],
          end: b[1] + '/' + b[0] + '/' + b[2]
        };
        this.nights = end.diff(start, "days")
        this.preview_image = this.package_data.room != null ? this.package_data.room.images.length != 0 ? this.base_url2 + 'app/images/' + this.package_data.room.images[0].image : 'assets/img/other/no-room.jpg' : 'assets/img/other/no-room.jpg';
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  getHi() {
    setTimeout(() => {
      let a = document.getElementById("prevDiv")
      if (a.offsetHeight < 700) {
        a.style.width = '100.7%'
      }
      else {
        a.style.width = '100%'
      }
    }, 100)
  }

  showImgModal(pimg) {
    this.modalImg = pimg;
    this.showImg = true;
  }

  hideImg() {
    setTimeout(() => {
      this.showdata = false;
    }, 100);
  }

  seeDescription() {
    this.desT = !this.desT
  }

  descripArray(data) {
    let windowWidth = window.innerWidth
    if (this.service.is_preview == true) {
      if (windowWidth <= 1440) {
        return data.split(' ').slice(0, 42).join().replace(/,/g, " ");
      }
      else if (windowWidth <= 1366) {
        return data.split(' ').slice(0, 32).join().replace(/,/g, " ");
      }
      else {
        return data.split(' ').slice(0, 52).join().replace(/,/g, " ");
      }
    }
    else {
      return data.split(' ').slice(0, 30).join().replace(/,/g, " ");
    }
  }

  descripArray2(data) {
    let windowWidth = window.innerWidth
    if (this.service.is_preview == true) {
      if (windowWidth <= 1440) {
        return data.split(' ').slice(42).join().replace(/,/g, " ");
      }
      else if (windowWidth <= 1366) {
        return data.split(' ').slice(22).join().replace(/,/g, " ");
      }
      else {
        return data.split(' ').slice(52).join().replace(/,/g, " ");
      }
    }
    else {
      return data.split(' ').slice(30).join().replace(/,/g, " ");
    }
  }

  mouo(id) {
    document.getElementById(id).style.visibility = 'visible'
  }

  mouout(id) {
    document.getElementById(id).style.visibility = 'hidden'
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  checkDays(d) {
    let w = [
      { name: 'Monday', status: false },
      { name: 'Tuesday', status: false },
      { name: 'Wednesday', status: false },
      { name: 'Thursday', status: false },
      { name: 'Friday', status: false },
      { name: 'Saturday', status: false },
      { name: 'Sunday', status: false }
    ];
    w.map((val) => {
      d.map((val2) => {
        if (val2 == val.name) {
          val.status = true;
        }
      })
    })
    return w;
  }

  nameChar(wd) {
    return wd.charAt(0);
  }

  dateFunc2(d) {
    let a = d.split('/');
    return moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]);
  }

  roundPrice(pa, pb) {
    let a = Math.round(pa * 100 / pb)
    return a
  }

  getRoundPrice(pa, pb): string {

    let a: number = Math.round(pa * 100 / pb)

    if (a > 0 && a < 100) {
      let val = 100 - a
      return `${val}`;
    }
    else {
      return ``;
    }
  }

}
