<div class="vendorMainDiv">
   <div>
      <div class="row">
         <div class="col-md-12">
            <div class="row" style=" padding: 0px 0px 0px 10px;">

               <div class="col-md-6" style="padding: 0px 0px 0px 5px;">
                  <!-- Add Vendor -->
                  <details class="det-cart-2" [ngClass]="darkMode ? 'det-cart-2_dark':'det-cart-2'" open>
                     <summary style="outline: none;">
                        <div class="main-summry" role="alert" style="outline: none;"
                           [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                           + New Vendor
                           <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              id="rotate180" class="imagedrop" height="20" width="20" alt=""
                              (click)="chandrop('rotate180')" />

                        </div>
                     </summary>
                     <div class="row" style="margin-left: 0px; margin-right: 0px;">
                        <form [formGroup]="add_vendor_form" (ngSubmit)="onSubmit()">
                           <div id="collapse1" class="col-md-12" style="padding-left: 5px; padding-right: 5px;">
                              <div class="col-md-6 " style="padding-left: 0px; padding-right: 0px;">
                                 <div class="border-style bhs-1 property-shade bd-color mgl backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'">
                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/user.svg" alt=""
                                          style="height: 20px; ">
                                    </div>


                                    <input type="text" class="form-control border"
                                       [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Full Name"
                                       formControlName="first_name" />
                                    <div *ngIf="submitted && f.first_name.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.first_name.errors.required">Name is Required</strong>
                                    </div>
                                 </div>
                                 <div class="border-style bhs-1 property-shade bd-color-2 mgl   backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'">
                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                                          style="height: 20px;  border-radius: 5px;">
                                    </div>


                                    <input type="text" class="form-control border"
                                       [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Phone no."
                                       formControlName="phone" />
                                    <div *ngIf="submitted && f.phone.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.phone.errors.required">Phone is Required</strong>
                                    </div>
                                 </div>


                                 <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'">
                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt=""
                                          style="height: 16px; border-radius: 4px;">
                                    </div>


                                    <input type="text" class="form-control border"
                                       [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email"
                                       formControlName="email" />
                                    <div *ngIf="submitted && f.email.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.email.errors.required">Email is Required</strong>
                                    </div>
                                 </div>

                                 <div class="border-style bhs-1 property-shade bd-color-3 mgl backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'">

                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/company.svg" alt=""
                                          style="height: 20px;">
                                    </div>


                                    <input type="text" class="form-control border"
                                       [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                       placeholder="Company Name" formControlName="company_name" />
                                    <div *ngIf="submitted && f.company_name.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.company_name.errors.required">Company Name is Required</strong>
                                    </div>
                                 </div>

                                 <div class="border-style bhs-1 property-shade bd-color-5 mgl backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'">

                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/dollar.svg" alt=""
                                          style="height: 20px; ">
                                    </div>


                                    <input type="text" class="form-control border"
                                       [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                       placeholder="Hourly Rate" formControlName="hourly_rate" />
                                    <div *ngIf="submitted && f.hourly_rate.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.hourly_rate.errors.required">Hourly Rate is Required</strong>
                                    </div>
                                 </div>

                                 <div class="border-style bhs-1 property-shade bd-color-7 mgr backColor eight"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'" style="margin-right: 2.5px;">

                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/tradef2.svg" alt=""
                                          style="height: 18px; ">
                                    </div>


                                    <ng-select class="bg_back pd_input" [ngClass]="darkMode ? 'dark-drop':'null'"
                                       placeholder="Trade" [items]="trade" bindValue="name" bindLabel="name"
                                       formControlName="trade" style="width: 100%;">
                                    </ng-select>
                                    <div *ngIf="submitted && f.trade.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.trade.errors.required">Trade is Required</strong>
                                    </div>
                                 </div>

                                 <div class="border-style bhs-1 property-shade bd-color-7 mgr backColor"
                                    [ngClass]="darkMode? 'bd-color_dark':'null'" style="margin-right: 2.5px;">

                                    <div class="icn_width">
                                       <img src="assets/img/newstaticicons/inventory/vendor.svg" alt=""
                                          style="height: 26px; border-radius:4px;">
                                    </div>


                                    <ng-select class="bg_back pd_input" [ngClass]="darkMode ? 'dark-drop':'null'"
                                       placeholder="Vendor Type" [items]="type" bindValue="name" bindLabel="name"
                                       formControlName="vendor_type" style="width: 100%;">
                                    </ng-select>
                                    <div *ngIf="submitted && f.vendor_type.errors"
                                       class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.vendor_type.errors.required">Type is Required</strong>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-md-6" style="padding-left: 0px; padding-right: 0px;">
                                 <!-- <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor" [ngClass]="darkMode? 'bd-color_dark':'null'">
                                    <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt="" style="height: 16px; margin-left:10px; border-radius: 4px;">
                                    <input type="text" class="form-control border" [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email" formControlName="email"/>
                                    <div *ngIf="submitted && f.email.errors" class="small text-danger no-margin invalid-feedback">
                                       <strong *ngIf="f.email.errors.required">Email is Required</strong>
                                    </div>
                                 </div> -->

                                 <div class="map_wrapper" style="height: 313px;">
                                    <input class="address-bar" formControlName="address" autocorrect="off"
                                       autocapitalize="off" spellcheck="off" id="searchbar" #searchbar />
                                    <div id="map" #map></div>
                                 </div>
                              </div>
                              <div *ngIf="submitted && f.lat.errors"
                                 class="small text-danger no-margin invalid-feedback">
                                 <strong *ngIf="f.lat.errors.required">Location is Required</strong>
                              </div>
                              <div class="butt">
                                 <button class="btn-blue hov-btn" type="submit"
                                    [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Save</button>
                              </div>
                           </div>
                        </form>
                     </div>
                  </details>
                  <!-- Add Vendor -->

                  <!-- Vendor List 1st Col -->
                  <ng-container *ngFor="let vl of vendorList;let i = index">
                     <div class="col-md-12" style="padding: 0px 0px 0px 0px;" *ngIf="i%2 != 0">
                        <details class="det-cart" [ngClass]="darkMode ? 'det-cart-2_dark':'det-cart'" open>
                           <summary class="main-summry" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                              <b>{{vl.first_name}}</b>
                              <div class="summry-left">
                                 {{vl.trade}}

                                 <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                    class="imagedrop" height="20" width="20" alt="" id="rotate180{{i}}"
                                    (click)="chandrop('rotate180'+i)" style="margin-left: 5px;" />

                              </div>
                           </summary>

                           <div class="row" style="margin-left: 0px; margin-right: 0px;" id="edt">
                              <div id="collapse1" class="col-md-12" style="padding: 0px;">
                                 <div class="col-md-6" style="padding-left: 5px; padding-right: 0px;">
                                    <div class="border-style bhs-1 property-shade bd-color mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'"
                                       style="margin-top: 5px; margin-right: 3px;">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/user.svg" alt=""
                                             style="height: 20px; ">
                                       </div>

                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Full Name" [(ngModel)]="vl.first_name"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-2 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                                          style="height: 20px;  border-radius: 5px;">
                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Phone no." [(ngModel)]="vl.phone"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>

                                    <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">

                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt=""
                                             style="height: 16px;  border-radius: 4px;">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email"
                                          [value]="vl.email" readonly />
                                    </div>

                                    <div class="border-style bhs-1 property-shade bd-color-3 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/company.svg" alt=""
                                             style="height: 20px; ">
                                       </div>

                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Company Name" [value]="vl.company_name" readonly />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-7 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/dollar.svg" alt=""
                                             style="height: 20px; ">
                                       </div>

                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Hourly Rate" [(ngModel)]="vl.hourly_rate"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-5 mgl backColor eight"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/tradef2.svg" alt=""
                                             style="height: 18px; ">
                                       </div>

                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Trade"
                                          [value]="vl.trade" readonly />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-5 mgl backColor "
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/vendor.svg" alt=""
                                             style="height: 26px; border-radius:4px;">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Vendor Type" [value]="vl.vendor_type" readonly />
                                    </div>
                                 </div>

                                 <div class="col-md-6" style="padding-left: 0px; padding-right: 5px;">
                                    <!-- <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor" [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt="" style="height: 16px; margin-left:10px; border-radius: 4px;">
                                       <input type="text" class="form-control border" [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email" [value]="vl.email" readonly/>
                                    </div> -->
                                    <div class="map_wrapper" style="height: 313px;">
                                       <input class="address-bar " [(ngModel)]="vl.address"
                                          [disabled]="selectEdit == i ? false : true">
                                       <img
                                          src="https://maps.googleapis.com/maps/api/staticmap?center={{vl.lat}},{{vl.lng}}&zoom=15&size=300x313&maptype=roadmap &markers=color:red%7Clabel:S%7C{{vl.lat}},{{vl.lng}}&key=AIzaSyBtGPJeKV8bZQuM73Yr97Q_FNKBqEnkDJ4">
                                    </div>
                                 </div>

                                 <div class="">
                                    <button class="btn-blue1 hov-btn" *ngIf="selectEdit != i" (click)="selectEdit = i;"
                                       [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Edit</button>
                                    <button class="btn-blue1 hov-btn" *ngIf="selectEdit == i"
                                       (click)="editSave(vl.first_name, vl.phone, vl.hourly_rate, vl.id)"
                                       [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Save</button>
                                 </div>
                              </div>
                           </div>
                        </details>
                     </div>
                  </ng-container>
                  <!-- Vendor List 1st Col END-->
               </div>

               <!-- Vendor List 2nd Col -->
               <div class="col-md-6" style="padding-left: 0px;min-height: 0px;"
                  *ngFor="let vl of vendorList;let i = index">
                  <ng-container *ngIf="i%2 == 0 && vendorList != undefined">
                     <div class="col-md-12" style="padding: 0px 0px 0px 5px;">
                        <details class="det-cart" [ngClass]="darkMode ? 'det-cart-2_dark':'det-cart'" open>
                           <summary class="main-summry" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                              <b>{{vl.first_name}}</b>
                              <div class="summry-left">
                                 {{vl.trade}}

                                 <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                    class="imagedrop" height="20" width="20" alt="" id="rotate180{{i}}"
                                    (click)="chandrop('rotate180'+i)" style="margin-left: 5px;" />

                              </div>
                           </summary>

                           <div class="row" style="margin-left: 0px; margin-right: 0px;" id="edt">
                              <div id="collapse1" class="col-md-12" style="padding: 0px;">
                                 <div class="col-md-6" style="padding-left: 5px; padding-right: 0px;">
                                    <div class="border-style bhs-1 property-shade bd-colo r mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'"
                                       style="margin-top: 5px; margin-right: 3px;">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/user.svg" alt=""
                                             style="height: 20px; ">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Full Name" [(ngModel)]="vl.first_name"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-2 mgl hov backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                                             style="height: 20px;  border-radius: 5px;">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Phone no." [(ngModel)]="vl.phone"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>

                                    <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt=""
                                             style="height: 16px;  border-radius: 4px;">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email"
                                          [value]="vl.email" readonly />
                                    </div>

                                    <div class="border-style bhs-1 property-shade bd-color-3 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/company.svg" alt=""
                                             style="height: 20px; ">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Company Name" [value]="vl.company_name" readonly />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-7 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">

                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/dollar.svg" alt=""
                                             style="height: 20px; ">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Hourly Rate" [(ngModel)]="vl.hourly_rate"
                                          [disabled]="selectEdit == i ? false : true" />
                                    </div>
                                    <div class="border-style bhs-1 property-shade bd-color-5 mgl backColor"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/tradef2.svg" alt=""
                                             style="height: 18px; ">
                                       </div>

                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Trade"
                                          [value]="vl.trade" readonly />
                                    </div>

                                    <div class="border-style bhs-1 property-shade bd-color-5 mgl backColor eight"
                                       [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <div class="icn_width">
                                          <img src="assets/img/newstaticicons/inventory/vendor.svg" alt=""
                                             style="height: 26px; border-radius:4px;">
                                       </div>


                                       <input type="text" class="form-control border"
                                          [ngClass]="darkMode ? 'form-control_dark':'form-control'"
                                          placeholder="Vendor Type" [value]="vl.vendor_type" readonly />
                                    </div>
                                 </div>

                                 <div class="col-md-6" style="padding-left: 0px; padding-right: 5px;">
                                    <!-- <div class="border-style bhs-1 property-shade bd-color-9 mgr backColor" [ngClass]="darkMode? 'bd-color_dark':'null'">
                                       <img src="assets/img/newstaticicons/inventory/r-messege-red.svg" alt="" style="height: 16px; margin-left:10px;">
                                       <input type="text" class="form-control border" [ngClass]="darkMode ? 'form-control_dark':'form-control'" placeholder="Email" [value]="vl.email" readonly/>
                                    </div> -->
                                    <div class="map_wrapper" style="height: 313px;">
                                       <input class="address-bar" [(ngModel)]="vl.address"
                                          [disabled]="selectEdit == i ? false : true">
                                       <img
                                          src="https://maps.googleapis.com/maps/api/staticmap?center={{vl.lat}},{{vl.lng}}&zoom=15&size=300x313&maptype=roadmap &markers=color:red%7Clabel:S%7C{{vl.lat}},{{vl.lng}}&key=AIzaSyBtGPJeKV8bZQuM73Yr97Q_FNKBqEnkDJ4">
                                    </div>
                                 </div>

                                 <div class="">
                                    <button class="btn-blue1 hov-btn" *ngIf="selectEdit != i" (click)="selectEdit = i;"
                                       [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Edit</button>
                                    <button class="btn-blue1 hov-btn" *ngIf="selectEdit == i"
                                       (click)="editSave(vl.first_name, vl.phone, vl.hourly_rate, vl.id)"
                                       [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Save</button>
                                 </div>
                              </div>
                           </div>
                        </details>
                     </div>
                  </ng-container>
               </div>
               <!-- Vendor List 2nd Col END-->

            </div>
         </div>

      </div>
   </div>
</div>
<div class="modal-footer clrtb">
   <button type="button" data-dismiss="modal" class="hov-btn" [ngClass]="darkMode ? 'btnred_dark' : 'null'">
      Close
   </button>
</div>