import { Injectable, AfterViewInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  spinner = false;
  dark_mode;
  collapse;
  constructor() { }

  check_dark_mode() {
    let res;
    let status = JSON.parse(localStorage.getItem('user'));
    res = status.mode == 'light' ? false : true;
    // console.log("res here... ", res);
    return res;
  }

  check_sidebar_collapse() {
    let res;
    let status = localStorage.getItem('sidebar_collapse');
    res = status == 'true' ? true : false;
    return res;
  }

  sidebar_collapse() {
    this.collapse = !this.check_sidebar_collapse();
    localStorage.setItem('sidebar_collapse', this.collapse);
  }

}
