import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { get } from 'http';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { HelperService } from 'src/app/services/helper.service';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  contact_support_form: FormGroup;
  submitted = false;
  darkMode: boolean = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
  ) {

  }

  ngOnInit() {
    this.checkDarkMode();


    let id = JSON.parse(localStorage.getItem('current_property'));
    this.contact_support_form = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      user_id: ['', Validators.required],
    });

  }

  get f() { return this.contact_support_form.controls; }

  onSubmit() {
    this.submitted = true;
    let id = JSON.parse(localStorage.getItem('user')).id;
    this.contact_support_form.get('user_id').setValue(id);
    if (this.contact_support_form.invalid) {
      return;
    }
    this.api.post('api/online_help/store', this.contact_support_form.value, true).subscribe((res: any) => {
      this.helper.alert_success("Request submitted successfully");
      this.contact_support_form.reset()
      this.submitted = false;
    }, err => this.error_handling.handle_error(err.status));

  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

}
