<!-- <div class="alert alert-danger breadcrum" style="height: 52px;" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">
  <div class="row">
    <div class="col-md-12">
      <a class="btn btn-link" routerLink="/app/stays">STAYS</a>
      <a class="btn btn-link active">INVENTORY</a>
      <a class="btn btn-link" routerLink="/app/communication">GUEST LOOK UP</a>
    </div>
  </div>
</div> -->

<div class="filters_row" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null-null '">
  <div class="row">
    <div class="column-wrapper">
      <div class="col-md-7">
        <a class="btn btn-link" routerLink="/app/stays">STAYS</a>
        <a class="btn btn-link active">PROMO CALENDER</a>
        <a class="btn btn-link" routerLink="/app/communication">GUEST LOOK UP</a>
      </div>
      <div class="col-md-5 d-flex justify-content-end p-2">
        <button type="button" class="form-control save-buton_popup btn-hov_popup"
          style="border-radius: 8px; float: right">
          Clear
        </button>
        <div class="flex-grid w-100">
          <button type="button" class="btn btn-primary next-week-button btn-hov_popup" (click)="week_move('prev')"
            [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null-null '">
            <i class="fa fa-arrow-left"></i>
          </button>

          <div class="inventory-calendar">
            <span class="pointclick_date left_input_date form-control date-first" style="border: none">
              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker" [closeOnAutoApply]="false"
                (change)="calendar_change($event)" placeholder="Date" [(ngModel)]="calendar_date" [locale]="{
                    format: 'DD  MMM  YYYY',
                    separator: '          To          '
                  }" style="
                    display: inline-block;
                    padding: 10px;
                    height: 40px;
                    text-align: center;
                  " />
            </span>
          </div>

          <button type="button" [ngClass]="darkMode ? 'card-dark-btn-g' : 'btn-hov_popup'"
            class="btn btn-primary prev-week-button btn-hov_popup" (click)="week_move('next')">
            <i class="fa fa-arrow-right"></i>
          </button>

          <img class="go_icon btn-hov_popup" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov_popup'"
            src="assets/img/other/go.png" (click)="trigger_click()" />

          <ng-select [(ngModel)]="bulkOptionSelect" (change)="selectOptiopns(bulkOptionSelect)" [items]="bulkOption"
            bindValue="val" bindLabel="name" placeholder='Select Option'
            class="form-control bulk-update btn-hov_popup ng-sel orangSelect ml-2" style="
            border-radius: 8px;float: right;width: 180px;z-index: 999;text-align: center;">
          </ng-select>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Content Load -->
<div [ngClass]="darkMode ? 'darkOn' : null" *ngIf="inventory_calendar == undefined || inventory_calendar == '' "
  style="height: 88vh;overflow: hidden;">
  <table class="table">
    <thead>
      <tr>
        <th>
          <div class="basic_block inventory_grid mb-0 mt-2" [ngClass]="darkMode ? 'card-black' : ''">
            <div class="inventory_wrapper">
              <h4 class="inventory_text" [ngClass]="darkMode ? 'card-black-text' : ''">INVENTORY'S GRID</h4>
            </div>
            <h4 class="room_type_text" [ngClass]="darkMode ? 'card-black-text' : ''">ROOM TYPE</h4>
          </div>
        </th>

        <th *ngFor="let range of getEmptyElements(8)">
          <div class="basic_block days mb-0" [ngClass]="darkMode ? 'card-black' : ''" >
            <h3 class="days_month" [ngClass]="darkMode ? 'card-black-text' : ''">{{ today | date:'MMMM' }}</h3>
            <h3 class="day_number" [ngClass]="darkMode ? 'card-black-text' : ''">{{ today | date:'dd' }}</h3>
            <h3 class="days_day" [ngClass]="darkMode ? 'card-black-text' : ''">{{ today | date:'EEEE' }}</h3>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="drag_item">
        <td>
          <div class="type">
            <div class="type-text" style="flex: 1;justify-content: space-between;">
              <span>Room</span>
            </div>
          </div>
        </td>
        <td *ngFor="let range of getEmptyElements(8)">
          <div class="room_type">
            <img src="assets/img/inventory/available.png" alt="" class="image-113">
            <h4 class="availability"> 0 </h4>
            <h4 class="available_text">Available</h4>
          </div>
        </td>
      </tr>
    </tbody>
    <ng-container>
      <tr *ngFor="let range of getEmptyElements(6)">
        <td>
          <div class="box" style="background: linear-gradient(to right, #f98282ba 3%, #f98282 15%);">
            <div class="box_left">&nbsp;</div>
            <div class="box_right">&nbsp;</div>
            <hr>
            <div class="box_lines">
              <div class="upper"></div><br>
              <div class="lower"></div>
            </div>
            <hr>
            <div class="box_bar"></div>
          </div>
        </td>
        <td *ngFor="let range of getEmptyElements(8)">
          <div class="box">
            <div class="box_left">&nbsp;</div>
            <div class="box_right">&nbsp;</div>
            <hr>
            <div class="box_lines">
              <div class="upper"></div><br>
              <div class="lower"></div>
            </div>
            <hr>
            <div class="box_bar"></div>
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
<!-- Content Load  End -->

<div id="scroll_wrapper" class="scroll no-scroll" (scroll)="onTableScroll($event)">
  <div class="grid_calendar" *ngIf="inventory_calendar">
    <table class="table" [ngClass]="darkMode ? 'darkModeE' : ' card-null-null '">
      <thead>
        <tr>
          <th>
            <div class="basic_block inventory_grid" [ngClass]="darkMode ? 'card-black' : ' card-null-null-null '">
              <div class="inventory_wrapper" [ngClass]="darkMode ? 'card-black-text-bor' : ' card-null '">
                <h4 class="inventory_text" style="
                  text-overflow: ellipsis;
                  width: 165px;
                  white-space: nowrap;
                  display: inline-block;
                  overflow: hidden;
                " data-placement="top" data-toggle="tooltip" title="INVENTORY'S GRID"
                  [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                  INVENTORY'S GRID
                </h4>
              </div>
              <div class="inventory_wrapper roomTy" [ngClass]="darkMode ? 'card-black-text-bor' : ' card-null '">
                <h4 class="inventory_text" style="
                  text-overflow: ellipsis;
                  width: 165px;
                  white-space: nowrap;
                  display: inline-block;
                  overflow: hidden;
                " data-placement="top" data-toggle="tooltip" title="INVENTORY'S GRID"
                  [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                  ROOM TYPE
                </h4>
              </div>
            </div>
          </th>

          <th *ngFor="let range of calendar_range; let i = index">
            <div class="basic_block days" *ngIf="
              range.isBetween(
                current_view.startDate,
                current_view.endDate,
                null,
                '[]'
              )
            " [ngClass]="darkMode ? 'card-black' : ' card-null-null-null '">
              <h3 class="days_month" [ngStyle]="{
                'border-bottom':
                  checkHoliday(range.format('MMMM')) != undefined
                    ? '2px solid'
                    : '0'
              }" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                {{ checkHoliday(range.format("MMMM")) != undefined ? checkHoliday(range.format("MMMM")).name :
                range.format("MMMM") }}
              </h3>
              <h3 class="day_number" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                {{ range.format("DD") }}
              </h3>
              <h3 class="days_day" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                {{ range.format("dddd") }}
              </h3>
            </div>
          </th>
        </tr>
      </thead>

      <tbody *ngFor="
        let inv of inventory_calendar;
        trackBy: trackByInv;
        let i = index
      ">
        <tr class="drag_item">
          <td>
            <div class="type"
              [ngStyle]="{'background-color': colors[i % colors.length], 'margin-top': i == 0 ? '0px' : '5px'  }">
              <h4 class="type-text">
                {{ inv[0]?.display_name || inv[0]?.room_type?.name }}
              </h4>
            </div>
          </td>
          <td *ngFor="let range of calendar_range">
            <div class="room_type"
              [ngStyle]="{ 'background-color': colors[i % colors.length], 'margin-top': i == 0 ? '0px' : '5px' }"
              *ngIf="   range.isBetween( current_view.startDate,current_view.endDate,   null, '[]' ) ">
              <img src="assets/img/newstaticicons/inventory/available.svg" alt="" class="image-113" />
              <h4 class="availability">
                {{ invCount(inv[0]?.inventory_counts, range.format("YYYY-MM-DD")) }}
              </h4>
              <h4 class="available_text" data-toggle="tooltip" title="Available">
                Available
              </h4>
            </div>
          </td>
        </tr>

        <!-- package boxes -->

        <tr class="drag_item" *ngFor="let ven of inv; trackBy: trackByVen; let indonia = index">
          <td>
            <div>
              <div *ngIf=" ven.room.images != '' " class="package  w-clearfix " id="{{ven.room.images[0].image}}"
                (click)="openPackageDetail(ven)"
                [ngStyle]="{'background-size': 'cover', 'background-color': 'black','background-image':'url('+api.imgUrl+ven.room.images[0].image+')'}">

              </div>
              <div *ngIf=" ven.room.images == '' " class="package w-clearfix" id="" (click)="openPackageDetail(ven)"
                [ngStyle]="{'background-size': 'cover', 'background-color': 'black','background-image':'url(https://www.google.com/images/branding/googlelogo/1x/googlelogo_light_color_272x92dp.png)'}">
              </div>
            </div>

            <div class="pac-name-new" [ngStyle]="{ 'background-color': colors[i % colors.length]}">{{ ven.package_name
              }}</div>
            <div class="pac-name-new position-bottom" [ngStyle]="{ 'background-color': colors[i % colors.length] }">
              <span>
                <img src="assets/img/newstaticicons/inventory/view-room.svg" alt="room-view" />
                {{ven.room_view}}
              </span>
              <span>
                <img src="assets/img/newstaticicons/inventory/type-bed.svg" alt="bed-type" height="11" />
                {{ven.bedtype}}
              </span>
            </div>
          </td>

          <td class="" *ngFor="let range of calendar_range;  trackBy: trackByBox.bind(ven.id);  let indo = index">
            <div *ngIf=" range.isBetween(current_view.startDate, current_view.endDate, null, '[]' ); else blankBox">
              <div
                *ngIf="is_box_shown(ven?.boxes, range) != false &&  is_box_shown(ven?.boxes, range) as box; else placeBox">
                <div *ngIf="!check_box_status(box.restrictions)" class="box_wrapper hov"
                  [ngClass]=" darkMode ? 'card-dark-btn-g-btns hov_dark': ' card-null-null-null hov'">
                  <input #input class="input-class package_box" name="{{ box.id }}" type="checkbox"
                    value="{{ box.date }}" (click)="selectBox($event, box, ven)"
                    [checked]=" is_pkg_checked(box.date, box.id, box.room_type_id) "
                    [disabled]="   is_pkg_disabled( box.room_type_id, box.id,inv[0]?.inventory_counts, range )"
                    (mouseover)="chngeH('hin' + indo + indonia + i)" (mouseout)="chngeH2('hin' + indo + indonia + i)" />
                  <div class="package_details">
                    <div class="restriction_panel">
                      <div class="promotion-discount" [ngStyle]="{'background': getBackgroundColor(box)}">
                        <ng-container>
                          <i [ngClass]="getArrowIconClass(box)" aria-hidden="true"></i>
                          {{ getBoxPrice(box.room_type_id, box.package_id, box.date) }}
                        </ng-container>
                      </div>
                      <img #restriction_field src="assets/img/newstaticicons/inventory/add-icon.svg"
                        class="restriction-padding fl-r" id="restrictionId" width="20px" style="margin-bottom: 2px" />
                    </div>
                    <div class="title_container w-clearfix">
                      <span class="count-badge mt-1">1</span>
                      <h4 class="package_title" style=" text-align: center;color: #fff; margin-top: -5px;">
                        <span (click)="input.checked == false ? input.click() : return" style="font-weight: bold"
                          class="rate_btn-hov card-dark-text" contenteditable="true">
                          <input type="text" class="rateInput"
                            [value]="box.room_rate | currency:current_currency:'symbol':'1.2-2'"
                            (keyup.enter)="addSinglePrice(box.room_rate, box.date, ven)"
                            [ngStyle]="{ color: box.color ? 'red' : 'black' }" />
                        </span>

                        <div class="totlo">
                          <span class="pkg_name hedingo tooltiptext"
                            [ngClass]="darkMode ? 'card-dark-text' : 'card-null-null'"
                            id="hin{{ indo }}{{ indonia }}{{ i }}">
                            {{ box.package_name }}
                          </span>
                        </div>
                      </h4>
                    </div>

                    <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden">
                      <span *ngFor="let ota of box.otas">
                        <img *ngIf="ota.status == true" src="assets/img/svgicons/smallicons/{{ ota?.image }}"
                          id="channel-menu" alt="" class="ota" (click)="open_channel_box(box.otas, box.date, box.id, ven);
                        input.checked == false ? input.click() : return " />
                      </span>
                      <span *ngIf="checkLength(box.otas) > 6">
                        <img style="position: absolute; bottom: 2px;right: 14px; height: 25px; width: 16px;
                          " src="assets/img/manage_rates/dots.png" alt="" class="ota hov-right1" (click)="open_channel_box(box.otas, box.date, box.id, ven);
                          input.checked == false ? input.click() : return" />
                      </span>
                      <p style="margin: 5px 0; font-weight: bold; cursor: pointer; font-size: 13px;"
                        *ngIf="checkLength(box.otas) == 0" (click)="open_channel_box(box.otas, box.date, box.id, ven)">
                        No Channel Connected
                      </p>
                    </div>
                  </div>
                </div>

                <div *ngIf="check_box_status(box.restrictions)" class="box_wrapper">
                  <div class="package_details width-adj restricted w-clearfix"
                    [ngClass]="darkMode ? 'card-black' : ' card-null-null '">
                    <h4 class="restricted_text_reason">
                      Closed for
                    </h4>
                    <img #restriction_field src="assets/img/inventory/restriction.png"
                      class="restriction-padding fl-r restricted_closed_icon" width="20px"
                      (click)="open_restriction_box2(box, box.date, ven)" />
                    <div class="restricted_container w-clearfix">
                      <ul class="nav pull-left">
                        <li>
                          <h4 *ngIf="box.restrictions.cta == true" class="restricted_text_type">
                            Arrivals
                          </h4>
                        </li>
                        <li>
                          <h4 *ngIf="box.restrictions.ctd == true" class="restricted_text_type">
                            Departure <br />
                          </h4>
                        </li>
                        <li>
                          <h4 *ngIf="box.restrictions.stop_sell == true" class="restricted_text_type">
                            sale <br />
                          </h4>
                        </li>
                      </ul>
                      <img src="assets/img/inventory/closed_for.png" alt="" class="image-114" />
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #placeBox style="background-color: #21b9e1">
                <div class="box" style="background-color: #21b9e1">
                  <div class="box_left">&nbsp;</div>
                  <div class="box_right">&nbsp;</div>
                  <hr />
                  <div class="box_lines">
                    <div class="upper"></div>
                    <br />
                    <div class="lower"></div>
                  </div>
                  <hr />
                  <div class="box_bar"></div>
                </div>
              </ng-template>
            </div>
            <ng-template #blankBox>
              <div class="blankBox">&nbsp;</div>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div id="bulk_update" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document">
    <div class="modal-content bg-modal" style="background-color: #f3f3f3" [ngClass]="
      darkMode ? 'card-dark-modal-body moda-content-dark' : ' card-null '
    ">
      <div class="modal-body" style="padding: 5px; padding-bottom: 0px">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-danger breadcrum" id="grab-4" style="cursor: grab"
              [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '" (mouseover)="dodrag('bulk_update')"
              (mouseout)="stopDrag('bulk_update')">
              <ul class="nav navbar-nav">
                <li class="active">
                  <a data-dismiss="modal" class="btn btn-link">INVENTORY</a>
                </li>
                <li>
                  <a class="btn btn-link active"> UPDATE PROMOTION </a>
                </li>
              </ul>
              <button type="button" class="close cross-button pull-right" data-dismiss="modal" aria-label="Close"
                (click)="closeBulkModal(false)">
                <strong id="strong">&#x2716;</strong>
              </button>
            </div>

            <div class="bulk_group">
              <div class="row">
                <div class="col-md-6">
                  <div class="bulk-box">
                    <div class="card" [ngClass]="darkMode ? 'card-dark-card' : ' card-null '">
                      <label #pkg_label class="field-label-type left" data-toggle="collapse"
                        data-target="#package_details" [ngClass]="
                        darkMode ? 'card-dark-modal-heding' : ' card-null '
                      ">
                        Room Type
                        <div class="pull-right" style="transform: translateY(-2px)">
                          <a *ngIf="!pkg_label.classList.contains('collapsed')">
                            <img src="assets/img/reports/{{
                              darkMode ? 'button_down_dark' : 'button_down'
                            }}.svg" style="height: 25px; transform: rotate(180deg)" />
                          </a>
                          <a *ngIf="pkg_label.classList.contains('collapsed')">
                            <img src="assets/img/reports/{{
                              darkMode ? 'button_down_dark' : 'button_down'
                            }}.svg" style="height: 25px" />
                          </a>
                        </div>
                      </label>

                      <div id="package_details" class="collapse in borderClass">
                        <div class="row grid_5px" *ngIf="bulkCheck == false">
                          <div class="col-md-12">
                            <div class="element">
                              <div class="border-style bdc-1 btn-hov" [ngClass]="darkMode ? 'bdc_dark' : 'null'">
                                <input type="text" placeholder="Enter Promotion Name" class="form-control"
                                  [(ngModel)]="new_package_name" [ngClass]="
                                  darkMode ? 'card-dark-bar' : ' card-null '
                                " />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row grid_5px">
                          <div class="col-md-12">
                            <div class="element" style="margin-bottom: 0px">
                              <div class="border-style bdc-2 btn-hov" [ngClass]="darkMode ? 'bdc_dark' : 'null'">
                                <input type="number" min="1" placeholder="Enter Bulk Price" class="form-control"
                                  [(ngModel)]="bulk_price" [ngClass]="
                                  darkMode ? 'card-dark-bar' : ' card-null '
                                " />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div #scrollBox class="selected_pkg_content" style="
                          width: 100.7%;
                          padding-bottom: 1px;
                          display: inline-block;
                          margin-top: 0px;
                        " *ngIf="selected_boxes.length != 0">
                          <div class="editable-grid">
                            <div class="col-md-3" *ngFor="let pkg of selected_boxes; let i = index" style="
                              padding: 0px;
                              padding-right: 5px;
                              margin-top: 5px;
                            ">
                              <div class="package_details" style="width: 100%">
                                <p class="package_price2">
                                  {{ pkg?.date | date: "EE, LLL d" }}
                                </p>
                                <div class="title_container w-clearfix" style="text-align: center">
                                  <h4 class="package_title" style="color: #fff; margin-top: -5px">
                                    <span class="xsp">$</span><input type="number" [(ngModel)]="pkg.room_rate" style="
                                      background: transparent;
                                      border: none;
                                      outline: none;
                                      font-size: 18px !important;
                                      width: 40%;
                                    " />
                                    <br />
                                    <span style="color: #000; font-weight: bold">{{ pkg?.package_name }}</span>
                                  </h4>
                                </div>
                                <div class="otas_connected" style="
                                  height: 30px;
                                  clear: both;
                                  overflow: hidden;
                                ">
                                  <img *ngFor="let ota of pkg?.otas" src="assets/img/svgicons/smallicons/{{
                                    ota.image
                                  }}" id="channel-menu" alt="" class="ota" />
                                  <img *ngIf="pkg?.otas.length > 6" style="
                                    position: absolute;
                                    height: 25px;
                                    width: 16px;
                                  " src="assets/img/manage_rates/dots.png" data-toggle="dropdown" alt=""
                                    class="ota hov-right3" />
                                  <p style="margin: 5px 0; font-weight: bold; cursor: pointer; font-size: 13px;"
                                    *ngIf="pkg?.otas.length == 0">
                                    No Channel Connected
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bulk-box">
                    <div class="card" style="padding-bottom: 0px"
                      [ngClass]="darkMode ? 'card-dark-card' : ' card-null '">
                      <label #restriction_label class="field-label-type left" data-toggle="collapse"
                        data-target="#restrictions" [ngClass]="
                        darkMode ? 'card-dark-modal-heding' : ' card-null '
                      ">
                        RESTRICTIONS
                        <div class="pull-right" style="transform: translateY(-2px)">
                          <a *ngIf="
                            !restriction_label.classList.contains('collapsed')
                          ">
                            <img src="assets/img/reports/{{
                              darkMode ? 'button_down_dark' : 'button_down'
                            }}.svg" style="height: 25px; transform: rotate(180deg)" />
                          </a>
                          <a *ngIf="
                            restriction_label.classList.contains('collapsed')
                          ">
                            <img src="assets/img/reports/{{
                              darkMode ? 'button_down_dark' : 'button_down'
                            }}.svg" style="height: 25px" />
                          </a>
                        </div>
                      </label>

                      <div class="collapse in borderClass" id="restrictions">
                        <div class="row grid_5px">
                          <div class="col-md-6">
                            <div class="element">
                              <div class="border-style bdc-3 btn-hov" [ngClass]="darkMode ? 'bdc_dark' : 'null'">
                                <input type="number" min="1" placeholder="Min Stay" class="form-control"
                                  [(ngModel)]="bulk_min_stay" [ngClass]="
                                  darkMode ? 'card-dark-bar' : ' card-null '
                                " />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="element">
                              <div class="border-style bdc-4 btn-hov" [ngClass]="darkMode ? 'bdc_dark' : 'null'">
                                <input type="number" min="1" placeholder="Max Stay" class="form-control"
                                  [(ngModel)]="bulk_max_stay" [ngClass]="
                                  darkMode ? 'card-dark-bar' : ' card-null '
                                " />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row grid_5px">
                          <div class="col-md-12" style="margin-bottom: 5px">
                            <div class="restriction_box w-clearfix bdc-5" [ngClass]="darkMode ? 'bdc_dark' : 'null'">
                              <div class="restriction_container w-clearfix" [ngClass]="
                                darkMode ? 'card-dark-bardark' : ' card-null '
                              ">
                                <div class="div-block-9">
                                  <label class="radio-button-field w-clearfix w-radio">
                                    <label class="control-label checkbox-style chk-d">
                                      <input type="checkbox" #stop_sell class="checkbox"
                                        [(ngModel)]="res_bulk_obj.stop_sell" (change)="
                                        add_bulk_restriction(
                                          'stop_sell',
                                          stop_sell.checked
                                        )
                                      " />
                                      <span class="checkbox-checkmark" [ngClass]="
                                        darkMode
                                          ? 'checkbox-checkmark_dark'
                                          : 'checkbox-checkmark'
                                      "></span>
                                      <span class="permission-title" [ngClass]="
                                        darkMode
                                          ? 'card-dark-modal-heding'
                                          : ' card-null '
                                      ">
                                        <img src="assets/img/newstaticicons/inventory/stop-sell.svg" alt=""
                                          class="image-116" />
                                      </span>
                                    </label>

                                    <p class="radio-button-label w-form-label">
                                      Stop Sell
                                    </p>
                                  </label>
                                </div>
                                <div class="div-block-9">
                                  <label class="radio-button-field w-clearfix w-radio">
                                    <label class="control-label checkbox-style chk-d">
                                      <input type="checkbox" #cta class="checkbox" [(ngModel)]="res_bulk_obj.cta"
                                        (change)="
                                        add_bulk_restriction(
                                          'cta',
                                          cta.checked
                                        )
                                      " />
                                      <span class="checkbox-checkmark" [ngClass]="
                                        darkMode
                                          ? 'checkbox-checkmark_dark'
                                          : 'checkbox-checkmark'
                                      "></span>
                                      <span class="permission-title" [ngClass]="
                                        darkMode
                                          ? 'card-dark-modal-heding'
                                          : ' card-null '
                                      ">
                                        <img src="assets/img/newstaticicons/inventory/cta.svg" alt=""
                                          class="image-116" />
                                      </span>
                                    </label>
                                    <p class="radio-button-label w-form-label">
                                      CTA
                                    </p>
                                  </label>
                                </div>
                                <div class="div-block-9">
                                  <label class="radio-button-field w-clearfix w-radio">
                                    <label class="control-label checkbox-style chk-d">
                                      <input type="checkbox" #ctd class="checkbox" [(ngModel)]="res_bulk_obj.ctd"
                                        (change)="
                                        add_bulk_restriction(
                                          'ctd',
                                          ctd.checked
                                        )
                                      " />
                                      <span class="checkbox-checkmark" [ngClass]="
                                        darkMode
                                          ? 'checkbox-checkmark_dark'
                                          : 'checkbox-checkmark'
                                      "></span>
                                      <span class="permission-title" [ngClass]="
                                        darkMode
                                          ? 'card-dark-modal-heding'
                                          : ' card-null '
                                      ">
                                        <img src="assets/img/newstaticicons/inventory/ctd.svg" alt=""
                                          class="image-116" />
                                      </span>
                                    </label>
                                    <p class="radio-button-label w-form-label">
                                      CTD
                                    </p>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="bulk-box" *ngIf="!bulkCheck">
                    <div class="card" [ngClass]="darkMode ? 'card-dark-card' : ' card-null '">
                      <div>
                        <label #package_type class="field-label-type left" data-toggle="collapse"
                          data-target="#packageType" [ngClass]="
                          darkMode ? 'card-dark-modal-heding' : ' card-null '
                        ">
                          Package Type
                          <div class="pull-right" style="transform: translateY(-2px)">
                            <a *ngIf="
                              !package_type.classList.contains('collapsed')
                            ">
                              <img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px; transform: rotate(180deg)" />
                            </a>
                            <a *ngIf="
                              package_type.classList.contains('collapsed')
                            ">
                              <img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px" />
                            </a>
                          </div>
                        </label>
                      </div>
                      <div class="collapse in borderClass" id="packageType">
                        <ng-container>
                          <div class="border-style color-pt" [ngClass]="
                            darkMode ? 'card-dark-side-end' : ' card-null '
                          " style="margin-bottom: 5px">
                            <div class="ptInner">
                              <div class="" [ngClass]="
                                darkMode
                                  ? 'radio-box_dark one'
                                  : 'radio-box one'
                              ">
                                <label data-toggle="tooltip" title="Paid" class="control-label radio-style chbx"
                                  [ngClass]="
                                  darkMode ? 'hovbulit_dark' : 'hovbulit'
                                " (mouseover)="colorRadio('type1', 'type1T')"
                                  (mouseout)="colorRadio('type1', 'type1T')" (click)="getPackageType(pacType)">
                                  <input type="radio" class="pull-left radio" name="package_type" value="OTAs"
                                    [(ngModel)]="pacType" checked />
                                  <span class="radio-checkmark rd" [ngClass]="
                                    darkMode
                                      ? 'radio-checkmark_dark'
                                      : 'radio-checkmark'
                                  " id="type1"></span>
                                  <span class="title" [ngClass]="
                                    darkMode ? 'dark-title' : 'title'
                                  " id="type1T">OTA's</span>
                                </label>
                              </div>
                              <div class="" [ngClass]="
                                darkMode
                                  ? 'radio-box_dark two'
                                  : 'radio-box two'
                              ">
                                <label data-toggle="tooltip" title="Complimentry" class="control-label radio-style chbx"
                                  [ngClass]="
                                  darkMode ? 'hovbulit_dark' : 'hovbulit'
                                " (mouseover)="colorRadio('type2', 'type2T')"
                                  (mouseout)="colorRadio('type2', 'type2T')" (click)="getPackageType(pacType)">
                                  <input type="radio" class="pull-left radio" name="package_type" value="Channel"
                                    [(ngModel)]="pacType" />
                                  <span class="radio-checkmark rd" [ngClass]="
                                    darkMode
                                      ? 'radio-checkmark_dark'
                                      : 'radio-checkmark'
                                  " id="type2"></span>
                                  <span class="title" [ngClass]="
                                    darkMode ? 'dark-title' : 'title'
                                  " id="type2T">Channels</span>
                                </label>
                              </div>
                              <div class="" [ngClass]="
                                darkMode
                                  ? 'radio-box_dark three'
                                  : 'radio-box three'
                              ">
                                <label data-toggle="tooltip" title="Exclude" class="control-label radio-style chbx"
                                  [ngClass]="
                                  darkMode ? 'hovbulit_dark' : 'hovbulit'
                                " (mouseover)="colorRadio('type3', 'type3T')"
                                  (mouseout)="colorRadio('type3', 'type3T')" (click)="getPackageType(pacType)">
                                  <input type="radio" class="pull-left radio" name="package_type" value="Loyalty"
                                    [(ngModel)]="pacType" />
                                  <span class="radio-checkmark rd" [ngClass]="
                                    darkMode
                                      ? 'radio-checkmark_dark'
                                      : 'radio-checkmark'
                                  " id="type3"></span>
                                  <span class="title" [ngClass]="
                                    darkMode ? 'dark-title' : 'title'
                                  " id="type3T">Loyalty</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="bulk-box" [ngClass]="
                    pacType != 'Channel' && !bulkCheck ? 'Pointernone' : ''
                  ">
                    <div class="card" [ngClass]="{
                      'card-dark-card': darkMode,
                      Pnone: pacType != 'Channel' && !bulkCheck
                    }">
                      <div>
                        <label #channel_label class="field-label-type left" data-toggle="collapse"
                          data-target="#channels" [ngClass]="
                          darkMode ? 'card-dark-modal-heding' : ' card-null '
                        ">
                          Channels
                          <div class="pull-right" style="transform: translateY(-2px)">
                            <a *ngIf="
                              !channel_label.classList.contains('collapsed')
                            ">
                              <img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px; transform: rotate(180deg)" />
                            </a>
                            <a *ngIf="
                              channel_label.classList.contains('collapsed')
                            ">
                              <img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px" />
                            </a>
                          </div>
                        </label>
                      </div>
                      <div class="collapse in borderClass" id="channels">
                        <ng-container *ngFor="let channel of bulk_channel; let i = index">
                          <div class="div-block-11 w-clearfix" *ngIf="channel.type == 'Internal'">
                            <label>
                              <div class="div-block-12" style="background-color: #f1f2f6" *ngIf="channel.image != null"
                                [ngClass]="
                                darkMode ? 'card-dark-small-c' : ' card-null '
                              ">
                                <img *ngIf="channel.type == 'Internal'" src="assets/img/svgicons/channels/{{
                                  channel.image
                                }}" alt="" class="image-137" />
                              </div>
                              <input class="checkbox" name="booked_date" type="checkbox" value="{{ channel.name }}"
                                (click)="add_bulk_channels($event, channel.id)" [checked]="channel.is_binded"
                                id="channelCheckbox{{ i }}" />
                              <div class="button red" [ngClass]="
                                darkMode ? 'btn-hov_dark' : 'btn-hov'
                              "></div>
                            </label>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div class="bulk-box" [ngClass]="
                    pacType != 'OTAs' && !bulkCheck ? 'Pointernone' : ''
                  ">
                    <div class="card" [ngClass]="{
                      'card-dark-card': darkMode,
                      Pnone: pacType != 'OTAs' && !bulkCheck
                    }">
                      <div>
                        <label #ota class="field-label-type left" data-toggle="collapse" data-target="#ota" [ngClass]="
                          darkMode ? 'card-dark-modal-heding' : ' card-null '
                        ">
                          OTA's
                          <div class="pull-right" style="transform: translateY(-2px)">
                            <a *ngIf="!ota.classList.contains('collapsed')"><img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px; transform: rotate(180deg)" /></a>
                            <a *ngIf="ota.classList.contains('collapsed')"><img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" style="height: 25px" /></a>
                          </div>
                        </label>
                      </div>
                      <div class="collapse in borderClass" id="ota">
                        <ng-container *ngFor="let channel of bulk_channel; let i = index">
                          <div class="div-block-11 w-clearfix" *ngIf="channel.type == 'External'">
                            <label>
                              <div class="div-block-12" style="background-color: #f1f2f6" *ngIf="channel.image != null"
                                [ngClass]="
                                darkMode ? 'card-dark-small-c' : ' card-null '
                              ">
                                <img *ngIf="channel.type == 'External'" src="assets/img/svgicons/otas/{{
                                  darkMode
                                    ? 'ota_dark/' + channel.image
                                    : channel.image
                                }}" alt="" class="image-137" />
                              </div>
                              <input class="checkbox" name="booked_date" type="checkbox" value="{{ channel.name }}"
                                (click)="add_bulk_channels($event, channel.id)" [checked]="channel.is_binded"
                                id="otaCheckbox{{ i }}" />
                              <div class="button red" [ngClass]="
                                darkMode ? 'btn-hov_dark' : 'btn-hov'
                              "></div>
                            </label>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer text-right">
      <button class="btn btn-info btn-lg save_bulk btn-hov-save" (click)="save_bulkUpdate()"
        [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'">
        Save
      </button>
      <button class="btn btn-danger btn-lg btn-hov" aria-label="Close" data-dismiss="modal" type="button"
        (click)="closeBulkModal(false)" [ngClass]="darkMode ? 'card-dark-top-nav-close' : 'btn-hov-save'">
        Cancel
      </button>
    </div>
  </div>
</div>

<!-- Package detail modal -->
<div id="package_details_popup" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content bg-modal" [ngClass]="
      darkMode
        ? 'package_details_popup_dark modal-content-dark'
        : 'modal-content'
    " *ngIf="showPackageDetails != undefined">
      <div class="details-head" [ngClass]="darkMode ? 'deatails-head_dark' : 'details-head'" id="grab-2"
        style="cursor: grab" (mouseover)="dodrag('package_details_popup')"
        (mouseout)="stopDrag('package_details_popup')">
        {{ showPackageDetails?.package_name }}
        <span>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <strong id="strong">&#x2716;</strong>
          </button>
        </span>
      </div>

      <div class="details-main">
        <div class="details-imgs">
          <div style="display: flex">
            <div style="width: 50%">
              <div id="myCarouselPackage" class="carousel slide" data-ride="carousel">
                <!-- Wrapper for slides -->
                <div class="carousel-inner">
                  <div class="item" *ngFor="
                    let img of showPackageDetails?.room?.images;
                    let imgIndex = index
                  " [ngClass]="imgIndex == 0 ? 'active' : null">
                    <img src="{{
                      (showPackageDetails?.room?.images)[0]?.image
                        ? api.imgUrl + img.image
                        : 'assets/img/other/no-room.jpg'
                    }}" class="details-img" alt="room image" />
                  </div>
                </div>

                <!-- Left and right controls -->
                <a class="left carousel-control" href="#myCarouselPackage" data-slide="prev"
                  *ngIf="showPackageDetails?.room?.images.length > 1">
                  <i class="fa fa-chevron-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#myCarouselPackage" data-slide="next"
                  *ngIf="showPackageDetails?.room?.images.length > 1">
                  <i class="fa fa-chevron-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
            <div class="img-flex" [ngStyle]="
              darkMode
                ? {
                    background: '#000213',
                    'border-radius': '10px',
                    margin: '0px 5px',
                    'margin-right': '0px'
                  }
                : {
                    background: '#EBF2F5',
                    'border-radius': '10px',
                    margin: '0px 5px',
                    'margin-right': '0px'
                  }
            ">
              <div class="img-div">
                <div class="details-div-date">
                  <span class="assec" [ngClass]="darkMode ? 'assec' : 'assec_light'">Accessibility</span>
                </div>

                <div class="" [ngClass]="darkMode ? 'colmar' : 'colmar_light'" style="
                  display: flex;
                  justify-content: space-around;
                  margin-top: 13px;
                ">
                  <div class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img src="assets/img/newstaticicons/rates-packages/sleep2.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '48px',
                              width: '43px',
                              'margin-left': '-3px',
                              'margin-top': '-5px'
                            }
                          : {
                              height: '48px',
                              width: '43px',
                              'margin-left': '-3px',
                              'margin-top': '-5px'
                            }
                      " />
                    </span>
                    <div style="font-size: 12px; float: right; margin-top: 6px">
                      {{ showPackageDetails?.room.children + showPackageDetails?.room.adults }}
                    </div>
                  </div>
                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="showPackageDetails?.room.smoking == 'true'"
                        src="assets/img/newstaticicons/back-office/smoke.svg" [ngStyle]="
                        darkMode
                          ? { height: '40px', width: '40px' }
                          : { height: '40px', width: '40px' }
                      " />
                      <img *ngIf="showPackageDetails?.room.smoking == 'false'"
                        src="assets/img/newstaticicons/back-office/nosmoke.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)'
                            }
                          : { height: '40px', width: '40px' }
                      " />
                    </span>
                  </p>
                </div>

                <div class="" [ngClass]="darkMode ? 'colmar' : 'colmar_light'" style="
                  display: flex;
                  justify-content: space-around;
                  margin-top: 25px;
                ">
                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="showPackageDetails?.room.pets == 'true'"
                        src="assets/img/newstaticicons/back-office/pets.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)',
                              'margin-left': '-8px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-left': '-8px'
                            }
                      " />
                      <img *ngIf="showPackageDetails?.room.pets == 'false'"
                        src="assets/img/newstaticicons/back-office/nopets.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)',
                              'margin-left': '-7px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-left': '-7px;'
                            }
                      " />
                    </span>
                  </p>

                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="
                        showPackageDetails?.room.wheelchairaccessibility ==
                        'true'
                      " src="assets/img/newstaticicons/back-office/wheel.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(0px)',
                              'margin-right': '-4px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-right': '-4px'
                            }
                      " />
                      <img *ngIf="
                        showPackageDetails?.room.wheelchairaccessibility ==
                        'false'
                      " src="assets/img/newstaticicons/back-office/nowheel.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(0px)',
                              'margin-right': '-4px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-right': '-4px'
                            }
                      " />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="promoData">
          <div class="codeOf" style="margin-bottom: 5px" [ngClass]="darkMode ? 'codeOf_dark' : 'codeOf'">
            <div>
              Total Nights
            </div>
            <div class="theCode" [ngClass]="darkMode ? 'theCodebg_dark' : 'theCode'">
              {{ nightCount( showPackageDetails?.date_to, showPackageDetails?.date_from ) }} Nights
            </div>
          </div>

          <div class="codeOf" [ngClass]="darkMode ? 'codeOf_dark' : 'codeOf'">
            <div style="
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            ">
              {{ showPackageDetails?.package_name }}
            </div>
            <div class="theCode" [ngClass]="darkMode ? 'theCodebg_dark' : 'theCode'">
              {{ showDate(showPackageDetails?.date_to) }} | {{ showDate(showPackageDetails?.date_from) }}
            </div>
          </div>
        </div>
        <div class="promoData2">
          <div class="codeOf2" [ngClass]="darkMode ? 'codeOf2_dark' : 'codeOf2'">
            <div style="margin-bottom: 10px; margin-top: 4px">
              {{ showPackageDetails?.roomttype.name }}
            </div>

            <div class="theCode2">
              <div class="season-box" [ngClass]="darkMode ? 'season-box_dark' : 'season-box'">
                <div style="
                  display: flex;
                  align-items: center;
                  height: 100%;
                  position: relative;
                ">
                  <div style="padding: 7px; text-align: left; text-indent: 5px">
                    <div class="pkg-off" *ngIf="showPackageDetails.percentage != 0">
                      {{ showPackageDetails.percentage }}% Off
                    </div>
                    <img src="assets/img/newstaticicons/rates-packages/promotion.svg" style="width: 95px" />
                    <div style="font-size: 20px; color: #707070">
                      <b>{{
                        dateFunc2(showPackageDetails.date_from).diff(
                        dateFunc2(showPackageDetails.date_to),
                        "days"
                        )
                        }}</b> Nights
                      <img src="assets/img/newstaticicons/rates-packages/night.svg"
                        style="margin-right: 10px; width: 20px" />
                    </div>
                    <span class="breakfast" *ngIf="showPackageDetails.breakfast_include == 'No'"
                      [ngClass]="darkMode ? 'card-dark-side' : 'null-light'">
                      <i class="fa fa-utensils"></i>
                      Breakfast Not Included
                    </span>
                    <span class="breakfast" *ngIf="showPackageDetails.breakfast_include == 'Yes'"
                      [ngClass]="darkMode ? 'card-dark-side' : 'null-light'">
                      <i class="fa fa-utensils"></i>
                      Breakfast Included
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Season detail modal -->
<div id="season_details_popup" class="modal fade" role="dialog">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content bg-modal" [ngClass]="
      darkMode
        ? 'package_details_popup_dark modal-content-dark'
        : 'modal-content'
    " *ngIf="showSeasonDetails != undefined">
      <div class="details-head" id="grab-3" [ngClass]="darkMode ? 'deatails-head_dark' : 'details-head'"
        style="cursor: grab" (mouseover)="dodrag('season_details_popup')" (mouseout)="stopDrag('season_details_popup')">
        {{ showSeasonDetails?.season_name }}
        <span><button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <strong id="strong">&#x2716;</strong>
          </button></span>
      </div>

      <div class="details-main">
        <div class="details-imgs">
          <div style="display: flex">
            <div style="width: 50%">
              <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <!-- Wrapper for slides -->
                <div class="carousel-inner">
                  <div class="item" *ngFor="
                    let img of showSeasonDetails?.season_room_types?.room
                      ?.images;
                    let imgIndex = index
                  " [ngClass]="imgIndex == 0 ? 'active' : null">
                    <img src="{{
                      (showSeasonDetails?.season_room_types?.room?.images)[0]
                        ?.image
                        ? api.imgUrl + img.image
                        : 'assets/img/other/no-room.jpg'
                    }}" class="details-img" alt="room image" />
                  </div>
                </div>

                <!-- Left and right controls -->
                <a class="left carousel-control" href="#myCarousel" data-slide="prev" *ngIf="
                  showSeasonDetails?.season_room_types?.room?.images.length >
                  1
                ">
                  <i class="fa fa-chevron-left"></i>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#myCarousel" data-slide="next" *ngIf="
                  showSeasonDetails?.season_room_types?.room?.images.length >
                  1
                ">
                  <i class="fa fa-chevron-right"></i>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>

            <div class="img-flex" [ngStyle]="
              darkMode
                ? {
                    background: '#000213',
                    'border-radius': '10px',
                    margin: '0px 5px',
                    height: '148px',
                    'margin-right': '0px'
                  }
                : {
                    background: '#EBF2F5',
                    'border-radius': '10px',
                    margin: '0px 5px',
                    height: '148px',
                    'margin-right': '0px'
                  }
            ">
              <div class="img-div">
                <div class="details-div-date">
                  <span class="assec" [ngClass]="darkMode ? 'assec' : 'assec_light'">Accessibility</span>
                </div>

                <div class="" [ngClass]="darkMode ? 'colmar' : 'colmar_light'" style="
                  display: flex;
                  justify-content: space-around;
                  margin-top: 13px;
                ">
                  <div class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img src="assets/img/newstaticicons/rates-packages/sleep2.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '48px',
                              width: '43px',
                              'margin-left': '-3px',
                              'margin-top': '-5px'
                            }
                          : {
                              height: '48px',
                              width: '43px',
                              'margin-left': '-3px',
                              'margin-top': '-5px'
                            }
                      " />
                    </span>
                    <div style="font-size: 12px; float: right; margin-top: 6px">
                      {{ showSeasonDetails?.season_room_types?.room?.children +
                      showSeasonDetails?.season_room_types?.room?.adults }}
                    </div>
                  </div>
                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room.smoking ==
                        'true'
                      " src="assets/img/newstaticicons/back-office/smoke.svg" [ngStyle]="
                        darkMode
                          ? { height: '40px', width: '40px' }
                          : { height: '40px', width: '40px' }
                      " />
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room.smoking ==
                        'false'
                      " src="assets/img/newstaticicons/back-office/nosmoke.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)'
                            }
                          : { height: '40px', width: '40px' }
                      " />
                    </span>
                  </p>
                </div>

                <div class="" [ngClass]="darkMode ? 'colmar' : 'colmar_light'" style="
                  display: flex;
                  justify-content: space-around;
                  margin-top: 25px;
                ">
                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room.pets ==
                        'true'
                      " src="assets/img/newstaticicons/back-office/pets.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)',
                              'margin-left': '-8px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-left': '-8px'
                            }
                      " />
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room.pets ==
                        'false'
                      " src="assets/img/newstaticicons/back-office/nopets.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(-1px)',
                              'margin-left': '-7px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-left': '-7px;'
                            }
                      " />
                    </span>
                  </p>
                  <p class="amine-color" [ngClass]="darkMode ? 'amine-color_dark' : 'amine-color'">
                    <span class="the-span">
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room
                          .wheelchairaccessibility == 'true'
                      " src="assets/img/newstaticicons/back-office/wheel.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(0px)',
                              'margin-right': '-4px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-right': '-4px'
                            }
                      " />
                      <img *ngIf="
                        showSeasonDetails?.season_room_types.room
                          .wheelchairaccessibility == 'false'
                      " src="assets/img/newstaticicons/back-office/nowheel.svg" [ngStyle]="
                        darkMode
                          ? {
                              height: '40px',
                              width: '40px',
                              transform: 'translateY(0px)',
                              'margin-right': '-4px'
                            }
                          : {
                              height: '40px',
                              width: '40px',
                              'margin-right': '-4px'
                            }
                      " />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="promoData">
          <div class="codeOf" [ngClass]="darkMode ? 'codeOf_dark' : 'codeOf'" style="margin-bottom: 5px">
            <div style="transform: translateY(3px)">
              Demand Type
            </div>
            <div class="theCode" [ngClass]="darkMode ? 'theCodebg_dark' : 'theCode'">
              {{ showSeasonDetails?.demand_type }}
            </div>
          </div>

          <div class="codeOf" [ngClass]="darkMode ? 'codeOf_dark' : 'codeOf'">
            <div style="transform: translateY(3px)">
              {{ showSeasonDetails?.season_name }}
            </div>
            <div class="theCode" [ngClass]="darkMode ? 'theCodebg_dark' : 'theCode'">
              {{ showDate2(showSeasonDetails?.date_to) }} | {{ showDate2(showSeasonDetails?.date_from) }}
            </div>
          </div>
        </div>
        <div class="promoData2">
          <div class="codeOf2" [ngClass]="darkMode ? 'codeOf2_dark' : 'codeOf2'">
            <div style="margin-bottom: 10px; margin-top: 4px">
              {{ showSeasonDetails?.season_room_types.room.roomtype.name }}
            </div>

            <div class="theCode2">
              <div class="season-box" [ngClass]="darkMode ? 'season-box_dark' : 'season-box'">
                <div class="weekprice">
                  ${{ showSeasonDetails?.season_room_types.week_days_rate }}
                </div>

                <div class="days-1">
                  <span class="days-name" [ngClass]="darkMode ? 'days-name_dark' : 'days-name'" *ngFor="
                    let wn of checkDays(
                      showSeasonDetails?.season_room_types.week_day
                    )
                  " [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">
                    {{ wn.name.split("")[0] }}
                  </span>
                </div>
                <hr class="myhr" />
                <div class="days-1">
                  <span class="days-name" [ngClass]="darkMode ? 'days-name_dark' : 'days-name'" *ngFor="
                    let wn of checkDays(
                      showSeasonDetails?.season_room_types.weekend_day
                    )
                  " [ngStyle]="{ opacity: wn.status ? '1' : '0.3' }">
                    {{ wn.name.split("")[0] }}
                  </span>
                </div>

                <div class="weekprice">
                  ${{ showSeasonDetails?.season_room_types.weekend_days_rate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Channel Modal -->
<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="
      darkMode
        ? 'card-dark-modal-body modal-content-dark'
        : ' card-null-null '
    ">
      <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
        [ngClass]="darkMode ? 'card-dark-top-nav ' : ' card-null-null '" style="
        height: 45px;
        cursor: grab;
        width: 100.3%;
        margin-left: -1px;
        margin-top: -1px;
      " (mouseover)="dodrag('myModal')" (mouseout)="stopDrag('myModal')">
        Connectivity
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <strong id="strong">&#x2716;</strong>
        </button>
      </div>

      <div class="scroll" style="height: 296px">
        <div class="col-md-12 editable-column pr-0" style="padding-right: 5px">
          <div class="col-md-6 pl-0 pr-0">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <label for="allS">
                    <div class="BbarChannel kk">
                      OTA's
                    </div>
                    <input class="checkbox" id="allS" name="allSelect" type="checkbox" (change)="checkAllOta($event)" />
                    <div class="button red"></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let ota of channel_list; let i = index">
                <div class="col-md-12" *ngIf="ota.type == 'External'">
                  <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : ' card-null-null '">
                    <label>
                      <span class="con-chan" [ngClass]="
                        darkMode ? 'con-chan_dark' : ' card-null-null '
                      " style="display: flex; justify-content: center">
                        <img src="assets/img/svgicons/otas/{{
                          darkMode ? 'ota_dark/' + ota?.image : ota?.image
                        }}" alt="" class="image-137 pr-5 pl-5" />
                      </span>

                      <input class="checkbox" name="booked_date" type="checkbox" [checked]="ota?.is_binded"
                        (change)="checkChannel($event, ota?.id, i)" />
                      <div class="button red" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"></div>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-md-6 pl-0 pr-0">
            <div class="row">
              <div class="col-md-12">
                <div>
                  <label for="allSC">
                    <div class="BbarChannel kk">
                      Channels
                    </div>
                    <input class="checkbox" id="allSC" name="allSelect" type="checkbox"
                      (change)="checkAllChannel($event)" />
                    <div class="button red"></div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let ota of channel_list; let i = index">
                <div class="col-md-12" *ngIf="ota.type == 'Internal'">
                  <div class="div-block-channels-grid" [ngClass]="darkMode ? 'card-dark-con' : ' card-null-null '">
                    <label>
                      <span class="con-chan" [ngClass]="
                        darkMode ? 'con-chan_dark' : ' card-null-null '
                      " style="display: flex; justify-content: center">
                        <img src="assets/img/svgicons/channels/{{ ota?.image }}" alt="" class="image-137 pr-5 pl-5" />
                      </span>

                      <input class="checkbox" name="booked_date" type="checkbox" [checked]="ota?.is_binded"
                        (change)="checkChannel($event, ota?.id, i)" />
                      <div class="button red" [ngClass]="darkMode ? 'btn-hov_dark' : 'btn-hov'"></div>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="form-control save-buton save-chan btn-hov-save"
        [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name=""
        data-dismiss="modal" style="
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 10px;
        border-radius: 25px;
        padding: 8px 30px;
        float: right;
      " (click)="save_ota_update()" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'">
        Save
      </button>
    </div>
  </div>
</div>

<!-- Restriction Modal -->
<div class="modal fade" id="ReModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="col-md-10 pl-0 pr-0">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-6"
          style="cursor: grab; height: 45px" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
          SELECT RESTRICTION
          <button type="button" class="close" data-dismiss="modal">
            <strong>&#x2716;</strong>
          </button>
        </div>
        <div class="col-md-12 editable-column height-99 pr-0" style="background-color: #fff" [ngClass]="
          darkMode ? 'card-dark-modal-body_restriction' : ' card-null '
        ">
          <div class="col-md-4 pl-25 br-bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" #ctd class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    (change)="add_restriction('ctd', ctd.checked)" [(ngModel)]="res_obj.ctd" />
                  <span class="checkbox-checkmark" [ngClass]="
                    darkMode
                      ? 'checkbox-checkmark_dark'
                      : 'checkbox-checkmark'
                  " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/ctd.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                    darkMode ? 'card-dark-modal-heding' : ' card-null '
                  " style="margin-top: -20px; padding-left: 5px">
                    CTD
                  </p>
                </label>
              </label>
            </div>
          </div>

          <div class="col-md-4 pl-25 br-bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" #stop_sell class="pull-left checkbox" (change)="
                    add_restriction('stop_sell', stop_sell.checked)
                  " [(ngModel)]="res_obj.stop_sell" />
                  <span class="checkbox-checkmark" [ngClass]="
                    darkMode
                      ? 'checkbox-checkmark_dark'
                      : 'checkbox-checkmark'
                  " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/stop-sell.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                    darkMode ? 'card-dark-modal-heding' : ' card-null '
                  " style="margin-top: -20px; padding-left: 5px">
                    Stop Sell
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 pl-25 bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" #cta class="pull-left checkbox" (change)="add_restriction('cta', cta.checked)"
                    [(ngModel)]="res_obj.cta" />
                  <span class="checkbox-checkmark" [ngClass]="
                    darkMode
                      ? 'checkbox-checkmark_dark'
                      : 'checkbox-checkmark'
                  " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/cta.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                    darkMode ? 'card-dark-modal-heding' : ' card-null '
                  " style="margin-top: -20px; padding-left: 5px">
                    CTA
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12 editable-column pr-0" style="background-color: #fff; border-radius: 0px 0px 10px 10px"
          [ngClass]="
          darkMode ? 'card-dark-modal-body_restriction' : ' card-null '
        ">
          <div class="col-md-6 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #ms_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    (change)="
                    add_restriction(
                      { key: 'min_stay', value: ms_el.checked },
                      true
                    )
                  " [(ngModel)]="res_obj.min_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                    darkMode
                      ? 'checkbox-checkmark_dark'
                      : 'checkbox-checkmark'
                  " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!ms_el.checked"
                    [(ngModel)]="res_obj.min_stay.value" min="1" (keyup)="
                    add_restriction(
                      { key: 'min_stay', value: 'continue' },
                      true
                    )
                  " (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                    darkMode ? 'card-dark-modal-heding' : ' card-null '
                  " style="margin-bottom: 0px">
                    Min. Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-6 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #mx_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    (change)="
                    add_restriction(
                      { key: 'max_stay', value: mx_el.checked },
                      true
                    )
                  " [(ngModel)]="res_obj.max_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                    darkMode
                      ? 'checkbox-checkmark_dark'
                      : 'checkbox-checkmark'
                  " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!mx_el.checked"
                    [(ngModel)]="res_obj.max_stay.value" min="1" (keyup)="
                    add_restriction(
                      { key: 'max_stay', value: 'continue' },
                      true
                    )
                  " (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                    darkMode ? 'card-dark-modal-heding' : ' card-null '
                  " style="margin-bottom: 0px">
                    Max. Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>

        <div class="actions">
          <a class="pull-right btn btn-info btn-hov-save" data-dismiss="modal" (click)="save_restriction()"
            [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov'">Save</a>
        </div>
      </div>
    </div>
  </div>
</div>
