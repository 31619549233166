import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { PnlService } from 'src/app/services/pnl.service';
import * as moment from 'moment';
import { ExpenseModel } from 'src/app/common/pnl/ExpenseModel';


@Component({
  selector: 'app-operating-expense',
  templateUrl: './operating-expense.component.html',
  styleUrls: ['./operating-expense.component.css']
})
export class OperatingExpenseComponent implements OnInit {

  darkMode: boolean = false;
  @Input() Expenses: ExpenseModel[] = [];
  arrowUpDown = false;
  arrowUpDown2 = false;

  @Input() placeholderText: string = '';
  @Input() label: string = '';
  @Input() mainLabel: string = '';
  @Input() expenseTypeId: number;
  @Output() ExpenseUpdated: EventEmitter<ExpenseModel> = new EventEmitter<ExpenseModel>();
  @Output() ExpenseAdded: EventEmitter<ExpenseModel> = new EventEmitter<ExpenseModel>();
  currency_format = localStorage.getItem('currency');
  property = JSON.parse(localStorage.getItem('property'));

  newExpenseName: string = '';
  newExpenseAmount: number = null;
  newIsFixed: boolean = false;

  constructor(
    private confirm: ConfirmationAlertService,
    private decimalPipe: DecimalPipe,
    private pnlApi: PnlService,

  ) { }

  ngOnInit() {
    this.checkDarkMode();
  }
  // change arrow in dropdown

  changeArrow() {
    this.arrowUpDown = !this.arrowUpDown;

  }

  addDummyExpenses() {
    let val = localStorage.getItem('pnl_selected_Date');
    let dateRange = JSON.parse(val);
    let expenseDate = new Date()
    if (dateRange) {
      if (dateRange.startDate) {
        expenseDate = dateRange.startDate
      }
    }

    let expense = {
      expense_type_id: this.expenseTypeId,
      property_id: this.property.id,
      name: this.newExpenseName,
      amount: this.newExpenseAmount,
      date: expenseDate,
      is_deleted: false,
      is_fixed: this.newIsFixed,
      expense_id: 0
    }

    this.ExpenseAdded.emit(expense)
    this.newExpenseAmount = null
    this.newExpenseName = ''

    // auto scrolling button click on bottom
    $('.scrollo02').animate({
      scrollTop: document.body.scrollHeight,
    }, 300);

  }

  varFixChecked(className: string) {
    let ids = <HTMLInputElement><unknown>document.getElementsByClassName(className);
    if (className == 'var') {
      ids.checked = true;
      this.newIsFixed = false;
    } else if (className == 'fix') {
      ids.checked = true;
      this.newIsFixed = true;
    }

  }

  removeExpenses(expense: ExpenseModel) {
    this.confirm.confirm(`Alert`, `Are you sure you want to delete this expense?`, [`This action can't be undone`, 'Are you sure you want to delte?'],
      function () {
        expense.is_deleted = true;
        this.ExpenseUpdated.emit(expense)
      }
      , '', ''
    );
  }


  sumTotalExpenses() {
    return this.Expenses.filter(x => x.amount != null && x.is_deleted == false).map(x => x.amount).reduce((a, b) => Number(a) + Number(b), 0).toFixed(2);
  }

  // input field add 2 decimal number in  end of the number?
  formatExpenseAmount(ev: any, expense?: ExpenseModel) {
    ev.target.value = parseFloat(ev.target.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1').toString();
    this.ExpenseUpdated.emit(expense)
  }

  // input field remove 2 decimal number in end of the number on focus?
  formatExpenseAmountFocus(ev: any) {
    ev.target.value = parseFloat(ev.target.value);
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

}
