<div class="d-flex flex-column perfomance" [ngClass]="darkMode ? 'dark_card' : 'null'">
    <!-- Legend  -->
    <header class="financial-performance-bar-container border-bottom-gray">
        <div class="d-flex flex-row align-items-center gap-1">
            <label>Compare</label>
            <select name="timespan" id="">
                <option value="Previous Month" selected>Previous Month</option>
                <option value="Previous Quarter">Previous Quarter</option>
                <option value="Bi-Annual">Bi-Annual</option>
                <option value="Previous Year">Previous Year</option>
            </select>
        </div>
        <div class="d-flex flex-row align-items-center gap-1">
            <span class="current-period-label-indicator"></span> Current Period
        </div>
        <div class="d-flex flex-row align-items-center gap-1">
            <span class="previous-period-label-indicator"></span>
            Previous Period
        </div>
        <div class="d-flex flex-row align-items-center gap-1">
            <span> % </span> Change
        </div>
    </header>

    <ng-container *ngFor="let data of rowData">
        <app-comparision-row [data]="data"></app-comparision-row>
    </ng-container>

</div>