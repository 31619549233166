import { Injectable } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfirmationAlertService {

  private subject = new Subject<any>();
  darkMode: boolean = false;
  constructor() { }

  confirm(title: string, message: string, instructions: any, siFn: () => void, permissions = '', created_on = '') {
    this.setConfirm(message, siFn, title, instructions, permissions, created_on);
    this.checkDarkMode()
  }

  setConfirm(message: string, siFn: () => void, title: string, instructions: any, permissions: string, created_on: string) {
    this.subject.next({
      type: "confirm",
      title: title,
      instructions: instructions,
      text: message,
      permissions: permissions.toString().replace(/,/g, ' - '),
      created_on: created_on,
      siFn: function () {
        siFn();
      }
    });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;

    // alert(mode);
    if (mode == 'dark') {
      this.darkMode = true;
    } else {
      this.darkMode = false;
    }
    console.log(this.darkMode, "mode");
  }
}
