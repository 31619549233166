import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobaldataService } from '../../../common/globaldata.service';
declare var $: any;
import { ManageRatesPackagesComponent } from '../manage-rates-packages.component';

@Component({
  selector: 'app-setup-season-channels',
  templateUrl: './setup-season-channels.component.html',
  styleUrls: ['./setup-season-channels.component.css']
})
export class SetupSeasonChannelsComponent implements OnInit {
  channels;
  pricing_modell;
  pricing_model;
  create_package_form: FormGroup;
  submitted = false;
  base_url;
  bHeight;
  ratehead
  link_to_ota;
  edit = false;
  darkMode: boolean = false;
  pdChannel = [];
  oBChannel = [];
  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private fb: FormBuilder,
    private service: ManageRatesPackagesService,
    private router: Router,
    private Aroute: ActivatedRoute,
    private rp: ManageRatesPackagesComponent
  ) {
    this.base_url = this.api.base_url;
  }

  ngOnInit() {
    this.get_channels();
    this.modalfooter();
    this.headChange();
    this.checkDarkMode();
    this.pricing_dataa();

    this.create_package_form = this.fb.group({
      // package_name: ['',Validators.required],
      // date_from: ['',Validators.required],
      // room_id: ['',Validators.pattern("[0-9]{0,10}")],
      // description: ['',Validators.required],
      // breakfast_include:['',Validators.required],
    });
    console.log(this.service.package_creating);
    if (this.service.package_creating !== undefined) {
      // this.setChecked(this.service.package_creating);
    }

    $("#SSSSSA").mouseup(function () {
      //$(this).after("<p style='color:green;'>Mouse button released.</p>");
      console.log("mouse doupwn")
      $("#grab-6").css({ "cursor": "grab" })
    });
    $("#SSSSSA").mousedown(function () {
      //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
      console.log("mouse down")
      $("#grab-6").css({ "cursor": "grabbing" })
    });


  }

  onClose() {
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    $('#settings2').modal('hide');
  }


  get f() { return this.create_package_form.controls; }

  get_channels() {
    this.api.post('api/property/property_channels_otas', { property_id: localStorage.getItem('current_property'), "type": "Internal" }, true).subscribe((res: any) => {

      this.channels = res.body.data.filter(function (e) { return e.type == 'Internal' && e.connected == "true" });

      this.pdChannel = this.channels.filter((e) => { return e.pricing_model == 'PerDayPricing' });
      this.oBChannel = this.channels.filter((e) => { return e.pricing_model != 'PerDayPricing' });
      if (GlobaldataService.editSeason != undefined) {
        console.log(GlobaldataService.editSeason)
        this.edit = true;
        setTimeout((val) => {
          this.setEditOta(GlobaldataService.editSeason.season_otas);
        }, 500)
      }
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  onSubmit() {



    if (this.edit == false) {
      var arr = [];
      $('input.channels_checkbox:checkbox:checked').each(function () {
        // this.service.package_creating=1;
        arr.push($(this).val());
      });
      if (arr.length == 0) {
        this.helper.alert_error('Select at least one OTA!');
        return;
      }

      if (GlobaldataService.savedSeason == undefined) {
        this.helper.alert_error('Plese Create Season First!');
        return;
      }

      let otsObj = {
        property_id: localStorage.getItem('current_property'),
        season_package_id: GlobaldataService.savedSeason.id,
        channel_id: arr
      }
      this.api.post('api/season_package_ota/store', otsObj, true).subscribe((res: any) => {
        console.log(res);
        this.helper.alert_success('Channels has been Linked successfully !');
        //this.service.open_tab('preview_package_open');
        //this.service.get_packages();
      }, err => this.error_handling.handle_error(err.status, err.message));
    } else {
      var arr = [];
      $('input.channels_checkbox:checkbox:checked').each(function () {
        // this.service.package_creating=1;
        arr.push($(this).val());
      });
      if (arr.length == 0) {
        this.helper.alert_error('Select at least one OTA!');
        return;
      }

      let otsObj = {
        property_id: localStorage.getItem('current_property'),
        season_package_id: GlobaldataService.editSeason.id,
        channel_id: arr
      }
      this.api.post('api/season_package_ota/store', otsObj, true).subscribe((res: any) => {
        console.log(res);
        this.rp.getRatePlan();
        this.helper.alert_success('OTAs has been Updated successfully !');
        //this.service.open_tab('preview_package_open');
        //this.service.get_packages();
      }, err => this.error_handling.handle_error(err.status, err.message));

      console.log(arr);
    }



  }
  setChecked(id) {
    var data = { package_id: id };
    this.api.post('api/ota_package/index', data, true).subscribe((res: any) => {
      res.body.data.forEach(element => {
        //$("#check-" + element.channel_id).attr('checked', 'true');
        $("#check-" + element.channel_id).prop('checked', true);
      });
      // this.helper.alert_success('OTAs has been Linked successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  setEditOta(ota) {
    ota.forEach(element => {
      //$("#check-" + element.channel_id).attr('checked', 'true');
      $("#check-" + element.channel_id).prop('checked', true);
    });

  }

  selectAll($evt) {
    console.log('all select')
    if ($evt.target.checked) {
      this.channels.forEach(element => {
        element.is_checked = true;
      });
    } else {
      this.channels.forEach(element => {
        element.is_checked = false;
      });
    }
  }

  imgr(name) {
    let a = name.split('.');
    return a[0];
  }

  modalfooter() {

    setTimeout(() => {
      let f = document.getElementById("ModalFooter")
      let h = document.getElementById("SS");

      this.bHeight = h.offsetHeight;

      if (this.bHeight > 581) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      }
      else if (this.bHeight < 581) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    }, 100)

  }


  headChange() {
    if (GlobaldataService.editSeason == undefined) {
      this.ratehead = 'Create Rate Plan'
    } else if (GlobaldataService.editSeason != undefined) {
      this.ratehead = 'Edit Rate Plan'
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }

  pricing_dataa() {
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/properties_channel/pricing', { property_id: property_id }, true).subscribe((res: any) => {
      this.pricing_modell = res.body.data;
      console.log(this.pricing_modell);

    }, err => this.error_handling.handle_error(err.status));
  }




}
