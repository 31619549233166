<div class="main-div">

    <app-pnl-breadcrum (dateChanged)="onDateChanged() "></app-pnl-breadcrum>

    <div class="dropdown">
        <a href="#" id="imageDropdown" data-toggle="dropdown">
            <img src="assets/img/inventory/download-icon.png" style="height:32px">
        </a>
        <ul class="dropdown-menu" role="menu" aria-labelledby="imageDropdown">
            <li role="presentation"><a role="menuitem" tabindex="-1" (click)="makePDF()">PDF</a></li>
            <li role="presentation"><a role="menuitem" tabindex="-1" (click)="makeExcel()">Excel</a></li>
        </ul>
    </div>

    <div #capture>
        <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
            <div class="scrollo0">
                <div class="row">
                    <div class="col-md-12 row-div1 inputData">
                        <div class="modal-bar-2 border-1">
                            <div class="feild">Room Revenue</div>
                            <div>{{currency_format}} {{earningFromRooms | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1 inputData">
                        <div class="modal-bar-2 border-2">
                            <div class="feild">F&B Revenue</div>
                            <div>{{currency_format}} {{fnbTotal | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1 inputData">
                        <div class="modal-bar-2 border-6">
                            <div class="feild">Discount
                                <!-- <span class="text-danger">(This is discounted revenue)</span> -->
                                <!---start tooltip--->
                                <span class="ispan">
                                    <i class="fas fa-info-circle i faone"
                                        style="font-size: 15px; margin-right: 20px !important;">
                                        <span class="tooltiptext" style="width: 153px;">
                                            <p class="small" style="padding: 0px !important; margin: 0px !important;">
                                                This is discounted revenue
                                            </p>
                                        </span>
                                    </i>
                                </span>
                                <!---end tooltip--->
                            </div>
                            <div>{{currency_format}} {{expensesTotalDiscount | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1">
                        <div class="modal-bar-2 blueBar">
                            <div class="feild">Total Revenue</div>
                            <div>{{currency_format}} {{earningFromRooms + fnbTotal | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1 inputData">
                        <div class="modal-bar-2 border-4 ">
                            <div class="feild">COGS </div>
                            <div>{{currency_format}} 00.00</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1 inputData">
                        <div class="modal-bar-2 border-6">
                            <div class="feild">Other Expenses <span class="text-danger">(Selling + General +
                                    Admin)</span></div>
                            <div>{{currency_format}} {{expensesTotal | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 row-div1">
                        <div class="modal-bar-2 blueBar">
                            <div class="feild">Total OPEX</div>
                            <div>{{currency_format}} {{expensesTotal | number : '1.2-2'}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bot-div">
                <div class="flex text space-between Total-bar" [ngClass]="darkMode ? 'dark_bredcrum ' : ''">Gross
                    Revenue
                    <div>{{currency_format}} {{totalProfitnLoss | number : '1.2-2'}}</div>
                </div>
            </div>

        </div>
    </div>

    <div style="height: 0px;overflow: hidden;">
        <table id="excel-table" class="table table-bordered">
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Room Revenue: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} {{earningFromRooms | number
                    : '1.2-2'}}</td>
            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">F&B Revenue: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} 00.00</td>
            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Total Revenue: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} {{earningFromRooms | number
                    : '1.2-2'}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">COGS: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} 00.00</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Operating Expenses </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(1)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}}{{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(1)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Labor Cost And Expenses </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(2)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}} {{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(2)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Payroll Expenses </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(3)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}}{{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(3)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Utilities Expenses </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(5)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}} {{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(5)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Maintenance and Repair </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(6)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}} {{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(6)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Food Expense </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(7)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}} {{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(7)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Other Expenses </td>

            </tr>

            <tr *ngFor="let expense of filteredExpenseList(4)">
                <td>{{expense.name}}</td>
                <td>{{currency_format}} {{expense.amount}}</td>
            </tr>
            <tr>
                <td>Total</td>
                <td></td>
                <td>{{currency_format}}{{sumTotalExpenses(4)}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Total OPEX: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} {{expensesTotal | number :
                    '1.2-2'}}</td>
            </tr>
            <tr>

            </tr>
            <tr>
                <td style="text-align: center;border-bottom: 1px solid;">Gross Revenue: </td>
                <td></td>
                <td style="text-align: center;border-bottom: 1px solid;">{{currency_format}} {{
                    calculateTotal().toFixed(2)}}</td>
            </tr>
        </table>
    </div>
</div>