import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {
  a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }
  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
