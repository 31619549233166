<div *ngIf="user.role == 'SuperAdmin' ||  Performance == 'true'" style="overflow: hidden;">
  <div class="dash_window_2 no_margin w-clearfix dashboard-card" [ngClass]="darkMode ? 'darkDashboard' : 'null'"
    *ngIf="performanceData_per">
    <!--- Rates -->
    <div class="card-1">
      <div class="win_header">
        <h4 class="heading">Performance</h4>
        <div class="bar two-c">
          <ng-select class="form-control custom input_2 padding-holder " placeholder=" Today " [(ngModel)]="graphDaysP"
            (change)="getDashboardData(graphDaysP)" [items]="graphDays" bindValue="val" bindLabel="days"
            style="height: 30px;padding:0px;padding-left: 0px;padding-right: 0px ; border: none">
          </ng-select>
        </div>
      </div>

      <div class="scroll no_scroll">
        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> Sold </div>
            <div class="bar-rate"> {{performanceData_per.sold}} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_colorsold"
              [ngStyle]="{'width': per(performanceData_per.revpar,performanceData_per.total)+'%'}">
            </div>
          </div>
        </div>

        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> Revenue </div>
            <div class="bar-rate"> {{ currency_format }} {{performanceData_per.total |
              number : '1.2-2'}} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_color7" [ngStyle]="{'width':'100%'}"></div>
          </div>
        </div>

        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> RevPar </div>
            <div class="bar-rate"> {{ currency_format }} {{ performanceData_per.revpar |
              number : '1.2-2' }} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_color1"
              [ngStyle]="{'width': per(performanceData_per.revpar,performanceData_per.total)+'%'}">
            </div>
          </div>
        </div>
        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> BAR </div>
            <div class="bar-rate"> {{ currency_format }} {{performanceData_per.bar | number
              : '1.2-2'}} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_color2"
              [ngStyle]="{'width': per(performanceData_per.bar,performanceData_per.total)+'%'}">
            </div>
          </div>
        </div>

        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> Occupancy </div>
            <div class="bar-rate"> {{performanceData_per.occupancy}} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_color4" [ngStyle]="{'width': '10%'}"></div>
          </div>
        </div>
        <div class="big_bar">
          <div class="text_wrapper">
            <div class="bar-txt"> ADR </div>
            <div class="bar-rate"> {{ currency_format }} {{performanceData_per.adr | number
              : '1.2-2'}} </div>
          </div>
          <div class="progress_bar">
            <div class="bar_liner pro_color5"
              [ngStyle]="{'width': per(performanceData_per.adr,performanceData_per.total)+'%'}">
            </div>
          </div>
        </div>

        <!-- <div class="big_bar">
                                    <div class="text_wrapper">
                                        <div class="bar-txt"> Total Bookings </div>
                                        <div class="bar-rate"> {{performanceData_per.total_bookings}} </div>
                                    </div>
                                    <div class="progress_bar">
                                        <div class="bar_liner pro_color6" [ngStyle]="{'width': '10%'}"></div>
                                    </div>
                                </div> -->

      </div>
    </div>

  </div>


</div>
<div *ngIf="Performance == 'false'" style="overflow: hidden;">

  <div class="dash_window_2 no_margin w-clearfix dashboard-card" [ngClass]="darkMode ? 'darkDashboard' : 'null'"
    style="cursor: url(../../../assets/img/alert/no-drop.png), auto; " (click)="showerror() ">
    <!--- Rates -->
    <div class="card-1 " style="filter: blur(5px); ">
      <div class="win_header ">
        <h4 class="heading ">Performance</h4>
        <div class="bar two-c ">
          <ng-select class="form-control custom input_2 padding-holder" placeholder="Today" [(ngModel)]="graphDaysP"
            (change)="getDashboardData(graphDaysP)" [items]="graphDays" bindValue="val" bindLabel="days"
            style="height: 30px;padding:0px;padding-left: 0px;padding-right: 0px ; border: none ">
          </ng-select>
        </div>
      </div>
      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> RevPar </div>
          <div class="bar-rate "> {{ 0 | currency:currency_format }} </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color1 "
            [ngStyle]="{ 'width': per(performanceData_per.revpar,performanceData_per.total)+ '%'} ">
          </div>
        </div>
      </div>
      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> BAR </div>
          <div class="bar-rate "> {{ currency_format }} 0 </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color2 "
            [ngStyle]="{ 'width': per(performanceData_per.bar,performanceData_per.total)+ '%'} ">
          </div>
        </div>
      </div>

      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> Occupancy </div>
          <div class="bar-rate "> 0 </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color4 " [ngStyle]="{ 'width': '10%'} "></div>
        </div>
      </div>
      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> ADR </div>
          <div class="bar-rate "> {{ currency_format }} 0 </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color5 "
            [ngStyle]="{ 'width': per(performanceData_per.adr,performanceData_per.total)+ '%'} ">
          </div>
        </div>
      </div>

      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> Total Bookings </div>
          <div class="bar-rate "> 0 </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color6 " [ngStyle]="{ 'width': '10%'} "></div>
        </div>
      </div>
      <div class="big_bar ">
        <div class="text_wrapper ">
          <div class="bar-txt "> Revenue </div>
          <div class="bar-rate "> {{ currency_format }} 0 </div>
        </div>
        <div class="progress_bar ">
          <div class="bar_liner pro_color7 " [ngStyle]="{ 'width': '100%'} "></div>
        </div>
      </div>
    </div>

  </div>

</div>