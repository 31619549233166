import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';
import { PayroleService } from 'src/app/services/payrole.service';
import { EmployeeListsModel } from './employeelistModel';
import { EmployeeDetailsModel } from './employee-detailsModel';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
declare var html2canvas: any;


@Component({
  selector: 'app-employee-lists',
  templateUrl: './employee-lists.component.html',
  styleUrls: ['./employee-lists.component.css']
})

export class EmployeeListsComponent implements OnInit {

  @ViewChild('captureEmployeeData', { static: true }) captureEmployeeData: ElementRef;
  OrderModalShow: boolean = false;
  exte: boolean = true;
  darkMode: boolean = false;
  printMode: boolean = true;
  currency_format = localStorage.getItem('currency');
  propertyId = localStorage.getItem("current_property")
  employeelist: EmployeeListsModel[] = [];
  employeeDetails: EmployeeDetailsModel[] = [];
  filteredDeductions: any[] = [];
  randomColor = ['#76A200', '#EC1361', '#0179F1', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];

  constructor(
    private payroleService: PayroleService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private confirm: ConfirmationAlertService,
  ) { }


  ngOnInit() {
    this.checkDarkMode();
    this.employeeListData()
  }

  emp_details(employee_id) {
    this.payroleService.getPayroleEmployeeDetailsPrint(employee_id).subscribe(res => {
      this.OrderModalShow = true;
      this.employeeDetails = res.data.data;
      this.employeeDetails[0].deductions.forEach((ele) => {
        ele['isChecked'] = false;
      })
      this.filteredDeductions = this.employeeDetails[0].deductions;
    })
  }

  emp_detail_edit(employee_id) {
    this.payroleService.getPayroleEmployeeDetails(employee_id).subscribe(res => {
      this.payroleService.payroleEmployeeEdit$.next(res.data.data);
    })
  }

  emp_detail_delete(employee_id) {
    let emp_delte = {
      "employee_id": employee_id,
      "property_id": this.propertyId
    }

    this.confirm.confirm(`Alert`, `Are you sure you want to delete this employee?`, [`This action can't be undone`, 'Are you sure you want to delte?'],
      () => {
        this.api.post('/api/payroll/delete_payroll_employee', emp_delte, true).subscribe((res: any) => {
          this.employeelist = res.body.data.data;
          this.helper.alert_success(res.body.message);
        }, err => this.error_handling.handle_error(err.body.message));
      }
    );

  }

  onSaveCategory() {
    this.OrderModalShow = false;
  }

  onModalClose() {
    this.OrderModalShow = false;
  }

  // print to pdf file

  makePDF() {

    this.exte = false
    this.employeeDetails[0].deductions = this.employeeDetails[0].deductions.filter(x => x.isChecked);
    this.printMode = false;
    setTimeout(() => {
      let printView = document.getElementById('contentPrint');
      let HTML_Width = printView.offsetWidth;
      let HTML_Height = printView.offsetHeight;
      let top_left_margin = 0;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas(printView, { allowTaint: true }).then(function (canvas) {
        canvas.getContext('2d');
        let imgData = canvas.toDataURL();
        let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'SVG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(imgData, 'SVG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }
        pdf.save("Employee-list.pdf");

      });
      this.exte = true

    }, 1000)

    setTimeout(() => {
      this.printMode = true;
      this.filteredDeductions.forEach((ele) => {
        ele['isChecked'] = false;
      })
      this.employeeDetails[0].deductions = this.filteredDeductions;
    }, 2000);
  };

  employeeListData() {
    this.payroleService.getPayroleEmployeeList().subscribe(res => {
      this.employeelist = res.data.list;
    })
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
