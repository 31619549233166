import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormArray,
} from '@angular/forms';
import { HelperService } from '../../../services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { ManageRatesPackagesComponent } from '../manage-rates-packages.component';
import { ManageRatesPackagesService } from '../manage-rates-packages.service';

declare var $: any;
import * as moment from 'moment';
import { GlobaldataService } from 'src/app/common/globaldata.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Console } from 'console';
import { parse } from 'querystring';
import { ReservationService } from 'src/app/services/reservation.service';
import { ExtrasResponse, AddOnsExtras } from 'src/app/common/Inventory/Reservation/ExtrasAddons';

interface OnFocusChangeParams {
  event: any;
}

interface OnBlurChangeParams {
  event: any;
}

@Component({
  selector: 'app-setup-packages',
  templateUrl: './setup-packages.component.html',
  styleUrls: ['./setup-packages.component.css'],
  providers: [ManageRatesPackagesComponent],
})
export class SetupPackagesComponent implements OnInit {
  create_package_form: FormGroup;
  submitted = false;
  open_custom = false;
  setup_package;
  rooms;
  room;
  roomrate;
  start_c;
  end_c;
  data;
  abc;
  getSelectedRooms;
  // property_id;
  bHeight;
  room_id_id;
  season_id;
  playerName;
  greenbar;
  season_package_id;
  package_id;
  package_idd;
  selectroom;
  roomtypes;
  season_packages_data;
  seasonname;
  rate_plan_id;
  rd;
  starting;
  ending;
  roomname;
  Dayjs;
  package_data;
  minDate = new Date(2019, 0, 1);
  minDate2 = new Date(2019, 0, 1);
  maxDatee;
  minDatee;
  // maxDate;
  // maxDate is 1st Jan 2020
  // minDate is 1st Jan 2019
  // maxDate is 1st Jan 2020
  maxDate = new Date(2020, 0, 1);
  selectedRoom;
  testvar;
  edit = false;
  current_user = JSON.parse(localStorage.getItem('user')).id;
  darkMode = false;
  datess = [];
  date;
  date_to;
  date_to_rate;
  date_from;
  date_from_rate;
  start_date;
  end_date;
  room_id;

  amenities = [];

  wheelT = false;
  smoke = false;
  pet = false;
  breakfast = false;

  date_between;
  upsells = [];
  serviceItem;
  servicePrice;
  minibarItem;
  minibarPrice;
  d = Array();
  newdate;
  colors = [
    '#4db7d8',
    '#059800',
    '#6b4020',
    '#c7a563',
    '#2c2d99',
    '#73f199',
    '#925593',
    '#fd7600',
    '#5624ff',
  ];
  colors2 = [
    '#004386',
    '#ff6f00',
    '#e651ff',
    '#9c27b0',
    '#2dabd3',
    '#4caf50',
    '#ff6f00',
    '#ff51f3',
    '#673ab7',
  ];
  upsell_items = [];
  langCode;
  options;
  selectedD: any;
  rtyp: any;
  get_rate_by_checkin_checkout = [];

  suggList = GlobaldataService.holidays;
  slist = false;
  packageDates = [
    // for zero form
    { date_day: moment(new Date()).format('YYYY-MM-DD'), price: '' },
  ];
  property_id = localStorage.getItem('current_property');
  current_currency = localStorage.getItem('currency');
  addOnsExtras: AddOnsExtras[] = [];
  serviceAddons: AddOnsExtras[] = [];
  minibarAddons: AddOnsExtras[] = [];

  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public helper: HelperService,
    public fb: FormBuilder,
    public manageRates: ManageRatesPackagesComponent,
    public service: ManageRatesPackagesService,
    public reservationService: ReservationService,
    public Aroute: ActivatedRoute
  ) {
    this.Aroute.params.subscribe((val) => {
      this.get_package();
    });
  }

  ngOnInit() {
    this.createZeroForm();
    this.getHolidays();
    this.getLangCode();
    this.get_rooms();
    this.modalfooter();
    this.checkDarkMode();
    this.getUpsell();
    this.get_season();
    this.getExpenses()
    this.create_package_form = this.fb.group({
      property_id: '',
      package_name: ['', Validators.required],
      package_id: '',
      date: ['', Validators.required],
      date_from: ['', Validators.required],
      date_to: ['', Validators.required],
      room_type_id: [null, Validators.pattern('[0-9]{0,10}')],
      description: ['', Validators.required],
      // breakfast_include: ['', Validators.required],
      room_id: '',
      upsel_item: null,
      upsel_value: '',
      service_item: null,
      service_value: '',
      minibar_item: null,
      minibar_value: '',
      current_user: JSON.parse(localStorage.getItem('user')).id,
      rate_plan_id: '',
      upsell: '',
      roomname: '',
      wheelchairaccessibility: this.wheelT,
      pets: this.pet,
      smoking: this.smoke,
      breakfasts: this.breakfast,
      custom_room_type: '',
      package_type: ['', Validators.required],
      booking_extras: this.fb.array([]),
    });
    this.options = { year: 'numeric', month: 'long', day: 'numeric' };
    const today = new Date();
    this.minDate = new Date(today.toLocaleDateString('en-US', this.options));
    this.minDate2 = new Date(today.toLocaleDateString('en-US', this.options));
    this.minDate2.setDate(this.minDate2.getDate() + 1);

    this.get_package();
    this.f.upsel_item.valueChanges.subscribe((res) => {
      if (res != undefined && res != '' && res == 'open_custom') {
        this.on_sec_change();
      }
    });

    $('#SP').mouseup(function () {
      $('#grab-10').css({ cursor: 'grab' });
    });

    $('#SP').mousedown(function () {
      $('#grab-10').css({ cursor: 'grabbing' });
    });
  }

  dodrag2() {
    $('#settings').draggable('enable');
    // $('#SSS').css({ "cursor": "grab" })
  }

  stopDrag2() {
    $('#settings').draggable('disable');
    // $('#SSS').css({ "cursor": "grabbing" })
  }

  getHolidays() {
    // this.api.get("api/helper/get_holidays", true).subscribe(
    //   (res: any) => {
    //     GlobaldataService.holidays = res.body.data;
    //     this.suggList = res.body.data;
    //   },
    //   (err) => this.error_handling.handle_error(err.status, err.message)
    // );
  }

  get_rooms() {
    const property_id = localStorage.getItem('current_property');
    this.api
      .post('api/room/rate_room_index?per_page=500', { property_id }, true)
      .subscribe(
        (res: any) => {
          this.roomtypes = res.body.data;
          // console.log(this.roomtypes);
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_season() {
    const property_id = localStorage.getItem('current_property');
    // this.api
    //   .post(
    //     "api/package/get_season_name_by_property",
    //     { property_id: property_id },
    //     true
    //   )
    //   .subscribe(
    //     (res: any) => {
    //       this.seasonname = res.body.data;
    //     },
    //     (err) => this.error_handling.handle_error(err.status)
    //   );
  }

  get_rates(start_date, end_date, room_id) {
    const property_id = localStorage.getItem('current_property');
    this.api
      .post(
        'api/booking/rate_get_rates_by_checkin_checkout',
        { property_id, start_date, end_date, room_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.get_rate_by_checkin_checkout = res.body.data;
          console.log(this.get_rate_by_checkin_checkout, 'get_rate_by_checkin_checkout after');
          //  console.log("lpc");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getLangCode() {
    const value = localStorage.getItem('code');
    this.langCode = value;
  }

  createZeroForm() {
    // this is create zero Form
    this.create_package_form = this.fb.group({
      allocation_high_1: '',
      allocation_high_2: '',
      allocation_high_rate: '',
      allocation_medium_1: '',
      allocation_medium_2: '',
      allocation_medium_rate: '',
      allocation_low_1: '',
      allocation_low_2: '',
      allocation_low_rate: '',
      property_id: localStorage.getItem('current_property'),
      season_package_id: '',
      rate_type: localStorage.getItem('current_currency'),
      room_type_id: '',
      room_id: '',

      prices: this.fb.array(
        this.packageDates.map((ob) => {
          return this.date_price(ob.date_day, ob.price);
        })
      ),
    });
  }

  date_price(d, p): FormGroup {
    // this will be used in create_from_none and createItem_from_createSeason
    return this.fb.group({
      date_day: [d, Validators.required],
      price: [p, Validators.required],
    });
  }

  get f() {
    return this.create_package_form.controls;
  }

  on_sec_change() {
    setTimeout(() => {
      const que = this.create_package_form.value.upsel_item;
      if (que == 'open_custom') {
        this.open_custom = true;
        this.create_package_form.value.upsel_item = null;
        this.serviceItem = null;
        setTimeout(() => {
          $('#sec_custom_room_type').val(null);
        }, 100);
      }
    }, 500);
  }

  resetOptions() {
    this.create_package_form.value.upsel_item = null;
    this.open_custom = false;
    this.serviceItem = null;
  }

  onSubmit() {
    this.submitted = true;

    Object.keys(this.create_package_form.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.create_package_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
        });
      }
    });
    console.log(this.create_package_form.value, 'this.create_package_form.value');

    if (this.create_package_form.invalid) {
      return;
    }
    const package_prices = [];

    const property_id = localStorage.getItem('current_property');
    const current_user = JSON.parse(localStorage.getItem('user')).id;
    const roomrateElements = document.getElementsByClassName('newrate');

    for (let i = 0; i < roomrateElements.length; i++) {
      const promoPriceElement = roomrateElements[i] as HTMLInputElement;
      const promo_price = parseFloat(promoPriceElement.value.replace('$', '').replace(',', ''));

      const barRateElement = document.getElementsByClassName('roomrate')[i] as HTMLInputElement;
      const bar_price = parseFloat(barRateElement.value.replace('$', '').replace(',', ''));

      const dateElement = document.getElementsByClassName('season-date')[i] as HTMLInputElement;
      const date_day = dateElement.getAttribute('id');

      package_prices.push({
        price: isNaN(promo_price) ? bar_price : promo_price,
        date_day: date_day,
      });
      console.log(promo_price, bar_price, 'price promo update');
    }

    this.create_package_form.patchValue({
      property_id,
      room_id: this.selectedRoom.id,
      room_type_id: this.selectedRoom.room_type_id,
      upsell: JSON.stringify(this.upsells),
    });

    if (this.edit == true) {
      this.updatePackage(this.service.package_creating);
      return;
    }

    // this.package_data.push(package_prices);
    this.create_package_form.addControl(
      'prices',
      this.fb.array(package_prices)
    );

    console.log(this.create_package_form.value);

    this.api
      .post('api/package/store', this.create_package_form.value, true)
      .subscribe(
        (res: any) => {


          this.current_user = res.body.data.current_user;
          this.service.package_creating = res.body.data.id;
          this.package_idd = res.body.data.id;

          this.updating(res.body.data.id);
          console.log(res.body.data.id);
          this.service.get_packages();
          this.submitted = false;
          this.selectedRoom = '';
          // this.create_package_form.reset();
          this.manageRates.getRatePlan();
          this.helper.alert_success('Package has been created successfully !');
          // this.service.open_tab('setup_rate_open');
        },
        (err) => this.error_handling.handle_error(err.status)
      );


  }

  updating(id: any) {
    this.create_package_form.patchValue({
      package_id: id,
    });
    const sendData = JSON.parse(JSON.stringify(this.create_package_form.value));

    this.api.post('api/package_allocation/update', sendData, true).subscribe(
      (res: any) => {
        this.service.get_packages();
        // this.helper.alert_success('Rates are now setup');
        this.manageRates.getRatePlan();
        this.submitted = false;
        this.service.open_tab('setup_restrictions_open');
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  //
  getSelectedRoom(id) {
    // console.log(id);
    // this.get_rates_for_update(this.date_to_rate,this.date_from_rate)

    this.api.get('api/room/promotion_room_show/' + id.id, true).subscribe(
      (res: any) => {
        //
        // date_from: {startDate:Dayjs ,endDate:Dayjs};
        this.rd = id.id;

        this.get_rates(this.date_to_rate, this.date_from_rate, this.rd);
        // console.log(thisrd);
        //  this.get_rates(this.date_to_rate,this.date_from_rate ,this.rd);
        this.selectedRoom = res.body.data;
        console.log(this.selectedRoom);
      },
      (err) => this.error_handling.handle_error(err.status)
    );

    //       }, err => this.error_handling.handle_error(err.status));

    // this.breakfast = this.room.breakfasts.toLowerCase() == 'true' ? true : false;;
    // this.create_package_form.patchValue({breakfasts: this.breakfast});
  }

  getSelectedrooms(season) {
    const season_package_id = season.season_id;
    this.rate_plan_id = season.season_id;
    // console.log(this.rate_plan_id);

    this.api
      .post('api/package/get_rooms_rate_plan', { season_package_id }, true)
      .subscribe(
        (res: any) => {
          this.season_packages_data = res.body.data;

          // this.selectedD = {startDate: moment(season.date_to),endDate:moment(season.date_from)}
          // this.date = {startDate: moment(season.date_to),endDate:moment(season.date_from)}
          //
          // this.minDatee= moment(season.date_to);
          // this.maxDatee = moment(season.date_from);

          // this.date_from = season.date_from
          // this.date_to = season.date_to;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getroomsbysesaon(arr) {
    const property_id = localStorage.getItem('current_property');
    // var ar = JSON.parse(arr);

    this.api
      .post(
        'api/package/get_rooms_rate_plan',
        {
          property_id,
          arr,
          startDate: this.date_to_rate,
          enddate: this.date_from_rate,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.season_packages_data = res.body.data;
          //  this.season_packages_data = res.body.data;

          // return res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_rates_for_update(startDate, enddate) {
    const property_id = localStorage.getItem('current_property');
    this.api
      .post(
        'api/package/get_data_from_rate_plan',
        { property_id, startDate, enddate },
        true
      )
      .subscribe(
        (res: any) => {
          this.get_rate_by_checkin_checkout = res.body.data;
          console.log(this.get_rate_by_checkin_checkout);

          // return res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get_package() {
    if (
      this.service.package_creating !== undefined &&
      this.service.package_creating !== ''
    ) {
      this.api
        .get('api/package/promotion_package_show/' + this.service.package_creating, true)
        .subscribe(
          (res: any) => {
            // if(res.body.data.room.smoking === 'true'){
            //   this.smoke = true;
            // }
            this.service.otaArray.next(res.body.data.otas);

            const abc = [];
            res.body.data.room.smoking === 'true'
              ? (this.smoke = true)
              : (this.smoke = false);
            res.body.data.room.wheelchairaccessibility === 'true'
              ? (this.wheelT = true)
              : (this.wheelT = false);
            res.body.data.room.pets === 'true'
              ? (this.pet = true)
              : (this.pet = false);
            const property_id = localStorage.getItem('current_property');

            res.body.data.room.smoking === 'true'
              ? abc.push({ smoking: true })
              : '';
            res.body.data.room.wheelchairaccessibility === 'true'
              ? abc.push({ wheelT: true })
              : '';
            res.body.data.room.pets === 'true' ? abc.push({ pets: true }) : '';

            this.api
              .post(
                'api/package/get_rooms_rate_plan',
                {
                  property_id,
                  arr: abc,
                  startDate: res.body.data.date_to,
                  enddate: res.body.data.date_from,
                },
                true
              )
              .subscribe(
                (res: any) => {
                  this.season_packages_data = res.body.data;
                },
                (err) => this.error_handling.handle_error(err.status)
              );

            this.package_id = this.service.package_creating;
            // console.log(res.body.data.room.smoking)
            // this.season_packages_data =  this.getroomsbysesaon(res.body.data.rate_plan_id);
            this.create_package_form.patchValue({
              property_id: res.body.data.property_id,
              package_name: res.body.data.package_name,
              date_from: res.body.data.date_from,
              rate_plan_id: res.body.data.rate_plan_id,
              date_to: res.body.data.date_to,
              room_type_id: res.body.data.room_type_id,
              roomname: res.body.data.room_id,
              description: res.body.data.description,
              room_id: res.body.data.room_id,
              package_type: res.body.data.package_type,
              selectroom: res.body.data.id,
            });
            this.edit = true;

            const a = res.body.data.date_to.split('/');
            const b = res.body.data.date_from.split('/');

            const end = moment(a[1] + '/' + a[0] + '/' + a[2]);
            const start = moment(b[1] + '/' + b[0] + '/' + b[2]);
            const endd = moment(a[1] + '-' + a[0] + '-' + a[2]);
            const startt = moment(b[1] + '-' + b[0] + '-' + b[2]);
            this.date = { startDate: end, endDate: start };
            console.log((this.date = { startDate: end, endDate: start }));
            // console.log(this.date = {startDate: end, endDate: start });
            // this.selectedD = {startDate: end, endDate: start}
            // this.minDatee= moment(this.ending);
            // this.maxDatee = moment(this.starting);
            this.date_from = res.body.data.date_from;
            this.date_to = res.body.data.date_to;
            current_user: res.body.data.current_user;
            this.start_c = a[2] + '-' + a[1] + '-' + a[0];
            this.end_c = b[2] + '-' + b[1] + '-' + b[0];

            this.api
              .post(
                'api/booking/rate_get_rates_by_checkin_checkout',
                {
                  property_id,
                  start_date: this.start_c,
                  end_date: this.end_c,
                  room_id: res.body.data.room_id,
                },
                true
              )
              .subscribe(
                (ress: any) => {
                  this.get_rate_by_checkin_checkout = [];

                  console.log(ress, res, "response", this.get_rate_by_checkin_checkout);


                  for (let l = 0; l < res.body.data.prices.length; l++) {
                    const data_def: any =
                      parseInt(res.body.data.prices[l].price) -
                      parseInt(ress.body.data[l].price);
                    const sum_data: any =
                      100 * (data_def / parseInt(ress.body.data[l].price));
                    const final_data = sum_data.toFixed(0);
                    this.get_rate_by_checkin_checkout.push({
                      date: res.body.data.prices[l].date_day,
                      price: ress.body.data[l].price,
                      package_price: res.body.data.prices[l].price,
                      name: 'Apartment, 1 Bedroom',
                      per: final_data,
                    });
                  }

                  // console.log(this.get_rate_by_checkin_checkout, 'get_rate_by_checkin_checkout');
                },
                (err) => this.error_handling.handle_error(err.status)
              );

            // console.log(this.get_rate_by_checkin_checkout);
            // this.get_rate_by_checkin_checkout =  res.body.data.prices//this.get_rates_for_update(this.start_c,this.end_c);

            this.upsells = JSON.parse(res.body.data.upsell) || [];
            this.upsells.forEach((sel) => {
              this.upsell_items = this.upsell_items.filter((el) => {
                return sel.name != el.value;
              });
            });
            this.getSelectroom(res.body.data.room_id);
          },
          (err) => this.error_handling.handle_error(err.status, err.message)
        );
    }
  }

  getDifferenceInDays(date1, date2) {
    const date_between = Math.abs(date2 - date1);
    return date_between / (1000 * 60 * 60 * 24);
  }

  getSelectroom(id) {
    this.api.get('api/room/promotion_room_show/' + id, true).subscribe(
      (res: any) => {
        this.selectedRoom = res.body.data;
        console.log(res.body.data);
        this.data = {
          id: res.body.data.id,
          Name: res.body.data.roomtype,
        };
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  updatePackage(id) {
    const roomrateElements = document.getElementsByClassName('newrate');
    const package_prices = [];

    for (let i = 0; i < roomrateElements.length; i++) {
      const promoPriceElement = roomrateElements[i] as HTMLInputElement;
      const promo_price = parseFloat(promoPriceElement.value.replace('$', '').replace(',', ''));

      const barRateElement = document.getElementsByClassName('roomrate')[i] as HTMLInputElement;
      const bar_price = parseFloat(barRateElement.value.replace('$', '').replace(',', ''));

      const dateElement = document.getElementsByClassName('season-date')[i] as HTMLInputElement;
      const date_day = dateElement.getAttribute('id');

      package_prices.push({
        price: isNaN(promo_price) ? bar_price : promo_price,
        date_day: date_day,
      });
      console.log(promo_price, bar_price, 'price promo updated');
    }

    this.create_package_form.addControl(
      'package_data',
      this.fb.array(package_prices)
    );

    this.api
      .post('api/package/promotion_package_update/' + id, this.create_package_form.value, true)
      .subscribe(
        (res: any) => {
          this.service.get_packages();
          this.getSelectedRooms = id;
          this.service.update_new_data.next(true);
          console.log(this.create_package_form, 'package form');
          this.helper.alert_success(
            'Promotion has been updated successfully !'
          );
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  onClose() {
    this.service.package_creating = '';
    this.selectedRoom = '';
    this.create_package_form.reset();
  }

  // add_upsell(item, val){
  //   if(item != '' && val != ''){
  //    this.upsel_item = null;
  //    this.upsel_value = '';
  //    this.upsells.push({name: item, value: val});
  //    this.upsell_items = this.upsell_items.filter(el => {
  //      return item != el.value;
  //    });
  //   }
  // }
  // remove_upsell(i){
  //   this.upsells = this.upsells.filter(e => { return e.name != i });
  // }

  changeWheel() {
    this.wheelT = !this.wheelT;
    this.create_package_form.patchValue({
      wheelchairaccessibility: this.wheelT,
    });
    const obj = { wheelT: this.wheelT };
    if (obj.wheelT == true) {
      this.amenities.push({ wheelT: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.wheelT !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changeSmoke() {
    this.smoke = !this.smoke;
    this.create_package_form.patchValue({ smoking: this.smoke });
    const obj = { smoking: this.smoke };
    if (obj.smoking == true) {
      this.amenities.push({ smoking: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.smoking !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changePet() {
    this.pet = !this.pet;
    this.create_package_form.patchValue({ pets: this.pet });
    const obj = { pets: this.pet };
    if (obj.pets == true) {
      this.amenities.push({ pets: true });
      console.log(this.amenities);
    } else {
      this.amenities = this.amenities.filter((item) => item.pets !== true);
      console.log(this.amenities);
    }
    this.getroomsbysesaon(this.amenities);
  }

  changebreakfast() {
    this.breakfast = !this.breakfast;
    this.create_package_form.patchValue({ breakfasts: this.breakfast });
  }

  choose_date(e) {
    if (e.startDate != null && e.endDate != null) {
      this.date_to = e.startDate.format('DD/MM/YYYY');
      this.date_from = e.endDate.format('DD/MM/YYYY');

      // console.log(this.date_to);

      this.date_to_rate = e.startDate.format('YYYY-MM-DD');
      this.date_from_rate = e.endDate.format('YYYY-MM-DD');
      // this.get_rates_for_update(this.date_to_rate,this.date_from_rate)

      this.onSearchChange;

      if (this.edit == true) {
        this.getSelectedRoom(this.data);
      }

      const arr = [];
      this.getroomsbysesaon(arr);

      if (this.selectedRoom != undefined) {
        console.log(this.selectedRoom);
        const room_id = this.selectedRoom.id;
        const start_date = this.date_to_rate;
        const end_date = this.date_from_rate;
        this.api
          .post(
            'api/booking/rate_get_rates_by_checkin_checkout',
            { property_id: this.property_id, start_date, end_date, room_id },
            true
          )
          .subscribe(
            (res: any) => {
              this.get_rate_by_checkin_checkout = res.body.data;
            },
            (err) => this.error_handling.handle_error(err.status)
          );
      }
    }
  }

  modalfooter() {
    setTimeout(() => {
      const f = document.getElementById('ModalFooterP');
      const h = document.getElementById('SP');
      this.bHeight = h.offsetHeight;
      if (this.bHeight > 700) {
        f.classList.add('widthBig');
        f.classList.remove('widthPad');
        h.classList.remove('paddR5');
        h.classList.add('paddR2');
      } else if (this.bHeight < 700) {
        f.classList.remove('widthBig');
        f.classList.add('widthPad');
        h.classList.add('paddR5');
        h.classList.remove('paddR2');
      }
    }, 100);
  }

  checkDarkMode() {
    const mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  showList() {
    this.slist = true;
  }

  hideList() {
    this.slist = false;
  }

  selectHoliday(name) {
    setTimeout(() => {
      this.create_package_form.patchValue({ package_name: name });
    });
  }

  colorRadio(id, idt) {
    const a = document.getElementById(id);
    const b = document.getElementById(idt);
    a.classList.contains('radioByJs')
      ? a.classList.remove('radioByJs')
      : a.classList.add('radioByJs');
    b.classList.contains('radioByJs')
      ? b.classList.remove('radioByJs')
      : b.classList.add('radioByJs');
  }

  getUpsell() {
    this.api
      .get(
        'api/property/get_extras/' + localStorage.getItem('current_property'),
        true
      )
      .subscribe((res: any) => {
        this.upsell_items = res.body.data;
      });
  }

  add_upsell(item, value) {
    let u: any;
    u = this.upsell_items.filter((el) => {
      return item == el.id;
    });
    this.upsells.push({
      name: u[0].name,
      upsell_id: u[0].id,
      price: value,
    });
    this.serviceItem = null;
    this.servicePrice = null;
  }

  remove_upsell(i) {
    this.upsells = this.upsells.filter((e) => {
      return e.name != i;
    });
  }

  getExtrasPrice(data: number, type: string) {
    const matchingExtra = this.addOnsExtras.find(element => element.id === data);

    if (matchingExtra) {
      let p = matchingExtra.price === 'free' ? 'Free' : matchingExtra.price === 'not-available' ? 'Not Available' : matchingExtra.price;

      if (type === 'service') {
        this.create_package_form.patchValue({ service_value: p });
      } else if (type === 'minibar') {
        this.create_package_form.patchValue({ minibar_value: p });
      }
    }
  }


  // onSearchChange(event, i) {
  //   const roomrate = document.getElementsByClassName('roomrate');
  //   const greenbar = document.getElementsByClassName('disbox');
  //   const promotion_rate = event.target.value;
  //   const bar_rate = roomrate[i].getAttribute('id');
  //   let promo_rate = promotion_rate.substring(1);
  //
  //   const data_def: any = parseInt(promo_rate) - parseInt(bar_rate);
  //
  //   let sum_data: any = 100 * (data_def / parseInt(bar_rate));
  //   console.log(data_def,promotion_rate,bar_rate,sum_data,"sum data");
  //
  //   if (isNaN(sum_data)) {
  //     sum_data = 0;
  //   }
  //
  //   if (sum_data > 0) {
  //     greenbar[i].innerHTML = `<div class="greenbar" style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21)); width: 55px;border-radius: 20px;color: #fff;text-align: center;">
  //     <i class="fa fa-caret-up"></i> ${sum_data.toFixed(0)}% </div>`;
  //   } else {
  //     greenbar[i].innerHTML = `<div class="greenbar" style="background: linear-gradient(rgb(249 25 25), rgb(115 6 35));    width: 55px;border-radius: 20px;color: #fff;text-align: center;">
  //     <i class="fa fa-caret-down"></i> ${Math.abs(sum_data.toFixed(0))}% </div>`;
  //   }
  // }
  onSearchChange(event, i) {
    let newrate = document.getElementsByClassName('newrate');
    let roomrate = document.getElementsByClassName('roomrate');
    let diss = document.getElementsByClassName('dis');
    let getid = document.getElementById(i);
    let dis = document.getElementById(i);
    let greenbar = document.getElementsByClassName('disbox');
    let promotion_rate = (<HTMLInputElement>document.getElementById(`c${i}`))
      .value;
    let bar_rate = roomrate[i].getAttribute('id');

    var data_def: any = parseInt(promotion_rate) - parseInt(bar_rate);
    var sum_data: any = 100 * (data_def / parseInt(bar_rate));

    if (isNaN(sum_data)) {
      sum_data = 0;
    }

    greenbar[i].innerHTML = this.generateGreenbar(sum_data);
  }

  generateGreenbar(sum_data) {
    const background = sum_data === 0
      ? 'linear-gradient(#7340ce, #300087)'
      : sum_data > 0
        ? 'linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21))'
        : 'linear-gradient(rgb(249 25 25), rgb(115 6 35))';

    const icon = sum_data === 0 ? '' : sum_data > 0 ? '<i class="fa fa-caret-up"></i>' : '<i class="fa fa-caret-down"></i>';
    const text = `${icon} ${sum_data.toFixed(0)}%`;

    return `<div class="greenbar" style="background: ${background}; width: 77px; border-radius: 20px; color: #fff; text-align: center; font-weight: bold; font-size: 12px; padding: 5px 0px;"> ${text} </div>`;
  }

  onFocusChange({ event }: OnFocusChangeParams) {
    let promo_rate = event.target.value.substring(1);
    const numberValue = parseFloat(promo_rate.replace(/,/g, ''));
    if (event.target.value) {
      return event.target.value = numberValue;
    }
  }

  onBlurChange({ event }: OnBlurChangeParams) {
    let promo_rate = event.target.value;
    if (promo_rate) {
      return event.target.value = this.current_currency + parseInt(promo_rate).toFixed(2);
    }
  }

  getExpenses() {
    this.reservationService.getAddOns$().subscribe((res: ExtrasResponse) => {
      this.addOnsExtras = res.data
      this.serviceAddons = this.addOnsExtras.filter(x => x.type == "service");
      this.minibarAddons = this.addOnsExtras.filter(x => x.type == "minibar");
    },
      (err) => this.error_handling.handle_error(err)
    );
  }

  displayAddonsNotEmpty(type: string, bookingExtras: AddOnsExtras[]) {
    let isEmpty = bookingExtras.some(x => x.type === type);
    return isEmpty;
  }

  addExtras(addonId: number) {
    const form = this.create_package_form.get('booking_extras') as FormArray;

    if (this.serviceItem || this.minibarItem) {
      form.push(this.createExtrasGroup(addonId, 1));
      console.log("form", form.value);
    }

    this.serviceItem = null;
    this.servicePrice = null;
    this.minibarItem = null;
    this.minibarPrice = null;

  }

  createExtrasGroup(addOnId: number, count: number): FormGroup {
    return this.fb.group({
      property_id: localStorage.getItem("current_property"),
      extras_item_id: addOnId,
      item_count: count,
    });

  }

  getFormAddons() {
    const form = this.create_package_form.get('booking_extras') as FormArray;
    const matchingExtras = [];

    for (const addOn of form.controls) {
      const extrasItemId = addOn.get('extras_item_id').value;
      const matchingExtra = this.addOnsExtras.find((addonExtra) => addonExtra.id === extrasItemId);

      if (matchingExtra) {
        matchingExtras.push(matchingExtra);
      }
    }

    return matchingExtras;
  }

}
