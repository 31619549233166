<header>

  <div class="notification" [ngClass]="darkMode ? 'dark_noti' : 'notification'">
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="greetings">Good Evening {{ user.first_name }}</div> -->
        <div class="message">
          {{notifications}}
        </div>
        <div class="topImage" style="float: right;">
          <span>{{ user?.first_name }} {{ user?.last_name }}</span>
          <!-- <img  class="img-circle profile-img" src="assets/img/nav/user.png" > -->
          <img
            src="{{ user?.profile_picture ? base_url2+'/app/images/' + user?.profile_picture : 'assets/img/nav/user.png' }}"
            class="img-circle profile-img" />
          <!-- <img  class="img-circle profile-img" src="{{ user ? base_url2+'/app/images/' + user : 'assets/img/nav/user.png' }} " > -->
        </div>

      </div>
    </div>
  </div>

</header>