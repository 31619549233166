import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject, EMPTY, Subject, combineLatest, from, Observable} from 'rxjs';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import {catchError, map, mergeMap, scan, shareReplay, switchMap, tap} from 'rxjs/operators';
import {GetBookingResponseModel} from '../common/Inventory/BookingResponse';
import {TotalRoomsInSeasonResponseModel} from '../common/Inventory/TotalRoomsInSeasonRespose';
import {BookingDetailsByRoom, BookingDetailsByRoomResponse} from '../common/Inventory/BookingDetailsByRoom';
import { CalendarCellModel } from '../common/Inventory/CalendarCellModel';
import { BookingDetailsByRoomTypes } from '../common/Inventory/BookingDetailsByRoomTypes';
import { BulkReservationSelectionRange } from '../common/Inventory/Reservation/BulkReservationSelectionRange';
const moment = extendMoment(Moment);

 @Injectable({
  providedIn: 'root'
})

export class InventoryCalendarService {
  
  propertyId = localStorage.getItem('current_property');
  endpoints = {
    getRoomBookings: '/api/inventory/get_room_bookings',
    getTotalRoomsInSeason: '/api/inventory/get_total_rooms_in_season',
    getBookingDetailsByRoom: '/api/inventory/get_booking_details_in_room'
  };

  private _roomBookingRequestInvoker$ = new BehaviorSubject<void>(undefined);
  private _totalRoomInSeasonRequestInvoker$ = new BehaviorSubject<void>(undefined);
  private _selectedRoom$ = new Subject<number>();
  private cachedSeasonDates: { startDate: moment.Moment, endDate: moment.Moment };

  private _calendarDateRange$: BehaviorSubject<{startDate: Moment.Moment,endDate: Moment.Moment}> = new BehaviorSubject<{startDate: Moment.Moment,endDate: Moment.Moment}>({
    startDate: moment().subtract(14, 'days'),
    endDate: moment().add(14, 'days')
  });


  public calendarDateRange$ = this._calendarDateRange$.asObservable().pipe(tap(x=> {
    this.inventoryBaseRequest.start_date = x.startDate.format('YYYY-MM-DD');
    this.inventoryBaseRequest.end_date = x.endDate.format('YYYY-MM-DD');
  }));
  public roomBookingDetails$ = new Subject<number>();

  private _roomBookingDetailsMap = new Map<number, BookingDetailsByRoom>();

  private calendarCellClickedSubject = new Subject<CalendarCellModel | undefined>();
  public calendarCellClicked$ = this.calendarCellClickedSubject.asObservable();
  inventoryBaseRequest: { start_date: string; end_date: string; property_id: string; };
  
  constructor(
    private api: ApiService,
    private http: HttpClient,
  ) {
    let cachedDates = JSON.parse(localStorage.getItem('cachedSeasonDates'));
    if (!cachedDates) {
      cachedDates = {startDate: moment().subtract(14, 'days'), endDate: moment().add(14, 'days')};
    }
    this.cachedSeasonDates = cachedDates;
  }

  //#region Room Booking Request
  private roomBookingRequest$ = this.http.post<GetBookingResponseModel>(
    this.api.base_url + this.endpoints.getRoomBookings,
    this.getInventoryBaseRequest(),
    {headers: this.prepareAuthHeaders()}
  ).pipe(
    map((val) => {
      console.log('getting data from server.. .');
      return val.data.bookings;
    })
  );

  private totalRoomsInSeasonRequest$ = this.http.post<TotalRoomsInSeasonResponseModel>(
    this.api.base_url + this.endpoints.getTotalRoomsInSeason,
    this.getInventoryBaseRequest(),
    {headers: this.prepareAuthHeaders()}
  ).pipe(
    tap((val) => {
      this.cachedSeasonDates = {
        startDate: moment(val.data.seasons[0].date_from),
        endDate: moment(val.data.seasons.slice(-1)[0].date_to)
      };
      localStorage.setItem('cachedSeasonDates', JSON.stringify(this.cachedSeasonDates));
    }),
    map((val) => {
      console.log('getting data from server...',val.data);
      return val.data;
    })
  )

  public roomBookings$ = this._roomBookingRequestInvoker$.pipe(
    mergeMap(() => this.roomBookingRequest$),
    shareReplay(1)
  );
  //#endregion

  //#region Total Rooms in Season Request
  public totalRoomsInSeason$ = this._totalRoomInSeasonRequestInvoker$.pipe(
    mergeMap(() => this.totalRoomsInSeasonRequest$),
    shareReplay(1)
  );

  private getBookingDetailsByRoomRequest(roomId) {
    return this.http.post<BookingDetailsByRoomResponse>(
      this.api.base_url + this.endpoints.getBookingDetailsByRoom,
      {...this.getInventoryBaseRequest(), room_id: roomId},
      {headers: this.prepareAuthHeaders()}
    ).pipe(
      catchError((error) => {
        console.error('HTTP error:', error);
        return EMPTY;
      }),
      map((val) => val.data)
    );
  }


  public bookingDetailsByRoom$: Observable<Map<number, BookingDetailsByRoom>> = this._selectedRoom$.pipe(
    mergeMap((roomId) => {
      if (roomId > 0) {
        return from(this.getBookingDetailsByRoomRequest(roomId)).pipe(tap((val) => {
          this._roomBookingDetailsMap.set(roomId, val);
        }), );
      } else {
        return EMPTY;
      }
    }),
    map((x => {
      return this._roomBookingDetailsMap;
    }))
  );

  // public totalRoomsInSeason$ = this._calendarDateRange$.pipe(switchMap(dateRange => {
  //   if (!dateRange) {
  //     return this.totalRoomsInSeasonRequest$
  //   }

  //   let startDate = moment(dateRange.startDate)
  //   let endDate = moment(dateRange.endDate)
  //   let cachedStartDate = moment(this.cachedSeasonDates.startDate)
  //   let cachedEndDate = moment(this.cachedSeasonDates.endDate)

  //   if (startDate.isSameOrAfter(cachedStartDate) && endDate.isSameOrBefore(cachedEndDate)) {
  //     return this.totalRoomInSeasonCache$
  //   }
  //   else {
  //     return this.totalRoomsInSeasonRequest$
  //   }
  // }))
  //#endregion

  onCalendarCellClicked(data: CalendarCellModel){
    this.calendarCellClickedSubject.next(data);
  }

  public setSelectedRoom(roomId) {
    this._selectedRoom$.next(roomId);
  }

  public setCalendarDateRange(startDate: moment.Moment, endDate: moment.Moment) {
    this._calendarDateRange$.next({startDate, endDate});
    setTimeout(()=> {
      this._totalRoomInSeasonRequestInvoker$.next(undefined);
    },1000)
  }

  public getCalendarDateRange(): { startDate: moment.Moment, endDate: moment.Moment }{
    return this._calendarDateRange$.value;
  }

  getInventoryBaseRequest() {
    this.inventoryBaseRequest = {
      start_date: this._calendarDateRange$.value.startDate.format('YYYY-MM-DD'),
      end_date: this._calendarDateRange$.value.endDate.format('YYYY-MM-DD'),
      property_id: this.propertyId
    };
    return this.inventoryBaseRequest;
  }

  updateData() {
    this._roomBookingRequestInvoker$.next(undefined);
  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set('Authorization', bearer_token);
    return header;
  }

  isValidBulkReservationRange(range: { start?: CalendarCellModel; end?: CalendarCellModel; }): boolean {
    if (!range.start || !range.end) {
      return false;
    }

    //is date range valid
    const startDate = range.start.date as moment.Moment;
    const endDate = range.end.date as moment.Moment;
    if (startDate.isAfter(endDate)) {
      return false;
    }

    //is room no same
    if (range.start.roomNo !== range.end.roomNo) {
      return false;
    }

    return true;
  }

  ensureNoBookingsBetweenRange(bulkReservationSelectionRange: { start?: CalendarCellModel; end?: CalendarCellModel; }, bookingDetailsByRoomTypes: BookingDetailsByRoomTypes): boolean {
    if (!this.isValidBulkReservationRange(bulkReservationSelectionRange)) {
      return false;
    }

    const startDate = bulkReservationSelectionRange.start.date as moment.Moment;
    const endDate = bulkReservationSelectionRange.end.date as moment.Moment;
    const roomTypeId = bulkReservationSelectionRange.start.roomTypeId;
    const roomNoId = bulkReservationSelectionRange.start.roomNoId;

    let dateRange = moment.range(moment(startDate),moment(endDate));
    for (let day of dateRange.by("days")) {
      const bookings = bookingDetailsByRoomTypes.getByRoomTypeAndDate(roomTypeId, day.format('YYYY-MM-DD'));
      const booking = bookings.find((val) => val.room_no_id === roomNoId);
      if (booking) {
        return false;
      }
    }

    return true;
  }

  ensureNoMultiRangeBookingOnSameRoomType(bulkReservationSelectionRange: BulkReservationSelectionRange, bulkReservationRanges: BulkReservationSelectionRange[]) {
    if (bulkReservationRanges.length === 0) {
      return true;
    }
    const roomNoId = bulkReservationSelectionRange.start.roomNoId;
    for (let range of bulkReservationRanges) {
      if (range.start.roomNoId === roomNoId) {
        return false;
      }
    }
    return true;
  }
  
}
