<!-- Food and Beverages (Setup_Shop-Page) work start -->

<div class="main-div">
    <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
        <div class="row">
            <div class="column-wrapper display-flex space-between">
                <div>
                    <ul class="nav navbar-nav tabsBar" id="tabsBarSetupShop">
                        <li *ngFor="let shop of shopNames">
                            <a class="btn btn-link" (click)="switchShop(shop.shop_id)"
                                [ngClass]="{ 'active': shop.shop_id === activeShopId }">{{shop?.shop_name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 blueHedaer" [ngClass]="darkMode ? 'dark_blueBar' : ''">
        <div class="row">
            <div class="category">Food Category</div>
            <div class="item">Item name</div>
            <!-- <div class="categoryBtn">
                <button class="btn btnCategory" (click)="createCategoryItem()"
                    [ngClass]="darkMode ? 'dark_bredcrum' : ''">+ Create</button>
            </div> -->
        </div>
    </div>

    <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">
        <div class="row" *ngFor="let catItem of setupShops;index as i">
            <div action="" class="addCategoryForm">
                <div class="modal-bar" [ngStyle]="{'border-color': border_colors[i%border_colors.length]}">
                    <div class="categoryLabel">
                        <input class="form-control Label" placeholder="Add Category" type="text"
                            [(ngModel)]="catItem.category_name" name="shopname{{i}}">
                    </div>
                    <div class="flex">
                        <ul class="items d-flex flex-wrap">
                            <li for="" [ngClass]="status ? 'selectItem' : 'null'"
                                *ngFor="let item of catItem.category_items;index as j"
                                [ngStyle]="{'border-color': border_colors[j%border_colors.length]}">
                                {{item.item_name}}
                                <i class="fa fa-times" (click)="removeMenuItem(catItem,j)" aria-hidden="true"></i>
                            </li>
                            <div class="item-input">
                                <input class="form-control" name="catItem{{i}}" placeholder="Add Item"
                                    [(ngModel)]="catItem.item_name" type="text" (keyup.enter)="addMenuItem(i)">
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btnCol">
                <button class="btn addBtnCross" data-toggle="modal" data-target="#confirmationModal-3"
                    (click)="deleteCategoryItem(catItem.category_id)">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>

        <div class="row">
            <div action="" class="addCategoryForm">
                <div class="modal-bar border-1">
                    <div class="categoryLabel">
                        <input class="form-control Label" placeholder="Add Category" type="text"
                            [(ngModel)]="new_category_name" name="shopname">
                    </div>
                    <div class="flex">
                        <ul class="items d-flex flex-wrap">
                            <li for="" [ngClass]="status ? 'selectItem' : 'null'"
                                *ngFor="let item of new_category_items;index as j"
                                [ngStyle]="{'border-color': border_colors[j%border_colors.length]}">
                                {{item.item_name}}
                                <i class="fa fa-times" (click)="removeNewMenuItem(j)" aria-hidden="true"></i>
                            </li>
                            <div class="item-input">
                                <input class="form-control" name="catItem" placeholder="Add Item"
                                    [(ngModel)]="new_item_name" type="text" (keyup.enter)="addNewMenuItem()">
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="btnCol">
                <button class="btn addBtn" (click)="createCategoryItem()">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" [ngClass]="darkMode ? 'btnDark': ''" (click)="saveCategoryItems()">Save</button>
    </div>
</div>