import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { ErrorHandlingService } from '../../../services/error-handling.service';
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import * as moment from 'moment';
import { GlobaldataService } from 'src/app/common/globaldata.service';

declare var $: any;

@Component({
  selector: 'app-preview-packages',
  templateUrl: './preview-packages.component.html',
  styleUrls: ['./preview-packages.component.css']
})

export class PreviewPackagesComponent implements OnInit {
  package_data;
  numbers;
  otas = [];
  package_dates: any;
  setup_rate;
  sleeps = 0;
  nights;
  i;
  preview_image;
  internals = [];
  externals = [];
  ami;
  amenities;
  aaa = [];
  with_promo = [];
  without_promo = [];
  total_a;
  current_currency;
  bHeight;
  desT: boolean = false;
  darkMode: boolean = false;
  pushPackage: boolean = false;
  testArr: any;
  amenCol = ['#a9aae0', '#d24f51', '#bf4eeb', '#efab20', '#32d04a', '#519eb9'];
  modalImg: any;
  showImg: boolean = false;
  percentage: any;
  currency_format = localStorage.getItem('currency');
  current_property = localStorage.getItem('property');

  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public service: ManageRatesPackagesService
  ) {

  }

  colors = ['pkg_title', 'green_text', 'purple_text', 'orange_text', 'yellow_text', 'red_dark_text', 'last_text'];
  colors2 = ['green_text', 'purple_text', 'orange_text', 'yellow_text', 'red_dark_text', 'last_text'];
  bdr = ['bdr1', 'bdr2', 'bdr3', 'bdr4', 'bdr5', 'bdr6', 'bdr7'];

  ngOnInit() {
    this.currency();
    this.get_package();
    this.modalfooter();
    this.checkDarkMode();
    this.percentage = GlobaldataService.previewPercent;

    $('#SP').mouseup(function () {
      $('#grab-12').css({ 'cursor': 'grab' });
    });
    $('#SP').mousedown(function () {
      $('#grab-12').css({ 'cursor': 'grabbing' });
    });

  }

  get_package() {
    if (this.service.package_creating !== undefined) {
      this.api.get('api/package/promotion_package_show/' + this.service.package_creating, true).subscribe((res: any) => {
        // console.log(this.package_data = res.body.data);

        this.package_data = res.body.data;
        console.log(this.package_data, 'package data');

        this.otas = res.body.data.otas;
        this.package_dates = new Date(res.body.data.date_from).toLocaleString('default', {
          month: 'short',
          day: 'numeric'
        }) + ' - ' + new Date(res.body.data.date_to).toLocaleString('default', { month: 'short', day: 'numeric' });
        let a = res.body.data.date_to.split('/');
        let b = res.body.data.date_from.split('/');
        var start = moment(a[1] + '/' + a[0] + '/' + a[2]);
        var end = moment(b[1] + '/' + b[0] + '/' + b[2]);
        this.package_dates = {
          start: a[1] + '/' + a[0] + '/' + a[2],
          end: b[1] + '/' + b[0] + '/' + b[2]
        };
        //  console.log(this.package_data);
        this.get_amenities();
        this.sepration_ch();
        this.nights = end.diff(start, 'days');
        this.preview_image = this.package_data.room != null ? this.package_data.room.images.length != 0 ? this.api.imgUrl + this.package_data.room.images[0].image : 'assets/img/other/no-room.jpg' : 'assets/img/other/no-room.jpg';
      }, err => this.error_handling.handle_error(err.status, err.message));

    }

    console.log(this.package_data, 'package_data');
  }

  sepration_ch() {
    if (this.package_data.promo_allocations.length >= 1) {
      this.package_data.otas.map((val) => {
        this.package_data.promo_allocations.map((val2) => {
          if (val.channel_id == val2.channel_id) {
            this.with_promo.push({ chan: val, prom: val2 });
          } else {
            this.without_promo.push({ chan: val, prom: { promo_code: null } });
          }
        });
      });
      this.testArr = this.with_promo.concat(this.without_promo);
      this.testArr.map((val) => {
        if (val.chan.channel.type == 'External') {
          this.externals.push(val);
        } else {
          this.internals.push(val);
        }
      });
      this.internals = this.internals.filter((v, i, a) => a.findIndex(t => (t.chan.channel_id === v.chan.channel_id)) === i);
      this.externals = this.externals.filter((v, i, a) => a.findIndex(t => (t.chan.channel_id === v.chan.channel_id)) === i);
    } else {
      this.package_data.otas.map((val) => {
        if (val.channel.type == 'External') {
          this.externals.push(val);
        } else {
          this.internals.push(val);
        }
      });
    }
    let week_days = this.package_data.week_day.length;
    let weekend_days = this.package_data.weekend_day.length;
    let week_days_rate = this.package_data.week_days_rate;
    let weekend_days_rate = this.package_data.weekend_days_rate;

    let a = week_days * week_days_rate;
    let b = weekend_days * weekend_days_rate;
    let c = a + b;
    this.total_a = c;
  }

  checkDays(d) {
    let w = [
      { name: 'Monday', status: false },
      { name: 'Tuesday', status: false },
      { name: 'Wednesday', status: false },
      { name: 'Thursday', status: false },
      { name: 'Friday', status: false },
      { name: 'Saturday', status: false },
      { name: 'Sunday', status: false }
    ];
    w.map((val) => {
      d.map((val2) => {
        if (val2 == val.name) {
          val.status = true;
        }
      });
    });
    return w;
  }

  get_amenities() {
    this.api.post('api/amenitie/index', { order: 'other', type: 'Room', 'parent_id': 'Null' }, true).subscribe((res: any) => {
      this.amenities = res.body.data;
      if (this.package_data.room.amenities_title != undefined) {
        this.amenities.map((val) => {
          val.children.map((val2) => {
            this.package_data.room.amenities_title.map((val3) => {
              if (val2.id == val3.amenitie_id) {
                this.aaa.push({
                  title: val2.title,
                  parentId: val.id,
                  parentTitle: val.title
                });
              }
            });
          });
        });
      } else {
        this.amenities.map((val) => {
          val.children.map((val2) => {
            this.package_data.room.amenities.map((val3) => {
              if (val2.id == val3.amenitie_id) {
                this.aaa.push({
                  title: val2.title,
                  parentId: val.id,
                  parentTitle: val.title
                });
              }
            });
          });
        });
      }
    }, err => this.error_handling.handle_error(err.status));
  }

  showPromo(id) {
    let a = this.package_data.promos.find((x) => {
      if (x.channel_id == id) {
        return x;
      } else {
        return undefined;
      }
    });
  }

  modalfooter() {
    setTimeout(() => {
      let f = document.getElementById('ModalFooterP');
      let h = document.getElementById('SP');
      this.bHeight = h.offsetHeight;
      if (this.bHeight > 700) {
        f.classList.add('widthBig');
        f.classList.remove('widthPad');
        h.classList.remove('paddR5');
        h.classList.add('paddR2');
      } else if (this.bHeight < 700) {
        f.classList.remove('widthBig');
        f.classList.add('widthPad');
        h.classList.add('paddR5');
        h.classList.remove('paddR2');
      }
    }, 100);
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  showImgModal(pimg) {
    this.modalImg = pimg;
    this.showImg = true;
  }

  hideImg() {
    setTimeout(() => {
      this.showImg = false;
    }, 100);
  }

  seeDescription() {
    this.desT = !this.desT;
  }

  descripArray(data) {
    if (this.service.is_preview == true) {
      return data.split(' ').slice(0, 52).join().replace(/,/g, ' ');
    } else {
      return data.split(' ').slice(0, 30).join().replace(/,/g, ' ');
    }
  }

  descripArray2(data) {
    if (this.service.is_preview == true) {
      return data.split(' ').slice(52).join().replace(/,/g, ' ');
    } else {
      return data.split(' ').slice(30).join().replace(/,/g, ' ');
    }
  }

  mouo(id) {
    document.getElementById(id).style.visibility = 'visible';
  }

  mouout(id) {
    document.getElementById(id).style.visibility = 'hidden';
  }

  dateFunc2(d) {
    let a = d.split('/');
    return moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]);
  }

  // roundPrice(pa,pb){
  //   let a  = Math.round(pa * 100 / pb)
  //   return 100 - a;
  // }


  getRoundPrice(pa, pb): string {

    let a: number = Math.round(pa * 100 / pb);

    if (a > 0 && a < 100) {
      let val = 100 - a;
      return `${val}`;
    } else {
      return ``;
    }

  }

  roundPrice(pa, pb) {
    console.log('this is PA' + pa);

    console.log('this is PB' + pb);

    let a: number = Math.round(pa * 100 / pb);

    if (a > 0 && a < 100) {
      return 100 - a;
    } else {
      return -1;
    }
    // return 100 - a;
  }

  roundPricee(a) {
    return a.toFixed(0);
  }

  currency() {
    this.current_currency = localStorage.getItem('current_currency');
  }

  // getRoundPrice(pa, pb): string {

  //   let a: number = Math.round(pa * 100 / pb)

  //   if (a > 0 && a < 100) {
  //     let val = 100 - a
  //     return `${val}`;
  //   }
  //   else {
  //     return ``;
  //   }

  // }

  calculteTaxAmount(price) {
    const property_data = JSON.parse(this.current_property);
    const total = (property_data.taxper / 100) * price;
    return total;
  }

  dateFunc(d) {
    const a = d.split('/');
    return moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]).format('DD MMM YY');
  }
}
