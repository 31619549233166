import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from 'rxjs/operators';
import { LayoutService } from "../layouts/layout.service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()

export class RequestInterceptor implements HttpInterceptor {

    private totalRequests = 0;
    constructor(
        private toaster: ToastrService,
        private layout: LayoutService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.totalRequests++;
        this.layout.spinner = true;
        this.spinner.show();
        // console.log("Http loader Active... ", this.layout.spinner);
        return next.handle(request).pipe(
            tap(res => {
                if (res instanceof HttpResponse) {
                    this.decreaseRequests();
                }
            }),
            catchError((error: HttpErrorResponse) => {
                // console.log(error);
                let errorMessage = '';
                if (error.error) {
                    if (typeof error.error.message === 'object') {
                        for (const i in error.error.message) {
                            errorMessage = `<span class="error-icon"></span>
                        <span class="error-message">${error.error.message[i]}</span>`;
                        }
                    } else {
                        // client-side error
                        errorMessage = `<span class="error-icon"></span>
                                      <span class="error-message">${error.error.message}</span>`;
                    }
                } else {
                    // server-side error
                    errorMessage = error.message;
                }

                if (error.status == 401) {
                    console.log("You are unautorize");
                    localStorage.removeItem('auth_token');
                    this.router.navigateByUrl('/');
                }
                this.decreaseRequests();
                this.toaster.error(errorMessage, '', { enableHtml: true });
                return throwError(errorMessage);
            })
        );
    }

    private decreaseRequests() {
        this.totalRequests--;
        if (this.totalRequests === 0) {
            this.layout.spinner = false;
            this.spinner.hide();
            // console.log("Http loader False... ", this.layout.spinner);
        }
    }
}
