import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { RmsService } from 'src/app/services/rms.service';

@Component({
  selector: 'app-cigar',
  templateUrl: './cigar.component.html',
  styleUrls: ['./cigar.component.css']
})
export class CigarComponent implements OnInit {

  cigarCardItems: Subject<any> = new Subject();
  darkMode: boolean = false;

  constructor(
    private rmsService: RmsService
  ) { }

  cigar_orderCard: Array<any> = [
    {
      id: '1',
      itemImage: "../../../../assets/img/back-office/rms_img/arturo.jpg",
      title: 'Arturo Fuente',
      count: 0,
      price: 699
    },
    {
      id: '2',
      itemImage: "../../../../assets/img/back-office/rms_img/davidoff-signature.jpg",
      title: 'Davidoff Signature',
      count: 0,
      price: 699
    },
    {
      id: '3',
      itemImage: "../../../../assets/img/back-office/rms_img/Cohiba.jpg",
      title: 'Cohiba',
      count: 0,
      price: 700
    },
    {
      id: '4',
      itemImage: "../../../../assets/img/back-office/rms_img/padron.jpg",
      title: 'Padron',
      count: 0,
      price: 750
    },
    {
      id: '5',
      itemImage: "../../../../assets/img/back-office/rms_img/acid-cigar.jpg",
      title: 'Acid',
      count: 0,
      price: 650
    },
    {
      id: '6',
      itemImage: "../../../../assets/img/back-office/rms_img/Macanudo-Cafe.jpg",
      title: 'Macanudo Cafe',
      count: 0,
      price: 450
    },
    {
      id: '7',
      itemImage: "../../../../assets/img/back-office/rms_img/mini-cigarillo.jpg",
      title: 'Mini Cigarillo',
      count: 0,
      price: 600
    },
    {
      id: '8',
      itemImage: "../../../../assets/img/back-office/rms_img/petit-bouqute.jpg",
      title: 'Petit Bouqute',
      count: 0,
      price: 850
    },
    {
      id: '9',
      itemImage: "../../../../assets/img/back-office/rms_img/perla.jpg",
      title: 'Perla',
      count: 0,
      price: 950
    },
    {
      id: '10',
      itemImage: "../../../../assets/img/back-office/rms_img/laquito.jpg",
      title: 'Laquito',
      count: 0,
      price: 800
    },
    {
      id: '11',
      itemImage: "../../../../assets/img/back-office/rms_img/petit-edmundo.jpg",
      title: 'Petit Edmundo',
      count: 0,
      price: 900
    },
    {
      id: '12',
      itemImage: "../../../../assets/img/back-office/rms_img/robusto.jpg",
      title: 'Robusto',
      count: 0,
      price: 950
    },
  ]

  ngOnInit() {
    this.checkDarkMode();
  }

  orderNowCigar(index: any) {
    console.log(this.cigar_orderCard[index], 'array');
    //let pushitem = this.cigarCardItems.next(this.cigar_orderCard[index]);
    //console.log(pushitem, 'cigaritems');

    this.rmsService.addOrderItem(this.cigar_orderCard[index])
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
