import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { ManageShop } from 'src/app/common/pnl/fnb/manageShopModel';
import { FnbService } from 'src/app/services/fnb.service';

@Component({
  selector: 'app-manage-shop',
  templateUrl: './manage-shop.component.html',
  styleUrls: ['./manage-shop.component.css']
})
export class ManageShopComponent implements OnInit {

  darkMode: boolean = false;
  shop_names: ManageShop[] = [];
  @ViewChildren('shopNameInput') shopNameInputs: QueryList<any>;
  new_shop_name: string;
  propertyId = localStorage.getItem("current_property");

  constructor(
    private fnbService: FnbService,
    private toaster: ToastrService,
    private confirm: ConfirmationAlertService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getShopNames();
  }

  getShopNames() {
    this.fnbService.getcreateShopNames().subscribe(res => {
      this.shop_names = res.data.shops;
    });
  }

  editShop(ids) {
    let input_id = <HTMLInputElement>document.getElementById('shop' + ids);
    input_id.removeAttribute('readonly');
    input_id.focus()
  }

  deleteShop(i) {
    this.confirm.confirm(`Alert`, `Are you sure you want to delete this shop?`,
      [`This action can't be undone`, 'Are you sure you want to delte?'],
      () => {
        this.shop_names[i].is_deleted = 1;
        this.fnbService.createShopReq(this.shop_names).subscribe(res => {
          this.getShopNames();
          this.toaster.success(res.message)
        })
      }
      , '', ''
    );
  }

  saveShopEdit() {

    this.shopNameInputs.forEach(input => {
      input.nativeElement.setAttribute('readonly', '');
    });

    this.fnbService.createShopReq(this.shop_names).subscribe(res => {
      this.toaster.success(res.message)
    })
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
