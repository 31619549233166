import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-book-call',
  templateUrl: './book-call.component.html',
  styleUrls: ['./book-call.component.css']
})
export class BookCallComponent implements OnInit {
  inlineDate;
  contact_support_form: FormGroup;
  selectedTime: any;
  darkMode: boolean = false;
  duaration: any;
  countryByTime
  zoneTime;
  date: any;
  city: any;
  times: any;
  submitted: boolean;
  selectedDateTime = [
    {
      startTime: '09:00',
      endTime: '10:00',
      date: 'Tue Jul 14 2020'
    },
    {
      startTime: '11:00',
      endTime: '12:00',
      date: 'Tue Jul 14 2020'
    },
    {
      startTime: '12:00',
      endTime: '01:00',
      date: 'Tue Jul 14 2020'
    },
    {
      startTime: '02:00',
      endTime: '03:00',
      date: 'Tue Jul 14 2020'
    }
  ];
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private helper: HelperService,
    private error_handling: ErrorHandlingService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();

    let id = JSON.parse(localStorage.getItem('current_property'));
    this.contact_support_form = this.fb.group({
      subject: ['', Validators.required],
      description: ['', Validators.required],
      user_id: ['', Validators.required],
    });
    this.getTimeZone();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
  subletmit2() {
    let obj = {
      user_id: JSON.parse(localStorage.getItem('user')).id,
      call_duration: this.duaration,
      call_date: this.date.toDateString(),
      timezone: this.city,
      call_time: this.times
    }
    this.api.post('api/helper/timeslot_store', obj, true).subscribe((res: any) => {
      this.helper.alert_success("Request submitted successfully");
      this.submitted = false;
    }, err => this.error_handling.handle_error(err.status));
  }
  eventClicked(e) {
    let d = e.startDate._d;
    this.date = d;
    let b = document.getElementById("redCard").style.display = 'block';
  }
  getTime(timess) {
    this.times = timess.startTime + '-' + timess.endTime;
  }

  getTimeZone() {
    let a = new Date().toString().split(" ");
    this.city = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.countryByTime = a[6].split("(")
    this.zoneTime = a[5]
  }
}
