import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-swtich',
  templateUrl: './plan-swtich.component.html',
  styleUrls: ['./plan-swtich.component.css']
})
export class PlanSwtichComponent implements OnInit {
  darkMode: boolean = false;
  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }



}
