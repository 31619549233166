<!-- Content Load -->
<ng-container *ngIf="!(bookingsInRange$ | async)">
  <div [ngClass]="darkMode ? 'darkOn' : 'null'" style="height: 88vh;
overflow: hidden;">
    <table class="table">
      <thead>
        <tr>
          <th>
            <div class="basic_block inventory_grid mb-0 mt-2">
              <div class="inventory_wrapper">
                <h4 class="inventory_text">ROOM&nbsp;TYPE</h4>
              </div>
              <h4 class="room_type_text">AVAILABILITY</h4>
            </div>
          </th>

          <th *ngFor="let range of getEmptyElements(8); let i = index">
            <div class="basic_block days mb-0">
              <h3 class="days_month">{{ today | date:'MMMM' }}</h3>
              <h3 class="day_number">{{ today | date:'dd' }}</h3>
              <h3 class="days_day">{{ today | date:'EEEE' }}</h3>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="drag_item">
          <td>
            <div class="type">
              <div class="type-text" style="flex: 1;justify-content: space-between;">
                <span>Room Type</span>
                <img src="assets/img/reports/button_down.svg" style="width: 15px;" />
              </div>
            </div>
          </td>
          <td *ngFor="let range of getEmptyElements(8)">
            <div class="room_type">
              <img src="assets/img/inventory/available.png" alt="" class="image-113">
              <h4 class="availability"> 0 </h4>
              <h4 class="available_text">Available</h4>
            </div>
          </td>
        </tr>
      </tbody>
      <ng-container>
        <tr *ngFor="let range of getEmptyElements(6)">
          <td>
            <div class="box" style="background: linear-gradient(to right, #f98282ba 3%, #f98282 15%);">
              <div class="box_left">&nbsp;</div>
              <div class="box_right">&nbsp;</div>
              <hr>
              <div class="box_lines">
                <div class="upper"></div><br>
                <div class="lower"></div>
              </div>
              <hr>
              <div class="box_bar"></div>
            </div>
          </td>
          <td *ngFor="let range of getEmptyElements(8)">
            <div class="box">
              <div class="box_left">&nbsp;</div>
              <div class="box_right">&nbsp;</div>
              <hr>
              <div class="box_lines">
                <div class="upper"></div><br>
                <div class="lower"></div>
              </div>
              <hr>
              <div class="box_bar"></div>
            </div>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</ng-container>
<!-- Content Load  End -->

<div id="scroll_wrapper" class="scroll no-scroll" [ngClass]="darkMode ? 'darkOn' : 'null'">
  <!-- Real Content  -->
  <div class="">
    <div class="grid_calendar capture scroll" id="grid-cal">
      <table class="table ">
        <thead>
          <tr>
            <th>
              <div class="basic_block inventory_grid">
                <div class="inventory_wrapper">
                  <h4 class="inventory_text">ROOM&nbsp;TYPE</h4>
                </div>
                <h4 class="room_type_text">AVAILABILITY</h4>
              </div>
            </th>

            <th *ngFor="let range of calendarRange; let i = index" [id]=" i == 1 ? 'header-box-width' : ''">
              <div class="basic_block days">
                <h3 class="days_month">{{ range.format('MMMM') }}</h3>
                <h3 class="day_number">{{ range.format('DD') }}</h3>
                <h3 class="days_day">{{ range.format('dddd') }}</h3>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="bookingsInRange$ | async as calendarData">
          <ng-container *ngFor="let season of calendarData.seasons">
            <ng-container *ngFor="let roomInfo of season.room_info; index as i">
              <!-- *ngIf="bookingsInRange$ | async as dateRoomMapping" -->
              <tr class="drag_item">
                <td>
                  <div class="type"
                    [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}">
                    <div class="type-text" style="flex: 1;justify-content: space-between;">
                      <span
                        style="width: 149px;display: block;overflow: hidden; text-overflow: ellipsis; height: 22px;white-space: nowrap;
                                overflow: hidden; text-overflow: ellipsis;text-transform: capitalize;">{{roomInfo.room_display_name}}</span>
                      <img src="assets/img/reports/button_down.svg" style="width: 15px;"
                        (click)="toggleDetailsRow(roomInfo.room_id.toString())" />
                    </div>
                  </div>
                </td>
                <td *ngFor="let date of calendarRange">
                  <div class="room_type"
                    [ngStyle]="{'background-color': colors[i%colors.length], 'margin-top': i == 0 ? '0px' : '5px'}">
                    <img src="assets/img/inventory/available.png" alt="" class="image-113">
                    <h4 class="availability">
                      {{getRoomCount(calendarData.dateRoomMapping,date,roomInfo.room_id)}}
                    </h4>
                    <h4 class="available_text">Available</h4>
                  </div>
                </td>
              </tr>

              <!-- package boxes -->
              <ng-container *ngIf="getRowToggle(roomInfo.room_id)">
                <ng-container *ngFor="let roomNum of getRoomSetbyRoomType(roomInfo.room_id)">
                  <tr class="drag_item">
                    <td>
                      <ng-container *ngTemplateOutlet="
                        roomInfoTemplate; context:
                        {
                          $implicit: {
                            roomNum: roomNum.room_number,
                            bedType: roomInfo.bed_type,
                            roomView: roomInfo.room_view, 
                            building: roomNum.building?.name,
                            bgColor: colors[i%colors.length]
                           } 
                        }">
                      </ng-container>
                    </td>
                    <ng-container
                      *ngIf="bookingDetailsByRoomTypes.getByRoomTypeId(roomInfo.room_id) != undefined; else emptyRow">
                      <td class="material-shadow" *ngFor="let range of calendarRange">
                        <ng-container *ngTemplateOutlet="
                      roomCellTemplate;
                      context: { 
                        $implicit: 
                        { 
                          booking: bookingDetailsByRoomTypes.getByRoomTypeAndDateAndRoomNo(roomInfo.room_id,range.format('YYYY-MM-DD'),roomNum.id), 
                          date: range, 
                          roomNo: roomNum.room_number,
                          rate: getRoomRateByDate(roomInfo,range),
                          roomTypeId: roomInfo.room_id,
                          roomNoId: roomNum.id
                        }}
                      ">
                        </ng-container>
                      </td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Real Content ENd  -->
</div>

<ng-template #roomInfoTemplate let-data>
  <div class="package w-clearfix" [ngStyle]="{'background-color': data.bgColor}">
    <div class="RN">
      <span class="rm-font">&nbsp;</span>
      <div class="restriction_panel bed-type-text">
        <div>
          <span class="rm-font">
            <img src="assets/img/newstaticicons/inventory/view-room.svg" alt="roomView">
            {{data.roomView}}
          </span>
          <!-- <span class="rm-font">{{data.building}}</span> -->
        </div>
        <div style="margin-bottom: 5px;">
          <img src="assets/img/newstaticicons/inventory/allowwheel.svg" alt="allaowdog" class="restriction-padding fl-r"
            style="width: 17px;" *ngIf="data?.wheelchairaccessibility == 'true'">
          <img src="assets/img/newstaticicons/inventory/allaowdog.svg" alt="allaowdog" class="restriction-padding fl-r"
            style="width: 25px;" *ngIf="data?.pets == 'true'">
          <img src="assets/img/newstaticicons/inventory/allowsmoke.svg" alt="allaowdog" class="restriction-padding fl-r"
            style="width: 25px;" *ngIf="data?.smoking == 'true'">
        </div>
      </div>
    </div>
    <div class="div-block-5 w-clearfix">
      <h4 class="package_title_main package-styling">
        {{data.roomNum}}
      </h4>
    </div>
    <h4 class="package_room_code over-ride-wit">
      <div class="roomview-bedType">
        <span class="rm-font">
          <img src="assets/img/newstaticicons/inventory/type-bed.svg" alt="bedType">
          {{data.bedType}}
        </span>
      </div>
    </h4>
  </div>
</ng-template>
<ng-template #roomCellTemplate let-data>
  <div>
    <div>
      <div class="box_wrapper">
        <div class="package_details" [ngClass]="getBoxColor(data)">
          <h4 class="package_price">
            <span> {{currency_format}} {{data.rate | number:'1.2-3'}}</span>
            <span>
              <!-- <img src="assets/img/svgicons/smallicons/{{ box?.bookingInfo?.channel_image }}"
        style="width: 25px;" /> -->
            </span>
          </h4>

          <div class="restriction_panel restrict">
            <img src="assets/img/inventory/restriction-lock.svg" class="restriction-padding fl-r" id="restrictionId"
              data-target="#ReModal" data-toggle="modal" width="20px" style="margin-bottom: 2px" />
          </div>

          <div class="title_container w-clearfix">
            <a class="package_price my_title" (click)="onBoxClick(data)">
              <span>{{(data.booking != undefined) ? splitGuestName(data.booking.guest_name) : 'Available'}}</span>
            </a>
          </div>

          <!-- <div class="otas_connected" style="height: 30px; clear: both; overflow: hidden;">
            <a class="menu-text">
            </a>
            <a class="menu-text">
              <img style="position: absolute;bottom: 2px;right: 10px;height: 25px;width: 16px;"
                src="assets/img/manage_rates/dots.png" class="ota">
            </a>
            <p style="margin-top: 4px; font-weight: bold; cursor: pointer;" data-toggle="dropdown">No
              Channel Connected</p>
          </div> -->

          <h4 class="otas_connected override-bottom" *ngIf="data.booking">
            <img src="assets/img/inventory/person.png" alt="" style="margin-right: 5px;">
            <span class="num_person">{{data.booking.no_of_adults}}</span>
            <img src="assets/img/inventory/twoperson.png" alt="" style="margin-right: 5px;">
            <span class="num_person">{{data.booking.no_of_childs}}</span>
            <img src="assets/img/inventory/baby.png" alt="" style="margin-right: 5px;">
            <span class="num_person">{{data.booking.no_of_infants}}</span>
            <span style="float:right;font-weight: bold;font-size: 15px;padding: 3px 0px 0px 3px;">
              {{currency_format}}{{data.rate | number:'1.2-3'}}
            </span>
          </h4>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #placeBox>
  <div class="box">
    <div class="box_left">&nbsp;</div>
    <div class="box_right">&nbsp;</div>
    <hr>
    <div class="box_lines">
      <div class="upper"></div><br>
      <div class="lower"></div>
    </div>
    <hr>
    <div class="box_bar"></div>
  </div>
</ng-template>

<ng-template #blankBox>
  <div class="blankBox material-shadow ">&nbsp;</div>
</ng-template>

<ng-template #emptyRow>
  <td class="" *ngFor="let range of calendarRange">
    <ng-container *ngTemplateOutlet="
    placeBox;
    ">
    </ng-container>
</ng-template>

<!-- Restriction Modal -->
<div class="modal fade" id="ReModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content" [ngClass]="darkMode ? 'modal-content-dark' : 'null'">
      <div class="col-md-10 pl-0 pr-0">
        <div class="alert alert-box alert-dismissible show mb-0 border-bread" style="cursor: grab; height: 45px"
          [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
          Select Restriction
          <button type="button" class="close" data-dismiss="modal">
            <strong>&#x2716;</strong>
          </button>
        </div>
        <div class="col-md-12 editable-column height-99 p-0" style="background-color: #fff" [ngClass]="
            darkMode ? 'card-dark-modal-body_restriction' : ' card-null '">
          <div class="col-md-4 pl-25 br-bt" style="padding-left: 27!important;">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.ctd" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/ctd.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    CTD
                    <!-- {{ "inventory.table.ctd" | translate }} -->
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 pl-25 br-bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" [(ngModel)]="res_obj.stop_sell" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/stop-sell.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    Stop sell
                    <!-- {{ "inventory.table.stop_sell" | translate }} -->
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 pl-25 bt">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio pt-10">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 25px">
                  <input type="checkbox" class="pull-left checkbox" [(ngModel)]="res_obj.cta" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: -9%"></span>
                  <img src="assets/img/newstaticicons/inventory/cta.svg" alt="" class="image-116" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-top: -20px; padding-left: 5px">
                    CTA
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12 editable-column p-0" style="background-color: #fff; border-radius: 0px 0px 10px 10px"
          [ngClass]="
            darkMode ? 'card-dark-modal-body_restriction' : ' card-null '
          ">
          <div class="col-md-4 dF br pl-0">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #ms_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.min_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: 8%"></span>
                  <img src="assets/img/newstaticicons/inventory/cta.svg" alt="" class="image-116 m-0" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-bottom: 0px; padding-left: 5px">
                    Maintenance
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #ms_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.min_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!ms_el.checked"
                    [(ngModel)]="res_obj.min_stay.value" min="1" (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-bottom: 0px">
                    Min Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
          <div class="col-md-4 dF br">
            <div class="div-block-drop">
              <label class="radio-button-field_restriction w-clearfix w-radio">
                <label class="control-label radio-style chbx checkbox-style" style="margin-top: 5px">
                  <input #mx_el type="checkbox" class="pull-left checkbox" style="visibility: none; margin: 13% 0%"
                    [(ngModel)]="res_obj.max_stay.allowed" />
                  <span class="checkbox-checkmark" [ngClass]="
                      darkMode
                        ? 'checkbox-checkmark_dark'
                        : 'checkbox-checkmark'
                    " style="margin-top: 8%"></span>
                  <input type="number" class="form-control" [disabled]="!mx_el.checked"
                    [(ngModel)]="res_obj.max_stay.value" min="1" (click)="input_focus($event)" />
                  <p class="radio-button-label w-form-label" [ngClass]="
                      darkMode ? 'card-dark-modal-heding' : ' card-null '
                    " style="margin-bottom: 0px">
                    Max Stay
                  </p>
                </label>
              </label>
            </div>
          </div>
        </div>
        <div class="actions">
          <a class="pull-right btn btn-info btn-hov-save" data-dismiss="modal"
            [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov'">save</a>
        </div>
      </div>
    </div>
  </div>
</div>