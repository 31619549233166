import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
declare var google;
declare var $: any;
import { Observable, of, from, observable } from 'rxjs';

@Component({
  selector: "app-vendor",
  templateUrl: "./vendor.component.html",
  styleUrls: ["./vendor.component.css"],
})
export class VendorComponent implements OnInit {

  darkMode: boolean = false

  add_vendor_form: FormGroup;
  submitted: boolean = false;
  @ViewChild('map', { static: false }) mapElement: ElementRef;
  @ViewChild('searchbar', { static: false }) searchbar: ElementRef;
  map: any;
  address: string;

  addressElement: HTMLInputElement = null;

  latLng = {
    lat: 0,
    lng: 0
  };
  markers = [];
  dropt: boolean = false;

  vendorList = [];
  selectEdit: any;
  trade = [];
  selectTrade: any;

  type = [
    { name: 'In House' },
    { name: 'External' }
  ]

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getVandorList();
    this.getTradeList();
    this.getLocation();
    this.add_vendor_form = this.fb.group({
      admin_id: JSON.parse(localStorage.getItem('user')).id,
      email: ['', Validators.required],
      email_message: true,
      first_name: ['', Validators.required],
      last_name: 'abc',
      phone: ['', Validators.required],
      role: "Vendor",
      password: "123",
      permissions: [],
      address: ['', Validators.required],
      website: "1",
      cell_no: "1",
      company_name: ['', Validators.required],
      trade: [null, Validators.required],
      hourly_rate: ['', Validators.required],
      lat: ['', Validators.required],
      lng: ['', Validators.required],
      vendor_type: [null, Validators.required]
    })
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  get f() { return this.add_vendor_form.controls; }

  chandrop(id) {
    let a = document.getElementById(id)
    a.classList.contains('rotate') ? a.classList.remove('rotate') : a.classList.add('rotate')

  }

  //Get Vendors List
  getVandorList() {
    this.api.post('api/user/vendor_list', { vendor_type: 'External' }, true).subscribe((res: any) => {
      this.vendorList = res.body.data;
    }, err => this.error_handling.handle_error(err.status))
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.loadMap(position.coords.latitude, position.coords.longitude);
        this.placeMarkerAndPanTo(new google.maps.LatLng(position.coords.latitude, position.coords.longitude), this.map);
      });
    }
  }

  loadMap(lat, lng) {
    let latLng = new google.maps.LatLng(lat, lng);
    this.add_vendor_form.patchValue({
      lat: latLng.lat(),
      lng: latLng.lng()
    })
    this.getAddress(latLng.lat(), latLng.lng())
    let mapOptions = {
      center: latLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    setTimeout(() => {
      this.initAutocomplete();
    }, 3000);
    this.map.addListener("click", (e) => {
      this.placeMarkerAndPanTo(e.latLng, this.map);
    });
  }

  //Add Marker
  placeMarkerAndPanTo(latLng, map) {
    this.add_vendor_form.patchValue({
      lat: latLng.lat(),
      lng: latLng.lng()
    });
    this.getAddress(latLng.lat(), latLng.lng());
    this.setMapOnAll(null)
    const marker = new google.maps.Marker({
      position: latLng,
      map: map,
    });
    map.panTo(latLng);
    this.markers.push(marker);
  }

  setMapOnAll(map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  getMapCenter() {
    return this.map.getCenter();
  }

  //Get Address of Selected
  getAddress(lat, lng) {
    let req = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=AIzaSyCk_GW7007XrPNapWIy5Ix7x8YtNlyF3eU';
    $.getJSON(req).then((res) => {
      this.address = res.results[0].formatted_address;
      this.add_vendor_form.patchValue({
        address: res.results[0].formatted_address
      });
    });
  }

  getTradeList() {
    this.api.get('api/helper/get_trades', true).subscribe((res: any) => {
      this.trade = res.body.data;
    }, err => this.error_handling.handle_error(err.status));
  }

  //Save Vendors
  onSubmit() {
    this.submitted = true;
    if (this.add_vendor_form.invalid) {
      return;
    }
    this.api.post('api/user/vendor_store', this.add_vendor_form.value, true).subscribe((res: any) => {
      this.getVandorList();
    }, err => this.error_handling.handle_error(err.status));
  }

  //Edit Vendor
  editSave(first_name, phone, hourly_rate, id) {
    let a = {
      admin_id: JSON.parse(localStorage.getItem('user')).id,
      first_name: first_name,
      phone: phone,
      hourly_rate: hourly_rate
    };

    this.api.post('api/user/update_vendor/' + id, a, true).subscribe((res: any) => {
      this.selectEdit = undefined;
      this.getVandorList();
    }, err => this.error_handling.handle_error(err.status));
  }

  initAutocomplete(): void {
    this.addressElement = <HTMLInputElement>$('#searchbar');
    this.createAutocomplete(this.addressElement[0]).subscribe((location) => {
      setTimeout(() => {
        $('.pac-logo').css({ 'display': 'inline-block', 'z-index': 999999 })
      }, 200)
      this.placeMarkerAndPanTo(location, this.map);
      let latLngObj = { 'lat': location.lat(), 'long': location.lng() };
      this.getAddress(location.lat(), location.lng());
      let options = {
        center: location,
        zoom: 16
      };
      this.map.setOptions(options);
    });
  }

  createAutocomplete(addressEl: HTMLInputElement): Observable<any> {
    const autocomplete = new google.maps.places.Autocomplete(addressEl);
    autocomplete.bindTo('bounds', this.map);
    setTimeout(() => {
      $('.pac-logo').css({ 'display': 'inline-block', 'z-index': 999999 })
    }, 200)
    return new Observable((sub: any) => {
      setTimeout(() => {
        $('.pac-logo').css({ 'display': 'inline-block', 'z-index': 999999 })
      }, 200)
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        setTimeout(() => {
          $('.pac-logo').css({ 'display': 'inline-block', 'z-index': 999999 })
        }, 200)
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          sub.error({
            message: 'Autocomplete returned place with no geometry'
          });
        } else {
          setTimeout(() => {
            $('.pac-logo').css({ 'display': 'inline-block', 'z-index': 999999 })
          }, 200)
          let latLngObj = { 'lat': place.geometry.location.lat(), 'long': place.geometry.location.lng() }
          this.getAddress(place.geometry.location.lat(), place.geometry.location.lng());
          sub.next(place.geometry.location);
        }
      });
    });
  }

}
