// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appID: 'db36769dbdff741d9ad3a80c6659d1b1',
  gKey: '1:187533802766:web:73c93109d1362bbe696b5f',
  config: {
    apiKey: 'AIzaSyDZWcWw2jCVZUtHoBpN1FIoA3_eiNJ7IdE',
    authDomain: 'weather-7dbe3.firebaseapp.com',
    databaseURL: 'https://weather-7dbe3.firebaseio.com',
    projectId: 'weather-7dbe3',
    storageBucket: 'weather-7dbe3.appspot.com',
    messagingSenderId: '187533802766'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
