<!-- <div class="col-md-12 blur-bar" style=" color: #828abf;font-weight: 600;background: linear-gradient(#30319a, #062460);border: none;border-radius: 10px;height: 45px;padding: 15px;"> 3 unread notifications & 2 unread keynotes. Everything else looks smooth ! </div> -->

<div class="col-md-12 zero-padding" [ngClass]="darkMode ? 'darkOn' : ' darkOff'">
    <div class="alert alert-danger breadcrum">
        <a href="" data-dismiss="modal" aria-label="Close" class="btn btn-link">BACK OFFICE</a>
        <!-- <a href="" routerLink="create_rooms" class="btn btn-link ">CREATE ROOMS</a> -->
        <a href="" class="btn btn-link active">PREVIEW</a>
    </div>
</div>

<div class="row" [ngClass]="darkMode ? 'darkOn' : ' darkOff'">
    <div class="col-md-12">
        <div class="col-md-5 zero-padding" style="padding-right: 2px;width: 38%;">

            <!-- <div class="image">
                <img src="{{base_url2}}/app/images/{{p_image}}"/>
            </div>
            <div class="bottom-right scrollmenu">
                <div class="inner" >
                    <img  *ngFor="let img of previewData?.room_name_images" src="{{base_url2}}/app/images/{{img?.image}}" (click)="img_preview(img?.image)"/>
                </div>
            </div>  -->


            <!-- <div id="myCarousel" class="carousel slide" data-ride="carousel">
          
              <ol class="carousel-indicators">
                <li data-target="#myCarousel" *ngFor="let img of previewData?.room_name_images;let subI = index" [ngClass]="subI == 0  ? 'active' : null" attr.data-slide-to="{{subI}}">
                   <img src="{{base_url2}}/app/images/{{img?.image}}" alt="Room Image" class="hovo"> 
                  </li>
              </ol>

                <div class="carousel-inner">

                  <div class="item active"  *ngFor="let img of previewData?.room_name_images;let i = index" [ngClass]="i == 0  ? 'active' : null">
                    <img src="{{base_url2}}/app/images/{{img?.image}}" alt="Los Angeles" style="width:100%;">
                  </div>
            
                  
                </div>
                
                <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                  <span class="glyphicon glyphicon-chevron-left"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#myCarousel" data-slide="next">
                  <span class="glyphicon glyphicon-chevron-right"></span>
                  <span class="sr-only">Next</span>
                </a>

              </div> -->


            <div id="myCarousel" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->

                <ol class="carousel-indicators">
                    <li data-target="#myCarousel" *ngFor="let im of previewData?.room_name_images;let i = index"
                        [ngClass]="i == 0  ? 'active' : null" attr.data-slide-to="{{i}}">
                        <img src="{{base_url2}}/app/images/{{im.image}}" alt="Room Image" class="hovo">
                    </li>
                </ol>

                <!-- Wrapper for slides -->
                <div class="carousel-inner">

                    <div class="item" *ngFor="let im of previewData?.room_name_images;let i = index"
                        [ngClass]="i == 0  ? 'active' : null">
                        <div>
                            <img src="{{base_url2}}/app/images/{{im.image}}" alt="Room Image">
                        </div>
                    </div>

                    <!-- Left and right controls -->
                    <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#myCarousel" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>


        </div>
        <div class="col-md-7 zero-padding" style="padding-left: 3px;width: 61.9%;">
            <div class="card">
                <div class="bar b1">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">Room Type</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.room_type_name}}</div>
                    </div>
                </div>
                <div class="bar b1">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">Display Name</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.display_name}}</div>
                    </div>
                </div>
                <div class="bar b2">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">Bed Type</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.bed_type_name}}</div>
                    </div>
                </div>
                <div class="bar b3">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">No of Bed</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.no_of_beds}}</div>
                    </div>
                </div>
                <div class="bar b4">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">Sleeps</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.adults}} Adults,
                            {{previewData.children}} Children, {{previewData.infants}}Infants </div>
                    </div>
                </div>
                <div class="bar b5">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">No of Rooms</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.room_count}}</div>
                    </div>
                </div>
                <div class="bar b6">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar zero-padding">Room Size</div>
                        <div class="col-md-8 detailBar zero-padding">{{previewData.room_size}} Sq Ft</div>
                    </div>
                </div>
                <div class="bar2 b7">
                    <div class="col-md-12 zero-padding">
                        <div class="col-md-4 headingBar2 zero-padding">Description</div>
                        <div class="col-md-8 detailBar2 zero-padding">
                            <div>{{previewData.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row" [ngClass]="darkMode ? 'darkOn' : ' darkOff'">

    <div class="col-md-12">

        <div class="col-md-12 zero-padding" style="padding-left: 0px" *ngIf="previewData.extraBed">
            <div class="col-md-12 zero-padding red-bar" style="text-align: left;padding: 0px 15px">Extra Bedding</div>
            <div class="col-md-12 zero-padding" style="padding-right: 1px; padding-left: 1px;">
                <div class="card aminity-card" style="padding: 5px 20px;padding-bottom: 0px;">
                    <div class="row mb5"
                        *ngIf="previewData.extrabedding[0]?.quantity != undefined && previewData.extrabedding[0]?.surcharge_type != undefined ">

                        <div class="cribsbar cb1"> Cribs | Infant Beds </div>
                        <div class="cribsbar cb2">{{previewData.extrabedding[0]?.quantity}}</div>
                        <div class="cribsbar cb3" *ngIf="previewData.extrabedding[0]?.surcharge_type != undefined">
                            {{previewData.extrabedding[0]?.surcharge_type}}</div>
                        <div class="cribsbar cb4" *ngIf="previewData.extrabedding[0]?.surcharge_amount != undefined">
                            {{previewData.extrabedding[0]?.surcharge_amount}}</div>
                        <div class="cribsbar cb5"
                            *ngIf="previewData.extrabedding[0]?.surcharge_amount_type != undefined">
                            {{previewData.extrabedding[0]?.surcharge_amount_type}}</div>

                    </div>
                    <div class="row mb5"
                        *ngIf="previewData.extrabedding[1]?.quantity != undefined && previewData.extrabedding[1]?.surcharge_type != undefined">

                        <div class="cribsbar cb6"> Rollaway | Extra beds </div>
                        <div class="cribsbar cb7">{{previewData.extrabedding[1]?.quantity}}</div>
                        <div class="cribsbar cb8" *ngIf="previewData.extrabedding[1]?.surcharge_type != undefined">
                            {{previewData.extrabedding[1]?.surcharge_type}}</div>
                        <div class="cribsbar cb9" *ngIf="previewData.extrabedding[1]?.surcharge_amount != undefined">
                            {{previewData.extrabedding[1]?.surcharge_amount}}</div>
                        <div class="cribsbar cb10"
                            *ngIf="previewData.extrabedding[1]?.surcharge_amount_type != undefined">
                            {{previewData.extrabedding[1]?.surcharge_amount_type}}</div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 zero-padding" style="padding-left: 0px">
            <div class="col-md-12 zero-padding blue-bar1" style="text-align: left;padding: 0px 15px">Aminities</div>
            <!-- <div class="col-md-12 zero-padding">
                            <div class="card aminity-card">
                             <div class="row">
                                <div class="col-md-12 zero-padding">
                                    <div class="col-md-2 zero-padding" *ngFor="let amiC of amenity0">
                                        <div class="col-md-12 amenityHead zero-padding">{{amiC.pTitle}}</div>
                                        <div class="col-md-12 amenityDetail zero-padding" *ngFor="let Cma of amiC.cTitle">{{Cma.name}}</div>
                                    </div>
                                </div>
                             </div> 
                            </div>
                        </div> -->
            <div class="col-md-12 zero-padding" style="padding-right: 1px; padding-left: 2px;">
                <div class="card aminity-card">
                    <div class="row">
                        <div class="col-md-12 zero-padding">
                            <div class="col-md-2 zero-padding" *ngFor="let amiC of amenity0;let i = index">
                                <div class="col-md-12 amenityHead zero-padding"
                                    [ngStyle]="{'color': darkMode ? amenCol[i%amenCol.length] : '#00205b'}">
                                    <!-- <img src="assets/img/create-rooms/{{amiC.pImg}}.png" class="amenityIcn"> -->
                                    <img src="assets/img/create-rooms/{{ amiC.pImg }}.png" class="amenityIcn"
                                        *ngIf="darkMode == false ">
                                    <img src="assets/img/create-rooms/darkmode/{{  amiC.pImg }}.png" class="amenityIcn"
                                        *ngIf="darkMode == true ">
                                    {{amiC.pTitle}}
                                </div>
                                <div class="col-md-12 amenityDetail zero-padding" *ngFor="let Cma of amiC.cTitle">
                                    {{Cma.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 zero-padding" style="padding-right: 0px">
            <!-- <div class="col-md-12 zero-padding red-bar" style="margin-bottom: 0px;text-align: left;padding:0px 15px">Floor Plan</div> -->
            <div class="col-md-12 zero-padding">
                <details style="outline: none" *ngFor="let fd of previewData.floors_data;let floI = index">
                    <summary style="outline: none" (click)="changeCaret1(fd.id)">
                        <div class="gradient-bar " [ngClass]="floI%2 == 0 ? 'bl3' : 'bl2'">
                            <div class="">
                                <div class="col-md-11" style="padding-left: 15px">
                                    <h4 style="font-size: 14px;line-height: 20px;padding-left: 0px">{{fd.name}}</h4>
                                </div>
                                <div class="col-md-1 text-right drpicon"> <i class="fa fa-caret-down down2"
                                        id="{{fd.id}}"></i> </div>
                                <!-- <img src="assets/img/create-rooms/plus-4.png" class="img-responsive assign-plus" height="30px" width="30px" > -->
                            </div>
                        </div>
                    </summary>

                    <div class="card" style="padding-right: 0px!important;padding-bottom: 0px !important">
                        <div class="row" style="padding-left: 20px;padding-right: 20px">
                            <div class="col-md-2 zero-padding wCard" style="padding-right: 5px;"
                                *ngFor="let fn of fd.floors">
                                <div class="col-md-12 zero-padding f-card">
                                    <details style="outline: none" open>
                                        <summary style="outline: none" (click)="changeCaret2(fn.id)">
                                            <div class="col-md-12 zero-padding" style="padding: 0px 5px;outline: none">
                                                <div class="col-md-6 zero-padding f-name"> <span class="fl">FL</span>
                                                    <span class="s-line">|</span> <span
                                                        class="f-nm">{{fn.floor_no}}</span> </div>
                                                <div class="col-md-6 zero-padding text-right" style="margin-top: 2px">
                                                    <i class="fa fa-caret-up down1" id="{{fn.id}}"></i> </div>
                                            </div>
                                        </summary>

                                        <div class="col-md-12 zero-padding main-rooms">
                                            <div class="col-md-12 zero-padding" style="padding: 0px 5px;margin-top: 5px"
                                                *ngFor="let rn of fn.roomnums">
                                                <div class="col-md-12 zero-padding f-name"> <span
                                                        class="r-no">{{rn.room_number}}</span> </div>
                                                <!-- <div class="col-md-6 zero-padding text-right"> <img src="assets/img/hotel-setup/trash.png"> </div> -->
                                            </div>
                                        </div>
                                    </details>
                                </div>
                            </div>

                        </div>
                    </div>
                </details>
            </div>
        </div>
    </div>


</div>


<div class="modal-footer m-foot" style="bottom: -6.3%;;padding: 0px;right: 0px; bottom: -40px !important;"
    [ngClass]="darkMode ? 'darkOn' : ' darkOff'">
    <a class="btn btn-primary btn-lg saveBtn" (click)="onSubmit()">Save</a>
    <a class="btn btn-primary btn-lg saveBtn" routerLink="../create_rooms">Back</a>
    <a routerLink="create_rooms" class="btn btn-danger btn-lg closeBtn" data-dismiss="modal"
        aria-label="Close">Close</a>
</div>