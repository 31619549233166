import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private auth: AuthService,
    private toaster: ToastrService
  ) { }

  handle_error(code, msg = null) {
    if (code == 401) {
      this.auth.logout();
    }
    else if (code == 422) {
      console.log('Please fill all required fields');
    }
    else {
      console.log(`Request Error : ${code}`);
    }
  }
}
