<div *ngIf="message?.type == 'confirm'" id="confirmationModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg mdlg">

        <!-- Modal content-->

        <div class="modal-content mdc" [ngClass]="alert.darkMode ? 'darkpopup' : 'null'">
            <div class="modal-body">
                <!-- <div class="alert alert-info alert-dismissible show" role="alert">
                <strong>{{ message?.title }}</strong>

            </div> -->
                <div class="alert alert-info alert-dismissible show" role="alert"
                    [ngClass]="alert.darkMode ? 'smooth-dark' : ' null '">
                    <strong>{{ message?.title }}</strong>
                    <a href="" class="close" data-dismiss="modal" aria-label="Close">
                        <strong id="cross">&#x2716;</strong>
                    </a>
                </div>


                <a href="" class="close" data-dismiss="modal" aria-label="Close">
                    <strong id="cross">&#x2716;</strong>
                </a>

                <!-- <div class="alert alert-danger text-center"> -->
                <div>
                    <table style="width: 260px; margin-top: 0px;">
                        <tr>
                            <td>
                                <img src="assets/img/alert/error.png" style="padding-right: 8px; width: 35px;">
                            </td>
                            <td>

                                <p class="mess">Deleting this will completely delete this Building & Its Details.</p>
                                <!-- <ul class="alert-list">
                                <li *ngFor="let instruction of message.instructions">{{ instruction }}</li>


                            </ul>                                               -->
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="row">
                    <div class="col-md-12" style="display: flex; justify-content: flex-end;">
                        <!-- <div class="border-style">
                        <div class="element">
                            <label>{{ message?.text }}</label><br>
                            <strong>
                                <div *ngIf="message.permissions"  >
                                    <small>
                                        Permissions : {{ message.permissions }}<br>
                                    </small>
                                </div>
                                <small *ngIf="message.created_on" class="text-danger">Created on {{ message.created_on }}</small>
                            </strong>
                        </div>
                    </div> -->
                        <div>
                            <button type="button" class="btn btn-primary btn-block btn-lg btnp" data-dismiss="modal"
                                (click)="message.siFn()">Yes</button>
                        </div>

                    </div>

                    <!-- <div class="col-md-3 pl-26">
                    <button type="button" class="btn btn-danger btn-block btn-lg" data-dismiss="modal">No</button>
                </div> -->
                </div>

            </div>
        </div>

    </div>
</div>











<!-- style="     margin: 0;position: absolute;top: 55%;-ms-transform: translateY(-50%);transform: translateY(-50%); " -->

<div id="confirmationModal-2" *ngIf="message?.type == 'confirm'" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content" style="background-color: #f1f1f1" [ngClass]="alert.darkMode ? 'darkpopup' : 'null'">

            <div class="modal-header" style="padding-bottom:5px ; border: none;"
                [ngClass]="alert.darkMode ? 'darkpopup' : 'null'">
                <div class="col-md-12">
                    <div class="row">
                        <div class="bluheader booking-sumary">
                            <h3 class="h3_blu_header bookingsummary"><strong>{{ message?.title }}</strong>
                                <a href="" class="close" data-dismiss="modal" aria-label="Close"
                                    style="color:white;margin-top:0px">
                                    <strong>&#x2716;</strong>
                                </a>
                            </h3>

                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row" style="">
                        <div class="confrm-card" [ngClass]="alert.darkMode ? 'card_dark' : ''">
                            <div class="col-md-7 " style="height: 50px; ">
                                <div class="row" style="padding-right: 5px">
                                    <div class="col-md-12 undo-modal">
                                        <div class="row">
                                            <div class="col-md-3 text-right">
                                                <i class="fa fa-exclamation-triangle"></i>
                                            </div>
                                            <div class="col-md-9" style="padding-left: 0px">
                                                <p class="undo" *ngFor="let instruction of message.instructions">{{
                                                    instruction }} </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5" style="height: 50px">
                                <div class="row">
                                    <div class="col-md-6" style="padding-left: 0px; padding-right: 3px"> <button
                                            class="btn btn-md btn-block save-btn" data-dismiss="modal"
                                            (click)="message.siFn()"
                                            [ngClass]="alert.darkMode ? 'btnblue_dark' : 'null'">Yes</button> </div>
                                    <div class="col-md-6" style="padding-left: 2px; padding-right:0px"> <button
                                            class="btn btn-md btn-block close-btn" data-dismiss="modal"
                                            aria-label="Close" [ngClass]="alert.darkMode ? 'btnred_dark' : 'null'">No</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>



        </div>

    </div>
</div>

<div id="confirmationModal-3" *ngIf="message?.type == 'confirm'" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">

        <!-- Modal content-->
        <div class="modal-content" style="background-color: #f1f1f1" [ngClass]="alert.darkMode ? 'darkpopup' : 'null'">

            <div class="modal-header" style="padding-bottom:5px ; border: none;"
                [ngClass]="alert.darkMode ? 'darkpopup' : 'null'">
                <div class="col-md-12">
                    <div class="row">
                        <div class="bluheader booking-sumary">
                            <h3 class="h3_blu_header bookingsummary"><strong>{{ message?.title }}</strong>
                                <a href="" class="close" data-dismiss="modal" aria-label="Close"
                                    style="color:white;margin-top:-5px">
                                    <strong>&#x2716;</strong>
                                </a>
                            </h3>

                        </div>
                    </div>
                </div>

                <div class="col-md-12 p-0">
                    <div class="row">
                        <div class="row">
                            <div class="col-md-12 ">
                                <div class="col-md-12 text-center">
                                    <h4 class="p-3">{{ message.text }}</h4>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="confirmation-row justify-content-around">
                                    <button class="btn btn-sm close-btn" data-dismiss="modal" (click)="message.siFn()"
                                        [ngClass]="alert.darkMode ? 'btnblue_dark' : 'null'">Yes</button>
                                    <button class="btn btn-sm save-btn " data-dismiss="modal" aria-label="Close"
                                        [ngClass]="alert.darkMode ? 'btnred_dark' : 'null'">No</button>
                                </div>
                            </div>
                            <!-- <div class="col-md-7 " style="height: 50px; ">
                                <div class="row" style="padding-right: 5px">
                                    <div class="col-md-12 undo-modal">
                                        <div class="row">
                                            <div class="col-md-3 text-right">
                                                <i class="fa fa-exclamation-triangle"></i>
                                            </div>
                                            <div class="col-md-9" style="padding-left: 0px">
                                                <p class="undo" *ngFor="let instruction of message.instructions">{{ instruction }} </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>-->


                        </div>
                    </div>
                </div>

            </div>



        </div>

    </div>
</div>
