<div class="alert alert-danger breadcrum" [ngClass]=" darkMode ? 'dark_bredcrum' : 'breadcrum'">
    <a class="btn btn-link" routerLink="/app/back_office">BACK OFFICE</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">BILLING</a>
    <a class="btn btn-link active">SUBSCRIPTION</a>
</div>
<div class="row">
    <div id="drag_box" class="col-md-12 flex">
        <div class="col-md-4 pr-5 drag_item">
            <div class="card hov-card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <img src="assets/img/subscriptions/small.png" class="h_img" alt="" srcset="">
                <h1 class="center currency_heading">$ 74.99</h1>
                <h5 class="center channels_heading" style="margin-bottom: 48px;">4 Channels</h5>
                <div class="gradient-bar" [ngClass]=" darkMode ? 'dark_bredcrum' : 'null'">
                    <div class="col-md-12" style="margin-top: 1px;">
                        <h4>Basic Package</h4>
                    </div>
                </div>
                <br />
                <p class="border-style row_cols color-1"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-2"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-3"> Lorem ipsum dolor sit amet</p>
                <br><br>
                <div class="btn-wrapper">
                    <button class="btn btn-primary btn-lg btn-block hov-btn" data-target="#settings" data-toggle="modal"
                        style="padding-top: 8px">Select</button>
                </div>
            </div>
        </div>
        <div class="col-md-4 pr-5 drag_item">
            <div class="card hov-card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <img src="assets/img/subscriptions/medium.png" class="h_img" alt="" srcset="">
                <h1 class="center currency_heading">$ 149.99</h1>
                <h5 class="center channels_heading" style="margin-bottom: 48px;">8 Channels</h5>
                <div class="gradient-bar" [ngClass]=" darkMode ? 'dark_bredcrum' : 'null'">
                    <div class="col-md-12" style="margin-top: 1px;">
                        <h4>Premium Package</h4>
                    </div>
                </div>
                <br />
                <p class="border-style row_cols color-4"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-5"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-6"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-7"> Lorem ipsum dolor sit amet</p>
                <br><br>
                <div class="btn-wrapper">
                    <button class="btn btn-primary btn-lg btn-block modal-btn active-btn hov-btn"
                        data-target="#settings" data-toggle="modal" style="padding-top: 8px">Active Plan</button>
                </div>
            </div>
        </div>
        <div class="col-md-4 drag_item">
            <div class="card hov-card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <img src="assets/img/subscriptions/large.png" class="h_img third" alt="" srcset="">
                <h1 class="center currency_heading">$ 299.99</h1>
                <h5 class="center channels_heading" style="margin-bottom: 48px;">12+ Channels</h5>
                <div class="gradient-bar" [ngClass]=" darkMode ? 'dark_bredcrum' : 'null'">
                    <div class="col-md-12" style="margin-top: 1px;">
                        <h4>Ultimate Package</h4>
                    </div>
                </div>
                <br />
                <p class="border-style row_cols color-8"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-9"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-10"> Lorem ipsum dolor sit amet</p>
                <p class="border-style row_cols color-11"> Lorem ipsum dolor sit amet</p>
                <br><br>
                <div class="btn-wrapper">
                    <button class="btn btn-primary btn-lg btn-block hov-btn" data-target="#settings" data-toggle="modal"
                        style="padding-top: 8px">Select</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="settings" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body" [ngClass]="darkMode ? 'modal_color' : 'null' ">
                <div class="row">
                    <div class="col-md-12">
                        <app-plan-swtich></app-plan-swtich>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>