<!-- <p>event works!</p> -->
<div class="main-div">
    <app-rms-breadcrum></app-rms-breadcrum>

    <div class="col-md-12 blueHedaer">
        <div class="row">
            <mat-tab-group [ngClass]="darkMode ? 'darkTab' : ''">
                <mat-tab class="category" label="All">

                    <!-----------row--1-------->
                    <ng-container *ngFor="let item of evt_orderCard; index as i">
                        <div class="col-md-3 row-1">
                            <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                                <div class="card-body">
                                    <img src="{{item.itemImage}}" alt="category" class="category_img">
                                    <div class="card-text">
                                        <h4 class="card-title">{{item.title}}</h4>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button class="btn btn-danger" (click)="evtOrder(i)">Order Now</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="center">
                        <div class="pagination">
                            <a href="#">&laquo;</a>
                            <a href="#" class="active">1</a>
                            <a href="#">2</a>
                            <a href="#">3</a>
                            <a href="#">4</a>
                            <a href="#">5</a>
                            <a href="#">6</a>
                            <a href="#">&raquo;</a>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab class="category" label="Pizza">

                </mat-tab>
                <mat-tab class="category" label="Burger"> Content 3 </mat-tab>
                <mat-tab class="category" label="Refreshment"> Content 4 </mat-tab>
                <mat-tab class="category" label="Rice"> Content 5 </mat-tab>
                <mat-tab class="category" label="Salad"> Content 6 </mat-tab>
                <div class="search">
                    <img src="../../../../assets/img/back-office/rms-icons/search.svg" />
                    <input type="text" class="form-control date-pick shadow-none" placeholder="Search" />
                </div>
            </mat-tab-group>
            <div class="search"
                style="position: absolute; display: flex;left: 665px;z-index: 1; width: 160px;top: 5px;">
                <img src="../../../../assets/img/back-office/rms-icons/search.svg"
                    style="position: absolute; top: 10px; left: 137px;" />
                <input type="text" class="form-control date-pick shadow-none" placeholder="Search"
                    style="height: 30px;" />

            </div>
        </div>
    </div>

</div>
<div class="col-md-12  col-sm-12 btnSave">
    <button class="btn saveBtn" [ngClass]="darkMode ? 'btnDark' : ''">Save</button>
</div>