<div class="mainheader " style="position: fixed; z-index: 99;background-color: #282B93;">
  <div class="col-md-3 col-sm-3">
    <div class="logo-inline">
      <div class="logo-text"><img class="image-height" src="assets/img/terms_of_use/Asset.png" alt=""></div>
    </div>
  </div>

  <div class="col-md-6 col-sm-6">
    <div class="heading">

    </div>
  </div>

  <div class="terms_of_use_select col-md-3 padding col-sm-3">
    <!-- <ng-select class="selector" [(ngModel)]="language">
                <ng-option value="English" >English</ng-option>
                <ng-option value="German">German</ng-option>
                <ng-option value="French">French</ng-option>
                <ng-option value="Duetch">Duetch</ng-option>
                <ng-option value="Italiano">Italiano</ng-option>
              </ng-select> -->
    <div id="google_translate_element"></div>
  </div>
</div>

<div class="container-fluid no_scroll scroll"
  style="padding: 0;padding: 0;overflow: scroll;height: 100vh;overflow-x: hidden;width: 88.6vw;">
  <div class="row back-color-1">


    <img src="assets/img/terms_of_use/term-full-size.png" alt="" class="image-38" />
    <div class="div-block-15"></div>
    <div class="col-md-12 col-sm-12">
    </div>
  </div>

  <div class="row">
    <div class="" [ngClass]="darkMode ? 'image-card_dark' : 'bg-image'">
      <div class="section-2" [ngClass]="darkMode ? 'sec-1' : 'section-2'">
        <div class="container-3 w-container">
          <!-- <div class="text-block-10 getting-started table bottom-pd cont">Table of Content</div> -->
          <!-- <div class="w-col w-col-2"></div> -->
          <div class="columns-2 w-row">
            <div class="column-4 w-col w-col-4 panel-group" id="terms_of_use">
              <div class="div-block-12">
                <div class="html-embed w-embed">
                  <hr>
                </div>
                <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                <div>
                  <div class="">
                    <div class="dropdown-4 w-dropdown">
                      <div class="dropdown-toggle-4 w-dropdown-toggle">

                        <a data-parent="#terms_of_use" href="main/terms_of_use#termscondition"
                          class="text-block-11">Terms & Condition
                          <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                        </a>
                      </div>
                      <div class="html-embed w-embed">
                        <hr>
                      </div>
                      <!-- <div id="collapse1" class="text panel-collapse collapse ">
                            <a href="#" class="w-dropdown-link">Link 1</a>
                            <a href="#" class="w-dropdown-link">Link 2</a>
                            <a href="#" class="w-dropdown-link">Link 3</a>
                         </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-col w-col-4">
              <div class="div-block-12">
                <div class="html-embed w-embed">
                  <hr>
                </div>
                <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                <div class="" id="terms_of_use">
                  <div class="">
                    <div class="dropdown-4 w-dropdown">
                      <div class="dropdown-toggle-4 w-dropdown-toggle">

                        <a data-parent="#terms_of_use" href="main/terms_of_use#privacypolicy"
                          class="text-block-11">Privacy Policy
                          <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                        </a>
                      </div>
                      <div class="html-embed w-embed">
                        <hr>
                      </div>
                      <!-- <div id="collapse4" class="text panel-collapse collapse ">
                               <a href="main/online_help#policy4" class="w-dropdown-link">Privacy Policy</a>
                               <a [routerLink] = "['/']"  fragment="policy4"> Privacy Policy</a>
                               <a href="#" class="w-dropdown-link">Link 2</a>
                               <a href="#" class="w-dropdown-link">Link 3</a>
                            </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-col w-col-4">
              <div class="div-block-12">
                <div class="html-embed w-embed">
                  <hr>
                </div>
                <div class="div-block-11" [ngClass]="darkMode ? 'div-block-11_dark' : 'null'"></div>
                <div class="" id="terms_of_use">
                  <div class="">
                    <div class="dropdown-4 w-dropdown">
                      <div class="dropdown-toggle-4 w-dropdown-toggle">

                        <a data-parent="#terms_of_use" href="main/terms_of_use#serviceagreement"
                          class="text-block-11">Service Agreement
                          <!-- <div class="icon-4 w-icon-dropdown-toggle"></div> -->
                        </a>
                      </div>
                      <div class="html-embed w-embed">
                        <hr>
                      </div>
                      <!-- <div id="collapse4" class="text panel-collapse collapse ">
                                   <a href="main/online_help#policy4" class="w-dropdown-link">Privacy Policy</a>
                                   <a [routerLink] = "['/']"  fragment="policy4"> Privacy Policy</a>
                                   <a href="#" class="w-dropdown-link">Link 2</a>
                                   <a href="#" class="w-dropdown-link">Link 3</a>
                                </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-termand-condition></app-termand-condition>
      <app-privacy-policy></app-privacy-policy>
      <app-service-agreement></app-service-agreement>


    </div>

  </div>
</div>