import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as echarts from 'echarts';
import * as moment from 'moment';
import { ExpenseTrend } from 'src/app/common/pnl/expense-trend/ExpenseTrendModel';
import { PnlService } from 'src/app/services/pnl.service';

@Component({
  selector: 'app-expenses-trend',
  templateUrl: './expenses-trend.component.html',
  styleUrls: ['./expenses-trend.component.css']
})
export class ExpensesTrendComponent implements OnInit, OnChanges {

  constructor(private pnlService: PnlService) { }
  @Input('choosenDate') choosen_date;
  startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
  endDate = moment().format('YYYY-MM-DD');
  unitsSold: Number[] = [];
  ExpenseTrendsChartData: ExpenseTrend;
  months = [
    { name: 2019 },
    { name: 2020 },
    { name: 2021 },
    { name: 2022 },
    { name: 2023 },
  ];
  darkMode: boolean = false;


  ngOnInit() {
    this.checkDarkMode();
  }

  ngOnChanges() {
    this.getExpenseTrendsChartData()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  getExpenseTrendsChartData() {
    //subtract 12 hours from end date
    this.endDate = moment(this.choosen_date.endDate).subtract(12, 'hours').format('YYYY-MM-DD');
    //subtract 6 months using moment
    this.startDate = moment(this.endDate).subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
    this.pnlService.getExpenseTrendsChart(this.startDate, this.endDate).subscribe((res) => {
      this.ExpenseTrendsChartData = res.data;
      this.expenseTrendChart()
      this.unitsSold = this.ExpenseTrendsChartData.data.map((item) => item.unitsSold)
    })
  }

  expenseTrendChart() {
    // console.log(this.ExpenseTrendsChartData, 'opex chart data');
    var dom = document.getElementById('chart-container');
    let darkMode = this.darkMode;
    var myChart = echarts.init(dom, darkMode ? 'dark' : null, {
      renderer: 'canvas',
      useDirtyRect: false
    });

    var app = {};
    let color = [
      '#FF1443',
      '#56EEF4',
      '#6000F0',
      '#FF2EB6',
      '#32E875',
      '#FFE057',
      '#01F1D0',
      '#F77DCA',
      '#FFF275',
      '#6C8EAD',
      '#E4C1F9',
    ]

    let expense_data = this.ExpenseTrendsChartData.data;
    let months = this.ExpenseTrendsChartData.labels
    let fixedCost = expense_data.map((item) => item.fixedCost)
    let revenue = expense_data.map((item) => item.revenue)
    let occupancy = expense_data.map((item) => item.occupancy)
    let unitsSold = expense_data.map((item) => item.unitsSold)
    let dividedCost = expense_data.map((item) => item.dividedCost)
    let dividedCostLabels = dividedCost[0].map(x => x.label);
    let dividedExpenseMapping = new Map<string, number[]>();

    for (let index = 0; index < dividedCost.length; index++) {
      const monthData = dividedCost[index];
      dividedCostLabels.forEach(label => {
        const dividedExpenseForMonth = monthData.find(x => x.label === label);
        if (dividedExpenseForMonth) {
          if (dividedExpenseMapping.has(label)) {
            dividedExpenseMapping.get(label).push(dividedExpenseForMonth.amount);
          } else {
            dividedExpenseMapping.set(label, [dividedExpenseForMonth.amount]);
          }
        } else {
          if (dividedExpenseMapping.has(label)) {
            dividedExpenseMapping.get(label).push(0);
          } else {
            dividedExpenseMapping.set(label, [0]);
          }
        }
      });
    }

    const dataline = (function () {
      let res = [];
      let len = 0;
      while (len < 12) {
        res = fixedCost;
        len++;
      }
      return res;
    })();

    let seriesData: any[] = [{
      name: 'Fixed Cost',
      type: 'line',
      data: dataline
    }]

    let colorIndex = 0;
    dividedExpenseMapping.forEach((value, key) => {
      seriesData.push({
        name: key,
        type: 'bar',
        stack: 'one',
        emphasis: emphasisStyle,
        data: value,
        itemStyle: { color: color[colorIndex] },
      })
      colorIndex++;
    })


    let markPoint =
      seriesData.push(
        {
          name: 'Revenue',
          type: 'bar',
          stack: 'one',
          emphasis: emphasisStyle,
          data: revenue,
          smooth: true,
          itemStyle: { color: '#4769FF' },

        })

    var emphasisStyle = {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0,0,0,0.3)'
      }
    };

    const arrayUniqueByKey = [...new Map(seriesData.map(item => [item['name'], item])).values()]
    let option = {
      legend: {
        top: "5%",
        left: "10%"
      },
      tooltip: {

        axisPointer: {
          type: "shadow"
        }
      },
      xAxis: [{
        data: months,
        axisLine: {
          // symbol: 'arrow',

        },
        axisTick: {
          alignWithLabel: true
        },
      }, {
        data: unitsSold,
        position: 'bottom',
        offset: 25,
        axisLine: {
          lineStyle: {
            type: 'dashed'
            // ...
          }
        },
        axisTick: {
          alignWithLabel: true
        },
      }],
      yAxis:
      {
        show: true,
        type: 'value',
        splitLine: {
          lineStyle: {
            color: '#fff'
          }
        },

      }
      ,
      series: arrayUniqueByKey,
      backgroundColor: darkMode ? 'none' : '#fff',
    };

    myChart.on('brushSelected', function (params: any) {
      var brushed = [];
      var brushComponent = params.batch[0];
      for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
        var rawIndices = brushComponent.selected[sIdx].dataIndex;
        brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
      }
      myChart.setOption({
        title: {
          backgroundColor: '#333',
          text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
          bottom: 0,
          right: '10%',
          width: 100,
          textStyle: {
            fontSize: 12,
            color: '#fff'
          }
        }
      });
    });

    if (option && typeof option === 'object') {
      myChart.setOption(option);
    }


    window.addEventListener("resize", () => myChart.resize());
  }
}
