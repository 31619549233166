<div class="" id="manageR">


    <!-- <div class="alert alert-info alert-dismissible show" role="alert">

        You've got 3 notifications and 2 keynotes, everything else looks smooth
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <strong id="cross">&#x2716;</strong>
        </button>
    </div> -->

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'breadcrum'">

        <div class="row">
            <div class="col-md-4 breadCW">
                <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">BACK OFFICE</a>
                <a href="" class="btn btn-link active">MANAGE ROOMS</a>
            </div>

            <div class="col-md-8 topBtnBar">
                <div class="col-md-8"
                    style="display: flex; justify-content: center; margin-top: 5px; margin-right: -5px;">
                    <input type="text" class="form-control keyword" placeholder="Search by Keyword"
                        (keyup)="param = 'query'" [(ngModel)]="query" style="box-shadow: none !important;">
                    <button type="button" class="btn btn-primary btn-block btn-lg hov-btn" (click)="param = 'query'"
                        style="height: 40px; width:40px; border-radius: 100%;margin-left: 5px;"
                        [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">Go</button>

                    <div class="col-md-2 create" style="padding-left: 5px;">
                        <a [routerLink]="['/app/back_office/create_rooms']" class="btn btn-danger  btn-lg hov-btn"
                            style="height: 40px; padding: 11px 15px!important;"
                            [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">+ Create New Room</a>
                    </div>

                </div>
            </div>

        </div>

    </div>

    <!-- <div class="card" style="background: #d10a50;">
        <div class="row">
            <div class="col-md-8" style="display: flex;">
                <input type="text" class="form-control keyword" placeholder="Search by Keyword" [(ngModel)]="query" style="box-shadow: none !important;">
                <button type="button" class="btn btn-primary btn-block btn-lg hov-btn" (click)="param = query" style="height: 40px; width:40px; border-radius: 100%;margin-left: 5px;">Go</button>
            </div>
            <div class="col-md-2" style="padding-left: 5px;">
                <button type="button" class="btn btn-primary btn-block btn-lg" (click)="param = query" style="height: 40px">Search</button>
            </div>
            <div class="col-md-2" style="padding-left: 5px;">
                <a [routerLink]="['/app/back_office/create_rooms']"  class="btn btn-danger btn-block btn-lg hov-btn" style="height: 40px">+ Create New Room</a>
            </div>
        </div>
    </div> -->
    <div class="scrolll no_scroll">
        <div class="card " *ngFor="let room of rooms | search:'bedtype,roomtype':param;let i = index"
            style="height:150px;" [ngClass]="darkMode ? 'card_dark' : 'card'">
            <div class="row">
                <div class="col-md-3 cbid1">
                    <!-- <img class="img-responsive" src="assets/img/manage-rooms/Mask Group 63.png"> -->


                    <!-- <img class="room-imgs" src="{{ room.images[0]?.image ? base_url2+'app/images/'+room.images[0].image : 'assets/img/other/no-room.jpg' }}" style="border-radius: 10px;" > -->



                    <div style="width:100%;">

                        <div *ngIf="room.images.length > 0" id="myCarousel{{i}}" class="carousel slide"
                            data-ride="carousel">
                            <!-- Indicators -->


                            <!-- Wrapper for slides -->
                            <div class="carousel-inner abc">

                                <div class="item" *ngFor="let img of room.images; let imgindex = index"
                                    [ngClass]="imgindex == 0 ? 'active' : 'null'" style="height: 140px;">

                                    <img class="room-imgs {{img.image}}" src="{{  base_url2+'app/images/'+img.image }}"
                                        style="border-radius: 10px; height: 100%;">
                                </div>
                            </div>

                            <!-- Left and right controls -->
                            <a class="left carousel-control adjus1" href="#myCarousel{{i}}" data-slide="prev"
                                *ngIf="room.images.length > 1 ">

                                <i class="fas fa-chevron-left ico" style="padding:6px;"></i>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="right carousel-control adjus" href="#myCarousel{{i}}" data-slide="next"
                                *ngIf="room.images.length > 1 ">
                                <i class="fas fa-chevron-right ico" style="padding:9px;"></i>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>

                        <div *ngIf="room.images.length == 0 ">
                            <img class="room-imgs" src="assets/img/other/no-room.jpg" style="border-radius: 10px; ">
                        </div>
                    </div>
                </div>

                <div class="col-md-7 roomdec">
                    <h3 class="card-title">
                        <span [ngStyle]="{'color': darkMode ? room.dark_mode : room.color_code}">
                            {{ room.roomtype }}
                            <span class="room_count totlo2">{{ room.room_count }}
                                <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">Room Count</span>
                            </span>

                        </span>

                        <!-- <span *ngIf="room.is_duplicated=='1'" class="duplicate_text">(Duplicated)</span> -->



                    </h3>
                    <p class="room-content">

                        {{ setDes(room.description) }}
                        <img src="assets/img/manage_rates/dots.png" *ngIf="room.description.split(' ').length > 60 "
                            alt="" style="height: 25px;cursor: pointer;" class="imHov" (mouseover)="desc(i)"
                            (mouseout)="descs(i)">
                    </p>

                    <p class="room-content2" *ngIf="desshow == i" [ngClass]="darkMode ? 'card_dark' : 'null' ">

                        {{ setDes2(room.description) }}
                    </p>


                    <!-- <div class="room-numbers">
                          <div class="rnm" *ngFor="let Rnmbr of room.room_num">{{Rnmbr.room_number}}</div>
                    </div> -->

                    <div class="room-details">
                        <ul class="nav navbar-nav pull-left amiNav">
                            <li>
                                <!-- <a class="text-limit-15"><img height="17px" src="assets/img/newstaticicons/rates-packages/lightmode/bed.svg" class="img_icon_margin" style="    height: 30px;"><span class="disHide">{{ room.bedtype }}</span></a> -->
                                <a class="totlo">
                                    <img class="bedIconic"
                                        src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg">
                                    <span class="text-limit-15">{{ room.bedtype }}</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'"> Bed Type
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a class="totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                                        class="img_icon_margin icon20H"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '18px'}">
                                    <span class="disHide text-limit-15"> {{ room.adults+room.children+room.infants
                                        }}</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'"> Sleeps </span>
                                </a>
                            </li>
                            <li>
                                <a class=" totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                                        class="img_icon_margin icon20H"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '16px'}">
                                    <span class="text-limit-15">{{ room.room_size }} Sq ft</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'"> Room Size
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a class=" totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                                        *ngIf="room?.pets == 'true'"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '17px'}" />
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                                        *ngIf="room?.pets == 'false'"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '17px'}" />
                                    <span class="disHide text-limit-15">
                                        Pets {{ room.pets == 'true' ? 'Allowed' : 'Not Allowed' }}</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'"> Pets </span>
                                </a>
                            </li>
                            <li>
                                <a *ngIf="room.wheelchairaccessibility == 'false'" class="totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                    <!-- Not Available -->
                                    <span class="text-limit-15">Not Available</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">
                                        <!-- Not Available -->
                                        Accessibility
                                    </span>
                                </a>
                                <a *ngIf="room.wheelchairaccessibility == 'true'" class="totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                                        [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                                    <!-- Available -->
                                    <span class="text-limit-15">Available</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">
                                        Accessibility
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a *ngIf="room.smoking == 'false'" class="totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                                        [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}" />
                                    <!-- Not Allowed -->
                                    <span class="text-limit-15">Not Allowed</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">
                                        <!-- Not Allowed  -->
                                        Smoking
                                    </span>
                                </a>
                                <a *ngIf="room.smoking == 'true'" class="totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                                        [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '16px'}" />
                                    <!-- Allowed -->
                                    <span class="text-limit-15">Allowed</span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">
                                        <!-- Allowed  -->
                                        Smoking
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a class=" totlo">
                                    <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/room-icon.svg"
                                        class="img_icon_margin icon20H"
                                        [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '25px'} : {'margin': '-15px 5px -5px 0px','height': '25px'}">
                                    <span class="text-limit-15"
                                        *ngIf="room.display_name == null || room.display_name == ''"
                                        style="overflow: ellipsis; width:100px"> {{ room.roomtype }} </span>
                                    <span class="text-limit-15" *ngIf="room.display_name != null ">{{ room.display_name
                                        }} </span>
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'darktolo' : 'null'">
                                        {{room.display_name}} </span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="col-md-2 lastDiv ">
                    <div class="rightCard" [ngClass]="darkMode ? 'card_left_dark' : 'rightCard' ">

                        <div class="btns">
                            <div class="uper">
                                <div>
                                    <a class="pushBtn">Push</a>
                                </div>
                            </div>
                            <div class="lower">
                                <div class="text-right">
                                    <div class="btn-wrapper">
                                        <a href="javascript:void(0)" class=""
                                            (click)="amenityData(room.with_parent_amenities)"><img width="30px"
                                                src="assets/img/newstaticicons/back-office/amenity-01.svg"
                                                class="ichove" (click)="amenModal('true')"></a>

                                        <a href="javascript:void(0)" class=""
                                            (click)="chnnelpop(room.connected_channels)"><img width="30px"
                                                src="assets/img/newstaticicons/back-office/await.svg" class="ichove"
                                                (click)="closModal('true')"></a>

                                        <a href="javascript:void(0)" class="" (click)="openchModal2(room)"><img
                                                width="30px" src="assets/img/manage-rooms/rooms.svg" class="ichove"></a>

                                        <a routerLink="/app/back_office/create_rooms/{{room.id}}"><img width="30px"
                                                src="assets/img/manage-rooms/edit.svg" class="ichove"
                                                (click)="passVar()"></a>

                                        <a (click)="delete(room.id,room.roomtype)" class="" data-toggle="modal"
                                            data-target="#confirmationModal-2"><img width="30px"
                                                src="assets/img/manage-rooms/delete.svg" class="ichove"></a>
                                    </div>
                                    <!-- <br><br><br> -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" *ngIf="rooms?.length == 0">
            Sorry, no rooms available.
        </div>
        <div class="modal-footer" style="padding: 0px 18px;bottom:-8.5%">
            <a class="btn btn-danger closing btn-lg hov-btn" data-dismiss="modal" [ngClass]="darkMode ? 'btnred_dark' : ' btn-danger' ">Close</a>
        </div>


        <div id="channelspopup" *ngIf="channelShow == true" [ngClass]="darkMode ? 'dark_popup' : 'null' ">
            <div class="alert alert-box alert-dismissible show mb-0 border-bread" id="grab-5"
                (mouseover)="dodrag('grab-5')" (mouseout)="stopDrag('grab-5')" (mouseup)="cGrab('grab-5')"
                (mousedown)="cDGrab('grab-5')" [ngClass]="darkMode ? 'dark_bredcrum' : 'null' ">
                Channels
                <button aria-label="Close" id="cross" class="close hor" type="button" (click)="closModal('false')"
                    style="margin-top: 2px;">
                    <strong>&#x2716;</strong>
                </button>
            </div>
            <div class="scroll">
                <div class=" col-md-6 pl-0 pr-0" *ngIf="externalChannels.length > 0">
                    <label class="allLabel" for="allSelectOtas1">
                        <div class="blueH" [ngClass]="darkMode ? 'btnblue_dark' : 'null' ">Ota</div>
                        <!-- <input class="checkbox" name="booked_date" id="allSelectOtas1" type="checkbox"/>
                  <div class="button red " style="    margin-bottom: 0px;" ></div> -->
                    </label>
                    <ng-container *ngFor="let external of externalChannels">
                        <div class="Loop">
                            <label class="allLabel" for="allSelectOtas0"
                                [ngClass]="darkMode ? 'card_channel_dark' : ''">
                                <div class="blueH1 conon"><img src="assets/img/svgicons/otas/{{external.channel.image}}"
                                        [alt]="external.channel.image" class="image-137 pr-5 pl-5"/></div>
                                <!-- <input class="checkbox" name="booked_date" id="allSelectOtas0" type="checkbox"/>
                                <div class="button red " style="    margin-bottom: 0px;" ></div> -->
                            </label>
                        </div>
                    </ng-container>
                </div>
                <div class=" col-md-6 pl-0 pr-0" *ngIf="internalChannels.length > 0">
                    <label class="allLabel" for="allSelectOtas1">
                        <div class="blueH" [ngClass]="darkMode ? 'btnblue_dark' : 'null' ">Ota</div>
                        <!-- <input class="checkbox" name="booked_date" id="allSelectOtas1" type="checkbox"/>
                  <div class="button red " style="    margin-bottom: 0px;" ></div> -->
                    </label>
                    <ng-container *ngFor="let inetrnal of internalChannels">
                        <div class="Loop">
                            <label class="allLabel" for="allSelectOtas0"
                                [ngClass]="darkMode ? 'card_channel_dark' : ''">
                                <div class="blueH1 conon">
                                    <img src="assets/img/svgicons/channels/{{inetrnal.channel.image}}"
                                        [alt]="inetrnal.channel.image" class="image-137 pr-5 pl-5"/></div>
                                <!-- <input class="checkbox" name="booked_date" id="allSelectOtas0" type="checkbox"/>
                                <div class="button red " style="    margin-bottom: 0px;" ></div> -->
                            </label>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <button type="button" class="form-control save-buton save-chan btn-hov-save" id="closechannel" >
            Save
        </button> -->
        </div>

        <div id="amenityModal" *ngIf="ametinShow == true">
            <div class="alert alert-box alert-dismissible show mb-0 border-bread" style="font-size: 14px;" id="grab-56"
                (mouseover)="dodrag('grab-56')" (mouseout)="stopDrag('grab-56')" (mouseup)="cGrab('grab-56')"
                (mousedown)="cDGrab('grab-56')" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                Amenities
                <button type="button" class="closeBtnAm" id="cross" (click)="amenModal('false')">
                    <strong>✖</strong>
                </button>
            </div>
            <div class="cardBox " [ngClass]="darkMode ? 'dark_popup' : 'null' ">
                <div class="row" style="padding: 0px 15px 0px 20px;">
                    <div class="col-md-6 zero-padding">
                        <div class="col-md-12 AmiCard-C4 ng-star-inserted">

                            <ng-container *ngFor="let ami of amenityByRoom;let pi = index">
                                <div class="cardAmenity" *ngIf="pi%2 == 0"
                                    [ngClass]="darkMode ? 'dark_cardAmenity' : 'cardAmenity' ">
                                    <div class="row">
                                        <div class="col-md-12 pr20">
                                            <div class="line">
                                                <div class="row">
                                                    <div class="col-md-12 amenityColumn12">
                                                        <div class="icoAmeni">
                                                            <img alt="ameniti image" class="amiIcon"
                                                                src="assets/img/{{darkMode ? 'create-rooms/darkmode' : 'create-rooms'}}/{{ami.icon}}">
                                                        </div>
                                                        <div class="nameAmeni">
                                                            <span
                                                                [ngStyle]="{'color': darkMode ? amenColor[pi] : '#00205b'}">
                                                                {{ami.title}}
                                                            </span>
                                                            <a data-toggle="collapse" href="#collapse{{pi}}">
                                                                <img alt="" class="drop"
                                                                    src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                    id="dropDown1">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 AmicChild text panel-collapse collapse in"
                                            id="collapse{{pi}}" *ngIf="ami.children.length > 0">
                                            <p *ngFor="let ch of ami.children;let chI = index">{{ch.title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                    <div class="col-md-6 zero-padding" style="padding: 0px;">
                        <div class="col-md-12 AmiCard-C4 ng-star-inserted">

                            <ng-container *ngFor="let ami of amenityByRoom;let pi = index">
                                <div class="cardAmenity" *ngIf="pi%2 != 0"
                                    [ngClass]="darkMode ? 'dark_cardAmenity' : 'cardAmenity' ">
                                    <div class="row">
                                        <div class="col-md-12 pr20">
                                            <div class="line">
                                                <div class="row">
                                                    <div class="col-md-12 amenityColumn12">
                                                        <div class="icoAmeni">
                                                            <img alt="ameniti image" class="amiIcon"
                                                                src="assets/img/{{darkMode ? 'create-rooms/darkmode' : 'create-rooms'}}/{{ami.icon}}">
                                                        </div>
                                                        <div class="nameAmeni">
                                                            <span
                                                                [ngStyle]="{'color': darkMode ? amenColor[pi] : '#00205b'}">
                                                                {{ami.title}}
                                                            </span>
                                                            <a data-toggle="collapse" href="#collapse{{pi}}">
                                                                <img alt="" class="drop"
                                                                    src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                                    id="dropDown1">
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 AmicChild text panel-collapse collapse in"
                                            id="collapse{{pi}}">
                                            <p *ngFor="let ch of ami.children">{{ch.title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>



<div id="draggable2" *ngIf="showBox2 == true"
    style="position: absolute;z-index: 999999;top: 40%;width: 400px;height: 337px;left: 30%;">

    <div class="scroll" [ngClass]="darkMode ? 'dark_popup' : 'null' ">
        <div class="alert alert-info alert-dismissible show" role="alert"
            style="height: 45px; padding-top: 12px;    border-radius: 10px 10px 0px 0px;cursor: grab;" id="rfId"
            (mouseover)="dodrag('rfId')" (mouseout)="stopDrag('rfId')" (mouseup)="cGrab('rfId')"
            (mousedown)="cDGrab('rfId')" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">

            {{modaldata.roomtype}}
            <button aria-label="Close" class="close hor" id="cross" type="button" (click)="closeModal()">
                <strong>&#x2716;</strong>
            </button>
        </div>
        <div class="row">
            <div class="col-md-12" style="padding: 0px 25px;">
                <div class="row card" style="padding: 0px !important;"
                    *ngFor="let array of modaldata.room_num ; let i = index"
                    [ngClass]="darkMode ? 'card_dark' : 'card'">
                    <div class="col-md-12" style="padding: 0px;">

                        <details class="BuildingDetail" [open]="array.floors?.length > 0">
                            <summary class="show2"
                                style="text-transform: capitalize; display: flex; justify-content: space-between;">
                                {{array.name}}
                                <img src="assets/img/reports/button_down_dark.svg" alt="" class="drp" id="kol{{i}}"
                                    (click)="chandrop('kol'+i)">
                            </summary>
                            <details *ngFor="let floo of array.floors"
                                [ngClass]="floo.roomnums == '' ? 'DNaN' : 'null'">


                                <summary class="showDanger" style="display: flex; justify-content: space-between;"
                                    *ngIf="floo.roomnums != ''" [ngClass]="darkMode ? 'btnred_dark' : 'null'">
                                    FL {{floo.floor_no}}
                                    <img src="assets/img/reports/button_down_dark.svg" alt="" class="drp" id="koli{{i}}"
                                        (click)="chandrop('koli'+i)" x>
                                </summary>

                                <div class="row" style="margin:0px !important;">
                                    <div class="col-md-6" style="padding:0px 5px;" *ngFor="let roo of floo.roomnums">
                                        <p class="card crd" [ngClass]="darkMode ? 'room_dark' : 'null'">
                                            {{roo?.room_number}}</p>
                                    </div>

                                </div>

                            </details>
                        </details>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
