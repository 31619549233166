import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-help',
  templateUrl: './online-help.component.html',
  styleUrls: ['./online-help.component.css']
})



export class OnlineHelpComponent implements OnInit {



  darkMode: boolean = false
  windowScrolled: boolean;

  constructor() { }
  //   @HostListener("window:scroll", []) onWindowScroll() {
  //     // do some stuff here when the window is scrolled
  //     const verticalOffset = window.pageYOffset
  //           || document.documentElement.scrollTop
  //           || document.body.scrollTop || 0;
  //           console.log("ok" )
  //           if(verticalOffset <10){
  //             console.log("ok" )
  //           }
  //           else{
  //             console.log("No")
  //           }
  // }



  language = 'English';
  dropDown;
  ngOnInit() {
    this.checkDarkMode();
    this.scrollFunction()

  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  changeDrop() {
    this.dropDown = !this.dropDown
  }



  chngedrop(id) {
    let a = (<HTMLInputElement>document.getElementById(id))
    if (a.classList.contains('trans180')) {
      a.classList.remove('trans180')
    }
    else {
      a.classList.add('trans180')
    }


  }



  scrollFunction() {
    let topbutton = document.getElementById('mainrow')
    if (topbutton.scrollTop > 20 || topbutton.scrollTop > 20) {
      //  console.log('scroll yes')
    } else {
      //  console.log('scroll no')
    }
  }
}
