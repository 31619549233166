import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../layout.service";
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";
import { NgZone, Renderer2, ElementRef, ViewChild } from '@angular/core'
import { EventsService } from '../../services/events.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {

  darkMode: boolean = false;
  spinner: boolean = false;

  constructor(
    public layout_service: LayoutService,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    public events: EventsService,
    private spinnerService: NgxSpinnerService
  ) {

    router.events.subscribe((event: RouterEvent): void => {
      if (event instanceof NavigationStart) {
        layout_service.spinner = true;
        this.spinner = true;
        this.spinnerService.show('sp6');
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        layout_service.spinner = false;
        this.spinner = false;
        this.spinnerService.hide();
      }
    });

    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    })
  }

  ngOnInit() {
    this.checkDarkMode();
  }


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;

    if (mode == 'dark') {
      this.darkMode = true;
    }
  }



}



