import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-perfomance',
  templateUrl: './perfomance.component.html',
  styleUrls: ['./perfomance.component.css']
})
export class PerfomanceComponent implements OnInit {

  user = JSON.parse(localStorage.getItem('user'));
  Performance = 'false';
  graphDaysP = 'today';
  graphDays = [
    { val: 'today', days: 'Today' },
    { val: 'last_30_days', days: 'This Month' },
    { val: 'last_15_days', days: 'Previous Month' },
  ];
  currency_format = localStorage.getItem('currency');
  darkMode: boolean = false;
  @Input() performanceData_per;

  constructor(
    private toastr: ToastrService,
    public http: HttpClient,
    public weather: DashboardService,
    private error_handling: ErrorHandlingService,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.performanceData_per;
    this.permissions();
    this.checkDarkMode();
  }

  getDashboardData(filter) {
    let dashObj = {
      property_id: localStorage.getItem("current_property"),
      date_departure_from: moment().subtract(1, 'M').format('YYYY-MM-DD'),
      date_departure_to: moment().format('YYYY-MM-DD'),
      performance_filter: filter,
      graphDaysPFrontDesk: filter,
    };

    this.api.post('api/report/dashboard', dashObj, true).subscribe((res: any) => {
      this.performanceData_per = res.body.data.performance
    },
      err => this.error_handling.handle_error(err.status, err.message));
  }

  per(value, total) {
    // let fv = value
    // return fv > 20 ? fv : 50;
    let totlo = total == null ? 100 : total;
    let valo = value == null ? 10 : value
    let tot = valo * 100 / totlo
    return tot > 20 ? tot : tot > 100 ? 100 : tot + 10
    // return tot > 20 ? tot : tot+10
  }

  showerror() {
    this.toastr.error('Access Denied');
  }

  permissions() {
    var data = this.user.permissions;
    for (let i = 0; i < data.length; i++) {

      if (this.user.permissions[i]['app_permission']['name'] == 'Dashboard > Performance') { this.Performance = 'true' }
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
}
