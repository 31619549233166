import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { HomeLayoutComponent } from "./layouts/home-layout/home-layout.component";

import { HeaderComponent } from "./common/header/header.component";
import { FooterComponent } from "./common/footer/footer.component";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { NotFoundComponent } from "./common/not-found/not-found.component";

import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";

import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { AuthGuardService } from "./services/auth-guard.service";
import { BackOfficeComponent } from "./components/back-office/back-office.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { TestComponent } from "./components/test/test.component";
import { AccountSettingsComponent } from "./components/back-office/account-settings/account-settings.component";
import { HotelSetupComponent } from "./components/back-office/hotel-setup/hotel-setup.component";
import { CreateUsersComponent } from "./components/back-office/create-users/create-users.component";
import { ManageUsersComponent } from "./components/back-office/manage-users/manage-users.component";
import { CreateRoomsComponent } from "./components/back-office/create-rooms/create-rooms.component";
import { ManageRoomsComponent } from "./components/back-office/manage-rooms/manage-rooms.component";
import { ToastrModule } from "ngx-toastr";
import { RequestInterceptor } from "./services/requestInterceptor";
import { ConfirmationAlertComponent } from "./common/confirmation-alert/confirmation-alert.component";
import { SearchPipe } from "./common/search.pipe";
import { RecursiveSearchPipe } from "./common/recursivepipe";
import { SortPipe } from "./common/sortpipe";
import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { BillingComponent } from "./components/back-office/billing/billing.component";
import { TermsOfUseComponent } from "./components/back-office/terms-of-use/terms-of-use.component";
import { OnlineHelpComponent } from "./components/back-office/online-help/online-help.component";
import { ManageOtasComponent } from "./components/manage-otas/manage-otas.component";
import { InvoiceComponent } from "./components/back-office/billing/invoice/invoice.component";
import { SubscriptionComponent } from "./components/back-office/billing/subscription/subscription.component";
import { ManageHowYouPayComponent } from "./components/back-office/billing/manage-how-you-pay/manage-how-you-pay.component";
import { PlanSwtichComponent } from "./components/back-office/billing/plan-swtich/plan-swtich.component";
import { ContactSupportComponent } from "./components/back-office/contact-support/contact-support.component";
import { SelectHotelComponent } from "./components/back-office/select-hotel/select-hotel.component";
import { ManageRatesPackagesComponent } from "./components/manage-rates-packages/manage-rates-packages.component";
import { Test2Component } from "./components/test2/test2.component";

import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule } from '@angular/router';
import {
  MatNativeDateModule,
  MatRippleModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";

import { ManageRatesPackagesService } from "./components/manage-rates-packages/manage-rates-packages.service";
import { SetupPackageComponent } from "./components/manage-rates-packages/setup-package/setup-package.component";
import { SetupRateComponent } from "./components/manage-rates-packages/setup-rate/setup-rate.component";
import { SetupRestrictionComponent } from "./components/manage-rates-packages/setup-restriction/setup-restriction.component";
import { LinkOTAComponent } from "./components/manage-rates-packages/link-ota/link-ota.component";
import { PreviewPackageComponent } from "./components/manage-rates-packages/preview-package/preview-package.component";
import { ArrivalsComponent } from "./components/arrivals/arrivals.component";
import { WithoutHeaderComponent } from "./layouts/without-header/without-header.component";
import { InventoryComponent } from "./components/inventory/inventory.component";
import { ReportsComponent } from "./components/reports/reports.component";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { LogComponent } from "./components/log/log.component";
import { HouseKeepingComponent } from "./components/house-keeping/house-keeping.component";
import { TestkhiComponent } from "./components/testkhi/testkhi.component";
import { MatChipsModule } from "@angular/material/chips";
import { CommunicationComponent } from "./components/communication/communication.component";
import { EmailDirectoryComponent } from "./components/communication/email-directory/email-directory.component";
import { EmailTemplateComponent } from "./components/communication/email-template/email-template.component";
import { PreviewRoomsComponent } from "./components/back-office/preview-rooms/preview-rooms.component";
import { VendorComponent } from "./components/back-office/vendor/vendor.component"; // this must add to app module.ts
import { NgxPaginationModule } from "ngx-pagination";

import { RegistrationComponent } from "./components/registration/registration.component";
import { PaynowInvoiceComponent } from "./components/paynow-invoice/paynow-invoice.component";
import { PreviewHotelComponent } from "./components/back-office/preview-hotel/preview-hotel.component";
import { ProfitandlossComponent } from "./components/back-office/profitandloss/profitandloss.component";
import { FoodandbeveragesComponent } from "./components/back-office/foodandbeverages/foodandbeverages.component";
import { CreateShopComponent } from "./components/back-office/foodandbeverages/create-shop/create-shop.component";
import { SetupShopComponent } from "./components/back-office/setup-shop/setup-shop.component";
import { CreateUsersFnbComponent } from "./components/back-office/create-users-fnb/create-users-fnb.component";
import { CreateMenuComponent } from "./components/back-office/create-menu/create-menu.component";
import { ManageShopComponent } from "./components/back-office/manage-shop/manage-shop.component";
import { ManageMenuComponent } from "./components/back-office/manage-menu/manage-menu.component";

// ngx-bootstrap
import { ListItemComponent } from './components/back-office/list-item/list-item.component';
import { SoldItemComponent } from './components/back-office/sold-item/sold-item.component';
import { FinanceComponent } from './components/back-office/finance/finance.component';
import { ExpensesComponent } from './components/back-office/expenses/expenses.component';
import { RoomserviceComponent } from './components/back-office/roomservice/roomservice.component';
import { CafeComponent } from './components/back-office/cafe/cafe.component';
import { RestaurantComponent } from './components/back-office/restaurant/restaurant.component';
import { EventComponent } from './components/back-office/event/event.component';
import { CigarComponent } from './components/back-office/cigar/cigar.component';
import { OrderlistComponent } from './components/back-office/orderlist/orderlist.component';
import { PnlBreadcrumComponent } from './components/back-office/pnl-breadcrum/pnl-breadcrum.component';
import { OperatingExpenseComponent } from './components/back-office/expenses/operating-expense/operating-expense.component';

import { RmsTrayComponent } from './components/back-office/rms-tray/rms-tray.component';
import { PayrollComponent } from './components/back-office/payroll/payroll.component';
import { PayrollBeardcrumComponent } from './components/back-office/payroll-beardcrum/payroll-beardcrum.component';
import { EmployeeListsComponent } from './components/back-office/employee-lists/employee-lists.component';
import { WordLengthPipe } from './common/word-length.pipe';
import { ShortNumberPipe } from './common/short-number.pipe';
import { RmsBreadcrumComponent } from './components/back-office/rms-breadcrum/rms-breadcrum.component';
import { DicountTrackingComponent } from './components/reports/pnl/dicount-tracking/dicount-tracking.component';
import { FinancialPerformanceComponent } from './components/reports/pnl/financial-performance/financial-performance.component';
import { ComparisionRowComponent } from './components/reports/pnl/financial-performance/comparision-row/comparision-row.component';
import { OpexComponent } from './components/reports/pnl/opex/opex.component';
import { UpsellsComponent } from './components/reports/pnl/upsells/upsells.component';
import { RevenueTrendsComponent } from './components/reports/pnl/revenue-trends/revenue-trends.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';
import { ArrivalSkeletonComponent } from './components/arrivals/arrival-skeleton/arrival-skeleton.component';
import { BreakEvenComponent } from './components/reports/pnl/break-even/break-even.component';
import { NgxEchartsModule } from "ngx-echarts";
import { TermandConditionComponent } from './components/back-office/terms-of-use/termand-condition/termand-condition.component';
import { PrivacyPolicyComponent } from './components/back-office/terms-of-use/privacy-policy/privacy-policy.component';
import { ServiceAgreementComponent } from './components/back-office/terms-of-use/service-agreement/service-agreement.component';
import { GetStartComponent } from './components/back-office/online-help/get-start/get-start.component';
import { GlossaryComponent } from './components/back-office/online-help/glossary/glossary.component';
import { MessageComponent } from './components/back-office/contact-support/message/message.component';
import { BookCallComponent } from './components/back-office/contact-support/book-call/book-call.component';
import { OpexVarNFixComponent } from './components/reports/pnl/Opex-var-N-fix/Opex-var-N-fix.component';
import { StaysDashboardComponent } from './components/dashboard/stays-dashboard/stays-dashboard.component';
import { PerfomanceComponent } from './components/dashboard/perfomance/perfomance.component';
import { ReservationComponent } from './components/dashboard/reservation/reservation.component';
import { FrontDeskComponent } from './components/dashboard/front-desk/front-desk.component';
import { WeatherComponent } from './components/dashboard/weather/weather.component';
import { NotificationsComponent } from './components/dashboard/notifications/notifications.component';
import { ExpensesTrendComponent } from './components/reports/pnl/expenses-trend/expenses-trend.component';
import { StayspermissionComponent } from './components/back-office/create-users/stayspermission/stayspermission.component';
import { ArrivalspermissionComponent } from './components/back-office/create-users/arrivalspermission/arrivalspermission.component';
import { PromoPermissionComponent } from './components/back-office/create-users/promo-permission/promo-permission.component';
import { ReportsPermissionComponent } from './components/back-office/create-users/reports-permission/reports-permission.component';
import { CmPermissionComponent } from './components/back-office/create-users/cm-permission/cm-permission.component';
import { BackofficePermissionComponent } from './components/back-office/create-users/backoffice-permission/backoffice-permission.component';
import { DashboardPermissionComponent } from './components/back-office/create-users/dashboard-permission/dashboard-permission.component';
import { HousekeepingPermissionComponent } from './components/back-office/create-users/housekeeping-permission/housekeeping-permission.component';
import { NotificationComponent } from './components/back-office/create-users/notification/notification.component';
import { GuestDataComponent } from './components/back-office/create-users/guest-data/guest-data.component';
import { Select2Module } from "ng-select2-component";
// ^ rate plane compponents
import { SetupSeasonPreviewComponent } from "./components/manage-rates-packages/setup-season-preview/setup-season-preview.component";
import { SetupSeasonChannelsComponent } from "./components/manage-rates-packages/setup-season-channels/setup-season-channels.component";
import { SetupSeasonOtaComponent } from "./components/manage-rates-packages/setup-season-ota/setup-season-ota.component";
import { SetupSeasonPolicyComponent } from "./components/manage-rates-packages/setup-season-policy/setup-season-policy.component";
import { SetupSeasonRateComponent } from "./components/manage-rates-packages/setup-season-rate/setup-season-rate.component";
import { SetupSeasonComponent } from "./components/manage-rates-packages/setup-season/setup-season.component";
import { SetupPackagesComponent } from "./components/manage-rates-packages/setup-packages/setup-packages.component";
import { SetupRatesComponent } from "./components/manage-rates-packages/setup-rates/setup-rates.component";
import { SetupRestrictionsComponent } from "./components/manage-rates-packages/setup-restrictions/setup-restrictions.component";
import { LinkOTASComponent } from "./components/manage-rates-packages/link-otas/link-otas.component";
import { LinkChannelsComponent } from "./components/manage-rates-packages/link-channels/link-channels.component";
import { PreviewPackagesComponent } from "./components/manage-rates-packages/preview-packages/preview-packages.component";
import { PromotionCalenderComponent } from './components/promotion-calender/promotion-calender.component';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
// * promotions components

import { NewInventoryComponent } from './components/new-inventory/new-inventory.component';
import { ReservationModalComponent } from "./components/reservation-modal/reservation-modal.component";
import { InventoryCalenderComponent } from "./components/new-inventory/inventory-calender/inventory-calender.component";
import { NgxSummernoteModule } from "ngx-summernote";
import { SmsServiceService } from "./services/sms-service.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommSkeletonComponent } from "./components/communication/comm-skeleton/comm-skeleton.component";

const DD_MMM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD  MMM  YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    DashboardComponent,
    SidebarComponent,
    ForgotPasswordComponent,
    BackOfficeComponent,
    ResetPasswordComponent,
    TestComponent,
    AccountSettingsComponent,
    HotelSetupComponent,
    CreateUsersComponent,
    ManageUsersComponent,
    CreateRoomsComponent,
    ManageRoomsComponent,
    ConfirmationAlertComponent,
    SearchPipe,
    SortPipe,
    RecursiveSearchPipe,
    BillingComponent,
    TermsOfUseComponent,
    OnlineHelpComponent,
    ManageOtasComponent,
    InvoiceComponent,
    SubscriptionComponent,
    ManageHowYouPayComponent,
    PlanSwtichComponent,
    ContactSupportComponent,
    SelectHotelComponent,
    ManageRatesPackagesComponent,
    Test2Component,
    SetupPackageComponent,
    SetupRateComponent,
    SetupRestrictionComponent,
    LinkOTAComponent,
    PreviewPackageComponent,
    ArrivalsComponent,
    WithoutHeaderComponent,
    InventoryComponent,
    ReportsComponent,
    LogComponent,
    HouseKeepingComponent,
    TestkhiComponent,
    CommunicationComponent,
    EmailDirectoryComponent,
    EmailTemplateComponent,
    PreviewRoomsComponent,
    VendorComponent,
    RegistrationComponent,
    PaynowInvoiceComponent,
    PreviewHotelComponent,
    ProfitandlossComponent,
    FoodandbeveragesComponent,
    CreateShopComponent,
    SetupShopComponent,
    CreateUsersFnbComponent,
    CreateMenuComponent,
    ManageShopComponent,
    ManageMenuComponent,
    ListItemComponent,
    SoldItemComponent,
    FinanceComponent,
    ExpensesComponent,
    RoomserviceComponent,
    CafeComponent,
    RestaurantComponent,
    EventComponent,
    CigarComponent,
    OrderlistComponent,
    PnlBreadcrumComponent,
    OperatingExpenseComponent,
    RmsTrayComponent,
    PayrollComponent,
    PayrollBeardcrumComponent,
    EmployeeListsComponent,
    WordLengthPipe,
    ShortNumberPipe,
    RmsBreadcrumComponent,
    DicountTrackingComponent,
    FinancialPerformanceComponent,
    ComparisionRowComponent,
    OpexComponent,
    UpsellsComponent,
    RevenueTrendsComponent,
    PrintInvoiceComponent,
    ArrivalSkeletonComponent,
    BreakEvenComponent,
    TermandConditionComponent,
    ServiceAgreementComponent,
    GetStartComponent,
    GlossaryComponent,
    MessageComponent,
    BookCallComponent,
    OpexVarNFixComponent,
    StaysDashboardComponent,
    PerfomanceComponent,
    ReservationComponent,
    FrontDeskComponent,
    WeatherComponent,
    NotificationsComponent,
    ExpensesTrendComponent,
    StayspermissionComponent,
    ArrivalspermissionComponent,
    PromoPermissionComponent,
    ReportsPermissionComponent,
    CmPermissionComponent,
    BackofficePermissionComponent,
    DashboardPermissionComponent,
    HousekeepingPermissionComponent,
    NotificationComponent,
    GuestDataComponent,
    // ^ rate plane compponents
    SetupSeasonComponent,
    SetupSeasonRateComponent,
    SetupSeasonPolicyComponent,
    SetupSeasonOtaComponent,
    SetupSeasonChannelsComponent,
    SetupSeasonPreviewComponent,
    // * promotions compponents
    SetupPackagesComponent,
    SetupRatesComponent,
    SetupRestrictionsComponent,
    LinkOTASComponent,
    LinkChannelsComponent,
    PreviewPackagesComponent,
    PromotionCalenderComponent,
    NewInventoryComponent,
    ReservationModalComponent,
    InventoryCalenderComponent,
    PrivacyPolicyComponent,
    CommSkeletonComponent
  ],
  imports: [
    CommonModule,
    NgxSummernoteModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatChipsModule,
    AppRoutingModule,
    DragDropModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTabsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-center",
      preventDuplicates: true,
    }),
    NgxDaterangepickerMd.forRoot(),
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    ScrollingModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgxEchartsModule,
    Select2Module,
    MatProgressSpinnerModule,
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApiService,
    AuthService,
    SmsServiceService,
    ManageRatesPackagesService,
    AuthGuardService,
    HomeLayoutComponent,
    WithoutHeaderComponent,
    RequestInterceptor,
    DecimalPipe,
    CurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    SearchPipe,
    RecursiveSearchPipe,
    SortPipe,
    ConfirmationAlertComponent,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DD_MMM_YYYY_Format },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
