<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum': 'breadcrum'">
    <a class="btn btn-link" routerLink="/app/back_office">BACK OFFICE</a>
    <a class="btn btn-link" routerLink="/app/back_office/billing">BILLING</a>
    <a class="btn btn-link active">MANAGE HOW YOU PAY</a>
</div>

<div id="drag-list">

    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Credit Card</h1>
                <img class="img-responsive" src="assets/img/manage_how_you_pay/american_express.jpg" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px; border-radius: 4px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/visa_new.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px; border-radius: 4px;">
                <img class="img-responsive border-radius: 4px;" src="assets/img/manage_how_you_pay/discover.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px; border-radius: 4px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/master_new.png" style="border-radius: 0px; padding-right: 0px;
                margin-right: 10px; border-radius: 4px;">
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec1">
                Description
                </h3> -->
                <div class="border-style description-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use Credit Card to make a payment . Make sure your Credit Card is activated .
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Debit Card

                    <!-- <span class="sp_class">US Customers only</span> -->

                </h1>
                <!-- <p>US Customers only</p> -->
                <img class="img-responsive" src="assets/img/manage_how_you_pay/visa_electron.png" style="border-radius: 0px; padding-right: 0px;
                    margin-right: 10px; border-radius: 4px;">
                <img class="img-responsive" src="assets/img/manage_how_you_pay/maestro.png" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px;">

                <img class="img-responsive" src="assets/img/manage_how_you_pay/dbc2.svg" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px; border-radius: 4px;">

                <img class="img-responsive" src="assets/img/manage_how_you_pay/td_bank.svg" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px; border-radius: 4px;">

            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec2">
                    Description
                    </h3> -->
                <div class="border-style debit-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use Debit Card to make a payment . Make sure your Debit Card is activated .
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block  hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Electronic Fund Transfer
                    <!-- <span class="sp_class">ACH or Wire</span> -->
                </h1>
                <img class="img-responsive" src="assets/img/manage_how_you_pay/etf.svg" style="border-radius: 3px; padding-right: 0px;
                    margin-right: 10px; border-radius: 4px;">
                <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/stripe.png" style="border-radius: 10px;">                    -->
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec3">
                        Description
                    </h3> -->
                <div class="border-style electronic-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use a Electronic Fund Transfer to make a payment . Make sure your account is activated
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block  hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Pay with Stripe</h1>
                <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/home.png" style="border-radius: 10px;"> -->
                <img class="img-responsive" src="assets/img/manage_how_you_pay/stripe_new.png" style="border-radius: 0px; padding-right: 0px;
                    margin-right: 10px;  border-radius: 4px;">
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec4">
                        Description
                    </h3> -->
                <div class="border-style stripe-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use Stripe to make a payment . Make sure your Stripe account is activated
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block  hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Pay with Paypal</h1>
                <img class="img-responsive" src="assets/img/manage_how_you_pay/paypal.png" style="border-radius: 6px; padding-right: 0px;
                    margin-right: 10px;">
                <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/square.png" style="border-radius: 10px;"> -->
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec5">
                        Description
                    </h3> -->
                <div class="border-style paypal-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use Paypal to make a payment . Make sure your Paypal account is activated
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block  hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
        <div class="row">
            <div class="col-md-3">
                <h1 class="blue_heading">Pay with Square</h1>
                <!-- <img class="img-responsive" src="assets/img/manage_how_you_pay/paypal.png" style="border-radius: 10px;"> -->
                <img class="img-responsive" src="assets/img/manage_how_you_pay/square_day.svg" style="width: 103px;
                padding-right: 0px;
                margin-right: 10px;
                height: 25px;
                margin-top: 4px;" *ngIf="darkMode == false ">

                <img class="img-responsive" src="assets/img/manage_how_you_pay/darkmode/square_dark.svg" style="width: 103px;
                padding-right: 0px;
                margin-right: 10px;
                height: 25px;
                margin-top: 4px;" *ngIf="darkMode == true">
            </div>
            <div class="col-md-6">
                <!-- <h3 class="card-title description dec6">
                    Description
                </h3> -->
                <div class="border-style square-shade" style="margin-top: 10px;">
                    <div class="element">
                        You can use Square to make a payment . Make sure your Square account is activated .
                    </div>
                </div>
            </div>
            <div class="col-md-3 active-padding">
                <div class="text-right text-padding">
                    <div class="btn-wrapper">
                        <button class="btn btn-primary btn-lg btn-block  hov-btn">Activate</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>