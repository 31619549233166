<div [ngClass]="[service.is_preview == true ? 'padd5' : 'padd0']" id="SP" class="preview-package">
  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close"> RATES & PACKAGES</a>
    <a href="" class="btn btn-link active" data-dismiss="modal" aria-label="Close" [routerLink]="setup_rate">
      PREVIEW PROMOTION</a>
  </div>
  <div class="no_scroll scroll" *ngIf="package_data">
    <div class="white_box channels preview_box w-clearfix" *ngIf="package_data"
      [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '">
      <div class="col-md-12">
        <div class="row" style="padding: 0px 10px">
          <div class="col-md-2 roomcard zero-padding" style="width: 35%;">
            <div class="col-md-12 zero-padding room-image" style="padding-right: 0px !important">
              <div class="col-md-7 zero-padding promo-name" style="width: 54%;">
                <div class="nmD">
                  <h3 class="package_title pkg-heading" data-placement="top" data-toggle="tooltip"
                    title="{{ package_data?.package_name }}">{{ package_data?.package_name }}</h3>
                  <div>
                    <span class="nights">
                      <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old':'/night'}}.svg" alt=""
                        class="im-mr" style="margin-top: 0px;margin-left: 1px;float: left;margin-right: 5px;" />
                      {{ nights }} Nights
                    </span>
                  </div>
                  <h4 class="validity" *ngIf="package_dates != undefined" style="padding: 0px;">
                    {{ package_dates.start | date:"MMM dd" | uppercase }} | {{package_dates.end | date:"MMM dd" |
                    uppercase}}
                  </h4>
                </div>
              </div>
              <div class="col-md-5 zero-padding" style="width: 45%;">
                <div class="image-00">
                  <div style="overflow: hidden;border-radius: 10px;">
                    <img src="{{ preview_image }}" alt="" style="border-radius: 10px; margin-right: 5px;"
                      class="image-14 pointImg" data-toggle="modal" data-target="#imgModal"
                      (click)="showImgModal(package_data?.room.images)">
                    <div class="imageDots">
                      <ng-container *ngFor="let a of package_data?.room.images | slice:0:4">
                        <i class="fa fa-circle" data-toggle="modal" data-target="#imgModal"
                          (click)="showImgModal(package_data?.room.images)"></i>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 zero-padding descript wd2">
            <!-- <h3 class="package_title descrption">{{ 'rates_and_packages.preview_package.description' | translate }}</h3> -->
            <h3 class="package_title descrption">{{ package_data.roomttype.name}}</h3>

            <div>
              <div class="description_text p-b">
                {{ descripArray(package_data.room.description) }}
                <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()"
                  (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;"
                  *ngIf="service.is_preview == false && package_data?.description.split(' ').length > 30">
                <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()"
                  (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;"
                  *ngIf="service.is_preview == true && package_data?.description.split(' ').length > 52">
              </div>
              <div class=" description_text p-b" *ngIf="desT" [ngClass]="darkMode ? 'tooldes' : 'tooldeslight'">
                <p>{{ descripArray2(package_data.room.description) }}</p>
              </div>
            </div>
            <div class="bt" style="clear: both; display: flex; ">
              <div class="totlo" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                  alt="{{ package_data?.room?.bedtype?.name }}" class="image-adj"
                  [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '28px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo1">
                  <p style="margin: 0px;">{{ package_data?.room?.bedtype?.name }}</p>
                </span>
              </div>
              <!-- <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
                *ngIf="service.is_preview == true" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
                {{ package_data?.room?.bedtype?.name }}
              </span> -->
              <div class="totlo" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                  alt="" class="image-adj"
                  [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '21px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo2">
                  <p style="margin: 0px;"> Sleeps {{
                    package_data?.room?.adults + package_data?.room?.children }}</p>
                </span>
              </div>
              <!-- <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
                *ngIf="service.is_preview == true" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
                 {{ package_data?.room?.adults +
                package_data?.room?.children }}
              </span> -->
              <div class="totlo" (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'square_feet'"
                  [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'meters'"
                  [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo3">
                  <p style="    margin: 0px;">{{ package_data?.room?.room_size }} {{package_data?.room?.room_size_type
                    == 'square_feet' ? 'm' : 'Sqft' }}</p>
                </span>
              </div>
              <!-- <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']"
                *ngIf="package_data?.room?.room_size_type == 'square_feet' && service.is_preview == true"
                (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
                {{ package_data?.room?.room_size }} Sqft
              </span> -->
              <!-- <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']"
                *ngIf="package_data?.room?.room_size_type == 'meters' && service.is_preview == true"
                (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
                {{ package_data?.room?.room_size }} m
              </span> -->

              <div class="totlo" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'false'"
                  [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'true'"
                  [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo4">
                  <p style="    margin: 0px;">
                  {{ package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}</p>
                </span>
              </div>
              <!-- <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
                *ngIf="service.is_preview == true" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
                {{ package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}
              </span> -->
              <div class="totlo" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'false'"
                  [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '20px'}">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'true'"
                  [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '18px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo5">
                  <p style="    margin: 0px;">{{ package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}
                  </p>
                </span>
              </div>
              <!-- <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
                *ngIf="service.is_preview == true" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
                {{ package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}
              </span> -->
              <div class="totlo" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'false'"
                  [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
                <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                  alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'true'"
                  [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo6">
                  <p style="margin: 0px;">{{ package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}</p>
                </span>
              </div>
              <!-- <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
                *ngIf="service.is_preview == true" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
                {{ package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}
              </span> -->
              <div class="totlo" (mouseover)="mouo('tolo7')" (mouseout)="mouout('tolo7')">
                <img
                  src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/room-icon.svg"
                  alt="" class="image-adj"
                  [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '23px'}">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'" id="tolo7">
                  <p style="margin: 0px;">{{ package_data?.room?.display_name }}</p>
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-3 zero-padding d-flex wd30">
            <div class="season-box2 color-change" style="width:55%;"
              [ngClass]="darkMode ? 'card-darkPackageBox': ' card-null'">
              <div class="new" id="n1" style="height: 100%">
                <div class="d-flex flex-column justify-content-between h-100">
                  <div class="d-flex justify-content-between align-items-center">
                    <img src="assets/img/newstaticicons/rates-packages/promotion.svg" width="90px" alt="promotion image"
                      class="promoTionImg" />
                    <div class="d-flex align-items-center">
                      <p class="pr-2" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
                        {{ getRoundPrice(package_data.sum_price, package_data.season_rates)}}
                      </p>
                      <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt=""
                        *ngIf="getRoundPrice(package_data.sum_price , package_data.season_rates).length>0 && getRoundPrice(package_data.sum_price , package_data.season_rates).charAt(0)!=='-'">
                    </div>
                  </div>
                  <div style="margin-top: 7px">
                    <span class="breakfast">
                      {{package_data.room.adults}} Adults <span>/ {{package_data.min_stay}} nights min stay</span>
                    </span>
                    <div class="py-1" style="font-size: 14px; color: #707070">
                      <span class="price {{ colors[i % 7] }}" *ngIf="dateFunc2(package_data.date_from).diff(dateFunc2(package_data.date_to),
                          'days' ) >= 1" style="font-size: 16px !important">
                        {{currency_format}}{{roundPricee(package_data?.sum_price /
                        dateFunc2(package_data.date_from).diff(dateFunc2(package_data.date_to),
                        "days")) | number:'1.2-3'}}
                      </span>
                      <span class="price {{ colors[i % 7] }}" *ngIf="dateFunc2(package_data.date_from).diff(
                          dateFunc2(package_data.date_to), 'days') == 0 " style="font-size: 15px !important">
                        {{currency_format}}{{ package_data?.sum_price | number:'1.2-3'}}
                      </span>
                      <b>/ per night</b>
                    </div>
                    <span class="breakfast">
                      <i class="fa fa-utensils"></i> Breakfast {{package_data.breakfast_include == 'Yes' ? '' : 'Not'}}
                      Included
                    </span>
                    <!-- <span class="breakfast"
                      *ngIf="package_data.breakfast_include == 'Paid' ||  package_data.breakfast_include == 'Exclude'">
                      <i class="fa fa-utensils"></i> Breakfast Not Included
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="season-box2" style="width: 50%" [ngClass]="darkMode ? 'card-dark-side-season': ' card-null '">
              <div class="new" style="height: 100%">
                <div class="col-md-12 zero-padding my-col" style="padding: 0px !important;height:23%;">
                  <div class="col-md-12 zero-padding d-flex justify-content-between align-items-center"
                    [ngClass]="[package_data?.breakfast_include=='Complimentry' ? 'm_b_r' : 'm_b_r2']">
                    <span class="total "
                      style="font-size: 14px;font-weight: bold;text-transform:uppercase;font-family: robotoblack;color: #00a721">Total</span>
                    <span class="linked">
                      {{ dateFunc(package_data.date_to) | date: "MMM dd" | uppercase }}
                      | {{ dateFunc(package_data.date_from) | date:"MMM dd" | uppercase }}
                    </span>
                  </div>
                </div>
                <div class="col-md-12 zero-padding" style="color: #707070">
                  <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old':'/night'}}.svg"
                    style="width: 17px;margin: 0 5px 2px 0;" />
                  <b>{{ dateFunc2(package_data.date_from).diff(dateFunc2(package_data.date_to), "days")}}</b>
                </div>
                <div class="col-md-12 zero-padding pt-2" style="padding-left: 0px !important;">
                  <!-- <div style="font-size: 14px;color: #707070;">
                    <b>{{((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)), "days"))}}</b>
                    <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old':'/night'}}.svg"
                    style="width: 15px; margin-left: 5px" />
                  </div> -->
                  <span class="tax_cl"> {{
                    package_data?.season_rates | currency:current_currency}}</span>
                  <br>
                  <span class="price">{{package_data?.sum_price | currency:current_currency}} </span>
                  <div class="breakfast pt-2">
                    +{{current_currency}}{{calculteTaxAmount(package_data?.season_rates) | currency:current_currency :
                    'symbol':'1.2-3'}}
                    taxes
                  </div>
                </div>
                <!-- <div class="col-md-12 zero-padding">
                  <span class="breakfast" *ngIf="package_data?.breakfast_include=='Complimentry'">Breakfast Included.</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aminity-Section">
      <div class="gradient-bar adj" *ngIf="package_data " [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
        <h4 class="h4">Amenities</h4>
      </div>

      <div class="card aminity-card" style="padding:0px 10px !important;"
        [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '" *ngIf="package_data ">
        <div class="row" style="padding: 0px 10px !important">
          <div class="col-md-12 zero-padding"
            style="padding: 5px 5px 10px 5px !important; display: flex; justify-content: center;">
            <div class=" zero-padding" style="width: 15.6%;"
              *ngFor="let amiC of package_data?.room.with_parent_amenities;let i = index">
              <div class="col-md-12 amenityHead zero-padding"
                [ngStyle]="{'color': darkMode ? amenCol[i%amenCol.length] : '#00205b'}">
                <img src="assets/img/create-rooms/{{amiC.icon}}"
                  style="height: 20px;margin-top: -5px; margin-right: 5px;width: 20px;" *ngIf="darkMode == false">
                <img src=" assets/img/create-rooms/darkmode/{{amiC.icon}}"
                  style="height: 20px;margin-top: -5px; margin-right: 5px;width: 20px;" *ngIf="darkMode == true">
                {{amiC.title}}
              </div>
              <div class="col-md-12 amenityDetail zero-padding" style="padding-left: 38px !important;"
                *ngFor="let Cma of amiC.children">{{Cma.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="otas">
      <div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
        <div class="col-md-10 zero-padding">
          <h4 *ngIf="package_data.push_status == 'pushed'">Connected OTAs</h4>
          <h4 *ngIf="package_data.push_status == 'pending'" style="padding: 0px 20px;">Selected OTAs &nbsp;
            <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
              <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                <p style="margin: 0px;">When you push the package, this will change into CONNECTED</p>
              </span>
            </i>
          </h4>
        </div>
      </div>
      <div class="white_box channels w-clearfix fixes" *ngIf="package_data"
        [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
        <div class="col-md-12" style="    padding-left: 1px !important;">
          <div class="col-md-3 csw" *ngFor="let ota of externals">
            <div class="channel_container {{ ota.chan.channel.name }} w-clearfix"
              style="display: flex;align-items: center;" *ngIf="package_data.promo_allocations.length >= 1"
              [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
              <img
                src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota.chan.channel_image : ota.chan.channel_image }}"
                alt="" class="tripadvisor ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
            </div>
            <div class="channel_container {{ ota.channel.name }} w-clearfix" style="display: flex;align-items: center;"
              *ngIf="package_data.promo_allocations.length < 1"
              [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
              <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota.channel.image : ota.channel.image}}"
                alt="" class="tripadvisor ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
            </div>
          </div>
          <h4 *ngIf="otas?.length == 0" class="no-margin"
            style="padding-bottom: 10px; font-weight: 600; padding-left: 5px;">No OTAs Connected</h4>
        </div>
      </div>
      <div class="channels">
        <div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
          <div class="col-md-10 zero-padding">
            <h4 *ngIf="package_data.push_status == 'pushed'">Connected Channels</h4>
            <h4 *ngIf="package_data.push_status == 'pending'" style="padding: 0px 20px;">Selected Channels &nbsp;
              <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
                <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'tooltiptext'">
                  <p style="margin: 0px;">When you push the package, this will change into CONNECTED</p>
                </span>
              </i>
            </h4>
          </div>
        </div>
        <div class="white_box channels w-clearfix fixes" *ngIf="package_data"
          [ngClass]="darkMode ? 'card-dark-side' : ' card-null '" style="margin-bottom: 0px;">
          <div class="col-md-12" style="    padding-left: 1px !important;">
            <div class="col-md-3 csw" *ngFor="let ota of internals">
              <div class="channel_container {{ ota.chan.channel.name }} w-clearfix"
                style="display: flex; align-items: center;" *ngIf="package_data.promo_allocations.length >= 1"
                [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
                <img src="assets/img/svgicons/channels/{{ ota.chan.channel_image }}" alt=""
                  class="tripadvisor  ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']"
                  *ngIf="ota.prom.promo_code == null">
                <div class="img0" *ngIf="ota.prom.promo_code != null" style="width: 100%">
                  <div class="image000">
                    <img src="assets/img/svgicons/channels/{{ ota.chan.channel_image }}" alt=""
                      class="tripadvisor  ota-icon" [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']"
                      style="transform: translateY(20%);">
                  </div>
                  <div class="col-md-12">
                    <div class="null" [ngClass]="[service.is_preview == true ? 'promo2' : 'promo']">
                      <div class="promotion" [ngClass]="[service.is_preview == true ? 'promotion' : 'promotion2']">
                        Promo : {{ota.prom.promo_code}} |
                        {{ota.prom.counter}} </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="channel_container {{ ota.channel.name }} w-clearfix"
                style="display: flex; align-items: center;" *ngIf="package_data.promo_allocations.length < 1"
                [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card '">
                <img src="assets/img/svgicons/channels/{{ ota.channel.image }}" alt="" class="tripadvisor ota-icon"
                  [ngClass]="[service.is_preview == true ? 'otasP' : 'otasPo']">
              </div>
            </div>
            <h4 *ngIf="otas?.length == 0" class="no-margin"
              style="padding-bottom: 10px; font-weight: 600; padding-left: 5px;">No Channels Connected</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer oneM" *ngIf="service.is_preview == false" id="ModalFooterP"
        style="bottom: -50px;right: 10px!important">
        <a class="btn btn-danger btn-lg btn-hov" type="button" data-dismiss="modal" aria-label="Close"
          [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">Close</a>
      </div>
      <div class="modal-footer twoM" *ngIf="service.is_preview == true" style="right: 10px!important;bottom: -50px;">
        <a class="btn btn-danger btn-lg btn-hov" type="button" data-dismiss="modal" aria-label="Close"
          [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">Close</a>
      </div>
    </div>
  </div>
  <div class="row  card" *ngIf="!package_data" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
    <div class="col-md-12">
      <h3 style="margin: 0px;">No Preview Available</h3>
    </div>
  </div>
  <div *ngIf="showImg" class="lightHouse">
    <div id="myCarousel" class="carousel slide" data-ride="carousel">
      <button type="button" (click)="hideImg()" class="lightHouse-btn"><i class="fa fa-times"></i></button>
      <ol class="carousel-indicators">
        <li data-target="#myCarousel" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null"
          attr.data-slide-to="{{i}}">
          <img [src]="api.imgUrl+im.image" alt="Room Image" class="hovo">
        </li>
      </ol>
      <div class="carousel-inner">
        <div class="item" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null">
          <div><img [src]="api.imgUrl+im.image" alt="Room Image"></div>
        </div>
        <a class="left carousel-control" href="#myCarousel" data-slide="prev"> <span
            class="glyphicon glyphicon-chevron-left"></span> <span class="sr-only">Previous</span> </a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next"> <span
            class="glyphicon glyphicon-chevron-right"></span> <span class="sr-only">Next</span> </a>
      </div>
    </div>
  </div>
</div>
