import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-create-users-fnb',
  templateUrl: './create-users-fnb.component.html',
  styleUrls: ['./create-users-fnb.component.css']
})
export class CreateUsersFnbComponent implements OnInit {

  public userForm: FormGroup;
  fname: string = "";
  lname: string = "";
  email: string = "";
  phone: string = "";
  role: string = "";
  shopNameValue: string = "";
  userDetails: any[] = [];
  editMode = false;
  viewMode = false;
  editUserId;
  shop_name;
  darkMode: boolean = false;

  constructor(private fb: FormBuilder) {
    this.userForm = this.fb.group({
      fname: new FormControl(null, Validators.required),
      lname: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, Validators.required),
      role: new FormControl(null, Validators.required),
      shopname: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    this.checkDarkMode();
  }



  selectRole = [
    { name: 'Event Planner' },
    { name: 'Executive Cheif' },
    { name: 'Management' },
    { name: 'HouseKeeper' },
    { name: 'Waitrer' },
  ];

  shopName = [
    { name: 'Cafe' },
    { name: 'Room Service' },
    { name: 'Resturant' },
    { name: 'Event' },
  ];


  saveUserData() {
    if (this.editMode) {
      console.log('this is edit mode');
    } else if (this.viewMode) {
      console.log('this is view mode');
    }
    else {
      this.userDetails.push({
        fname: this.userForm.get('fname').value,
        lname: this.userForm.get('lname').value,
        email: this.userForm.get('email').value,
        phone: this.userForm.get('phone').value,
        role: this.userForm.get('role').value,
        shopname: this.userForm.get('shopname').value,
      });
    }
    console.log(this.userDetails);
    // console.log(this.userDetails);
    this.editMode = false;
    this.viewMode = false;
    this.userForm.reset();
  }
  onViewUserData(uId: any) {
    this.editUserId = uId;
    console.log(this.userDetails[uId]);
    this.userForm.setValue({
      fname: this.userDetails[uId].fname,
      lname: this.userDetails[uId].lname,
      email: this.userDetails[uId].email,
      phone: this.userDetails[uId].phone,
      role: this.userDetails[uId].role.name,
      shopname: this.userDetails[uId].shopname.length.name,
    });

    this.editMode = false;
    this.viewMode = true;
  }
  onEditUserData(uId: any) {
    this.editUserId = uId;
    console.log(this.userDetails[uId]);
    this.userForm.setValue({
      fname: this.userDetails[uId].fname,
      lname: this.userDetails[uId].lname,
      email: this.userDetails[uId].email,
      phone: this.userDetails[uId].phone,
      role: this.userDetails[uId].role.name,
      shopname: this.userDetails[uId].shopname.name,
    })
    this.editMode = true;

  }
  onDeleteUserData(u: any) {
    this.userDetails.splice(u, 1);
  }
  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
