import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { RmsService } from 'src/app/services/rms.service';

@Component({
  selector: 'app-roomservice',
  templateUrl: './roomservice.component.html',
  styleUrls: ['./roomservice.component.css']
})
export class RoomserviceComponent implements OnInit {
  // roomCardItems: Subject<any> = new Subject();
  darkMode: boolean = false;

  constructor(
    private rmsService: RmsService
  ) { }

  roomorderCard: Array<any> =
    [
      {
        id: '1',
        itemImage: "../../../../assets/img/back-office/rms_img/cheese-pizza.png",
        title: 'Cheese Pizza',
        count: 0,
        price: 850
      },
      {
        id: '2',
        itemImage: "../../../../assets/img/back-office/rms_img/mutton-biryani.png",
        title: 'Mutton Biryani',
        count: 0,
        price: 550
      },
      {
        id: '3',
        itemImage: "../../../../assets/img/back-office/rms_img/chicken-nihari.png",
        title: 'Chicken Nihari',
        count: 0,
        price: 650
      },
      {
        id: '4',
        itemImage: "../../../../assets/img/back-office/rms_img/hanger-burger.png",
        title: 'Hangry Burger',
        count: 0,
        price: 600
      },
      {
        id: '5',
        itemImage: "../../../../assets/img/back-office/rms_img/pasta.png",
        title: 'Pasta',
        count: 0,
        price: 750
      },
      {
        id: '6',
        itemImage: "../../../../assets/img/back-office/rms_img/lasagne.png",
        title: 'Cheese lasagne',
        count: 0,
        price: 650
      },
      {
        id: '7',
        itemImage: "../../../../assets/img/back-office/rms_img/arabic-salad.png",
        title: 'Arabic Salad',
        count: 0,
        price: 550
      },
      {
        id: '8',
        itemImage: "../../../../assets/img/back-office/rms_img/rasmalai.png",
        title: 'Rasmalai',
        count: 0,
        price: 200
      },
      {
        id: '9',
        itemImage: "../../../../assets/img/back-office/rms_img/spaghetti.png",
        title: 'Spaghetti Pasta',
        count: 0,
        price: 750
      },
      {
        id: '10',
        itemImage: "../../../../assets/img/back-office/rms_img/beef-burger.png",
        title: 'Beef Burger',
        count: 0,
        price: 350
      },
      {
        id: '11',
        itemImage: "../../../../assets/img/back-office/rms_img/veg-roll.png",
        title: 'Vegetable Roll',
        count: 0,
        price: 250
      },
      {
        id: '12',
        itemImage: "../../../../assets/img/back-office/rms_img/chicken-biryani.png",
        title: 'Chicken Biryani',
        count: 0,
        price: 500
      }

    ]
  ngOnInit() {
    this.checkDarkMode();
  }
  roomorder(index: any) {
    this.rmsService.addOrderItem(this.roomorderCard[index])
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
