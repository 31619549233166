<div class="sidebar" [ngClass]="{'sidebar-short': collapse}" id="sider">

    <div class="profile-box" [ngClass]="darkMode ? 'card-dark-bar' : 'null'">
        <div class="text-center">
            <!-- <img src="{{ user?.profile_picture ? 'http://cm.com/cmlocal/public/app/images/' + user?.profile_picture : 'assets/img/nav/user.png' }}" class="img-circle profile-img"> -->
            <!-- <h3 class="title no-margin"><span>{{ user?.first_name }} {{ user?.last_name }}</span></h3>
      <p class="sub-title no-margin"><strong>{{ user?.role ? user.role : 'Unknown' }}</strong></p> -->
            <img src="{{ logo != 'null' && logo != null ? base_url2+'/app/images/' + logo : 'assets/img/nav/logo.png' }}"
                id="brandLogo" class="img-circle profile-img">
        </div>
    </div>

    <ul class="nav bg-blue" [ngClass]="darkMode ? 'darkUl' : 'null'">
        <li class="fordark">
            <a href="" routerLink="/app/dashboard" routerLinkActive="active" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_1.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/dashboard.svg">
                <span>Dashboard</span>
            </a>
        </li>
        <li class="fordark">
            <a *ngIf="user.role != 'SuperAdmin' &&  Inventory == 'false'" style="cursor: no-drop;" (click)="showerror()"
                class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
                <span>Stays</span>
            </a>

            <a href="" *ngIf="user.role != 'SuperAdmin' &&  Inventory == 'true'" routerLink="/app/stays"
                class="flex_nav {{isActiveTab()}}">
                <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
                <span>Stays</span>
            </a>

            <a href="" *ngIf="user.role == 'SuperAdmin'" routerLink="/app/stays" class="flex_nav {{isActiveTab()}}">
                <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
                <span>Stays</span>
            </a>


        </li>
        <li>
          <a *ngIf="user.role != 'SuperAdmin' &&  Inventory == 'false'" style="cursor: no-drop;" (click)="showerror()" class="flex_nav">
            <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
            <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
            <span>Inventory</span>
          </a>
          <a href="" *ngIf="user.role != 'SuperAdmin' &&  Inventory == 'true'" routerLink="/app/new-inventory" routerLinkActive="active"  class="flex_nav">
              <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
              <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
              <span>Inventory</span>
          </a>
          <a href="" *ngIf="user.role == 'SuperAdmin'" routerLink="/app/new-inventory" routerLinkActive="active"class="flex_nav {{router.url == '/app/new-inventory' ? 'active' : ''}}">
              <!-- <img class="img-responsive" src="assets/img/nav/_2.png"> -->
              <img class="img-responsive" src="assets/img/newstaticicons/sidebar/inventory.svg">
              <span>Inventory</span>
          </a>
        </li>
        <li class="fordark">
            <a *ngIf="user.role != 'SuperAdmin' && Arrivals == 'false'" style="cursor: no-drop;" (click)="showerror()"
                class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_3.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg">
                <span>Arrivals</span>
            </a>
            <a href="" *ngIf="user.role != 'SuperAdmin' && Arrivals == 'true'" routerLink="/app/arrivals"
                routerLinkActive="active" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_3.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg">
                <span>Arrivals</span>
            </a>

            <a href="" *ngIf="user.role == 'SuperAdmin'" routerLink="/app/arrivals" routerLinkActive="active"
                class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_3.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/arrivals.svg">
                <span>Arrivals</span>
            </a>
        </li>

        <!-- Rates_Packages == 'true -->

        <li class="fordark">

            <a href="" *ngIf="user.role != 'SuperAdmin' && Rates_Packages == 'true' " routerLink="/app/rates_packages"
                routerLinkActive="active" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_4.png"> -->

                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>Promotions</span>
            </a>

            <a href="" *ngIf="user.role == 'SuperAdmin' " routerLink="/app/rates_packages" routerLinkActive="active"
                class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_4.png"> -->

                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>Promotions</span>
            </a>

            <a *ngIf="user.role != 'SuperAdmin'  && Rates_Packages == 'false'" style="cursor: no-drop;"
                (click)="showerror()" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_4.png"> -->

                <img class="img-responsive"
                    src="assets/img/newstaticicons/sidebar/{{router.url === '/app/rates_packages' ? 'rate-pack-green' : 'rate-pack-red'}}.svg">
                <span>Promotions</span>
            </a>

        </li>


        <!-- <li class="fordark">
      <a   *ngIf="user.role != 'SuperAdmin' && House_Keeping == 'false'"  style="cursor: no-drop;"   (click)="showerror()" class="flex_nav">
        <img class="img-responsive" src="assets/img/nav/house-icon.png">
        <span>House Keeping</span>
      </a>
      <a href=""  *ngIf="user.role != 'SuperAdmin' && House_Keeping == 'true'"  routerLink="/app/housekeeping" routerLinkActive="active" class="flex_nav">
        <img class="img-responsive" src="assets/img/nav/house-icon.png">
        <span>House Keeping</span>
      </a>

      <a href=""  *ngIf="user.role == 'SuperAdmin'"  routerLink="/app/housekeeping" routerLinkActive="active" class="flex_nav">
        <img class="img-responsive" src="assets/img/nav/house-icon.png">
        <span>House Keeping</span>
      </a>

    </li> -->
        <!-- <li class="fordark">
      <a href=""  *ngIf="user.role == 'SuperAdmin'" routerLink="/app/communication/emaildirectory" routerLinkActive="active" class="flex_nav">
        <img class="img-responsive" src="assets/img/nav/message-icon.png">
        <span>Communication</span>
      </a>
      <a   *ngIf="user.role != 'SuperAdmin'"  style="cursor: no-drop;"   (click)="showerror()"  class="flex_nav">
        <img class="img-responsive" src="assets/img/nav/message-icon.png">
        <span>Communication</span>
      </a>
    </li> -->

        <li class="fordark">
            <a *ngIf="user.role != 'SuperAdmin' && Reports == 'false'" style="cursor: no-drop;" (click)="showerror()"
                class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_6.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/reports.svg">
                <span>Reports</span>
            </a>


            <a href="" *ngIf="user.role == 'SuperAdmin' || Reports == 'true' " routerLink="/app/reports_analytics"
                routerLinkActive="active" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_6.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/reports.svg">
                <span>Reports</span>
            </a>
        </li>



        <li class="fordark">


            <!-- logo for cgsl (local site) -->
            <a href="https://cm.cgslhcm.com/" *ngIf="user.role == 'SuperAdmin'" routerLinkActive="active"
                class="flex_nav">

                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/pms.svg">
                <span>Channel Manager</span>
            </a>

            <!-- logo for pointclickstay (foreign site) -->
            <!-- <a href="https://cm.pointclickstay.com/" *ngIf="user.role == 'SuperAdmin'"  routerLinkActive="active" class="flex_nav">

        <img class="img-responsive" src="assets/img/newstaticicons/sidebar/pms.svg">
        <span>Channel Manager</span>
      </a> -->

            <a *ngIf="user.role != 'SuperAdmin' " style="cursor: no-drop;" (click)="showerror()" class="flex_nav">

                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/pms.svg">
                <span>Channel Manager</span>
            </a>
        </li>




        <li class="fordark">
            <!-- Back_Office -->
            <a href="" *ngIf="user.role != 'SuperAdmin' && Back_Office == 'true'" [routerLink]="['/app/back_office']"
                routerLinkActive="active" class="flex_nav">

                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg">

                <span>Back Office </span>
            </a>

            <a href="" *ngIf="user.role == 'SuperAdmin' " routerLink="/app/back_office" routerLinkActive="active"
                class="flex_nav {{router.url.includes('terms_of_use') || router.url.includes('online_help') ? 'active' : '' }}">
                <!-- <img class="img-responsive" src="assets/img/nav/_8.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg">

                <span>Back Office </span>
            </a>
            <a *ngIf="user.role != 'SuperAdmin'  && Back_Office == 'false'" routerLinkActive="active"
                (click)="showerror()" style="cursor: no-drop;" class="flex_nav">
                <!-- <img class="img-responsive" src="assets/img/nav/_8.png"> -->
                <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg">

                <span>Back Office </span>
            </a>

        </li>

        <!-- <li class="forDark">
      <a href="" class="back_off" [routerLink]="['/app/back_office']" routerLinkActive="active" [ngClass]="{'active': tasksActive.isActive || settingsActive.isActive }">
        <img class="img-responsive" src="assets/img/newstaticicons/sidebar/back-office.svg" />
        <span>{{ "sidebar.back_office" | translate }}</span>
      </a>

      <a routerLink="/main" routerLinkActive #tasksActive="routerLinkActive" style="display: none"></a>
      <a routerLink="/app/back_office" routerLinkActive #settingsActive="routerLinkActive" style="display: none"></a>
    </li> -->





        <li class="fordark">
            <a href="" (click)="logout()">
                <img class="img-responsive" src="assets/img/nav/_9.png">
                <span>Logout</span>
            </a>
        </li>


        <li class="bg-white" [ngClass]="darkMode ? 'card-dark-bar' : 'null'">
            <a (click)="do_collapse()" class="text-right">
                <img *ngIf="!collapse" src="assets/img/nav/arrow_left.png" class="collapse-arrow">
                <img *ngIf="collapse" src="assets/img/nav/arrow_right.png" class="collapse-arrow">
            </a>
        </li>



    </ul>

</div>