<div style="padding-right: 0px;" id="prevDiv">
  <!-- <div class="alert alert-info alert-dismissible show" role="alert" [ngClass]="darkMode ? 'alert-info-dark' : ' card-null '">
    {{ service.is_preview == true ? "Preview Event" : "Create Internal Package" }}
    <button type="button" class="close op-adj" data-dismiss="modal" aria-label="Close">
      <strong id="cross">&#x2716;</strong>
    </button>
  </div> -->
  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">INTERNAL PROMOTION</a>
    <a href="" class="btn btn-link active" data-dismiss="modal" aria-label="Close" routerLink="/app/rates_packages/setup_rate">PREVIEW
      PROMOTION
    </a>
  </div>
  <div class="white_box channels preview_box w-clearfix" *ngIf="package_data"
    [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '">
    <div class="col-md-12">
      <div class="row" style="padding: 0px 10px;display: flex;align-items: center;">
        <div class="col-md-4 zero-padding mainImgWi" style="padding-right: 0px !important;">
          <div class="col-md-12 zero-padding" style="padding-right: 0px !important;    padding-left: 0px !important;">
            <div class="col-md-7 zero-padding" style="padding-left: 0px !important;width: 43%;">
              <div class="nmD">
                <h3 class="package_title pkg-heading" data-placement="top" data-toggle="tooltip" title="Test package">
                  {{package_data?.package_name}}
                </h3>
                <!-- <div>
                        <span style="display: block; padding-bottom: 7px;"><img src="assets/img/dashboard/nights_1nights.png" alt=""
                            class="im-mr" style="margin-top: 4px;margin-left: 1px;float: left;margin-right: 5px;" />
                            {{ nights }} Nights
                        </span>
                      </div> -->
                <h4 class="validity">
                  {{ package_dates?.start | date }} | {{package_dates?.end | date}}
                </h4>
              </div>
            </div>
            <div class="col-md-5 zero-padding" style="width: 57%;">
              <div class="image-00">
                <div style="overflow: hidden;border-radius: 10px;">
                  <img alt="" class="image-14 pointImg"
                    (click)="showImgModal(package_data?.room.images);showdata = true"
                    style="border-radius: 10px; margin-right: 5px;" data-toggle="modal" data-target="#imgModal"
                    src="{{preview_image}}">
                  <div class="imageDots">
                    <ng-container *ngFor="let a of package_data?.room.images | slice:0:4">
                      <i class="fa fa-circle" data-toggle="modal" data-target="#imgModal"
                        (click)="showImgModal(package_data?.room.images);showdata = true"></i>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 zero-padding descript col-md-5-width">
          <h3 class="package_title descrption">Description</h3>
          <div>
            <div class="description_text p-b">
              {{ descripArray(package_data?.description) }}
              <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()"
                (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;"
                *ngIf="service.is_preview == false && package_data?.description.split(' ').length > 30">
              <img src="assets/img/manage_rates/dots.png" class="dota" (mouseover)="seeDescription()"
                (mouseout)="seeDescription()" style="height: 25px;cursor: pointer;"
                *ngIf="service.is_preview == true && package_data?.description.split(' ').length > 52">
            </div>
            <div class=" description_text p-b" *ngIf="desT" [ngClass]="darkMode ? 'tooldes' : 'tooldeslight'">
              <p>
                {{ descripArray2(package_data?.description) }}
              </p>
            </div>
          </div>
          <div class="bt" style="clear: both; display: flex; justify-content: center;">
            <div class="totlo" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                alt="{{ package_data?.room?.bedtype?.name }}" class="image-adj"
                [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '28px'}">
              <span class="tooltiptext" id="tolo1" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="    margin: 0px;">
                  {{ package_data?.room?.bedtype?.name }}
                </p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
              *ngIf="service.is_preview == true" (mouseover)="mouo('tolo1')" (mouseout)="mouout('tolo1')">
              {{ package_data?.room?.bedtype?.name }}
            </span>
            <div class="totlo" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                alt="" class="image-adj"
                [ngStyle]="darkMode ? {'margin' : '-3px 0px -5px 0px', 'height': '36px'} : {'height': '21px'}">
              <span class="tooltiptext" id="tolo2" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="    margin: 0px;">
                  Sleeps {{ package_data?.room?.adults + package_data?.room?.children }}
                </p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
              *ngIf="service.is_preview == true" (mouseover)="mouo('tolo2')" (mouseout)="mouout('tolo2')">
              sleeps {{ package_data?.room?.adults + package_data?.room?.children }}
            </span>
            <div class="totlo" (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'square_feet'"
                [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.room_size_type == 'meters'"
                [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '19px'}">
              <span class="tooltiptext" id="tolo3" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="    margin: 0px;">
                  {{ package_data?.room?.room_size }} {{package_data?.room?.room_size_type == 'square_feet' ? 'm' :
                  'sqft' }}
                </p>
              </span>
            </div>
            <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']"
              *ngIf="package_data?.room?.room_size_type == 'square_feet' && service.is_preview == true"
              (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              {{ package_data?.room?.room_size }} {{ 'sqft' }}
            </span>
            <span class="sp-pd limit2 text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right ']"
              *ngIf="package_data?.room?.room_size_type == 'meters' && service.is_preview == true"
              (mouseover)="mouo('tolo3')" (mouseout)="mouout('tolo3')">
              {{ package_data?.room?.room_size }} m
            </span>
            <div class="totlo" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'false'"
                [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.wheelchairaccessibility == 'true'"
                [ngStyle]="darkMode ? {'margin' : '-5px 0px -5px 0px', 'height': '36px'} : {'height': '23px'}">
              <span class="tooltiptext" id="tolo4" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="margin: 0px;">
                  {{ package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}
                </p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
              *ngIf="service.is_preview == true" (mouseover)="mouo('tolo4')" (mouseout)="mouout('tolo4')">
              {{ package_data?.room?.wheelchairaccessibility == 'false' ? 'Not Available' : 'Available' }}
            </span>
            <div class="totlo" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'false'"
                [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '20px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.smoking == 'true'"
                [ngStyle]="darkMode ? {'margin' : '-6px 0px -5px 0px', 'height': '36px'} : {'height': '18px'}">
              <span class="tooltiptext" id="tolo5" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="    margin: 0px;">
                  {{ package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}
                </p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
              *ngIf="service.is_preview == true" (mouseover)="mouo('tolo5')" (mouseout)="mouout('tolo5')">
              {{ package_data?.room?.smoking == 'false' ? 'Not Allowed' : 'Allowed' }}
            </span>
            <div class="totlo" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'false'"
                [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
              <img src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                alt="" class="image-adj" *ngIf="package_data?.room?.pets == 'true'"
                [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px', 'height': '43px'} : {'height': '18px'}">
              <span class="tooltiptext" id="tolo6" [ngClass]="darkMode ? 'tooltiptextdark' : ' card-null '">
                <p style="    margin: 0px;">
                  {{ package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}
                </p>
              </span>
            </div>
            <span class="sp-pd text-limit-15 " [ngClass]="[service.is_preview == true ? 'null' : 'p-right']"
              *ngIf="service.is_preview == true" (mouseover)="mouo('tolo6')" (mouseout)="mouout('tolo6')">
              {{ package_data?.room?.pets == 'false' ? 'Not Allowed' : 'Allowed' }}
            </span>
          </div>
        </div>
        <div class="col-md-3 zero-padding null over-width-style">
          <div class="season-box2 onoone card-null width-popup"
            [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
            <div style="text-indent: 10px; padding-top: 7px;">
              <!-- <div class="pkg-off" *ngIf="percentage != 0">{{percentage}}% Off</div> -->
              <!-- <div class="" style="font-size: 16px;color: #707070;padding-top: 70px;">
                <b>{{((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)), "days"))}}</b>
                <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}" style="margin-right: 10px;width: 20px"/>
              </div> -->
              <!-- <div style="color: #707070;"><span style="font-size: 20px;color: red;">Free {{package_data?.zero_price}}</span> Nights</div> -->
              <div style="color: red;">
                <img src="assets/img/newstaticicons/rates-packages/promotion.svg"
                  style="width: 90px; position: absolute;top: 4px;left: 4px;">
              </div>
              <span class="breakfast" *ngIf=" package_data?.breakfast_include == 'Complimentry'">
                <i class="fa fa-utensils"></i> Breakfast Included
              </span>
              <span class="breakfast" *ngIf=" package_data?.breakfast_include == 'Paid'">
                <i class="fa fa-utensils"></i> Breakfast Not Included
              </span>
            </div>
            <div class="DicsDiv">
              <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt="">
              <p [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{ roundPrice(package_data.sum_price , seasonRoomPrice) }}%
              </p>
              <!-- <img src="assets/img/newstaticicons/rates-packages/discount.svg" alt=""
              *ngIf="getRoundPrice(package_data.sum_price , package_data.seasonRoomPrice).length>0 && getRoundPrice(package_data.sum_price , package_data.seasonRoomPrice).charAt(0)!=='-'">
            <p class="" [ngClass]="darkMode ? 'disCPDa' : 'disCP'">
              {{ getRoundPrice(package_data.sum_price , package_data.seasonRoomPrice)}}
            </p> -->
            </div>
          </div>
          <!-- <div   class="season-box2 onoone  card-null width-popup">
            <div   class="new">
              <div   class="top-cal">
                <div   class="txtt">
                  <div   class="weekprice"> $ 120 </div>
                </div>
              </div>
              <div class="days-1">
                <span class="days-name" *ngFor="let wn of checkDays(package_data.week_day)" [ngStyle]="{'opacity': wn.status ? '1' : '0.3' }">
                  {{nameChar(wn.name)}}
                </span>
              </div>
              <hr class="myhr2">
              <div class="days-1">
                <span class="days-name" *ngFor="let wn of checkDays(package_data.weekend_day)" [ngStyle]="{'opacity': wn.status ? '1' : '0.3' }">
                  {{nameChar(wn.name)}}
                </span>
              </div>
              <div class="weekprice" style="margin-top: 8px">
                $ {{package_data?.weekend_days_rate}}
              </div>
            </div>
          </div> -->
          <div class="season-box2  card-null" style="width: 44.5%;overflow-y: hidden;"
            [ngClass]="darkMode ? 'card-darkPackageBox' : ' card-null '">
            <div class="new" style="height: 120px">
              <div class="col-md-12 zero-padding my-col" style="padding: 0px !important">
                <div class="col-md-12 zero-padding m_b_r2">
                  <span class="total "
                    style="font-size: 16px;font-weight: bold;text-transform: capitalize;font-family: robotoblack;color: #00a721">Total</span>
                </div>
                <!-- <div class="" style="font-size: 16px;color: #707070;">
                  <b>{{((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)), "days"))}}</b>
                  <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}" style="margin-right: 10px;width: 20px"/>
                </div> -->
                <div class="col-md-12 zero-padding null">
                  <b style="font-size: 14px;color: #707070;">{{((dateFunc2(package_data.date_from)).diff((dateFunc2(package_data.date_to)),
                    "days"))}}</b>
                  <img src="assets/img/newstaticicons/inventory{{darkMode ? '/night-old.svg':'/night.svg'}}"
                    style="margin-left: 5px;width: 15px" /><br>
                  <span [ngClass]="darkMode ? 'lineytextdark' : 'lineytext'">${{seasonRoomPrice}}</span>
                  <span class="price">${{package_data?.sum_price}}</span><br>
                  <span class="tax_cl"> Include Tax</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="gradient-bar adj" *ngIf="package_data " [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <h4 class="h4">Prices</h4>
  </div>

  <div class="card aminity-card" style="padding:0px 10px !important;" [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '" *ngIf="package_data ">
		<div class="row" style="padding: 0px 10px !important">
			<div class="col-md-12 zero-padding" style="padding: 5px 5px 10px 5px !important;">
				<div class="col-md-2 zero-padding" style="width: 100%;">
					<div class="border-style color2 b2 new-bar" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" *ngFor="let dp of package_data.prices; let i = index;" [ngStyle]="{'border-color': amenCol[i]}">
            <div class="text-center permission-wrapper fs-9">
               <label class="control-label checkbox-style">
                  Date: {{dp.date_day | date}} | Price: ${{dp.price}}
              </label>
            </div>
         </div>
				</div>
      </div>
		</div>
  </div> -->

  <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <h4>
      Amenities
    </h4>
  </div>
  <div class="card aminity-card" style="padding:0px 10px !important;"
    [ngClass]="darkMode ? 'card-dark-side00' : ' card-null '" *ngIf="package_data ">
    <div class="row" style="padding: 0px 10px !important">
      <div class="col-md-12 zero-padding" style="padding: 5px 5px 10px 5px !important;">
        <div class="col-md-2 zero-padding" *ngFor="let amiC of package_data?.room.with_parent_amenities;let i = index">
          <div class="col-md-12 amenityHead zero-padding"
            [ngStyle]="{'color': darkMode ? amenCol[i%amenCol.length] : '#00205b'}">
            <!-- <img src="assets/img/create-rooms/{{amiC.icon}}" style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;"> -->
            <img src="assets/img/create-rooms/{{amiC.icon}}"
              style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;" *ngIf="darkMode == false">
            <img src=" assets/img/create-rooms/darkmode/{{amiC.icon}}"
              style="height: 25px;margin-top: -5px; margin-right: 5px;width: 25px;" *ngIf="darkMode == true">
            {{amiC.title}}
          </div>
          <div class="col-md-12 amenityDetail zero-padding" style="padding-left: 38px !important;"
            *ngFor="let Cma of amiC.children">
            {{Cma.title}}
          </div>
        </div>
        <h3 *ngIf="otas?.length == 0" class="no-margin"
          style="padding-bottom: 10px; font-weight: 600; padding-left: 5px;">
          No Channel Connected
        </h3>
      </div>
    </div>
  </div>
  <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <div class="col-md-10 zero-padding">
      <h4>
        Connected OTAs
      </h4>
    </div>
  </div>
  <div class="white_box channels w-clearfix fixes card-null ng-star-inserted"
    [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
    <div class="col-md-12" style="padding-left: 1px !important;padding-bottom: 5px;"
      [ngStyle]="{'color': darkMode ? amenCol[0%amenCol.length] : '#00205b'}">
      <ng-container *ngFor="let channel of package_data?.otas;let i = index">
        <div class="col-md-3 csw ng-star-inserted" *ngIf="channel.channel.type == 'External'">
          <div style="display: flex;align-items: center;"
            class="channel_container {{channel.channel.name}} w-clearfix card ng-star-inserted"
            [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card-null '">
            <img alt="" class="tripadvisor ota-icon otasP" src="assets/img/svgicons/otas/{{channel.channel_image}}">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="gradient-bar adj" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <div class="col-md-10 zero-padding">
      <h4>
        Connected Channels
      </h4>
    </div>
  </div>
  <div class="white_box channels w-clearfix fixes card-null ng-star-inserted"
    [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
    <div class="col-md-12" style="padding-left: 1px !important;padding-bottom: 5px;"
      [ngStyle]="{'color': darkMode ? amenCol[0%amenCol.length] : '#00205b'}">
      <ng-container *ngFor="let channel of package_data?.otas;let i = index">
        <div class="col-md-3 csw ng-star-inserted" *ngIf="channel.channel.type == 'Internal'">
          <div style="display: flex;align-items: center;"
            class="channel_container {{channel.channel.name}} w-clearfix card ng-star-inserted"
            [ngClass]="darkMode ? 'card-dark-side-end0000' : ' card-null '">
            <img alt="" class="tripadvisor ota-icon otasP" src="assets/img/svgicons/channels/{{channel.channel_image}}">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer prev-0" *ngIf="service.is_preview == false"
    style="bottom: -55px !important;left: -12px!important;">

    <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close"
      [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">Cancel</a>
  </div>
  <div class="modal-footer prev-1" *ngIf="service.is_preview == true"
    style="bottom: -45px !important;left: 2px!important;">

    <a class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close"
      [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '">Cancel</a>
  </div>
</div>



<div *ngIf="showdata" class="lightHouse">
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <button type="button" (click)="hideImg()" class="lightHouse-btn">
      <i class="fa fa-times"></i>
    </button>
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null"
        attr.data-slide-to="{{i}}">
        <img src="{{base_url2}}app/images/{{im.image}}" alt="Room Image" class="hovo">
      </li>
    </ol>
    <!-- Wrapper for slides -->
    <div class="carousel-inner">
      <div class="item" *ngFor="let im of modalImg;let i = index" [ngClass]="i == 0  ? 'active' : null">
        <div>
          <img src="{{base_url2}}app/images/{{im.image}}" alt="Room Image">
        </div>
      </div>
      <!-- Left and right controls -->
      <a class="left carousel-control" href="#myCarousel" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
</div>