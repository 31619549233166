<div class="dash_window no_margin w-clearfix dashboard-card" [ngClass]="darkMode ? 'darkDashboard' : 'null'">
    <div class="win_header">
        <h4 class="heading">Stays</h4>

    </div>

    <div class="card_vacan">
        <div class="mainhd">Today's</div>
        <div class="Flex_conatiner">
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                    alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
                <span class="fon vacancy1">{{all_arrivals_stays.in_house}}</span>
                <span class="tooltiptext1">
                    <p>
                        In-House
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                    style="width: 18px;" alt="">
                <span class="fon vacancy2">{{all_arrivals_stays.todays_departure}}</span>
                <span class="tooltiptext1">
                    <p>
                        Departure
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
                <span class="fon vacancy3">{{all_arrivals_stays.todays_arrivals}}</span>
                <span class="tooltiptext1">
                    <p>
                        Arrivals
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder  info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                    alt="" width='31px'>
                <span class="fon vacancy4">{{all_arrivals_stays.todays_bookings}}</span>
                <span class="tooltiptext1">
                    <p>
                        Reservation
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder info">
                <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                    alt="" width='35px' style="margin-top:10px;">
                <span class="fon vacancy5">{{all_arrivals_stays.todays_available}}</span>
                <span class="tooltiptext1">
                    <p>
                        Available
                    </p>
                </span>
            </div>
        </div>
    </div>
    <div class="card_vacan">
        <div class="mainhd">Next 7 days</div>
        <div class="Flex_conatiner">
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                    alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
                <span class="fon vacancy1">{{all_arrivals_stays.next_seven_d_in_house}}</span>
                <span class="tooltiptext1">
                    <p>
                        In-House
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                    style="width: 18px;" alt="">
                <span class="fon vacancy2">{{all_arrivals_stays.next_seven_d_departure}}</span>
                <span class="tooltiptext1">
                    <p>
                        Departure
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
                <span class="fon vacancy3">{{all_arrivals_stays.next_seven_d_arrival}}</span>
                <span class="tooltiptext1">
                    <p>
                        Arrivals
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder  info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                    alt="" width='31px'>
                <span class="fon vacancy4">{{all_arrivals_stays.next_seven_d_bookings}}</span>
                <span class="tooltiptext1">
                    <p>
                        Reservation
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder info">
                <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                    alt="" width='35px' style="margin-top:10px;">
                <span class="fon vacancy5">{{all_arrivals_stays.next_seven_d_available}}</span>
                <span class="tooltiptext1">
                    <p>
                        Available
                    </p>
                </span>
            </div>
        </div>
    </div>
    <div class="card_vacan">
        <div class="mainhd">Next 15 days</div>
        <div class="Flex_conatiner">
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/in-house2.svg' : 'assets/img/dashboard/in-house2.svg'}}"
                    alt="" [ngStyle]="{'width':darkMode ? '30px' : '30px' }">
                <span class="fon vacancy1">{{all_arrivals_stays.next_fifteen_d_in_house}}</span>
                <span class="tooltiptext1">
                    <p>
                        In-House
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/departure.svg' : 'assets/img/newstaticicons/dashboard/departure.svg'}}"
                    style="width: 18px;" alt="">
                <span class="fon vacancy2">{{all_arrivals_stays.next_fifteen_d_departure}}</span>
                <span class="tooltiptext1">
                    <p>
                        Departure
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder info">
                <img src="assets/img/newstaticicons/dashboard/check-in.svg" alt="" height="35px">
                <span class="fon vacancy3">{{all_arrivals_stays.next_fifteen_d_arrival}}</span>
                <span class="tooltiptext1">
                    <p>
                        Arrivals
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder Imgtext_holder_boder  info">
                <img src="{{darkMode ? 'assets/img/dashboard/darkmode/reservation.svg':'assets/img/newstaticicons/dashboard/reservation.svg'}}"
                    alt="" width='31px'>
                <span class="fon vacancy4">{{all_arrivals_stays.next_fifteen_d_bookings}}</span>
                <span class="tooltiptext1">
                    <p>
                        Reservation
                    </p>
                </span>
            </div>
            <div class="Imgtext_holder info">
                <img src="{{darkMode ? 'assets/img/newstaticicons/inventory/forchk/available3.svg' : 'assets/img/dashboard/available.svg'}}"
                    alt="" width='35px' style="margin-top:10px;">
                <span class="fon vacancy5">{{all_arrivals_stays.next_fifteen_d_available}}</span>
                <span class="tooltiptext1">
                    <p>
                        Available
                    </p>
                </span>
            </div>
        </div>
    </div>
</div>