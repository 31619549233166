import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-upsells',
  templateUrl: './upsells.component.html',
  styleUrls: ['./upsells.component.css']
})
export class UpsellsComponent implements OnInit {

  constructor() { }

  legendArray = [
    {
      label: 'F&B',
      color: '#F5D7AF',
      data: [200, 400, 600, 800, 1000, 2500]
    },
    {
      label: 'Spa Service',
      color: '#FFBE68',
      data: [200, 400, 600, 800, 1000, 2500]
    },
    {
      label: 'Mini Bar',
      color: '#C87D5A',
      data: [200, 400, 600, 800, 1000, 2500]
    },
    {
      label: 'Gym',
      color: '#B44641',
      data: [200, 400, 600, 800, 1000, 2500]
    },
    {
      label: 'Extra Person',
      color: '#782D2D',
      data: [200, 400, 600, 800, 1000, 2500]
    },
    {
      label: 'Pickup',
      color: '#3C0000',
      data: [200, 400, 600, 800, 1000, 2500]
    },
  ]

  monthRowArray = [
    {
      month: 'Dec',
      count: 26,
      cost: '$3000',
      countPercentage: '35%',
      costPercentage: '65%'
    },
    {
      month: 'Nov',
      count: 21,
      cost: '$4000',
      countPercentage: '30%',
      costPercentage: '70%'
    },
    {
      month: 'Oct',
      count: 30,
      cost: '$2000',
      countPercentage: '45%',
      costPercentage: '55%'
    },
  ]
  darkMode: boolean = false;

  ngOnInit() {
    this.checkDarkMode();
    this.populateStackedBar();
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  populateStackedBar() {

    const data = {
      labels: ['august', 'september', 'october', 'november', 'december', 'Total'],
      datasets: this.legendArray.map(x => {
        return {
          label: x.label,
          data: x.data,
          backgroundColor: x.color,
        }
      })
    };

    let currency = localStorage.getItem('currency');

    const config: any = {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.dataset.label} : ${currency} ${tooltipItem.formattedValue}.00`;
              },
              title: function (tooltipItem, data) {
                return;
              }
            }
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            display: false
          }
        }
      }
    };

    const myChart = new Chart('stacked-bar', config)
  }
}

