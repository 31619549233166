<!-- <p>get-start works!</p> -->


<div class="w-container">
   <div class="container" id="gettingstarted" [ngClass]="darkMode ? 'container_dark' : 'container'">

      <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'colorr_dark':'colorr'">Getting Started
      </div>

      <div class="w-row">
         <div class="text-block-12">1. Lorem ipsem dolol sit amet Consectuers adipsince elit sed diam
         </div>

         <div class="text-block-13 text">it is sometimes known, is dummy text used in laying out print, graphic or
            web designs. The passage is attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
            specimen book.it is sometimes known, is dummy text used in laying out print, graphic or web
            designs. The passage is attributed to an unknown typesetter in the 15th century who is thought
            to have scrambled.
         </div>

         <div class="column-6 w-col w-col-6">
            <img
               src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
               alt="" class="image-25" />
         </div>
         <div class="column-8 w-col w-col-6">
            <div class="div-block-10">
               <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print,
                  graphic or web designs. The passage is attributed to an unknown typesetter in the 15th
                  century who is thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et
                  Malorum for use in a type specimen book.it is sometimes known, is dummy text used in laying
                  out print, graphic or web designs.print, graphic or web designs.text used in laying out
                  print, graphic.it is sometimes known, is dummy text used in laying
                  out print, graphic or web designs.print, graphic or web designs.text used in laying out
                  print, graphic
                  graphic or web designs.print, graphic or web designs.text used in laying out
                  print, graphic</div>
            </div><img src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG.png"
               srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG-p-1600.png 1600w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dbff8b25533478d5b9279eb_BG.png 1919w"
               sizes="(max-width: 767px) 96vw, (max-width: 991px) 354px, 460px" alt="" class="image-26" />
         </div>
      </div>

      <div class="w-row">

         <div class="text-block-12">2. Lorem ipsem dolol sit amet Consectuers adipsince elit
            sed diam
         </div>


         <div class="text-block-13 text">
            it is sometimes known, is dummy text used in laying out print, graphic or web
            designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
            scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it is
            sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who
         </div>

         <div class="column-7 w-col w-col-6"><img
               src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
               srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
               sizes="(max-width: 479px) 100vw, (max-width: 767px) 92vw, (max-width: 991px) 329px, 435px" alt=""
               class="image-27" /></div>
         <div class="w-col w-col-6">
            <div class="text-block-14 text">
               it is sometimes known, is dummy text used in laying out print, graphic or
               web designs. The passage is attributed to an unknown typesetter in the 15th century who is
               thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
               specimen book.it is sometimes known, is dummy text used in laying out print, graphic or web
               designs. The passage is attributed to an unknown typesetter in the 15th century who is thought
               to have scrambled.<br />designs. </div>
         </div>

         <div class="text-block-13 text-pd mr-top text">
            it is sometimes known, is dummy text used in laying out print, graphic or
            web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to
            have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it
            is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who.
         </div>
      </div>

      <div class="w-row">
         <div class="text-block-12">3. Lorem ipsem dolol sit amet Consectuers adipsince elit sed
            diam
         </div>
         <div class="text-block-13 text">it is sometimes known, is dummy text used in laying out print, graphic or web
            designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have
            scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type specimen book.it is
            sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who.
         </div>

         <div class="column-5 w-col w-col-3 text">
            <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print, graphic or
               web designs. The passage is attributed to an unknown typesetter in the 15th century who is
               thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum.
            </div>
         </div>
         <div class="w-col w-col-3"><img
               src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
               srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
               sizes="(max-width: 767px) 96vw, (max-width: 991px) 167px, 220px" alt="" class="image-28" />
         </div>
         <div class="w-col w-col-3">
            <div class="text-block-14 text">it is sometimes known, is dummy text used in laying out print, graphic or
               web designs. The passage is attributed to an unknown typesetter in the 15th century who is
               thought to have scrambled parts of Cicero&#x27;s De Finibus Bonorum et Malorum for use in a type
               specimen book.
            </div>
         </div>
         <div class="w-col w-col-3"><img
               src="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png"
               srcset="https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-500.png 500w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-800.png 800w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206-p-1080.png 1080w, https://uploads-ssl.webflow.com/5db984482067fa429b8b70d3/5dc00559553347205c92e361_background%206.png 1920w"
               sizes="(max-width: 767px) 96vw, (max-width: 991px) 167px, 220px" alt="" class="image-28 image-34" />
         </div>
      </div>
   </div>
</div>