import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-start',
  templateUrl: './get-start.component.html',
  styleUrls: ['./get-start.component.css']
})
export class GetStartComponent implements OnInit {

  darkMode: boolean = false

  constructor() { }

  ngOnInit() {
    this.checkDarkMode()
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
}
