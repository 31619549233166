<div class="main">
    <div class="box" style="display: block;">
        <div class="header flex space-between pb-2">
            <div class="logoHeader">
                <!-- <img
                    src="{{ logo != 'null' && logo != null ? base_url2+'../app/images/' + logo : '../../../assets/img/nav/logo.png' }}" /> -->
                <div>
                    <span>{{ property?.name }}</span>
                </div>
            </div>

            <div class="hotel-text">
                <div>{{ property?.address_one }}</div>
                <div>{{ property?.email }}</div>
                <div>{{ property?.phone }}</div>
                <div>{{ property?.taxID}}</div>
            </div>
        </div>

        <div class="red-bar flex space-between">
            <div class="flex space-between"> Reservation ID :{{printInvoice.booking_id}}</div>
            <div class="flex space-between" style="margin-right:10px">Invoice No:</div>
        </div>

        <div class="flex room-details">

            <div class="flex flex-coluum sec">
                <div class="main-con">
                    <div class="head">
                        <div class="heads">
                            <h4 class="hds">Chk-in</h4>
                        </div>
                        <div class="heads">
                            <h4 class="hds">Chk-out</h4>
                        </div>
                        <div class="heads">
                            <h4 class="hds">Rm No</h4>
                        </div>
                        <div class="heads">
                            <h4 class="hds">LOS</h4>
                        </div>
                        <div class="heads">
                            <h4 class="hds">Rate</h4>
                        </div>
                        <div class="heads">
                            <h4 class="hds">Total</h4>
                        </div>
                    </div>
                    <hr>
                    <div class="flex" *ngFor="let booking of bulk_booking">
                        <div class="dataRoom">
                            <span class="datas">{{booking?.check_in | date:"dd MMM"}}</span>
                        </div>
                        <div class="dataRoom">
                            <span class="datas">{{booking?.check_out | date:"dd MMM"}}</span>
                        </div>
                        <div class="dataRoom">
                            <span class="datas">{{booking?.roomnum?.room_number}}</span>
                        </div>
                        <div class="dataRoom">
                            <span class="datas">{{roomNightStay(booking?.check_out,booking?.check_in)}}</span>
                        </div>
                        <div class="dataRoom">
                            <span class="datas">{{currency}} {{perNightPrice(booking.pricesjson) |
                                number:'1.2-3'}}</span>
                        </div>
                        <div class="dataRoom">
                            <span class="datas">{{currency}} {{roomRate(booking.pricesjson) | number:'1.2-3'}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="third">
                <div class="main3">
                    <h4 style="color:#270EA7; margin: 0%; font-weight: 600;">Guest Info</h4>
                    <hr />
                    <div>
                        <div class="guestDetail">{{printInvoice?.booking?.guest_firstname}}</div>
                        <div class="guestDetail" *ngIf="printInvoice?.booking?.guest_email != 'null'">
                            {{printInvoice?.booking?.guest_email}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bills">
            <ng-container *ngIf="calculateExtrasPrice('service') != 0">
                <div class="red-bar flex space-between">
                    <div class="flex space-between">Service Add-ons</div>
                </div>
                <div class="py-3">
                    <ng-container
                        *ngFor="let extras of (printInvoice.isMaster ? bulkBookingExtras : printExtras(printInvoice.booking_extras))">
                        <div class="flex space-between bill" *ngIf="extras.type == 'service'">
                            <div class="col-md-4">{{extras?.name}}</div>
                            <div class="col-md-4 text-center">{{extras?.count}}</div>
                            <div class="col-md-4 text-right">{{currency}}{{ (+extras.price_after_tax * extras?.count) |
                                number:'1.2-3'}}</div>
                        </div>
                    </ng-container>
                    <div class="total">
                        <span>Sub Total</span>
                        <span>{{currency}}{{calculateExtrasPrice('service') | number:'1.2-3'}} <a href="#">{{
                                printInvoice.booking.TaxInclusive === "false" ? 'Excl' : 'Incl'}} Tax</a></span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="calculateExtrasPrice('minibar') != 0">
                <div class="red-bar flex space-between">
                    <div class="flex space-between">Food | Mini Bar Add-ons</div>
                </div>
                <div class="py-3">
                    <ng-container
                        *ngFor="let extras of (printInvoice.isMaster ? bulkBookingExtras : printExtras(printInvoice.booking_extras))">
                        <div class="flex space-between bill" *ngIf="extras.type == 'minibar'">
                            <div class="col-md-4">{{extras.name}}</div>
                            <div class="col-md-4 text-center">{{extras?.count}}</div>
                            <div class="col-md-4 text-right">{{currency}}{{(+extras.price_after_tax * extras?.count) |
                                number:'1.2-3'}}
                            </div>
                        </div>
                    </ng-container>
                    <div class="total">
                        <span>Sub Total</span>
                        <span>{{currency}}{{calculateExtrasPrice('minibar') | number:'1.2-3'}} <a href="#">{{
                                printInvoice.booking.TaxInclusive === "false" ? 'Excl' : 'Incl'}} Tax</a></span>
                    </div>
                </div>
            </ng-container>

            <div class="flex space-between bill" *ngIf="getTaxPrice('service') != 0">
                <div>Service Tax {{property?.taxper}}%</div>
                <div class="pr-2">{{currency}}{{ getTaxPrice('service') | number: '1.2-3'
                    }}</div>
            </div>
            <div class="flex space-between bill" *ngIf="getTaxPrice('minibar') != 0">
                <div>Food | Mini Bar Tax {{property?.food_tax_percentage}}%</div>
                <div class="pr-2">{{currency}}{{ getTaxPrice('minibar') | number: '1.2-3'
                    }}</div>
            </div>
            <!-- <div class="flex space-between bill">
                <div>Property Tax {{property?.taxper}}%</div>
                <div>{{currency}}{{(calculateAllRoomRate() * property?.taxper / 100) | number:'1.2-3'}}</div>
            </div> -->
            <div class="flex space-between bill" *ngIf="printInvoice?.booking?.discount">
                <div>Discount {{printInvoice?.booking?.discount}}%</div>
                <div>{{currency}}{{ ((calculateAllRoomRate() * printInvoice.booking.discount) / 100) | number:'1.2-3'}}
                </div>
            </div>
            <div class="flex space-between bill" *ngIf="printInvoice?.booking?.extra_person_fee != 0">
                <div>Extra Person</div>
                <div>{{currency}}{{printInvoice?.booking?.extra_person_fee}}</div>
            </div>
            <div class="total">
                <span>Total</span>
                <span>{{currency}}{{calculateTotal() | number:'1.2-3'}}</span>
            </div>
        </div>

        <div class="bills">
            <div class="des">Desclaimer</div>
            <div class="claims">- Non-Refundable</div>
            <div class="claims">- It is a long established fact that a reader will be distracted by the readable
                content of a page when looking at its layout.</div>
            <div class="claims">- Contrary to popular belief, Lorem Ipsum is not simply random text.</div>
        </div>

    </div>
</div>