import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recursive'
})
export class RecursiveSearchPipe implements PipeTransform {
  // private searchedItems: Array<any> = [];
  // private key: any;
  // private prop: string;
  // private childrenPropName: string;

  // transform(value: any, key?: any, prop?: any, childrenProp?: any): any {
  //   if(key != undefined) {
  //     this.searchedItems = [];
  //     if(Number.isInteger(key)){
  //       this.key = key;
  //     }
  //     else {
  //       this.key = key.toLowerCase();
  //     }
  //     this.prop = prop;
  //     this.childrenPropName = childrenProp;
  //     let searchResult = this.searchRecursive(value);
  //     return searchResult;
  //   }
  //   return value;
  // }

  // searchRecursive(value) {
  //   for(var i = 0; i < value.length; i++) {

  //     // let lowerCaseName = value[i][this.prop].toLowerCase();
  //     let lowerCaseName = value[i][this.prop];
  //     if(lowerCaseName.includes(this.key,this.prop)) {
  //       // if(lowerCaseName.some(check => this.key === check[this.key])) {
  //       this.searchedItems.push(value[i]);
  //     } else if(value[i][this.childrenPropName]) {
  //       if(value[i][this.childrenPropName].length > 0) {
  //         this.searchRecursive(value[i][this.childrenPropName]);
  //       }
  //     }
  //   }

  //   return this.searchedItems;
  // }

  transform(values: any[], searchValue: any, nested: boolean): any {
    if (searchValue == null) { return values; }

    // if (searchValue === undefined || searchValue === '') { return values; }
    // return values.filter(data => { 
    //   return  data.otas.includes(values)  || data.otas.find(item => item.name.includes(values));

    // });
    return values.filter(v => this.filterFn(v, searchValue, nested));
  }

  filterFn(value: any, searchValue, nested) {
    // console.log(value);
    if (value.otas === undefined || value.otas.length == 0) {
      return;
    }
    // console.log(value.otas);
    let flag = value.otas.filter(v => (v.name, searchValue)).length > 0;
    console.log(flag)
    //   let flag = this.testSearchCondition(value.otas.name, searchValue);
    //   if (!flag && value.otas) {
    //     flag = value.otas.filter(v => this.testSearchCondition(v.name, searchValue)).length > 0;
    //   }
    //   return flag;
    // }
    // testSearchCondition(name: string, searchValue: string): boolean {
    //   return name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
  }

  // transform(items: any, filter: any, isAnd: boolean): any {
  //   if (filter && Array.isArray(items)) {
  //     let filterKeys = Object.keys(filter);
  //     if (isAnd) {
  //       return items.filter(item =>
  //           filterKeys.reduce((memo, keyName) =>
  //               (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true));
  //     } else {
  //       return items.filter(item => {
  //         return filterKeys.some((keyName) => {
  //           console.log(keyName);
  //           return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";
  //         });
  //       });
  //     }
  //   } else {
  //     return items;
  //   }
  // }
}