import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { MustMatch } from '../../../common/must-match.validator';
import { PasswordStrengthValidator } from "../../../common/password-strength.validators";
import { HelperService } from "../../../services/helper.service";
import { EventsService } from '../../../services/events.service';
@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  open_custom = false;

  languages;
  days;

  pro_pic;

  user: any;
  base_url;
  base_url2;

  open_change_pass = false;
  submitted = false;
  pass_sub = false;
  questions;
  check_answer;
  upload_img = new FormData();
  darkMode: boolean = false;
  current_password;
  account_settings_form: FormGroup;

  constructor(
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
    private helper: HelperService,
    public events: EventsService
  ) {
    this.base_url = this.api.base_url;
    this.base_url2 = this.api.base_url2;
    this.events.receiveDarkMode().subscribe((res: any) => {
      this.darkMode = res.darkMode;
    })
  }

  ngOnInit() {
    this.checkDarkMode();
    this.get_languages();
    this.account_settings_form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone: ['', Validators.required],
      email: '',
      language: null,
      mode: '',
      layout: '',
      question: null,
      answer: '',
      profile_picture: '',
      current_password: '',
      // new_password:['', [Validators.required, PasswordStrengthValidator]],
      new_password: '',
      confirm_password: ''
    }, { validator: this.passwordMatchValidator });

    this.f['question'].valueChanges.subscribe((res) => {
      this.account_settings_form.value.question = res == '' ? '' : res;
      this.on_sec_change();
    });

    this.f['current_password'].valueChanges.subscribe((res) => {
      // this.account_settings_form.controls['new_password'].setValidators([Validators.required, PasswordStrengthValidator]);
      // this.account_settings_form.controls['confirm_password'].setValidators([Validators.required]);
      this.account_settings_form.controls['new_password'].setValidators([PasswordStrengthValidator]);
      this.account_settings_form.controls['confirm_password'].setValidators([]);
      this.account_settings_form.controls['new_password'].updateValueAndValidity();
    });

    this.get_user();
    this.get_questions();
  }
  get_questions() {
    this.api.post('api/question/index', {}, true).subscribe((res: any) => {
      this.questions = res.body.data;

      let id = JSON.parse(localStorage.getItem('user')).id;
      this.questions.push({ id: '', question: "Enter your custom question." });
    }, err => this.error_handling.handle_error(err.status));
  }
  open_dialoge() {
    if (
      this.account_settings_form.value.question !== null &&
      this.account_settings_form.value.question !== undefined &&
      this.account_settings_form.value.answer !== undefined &&
      this.account_settings_form.value.answer !== ''
    ) {
      // this.open_change_pass = !this.open_change_pass;
      this.api.post('api/user_security/verify_answer', { user_id: this.user.id, question_id: this.account_settings_form.value.question, answer: this.account_settings_form.value.answer }, true).subscribe((res: any) => {
        if (res.body.status == 200) {
          this.helper.alert_success("Security question and answer matched !");
          this.open_change_pass = true;
          this.check_answer = true;
        }
      }, err => {
        if (err.status == 400) {
          this.check_answer = false;
        }
      });
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }



  get_languages() {
    this.api.get('api/helper/languages', true).subscribe((res: any) => {
      this.languages = res.body.data;

      // }, err => this.error_handling.handle_error(err.status, err.message));
    }, err => this.error_handling.handle_error(err.status));
  }

  onSubmit() {
    this.submitted = true;
    if (this.account_settings_form.invalid) {
      let errors = this.findInvalidControls();
      return;
    }
    //  Object.keys(this.account_settings_form.controls).forEach(key => {
    // const controlErrors: ValidationErrors = this.account_settings_form.get(key).errors;
    // if (controlErrors != null) {
    //       Object.keys(controlErrors).forEach(keyError => {
    //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
    //       });
    //     }
    //   });
    // // console.log(this.account_settings_form.invalid);
    // return;

    if (
      this.account_settings_form.value.current_password !== null &&
      this.account_settings_form.value.current_password !== undefined &&
      this.account_settings_form.value.new_password !== undefined &&
      this.account_settings_form.value.new_password !== ''
    ) {
      this.pass_sub = true;
      this.api.post('api/user/update_password', { old_password: this.account_settings_form.value.current_password, password: this.account_settings_form.value.new_password }, true).subscribe((res: any) => {
        this.user = res.body.data;
        this.open_change_pass = false;
        this.account_settings_form.reset();
        this.get_user();
        this.account_settings_form.get('new_password').setValidators([]);
        this.account_settings_form.get('new_password').updateValueAndValidity();
        this.account_settings_form.get('confirm_password').setValidators([]);
        this.account_settings_form.get('confirm_password').updateValueAndValidity();
        this.helper.alert_success("Password updated successfully !");
        // }, err => this.error_handling.handle_error(err.status, err.message));
      }, err => this.error_handling.handle_error(err.status));
      this.updateUser();
    }
    // else{

    //       this.api.post('api/user/update/' + this.user.id, this.account_settings_form.value, true).subscribe((res:any) => {
    //           this.user=res.body.data;
    //           this.helper.alert_success("Changes has been saved !");
    //     }, err => this.error_handling.handle_error(err.status));
    // }
    else {
      this.updateUser();
    }

    //   if(this.account_settings_form.value.question !== null && 
    //     this.account_settings_form.value.question !== undefined && 
    //     this.account_settings_form.value.answer !== undefined && 
    //     this.account_settings_form.value.answer !== '') {
    //     var qs = this.questions.filter(x => x.id===this.account_settings_form.value.question);
    //     this.account_settings_form.patchValue({question: qs[0].question});
    //   }

  }


  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.pro_pic = event.target.result;
      }
      this.upload_img.append('image', event.target.files[0], event.target.files[0].name);
      this.api.post2('api/helper/upload_image', this.upload_img, false).subscribe((img_res: any) => {
        this.account_settings_form.get('profile_picture').setValue(img_res.body.data.image);
        this.events.publishUserImg(img_res.body.data.image)
      }, err => this.error_handling.handle_error(err.status));
    }
  }

  remove_file() {
    this.pro_pic = null;
    this.user.profile_picture = null;
    this.account_settings_form.get('profile_picture').setValue("");
    this.events.publishUserImg(null)
  }

  get_user() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.account_settings_form.patchValue({
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone,
      email: this.user.email,
      language: this.user.language,
      mode: this.user.mode,
      layout: this.user.layout,
      profile_picture: this.user.profile_picture
    });
  }

  on_sec_change() {
    let que = this.account_settings_form.value.question;
    if (que == '') {
      this.open_custom = true;
    }
  }

  get f() { return this.account_settings_form.controls; }

  get show_pic() {
    if (this.pro_pic == null) {
      if (this.user.profile_picture) {
        return this.base_url2 + 'app/images/' + this.user.profile_picture;
      }
    } else if (this.pro_pic != null) {
      return this.pro_pic;
    }
    return 'assets/img/back-office/4535.png';
  }

  add_custom() {
    let obj = {
      type: 'custom',
      question: this.account_settings_form.value.question,
      answer: this.account_settings_form.value.answer,
      first_name: this.account_settings_form.value.first_name,
      last_name: this.account_settings_form.value.last_name
    }
    this.api.post('api/user/update/' + this.user.id, obj, true).subscribe((res: any) => {
      this.helper.alert_success("Custom question added successfully.");
      this.get_questions();
      this.open_custom = false;
    }, err => this.error_handling.handle_error(err.status));
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.account_settings_form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['new_password'].value === frm.controls['confirm_password'].value ? null : { 'mismatch': true };
  }

  updateUser() {
    this.api.post('api/user/update/' + this.user.id, this.account_settings_form.value, true).subscribe((res: any) => {
      this.user = res.body.data;
      localStorage.setItem('user', JSON.stringify(this.user))
      if (this.user.mode == 'dark') { this.darkMode = true; this.checkDarkMode(); this.events.publishDarkMode({ darkMode: true }); }
      else { this.darkMode = false; this.checkDarkMode(); this.events.publishDarkMode({ darkMode: false }); }
      this.helper.alert_success("Changes Updated !");
    }, err => this.error_handling.handle_error(err.status));
  }

}
