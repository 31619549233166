import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FinancialPerformanceData } from 'src/app/common/pnl/financial-performance/FinancialPerformanceModels';

@Component({
  selector: 'app-comparision-row',
  templateUrl: './comparision-row.component.html',
  styleUrls: ['../financial-performance.component.css', './comparision-row.component.css']
})
export class ComparisionRowComponent implements OnInit {

  @Input('data') data: FinancialPerformanceData
  currency_format = localStorage.getItem('currency');
  darkMode: boolean = false;

  constructor() { }

  ngOnInit() {
    this.checkDarkMode();
  }

  getChangePercentage() {
    return Number(this.data.change)
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  calculateBarWidth() {

    //normalize the values
    let max = Math.max(this.data.current, this.data.previous);
    let delta = Math.abs(this.data.current - this.data.previous);

    let barWidth = (this.data.current / (max + (delta / 2))) * 100;
    barWidth = barWidth ? barWidth : 0;

    if (barWidth > 100) {
      barWidth = 100;
    }
    if (barWidth < 10) {
      barWidth = 10;
    }
    return barWidth;
  }

  calculateIndicatorAdjustment() {
    let max = Math.max(this.data.current, this.data.previous);
    let delta = Math.abs(this.data.current - this.data.previous);

    let previousIndicator = (this.data.previous / (max + (delta / 2))) * 100;
    previousIndicator = previousIndicator ? previousIndicator : 0;

    if (previousIndicator > 100) {
      previousIndicator = 100;
    }
    if (previousIndicator < 10) {
      previousIndicator = 10;
    }
    return previousIndicator;

  }

}
