<div class="d">
  <div class="alert alert-danger breadcrum" style="margin-bottom: 5px; height: 52px"
    [ngClass]="darkMode ? 'bredcurm-dark' : ' breadcrum '">
    <div class="row">
      <div class="col-md-6">
        <ul class="nav navbar-nav">
          <li class="active">
            <a data-toggle="tab" href="#all_arrivals" class="btn btn-link active"
              (click)="show_print_btn = false; paginavishow()">Arrivals</a>
          </li>
          <li>
            <a data-toggle="tab" href="#today_arrival" class="btn btn-link"
              (click)="show_print_btn = false; paginavishow()">Today's
              Arrival</a>
          </li>
          <li>
            <a data-toggle="tab" href="#last_week_arrivals" class="btn btn-link"
              (click)="show_print_btn = false; paginavishow()">Check Out</a>
          </li>
          <li>
            <a data-toggle="tab" href="#checked_in" class="btn btn-link"
              (click)="show_print_btn = true; paginavishow()">In
              House</a>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="row drowD">
          <div class="col-md-1 filters_col" style="padding: 0%; padding-right: 5px">
            <button type="reset" class="btn btn-block bread-search" (click)="query='';selected0 = null;get_arrivals()">
              Reset
            </button>
            <!-- <img src="assets/img/inventory/download-icon.png" class="pdfImg" id="border-0" (click)="exportexcel()"  style="height: 40px" /> -->
          </div>
          <div class="col-md-2 columnBar" [ngClass]="darkMode ? 'b-search-dark' : 'b-search'" style="padding-left: 0px">
            <div class="input-group  b-addon_guest"
              style="display: flex; justify-content: flex-end;align-items: center;">
              <img src="assets/img/arrival/guest-search.svg" alt="search" style="height: 22px;padding-left: 5px;" />
              <div class="d-flex pr-2">
                <input type="text" matInput placeholder="Guest Search" class="form-control bred-search"
                  [(ngModel)]="query" (keyup.enter)="param = query" (keyup.enter)="querue(query)" id="input0"
                  style="text-transform: capitalize; padding: 5px;" />
                <!-- <span class="input-group-addon b-addon" (click)="reset0()"><i class="glyphicon glyphicon-remove"></i></span> -->
                <!-- <button class="input-group-addon b-addon" *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="query=''"> -->
                <i class="fa fa-times" (click)="query='';get_arrivals()" *ngIf="query"
                  style="position: relative;top: 14px;left: 5px;height: 15px;width: 15px;color: orange;"></i>
                <!-- <mat-icon style="position: absolute; bottom: 40%; right: 7%; height: 15px; width: 15px; color: orange;">close</mat-icon> -->
                <!-- </button> -->
              </div>
            </div>
          </div>
          <div class="col-md-2 filters_col columnBar" style="padding: 0px; padding-right: 5px; width: 185px">
            <div class="form-control input_2 background-gray padding-5 calendar-top f-calendar" style="
                                  background: linear-gradient(#fff, lightgrey);
                                  border: none !important;">
              <span class="input-group-addon" style="
                              padding: inherit;
                              background: transparent;
                              border: none;
                              font-size: 24px;
                              color: #00205b;
                              display: inline;
                            ">
                <img src="assets/img/reports/calendar.png" style="margin-top: 10px; margin-bottom: 10px" alt="" />
              </span>
              <input type="hidden" name="date_to" />
              <input type="hidden" name="date_from" />
              <!-- <input type="text" ngxDaterangepickerMd class="form-control date_range_picker arrival-lenght singMulCalen" (change)="calendar_change($event)" placeholder="Date" [singleDatePicker]="c_con" [autoApply]="true" [(ngModel)]="selected0" [locale]="{ format: 'DD, MMMM YYYY', separator: '  To  ' }"
                                [dateLimit]="12" /> -->
              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker arrival-lenght singMulCalen"
                (change)="calendar_change($event)" placeholder="Date" [singleDatePicker]="c_con" [autoApply]="true"
                [(ngModel)]="selected0" [locale]="{ format: 'DD MMM YYYY', separator: '  To  ' }" />
            </div>
            <!-- <input type="checkbox" class="calendar_chk" (click)="chkbox()" data-toggle='tooltip'
                  data-placement='bottom' data-original-title="Single Date" class='checkbox'
                  style="  height: 15px;width: 15px;cursor: pointer;position: absolute; top: 13px; right: 10px;"> -->
          </div>
          <div class="col-md-2 filters_col columnBar" style="padding: 0%; padding-right: 5px">
            <div style="position: relative;">
              <img src="assets/img/arrival/sort-by.svg" class="sortImg" alt="sortby" />
              <ng-select class="form-control input_2 padding-holder chi leftAlign" placeholder="Sort by"
                (change)="chngeFilters($event)">
                <ng-option value="channel">Channel</ng-option>
                <ng-option value="paystatus">Payment</ng-option>
                <ng-option value="bstatus">Status</ng-option>
                <ng-option value="srtBooking">Booked date</ng-option>
                <ng-option value="srtCheckIn">Check-in</ng-option>
                <!-- <ng-option value="bstatus"> Filter by Booking</ng-option> -->
              </ng-select>
            </div>
          </div>
          <div class="col-md-2 filters_col" style="padding: 0%; padding-right: 5px" *ngIf="filterBy != null">
            <ng-select class="form-control input_2 padding-holder chi" placeholder="Channel" id="myChannel"
              [(ngModel)]="my_channel" (change)="channelfilter(my_channel)" [items]="sortChannels" bindValue="name"
              bindLabel="name" *ngIf="filterBy == 'sortChannels'">
            </ng-select>
            <ng-select class="form-control input_2 padding-holder chi" placeholder="Payment status" id="myStatus"
              [(ngModel)]="my_status" (change)="statusfilter(my_status, 'payment')" [items]="status" bindValue="s_value"
              bindLabel="name" *ngIf="filterBy == 'paystatus'">
            </ng-select>
            <ng-select class="form-control input_2 padding-holder chi" placeholder="Booking status" id="myStatusb"
              [(ngModel)]="my_statusb" (change)="statusfilter(my_statusb, 'status')" [items]="bstatus" bindValue="label"
              bindLabel="name" *ngIf="filterBy == 'bstatus'">
            </ng-select>
            <ng-select class="form-control input_2 padding-holder chi" placeholder="Sort Order" id="myStatusboking"
              [(ngModel)]="my_booking" (change)="statusfilter(my_booking, 'booking')" [items]="srtBooking"
              bindValue="s_value" bindLabel="name" *ngIf="filterBy == 'srtBooking'">
            </ng-select>
            <ng-select class="form-control input_2 padding-holder chi" placeholder="Sort Order" id="myStatuscheckIn"
              [(ngModel)]="my_checkIn" (change)="statusfilter(my_checkIn, 'check-in')" [items]="srtCheckIn"
              bindValue="s_value" bindLabel="name" *ngIf="filterBy == 'srtCheckIn'">
            </ng-select>
          </div>
          <div class="col-md-1 filters_col" style="padding: 0%; padding-right: 5px">
            <button class="btn btn-block bread-search" (click)="adte(selected0)">
              Search
            </button>
            <!-- <img src="assets/img/inventory/download-icon.png" class="pdfImg" id="border-0" (click)="exportexcel()"  style="height: 40px" /> -->

          </div>
          <!-- show download button 👇 -->
          <div *ngIf="!show_print_btn" class="filters_col">
            <ng-select class=" cevt-drop " [(ngModel)]="sheet" (change)="sheets($event)">
              <ng-option value="PDF">PDF</ng-option>
              <ng-option value="Excel">Excel</ng-option>
            </ng-select>
          </div>
          <!-- show print button 👇 -->
          <div *ngIf="show_print_btn" class="filters_col" style="padding: 0%; padding-right: 5px; margin-left:5px">
            <button class="btn btn-block bread-search" (click)="printInHouse()"
              style="border-radius: 100%;width: 42px; background-color: #1c2882;padding: 0px;">
              <img src="assets/img/newstaticicons/arrivals/print-icon-updated.png" style="height:26px" />
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-content capture" id="capture">

    <div id="all_arrivals" class="tab-pane fade in active " style="margin-top: 5px">
      <div class="card card-table table-responsive" style="
          border-radius: 10px;
          box-shadow: 1px -6px 6px #00000040;margin-bottom: 0;
        " [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
        <div class="col-md-12 blue-head" style="padding: 0px">
          <ul class="blue-ul">
            <li style="width: 10%; border-right: 1px solid white">
              <a>Channel</a>
            </li>
            <li style="width: 14%; border-right: 1px solid white">
              <a>Guest</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white"><a>LOS</a></li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Date Booked</a>
            </li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Check In</a>
            </li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Check Out</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Payment</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white"><a>Reservation Status</a></li>
            <!-- <li style="width: 12%; border-right: 1px solid white"><a>Room</a>< data-target="#myModal/li> -->
            <li style="width: 9%"><a> Room Type </a></li>
          </ul>
        </div>
        <div class="scroll no_scroll">
          <!-- arrival skeleton (working on saud 👇) -->
          <ng-container *ngIf="loader">
            <app-arrival-skeleton></app-arrival-skeleton>
          </ng-container>
          <div id="list3" *ngIf="param">
            <div
              *ngFor="let arrival of arrivals2 | search : 'guest_firstname,reservation_id,channel_name,name,status,all,property_id,guest_phone,payment_status' : param"
              class="col-md-12 border-div {{ arrival.status != 'cancelled' ? arrival.payment_status + '-border' : 'cancelled-border' }}"
              style="padding: 0px" id="loop" [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul" id="ko">
                <li style="width: 10%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    <img src="assets/img/svgicons/{{
                                      !darkMode ? 'all-channels' : 'otas/ota_dark'
                                    }}/{{ chngealpha(arrival?.channel_image) }}.svg" class="imageOaChnnel capSvg"
                      alt="" *ngIf="exte" />
                    <img src="assets/img/svgicons/{{
                                      !darkMode ? 'all-channels-webp' : 'webp-dark'
                                    }}/{{ chngealpha(arrival?.channel_image) }}.webp" class="imageOaChnnelweb capSvg"
                      alt="" *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 13.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">{{
                    arrival.guest_firstname}}</a>
                </li>
                <li style="width: 10.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    {{ daysTotal(arrival.date_departure, arrival.date_arrival) }}
                  </a>
                </li>
                <li style="width: 11.5%">
                  <a>{{ arrival.created_at | date: "d MMM | h:mm " }} </a>
                </li>
                <li style="width: 10.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-in-{{
                  arrival.status == 'cancelled'
                    ? 'red' : arrival.payment_status == 'unpaid'
                    ? 'orange'
                    : arrival.payment_status == 'paid'
                    ? 'green'
                    : arrival.payment_status == 'paynow'
                    ? 'orange'
                    : null
                }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDateArr(arrival) |
                    date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 11.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
                  arrival.status == 'cancelled'
                    ? 'red'
                    : arrival.payment_status == 'unpaid'
                    ? 'orange'
                    : arrival.payment_status == 'paid'
                    ? 'green'
                    : arrival.payment_status == 'paynow'
                    ? 'orange'
                    : null
                }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDatedep(arrival) |
                    date: "d MMM" }}</a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21) );">
                      {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                    </button>
                    <button *ngIf=" arrival.payment_status == 'paynow' && arrival.status == 'cancelled'  " type="submit"
                      class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"
                      style=" background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'unpaid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(
                  rgb(230, 60, 2),
                  rgb(228, 91, 0),
                  rgb(148, 59, 0)
                );">
                      Due
                    </button> -->
                  </a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button *ngIf="arrival.status == 'confirm'" type="submit" class="paid w-button btn-0" style="
                  background: linear-gradient(
                    rgb(10, 222, 36),
                    rgb(8, 113, 21)
                  );
                ">
                      Confirmed
                    </button>
                    <button *ngIf="arrival.status == 'hold'" type="submit" class="paid w-button btn-0 HoldBtn"
                      data-toggle="modal" data-target="#payNow" (click)="paynowMainRes(arrival)">
                      Hold
                    </button>
                    <button *ngIf="arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="
                  background: linear-gradient(rgb(249 25 25), rgb(115 6 35));
                ">
                      Cancelled
                    </button>
                    <!-- <button *ngIf="arrival.status == 'modified' " type="submit" class="paid w-button btn-0"
                        style="background: linear-gradient(rgb(0 207 241), rgb(8 95 121))">Modified</button> -->
                    <button *ngIf="arrival.status == 'modified'" type="submit" class="paid w-button btn-0" style="
                  background: linear-gradient(rgb(0, 207, 241), rgb(8, 95, 121)) ">
                      Modified
                    </button>

                  </a>
                </li>
                <li class="totalLast">{{ getRoomOfGuest(arrival) }}</li>
              </ul>
            </div>
          </div>
          <div id="range" *ngIf="sortedActivities1">
            <div *ngFor="let arrival of sortedActivities1" class="col-md-12 border-div
        {{
          arrival.status != 'cancelled'
            ? arrival.payment_status + '-border'
            : 'cancelled-border'
        }}
          " style="padding: 0px" id="loop" [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul" id="ko">
                <li style="width: 10%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    <img src="assets/img/svgicons/{{
                        !darkMode ? 'all-channels' : 'otas/ota_dark'
                      }}/{{ chngealpha(arrival?.channel_image) }}.svg" class="imageOaChnnel capSvg" alt=""
                      *ngIf="exte" />
                    <img src="assets/img/svgicons/{{
                        !darkMode ? 'all-channels-webp' : 'webp-dark'
                      }}/{{ chngealpha(arrival?.channel_image) }}.webp" class="imageOaChnnelweb capSvg" alt=""
                      *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 13.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">{{
                    arrival.guest_firstname}}</a>
                </li>
                <li style="width: 10.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    {{ daysTotal(arrival.date_departure, arrival.date_arrival) }}
                  </a>
                </li>
                <li style="width: 11.5%">
                  <a>{{ arrival.created_at | date: "d MMM | h:mm " }} </a>
                </li>
                <li style="width: 10.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-in-{{
    arrival.status == 'cancelled'
      ? 'red'
      : arrival.payment_status == 'unpaid'
      ? 'orange'
      : arrival.payment_status == 'paid'
      ? 'green'
      : arrival.payment_status == 'paynow'
      ? 'orange'
      : null
  }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDateArr(arrival) | date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 11.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
    arrival.status == 'cancelled'
      ? 'red'
      : arrival.payment_status == 'unpaid'
      ? 'orange'
      : arrival.payment_status == 'paid'
      ? 'green'
      : arrival.payment_status == 'paynow'
      ? 'orange'
      : null
  }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDatedep(arrival) | date: "d MMM"
                    }}</a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21) );">
                      {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                    </button>
                    <button *ngIf=" arrival.payment_status == 'paynow' && arrival.status == 'cancelled'  " type="submit"
                      class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"
                      style=" background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'unpaid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(
    rgb(230, 60, 2),
    rgb(228, 91, 0),
    rgb(148, 59, 0)
  );">
                      Due
                    </button> -->
                  </a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button *ngIf="arrival.status == 'confirm'" type="submit" class="paid w-button btn-0" style="
    background: linear-gradient(
      rgb(10, 222, 36),
      rgb(8, 113, 21)
    );
  ">
                      Confirmed
                    </button>
                    <button *ngIf="arrival.status == 'hold'" type="submit" class="paid w-button btn-0"
                      data-toggle="modal" data-target="#payNow" (click)="paynowMainRes(arrival)">
                      Hold
                    </button>
                    <button *ngIf="arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="
    background: linear-gradient(rgb(249 25 25), rgb(115 6 35));
  ">
                      Cancelled
                    </button>
                    <!-- <button *ngIf="arrival.status == 'modified' " type="submit" class="paid w-button btn-0"
          style="background: linear-gradient(rgb(0 207 241), rgb(8 95 121))">Modified</button> -->
                    <button *ngIf="arrival.status == 'modified'" type="submit" class="paid w-button btn-0" style="
    background: linear-gradient(rgb(0, 207, 241), rgb(8, 95, 121))">
                      Modified
                    </button>

                  </a>
                </li>
                <li class="totalLast">{{ getRoomOfGuest(arrival) }}</li>
              </ul>
            </div>
          </div>
          <div id="list4">
            <!-- <div *ngFor="let arrival of arrivals  | search:'guest_firstname,channel_name,name,status,date_arrival,all,property_id':param; let i=index " class="col-md-12 border-div {{ arrival.payment_status }}-border"  style="page-break-before: always;padding: 0px" data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" id="restData" > -->
            <div *ngFor="let arrival of arrivals; let ariiI = index" class="col-md-12 border-div
        {{
          arrival.status != 'cancelled'
            ? arrival.payment_status + '-border'
            : 'cancelled-border'
        }} " style="page-break-before: always; padding: 0px" id="restData" id="{{ arrival.channel.name }}"
              [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul" id="ko">
                <li style="width: 10%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    <img src="assets/img/svgicons/{{
                      !darkMode ? 'all-channels' : 'otas/ota_dark'
                    }}/{{ chngealpha(arrival?.channel_image) }}.svg" class="imageOaChnnel capSvg" alt=""
                      *ngIf="exte" />
                    <img src="assets/img/svgicons/{{
                      !darkMode ? 'all-channels-webp' : 'webp-dark'
                    }}/{{ chngealpha(arrival?.channel_image) }}.webp" class="imageOaChnnelweb capSvg" alt=""
                      *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 13.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">{{
                    arrival.guest_firstname}}</a>
                </li>
                <li style="width: 10.5%">
                  <a data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)" class="cp">
                    {{ daysTotal(arrival.date_departure, arrival.date_arrival) }}
                  </a>
                </li>
                <li style="width: 11.5%" data-target="#myModal" data-toggle="modal" (click)="my_object = arrival">
                  <a>{{ arrival.created_at | date: "d MMM | h:mm " }} </a>
                </li>
                <li style="width: 10.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-in-{{
  arrival.status == 'cancelled'
    ? 'red'
    : arrival.payment_status == 'unpaid'
    ? 'orange'
    : arrival.payment_status == 'paid'
    ? 'green'
    : arrival.payment_status == 'paynow'
    ? 'orange'
    : null
  }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDateArr(arrival) | date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 11.5%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
  arrival.status == 'cancelled'
    ? 'red'
    : arrival.payment_status == 'unpaid'
    ? 'orange'
    : arrival.payment_status == 'paid'
    ? 'green'
    : arrival.payment_status == 'paynow'
    ? 'orange'
    : null
  }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{ chngeDatedep(arrival) | date: "d MMM"
                    }}</a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21) );">
                      {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                    </button>
                    <button *ngIf=" arrival.payment_status == 'paynow' && arrival.status == 'cancelled'  " type="submit"
                      class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"
                      style=" background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0));">
                      Due
                    </button>
                    <button *ngIf="arrival.payment_status == 'unpaid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(
  rgb(230, 60, 2),
  rgb(228, 91, 0),
  rgb(148, 59, 0)
  );">
                      Due
                    </button> -->
                  </a>
                </li>
                <li style="width: 12%">
                  <a>
                    <button *ngIf="arrival.status == 'confirm'" type="submit" class="paid w-button btn-0" style="
  background: linear-gradient(
    rgb(10, 222, 36),
    rgb(8, 113, 21)
  );
  ">
                      Confirmed
                    </button>
                    <button *ngIf="arrival.status == 'hold'" type="submit" class="paid w-button btn-0 HoldBtn"
                      data-toggle="modal" data-target="#payNow" (click)="paynowMainRes(arrival)">
                      Hold
                    </button>
                    <button *ngIf="arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="
  background: linear-gradient(rgb(249 25 25), rgb(115 6 35));
  ">
                      Cancelled
                    </button>

                    <button *ngIf="arrival.status == 'modified'" type="submit" class="paid w-button btn-0" style="
  background: linear-gradient(rgb(0, 207, 241), rgb(8, 95, 121))">
                      Modified
                    </button>

                  </a>
                </li>
                <li class="totalLast">{{ getRoomOfGuest(arrival) }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="today_arrival" class="tab-pane fade" style="margin-top: 5px">
      <div class="card card-table table-responsive" style="
            border-radius: 10px 10px 0px 0px;
            box-shadow: 1px -6px 6px #00000040;
          " [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
        <div class="col-md-12 blue-head" style="padding: 0px">
          <ul class="blue-ul">
            <li style="width: 10%; border-right: 1px solid white">
              <a>Channel</a>
            </li>
            <li style="width: 15%; border-right: 1px solid white">
              <a>Guest</a>
            </li>
            <li style="width: 15%; border-right: 1px solid white">
              <a>Reservation Id</a>
            </li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Date Booked</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Check In</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Check Out</a>
            </li>
            <li style="width: 15%; border-right: 1px solid white">
              <a>Payment</a>
            </li>
            <li style="width: 10%"><a>Room</a></li>
          </ul>
        </div>
        <div class="scroll no_scroll">
          <div id="list3" *ngIf="param">
            <div
              *ngFor="let arrival of today_arrival | search : 'guest_firstname,reservation_id,channel_name,name,status,all,property_id,guest_phone,payment_status' : param"
              class="col-md-12 border-div {{ arrival.status != 'cancelled' ? arrival.payment_status + '-border' : 'cancelled-border' }}"
              style="padding: 0px" id="loop" [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul nav1">
                <li style="width: 10%">
                  <a class="cp">
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels' : 'otas/ota_dark'}}/{{ chngealpha(arrival?.channel_image) }}.svg"
                      class="imageOaChnnel capSvg" alt="" *ngIf="exte" />
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels-webp' : 'webp-dark'}}/{{ chngealpha(arrival?.channel_image) }}.webp"
                      class="imageOaChnnelweb capSvg" alt="" *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 14.5%">
                  <a class="cp" data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)">{{
                    formatGuestName(arrival.bookingitems[0].guest_name) }}</a>
                </li>
                <li style="width: 15%">
                  <a class="cp">{{ arrival.reservation_id }} </a>
                </li>
                <li style="width: 12%">
                  <a>{{ arrival.bookingitems[0].created_at | date: "d MMM | h:mm " }}</a>
                </li>
                <li style="width: 11.7%; text-align:left;padding-left:10px;">
                  <a>
                    <img src="assets/img/dashboard/in-house2.svg" alt="" class="svgico capSvg"
                      style="height: 30px; margin-top: -8px;margin-right: 5px;" /> {{
                    arrival.bookingitems[0].check_in | date: "d MMM" }} | {{
                    arrival.bookingitems[0]?.check_in_time !== null && arrival.bookingitems[0]?.check_in_time !== "0"
                    ? arrival.bookingitems[0]?.check_in_time
                    : '1:00:00 PM'
                    }}
                  </a>
                </li>
                <li style="width: 13%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
                                        arrival.status == 'cancelled'
                                      ? 'red'
                                      : arrival.payment_status == 'unpaid'
                                      ? 'orange'
                                      : arrival.payment_status == 'paid'
                                      ? 'green'
                                      : arrival.payment_status == 'paynow'
                                      ? 'orange'
                                      : null
                                      }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{
                    arrival.bookingitems[0].check_out | date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 14%">
                  <a>
                    <!-- <button type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0));">On Hold</button> -->

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(rgb(10, 222, 36),rgb(8, 113, 21));">
                                          {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                                      </button>
                                      <button *ngIf="arrival.payment_status == 'paynow' && arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0), rgb(148, 59, 0) ); ">
                                          Unpaid
                                      </button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"style="background: linear-gradient(#7340ce, #501cad, #5b34a0)" data-toggle="modal"data-target="#payNow" (click)="paynowMainRes(arrival)"> Paynow</button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0) );" data-toggle="modal">
                                          Unpaid
                                      </button> -->

                    <!-- payment status button -> (working on saud) 👇-->

                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>

                  </a>
                </li>
                <li class="totalLast" style="padding: 0 0 0 20px; width:9%;">
                  <a>{{ arrival.bookingitems[0] && arrival.bookingitems[0].roomnum &&
                    arrival.bookingitems[0].roomnum.room_number }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div id="range" *ngIf="sortedActivities1">
            <div *ngFor="let arrival of sortedActivities1" class="col-md-12 border-div
                                {{
                                  arrival.status != 'cancelled'
                                    ? arrival.payment_status + '-border'
                                    : 'cancelled-border'
                                }} " style="padding: 0px" id="loop"
              [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">

              <ul class="grey-ul nav1">
                <li style="width: 10%">

                  <a class="cp">
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels' : 'otas/ota_dark'}}/{{ chngealpha(arrival?.channel_image) }}.svg"
                      class="imageOaChnnel capSvg" alt="" *ngIf="exte" />
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels-webp' : 'webp-dark'}}/{{ chngealpha(arrival?.channel_image) }}.webp"
                      class="imageOaChnnelweb capSvg" alt="" *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 14.5%">
                  <a class="cp" data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)">{{
                    formatGuestName(arrival.bookingitems[0].guest_name) }}</a>
                </li>
                <li style="width: 15%">
                  <a class="cp">{{ arrival.reservation_id }} </a>
                </li>
                <li style="width: 12%">
                  <a>{{ arrival.bookingitems[0].created_at | date: "d MMM | h:mm " }}</a>
                </li>
                <li style="width: 11.7%; text-align:left;padding-left:10px;">
                  <a>
                    <img src="assets/img/dashboard/in-house2.svg" alt="" class="svgico capSvg"
                      style="height: 30px; margin-top: -8px;margin-right: 5px;" /> {{
                    arrival.bookingitems[0].check_in | date: "d MMM" }} | {{
                    arrival.bookingitems[0]?.check_in_time !== null && arrival.bookingitems[0]?.check_in_time !== "0"
                    ? arrival.bookingitems[0]?.check_in_time
                    : '1:00:00 PM'
                    }}
                  </a>
                </li>
                <li style="width: 13%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
                                            arrival.status == 'cancelled'
                                          ? 'red'
                                          : arrival.payment_status == 'unpaid'
                                          ? 'orange'
                                          : arrival.payment_status == 'paid'
                                          ? 'green'
                                          : arrival.payment_status == 'paynow'
                                          ? 'orange'
                                          : null
                                          }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{
                    arrival.bookingitems[0].check_out | date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 14%">
                  <a>
                    <!-- <button type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0));">On Hold</button> -->

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(rgb(10, 222, 36),rgb(8, 113, 21));">
                                              {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                                          </button>
                                          <button *ngIf="arrival.payment_status == 'paynow' && arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0), rgb(148, 59, 0) ); ">
                                              Unpaid
                                          </button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"style="background: linear-gradient(#7340ce, #501cad, #5b34a0)" data-toggle="modal"data-target="#payNow" (click)="paynowMainRes(arrival)"> Paynow</button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0) );" data-toggle="modal">
                                              Unpaid
                                          </button> -->

                    <!-- payment status button -> (working on saud) 👇-->

                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>
                  </a>
                </li>
                <li class="totalLast" style="padding: 0 0 0 20px; width:9%;">
                  <a>{{ arrival.bookingitems[0] && arrival.bookingitems[0].roomnum &&
                    arrival.bookingitems[0].roomnum.room_number }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div id="today_arrival_list4">
            <div *ngFor="let arrival of today_arrival" class="col-md-12 border-div
                                {{
                                    arrival.status != 'cancelled'
                                      ? arrival.payment_status + '-border'
                                      : 'cancelled-border'
                                  }}
                                " style="page-break-before: always; padding: 0px" id="restData1"
              [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul nav1">
                <li style="width: 10%">

                  <a class="cp">
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels' : 'otas/ota_dark'}}/{{ chngealpha(arrival?.channel_image) }}.svg"
                      class="imageOaChnnel capSvg" alt="" *ngIf="exte" />
                    <img
                      src="assets/img/svgicons/{{!darkMode ? 'all-channels-webp' : 'webp-dark'}}/{{ chngealpha(arrival?.channel_image) }}.webp"
                      class="imageOaChnnelweb capSvg" alt="" *ngIf="!exte" />
                  </a>
                </li>
                <li style="width: 14.5%">
                  <a class="cp" data-toggle="modal" data-target="#reservation-popup" (click)="modal_preview(arrival)">{{
                    formatGuestName(arrival.bookingitems[0].guest_name) }}</a>
                </li>
                <li style="width: 15%">
                  <a class="cp">{{ arrival.reservation_id }} </a>
                </li>
                <li style="width: 12%">
                  <a>{{ arrival.bookingitems[0].created_at | date: "d MMM | h:mm " }}</a>
                </li>
                <li style="width: 11.7%; text-align:left;padding-left:10px;">
                  <a>
                    <img src="assets/img/dashboard/in-house2.svg" alt="" class="svgico capSvg"
                      style="height: 30px; margin-top: -8px;margin-right: 5px;" /> {{
                    arrival.bookingitems[0].check_in | date: "d MMM" }} | {{
                    arrival.bookingitems[0]?.check_in_time !== null && arrival.bookingitems[0]?.check_in_time !== "0"
                    ? arrival.bookingitems[0]?.check_in_time
                    : '1:00:00 PM'
                    }}
                  </a>
                </li>
                <li style="width: 13%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
                                            arrival.status == 'cancelled'
                                          ? 'red'
                                          : arrival.payment_status == 'unpaid'
                                          ? 'orange'
                                          : arrival.payment_status == 'paid'
                                          ? 'green'
                                          : arrival.payment_status == 'paynow'
                                          ? 'orange'
                                          : null
                                          }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" /> {{
                    arrival.bookingitems[0].check_out | date: "d MMM" }}
                  </a>
                </li>
                <li style="width: 14%">
                  <a>
                    <!-- <button type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2), rgb(228, 91, 0), rgb(148, 59, 0));">On Hold</button> -->

                    <!-- <button *ngIf="arrival.payment_status == 'paid'" type="submit" class="paid w-button btn-0" style=" background: linear-gradient(rgb(10, 222, 36),rgb(8, 113, 21));">
                                              {{ arrival.status == "cancelled" ? "Charged" : "Paid" }}
                                          </button>
                                          <button *ngIf="arrival.payment_status == 'paynow' && arrival.status == 'cancelled'" type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(230, 60, 2),rgb(228, 91, 0), rgb(148, 59, 0) ); ">
                                              Unpaid
                                          </button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0"style="background: linear-gradient(#7340ce, #501cad, #5b34a0)" data-toggle="modal"data-target="#payNow" (click)="paynowMainRes(arrival)"> Paynow</button> -->
                    <!-- <button *ngIf="arrival.payment_status == 'paynow'" type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2),rgb(228, 91, 0),rgb(148, 59, 0) );" data-toggle="modal">
                                              Unpaid
                                          </button> -->

                    <!-- payment status button -> (working on saud) 👇-->

                    <button type="submit" class="paid w-button btn-0"
                      [ngClass]="changeButtonTodayArrival(arrival)+'Btn'">
                      {{ changeButtonTodayArrival(arrival)}}
                    </button>

                  </a>
                </li>
                <li class="totalLast" style="padding: 0 0 0 20px; width:9%;">
                  <a>{{ arrival.bookingitems[0] && arrival.bookingitems[0].roomnum &&
                    arrival.bookingitems[0].roomnum.room_number }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="checked_in" class="tab-pane fade" style="margin-top: 5px">
      <div class="card card-table table-responsive" style="
                border-radius: 10px;
                box-shadow: 1px -6px 6px #00000040;
              " [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
        <div class="col-md-12 blue-head" style="padding: 0px">
          <ul class="blue-ul">
            <li style="width: 10%; border-right: 1px solid white">
              <a>Booked By</a>
            </li>
            <li style="width: 15%; border-right: 1px solid white">
              <a>Guest</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Reservation Id</a>
            </li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Date Booked</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Check In</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Check Out</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Receipt</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Payment</a>
            </li>
            <li style="width: 10%"><a>Room</a></li>
          </ul>
        </div>
        <div id="list4" class="scroll no_scroll">
          <ng-container *ngFor="let arrival of checked_in">
            <div class="col-md-12 border-div {{ changeBorderColor(arrival) }}"
              style="page-break-before: always; padding: 0px" id="restData"
              [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">
              <ul class="grey-ul nav1">
                <li style="width: 10%">
                  <a class="cp">{{ arrival.booking.guest_firstname }}</a>
                </li>
                <li style="width: 14.5%">
                  <a class="cp">{{ formatGuestName(arrival.guest_name) }}
                  </a>
                </li>
                <li style="width: 12.3%">
                  <a class="cp">{{ arrival.booking.reservation_id }} </a>
                </li>
                <li style="width: 11.5%">
                  <a>{{ arrival.booking.created_at | date: "d MMM | h:mm " }}</a>
                </li>
                <li style="width: 12%;text-align:left;padding-left: 10px;">
                  <a>
                    <img src="assets/img/dashboard/in-house2.svg" alt="" class="svgico capSvg"
                      style="height: 30px; margin-top: -8px;margin-right:5px" />
                    {{ (arrival?.check_in != null || arrival.check_in != "Invalid date") ? (arrival?.check_in| date: "d
                    MMM "):(arrival?.date_arrival| date: "d MMM" ) }} | {{ arrival?.check_in_time !=
                    null?arrival?.check_in_time:'1:00:00 PM' }}
                  </a>
                </li>
                <li style="width: 11%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{
                                arrival.booking.status == 'cancelled'
                                  ? 'red'
                                  : arrival.booking.payment_status == 'unpaid'
                                  ? 'orange'
                                  : arrival.booking.payment_status == 'paid'
                                  ? 'green'
                                  : arrival.booking.payment_status == 'paynow'
                                  ? 'orange'
                                  : arrival.booking.payment_status == 'deposite'
                                  ? 'green'
                                  : null
                              }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg pr-2" />{{
                    arrival?.check_out !=null? (arrival?.check_out | date: "d MMM" )
                    :(arrival?.date_departure | date: "d MMM") }}</a>
                </li>
                <li style="width: 8%">
                  <a class="cp btn-0 w-button" (click)="populateInvoice(arrival)" data-toggle="modal"
                    data-target="#payNowGuest_view"
                    style="line-height: 18px; color: white!important; background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0));">
                    <img *ngIf="arrival?.isMaster == true" src="assets/img/invoices/bulk-booking-white.svg"
                      alt="bulk-booking" style="height: 20px;padding-right: 5px;">
                    Invoice
                  </a>
                </li>
                <li style="width: 12%">
                  <a>
                    <!-- <button type="submit" class="paid w-button btn-0" style="background: linear-gradient( rgb(230, 60, 2),rgb(228, 91, 0), rgb(148, 59, 0));">On Hold</button> -->
                    <!-- <button *ngIf="arrival.booking.payment_status == 'paid'" type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21) );">
                                            Paid
                                        </button> -->
                    <!-- <button *ngIf=" arrival.booking.payment_status == 'paynow' && arrival.booking.payment_status == 'deposite'  " type="submit" class="paid w-button btn-0" style=" background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0)); " data-toggle="modal" data-target="#payNowGuest">Unpaid </button> -->
                    <!-- <button *ngIf="arrival.booking.payment_status == 'paynow' || arrival.booking.payment_status == 'deposite' || arrival.booking.payment_status == 'unpaid' " type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0))">Unpaid </button> -->
                    <!-- <button *ngIf="arrival.booking.payment_status == 'paynow' || arrival.booking.payment_status == 'unpaid' " type="submit" class="paid w-button btn-0" style="background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0))">
                                            Unpaid
                                        </button>

                                        <button *ngIf="arrival.booking.payment_status == 'deposite'" type="submit" class="paid w-button btn-0" style="background: linear-gradient(#7340ce, #501cad, #5b34a0);">
                                            Deposite
                                        </button> -->

                    <!-- payment status button -> (working on saud) 👇-->

                    <button type="submit" class="paid w-button btn-0" [ngClass]="changeButtonCheckedIn(arrival)+'Btn'">
                      {{ changeButtonCheckedIn(arrival)}}
                    </button>

                  </a>
                </li>
                <li class="totalLast" style="padding: 0 0 0 20px; width:7%;">
                  <!-- <a>{{ arrival.roomnum.room_number }}</a> -->
                  <a>
                    {{ arrival.roomnum.room_number}}
                  </a>
                  <a>{{ arrival.bookingitems && arrival.bookingitems[0] &&
                    arrival.bookingitems[0].roomnum
                    && arrival.bookingitems[0].roomnum.room_number }}</a>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div id="last_week_arrivals" class="tab-pane fade" style="margin-top: 5px">
      <div class="card card-table table-responsive" style=" border-radius: 10px;
                  box-shadow: 1px -6px 6px #00000040;
                " [ngClass]="darkMode ? 'card-0-dark' : ' card-09 '">
        <div class="col-md-12 blue-head" style="padding: 0px">
          <ul class="blue-ul">
            <li style="width: 12%; border-right: 1px solid white">
              <a>Booked By </a>
            </li>
            <li style="width: 15%; border-right: 1px solid white">
              <a>Guest</a>
            </li>
            <li style="width: 11%; border-right: 1px solid white">
              <a>Date Booked</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Check In</a>
            </li>
            <li style="width: 12%; border-right: 1px solid white">
              <a>Check Out</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Receipt</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Guest Status</a>
            </li>
            <li style="width: 10%; border-right: 1px solid white">
              <a>Payment Status</a>
            </li>
            <li style="width: 8%"><a>Room</a></li>
          </ul>
        </div>
        <div id="list4" class="scroll no_scroll">
          <ng-container *ngFor="let arrival of lastweek;index as i">
            <div class="col-md-12 border-div {{ changeBorderColor(arrival) }}"
              style="page-break-before: always; padding: 0px" id="restData"
              [ngClass]="darkMode ? 'dark-border-div' : ' border-div '">

              <ul class="grey-ul nav1">
                <li style="width: 12%">
                  <a class="cp">{{ arrival.booking.guest_firstname }}</a>
                </li>
                <li style="width: 15%">
                  <a class="cp">{{ formatGuestName(arrival.guest_name) }}</a>
                </li>
                <li style="width: 11%">
                  <a>{{ arrival.booking.created_at | date: "d MMM | h:mm " }}</a>
                </li>
                <li style="width: 13%;text-align:left;padding-left:25px;">
                  <a>
                    <img src="assets/img/dashboard/in-house2.svg" alt="" class="svgico capSvg"
                      style="height: 30px; margin-top: -8px;margin-right: 5px;" />
                    {{
                    arrival.check_in !=null ? (arrival.check_in| date: "d MMM "):(arrival.date_arrival
                    | date: "d MMM " ) }} | {{ arrival.check_in_time !=null ? arrival.check_in_time:'1:00:00 PM'
                    }}
                  </a>
                </li>

                <li style="width: 11%">
                  <a>
                    <img src="assets/img/newstaticicons/arrivals/new/check-out-{{                            arrival.booking.status == 'cancelled'
                              ? 'red'
                              : arrival.booking.payment_status == 'unpaid'
                              ? 'orange'
                              : arrival.booking.payment_status == 'paid'
                              ? 'green'
                              : arrival.booking.payment_status == 'paynow'
                              ? 'orange'
                              : arrival.booking.payment_status == 'deposite'
                              ? 'green'
                              : null
                          }}.{{ exte == true ? 'svg' : 'webp' }}" alt="" class="svgico capSvg" />
                    <!-- {{ arrival.check_out !=null ? (arrival.check_out | date: "d MMM" ) :
                    (arrival.date_departure | date: "d MMM")
                    }} -->
                    {{ arrival.updated_at | date: "d MMM | h:mm" }}
                  </a>
                </li>
                <li style="width: 10%;">
                  <a class="cp btn-0 w-button" (click)="populateInvoice(arrival)" data-toggle="modal"
                    data-target="#payNowGuest_view"
                    style="line-height: 18px; color: white!important; background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0));">
                    <img *ngIf="arrival?.isMaster == true" src="assets/img/invoices/bulk-booking-white.svg"
                      alt="bulk-booking" style="height: 20px;padding-right: 5px;">
                    Invoice
                  </a>
                </li>
                <li style="width: 10%;">
                  <a class="cp btn-0 w-button" data-toggle="modal" data-target="#payNow"
                    style="line-height: 18px; color: white!important; background: linear-gradient( rgb(10, 222, 36), rgb(8, 113, 21)  );">{{
                    arrival.status=='confirm'?'Confirmed':arrival.status }} </a>
                </li>


                <li style="width: 10%">

                  <!-- <a>
                            <button  *ngIf=" arrival.booking.payment_status == 'unpaid'" type="submit" class="paid w-button btn-0" style="
                                background: linear-gradient(
                                  rgb(230, 60, 2),
                                  rgb(228, 91, 0),
                                  rgb(148, 59, 0)
                                );
                              " data-toggle="modal" data-target="#payNowGuest" (click)="getCheckOut(arrival);pay_now(arrival)">
                              Pay Now
                            </button>
                          </a> -->

                  <a>

                    <button *ngIf="arrival.booking.payment_status == 'paid'" type="submit" class="paid w-button btn-0"
                      style="background: linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21) );" data-toggle="modal"
                      [attr.data-target]="arrival.check_in !=null && '#payNowGuest'"
                      (click)="arrival.check_in !=null && getCheckOut(arrival);pay_now(arrival)">
                      Paid
                    </button>
                    <!-- <button *ngIf=" arrival.booking.payment_status == 'paynow' && arrival.booking.payment_status == 'deposite'  " type="submit"
                              class="paid w-button btn-0" style=" background: linear-gradient(rgb(230, 60, 2), rgb(228, 91, 0),rgb(148, 59, 0)); " data-toggle="modal" data-target="#payNowGuest">
                              Unpaid
                            </button> -->

                    <button
                      *ngIf=" arrival.booking.payment_status == 'paynow' || arrival.booking.payment_status == 'deposite' || arrival.booking.payment_status == 'unpaid' "
                      type="submit" class="paid w-button btn-0"
                      style="background: linear-gradient(#7340ce, #501cad, #5b34a0)" data-toggle="modal"
                      [attr.data-target]="arrival.check_in !=null && '#payNowGuest'"
                      (click)="arrival.check_in !=null && getCheckOut(arrival);pay_now(arrival)">
                      Paynow
                    </button>
                  </a>

                </li>
                <li class="totalLast">
                  <a>
                    {{ arrival.roomnum.room_number }}
                  </a>
                  <a>{{ arrival.bookingitems && arrival.bookingitems[0] &&
                    arrival.bookingitems[0].roomnum
                    && arrival.bookingitems[0].roomnum.room_number }}</a>
                </li>
              </ul>

            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- pagination in all 👉 (all_arrivals,today_arrival,checked_in,last_week_arrivals) 👇 -->
    <div class="global_pagination" [ngClass]="darkMode ? 'dark_global_pagination' : 'null'">
      <div class="text-center">
        <nav aria-label="Page navigation example text-center" id="paginav">
          <div class="pagination-center">
            <div style="float: left">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link {{selectedIndex == 0 ? 'disabled' : ''}}" aria-label="Previous"
                    (click)="first_page(1)" (click)="selectedIndex = 0">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link {{selectedIndex == 0 ? 'disabled' : ''}}" aria-label="Previous" (click)="prev()">
                    <span aria-hidden="true">&lsaquo;</span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left" class="cuspagidiv" id="cuspagidiv">
              <div class="page-item cusPagi" *ngFor="let numbers of pagi; let i = index"
                id="active{{ numbers.page_no }}" (click)="selectedIndex = i" [class.active]="selectedIndex == i"
                (click)="paginationPage(numbers.url, numbers.page_no)">
                <a class="page-link">{{ numbers.page_no }}</a>
              </div>
            </div>
            <div style="float: left">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link {{pagi.length == (selectedIndex + 1) ? 'disabled' : ''}}" aria-label="Next"
                    (click)="next()">
                    <span aria-hidden="true">&rsaquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </div>
            <div style="float: left">
              <ul class="pagination" id="myDIVActive">
                <li class="page-item">
                  <a class="page-link {{pagi.length == (selectedIndex + 1) ? 'disabled' : ''}}" aria-label="Next"
                    (click)="last_page()">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>

  <div id="summary" class="modal fade" role="dialog letmecheck" [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
    <form [formGroup]="reservation_form" *ngIf="this.reservation_form" id="formSum">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-width" *ngIf="my_object">
        <!-- Modal content-->
        <div class="modal-content bg-modal">
          <div class="modal-header">
            <div class="bluheader booking-sumary" (mouseover)="dodrag('summary')" (mouseout)="dontdrag('summary')">
              <h3 class="h3_blu_header bookingsummary">Reservation </h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetModal()">
                <strong id="cross" style="font-size: 21px">&#x2716;</strong>
              </button>
            </div>
          </div>
          <div class="modal-body dFlex">
            <div class="leftD" (mouseover)="dodrag('summary')" (mouseout)="dontdrag('summary')">
              <div class="col-md-6 zero-padding float-L" [ngClass]="disabled ? 'disClick' : 'null'">
                <div class="col-md-12 zero-padding" style="padding-right: 2px">
                  <div class="bluheader booking-sumary redbar">
                    <h3 class="h3_blu_header bookingsummary">
                      Stays
                      <!-- <i class="fa fa-info-circle checkInfo"
                                  [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                                  <div class="tooltip" style="width:90%"
                                      [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'"> {{toolChkData}}
                                  </div>
                              </i> -->
                    </h3>
                    <!-- <input type="checkbox" class="applyAll2" (change)="getValA($event)" [(ngModel)]="seperA" [ngModelOptions]="{standalone: true}" id="checkedForSep"> -->
                  </div>
                </div>
                <div class="col-md-12 zero-padding col-12-0ne onee"
                  style="padding-right: 2px; padding-bottom: 5px; overflow: inherit;">
                  <div class="greyBox">

                    <div class="modal-bar-2" [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}"
                      style="padding-left: 5px !important;">
                      <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                        style="max-height: 30px; border: none; padding-left: 0px">
                        <span class="input-group-addon"
                          style=" background: transparent; border: none; font-size: 24px; color: #00205b;  display: inline; padding-right: 1px;  ">
                          <img
                            src="assets/img/newstaticicons/{{ darkMode ? '/dashboard/check-in.svg' : 'dashboard/check-in.svg'  }}"
                            style="margin-left: 6px; height: 24px" lt="" />

                        </span>
                        <input type="hidden" name="date_to" />
                        <input type="hidden" name="date_from" />
                        <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                          [autoApply]="true" placeholder="Check In"
                          [locale]="{ format: 'DD MMM YYYY', separator: ' To ' }" [singleDatePicker]="true"
                          [formControl]="$any(reservation_form).get('date_arrival')"
                          (change)="map_boxes('true');calculate_days(); pro = []"
                          style="padding-left: 8px !important;" />
                      </div>
                    </div>
                    <div class="modal-bar-2 two" [ngStyle]="{'border-color': randomColors[5 % randomColors.length]}">
                      <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2"
                        style="max-height: 30px; border: none; padding-left: 0px">
                        <span class="input-group-addon"
                          style=" background: transparent; border: none; font-size: 24px; color: #00205b; display: inline; padding-right: 1px; ">
                          <img
                            src="assets/img/newstaticicons/{{darkMode ? 'dashboard/departure.svg' : 'dashboard/departure.svg'}}"
                            style="margin-left: 6px; height: 24px" alt="" />

                        </span>
                        <input type="hidden" name="date_to" />
                        <input type="hidden" name="date_from" />
                        <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                          [autoApply]="true" placeholder="Check Out"
                          [locale]="{ format: 'DD MMM YYYY', separator: ' To ' }" [singleDatePicker]="true"
                          [formControl]="$any(reservation_form).get('date_departure')" [disabled]="true"
                          (change)="map_boxes('true');calculate_days(); pro = []"
                          style="padding-left: 4px !important;" />
                      </div>
                    </div>
                    <div class="modal-bar-2 three timePickerEta"
                      [ngStyle]="{'border-color': randomColors[3 % randomColors.length]  }">
                      <img src="assets/img/newstaticicons/inventory/ETA-clock.svg" alt="" class="placeholder-img-modal"
                        style="height: 23px" />
                      <input type="time" class="form-control" style="
                                            height: 30px;
                                            border: none;
                                            padding: 10px 0px 10px 35px;
                                            color: #00205b;
                                            font-size: 14px;
                                          " placeholder="ETA" formControlName="eta" value="{{ my_object?.eta }}" />
                    </div>

                    <div class="modal-bar-2 four mb-0 timePickerEtd"
                      [ngStyle]="{'border-color': randomColors[7 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/ETD-clock.svg" alt=""
                        class="placeholder-img-modal" />
                      <input type="time" class="form-control" style="
                                              height: 30px;
                                              border: none;
                                              padding: 10px 0px 10px 35px;
                                              color: #00205b;
                                              font-size: 14px;
                                            " placeholder="ETD" formControlName="etd" value="{{ my_object?.etd }}" />
                    </div>
                    <div class="modal-bar-2 fourteen"
                      [ngStyle]="{'border-color': randomColors[4 % randomColors.length]}">
                      <img alt="" src="assets/img/newstaticicons/inventory{{
                                                    darkMode ? '/night-old' : '/night'
                                                  }}.svg" style="height: 20px" class="placeholder-img-modal"
                        *ngIf="showDayNight" />

                      <img alt="" src="assets/img/newstaticicons/inventory/icons-sun.png" *ngIf="!showDayNight"
                        style="height: 20px; width: 20px;">
                      <input type="text" class="form-control" style="
                                                    height: 30px;
                                                    border: none;
                                                    padding: 10px 0px 10px 35px;
                                                    color: #00205b;
                                                    font-size: 14px;
                                                  " placeholder="Total Nights" type="text" value=""
                        value="{{ totalNights == 0?1 :totalNights  }}" readonly />

                    </div>

                    <!-- <div
                    class="modal-bar-2 twenty4"
                    [ngStyle]="{
                      'border-color': randomColors[11 % randomColors.length]
                    }"
                  >
                    <img
                      src="assets/img/newstaticicons/arrivals/id-verified-light.svg"
                      alt=""
                      class="placeholder-img-modal channel-img"
                      style="margin-top: 4px"
                    />
                    <input
                      type="text"
                      class="form-control"
                      value=""
                      placeholder="Confirmation Id No"
                      style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 45px;
                        color: #00205b;
                        font-size: 14px;
                      "
                    />
                  </div> -->
                    <div class="modal-bar-2 eighteen" style="margin-top: 5px; margin-bottom: 0px"
                      [ngStyle]="{'border-color': randomColors[6 % randomColors.length]}">
                      <img alt="" src="assets/img/newstaticicons/arrivals/hotel.svg" style="height: 16px"
                        class="placeholder-img-modal" />
                      <input type="text" class="form-control" style="
                                                    height: 30px;
                                                    border: none;
                                                    padding: 10px 0px 10px 35px;
                                                    color: #00205b;
                                                    font-size: 14px;
                                                  " placeholder="Hotel Name" value="{{ my_object?.property?.name }}"
                        readonly />
                    </div>

                    <!-- <div class="modal-bar-2 twenty509" [ngStyle]="{
                                      'border-color': randomColors[12 % randomColors.length]
                                    }">
                                            <img src="assets/img/newstaticicons/arrivals/{{
                                        darkMode ? 'coins' : 'coins-light'
                                      }}.svg" alt="" style="height: 20px" class="placeholder-img-modal" />
                                            <input type="text" class="form-control" value="" placeholder="Currency"
                                                style="
                                        height: 30px;
                                        border: none;
                                        padding: 10px 0px 10px 35px;
                                        color: #00205b;
                                        font-size: 14px;
                                      " formControlName="currency" readonly />
                                        </div> -->
                    <!-- <div class="modal-bar-2 twenty1"
                                            [ngStyle]="{'border-color': randomColors[4 % randomColors.length]}">
                                            <img src="assets/img/newstaticicons/arrivals/hotelcollect.svg" alt=""
                                                class="placeholder-img-modal rmvZindex" />
                                            <input type="text" class="form-control" value="" placeholder="Business Model" style="
                                                    height: 30px;
                                                    border: none;
                                                    padding: 10px 0px 10px 35px;
                                                     color: #00205b;
                                                    font-size: 14px;
                                                  " formControlName="collect_details" />

                                            <span style=" height: 40px; font-weight: 700;
                                              border: none;
                                              padding: 10px 0px 10px 0px;
                                              color: #00205b;
                                              font-size: 14px;">
                                                {{current_arrival?.collect_details == 1 ? 'Expedia Collect' : 'Hotel Collect'}}
                                            </span>
                                        </div> -->
                    <div class="modal-bar-2 twenty55" id="flip"
                      [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}">
                      <div *ngFor="let update_weather of weather_update; let i = index">
                        <i class='wi wi-main wi-{{ update_weather.icon }}'
                          [ngStyle]="{'color': WHcolors[i%WHcolors.length]}"></i>
                        <div class="weatherDiv">
                          <span class="wh_name">{{update_weather.title}} </span>
                          <span class="wh_temp">{{update_weather.min_temp+'°'}} </span>
                          <span class="wh_con">{{update_weather.condition}} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-6 zero-padding float-R" [ngClass]="disabled ? 'disClick' : 'null'">
                <div class="col-md-12 zero-padding" style="padding-left: 3px">
                  <div class="bluheader booking-sumary redbar">
                    <h3 class="h3_blu_header bookingsummary">Payment</h3>
                  </div>
                </div>
                <div class="col-md-12 zero-padding onee"
                  style="padding-right: 2px; padding-bottom: 5px; overflow: inherit;">
                  <div class="greyBox ">
                    <div class="sticky_payment">
                      <div class="modal-bar-2 twenty1"
                        [ngStyle]="{'border-color': randomColors[3 % randomColors.length]}">
                        <img src="assets/img/newstaticicons/inventory/cardhold.svg" alt=""
                          class="placeholder-img-modal Rmvpos" />
                        <input type="text" class="form-control" value="" placeholder="Cardholder Name" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 0px 10px 0px;
                                                color: #00205b;
                                                font-size: 14px;
                                              " formControlName="payee_name" />
                      </div>
                    </div>
                    <div class="modal-bar-2 twenty2"
                      [ngStyle]="{'border-color': randomColors[4 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt=""
                        class="placeholder-img-modal rmvZindex" />
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom rmvpaddingleft"
                        placeholder=" Card Type " [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                      height: 30px;
                                                      border: none;
                                                      padding-left: 0px !important;
                                                      padding-right: 7px;
                                                    " formControlName="guest_card_type">
                        <ng-option value="Discover">Discover</ng-option>
                        <ng-option value="AX">American Express</ng-option>
                        <ng-option value="CB">Carte Blanche</ng-option>
                        <ng-option value="DN">Diners Club</ng-option>
                        <ng-option value="DS">Discover Card</ng-option>
                        <ng-option value="JC">Japan Credit Bureau</ng-option>
                        <ng-option value="MC">MasterCard</ng-option>
                        <ng-option value="UP">China Union Pay</ng-option>
                        <ng-option value="VI">Visa</ng-option>
                      </ng-select>
                    </div>
                    <div class="modal-bar-2 twenty3"
                      [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                        class="placeholder-img-modal rmvZindex" />
                      <input type="text" class="form-control" value="" placeholder="Card Number" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 0px 10px 0px;
                                                color: #00205b;
                                                font-size: 14px;
                                              " formControlName="guest_card" maxlength="20" />
                    </div>
                    <div class="col-md-6 zero-padding float-L" style="padding-right: 2px"
                      [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}">
                      <div class="modal-bar-2 twenty3"
                        [ngStyle]="{'border-color': randomColors[20 % randomColors.length]}">
                        <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                          class="placeholder-img-modal rmvZindex" />
                        <input class="form-control" name="x" size="5" maxlength="5" style="
                          height: 30px;
                          border: none;
                          padding: 10px 0px 10px 0px;
                          color: #00205b;
                          font-size: 14px;
                        " formControlName="guest_card_expiry" placeholder="MM | YY" />
                      </div>
                      <!-- <div *ngIf="submittedUpodated && b.booking_items['controls'][i]['controls'].guest_card_expiry.errors
                      " class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf=" b.booking_items['controls'][i]['controls'].guest_card_expiry.errors.required ">Required</strong>
                    </div> -->
                    </div>
                    <div class="col-md-6 zero-padding float-R" style="padding-left: 3px"
                      [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}">
                      <div class="modal-bar-2 twenty3"
                        [ngStyle]="{'border-color': randomColors[21 % randomColors.length]}">
                        <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                          class="placeholder-img-modal rmvZindex" />
                        <input type="number" class="form-control" placeholder="CVV" style="
                                                    height: 30px;
                                                    border: none;
                                                    padding: 10px 0px 10px 0px;
                                                    color: #00205b;
                                                    font-size: 14px;
                                                  " formControlName="guest_card_series_code" />
                      </div>
                      <!-- <div *ngIf="
                        submittedUpodated &&
                        b.booking_items['controls'][i]['controls'].guest_card_series_code.errors
                      " class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="
                          b.booking_items['controls'][i]['controls'].guest_card_series_code.errors.required
                        ">Required</strong>
                    </div> -->
                    </div>
                    <div class="modal-bar-2 twenty1 bookinginnerdrpdwn"
                      [ngStyle]="{'border-color': randomColors[3 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/arrivals/channel.svg" alt=""
                        class="placeholder-img-modal rmvZindex" />
                      <!-- <input type="text" class="form-control" value="" placeholder="Channel" style="
                                                          height: 30px;
                                                          border: none;
                                                          padding: 10px 0px 10px 35px;
                                                          color: #00205b;
                                                          font-size: 14px;
                                                        " formControlName="channel_name" /> -->
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Channel"
                        [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                height: 30px;
                                border: none;
                                padding-left: 0px;
                                padding-right: 7px;
                              " [items]="allChannels" bindLabel="name" bindValue="id" formControlName="channel_id"
                        (change)="getChannelId($event)">
                      </ng-select>
                    </div>
                    <div class="modal-bar-2 uii dynamoColor InnerdropDown"
                      [ngStyle]="{'border-color': randomColors[4 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                        class="placeholder-img-modal rmvZindex">
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                        placeholder="Payment Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                              height: 30px;
                                              border: none;
                                              padding-left: 0px;
                                              padding-right: 7px;" formControlName="payment_status">
                        <ng-option value="paid">Paid</ng-option>
                        <ng-option value="unpaid">Due</ng-option>
                        <ng-option value="deposite">Deposit</ng-option>
                      </ng-select>
                    </div>
                    <div class="modal-bar-2 uii dynamoColor InnerdropDown"
                      [ngStyle]="{'border-color': randomColors[1 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                        class="placeholder-img-modal rmvZindex">
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Status"
                        [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                      height: 30px;
                                                      border: none;
                                                      padding-left: 0px;
                                                      padding-right: 7px;
                                                      " formControlName="status">
                        <ng-option value="confirm">Confirmed</ng-option>
                        <ng-option value="hold">Hold</ng-option>
                        <ng-option value="cancelled">Cancelled</ng-option>
                        <ng-option value="modified">Modified</ng-option>
                      </ng-select>
                    </div>
                    <!-- <div class="modal-bar-2 twenty1"
                                            [ngStyle]="{'border-color': randomColors[3 % randomColors.length]}">
                                            <img src="assets/img/newstaticicons/arrivals/point-of-service-svgrepo-com.svg"
                                                alt="" class="placeholder-img-modal rmvZindex" />
                                            <input type="text" class="form-control" value="" placeholder="POS" style="
                                                        height: 30px;
                                                        border: none;
                                                        padding: 10px 0px 10px 0px;
                                                        color: #00205b;
                                                        font-size: 14px;
                                                      " formControlName="pos" />
                                        </div> -->

                  </div>
                </div>
              </div>


              <div class="col-md-6 zero-padding float-L">
                <div class="col-md-12 zero-padding" style="padding-right: 2px">
                  <div class="bluheader booking-sumary redbar redowithCOunt">
                    <h3 style="display: inline-block" class="h3_blu_header bookingsummary">
                      Who's Checking In
                      <!-- <i class="fa fa-info-circle checkInfo"
                                                              [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                                                              <div class="tooltip" style="width:91% !important; top: -32px !important; "
                                                                  [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'">If the guest checking in is not the same as payee: Guest will be required to give credit card information for In hotel expenses</div>
                                                          </i> -->
                    </h3>
                    <span class="rCou">{{reservation_form["controls"].booking_items["controls"].length
                      }}
                      <img src="assets/img/communications/plus-4.png" alt="" class="img-plus" *ngIf="!disabled"
                        (click)="addNewGuest(reservation_form['controls'].booking_items,null)">
                    </span>
                  </div>
                </div>
                <div class="col-md-12 zero-padding col-12-two oneeee " formArrayName="booking_items"
                  [ngStyle]="{ 'padding-right': '2px' }">
                  <div *ngFor="let guest of reservation_form['controls'].booking_items['controls']; let i = index"
                    class="collapsableDiv">
                    <div [formGroupName]="i">
                      <!-- <div class="sticky" [ngClass]="disabled ? 'disClick' : 'null'"> -->
                      <div class="sticky">
                        <label class="label-mr">
                          {{ this.my_object.bookingitems[i] &&
                          this.my_object.bookingitems[i].room ?
                          (this.my_object.bookingitems[i].room.display_name
                          !=''?this.my_object.bookingitems[i].room.display_name:this.my_object.bookingitems[i].room.roomtype.name):
                          'New Room' }}
                          <img src="{{
                                                      darkMode
                                                        ? 'assets/img/svgicons/otas/ota_dark/button_down_dark.svg'
                                                        : 'assets/img/reports/button_down.svg'
                                                    }}" alt="dropdown" class="collapseimg" [ngClass]="
                                                      i == 0 ? 'rotate collapseimg' : 'collapseimg'
                                                    " data-toggle="collapse" id="drop{{ i }}"
                            [attr.data-target]="'#demo' + i" (click)="rotateDrop('drop' + i)" />
                        </label>
                        <hr style=" margin: 0px;
                                            margin-bottom: 5px;
                                            margin-top: 5px;
                                          " />
                      </div>
                      <div class="" [ngClass]="disabled ? 'disClick' : 'null'">
                        <div id="demo{{ i }}" class="" [ngClass]="i == 0 ? 'collapse in' : 'collapse'">

                          <div class="row">
                            <div class="col-md-6 pr-1">
                              <div class="modal-bar-2 nine"
                                [ngStyle]="{'border-color':randomColors[16 % randomColors.length]}">
                                <!-- <img src="assets/img/newstaticicons/inventory{{ darkMode ? '/forchk/guest-name-infinity0' : '/forchk/full-name0'
                                                            }}.svg" alt="" class="placeholder-img-modal" style="height: 16px; position: relative;" /> -->
                                <i class="fa fa-users" style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                <input type="text" class="form-control" placeholder="Guest Name" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding: 10px 10px;
                                                              color: #00205b;
                                                              font-size: 14px;
                                                            " formControlName="guest_name"
                                  (input)="changeGuestName($event,i)" />
                              </div>
                            </div>
                            <div class="col-md-6 pl-1">
                              <div class="modal-bar-2 nine"
                                [ngStyle]="{'border-color':randomColors[16 % randomColors.length]}">
                                <i class="fa fa-users" style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                <input type="text" class="form-control" placeholder="Guest Name" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding: 10px 10px;
                                                              color: #00205b;
                                                              font-size: 14px;
                                                            " formControlName="guest_name" />
                                <div (click)="addguest(i)" style="display: flex; cursor:pointer">
                                  <i class="fa fa-plus"
                                    style="padding-left:4px; color: #00205b; padding-right: 10px; "></i>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="modal-bar-2 one" *ngIf="my_object.same_date === '0'"
                            [ngStyle]="{'border-color': randomColors[17 % randomColors.length]}">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2" style="
                                                          max-height: 30px;
                                                          border: none;
                                                          padding-left: 0px;
                                                        ">
                              <span class="input-group-addon" style="
                                                            background: transparent;
                                                            border: none;
                                                            font-size: 24px;
                                                            color: #00205b;
                                                            display: inline;
                                                            padding-right: 1px;
                                                          ">
                                <!-- <i class="fa fa-calendar-alt"></i> -->
                                <img
                                  src="assets/img/newstaticicons/{{ darkMode  ? 'inventory/forchk/check-in.svg' : 'dashboard/check-in.svg'  }}"
                                  style="margin-left: 6px; height: 24px; " class="rmvZindex" alt="" />
                              </span>
                              <input type="hidden" name="date_to" />
                              <input type="hidden" name="date_from" />
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="{{
                                                            current_arrival?.date_booked
                                                          }} Check In" [locale]="{
                                                            format: 'DD MMM YYYY',
                                                            separator: ' To '
                                                          }" [singleDatePicker]="true" formControlName="check_in"
                                (change)="map_boxes('true')" />
                            </div>
                          </div>
                          <div class="modal-bar-2 two" *ngIf="my_object.same_date === '0'"
                            [ngStyle]="{'border-color':randomColors[19 % randomColors.length]}">
                            <div class="form-control input_2 background-gray-2 padding-5 calendar-top-2" style="
                                                          max-height: 30px;
                                                          border: none;
                                                          padding-left: 0px;
                                                        ">
                              <span class="input-group-addon" style="
                                                            background: transparent;
                                                            border: none;
                                                            font-size: 24px;
                                                            color: #00205b;
                                                            display: inline;
                                                            padding-right: 1px;
                                                          ">
                                <img src="assets/img/newstaticicons/{{
                                                              darkMode
                                                                ? 'inventory/forchk/check-out.svg'
                                                                : 'dashboard/departure.svg'
                                                            }}" style="margin-left: 6px; height: 24px;" alt=""
                                  class="rmvZindex" />
                              </span>
                              <input type="hidden" name="date_to" />
                              <input type="hidden" name="date_from" />
                              <input type="text" ngxDaterangepickerMd class="form-control date_range_picker date-cal"
                                [autoApply]="true" placeholder="{{
                                                            current_arrival?.date_booked
                                                          }} Check Out" [locale]="{
                                                            format: 'DD MMM YYYY',
                                                            separator: ' To '
                                                          }" [singleDatePicker]="true" formControlName="check_out"
                                (change)="map_boxes('true')" />
                            </div>
                          </div>

                          <div class="modal-bar-2 six" id="Dnoneo{{ i }}"
                            [ngStyle]="{'border-color': randomColors[18 % randomColors.length]}">
                            <img src="assets/img/dashboard/in-house2.svg" alt="" style="
                                                          height: 22px;
                                                          width: 30px;
                                                          margin-top: 2px;
                                                          margin-left: -3px;
                                                        " class="placeholder-img-modal rmvZindex" id="status" />
                            <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Guest Status"
                              [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                          height: 30px;
                                                          border: none;
                                                          padding-left: 0px !important;
                                                          padding-right: 7px;
                                                        " formControlName="status"
                              (change)="getIdBookin(guest.value.id, i, guest);guest_status($event)">
                              <ng-option value="Pending">Arriving Soon</ng-option>
                              <ng-option value="Checked In">Check In</ng-option>
                              <ng-option value="No Show">No Show</ng-option>
                            </ng-select>
                          </div>

                          <div class="guestCheckedIncard {{ guest.value.status }}">
                            <div class="modal-bar-2 twenty2"
                              [ngStyle]="{'border-color': randomColors[10 % randomColors.length]}">
                              <img src="assets/img/newstaticicons/inventory/cardtype.svg" alt=""
                                class="placeholder-img-modal rmvZindex" />
                              <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                placeholder="Card Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                                                  height: 30px;
                                                                                  border: none;
                                                                                  padding-left: 35px;
                                                                                  padding-right: 7px;
                                                                            " formControlName="checkIn_card_type">
                                <ng-option value="Discover">Discover</ng-option>
                                <ng-option value="AX">American Express</ng-option>
                                <ng-option value="CB">Carte Blanche</ng-option>
                                <ng-option value="DN">Diners Club</ng-option>
                                <ng-option value="DS">Discover Card</ng-option>
                                <ng-option value="JC">Japan Credit Bureau</ng-option>
                                <ng-option value="MC">MasterCard</ng-option>
                                <ng-option value="UP">China Union Pay</ng-option>
                                <ng-option value="VI">Visa</ng-option>
                              </ng-select>
                            </div>
                            <div class="modal-bar-2 twenty3"
                              [ngStyle]="{'border-color': randomColors[23 % randomColors.length]}">
                              <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                                class="placeholder-img-modal" style="position: relative;" />
                              <input type="text" class="form-control" placeholder="Card Number" style="
                                                            height: 30px;
                                                            border: none;
                                                            padding: 10px 0px 10px 0px;
                                                            color: #00205b;
                                                            font-size: 14px;" maxlength="16"
                                formControlName="card_no" />
                            </div>
                            <!-- <div *ngIf="
                                                          submittedUpodated &&
                                                          b.booking_items['controls'][i]['controls'].card_no
                                                            .errors
                                                        " class="small text-danger no-margin invalid-feedback">
                                                        <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls']
                                                              .card_no.errors.required
                                                          ">Required</strong>
                                                        <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls']
                                                              .card_no.errors.pattern
                                                          ">Card no invalid</strong>
                                                      </div> -->
                            <div class="col-md-6 zero-padding" style="padding-right: 2px">
                              <div class="modal-bar-2 twenty3"
                                [ngStyle]="{'border-color': randomColors[20 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                                  class="placeholder-img-modal" style="position: relative;" />
                                <input class="form-control" name="x" size="5" maxlength="5"
                                  onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                                  style="
                                                              height: 30px;
                                                              border: none;
                                                              padding: 10px 0px 10px 0px;
                                                              color: #00205b;
                                                              font-size: 14px;
                                                            " formControlName="card_expiry" placeholder="MMM | YY" />
                              </div>
                              <!-- <div *ngIf="
                                                            submittedUpodated &&
                                                            b.booking_items['controls'][i]['controls']
                                                              .card_expiry.errors
                                                          " class="small text-danger no-margin invalid-feedback">
                                                          <strong *ngIf="
                                                              b.booking_items['controls'][i]['controls']
                                                                .card_expiry.errors.required
                                                            ">Required</strong>
                                                        </div> -->
                            </div>
                            <div class="col-md-6 zero-padding" style="padding-left: 3px">
                              <div class="modal-bar-2 twenty3"
                                [ngStyle]="{'border-color': randomColors[21 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory/cardnumber.svg" alt=""
                                  style="position: relative;" class="placeholder-img-modal" />
                                <input type="number" class="form-control" value="" placeholder="CVV" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding: 10px 0px 10px 0px;
                                                              color: #00205b;
                                                              font-size: 14px;
                                                            " pattern="/^\d+$/"
                                  onKeyPress="if(this.value.length==4) return false;" formControlName="card_cvv" />
                              </div>
                              <!-- <div *ngIf="
                                                                                    submittedUpodated &&
                                                                                    b.booking_items['controls'][i]['controls']
                                                                                      .card_cvv.errors
                                                                                  " class="small text-danger no-margin invalid-feedback">
                                                          <strong *ngIf="
                                                                                      b.booking_items['controls'][i]['controls']
                                                                                        .card_cvv.errors.required
                                                                                    ">Required
                                                          </strong>
                                                        </div> -->
                            </div>

                            <div class="modal-bar-2 twenty3"
                              [ngStyle]="{'border-color': randomColors[22 % randomColors.length]}">
                              <!-- <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal">
                                                                   <input type="text" class="form-control" value="" placeholder="Personal Id"
                                                                       style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px" formControlName="id_type"> -->
                              <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                class="placeholder-img-modal" style="height: 20px; position: relative;" />
                              <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                placeholder="ID Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                            height: 30px;
                                                            border: none;
                                                            padding-left: 0px;
                                                            padding-right: 7px;
                                                          " formControlName="id_type">
                                <ng-option value="State ID">State ID</ng-option>
                                <ng-option value="Driving License">Driving License
                                </ng-option>
                                <ng-option value="Passport">Passport</ng-option>
                              </ng-select>
                            </div>
                            <div *ngIf=" submittedUpodated &&
                                                          b.booking_items['controls'][i]['controls'].id_type
                                                            .errors
                                                        " class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls']
                                                              .id_type.errors.required
                                                          ">Required</strong>
                            </div>
                            <div class="modal-bar-2 twenty3"
                              [ngStyle]="{'border-color': randomColors[24 % randomColors.length]}">
                              <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                                class="placeholder-img-modal" style="position: relative;" />
                              <input type="text" class="form-control" value="" placeholder="Id Number" style="
                                                            height: 30px;
                                                            border: none;
                                                            padding: 10px 0px 10px 0px;
                                                            color: #00205b;
                                                            font-size: 14px;
                                                          " formControlName="id_value" />
                            </div>
                            <div *ngIf="
                                                          submittedUpodated &&
                                                          b.booking_items['controls'][i]['controls']
                                                            .id_value.errors
                                                        " class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls']
                                                              .id_value.errors.required
                                                          ">Required</strong>
                            </div>
                            <div class="modal-bar-2 twenty3"
                              [ngStyle]="{ 'border-color': randomColors[26 % randomColors.length]}">
                              <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                                class="placeholder-img-modal" style="position: relative;" />
                              <input type="text" class="form-control" value="" placeholder="Phone Number" style="
                                                            height: 30px;
                                                            border: none;
                                                            padding: 10px 0px 10px 0px;
                                                            color: #00205b;
                                                            font-size: 14px;
                                                          " formControlName="phone_number" />
                            </div>
                            <div *ngIf="
                                                          submittedUpodated &&
                                                          b.booking_items['controls'][i]['controls']
                                                            .phone_number.errors
                                                        " class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls']
                                                              .phone_number.errors.required
                                                          ">Required</strong>
                            </div>
                            <div class="modal-bar-2 twenty3 " title="{{ my_object?.guest_email }}"
                              [ngStyle]="{'border-color': randomColors[28 % randomColors.length]}">
                              <img src="assets/img/newstaticicons/inventory/{{
                                                            darkMode
                                                              ? 'forchk/mail-infinity0'
                                                              : '/r-messege-red'
                                                          }}.svg" alt="" class="placeholder-img-modal"
                                style="position: relative;" />
                              <input type="text" class="form-control ovrflow" value="" placeholder="Email" style="
                                                            white-space: nowrap;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            height: 30px;
                                                            border: none;
                                                            padding: 10px 10px 10px 0px;
                                                            color: #00205b;
                                                            font-size: 14px;
                                                          " formControlName="email" />
                            </div>
                            <!-- <div *ngIf="
                                                          submittedUpodated &&
                                                          b.booking_items['controls'][i]['controls'].email
                                                            .errors
                                                        " class="small text-danger no-margin invalid-feedback">
                                                        <strong *ngIf="
                                                            b.booking_items['controls'][i]['controls'].email
                                                              .errors.required
                                                          ">Required</strong>
                                                      </div> -->
                          </div>

                          <div class="modal-bar-2 eight0 filterAccec  " *ngIf="roomFilter == false"
                            [ngStyle]="{'border-color': randomColors[25 % randomColors.length] }">
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            condition(
                                                              my_object.bookingitems[i]?.room.smoking
                                                            ) == true
                                                              ? 'smoke'
                                                              : 'nosmoke'
                                                          }}.svg" style="height: 35px; margin-top: 6px" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            condition(
                                                              my_object.bookingitems[i]?.room
                                                                .wheelchairaccessibility
                                                            ) == true
                                                              ? 'wheel'
                                                              : 'nowheel'
                                                          }}.svg" style="height: 35px; margin-top: 6px" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            condition(
                                                              my_object.bookingitems[i]?.room.pets
                                                            ) == true
                                                              ? 'pets'
                                                              : 'nopets'
                                                          }}.svg" style="height: 35px; margin-top: 6px" />
                            </div>
                          </div>
                          <div class="modal-bar-2 eight0 filterAccec" *ngIf="roomFilter"
                            [ngStyle]="{'border-color': randomColors[27 % randomColors.length]}">
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            showsmoke == true ? 'smoke' : 'nosmoke'
                                                          }}.svg" style="height: 35px; margin-top: 6px"
                                (click)="chnageicon1(); RoomIconFilter()" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            showwheel == true ? 'wheel' : 'nowheel'
                                                          }}.svg" style="height: 35px; margin-top: 6px"
                                (click)="chnageicon2(); RoomIconFilter()" />
                            </div>
                            <div class="pointer">
                              <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/{{
                                                            showpets == true ? 'pets' : 'nopets'
                                                          }}.svg" style="height: 35px; margin-top: 6px"
                                (click)="chnageicon3(); RoomIconFilter()" />
                            </div>
                          </div>
                          <div class="modal-bar-2 five" style="text-transform: capitalize;"
                            [ngStyle]="{'border-color': randomColors[29 % randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory/{{
                                                          darkMode
                                                            ? 'forchk/room-id-infinity1dark'
                                                            : 'forchk/room-id-infinity1'
                                                        }}.svg" alt="" style="
                                                          height: 22px;
                                                          width: 30px;
                                                          margin-top: 2px;
                                                          margin-left: -3px; position: relative;
                                                        " class="placeholder-img-modal" />
                            <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Room Type"
                              style="
                                                          height: 30px;
                                                          border: none;
                                                          padding-left: 0px;
                                                          padding-right: 7px;
                                                        " [items]="room_name" bindLabel="name" bindValue="room_id"
                              formControlName="room_id" (change)="(getroomnumber($event,i,true)); map_boxes('true')"
                              [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                            </ng-select>
                          </div>
                          <div class="row">
                            <div class="col-md-8 pr-1">
                              <div class="modal-bar-2 eleven" style="margin-bottom: 5px"
                                [ngStyle]="{'border-color': randomColors[30 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory/forchk/package.svg" alt=""
                                  style="height: 26px; width: 30px;position: relative;"
                                  class="placeholder-img-modal imggg" />
                                <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                  placeholder="Promotion" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding-left: 0px;
                                                              padding-right: 7px;
                                                            " [items]="allPackages" bindLabel="package_name"
                                  bindValue="id" formControlName="package_id" (change)="chkPackage($event)"
                                  [ngClass]="darkMode ? 'bg_back' : 'bg_white'">
                                </ng-select>
                              </div>
                            </div>
                            <div class="col-md-4 pl-0">
                              <div class="modal-bar-2 eleven pr-4 pt-1 justify-content-center">
                                <img src="assets/img/newstaticicons/inventory/addon-icon.svg" alt="addons-icon"
                                  style="width: 35px; cursor: pointer;" class="h-auto placeholder-img-modal imggg"
                                  (click)="openAddOnsModal(i)">
                              </div>
                            </div>
                          </div>
                          <div class="modal-bar-2 six"
                            [ngStyle]="{'border-color': randomColors[34 % randomColors.length]}">
                            <img src="assets/img/newstaticicons/inventory{{
                                                          darkMode ? '/forchk/room_number.svg' : '/bed2.svg'
                                                        }}" alt="" style=" position: relative;
                                                          height: 22px;
                                                          width: 30px;
                                                          margin-top: 2px;
                                                          margin-left: -3px;
                                                        " class="placeholder-img-modal" />
                            <ng-select class="form-control input_2 padding-holder custom-0" placeholder="Room No"
                              [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                          height: 30px;
                                                          border: none;
                                                          padding-left: 0px;
                                                          padding-right: 7px;
                                                        " [items]="room_number" bindLabel="room_number" bindValue="id"
                              formControlName="room_no_id" (change)="changeRoomNo($event, i)">
                            </ng-select>
                          </div>

                          <div class="row" style="padding: 0px 20px">
                            <div class="col-md-4 zero-padding" style="padding-right: 2px">
                              <div class="modal-bar-2 twenty44"
                                [ngStyle]="{'border-color': randomColors[32 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory{{
                                                              darkMode
                                                                ? '/forchk/adult-dark.svg'
                                                                : '/forchk/adult0.svg'
                                                            }}" alt="" style="height: 20px; position: relative;"
                                  class="placeholder-img-modal" />
                                <input type="number" class="form-control inp adultPadding" placeholder="{{
                                                              resWidth == 1366
                                                                ? 'Ad..'
                                                                : resWidth == 1440
                                                                ? 'Adu..'
                                                                : resWidth == 1600
                                                                ? 'Adult.'
                                                                : 'Adult'
                                                            }}" formControlName="no_of_adults" />
                              </div>
                            </div>
                            <div class="col-md-4 zero-padding" style="padding-left: 3px; padding-right: 2px">
                              <div class="modal-bar-2 nine"
                                [ngStyle]="{'border-color':randomColors[31 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory/{{
                                                              darkMode ? 'infant-yellow' : 'infant'
                                                            }}.svg" alt="" style="height: 20px; position: relative;"
                                  class="placeholder-img-modal" />
                                <input type="number" class="form-control inp infantsPadding" placeholder="{{
                                                              resWidth == 1366
                                                                ? 'In..'
                                                                : resWidth == 1440
                                                                ? 'Inf..'
                                                                : resWidth == 1600
                                                                ? 'Infan.'
                                                                : 'Infant'
                                                            }}" formControlName="no_of_infants" />
                              </div>
                            </div>

                            <div class="col-md-4 zero-padding " style="padding-left: 3px">
                              <div class="modal-bar-2 ten"
                                [ngStyle]="{'border-color': randomColors[33 % randomColors.length]}">
                                <img src="assets/img/newstaticicons/inventory{{
                                                              darkMode
                                                                ? '/forchk/child-infinity0'
                                                                : '/child'
                                                            }}.svg" alt="" style="height: 20px; position: relative;"
                                  class="placeholder-img-modal" />
                                <input type="number" class="form-control inp childPadding" placeholder="{{
                                                              resWidth <= 1366
                                                                ? 'Ch..'
                                                                : resWidth <= 1440
                                                                ? 'Chi..'
                                                                : resWidth <= 1600
                                                                ? 'Child.'
                                                                : 'Children'
                                                            }}" formControlName="no_of_childs" />
                              </div>
                            </div>

                          </div>
                          <!-- <div class="modal-bar-2 ten" [ngStyle]="{
                                                        'border-color':
                                                          randomColors[36 % randomColors.length]
                                                      }">
                                                        <label for="" class="pcom">
                                                            <input type="radio" id="test{{ i }}" value="paid"
                                                                formControlName="breakfast" />
                                                            <label for="test{{ i }}" id="paid{{ i }}">Paid</label>
                                                        </label>
                                                        <label for="" class="pcom">
                                                            <input type="radio" id="test2{{ i }}" value="complimentry"
                                                                formControlName="breakfast" />
                                                            <label for="test2{{ i }}"
                                                                id="comp{{ i }}">Complimentry</label>
                                                        </label>
                                                    </div> -->
                          <div class="modal-bar-2 thirteen sendBtnLink" style="margin-bottom: 0px"
                            [ngStyle]="{ 'border-color': randomColors[37 % randomColors.length]}">
                            <span>Contactless Checkin Link </span>
                            <label>Send</label>
                          </div>
                          <div formArrayName="extra_persons">
                            <div *ngFor="let extraPerson of getExtraPersons(i).controls; let extraPersoni=index">
                              <div [formGroupName]="extraPersoni" class="row" style="padding: 0px 20px;">
                                <input type="hidden" formControlName="id">
                                <div class="modal-bar-2 nine">
                                  <i class="fa fa-users"
                                    style="font-size:20px; padding-bottom:0px; color: #804080;"></i>
                                  <input type="text" class="form-control" placeholder="Guest Name"
                                    style="height: 30px;border: none;padding: 10px 10px;color: #00205b;font-size: 14px"
                                    formControlName="name">
                                  <div (click)="removeGuest(i,extraPersoni)" style="display: flex; cursor:pointer">
                                    <i class="fa fa-times "
                                      style="padding-left:4px; color: #a00; padding-right: 10px; "></i>

                                  </div>
                                </div>
                                <div class="modal-bar-2 eighteen uii">
                                  <img src="assets/img/newstaticicons/inventory/idtype.svg" alt=""
                                    class="placeholder-img-modal" style="height: 20px;">
                                  <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                                    placeholder="ID Type" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                    style=" height: 30px;border:none ;padding-left: 35px;padding-right: 7px"
                                    formControlName="id_type">
                                    <ng-option value="State ID">State ID</ng-option>
                                    <ng-option value="Driving License">Driving
                                      License
                                    </ng-option>
                                    <ng-option value="Passport">Passport</ng-option>
                                  </ng-select>
                                </div>
                                <div class="modal-bar-2 nineteen">
                                  <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                                    class="placeholder-img-modal id-type" style="height: 20px;">
                                  <input type="text" class="form-control" value="" placeholder="ID Number"
                                    style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                                    formControlName="id_number">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ? addOns modal section start (working on saud 😊) -->
                <ng-container
                  *ngFor="let rooms of reservation_form['controls'].booking_items['controls']; let i = index">
                  <div class="rightD addOns-sideTray" id="addOnsDetail" [ngClass]="darkMode ? 'dark' : 'light'"
                    *ngIf="addOnsModal[i]" cdkDrag>
                    <div class="bluheader booking-sumary redbar mt-2">
                      <h3 class="h3_blu_header bookingsummary">
                        {{ guestNamesInreservationForm[i] || my_object.bookingitems[i]?.guest_name || 'Guest Name'}}
                      </h3>
                      <button type="button" class="close mb-2" (click)="addOnsModal[i] = false">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="bluheader booking-sumary mb-0">
                      <h3 class="h3_blu_header bookingsummary">
                        {{ this.roomNamesInreservationForm[i] || this.my_object.bookingitems[i]?.room?.display_name
                        ||this.my_object.bookingitems[i]?.roomtype.name || 'New Room'}}
                      </h3>
                      <h3 class="h3_blu_header bookingsummary">
                        {{ this.roomNoInreservationForm[i] || this.my_object.bookingitems[i]?.roomnum?.room_number ||
                        ''}}
                      </h3>
                    </div>
                    <div class="addons-section">
                      <ng-container *ngFor="let addOn of addOnsDisplayMap.get(i); index as j">
                        <div class="modal-bar-2 mt-2" [ngStyle]="{'border-color': WHcolors[j % WHcolors.length]}">
                          <div class="addon-items d-flex w-100 justify-content-between align-items-center pr-3">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                              <span class="text-left" (click)="updateCount(addOn, -1, i)">{{ addOn.name }}</span>
                              <span>
                                <button class="btn-add">
                                  <i class="fa fa-minus pr-1" (click)="updateCount(addOn, -1, i)"></i>
                                  {{getBookingExtraQuantity(addOn, i)}}
                                  <i class="fa fa-plus pl-1" (click)="updateCount(addOn, 1, i)"></i>
                                </button>
                              </span>
                              <span>{{ currency }}{{ (+addOn.price_after_tax * getBookingExtraQuantity(addOn, i)) |
                                number:'1.2-3' }}</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#ff6f00'}">
                      <div class="d-flex align-items-center">
                        <img src="assets/img/newstaticicons/arrivals/tax-icon.svg" alt="" class="placeholder-img-modal"
                          style="height: 20px;">
                      </div>
                      <h3 class="h3_blu_header bookingsummary text-dark">
                        13%
                      </h3>
                    </div>
                    <div class="modal-bar-2 justify-content-between pr-2" [ngStyle]="{'border-color': '#00a8ff'}">
                      <div class="d-flex align-items-center">
                        <img src="assets/img/newstaticicons/inventory/sigma-icon.svg" alt=""
                          class="placeholder-img-modal" style="height: 20px;">
                      </div>
                      <h3 class="h3_blu_header bookingsummary text-dark">
                        {{ currency }}{{calculateAddonsPriceByRoomIndex(i) | number:'1.2-3'}}
                      </h3>
                    </div>
                  </div>
                </ng-container>
                <!-- ? addOns modal section end (working on saud 😊) -->
              </div>

              <div class="col-md-6 zero-padding float-R" [ngClass]="disabled ? 'disClick' : 'null'">
                <div class="col-md-12 zero-padding" style="padding-left: 3px">
                  <div class="bluheader booking-sumary redbar">
                    <h3 class="h3_blu_header bookingsummary">
                      Booked By
                      <!-- <i class="fa fa-info-circle checkInfo"
                                  [ngClass]="darkMode ? 'darkToolo' : 'lightToolo'">
                                  <div class="tooltip" style="width:68% !important; top: -29px;"
                                      [ngClass]="applyChkOutDate ? 'null' : 'tooltipWidth'"> Check if the room
                                      payee is same as guest payment information will be auto polpulated
                                  </div>
                              </i> -->
                    </h3>
                  </div>
                </div>
                <div class="col-md-12 zero-padding col-12-three"
                  style="padding-left: 3px; height: auto; padding-right: 0px">
                  <div class="greyBox">
                    <div class="row">
                      <div class="col-md-6 pr-1">
                        <div class="modal-bar-2 twelve"
                          [ngStyle]="{'border-color': randomColors[38 % randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory{{
                                                        darkMode
                                                          ? '/forchk/full-name-infinity0'
                                                          : '/forchk/guest-name0'
                                                      }}.svg" alt="" class="placeholder-img-modal"
                            style="height: 16px" />
                          <input type="text" class="form-control" value="" placeholder="First Name" style="
                                                        height: 30px;
                                                        border: none;
                                                        padding: 10px 35px;
                                                        color: #00205b;
                                                        font-size: 14px;
                                                      " formControlName="guest_firstname"
                            value="{{ my_object?.guest_firstname }}" />
                        </div>
                      </div>
                      <div class="col-md-6 pl-1">
                        <div class="modal-bar-2 twelve"
                          [ngStyle]="{'border-color': randomColors[38 % randomColors.length]}">
                          <img src="assets/img/newstaticicons/inventory{{
                                                        darkMode
                                                          ? '/forchk/full-name-infinity0'
                                                          : '/forchk/guest-name0'
                                                      }}.svg" alt="" class="placeholder-img-modal"
                            style="height: 16px" />
                          <input type="text" class="form-control" value="" placeholder="Last Name" style="
                                                        height: 30px;
                                                        border: none;
                                                        padding: 10px 35px;
                                                        color: #00205b;
                                                        font-size: 14px;
                                                      " formControlName="guest_lastname"
                            value="{{ my_object?.guest_lastname }}" />
                        </div>
                      </div>
                    </div>
                    <div class="modal-bar-2 fourteen mb-5"
                      [ngStyle]="{  'border-color': randomColors[40 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/r-call-red.svg" alt=""
                        class="placeholder-img-modal location" style="height: 20px" />
                      <input type="text" class="form-control" value="" placeholder="Cell No" style="
                                                                                  height: 30px;
                                                                                  border: none;
                                                                                  padding: 10px 35px;
                                                                                  color: #00205b;
                                                                                  font-size: 14px;
                                                                                " formControlName="guest_phone"
                        value="{{ my_object?.guest_phone }}" />
                    </div>
                    <div class="modal-bar-2 fifteen" title="{{ my_object?.guest_email }}"
                      [ngStyle]="{'border-color': randomColors[39 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/{{
                                                darkMode ? 'forchk/mail-infinity0' : '/r-messege-red'
                                              }}.svg" alt="" class="placeholder-img-modal location"
                        style="height: 20px" />
                      <input type="text ovrflow " class="form-control" placeholder="Email" style="
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                height: 30px;
                                                border: none;
                                                padding: 10px 10px 10px 35px;
                                                color: #00205b;
                                                font-size: 14px;
                                              " formControlName="guest_email" value="{{ my_object?.guest_email }}" />
                    </div>
                    <div class="modal-bar-2 sixteen"
                      [ngStyle]="{'border-color': randomColors[42 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory{{
                                                darkMode
                                                  ? '/darkmode_arrival/location.svg'
                                                  : '/location.svg'
                                              }}" alt="" class="placeholder-img-modal location" style="height: 20px" />
                      <input type="text" class="form-control" value="" placeholder="Address" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 35px;
                                                color: #00205b;
                                                font-size: 14px;
                                              " formControlName="guest_address"
                        value="{{ my_object?.guest_address }}" />
                    </div>
                    <div class="modal-bar-2 seventeen"
                      [ngStyle]="{'border-color': randomColors[41 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory{{
                                                                        darkMode
                                                                          ? '/darkmode_arrival/location.svg'
                                                                          : '/location.svg'
                                                                      }}" alt="" class="placeholder-img-modal location"
                        style="height: 20px" />
                      <input type="text" class="form-control" value="" placeholder="City" style="
                                                                        height: 30px;
                                                                        border: none;
                                                                        padding: 10px 35px;
                                                                        color: #00205b;
                                                                        font-size: 14px;
                                                                      " formControlName="guest_city"
                        value="{{ my_object?.guest_city }}" />
                    </div>
                    <div class="modal-bar-2 eighteen uii"
                      [ngStyle]="{'border-color': randomColors[43 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/idtype.svg" alt="" class="placeholder-img-modal"
                        style="height: 20px" />
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="ID Type"
                        [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding-left: 35px;
                                                              padding-right: 7px;
                                                            " formControlName="personal_id_type">
                        <ng-option value="State ID">State ID</ng-option>
                        <ng-option value="Driving License">Driving License</ng-option>
                        <ng-option value="Passport">Passport</ng-option>
                      </ng-select>
                    </div>
                    <div class="modal-bar-2 nineteen"
                      [ngStyle]="{'border-color': randomColors[44 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/forchk/id-number-dark.svg" alt=""
                        class="placeholder-img-modal id-type" style="height: 20px" />
                      <input type="text" class="form-control" value="" placeholder="ID Number" style="
                                                              height: 30px;
                                                              border: none;
                                                              padding: 10px 0px 10px 35px;
                                                              color: #00205b;
                                                              font-size: 14px;
                                                            " formControlName="personal_id_value"
                        value="{{ my_object?.personal_id_value }}" />
                    </div>
                    <div class="modal-bar-2 twenty mb-0"
                      [ngStyle]="{'border-color': randomColors[45 % randomColors.length]}">
                      <img src="assets/img/newstaticicons/inventory/pervilige.svg" alt="" class="placeholder-img-modal"
                        style="height: 20px" />
                      <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                        placeholder="Priviliges" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                                          height: 30px;
                                                          border: none;
                                                          padding-left: 35px;
                                                          padding-right: 7px;
                                                        " formControlName="privilege">
                        <ng-option value="Loyality Card Holder">Loyality Card Holder</ng-option>
                        <ng-option value="Discount Offers">Discount Offers</ng-option>
                        <ng-option value="None">None</ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rightD" style="right: 0px; z-index: -1" id="sideBarDetail"
              [ngClass]="darkMode ? 'dark' : 'light'">
              <div class="sdt-scroll" style="    overflow: scroll;
                            max-height: 300px;
                            overflow-x: hidden;
                            overflow-y: hidden;">
                <div class="dataPrevDiv">


                  <div class="data">
                    <div *ngFor="let data of reservation_form.value.booking_items;let i = index"
                      [ngClass]="disabled ? 'disClick' : 'null'">
                      <div class="bluheader booking-sumary"
                        style="cursor: grab;margin-bottom: 0px;margin-top: 5px; text-align: center;">
                        <h3 class="h3_blu_header bookingsummary">
                          {{convertJson(reservation_form.value.booking_items,i) &&
                          (convertJson(reservation_form.value.booking_items,i).display_name
                          ||convertJson(reservation_form.value.booking_items,i).roomName)}}
                        </h3>
                      </div>
                      <div class="modal-bar-2 twenty6  dFlex rateDive" style="border-color: rebeccapurple;"
                        *ngFor="let rateDiv of convertJson(reservation_form.value.booking_items,i) && convertJson(reservation_form.value.booking_items,i).rates">
                        <div>{{rateDiv.date | date:'EEE dd MMM'}}</div>
                        <div [ngClass]="darkMode ? 'darkDD' : 'null'"> {{currency}}
                          {{rateDiv.price | number:'1.2-2'}}
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="totalDiscount" [ngClass]="darkMode ? 'DarkDic' : 'null'">
                    <div [ngClass]="disabled ? 'disClick' : 'null'">
                      <div class="modal-bar-2 twenty60" style="height: auto; padding: 0px"
                        [ngClass]="darkMode ? 'darkArea' : 'lightArea'">
                        <textarea name="" id="" cols="30" rows="5" placeholder=" Special requests"
                          formControlName="SpecialRequests"></textarea>
                      </div>

                      <div class="modal-bar-2 twenty69">
                        <img src="assets/img/newstaticicons/arrivals{{
                        darkMode ? '/addpersonicon1.svg' : '/addpersonicon1.svg'
                      }}" alt="" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" *ngIf="user.role == 'SuperAdmin' || Discounts == 'true'" class="form-control"
                          placeholder="Extra Person Fees" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                      " formControlName="extra_person_fee" [(ngModel)]="extra_person_fee" (keyup)="calculatPrice()" />

                        <input type="text" *ngIf="user.role != 'SuperAdmin' && Discounts == 'false'"
                          class="form-control" placeholder=" Extra Person fees" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                        cursor: no-drop;
                      " formControlName="extra_person_fee" readonly (click)="showerror()" id="dis" />
                      </div>
                      <div class="modal-bar-2 twenty57">
                        <img src="assets/img/newstaticicons/arrivals{{
                        darkMode ? '/Add-Ons.svg' : '/Add-Ons.svg'
                      }}" alt="Add-Ons" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" placeholder="Add-Ons"
                          style="height: 30px; border: none;padding: 10px 0px 10px 35px; color: #00205b;font-size: 14px;"
                          readonly [value]="calculateAddOnsPrice()" />
                      </div>
                      <!-- <div class="modal-bar-2 one0" *ngIf="ext == true">
                        <img src="assets/img/newstaticicons/arrivals{{
                        darkMode ? '/extras_icon.svg' : '/extras_icon.svg'
                      }}" alt="" style="height: 20px" class="placeholder-img-modal" />


                        <input type="text" class="form-control" placeholder="Extras" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                        cursor: no-drop;
                      " formControlName="extras" readonly id="dis" />
                      </div> -->

                      <div class="modal-bar-2 two0">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/darkmode/subtotal.svg':'/sigma-icon.svg'}}"
                          alt="" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" placeholder="Subtotal" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                        cursor: no-drop;
                      " formControlName="subtotal" readonly (click)="showerror()" id="dis" />
                      </div>
                      <div class="modal-bar-2 three0" *ngIf="bnb != 0">
                        <img src="assets/img/invoices/fnb-icon.svg" alt="" style="height: 20px"
                          class="placeholder-img-modal" />


                        <input type="text" class="form-control" placeholder="B n B" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                        cursor: no-drop;
                      " value="{{bnb}}" readonly (click)="showerror()" id="dis" />
                      </div>

                      <div class="modal-bar-2 twenty57 dFlex segmo">
                        <label (click)="changeSegment('true', 'segmon')" [ngClass]="segmonLeft ? 'active' : 'null'">Tax
                          Inclusive</label><label (click)="changeSegment('false', 'segmon')"
                          [ngClass]="!segmonLeft ? 'active' : 'null'">Tax Exclusive</label>
                        <div class="segmon" id="segmon"></div>
                      </div>

                      <div class="modal-bar-2 four0">
                        <img src="assets/img/newstaticicons/arrivals{{
                                                  darkMode ? '/discount-light.svg' : '/discount-light.svg'
                                                }}" alt="" style="height: 20px" class="placeholder-img-modal" />
                        <!-- <input type="text" *ngIf="user.role == 'SuperAdmin' || Discounts == 'true'"
                                                 class="form-control" value="" placeholder="Discount" style="
                                                  height: 30px;
                                                  border: none;
                                                  padding: 10px 0px 10px 35px;
                                                  color: #00205b;
                                                  font-size: 14px;
                                                " formControlName="discount" (keyup)="calculatPrice()" id="discountPromo" />

                                            <input type="text" *ngIf="user.role != 'SuperAdmin' && Discounts == 'false'"
                                                class="form-control" placeholder="Discount" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 0px 10px 35px;
                                                color: #00205b;
                                                font-size: 14px;
                                                cursor: no-drop;
                                              " formControlName="discount" readonly (click)="showerror()" id="dis" /> -->

                        <div *ngIf="user.role == 'SuperAdmin' || Discounts == 'true'"
                          style="display: flex; justify-content: space-between; width: 100%;">

                          <input type="number" min="0"
                            oninput="this.value = !!this.value && this.value >= 0 ? this.value.toFixed(3) : null"
                            class="form-control" value="" placeholder="Discount"
                            style="height: 30px;border: none;padding: 10px 0px 10px 45px;color: #00205b;font-size: 14px;width:60%;"
                            formControlName="discount" [max]="100" [min]="0" (keyup)="calculatPrice()"
                            id="discountPromo">

                          <!-- <input  type="number" style="font-weight:700; padding-top: 5px; min-width: 80px;" formControlName="discountAmount" value="discountedAmountReservationPopup" > -->
                          <!-- <span>{{currency+" "+discountedAmountReservationPopup.toFixed(2)}}</span> -->
                          <!-- <input
                                                  type="number"
                                                  class="form-control"
                                                  oninput="this.value =!!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                                  placeholder="DiscountedAmount"
                                                  [(ngModel)] = "discountedAmountReservationPopup" [ngModelOptions]="{standalone: true}"
                                                  (keyup) = "updateDiscountPercentage()"
                                                  style="height: 30px;border: none;padding: 10px 0px 10px 0px;color: #00205b;font-size: 14px"
                                                  > -->
                          <div
                            style="display:flex;align-items: center;border-left: 2px solid #80808061;height: 20px; margin-top: 5px;">
                            <!-- <img src="assets/img/newstaticicons/inventory{{
                                                        darkMode ? '/darkmode_arrival/dollar.svg' : '/dollar.svg'
                                                      }}" style="height: 20px;padding: 0 10px;" /> -->
                            <input type="number" class="form-control"
                              oninput="this.value =!!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                              placeholder="DiscountedAmount" [(ngModel)]="discountedAmountReservationPopup"
                              [ngModelOptions]="{standalone: true}" (keyup)="updateDiscountPercentage()"
                              style="height: 30px;border: none;padding: 10px 0px 10px 15px;color: #00205b;font-size: 14px">
                          </div>
                        </div>

                        <div *ngIf="user.role != 'SuperAdmin' &&  Discounts == 'false'">
                          <input type="text" class="form-control" value="" placeholder="Discount"
                            style="height: 30px;border: none;padding: 10px 0px 10px 35px;color: #00205b;font-size: 14px"
                            formControlName="discount" [disabled]="true" id="discountPromo">
                        </div>
                      </div>

                      <div class="modal-bar-2 five0">
                        <img
                          src="assets/img/newstaticicons/inventory{{darkMode ? '/amount-deposit.svg':'/amount-deposit.svg'}}"
                          alt="" style="height: 20px" class="placeholder-img-modal" />

                        <input type="text" class="form-control" placeholder="deposit" style="
                        height: 30px;
                        border: none;
                        padding: 10px 0px 10px 35px;
                        color: #00205b;
                        font-size: 14px;
                        /* cursor: no-drop; */
                      " formControlName="cashDeposit" id="dis" />
                      </div>

                      <div class="modal-bar-2 six0">
                        <img src="assets/img/newstaticicons/arrivals{{
                                                darkMode ? '/tax-icon.svg' : '/tax-icon.svg'
                                              }}" alt="" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" placeholder="tax" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 0px 10px 35px;
                                                color: #00205b;
                                                font-size: 14px;
                                                cursor: no-drop;
                                              " formControlName="Taxperr" readonly (click)="showerror()" />

                        <span style="font-weight: 700;">{{my_object.Taxper}}</span>
                        <span style="font-size: 10px; padding: 2px;">
                          <i class="fa fa-percent"></i>
                        </span>
                      </div>

                      <div class="modal-bar-2 nine0 mb-0">
                        <img src="assets/img/newstaticicons/arrivals{{
                                                    darkMode
                                                      ? '/dollar-light.svg'
                                                      : '/dollar-light.svg'
                                                  }}" alt="" style="height: 20px" class="placeholder-img-modal" />
                        <input type="text" class="form-control" value="" placeholder="Total Amount" style="
                                                height: 30px;
                                                border: none;
                                                padding: 10px 0px 10px 35px;
                                                color: #00205b;
                                                font-size: 14px;
                                              " formControlName="total" readonly />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="roundCurve" (click)="sideMenu('sideBarDetail')">
                <i class="fa fa-caret-right"></i>
              </div>
            </div>
          </div>
          <div class="modal-footer my-foot" style="padding-bottom: 5px !important"></div>
          <div class="col-md-12" style="padding-right: 0px; margin-top: 5px">
            <div class="popupBtns">
              <button class="btn btn-block send-0" *ngIf="!disabled" (click)="onSubmit()">
                Save
              </button>
              <button class="btn btn-block send-0" (click)="viewBtn()" *ngIf="disabled">
                Edit
              </button>
              <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close" (click)="resetModal()">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- payment Modal -->

  <div id="payNow" class="modal fade" role="dialog" [ngClass]="darkMode ? 'darkOn' : 'darkOff'">
    <div class="modal-dialog" *ngIf="paynowPopup">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <div class="bluheader booking-sumary" (mouseover)="dodrag('payNow')" (mouseout)="dontdrag('payNow')">
            <h3 class="h3_blu_header bookingsummary">Payment</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <strong id="cross" style="font-size: 21px">&#x2716;</strong>
            </button>
          </div>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="row">
              <div class="col-md-6 zero-padding" style="padding-right: 2px">
                <div class="modal-bar-2 bar one0">
                  <img alt="" class="placeholder-img-modal" style="margin-left: 3px; height: 24px" src="assets/img/newstaticicons/{{
                    darkMode
                      ? 'inventory/forchk/check-in.svg'
                      : 'dashboard/check-in.svg'
                  }}" />
                  <input type="text" class="form-control" value="{{ paynowPopup.date_arrival | date: 'EEE dd MMM' }}"
                    placeholder="Date Arrival" style="
                    height: 30px;
                    border: none;
                    padding: 10px 0px 10px 35px;
                    color: #00205b;
                    font-size: 14px;
                  " readonly />
                </div>
              </div>
              <div class="col-md-6 zero-padding" style="padding-left: 3px">
                <div class="bar three0">
                  <img alt="" class="placeholder-img-modal" src="assets/img/newstaticicons/{{
                    darkMode
                      ? 'inventory/forchk/check-out.svg'
                      : 'dashboard/departure.svg'
                  }}" style="margin-left: 3px; height: 24px" />
                  <input type="text" class="form-control" value="{{ paynowPopup.date_departure | date: 'EEE dd MMM' }}"
                    placeholder="Date Departure" style="
                    height: 30px;
                    border: none;
                    padding: 10px 0px 10px 35px;
                    color: #00205b;
                    font-size: 14px;
                  " readonly />
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6 zero-padding" style="padding-right: 2px">
                <div class="bar two0">
                  <img alt="" class="placeholder-img-modal" style="height: 20px" src="assets/img/newstaticicons/inventory{{
                    darkMode ? '/forchk/discount.svg' : '/discount.svg'
                  }}" />
                  <input type="text" class="form-control" value="{{
                    paynowPopup.discount == null ? '0' : paynowPopup.discount
                  }}" placeholder="Discount" style="
                    height: 30px;
                    border: none;
                    padding: 10px 0px 10px 35px;
                    color: #00205b;
                    font-size: 14px;
                  " readonly />
                </div>
              </div>
              <div class="col-md-6 zero-padding" style="padding-left: 3px">
                <div class="bar four0">
                  <img alt="" class="placeholder-img-modal" style="height: 20px" src="assets/img/newstaticicons/inventory{{
                    darkMode ? '/darkmode_arrival/dollar.svg' : '/dollar.svg'
                  }}" />
                  <input type="text" class="form-control" value="{{ paynowPopup.total }}" placeholder="Total" style="
                    height: 30px;
                    border: none;
                    padding: 10px 0px 10px 35px;
                    color: #00205b;
                    font-size: 14px;
                  " readonly />
                </div>
              </div>
            </div>
            <div class="row mt-5 drops">
              <form [formGroup]="reservation_form" *ngIf="this.reservation_form">
                <div class="col-md-6 zero-padding" style="padding-right: 2px">
                  <div class="bar five0">
                    <img src="assets/img/newstaticicons/inventory/channel.svg" alt=""
                      class="placeholder-img-modal channel-img" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                      placeholder="Payment Status" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                            height: 30px;
                                            border: none;
                                            padding-left: 35px;
                                            padding-right: 7px;
                                            background-color: transparent;
                                          " formControlName="payment_status" (change)="chkPayStatus($event)"
                      *ngIf="!paymentPaid">
                      <ng-option value="paid">Paid</ng-option>
                      <!-- <ng-option value="unpaid">Unpaid</ng-option> -->
                      <ng-option value="paynow">Paynow</ng-option>
                    </ng-select>
                    <input type="text" class="form-control" value="{{
                      paynowPopup.payment_status == 'paid' ? 'Paid' : null
                    }}" placeholder="Total" style="
                      height: 30px;
                      border: none;
                      padding: 10px 0px 10px 35px;
                      color: #00205b;
                      font-size: 14px;
                    " *ngIf="paymentPaid" readonly />
                  </div>
                </div>

                <div class="col-md-6 zero-padding" style="padding-left: 3px">
                  <div class="bar six0">
                    <img src="assets/img/newstaticicons/inventory/channel.svg" alt=""
                      class="placeholder-img-modal channel-img" />
                    <ng-select class="form-control input_2 padding-holder custom-0 input-custom" placeholder="Status"
                      [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                      height: 30px;
                      border: none;
                      padding-left: 35px;
                      padding-right: 7px;
                      background-color: transparent;
                    " formControlName="status">
                      <ng-option value="confirm">Confirmed</ng-option>
                      <ng-option value="hold">Hold</ng-option>
                      <ng-option value="cancelled" *ngIf="showCancellOnPay">Cancelled</ng-option>
                    </ng-select>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="bluheader booking-sumary mt-5 redbar">
            <h3 class="h3_blu_header bookingsummary">Share With</h3>
          </div>
          <div class="card shareButtons">
            <div class="row">
              <div class="col-md-12 zero-padding">
                <div class="bar seven0">
                  <div class="row">
                    <div class="col-md-4">
                      <img src="assets/img/newstaticicons/arrivals/{{
                        emailSend ? 'emailyes' : 'emailno'
                      }}.svg" alt="" (click)="changeSvg('emailSend')" />
                      <span> Email</span>
                    </div>
                    <div class="col-md-4">
                      <img src="assets/img/newstaticicons/arrivals/{{
                        whatsAppSend ? 'whatsappyes' : 'whatsappno'
                      }}.svg" alt="" (click)="changeSvg('whatsAppSend')" />
                      <span> Whats App</span>
                    </div>
                    <div class="col-md-4">
                      <img src="assets/img/newstaticicons/arrivals/{{
                        invoiceSend ? 'printyes' : 'printno'
                      }}.svg" alt="" (click)="changeSvg('invoiceSend')" />
                      <span> Print Invoice</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
         <button class="btn btn-block send-0"  >Save</button>
         <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">Close</button>
       </div> -->
        <div class="mFoo">
          <button class="btn btn-block send-0" (click)="savePayment()">
            Save
          </button>
          <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div id="payNowGuest" class="modal fade" role="dialog" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" [ngClass]="darkMode ? 'darkOn' : 'darkOff' ">

    <div class="modal-dialog" *ngIf="checkoutdata" style="justify-content: center; display: flex;">
      <!-- Modal content-->
      <div class="modal-content" style="width: 66%">
        <div class="modal-header">

          <div class="text-center">
            <!-- <img class="img-circle profile-img" src="{{ user ? base_url2+'/app/images/' + profile_picture : 'assets/img/nav/user.png' }}" alt="logo"> -->
            <img
              src="{{ logo != 'null' && logo != null ? base_url2+'/app/images/' + logo : 'assets/img/nav/logo.png' }}"
              id="brandLogo" class="img-circle profile-img">
          </div>

          <div class="text-center tax_id">
            <span>Tax ID: 12348567815</span>
          </div>

          <!-- <h3 class="h3_blu_header bookingsummary">
            <span class="" style="width:50%;display:block;float:left;font-size:18px; color: white;">Invoice
              Receipt</span>
          </h3> -->
          <!-- <h3 class="h3_blu_header bookingsummary">
            {{ checkoutdata.guest_name }}
          </h3> -->
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top: -5px">
            <strong id="cross" style="font-size: 21px">&#x2716;</strong>
          </button> -->
          <!-- <div class="row"> -->

          <div class="bluheader booking-sumary "
            style="display: block;   margin-bottom: 5px !important; border-radius: 8px; height: 40px; margin-bottom: 0px; margin-left: 0px; padding: 0px 10px;"
            (mouseover)="dodrag('payNowGuest')" (mouseout)="dontdrag('payNowGuest')">
            <div class="div">
              <span class="">26 May 22</span>
              <h4 class="">Invoice receipt</h4>
              <span class="">12:20</span>
            </div>
          </div>

          <div class="modal-body">

            <div class="show2 " (mouseover)="dodrag('payNowGuest')" (mouseout)="dontdrag('payNowGuest')">
              <div class="mdiv" style=" display: flex; padding-left: 10px; width: 100%;">
                <div class="div2">
                  <span style="color:white;">Stay</span>
                </div>
              </div>
            </div>

            <div class="card mt-5" style="padding: 5px 5px !important" (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">

              <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/inventory/forchk/guest-name0.svg" width="20" height="16">
                </div>
                <div class="mdiv">
                  <div class="div2">
                    <span class="">{{paynow_invoice.guest_name}}</span>
                  </div>
                </div>
              </div>

              <div class="bar two0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/dashboard/darkmode/reservation.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <span class="">{{paynow_invoice.booking_id}}</span>
                  </div>
                </div>
              </div>

              <div class="bar three0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/inventory/bed2.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2" style="display: flex; align-items: center;">
                    <span class="">{{paynow_invoice.roomnum.room_number}}</span>
                  </div>

                </div>
              </div>

              <div class="bar four0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/inventory//r-messege-red.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <span class="">{{paynow_invoice.email}}</span>
                  </div>
                </div>
              </div>

              <div class="bar five0" style="padding: 0px; display:flex; align-items: center;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/inventory/pervilige.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <span class="">{{paynow_invoice.booking.privilege}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="show2 " style="margin-top: 5px;" (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">
              <div class="mdiv" style=" display: flex;padding-left: 10px;;width: 100%;">
                <div class="div2">
                  <span style="color:white;">Room</span>
                </div>
              </div>
            </div>


            <div class="card mt-5" style="padding: 5px 5px !important" (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">
              <div class="date-box">
                <div class="season-date">
                  <span>{{convert_month(paynow_invoice.check_in)}}</span>
                  <span> {{convert_date(paynow_invoice.check_in)}}</span>
                </div>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <span class="sname" style="color: #C318DE !important; font-weight: 700;"
                  [ngStyle]="">{{paynow_invoice.room.roomtype.name}}</span>
                <img src="assets/img/paynow/orange-dots.svg" alt="" style="height: 14px; width: 60px;">
                <div class="season-date">
                  <span>{{convert_month(paynow_invoice.check_out)}}</span>
                  <span> {{convert_date(paynow_invoice.check_out)}}</span>
                </div>
              </div>

              <div>

                <div class="modal-bar-2 twenty6 mt-5 dFlex rateDive"
                  style="background-color: #f1f1f1; padding: 5px 10px !important; justify-content: left;"
                  *ngFor="let ratediv of price_json.rates;let i = index">
                  <div style="align-items: left;"> {{date_type(ratediv.date)}}</div>
                  <div [ngClass]="darkMode ? 'darkDD' : 'null'" style="align-items: right;">$
                    {{ratediv.price}}
                  </div>
                </div>



                <div class="bar six0" style="padding: 0px; display:flex; align-items: center;">
                  <div class="div1" style="padding: 0  10px;">
                    <img src="assets/img/newstaticicons/inventory/forchk/package.svg" width="25" height="20">
                  </div>
                  <div class="mdiv"
                    style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:grey">
                    <div class="div2" style="display: flex; align-items: center;">
                      <span class="">Promotion</span>
                    </div>
                    <div class="div3">

                      <span style="display: flex; align-items: center; font-weight: 700;">
                        <span [ngClass]="darkMode ? 'disCPDa' : 'disCP'">20</span>
                        <img src="assets/img/newstaticicons/rates-packages/discount.svg"
                          style="height: 35px; width: 35px; padding: 2px;" alt="">
                      </span>
                    </div>
                  </div>
                </div>



              </div>
            </div>


            <!-- <div class="bluheader redbar booking-sumary " style="margin-bottom: 0px; margin-top:  5px; padding-right: 8px; cursor: default">
                            <h3 class="h3_blu_header bookingsummary">Extras</h3>
                              <button type="button" class="addBtnExpensis">
                                <strong id="cross">&#x2B;</strong>
                              </button>
                        </div> -->






            <form [formGroup]="checked_out_guestform" *ngIf="checked_out_guestform">
              <div class="card mt-5" formArrayName="expenses">
                <div *ngFor=" let expen of checked_out_guestform['controls'].expenses['controls']; let i = index ">

                  <div class="row" [formGroupName]="i" [ngClass]="i != 0 ? 'mt-5' : ''">
                    <div class="col-md-6 zero-padding" style="padding-right: 2px">
                      <div class="bar seven0" style="padding: 0px">
                        <input type="text" class="form-control" placeholder="Extras" style=" height: 30px;
                                              border: none;
                                              color: #00205b;
                                              font-size: 14px;
                                              background-color: transparent;
                                            " formControlName="expense_name" readonly />
                      </div>
                      <!-- <div *ngIf="
                                            submittedCheckOut &&
                                            f.expenses['controls'][i]['controls'].expense_name.errors
                                          " class="small text-danger no-margin invalid-feedback">
                                            <strong *ngIf="
                                              f.expenses['controls'][i]['controls'].expense_name
                                                .errors.required
                                            ">Required</strong>
                                          </div> -->
                    </div>
                    <div class="col-md-6 zero-padding">
                      <div class="removeExpense">
                        <div class="bar six0" style="padding: 0px">
                          <input type="number" class="form-control" placeholder="Price" style="
                                                  height: 30px;
                                                  border: none;
                                                  color: #00205b;
                                                  font-size: 14px;
                                                  background-color: transparent;
                                                " formControlName="price" readonly />
                        </div>
                        <button class="redbar" (click)="removeExpence(i); expenceTotal()">
                          <strong id="cross">&#x2716;</strong>
                        </button>
                      </div>
                      <!-- <div *ngIf="
                                            submittedCheckOut &&
                                            f.expenses['controls'][i]['controls'].price.errors
                                          " class="small text-danger no-margin invalid-feedback">
                                            <strong *ngIf="
                                              f.expenses['controls'][i]['controls'].price.errors
                                                .required
                                            ">Required</strong>
                                          </div> -->
                    </div>
                  </div>

                </div>

                <div>
                  <div class="row" [ngClass]="
                                        checked_out_guestform['controls'].expenses['length'] == 0  ? '' : 'mt-5' ">
                    <div class="col-md-6 zero-padding" style="padding-right: 2px">
                      <div class="bar seven0" style="padding: 0px">
                        <ng-select class="form-control input_2 padding-holder custom-0 input-custom"
                          placeholder="Expense" [ngClass]="darkMode ? 'bg_back' : 'bg_white'" style="
                                              height: 30px;
                                              border: none;
                                              padding-left: 0px;
                                              padding-right: 7px;
                                              background-color: transparent;
                                            " [items]="allExpenses" bindLabel="name" bindValue="name"
                          [(ngModel)]="expenseVal" [ngModelOptions]="{ standalone: true }"
                          (change)="getExpenceVal(expenseVal)">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-md-6 zero-padding">
                      <div class="removeExpense">
                        <div class="bar eight0" style="padding: 0px">
                          <input type="number" class="form-control" placeholder="Price" style="
                                                height: 30px;
                                                border: none;
                                                color: #00205b;
                                                font-size: 14px;
                                                background-color: transparent;
                                              " [(ngModel)]="expensePrice" [ngModelOptions]="{ standalone: true }"
                            (keyup)="expenceTotal()" />
                        </div>
                        <button class="bluebar" (click)="
                                              addExpenses(
                                                checked_out_guestform['controls'].expenses,
                                                expenseVal,
                                                expensePrice
                                              );
                                              submittedCheckOut = false
                                            ">
                          <strong id="cross">&#x2B;</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row mt-5" style="padding: 0 -1px !important;">
                                      <div class="col-md-12 zero-padding" style="padding-right: 2px">
                                        <div class="bar nine0" style="padding: 0px">
                                          <input type="text" class="form-control" value="Expence Total: {{ expenceTotalPrice }}"
                                            placeholder="Expense total" style="
                                            height: 30px;
                                            border: none;
                                            color: #00205b;
                                            font-size: 14px;
                                            background-color: transparent;
                                          " readonly />
                                        </div>
                                      </div>
                                    </div> -->
              </div>
            </form>




            <div class="show2" style="margin-top: 5px;" (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">
              <div class="mdiv" style=" display: flex; padding-left: 10px; width: 100%;">
                <div class="div2">
                  <span style="color:white;">Payment</span>
                </div>
              </div>
            </div>

            <div class="card mt-5" style="padding: 5px 5px !important; " (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">




              <div class="bar eight0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/arrivals/addpersonicon1.svg" width="25" height="20">
                </div>
                <div class="mdiv">
                  <div class="div2">
                    <span class="">$ {{paynow_invoice.booking.extra_person_fee}}</span>
                  </div>
                </div>
              </div>

              <div class="bar nine0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px; ">
                <div class="div1" style="padding: 0  10px;">

                  <img src="assets/img/newstaticicons/arrivals/amount-deposite.svg" width="25" height="20">
                </div>
                <div class="mdiv" *ngIf="paynow_invoice.booking.cashDeposit !== 0"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2" style="display: flex; align-items: center;">
                    <span class=""> ($ {{paynow_invoice.booking.cashDeposit}})</span>
                  </div>

                </div>
              </div>
              <div class="bar nine0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px; ">
                <div class="div1" style="padding: 0  10px;">

                  <img src="assets/img/newstaticicons/arrivals/extras_icon.svg" width="25" height="20">
                </div>
                <div class="mdiv" *ngIf="paynow_invoice.booking.extras !== 0"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2" style="display: flex; align-items: center;">
                    <span class=""> $ {{paynow_invoice.booking.extas_amount || 0}}</span>
                  </div>

                </div>
              </div>

              <div class="bar ten0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/arrivals/discount-light.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2" style="display: flex; align-items: center;">
                    <input type="number" class="form-control" placeholder="Discount" style="
                    height: 30px;
                    border: none;
                    color: #00205b;
                    font-size: 14px;
                    background-color: transparent;
                  " [(ngModel)]="paynow_dis" (keyup)="discount_offer($event)" />

                  </div>
                  <div class="div3">



                    <!-- <div class="toogleData">
                      <div *ngIf="restriction== true" (click)="restriction = false;discount_offer(letmeheck)">
                          <img src="assets/img/reports/revenue.png"   class="type-img"/>
                      </div>
                      <div *ngIf="restriction== false" (click)="restriction = true;discount_offer(letmeheck)" >
                          <img src="assets/img/reports/percentage_new.png" class="type-img" />
                      </div>
                  </div> -->

                  </div>
                </div>
              </div>

              <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <!-- <span style="color: #00205b; font-weight: 700; ">Sub</span> -->
                  <img src="assets/img/newstaticicons/arrivals/subtotal-sigma.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <span class="">$ {{paynow_invoice.subtotal}} </span>
                  </div>
                </div>
              </div>



              <div class="bar two0" style="padding: 0px; display:flex; align-items: center;">
                <div class="div1" style="padding: 0  10px;">

                  <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <span class="">$ {{paynow_invoice.tax_value}}</span>
                  </div>
                  <div class="div3">

                    <span style="display: flex; align-items: center; font-weight: 700; padding-right: 5px;">
                      <span [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{paynow_invoice.booking.Taxper}}
                        %</span>
                      <!-- <img src="assets/img/newstaticicons/rates-packages/discount.svg"
                    style="height: 35px; width: 35px; padding: 2px;" alt=""> -->
                    </span>
                  </div>
                </div>
              </div>




            </div>

            <div class="show2" style="margin-top: 5px;" (mouseover)="dodrag('payNowGuest')"
              (mouseout)="dontdrag('payNowGuest')">
              <div class="mdiv" style=" display: flex; padding-left: 10px; width: 100%;">
                <div class="div2">
                  <span style="color:white;">F & B</span>
                </div>
              </div>
            </div>

            <div class="card mt-5" style="padding: 5px 5px !important; margin-bottom: 5px"
              (mouseover)="dodrag('payNowGuest')" (mouseout)="dontdrag('payNowGuest')">

              <div class="bar one0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 5px;">
                <div class="div1" style="padding: 0  10px;">
                  <!-- <span style="color: #00205b; font-weight: 700; ">Sub</span> -->
                  <img src="assets/img/invoices/fnb-icon.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2">
                    <input type="number" class="form-control" placeholder="Price" id="price" style="
                                                height: 30px;
                                                border: none;
                                                color: #00205b;
                                                font-size: 14px;
                                                background-color: transparent; " />
                  </div>
                </div>
              </div>



              <div class="bar two0" style="padding: 0px; display:flex; align-items: center; margin-bottom: 0px">
                <div class="div1" style="padding: 0  10px;">

                  <img src="../../../assets/img/newstaticicons/arrivals/tax-icon.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b; align-items: center; ">
                  <div class="div2">
                    <!-- <span class="">$  {{paynow_invoice.tax_value}}</span> -->
                    <input type="number" class="form-control" placeholder="Tax" style="
                                                height: 30px;
                                                border: none;
                                                color: #00205b;
                                                font-size: 14px;
                                                background-color: transparent; " [(ngModel)]="fnv"
                      (keyup)="fnb(fnv)" />

                  </div>

                  <div class="div3">

                    <!-- <span style="display: flex; align-items: center; font-weight: 700; padding-right: 5px;" >
                      <span [ngClass]="darkMode ? 'disCPDa' : 'disCP'">{{paynow_invoice.booking.Taxper}} %</span>

                    </span> -->
                    <!-- <div class="toogleData">
                      <div *ngIf="restriction== true" (click)="restriction = false;discount_offer(letmeheck)">
                          <img src="assets/img/reports/revenue.png"   class="type-img" style="margin: 0;"/>
                      </div>
                      <div *ngIf="restriction== false" (click)="restriction = true;discount_offer(letmeheck)" >
                          <img src="assets/img/reports/percentage_new.png" class="type-img" style="margin: 0;" />
                      </div>
                  </div> -->
                  </div>
                </div>
              </div>


              <!-- <div class="bar three0" style="padding: 0px; display:flex; align-items: center; ">
                <div class="div1" style="padding: 0  10px;">
                  <img src="assets/img/newstaticicons/arrivals/dollar-light.svg" width="25" height="20">
                </div>
                <div class="mdiv"
                  style="font-weight: 700; display: flex;justify-content: space-between;width: 100%; color:#00205b">
                  <div class="div2" style="display: flex; align-items: center;">
                    <span class="">$ {{paynow_invoice.finaltotal}} </span>
                  </div>
                </div>
              </div> -->

            </div>

            <div class="bluheader booking-sumary "
              style="display: block;   margin-bottom: 5px !important; border-radius: 8px; height: 40px; margin-bottom: 0px; margin-left: 0px; padding: 0px 10px;"
              (mouseover)="dodrag('payNowGuest')" (mouseout)="dontdrag('payNowGuest')">
              <div class="div">

                <h4 class="">Total</h4>
                <h4 class="">$ {{paynow_invoice.finaltotal}}</h4>

              </div>
            </div>

          </div>



          <!-- button starts -->

          <div
            style="display:flex; justify-content: space-between; margin-bottom:8px; margin-right: 5px; margin-left: 5px;">
            <a class="no-print  btn-block mt-1"
              style="  background:#8aee9e !important;color:#ffffff;height: 30px;width: 30%; font-weight: 700; border-radius: 20px; display: flex; align-items: center;  justify-content: center;"
              routerLink="">
              <img src="assets/img/reports/share_dark.svg" style="    height: 14px; width: 14px;">
              <span style="padding: 5px;">GO GREEN</span> </a>


            <a class="no-print  btn-block mt-1"
              style=" background-image: linear-gradient(180deg, #2d2d9a, #00205b);color:#fff;height: 30px;width: 20%; font-weight: 700;border-radius: 20px; display: flex; align-items: center; justify-content: center; margin-top: 0;">
              <!-- <i class="fa fa-print" aria-hidden="true" style="color: black; padding-right: 10px;"></i> -->
              <!-- <span style="padding: 5px;"> Print</span> -->
              <button class="no-print  btn-block mt-1" (click)="checkoutGuest(paynow_invoice)"
                style=" background-image: linear-gradient(180deg, #2d2d9a, #00205b);color:#fff;height: 30px;width: 20%; font-weight: 700;border-radius: 20px; display: flex; align-items: center; justify-content: center; margin-top: 0;">Checkout</button>
            </a>

            <a class="no-print  btn-block mt-1"
              style="background:#8aee9e !important;color:#fff;height: 30px;width: 20%; font-weight: 700;border-radius: 20px; display: flex; align-items: center; justify-content: center; margin-top: 0;">
              <i class="fa fa-print" aria-hidden="true" style="color: black; padding-left: 10px;"></i>
              <!-- <span style="padding: 5px;"> Print</span> -->
              <button class="no-print  btn-block mt-1"
                style="background:#8aee9e !important;color:#fff;height: 30px; font-weight: 700;border-radius: 20px; display: flex; align-items: center; justify-content: center; margin-top: 0;"
                (click)="printDiv('printableArea')">Print</button>
            </a>

          </div>

          <!-- button starts -->


          <!-- <button class="btn btn-block send-0" (click)="checkoutGuest(checkoutdata.id, checkoutdata.guest_name)">
          Checked Out
        </button> -->

          <!-- <button class="btn btn-block send-0" routerLink="/paynow-invoice" >Checked Out</button> -->
          <!-- <button class="btn btn-block close-0" data-dismiss="modal" aria-label="Close">
          Close
        </button>
        <button class="btn btn-block close-0" data-dismiss="modal" routerLink="/paynow-invoice" aria-label="">
          Pay
        </button> -->
        </div>
      </div>
    </div>



  </div>

  <div id="download" style="display: none">
    <img #canvas />
    <a #downloadLink></a>
  </div>

  <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
    [ngClass]="darkMode ? 'darkOn' : 'darkOff' " [ngStyle]="{ display: displayStyle }">
    <div class="modal-dialog  modal-sm">
      <div class="modal-content" style="background-color: #f3f3f3; border-radius: 15px !important;"
        [ngClass]="darkMode ? 'dark-border-div dar-modal-body modal-content-dark' : 'null'">
        <div class="modal-header" style="padding: 0; border: 0px;">
          <div class="bluheader booking-sumary" id="grab-4" style="cursor: grab;" (mouseover)="dodrag('myModal')"
            (mouseout)="dontdrag('myModal')">
            <h3 class="h3_blu_header bookingsummary w-100">Reservation ID
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)=" closePopup()">
                <strong id="strong">&#x2716;</strong>
              </button>
            </h3>
          </div>
        </div>

        <div class="modal-body">
          <div class="main-box2">

            <div class="data-box2">
              <div class="red-bar"
                style="text-align: center; background: linear-gradient(rgb(115, 64, 206), rgb(80, 28, 173), rgb(91, 52, 160))!important;">
                {{my_object?.channel_name }}
              </div>
              <div [ngClass]="darkMode ? 'datas' : 'datas-light'">
                <div *ngIf="!(my_object?.confimation_number == null || my_object?.confimation_number == '')">
                  <div class="red-bar" style="text-align:center;">Confirmation ID</div>
                  <div [ngClass]="darkMode ? 'border-orange' : 'border-normal two0'">
                    <div style="text-align: center;">
                      {{my_object?.confirmation_number}}
                    </div>
                  </div>
                </div>

                <div class="red-bar" style="text-align: center;">Reservation ID</div>
                <div [ngClass]="darkMode ? 'border-orange' : 'border-normal three0'">
                  <div style="text-align: center;">
                    {{my_object?.reservation_id}} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <button type="button" class="form-control save-buton save-chan btn-hov-save"  [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'" control-label radio-style chbx name="" data-dismiss="modal"
           style="margin-top: 5px; margin-left: 5px;margin-right: 10px;border-radius: 25px;padding: 8px 30px;float: right;" (click)="save_ota_update()" id="closechannel" [ngClass]="darkMode ? 'card-dark-btn-g-save' : 'btn-hov-save'">
            {{ "inventory.promo.save" | translate }}
          </button> -->
<!-- </div>
    </div>
  </div> -->

<!-- reservation modal for edit -->
<!-- <div id="reservation-popup" class="modal fade bulk_updatemodal" role="dialog" style="width:100%;"> -->
<app-reservation-modal (onSaveReservation)="get_arrivals()"></app-reservation-modal>
<!-- </div> -->

<!-- print popup for view invoice  -->

<div class="modal fade" id="payNowGuest_view" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" [ngClass]="darkMode ? 'darkOn' : 'darkOff' ">
  <app-paynow-invoice *ngIf="invoiceArrival" [invoice_arrival]="invoiceArrival"></app-paynow-invoice>
</div>

<!-- print popup for view invoice  -->

<!-- </div> -->

<div style="height: 1px;overflow: hidden;">
  <table id="excel-table" class="table table-bordered">
    <tr class="table-primary"
      style="background-color: #000654;height: 45px;border-radius: 10px;margin-top: 5px;margin-bottom: 5px;position: unset;">


      <th style="color: #FFF;text-align: center;">Guest Name</th>
      <th style="color: #FFF;text-align: center;">Room Type</th>
      <th style="color: #FFF;text-align: center;">Room Number</th>
      <th style="color: #FFF;text-align: center;">Los</th>
      <th style="color: #FFF;text-align: center;">Check In</th>
      <th style="color: #FFF;text-align: center;">Check Out</th>
      <th style="color: #FFF;text-align: center;">Payment Status</th>
      <th style="color: #FFF;text-align: center;">Booking Status</th>
      <th style="color: #FFF;text-align: center;">Extra Person Fee</th>
      <th style="color: #FFF;text-align: center;">Deposit</th>
      <th style="color: #FFF;text-align: center;">Discount (%)</th>
      <th style="color: #FFF;text-align: center;">Discount (Amount)</th>
      <th style="color: #FFF;text-align: center;">Sub Total</th>
      <th style="color: #FFF;text-align: center;">Tax</th>
      <th style="color: #FFF;text-align: center;">Amount</th>
      <th style="color: #FFF;text-align: center;">ID Type </th>
      <th style="color: #FFF;text-align: center;">Id Number </th>

    </tr>
    <tr *ngFor="let arr of arrivals" style="padding: 20px;">
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.guest_firstname}}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.room?.display_name == '' ?
        arr.room?.roomtype.name : arr.room?.display_name}}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.bookingitems[0] &&
        arr.bookingitems[0].roomnum && arr.bookingitems[0].roomnum.room_number}}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ daysTotal(arr.date_departure, arr.date_arrival)
        }}
      </td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ chngeDateArr(arr) | date: "d MMM " }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ chngeDatedep(arr) | date: "d MMM " }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.payment_status }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.status }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.extra_person_fee || 0 }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.cashDeposit || 0 }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.discount !=null?
        arr.discount+'%':arr.discount || 0}}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ calculateDiscountAmount(arr) }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ prices(arr.prices,arr.extra_person_fee)}}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.Taxper }}%</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.total }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.personal_id_type }}</td>
      <td style="text-align: center;border-bottom: 1px solid;">{{ arr.personal_id_value }}</td>
    </tr>

  </table>

</div>
<div id="in-house-data" style="display:none">
  <table style="border: 1px solid black; width: 100%; text-align:center; border-collapse: collapse; ">
    <tr style="border: 1px solid black;">
      <th>Sno</th>
      <th>Guest</th>
      <th>Reservation Id</th>
      <th>Check In</th>
      <th>Check Out</th>
      <th>Room</th>
    </tr>
    <tr *ngFor="let arrival of checked_in; let i =index">
      <td>{{i+1}}</td>
      <td>{{arrival.guest_name}}</td>
      <td>{{arrival.booking.reservation_id}}</td>
      <td>{{arrival.check_in !=null? (arrival.check_in | date: "d MMM" ) :(arrival.date_arrival | date: "d MMM")}}
      </td>
      <td>{{arrival.check_out !=null? (arrival.check_out | date: "d MMM" ) :(arrival.date_departure | date: "d MMM")}}
      </td>
      <td>
        {{ arrival.roomnum.room_number }}
        {{arrival.bookingitems && arrival.bookingitems[0] && arrival.bookingitems[0].roomnum
        && arrival.bookingitems[0].roomnum.room_number}}</td>
    </tr>


  </table>
</div>
