<!-- <p>glossary works!</p> -->
<div class="w-container">

      <div class="container" [ngClass]="darkMode ? 'container_dark' : 'container'">
            <div class="" id="collapse1" [ngClass]="darkMode ? 'card-darkk' : 'section-2'"></div>
            <div class="WordSection1" [ngClass]="darkMode ? 'card-dark' : 'null'">

                  <div class="text-block-12 head-2 colorr" [ngClass]="darkMode ? 'colorr_dark':'colorr'"><span
                              [ngClass]="darkMode ? 'service_s':'null'">G</span>lossary
                  </div>
                  <div class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">

                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">A</span>
                        <img src="assets/img/reports/{{ darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol1" data-toggle="collapse" href="#collapse100"
                              (click)="chngedrop('imgcol1')">
                  </div>

                  <div id="collapse100" class="text panel-collapse collapse ">
                        <p class="MsoNormal"
                              style="margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                              <span style="font-size:19.0pt;font-family: sans-serif; color:#232E38"
                                    class="heading1">Availability</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The number of vacancies possible for a specific type of
                                    accommodation for a specific set of dates.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif; color:#232E38"
                                    class="heading1">Average
                                    Daily Rate (ADR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">Your&nbsp;<a><span>average daily rate</span></a>&nbsp;is the&nbsp;figure
                                    derived by dividing actual daily revenue by the total number of rooms sold.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Average
                                    Length of stay (ALOS)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The total room nights in a hotel divided by the number of
                                    reservations in the hotel. It is used to keep track of hotel performance in
                                    attracting and keeping guests in house. Formula: Total occupied room nights /
                                    Total bookings.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1001" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">B</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol2" data-toggle="collapse" href="#collapse1001"
                              (click)="chngedrop('imgcol2')">
                  </div>
                  <div id="collapse1001" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Best
                                    Available Rate (BAR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The lowest non-restricted rate bookable by all guests. This rate
                                    can change several times a week up to several times a day. Also called Best
                                    Flexible Rate (BFR).</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Best
                                    Rate Guarantee (BRG)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The promise that hotels or&nbsp;<a><span>online travel
                                                agencies</span></a>&nbsp;(<a><span>OTAs</span></a>) will display the
                                    best rates on their own
                                    site as compared to any other side for the same product.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Booking
                                    Engine</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A hotel website�s&nbsp;<a><span>booking engine</span></a>&nbsp;is the software
                                    application being used to secure online reservations. Your&nbsp;<a><span>booking
                                                engine</span></a>&nbsp;will
                                    allow you to sell
                                    your rooms through your own website and third party sites
                                    like&nbsp;<a><span>Facebook</span></a>&nbsp;that
                                    your hotel is listed on.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1002" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">C</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol3" data-toggle="collapse" href="#collapse1002"
                              (click)="chngedrop('imgcol3')">
                  </div>
                  <div id="collapse1002" class="text panel-collapse collapse ">

                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Central
                                    Reservation System (CRS)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The application used to manage a hotel�s distribution and hotel
                                    room bookings. Typically will be used to reach guests via
                                    multiple&nbsp;<a><span>distribution
                                                channels</span></a>&nbsp;such as travel
                                    agencies (via&nbsp;<a><span>GDS</span></a>), online travel agencies (such as
                                    Expedia,
                                    Orbitz, Travelocity, Priceline and others), direct to the hotel website, and
                                    telephone (either via call center, direct to property or both).</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Channel
                                    Management</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Controlling the allocation of hotel inventory and rates across
                                    all distribution channels including website, third parties, and the GDS.
                                    Effective&nbsp;<a><span>channel management solutions</span></a>&nbsp;should
                                    reduce labour costs and improve efficiency by providing a centralised way to
                                    control multiple channels.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Channel
                                    Manager</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">As the name suggests,&nbsp;<a><span>channel managers</span></a>&nbsp;are pieces of technology
                                    that allow properties to efficiently manage the different online distribution
                                    channels that their property sells through.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Closed
                                    to arrival (CTA)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">It means that no new reservations can be taken for guests
                                    arriving on this date. Reservations can stay through the date. It is used
                                    to&nbsp;<a><span>control inventory</span></a>.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Closed
                                    to Departure (CTD)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A restriction by which the hotel can restrict any check outs on
                                    a specific day.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Commission</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">That payment that a travel agent receives from a supplier for
                                    selling transportation, accommodation or other services.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1003" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">D</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol4" data-toggle="collapse" href="#collapse1003"
                              (click)="chngedrop('imgcol4')">
                  </div>
                  <div id="collapse1003" class="text panel-collapse collapse ">

                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Device
                                    Asset</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A Device Asset is an electronic item owned or leased by the
                                    hotel.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Distribution
                                    Strategy</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Determining when and through what channels to sell rooms based
                                    upon the cost of acquisition of the individual channel. By driving business to
                                    lower cost acquisition channels during high demand periods, hotels can maximize
                                    their profitability.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Dynamic
                                    Packaging</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The ability to create packages based upon the components the
                                    customer selects.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1004" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">E</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol5" data-toggle="collapse" href="#collapse1004"
                              (click)="chngedrop('imgcol5')">
                  </div>
                  <div id="collapse1004" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Early
                                    Bird</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A&nbsp;<a><span>promotion</span></a>&nbsp;only available for advanced
                                    bookings where a minimum number of days are left between the reservation and
                                    the date of check in, often a discounted rate with fencing.</span>
                        </p>
                  </div>

                  <div data-toggle="collapse" href="#collapse1005" aria-expanded="false" aria-controls="collapseOne"
                        (click)="dropDown = 1005" #pkg_label class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">F</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol6" data-toggle="collapse" href="#collapse1005"
                              (click)="chngedrop('imgcol6')">
                  </div>
                  <div id="collapse1005" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Flash
                                    Sales</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A promotional sale by a hotel that is available for purchase by
                                    the customers for a limited time only.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Folio</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A Folio is a collection of Charges and Payments incurred or made
                                    by a Guest or corporate account, or in-house account, etc.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Front
                                    Desk System</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">Your small property�s&nbsp;<a><span>front desk system</span></a>&nbsp;(also known as
                                    a&nbsp;<a><span>property management system</span></a>), is the software
                                    used to automate the operations of a hotel. It will allow you to manage the
                                    day-to-day operations of a property.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Function
                                    Room Occupancy</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The measure of how efficiently hotels are utilising their
                                    function room space. It is recorded as an occupancy percentage. Formula: Total
                                    occupied Function Room space/ Total square footage of function room space
                                    available.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1006" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">G</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol7" data-toggle="collapse" href="#collapse1006"
                              (click)="chngedrop('imgcol7')">
                  </div>
                  <div id="collapse1006" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Global
                                    Distribution System (GDS)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A reservation platform started by code sharing airline partners
                                    to enable reservations for Airlines. Later on hotels, cars and cruise companies
                                    came on board. There are 4 major&nbsp;<a><span>GDS</span></a>&nbsp;companies. Sabre,
                                    Galileo, Amadeus
                                    and WorldSpan (Galileo and WorldSpan are owned by Travelport) offer a
                                    comprehensive travel shopping and reservation platform to travel agents
                                    worldwide. Agents use one of these systems to book airline, car, hotel and
                                    other travel arrangements for their customers. OTAs also use one or more GDS to
                                    power some or all of their content on their site.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Gross
                                    Margins</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;
          color:#232E38">The figure you arrive at after dividing your gross profits by
                                    net sales.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Gross
                                    Operating Profit Per Available Room (GOPPAR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Calculated as room revenue minus expenses divided by total rooms
                                    available. It is the new RevPAR for owners.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1007" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">H</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol8" data-toggle="collapse" href="#collapse1007"
                              (click)="chngedrop('imgcol8')">
                  </div>
                  <div id="collapse1007" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Hosted
                                    Payment System</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The system that presents a web page where&nbsp;<a><span>payment
                                                information</span></a>&nbsp;is securely collected</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Housekeeping
                                    Asset</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A Housekeeping Asset is a consumable item used to maintain a
                                    Room-Type such as toilet paper, cleaners, soaps, shampoo, etc. All items are
                                    not accounted for.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1008" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">I</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol9" data-toggle="collapse" href="#collapse1008"
                              (click)="chngedrop('imgcol9')">
                  </div>
                  <div id="collapse1008" class="text panel-collapse collapse ">

                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Inventory</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The rooms available that the hotel has to distribute/sell across
                                    all channels.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Invoice</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">An Invoice is a request for payment made by the hotel to a
                                    company with which is does business.</span>
                        </p>
                  </div>

                  <!-- <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 1009" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">J</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol11" data-toggle="collapse" href="#collapse1009" (click)="chngedrop('imgcol11')">
  </div>
  <div id="collapse1009"></div>

  <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10010" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">K</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol22" data-toggle="collapse" href="#collapse10010" (click)="chngedrop('imgcol22')">
  </div>
  <div id="collapse10010"></div> -->

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10011" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">L</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol23" data-toggle="collapse" href="#collapse10011"
                              (click)="chngedrop('imgcol23')">
                  </div>
                  <div id="collapse10011" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Linen
                                    Asset</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A Linen Asset is an item used to maintain a Room-Type that is
                                    re-used after laundering such as sheets, blankets, towels, etc. These items
                                    quantities are tracked, but not the individual assets.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">�Lookers
                                    to Bookers�(L2B)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Refers to the conversion of online shoppers to actual buyers;
                                    often communicated in a ratio.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Lose-it
                                    rate</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">This term refers to a rate where the hotel would be better off
                                    leaving the room unsold than sell at this rate. This could be because the hotel
                                    expects to find a better group at a rate high enough to take the chance of
                                    waiting for it to come along. It is often true if the dates requested are in a
                                    peak group season, and if there is significant time left in the group booking
                                    window for those dates.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Loyalty
                                    Program</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A&nbsp;<a><span>Loyalty Program</span></a>&nbsp;is a marketing program
                                    that offers rewards to guests for regular or frequent business. A loyalty
                                    program may also be a partnership with loyalty programs of other businesses
                                    (airlines for example). Loyalty may be tracked by points or other means.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10022" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">M</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol24" data-toggle="collapse" href="#collapse10022"
                              (click)="chngedrop('imgcol24')">
                  </div>
                  <div id="collapse10022" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Maintenance
                                    Asset</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;
     color:#232E38">A Maintenance Asset is an item used by the facilities
                                    maintenance staff such as a light bulb, caulk, bolts, screws, brooms, carts,
                                    mops, etc. Some of these items are traceable/accountable and some are not.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Maximum
                                    Length of Stay</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A room inventory control function that limits the number of
                                    nights a reservation can stay when arriving on a certain date.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Minimum
                                    length of stay (MinLOS)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">An inventory control function primarily used to ensure that a
                                    peak demand night does not get filled with one night stays thus blocking the
                                    days around it for longer lengths of stay. It requires a reservation to meet or
                                    exceed a certain length of stay (2 or more) in order to complete the
                                    reservation.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10033" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">N</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol25" data-toggle="collapse" href="#collapse10033"
                              (click)="chngedrop('imgcol25')">
                  </div>
                  <div id="collapse10033" class="text panel-collapse collapse ">


                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38" class="heading1">Net
                                    sales</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The figure you arrive at after subtracting your costs from your
                                    total sales.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Night
                                    Audit</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A Night Audit is a reconciliation of the previous 24 hours of
                                    transactions.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10044" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">O</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol26" data-toggle="collapse" href="#collapse10044"
                              (click)="chngedrop('imgcol26')">
                  </div>
                  <div id="collapse10044" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Occupancy
                                    rate</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">�Fill� measure of a hotel calculated by dividing the total
                                    number of rooms occupied by the total number of rooms available times 100, e.g.
                                    75% occupancy.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Online
                                    Travel Agencies (OTA)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">Websites offering comprehensive travel shopping and Reservations
                                    Solutions to consumers. Examples include Expedia, Orbitz, Travelocity,
                                    Priceline, and many local and regional sites.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Overbooking</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The practice of&nbsp;<a><span>booking rooms beyond the capacity</span></a>&nbsp;of the
                                    hotel in anticipation that some guests will not show up.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10055" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">P</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol27" data-toggle="collapse" href="#collapse10055"
                              (click)="chngedrop('imgcol27')">
                  </div>
                  <div id="collapse10055" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Package</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A collection of components being sold at a single price, where
                                    the component prices may or may not be known to the consumer.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Price
                                    Match Guarantee (PMG)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">The promise that hotels or OTAs will offer the lowest rates or
                                    match the lowest rate available across any channel for the same product.</span>
                        </p>
                  </div>

                  <!-- <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10066" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Q</span>
     <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol28"  data-toggle="collapse" href="#collapse10066" (click)="chngedrop('imgcol28')">
  </div>
  <div id="collapse10066"></div> -->

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10077" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">R</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol29" data-toggle="collapse" href="#collapse10077"
                              (click)="chngedrop('imgcol29')">
                  </div>
                  <div id="collapse10077" class="text panel-collapse collapse ">

                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Rate
                                    Fences</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Rate rules that may include restrictions such as advance booking
                                    cutoffs or non-refundable payments. In general, no discount rate should ever be
                                    offered without fences, like advance purchase required, minimum stay length,
                                    non-refundable, etc. An example of how to use them: If all your business
                                    travelers are paying $100 per night, and book within 5 days of arrival and you
                                    need to encourage some extra, non business revenue, you may want to offer a $79
                                    rate for guests booking two weeks in advance. This advance purchase requirement
                                    is a �fence� that keeps the guests already willing to pay $100 from buying your
                                    rooms for $79.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Rate
                                    Parity</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The strategy that all distribution channels of a hotel should
                                    reflect the same rate for the same conditions for a particular room type. Rate
                                    parity strengthens customer loyalty and encourages guests to book directly with
                                    the hotel where terms/policies may be more flexible, given the same pricing as
                                    in other channels.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Reservation</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The record of a request to set aside a room or other resource
                                    for use in the future.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Revenue
                                    Management</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The process of understanding, anticipating and reacting to
                                    consumer behavior to maximise revenue.&nbsp;<a><span>Yield
                                                Management</span></a>&nbsp;is also referred to as
                                    Revenue Management.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Revenue
                                    Per Available Room (RevPAR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Calculated by taking the daily room revenue of a hotel and
                                    dividing it by the total rooms available at that hotel.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Revenue
                                    Per Occupied Room (RevPOR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Also known as revenue per booking. Calculated by taking the
                                    total daily revenue (including ancillary revenues) and dividing it by the total
                                    number of occupied rooms at the hotel.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Room
                                    Block</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A Room Block is a group of rooms. Room Blocks may be created to
                                    organize rooms in various configurations to aid in planning and sales or other
                                    management tasks. Examples of Room Blocks might include: associating rooms with
                                    a single fixed price, a single Guest, a Channel, or a single team of Staff
                                    Members that manage or maintain the rooms in the block.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Room
                                    Inventory</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">The set of rooms available for stays for a particular date or
                                    range of dates.</span>
                        </p>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Room Type</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
          white"><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">A Room Type represents some form of categorisation, set, or
                                    collection of rooms with some common element at the hotel that must be managed
                                    for marketing purposes within the hotel. For example, a Room-Type might be a
                                    Suite or a Single Room with a double bed, Poolside or Ocean-side. A Room may
                                    belong to multiple Room Types.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10088" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">S</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol20" data-toggle="collapse" href="#collapse10088"
                              (click)="chngedrop('imgcol20')">
                  </div>
                  <div id="collapse10088" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Service
                                    Contract</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A Service Contract is a legally binding exchange of services
                                    promised and agreements between the hotel and service provider that the law
                                    will enforce.</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;background:
     white"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">Examples of services are:</span>
                        </p>
                        <ul type=disc>
                              <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
        normal;'><span style='font-size:12.0pt;font-family: sans-serif;' class="heading1">Telephone
                                          service.</span>
                              </li>
                              <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
        normal;'><span style='font-size:12.0pt;font-family: sans-serif;'>Cable
                                          TV and movie service.</span>
                              </li>
                              <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
        normal;'><span style='font-size:12.0pt;font-family: sans-serif;'>Temporary
                                          or other non-employee personnel.</span>
                              </li>
                              <li class="MsoNormal" style='color:#232E38;margin-bottom:12.0pt;line-height:
        normal;'><span style='font-size:12.0pt;font-family: sans-serif;'>Alarm
                                          and control systems.</span>
                              </li>
                        </ul>
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">System
                                    of Record</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;"><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">A System of Record is the system that has the core responsibility
                                    for collecting and maintaining a specific type of data or information.</span>
                        </p>
                  </div>

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10099" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">T</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol222" data-toggle="collapse" href="#collapse10099"
                              (click)="chngedrop('imgcol222')">
                  </div>
                  <div id="collapse10099" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
          7.5pt;margin-left:0in;line-height:normal;"><span
                                    style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Total
                                    Revenue Per Available Room (TrevPAR)</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
          "><span style="font-size:12.0pt;font-family: sans-serif;;
          color:#232E38">Calculated by taking the total revenue of a hotel and dividing
                                    by the total inventory of rooms.</span>
                        </p>
                  </div>

                  <!-- <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 10000" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">U</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol223" data-toggle="collapse" href="#collapse10000" (click)="chngedrop('imgcol223')">
  </div>
  <div id="collapse10000"></div> -->

                  <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100121" #pkg_label
                        class="MsoNormal borbo"
                        style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
                        <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">V</span>
                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                              class="imagedrop" id="imgcol224" data-toggle="collapse" href="#collapse100121"
                              (click)="chngedrop('imgcol224')">
                  </div>
                  <div id="collapse100121" class="text panel-collapse collapse ">
                        <p class="MsoNormal" style="margin-top:15.0pt;margin-right:0in;margin-bottom:
     7.5pt;margin-left:0in;line-height:normal;"><span style="font-size:19.0pt;font-family: sans-serif;;color:#232E38"
                                    class="heading1">Vacancy</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">One or more rooms available to accommodate guests.</span>
                        </p>
                        <p class="MsoNormal" style="margin-bottom:7.5pt;line-height:normal;
     "><b><span style="font-size:12.0pt;font-family: sans-serif;;
     color:#232E38">There you have it!</span></b><span style='font-size:12.0pt;
        font-family: sans-serif;;color:#232E38'>&nbsp;Still, there are plenty
                                    more terms to learn about when it comes to increasing revenue at your bed and
                                    breakfast!</span>
                        </p>
                  </div>

                  <!-- <div aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100123" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">W</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol225"  data-toggle="collapse" href="#collapse100123" (click)="chngedrop('imgcol225')">
  </div>
  <div id="collapse100123"></div>

  <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100124" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">X</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol226" data-toggle="collapse" href="#collapse100124" (click)="chngedrop('imgcol226')">
  </div>
  <div id="collapse100124"></div>

  <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100125" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Y</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol227" data-toggle="collapse" href="#collapse100125" (click)="chngedrop('imgcol227')">
  </div>
  <div id="collapse100125"></div>

  <div  aria-expanded="false" aria-controls="collapseOne" (click)="dropDown = 100126" #pkg_label class="MsoNormal borbo" style="margin-top:15.0pt;margin-right:0in;margin-bottom: 7.5pt;margin-left:0in;line-height:normal;">
     <span style='font-size:17.0pt;font-family: sans-serif;;color:#232E38' class="gloss">Z</span>
      <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" class="imagedrop" id="imgcol228" data-toggle="collapse" href="#collapse100126" (click)="chngedrop('imgcol228')">
  </div>
  <div id="collapse100126"></div> -->




            </div>
      </div>
</div>