<div class="row">
    <div class="col-md-12">
        <div class="filter-box reports-filter">
            <form class="form-inline">
                <div class="no-gutters">
                    <div class="col-md-12 custom-width">
                        <div class="col-md-2 filters_col">
                            <img src="assets/img/reports/line_chart.png" alt="" class="placeholder-img">
                            <ng-select class="form-control input_2 padding-holder" [items]="chart" bindLabel="name"
                                bindValue="id" [(ngModel)]="selectedChart" placeholder="Line Chart" name="selectedChart"
                                (change)="getList_barData(selectedChart == 3 ? 'list' : selectedChart == 2 ? 'bar' : null)">
                            </ng-select>
                        </div>

                        <div class="col-md-4 filters_col">
                            <img src="assets/img/reports/calendar.png" alt="" class="placeholder-img" />
                            <div class="input form-control"
                                style="background: linear-gradient(#fff, lightgrey);padding-left: 30px;">
                                <input type="text" ngxDaterangepickerMd
                                    class="form-control date_range_picker arrival-lenght" [(ngModel)]="choosen_date"
                                    placeholder="From" [singleDatePicker]="false" [autoApply]="true" [dateLimit]="31"
                                    [locale]="{format: 'DD MMMM', separator: '  To  '}"
                                    [ngModelOptions]="{standalone: true}"
                                    (change)="date_change($event); getList_barData(selectedChart == 3 ? 'list' : selectedChart == 2 ? 'bar' : null)">
                            </div>
                        </div>

                        <!-- <div class="col-md-2 filters_col">
                            <img src="assets/img/reports/occup.svg" alt="" class="placeholder-img">
                            <ng-select class="form-control input_2 padding-holder" placeholder="Metrics" [items]="metrics" name="metrics" bindLabel="name" bindValue="slug" [(ngModel)]="current_metric">
                            </ng-select>
                        </div> -->

                        <div class="col-md-2 filters_col">
                            <img src="assets/img/reports/filter.svg" alt="" class="placeholder-img">
                            <ng-select class="form-control input_2 padding-holder" placeholder="By Room"
                                [items]="metrics_room" name="metrics" bindLabel="name" bindValue="name"
                                [(ngModel)]="current_donut" (change)="changeView(current_donut)">
                            </ng-select>
                        </div>

                        <!-- Go Button -->
                        <div class="col-md-1" style="margin-top: 3px;">
                            <!-- <a class="btn btn-md" [ngStyle]="{'background' : darkMode ? 'linear-gradient(#00205b, #000612) !important' : 'linear-gradient(#30319a, #062460)!important' }" (click)="reset_filter()">Reset</a> -->
                            <div class="btn btn-primary"
                                [ngStyle]="{'background' : darkMode ? 'linear-gradient(#00205b, #000612)' : '#337ab7'}"
                                (click)="reset_filter()">Reset</div>
                        </div>

                        <!-- Restriction Percentage Toggles -->
                        <div class="col-md-1 checkbox-div">

                        </div>
                        <div class="toogleData">
                            <div (click)="changeFormat()" class="type-img">
                                <img src="assets/img/reports/{{restriction ? 'revenue.png' : 'percentage_new.png'}}" />
                            </div>
                            <!-- <div *ngIf="restriction== false" (click)="restriction = true" class="type-img">
                                <img src="assets/img/reports/percentage_new.png" />
                            </div> -->
                        </div>

                        <div>
                            <img src="assets/img/reports/download.png" class="go_icon"
                                style="right: 3px; height: 40px; width: 40px; position: absolute" (click)="makePDF()">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div #capture class="overFlow">

    <div class=" w-100">
        <div class="card text-center" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
            [ngClass]="exte ? 'shadow' : 'shadow-none'">
            <div class="row">
                <div class="col-md-12 padding-25">
                    <div class="card-heading justify-content-end" *ngIf="selectedChart != 3">
                        <!-- <div class="heading-style"
                            [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b', 'padding': '3px 0 7px 0'}">
                            {{ current_metric == 'checkins' ? 'Occupancy Rate' : current_metric == 'avg_daily_rate' ?
                            'Average Daily Rate' : current_metric == 'rev_par' ? 'Revenue Per Available Room' :
                            'Occupancy Rate' }}
                        </div> -->
                        <!-- <div class="room-type-list d-flex align-items-center" [ngClass]="darkMode ? 'room_type_dark' : 'null'">
                            <ng-container *ngIf="selectedChart == 1">
                                <ng-select class="form-control input_2 padding-holder" placeholder="Channels"
                                    [(ngModel)]="current_donut == 'By Room' ? selected_room : selected_channel"
                                    (change)="changeGraph($event, current_donut == 'By Room' ? 'room' : 'channel')"
                                    [items]="current_donut == 'By Room' ? roomList : channelList" bindLabel="name"
                                    bindValue="name">
                                </ng-select>
                                <div id="legend" *ngIf="current_donut == 'By Room' && selected_room == 'All Rooms'">
                                    <ul>
                                        <ng-container *ngFor="let room of roomList; index as i">
                                            <li class="d-flex" *ngIf="room != 'All Rooms'">
                                                <div class="color-box"
                                                    [ngStyle]="{'background': currentDay ? roomsColorLight[i % roomsColorLight.length] : 'linear-gradient(' + roomsColorLight[i]+','+ roomsColorDark[i]+')'}">
                                                </div>
                                                <span [ngStyle]="{'color': darkMode ? 'darkgray' : '#00205b'}">{{room}}</span> 
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </ng-container>
                        </div> -->
                        <div class="pull-right">
                            <!-- <div class="dipalyfl">
                                <div class="chk-bx"></div><span class="chk-bx-text">BAR</span>
                                <div class="chk-bx"></div><span class="chk-bx-text">OCR</span>
                                <div class="chk-bx"></div><span class="chk-bx-text">ADR</span>
                                <div class="chk-bx"></div><span class="chk-bx-text">RevPAR</span>
                                <div class="chk-bx"></div><span class="chk-bx-text">Rev.</span>
                            </div> -->
                            <div class="total-earning total-earningDark" [ngClass]="darkMode ? 'null' : 'redColor'"
                                style="float: left;margin: 2px 0px 0px 0px; font-size: 14px">
                                <i class="fas fa-info-circle">
                                    <span class="tooltiptext" [ngClass]="darkMode ? 'tooltiptextdark' : 'null'">
                                        <p>Total Earnings In</p>
                                    </span>
                                </i> {{ choosen_range.length }} Days
                            </div>
                            <div class="cash-amountDark" [ngClass]="darkMode ? 'null' : 'blueColor'"
                                style="float: left;">
                                {{currency_format}} {{ebr_total | number : '1.2-2'}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Line View -->
                <div *ngIf="selectedChart == 1">
                    <div class="col-md-12">
                        <div class="line_graph_box">
                            <div class="text-left logo-box">
                                <!-- <div *ngIf="room == true">
                                <ng-container *ngFor="let item of pageOfItems;index as i">
                                    <label class="list-data-name" [ngStyle]="{'color':  border_colors[i% border_colors.length]}">
                                        {{item}}
                                        //*ngFor="let channel of graph_arr ? graph_arr.slice(0, 5) : []; let k = index;"
                                    </label>
                                </ng-container>
                            </div> -->
                                <label
                                    *ngFor="let channel of graph_arr | paginate: { itemsPerPage: 5, currentPage: page2 }; let k = index;">
                                    <span *ngIf="channel.channel_id != 0">
                                        <input type="radio" name="graph_radio" id="switch_graph"
                                            (change)="switch_paths(channel.channel_id);" (click)="toolColor = k"
                                            [checked]="graph_line_switch == channel.channel_id" />
                                        <div *ngIf="room == false">
                                            <div *ngIf="exte">
                                                <img alt="" *ngIf="channel.type == 'External'"
                                                    src="assets/img/svgicons/otas/{{ channel.channel_img}}"
                                                    style="height: 60px;" />
                                                <img alt="" *ngIf="channel.type == 'Internal'"
                                                    src="assets/img/svgicons/channels/{{channel.channel_img}}"
                                                    style="height: 60px;" />
                                                <!-- {{room_type_name}} -->
                                            </div>
                                        </div>
                                        <img alt="" *ngIf="!exte"
                                            src="assets/img/svgicons/all-channels-webp/{{chngeEx(channel.channel_img)}}.webp"
                                            style="height: 60px;" />
                                    </span>
                                </label>
                            </div>

                            <!-- Graph Tooltip -->
                            <div id="stats_box" [ngStyle]="{ left: stats_box.x + 'px' }" *ngIf="showTool">
                                <div class="line-card"
                                    [ngStyle]="{ 'top' : stats_box.y < 0 ? 0 + 'px': stats_box.y + 'px' }">
                                    <div class="card-wrapper inside-card text-left"
                                        [ngStyle]="{'border-color' : border_colors[toolColor]}">
                                        <img *ngIf="stats_box_data.image != '' && stats_box_data.type == 'External'"
                                            class="card-img" src="assets/img/svgicons/otas/{{stats_box_data.image}}"
                                            style="margin: -10px 0px" />
                                        <img *ngIf="stats_box_data.image != '' && stats_box_data.type == 'Internal'"
                                            class="card-img" src="assets/img/svgicons/channels/{{stats_box_data.image}}"
                                            style="margin: -10px 0px" />
                                        <p class="mb-0" *ngIf="stats_box_data.image == ''">
                                            <strong>no_path_selected</strong>
                                        </p>
                                        <hr [ngStyle]="{'border-color': border_colors[toolColor]}" />
                                        <div class="left-side pull-left text-left">
                                            <div *ngIf="current_metric == 'checkins' || current_metric == 'metrics'"
                                                class="checkin-box">
                                                <p class="no-margin checkIns">Occupancy</p>
                                                <img class="pull-left" src="assets/img/reports/checkin.png" />
                                                <span class="checkIns-value">{{ stats_box_data.checkins}}</span>
                                            </div>
                                            <div *ngIf="current_metric == 'rev_par'" class="checkin-box">
                                                <p class="no-margin checkIns">RevPAR</p>
                                                <img class="pull-left" src="assets/img/reports/checkin.png" />
                                                <span class="checkIns-value">{{stats_box_data.rev_par}}</span>
                                            </div>
                                            <div *ngIf="current_metric == 'avg_daily_rate'" class="checkin-box">
                                                <p class="no-margin checkIns">ADR</p>
                                                <img class="pull-left" src="assets/img/reports/checkin.png" />
                                                <span class="checkIns-value">{{stats_box_data.avg_daily_rate}}</span>
                                            </div>
                                            <div *ngIf="current_metric == 'arr'" class="checkin-box">
                                                <p class="no-margin checkIns">ARR</p>
                                                <img class="pull-left" src="assets/img/reports/checkin.png" />
                                                <span class="checkIns-value">{{stats_box_data.avrr}}</span>
                                            </div>
                                        </div>
                                        <div class="right-side pull-right text-right"
                                            style="top: 40%; position: absolute; right: 4%;color: black;">
                                            <div class="date">
                                                {{ stats_box_data.date.format("Do MMM") }}
                                            </div>
                                            <!-- <div class="day">
                                            {{ stats_box_data.date.format("dd") }}
                                        </div> -->
                                            <div class="price">
                                                {{currency_format}} {{ stats_box_data.revenue}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Graph Tooltip END-->

                            <!--Line Graph -->
                            <svg viewBox="0 0 1540 400" id="mousemove" (mousemove)="move_box($event)"
                                *ngIf="current_donut == 'By Channels'">
                                <defs>
                                    <pattern id="pattern-2" viewBox="-10 13 181 180" patternUnits="userSpaceOnUse"
                                        width="100" height="100">
                                        vertical lines
                                        <rect x="86.5" y="71.3" width="6.9" height="180.6" style="fill: gray;"
                                            transform="matrix(1, 0, 0, 1, -9.2, -57.9)" />

                                        horizontal lines
                                        <rect x="86.5" y="80" width="6.9" height="180.6"
                                            style="fill: gray; stroke-width: 1;"
                                            transform="matrix(0, 1, -1, 0, 242, 13)" />
                                    </pattern>

                                    <pattern id="pattern-3"
                                        attr.patternTransform="matrix({{1500 / choosen_range.length / 100}}, 0, 0, 0.36, 24.5, 0)"
                                        xlink:href="#pattern-2" />

                                    <pattern id="square_grid" x="0" y="0" attr.width="{{ 1500 / choosen_range.length }}"
                                        height="36.4" patternUnits="userSpaceOnUse">
                                        <rect x="0" y="0" width="100%" height="100%"
                                            style="stroke: gray; fill: transparent;" />
                                    </pattern>

                                    <clipPath id="grid_space">
                                        <rect x="0" y="0" width="1500" height="378" fill="transprent" />
                                    </clipPath>
                                </defs>

                                <rect #grey_background id="grey_background" width="1560" height="400"
                                    style="fill: #f3f3f3;" />
                                <rect #grid_background id="grid_background" x="0" y="0" width="1500" height="380"
                                    style="fill: url(#square_grid); fill-opacity: 0.5;"
                                    transform="translate(0, 380) scale(1,-1)" />

                                <g class="graph_lines" style="clip-path: url(#grid_space);">
                                    <path attr.id="path{{ channel.channel_id }}"
                                        *ngFor="let channel of graph_arr.slice(0, 5); let i = index"
                                        (click)="switch_paths(channel.channel_id); toolColor = i"
                                        class="graph_line active" [attr.d]="channel.points" stroke-linejoin="round"
                                        [attr.fill]="stats_box_data.id == channel.channel_id ? border_colors[i]: 'transparent'"
                                        [ngStyle]="{ stroke: stats_box_data.id == channel.channel_id ? 'black' : border_colors[i] }"
                                        (mouseenter)="stats_box_data.id == channel.channel_id ? showTool = true : showTool = false"
                                        (mouseleave)="showTool = false" />
                                    <use id="use" attr.xlink:href="#path{{ graph_line_switch }}" height="90%" />
                                </g>
                                <g class="y-axis" transform="translate(0,20)">
                                    <text *ngFor="let per of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [attr.y]="36 * per"
                                        x="1520">
                                        {{ (10 - per) * 10 }}%
                                    </text>
                                </g>
                            </svg>
                            <!-- By Channel Pagination -->
                            <pagination-controls *ngIf="room == false" class="pagination-controls" previousLabel=""
                                nextLabel="" (pageChange)="page2 = $event"></pagination-controls>

                            <!--Line Graph End-->
                        </div>
                    </div>
                </div>

                <!-- By Room Graph -->
                <div class="line_graph_room" *ngIf="current_donut == 'By Room'">
                    <div class="roomNames">
                        <ng-container
                            *ngFor="let item of room_names | paginate: { itemsPerPage: 5, currentPage: page }; index as i">
                            <label class="list-data-name"
                                [ngStyle]="{'color':  byRoomGraphColors[i% byRoomGraphColors.length]}" [id]="item">
                                {{item}}
                            </label>
                        </ng-container>
                    </div>
                    <canvas id="byRoomGraph"></canvas>
                    <!-- By Room Pagination -->
                     <pagination-controls (pageChange)="page = $event" class="pagination-controls" previousLabel=""
                        nextLabel="" (pageChange)="page = $event"></pagination-controls>

                    <!-- <div class="d-flex w-100" *ngIf="currentDay; else currentWeek">
                        <div class="bar-chart-container">
                            <div class="redBar" (click)="loadCurrentWeekGraph()">{{todayDate | date: 'EEEE dd MMM'}}
                            </div>
                            <canvas id="currentDayRoomGraph" width="300" height="471" [ngStyle]="{'background' : darkMode ? 'transparent' : '#D6F6F6'}"></canvas>
                        </div>
                        <div class="line-chart-container" style="background: #F6D6D6;" [ngStyle]="{'background' : darkMode ? 'transparent' : '#F6D6D6'}">
                            <ng-container *ngIf="(current_donut == 'By Channels' && selectedChart == 1)">
                                <div id="currentDayAllChannel" width="1350" height="507"></div>
                            </ng-container>
                            <ng-container *ngIf="(current_donut == 'By Room' && selectedChart == 1)">
                                <ng-container *ngIf="selected_room == 'All Rooms'; else singleRoom">
                                    <div id="all-rooms-scatter-chart" width="1355" height="507"></div>
                                </ng-container>
                                <ng-template #singleRoom>
                                    <div id="room-line-chart" width="1400" height="507"></div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                    <ng-template #currentWeek>
                        <div class="d-flex w-100 current-week-graph" style="background: #F6D6D6;" [ngStyle]="{'background' : darkMode ? 'transparent' : '#F6D6D6'}">
                            <ul class="d-flex w-100 p-0 weekDates m-0">
                                <li *ngFor="let date of dates" [ngClass]="getClass(date)"
                                    (click)="loadCurrentDayGraph(date)">
                                    {{ date.format('dddd DD MMM') }}
                                </li>
                            </ul>
                        </div>
                        <div class="d-flex w-100" style="background: #F6D6D6;" [ngStyle]="{'background' : darkMode ? 'transparent' : '#F6D6D6'}">
                            <ng-container *ngIf="(current_donut == 'By Channels' && selectedChart == 1)">
                                <div id="currentWeekAllChannel" width="1650" height="507"></div>
                            </ng-container>
                            <ng-container *ngIf="(current_donut == 'By Room' && selectedChart == 1)">
                                <ng-container *ngIf="selected_room == 'All Rooms'; else singleRoom">
                                    <div id="current-week-all-room" width="1650" height="507"></div>
                                </ng-container>
                                <ng-template #singleRoom>
                                    <div id="current-week-single-room" width="1650" height="507"></div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-template> -->
                </div>

                <!-- Line View END -->

                <!-- BAR VIEW -->
                <div *ngIf="selectedChart == 2" class="Two">
                    <div *ngFor="let page of page_arr[current_page - 1]" class="col-md-12 pd-5">
                        <div class="col-md-3 chart-padding chart-width">
                            <div class="list-data-img" [ngClass]="darkMode ? 'list-data-imgDark' : 'list-data-img'"
                                style="padding: 9px;width: 100%;">
                                {{page.room_display_name || page.room_name}}
                            </div>
                            <div class="progress-count" [ngClass]="darkMode ? 'darkPro' : 'null'">
                                {{page.sold}}
                            </div>
                        </div>
                        <div class="col-md-9 bar-padding bar-width">
                            <div class="pt-30">
                                <div class="progress" [ngClass]="darkMode ? 'darkProBar' : 'null'">
                                    <div class="progress-bar" [ngStyle]="{ 'width': page.percentage + '%' }"
                                        [ngClass]="darkMode ? 'backdarkbar' : 'backlightbar'">

                                        <!-- <div class="progress-count" [ngClass]="darkMode ? 'darkPro' : 'null'">
                                    {{page.total_night_stay}}
                                  </div> -->
                                        <div class="progress-value" [ngClass]="darkMode ? 'darkPro' : 'null'">

                                            <span *ngIf="current_metric == 'metrics'">
                                                {{ restriction ? currency_format+' ' + (page.total_revenue | number :
                                                '1.2-2') : page.percentage + "%" }}
                                            </span>
                                            <!-- <span *ngIf="restriction == false">
                                                                        ${{page.total_revenue}}
                                                                    </span> -->
                                            <span *ngIf="current_metric == 'checkins'">
                                                {{page.occ}}%
                                            </span>
                                            <span *ngIf="current_metric == 'avg_daily_rate'">
                                                {{ currency_format }} {{page.avg_daily_rate_total}}
                                            </span>
                                            <span *ngIf="current_metric == 'rev_par'">
                                                {{ currency_format }} {{page.rev_par_total }}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- BarView END -->

                <!-- LIST VIEW -->
                <div *ngIf="selectedChart == 3">
                    <div class="" [ngClass]="darkMode ? 'main-flexDark' : 'main-flex'">
                        <div class="list-head">
                            Rooms
                        </div>
                        <div class="list-heads">Commission</div>
                        <div class="list-heads">Avg. Stay</div>
                        <div class="list-heads">ADR</div>
                        <div class="list-heads" style="padding-left: 5px;">RevPAR</div>
                        <div class="list-heads" style="padding-left: 5px;">Occupancy</div>
                        <div class="list-heads">Sold</div>
                        <div class="list-heads">Discount</div>
                        <div class="list-heads" style="padding-left: 10px;">Revenue</div>
                    </div>
                    <div class="col-md-12 pd-new3" *ngFor="let page of page_arr[current_page - 1]">
                        <div class="list-data-flex" style="height: 40px; margin-bottom: 5px;">
                            <div class="list-data-img" style="padding: 10px;"
                                [ngClass]="darkMode ? 'list-data-imgDark' : 'list-data-img'">
                                {{page.room_display_name}}
                            </div>
                            <div class="border-left"
                                [ngClass]="darkMode ? 'list-data-data-dark colD1' : 'list-data-data'">
                                {{ page.commission }} %</div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD2' : 'list-data-data'">{{
                                page.avg_night_stay }}</div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD3' : 'list-data-data'">{{
                                currency_format }}
                                {{ (page.total_night_stay>0?page.total_revenue/page.total_night_stay:0) | number :
                                '1.2-2'
                                }}</div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD4' : 'list-data-data'">{{
                                currency_format }}
                                {{
                                (page.total_night_stay>0?((page.total_revenue/page.total_night_stay)*(page.occ/100)):0)
                                |number : '1.2-2' }}</div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD5' : 'list-data-data'">{{ page.occ }} %
                            </div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD1' : 'list-data-data'"> {{ page.sold }}
                            </div>
                            <div [ngClass]="darkMode ? 'list-data-data-dark colD1' : 'list-data-data'">
                                <span *ngIf="restriction == true"> {{ page.discount | number : '1.2-2' }}</span>
                                <span
                                    *ngIf="restriction == false">{{calculatePrecentage(page.discount,page.total_revenue)}}</span>
                            </div>
                            <div class="border-right"
                                [ngClass]="darkMode ? 'list-data-data-dark colD6' : 'list-data-data'">
                                <!-- <span *ngIf="restriction == false">% {{ page.percentage }}</span> -->
                                <span>{{ currency_format }} {{ page.total_revenue | number : '1.2-2' }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- LIST View End -->

                <!-- Pagination -->
                <div *ngIf="pagination.length > 1 && selectedChart != 1" class="pagination-box text-center"
                    [ngClass]="darkMode ? 'darkPagi' : 'null'">
                    <div class="col-md-12 display-flex" style="    margin-top: 4px;">
                        <div class="page-link" aria-label="Previous" style="margin-right: 5px; display: inline-block;"
                            class="no-user">
                            <i (click)="change_page('first')" class="fas fa-angle-double-left"></i>
                            <i (click)="change_page('backward')" class="fas fa-angle-left" id="prev"></i>
                        </div>

                        <ul class="div-content">
                            <li *ngFor="let page of pagination" id="paginate" class="pagination no-user paginate"
                                [ngClass]="{ active: page == current_page }" (click)="change_page(page)">
                                {{ page }}
                            </li>
                        </ul>
                        <div class="page-link" aria-label="Next" style="margin-left: 5px; display: inline-block;"
                            class="no-user">
                            <i (click)="change_page('forward')" class="fas fa-angle-right" id="next"></i>
                            <i (click)="change_page('last')" class="fas fa-angle-double-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flexx " *ngIf="current_donut == 'By Channels'">
            <div class="dataWrapper drag_box" id="first">
                <div class="col-md-12 wht-box hov drag_item" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                    [ngClass]="exte ? 'shadow' : 'shadow-none'" id="metrics">
                    <div>
                        <div>
                            <div class="summary-top headingOne0"
                                [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                Earning By Room

                                <div>
                                    <span style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                        Showing Results From
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.startDate.format("Do MMM")}}</span>
                                        to
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                    </span>
                                    <a data-toggle="collapse" href="#collapse100124">
                                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                            class="mR10" (click)="chandrop('button3')" id="button3"
                                            style="margin-top: -5px;" *ngIf="exte">
                                        <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                            class="mR10" (click)="chandrop('button3')" id="button3"
                                            style="margin-top: -5px;" *ngIf="!exte">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="collapse100124" class="text panel-collapse collapse in">
                            <hr class="hhr">
                            <div class="">
                                <div class="col-md-7 pl-0" *ngIf="r_data != undefined" style="height: 312px;     overflow: auto;">
                                    <div class="bar-flex" *ngFor="let er of r_data.Room_earn; let i = index;">
                                        <div class="check-div">
                                            <div class="ck" [ngStyle]="{'background-color': channel_colors[i]}">
                                                <div class="under-box"></div>
                                            </div>
                                        </div>
                                        <div class="channel-name">
                                            <span [ngClass]="darkMode ? 'rob' : 'rob2'">{{er.room_type_name}}</span>
                                        </div>
                                        <div class="price-div">
                                            <span [ngClass]="darkMode ? 'rob' : 'rob2'">{{restriction ?
                                                currency_format+' '
                                                + (er.room_sum |number : '1.2-2') : er.percentage+'%'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 donutRes">
                                    <div class="ebr-total" [ngStyle]="{'color': darkMode ? '#fff' : '#000'}"><span
                                            class="currency">{{ currency_format }}</span>{{(ebr_total | number :
                                        '1.2-3').slice(0,-3) }}<span class="currency">{{(ebr_total | number :
                                            '1.2-3').slice(-3)}}</span></div>
                                    <canvas id="EBR1" class="chartjs-render-monitor overridecanvas2"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 wht-box hov drag_item" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                    [ngClass]="exte ? 'shadow' : 'shadow-none'" id="checkins">
                    <div class="panel-collapse padding-form collapse in">
                        <div>
                            <div class="summary-top headingOne0"
                                [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                OCC
                                <div>
                                    <span style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                        Showing Results From
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.startDate.format("Do MMM")}}</span>
                                        to
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                    </span>
                                    <a data-toggle="collapse" href="#collapseOcc">
                                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                            class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                            style="margin-top: -5px;" *ngIf="exte">
                                        <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                            class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                            style="margin-top: -5px;" *ngIf="!exte">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="collapseOcc" class="text panel-collapse collapse in">
                            <hr class="hhr">
                            <div class="">
                                <div class="col-md-7 pl-0" *ngIf="r_data != undefined" style="height: 312px;     overflow: auto;">

                                    <!-- <div class="bar-box2" *ngFor="let oc of r_data.Occ; let i = index;"> -->
                                    <div class="bar-box2" *ngFor="let oc of r_data.Occ; let i = index;">
                                        <div class="fle">
                                            <label for="" class="lab-text"
                                                style="text-transform: capitalize;">{{oc.channel_image.split('.')[0]}}</label>
                                            <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{oc.occ
                                                }} %</label>
                                        </div>
                                        <div [attr.data-roomname]="oc.channel_image.split('.')[0]"
                                            class="progress-back">
                                            <div class="progress" [ngStyle]="{'width': oc.occ+'%'}">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-5 donutRes">
                                    <div class="ebr-total" [ngStyle]="{'color': darkMode ? '#fff' : '#000'}"
                                        *ngIf="occ_total != undefined">{{occ_total.toFixed(0)}}%</div>
                                    <canvas id="occ1" class="chartjs-render-monitor overridecanvas2"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dataWrapper drag_box" id="second">
                <div class="col-md-12 wht-box hov drag_item" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                    [ngClass]="exte ? 'shadow' : 'shadow-none'" id="avg_daily_rate">
                    <div class="panel-collapse padding-form collapse in">
                        <div>
                            <div class="summary-top headingOne0"
                                [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                ADR
                                <div>
                                    <span style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                        Showing Results From
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.startDate.format("Do MMM")}}</span>
                                        to
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                    </span>
                                    <a data-toggle="collapse" href="#collapseAdr">
                                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                            class="mR10" (click)="chandrop('buttonAdr')" id="buttonAdr"
                                            style="margin-top: -5px;" *ngIf="exte">
                                        <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                            class="mR10" (click)="chandrop('buttonAdr')" id="buttonAdr"
                                            style="margin-top: -5px;" *ngIf="!exte">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="collapseAdr" class="text panel-collapse collapse in">
                            <hr class="hhr">
                            <div class="">
                                <div class="col-md-7 pl-0" *ngIf="r_data != undefined" style="height: 312px;     overflow: auto;">
                                    <div class="bar-box2" *ngFor="let adr of r_data.ADR ; let i = index;">
                                        <div class="fle">
                                            <label for="" class="lab-text"
                                                style="text-transform: capitalize;">{{adr.channel_image.split('.')[0]}}</label>
                                            <label for=""
                                                [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{restriction ==
                                                true ? currency_format+' ' +(adr.channel_sum | number : '1.2-2') :
                                                adr.percentage+'%'}}</label>
                                        </div>
                                        <div [attr.data-roomname]="adr.channel_image.split('.')[0]"
                                            class="progress-back">
                                            <div class="progress" [ngStyle]="{'width': adr.percentage+'%'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 donutRes">
                                    <div class="ebr-total" [ngStyle]="{'color': darkMode ? '#fff' : '#000'}"
                                        *ngIf="adr_total != undefined"> <span class="currency">{{ currency_format
                                            }}</span>{{(adr_total.toFixed(0) | number : '1.2-3').slice(0,-3)}}<span
                                            class="currency">{{(ebr_total | number : '1.2-3').slice(-3)}}</span></div>
                                    <canvas id="ADR1" class="chartjs-render-monitor overridecanvas2"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 wht-box hov drag_item" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                    [ngClass]="exte ? 'shadow' : 'shadow-none'" id="rev_par">
                    <div class="panel-collapse padding-form collapse in">
                        <div>
                            <div class="summary-top headingOne0"
                                [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                RevPAR
                                <div>
                                    <span style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                        Showing Results From
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.startDate.format("Do MMM")}}</span>
                                        to
                                        <span style="color:#fb4139;">
                                            {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                    </span>
                                    <a data-toggle="collapse" href="#collapseRev">
                                        <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                            class="mR10" (click)="chandrop('buttonRev')" id="buttonRev"
                                            style="margin-top: -5px;" *ngIf="exte">
                                        <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                            class="mR10" (click)="chandrop('buttonRev')" id="buttonRev"
                                            style="margin-top: -5px;" *ngIf="!exte">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="collapseRev" class="text panel-collapse collapse in">
                            <hr class="hhr">
                            <div class="" *ngIf="r_data != undefined">
                                <div class="col-md-7 pl-0" style="height: 312px; overflow: auto;">
                                    <div class="bar-box2" *ngFor="let rp of r_data.RevPar; let i = index;">
                                        <div class="fle">
                                            <label for="" class="lab-text"
                                                style="text-transform: capitalize;">{{rp.channel_image.split('.')[0]}}</label>
                                            <label for=""
                                                [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{restriction ==
                                                true ? currency_format+' ' +(rp.rev_par | number : '1.2-2') :
                                                rp.percentage+'%' }}</label>
                                        </div>

                                        <div [attr.data-roomname]="rp.channel_image.split('.')[0]"
                                            class="progress-back">
                                            <div class="progress" [ngStyle]="{'width': rp.percentage+'%'}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5 donutRes">
                                    <div class="ebr-total" [ngStyle]="{'color': darkMode ? '#fff' : '#000'}"
                                        *ngIf="rev_total != undefined"> <span class="currency">{{ currency_format
                                            }}</span>{{(rev_total.toFixed(0) | number : '1.2-3').slice(0,-3)}}<span
                                            class="currency">{{(ebr_total | number : '1.2-3').slice(-3)}}</span></div>
                                    <canvas id="REV1" class="chartjs-render-monitor overridecanvas2"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="room-cards" *ngIf="current_donut == 'By Room'">
            <div class="flex text space-between total-bar">
                <div>Performance</div>
                <a data-toggle="collapse" href="#collapse-performance">
                    <img src="assets/img/reports/button_down_dark.svg" class="mR10" (click)="chandrop('button1')"
                        id="button1" style="margin-top: -5px;" *ngIf="exte">
                    <img src="assets/img/svgicons/all-channels-webp/button_down_dark.webp" class="mR10"
                        (click)="chandrop('button1')" id="button1" style="margin-top: -5px;" *ngIf="!exte">
                </a>
            </div>

            <div class="" id="collapse-performance" *ngIf="d_data.length > 0" class="collapse in">
                <div class="col-md-6 padng drag_box" id="third">
                    <!-- total main hotel starts -->
                    <div class="col-md-12 zero-padding drag_item">
                        <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                            [ngClass]="exte ? 'shadow' : 'shadow-none'">
                            <div class="panel-collapse padding-form collapse in">
                                <div class="">
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Manager KPI's
                                        <!-- key performance indicators -->
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span
                                                    style="color:#fb4139;font-size: 16px">{{this.choosen_date.startDate.format("DoMMM")}}
                                                </span> to
                                                <span
                                                    style="color:#fb4139;font-size: 16px">{{this.choosen_date.endDate.format("DoMMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#hotelcollapse100125">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonDown')" id="buttonDown"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonDown')" id="buttonDown"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="hotelcollapse100125" class="text panel-collapse collapse in">
                                    <div class="text panel-collapse collapse in"
                                        *ngFor="let all of all_hotels;let i = index ">
                                        <hr class="hhr">
                                        <div class="d-flex" *ngIf="i == 0">
                                            <div class="col-md-6" style="padding: 0; width: 57%;">
                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Occupancy Rate</label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{all.occ}}%</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress0" [ngStyle]="{'width': all.occ+'%'}">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">LOS</label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{all.los}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress1"
                                                            [ngStyle]="{'width': (all.los / all.sold)+'%'}">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Avg . Night Stayed per Room
                                                        </label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                            getTotalAvgNightStayedPerRoom(all) | number :
                                                            '1.1-1'}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress2"
                                                            [ngStyle]="{'width': (all.los / all.sold)+'%'}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Avg . Daily Rate (ADR) </label>
                                                        <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">
                                                            {{ currency_format }} {{getTotalAdr(all) | number :
                                                            '1.2-2'}}
                                                        </label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress3"
                                                            [ngStyle]="{'width': calculatePrecentage(getTotalAdr(all) * 10,hotel_sum_total)}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Revenue Per Room (RevPAR)
                                                        </label>
                                                        <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">
                                                            {{ currency_format }} {{getTotalRevPar(all) | number :
                                                            '1.2-2'}}
                                                        </label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress4"
                                                            [ngStyle]="{'width': calculatePrecentage((+getTotalRevPar(all) || 0) * 10, hotel_sum_total)}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">

                                                        <label for="" class="lab-text">Avg . Lead Time</label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{(getTotalAvgLeadTime(all))
                                                            | number : '1.1-1'}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress7"
                                                            [ngStyle]="{'width': (getTotalAvgLeadTime(all) * 10) +'%'}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Cancellation </label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{all.cancellation}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress5"
                                                            [ngStyle]="{'width':all.cancellation <= 100 ? all.cancellation+'%' : '100%'}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">

                                                        <label for="" class="lab-text">Sold</label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{all.sold}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress6"
                                                            [ngStyle]="{'width':all.sold <= 100 ? all.sold+'%' : '100%'}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Discount </label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                            restriction ? currency_format+ " " + (all.discount | number
                                                            :
                                                            '1.2-2') : calculatePrecentage(all.discount,
                                                            hotel_sum_total)
                                                            }}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress10"
                                                            [ngStyle]="{'width': calculatePrecentage(all.discount,hotel_sum_total)}">
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="bar-box">
                                                <div class="fle">

                                                    <label for="" class="lab-text">Unpaid</label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{all.unpaid_count}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress6" [ngStyle]="{'width':all.unpaid_count <= 100 ? 0+'%' : '100%'}">
                                                    </div>
                                                </div>
                                            </div> -->

                                                <div class="bar-box">
                                                    <div class="fle">
                                                        <label for="" class="lab-text">Unpaid</label>
                                                        <label for=""
                                                            [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                            currency_format }} {{all.totalUnpaid | number :
                                                            '1.2-2'}}</label>
                                                    </div>
                                                    <div class="progress-back">
                                                        <div class="progress9"
                                                            [ngStyle]="{'width':calculatePrecentage(all.totalUnpaid,hotel_sum_total)}">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dount col-md-6 d-flex justify-content-center align-items-center"
                                                style="width: 43%;padding-left: 50px;">
                                                <div class="" style=" font-size: 21px; position: absolute; top:39%;"
                                                    [ngStyle]="{'color': (darkMode ? '#fff' : '#000')}">
                                                    <span class="currency">{{currency_format}}</span>
                                                    {{(hotel_sum_total | number : '1.2-3').slice(0,-3)}}
                                                    <span class="currency">{{(ebr_total | number : '1.2-3').slice(-3)}}
                                                    </span>
                                                </div>
                                                <div class="p-1 d-flex flex-column justify-content-center align-items-center"
                                                    style="width: 335px;">
                                                    <canvas id="myChart_hotel" class="kpi-donut"></canvas>
                                                    <div class="legend_kpi">
                                                        <div class="legend-room">
                                                            <div class="d-flex mr-4"
                                                                [ngClass]="darkMode ? 'text-light' : 'null'"
                                                                *ngFor="let roomname of roomDisplayName.slice(0,4);index as i">
                                                                <div class="legendColor"
                                                                    [ngStyle]="{'background': channel_colors[i]}"></div>
                                                                {{roomname}}
                                                            </div>
                                                        </div>
                                                        <div class="legend-room">
                                                            <div class="d-flex mr-4"
                                                                [ngClass]="darkMode ? 'text-light' : 'null'"
                                                                *ngFor="let roomname of roomDisplayName.slice(4);index as i">
                                                                <div class="legendColor"
                                                                    [ngStyle]="{'background': channel_colors[i+4]}">
                                                                </div>
                                                                {{roomname}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- total main hotel ends -->
                    <div class="col-md-12 zero-padding drag_item" *ngFor="let d of d_data; let i = index;">
                        <div class="col-md-12 wht-box hov" *ngIf="i % 2 != 0"
                            [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                            [ngClass]="exte ? 'shadow' : 'shadow-none'">
                            <div class="panel-collapse padding-form collapse in">
                                <div class="">
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        {{d.room_display_name}}
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span
                                                    style="color:#fb4139;">{{this.choosen_date.startDate.format("DoMMM")}}</span>
                                                to
                                                <span
                                                    style="color:#fb4139;">{{this.choosen_date.endDate.format("DoMMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#{{i}}collapse100121">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('button'+i)" id="button{{i}}"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('button'+i)" id="button{{i}}"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="{{i}}collapse100121" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <div class="canvas">
                                        <div class="col-md-7 pl-0">
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Occupancy Rate</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.occupany_rate}}%</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress0" [ngStyle]="{'width': d.occupany_rate+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">LOS</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.total_nights}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress1" [ngStyle]="{'width': d.avg_night_stay+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Night Stayed per Room </label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.avg_night_stay}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress2" [ngStyle]="{'width': d.avg_night_stay+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Daily Rate (ADR) </label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                        currency_format }} {{d?.avg_daily_rate | number :
                                                        '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress3"
                                                        [ngStyle]="{'width': d.avg_daily_rate_percentage+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Revenue Per Room (RevPAR) </label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'"> {{
                                                        currency_format }} {{d?.rev_par | number : '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress4"
                                                        [ngStyle]="{'width': d.rev_par_percentage+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Lead Time</label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">
                                                        {{d?.avg_lead}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress7" [ngStyle]="{'width': d?.avg_lead+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="bar-box">
                                            <div class="fle">
                                                <label for="" class="lab-text">Discount </label>
                                                <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.discount}}</label>
                                            </div>
                                            <div class="progress-back">
                                                <div class="progress10" [ngStyle]="{'width': d.discount <= 100 ? d.discount+'%' : '100%'}">
                                                </div>
                                            </div>
                                        </div> -->
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Cancellations </label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.cancellation}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress5"
                                                        [ngStyle]="{'width': d.cancellation <= 100 ? d.cancellation+'%' : '100%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <!-- <label for="" class="lab-text">Reservations </label> -->
                                                    <label for="" class="lab-text">Sold</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.reservation}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress6"
                                                        [ngStyle]="{'width': d.reservation <= 100 ? d.reservation+'%' : '100%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Discount</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{restriction
                                                        ?
                                                        currency_format+ " "+ (d.total_discounted_price| number :
                                                        '1.2-2') :
                                                        calculatePrecentage(d.total_discounted_price,d.total_sum)}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress10"
                                                        [ngStyle]="{'width':calculateDiscountPercentage(d.room_type_name)}">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="bar-box">
                                            <div class="fle">

                                                <label for="" class="lab-text">Unpaid Count</label>
                                                <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.unpaid_count}}</label>
                                            </div>
                                            <div class="progress-back">
                                                <div class="progress6" [ngStyle]="{'width': d.unpaid_count <= 100 ? d.unpaid_count+'%' : '100%'}">
                                                </div>
                                            </div>
                                        </div> -->
                                            <div class="bar-box">
                                                <div class="fle">

                                                    <label for="" class="lab-text">Unpaid</label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'"> {{
                                                        currency_format }} {{d?.unpaid_sum | number : '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress9"
                                                        [ngStyle]="{'width': calculatePrecentage(d.unpaid_sum,d.total_sum)}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="donut-price room-price"
                                                [ngStyle]="{'color': darkMode ? '#fff' : '#000'}">

                                                <span class="currency" *ngIf="restriction">{{currency_format}}</span>
                                                {{restriction ? (d.total_sum | number : '1.2-3').slice(0,-3) :
                                                d.total_sum_percentage+'%'}}
                                                <span class="currency" *ngIf="restriction">{{(ebr_total | number :
                                                    '1.2-3').slice(-3)}}</span>
                                            </div>
                                            <canvas id="myChart_{{i}}" chart-labels="chartLabels"
                                                chart-options="chartOptions"
                                                class="chartjs-render-monitor overridecanvas"></canvas>
                                            <div class="perfor">
                                                <div class="flexx" style="margin-top: 10px;">
                                                    <div class="kuchbhi flexx"
                                                        *ngFor="let small of d.channels | slice: 0:8;index as c">
                                                        <div class="legendColor"
                                                            [attr.data-channelname]="small.channel_name.toLowerCase()">
                                                        </div>
                                                        <img src="assets/img/svgicons/smallicons/{{small?.channel_image}}"
                                                            class="legends" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 padnggg drag_box" id="fourth">
                    <div *ngFor="let d of d_data; let j = index;" class="col-md-12 zero-padding drag_item">
                        <div class="col-md-12 wht-box hov" *ngIf="j % 2 == 0"
                            [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                            [ngClass]="exte ? 'shadow' : 'shadow-none'">
                            <div class="panel-collapse padding-form collapse in">
                                <div class="">
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        {{d.room_display_name}}
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span
                                                    style="color:#fb4139;font-size: 16px">{{this.choosen_date.startDate.format("Do
                                                    MMM")}}</span> to
                                                <span
                                                    style="color:#fb4139;font-size: 16px">{{this.choosen_date.endDate.format("Do
                                                    MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#{{j}}collapse100125">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('button'+j)" id="button{{j}}"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('button'+j)" id="button{{j}}"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="{{j}}collapse100125" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <div class="canvas">
                                        <div class="col-md-7 pl-0">
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Occupancy Rate</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.occupany_rate}}%</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress0" [ngStyle]="{'width': d.occupany_rate+'%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">LOS</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.total_nights}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress1" [ngStyle]="{'width': d.avg_night_stay+'%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Night Stayed per Room </label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.avg_night_stay}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress2" [ngStyle]="{'width': d.avg_night_stay+'%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Daily Rate (ADR) </label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                        currency_format }} {{d?.avg_daily_rate | number :
                                                        '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress3"
                                                        [ngStyle]="{'width': d.avg_daily_rate_percentage+'%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Revenue Per Room (RevPAR) </label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{
                                                        currency_format }} {{d?.rev_par | number : '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress4"
                                                        [ngStyle]="{'width': d.rev_par_percentage+'%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Avg . Lead Time</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.avg_lead}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress7" [ngStyle]="{'width': d?.avg_lead+'%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Cancellation </label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.cancellation}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress5"
                                                        [ngStyle]="{'width': d.cancellation <= 100 ? d.cancellation+'%' : '100%'}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Sold</label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{d?.reservation}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress6"
                                                        [ngStyle]="{'width': d.reservation <= 100 ? d.reservation+'%' : '100%'}">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="bar-box">
                                                <div class="fle">
                                                    <label for="" class="lab-text">Discount </label>
                                                    <label for=""
                                                        [ngClass]="darkMode ? 'right-per' : 'right-per2'">{{restriction
                                                        ?
                                                        currency_format + (d.total_discounted_price | number : '1.2-2')
                                                        :
                                                        calculatePrecentage(d.total_discounted_price,d.total_sum)}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress10"
                                                        [ngStyle]="{'width': calculateDiscountPercentage(d.room_type_name)}">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bar-box">
                                                <div class="fle">

                                                    <label for="" class="lab-text">Unpaid</label>
                                                    <label for="" [ngClass]="darkMode ? 'right-per' : 'right-per2'"> {{
                                                        currency_format }} {{d?.unpaid_sum | number : '1.2-2'}}</label>
                                                </div>
                                                <div class="progress-back">
                                                    <div class="progress9"
                                                        [ngStyle]="{'width': calculatePrecentage(d.unpaid_sum,d.total_sum)}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="donut-price" [ngStyle]="{'color': darkMode ? '#fff' : '#000'}">
                                                <span class="currency"
                                                    *ngIf="restriction">{{currency_format}}</span>{{restriction ?
                                                (d.total_sum | number : '1.2-3').slice(0,-3) :
                                                d.total_sum_percentage+'%'
                                                }}<span class="currency" *ngIf="restriction">{{(ebr_total | number :
                                                    '1.2-3').slice(-3)}}</span>
                                            </div>
                                            <canvas id="myChart_1{{j}}"
                                                class="chartjs-render-monitor overridecanvas"></canvas>
                                            <div class="perfor">
                                                <div class="flexx" style="margin-top: 10px;">
                                                    <div class="kuchbhi"
                                                        *ngFor="let small of d.channels | slice: 0:8;let c = index;">
                                                        <div class="legendColor"
                                                            [attr.data-channelname]="small.channel_name.toLowerCase()">
                                                        </div>
                                                        <img src="assets/img/svgicons/smallicons/{{small.channel_image}}"
                                                            class="legends" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div id="financials-cards" *ngIf="current_donut == 'By Room'">
        <div class="flex text space-between total-bar w-100">
            <div>Financials</div>
            <a data-toggle="collapse" href="#collapse-financials">
                <img src="assets/img/reports/button_down_dark.svg" class="mR10" (click)="chandrop('button2')"
                    id="button2" style="margin-top: -5px;" *ngIf="exte">
                <img src="assets/img/svgicons/all-channels-webp/button_down_dark.webp" class="mR10"
                    (click)="chandrop('button2')" id="button2" style="margin-top: -5px;" *ngIf="!exte">
            </a>
        </div>

        <div id="collapse-financials" class="p-1 collapse in">
            <!-- left hand side chart cards -->
            <div class="col-md-6 p_r drag_box" id="fivth">
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="metrics">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Financial Performance
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#collapse-financial-performance">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('button3')" id="button3"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('button3')" id="button3"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapse-financial-performance" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-financial-performance
                                        [choosen-date]="choosen_date"></app-financial-performance>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        OPEX
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#opex-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="opex-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-opex [choosenDate]="choosen_date"></app-opex>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        OPEX
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">{{this.choosen_date.startDate.format("Do
                                                    MMM")}}</span> to
                                                <span style="color:#fb4139;">{{this.choosen_date.endDate.format("Do
                                                    MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#opex-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOpex')" id="buttonOpex"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOpex')" id="buttonOpex"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="opex-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <Opex-var-N-fix [choosenDate]="choosen_date"></Opex-var-N-fix>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- right hand side chart cards -->
            <div class="col-md-6 p-0 drag_box" id="sixth">
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Discount Tracking
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#collapse-discount-tracking">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="collapse-discount-tracking" class="text panel-collapse collapse in"
                                    [ngClass]="darkMode ? 'dis_dark_card' : 'null'">
                                    <hr class="hhr">
                                    <div class="d-flex justify-content-around align-items-center"
                                        style="height: 375px;">
                                        <div class="h-100 d-flex flex-column justify-content-center align-items-center"
                                            style="width:50%">
                                            <div class="overflow_scroll">
                                                <div class="bar-box2 mt-4"
                                                    *ngFor="let room of DiscountGraphModel.room_data; let i = index;">
                                                    <label for="" class="room_name">{{room.room_display_name}}</label>
                                                    <div
                                                        style="display: flex; flex-direction: row; align-items: end; line-height: 0.8;">
                                                        <label for=""
                                                            style="text-transform: capitalize; line-height:1.2;"
                                                            class="animate-2s"
                                                            [ngStyle]="{'width':calculateDiscountTrackingWidth(room,false,-1)}"></label>
                                                        <label class="lab-text animate-2s"
                                                            [ngStyle]="{'width':calculateDiscountTrackingWidth(room,true,-1)}">
                                                            {{room.regular_count}}
                                                        </label>
                                                        <label class="lab-text">{{room.discount_count}}</label>
                                                    </div>
                                                    <div class="mb-2">
                                                        <div class="discount-tracking-bars">
                                                            <div class="discount-tracking-regular-count-bar animate-2s"
                                                                [ngStyle]="{'width': calculateDiscountTrackingWidth(room,false)}">
                                                            </div>
                                                            <div class="discount-tracking-discount-count-bar animate-2s"
                                                                [ngStyle]="{'width': calculateDiscountTrackingWidth(room,true)}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="discount-tracking-canvas">
                                            <canvas id="discount-tracking-canvas" class="chartjs-render-monitor"
                                                width="450" height="223"></canvas>
                                        </div>
                                    </div>
                                    <!-- Discount tracking section legend (working on saud) 👇 -->
                                    <div class="d-flex flex-row justify-content-around pb-3 legend">
                                        <div class="d-inline-flex flex-row justify-content-around">
                                            <div class="mr-5 d-flex align-items-center legend_hover"><span
                                                    class="discount-tracking-legend-indicator"
                                                    style="background-color: #FF6464;"></span> Non Discounted Units
                                            </div>
                                            <div class="d-flex align-items-center legend_hover"><span
                                                    class="discount-tracking-legend-indicator"
                                                    style="background-color: #820224;"></span> Discounted Units
                                            </div>
                                        </div>
                                        <div class="d-inline-flex flex-row justify-content-around">
                                            <div class="mr-5 d-flex align-items-center legend_hover"><span
                                                    class="discount-tracking-legend-indicator"
                                                    style="background-color: #00295F;"></span> Revenue </div>
                                            <div class="d-flex align-items-center legend_hover"><span
                                                    class="discount-tracking-legend-indicator"
                                                    style="background-color: #1CB5F9;"></span> Discount </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12  wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Break-Even
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#breakeven-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="breakeven-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-break-even [choosenDate]="choosen_date"></app-break-even>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="trend-cards" *ngIf="current_donut == 'By Room'">
        <div class="flex text space-between total-bar w-100">
            <div>Trends</div>
            <a data-toggle="collapse" href="#trends-chart">
                <img src="assets/img/reports/button_down_dark.svg" class="mR10" (click)="chandrop('button2')"
                    id="button3" style="margin-top: -5px;" *ngIf="exte">
                <img src="assets/img/svgicons/all-channels-webp/button_down_dark.webp" class="mR10"
                    (click)="chandrop('button2')" id="button3" style="margin-top: -5px;" *ngIf="!exte">
            </a>
        </div>

        <div id="trends-chart" class="p-1 collapse in">
            <!-- left hand side chart cards -->
            <div class="col-md-6 p_r drag_box" id="fivth">
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Revenue Trends
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#expense-trends-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="expense-trends-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-revenue-trends [choosenDate]="choosen_date"></app-revenue-trends>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12 wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Cost to Float
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#revenue-trends-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="revenue-trends-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-expenses-trend [choosenDate]="choosen_date"></app-expenses-trend>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- right hand side chart cards -->
            <div class="col-md-6 p_r drag_box" id="fivth">
                <div class="col-md-12 p-0 drag_item">
                    <div class="col-md-12  wht-box hov" [ngStyle]="{'background': darkMode ? '#000213' : '#fff'}"
                        [ngClass]="exte ? 'shadow' : 'shadow-none'">
                        <div class="" id="checkins">
                            <div class="panel-collapse padding-form collapse in">
                                <div>
                                    <div class="summary-top headingOne0"
                                        [ngStyle]="{'color': darkMode ? '#fb4139' : '#00205b'}">
                                        Opportunities
                                        <div>
                                            <span
                                                style="font-size: 14px;color: #7b5d5d;margin-right: 10px;font-weight: bold;">
                                                Showing Results From
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.startDate.format("Do MMM")}}</span> to
                                                <span style="color:#fb4139;">
                                                    {{this.choosen_date.endDate.format("Do MMM")}}</span>
                                            </span>
                                            <a data-toggle="collapse" href="#upsells-card">
                                                <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="exte">
                                                <img src="assets/img/svgicons/all-channels-webp/{{darkMode ? 'button_down_dark' : 'button_down'}}.webp"
                                                    class="mR10" (click)="chandrop('buttonOcc')" id="buttonOcc"
                                                    style="margin-top: -5px;" *ngIf="!exte">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div id="upsells-card" class="text panel-collapse collapse in">
                                    <hr class="hhr">
                                    <app-upsells></app-upsells>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>