import { Component, OnInit } from '@angular/core';
import { PermissionData, UpdatedPermissionsResponse } from 'src/app/common/permissions/updatedPermissionsModel';
import { PnlService } from 'src/app/services/pnl.service';

@Component({
  selector: 'app-stayspermission',
  templateUrl: './stayspermission.component.html',
  styleUrls: ['./stayspermission.component.css']
})
export class StayspermissionComponent implements OnInit {
  darkMode: boolean = false;
  permissions: PermissionData[] = [];

  constructor(
    private pnlservice: PnlService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.pnlservice.getPermissions().subscribe((res: UpdatedPermissionsResponse) => {
      this.permissions = res.data;
      console.log(this.permissions, "permission");
    })
  }
  // changeclass(id: any, index: number) {
  //   let ids = <HTMLElement>document.getElementById(id + index)
  //   console.log(ids);

  //   if (ids.classList.contains("rotate")) {
  //     ids.classList.remove("rotate")
  //   } else {
  //     ids.classList.add("rotate")
  //   }
  // }
  changeclass(id: any) {
    let ids = <HTMLElement>document.getElementById(id)
    console.log(ids);

    if (ids.classList.contains("rotate")) {
      ids.classList.remove("rotate")
    } else {
      ids.classList.add("rotate")
    }
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  setId(name) {
    let replaceToday = name.replace("Today's", "Today");
    let replaceFood = replaceToday.replace("Food & Beverages", "Food Beverages")
    let replaceName = replaceFood.replace("BP & TA", "BP TA")
    let newName = replaceName.replaceAll(' ', '_');
    return newName;
  }
}
