import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
declare var $: any;
@Component({
  selector: 'app-preview-rooms',
  templateUrl: './preview-rooms.component.html',
  styleUrls: ['./preview-rooms.component.css']
})
export class PreviewRoomsComponent implements OnInit {

  previewData: any;
  p_image;
  darkMode: boolean = false;
  constructor(
    private api: ApiService,
    private helper: HelperService,
    private router: Router,
    private error_handling: ErrorHandlingService,
    private fb: FormBuilder,
  ) { }

  //  amenity0 = [
  // {
  //   pTitle:'Bedding & Bath',
  //   id:18,
  //   cTitle:[]
  // },
  // { 
  //   pTitle:'Cookery',
  //   id:19,
  //   cTitle:[]
  // },
  // {
  //   pTitle:'Entertainment',
  //   id:20,
  //   cTitle:[]
  // },
  // {
  //   pTitle:'General',
  //   id:17,
  //   cTitle:[]
  // },
  // {
  //   pTitle:'Laundry',
  //   id:21,
  //   cTitle:[]
  // },
  // {
  //   pTitle:'Room Specific',
  //   id:22,
  //   cTitle:[]
  // },
  //  ];

  amenity0 = [
    {
      pTitle: 'Bedding & Bath',
      id: 18,
      pImg: 'room_bed',
      cTitle: []
    },
    {
      pTitle: 'Cookery',
      id: 19,
      pImg: 'cookery',
      cTitle: []
    },
    {
      pTitle: 'Entertainment',
      id: 20,
      pImg: 'entertainment',
      cTitle: []
    },
    {
      pTitle: 'General',
      id: 17,
      pImg: 'room_general',
      cTitle: []
    },
    {
      pTitle: 'Laundry',
      id: 21,
      pImg: 'laundry',
      cTitle: []
    },
    {
      pTitle: 'Room Specific',
      id: 22,
      pImg: 'room_specific',
      cTitle: []
    },
  ];
  cribsData: any;
  base_url: any;
  base_url2: any;
  amenCol = ['#7ae997', '#de888d', '#6c6ce7', '#da8639', '#f1c1f8', '#d4575a', '#e7d67c', '#4cdbbf', '#81a2ff'];


  ngOnInit() {
    history.state.navigationId == 1 ? this.router.navigate(['/app/back_office/']) : this.previewData = history.state;
    console.log(this.previewData)
    this.p_image = this.previewData.room_name_images[0].image;
    this.aminity0();
    this.base_url = this.api.base_url;
    this.base_url2 = this.api.base_url2;
    this.checkDarkMode();
  }

  img_preview(img) {
    this.p_image = img
  }

  getArr(a) {
    let r = a.split(',');
    return r;
  }

  changeCaret1(y) {
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let a = document.getElementById(y);
    if (a.className == "fa fa-caret-up down2") {
      a.className = "fa fa-caret-down down2"
    }
    else {
      a.className = "fa fa-caret-up down2"
    }
  }

  changeCaret2(z) {
    // document.getElementById('caretOuter').className = "fa fa-caret-up down2";
    let b = document.getElementById(z);
    if (b.className == "fa fa-caret-down down1") {
      b.className = "fa fa-caret-up down1"
    }
    else {
      b.className = "fa fa-caret-down down1"
    }

  }

  onSubmit() {
    // this.api.post('api/room/store', this.create_room_form.value,true).subscribe((res:any) => {
    //   this.helper.alert_success("Room Added Successfully...");
    //   this.router.navigate(['/app/back_office/manage_rooms']);
    // }, err => this.error_handling.handle_error(err.status));
    let f = [];
    this.previewData.floors_data.map((val) => {
      val.floors.map((val2) => {
        f.push(
          {
            building_id: val.id,
            floor_id: val2.id,
            roomnums: val2.roomnums
          })
      })
      var filtered = f.filter(function (el) {
        return el != null;
      });
    })
    if (this.previewData.extrabedding) {
      this.cribsData = [
        {
          type: this.previewData.extrabedding[0].type,
          quantity: this.previewData.extrabedding[0].quantity,
          size: 'Crib',
          surcharge_type: this.previewData.extrabedding[0].surcharge_type,
          surcharge_amount_type: this.previewData.extrabedding[0].surcharge_amount_type,
          surcharge_amount: this.previewData.extrabedding[0].surcharge_amount
        },
        {
          type: this.previewData.extrabedding[1].type,
          quantity: this.previewData.extrabedding[1].quantity,
          size: 'Full',
          surcharge_type: this.previewData.extrabedding[1].surcharge_type,
          surcharge_amount_type: this.previewData.extrabedding[1].surcharge_amount_type,
          surcharge_amount: this.previewData.extrabedding[1].surcharge_amount
        },
      ];

    }

    let a = {
      adults: this.previewData.adults,
      bed_type_id: this.previewData.bed_type_id,
      bed_type_name: this.previewData.bed_type_name,
      children: this.previewData.children,
      description: this.previewData.description,
      floors_data: f,
      infants: this.previewData.infants,
      most_amenties: this.previewData.most_amenties,
      no_of_beds: this.previewData.no_of_beds,
      property_id: this.previewData.property_id,
      room_count: this.previewData.room_count,
      room_name_images: this.previewData.room_name_images,
      room_size: this.previewData.room_size,
      room_type_id: this.previewData.room_type_id,
      room_type_name: this.previewData.room_type_name,
      smoking: this.previewData.smoking.toString(),
      room_type_class: this.previewData.room_type_class,
      room_size_type: this.previewData.room_size_type,
      bedsize: this.previewData.bedsize,
      room_view_id: this.previewData.room_view_id,
      adults_age: this.previewData.adults_age,
      display_name: this.previewData.display_name,
      children_age: this.previewData.children_age,
      infants_age: this.previewData.infants_age,
      pets: this.previewData.pets.toString(),
      wheelchairaccessibility: this.previewData.wheelchairaccessibility.toString(),
      // value_add_inclusions: this.previewData.value_add_inclusion.join(),
      extrabedding: this.cribsData,
    }


    this.api.post('api/room/store', a, true).subscribe((res: any) => {
      this.helper.alert_success("Room Added Successfully...");
      this.router.navigate(['/app/back_office/manage_rooms']);
    }, err => this.error_handling.handle_error(err.status));
  }

  aminity0() {
    let ami = this.previewData.amenities_title
    ami.map((val0) => {
      this.amenity0.map((val1) => {
        if (val1.id == val0.parentId) {
          val1.cTitle.push({ name: val0.title })
        }
      })
    })
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

}
