<div style="padding-right: 05px;" id="SS">
  <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a> <a
      class="btn btn-link active" data-dismiss="modal" routerLink="setup_rate">PREVIEW RATE PLAN</a>
  </div>

  <div class="no_scroll scroll">
    <div *ngIf="package_data != undefined" style="    padding-left: 2px;">
      <div class="card" style="padding-bottom: 0px !important;    padding-right: 0px;"
        *ngFor="let pr of package_data.season_room_types; let x = index;"
        [ngClass]="darkMode ? 'card-dark-side shadowDark' : ' card-null-shadow '">
        <div id="{{selected == x ? 'yah' : 'no'}}">
          <div class="row" style="padding: 0px 13px;transform: translateY(1px);">
            <div class="col-md-12">
              <div class="col-md-2 text-center " style="width: 14%; ">
                <div class="text-left seas" style="transform: translateY(-6px);">
                  <span class="season-name {{ colors2[0] }}"
                    style="transform: translateY(-3px);text-transform: capitalize">{{package_data.season_name}}</span>
                </div>
              </div>
              <div class="col-md-3" style="width: 33%;padding-left: 25px !important;">
                <div class="R-Name">
                  <span class="room-name {{ colors2[1] }}">{{pr.room.roomtype.name}}</span>
                </div>
                <div class="Acc"
                  style="transform: translateY(-6px);width: 50%; float: left;text-align: center;padding-left: 0px;">
                  <span class="room-name {{ colors2[2] }}">Accessibility</span>
                </div>
              </div>
              <div class="col-md-3 rateDis text-center">
                <div class="rateD R-des">
                  <div class="allocation-name {{ colors2[3] }}" style="transform: translateY(-6px);padding-left: 0%;">
                    Rate Distribution
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-center R-rates">
                <div class="rateD R-r">
                  <div class="allocation-name {{ colors2[4] }}" style="transform: translateY(-6px);">Rates</div>
                </div>
              </div>
              <div class="col-md-1 dropArrow text-right">
                <a data-toggle="collapse" href="#collapse{{x}}" aria-expanded="false" aria-controls="collapseOne">
                  <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg" id="show8{{x}}"
                    class="dropBtn" (click)="chngedrop('show8'+x)">
                </a>
              </div>
            </div>
          </div>
          <div class="row collapse" style="padding: 0px 5px;padding-right: 15px;" id="collapse{{x}}">
            <div class="col-md-12" style="margin-bottom:4px">
              <div class="col-md-2 text-center over-ride-wi" style="width: 15%; ">
                <div class="" [ngClass]="darkMode ? 'datar' : 'datar2'">
                  <div class="row" style="padding: 0px 22px;">
                    <div class="col-md-12 text-center">
                      <div class="" [ngClass]="darkMode ? 'Date0' : 'Date02'">{{package_data.date_to | date:'dd MMM'}} |
                        {{package_data.date_from | date:'dd MMM'}}</div>
                    </div>
                    <div class="col-md-12 text-center" style="margin-top: 5px;">
                      <div class="" [ngClass]="darkMode ? 'high0' : 'high02'">{{package_data.demand_type}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 over-ride-wid" style="width: 32%">
                <div class="col-md-6" style="padding-left: 0px !important;">
                  <img
                    src="{{(pr?.room?.images)[0]?.image ? api.imgUrl + pr.room.images[0]?.image : 'assets/img/other/no-room.jpg'}}"
                    class="roomimg" style="height: 125px;border-radius: 8px;">
                </div>
                <div class="col-md-6" style="    padding-right: 0px !important;">
                  <div style="margin-top: -7px;">
                    <div class="" [ngClass]="darkMode ? 'img-div' : 'img-div2'">
                      <div class="" [ngClass]="darkMode ? 'dabba' : 'dabba2'">
                        <div class="amine-color2">
                          <span class="the-span">
                            <img class="beddd"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                              [ngStyle]="darkMode ? {'margin' : '-16px 0px -5px 0px', 'height': '35px'} : {'margin-top' : '-12px','height':'33px'}"
                              style="height: 35px;" />
                          </span>
                        </div>
                        <div class="amine-color2" *ngIf="pr.room.wheelchairaccessibility != ''">
                          <span class="the-span">
                            <img class="wheelchair" *ngIf="pr?.room?.wheelchairaccessibility == 'true'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                              [ngStyle]="darkMode ? {'margin' : '-18px 0px -5px 0px', 'height': '32px'} : {'height': '23px','margin-top':'-13px'}" />
                            <img class="wheelchair" *ngIf="pr?.room?.wheelchairaccessibility == 'false'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                              [ngStyle]="darkMode ? {'margin' : '-18px 0px -5px 0px', 'height': '32px'} : {'height': '23px','margin-top':'-13px'}" />
                          </span>
                        </div>
                      </div>
                      <div class="" [ngClass]="darkMode ? 'dabba' : 'dabba2'" style="margin-top: 5px;">
                        <div class="amine-color2" *ngIf="pr.room.pets != ''">
                          <span class="the-span">
                            <img class="pets" *ngIf="pr?.room?.pets == 'true'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                              [ngStyle]="darkMode ? {'margin' : '-19px 0px -5px 0px', 'height': '35px'} : {'height': '18px','margin-top':'-11px'}">
                            <img class="pets" *ngIf="pr?.room?.pets == 'false'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                              [ngStyle]="darkMode ? {'margin' : '-19px 0px -5px 0px', 'height': '35px'} : {'height': '18px','margin-top':'-11px'}">
                          </span>
                        </div>
                        <div class="amine-color2" *ngIf="pr.room.smoking != ''">
                          <span class="the-span">
                            <img class="smoke" *ngIf="pr?.room?.smoking == 'true'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                              [ngStyle]="darkMode ? {'margin' : '-20px 0px -5px 0px', 'height': '33px'} : {'height': '18px','margin-top':'-12px'}">
                            <img class="smoke" *ngIf="pr?.room?.smoking == 'false'"
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                              [ngStyle]="darkMode ? {'margin' : '-18px 0px -5px 0px', 'height': '30px'} : {'height': '18px','margin-top':'-12px'}">
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 text-center ovi-tt" style="padding-left: 3px !important;width: 23.2%;">
                <div class="neo">
                  <div *ngFor="let al of pr.allocation; let ai = index;">
                    <div class="col-md-12 neo-row" style="padding: 3px 15px !important;">
                      <div class="col-md-4 text-left high9">{{al.allocation_type}}</div>
                      <div class="col-md-4 text-center rate9"> {{al.start_point}} > {{al.end_point}}</div>
                      <div class="col-md-4 text-right per9">{{ (al.rate_type == '%' ? al.allocation_rate+'
                        '+al.rate_type : al.rate_type+' '+al.allocation_rate) }}</div>
                    </div>
                  </div>
                  <!-- If Alllocation Not set Show 0 -->
                  <div *ngIf="pr.allocation.length == 0">
                    <div class="col-md-12 neo-row" style="padding: 3px 15px !important;">
                      <div class="col-md-4 text-left high9">High</div>
                      <div class="col-md-4 text-center rate9"> 0 > 0</div>
                      <div class="col-md-4 text-right per9">0</div>
                    </div>
                    <div class="col-md-12 neo-row" style="padding: 3px 15px !important;">
                      <div class="col-md-4 text-left high9">Medium</div>
                      <div class="col-md-4 text-center rate9"> 0 > 0</div>
                      <div class="col-md-4 text-right per9">0</div>
                    </div>
                    <div class="col-md-12 neo-row" style="padding: 3px 15px !important;">
                      <div class="col-md-4 text-left high9">Low</div>
                      <div class="col-md-4 text-center rate9"> 0 > 0</div>
                      <div class="col-md-4 text-right per9">0</div>
                    </div>
                  </div>
                  <!-- If Alllocation Not set Show 0 -->
                </div>
              </div>
              <div class="col-md-4 text-center over-ride-widt" style="    width: 29.8%;padding-right: 0px !important;">
                <div class=" zero-padding null">
                  <div class=" " [ngClass]="darkMode ? 'season-box2' : 'season-box0'"
                    style="width: 65%;margin-left: 0px;overflow: hidden;">
                    <div class="new">
                      <div class="top-cal">
                        <div class="txtt">
                          <div class="weekprice"> {{pr.week_days_rate | currency:current_currency}} </div>
                        </div>
                      </div>
                      <div class="days-1">
                        <span class="days-name" *ngFor="let wd of editArr(pr.week_day)"
                          [ngStyle]="{'opacity': wd.status ? '1' : '0.3' }">{{wd?.name.charAt(0)}}</span>
                      </div>
                      <hr class="myhr2">
                      <div class="days-1">
                        <span class="days-name" *ngFor="let wd of editArr(pr.weekend_day)"
                          [ngStyle]="{'opacity': wd.status ? '1' : '0.3' }">{{wd?.name.charAt(0)}}</span>
                      </div>
                      <div class="weekprice" style="margin-top: 8px"> {{pr.weekend_days_rate |
                        currency:current_currency}} </div>
                    </div>
                  </div>
                  <div class="" [ngClass]="darkMode ? 'season-box2' : 'season-box0'" style="width: 33.5%;">
                    <div class="new" style="height: 120px">
                      <div class="col-md-12 zero-padding my-col " style="padding: 0px !important;text-align: left;">
                        <div class="col-md-12 zero-padding m_b_r " style="text-align: left;">
                          <span class="total "
                            style="font-size: 16px;font-weight: bold;text-transform: capitalize;font-family: robotoblack;color: #00a721">Total</span>
                        </div>
                        <div class="col-md-12 zero-padding m_b_r" style=" text-align: left;margin-top:45%">
                          <span class="price "
                            style="width: 494px;font-size: 27px;color: #FF3A3A;font-weight: 700;">{{pr?.week_day.length
                            * pr?.week_days_rate + pr?.weekend_day.length * pr?.weekend_days_rate}} </span>
                          <span class="tax_cl"> +tax</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <div class="col-md-10 zero-padding">
        <h4 *ngIf="package_data.push_status == 'pushed'">Connected OTAs</h4>
        <h4 *ngIf="package_data.push_status == 'pending'"
          style="color: #ffffff;font-weight: 600;padding: 0px 20px !important;margin: 0px;">
          Selected OTAs &nbsp;
          <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
            <span class="tooltiptext">
              <p style="margin: 0px;">When you push the package, this will change into CONNECTED</p>
            </span>
          </i>
        </h4>
      </div>
    </div>

    <div class="white_box channels w-clearfix fixes" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
      *ngIf="package_data">
      <div class="col-md-12">
        <ng-container *ngIf="package_data != undefined">
          <ng-container *ngFor="let ota of package_data?.season_otas">
            <div class="{{ service.is_preview == true ? 'col-md-3' : 'col-md-4' }} csw"
              *ngIf="ota.channel.type == 'External'">
              <div class="channel_container {{ ota.channel.name }} w-clearfix"
                style="display: flex;align-items: center;" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
                <img src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + ota.channel.image : ota.channel.image}}"
                  alt="" class="tripadvisor ota-icon">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="gradient-bar adj" *ngIf="package_data" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <div class="col-md-10 zero-padding">
        <h4 *ngIf="package_data.push_status == 'pushed'">Connected Channels</h4>
        <h4 *ngIf="package_data.push_status == 'pending'"
          style="color: #ffffff;font-weight: 600;padding: 0px 20px !important;margin: 0px;">
          Selected Channels &nbsp;
          <i class="fas fa-info-circle" *ngIf="package_data.push_status == 'pending'">
            <span class="tooltiptext">
              <p style="margin: 0px;">When you push the package, this will change into CONNECTED</p>
            </span>
          </i>
        </h4>
      </div>
    </div>

    <div class="white_box channels w-clearfix fixes" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '"
      *ngIf="package_data">
      <div class="col-md-12">
        <ng-container *ngIf="package_data != undefined">
          <ng-container *ngFor="let ota of package_data?.season_otas">
            <div class="{{ service.is_preview == true ? 'col-md-3' : 'col-md-4' }} csw"
              *ngIf="ota.channel.type == 'Internal'">
              <div class="channel_container {{ ota.channel.name }} w-clearfix"
                style="display: flex;align-items: center;" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
                <img src="assets/img/svgicons/channels/{{ ota.channel.image }}" alt="" class="tripadvisor ota-icon">
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="row  card" *ngIf="!package_data" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
      <div class="col-md-12">
        <h3 style="margin: 0px;">No Preview Available</h3>
      </div>
    </div>

    <div class="modal-footer isfalse" *ngIf="service.is_preview == false" id="ModalFooter"
      style="right: 10px!important;bottom: -50px;">
      <a class="btn btn-danger btn-lg btn-hov" data-dismiss="modal" aria-label="Close" (click)="onClose()"
        [ngClass]="darkMode ? 'card-dark-btn-g_dark hovoo' : ' card-null '">Cancel</a>
    </div>
    <div class="modal-footer istrue" *ngIf="service.is_preview == true" style="right: 10px!important;bottom: -50px;">
      <a class="btn btn-danger btn-lg btn-hov" data-dismiss="modal" aria-label="Close"
        [ngClass]="darkMode ? 'card-dark-btn-g_dark hovoo' : ' card-null '">Cancel</a>
    </div>
  </div>
</div>