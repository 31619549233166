import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart, ChartData } from 'chart.js';
import { PnlService } from 'src/app/services/pnl.service';
// declare var Chart: any;

@Component({
  selector: 'app-break-even',
  templateUrl: './break-even.component.html',
  styleUrls: ['./break-even.component.css']
})
export class BreakEvenComponent implements OnInit, OnChanges {

  @Input('choosenDate') choosen_date;

  pnlModel;
  private breakEvenChart: Chart;

  constructor(private pnlService: PnlService) {

  }

  ngOnInit() {
    this.updateBreakEvenChart();
  }

  ngOnChanges() {
    this.getPnlData();
    // this.updateBreakEvenChart();
  }

  getPnlData() {
    this.pnlService.getBreakEvenChart(this.choosen_date.startDate, this.choosen_date.endDate).subscribe(d => {
      this.pnlModel = d.data
      if (this.breakEvenChart) {
        this.breakEvenChart.destroy();
      }
      this.updateBreakEvenChart();
    })
  }

  updateBreakEvenChart() {
    function aggregateArray(array1) {
      let val = []

      array1.forEach((x, i) => {
        let previous = 0
        if (i > 0) {
          previous = val[i - 1]
        }

        val.push(previous + array1[i])
      })
      return val;
    }

    function findBreakEvenPoint(array1, array2) {

      let breakEvenIndex = 0
      let breakEvenVal = 0
      let found = false
      let r1 = array1;
      let r2 = array2;
      array1.forEach((x, i) => {
        if (array1[i] > array2[i] && found == false) {
          breakEvenIndex = i
          breakEvenVal = array1[i]
          found = true
        }
      })

      return {
        index: breakEvenIndex,
        value: breakEvenVal,
      }

    }

    function generateBreakEvenData(breakEven) {
      let range = [...Array(breakEven.index).keys()]
      let array = range.map(x => breakEven.value)

      return array;
    }

    const DATA_COUNT = 7;
    const NUMBER_CFG = [
      1,
      2,
      3,
      34,
      5,
      6,
      56,
      67,
      67,
      54,
      53,
      4,
      7,
      567,
      7,
      46,
      78
    ];
    const FIXED_COST = 250;
    let range = [...Array(30).keys()];
    let fixedCost = this.pnlModel.data[0].data;
    let variableCost = this.pnlModel.data[1].data;
    console.log(this.pnlModel.labels, 'break even data');

    let totalCost = aggregateArray(variableCost) // [6,8,10,12]
    totalCost = totalCost.map(x => (x + FIXED_COST))


    let revenue = this.pnlModel.data[2].data;
    revenue = aggregateArray(revenue)

    const labels = this.pnlModel.labels;

    let breakEven = findBreakEvenPoint(revenue, totalCost)
    let breakEvenSet = generateBreakEvenData(breakEven)
    breakEvenSet.push(0)


    const data = {
      labels: labels,
      datasets: [
        {
          label: "Variable Cost",
          data: variableCost,
          borderColor: "#0F0",
          backgroundColor: "rgba(0,255,0,0.5)",
          pointStyle: false,
          pointRadius: 0,
        },
        {
          label: "Fixed Cost",
          data: fixedCost,
          borderColor: "#f00",
          backgroundColor: "rgba(255,0,0,0.5)",
          pointStyle: false,
          pointRadius: 0,
        },
        {
          label: "Total Cost",
          data: totalCost,
          borderColor: "#00f",
          pointStyle: false,
          tension: 0.3,
          pointRadius: 0,
        },
        {
          label: "Revenue",
          data: revenue,
          borderColor: "#e4f",
          tension: 0.1,
          pointStyle: false,
          pointRadius: 0,
          fill: {
            target: 2,
            above: 'rgba(0, 255, 0,0.2)',   // Area will be red above the origin
            below: 'rgba(255, 0, 0,0.3)'    // And blue below the origin
          },
        },
        {
          label: "Break even",
          data: breakEvenSet,
          borderColor: "#acd",
          stepped: true,
          pointStyle: false,
          pointRadius: 0,
          borderDash: [5, 5],
        },
        {
          label: 'Profit',
          backgroundColor: 'rgba(0, 255, 0,0.3)',
          borderColor: 'rgba(0, 255, 0,0.2)',

        },
        {
          label: 'Loss',
          backgroundColor: 'rgba(255, 0, 0,0.3)',
          borderColor: 'rgba(255, 0, 0,0.2)'
        },
      ]
    };

    const config: any = {
      type: "line",
      data: data,
      options: {
        responsive: true,
        scales: {
          x: {
            border: {
              display: false
            },
            grid: {
              display: false,
            }
          },
          y: {
            border: {
              display: false
            },
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          legend: {
            position: "right"
          },

        }
      }
    };

    this.breakEvenChart = new Chart("break-even-chart", config);

  }

}
