import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ApiService } from "../../../services/api.service";
import { HelperService } from "../../../services/helper.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { ManageRatesPackagesService } from '../manage-rates-packages.service';
import { Router, ActivatedRoute } from "@angular/router";
import { GlobaldataService } from '../../../common/globaldata.service';
import * as moment from 'moment';
declare var $: any;
import { ManageRatesPackagesComponent } from '../manage-rates-packages.component';


@Component({
  selector: 'app-setup-season-policy',
  templateUrl: './setup-season-policy.component.html',
  styleUrls: ['./setup-season-policy.component.css']
})
export class SetupSeasonPolicyComponent implements OnInit {
  submitted = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    public helper: HelperService,
    private error_handling: ErrorHandlingService,
    public service: ManageRatesPackagesService,
    private router: Router,
    private Aroute: ActivatedRoute,
    private rp: ManageRatesPackagesComponent
  ) { }

  channels;
  show_channels = [];
  cancelation_channels = [];
  setup_restrictions: FormGroup;
  edit = false;
  cancellaction_type;
  channels2;
  bHeight;
  ratehead;
  // cancellaction_type;
  darkMode: boolean = false;



  ngOnInit() {
    this.get_channels();
    this.modalfooter();
    this.headChange();
    this.checkDarkMode();
    console.log('season Policy page')
    if (GlobaldataService.editSeason != undefined) {
      this.edit = true;
      this.setup_restrictions = this.fb.group({
        min_stay: [GlobaldataService.editSeason.min_stay],
        max_stay: [GlobaldataService.editSeason.max_stay],
        // minimum_age: [GlobaldataService.editSeason.minimum_age],
        // pets: [GlobaldataService.editSeason.pets],
        // pets_extra_amount: [GlobaldataService.editSeason.pets_extra_amount],
        cancellation_days: [GlobaldataService.editSeason.cancellation_days],
        cancellaction_type: [GlobaldataService.editSeason.cancellaction_type],
        cancellation_value: [GlobaldataService.editSeason.cancellation_value],
        cancellation_channels: [GlobaldataService.editSeason.cancellation_channels]
      });
      this.show_channels = GlobaldataService.editSeason.cancel_channels;
      console.log(this.show_channels)
    } else {
      this.setup_restrictions = this.fb.group({
        min_stay: ['', Validators.required],
        max_stay: ['', Validators.required],
        // minimum_age: ['', Validators.required],
        // pets: [null, Validators.required],
        // pets_extra_amount: [''],
        cancellation_days: ['', Validators.required],
        cancellaction_type: [null, Validators.required],
        cancellation_value: [''],
        cancellation_channels: ['', Validators.required]
      });
    }


    $("#SSS").mouseup(function () {
      //$(this).after("<p style='color:green;'>Mouse button released.</p>");
      console.log("mouse doupwn")
      $("#grab-3").css({ "cursor": "grab" })
    });
    $("#SSS").mousedown(function () {
      //$(this).after("<p style='color:purple;'>Mouse button pressed down.</p>");
      console.log("mouse down")
      $("#grab-3").css({ "cursor": "grabbing" })
    });

  }

  onClose() {
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    this.setup_restrictions.reset();
    this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    $('#settings2').modal('hide');
  }

  get f() { return this.setup_restrictions.controls; }

  onSubmit() {
    console.log(this.cancelation_channels);
    //this.submitted = true;
    console.log(this.setup_restrictions.value);


    if (this.edit == false) {

      this.setup_restrictions.patchValue({
        cancellation_channels: this.cancelation_channels.join()
      });

      if (GlobaldataService.savedSeason == undefined) {
        this.helper.alert_error("Please Create Season First!");
        return;
      }

      this.api.post('api/season_package/update/' + GlobaldataService.savedSeason.id, this.setup_restrictions.value, true).subscribe((res: any) => {
        console.log(res);
        this.rp.getRatePlan();
        this.helper.alert_success("Policies has been updated !");
        // this.setup_restrictions.reset()
        //this.submitted = false;
        // this.service.open_tab('link_to_ota_open');
        // this.service.get_packages();
      }, err => this.error_handling.handle_error(err.status));

    } else {

      this.setup_restrictions.patchValue({
        cancellation_channels: this.cancelation_channels.join()
      });

      this.api.post('api/season_package/update/' + GlobaldataService.editSeason.id, this.setup_restrictions.value, true).subscribe((res: any) => {
        console.log(res);
        this.rp.getRatePlan();
        this.helper.alert_success("Policies has been updated !");
      }, err => this.error_handling.handle_error(err.status));

    }


  }

  increment(inp_name) {
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    this.setup_restrictions.get(inp_name).setValue((val) + 1);
  }

  decrement(inp_name) {
    let val = this.setup_restrictions.get(inp_name).value;
    val = val == "" ? 0 : parseInt(val);
    if (val >= 1) {
      this.setup_restrictions.get(inp_name).setValue((val) - 1);
    }
  }

  get_channels() {
    this.api.post('api/channels/rate_channel_index', { property_id: localStorage.getItem('current_property') }, true).subscribe((res: any) => {
      this.channels = res.body.data.filter(function (e) {
        return e.type == 'External' && e.connected == true;
      });
      this.channels2 = res.body.data.filter(function (e) {
        return e.type == 'Internal' && e.connected == true;
      });
      this.get_package();
      this.setEditChe();
      // console.log(this.channels);
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  setEditChe() {
    this.show_channels.forEach((res) => {
      this.channels.forEach(element => {
        if (element.id == res.id) {
          element.is_checked = true;
        }
      });
      this.channels2.forEach(element => {
        if (element.id == res.id) {
          element.is_checked = true;
        }
      });
      this.cancelation_channels.push(res.id);
    });
  }

  setChannel(value, event) {
    if (event.target.checked) {
      this.cancelation_channels.push(value);
    } else {
      this.cancelation_channels = this.cancelation_channels.filter(e => {
        return e !== value;
      });
    }
  }

  get_package() {
    if (this.service.package_creating !== undefined) {
      this.api.get('api/package/promotion_package_show/' + this.service.package_creating, true).subscribe((res: any) => {
        this.setup_restrictions = this.fb.group({
          min_stay: res.body.data.min_stay,
          max_stay: res.body.data.max_stay,
          minimum_age: res.body.data.minimum_age,
          pets: res.body.data.pets,
          pets_extra_amount: res.body.data.pets_extra_amount,
          cancellation_days: res.body.data.cancellation_days,
          cancellaction_type: res.body.data.cancellaction_type,
          cancelation_channels: res.body.data.cancelation_channels,
          cancellation_value: res.body.data.cancellation_value,
        });
        this.show_channels = res.body.data.cancel_channels;
        this.show_channels.forEach((res) => {
          this.channels.forEach(element => {
            if (element.id == res.id) {
              element.is_checked = true;
            }
          });
          this.cancelation_channels.push(res.id);
        });
      }, err => this.error_handling.handle_error(err.status, err.message));
    }
  }

  selectAll($evt) {
    console.log('all select')
    if ($evt.target.checked) {
      this.channels.forEach(element => {
        element.is_checked = true;
        this.cancelation_channels.push(element.id);
      });
    } else {
      this.channels.forEach(element => {
        element.is_checked = false;
      });
      this.cancelation_channels = [];
    }
  }

  selectAll2($evt) {
    console.log('all select')
    if ($evt.target.checked) {
      this.channels2.forEach(element => {
        element.is_checked = true;
        this.cancelation_channels.push(element.id);
      });
    } else {
      this.channels2.forEach(element => {
        element.is_checked = false;
      });
      this.cancelation_channels = [];
    }
  }


  modalfooter() {

    setTimeout(() => {
      let f = document.getElementById("ModalFooter")
      let h = document.getElementById("SS");
      let P = document.getElementById("SSS");

      this.bHeight = h.offsetHeight;

      if (this.bHeight > 700) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        P.classList.remove("paddR5");
        P.classList.add("paddR2");
      }
      else if (this.bHeight < 700) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        P.classList.add("paddR5");
        P.classList.remove("paddR2");
      }
    }, 100)

  }

  headChange() {
    if (GlobaldataService.editSeason == undefined) {
      this.ratehead = 'Create Rate Plan'
    } else if (GlobaldataService.editSeason != undefined) {
      this.ratehead = 'Edit Rate Plan'
    }
  }

  imgr(name) {
    let a = name.split('.');
    return a[0];
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
      console.log('dark Mode Enabled')
    }
  }

}
