import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CreateShopResponse } from '../common/pnl/fnb/createShopModel';
import { Shops } from '../common/fnb/createShop';
import { ManageShopResponse } from '../common/pnl/fnb/manageShopModel';
import { Subject } from 'rxjs';
import { SetupShops } from '../common/fnb/SetupShopModel';
import { SetupShopResponse } from '../common/fnb/SetupShopResponse';
import { ProductItem } from '../common/fnb/ProductItemModel';
import { CategoriesAndItemsResponse } from '../common/fnb/CategoriesAndItemsModel';
import { MenuProductResponse } from '../common/fnb/MenuProductModel';
import { CreateMenuModel, MenuDealModel } from '../components/back-office/create-menu/CreateMenuModel';
import { CreateMenuDealItemResponse } from '../common/fnb/createMenuDealItemModel';
import { MenuDealItemResponse } from '../common/fnb/MenuDealItem';

@Injectable({
  providedIn: 'root'
})
export class FnbService {

  private CreateShopEndpoint: string = '/api/fnb/add_update_shop';
  private GetCreateShopNamesEndpoint: string = '/api/fnb/get_shop_names/';
  private CategoryAndItemsEndpoint: string = '/api/fnb/add_update_category_and_items';
  private GetCategoryAndItemsEndpoint: string = '/api/fnb/get_categories_and_items/';
  private MenuProductEndpoint: string = '/api/fnb/add_update_menu_product';
  private GetMenuCategoriesAndItemsEndpoint: string = '/api/fnb/get_categories_and_items/';
  private GetMenuProductItemsEndpoint: string = '/api/fnb/get_menu_products/';
  private GetUnDeleteCategoryEndpoint: string = '/api/fnb/delete_category/';
  private MenuDealEndpoint: string = '/api/fnb/add_update_menu_deal';
  private GetMenuDealItemsEndpoint: string = '/api/fnb/get_deals/';
  private propertyId: string;

  constructor(private api: ApiService, private http: HttpClient) {
    this.propertyId = localStorage.getItem("current_property");
  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);
    return header

  }

  createShopReq(shops) {
    return this.http.post<CreateShopResponse>(this.api.base_url + this.CreateShopEndpoint, shops, {
      headers: this.prepareAuthHeaders()
    })
  }

  getcreateShopNames() {
    return this.http.get<ManageShopResponse>(this.api.base_url + this.GetCreateShopNamesEndpoint + this.propertyId, {
      headers: this.prepareAuthHeaders()
    })
  }

  createCategoryAndItemsReq(setupShop: SetupShops[]) {
    return this.http.post(this.api.base_url + this.CategoryAndItemsEndpoint, setupShop, {
      headers: this.prepareAuthHeaders()
    })
  }

  getCategoryAndItems(shopId: number) {
    return this.http.get<SetupShopResponse>(this.api.base_url + this.GetCategoryAndItemsEndpoint + shopId, {
      headers: this.prepareAuthHeaders()
    })
  }

  createMenuProductItemReq(productItem) {
    return this.http.post(this.api.base_url + this.MenuProductEndpoint, productItem, {
      headers: this.prepareAuthHeaders()
    })
  }

  getMenuCategoriesAndItems(shopId: number) {
    return this.http.get<CategoriesAndItemsResponse>(this.api.base_url + this.GetMenuCategoriesAndItemsEndpoint + shopId, {
      headers: this.prepareAuthHeaders()
    })
  }

  getMenuProductItems(shopId: number) {
    return this.http.get<MenuProductResponse>(this.api.base_url + this.GetMenuProductItemsEndpoint + shopId, {
      headers: this.prepareAuthHeaders()
    })
  }

  getUnDeletedCategoriesAndItems(categoryId: number) {
    return this.http.get(this.api.base_url + this.GetUnDeleteCategoryEndpoint + categoryId, {
      headers: this.prepareAuthHeaders()
    })
  }

  createMenuDealItemReq(dealItem) {
    return this.http.post<CreateMenuDealItemResponse>(this.api.base_url + this.MenuDealEndpoint, dealItem, {
      headers: this.prepareAuthHeaders()
    })
  }

  getDealsItem(shopId: number) {
    return this.http.get<MenuDealItemResponse>(this.api.base_url + this.GetMenuDealItemsEndpoint + shopId, {
      headers: this.prepareAuthHeaders()
    })
  }

  getFormData(formDataValues: { [s: string]: unknown; } | ArrayLike<unknown> | CreateMenuModel | MenuDealModel, editMode: boolean): FormData {
    const formData = new FormData();

    Object.entries(formDataValues).reduce((formData, [key, value]) => {
      if (editMode) {
        formData.set(key, value);
      } else {
        formData.append(key, value);
        console.log('service formdata');
      }
      return formData;
    }, formData);

    return formData;
  }
}
