import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';
declare var $: any;
import { Observable, Subject, Subscription } from 'rxjs';
import { AddOnsExtras, ExtrasResponse } from 'src/app/common/Inventory/Reservation/ExtrasAddons';
import { Promotion } from 'src/app/common/Inventory/Reservation/Promotion';
import { RoomType } from 'src/app/common/Inventory/Reservation/RoomType';
import { ReservationService } from 'src/app/services/reservation.service';
import { ReservationOptions } from './shared/reservation-options';
import { RoomDatePrice } from 'src/app/common/Inventory/Reservation/RoomDatePrice';
import { RoomNumber } from 'src/app/common/Inventory/Reservation/RoomNumber';
import { ActivatedRoute } from '@angular/router';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ActiveChannel } from 'src/app/common/Channel/active-channel';
import { ToastrService } from 'ngx-toastr';
import { ExtraPersonInfo } from 'src/app/common/Inventory/ExtraPersonInfo';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { BookingExtras } from 'src/app/common/Inventory/Reservation/BookingExtras';
import { BulkReservationSelectionRange } from 'src/app/common/Inventory/Reservation/BulkReservationSelectionRange';
import { data } from 'jquery';
import { BookingItemModel } from 'src/app/common/Inventory/Reservation/BookingItemModel';
import { TimePeriod } from 'ngx-daterangepicker-material/daterangepicker.component';

@Component({
  selector: 'app-reservation-modal',
  templateUrl: './reservation-modal.component.html',
  styleUrls: ['./reservation-modal.component.css']
})
export class ReservationModalComponent implements OnInit {

  darkMode: boolean = false;
  randomColors = [];

  // --- Reservation ---
  _reservationService: ReservationService;
  sperateRoomDates = false;

  // --- other variables ---
  info: any;

  isToastOpen = false;
  bookingStatuses = []; // Make these constants
  paymentStatuses = []; // Make these constants
  guestStatuses = []; // Make these constants
  currencySymbol = '';
  // --- other variables ---

  // --- Observables ---
  onDateChange$: Subject<any> = new Subject();
  onRoomTypesUpdated$: Subject<{ roomTypes: RoomType[], bookingItemIndex: number }> = new Subject();
  onPromotionUpdated$: Subject<{ promoList: Promotion[], bookingItemIndex: number }> = new Subject();
  onDestroy$: Subject<any> = new Subject();
  // --- Observables ---

  // --- Reservation ---
  reservationOptions: ReservationOptions = this.initializeReservationOptions();

  showDayNight: boolean = true;
  reservationForm: FormGroup;
  currentDate: string;
  currentStaticDate: string;
  reservationUpdate: boolean;
  totalAddOnsAmount = 0;
  isSelected = true;
  activeChannels = []
  editForm: FormGroup; // Temporary holding form for edit
  isSubmittedOnce = false;
  isOpenStay: any;
  isOpenPayment: any;
  general: any;
  itemSelections: any;
  bookingExtras: any[] = [];
  property: any;
  // checkin and checkout date 
  checkInDate: string | undefined;
  checkOutDate: string | undefined;
  // waether update array
  weather_update = [];
  // colors array
  WHcolors = [
    "#F98282",
    "#49FC6D",
    "#FF6DBD",
    "#7577FF",
    "#F5A65B",
    "#21E1AD",
    "#A7C957",
    "#D87EFF",
    "#D08C60",
    "#09B562",
    "#D85B5B",
    "#7B89FF",
    "#FF9A36",
    "#C4B534",
    "#BB8565",
    "#FD88FF",
    "#FFA985",
    "#33FFA0",
    "#A6C36F",
    "#F9C74F",
    "#E28D99",
    "#6387FF",
    "#9FFF0D",
    "#25A18E",
    "#2D9DFF",
    "#FF658A",
    "#A779BE",
    "#72BDA3",
    "#C2A363",
    "#277DA1",
  ];
  // tooltip text for stays same date apply or not
  tooltipTextForStays: string = "Uncheck the checkbox to apply seperate dates on each room.";
  applyChkOutDate: boolean = true;
  // apply same date of all rooms or not
  seperateDate: boolean = true;
  perDayData = [];
  taxper: string;
  // addons extras
  addOnsModal: boolean[] = [];
  addOnsExtras: AddOnsExtras[] = [];
  addOnsDisplayMap: Map<number, AddOnsExtras[]> = new Map();
  // form disabled
  disabled: boolean = false;
  // update and save reservation for arrival
  @Output() onSaveReservation: EventEmitter<void> = new EventEmitter<void>();
  current_arrival;
  // --- Reservation ---

  bulkReservationSelectionRanges: BulkReservationSelectionRange[] = []
  onRoomTypesPopulated$: Subject<number> = new Subject();

  bulkReservationOptions: {
    isCalendarBulkReservation: boolean,
    onRoomsPopulatedSubscription?: Subscription
  }
  isModified: boolean = false;


  constructor(
    public fb: FormBuilder,
    private injector: Injector,
    private toaster: ToastrService,
    private error_handling: ErrorHandlingService,
    private reservation_service: ReservationService,
  ) {
    this.property = JSON.parse(localStorage.getItem("property"));
    this.taxper = this.property.taxper;

    this.currencySymbol = this.property.currency;
    this.bookingStatuses = this.reservationService.getBookingStatuses();
    this.paymentStatuses = this.reservationService.getPaymentStatuses();
    this.guestStatuses = this.reservationService.getGuestStatuses();
    // const isEdit = this.activatedRoute.snapshot.params.isEdit;
    this.currentDate = moment(new Date()).format('ddd, MM | HH:mm');
    this.currentStaticDate = moment(new Date()).format('DD MMM');

    this.reservation_service.isEditReservation$.subscribe((res: any) => {
      this.reservationOptions.isEdit = true;
      this.disabled = true;
      this.reservationOptions.reservationId = res.reservationId;
      // this.reservationOptions.confirmationId = Number(this.activatedRoute.snapshot.params.confirmationtionId);
      this.onEditReservation();
    })

  }

  private initializeReservationOptions(): ReservationOptions {
    return {
      promotions: [],
      roomTypes: [],
      filteredRoomTypes: new Map<number, RoomType[]>(),
      selectedRoomTypePrice: 0,
      dateRange: { checkin: '', checkout: '' },
      roomDatePriceMap: new Map<string, RoomDatePrice[]>(),
      roomDatePriceTotalMap: new Map<string, number>(),
      roomNumbersMap: new Map<string, RoomNumber[]>(),
      seperateCheckinDates: false,
      selectedChannel: null,
      reservationId: null,
      confirmationId: null,
      isEdit: false,
      checkedInTimeMap: new Map<string, string>(),
      durationOfStay: '',
      roomViews: [],
      bedTypes: [],
      packageSelectionMap: new Map<string, string>()
    };
  }

  get reservationService(): ReservationService {
    if (!this._reservationService) {
      this._reservationService = this.injector.get(ReservationService);
    }
    return this._reservationService;
  }

  ngOnInit() {
    this.checkDarkMode();
    this.getWeather();
    this.getExpenses();

    this.onDateChange$.subscribe(date => {
      this.onDateChanges(date);
    });

    this.onRoomTypesUpdated$.subscribe(data => {

      if (this.reservationOptions.isEdit) {
        // get all booking items
        const items = this.editForm.get('booking_items') as FormArray;

        for (let i = 0; i < items.controls.length; i++) {
          const item = items.at(i) as FormGroup;
          const selectedRoomId = item.get('room_id').value;
          if (!selectedRoomId) { continue; }
          const selectedRoomType = data.roomTypes.filter(rt => rt.room_id == selectedRoomId).pop();
          if (!selectedRoomType) { continue; }
          const selectedRoom = selectedRoomType.room_no.find(rn => rn.id == item.get('room_no_id').value);

          this.reservationOptions.roomNumbersMap.set(i.toString(), selectedRoomType.room_no);
          this.reservationOptions.filteredRoomTypes.set(i, data.roomTypes);
          // So that it doesn't get filtered out
          selectedRoom.isAvailable = true;
        }
      }

      data.roomTypes.forEach(rt => {
        rt.room_no = rt.room_no.filter(rn => rn.isAvailable == true);
      });
      this.reservationOptions.roomTypes = data.roomTypes;

      const bookingItemsLength = this.reservationForm.get('booking_items')['controls'].length;

      if (this.bulkReservationOptions?.isCalendarBulkReservation == true) {
        this.reservationOptions.filteredRoomTypes.set(data.bookingItemIndex, data.roomTypes);
      }
      else {
        Array.from({ length: bookingItemsLength }, (v, i) => i).forEach((i) => {
          this.reservationOptions.filteredRoomTypes.set(i, data.roomTypes);
        });
      }

      this.reservationOptions.roomViews = [... new Set(data.roomTypes.map(x => x.roomview))] as string[];
      this.reservationOptions.bedTypes = [... new Set(data.roomTypes.map(x => x.bedtype))] as string[];

      this.onRoomTypesPopulated$.next(data.bookingItemIndex);
    });

    this.onPromotionUpdated$.pipe(
      map(data => { return { promoList: data.promoList.filter(promo => promo.id !== 0), bookingItemIndex: data.bookingItemIndex } })
    ).subscribe(data => {
      this.reservationOptions.promotions = data.promoList;

      this.reservationOptions.promotions.push({
        id: 0,
        room_id: 0,
        package_name: "None",
        room_type_id: 0,
        date_from: "",
        date_to: "",
        season_rates: 0
      })
      // }

      const currentSelectedValue = this.reservationForm.get('package_id').value;

      if (currentSelectedValue === null) {
        this.reservationForm.get('package_id').setValue(null, { emitEvent: false });
      }
    });

    this.reservationForm = this.reservationService.intializeReservationForm();
    this.reservationService.getActiveChannels().subscribe(
      (activeChannels: ActiveChannel[]) => {
        this.activeChannels = activeChannels;
      });

    if (this.reservationOptions.isEdit) {
      this.onEditReservation();
    }

    this.registerOnReservationFormChanges();

    this.reservationService.bulkReservationSelectionRange$.subscribe((range) => {
      if (range == undefined) {
        this.bulkReservationSelectionRanges = [];
        return;
      }
      this.bulkReservationSelectionRanges.push(range)
    })


    this.reservationService.onReservationPopupOpened$.subscribe((d) => {
      this.handleCalendarBulkReservationPipeline();
    })

    this.reservationService.onResetReservationForm$.subscribe(() => {
      this.bulkReservationOptions?.onRoomsPopulatedSubscription?.unsubscribe();
      this.reservationOptions = this.initializeReservationOptions();
    })
  }

  ngOnDestroy() {
    this.onDestroy$.unsubscribe();
  }

  ngOnChanges() {
  }

  getWeather() {
    var cities: any = "";
    var prop;
    cities =
      null != JSON.parse(localStorage.getItem("property"))
        ? [(prop = JSON.parse(localStorage.getItem("property"))).city]
        : ["Toronto"];
    cities.forEach((city) => {
      let req =
        "https://api.openweathermap.org/data/2.5/weather?q=" +
        city +
        "&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1";
      let forcast =
        "https://api.openweathermap.org/data/2.5/forecast/daily?q=" +
        city +
        "&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4";

      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split(".")[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString("en-us", { weekday: "short" }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: [],
        };

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach((element) => {
            var day = new Date(element.dt * 1000).toLocaleTimeString("en-us", {
              weekday: "short",
            });
            var icon = element.weather[0].icon.split(".")[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });

  }


  guestInfo(event) {

    const isChecked = event.target.checked;

    if (isChecked) {
      const guestName = this.reservationForm.value.booking_items[0];
      if (guestName != null) {
        this.reservationForm.patchValue({
          guest_firstname: guestName.guest_first_name,
          guest_lastname: guestName.guest_last_name,
        });
      }
    } else {
      this.reservationForm.patchValue({
        guest_firstname: '',
        guest_lastname: '',
      });
    }
  }

  BookedByInfo(event) {
    const isChecked = event.target.checked;

    const bookingItems = this.reservationForm.get('booking_items') as FormArray;
    const guestName = this.reservationForm.value;

    if (isChecked && guestName) {
      bookingItems.at(0).patchValue({ guest_first_name: guestName.guest_firstname, guest_last_name: guestName.guest_lastname });
    } else {
      bookingItems.at(0).patchValue({ guest_first_name: '', guest_last_name: '' });
    }
  }

  getAmmenityValue(index: number, ammenity: 'pets' | 'accessibility' | 'smoking'): boolean {
    //  debugger
    const bookingItem = (this.reservationForm.get('booking_items') as FormArray).at(index);
    if (ammenity == 'pets') {
      return bookingItem.get('isPetAllowed').value;
    }

    if (ammenity == 'accessibility') {
      return bookingItem.get('isWheelChairAccessible').value;
    }

    if (ammenity == 'smoking') {
      return bookingItem.get('isSmokingAllowed').value;
    }
  }

  setAmmenityValue(index: number, ammenity: 'pets' | 'accessibility' | 'smoking') {

    const bookingItem = (this.reservationForm.get('booking_items') as FormArray).at(index);

    if (ammenity == 'pets') {
      bookingItem.patchValue({ isPetAllowed: !bookingItem.get('isPetAllowed').value });
    }

    if (ammenity == 'accessibility') {
      bookingItem.patchValue({ isWheelChairAccessible: !bookingItem.get('isWheelChairAccessible').value });
    }

    if (ammenity == 'smoking') {
      bookingItem.patchValue({ isSmokingAllowed: !bookingItem.get('isSmokingAllowed').value });
    }
  }

  // --- Life cycle functions ---
  private onEditReservation() {
    this.reservationService.prepareEditForm$(this.reservationOptions.reservationId).subscribe((editForm) => {
      this.editForm = editForm;
      const checkin = editForm.value.date_arrival;
      const checkout = editForm.value.date_departure;

      this.reservationOptions.dateRange = {
        checkin: checkin,
        checkout: checkout,
      };

      this.perDayData = [];

      this.getRoomTypesFromServer(this.reservationOptions.dateRange);
      this.getRoomPromotionsFromServer(this.reservationOptions.dateRange);

      this.reservationForm.patchValue(editForm.value);

      const outgoingBookingItems = this.reservationForm.get('booking_items') as FormArray;
      outgoingBookingItems.clear();
      const incomingBookingItems = editForm.get('booking_items') as FormArray;

      for (let i = 0; i < incomingBookingItems.length; i++) {

        const bookingItem = incomingBookingItems.at(i) as FormGroup;
        outgoingBookingItems.push(this.reservationService.createBookingItem(bookingItem.value));
        const incomingExtraPersons = bookingItem.get('extra_persons') as FormArray;
        const outgoingExtraPersons = outgoingBookingItems.at(i).get('extra_persons') as FormArray;
        outgoingExtraPersons.clear();
        for (let j = 0; j < incomingExtraPersons.length; j++) {
          const extraPerson = incomingExtraPersons.at(j) as FormGroup;
          if (extraPerson) {
            const extraPersonModel: ExtraPersonInfo = {
              name: extraPerson.value.name,
              id_type: extraPerson.value.id_type,
              id_number: extraPerson.value.id_number,
            };
            outgoingExtraPersons.push(this.reservationService.createExtraPerson(extraPersonModel));
          }
        }

        // fill booking extras
        const incomingBookingExtras = bookingItem.get('booking_extras') as FormArray;
        const outgoingBookingExtras = outgoingBookingItems.at(i).get('booking_extras') as FormArray;
        outgoingBookingExtras.clear();
        for (let j = 0; j < incomingBookingExtras.length; j++) {
          const bookingExtras = incomingBookingExtras.at(j) as FormGroup;
          if (bookingExtras) {
            const bookingExtraModel: BookingExtras = {
              property_id: bookingExtras.value.property_id,
              extras_item_id: bookingExtras.value.extras_item_id,
              item_count: bookingExtras.value.item_count,
            };
            outgoingBookingExtras.push(this.reservationService.createBookingExtras(bookingExtraModel));
          }
        }

        this.registerOnReservationFormChanges();
        this.addOnsDisplayMap.set(i, this.addOnsExtras);

      }

      for (let i = 0; i < outgoingBookingItems.controls.length; i++) {
        const item = outgoingBookingItems.at(i) as FormGroup;
        const roomId = item.get('room_id').value;
        this.OnRoomTypeOrPromotionChange(roomId, i, item);
        // this.updateRoomFilters(roomId, item, this.reservationOptions.roomTypes);
      }

      this.updateDateUI({ checkin: checkin, checkout: checkout });
    });

  }

  updateRoomFilters(roomId, bookingItem: FormGroup, roomTypes: RoomType[]) {
    if (roomTypes) {
      const selectedRoom = roomTypes.find(x => x.room_id == roomId);
      if (!selectedRoom) {
        throw new Error('Could not find booked room in given room types');
      }

      bookingItem.patchValue({ bedType: selectedRoom.bedtype, roomView: selectedRoom.roomview });
    }
  }

  registerOnReservationFormChanges() {
    // Registering On change events for the Booking Items (Each Room Reservation)

    const items = this.reservationForm.get('booking_items') as FormArray;

    for (let i = 0; i < items.controls.length; i++) {
      const item = items.at(i) as FormGroup;
      this.registerOnBookingItem(item, i);
    }

    this.reservationForm.get('channel_id').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(channelId => {
      this.reservationOptions.selectedChannel = this.activeChannels.filter(x => x.id == channelId)[0];
    });

    this.reservationForm.get('prices').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(prices => {
      this.updateTotals();
    });

    this.reservationForm.get('discount').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(discount => {
      this.updateTotals('discountPercentage');
    });

    this.reservationForm.get('discount_amount').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(discountAmount => {
      // this.updateTotals('discountAmount');
    });

    this.reservationForm.get('cashDeposit').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(cashDeposit => {
      this.updateTotals();
    }
    );
    //remove this - only for debugging
    this.reservationForm.get('date_arrival').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(date => {
      console.log(date, "date arrival")
      //this.onDateChange$.next({ checkin: date, checkout: this.reservationForm.get('date_departure').value });
    });
  }

  private registerOnBookingItem(item: FormGroup, i: number) {
    item.get('room_id').valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((roomId) => {
        if (this.reservationOptions.roomTypes) {
          const roomNo = this.reservationOptions.roomTypes.find(x => x.room_id == roomId)?.room_no;
          this.reservationOptions.roomNumbersMap.set(i.toString(), roomNo);
        }
        this.OnRoomTypeOrPromotionChange(roomId, i, item);
      });


    // Triggered When Guest Status Changes
    item.get('guest_status').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(status => {
      item.patchValue({ status, guest_status: status }, { emitEvent: false });
      // debugger;
      // const displayVal = status == 'Checked In' ? 'inherit' : 'none';
      // document.getElementById('checkin-fields-' + i).style.display = displayVal;

      const checkinTime = status == 'Checked In' ? this.currentDate : false;
      item.patchValue({ check_in_time: checkinTime });
    });

    item.get('roomView').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(roomView => {
      const bedType = item.get('bedType').value;
      // debugger;
      if (bedType && bedType.length > 0) {
        this.reservationOptions.filteredRoomTypes.set(i, this.reservationOptions.roomTypes.filter(x => x.bedtype == bedType));
      }
      this.reservationOptions.filteredRoomTypes.set(i, this.reservationOptions.filteredRoomTypes.get(i).filter(x => x.roomview == roomView));

      if (roomView == 0) {
        item.patchValue({ roomView: null }, { emitEvent: false });
      }
    });
    item.get('package_id').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(packageId => {
      this.reservationOptions.packageSelectionMap.set(i.toString(), packageId);
      if (packageId !== null && packageId !== undefined) {
        this.OnRoomTypeOrPromotionChange(item.get('room_id').value, i, item);
      }

      if (packageId == 0) {
        item.patchValue({ package_id: null }, { emitEvent: false });
      }

    });

    item.get('bedType').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(bedType => {
      const roomView = item.get('roomView').value;
      if (roomView && roomView.length > 0) {
        this.reservationOptions.filteredRoomTypes.set(i, this.reservationOptions.roomTypes.filter(x => x.roomview == roomView));
      }
      this.reservationOptions.filteredRoomTypes.set(i, this.reservationOptions.filteredRoomTypes.get(i).filter(x => x.bedtype == bedType));

      if (bedType == 0) {
        item.patchValue({ bedType: null }, { emitEvent: false });
      }
    });

    item.get('isPetAllowed').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.filterRoomsByAmmenities(item, i);
    });

    item.get('isSmokingAllowed').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.filterRoomsByAmmenities(item, i);
    });

    item.get('isWheelChairAccessible').valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(x => {
      this.filterRoomsByAmmenities(item, i);
    });

    // adding addons to display for each booking item
    this.addOnsDisplayMap.set(i, this.addOnsExtras);

  }

  private filterRoomsByAmmenities(item: FormGroup, index: number) {
    const isPetAllowed = item.get('isPetAllowed').value;
    const isSmokingAllowed = item.get('isSmokingAllowed').value;
    const isWheelChairAccessible = item.get('isWheelChairAccessible').value;

    this.reservationOptions.filteredRoomTypes.set(index, this.reservationOptions.roomTypes.filter(x => x.pets == isPetAllowed && x.wheelchairaccessibility == isWheelChairAccessible && x.smoking == isSmokingAllowed));
  }

  private onDateChanges(date: any, bookingItemIndex: number = null) {
    if (this.reservationOptions.seperateCheckinDates) {
      this.getRoomTypesFromServer(date, bookingItemIndex);
      this.getRoomPromotionsFromServer(date, bookingItemIndex);

      this.reservationOptions.dateRange = { ...date };
      this.reservationForm.patchValue({ date_arrival: date.checkin, date_departure: date.checkout });

      const bi = this.reservationForm.get('booking_items')['controls'][bookingItemIndex] as FormGroup;
      bi.patchValue({ check_in: date.checkin, check_out: date.checkout });

      this.updateDateUI({ ...date });
    }
    else {
      this.getRoomTypesFromServer(date);
      this.getRoomPromotionsFromServer(date);
      this.reservationOptions.dateRange = date;
      this.reservationForm.patchValue({ date_arrival: date.checkin, date_departure: date.checkout });
      // add check_in and check_out dates to all the bookingitems using pathValue
      const items = this.reservationForm.get('booking_items') as FormArray;
      for (let i = 0; i < items.controls.length; i++) {
        const item = items.at(i) as FormGroup;
        // debugger;
        item.patchValue({ check_in: date.checkin, check_out: date.checkout });
      }
      this.updateDateUI(date);
    }

  }

  private updateDateUI(date: any) {
    const checkin = moment(date.checkin).format('DD MMM');
    this.reservationOptions.dateRange.checkin = checkin;

    const checkout = moment(date.checkout).format('DD MMM');
    this.reservationOptions.dateRange.checkout = checkout;
    // using moment find the duration of stay

    const duration = moment.duration(moment(date.checkout).diff(moment(date.checkin)));

    this.reservationOptions.durationOfStay = duration.asDays() == 1 ? duration.asDays() + ' Night' : duration.asDays() + ' Nights';
    if (duration.asDays() == 0) {
      this.reservationOptions.durationOfStay = 1 + ' Day';
      this.showDayNight = false;
    } else {
      this.showDayNight = true;
    }
  }

  formatDateForPicker(date: string) {
    if (!date) {
      return null;
    }
    return moment(date).format('DD MMM YYYY');
  }

  // --- Reservation Related functions ---

  onCalendarDateChange($event: any, isCheckIn: boolean) {

    const date_arrival = this.reservationForm.get('date_arrival').value;
    const date_departure = this.reservationForm.get('date_departure').value;

    const selectedDate = $event.endDate ? $event.endDate.format('YYYY-MM-DD') : undefined;

    if (isCheckIn === true) {
      this.checkInDate = selectedDate
    } else {
      this.checkOutDate = selectedDate
    };

    this.checkInDate == undefined ? this.checkInDate = date_arrival : this.checkInDate = this.checkInDate;
    this.checkOutDate == undefined ? this.checkOutDate = date_departure : this.checkOutDate = this.checkOutDate;

    // if checkin and checkout date exist
    if (this.checkInDate && this.checkOutDate) {
      this.onDateChange$.next({ checkin: this.checkInDate, checkout: this.checkOutDate });
    }
  }

  onBookingItemDateChange($event: TimePeriod, bookingItemIndex: number, isCheckIn: boolean) {
    const selectedDate = $event.endDate ? $event.endDate.format('YYYY-MM-DD') : undefined;
    const bookingItemFormGroup = (this.reservationForm.get('booking_items') as FormArray).at(bookingItemIndex) as FormGroup;

    if (isCheckIn === true) {
      bookingItemFormGroup.patchValue({ check_in: selectedDate })
    } else {
      bookingItemFormGroup.patchValue({ check_out: selectedDate })
    };

    // if checkin and checkout date exist
    if (bookingItemFormGroup.get('check_in').value !== undefined && bookingItemFormGroup.get('check_out').value !== undefined) {
      const dates = {
        checkin: bookingItemFormGroup.get('check_in').value,
        checkout: bookingItemFormGroup.get('check_out').value
      };
      this.onDateChanges(dates, bookingItemIndex);
    }
  }

  getRoomTypesFromServer(dates, bookingItemIndex = null) {
    this.reservationService.getAvailableRooms$(dates.checkin, dates.checkout).subscribe(res => {
      //console.log('roomtypesfromserver',bookingItemIndex,res.data)
      this.onRoomTypesUpdated$.next({ roomTypes: res.data as RoomType[], bookingItemIndex });
    });

  }

  getRoomPromotionsFromServer(dates, bookingItemIndex = null) {
    this.reservationService.getAvailablePromotions$(dates.checkin, dates.checkout).subscribe(res => {
      this.onPromotionUpdated$.next({ promoList: res.data as Promotion[], bookingItemIndex });
    });
  }

  onSubmitReservation(resForm: FormGroup) {
    // debugger
    this.isSubmittedOnce = true;

    const total = Number(resForm.get('total').value);

    resForm.patchValue({ total: total });

    const bookingItemsArray = resForm.get('booking_items') as FormArray;

    for (let i = 0; i < bookingItemsArray.length; i++) {
      const bookingItem = bookingItemsArray.at(i) as FormGroup;
      const firstName = bookingItem.get('guest_first_name').value;
      const lastName = bookingItem.get('guest_last_name').value;
      // concat the first and last name in guest_name
      const fullName = `${firstName},${lastName}`;
      bookingItem.patchValue({ guest_name: fullName });
      if (resForm.get('sperateRoomDates').value == false) {
        resForm.value.booking_items[i].check_in = resForm.value.date_arrival
        resForm.value.booking_items[i].check_out = resForm.value.date_departure
      }
    }

    if (resForm.status == 'INVALID') {
      this.toaster.error('Please fill all the fields correctly', 'Invalid Form');
      return;
    }

    let result: Observable<any>;

    if (this.reservationOptions.isEdit) {
      if (resForm.dirty) { resForm.patchValue({ status: 'modified' }); }
      resForm.value.booking_id = this.reservationOptions.reservationId;
      console.log(resForm.value, "update for reservation");
      result = this.reservationService.updateReservation$(resForm.value);
    } else {
      console.log(resForm.value, "resForm.value");                                  

      result = this.reservationService.createReservation$(resForm.value);
    }

    result.subscribe(
      (res) => {
        this.isSubmittedOnce = false
        if (this.reservationOptions.isEdit) {
          this.toaster.success('Reservation updated successfully', 'Success');
          this.onSaveReservation.emit();

        } else {
          this.toaster.success('Reservation created successfully', 'Success');
          setTimeout(() => {
            this.resetForm(this.reservationForm);
            $("#reservation-popup").modal("hide");
          }, 1000);

          this.reservationUpdate = true;

          setTimeout(() => {
            this.reservationUpdate = false;
          }, 6000);
        }
      },
      (error) => {
        console.log("error =>", error);
        this.toaster.error('Failed to create reservation', 'Error');
      }
    );
  }

  updateTotals(whenChanged: 'discountPercentage' | 'discountAmount' | null = null) {
    let allRoomPrices: number[] = this.reservationForm.get('prices').value.split(',').map(Number);
    const extraPersonFee = Number(this.reservationForm.get('extra_person_fee').value);
    const cashDeposit = Number(this.reservationForm.get('cashDeposit').value);

    if (!allRoomPrices) {
      allRoomPrices = [];
    }
    let subtotal = allRoomPrices.reduce((a, b) => a + b, 0);
    subtotal = subtotal + extraPersonFee + this.calculateAddOnsPrice();

    this.reservationForm.patchValue({ subtotal: Number(subtotal) });

    let discountPercentage = 0;
    let discountAmount: any = 0;

    switch (whenChanged) {
      case 'discountPercentage':
      case null:
        discountPercentage = Number(this.reservationForm.get('discount').value);
        if (discountPercentage > 0) {
          if (discountPercentage > 100) {
            this.reservationForm.patchValue({ discount: 100 }, { emitEvent: false });
            discountPercentage = 100;
          }
          discountAmount = ((subtotal) * discountPercentage) / 100;
          discountAmount = discountAmount.toFixed(2);
        } else if (discountPercentage < 0) {
          this.toaster.error('Discount percentage cannot be less than 0');
          return;
        } else {
          this.reservationForm.patchValue({ discount_amount: 0 }, { emitEvent: false });
        }
        break;
      case 'discountAmount':
        discountAmount = Number(this.reservationForm.get('discount_amount').value);
        if (discountAmount => 0 && discountAmount < subtotal) {
          discountPercentage = (discountAmount * 100) / (subtotal);
          discountPercentage = parseFloat(discountPercentage.toFixed(2));
        }
        else {
          this.toaster.error('Discount amount cannot be greater than subtotal');
          return;
        }
        break;
    }

    let formula;
    this.property.taxInclusive == "false"
      ? (formula = "0." + this.property.taxper)
      : (formula = 0);
    const propertyTaxPrice = (subtotal * formula).toFixed(2);
    const total = ((subtotal + Number(propertyTaxPrice)) - discountAmount - cashDeposit);

    this.reservationForm.patchValue({ total, discount: discountPercentage, discount_amount: discountAmount, Tax: propertyTaxPrice }, { emitEvent: false, onlySelf: true });
  }

  OnRoomTypeOrPromotionChange(roomId: number, bookItemIndex: number, bookingItem: FormGroup) {
    const seletedRoom = this.reservationOptions.roomTypes.find(x => x.room_id === roomId);
    const validateLimit = (maxValue: number) => (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value > maxValue) {
        return { limitExceeds: true };
      }
      return null;
    };

    const adultsControl = bookingItem.get('no_of_adults');
    const childrenControl = bookingItem.get('no_of_childs');
    const infantsControl = bookingItem.get('no_of_infants');

    if (seletedRoom) {

      adultsControl.setValidators([Validators.required, validateLimit(seletedRoom.adults)]);
      childrenControl.setValidators([Validators.required, validateLimit(seletedRoom.childrens)]);
      infantsControl.setValidators([Validators.required, validateLimit(seletedRoom.infants)]);

      // Update the form controls' validity
      adultsControl.updateValueAndValidity();
      childrenControl.updateValueAndValidity();
      infantsControl.updateValueAndValidity();

      // debugger

      bookingItem.patchValue({
        isPetAllowed: seletedRoom.pets,
        isWheelChairAccessible: seletedRoom.wheelchairaccessibility,
        isSmokingAllowed: seletedRoom.smoking,
      });
    }

    console.log(bookingItem, "bookingItem");

    const checkin = bookingItem.get('check_in').value;
    const checkout = bookingItem.get('check_out').value;

    if (checkin != null || checkout != null) {
      this.reservationService.getRatesByCheckinCheckout$(checkin, checkout, roomId, bookingItem.get('package_id').value)
        .subscribe((data: any) => {

          // Populating room date price map
          const prices: RoomDatePrice[] = data.data as RoomDatePrice[];
          this.reservationOptions.roomDatePriceMap.set(bookItemIndex.toString(), prices);

          // Populating room date price total map
          const total = prices.map(x => x.price).reduce((a, b) => a + b, 0);
          this.reservationOptions.roomDatePriceTotalMap.set(bookItemIndex.toString(), total);

          // Updating pricesJson
          const priceAmounts = JSON.stringify(prices.map(x => x.price)).replace(/],/g, ',').replace(/\[/g, '').replace(/]/g, '');
          const pricesJson = {
            roomName: prices[0].name,
            display_name: prices[0].display_name,
            roomIndex: bookItemIndex,
            rates: prices,
          };

          this.perDayData.unshift(pricesJson);
          this.perDayData = Object.values(
            this.perDayData.reduce((uniqueMap, item) => {
              if (!uniqueMap[item.roomIndex]) {
                uniqueMap[item.roomIndex] = item;
              }
              return uniqueMap;
            }, {})
          );

          if (this.perDayData) {
            document.getElementById("sideTrayReservation").style.right = "-244px";
          }

          bookingItem.patchValue({ pricesjson: JSON.stringify(pricesJson), prices: priceAmounts });

          // updating all prices
          const totalPricesArray = [];
          Array.from(this.reservationOptions.roomDatePriceMap.values()).forEach(x => {
            totalPricesArray.push(x.map(y => y.price));
            this.reservationOptions.roomDatePriceTotalMap.set(bookItemIndex.toString(), total);
          });

          const pricesStr = JSON.stringify(totalPricesArray).replace(/],/g, ',').replace(/\[/g, '').replace(/]/g, '');
          this.reservationForm.patchValue({ prices: pricesStr });

          if (prices[0].barrate != undefined) {
            var barrate = 0;
            var price = 0;
            for (let k = 0; k < prices.length; k++) {
              barrate += prices[k].barrate;
              price += prices[k].price;
            }

            const discount = (((barrate - price) * 100) / price
            ).toFixed(2);

            this.reservationForm.patchValue({
              discount: discount,
            });
          }
        }, error => {
          console.log(error);
        })
    }

  }

  addNewBookingItem(): number {
    const items = this.reservationForm.get('booking_items') as FormArray;
    items.push(this.reservationService.createBookingItem(null));

    const bookingItem = items.at(items.length - 1) as FormGroup;
    const index = items.length - 1;

    this.registerOnBookingItem(bookingItem, index);
    this.reservationOptions.filteredRoomTypes.set(items.length - 1, this.reservationOptions.roomTypes);

    return index;
  }

  // changeRoomNumber(bookingItem: FormGroup, index: number) {
  //   this.registerOnBookingItem(bookingItem, index);
  // }

  // --- Reservation Related functions   ---
  addExtraPerson(bookingItemIndex: number) {
    const formGroup = this.reservationService.createExtraPerson(null);
    const items = this.reservationForm.get('booking_items') as FormArray;
    const bookingItem = items.at(bookingItemIndex) as FormGroup;
    const extraPersons = bookingItem.get('extra_persons') as FormArray;
    extraPersons.push(formGroup);
  }

  getExtraPersons(bookingItemIndex: number) {
    const extraPersons = (this.reservationForm.get('booking_items') as FormArray)
      .at(bookingItemIndex)
      .get('extra_persons') as FormArray;

    return extraPersons;
  }

  removeExtraPerson(bookingItemIndex: number, extraPersonIndex: number) {
    const items = this.reservationForm.get('booking_items') as FormArray;
    const bookingItem = items.at(bookingItemIndex) as FormGroup;
    const extraPersons = bookingItem.get('extra_persons') as FormArray;
    extraPersons.removeAt(extraPersonIndex);
  }
  removeExtraRoom(extraRoomIndex: number) {
    const items = this.reservationForm.get('booking_items') as FormArray;
    items.removeAt(extraRoomIndex);
  }

  // --- UI Update features

  removeExtraRoombtn() {
    const extraRoombtn = this.reservationForm.get('booking_items') as FormArray;
    // debugger
    return extraRoombtn.length;
  }

  isLastElement(array: [], index: number) {
    if (array.length > 0) {
      return array.length - 1 == index;
    }
    return false;
  }

  getTotalPriceForDateRange(bookingItemIndex: number) {
    const val = this.reservationOptions.roomDatePriceTotalMap.get(bookingItemIndex.toString());

    return val ? val : '';
  }

  getOneDayPriceForRoom(bookingItemIndex: number) {
    const val = this.reservationOptions.roomDatePriceMap.get(bookingItemIndex.toString());
    if (val && val.length > 1) {
      const price = val[0].price;
      return price;
    }
    return 0;
  }

  getTotal() {
    const total = this.reservationForm.get('total').value;
    return total ? `${this.currencySymbol}${Number(total).toFixed(2)}` : 0;;
  }

  getSubTotal() {
    const subTotal = this.reservationForm.get('subtotal').value;
    return subTotal ? `${this.currencySymbol}${Number(subTotal).toFixed(2)}` : 0;
  }

  getRoomNumberByBookingItemIndex(bookingItemIndex: number): RoomNumber[] {
    const val = this.reservationOptions.roomNumbersMap.get(bookingItemIndex.toString());

    return val ? val : [];
  }

  // --- UI Update features
  pushSelectedRoomNoToRoomTypes() {
    const bookingItems = this.reservationForm.get('booking_items') as FormArray;
    bookingItems.controls.forEach((bookingItem: FormGroup, bookingItemIndex: number) => {
      this.reservationOptions.roomNumbersMap.set(bookingItemIndex.toString(), bookingItem.get('room_numbers').value);
    });
  }


  // toggleSelection(index: number) {
  //   this.isSelectedDiv[index] = !this.isSelectedDiv[index];
  // }

  isPaidValid() {
    // const bookingItems = this.reservationForm.get('booking_items') as FormArray
    const bookingStatus = this.reservationForm.get('status').value;

    if (bookingStatus == 'hold') { return true; }
    return false;
  }
  isUnpaidValid() {
    // const bookingItems = this.reservationForm.get('booking_items') as FormArray
    const bookingStatus = this.reservationForm.get('status').value;

    if (bookingStatus == 'confirm') { return true; }
    return false;
  }
  isConfirmValid() {
    // const bookingItems = this.reservationForm.get('booking_items') as FormArray
    const paymentStatus = this.reservationForm.get('payment_status').value;

    if (paymentStatus == 'unpaid') { return true; }
    return false;
  }

  isHoldValid() {
    // const bookingItems = this.reservationForm.get('booking_items') as FormArray
    const paymentStatus = this.reservationForm.get('payment_status').value;

    if (paymentStatus == 'paid') { return true; }
    return false;
  }

  getExpenses() {
    this.reservationService.getAddOns$().subscribe((res: ExtrasResponse) => {
      this.addOnsExtras = res.data
        .filter((addOn: AddOnsExtras) => addOn.type === "service" && addOn.name !== "")
        .map((addOn: AddOnsExtras) => ({ ...addOn, count: 0 }));
      this.addOnsDisplayMap.set(0, this.addOnsExtras)
    },
      (err) => this.error_handling.handle_error(err)
    );
  }

  // open addons modal multiple
  openAddOnsModal(index: number) {
    this.addOnsModal[index] = !this.addOnsModal[index];
    this.addOnsDisplayMap.set(index, this.addOnsExtras)
  }

  // ^ update addons count by room index
  updateCount(addOnExtras: AddOnsExtras, change: number, roomIndex: number) {
    const bookingItemsArray = this.reservationForm.get('booking_items') as FormArray;
    const extrasArray = bookingItemsArray.at(roomIndex).get('booking_extras') as FormArray;

    const index = extrasArray.controls.findIndex(
      (control) => control.value.extras_item_id === addOnExtras.id
    );

    let count = this.getBookingExtraQuantity(addOnExtras, roomIndex) as number;
    count += change;

    if (count <= 0 && index !== -1) {
      count = 0;
      extrasArray.removeAt(index);
    } else if (count > 0) {
      if (index !== -1) {
        extrasArray.controls[index].get('item_count').setValue(count);
      } else {
        extrasArray.push(this.createExtrasGroup(addOnExtras, count));
      }
    }
    this.updateTotals();
  }

  getBookingExtraQuantity(bookingExtra: AddOnsExtras, roomIndex: number) {
    const bookingItemsArray = this.reservationForm.get('booking_items') as FormArray;
    const extrasArray = bookingItemsArray.at(roomIndex).get('booking_extras') as FormArray;
    let count = 0;
    extrasArray.controls.forEach((v, i) => {
      if (v.get("extras_item_id").value == bookingExtra.id) {
        count = v.get("item_count").value;
      }
    })
    return count;
  }

  // ^ create extras group
  createExtrasGroup(addOn: AddOnsExtras, count: number): FormGroup {
    return this.fb.group({
      property_id: parseInt(this.property.id),
      extras_item_id: addOn.id,
      item_count: count,
    });
  }

  // ^ calculate addons price by room index
  calculateAddonsPriceByRoomIndex(roomIndex: number): number {
    const bookingItemsArray = this.reservationForm.get("booking_items") as FormArray;
    let totalExtrasPrice = 0;
    const taxper = parseFloat(JSON.parse(localStorage.getItem("property")).taxper);
    const taxInclusive = JSON.parse(localStorage.getItem("property")).taxInclusive;

    let addOnsToDisplay = this.addOnsDisplayMap.get(roomIndex);

    if (roomIndex >= 0 && roomIndex < bookingItemsArray.length) {
      const extrasArray = (bookingItemsArray.at(roomIndex) as FormGroup).get("booking_extras") as FormArray;

      if (extrasArray && extrasArray.length > 0) {
        extrasArray.controls.forEach(control => {
          const extrasItemId = control.value.extras_item_id;
          const matchingAddon = addOnsToDisplay.find(addon => addon.id === extrasItemId);

          if (matchingAddon) {
            const addonPrice = parseFloat(matchingAddon.price_after_tax);
            const addonCount = this.getBookingExtraQuantity(matchingAddon, roomIndex) as number;
            totalExtrasPrice += taxInclusive
              ? addonPrice * addonCount
              : (addonPrice + (addonPrice * taxper / 100)) * addonCount;
          }
        });
      }
    }

    return totalExtrasPrice;
  }

  // ^ calculate addons extras price
  calculateAddOnsPrice(): number {
    let totalExtrasPrice = 0;
    const bookingItemsArray = this.reservationForm.get("booking_items") as FormArray;

    totalExtrasPrice = bookingItemsArray.controls.reduce((total, bookingItem, index) => {
      const extrasArray = (bookingItem as FormGroup).get("booking_extras") as FormArray;
      let addOnsToDisplay = this.addOnsDisplayMap.get(index);

      const bookingItemPrice = extrasArray.controls.reduce((subtotal, control) => {

        const extrasItemId = control.value.extras_item_id;

        if (addOnsToDisplay) {
          const matchingAddon = addOnsToDisplay.find(addon => addon.id === extrasItemId);

          if (matchingAddon) {
            const addonCount = this.getBookingExtraQuantity(matchingAddon, index) as number;
            return subtotal + (Number(matchingAddon.price_after_tax) * addonCount);
          } else {
            return subtotal;
          }
        }
      }, 0);

      return total + bookingItemPrice;
    }, 0);

    return totalExtrasPrice;
  }

  // side tray open
  sideMenu(id1) {
    let a = <HTMLInputElement>document.getElementById(id1);
    a.style.right == "0px"
      ? (a.style.right = "-244px")
      : (a.style.right = "0px");

  }

  // dark mode 
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // rotate icon for collapse dropdown
  rotateDrop(id) {
    let a = document.getElementById(id);
    a.classList.contains("rotate")
      ? a.classList.remove("rotate")
      : a.classList.add("rotate");
  }

  // apply scroll if field is invalid in payment section
  areFieldsInvalid() {
    const { channel_id, payment_status, status } = this.reservationForm.controls;
    return (
      (channel_id.hasError('required') || payment_status.hasError('required') || status.hasError('required'))
    );
  }

  // apply scroll if field is invalid in Booked By section
  shouldShowScroll(): boolean {
    const form = this.reservationForm;
    const isSubmitted = this.isSubmittedOnce;

    return (
      (form.get('guest_firstname').hasError('required') ||
        form.get('guest_lastname').hasError('required') ||
        form.get('personal_id_type').hasError('required') ||
        form.get('personal_id_value').hasError('required')) &&
      isSubmitted
    );
  }

  getRoomNameByIndex(bookingItem: FormGroup, index: number) {
    const roomTypeId = bookingItem.value.room_id;

    if (roomTypeId && this.reservationOptions.filteredRoomTypes.has(index)) {
      const roomType = this.reservationOptions.filteredRoomTypes.get(index).find(x => x.room_id === roomTypeId);

      if (roomType) {
        return roomType.name || "Room";
      }
    }

    return "Room";
  }


  getRoomNumberByIndex(bookingItem: FormGroup, index: number) {
    return bookingItem.value.room_no_id
      ? (this.reservationOptions.filteredRoomTypes.get(index)
        .find(x => x.room_id === bookingItem.value.room_id).room_no
        .find(x => x.id === bookingItem.value.room_no_id).room_number || "Room No")
      : "Room No";
  }

  changePaymentStatus(event: string) {
    this.reservationForm.patchValue({
      status: event === 'paid' ? 'confirm' : event === 'unpaid' || event === 'Deposite' ? 'Hold' : null
    });
  }

  resetForm(form: FormGroup) {
    let a = <HTMLInputElement>document.getElementById("sideTrayReservation");
    a.style.right = "0px";
    this.addOnsModal.fill(false);

    if (!this.reservationOptions.isEdit) {
      //remove all booking items except first one 
      const items = this.reservationForm.get('booking_items') as FormArray;
      while (items.length > 1) {
        items.removeAt(1);
      }

      form.reset(this.reservationService.intializeReservationForm(), { emitEvent: false });
      this.perDayData = [];
      this.reservationForm.patchValue({
        eta: localStorage.getItem("eta"),
        etd: localStorage.getItem("etd"),
      });
      this.reservationService.resetReservationForm();
    }

  }

  changeCurrencyFormat(event: any, currency: boolean) {
    const inputValue = event.target.value;
    const parsedValue = parseInt(inputValue);
    if (!isNaN(parsedValue)) {
      if (currency) {
        const formattedValue = `${parsedValue.toFixed(2)}`;
        event.target.value = formattedValue;
      }
      else {
        const formattedValue = `${parsedValue}`;
        event.target.value = formattedValue;
      }
    }
  }

  discountAmount(event) {
    if (event.type == "blur") {
      const stringValue = parseFloat(event.target.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1').toString();
      this.reservationForm.patchValue({ discount_amount: stringValue });
    } else {
      const numericValue = parseFloat(event.target.value);
      this.reservationForm.patchValue({ discount_amount: numericValue });
    }
  }

  handleCalendarBulkReservationPipeline() {
    if (this.bulkReservationSelectionRanges.length === 0) return;

    this.bulkReservationOptions = {
      isCalendarBulkReservation: true,
    }
    this.reservationOptions.seperateCheckinDates = true;

    this.bulkReservationSelectionRanges.forEach((range, i) => {
      if (i > 0) this.addNewBookingItem()
      this.onDateChanges({ checkin: range.start.date.format("YYYY-MM-DD"), checkout: range.end.date.format("YYYY-MM-DD") }, i);
    })

    this.bulkReservationOptions.onRoomsPopulatedSubscription = this.onRoomTypesPopulated$.subscribe((biIndex) => {
      this.reservationForm.get('booking_items')['controls'][biIndex].patchValue({
        room_id: this.bulkReservationSelectionRanges[biIndex].start.roomTypeId,
        room_no_id: this.bulkReservationSelectionRanges[biIndex].start.roomNoId
      });

    })

    //
    //open seperate dates 
    this.reservationForm.patchValue({ sperateRoomDates: true });
  }

}

