import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject, Observable, Subject, Subscription, interval } from "rxjs";
import { HttpResponseModel as IHttpResponseModel } from "../common/HttpResponseModel";
import { DiscountTrackingGraphResponse } from "../common/pnl/DiscountTrackingGraphResponse";
import { ExpenseTypeResponse } from "../common/pnl/ExpenseTypesResponse";
import { PNLDataResponse } from "../common/pnl/PNLDataResponse";
import { ApiService } from "./api.service";
import { map } from 'rxjs/operators';
import { RevenueTrendsGraphResponse } from "../common/pnl/RevenueTrendsGraphResponse";
import { CreateShopResponse } from "../common/pnl/fnb/createShopModel";
import { ExpenseTrendResponse } from "../common/pnl/expense-trend/ExpenseTrendModel";
import { OpexNestedChartResponse } from "../common/pnl/opex-nested-chart/opexNestedModel";
import { NotificatioResponse } from "../common/notifications/NotificationModal";
import { FinancialPerformanceResponse } from "../common/pnl/financial-performance/FinancialPerformanceModels";
import { ExpenseModel } from "../common/pnl/ExpenseModel";
import { GetExpensesRequest } from "../common/pnl/PNLDataRequest";
import { RevenueTrendsRequest } from "../common/pnl/RevenueTrendsRequest";
import { BreakEvenChartResponse } from "../common/pnl/break-even/BreakEvenModel";
import { OpexGraphResponse } from "../common/pnl/opex-graph/OpexGraphResponse";
import { UpdatedPermissionsResponse } from "../common/permissions/updatedPermissionsModel";

@Injectable({
  providedIn: "root",
})
export class PnlService {

  private GetExpenseDataEndPoint: string = "/api/pnl/get_data";
  private SubmitExpenseListEndPoint: string = '/api/pnl/submit_expense';
  // private GetExpenseTypesEndpoint: string = '/api/pnl/get_expense_types';
  private GetExpenseTypesEndpoint: string = '/api/pnl/get_main_expense_types';
  private GetDiscountTrackingGraphEndpoint: string = '/api/pnl/report-by-revenue-discount';
  private GetFinancialPerformanceGraphEndpoint: string = '/api/pnl/report-by-financial-performance';
  private GetOpexGraphEndpoint: string = '/api/pnl/report-by-opex';
  private UploadExpenseExcelEndpoint: string = '/api/pnl/upload_expense';
  private GetRevenueGraphEndpoint: string = '/api/pnl/report-by-revenue-trends';
  private GetOpexChartEndpoint: string = '/api/pnl/report-by-main-expense-type';
  private GetBreakEvenChartEndpoint: string = '/api/pnl/report-by-break-even';
  private GetOpexNestedChartEndpoint: string = '/api/pnl/report-by-opex-nested';
  private GetCreateShopEndpoint: string = '/api/fnb/add_update_shop';
  private GetExpenseTrendsEndpoint: string = '/api/pnl/report-by-cost-to-float';
  private GetPermissionsEndpoint: string = '/api/helper/app_updated_permissions';
  private GetNotificationEndpoint: string = '/api/push-notification/generate_blue_bar_notification';
  private propertyId: string;
  public static isChangesUnsaved: boolean;
  public notification$ = new BehaviorSubject<string>('')

  constructor(private api: ApiService, private http: HttpClient) {
    this.propertyId = localStorage.getItem("current_property");
  }

  getExpenses(startDate: string, endDate: string): Observable<PNLDataResponse> {
    const expenseRequest: GetExpensesRequest = {
      property_id: this.propertyId,
      start_date: startDate,
      end_date: endDate,
      query_type: "custom"
    }
    return this.getExpensesByReq(expenseRequest)
  }

  getExpensesByMonth(monthAndYear: string): Observable<PNLDataResponse> {
    const expenseRequest: GetExpensesRequest = {
      property_id: this.propertyId,
      month: monthAndYear,
      query_type: "month"
    }
    return this.getExpensesByReq(expenseRequest)
  }

  private getExpensesByReq(data: GetExpensesRequest): Observable<PNLDataResponse> {
    return this.http.post<PNLDataResponse>(this.api.base_url + this.GetExpenseDataEndPoint, data, {
      headers: this.prepareAuthHeaders()
    })

  }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);
    return header

  }

  saveExpenses(expense: ExpenseModel[]) {
    return this.http.post<IHttpResponseModel>(this.api.base_url + this.SubmitExpenseListEndPoint, { expense }, {
      headers: this.prepareAuthHeaders()
    })
  }

  getExpenseTypes(): Observable<ExpenseTypeResponse> {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);

    return this.http.get<ExpenseTypeResponse>(this.api.base_url + this.GetExpenseTypesEndpoint, {
      headers: header,
    })
  }



  uploadExpenseExcel(file): Observable<any> {

    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("uploaded_file", file);

    // Make http post request over api
    // with formData as req
    return this.http.post(this.api.base_url + this.UploadExpenseExcelEndpoint, formData, {
      headers: this.prepareAuthHeaders()
    })

  }


  getDiscountTrackingGraph(startDate: string, endDate: string): Observable<DiscountTrackingGraphResponse> {
    const request = {
      property_id: this.propertyId,
      start_date: startDate,
      end_date: endDate,
    }

    return this.http.post<DiscountTrackingGraphResponse>(this.api.base_url + this.GetDiscountTrackingGraphEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getFinancialPerformanceGraph(startDate: string, endDate: string): Observable<FinancialPerformanceResponse> {

    const request = {
      property_id: this.propertyId,
      start_date: startDate,
      end_date: endDate,
    }

    return this.http.post<FinancialPerformanceResponse>(this.api.base_url + this.GetFinancialPerformanceGraphEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })

  }

  getOpexGraph(startDate: string, endDate: string): Observable<OpexGraphResponse> {
    //format startdate to yyyy-mm-dd
    let strDate = moment(startDate).format('YYYY-MM-DD')
    let enDate = moment(endDate).format('YYYY-MM-DD')
    const request = {
      property_id: this.propertyId,
      start_date: strDate,
      end_date: enDate,
    }

    return this.http.post<OpexGraphResponse>(this.api.base_url + this.GetOpexGraphEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getRevenueTrendsGraph(startDate: string, endDate: string, queryType: "month" | "year"): Observable<RevenueTrendsGraphResponse> {
    const request: RevenueTrendsRequest = {
      property_id: this.propertyId,
      start_date: startDate,
      end_date: endDate,
      query_type: queryType,
    }

    return this.http.post<RevenueTrendsGraphResponse>(this.api.base_url + this.GetRevenueGraphEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getOpexChart(startDate: string, endDate: string): Observable<OpexGraphResponse> {
    //format startdate to yyyy-mm-dd
    let strDate = moment(startDate).format('YYYY-MM-DD')
    let enDate = moment(endDate).format('YYYY-MM-DD')
    const request = {
      property_id: this.propertyId,
      start_date: strDate,
      end_date: enDate,
    }

    return this.http.post<OpexGraphResponse>(this.api.base_url + this.GetOpexChartEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getBreakEvenChart(startDate: moment.Moment, endDate: moment.Moment): Observable<BreakEvenChartResponse> {
    //format startdate to yyyy-mm-dd
    let strDate = startDate.format('YYYY-MM-DD')
    let enDate = endDate.format('YYYY-MM-DD')
    const request = {
      property_id: this.propertyId,
      start_date: strDate,
      end_date: enDate,
    }

    return this.http.post<BreakEvenChartResponse>(this.api.base_url + this.GetBreakEvenChartEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getOpexNestedChart(startDate: moment.Moment, endDate: moment.Moment): Observable<OpexNestedChartResponse> {
    //format startdate to yyyy-mm-dd
    let strDate = startDate.format('YYYY-MM-DD')
    let enDate = endDate.format('YYYY-MM-DD')
    const request = {
      property_id: this.propertyId,
      start_date: strDate,
      end_date: enDate,
    }

    return this.http.post<OpexNestedChartResponse>(this.api.base_url + this.GetOpexNestedChartEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getExpenseTrendsChart(startDate: string, endDate: string): Observable<ExpenseTrendResponse> {
    //format startdate to yyyy-mm-dd
    let strDate = moment(startDate).format('YYYY-MM-DD')
    let enDate = moment(endDate).format('YYYY-MM-DD')
    const request = {
      property_id: this.propertyId,
      start_date: strDate,
      end_date: enDate,
    }

    return this.http.post<ExpenseTrendResponse>(this.api.base_url + this.GetExpenseTrendsEndpoint, request, {
      headers: this.prepareAuthHeaders()
    })
  }

  getPermissions(): Observable<UpdatedPermissionsResponse> {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);

    return this.http.get<UpdatedPermissionsResponse>(this.api.base_url + this.GetPermissionsEndpoint, {
      headers: header,
    })
  }

  getNotifications(): Observable<NotificatioResponse> {
    return this.http.post<NotificatioResponse>(this.api.base_url + this.GetNotificationEndpoint, {
      property_id: this.propertyId
    }, {
      headers: this.prepareAuthHeaders()
    })
  }

}
