import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors, FormArray, AbstractControl } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
import { GlobaldataService } from '../../../common/globaldata.service';
declare var $: any;
import * as moment from 'moment';
import { ManageRatesPackagesComponent } from '../manage-rates-packages.component';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-setup-season-rate',
  templateUrl: './setup-season-rate.component.html',
  styleUrls: ['./setup-season-rate.component.css']
})

export class SetupSeasonRateComponent implements OnInit {
  create_package_form: FormGroup;
  submitted = false;
  createdSeason: any = undefined;
  sendData;
  bHeight;
  setup_season;
  ratehead;
  pricing_data;

  gg;
  week = [{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }];
  week2 = [{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }];
  fake = [1];
  darkMode: boolean = false;
  name_colors = ['#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3', ' #283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3', '#283ed8', '#e46300', '#ec12a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff', '#48fff3'];
  constructor(
    public api: ApiService,
    public error_handling: ErrorHandlingService,
    public helper: HelperService,
    public fb: FormBuilder,
    public router: Router,
    public confirm: ConfirmationAlertService,
    public Aroute: ActivatedRoute,
    public rp: ManageRatesPackagesComponent,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.pricing_dataa();
    this.modalfooter();
    this.headChange();
    this.checkDarkMode();


    if (GlobaldataService.savedSeason != undefined) {
      this.createdSeason = GlobaldataService.savedSeason;
      console.log('Coming From Create Season', GlobaldataService.savedSeason)
      this.create_package_form = this.fb.group({
        season_rates: this.fb.array(this.createdSeason.season_room_types.map(item => {


          return this.createItem_from_createSeason(item);
        }))
      });

    } else if (GlobaldataService.editSeason) {

      if (GlobaldataService.editSeason.editAllocation != undefined) {
        console.log('Coming From Edit Season', GlobaldataService.editSeason.editAllocation)
        this.create_package_form = this.fb.group({
          season_rates: this.fb.array(GlobaldataService.editSeason.editAllocation.season_room_types.map(item => {

            return this.createItem_from_EditSeason(item)
          }))
        });
      } else {

        this.create_package_form = this.fb.group({

          season_rates: this.fb.array(GlobaldataService.editSeason.season_room_types.map(item => {
            let x = GlobaldataService.editSeason.season_room_types.sort((a, b) => (a.id > b.id ? 1 : -1));
            console.log(x);
            return this.createItem_from_EditSeason(item);

          }))
        });
        // console.log(GlobaldataService.editSeason.season_room_types);

      }

    }
    else {
      console.log('Comming From No Where');
      this.create_package_form = this.fb.group({
        season_rates: this.fb.array(this.fake.map(item => {

          return this.create_from_none(item)

        }))
      });


      // weekend: this.fb.array([this.addWeekendd()])


    }

    $("#SS").mouseup(function () {
      $("#grab-1").css({ "cursor": "grab" })
    });
    $("#SS").mousedown(function () {
      $("#grab-1").css({ "cursor": "grabbing" })
    });
  }

  // addWeekendd(){

  // }



  createItem_from_createSeason(item): FormGroup {
    let obr = this.create_array_range(1, item.room.adults + item.room.children);
    return this.fb.group({
      week_days_rate: [''],
      weekend_days_rate: [''],
      allocation_high_1: '',
      allocation_high_2: '',
      allocation_high_rate: '',
      allocation_medium_1: '',
      allocation_medium_2: '',
      allocation_medium_rate: '',
      allocation_low_1: '',
      allocation_low_2: '',
      allocation_low_rate: '',
      pricing_model: this.pricing_data,
      roomtypeName: [item.room.roomtype.name],
      display_name: [item.room.display_name],
      roomCount: [item.room.room_count],
      room_type_id: [item.id],
      room_id: [item.room_id],
      smoking: [item.room.smoking],
      pets: [item.room.pets],
      wheelIt: [item.room.wheelchairaccessibility],
      property_id: localStorage.getItem('current_property'),
      season_package_id: this.createdSeason.id,
      week_days: [[{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]],
      weekend_days: [[{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]],
      rate_type: "$",
      obp: this.fb.array(obr.map(ob => {
        return this.create_occupancy();
      }))
    });
  }

  create_occupancy(): FormGroup {  // this will be used in create_from_none and createItem_from_createSeason
    return this.fb.group({ occupant_value: [''], occupant_price: [''] })
  }

  create_from_none(item): FormGroup {
    let obr = this.create_array_range(1, 1);
    return this.fb.group({
      week_days_rate: [''],
      weekend_days_rate: [''],
      allocation_high_1: '',
      allocation_high_2: '',
      allocation_high_rate: '',
      allocation_medium_1: '',
      allocation_medium_2: '',
      allocation_medium_rate: '',
      allocation_low_1: '',
      allocation_low_2: '',
      allocation_low_rate: '',
      pricing_model: this.pricing_data,
      roomtypeName: ['Room'],
      display_name: ['Room'],
      roomCount: ['0'],
      room_type_id: ['1'],
      room_id: ['1'],



      property_id: localStorage.getItem('current_property'),
      season_package_id: '',
      week_days: [[{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]],
      weekend_days: [[{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]],
      rate_type: "$",
      obp: this.fb.array(obr.map(ob => {
        return this.create_occupancy();
      }))
    });
  }

  createItem_from_EditSeason(item): FormGroup {
    let wd = [{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]
    let we = [{ name: 'Mo', value: 'Monday', checked: false }, { name: 'Tu', value: 'Tuesday', checked: false }, { name: 'We', value: 'Wednesday', checked: false }, { name: 'Th', value: 'Thursday', checked: false }, { name: 'Fr', value: 'Friday', checked: false }, { name: 'Sa', value: 'Saturday', checked: false }, { name: 'Su', value: 'Sunday', checked: false }]
    wd.map((val) => {
      item.week_day.map((val2) => {
        if (val2 == val.value) {
          val.checked = true;
        }
      })
    })
    we.map((val3) => {
      item.weekend_day.map((val4) => {
        if (val4 == val3.value) {
          val3.checked = true;
        }
      })
    })

    let obr = item.occupants.length > 0 ? item.occupants : this.create_array_range(1, item.room.adults + item.room.children);
    return this.fb.group({
      week_days_rate: [item.week_days_rate],
      weekend_days_rate: [item.weekend_days_rate],
      allocation_high_1: [item.allocation.length >= 1 ? item.allocation[0].allocation_type == 'High' ? item.allocation[0].start_point : '' : ''],
      allocation_high_2: [item.allocation.length >= 1 ? item.allocation[0].allocation_type == 'High' ? item.allocation[0].end_point : '' : ''],
      allocation_high_rate: [item.allocation.length >= 1 ? item.allocation[0].allocation_type == 'High' ? item.allocation[0].allocation_rate : '' : ''],
      allocation_medium_1: [item.allocation.length >= 2 ? item.allocation[1].allocation_type == 'Medium' ? item.allocation[1].start_point : '' : ''],
      allocation_medium_2: [item.allocation.length >= 2 ? item.allocation[1].allocation_type == 'Medium' ? item.allocation[1].end_point : '' : ''],
      allocation_medium_rate: [item.allocation.length >= 2 ? item.allocation[1].allocation_type == 'Medium' ? item.allocation[1].allocation_rate : '' : ''],
      allocation_low_1: [item.allocation.length >= 3 ? item.allocation[2].allocation_type == 'Low' ? item.allocation[2].start_point : '' : ''],
      allocation_low_2: [item.allocation.length >= 3 ? item.allocation[2].allocation_type == 'Low' ? item.allocation[2].end_point : '' : ''],
      allocation_low_rate: [item.allocation.length >= 3 ? item.allocation[2].allocation_type == 'Low' ? item.allocation[2].allocation_rate : '' : ''],
      pricing_model: this.pricing_data,
      roomtypeName: [item.room.roomtype.name],
      display_name: [item.room.display_name],
      roomCount: [item.room.room_count],
      smoking: [item.room.smoking],
      pets: [item.room.pets],
      wheelIt: [item.room.wheelchairaccessibility],
      room_type_id: [item.id],
      room_id: [item.room_id],
      property_id: localStorage.getItem('current_property'),
      season_package_id: GlobaldataService.editSeason.id,
      week_days: [wd],
      weekend_days: [we],
      rate_type: [item.allocation.length > 0 ? item.allocation[0].rate_type != '%' ? '$' : '%' : '$'],
      obp: this.fb.array(obr.map(ob => {
        return this.create_occupancy_edit(ob);
      }))
    });
  }

  create_occupancy_edit(ob): FormGroup {  // this will be used in create_from_none and createItem_from_createSeason
    return this.fb.group({ occupant_value: [ob.occupant_value], occupant_price: [ob.occupant_price] })
  }

  create_array_range(start, len) {
    let arr = new Array(len);
    for (var i = 0; i < len; i++, start++) {
      arr[i] = start;
    }
    return arr;
  }

  onClose() {
    GlobaldataService.savedSeason = undefined;
    GlobaldataService.editSeason = undefined;
    this.create_package_form.reset();
    this.router.navigate(['.', { outlets: { seasonPanel: null, primary: '/app/rates_packages' } }]);
    $('#settings2').modal('hide');
  }

  get f(): any {
    return this.create_package_form.controls;
    // return (this.recipeForm.get('controlName') as FormArray).controls;
  }
  pricing_dataa() {
    var property_id = localStorage.getItem("current_property");
    this.api.post('api/properties_channel/pricing', { property_id: property_id }, true).subscribe((res: any) => {
      this.pricing_data = res.body.data;
      console.log(this.pricing_data)

    }, err => this.error_handling.handle_error(err.status));
  }


  addWeekday(day, i, evt, mi) {
    if (evt.target.checked) {
      this.create_package_form.value.season_rates[mi].week_days[i].checked = true;
      this.create_package_form.value.season_rates[mi].weekend_days[i].checked = false;

    } else {
      this.create_package_form.value.season_rates[mi].week_days[i].checked = false;
    }
  }

  addWeekend(day, i, evt, mi) {
    if (evt.target.checked) {
      this.create_package_form.value.season_rates[mi].weekend_days[i].checked = true;
      this.create_package_form.value.season_rates[mi].week_days[i].checked = false;
    } else {
      this.create_package_form.value.season_rates[mi].weekend_days[i].checked = false;
    }
  }

  selectAllocationPrice(mainIndex, type) {
    setTimeout(() => {
      this.create_package_form.value.season_rates[mainIndex].rate_type = type;
    }, 500)
  }

  onSubmit() {
    this.submitted = true;
    console.clear();

    // this.create_package_form.value.season_rates.push({check : "let me"})
    console.log(this.create_package_form.value);
    Object.keys(this.create_package_form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.create_package_form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
    if (this.create_package_form.invalid) {
      return;
    }

    if (this.create_package_form.value.season_rates[0].season_package_id == '') {
      this.helper.alert_error('Please Create Season First !');
      return false;
    }
    const s = this.create_package_form.value.season_rates;
    this.sendData = JSON.parse(JSON.stringify(this.create_package_form.value.season_rates));

    this.sendData.map((val) => {
      let w = [];
      let we = [];
      val.week_days.map((val2) => {
        if (val2.checked == true) {
          w.push(val2.value);
        }
      })
      val.weekend_days.map((val3) => {
        if (val3.checked == true) {
          we.push(val3.value);
        }
      })
      val.week_days = w.join();
      val.weekend_days = we.join();
    })
    let allocationData = {
      data: this.sendData
    }

    let DataAr = {
      obp: this.sendData[0].obp,
      room_id: this.sendData[0].room_id,
      season_package_id: this.sendData[0].season_package_id
    }
    console.log(this.sendData);
    // this.pushar(this.sendData);

    this.api.post('api/season_room_type/update', allocationData, true).subscribe((res: any) => {
      this.rp.getRatePlan();
      this.helper.alert_success('Season Rates has been Saved successfully !');
    }, err => this.error_handling.handle_error(err.status));

  }

  modalfooter() {
    setTimeout(() => {
      let f = document.getElementById("ModalFooter")
      let h = document.getElementById("SS");
      this.bHeight = h.offsetHeight;
      if (this.bHeight > 700) {
        f.classList.add("widthBig");
        f.classList.remove("widthPad");
        h.classList.remove("paddR5");
        h.classList.add("paddR2");
      }
      else if (this.bHeight < 700) {
        f.classList.remove("widthBig");
        f.classList.add("widthPad");
        h.classList.add("paddR5");
        h.classList.remove("paddR2");
      }
    }, 1)
  }

  headChange() {
    if (GlobaldataService.editSeason == undefined) {
      this.ratehead = 'Create Rate Plan'
    } else if (GlobaldataService.editSeason != undefined) {
      this.ratehead = 'Edit Rate Plan'
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  dropchnage(id) {
    let mm = document.getElementById(id)
    if (mm.classList.contains("rotatee")) {
      mm.classList.remove("rotatee");
    }
    else {
      mm.classList.add("rotatee")
    }
  }






  // pushar(DataAr) {
  //   console.log(DataAr);
  //   this.api.post('api/expedia_push/set_rate', { data: DataAr }, true).subscribe((res: any) => {
  //     // this.getRatePlan();
  //     console.log(res.body.text);
  //     if(res.body.text != undefined ){
  //     this.toastr.error(res.body.text);
  //     }
  //   }, err => this.error_handling.handle_error(err.status, err.message));
  // }

  letmehceck(a: any) {
    console.log(a);
  }
}
