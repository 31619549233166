import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DiscountTrackingGraphModel } from 'src/app/common/pnl/DiscountTrackingGraphModel';

@Component({
  selector: 'app-dicount-tracking',
  templateUrl: './dicount-tracking.component.html',
  styleUrls: ['./dicount-tracking.component.css']
})
export class DicountTrackingComponent implements OnInit, OnChanges {

  constructor() { }
  @Input('discountData') DiscountGraphModel: DiscountTrackingGraphModel;
  ngOnInit() {
  }

  discountChart: Chart;

  ngOnChanges(): void {
    this.updateChart()
  }

  updateChart() {

    if (!this.DiscountGraphModel)
      return;

    let bgColors = [this.DiscountGraphModel.total.total_revenue > 0 ? '#00295F' : "#aaa", this.DiscountGraphModel.total.total_discount > 0 ? '#1CB5F9' : "#ddd"]
    let totalRev = this.DiscountGraphModel.total.total_revenue > 0 ? this.DiscountGraphModel.total.total_revenue : 1;
    let totalDiscount = this.DiscountGraphModel.total.total_discount > 0 ? this.DiscountGraphModel.total.total_discount : 1;
    let values = [totalRev, totalDiscount]

    const piedata = {
      labels: ['Revenue', 'Discount'],
      datasets: [{
        data: values,
        backgroundColor: bgColors,
        borderWidth: 0
      }],

    };

    const pieconfig: any = {
      type: 'pie',
      data: piedata,
      options: {
        responsive: false,
        legend: {
          display: true,
          position: 'bottom',
          fullSize: true,
          labels: {
            boxWidth: 15,
            boxHeight: 5,
            fontSize: 15,
            textShadow: true,
            padding: 15,
          },
        },
        plugins: {
          labels: {
            render: function (args) {
              //return sums.filter(x=> x.amount == args.value)[0].name +": "+currencyFormat+" "+ args.value
              return this.currencyFormat + ' ' + args.value;
            },
            fontColor: '#fff',
            position: 'default',
            fontSize: 12,
          }
        },

      }
    };

    if (this.discountChart) {
      this.discountChart.destroy();
    }

    this.discountChart = new Chart(
      'discount-tracking-pie',
      pieconfig
    );
  }




}
