import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, registerables } from 'chart.js';
import colorLib from '@kurkle/color';
Chart.register(...registerables);
import { PnlService } from 'src/app/services/pnl.service';
import * as echarts from 'echarts';
type EChartsOption = echarts.EChartsOption;
import { OpexGraphData } from 'src/app/common/pnl/opex-graph/OpexGraphResponse';
import { OpexChartData } from 'src/app/common/pnl/OpexChartModel';
import { OpexNestedData } from 'src/app/common/pnl/opex-nested-chart/opexNestedModel';

@Component({
  selector: 'app-opex',
  templateUrl: './opex.component.html',
  styleUrls: ['./opex.component.css']
})
export class OpexComponent implements OnInit, OnChanges {

  constructor(private pnlService: PnlService) {
  }

  @Input('choosenDate') choosen_date;
  opexData: OpexGraphData;
  opexChartData: OpexChartData;
  opexNestedChartData: OpexNestedData;
  var_cost: boolean = false;
  fix_cost: boolean = false;

  opexBreakdownColors = [
    "#FF8800",
    "#00DCFF",
    "#FF00DD",
    "#FDF001",
    "#0019ff",
    "#A200FF",
    "#66B436",
    '#5D00FF',
    '#E70E02',
    '#00afb9',
    '#ffc300',
    '#89FC00',
  ]

  format_amount: boolean = true;

  private opexBreakdownChart: Chart;
  private opexPolarChart: Chart;
  private opexComparisonChart: Chart;
  private opexExpenseCategoryChart: Chart;
  private opexNestedExpenseCategoryChart;

  ngOnInit() {
    // this.updateCharts();
  }

  ngOnChanges(): void {
    // this.updateCharts();
    this.getPnlData();
  }


  getPnlData() {
    // this.pnlService.getOpexChart(this.choosen_date.startDate, this.choosen_date.endDate).subscribe((res) => {
    //   this.opexChartData = res.data;
    //   this.updateCharts();
    // })

    this.pnlService.getOpexNestedChart(this.choosen_date.startDate, this.choosen_date.endDate).subscribe((res) => {
      this.opexNestedChartData = res.data;
      this.updateOpexNestedChart();
    })
  }
  // empty donut (working on saud 👇)
  private emptyDount() {
    return {
      id: 'emptyDoughnut',
      afterDraw(chart, args, options) {
        const { datasets } = chart.data;
        const { color, width, radiusDecrease } = options;
        let hasData = false;

        for (let i = 0; i < datasets.length; i += 1) {
          const dataset = datasets[i];
          hasData = dataset.data.length > 0;
        }

        if (!hasData) {
          const { chartArea: { left, top, right, bottom }, ctx } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.beginPath();
          ctx.lineWidth = width || 2;
          ctx.strokeStyle = color || '#e7e7e7';
          ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
          ctx.stroke();
        }
      }
    };
  }

  updateCharts() {
    this.pnlService.getOpexGraph(this.choosen_date.startDate, this.choosen_date.endDate).subscribe(
      data => {
        this.opexData = data.data
        // if (this.opexBreakdownChart) {
        //   this.opexBreakdownChart.destroy();
        // }
        // if (this.opexExpenseCategoryChart) {
        //   this.opexExpenseCategoryChart.destroy();
        // }
        // this.updateOpexBreakDownChart()
        // this.updateExpensecategoryChart()
        // this.updateOpexPolarChart()
        // this.updateOpexComparisonChart()

      })
  }

  updateOpexBreakDownChart() {

    let opexData = this.opexData;
    let color = this.opexBreakdownColors;
    let labels = [];

    const htmlLegendPlugin = htmlLegend();

    opexData.expense_data.map(x => x.name).forEach((label, index) => {

      let name = label.split(' ')[0]
      // name = name.replace('Expenses', '')
      // name = name.replace('Expense', '')
      labels.push(name)
    });


    const data: ChartData = {
      labels: labels.filter(x => x !== 'Other '),

      datasets: [
        {
          label: 'Opex Breakdown',
          data: opexData.expense_data.map(expense => expense.total_amount),
          backgroundColor: color,
          borderWidth: 0
        }
      ]
    };

    const config: any = {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
        aspectRatio: 2,
        // plugins: {
        //   legend: {
        //     position: "right",
        //     align: 'left',
        //     display: true,
        //     fullSize: true,
        //     labels: {
        //       boxHeight: 10,
        //       boxWidth: 16,
        //       usePointStyle: true,
        //       pointStyle: "rectRounded",
        //       textAlign: 'left',
        //       padding: 10,
        //       font: {
        //         size: 15,
        //         weight: '500',
        //         family: 'Roboto'
        //       }
        //     }
        //   }
        // }
        plugins: {
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      },
      plugins: [htmlLegendPlugin],
    };
    this.opexBreakdownChart = new Chart('opex-breakdown-canvas', config);
  }

  updateExpensecategoryChart() {
    const plugin = this.emptyDount();
    let color = ['#FF595E', '#6A4C93 ', '#75934C'];
    let opexChartData = this.opexNestedChartData;
    let labels = [];
    const htmlLegendPlugin = htmlLegend();

    opexChartData.expense_data.expense_type.map(x => x.expense_main_type_name).forEach(element => {
      labels.push(element.split(' ')[0]);
    });


    const data: ChartData = {
      labels: labels,

      datasets: [
        {
          label: 'Expense Category',
          data: opexChartData.expense_data.expense_type.map(x => x.total),
          backgroundColor: color,
          borderWidth: 0
        }
      ]
    };

    const config: any = {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
        // aspectRatio: 2,
        plugins: {

          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,

            external: function (context) {
              // Tooltip Element
              let tooltipEl: any = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = "<div class='cus-box' style='text-align:center;'></div>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = "";

                titleLines.forEach(function (title) {
                  innerHtml += "<div>" + title + "</div>";
                });
                innerHtml += "<div>";

                bodyLines.forEach(function (body, i) {
                  var colors = tooltipModel.labelColors[i];
                  let n = body[0];
                  let re = n.split(':')[0];
                  let amt = n.split(':')[1];
                  innerHtml += `<div style='background: #ffffffc7 !important;border-radius:10px;border:1px solid ${colors.backgroundColor};padding:5px'>
                  <label class="list-data-name"  style='white-space: nowrap; width: 100px;  overflow: hidden;text-overflow: ellipsis; font-size:14px;border-bottom:1px solid ${colors.backgroundColor}'>${re}</label>
                  <div style='font-weight: bold; font-size: 14px;text-align:center;padding-left:5px;'> ${(localStorage.getItem('currency') + amt)}</div>
                  </div>`;
                });
                innerHtml += "</div>";
                var tableRoot = tooltipEl.querySelector('.cus-box');
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              // tooltipEl.style.font = bodyFont.string;
              tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          emptyDoughnut: {
            color: '#e7e7e7',
            width: 230,
            radiusDecrease: 120
          },
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'opex-legend',
          },
          legend: {
            display: false,
          }
        },
      },
      plugins: [plugin, htmlLegendPlugin]
    };

    this.opexExpenseCategoryChart = new Chart('expense-category-canvas', config);

  }

  var_fix_cost(id: string) {
    let ids = <HTMLInputElement>document.getElementById(id);

    if (ids.checked) {
      if (id == 'var') {
        this.fix_cost = false;
        this.var_cost = true;
        let data = <Chart>this.opexExpenseCategoryChart;
        data.config.data.datasets[0].data = [];
        data.config.data.datasets[0].data = this.opexNestedChartData.expense_data.expense_type.map(x => x.variable_cost);
        data.update();
        this.updateExpensecategoryChart();
      } else {
        this.var_cost = false;
        this.fix_cost = true;
        let data = <Chart>this.opexExpenseCategoryChart;
        data.config.data.datasets[0].data = [];
        data.config.data.datasets[0].data = this.opexNestedChartData.expense_data.expense_type.map(x => x.fixed_cost);
        data.update();
        this.updateExpensecategoryChart();
      }
    }
    else {
      let data = <Chart>this.opexExpenseCategoryChart;
      data.config.data.datasets[0].data = [];
      data.config.data.datasets[0].data = this.opexNestedChartData.expense_data.expense_type.map(x => x.total);
      data.update();
      this.updateExpensecategoryChart();
    }

  }

  updateOpexComparisonChart() {
    let opexData = this.opexData
    const data: any = { //ChartData
      labels: ['Revenue', 'OPEX'],

      datasets: [
        {
          label: 'Total Expense',
          data: [opexData.total.total_revenue, opexData.total.total_expense],
          backgroundColor: [
            "#0179F1",
            '#EC1361',
          ],
          pointStyle: "rectRounded",
          borderWidth: 0,
          cutout: 90,
        }
      ]
    };

    const config: any = { //ChartConfiguration
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "bottom",
            align: 'center',
            display: true,
            fullSize: true,
            labels: {
              boxHeight: 10,
              boxWidth: 16,
              usePointStyle: true,
              pointStyle: "rectRounded",
              padding: 15,
              font: {
                size: 12
              }
            }
          }
        }
      },
    };
    this.opexComparisonChart = new Chart('opex-comparison-canvas', config);

  }

  updateOpexPolarChart() {

    let opexData = this.opexData;
    // let color = this.opexBreakdownColors.map(x => this.transparentize(x,0.5));
    let color = ['#ff8700', '#80ed99', '#fcf300', '#FB5607', '#FF006E', '#7209B7', '#3A86FF'];

    let labels = []

    opexData.expense_data.map(x => x.name).forEach((label, index) => {

      let name = label.split('and')[0]
      name = name.replace('Expenses', '')
      name = name.replace('Expense', '')
      name = name.replace('Other', 'Other Expense')
      name = name.replace('Operating', 'General Expense')
      labels.push(name)
    })

    const data: any = {
      labels: labels,

      datasets: [
        {
          label: 'Opex Breakdown',
          data: opexData.expense_data.map(expense => expense.total_amount),
          backgroundColor: color,
          borderWidth: 0
        }
      ]
    };

    const config: any = {
      type: 'polarArea',
      data: data,
      options: {
        responsive: true,
        scales: {
          r: {
            pointLabels: {
              display: true,
              centerPointLabels: true,
              font: {
                size: 12,
              }
            }
          }
        },
        plugins: {
          legend: {
            position: "right",
            align: 'left',
            display: false,
            fullSize: true,
            labels: {
              boxHeight: 10,
              boxWidth: 16,
              usePointStyle: true,
              pointStyle: "rectRounded",
              textAlign: 'left',
              padding: 10,
              font: {
                size: 15,
                weight: '500',
                family: 'Roboto'
              }
            }
          }
        }
      },
    };
    if (this.opexPolarChart) {
      this.opexPolarChart.destroy();
    }
    this.opexPolarChart = new Chart('opex-polar-canvas', config);
  }


  transparentize(value, opacity) {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
  }
  // set pie chart color according to expense label names (working on saud 👇)


  updateOpexNestedChart() {

    var dom = document.getElementById("opexNestedChart");

    let expenseData = this.opexNestedChartData.expense_data;
    let labels = [];
    let admin_label = [];
    let selling_label = [];
    let general_label = [];
    let labelexpenseCategory = [];

    expenseData.expense_subtype.filter(x => x.total_amount !== 0).map(x => {
      let name = x.expense_main_type;
      name == "Administrative Expense" ? admin_label.push({ value: x.total_amount, name: x.name.split(' ')[0] }) :
        name == "Selling Expense" ? selling_label.push({ value: x.total_amount, name: x.name.split(' ')[0] }) :
          name == "General Expense" ? general_label.push({ value: x.total_amount, name: x.name.split(' ')[0] }) : null
    });

    let final_expense = [...selling_label, ...general_label, ...admin_label];

    final_expense.map(x => {
      labels.push(x.name), console.log(x.name, 'names');
    })

    expenseData.expense_type.filter(x => x.total !== 0).map(x => {
      let expense = x.expense_main_type_name.replace('Administrative', 'Admin')
      labelexpenseCategory.push({ value: x.total, name: expense.split(' ')[0] });
    });

    var myChart = echarts.init(dom, null, {
      renderer: "canvas",
      useDirtyRect: false
    });

    const changecolor = (value) => {
      console.log(value, "values");
      var color;
      switch (value) {
        case "OTA":
          color = "#80ffe8";
          break;
        case "Agent":
          color = "#00ffd0";
          break;
        case 'Labor':
          color = "#F72585";
          break;
        case 'Payroll':
          color = '#F76FAD';
          break;
        case 'Operating':
          color = '#7209b7';
          break;
        case 'Cleaning':
          color = '#8F30D3';
          break;
        case 'Conveyance':
          color = '#AC4DF0';
          break;
        case 'Maintenance':
          color = '#CA68FF';
          break;
        case 'Food':
          color = '#E884FF';
          break;
        case 'Electricity':
          color = '#FFA0FF';
          break;
        case 'Internet':
          color = '#FFA9FF';
          break;
        case 'Fuel':
          color = '#FFBCFF';
          break;
        case 'Phone':
          color = '#FFD8FF';
          break;
        case 'Admin':
          color = '#f72585';
          break;
        case 'Selling':
          color = '#00FFD0';
          break;
        case 'General':
          color = '#7209b7';
          break;
      }
      // "#FF8800",
      // "#00DCFF",
      // "#FF00DD",
      // "#FDF001",
      // "#0019ff",
      // "#A200FF",
      // "#66B436",
      // '#5D00FF',
      // '#E70E02',
      // '#00afb9',
      // '#ffc300',
      // '#89FC00',
      return color;
    };

    console.log(this.opexNestedChartData.expense_data, 'expense data');


    this.opexNestedExpenseCategoryChart = {
      legend: {
        data: labels,
        height: "100%",
        orient: "vertical",
        right: 10,
        top: "center"
      },
      series: [
        {
          name: " ",
          type: "pie",
          selectedMode: "single",
          radius: [0, "40%"],
          label: {
            position: "inner",
            fontSize: 12
          },
          labelLine: {
            show: false
          },
          center: ["40%", "50%"],
          data: labelexpenseCategory.map(item => {
            return {
              value: item.value,
              name: item.name,
              itemStyle: {
                color: changecolor(item.name)
              }
            };
          }),
        },
        {
          name: " ",
          type: "pie",
          radius: ["50%", "70%"],
          labelLine: {
            length: 20
          },
          label: {
            emphasis: {
              show: true,
              formatter: "{b}: {per|{c}}",
              textStyle: {
                fontSize: '16',
                fontWeight: 'bold',
                color: "#4C5058",
                padding: [10, 10, 10, 10]
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            formatter: '{b|{b}：}{per|{c}}',
            padding: [0, 10, 0, 10],
            backgroundColor: "#F6F8FC",
            borderColor: "#8C8D8E",
            borderWidth: 1,
            borderRadius: 4,
            rich: {
              a: {
                color: "#6E7079",
                lineHeight: 22,
                align: "center"
              },
              hr: {
                borderColor: "#8C8D8E",
                width: "100%",
                borderWidth: 1,
                height: 0
              },
              b: {
                color: "#4C5058",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: 33
              },
              per: {
                color: "#fff",
                backgroundColor: "#4C5058",
                padding: [3, 4],
                borderRadius: 4
              }
            },
          },
          center: ["40%", "50%"],
          data: final_expense.map(item => {
            return {
              value: item.value,
              name: item.name,
              itemStyle: {
                color: changecolor(item.name)
              }
            };
          }),
          sort: null
        }
      ],
      showEmptyCircle: true,
      emptyCircleStyle: {
        color: "rgba(255, 0, 0, 1)"
      }
    };

    if (this.opexNestedExpenseCategoryChart && typeof this.opexNestedExpenseCategoryChart === "object") {
      myChart.setOption(this.opexNestedExpenseCategoryChart);
    }

    window.addEventListener("resize", () => myChart.resize());

    // toggle click change amount to percentage (working on saud 👇)
    let change = <HTMLDivElement>document.getElementById('change_format');

    change.addEventListener('click', () => {
      this.format_amount = !this.format_amount;
      myChart.setOption({
        series: [{}, {
          label: {
            formatter: this.format_amount ? '{b|{b}：}{per|{c}}' : '{b|{b}：}{per|{d}%}',
          }
        }]
      })
    })
  }

}


// HTml Legend plugin chart js
function htmlLegend() {
  const getOrCreateLegendList = (chart: any, id: string) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      listContainer.style.width = 'max-width';

      legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(item => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.alignItems = 'center';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';
        li.style.marginBottom = '5px';

        li.onclick = () => {
          const { type } = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '12px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '12px';
        boxSpan.style.borderRadius = '3px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.fontSize = '14px';
        textContainer.style.fontFamily = 'Roboto';
        textContainer.style.fontWeight = '500';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };
  return htmlLegendPlugin;
}

