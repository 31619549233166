import { HelperService } from './../../../services/helper.service';
import { PnlService } from './../../../services/pnl.service';
import { Expenses } from './../expenses/expense';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as XLSX from "xlsx";
import * as moment from 'moment';
import { PnlDataModel } from 'src/app/common/pnl/PnlDataModel';
declare var html2canvas: any;


@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
  @ViewChild('capture', { static: true }) capture: ElementRef;
  a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]

  currency_format = localStorage.getItem('currency');
  pnldata: PnlDataModel = null
  selectedDate
  earningFromRooms
  fnbTotal
  costOfItems
  expensesTotal
  expensesTotalDiscount: number;
  totalProfitnLoss: number;
  month = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);
  start_date = new Date().toJSON().slice(0, 10)
  end_date = new Date().toJSON().slice(0, 10)
  darkMode: boolean = false;
  exte: boolean = true;
  // month:any
  @ViewChild('grid_background', { static: false }) grid_background: ElementRef;

  constructor(
    private pnlApi: PnlService,
    private helperService: HelperService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.onDateChanged()

    // console.log(this.filteredExpenseList(1))
  }

  onDateChanged() {

    let val = localStorage.getItem('pnl_selected_Date');
    let dateRange = JSON.parse(val);


    if (dateRange) {

      this.month = dateRange.startDate.slice(0, -3)
      // debugger;
      this.start_date = dateRange.startDate.split('T')[0]
      this.end_date = dateRange.endDate.split('T')[0]
    }

    this.getPnlDataByDate(this.start_date, this.end_date)

    // this.getPnlData(this.month);
  }

  // getPnlData(month){

  //   this.pnlApi.getExpensesByMonth(month).subscribe((res)=>{
  //     // console.log(res)
  //     this.pnldata = res.data
  //     // console.log(this.pnldata)
  //     this.earningFromRooms = res.data.revenue.earning_from_rooms
  //     // this.costOfItems = res.data.pnlStatement.totalCostOfGoodSold
  //     this.expensesTotal = res.data.expenses.sum

  //   })
  // }

  getPnlDataByDate(start, end) {

    this.pnlApi.getExpenses(start, end).subscribe((res) => {
      this.pnldata = res.data

      console.log(res.data.statementOfFinance.totalDiscount, 'expensesTotalDiscount')
      this.earningFromRooms = res.data.revenue.earning_from_rooms;
      this.fnbTotal = res.data.revenue.fnb;
      this.expensesTotal = res.data.expenses.sum;
      this.expensesTotalDiscount = res.data.statementOfFinance.totalDiscount;
      this.totalProfitnLoss = res.data.statementOfFinance.totalProfitnLoss;
    })
  }



  calculateTotal() {
    let total = this.earningFromRooms - this.expensesTotal;
    return total;
  }



  makePDF() {
    this.exte = false
    setTimeout(() => {
      let HTML_Width = this.capture.nativeElement.offsetWidth;
      let HTML_Height = this.capture.nativeElement.offsetHeight;
      let top_left_margin = 0;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      html2canvas(this.capture.nativeElement, { allowTaint: true }).then(function (canvas) {
        canvas.getContext('2d');
        let imgData = canvas.toDataURL();
        let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'SVG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
        for (let i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(imgData, 'SVG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }
        pdf.save("Financial-statement.pdf");

      });
      this.exte = true
    }, 1000)
  };


  makeExcel() {
    let element = document.getElementById("excel-table");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, 'Financial-statement.xlsx');
  }

  filteredExpenseList(expenseTypeId: any) {
    let expenseList = this.pnldata.expenses.data.filter(x => x.expense_type_id == expenseTypeId && x.is_deleted == false);
    return expenseList;
  }


  sumTotalExpenses(expensTypeId: any) {
    return this.pnldata.expenses.data.filter(x => x.amount != null && x.expense_type_id == expensTypeId && x.is_deleted == false).map(x => x.amount).reduce((a, b) => Number(a) + Number(b), 0).toFixed(2)
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

}

