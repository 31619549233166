<!-- Food and Beverages (create-menu-Page) work start -->

<div class="main-div" *ngIf="orderList">
  <!-- <app-rms-breadcrum></app-rms-breadcrum> -->

  <div class="col-md-12 blueHedaer">
    <div class="row">
      <!-- <div class="category">Image</div> -->
      <div class="category">Room No</div>
      <div class="category">Order Time</div>
      <div class="category">Status</div>
      <div class="category">Price</div>
      <div class="category">Action</div>
    </div>
  </div>
  <div class="card inputCard" [ngClass]="darkMode ? 'card_dark' : ''">
    <!-- <div class="image" *ngIf="imageShow">
            <i class="fa fa-times" aria-hidden="true" (click)="imageFullPre()"></i>
            <img src="../../../../assets/img/manage_menu/pizza big.png" alt="pizza">
        </div> -->
    <div class="row">
      <div class="col-md-12 inputCat">
        <ng-container *ngFor="let order of orderList | paginate: { itemsPerPage: 15, currentPage: page };index as i">
          <div class="col-md-12 first">
            <div class="modal-bar" [ngStyle]="{'border-color' : randomColor[i%randomColor.length]}">
              <div class="row d-flex w-100">
                <div class="col-md-9 addItemCat">
                  <div class="form-control borderRight">{{order.room_number.room_number}}</div>
                  <div class="form-control borderRight d-flex align-items-center"
                    style="justify-content: space-evenly;">
                    {{ order.order_time }}
                    <!-- Assuming the `isOrderExpired` and `formatTime` functions are defined in your component -->
                    <ng-container
                      *ngIf="!(isOrderExpired(order.order_date, order.order_time) || order.order_status.toLowerCase() === 'modified' || order.order_status.toLowerCase() === 'cancelled')">
                      | {{ formatTime(remainingTime) }}
                    </ng-container>
                  </div>
                  <div class="form-control borderRight d-flex align-items-center justify-content-center">
                    <button class="btn btnStatus" [ngClass]="{
                      'redBtn': order.order_status.toLowerCase() === 'cancelled',
                      'purpleBtn': order.order_status.toLowerCase() === 'modified'
                    }">
                      {{ order.order_status }}
                    </button>
                  </div>
                  <div class="form-control borderRight">{{currency_format}} {{order.total | number:'1.2-3'}}</div>
                </div>
                <div class="col-md-3 actions">
                  <button type="button" class="btn cart-btn" (click)="viewOrderDeatails(i)">
                    <img src="assets/img/create_user_fnb/empty-cart.svg" alt="view"
                       class="edit" data-toggle="tooltip" data-placement="bottom"
                      title="View">
                    <span class="badge badge-light">{{order.order_items.length}}</span>
                  </button>
                  <img src="assets/img/create_user_fnb/edit-icon.svg" alt="edit" class="edit pl-1"
                    data-toggle="tooltip" data-placement="bottom" title="Edit" (click)="editOrderItem(i)">
                  <img src="assets/img/create_user_fnb/cross-icon.svg" alt="delete" height="38" class="edit"
                    data-toggle="tooltip" data-placement="bottom" title="Delete"
                    [attr.data-toggle]="cancelledOrder ? null : 'modal'"
                    [attr.data-target]="cancelledOrder ? null : '#confirmationModal-3'" (click)="cancelOrder(i)">
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <pagination-controls *ngIf="orderList.length > 15" class="pagination" previousLabel=""
        nextLabel="" (pageChange)="page = $event"></pagination-controls>
      </div>
    </div>
  </div>
</div>


<!-- Modal Table -->
<div class="modal fade" id="productModal" *ngIf="OrderModalShow" tabindex="-1" aria-labelledby="exampleModalLabel"
  aria-hidden="true" cdkDrag>
  <div class="modal-dialog">
    <div class="modal-content" [ngClass]="darkMode ? 'modal_dark' : ''" #htmlContent>
      <div class="redHedaer">
        <h5 class="modal-title" id="exampleModalLabel">Order For Room {{viewOrderList.room_no_or_table_id}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-labelledby="" (click)="onModalClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let orderItem of viewOrderList.order_items;index as j">
          <div class="col-md-12 first">
            <button class="btn btnCount" [ngClass]="{'badge-danger' : orderItem.item_status === 'Removed'}">
              {{ orderItem.item_status === 'Removed' ? 0 : orderItem.quantity}}
            </button>
            <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/' + (orderItem.product?.image || orderItem.deal?.image)"
              alt="pizza">
            <div class="modal-bar" [ngClass]="{
              'border-success': orderItem.item_status === 'Added',
              'border-danger': orderItem.item_status === 'Removed',
              'border-purple': orderItem.item_status === 'Modified'
            }" [ngStyle]="{'border-color' : randomColor[j%randomColor.length]}">
              <div class="d-flex align-items-center">
                <span>{{orderItem.product?.item_name || orderItem.deal?.deal_name}}
                  <ng-container *ngIf="orderItem.item_status">
                    <span class="badge" [ngClass]="{
                      'badge-success': orderItem.item_status === 'Added',
                      'badge-danger': orderItem.item_status === 'Removed',
                      'badge-purple': orderItem.item_status === 'Modified'
                    }">
                      {{ orderItem.item_status }}
                    </span>
                  </ng-container>
                </span>
                <!---start tooltip--->
                <div class="ispan1" *ngIf="orderItem.deal?.deal_name">
                  <img aria-expanded="false" aria-haspopup="true" class="btn-hov"
                    src="assets/img/manage_rates/dots.png">
                  <span class="toolo" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                    <p class="small" style="padding: 0px !important; margin: 0px !important;">
                      {{ getTooltipText(orderItem) }}
                    </p>
                  </span>
                </div>
              </div>
              <!---end tooltip--->
              <span>
                {{currency_format}} {{ orderItem.item_status === 'Removed' ? 0 : orderItem.amount | number:'1.2-3'}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 first">
            <div class="modal-bar border-1">
              <span>Total</span>
              <span>
                {{currency_format}} {{viewOrderList.total | number:'1.2-3'}}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 first">
            <div class="modal-bar border-7">
              <span>{{viewOrderList.order_time}}</span>
              <span>{{viewOrderList.order_date | date: 'dd MMM yy'}}</span>
            </div>
          </div>
          <div class="col-md-12 first" *ngIf="viewOrderList.special_requests">
            <div class="modal-bar border-4">
              <span>Special requests</span>
              <span>{{viewOrderList.special_requests}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <img src="../../../../assets/img/order-list/Shared.svg" alt="share">
      </button>
      <button type="button" class="btn" (click)="printPdf()">
        <img src="../../../../assets/img/order-list/print.svg" alt="print">
      </button>
    </div>
  </div>
</div>