import { Component, OnInit } from '@angular/core';
import { RmsService } from 'src/app/services/rms.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  darkMode: boolean = false;

  constructor(
    private rmsService: RmsService
  ) { }
  evt_orderCard: Array<any> = [
    {
      id: '1',
      itemImage: "../../../../assets/img/back-office/rms_img/conference.jpg",
      title: 'Conferences',
      count: 0,
      price: 500
    },
    {
      id: '2',
      itemImage: "../../../../assets/img/back-office/rms_img/seminar.jpg",
      title: 'Seminar',
      count: 0,
      price: 250
    },
    {
      id: '3',
      itemImage: "../../../../assets/img/back-office/rms_img/charity.jpg",
      title: 'Charity',
      count: 0,
      price: 250
    },
    {
      id: '4',
      itemImage: "../../../../assets/img/back-office/rms_img/trade-show.jpg",
      title: 'Trade Show',
      count: 0,
      price: 300
    },
    {
      id: '5',
      itemImage: "../../../../assets/img/back-office/rms_img/networking.jpg",
      title: 'Networking',
      count: 0,
      price: 400
    },
    {
      id: '6',
      itemImage: "../../../../assets/img/back-office/rms_img/cermonies.jpg",
      title: 'Cermonies',
      count: 0,
      price: 750
    },
    {
      id: '7',
      itemImage: "../../../../assets/img/back-office/rms_img/product-launch.jpg",
      title: 'Product Launch',
      count: 0,
      price: 350
    },

    {
      id: '8',
      itemImage: "../../../../assets/img/back-office/rms_img/board-meeting.jpg",
      title: 'Board Meeting',
      count: 0,
      price: 250
    },
    {
      id: '9',
      itemImage: "../../../../assets/img/back-office/rms_img/recruiting.jpg",
      title: 'Recruiting',
      count: 0,
      price: 350
    },
    {
      id: '10',
      itemImage: "../../../../assets/img/back-office/rms_img/job-fair.jpg",
      title: 'Job Fair',
      count: 0,
      price: 300
    },
    {
      id: '11',
      itemImage: "../../../../assets/img/back-office/rms_img/live-performance.jpg",
      title: 'Live Performance',
      count: 0,
      price: 950
    },
    {
      id: '12',
      itemImage: "../../../../assets/img/back-office/rms_img/vip-events.jpg",
      title: 'VIP Events',
      count: 0,
      price: 950
    }


  ]
  ngOnInit() {
    this.checkDarkMode();
  }
  evtOrder(index: any) {
    this.rmsService.addOrderItem(this.evt_orderCard[index])
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}
