<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>House Keeping</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck4" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button27"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-HouseKeeping" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button27')">
    </a>
  </div>
  <div class="collapse" id="collapse-HouseKeeping" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <!------1st column------>
    <div class="col-md-6 pr-0 pl-0">
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="border-style  first-name-shade lab-sty hk1">
          <label>List View</label>

          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
            style="margin:0px;cursor: pointer;">
        </div>

      </div>
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="border-style  first-name-shade lab-sty hk2">
          <label> Attendance</label>

          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
            style="margin:0px;cursor: pointer;">
        </div>

      </div>

    </div>
    <!------2nd column------>
    <div class="col-md-6 pr-0 pl-2">
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="border-style  first-name-shade lab-sty hk3">
          <label>Calender View</label>

          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
            style="margin:0px;cursor: pointer;">
        </div>

      </div>
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="border-style  first-name-shade lab-sty hk4">
          <label>Schedule</label>

          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
            style="margin:0px;cursor: pointer;">
        </div>

      </div>

    </div>

  </div>
</div>