import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ToastrService } from 'ngx-toastr';
import { ShopDealItem } from 'src/app/common/fnb/MenuDealItem';
import { Category, MenuProductItems, Product } from 'src/app/common/fnb/MenuProductModel';
import { PlaceOrderItem } from 'src/app/common/rms/PlaceOrderItem';
import { FnbService } from 'src/app/services/fnb.service';
import { RmsService } from 'src/app/services/rms.service';
@Component({
  selector: 'app-cafe',
  templateUrl: './cafe.component.html',
  styleUrls: ['./cafe.component.css']
})
export class CafeComponent implements OnInit {

  darkMode: boolean = false;
  dealCategoryItems: ShopDealItem[] = [];
  categories: Category[] = []
  productItems: Product[] = [];
  filteredCategoryItems: Product[] = [];
  search_item: string;
  @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;
  currency_format = localStorage.getItem('currency');
  tab_name: string = "All";
  searchAllData: Product[];
  searchDealItems: ShopDealItem[];
  searchFilteredItems: Product[];
  isOrderListEmpty: boolean = true;

  constructor(
    private rmsService: RmsService,
    private fnbService: FnbService,
    private toaster: ToastrService,

  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.onShopSwitched();
    this.getProductItems();
    this.getDealItems();
    this.rmsService.OnOrderListEmpty.subscribe(x => {
      this.isOrderListEmpty = x
    })
  }

  orderNow(index: number, productItems) {
    if (
      this.productItems === productItems || this.filteredCategoryItems === productItems || this.dealCategoryItems === productItems
    ) {
      this.rmsService.addOrderItem(productItems[index]);
      console.log(productItems[index]);
    }
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // get deal menu category and items (working on saud 👇)
  getDealItems() {
    this.rmsService.getDealCategoryItems().subscribe((dealItems: ShopDealItem[]) => {
      this.searchDealItems = dealItems;
      this.dealCategoryItems = this.searchDealItems;
    });
  }

  // get product menu category and items (working on saud 👇)
  getProductItems() {
    this.rmsService.getProductCategoryItems().subscribe((productItems: MenuProductItems) => {
      this.categories = productItems.categories;
      this.searchAllData = productItems.products;
      this.productItems = this.searchAllData;
    });
  }

  // fetch data selected tab according category id (working on saud 👇)
  fetchMenuItem(event: MatTabChangeEvent) {

    this.tab_name = event.tab.textLabel;

    const selectedIndex = event.index - 1;
    const selectedCategory = this.categories[selectedIndex];

    this.searchFilteredItems = selectedCategory
      ? this.productItems.filter(item => item.category_id === selectedCategory.category_id)
      : [];

    this.filteredCategoryItems = this.searchFilteredItems;
  }

  // data display according shopId (working on saud 👇)
  onShopSwitched() {
    this.rmsService.matTabIndex$.subscribe((res: number) => {
      this.tabGroup.selectedIndex = res;
    })
  }

  searchMenuItem() {
    const searchValue = this.search_item.toLowerCase();

    if (!searchValue) {
      this.productItems = this.searchAllData;
      this.filteredCategoryItems = this.searchFilteredItems;
      this.dealCategoryItems = this.searchDealItems;
      return;
    }

    if (this.tab_name === 'All') {
      this.productItems = this.searchAllData.filter(item =>
        item.item.item_name.toLowerCase().includes(searchValue)
      );
    } else if (!(this.tab_name === 'All' || this.tab_name === 'Deal')) {
      this.filteredCategoryItems = this.searchFilteredItems.filter(item =>
        item.item.item_name.toLowerCase().includes(searchValue)
      );
    } else if (this.tab_name === 'Deal') {
      this.dealCategoryItems = this.searchDealItems.filter(item =>
        item.deal_name.toLowerCase().includes(searchValue)
      );
    }
  }

  newOrderPlaced() {
    if (!this.isOrderListEmpty) {
      this.toaster.warning(
        'You still have products in your cart. Your selection will get cleared. Are you sure you want to continue?', 'WARNING', {
        positionClass: 'toast-center-center',
        timeOut: 8000
      })
    } else {
      this.rmsService.orderTrayEmpty.next(true)
    }
  }
}

