import { BookingsByRoomType, RoomBooking, Booking } from "./BookingDetailsByRoom";


export class BookingDetailsByRoomTypes {
  private _bookingsByRoomTypes: BookingsByRoomType[] = [];
  public get bookingsByRoomTypes(): BookingsByRoomType[] {
    return this._bookingsByRoomTypes;
  }
  public set bookingsByRoomTypes(value: BookingsByRoomType[]) {
    this._bookingsByRoomTypes = value;
  }

  getByRoomTypeId(roomTypeId: number): BookingsByRoomType {
   return this.bookingsByRoomTypes.find((val) => val.roomTypeId === roomTypeId);
  }

  getByRoomTypeAndDate(roomTypeId: number, date: string): Booking[] {
    const roomBookings = this.getByRoomTypeId(roomTypeId).roomNumBookings;
    const roomBooking = roomBookings.find((val) => val.date === date);
    if(roomBooking === undefined) {
      return [];
    }
    return roomBooking.bookings;
  }

  getByRoomTypeAndDateAndRoomNo(roomTypeId: number, date: string, roomNoId: number): Booking {
    const bookings = this.getByRoomTypeAndDate(roomTypeId, date);
    return bookings.find((val) => val.room_no_id === roomNoId);
  }
}
