<div class="dash_window dashboard-card" [ngClass]="darkMode ? 'darkDashboard' : 'null'">
  <div class="win_header">
    <h4 class="heading "> Weather</h4>
  </div>
  <div id="box4 " class="box ">
    <div class="weather_bg w-clearfix side side{{ i+1 }} " *ngFor="let update_weather of weather_update; let i=index ">
      <div class="row ">
        <div class="col-md-8 hover_effect">
          <h1 class="weather-title ">{{ update_weather.title }}</h1>
          <h4 class="weather-sub-title ">{{ update_weather.moment.format('dddd DD MMMM')
            }}</h4>
          <div class="weather ">
            <h1 class="temp "><span class="weather-tm temp ">{{update_weather.min_temp}}</span>°</h1>
            <p class="weather_con "><span class="con_d ">{{update_weather.condition}}</span></p>
            <p><span class="weather-wind ">{{update_weather.wind}} km/h</span></p>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="weather-sky ">
            <i class='wi wi-main wi-{{ update_weather.icon }}'></i>
          </div>
        </div>
      </div>
      <table>
        <tr>
          <td *ngFor="let forcast of update_weather.forcast; let i=index " class="mon txt-day "
            [class]="weather_classes[i%weather_classes.length] ">
            {{ forcast.day.split(' ')[0] }}
          </td>
        </tr>
        <tr>
          <td *ngFor="let forcast of update_weather.forcast; let i=index " class="wi_icon icon mon_icon "
            [class]="weather_classes[i%weather_classes.length] ">
            <i class='wi wi-{{ forcast.icon.split()[0] }}'></i>
          </td>
        </tr>
        <tr>
          <td *ngFor="let forcast of update_weather.forcast; let i=index " class="mon_min "
            [class]="weather_classes[i%weather_classes.length] ">
            {{ forcast.min_temp }}°
          </td>
        </tr>
        <tr>
          <td *ngFor="let forcast of update_weather.forcast; let i=index " class="mon_max "
            [class]="weather_classes[i%weather_classes.length] ">
            {{ forcast.max_temp }}°
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>