<div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
  <div class="row">
    <div class="column-wrapper display-flex space-between">
      <div>
        <ul class="nav navbar-nav tabsBar" id="shopNames">
          <li *ngFor="let shop of shopNames">
            <a class="btn btn-link" (click)="switchShop(shop.shop_id)"
              [ngClass]="{ 'active': shop.shop_id === activeShopId }">{{shop?.shop_name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>