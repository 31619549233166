<div class="dash_window no_margin w-clearfix dashboard-card notifi" [ngClass]="darkMode ? 'darkDashboard' : 'null'">
  <div class="win_header ">
    <h4 class="heading ">Notifications
    </h4>
  </div>
  <div class="scroll no_scroll ">
    <div *ngFor="let ev of event_images_noti " class="hodilay-Class "
      [ngStyle]="{ 'background-image': 'url( https://uat.cgslhcm.com/pmsbackend_8/public/'+ev.image+ ')'} ">
      <span style="visibility: hidden; "> </span>

    </div>

    <div *ngFor="let noti of dashboard_noti?.user_keynotes;let i=index ">
      <div class="keynotes _1 w-clearfix " *ngIf="noti.keynote.is_holiday==0 "
        [ngStyle]="{ 'border-color': colors[i%colors.length]} ">
        <div class="noti_content_keynote w-clearfix ">
          <p class="keynote_noti_text_morelines "
            [ngStyle]="{ 'color': color_borders_dark[i%color_borders_dark.length]} ">
            {{noti.keynote.text}}</p>
        </div>
        <img src="assets/img/dashboard/darkmode/bell.svg " alt=" " class="keynote_icon ">
        <!-- <img src="assets/img/dashboard/noti_icons/red_noti.png " alt=" " class="keynote_icon "> -->
      </div>
      <div *ngIf="noti.keynote.is_holiday==1 " class="hodilay-Class "
        [ngStyle]="{ 'background-image': 'url(http://cm.com/pmsbackend/public/' +noti.keynote.gif_icon+ ')'} ">
        <span style="visibility: hidden; ">&nbsp;</span>

      </div>
    </div>
  </div>
</div>