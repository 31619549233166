import { Injectable, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { ApiService } from "./api.service";
import { ErrorHandlingService } from "./error-handling.service";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  def_id = 0;
  user_subject: Subject<any> = new Subject();
  mode = 'light';
  // private package_data = new BehaviorSubject<any>();
  // currentData = this.package_data.asObservable();
  @Output() change: EventEmitter<any> = new EventEmitter();

  constructor(
    private toaster: ToastrService,
    private api: ApiService,
    private error_handling: ErrorHandlingService
  ) {
    this.user_subject.subscribe(user => {
      this.mode = user.mode;
    });
  }

  gotoEdit() {
    this.change.emit(this.def_id);
    // console.log("Ef_id",this.def_id);
  }

  alert_success(msg) {
    let text = `<span class="success-icon"></span><span class="success-message">${msg}</span>`;
    this.toaster.success(text, '', { enableHtml: true, positionClass: 'toast-center-center', });
  }
  alert_warning(msg) {
    let text = `<span class="warning-icon"></span><span class="warning-message">${msg}</span>`;
    this.toaster.warning(text, '', { enableHtml: true, positionClass: 'toast-center-center', });
  }
  alert_error(msg) {
    let text = `<span class="error-icon"></span><span class="error-message">${msg}</span>`;
    this.toaster.error(text, '', { enableHtml: true, positionClass: 'toast-center-center', });
  }
  // get_packages_data(data:any)
  // { //var dt= data;
  //   // var property_id = localStorage.getItem("current_property");
  //   // this.api.post('api/package/index',{property_id:property_id},true).subscribe((res:any) => {
  //   //  data= res.body.data;
  //   // }, err => this.error_handling.handle_error(err.status, err.message));
  //   // this.package_data.next(data);
  // }

}
