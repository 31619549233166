<div class="financial-performance-bar-container border-bottom-gray" [ngClass]="darkMode ? 'dark_row' : 'null'">
    <div class="financial-performance-bar-label">
        <label class="hover_effect">
            {{data.label}}
        </label>
    </div>
    <div class="d-flex align-items-center flex-grow-1">
        <div class="w-100">
            <div class="animate-2s hover_effect" [ngStyle]="{'width':calculateBarWidth()+'%'}"
                style="background-color: #880488; height:3rem; border-radius: 12px;  box-shadow:0px 0px 60px rgba(0, 0, 0, 0.15);">
                <label
                    style="color:#eee; padding-left:10px; padding-top:6px; padding-bottom:5px; padding-right:5px; font-size: 14px;">{{currency_format}}
                    {{data.current | shortNumber}}</label>
            </div>
            <!-- Previous Month -->
            <div
                style="position:relative; width: -moz-available; width: -webkit-fill-available; width: fill-available; height:0;">
                <div [ngStyle]="{'margin-left':calculateIndicatorAdjustment()+'%'}"
                    style="position:absolute; top:-2.7em;" class="hover_effect">
                    <div class="previous-period-label-indicator"></div>
                    <div class="num" style="position: relative; left:-50%; bottom:5px">{{data.previous | shortNumber}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-row gap-1 align-items-center hover_effect" style="width:75px">
        <img *ngIf="data.change > 0" src="assets/img/newstaticicons/report/up-arrow-indicator.svg" />
        <img *ngIf="(data.change > 0) === false" src="assets/img/newstaticicons/report/down-arrow-indicator.svg" />
        <label>{{getChangePercentage() | number : '1.2-2'}}%</label>

    </div>
</div>