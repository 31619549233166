<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
  <!-- <a class="btn btn-link " href="" routerLink="/app/communication">Directory</a> -->
  <a class="btn btn-link active" href="" routerLink="/app/back_office/communication/emaildirectory">EMAIL</a>
  <a class="btn btn-link " href="" routerLink="/app/back_office/communication/emailtemplate">TEMPLATE</a>
  <div class="col-md-1 " style="float:right;padding-right: 5px"> <img src="assets/img/communications/Group 5701.png"
      class="bread-icon" style="cursor: pointer"> </div>
</div>
<div class="alert alert-info">
  <ul class="nav nav-inline nav-blue">
    <li class="li-width " style="border-left: none;width: 34.2%;"><a class="text-left">Subject</a></li>
    <li class="li-width"><a>To</a></li>
    <li class="li-width"><a>Date</a></li>
    <li class="li-width"><a>Time</a></li>
    <li class="li-width"><a>Reservation Id</a></li>
  </ul>
</div>
<div class="col-md-12 " style="padding: 0px">

<!-- skeleton -->
  <app-comm-skeleton *ngIf="g_templates.length == 0" [template]="false"></app-comm-skeleton>
<!-- skeleton end -->

  <div class="card" *ngIf="g_templates.length != 0" [ngClass]="darkMode ? 'card_dark2' : 'card'">
    <div id="drag-list">
      <div class="" style="margin-bottom: 4px" *ngFor="let g_templates of g_templates;let i = index">
        <div class="row field-space">
          <div class="col-md-12">
            <div class="border-style" [ngStyle]="{'border-color': colorArr[i%colorArr.length]}">
              <div class="element" data-toggle="modal" data-target="#myModal-2">
                <ul class="nav nav-inline" (click)="sent_items = g_templates;">
                  <li class="headBar1"> {{ g_templates?.template?.title }} </li>
                  <!-- <li class="headBar2" > {{ g_templates?.group?.name     }}   </li> -->
                  <li class="headBar2"> {{ getLogsTem(g_templates) }} </li>
                  <li class="headBar3"> {{ g_templates?.send_date }} </li>
                  <li class="headBar4"> {{ g_templates?.send_time }} </li>
                  <li class="headBar5"> 123test </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 action-btns">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="myModal-2" class="modal fade" role="dialog" style="width: 100%;">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content" style="background-color: #f1f1f1" [ngClass]="darkMode ? 'dark_popup' : 'null'">
      <div class="modal-header">
        <div class="bluheader booking-sumary dargmd" (mouseover)="dodrag('myModal-2')"
          (mouseout)="dontdrag('myModal-2')">
          <h3 class="h3_blu_header bookingsummary">Sent Email<button type="button" class="close" data-dismiss="modal"
              aria-label="Close" style="color: white;line-height: 34px">
              <strong id="cross">&#x2716;</strong>
            </button>
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-md-12 ">
          <div class="row" *ngIf="sent_items != undefined">
            <div class="col-md-8 zero-padding" style="padding-right: 5px;height: 447px">
              <div class="bluheader booking-sumary shows" style="padding-right:0px;padding-top: 0px"
                [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                <h3 class="h3_blu_header bookingsummary" style="float:left ;line-height: 50px">
                  Recipients </h3>
                <!-- <img src="assets/img/communications/plus-4.png" class="plus-img" width="30px" height="30px"> -->
                <div class="col-md-6 plus-div" id="plus-search" style="visibility: hidden;">
                  <div class="col-md-12" style="padding:0px" id="plus-search-box ">
                    <div class="col-md-12 " style="padding: 0px;">
                      <div class="col-md-12" style=" padding-left:10px;padding-right: 0px">
                        <input type="text" class="input-plus" placeholder="Name, Email" [(ngModel)]="query"
                          (keyup)="param = query">
                        <img src="assets/img/communications/plus-4.png" id="p-1" width="30px" height="30px"
                          style="cursor: pointer;margin-left: 5px;margin-top: -3px">
                      </div>
                      <div class="col-md-12 my-scroll-s">
                        <div class="col-md-12 s-result"
                          *ngFor="let g_directory of g_directory | search:'full_name,email':param">
                          <div class=" col-md-7 name-s" style="padding: 0px">{{
                            g_directory.full_name }}</div>
                          <div class=" col-md-5 email-s" style="padding: 0px">{{
                            g_directory.email }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="usersCardPopup">
                <div class="col-md-12 card zero-padding"
                  style="margin-top: -0px !important;padding-top: 0px !important;height: 240px;"
                  [ngClass]="darkMode ? 'card_dark' : 'card'">
                  <div class="col-md-12  zero-padding" *ngFor="let e of sent_items.logs">
                    <ul class="nav nav-inline nav-blue-4">
                      <li class="li-width-4 nm"><a>{{e.name}}</a></li>
                      <li class="li-width-4 em"><a>{{e.receiver_email}}</a></li>
                      <li class="li-width-4 tr">
                        <a>
                          <!--<i class="fa fa-trash-alt" [ngClass]="darkMode ? 'dark_delet' : 'null'"></i>--></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 zero-padding">
                  <div class="bluheader booking-sumary shows" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                    <h3 class="h3_blu_header bookingsummary">Templates & Schedule </h3>
                    <!---start tooltip--->
                    <span class="ispan">
                      <i class="fas fa-info-circle i" style="font-size: 15px;">
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            ( It gives you opportunity to send this mail on a designated
                            date )
                          </p>
                        </span>
                      </i>
                    </span>
                    <!---end tooltip--->
                  </div>
                </div>
                <div class="col-md-12 card" style="    margin-top: -0px !important;    padding-bottom: 0px !important;"
                  [ngClass]="darkMode ? 'card_dark' : 'card'">
                  <div class="col-md-12 zero-padding">
                    <ng-select class="form-control modal-custom input_2 padding-holder cmst"
                      placeholder="Select Template " [ngClass]="darkMode ? 'dark-drop' : 'null'" style="padding:0px;"
                      [(ngModel)]="sent_items.template.title">
                      <!-- <ng-option>We are looking forward to meeting you</ng-option>
                                  <ng-option>Your rooms are ready and waiting for you</ng-option>
                                  <ng-option>Why did you cancelled?</ng-option> -->
                    </ng-select>
                  </div>
                  <!-- <div class="col-md-12 zero-padding" style="margin-top: 5px;padding-left:20px" >
                               <label class="container" style="padding-left: 10px !important" >  <span class="schedule"> Schedule </span>  <span class="txt-0"> ( It gives you opportunity to send this mail on a designated date ) </span>
                               <input type="checkbox" [(ngModel)]="showhide" >
                               <span class="checkmark" style="    margin-top: 4px;margin-left: 8px;"></span>
                               </label>
                            </div> -->
                  <div class="col-md-12 zero-padding" style="margin-top: 5px;">
                    <div class="col-md-6 zero-padding" style="padding-right: 3px">
                      <div class="form-control input_2 background-gray padding-5 calendar-top-0 modal-bar-4 one"
                        style="background-color:#f1f1f1;padding-top: 0px !important;color: #00205b; padding:0px;">
                        <input type="hidden" name="date_to" style="padding: 10px;">
                        <input type="hidden" name="date_from" style="padding: 10px;">
                        <input type="text" ngxDaterangepickerMd class="form-control date_range_picker"
                          [autoApply]="true" placeholder="Date" [locale]="{format: 'DD MMM YYYY', separator: ' To '}"
                          [singleDatePicker]="true"
                          style="width: 100% !important; padding: 10px;color: #fff;  border-radius: 0px;"
                          [value]="sent_items.send_date">
                        <!-- <div *ngIf="submitted && f.date_from.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.date_from.errors.required">Checkin Date is required</strong>
                                     </div>
                                     <div *ngIf="submitted && f.date_to.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.date_to.errors.required">Checkout Date is required</strong>
                                     </div> -->
                      </div>
                    </div>
                    <div class="col-md-6 zero-padding input_2" style="padding-left: 2px">
                      <input type="text" placeholder="Time" class="modal-bar-4 two form-control" style="color: #fff"
                        [value]="sent_items.send_time">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 zero-padding " style="padding-bottom: 5px">
              <div class="col-md-12 zero-padding">
                <div class="box-head">
                  <span>Message</span>
                </div>
                <div class="box-content">
                  <input type="text" class="form-control email-txt "
                    style="border-radius: 0px;border: none;height: 550px;display: none">
                </div>
                <div class="box-foot" [ngxSummernote]="sConfig" [(ngModel)]="sent_items.template.html">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer my-foot" style="height: 447px">
      </div>
      <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
        <div class="col-md-2  zero-padding" style="float: right;">
          <div class="col-md-6 zero-padding" style="padding-right: 3px">
            <!--<button class="btn btn-block send-0 modal-btn saveSumNote" [ngClass]="darkMode ? 'btnblue_dark' : 'null'">Send</button> -->
          </div>
          <div class="col-md-6 zero-padding" style="padding-left: 2px"> <button
              class="btn btn-block close-0 modal-btn canSumNote" data-dismiss="modal" aria-label="Close"
              [ngClass]="darkMode ? 'btnred_dark' : 'null'">Close</button> </div>
        </div>
      </div>
    </div>
  </div>
</div>
