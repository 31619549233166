<div class="col-md-12 p-7">
    <div class="notification" [ngClass]="darkMode ? 'dark_bg_blue' : 'null'">
        <div class="row">
            <div class="col-md-12">
                <div class="greetings blue_heading">Subscription Plan<button aria-label="Close" class="close"
                        data-dismiss="modal" type="button" style="float: right;color: #fff"><strong>✖</strong></button>
                </div>
            </div>
        </div>
    </div>
    <div class="notification alert-danger" style="margin-bottom: 5px;" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
        <div class="row">
            <div class="col-md-10">
                <div class="greetings red_heading">Swtich to this Plan</div>
            </div>
        </div>
    </div>
    <div class="border-style pink-shade" [ngClass]="darkMode ? 'bardarK' : 'null'">
        <div class="row">
            <div class="col-md-5">
                <span class="sure_class">Are you sure you want to switch from Basic Plan to Ultimate Plan ?</span>
            </div>
            <div class="col-md-7 pr-30">
                <div class="radio_buttons">
                    <label class="control-label radio-style"><input class="radio" name="currency_conversion"
                            type="radio" value="Enabled"><span class="radio-checkmark"></span><span class="title"
                            style="padding-right: 25px;">Continue next billing cycle</span></label>
                    <label class="control-label radio-style"><input class="radio" name="currency_conversion"
                            type="radio" value="Enabled"><span class="radio-checkmark"></span><span class="title">Start
                            billing from this day</span></label>
                </div>
            </div>
        </div>


    </div>
    <div class="" style="float: right;display: inline-flex;margin-top: 10px;">
        <button class="btn btn-primary btn-lg btnhov" type="submit"
            [ngClass]="darkMode ? 'dark_bg_blue' : 'btn-primary'">Save</button>
        <a aria-label="Close" class="btn btn-danger btn-lg btnhov" data-dismiss="modal"
            [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Cancel</a>
    </div>
</div>