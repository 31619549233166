<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
  <!-- <a class="btn btn-link " href="" routerLink="/app/communication">Directory</a> -->
  <a class="btn btn-link " href="" routerLink="/app/back_office/communication/emaildirectory">EMAIL</a>
  <a class="btn btn-link active" href="" routerLink="/app/back_office/communication/emailtemplate">TEMPLATE</a>
  <div class="col-md-1 " style="float:right;padding-right: 5px">
    <!-- <img src="assets/img/communications/plus-4.png" class="bread-icon" style="" data-toggle="modal" data-target="#myModal-4"> -->
  </div>
</div>

<div class="alert alert-info">
  <ul class="nav nav-inline nav-blue">
    <li class="li-width " style="border-left: none;width: 46.5%;"><a class="text-left">Subject</a></li>
    <li class="li-width"><a>To</a></li>
    <li class="li-width"><a>Date</a></li>
    <!-- <li class="li-width"><a >Action</a></li> -->
  </ul>
</div>

<!-- Email Templates -->

<!-- skeleton -->
<app-comm-skeleton *ngIf="skeleton" [template]="true"></app-comm-skeleton>
<!-- skeleton end -->

<div class="col-md-12 zero-padding card" [ngClass]="darkMode ? 'card_dark2' : 'card'"
  style="padding-bottom: 0px !important;" *ngIf="listitem && !skeleton">
  <div id="drag-list" *ngFor="let tempIndex of listitem; let bi = index">
    <div class="" style="margin-bottom: 4px">
      <div class="row field-space">
        <div class="col-md-12">
          <div class="border-style border-color-green" [ngStyle]="{'border-color': tempBorder[bi] }">
            <div class="element ">
              <ul class="nav nav-inline">
                <li class="" style="width: 49%;text-align: left ;cursor: pointer">{{tempIndex.title}}</li>
                <li class=" li-width-2" style="cursor: pointer"> {{tempIndex.heading}} </li>
                <li class=" li-width-2" style="cursor: pointer">{{tempIndex.updated_at| date}}</li>
                <!-- <li class=" li-width-2">
                           <i class="fa fa-edit" data-toggle="modal" data-target="#myModal-4" (click)="getTemplate(tempIndex);getId(tempIndex.id)"></i>
                           &nbsp;&nbsp;&nbsp;
                           <i class="fa fa-trash-alt" (click)="delete(tempIndex.id,tempIndex.title)" data-toggle="modal" data-target="#confirmationModal-2"></i>
                        </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Email Templates -->

<!-- Create Template -->
<div id="myModal-4" class="modal fade" role="dialog" style="width: 100%;">
  <form [formGroup]="email_template">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" [ngClass]="darkMode ? 'dark_popup' : 'null'">
        <div class="modal-header">
          <div class="bluheader booking-sumary dargmd" (mouseover)="dodrag('myModal-4')"
            (mouseout)="dontdrag('myModal-4')">
            <h3 class="h3_blu_header bookingsummary">Create Template
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                style="color: white;line-height: 34px" (click)="onClose()">
                <strong id="cross">&#x2716;</strong>
              </button>
            </h3>
          </div>
        </div>
        <div class="modal-body" id="m-b">
          <div class="col-md-12 zero-padding card" style="margin-top: -0px!important"
            [ngClass]="darkMode ? 'card_dark' : 'card'">
            <div class="col-md-6 zero-padding" style="padding-right: 2px">
              <div class="modal-bar m-1">
                <input type="text" class="form-control" placeholder="Title"
                  style="padding-left: 10px; border-radius: 0px;" formControlName="title">
              </div>
              <div *ngIf="submitted && f.title.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.title.errors.required">Title is required</strong>
              </div>
            </div>
            <div class="col-md-6 zero-padding" style="padding-left: 3px">
              <div class="modal-bar m-2">
                <ng-select class="form-control modal-custom input_2 padding-holder cmst" [items]="groups"
                  bindLabel="name" bindValue="id" formControlName="email_group_id" placeholder="For "
                  style="height: 40px;padding:0px; border:none;background-color: #f1f1f1"
                  [ngClass]="darkMode ? 'dark-drop' : 'null'">
                </ng-select>
              </div>
              <div *ngIf="submitted && f.heading.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.heading.errors.required"> Day type is required</strong>
              </div>
            </div>
            <div class="col-md-12 zero-padding">
              <div class="message">
                <span>Message</span>

                <!---start tooltip--->
                <span class="ispan">
                  <i class="fas fa-info-circle i" style="font-size: 15px;">
                    <span class="tooltiptext" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                      <p class="small" style="padding: 0px !important; margin: 0px !important;">
                        Create Emal and Drag your images
                      </p>
                    </span>
                  </i>
                </span>
                <!---end tooltip--->

              </div>
              <div class="m-foot" fromControlName="html" [ngxSummernote]="sConfig" [(ngModel)]="notes"
                [ngModelOptions]="{standalone: true}">
              </div>
              <div *ngIf="submitted && f.html.errors" class="small text-danger no-margin invalid-feedback"
                style="margin-top:10px;">
                <strong *ngIf="f.html.errors.required">Email Template is required</strong>
              </div>
            </div>
            <div class="col-md-12 zero-padding" style="margin-top: 14px">
              <div class="col-md-3 zero-padding" style="padding-right: 2px">
                <div class="modal-bar m-3">
                  <ng-select class="form-control modal-custom input_2 padding-holder cmst" [items]="shdule_arr"
                    bindLabel="booking" bindValue="booking" formControlName="schedule" placeholder="Schedule"
                    style="height: 40px;padding:0px;border:none;background-color: #f1f1f1"
                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                  </ng-select>
                </div>

                <div *ngIf="submitted && f.schedule.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.schedule.errors.required">schedule is required</strong>
                </div>

              </div>
              <div class="col-md-3 zero-padding" style="padding-left: 3px;padding-right: 2px">
                <div class="modal-bar m-4">
                  <ng-select class="form-control modal-custom input_2 padding-holder cmst"
                    formControlName="send_type_day" placeholder="Days Before "
                    style="height: 40px;padding:0px;border:none;background-color: #f1f1f1"
                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                    <ng-option value="Days Before">Days Before</ng-option>
                    <ng-option value="Days After">Days After</ng-option>
                  </ng-select>
                </div>
                <div *ngIf="submitted && f.send_type_day.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.send_type_day.errors.required"> Day type is required</strong>
                </div>
              </div>
              <div class="col-md-3 zero-padding" style="padding-left: 3px">
                <div class="modal-bar m-5">
                  <input type="number" class="form-control" formControlName="number_of_days"
                    placeholder="Number of days" style="padding-left: 10px; border-radius: 0px;">
                </div>
                <div *ngIf="submitted && f.number_of_days.errors" class="small text-danger no-margin invalid-feedback">
                  <strong *ngIf="f.number_of_days.errors.required">Number of Days is required</strong>
                </div>
              </div>
              <div class="col-md-3 zero-padding" style="padding-top: 7px">
                <label class="container" style="margin-left: 15px;margin-top: 4px">
                  <span class="sent-auto"> Send Automatically </span>
                  <input type="checkbox" formControlName="send_type">
                  <span class="checkmark" style="    margin-top: 2px;margin-left: 8px;"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer my-foot">
        </div>
        <div class="col-md-12" style="padding-right: 0px;margin-top: 5px">
          <div class="col-md-2  zero-padding" style="float: right;">
            <div class="col-md-6 zero-padding" style="padding-right: 3px">
              <button class="btn btn-block send-0 modal-btn" (click)="getTemplate('new')"
                [ngClass]="darkMode ? 'btnblue_dark' : 'null'" *ngIf="!edit">Save</button>
              <button class="btn btn-block send-0 modal-btn" (click)="getTemplate('editForm')"
                [ngClass]="darkMode ? 'btnblue_dark' : 'null'" *ngIf="edit">Save</button>
            </div>
            <div class="col-md-6 zero-padding" style="padding-left: 2px">
              <button class="btn btn-block close-0 modal-btn" data-dismiss="modal" aria-label="Close"
                [ngClass]="darkMode ? 'btnred_dark' : 'null'" (click)="onClose()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- Create Template -->

<!-- Delete Template -->
<div id="myModal-5" class="modal fade" role="dialog"
  style="margin: 0;position: absolute;top: 55%;-ms-transform: translateY(-50%);transform: translateY(-50%); width:100%;">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content" style="background-color: #f1f1f1">
      <div class="modal-header" style="padding-bottom:5px ; border: none;">
        <div class="col-md-12">
          <div class="row">
            <div class="bluheader booking-sumary">
              <h3 class="h3_blu_header bookingsummary">Email Template</h3>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="confrm-card">
              <div class="col-md-7 " style="height: 50px; ">
                <div class="row" style="padding-right: 5px">
                  <div class="col-md-12 undo-modal">
                    <div class="row">
                      <div class="col-md-3 text-right">
                        <i class="fa fa-exclamation-triangle"></i>
                      </div>
                      <div class="col-md-9" style="padding-left: 0px">
                        <p class="undo">Are you sure you want to delete this template <br> This action can
                          not be undone </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5" style="height: 50px">
                <div class="row">
                  <div class="col-md-6" style="padding-left: 0px; padding-right: 3px"> <button
                      class="btn btn-md btn-block save-btn">Continue</button> </div>
                  <div class="col-md-6" style="padding-left: 2px; padding-right:0px"> <button
                      class="btn btn-md btn-block close-btn" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete Template END-->
