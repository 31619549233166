<!-- Main header and Filter Bar -->
<div class="filter-box mr-bottom" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null-null-null '">
  <form class="form-inline">
    <div class="row no-gutters">
      <div class="col-md-12 dFlex">
        <div class="col-md-1 filters_col" style="padding: 0px 5px;">
          <button class="btn-house btn_hov" (click)="reset()">Reset</button>
        </div>
        <div class="col-md-2 filters_col">
          <div class="main-div">
            <div class="f-div ripple" *ngIf="allHouseData != undefined"
              (click)="filterRoomsByCondition('dirty', undefined)" style="cursor: pointer;">
              {{ countDC.dirty }}
              <hr class="border" />
              <span class="romdH">Rooms Dirty</span>
            </div>
            <div class="sec-div" *ngIf="allHouseData != undefined">
              <div class="TopFill" (click)="filterRoomsByCondition('dirty', 'occupied')">
                <span style="cursor: pointer;">
                  {{ countDC.dirty_occupied }} Occupied
                </span>
              </div>
              <hr class="hr-cen" />
              <div class="TopFill" (click)="filterRoomsByCondition('dirty', 'checked out')">
                <span style="cursor: pointer;">
                  {{ countDC.dirty_vacant }} vacant
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 filters_col">
          <div class="main-div mr-left">
            <div class="f-div ripple" *ngIf="allHouseData != undefined" style="cursor: pointer;"
              (click)="filterRoomsByCondition('cleaned', undefined)">
              {{ countDC.cleaned }}
              <hr class="border" />
              <span class="romdH">Rooms Cleaned</span>
            </div>
            <div class="sec-div" *ngIf="allHouseData != undefined">
              <div class="TopFill" (click)="filterRoomsByCondition('cleaned', 'occupied')">
                <span style="cursor: pointer;">
                  {{ countDC.clean_occupied }} Occupied
                </span>
              </div>
              <hr class="hr-cen" />
              <div class="TopFill" (click)="filterRoomsByCondition('cleaned', 'available')">
                <span style="cursor: pointer;">
                  {{ countDC.cleaned_vacant }} vacant
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-2 filters_col" style="padding: 0px 5px;">
          <ng-select
            *ngIf="chartValue == 2"
            [items]="roomtypes"
            id="idCompare"
            class="form-control input_2 padding-holder orangSelect chkDrop"
            [clearable]="'true'"
            [multiple]="true"
            bindLabel="name"
            bindValue="id"
            [(ngModel)]="selectedRoom"
            [ngModelOptions]="{ standalone: true }"
            (change)="selectTopRoom(selectedRoom)"
            [closeOnSelect]="false"
            placeholder="Select Room Type"
          >
            <ng-template ng-multi-label-tmp let-items="items">
              <div class="ng-value">
                <span class="ng-value-label"
                  >{{ items[0].roomtype }} {{ items.length - 1 }} more</span
                >
              </div>
            </ng-template>

            <ng-template
              ng-option-tmp
              let-item="item"
              let-item$="item$"
              let-index="index"
            >
              <input
                id="item-{{ index }}"
                name="item-{{ index }}"
                type="checkbox"
                [ngModel]="item$.selected"
                [disabled]="selectedRoom.length == 2 ? true : false"
              />
              {{ item.roomtype }}
            </ng-template>
          </ng-select>
        </div> -->

        <div class="col-md-2 pl-0 pr-10 filters_col" style="padding-left: 5px;"><input class="search-box"
            placeholder="Room no" type="text" id="roomnum"><span class="go_icon" (click)="go()">Go</span>
        </div>

        <div class="col-md-2 filters_col" style="padding: 0px 5px;">
          <ng-select class="form-control input_2 padding-holder orangSelect chkDrop" placeholder="Select shift"
            (change)="shifts_change($event)">
            <ng-option value="Morning">Morning</ng-option>
            <ng-option value="Evening">Evening</ng-option>
            <ng-option value="Night"> Night</ng-option>

          </ng-select>


        </div>

                <div class="col-md-1 filters_col">
                    <ng-select class="form-control input_2 padding-holder" [items]="chart" bindLabel="name"
                        (ngModel)="(selectedCharts)" ng-init="chart[0].name" placeholder="List View"
                        (change)="selectedCharts($event)">
                    </ng-select>
                </div>
                <div class="col-md-1 filters_col" style="padding: 0px 5px 0px 3px; padding-right: 2px;">
                    <button class="btn-house btn_hov" (click)="getRoomDetails()">
                        Schedule
                    </button>
                </div>
                <div class="col-md-1 filters_col" style="padding: 0px 5px 0px 3px; padding-right: 9px;">
                    <button class="btn-house btn_hov" data-toggle="modal" data-target="#popup-second">
                        Maintenance
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- Main header and Filter Bar END-->

<!-- Table Main View -->
<div *ngIf="chartValue == 1">
  <div class="filter-box" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null-null-null '">
    <form class="form-inline">
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="col-md-2 filters_col" style="padding: 0px 5px;" *ngIf="showBuildings">
            <ng-select [(ngModel)]="filterBuilding" name="filterBuilding" placeholder="Select Building"
              [items]="propertyIndex" bindLabel="name" bindValue="id" class="form-control input_2"
              (change)="filterB(filterBuilding)">
            </ng-select>
          </div>
          <div class="col-md-2 filters_col" [ngStyle]="{ padding: showBuildings ? '' : '0px 3px 0px 5px' }">
            <ng-select [(ngModel)]="filterRoom" name="filterRoom" class="form-control input_2" placeholder="Filter By"
              (change)="filterCon(filterRoom)" [items]="filterConMainT" bindLabel="name" bindValue="name">
            </ng-select>
          </div>
          <!-- <div class="col-md-2 filters_col" *ngIf="!showBuildings"></div> -->
          <div class="col-md-2 filters_col" style="padding-left: 2px;">
            <div *ngIf="conditionHide == 'room'">
              <ng-select [(ngModel)]="filterChannel" name="filterChannel" [items]="selectConditionWisrArray"
                bindLabel="room.roomtype.name" bindValue="room_id" class="form-control input_2"
                (change)="filterConCon(filterChannel)" placeholder="Select Room Type" value="Select Room Type">
              </ng-select>
            </div>
            <ng-select [(ngModel)]="filterChannel" name="filterChannel" [items]="selectConditionWisrArray"
              bindLabel="condition" bindValue="condition" class="form-control input_2"
              (change)="filterConCon(filterChannel)" placeholder="Select Condition" value="Select Condition"
              *ngIf="conditionHide == 'condition'"></ng-select>
            <!-- <ng-select
              [(ngModel)]="filterChannel"
              name="filterChannel"
              [items]="selectConditionWisrArray"
              bindLabel="status"
              bindValue="status"
              class="form-control input_2"
              (change)="filterConCon(filterChannel)"
              placeholder="Select Status"
              value="Select Status"
              *ngIf="conditionHide == 'status'"
            ></ng-select>
            <ng-select
              [(ngModel)]="filterChannel"
              name="filterChannel"
              [items]="selectConditionWisrArray"
              bindLabel="user.first_name"
              bindValue="user_id"
              class="form-control input_2"
              (change)="filterConCon(filterChannel)"
              placeholder="Select User"
              value="Select User"
              *ngIf="conditionHide == 'assigned'"
            ></ng-select> -->
          </div>
          <!-- <div class="col-md-2 pl-0 pr-10 filters_col" style="padding-left: 5px;">
               </div> -->
          <!-- <div class="col-md-2 filters_col" *ngIf="!showBuildings"></div> -->
          <div class="{{
              labelCol ? 'col-md-6' : 'col-md-8'
            }} pr-10 display mr-col pd-left"
            style="padding-left: 0% !important;display: flex; justify-content: flex-end;">
            <div class="box1"></div>
            <span class="pd-right">Cleaned</span>
            <!-- <div class="box"></div>
            <span class="pd-right">Cleaning</span> -->
                        <div class="box2"></div>
                        <span class="pd-right">Dirty</span>
                        <div class="box3"></div>
                        <span class="pd-right">Maintenance</span>
                        <div class="box4"></div>
                        <span class="pd-right">Do Not Disturb</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="tab-content">
        <div class="card0" [ngClass]="darkMode ? 'card-0-dark' : 'null'">
            <div id="all_arrivals" class="tab-pane fade in active">
                <div class="card-table table-responsive" style="padding-top: 5px;margin-top: 5px;">
                    <ul class="customUL" [ngClass]="showBuildings ? 'calc7' : 'calc6'">
                        <li class="head-left">
                            <a>
                                <div class="th-border">Room</div>
                            </a>
                        </li>
                        <li *ngIf="showBuildings">
                            <a>
                                <div class="th-border">Building</div>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="th-border">Condition</div>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="th-border">Status</div>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="th-border">Shift</div>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="th-border">Assigned</div>
                            </a>
                        </li>
                        <li>
                            <a>
                                <div class="th-border">
                                    Start<span class="left-right">|</span>Finish
                                </div>
                            </a>
                        </li>
                        <li style="border-right:none" class="head-right" colspan="2">
                            <a><span class="note-pd">Note</span></a>
                        </li>
                    </ul>
                    <div>
                        <div id="drag-list" [ngClass]="showBuildings ? 'calc7' : 'calc6'">
                            <div id="dragListTr" class="customUl2  {{
                  makeLowerCase(houseKindex.condition)
                }}-border" style="cursor: grab" *ngFor="let houseKindex of housekeepingIndex; index as i"
                                [ngClass]="darkMode ? 'dark-border-div di00' : 'null'">
                                <div>
                                    <a>{{ houseKindex?.room_number }} </a>
                                </div>
                                <div *ngIf="showBuildings">
                                    <a>{{ houseKindex?.building_id }}</a>
                                </div>
                                <div>
                                    <a><button class="button-condition {{
                        makeLowerCase(houseKindex.condition)
                      }}" style="margin-top: -5px;" data-toggle="modal" data-target="#popup-dirty"
                      (click)="houseindex(houseKindex)">
                      {{ houseKindex?.condition }}
                    </button></a>
                </div>
                <div>
                  <a>{{ houseKindex?.status }}</a>
                </div>
                <div>
                  <a>{{
                    houseKindex.assigned_info == ""
                    ? "Not Assign"
                    : houseKindex.assigned_info[0].shift
                    }}</a>
                </div>

                <div>
                  <a><span [ngClass]="
                        houseKindex.assigned_info == '' ? 'not-assign-name' : ''
                      ">{{
                      houseKindex.assigned_info == ""
                      ? "Not Assigned"
                      : houseKindex.assigned_info[0].first_name
                      }}</span></a>
                </div>
                <div>
                  <a class="time_pad">08:00<span class="left-right">|</span>09:50</a>
                </div>
                <div>
                  <a>
                    <!-- <div class="noteRoom" data-toggle="tooltip" title="{{houseKindex.note}}"> -->
                    <!-- {{houseKindex?.note}} -->
                    <!-- </div> -->
                  </a>
                  <!-- <a><img src="assets/img/housekeeping/image_icon.png" class="img-table" alt=""
                        data-toggle="modal" data-target="#popup-third" (click)="modalImage(houseKindex.id)"
                        *ngIf="houseKindex.images.length > 0"></a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="pagi">
        <div class="pagination text-center" style="margin-left: 45%;">
          <div style="float: left;">
            <ul class="nav">
              <li class="hovPrev" (click)="firstpage()">
                <a><i class="fa fa-angle-double-left"></i></a>
              </li>
            </ul>
          </div>
          <div style="float: left;">
            <ul class="nav">
              <li class="hovPrev">
                <a><i class="fa fa-angle-left" (click)="prev(selectedIndex2)"></i></a>
              </li>
            </ul>
          </div>
          <div style="float: left;" class="cuspagidiv" id="cuspagidiv">
            <div *ngFor="let numbers of pagi2; let i = index; let last = last" class="page-item cusPagi"
              [class.active]="selectedIndex2 == i" (click)="selectedIndex2 = i"
              (click)="paginationPage(numbers.url, numbers.page_no)">
              <a>{{ numbers.page_no }}</a>
            </div>
          </div>
          <div style="float: left;">
            <ul class="nav">
              <li class="hovPrev">
                <a><i class="fa fa-angle-right" (click)="next(selectedIndex2)"></i></a>
              </li>
            </ul>
          </div>
          <div style="float: left;">
            <ul class="nav">
              <li class="hovPrev">
                <a><i class="fa fa-angle-double-right" (click)="lastpage()"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Table Main View END-->

<!-- Calander Main View -->
<div *ngIf="chartValue == 2">

    <div id="scroll_wrapper" class="scroll no-scroll" (scroll)="onTableScroll($event)">
        <div class="grid_calendar">

            <table class="table"
            *ngIf="inventory_calendar && inventory_calendar.length !== 0">
                <thead>
                    <tr class="heigh">
                        <th>
                            <div class="basic_block inventory_grid">
                                <div class="inventory_wrapper">
                                    <h4 class="inventory_text">INVENTORY'S&nbsp;GRID</h4>
                                </div>
                                <h4 class="room_type_text">ROOM&nbsp;TYPE</h4>
                            </div>
                        </th>

            <th *ngFor="let range of calendar_range; let i = index">
              <div class="basic_block days" *ngIf="
                        range.isBetween(
                          current_view.startDate,
                          current_view.endDate,
                          null,
                          '[]'
                        )
                      ">
                <h3 class="days_month">{{ range.format('MMMM') }}</h3>
                <h3 class="day_number">{{ range.format('DD') }}</h3>
                <h3 class="days_day">{{ range.format('dddd') }}</h3>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>


        </tbody>
        <ng-container>
          <tr *ngFor="let range of calendar_range; let i = index">
            <td>
              <div class="package w-clearfix">

                <div class="RN">
                  <span class="rm-font">&nbsp;</span>
                  <div class="restriction_panel">

                    <img src="assets/img/newstaticicons/inventory/allowwheel.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 17px;">
                    <img src="assets/img/newstaticicons/inventory/allaowdog.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 25px;">
                    <img src="assets/img/newstaticicons/inventory/allowsmoke.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 25px;">
                  </div>
                </div>
                <div class="div-block-5 w-clearfix">
                  <h4 class="package_title_main package-styling">
                    Room Number
                    <br>
                  </h4>
                </div>
                <h4 class="package_room_code over-ride-wit">
                  <span class="rm-font">FL </span>
                  <span class="rm-font">Bulding</span>
                </h4>
              </div>
            </td>
            <td *ngFor="let range of calendar_range; let i = index">
              <div class="box">
                <div class="box_left">&nbsp;</div>
                <div class="box_right">&nbsp;</div>
                <hr>
                <div class="box_lines">
                  <div class="upper"></div><br>
                  <div class="lower"></div>
                </div>
                <hr>
                <div class="box_bar"></div>
              </div>

            </td>

          </tr>

        </ng-container>





      </table>

            <table class="table" *ngIf="inventory_calendar && inventory_calendar.length !== 0">
                <thead>
                    <tr class="heigh" [ngClass]="darkMode ? 'darkModeE' : ' card-null-null-null '">
                        <th>
                            <div class="basic_block inventory_grid"
                                [ngClass]="darkMode ? 'card-black' : ' card-null-null-null '">
                                <!-- <h4 class="calendar_text" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">CALENDAR</h4> -->
                                <div class="inventory_wrapper"
                                    [ngClass]="darkMode ? 'card-black-text-bor' : ' card-null '">
                                    <h4 class="inventory_text" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                                        INVENTORY'S&nbsp;GRID
                                    </h4>
                                </div>
                                <h4 class="room_type_text" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                                    ROOM&nbsp;TYPE
                                </h4>
                            </div>
                        </th>
                        <th *ngFor="let range of calendar_range; let i = index">
                            <div class="basic_block days" *ngIf="
                    range.isBetween(
                      current_view.startDate,
                      current_view.endDate,
                      null,
                      '[]'
                    )
                  " [ngClass]="darkMode ? 'card-black' : ' card-null-null-null '">
                <h3 class="days_month" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                  {{ range.format("MMMM") }}
                </h3>
                <h3 class="day_number" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                  {{ range.format("DD") }}
                </h3>
                <h3 class="days_day" [ngClass]="darkMode ? 'card-black-text' : ' card-null '">
                  {{ range.format("dddd") }}
                </h3>
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngFor="
              let inv of inventory_calendar;
              trackBy: trackByInv;
              let i = index
            ">
          <tr class="drag_item heigh" *ngFor="let ven of inv; trackBy: trackByVen; let indonia = index"
            [ngClass]="darkMode ? 'darkModeE' : ' card-null-null-null '">
            <td>
              <div class="package w-clearfix">
                <div class="RN">
                  <span class="rm-font">&nbsp;</span>
                  <div class="restriction_panel" style="float: right;">
                    <img src="assets/img/newstaticicons/inventory/allowwheel.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 17px;"
                      *ngIf="ven?.room.wheelchairaccessibility == 'true'" />
                    <img src="assets/img/newstaticicons/inventory/allaowdog.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 25px;" *ngIf="ven?.room.pets == 'true'" />
                    <img src="assets/img/newstaticicons/inventory/allowsmoke.svg" alt="allaowdog"
                      class="restriction-padding fl-r" style="width: 25px;" *ngIf="ven?.room.smoking == 'true'" />
                  </div>
                </div>
                <div class="div-block-5 w-clearfix">
                  <h4 class="package_title_main package-styling" style="cursor: pointer;">
                    {{ ven?.room?.roomtype?.name }}<br />
                  </h4>
                </div>
                <h4 class="package_room_code">RM: {{ ven?.room_number }}</h4>
              </div>
            </td>
            <td *ngFor="
                  let range of calendar_range;
                  trackBy: trackByBox.bind(ven.id);
                  let indo = index
                ">
              <div *ngIf="
                    range.isBetween(
                      current_view.startDate,
                      current_view.endDate,
                      null,
                      '[]'
                    );
                    else blankBox
                  ">
                <div *ngIf="
                      is_box_shown(ven?.boxes, range) != false &&
                        is_box_shown(ven?.boxes, range) as box;
                      else placeBox
                    ">
                  <div class="box_wrapper hov" [ngClass]="
                        darkMode
                          ? 'card-dark-btn-g-btns hov_dark'
                          : ' card-null-null-null hov'
                      ">
                    <div class="skyblue-package" *ngIf="box.room_status == 'Vacant'">
                      <div class="font-awesom" [ngClass]="
                            darkMode ? 'font-color1' : ' card-null-null-null'
                          ">
                        <span class="font-color" style="color: #000">Available
                        </span>
                        <i class="fas fa-edit f-right" style="cursor: pointer"
                          (click)="dropClick(ven?.room_number + '' + indo)"></i>
                      </div>
                      <ul class="navv visibility" role="tablist" #navdrop{{indo}} id="{{ven?.room_number+''+indo}}">
                        <!-- <div class="div" *ngFor="let info of  staffList;"> -->
                        <ng-select name="Selected" placeholder="Assign Staff " class="form-control stafff"
                          [items]="staffList" bindLabel="first_name" bindValue="id" [(ngModel)]="Selected"
                          (change)="assign_staff(box.date,ven.id,Selected)">
                        </ng-select>
                        <!-- [(ngModel)]="selectStafff" -->
                        <!-- <li>{{info.first_name}}</li> -->
                        <!-- </div> -->
                      </ul>
                      <div class="div-block-4 w-clearfix">
                        <h4 class="package_title_main package-styling" [ngClass]="
                              darkMode ? 'font-color1' : ' card-null-null-null'
                            ">
                          <span class="font-color not-assign">
                            Not Assigned</span>
                          <span *ngFor="let info of ven.assigned_info">
                            <span class="font-color assign" *ngIf="info.shift == shift">
                              {{ info.first_name }}</span>
                          </span>
                        </h4>
                      </div>
                      <h4 class="package_room_code top-pd">
                        <span class="font-color">
                          <img src="assets/img/housekeeping/{{
                                darkMode ? 'dirtydark' : 'dirty'
                              }}.svg" style="width: 20px;" *ngIf="box.condition == 'dirty'" />
                          <img src="assets/img/newstaticicons/inventory/{{
                                darkMode ? 'darkmode/cleaneddark' : 'cleaned'
                              }}.svg" style="width: 20px;" *ngIf="box.condition == 'cleaned'" />
                        </span>
                        <span class="time">{{ shift }}</span>
                      </h4>
                    </div>

                    <div class="green-package" *ngIf="box.room_status == 'Occupied'">
                      <div class="font-awesom" [ngClass]="
                            darkMode ? 'font-color1' : ' card-null-null-null'
                          ">
                        <span class="font-color">
                          <img src="assets/img/newstaticicons/inventory/{{
                                darkMode ? 'darkmode/blackperson' : 'whiteperson'
                              }}.svg" style="width: 15px;" />
                          {{ box?.booking_details?.guest_firstname }}
                        </span>
                        <i class="fas fa-edit f-right"></i>

                      </div>
                      <div class="div-block-4 w-clearfix">
                        <h4 class="package_title_main package-styling" [ngClass]="
                              darkMode ? 'font-color1' : ' card-null-null-null'
                            ">
                          <span class="font-color not-assign">
                            Not Assigned</span>
                          <span *ngFor="let info of ven.assigned_info">
                            <span class="font-color assign" *ngIf="info.shift == shift"
                              style="background-color:#25bb43 ;">
                              {{ info.first_name }}</span>
                          </span>
                        </h4>
                      </div>
                      <h4 class="package_room_code top-pd">
                        <span class="font-color">
                          <img src="assets/img/newstaticicons/inventory/{{
                                darkMode ? 'darkmode/cleaneddark' : 'cleaned'
                              }}.svg" style="width: 20px;" *ngIf="box.condition == 'cleaned'" />
                          <img src="assets/img/housekeeping/{{
                                darkMode ? 'dirtydark' : 'dirty'
                              }}.svg" style="width: 20px;" *ngIf="box.condition == 'dirty'" />
                        </span>
                        <span class="time">{{ box.date }}</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <ng-template #placeBox style="background-color: #21b9e1;">
                  <div class="box" style="background-color: #21b9e1;">
                    <div class="box_left">&nbsp;</div>
                    <div class="box_right">&nbsp;</div>
                    <hr />
                    <div class="box_lines">
                      <div class="upper"></div>
                      <br />
                      <div class="lower"></div>
                    </div>
                    <hr />
                    <div class=""></div>
                  </div>
                </ng-template>
              </div>
              <ng-template #blankBox>
                <!-- <div class="blankBox">&nbsp;</div> -->
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>


    </div>
  </div>
</div>
<!-- Calander Main View -->

<!-- Schedule Popup -->
<div id="popup-first" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" style="width: 100%;">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document">
    <div class="modal-content bg-modal" style="background-color: #f3f3f3" [ngClass]="darkMode ? 'popup-top' : 'null'">
      <div class="modal-body">
        <div class="alert alert-info alert-dismissible show" role="alert" (mouseover)="dodrag('popup-first')"
          (mouseout)="dontdrag('popup-first')">
          Housekeeping Staff
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <strong id="cross">&#x2716;</strong>
          </button>
        </div>
        <div class="alert alert-danger breadcrum mr-bot" style="height: 45px;margin-bottom: 5px;"
          [ngClass]="darkMode ? 'card-dark-top-nav' : 'null'">
          <ul class="nav navbar-nav">
            <li [ngClass]="display ? 'active' : ''">
              <a data-toggle="tab" href="#schedule" (click)="display = true" class="btn btn-link active">SCHEDULE</a>
            </li>
            <li [ngClass]="display ? '' : 'active'">
              <a data-toggle="tab" href="#schedule" (click)="display = false" class="btn btn-link active">ATTENDENCE</a>
            </li>
          </ul>
          <div class="col-md-1" style="float: right">
            <img src="assets/img/inventory/download-icon.png"
              style="height: 33px;margin-top: 5px;float: right;margin-right: 3px;cursor: pointer" (click)="makePDF()" />
          </div>
          <div class="row">
            <div class="col-md-9 col-sm-12 col-xs-12" *ngIf="!display"
              style="float: right;padding-left:5px;padding-right: 5px; padding-top: 6px;"></div>
          </div>
        </div>
        <form [formGroup]="schedule_form" (ngSubmit)="saveSchedule()" *ngIf="display">
          <details class="details-sum">
            <summary>
              Assign House Keeper
              <span style="float: right;margin-right: 1%;">
                <img src="assets/img/reports/button_down_dark.svg" style="width: 20px;" id="AssHoKee"
                  (click)="chngeRo('AssHoKee')" />
              </span>
            </summary>

            <div>
              <div class="filter-box mr-tp" [ngClass]="
                  darkMode ? 'card-dark-top-nav' : ' card-null-null-null '
                ">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <div class="col-md-2 filters_col" style="padding: 0px 5px;">
                      <ng-select name="SelectStaff" placeholder="Assign Staff " class="form-control input_2"
                        [items]="staffList" bindLabel="first_name" bindValue="id" (change)="selectStf(selectStaff)"
                        [(ngModel)]="selectStaff" formControlName="vendor_id">
                      </ng-select>
                      <div *ngIf="scheduleSubmit && sf.vendor_id.errors"
                        class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="sf.vendor_id.errors.required">Select Vendor</strong>
                      </div>
                    </div>
                    <div class="col-md-2 filters_col">
                      <ng-select name="selectbuilding" placeholder="Assign Building" class="form-control input_2"
                        [items]="buildingList" bindLabel="name" bindValue="id" (change)="selectBuid(selectbuilding)"
                        [(ngModel)]="selectbuilding" formControlName="building_id">
                      </ng-select>
                      <div *ngIf="scheduleSubmit && sf.building_id.errors"
                        class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="sf.building_id.errors.required">Select Building</strong>
                      </div>
                    </div>
                    <div class="col-md-2 filters_col" style="padding-left: 5px;">
                      <ng-select name="selectfloor" placeholder="Assign floor" class="form-control input_2"
                        [items]="floorList" bindLabel="floor_no" bindValue="id" (change)="selectflr(selectfloor)"
                        [(ngModel)]="selectfloor" formControlName="floor_id">
                      </ng-select>
                      <div *ngIf="scheduleSubmit && sf.floor_id.errors"
                        class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="sf.floor_id.errors.required">Select Floor</strong>
                      </div>
                    </div>
                    <div class="col-md-2 filters_col filter-boxx" style="padding-left: 5px;">
                      <ng-select class="form-control input_2 selec" placeholder="Assign Room Type" [items]="roomList"
                        bindLabel="room_number" bindValue="id" (change)="selectRm(selectroom)" [(ngModel)]="selectroom"
                        [multiple]="true" [closeOnSelect]="false" formControlName="room_data">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{ index }}" type="checkbox" [(ngModel)]="item$.selected"
                            [ngModelOptions]="{ standalone: true }" /> {{ item.room_number
                          }}
                        </ng-template>
                      </ng-select>
                      <div *ngIf="scheduleSubmit && sf.room_data.errors"
                        class="small text-danger no-margin invalid-feedback">
                        <strong *ngIf="sf.room_data.errors.required">Select Rooms</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-left: -15px;margin-right: -15px;">
                <div class="col-md-12">
                  <div class="col-md-4" style="padding: 5px 5px 0px 0px;">
                    <ng-select class="form-control" placeholder="Shift"
                      style="background: transparent; border:none;color: black;padding: 0px;margin: 0px;border-radius: 8px; border-width: 0px 8px 0px;border-style: solid;border-color: #86819f;height: 40px; "
                      [items]="shifts" bindValue="name" bindLabel="name" (change)="selectShft(selectShift)"
                      [(ngModel)]="selectShift" formControlName="shift" [ngClass]="darkMode ? 'jumbo1' : 'null'">
                    </ng-select>
                    <div *ngIf="scheduleSubmit && sf.shift.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="sf.shift.errors.required">Select Shift</strong>
                    </div>
                  </div>
                  <div class="col-md-7 pd-col">
                    <div class="border-6" [ngClass]="darkMode ? 'jumbo1' : 'null'">
                      <div class="Days">
                        <span class="days-color" style="text-align: center;font-weight: bold;"
                          [ngClass]="darkMode ? 'darkcol' : 'null'">Days</span>
                        <ul class="no-style">
                          <li class="inline" *ngFor="let d of days; let i = index">
                            <label class="control-label checkbox-style">
                              <input type="checkbox" class="checkbox" [value]="d.name" [checked]="d.value"
                                (change)="checkDays($event, i)" />
                              <span class="checkbox-checkmark" [ngClass]="darkMode ? 'check_dark' : 'null'"></span>
                              <span class="title" [ngClass]="darkMode ? 'check_text' : 'null'">{{ d?.slug
                                }}</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1 filters_col" style="padding: 0px 5px;margin-left: 0px; margin-top: 5px;">
                    <button class="btn-house btn_hov btn_save" type="submit()">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-12">
               <div class="col-md-4" style="padding: 5px 5px 0px 0px;">
                  <ng-select class="form-control" placeholder="Shift" style="background: transparent; border:none;color: black;padding: 0px;margin: 0px;border-radius: 8px; border-width: 0px 8px 0px;border-style: solid;border-color: #86819f;height: 40px; "
                  [items]="shifts" bindValue="name" bindLabel="name" (change)="selectShft(selectShift)" [(ngModel)]="selectShift" formControlName="shift">
                  </ng-select>
                  <div *ngIf="scheduleSubmit && sf.shift.errors" class="small text-danger no-margin invalid-feedback">
                     <strong *ngIf="sf.shift.errors.required">Select Shift</strong>
                  </div>
               </div>
               <div class="col-md-7 pd-col">
                  <div class="border-6">
                     <div class="Days">
                        <span class="days-color" style="text-align: center;font-weight: bold;">Days</span>
                        <ul class="no-style">
                           <li class="inline" *ngFor="let d of days; let i = index;">
                              <label class="control-label checkbox-style">
                                 <input type="checkbox" class="checkbox" [value]="d.name" [checked]="d.days" (change)="checkDays($event, i)">
                                 <span class="checkbox-checkmark"></span>
                                 <span class="title">{{d?.slug}}</span>
                              </label>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="col-md-1 filters_col" style="padding: 0px 5px;margin-left: 0px; margin-top: 5px;">
                  <button class="btn-house btn_hov btn_save" type="submit()">Save</button>
               </div>
            </div> -->
          </details>
        </form>

        <div *ngIf="display" id="schedule" class="tab-pane fade in active">
          <div class="card-table table-responsive" *ngFor="let vd of scheduleDetails; let vb = index">
            <table class="arrivals-table table capture" id="t3" [ngClass]="darkMode ? 'dark-dd' : 'null'">
              <thead style="border-spacing:0 10px;">
                <tr>
                  <th width="25%" class="head-left" style="position:unset;">
                    <div class="th-border" style="width: 468px;">Name</div>
                  </th>
                  <th width="25%">
                    <div class="th-border" style="width: 468px;">Cell No</div>
                  </th>
                  <th width="28%">
                    <div class="th-border" style="width: 468px;">
                      <ul class="weak">
                        <li class="dis-day">Mo</li>
                        <li class="dis-day">Tu</li>
                        <li class="dis-day">We</li>
                        <li class="dis-day">Th</li>
                        <li class="dis-day">Fr</li>
                        <li class="dis-day">Sa</li>
                        <li class="dis-day">Su</li>
                      </ul>
                    </div>
                  </th>
                  <th width="22%" class="head-right">
                    <div class="">Shift</div>
                  </th>
                </tr>
              </thead>
              <tbody id="drag-list" class="popup-color">
                <tr>
                  <td colspan="4" class="arr-both green" [ngClass]="darkMode ? 'jumbo1' : 'null'">
                    <table width="100%">
                      <tr class="height-tr override-table">
                        <td width="24%">{{ vd?.user?.name_with_rate }}</td>
                        <td width="25%">{{ vd?.user?.phone }}</td>
                        <td width="28%">
                          <div class="th-border">
                            <ul class="weak">
                              <li class="dis" *ngFor="
                                  let element of vd?.user?.vendorinfo?.days
                                ">
                                <div class="check-div" *ngFor="let item of element | keyvalue"
                                  [ngClass]="darkMode ? 'chk-ddiv' : 'null'">
                                  <div class="check-div-inner" *ngIf="item.value">
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </td>
                        <td style="width: 21%;">
                          <span class="pd-night">{{
                            vd?.user?.vendorinfo?.shift
                            }}</span>
                          <div data-toggle="collapse" [attr.data-target]="'.demos' + vb"
                            style="float: right;padding-right: 10px;cursor: pointer;">
                            <img src="assets/img/reports/{{
                                darkMode ? 'button_down_dark' : 'button_down'
                              }}.svg" class="" alt="dropdown image" style="height: 20px;" id="rot{{ vb }}"
                              (click)="chngeRo('rot' + vb)" />
                          </div>
                        </td>
                      </tr>
                      <tr class="demo auto collapse demos{{ vb }}">
                        <td colspan="4" class="divider" style="height:auto;">
                          <div style="width: 100%;border-bottom:2px solid #b7b7b7;"></div>
                        </td>
                      </tr>
                      <tr class="no-shadow demo demos{{ vb }} collapse" id="forCollap" *ngFor="let bd of vd?.buliding">
                        <td colspan="4" class="pd-td">
                          <div class="buiding_bar">
                            <a href="" class="build_name">{{ bd?.name }} </a>
                            <div class="color_pickers">
                              <div class="bowr_apper">
                                <div class="box1"></div>
                                <span>Assigned</span>
                              </div>
                              <div class="bowr_apper">
                                <div class="boxb"></div>
                                <span>Available Rooms</span>
                              </div>
                            </div>
                          </div>
                          <!------------------------------------------------------------Slider area <start>---------------------------------------------------------------------->
                          <!-- <div class="slider_wrapper">

                                    </div> -->
                          <div class="room_wrapper" id="parent">
                            <table id="child" *ngFor="let fd of bd?.floors.reverse()">
                              <tr>
                                <td style="position: unset;">
                                  <div class="fl_box">
                                    <div class="inner_box">
                                      <span>{{ fd?.floor_no }} Floor</span>
                                    </div>
                                  </div>
                                </td>
                                <td *ngFor="let rd of fd?.roomnums">
                                  <div class="room_box" *ngIf="
                                      vd?.user?.id == rd?.assigned_info?.id
                                    " style="background-color: #00D839">
                                    <div class="vend-name">
                                      <span>{{
                                        rd?.assigned_info?.first_name
                                        }}</span>
                                    </div>

                                    <div class="inner_box_room">
                                      <span>{{ rd?.room_number }}</span>
                                      <!-- <span *ngIf="vd?.user?.name_with_rate  == rd?.assigned_info?.first_name">{{ rd?.assigned_info?.shift}}</span> -->
                                      <span class="overlap" *ngIf="
                                          vd?.user?.id == rd?.assigned_info?.id
                                        ">
                                        <strong (click)="cross(vd)" id="cross">&#x2716;</strong>
                                        <!-- <strong id="tick">&#10003;</strong> -->
                                      </span>
                                    </div>
                                  </div>
                                  <div class="room_box" *ngIf="
                                      vd?.user?.id != rd?.assigned_info[0]?.id &&
                                      rd?.assigned_info[0]?.id != null
                                    " style="background-color: rgb(225, 155, 50)">
                                    <div class="vend-name">
                                      <span>Assigned</span>
                                    </div>

                                    <div class="inner_box_room">
                                      <span>{{ rd?.room_number }} </span>
                                      <!-- <span *ngIf="vd?.user?.name_with_rate  == rd?.assigned_info?.first_name">{{ rd?.assigned_info?.shift}}</span> -->
                                      <span class="overlap">
                                        <strong (click)="cross(rd)" id="cross">&#x2716;</strong>
                                        <!-- <strong id="tick">&#10003;</strong> -->
                                      </span>
                                    </div>
                                  </div>

                                  <div class="room_box" *ngIf="
                                      vd?.user?.id != rd?.assigned_info[0]?.id &&
                                      rd?.assigned_info[0]?.id == null
                                    " style="background-color: #21b9e1">
                                    <div class="vend-name">
                                      <span>Not Assigned</span>
                                    </div>

                                    <div class="inner_box_room">
                                      <span>{{ rd?.room_number }} </span>
                                      <!-- <span *ngIf="vd?.user?.name_with_rate  == rd?.assigned_info?.first_name">{{ rd?.assigned_info?.shift}}</span> -->
                                      <span class="overlap">
                                        <!-- <strong (click)="cross(rd)" id="cross">&#x2716;</strong> -->
                                        <strong id="tick">&#10003;</strong>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!------------------------------------------------------------Slider area <end>---------------------------------------------------------------------->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Attendence popup work -->
        <div *ngIf="!display" id="attendence" class="card tab-pane fade in active capture"
          [ngClass]="darkMode ? 'card-0-dark' : 'null'">
          <table style="width: 100%">
            <thead>
              <tr>
                <th class="tth" *ngFor="let d of attendanceDate" [ngClass]="darkMode ? 'card-0-dark' : 'null'">
                  <div class="head-col">
                    {{ d.date | date: "EE d, MMM " }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody [ngClass]="darkMode ? 'tbledark' : 'null'">
              <tr *ngFor="let ab of attendanceBox">
                <td class="td-style" *ngFor="let b of ab?.boxes" [ngClass]="darkMode ? 'card-0-dark' : 'null'">
                  <div class="inner-div" *ngIf="b?.day_status" [ngStyle]="{
                      background:
                        b.shift == 'Night'
                          ? '#bf0c3d'
                          : b.shift == 'Morning'
                          ? '#00d839'
                          : b.shift == 'Evening'
                          ? '#00a8ff'
                          : '#c0c7cb'
                    }">
                    <span class="inner-text">{{ b?.name }}</span>
                    <span class="inner-shift">{{ b?.shift }}</span>
                  </div>

                  <div class="inner-div" *ngIf="!b?.day_status" [ngStyle]="{ background: '#c0c7cb' }">
                    <span class="inner-text">{{ b?.name }}</span>
                    <span class="inner-shift">Off</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button class="btn btn-danger btn-lg modal-btn" data-dismiss="modal" aria-label="Close"
      style="line-height: 1px;margin-right: 10px;" [ngClass]="darkMode ? 'hot' : 'null'">
      Close
    </button>
  </div>
</div>
<!-- Schedule Popup END -->

<!-- Maintanace Popup -->
<div id="popup-second" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" style="width: 100%;">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document"
    style=" width:600px; height: 365px; ">
    <div class="modal-content bg-modal" style="width:600px;" [ngClass]="darkMode ? 'popup-top' : 'darkOff'">
      <div class="modal-body modal_scroll">
        <div>
          <div class="alert alert-danger breadcrum show" style="margin-bottom: 0px;height: 45px"
            (mouseover)="dodrag('popup-second')" (mouseout)="dontdrag('popup-second')"
            [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null-null-null '">
            <ul class="nav navbar-nav">
              <li class="active">
                <a data-toggle="tab" href="#inhouse" (click)="display = true" class="btn btn-link active">IN HOUSE</a>
              </li>
              <li>
                <a data-toggle="tab" href="#vendor" class="btn btn-link" (click)="display = false">EXTERNAL VENDORS</a>
              </li>
            </ul>
            <!-- <img src="assets/img/housekeeping/download-icon.png" class="img-size" alt="" > -->
            <div class="row">
              <div class="col-md-9 col-sm-12 col-xs-12" style="float: right;padding-left:5px;padding-right: px">
                <div class="col-md-12 b-filters">
                  <div class="col-md-1"></div>
                  <div class="col-md-2 filters_col" style="padding: 0px;padding-right: 5px;margin-top: 5px"></div>
                </div>
              </div>
            </div>
          </div>

          <!--in House vendor <Start>--->
          <div class="row" style="padding-left: 20px;padding-right: 20px;">
            <form [formGroup]="in_house_form" (ngSubmit)="onSubmit()">
              <div *ngIf="display" id="inhouse" class="col-md-12" style="padding-right: 0px;padding-left: 0px">
                <div class="col-md-12 pd-0">
                  <div class="card card-height" style="margin-top: 0px !important;" [ngClass]="
                      darkMode ? 'card-0-dark' : ' card-null-null-null '
                    ">
                    <div class="div-head main-summry1">
                      <span class="head-text">Assign Trade</span>
                      <span class="head-info">
                        <img src="assets/img/housekeeping/drop_icon.svg" data-toggle="collapse" data-target="#collapse1"
                          style="float: right;cursor: pointer; height: 20px;" id="AssHoKee2"
                          (click)="chngeRo('AssHoKee2')" />
                      </span>
                    </div>
                    <div class="row" style="margin-left: 0px;margin-right: 0px;">
                      <div id="collapse1" class="col-md-12 pd-0 collapse in">
                        <div style="padding-top: 5px;">
                          <div class="col-md-12 pd-0" [ngClass]="darkMode ? 'jumbo' : 'null'">
                            <div class="border-style color-55 bd-color color_select">
                              <ng-select class="form-control" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                placeholder="Select" [items]="trade" bindValue="name" bindLabel="name"
                                formControlName="name" [(ngModel)]="selectMaintenence" (change)="
                                  getName(selectMaintenence, 'In House')
                                ">
                              </ng-select>
                            </div>
                            <div *ngIf="maintenenceSubmit && m.name.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="m.name.errors.required">Select Vendor Type
                                Required</strong>
                            </div>

                            <div class="border-style color-55 bd-color color-name" style="margin-top: 5px;">
                              <ng-select class="form-control" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                placeholder="Name" [items]="traders" bindValue="id" bindLabel="name_with_rate"
                                formControlName="vendor_id" [(ngModel)]="selectName">
                              </ng-select>
                            </div>
                            <div *ngIf="maintenenceSubmit && m.vendor_id.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="m.vendor_id.errors.required">Select Vendor
                                Required</strong>
                            </div>

                            <div class="Flex">
                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_Building">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Building" [items]="building" bindValue="id" bindLabel="name"
                                    formControlName="building_id" [(ngModel)]="selectBuilding"
                                    (change)="getFloor(selectBuilding)">
                                  </ng-select>
                                </div>
                                <div *ngIf="
                                    maintenenceSubmit && m.building_id.errors
                                  " class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="m.building_id.errors.required">Select
                                    Building Required</strong>
                                </div>
                              </div>

                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_floor">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Floor" [items]="floors" bindValue="id" bindLabel="floor_no"
                                    formControlName="floor_id" [(ngModel)]="selectFloor" (change)="
                                      getRoom(selectFloor, selectBuilding)
                                    ">
                                  </ng-select>
                                </div>
                                <div *ngIf="maintenenceSubmit && m.floor_id.errors"
                                  class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="m.floor_id.errors.required">Select
                                    Floor Required</strong>
                                </div>
                              </div>

                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_room">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Room No" [items]="rooms" bindValue="id" bindLabel="room_number"
                                    formControlName="room_no_id" [(ngModel)]="selectRoom">
                                  </ng-select>
                                </div>
                                <div *ngIf="
                                    maintenenceSubmit && m.room_no_id.errors
                                  " class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="m.room_no_id.errors.required">Select
                                    Room Required</strong>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 pd-0" style="margin-top: 5px;" [ngClass]="darkMode ? 'jumbo' : 'null'">
                            <div class="border-style color-55 bd-color color_date">
                              <input type="text" ngxDaterangepickerMd
                                class="form-control date_range_picker arrival-lenght pick" [(ngModel)]="m_calendar_date"
                                placeholder="Select Date" [singleDatePicker]="true" [autoApply]="true"
                                (change)="calendar_change(m_calendar_date)" [locale]="{
                                  format: 'DD, MMMM YYYY',
                                  separator: '  To  '
                                }" formControlName="start_date" />
                            </div>
                            <div *ngIf="maintenenceSubmit && m.start_date.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="m.start_date.errors.required">Select Date
                                Required</strong>
                            </div>

                            <div class="border-style color-55 bd-color-2 color_issue bac">
                              <input class="input form-control" style="outline: none;" type="text" placeholder="Issue"
                                formControlName="issue" />
                            </div>
                            <div *ngIf="maintenenceSubmit && m.issue.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="m.issue.errors.required">Issue
                                Required</strong>
                            </div>
                          </div>
                          <button class="btn-blue modal-btn elect_bg" style="font-size: 14px !important;" type="submit"
                            [ngClass]="darkMode ? 'cold' : ''">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!--in House vendor <end>--->
        </div>

        <!--External vendor <Start>--->
        <div class="row" style="padding-left: 20px;padding-right: 20px;">
          <form [formGroup]="external_form" (ngSubmit)="onExternalSubmit()">
            <div *ngIf="!display" id="vendor">
              <div class="col-md-12" style="padding-right: 0px; padding-left: 0px;">
                <div class="col-md-12 pd-0">
                  <div class="card card-height" style="margin-top: 0px !important; margin-left: 0px !important;"
                    [ngClass]="darkMode ? 'card-0-dark' : 'null'">
                    <div class="div-head main-summry1" [ngClass]="darkMode ? 'gradient-bar' : 'null'">
                      <span class="head-text">Assign Trade</span>
                      <span class="head-info">
                        <img src="assets/img/housekeeping/drop_icon.svg" data-toggle="collapse" data-target="#collapse1"
                          style="float: right;cursor: pointer; height: 20px;" id="AssHoKee1"
                          (click)="chngeRo('AssHoKee1')" />
                      </span>
                    </div>
                    <div class="row" style="margin-left: 0px;margin-right: 0px;">
                      <div id="collapse1" class="col-md-12 pd-0 collapse in">
                        <div style="padding-top: 5px;">
                          <div class="col-md-12 pd-0" [ngClass]="darkMode ? 'jumbo' : 'null'">
                            <div class="border-style color-55 bd-color color_select1">
                              <ng-select class="form-control" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                placeholder="Select" [items]="trade" bindValue="name" bindLabel="name"
                                formControlName="name" [(ngModel)]="selectMaintenence" (change)="
                                  getName(selectMaintenence, 'External')
                                ">
                              </ng-select>
                            </div>
                            <div *ngIf="maintenenceSubmit && em.name.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="em.name.errors.required">Select Vendor Type
                                Required</strong>
                            </div>

                            <div class="border-style color-55 bd-color color-name1" style="margin-top: 5px;">
                              <ng-select class="form-control" [ngClass]="darkMode ? 'bg_back' : 'bg_white'"
                                placeholder="Name" [items]="traders" bindValue="id" bindLabel="name_with_rate"
                                formControlName="vendor_id" [(ngModel)]="selectName">
                              </ng-select>
                            </div>
                            <div *ngIf="maintenenceSubmit && em.vendor_id.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="em.vendor_id.errors.required">Select Vendor
                                Required</strong>
                            </div>

                            <div class="Flex">
                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_Building1">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Building" [items]="building" bindValue="id" bindLabel="name"
                                    formControlName="building_id" [(ngModel)]="selectBuilding"
                                    (change)="getFloor(selectBuilding)">
                                  </ng-select>
                                </div>
                                <div *ngIf="
                                    maintenenceSubmit && em.building_id.errors
                                  " class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="em.building_id.errors.required">Select
                                    Building Required</strong>
                                </div>
                              </div>

                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_floor1">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Floor" [items]="floors" bindValue="id" bindLabel="floor_no"
                                    formControlName="floor_id" [(ngModel)]="selectFloor" (change)="
                                      getRoom(selectFloor, selectBuilding)
                                    ">
                                  </ng-select>
                                </div>
                                <div *ngIf="
                                    maintenenceSubmit && em.floor_id.errors
                                  " class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="em.floor_id.errors.required">Select
                                    Floor Required</strong>
                                </div>
                              </div>

                              <div class="trade_container">
                                <div class="border-style color-55 bd-color-2 color_room1">
                                  <ng-select class="form-control" [ngClass]="
                                      darkMode ? 'bg_back' : 'bg_white'
                                    " placeholder="Room No" [items]="rooms" bindValue="id" bindLabel="room_number"
                                    formControlName="room_no_id" [(ngModel)]="selectRoom">
                                  </ng-select>
                                </div>
                                <div *ngIf="
                                    maintenenceSubmit && em.room_no_id.errors
                                  " class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="em.room_no_id.errors.required">Select
                                    Room Required</strong>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12 pd-0" style="margin-top: 5px;" [ngClass]="darkMode ? 'jumbo' : 'null'">
                            <div class="border-style color-55 bd-color color_date1">
                              <input type="text" ngxDaterangepickerMd
                                class="form-control date_range_picker arrival-lenght pick" placeholder="Select Date"
                                [singleDatePicker]="true" [autoApply]="true"
                                (change)="ecalendar_change(em_calendar_date)" [locale]="{
                                  format: 'DD, MMMM YYYY',
                                  separator: '  To  '
                                }" formControlName="start_date" />
                            </div>
                            <div *ngIf="maintenenceSubmit && em.start_date.errors"
                              class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="em.start_date.errors.required">Select Date
                                Required</strong>
                            </div>

                            <div class="border-style color-55 bd-color-2 color_issue1">
                              <input class="input form-control" style="outline: none;" type="text" placeholder="Issue"
                                formControlName="issue" />
                              <div *ngIf="maintenenceSubmit && em.issue.errors"
                                class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="em.issue.errors.required">Issue
                                  Required</strong>
                              </div>
                            </div>
                          </div>
                          <button class="btn-blue modal-btn elect_bg" style="font-size: 14px !important;" type="submit"
                            [ngClass]="darkMode ? 'cold' : ''">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!--External vendor <End>--->
      </div>
      <button class="btn btn-danger btn-lg modal-btn" data-dismiss="modal" aria-label="Close"
        style="line-height: 1px;margin-right: 10px;" [ngClass]="darkMode ? 'hot' : ''">
        Close
      </button>
    </div>
  </div>
</div>
<!-- Maintanace Popup End -->
<!-- dirty Popup -->
<div id="popup-dirty" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" style="width: 100%;">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document"
    style=" width:600px; height: 365px; ">
    <form [formGroup]="rooms_status" (ngSubmit)="room_status()">
      <div class="modal-content bg-modal" style="width:600px;" [ngClass]="darkMode ? 'popup-top' : 'darkOff'">
        <div class="modal-body modal_scrolll" style="overflow:visible !important">
          <div>
            <!--in House vendor <Start>--->
            <div class="row" style="padding-left: 20px;padding-right: 20px;">
              <div *ngIf="display" id="inhouse" class="col-md-12" style="padding-right: 0px;padding-left: 0px">
                <div class="col-md-12 pd-0">
                  <div class="card card-height" style="margin-top: 0px !important;" [ngClass]="
                      darkMode ? 'card-0-dark' : ' card-null-null-null '
                    ">
                    <div class="div-head main-summry1">
                      <span class="head-text">Room No</span>
                      <!-- <span class="head-info">
                                    <img src="assets/img/housekeeping/drop_icon.svg" data-toggle="collapse"
                                       data-target="#collapse1" style="float: right;cursor: pointer; height: 20px;"
                                       id="AssHoKee2" (click)="chngeRo('AssHoKee2')">
                                 </span> -->
                      <button type="button" class="close11" data-dismiss="modal" aria-label="Close">
                        <strong id="cross">&#x2716;</strong>
                      </button>
                    </div>

                    <div class="row" style="margin-left: 0px;margin-right: 0px;">
                      <div id="collapse1" class="col-md-12 pd-0 collapse in">
                        <div style="padding-top: 5px;">
                          <div class="col md-12 pd-0">
                            <div class="col-md-12 pd-0" style="display: none;">
                              <div class="border-style color-55 bd-color color_select" style="margin-right: 5px;">
                                <input class="form-control" style="padding-left: 0;"
                                  [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="" formControlName="id"
                                  value="{{ housekeepingID?.id }}" />
                              </div>
                            </div>

                            <div class="col-md-6 pd-0" [ngClass]="darkMode ? 'jumbo' : 'null'">
                              <div class="border-style color-55 bd-color color_select" style="margin-right: 5px;">
                                <ng-select class="form-control" style="padding-left: 0;"
                                  [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="Condition"
                                  [(ngModel)]="condition" [items]="Condition" bindValue="id" bindLabel="name"
                                  formControlName="condition" (change)="chckstatus($event)">
                                </ng-select>
                              </div>

                              <div *ngIf="rs.condition.errors" class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="rs.condition.errors.required">Required</strong>
                              </div>
                            </div>

                            <div class="col-md-6 pd-0" [ngClass]="darkMode ? 'jumbo' : 'null'">
                              <div class="border-style color-55 bd-color color-name">
                                <ng-select class="form-control" style="padding-left: 0;"
                                  [ngClass]="darkMode ? 'bg_back' : 'bg_white'" placeholder="Status" [items]="status"
                                  bindValue="name" bindLabel="name" formControlName="status" [(ngModel)]="selectName">
                                </ng-select>
                              </div>
                            </div>
                          </div>

                          <!-- <button class="btn-blue modal-btn elect_bg" style="font-size: 14px !important;"
                                          type="submit" [ngClass]="darkMode ? 'cold' : ''">Submit</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--External vendor <Start>--->

          <!--External vendor <End>--->
        </div>
        <button class="btn btn-danger btn-lg modal-btn" data-dismiss="modal" aria-label="Close"
          style="line-height: 1px;margin-right: 5px;z-index: -999 !important" [ngClass]="darkMode ? 'hot' : ''">
          Close
        </button>
        <button class="btn-blue modal-btn elect_bg"
          style="font-size: 14px !important;z-index: -999 !important; margin-right: 5px !important;" type="submit"
          [ngClass]="darkMode ? 'cold' : ''">
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
<!-- dirty Popup End -->

<!-- Image Slider -->
<div id="popup-third" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" *ngIf="my_object">
  <div class="modal-dialog modal-dialog-centered modal-lg modal-width" role="document" style="width:700px;">
    <div class="modal-content bg-modal" style="background: #595b5d; width:700px;">
      <div class="modal-body model-body2">
        <button type="button" class="close1" data-dismiss="modal" aria-label="Close">
          <strong id="cross">&#x2716;</strong>
        </button>
        <div class="row">
          <div id="myCarousel" class="carousel slide" data-ride="carousel">
            <!-- Indicators -->
            <ol class="carousel-indicators">
              <li *ngFor="let restImages of my_object.images; let i = index" data-target="#myCarousel" class="clip"
                [ngClass]="i == 0 ? 'active' : null" attr.data-slide-to="{{ i }}">
                <img src="http://cm.com/pmsbackend/public/app/images/{{
                    restImages?.image
                  }}" alt="" class="small-img" (click)="img_preview(restImages?.image)" />
              </li>
            </ol>
            <!-- Wrapper for slides -->
            <div class="carousel-inner">
              <div class="item act" *ngFor="let restImages of my_object.images; let pi = index"
                [ngClass]="pi == 0 ? 'active' : null">
                <img src="http://cm.com/pmsbackend/public/app/images/{{restImages?.image}}" alt="" class="big-img" />
              </div>
            </div>
            <!-- Left and right controls -->
            <a class="left carousel-control" href="#myCarousel" data-slide="prev"
              style="height: 458px; border-radius: 10px;">
              <span class="glyphicon glyphicon-chevron-left ico"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="right carousel-control" href="#myCarousel" data-slide="next"
              style="height: 458px; border-radius: 10px;">
              <span class="glyphicon glyphicon-chevron-right ico"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Image Slider End-->
