<div class="container-fluid">
  <div class="row">
    <div id="login-section" class="col-md-5">
      <div class="row">

        <div class="col-md-offset-2 col-md-8">
          <div class="text-center">
            <a class="logo btn">
              <img src="../../../assets/img/logo.png" alt="">
            </a><br>
            <small class="sub-title">CREATE ACCOUNT TO</small>
            <h3 class="title no-margin">Property Management System</h3><br>
            <div class="alert alert-success" *ngIf="successMessage != null">
              <a class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{successMessage}}
            </div>
            <div class="alert alert-danger" *ngIf="errorMessage != null">
              <a class="close" data-dismiss="alert" aria-label="close">&times;</a>
              {{errorMessage}}
            </div>
          </div>


          <form method="POST" novalidate #form="ngForm" class="form-horizontal">

            <label class="control-label cg-label">User Name</label>
            <input type="text" name="uname" class="form-control cg-input" [(ngModel)]="uname" placeholder="" required
              pattern="^[A-Za-z0-9@.]+$" />
            <br>
            <label class="control-label cg-label">Email</label>
            <input type="text" name="email" class="form-control cg-input" [(ngModel)]="email" placeholder="" required
              pattern="^[A-Za-z0-9@.]+$" />
            <br>
            <label class="control-label cg-label">Password</label>
            <!-- <input type="password" name="pass" class="form-control cg-input" [(ngModel)]="pass" required minlength="6"> -->
            <input type="password" name="pass" class="form-control cg-input" placeholder="" [(ngModel)]="pass"
              required />
            <br>

            <label class="control-label cg-label">Confirm Password</label>
            <!-- <input type="password" name="pass" class="form-control cg-input" [(ngModel)]="pass" required minlength="6"> -->
            <input type="password" name="cpass" class="form-control cg-input" placeholder="" [(ngModel)]="cpass"
              required />
            <br>
            <!-- <label>
                  <input type="checkbox" name="remember" class="cg-checkbox no-margin" [(ngModel)]="remember"/>
                  <span class="remember-email">Remember Email</span>
                </label> -->
            <br>
            <!-- <button class="btn btn-info btn-block cg-btn login-btn" type="submit" routerLink="login"(click)="login(form)">LOGIN</button> -->
            <button class="btn btn-info btn-block cg-btn registration-btn" type="submit">CREATE</button>
            <br>
            <p class="create-p">Already you have an account &nbsp; <a class="create-a" routerLink="">Sign-In</a></p>
            <!-- <p class="create-p">Purchasing for work? &nbsp; <a class="create-a"  routerLink="registration">CREATE BUSINESS ACCOUNT</a></p> -->
            <!-- <button class="btn btn-info btn-block cg-btn registration-btn" type="submit" (click)="registration(form)">REGISTRATION</button> -->
            <!-- <a class="btn btn-block cg-btn-blank" routerLink="forgot_password">Forgot password</a> -->
            <!-- <a class="btn btn-block cg-btn-blank" routerLink="reset-password/MeCgvUpOlrqymen2OcjqLWoV0DpgeCRbKmLeLn4UUfksTWj6x1LP4fnBKrwR">Reset password</a> -->
            <!-- <a class="btn btn-block cg-btn-blank confirm-box" (click)="confirmBox()" data-toggle="modal" data-target="#confirmationModal">Test</a> -->
          </form>
          <br><br><br>

          <div class="footer">
            <div class="row">
              <div class="col-md-6">
                <small>Copyright {{ current_date }} CGSL</small>
              </div>
              <div class="col-md-6 text-right">
                <small><a>Contact Support</a></small>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-7 login-bg"></div>
  </div>
</div>

<app-confirmation-alert>

</app-confirmation-alert>