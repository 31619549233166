import { shopBreakDownModel } from 'src/app/common/pnl/PnlDataModel';
import { HelperService } from './../../../services/helper.service';
import { PnlService } from './../../../services/pnl.service';
import { Component, OnInit } from '@angular/core';
import { start } from 'repl';

@Component({
  selector: 'app-profitandloss',
  templateUrl: './profitandloss.component.html',
  styleUrls: ['./profitandloss.component.css']
})
export class ProfitandlossComponent implements OnInit {

  a = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1,]
  darkMode: boolean = false;
  currency_format = localStorage.getItem('currency');
  // Revenue: RevenueModel;
  earningFromRooms: number
  fnbRevenue: number
  expensesTotal: number
  shopBreakDown: shopBreakDownModel[] = []

  month = new Date().getFullYear() + '-' + (new Date().getMonth() + 1);


  start_date = new Date().toJSON().slice(0, 10);
  end_date = new Date().toJSON().slice(0, 10)


  constructor(
    private pnlrevenue: PnlService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    // let start_date = '2022-12-12';
    // let end_date = '2022-12-13';

    this.onDateChanged()

  }

  // getRevenu(month){
  //   this.pnlrevenue.getExpensesByMonth(month).subscribe((res) => {
  //     this.earningFromRooms = res.data.revenue.earning_from_rooms || 0
  //     this.expensesTotal = res.data.expenses.sum
  //   });
  // }

  getRevenueByDate(start, end) {
    this.pnlrevenue.getExpenses(start, end).subscribe((res) => {
      this.earningFromRooms = res.data.revenue.earning_from_rooms || 0
      this.fnbRevenue = res.data.revenue.fnb || 0
      this.expensesTotal = res.data.expenses.sum
      this.shopBreakDown = res.data.revenue.shopBreakDown

    });
  }

  onDateChanged() {

    let val = localStorage.getItem('pnl_selected_Date');
    let dateRange = JSON.parse(val);
    console.log(dateRange);
    if (dateRange) {
      this.month = dateRange.startDate.slice(0, -3)

      this.start_date = dateRange.startDate.split('T')[0]
      this.end_date = dateRange.endDate.split('T')[0]

      this.getRevenueByDate(this.start_date, this.end_date)

      // this.getRevenu(this.month);

    }
  }



  chandrop(id) {
    let a = document.getElementById(id);

    if (a.classList.contains('rotat')) {

      a.classList.remove('rotat');
    }
    else {
      a.classList.add('rotat');
    }

  }
  // changeDrop(id){
  //   let a=document.getElementById(id)
  //   if(a.classList.contains('rotate180')){
  //     a.classList.remove('rotate180')
  //   }
  //   else{
  //     a.classList.add('rotate180')
  //   }
  // }


  // change arrow in dropdown

  arrowUpDown = false;
  arrowUpDown2 = false;
  c_con: boolean = false;
  selected0;

  changeArrow() {
    this.arrowUpDown = !this.arrowUpDown;
  }

  changeArrow2() {
    this.arrowUpDown2 = !this.arrowUpDown2;
  }
  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
    }
  }

  calculateTotal() {
    let total = this.earningFromRooms + this.fnbRevenue;
    return total;
  }

  // dark Mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }
}

