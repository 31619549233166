import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { v4 as uuidv4 } from 'uuid';
import { FnbService } from 'src/app/services/fnb.service';
import { Shops } from 'src/app/common/fnb/createShop';
import { CategoryItem, SetupShops } from 'src/app/common/fnb/SetupShopModel';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { ToastrService } from 'ngx-toastr';
import { SetupShopResponse } from 'src/app/common/fnb/SetupShopResponse';
import { MenuProductResponse, Product } from 'src/app/common/fnb/MenuProductModel';
import { MenuDealItemResponse, ShopDealItem } from 'src/app/common/fnb/MenuDealItem';

declare var $: any;
@Component({
  selector: 'app-setup-shop',
  templateUrl: './setup-shop.component.html',
  styleUrls: ['./setup-shop.component.css']
})
export class SetupShopComponent implements OnInit {

  status: boolean = true;
  setupShops: SetupShops[] = [];
  darkMode: boolean = false;
  shopNames: Shops[] = [];
  categoryItems: CategoryItem[] = [];
  propertyId = localStorage.getItem("current_property");
  activeShopId: number = 0;
  border_colors = ['#CCdd00', '#EC1361', '#0179F1', '#FE6601', '#66F900', '#5D00FF', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];
  new_item_name: string;
  new_category_name: string;
  new_category_items: CategoryItem[] = []
  createMenuItems: Product[] = [];
  dealItems: ShopDealItem[] = [];

  constructor(
    public events: EventsService,
    private fnbService: FnbService,
    private confirm: ConfirmationAlertService,
    private toaster: ToastrService,
  ) {
    this.fnbService.getcreateShopNames().subscribe(res => {
      this.shopNames = res.data.shops;
      this.switchShop(this.shopNames[0].shop_id);
    });
  }

  ngOnInit() {
    this.checkDarkMode();
    const tabsBar = document.getElementById('tabsBarSetupShop');
    // x axis mouse wheel scroll breadcrumb (working on saud 👇)
    tabsBar.addEventListener('wheel', (event) => {
      event.preventDefault();
      tabsBar.scrollLeft += event.deltaY;
    });
  }

  // ? Function to add a new item to the category_items array (working on saud 👇)
  addNewMenuItem() {
    const newItem = {
      item_id: 0,
      item_name: this.new_item_name,
      is_deleted: 0
    };

    this.new_category_items.push(newItem);
    this.new_item_name = '';
  }

  addMenuItem(categoryIndex: number) {
    const catItem = this.setupShops[categoryIndex];
    const newItem = {
      item_id: 0,
      item_name: catItem.item_name,
      is_deleted: 0
    };

    catItem.category_items.push(newItem);
    catItem.item_name = '';
  }

  // ? Function to create a new category item (working on saud 👇)
  createCategoryItem() {
    if (!this.isEmptyCategoryItemExists()) {
      const newCategoryItem = {
        category_id: 0,
        category_name: this.new_category_name,
        // item_name: this.new_item_name,
        shop_id: this.activeShopId,
        property_id: this.propertyId,
        is_deleted: 0,
        category_items: this.new_category_items
      };

      this.setupShops.push(newCategoryItem);

      this.new_category_items = [];
      this.new_category_name = "";
      this.new_item_name = "";
    }
  }

  // ? Function returns true if the item is empty, false otherwise
  isEmptyCategoryItemExists() {
    return this.setupShops.some(categoryItem => categoryItem.category_name === '' && categoryItem.category_items.length === 0);
  }

  // ? Function to delete a category (working on saud 👇)
  deleteCategoryItem(categoryId: number) {
    this.confirm.confirm(`Alert`, `Are you sure you want to delete this category and items?`, [`This action can't be undone`, 'Are you sure you want to delte?'],
      () => {
        this.fnbService.getUnDeletedCategoriesAndItems(categoryId).subscribe((res: any) => {
          this.switchShop(this.activeShopId);
          this.toaster.success(res.message)
        })
      }
      , '', ''
    );
  }

  // ? Function to remove an item from the category_items array (working on saud 👇)
  removeNewMenuItem(categoryIndex: number) {
    this.new_category_items.splice(categoryIndex, 1);
  }

  removeMenuItem(catItem: SetupShops, categoryIndex: number) {
    const itemId = catItem.category_items[categoryIndex].item_id;

    const exists = this.createMenuItems.some(item => item.item_id === itemId);

    if (exists) {
      this.toaster.error('The category cannot be deleted as it has items in the menu product');
    } else {
      catItem.category_items.splice(categoryIndex, 1);
    }
  }

  // ? dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  // ? Function save category and items data (working on saud 👇)
  saveCategoryItems() {
    const nonEmptySetupShops = this.setupShops.filter(categoryItem => categoryItem.category_name !== '' || categoryItem.category_items[0].item_name !== '');
    console.log(nonEmptySetupShops, 'save data create menu item');
    this.fnbService.createCategoryAndItemsReq(nonEmptySetupShops).subscribe((res: SetupShopResponse) => {
      this.toaster.success(res.message);
      this.switchShop(this.activeShopId); // Fetch the updated data after saving
    });
  }

  switchShop(shop_id: number) {
    this.fnbService.getCategoryAndItems(shop_id).subscribe((res: any) => {
      console.log(res);
      this.setupShops = res.data.shops;
      this.getMenuProductsItemsSelectShop(shop_id)
      // ~ add the active class breadcrumb
      this.activeShopId = shop_id;
    })
  }

  getMenuProductsItemsSelectShop(shop_id: number) {
    this.fnbService.getMenuProductItems(shop_id).subscribe((res: MenuProductResponse) => {
      this.createMenuItems = res.data.products;
    });
  }

  //  selected shop fetch data deal menu (working on saud 👇)
  getDealMenuItems(shopId: number) {
    this.fnbService.getDealsItem(shopId).subscribe((res: MenuDealItemResponse) => {
      this.dealItems = res.data.shops;
    })
  }

}
