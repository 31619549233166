import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationAlertService } from 'src/app/common/confirmation-alert/confirmation-alert.service';
import { Order, ViewOrderDataResponse } from 'src/app/common/rms/ViewOrderModel';
import { EditOrderItemResponse } from 'src/app/common/rms/editOrderItem';
import { RmsService } from 'src/app/services/rms.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Subscription, interval } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { takeUntil } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.css']
})
export class OrderlistComponent implements OnInit, OnDestroy {
  @ViewChild('htmlContent', { static: false }) htmlContent!: ElementRef;
  OrderModalShow: boolean = false;
  darkMode: boolean = false;
  orderList: Order[] = [];
  currency_format = localStorage.getItem('currency');
  randomColor = ['#76A200', '#EC1361', '#0179F1', '#FE6601', '#5D00FF', '#66B436', '#FF953A', '#C11A22', '#FF47AC', '#00DCFF', '#FAD418', '#FF00BB', '#06A535', '#CA8D07', '#915000'];
  viewOrderList: Order;
  value: number = 100;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  countdown: number = 1800; // 30 minutes in seconds
  timerRef: any;
  orderTime: string = '07:47pm'; // Replace this with your actual order_time
  timerDuration: number = 30 * 60 * 1000; // Timer duration in milliseconds (30 minutes in this case)
  timerSubscription: Subscription;
  remainingTime: number;
  cancelledOrder: boolean = false;
  page: number = 1;

  constructor(
    private rmsService: RmsService,
    private toaster: ToastrService,
    private confirm: ConfirmationAlertService,
    private router: Router,
  ) {

  }

  ngOnInit() {

    this.checkDarkMode();
    setTimeout(() => {
      this.getOrderListItem();
    }, 500);

  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  viewOrderDeatails(index: number) {
    // Assuming the JSON data is stored in a variable called 'responseData'
    const orderData = this.orderList[index];
    if (orderData.order_items.length > 0) {
      this.OrderModalShow = true;
    }
    console.log(orderData);

    // Function to find items added, removed, and modified
    const itemsAdded = orderData.order_items.filter((item) => !orderData.order_edit_history.some((history) => history.previous_value.order_items.some((hItem) => hItem.id === item.id)));
    const itemsRemoved = orderData.order_edit_history.reduce((acc, history) => acc.concat(history.previous_value.order_items.filter((hItem) => !orderData.order_items.some((item) => item.id === hItem.id))), []);
    const itemsModified = orderData.order_items.filter((item) => orderData.order_edit_history.some((history) => history.previous_value.order_items.some((hItem) => hItem.id === item.id && hItem.quantity !== item.quantity)));

    // Update 'item_status' property based on conditions
    if (this.orderList[index].order_edit_history.length != 0) {
      itemsAdded.forEach((item) => (item.item_status = 'Added'));
      itemsRemoved.forEach((item) => (item.item_status = 'Removed'));
      itemsModified.forEach((item) => (item.item_status = 'Modified'));
    }

    // Check if there are item status changes and log the result
    if (itemsAdded.length > 0 || itemsRemoved.length > 0 || itemsModified.length > 0) {
      [...itemsAdded, ...itemsRemoved, ...itemsModified].forEach((item) => {
        this.viewOrderList = orderData;
        if (item.item_status == 'Removed') {
          this.viewOrderList.order_items.push(item);
        }
      });
    } else {
      return;
    }

  }

  editOrderItem(i: number) {
    if (this.isOrderExpired(this.orderList[i].order_date, this.orderList[i].order_time)) {
      this.cancelledOrder = true;
      this.toaster.error('order time has expired.', '', { positionClass: 'toast-center-center' });
      return;
    }

    if (
      this.orderList[i].order_status == "Cancelled" || this.orderList[i].order_status == "Modified"
    ) {
      this.cancelledOrder = true;
      this.toaster.error('Order cannot be modified as it has been canceled.', '', { positionClass: 'toast-center-center' });
      return;
    } else {
      let shop_id = this.orderList[0].order_items[0].shop_id;
      this.rmsService.fetchDealMenuItems(shop_id);
      this.rmsService.fetchProductMenuItems(shop_id);
      this.rmsService.editOrderItemsReq(this.orderList[i].order_id).subscribe((res: EditOrderItemResponse) => {
        let obj = {
          item_index: i,
          orders: res.data.orders
        }
        this.rmsService.orderListItem$.next(obj)
      })
      this.router.navigateByUrl('/app/back_office/rms_cafe');
    }
  }

  cancelOrder(i: number) {
    if (this.isOrderExpired(this.orderList[i].order_date, this.orderList[i].order_time)) {
      this.cancelledOrder = true;
      this.toaster.warning('order time has expired.', '', { positionClass: 'toast-center-center' });
      return;
    }

    if (
      this.orderList[i].order_status == "Cancelled" || this.orderList[i].order_status == "Modified"
    ) {
      this.cancelledOrder = true;
      this.toaster.error('Order already canceled.', '', { positionClass: 'toast-center-center' });
      return;
    } else {
      let obj = {
        order_id: this.orderList[i].order_id,
        guest_name: this.orderList[i].guest_name,
        room_no_or_table_id: this.orderList[i].room_no_or_table_id,
        special_requests: this.orderList[i].special_requests,
        order_served_in: this.orderList[i].order_served_in,
        total: this.orderList[i].total,
        order_status: "Cancelled"
      }

      this.confirm.confirm(`Alert`, `Are you sure you want to cancel this order?`,
        [`This action can't be undone`, 'Are you sure you want to delete?'],
        () => {
          this.rmsService.updateOrderItemsReq(obj).subscribe((res: any) => {
            this.getOrderListItem();
            this.toaster.success(res.message, '', { positionClass: 'toast-center-center' })
          })
        }
        , '', ''
      );
    }

  }

  onSaveCategory() {
    this.OrderModalShow = false;
  }

  onModalClose() {
    this.OrderModalShow = false;
  }

  // dark mode

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  async getOrderListItem() {
    try {
      const res: ViewOrderDataResponse = await this.rmsService.getOrderItemsReq().toPromise();
      this.orderList = res.data.orders;
      this.startTimer();
      this.sortOrders();
    } catch (error) {
      this.toaster.error(error, '', { positionClass: 'toast-center-center' });
    }
  }


  sortOrders() {
    this.orderList.sort((a, b) => {
      const dateA = new Date(a.order_date);
      const dateB = new Date(b.order_date);

      if (dateB > dateA) {
        return 1;
      } else if (dateB < dateA) {
        return -1;
      } else {
        const timeA = this.extractTime(a.order_time);
        const timeB = this.extractTime(b.order_time);
        return timeB - timeA;
      }
    });
  }

  private extractTime(timeString: string): number {
    const [time, period] = timeString.split(/([ap]m)/i);
    const [hour, minute] = time.split(':');
    const hourValue = parseInt(hour, 10);
    const minuteValue = parseInt(minute, 10);
    // Convert to 24-hour format
    const hour24 = (hourValue % 12) + (period.toLowerCase() === 'pm' ? 12 : 0);
    return hour24 * 100 + minuteValue;
  }

  // icon hover show deal remaining item in tooltip (working on saud 👇)
  getTooltipText(dealItem) {
    const hiddenItems = dealItem.deal.deal_items.map(item => item.deal_item_name);
    if (hiddenItems.length > 0) {
      return hiddenItems.join(', ');
    } else {
      return '';
    }
  }

  printPdf() {
    const element = this.htmlContent.nativeElement;

    const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, millimeters, A4 size
    html2canvas(element).then((canvas) => {
      const contentDataURL = canvas.toDataURL('image/png');

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imageWidth = canvas.width * 0.264583;
      const imageHeight = canvas.height * 0.264583;
      const positionX = (pageWidth - imageWidth) / 2;
      const positionY = (pageHeight - imageHeight) / 2;

      pdf.addImage(contentDataURL, 'PNG', positionX, positionY, imageWidth, imageHeight);
      pdf.save('orderlist.pdf');
    });
  }
  groupOrderItemsByShopName(orderItems: any[]): any[] {
    const groupedItems = [];
    orderItems.forEach((orderItem) => {
      const existingShopGroup = groupedItems.find((group) => group.shopName === orderItem.shop.shop_name);
      if (existingShopGroup) {
        existingShopGroup.items.push(orderItem);
      } else {
        groupedItems.push({ shopName: orderItem.shop.shop_name, items: [orderItem] });
      }
    });

    return groupedItems;
  }

  calculateSubTotal(items: any[]): number {
    return items.reduce((total, item) => total + item.amount, 0);
  }

  calculateTaxAmount(items: any[]): number {
    return items.reduce((total, item) => total + item.amount * (item.tax_per / 100), 0);
  }

  isOrderExpired(order_date: string, order_time: string): boolean {
    this.orderTime = order_time;
    const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000; // 30 minutes in milliseconds

    // Format the order_time to 24-hour format ("hh:mm:ss")
    const timeParts = order_time.split(/:|(?=am|pm)/i);
    let hours = parseInt(timeParts[0]);
    const isPM = /pm/i.test(order_time);
    if (isPM && hours !== 12) {
      hours += 12;
    } else if (!isPM && hours === 12) {
      hours = 0;
    }
    const orderTimeFormatted = `${hours.toString().padStart(2, '0')}:${timeParts[1]}:00`;
    const combinedDateTimeStr = `${order_date}T${orderTimeFormatted}`;
    const orderDateTime = new Date(combinedDateTimeStr);
    const currentTime = new Date();

    const isExpired = currentTime.getTime() - orderDateTime.getTime() > THIRTY_MINUTES_IN_MS;

    return isExpired;
  }

  startTimer(): void {
    const now = new Date();
    console.log(this.orderTime);
    const orderTimeParts = this.orderTime.match(/(\d+):(\d+)(\w+)/);

    if (!orderTimeParts) {
      console.log('Invalid order time format.');
      this.remainingTime = 0;
      return;
    }

    let hours = parseInt(orderTimeParts[1], 10);
    const minutes = parseInt(orderTimeParts[2], 10);
    const period = orderTimeParts[3].toLowerCase();

    if (isNaN(hours) || isNaN(minutes) || (period !== 'am' && period !== 'pm')) {
      console.log('Invalid order time format.');
      this.remainingTime = 0;
      return;
    }

    if (period === 'pm' && hours !== 12) {
      hours += 12;
    } else if (period === 'am' && hours === 12) {
      hours = 0;
    }

    // Create orderDateTime in UTC
    const orderDateTime = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes));

    // Check if the orderDateTime is valid
    if (orderDateTime.getTime() <= now.getTime()) {
      console.log('Invalid or past order time.');
      this.remainingTime = 0;
      return;
    }

    const timeDifference = orderDateTime.getTime() - now.getTime();

    this.remainingTime = Math.min(timeDifference, this.timerDuration);

    this.timerSubscription = interval(1000)
      .pipe(
        takeUntil(interval(this.remainingTime)) // Stop the timer after the specified duration
      )
      .subscribe(() => {
        this.remainingTime -= 1000;
      });
  }


  stopTimer(): void {
    if (this.timerSubscription && !this.timerSubscription.closed) {
      this.timerSubscription.unsubscribe();
      console.log('Timer stopped.');
    }
  }

  formatTime(time: number): string {
    if (time < 0) {
      return '00:00:00';
    }
    const hours = Math.floor(time / (1000 * 60 * 60)).toString().padStart(2, '0');
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
    const seconds = Math.floor((time % (1000 * 60)) / 1000).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

}
