import { Component, OnInit, HostListener, ChangeDetectorRef } from "@angular/core";
import { ApiService } from "../../services/api.service";
import { HttpClient } from "@angular/common/http";
import { ErrorHandlingService } from "../../services/error-handling.service";
declare var $: any;
import * as moment from "moment";
import ScrollBooster from "scrollbooster";
import { HelperService } from "../../services/helper.service";

// chip work----------------------------------
import { COMMA, ENTER } from "@angular/cdk/keycodes";
// import {Component} from '@angular/core';
import { MatChipInputEvent } from "@angular/material/chips";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
} from "@angular/forms";
// chip work----------------------------------
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import { ElementRef, ViewChild } from "@angular/core";
// import { ConsoleReporter } from 'jasmine';
import { element } from "protractor";
export interface Fruit {
  name: string;
}
@Component({
  selector: "app-house-keeping",
  templateUrl: "./house-keeping.component.html",
  styleUrls: ["./house-keeping.component.css"],
})
export class HouseKeepingComponent implements OnInit {
  @ViewChild("navdrop") navdrop: ElementRef; // Angular Version 8

  people = [
    { id: 1, name: "Single Room" },
    { id: 1, name: "Studio Room" },
    { id: 1, name: "Studio Room" },
  ];

  chart = [
    { id: 1, name: "List View" },
    { id: 2, name: "Calendar View" },
  ];
  display: boolean = true;
  housekeepingIndex: any;
  roomIndex;
  propertyIndex;
  dirty;
  clean;
  housekeepingID;
  dirtyO;
  user_name = "Not Assigned";
  buildingName;
  my_object;
  shift = "Morning";
  p_image;
  param;
  alldata: any;
  obj;
  vis = true;
  allHouseData;
  dirtyOccu: any;
  dirtyVacant: any;
  cleanedVacant: any;
  cleanedOccu: any;
  onlyDrooms: any;
  onlyCrooms: any;
  countDC;
  Selected;
  pagi2 = [];
  selectedIndex2 = 0;
  countDC2;
  filterBuildingData: any;
  conditionHide;
  aRooOOm;
  CBuilding;
  con0900;
  objR;
  objS;
  objA;
  objC;
  lastPageC;
  darkMode: boolean = false;
  uperFilterCon = undefined;
  uperFilterStatus = undefined;
  allPcount;
  status = [];
  Condition = [
    {
      name: "Dirty",
      id: "0",
    },
    {
      name: "Clean",
      id: "1",
    },
    {
      name: "Maintance",
      id: "2",
    },
    // {
    //   "name":'Vacant'
    // },
    // {
    //   "name":'Occupied'
    // }
  ];

  labelCol: boolean = false;
  filterConMainT = [
    {
      name: "Room",
    },
    {
      name: "Condition",
    },
    // {
    //   name:'Status'
    // },
    // {
    //   name:'Assigned'
    // },
  ];

  //conditionLast = [];

  // fCOn = [
  //   {name:'Select Filter By'}
  // ]

  selectConditionWisrArray: any;

  chartValue: number = 1;

  //New Top Bar Selection
  roomtypes = [];
  selectedRoom = [];

  //New Maintenence Work
  in_house_form: FormGroup;
  external_form: FormGroup;
  rooms_status: FormGroup;
  trade = [];

  selectMaintenence: any; // ngModel
  roomstatus: any; // ngModel
  traders = [];
  selectName: any;
  building = [];
  selectBuilding: any;
  floors = [];
  selectFloor: any;
  rooms = [];
  selectRoom: any;
  maintenenceSubmit: boolean = false;
  m_calendar_date = { startDate: moment() };
  em_calendar_date = { startDate: moment() };
  showBuildings: boolean = true;
  currency_format = localStorage.getItem("currency");
  current_currency;
  condition;
  filterChannel;
  filterRoom;
  filterBuilding;
  //New Maintenence Work End

  constructor(
    public http: HttpClient,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private helper: HelperService
  ) { }
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [{ name: "A101" }, { name: "A102" }, { name: "A103" }];

  ngOnInit() {
    this.get_roomtypes();
    this.checkDarkMode();
    this.getHouseKeeping();
    this.getBuildingandRooms();
    this.selectBDataFilter();
    //TopBar
    this.get_roomtypes(); // For Selection
    //maintenence
    this.getTradeList();
    this.getBuilding();
    this.scheduleForm();
    this.get_range();
    this.getVendorList();

    this.in_house_form = this.fb.group({
      name: ["", Validators.required],
      assigned_by_id: JSON.parse(localStorage.getItem("user")).id,
      vendor_id: ["", Validators.required],
      building_id: ["", Validators.required],
      floor_id: ["", Validators.required],
      room_no_id: ["", Validators.required],
      start_date: [moment().format("YYYY-MM-DD"), Validators.required],
      issue: ["", Validators.required],
    });

    this.external_form = this.fb.group({
      name: ["", Validators.required],
      assigned_by_id: JSON.parse(localStorage.getItem("user")).id,
      vendor_id: ["", Validators.required],
      building_id: ["", Validators.required],
      floor_id: ["", Validators.required],
      room_no_id: ["", Validators.required],
      start_date: [moment().format("YYYY-MM-DD"), Validators.required],
      issue: ["", Validators.required],
    });

    this.rooms_status = this.fb.group({
      id: ["", Validators.required],
      condition: ["", Validators.required],
      property_id: ["", Validators.required],
      status: null,
    });

    //maintenence End

    //calander work
    //this.get_channels();
    this.table_drag();
    //this.get_packages();

    $("#popup-first").draggable();
    $("#popup-first").draggable("disable");
    $("#popup-second").draggable();
    $("#popup-second").draggable("disable");

    $(function () {
      $("#drag-list").sortable({});
      $("#drag-list").mouseup(function () {
        $("#dragListTr").css({ cursor: "grab" });
      });
      $("#drag-list").mousedown(function () {
        $("#dragListTr").css({ cursor: "grabbing" });
      });
      $(".modal").mouseup(function () {
        $(".show").css({ cursor: "grab" });
      });
      $(".modal").mousedown(function () {
        $(".show").css({ cursor: "grabbing" });
      });
    });
  }

  get m() {
    return this.in_house_form.controls;
  }
  get em() {
    return this.external_form.controls;
  }
  get rs() {
    return this.rooms_status.controls;
  }
  get sf() {
    return this.schedule_form.controls;
  }

  //Main View Change
  selectedCharts($event) {
    var value;
    if (typeof $event == "object") {
      value = $event.id;
    }
    this.chartValue = value;
    setTimeout(() => {
      this.table_drag();
      this.map_boxes();
    });
  }

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;

  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.fruits.push({name: value.trim()});
  //   }
  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // remove(fruit: Fruit): void {
  //   const index = this.fruits.indexOf(fruit);

  //   if (index >= 0) {
  //     this.fruits.splice(index, 1);
  //   }
  // }

  /* Filter Bar Property list*/
  getBuildingandRooms() {
    let property_id = localStorage.getItem("current_property");
    let obj = {
      id: JSON.parse(localStorage.getItem("user")).id,
      email: JSON.parse(localStorage.getItem("user")).email,
      arr_room_empty: false,
    };
    this.api.post("api/property/index", obj, true).subscribe(
      (res: any) => {
        res.body.data.map((val) => {
          if (val.id == property_id) {
            this.propertyIndex = val.buildings;
            if (
              this.propertyIndex.length == 1 ||
              this.propertyIndex.length == 0
            ) {
              this.showBuildings = false;
            } else {
              this.showBuildings = true;
            }
          }
        });
        // this.propertyIndex = res.body.data[0].buildings
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }
  //Top bar Filter
  filterRoomsByCondition(condition, status) {
    this.uperFilterCon = condition;
    this.uperFilterStatus = status;
    let obj = {
      property_id: localStorage.getItem("current_property"),
      condition: condition,
    };
    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: condition,
      status: status,
    };

    this.api
      .post("api/housekeeping/index", status != undefined ? obj2 : obj, true)
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = res.body.data.data.data;

          this.countDC = [];
          this.countDC2 = [];
          this.pagi2 = [];
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/pms/api/housekeeping/index?page=" +
                i,
            });
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  reset() {
    this.api
      .post(
        "api/housekeeping/index",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = [];
          this.allHouseData = [];
          this.countDC = [];
          this.countDC2 = [];
          this.pagi2 = [];
          this.housekeepingIndex = res.body.data.data.data;
          this.allHouseData = res.body.data.data.data;
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/api/housekeeping/index?page=" +
                i,
            });
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    this.uperFilterCon = undefined;
    this.uperFilterStatus = undefined;
  }
  //Top bar Filter END

  //Get House Keeping Main API
  getHouseKeeping() {
    console.log(this.roomtypes);
    this.roomtypes.forEach((element) => {
      console.log(element.id);
    });

    this.api
      .post(
        "api/housekeeping/index",
        {
          property_id: localStorage.getItem("current_property"),
          shift: this.shift,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = [];
          this.allHouseData = [];
          this.lastPageC = 0;
          this.countDC = 0;
          this.countDC2 = 0;
          this.allPcount = 0;
          this.pagi2 = [];

          this.housekeepingIndex = res.body.data.data.data;
          console.log(res.body.data.data.data);
          this.allHouseData = res.body.data.data.data;
          this.lastPageC = res.body.data.data.last_page;
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          this.allPcount = res.body.data.data.current_page;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/api/housekeeping/index?page=" +
                i,
            });
          }

          console.log(this.pagi2);
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  // Main View Building Filter
  filterB(filterBuilding) {
    this.CBuilding = filterBuilding;
    this.api
      .post(
        "api/housekeeping/select_data",
        {
          property_id: localStorage.getItem("current_property"),
          building_id: filterBuilding,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  // drag_scroll(){
  //   $("#child").draggable({
  //     cursor: "move",
  //     containment: "parent",
  //     stop: function() {
  //       if($("#child").position().left < 1)
  //         $("#child").css("left", "720px");
  //     }
  //   });
  // }

  /*Image Modal */
  modalImage(m_id) {
    this.my_object = this.housekeepingIndex.find((x) => x.id === m_id);
    this.p_image = this.my_object.images[0].image;
  }

  img_preview(img) {
    this.p_image = img;
  }
  //Image Modal  End

  //Pagination
  paginationPage(url, pn) {
    this.scCustom9(pn);
    let obj = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,
      shift: this.shift,
    };
    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,
      status: this.uperFilterStatus,
      shift: this.shift,
    };
    let obj3 = {
      property_id: localStorage.getItem("current_property"),
      shift: this.shift,
    };

    this.api
      .post(
        "api/housekeeping/index?page=" + pn,
        this.uperFilterCon == undefined && this.uperFilterStatus == undefined
          ? obj3
          : this.uperFilterStatus == undefined
            ? obj
            : obj2,
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = [];
          let a = res.body.data.data.data;
          let start = res.body.data.data.from;
          let end = res.body.data.data.to;
          console.log(a);
          for (let i = start; i < end; i++) {
            this.housekeepingIndex.push(a[i]);
          }

          // this.housekeepingIndex = mapped;
          // console.log(this.housekeepingIndex);
          this.allPcount = res.body.data.data.current_page;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  prev(selectedIndex2) {
    if (selectedIndex2 > 0) {
      this.selectedIndex2--;
    } else {
      this.selectedIndex2 = 0;
    }
    let COM = this.selectedIndex2 + 1;
    let obj = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,
      shift: this.shift,
    };
    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,
      status: this.uperFilterStatus,
      shift: this.shift,
    };
    let obj3 = {
      property_id: localStorage.getItem("current_property"),
      shift: this.shift,
    };
    this.api
      .post(
        "api/housekeeping/index?page=" + COM,
        this.uperFilterCon == undefined && this.uperFilterStatus == undefined
          ? obj3
          : this.uperFilterStatus == undefined
            ? obj
            : obj2,
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = [];
          let a = res.body.data.data.data;
          let start = res.body.data.data.from;
          let end = res.body.data.data.to;
          console.log(a);
          for (let i = start; i < end; i++) {
            this.housekeepingIndex.push(a[i]);
          }
          // this.housekeepingIndex = res.body.data.data.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = (document.getElementById("cuspagidiv").scrollLeft -= 30);
  }

  next(selectedIndex2) {
    if (selectedIndex2 < this.countDC2.last_page - 1) {
      this.selectedIndex2++;
    } else {
      this.selectedIndex2 = this.countDC2.last_page - 1;
    }
    let cO = this.selectedIndex2 + 1;
    let obj = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,
      shift: this.shift,
    };

    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: this.uperFilterCon,

      status: this.uperFilterStatus,
      shift: this.shift,
    };
    var obj3: any;
    if (undefined != this.obj) {
      obj3 = {
        property_id: localStorage.getItem("current_property"),
        shift: this.shift,
        room_id: this.obj.room_id,
      };
    } else {
      obj3 = {
        property_id: localStorage.getItem("current_property"),
        shift: this.shift,
      };
    }

    this.api
      .post(
        "api/housekeeping/index?page=" + cO,
        this.uperFilterCon == undefined && this.uperFilterStatus == undefined
          ? obj3
          : this.uperFilterStatus == undefined
            ? obj
            : obj2,
        true
      )
      .subscribe(
        (res: any) => {
          this.housekeepingIndex = [];
          let a = res.body.data.data.data;
          let start = res.body.data.data.from;
          let end = res.body.data.data.to;
          console.log(a);
          for (let i = start; i < end; i++) {
            this.housekeepingIndex.push(a[i]);
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    var elmnt = (document.getElementById("cuspagidiv").scrollLeft += 30);
  }
  //Pagination

  selectBDataFilter() {
    this.api
      .post(
        "api/housekeeping/select_data",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.filterBuildingData = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  filterCon(filterRoom) {
    this.aRooOOm = filterRoom.toLowerCase();
    let aROom = filterRoom.toLowerCase();
    this.conditionHide = aROom;
    this.labelCol = true;
    //
    let obj = {
      property_id: localStorage.getItem("current_property"),
      room: true,
    };
    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: true,
      shift: this.shift,
    };
    let obj3 = {
      property_id: localStorage.getItem("current_property"),
      status: true,
    };
    let obj4 = {
      property_id: localStorage.getItem("current_property"),
      assigned: true,
    };
    this.api
      .post(
        "api/housekeeping/select_data",
        aROom == "room"
          ? obj
          : aROom == "condition"
            ? obj2
            : aROom == "status"
              ? obj3
              : aROom == "assigned"
                ? obj4
                : localStorage.getItem("current_property"),
        true
      )
      .subscribe(
        (res: any) => {
          this.selectConditionWisrArray = [];
          if (aROom == "room") {
            for (let i = 0; i < res.body.data.length; i++) {
              if (
                res.body.data[i]["room"]["display_name"] != "" &&
                res.body.data[i]["room"]["display_name"] != null
              ) {
                res.body.data[i]["room"]["roomtype"]["name"] =
                  res.body.data[i]["room"]["display_name"];
              }
            }
            this.selectConditionWisrArray = res.body.data;
          } else {
            this.selectConditionWisrArray = res.body.data;
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  filterConCon(filterChannel) {
    this.con0900 = filterChannel;
    let obj = {
      property_id: localStorage.getItem("current_property"),
      room_id: filterChannel,
      shift: this.shift,
      building_id: this.CBuilding,
    };
    let obj2 = {
      property_id: localStorage.getItem("current_property"),
      condition: filterChannel,
      building_id: this.CBuilding,
    };
    let obj3 = {
      property_id: localStorage.getItem("current_property"),
      status: filterChannel,
      building_id: this.CBuilding,
    };
    let obj4 = {
      property_id: localStorage.getItem("current_property"),
      user_id: filterChannel,
      building_id: this.CBuilding,
    };
    let abh = this.aRooOOm;
    abh == "room" ? (this.obj = obj) : "";

    this.api
      .post(
        "api/housekeeping/index",
        abh == "room"
          ? obj
          : abh == "condition"
            ? obj2
            : abh == "status"
              ? obj3
              : abh == "assigned"
                ? obj4
                : localStorage.getItem("current_property"),
        true
      )
      .subscribe(
        (res: any) => {
          this.pagi2 = [];
          this.housekeepingIndex = res.body.data.data.data;
          this.allHouseData = res.body.data.data.data;
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/api/housekeeping/index?page=" +
                i,
            });
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  scCustom() {
    setTimeout(() => {
      let a = document.getElementById("cuspagidiv");
      var elmnt = (document.getElementById("cuspagidiv").scrollLeft += 30);
    }, 400);
  }

  firstpage() {
    var a = (this.selectedIndex2 = 0);
    this.api
      .post(
        "api/housekeeping/index?page=" + a,
        {
          property_id: localStorage.getItem("current_property"),
          shift: this.shift,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.pagi2 = [];
          this.housekeepingIndex = res.body.data.data.data;
          this.allHouseData = res.body.data.data.data;
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/api/housekeeping/index?page=" +
                i,
            });
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    document.getElementById("cuspagidiv").scrollLeft -= 100000;
  }

  lastpage() {
    var a = (this.selectedIndex2 = this.lastPageC);
    var property_id = localStorage.getItem("current_property");
    this.api
      .post(
        "api/housekeeping/index?page=" + a,
        { property_id: property_id },
        true
      )
      .subscribe(
        (res: any) => {
          this.pagi2 = [];
          this.housekeepingIndex = [];
          let a = res.body.data.data.data;
          let start = res.body.data.data.from;
          let end = res.body.data.data.to;
          console.log(a);
          for (let i = start; i < end; i++) {
            this.housekeepingIndex.push(a[i]);
          }
          this.allHouseData = this.housekeepingIndex;
          this.countDC = res.body.data;
          this.countDC2 = res.body.data.data;
          for (let i = 1; i <= this.countDC2.last_page; i++) {
            this.pagi2.push({
              page_no: i,
              url:
                " https://uat.cgslhcm.com/api/housekeeping/index?page=" +
                i,
            });
          }
        },

        (err) => this.error_handling.handle_error(err.status, err.message)
      );
    this.selectedIndex2 = this.lastPageC - 1;
    document.getElementById("cuspagidiv").scrollLeft += 100000;
  }

  scCustom9(no) {
    var element = document.getElementById("cuspagidiv");
    if (this.allPcount > no) {
      if (this.allPcount > 100) {
        element.scrollLeft -= 50;
      } else {
        element.scrollLeft -= 30;
      }
    } else if (this.allPcount < no) {
      if (this.allPcount > 100) {
        element.scrollLeft += 50;
      } else {
        element.scrollLeft += 30;
      }
    }
  }

  dodrag(id) {
    $("#" + id).draggable("enable");
  }

  dontdrag(id) {
    $("#" + id).draggable("disable");
  }

  //  Filter Top bar
  get_roomtypes() {
    if (localStorage.getItem("current_property") > '') {
      this.api.post("api/room/index", { property_id: localStorage.getItem("current_property") }, true).subscribe((res: any) => {
        this.roomtypes = res.body.data;

        for (let i = 0; i < 2; i++) {
          this.selectedRoomType.push(this.roomtypes[i].room_type_id);
        }
      },
        (err) => this.error_handling.handle_error(err.status)
      );
    }
  }
  selectTopRoom(selectedRoom) {
    this.selectedRoomType = selectedRoom;
    if (selectedRoom.length == 2) {
      this.map_boxes();
    }
  }

  //New Maintenence PopUp Work
  getTradeList() {
    this.api.get("api/helper/get_trades", true).subscribe(
      (res: any) => {
        this.trade = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }

  getName(st, venType) {
    this.api
      .post(
        "api/housekeeping/users_by_trade",
        { trade: st, vendor_type: venType },
        true
      )
      .subscribe(
        (res: any) => {
          this.traders = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getBuilding() {
    this.api
      .post(
        "api/housekeeping/get_buildings",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.building = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getFloor(bId) {
    this.api
      .post(
        "api/housekeeping/get_floors",
        {
          property_id: localStorage.getItem("current_property"),
          building_id: bId,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.floors = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getRoom(rId, bId) {
    this.api
      .post(
        "api/housekeeping/get_room_no",
        { building_id: bId, floor_id: rId },
        true
      )
      .subscribe(
        (res: any) => {
          this.rooms = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  calendar_change(d) {
    this.in_house_form.patchValue({
      start_date: moment(d.startDate._d).format("YYYY-MM-DD"),
    });
  }

  ecalendar_change(d) {
    this.external_form.patchValue({
      start_date: moment(d.startDate._d).format("YYYY-MM-DD"),
    });
  }

  onSubmit() {
    this.maintenenceSubmit = true;
    if (this.in_house_form.value.start_date.startDate) {
      this.in_house_form.patchValue({
        start_date: moment(
          this.in_house_form.value.start_date.startDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    if (this.in_house_form.invalid) {
      return;
    }
    this.api
      .post("api/maintenance/store", this.in_house_form.value, true)
      .subscribe(
        (res: any) => {
          this.helper.alert_success("Vendor Assigned Successfully");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  onExternalSubmit() {
    this.maintenenceSubmit = true;
    if (this.external_form.invalid) {
      return;
    }

    this.api
      .post("api/maintenance/store", this.external_form.value, true)
      .subscribe(
        (res: any) => {
          this.helper.alert_success("Vendor Assigned Successfully");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  //New Maintenence PopUp Work END

  //Calander Work

  calendar_date = { startDate: moment(), endDate: moment().add("8", "days") };
  calendar_range = [];
  scroller;
  stop_scroll = false;
  current_view = {
    startDate: moment().subtract("1", "days"),
    endDate: moment().add("8", "days"),
    gap: 0,
    interval: null,
    scrollx: 0,
    scrolly: 0,
  };
  current_range = [];
  packages = [];
  inventory_calendar = [];
  inventory: any;
  room_types: any;
  channels: any;
  selectedRoomType = [];
  colors = ["#f98282", "#49fc6d", "#ef85ff", "#be7f2f", "#ffe878", "#43eecc"];
  selected_boxes = [];

  @HostListener("scroll", ["$event"])
  onTableScroll(event) {
    let gap = Math.floor(event.target.scrollLeft / 180);

    // if (this.stop_scroll) {
    //   this.stop_scroll = false;
    //   return;
    // }

    if (
      event.target.scrollLeft + event.target.clientWidth ==
      event.target.scrollWidth
    ) {
      this.calendar_date.endDate = this.calendar_date.endDate.add("3", "days");
      this.get_range();
      this.current_view.startDate = this.current_view.startDate.add(
        "0",
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.add("0", "days");
    }

    if (
      event.target.scrollLeft == 0 &&
      this.current_view.scrollx == event.target.scrollTop &&
      this.current_view.scrolly != event.target.scrollLeft
    ) {
      this.calendar_date.startDate = this.calendar_date.startDate.subtract(
        "4",
        "days"
      );
      this.get_range();
      this.current_view.startDate = this.current_view.startDate.subtract(
        "3",
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.subtract(
        "3",
        "days"
      );
      gap = 1;
      event.target.scrollLeft = 5;
      //this.stop_scroll = true;
    }

    if (gap != this.current_view.gap) {
      let space = gap - this.current_view.gap;
      this.current_view.startDate = this.current_view.startDate.add(
        space,
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.add(space, "days");

      clearTimeout(this.current_view.interval);
      let id = setTimeout(() => {
        this.current_table();
      }, 250);

      this.current_view.gap = gap;
      this.current_view.interval = id;
    }

    this.current_view.scrollx = event.target.scrollTop;
    this.current_view.scrolly = event.target.scrollLeft;
  }

  get_range(from_picker = false) {
    let range = [];

    let start = moment(this.calendar_date.startDate).toDate();
    let end = moment(this.calendar_date.endDate).toDate();

    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }

    if (from_picker) {
      document.querySelector(".scroll").scrollLeft = 5;
      while (range.length < 9) {
        range.push(moment(range[range.length - 1]).add("1", "days"));
      }
      this.current_view.startDate = range[0];
      this.current_view.endDate = range[range.length - 1];
      this.current_view.gap = 0;
    }
    this.calendar_range = range;
  }

  current_table(new_start = null, new_end = null) {
    let range = [];
    let start = moment(new_start || this.current_view.startDate).toDate();
    let end = moment(new_end || this.current_view.endDate).toDate();
    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }
    this.current_range = range;
    this.map_boxes();
  }

  map_boxes() {
    let d = {
      property_id: localStorage.getItem("current_property"),
      start_date: this.current_view.startDate.format("YYYY-MM-DD"),
      end_date: this.current_view.endDate.add("2", "days").format("YYYY-MM-DD"),
      room_type_id: this.selectedRoomType.join(),
    };
    this.api.post("api/housekeeping/house_keeping_calendar", d, true).subscribe(
      (res: any) => {
        for (let i = 0; i < res.body.data.length; i++) {
          if (
            res.body.data[i][0]["room"]["display_name"] == "" ||
            res.body.data[i][0]["room"]["display_name"] == null
          ) {
            res.body.data[i][0]["room"]["roomtype"]["name"] =
              res.body.data[i][0]["room"]["roomtype"]["name"];
          } else {
            res.body.data[i][0]["room"]["roomtype"]["name"] =
              res.body.data[i][0]["room"]["display_name"];
          }
        }
        this.inventory_calendar = res.body.data;
        console.log(this.inventory_calendar);
        this.ref.markForCheck();
        setTimeout(() => {
          let a = document.getElementById("scroll_wrapper");
          a.scrollTop = 1;
        });
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  table_drag() {
    let viewport = <HTMLElement>document.querySelector(".scroll");
    let content = <HTMLElement>document.querySelector(".grid_calendar");

    if (content == undefined || content == null) {
      return false;
    }
    this.scroller = new ScrollBooster({
      viewport,
      content,
      direction: "horizontal",
      bounce: false,
      emulateScroll: false,
      inputsFocus: true,
      onUpdate: (state) => {
        viewport.scrollLeft = state.position.x;
      },
    });
  }

  is_box_shown(boxes, date) {
    return boxes.find((b) => b.date == date.format("YYYY-MM-DD")) || false;
  }

  trackByInv(index, item) {
    return index;
  }

  trackByVen(index, item) {
    return index;
  }

  trackByBox(pkg_id, index, item) {
    return index + "_" + pkg_id;
  }
  //Calander Work

  //Schedule Popup Work
  scheduleDetails: any;
  staffList = [];
  selectStaff: any;

  buildingList = [];
  selectbuilding: any;

  floorList = [];
  selectfloor: any;

  roomList = [];
  selectroom: any;

  shifts = [{ name: "Morning" }, { name: "Evening" }, { name: "Night" }];
  selectShift: any;
  days = [
    { name: "Monday", value: false, slug: "Mon" },
    { name: "Tuesday", value: false, slug: "Tue" },
    { name: "Wednesday", value: false, slug: "Wed" },
    { name: "Thursday", value: false, slug: "Thu" },
    { name: "Friday", value: false, slug: "Fri" },
    { name: "Saturday", value: false, slug: "Sat" },
    { name: "Friday", value: false, slug: "Sun" },
  ];

  schedule_form: FormGroup;
  scheduleSubmit: boolean = false;
  attendanceBox: any;
  attendanceDate: any;
  getRoomDetails() {
    let r = {
      property_id: localStorage.getItem("current_property"),
    };
    this.api.post("api/housekeeping/schedule", r, true).subscribe(
      (res: any) => {
        this.scheduleDetails = res.body.data;

        $("#popup-first").modal({ backdrop: "static", keyboard: false });
        this.getVendorList();
        this.getBuildingList();
        this.getAttendence();
        let arr = [];
        this.scheduleDetails.forEach((element) => {
          arr.push({
            id: element.user.id,
            first_name: element.user.name_with_rate,
          });
        });

        this.staffList = arr;
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  getVendorList() {
    this.api
      .post("api/user/vendor_list", { vendor_type: "In House" }, true)
      .subscribe(
        (res: any) => {
          // this.staffList = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  getBuildingList() {
    this.api
      .post(
        "api/housekeeping/get_buildings",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.buildingList = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  getFloorList(bId) {
    this.api
      .post(
        "api/housekeeping/get_floors",
        {
          property_id: localStorage.getItem("current_property"),
          building_id: bId,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.floorList = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  getRoomList(fId) {
    this.api
      .post(
        "api/housekeeping/get_room_no",
        {
          property_id: localStorage.getItem("current_property"),
          building_id: this.selectbuilding,
          floor_id: fId,
        },
        true
      )
      .subscribe(
        (res: any) => {
          this.roomList = res.body.data;
          this.selectroom = undefined;
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  selectStf(selectStaff) {
    this.selectStaff = selectStaff;
  }

  selectBuid(selectbuilding) {
    this.selectbuilding = selectbuilding;
    this.getFloorList(selectbuilding);
  }

  selectflr(selectfloor) {
    this.selectFloor = selectfloor;
    this.getRoomList(selectfloor);
  }

  selectRm(selectroom) {
    this.selectroom = selectroom;
  }

  selectShft(selectShift) {
    this.selectShift = selectShift;
  }

  checkDays(e, i) {
    this.days[i].value = e.target.checked;
  }

  scheduleForm() {
    this.schedule_form = this.fb.group({
      property_id: localStorage.getItem("current_property"),
      vendor_id: ["", Validators.required],
      added_by: JSON.parse(localStorage.getItem("user")).id,
      days_include: "",
      days_exclude: "",
      shift: ["", Validators.required],
      building_id: ["", Validators.required],
      floor_id: ["", Validators.required],
      room_data: ["", Validators.required],
    });
  }

  saveSchedule() {
    this.scheduleSubmit = true;

    let di = [];
    let de = [];
    let count = 0;

    this.days.map((val, i) => {
      if (val.value == true) {
        count++;
      }
    });
    if (count == 0) {
      this.helper.alert_warning("Please Select Alteast 1 Day");
      return false;
    }
    this.days.forEach((element) => {
      if (element.value == true) {
        di.push(element.name);
      } else {
        de.push(element.name);
      }
    });

    this.schedule_form.patchValue({
      days_include: di.join(),
      days_exclude: de.join(),
    });

    if (this.schedule_form.invalid) {
      return;
    }

    this.api
      .post(
        "api/housekeeping/store_info_and_room_no",
        this.schedule_form.value,
        true
      )
      .subscribe(
        (res: any) => {
          this.getRoomDetails();
          this.helper.alert_success("Schedule Successfully!");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  //Schedule Popup Work END

  //getattendence
  getAttendence() {
    this.api
      .post("api/housekeeping/attendance", null, true)
      .subscribe((res: any) => {
        this.attendanceBox = res.body.data.data;
        this.attendanceDate = res.body.data.dates;
      });
  }
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  makeLowerCase(con) {
    let a = con.toLowerCase();
    return a;
  }

  makePDF() {
    var HTML_Width = $(".capture").width();
    var HTML_Height = $(".capture").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;
    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    html2canvas($(".capture")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext("2d");
      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      pdf.save("House keeping schedule.pdf");
    });
  }

  chngeRo(id) {
    let a = <HTMLInputElement>document.getElementById(id);
    a.classList.contains("rotate")
      ? id == "AssHoKee"
        ? a.classList.remove("rotate")
        : id == "AssHoKee1"
          ? a.classList.remove("rotate")
          : id == "AssHoKee2"
            ? a.classList.remove("rotate")
            : setTimeout(() => {
              a.classList.remove("rotate");
            }, 400)
      : a.classList.add("rotate");
  }

  tick(vd, rd) {
    let data = {
      vendor_id: vd,
      building_id: rd.building_id,
      floor_id: rd.floor_id,
      room_id: rd.room_id,
      room_no_id: rd.id,
    };

    this.api
      .post("api/housekeeping/store_roooms", data, true)
      .subscribe((res: any) => { });
  }
  cross(rd) {
    // alert('cross');
    console.log(rd);
  }

  dropClick(indo) {
    if (this.vis == true) {
      document.getElementById(indo).style.display = "block";
      this.vis = false;
    } else {
      document.getElementById(indo).style.display = "none";
      this.vis = true;
    }
    this.navdrop.nativeElement.classList.toggle("visibility");
  }

  shifts_change(event) {
    this.shift = event;
    this.getHouseKeeping();
    console.log(event);
  }
  displayCondition(data) {
    data.forEach((t) => (t.shift == this.shift ? t.first_name : "Not"));
  }

  chckstatus(event) {
    0 == event.id || 1 == event.id
      ? (this.status = [{ name: "Do Not Disturb" }])
      : (this.status = [{ name: "Closed" }]);
  }

  houseindex(houseKindex) {
    this.housekeepingID = houseKindex;
  }

  room_status() {
    this.rooms_status.patchValue({
      id: this.housekeepingID.id,
      property_id: localStorage.getItem("current_property"),
    });

    this.api
      .post("api/housekeeping/check", this.rooms_status.value, true)
      .subscribe(
        (res: any) => {
          this.getHouseKeeping();
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  go() {
    let roomnum = (<HTMLInputElement>document.getElementById("roomnum")).value;
    let data = {
      property_id: localStorage.getItem("current_property"),
      roomnum: roomnum,
    };
    this.api.post("api/housekeeping/roomnum", data, true).subscribe(
      (res: any) => {
        if (res.body.data.data.total == 0) {
          this.helper.alert_error(`Room Number ${roomnum} Not Found !`);
          return;
        }
        this.housekeepingIndex = [];
        this.housekeepingIndex = res.body.data.data.data;
        this.countDC = [];
        this.countDC2 = [];
        this.pagi2 = [];
        this.countDC = res.body.data;
        this.countDC2 = res.body.data.data;
        this.allHouseData = res.body.data.data.data;
        this.lastPageC = res.body.data.data.last_page;
        this.countDC = res.body.data;
        this.countDC2 = res.body.data.data;
        this.allPcount = res.body.data.data.current_page;
      },
      (err) => this.error_handling.handle_error(err.status)
    );
  }
  assign_staff(a, b, c) {
    let data = {
      date: a,
      room_no_id: b,
      vendor_id: c,
    };
    this.api
      .post("api/housekeeping/assign", data, true)
      .subscribe((res: any) => { }),
      (err) => this.error_handling.handle_error(err.status);
  }
  check(a: any) {
    console.log(a);
  }

}
