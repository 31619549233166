<div class="col-md-12" style="padding:0px;">
    <details class="RoomRevenue">
        <summary class="show2" (click)="changeArrow()"> {{label.replace('Operating','overhead')}}
            <img alt="" class="drp" src="assets/img/reports/button_down_dark.svg" id="dropDown1"
                [ngClass]="arrowUpDown ? 'rotat' : 'drp'">
        </summary>
        <details>
            <summary class="showDanger null">
                <div class="col-md-12 col-sm-12 " style="padding: 0px;">
                    <div class="revenue card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                        <div class="scrollo02">
                            <ng-container *ngFor="let expense of Expenses; index as i">
                                <div class="row">
                                    <div class="col-md-4 col-sm-4 inputCat1">
                                        <div class="modal-bar-2 border-1">
                                            <input type="text" class="form-control costinput" name="name"
                                                [placeholder]="placeholderText" [(ngModel)]="expense.name" required>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-4 inputCat2">
                                        <div class="modal-bar-2 border-1">
                                            <input type="number" class="form-control costinput" name="amount"
                                                placeholder="Add Price" [(ngModel)]="expense.amount"
                                                (blur)='formatExpenseAmount($event, expense)'
                                                (keyup.enter)='formatExpenseAmount($event, expense)'
                                                (focus)='formatExpenseAmountFocus($event)' required>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-2 inputCat3">
                                        <div class="modal-bar-2 border-1">
                                            <div class="radio">
                                                <label class="control-label radio-style chbx ellipses hovbulit"
                                                    data-toggle="tooltip" title="Var">
                                                    <input class="pull-left radio" name="expense{{i}}" type="radio"
                                                        [value]="false" [(ngModel)]="expense.is_fixed"
                                                        (click)="expense.is_fixed = true"
                                                        [checked]="expense.is_fixed === false">
                                                    <span class="radio-checkmark rd"></span>
                                                    <span class="title">Var</span></label>

                                                <label class="control-label radio-style chbx ellipses hovbulit"
                                                    data-toggle="tooltip" title="Fix">
                                                    <input class="pull-left radio" name="expense{{i}}" type="radio"
                                                        [value]="true" [(ngModel)]="expense.is_fixed"
                                                        (click)="expense.is_fixed = true"
                                                        [checked]="expense.is_fixed === true">
                                                    <span class="radio-checkmark rd"></span>
                                                    <span class="title">Fix</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-sm-2 btnCol">
                                        <!-- <button class="addBtn" *ngIf="i+1 == Expenses.length" [disabled]="expense.name === '' || expense.amount === 0" (click)="addDummyExpenses(expenseId)"><i class="fa fa-plus"></i></button> -->
                                        <button class="addBtnCross" data-toggle="modal"
                                            data-target="#confirmationModal-3" (click)="removeExpenses(expense)"><i
                                                class="fa fa-times"></i></button>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="row">
                                <div class="col-md-4 col-sm-4 inputCat1">
                                    <div class="modal-bar-2 border-1">
                                        <input type="text" class="form-control costinput" name="name"
                                            [placeholder]="placeholderText" [(ngModel)]="newExpenseName" required>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 inputCat2">
                                    <div class="modal-bar-2 border-1">
                                        <input type="number" class="form-control costinput" name="amount"
                                            placeholder="Add Price" [(ngModel)]="newExpenseAmount"
                                            (blur)='formatExpenseAmount($event)'
                                            (keyup.enter)='formatExpenseAmount($event)'
                                            (focus)='formatExpenseAmountFocus($event)' required>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 inputCat3">
                                    <div class="modal-bar-2 border-1">
                                        <div class="radio">
                                            <label class="control-label radio-style chbx ellipses hovbulit"
                                                data-toggle="tooltip" title="Var">
                                                <input type="radio" class="pull-left radio var" name="select-expense"
                                                    value="false" (click)="varFixChecked('var')"
                                                    [checked]="newIsFixed==false">
                                                <span class="radio-checkmark rd"></span>
                                                <span class="title">Var</span>
                                            </label>
                                            <label class="control-label radio-style chbx ellipses hovbulit"
                                                data-toggle="tooltip" title="Fix">
                                                <input type="radio" class="pull-left radio fix" name="select-expense"
                                                    value="true" (click)="varFixChecked('fix')"
                                                    [checked]="newIsFixed==true" />
                                                <span class="radio-checkmark rd"></span>
                                                <span class="title">Fix</span>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 btnCol">
                                    <button class="addBtn" (click)="addDummyExpenses()"
                                        [disabled]="newExpenseName == '' || newExpenseAmount == null"><i
                                            class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </summary>
        </details>
    </details>
    <div class="flex text space-between red-bar" [ngClass]="darkMode ? 'dark_bredcrum ' : ''">
        Total
        <div>{{currency_format}} {{sumTotalExpenses() | number : '1.2-2'}}</div>
    </div>
</div>