<!-- <p>message works!</p> -->
<form [formGroup]="contact_support_form">
    <div class="col-md-6 zero-padding" style="padding-right: 3px">

        <div class="col-md-12 zero-padding">
            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row main-row">
                    <div class="col-md-12 zero-padding">
                        <div class="bar" [ngClass]="darkMode ? 'bar_dark' : 'bar'">
                            <input type="text" placeholder="Subject" formControlName="subject">
                            <div *ngIf="submitted && f.subject.errors"
                                class="small text-danger no-margin invalid-feedback text-left">
                                <strong *ngIf="f.subject.errors.required">Subject is required</strong>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 zero-padding">
                        <textarea placeholder="Message" rows="12" class="bor" [ngClass]="darkMode ? 'bor_dark' : 'bor'"
                            formControlName="description"></textarea>
                        <div *ngIf="submitted && f.description.errors"
                            class="small text-danger no-margin invalid-feedback text-left">
                            <strong *ngIf="f.description.errors.required">Message is required</strong>
                        </div>
                    </div>
                    <div class="col-md-12 zero-padding">
                        <button class="submit hov-btn" [ngClass]="darkMode ? 'submit_dark' : 'submit'"
                            (click)="onSubmit()">
                            <img src="assets/img/back-office/c-submit.svg" alt="messege" class="count">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>