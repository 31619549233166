import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { RmsService } from 'src/app/services/rms.service';
import { OrderItem, OrderItemModel } from 'src/app/common/rms/OrderItemModel';
import { ToastrService } from 'ngx-toastr';
import { PlaceOrderItem } from 'src/app/common/rms/PlaceOrderItem';
import { OrderItems } from 'src/app/common/rms/editOrderItem';
import { RoomGuest } from 'src/app/common/rms/RoomNoGuest';
import { map, withLatestFrom } from 'rxjs/operators';
import { Select2Option } from 'ng-select2-component';


@Component({
  selector: 'app-rms-tray',
  templateUrl: './rms-tray.component.html',
  styleUrls: ['./rms-tray.component.css']
})
export class RmsTrayComponent implements OnInit {

  darkMode: boolean = false;
  isActive: boolean = true;
  changeArrow: boolean = true;
  showGuestName: boolean = false;
  showTableNo: boolean = false;
  editMode: boolean = false;
  isEmptyOrder: boolean = false;
  editModeCheck: boolean = false;
  cards = [1];
  itemCards: PlaceOrderItem[] = [];
  tableNum: any;
  special_request: string;
  propertyId = localStorage.getItem("current_property");
  currency_format = localStorage.getItem('currency');
  room_num = "";
  order_id: number;
  order_item_id: number;
  roomGuestList$: Observable<RoomGuest[]> | undefined;
  availableRoomNo$: Observable<Select2Option[]> | undefined;
  selectedRoomName$: BehaviorSubject<string> = new BehaviorSubject('');
  selectedGuest$: Observable<string> | undefined;
  guest_name: string;
  //onOrderAction$: Observable
  onClickCheckout: Subject<PlaceOrderItem[]> = new Subject();

  constructor(
    private rmsService: RmsService,
    private toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.getOrderListItem();

    RmsService.OnOrderItemAdded.subscribe((orderitem: any) => {
      if (this.itemCards.length == 0) {
        this.room_num = '';
        this.special_request = '';
        this.editModeCheck = false;
      }
      orderitem.count = 1
      this.itemCards.push(orderitem);
      this.rmsService.OnOrderListEmpty.next(!(this.itemCards.length > 0));
    })

    this.rmsService.orderTrayEmpty.subscribe(res => {
      if (res) {
        this.itemCards = []
      }
    })

    this.roomGuestList$ = this.rmsService.roomGuestList$.pipe(
      map(x => x.data.rooms)
    )

    this.availableRoomNo$ = this.roomGuestList$.pipe(
      map(x => x.map(room => ({
        label: room.room_no_name,
        value: room.room_no_name
      })))
    )

    this.selectedGuest$ = combineLatest([this.roomGuestList$, this.selectedRoomName$]).pipe(
      map(x => {
        const selectedRoom = x[1];
        const roomList = x[0];
        const roomObj = roomList.find(y => y.room_no_name == selectedRoom)
        
        if (roomObj) {
          return roomObj.guest_name
        }
      })
    )

    this.onClickCheckout.pipe(
      withLatestFrom(this.selectedGuest$, this.selectedRoomName$, this.roomGuestList$)
    ).subscribe({
      next: ([itemCards, guestName, roomName, roomGuestList]) => {

        if (!this.room_num) {
          this.toaster.warning("Please Enter valid room no", "", {
            positionClass: 'toast-center-center',
            timeOut: 8000
          });
        } else {
          const roomNum = roomGuestList.find(x => x.room_no_name.toLowerCase() == this.room_num.toLowerCase());

          if (!roomNum) {
            this.toaster.warning("Please Enter valid room no", "", {
              positionClass: 'toast-center-center',
              timeOut: 8000
            });
          } else {
            const roomId = roomNum.room_no_id;
            const orderItems: OrderItem[] = this.prepareOrderItems(itemCards);
            const data: OrderItemModel = this.prepareOrderItemModel(guestName, roomId, orderItems);

            if (this.editMode) {
              this.rmsService.updateOrderItemsReq(data).subscribe((res: any) => {
                this.editModeCheck = true;
                this.editMode = false;
                this.toaster.success(res.message, "", {
                  positionClass: 'toast-center-center',
                  timeOut: 8000
                });
                this.rmsService.OnOrderListEmpty.next(true)
              })
            } else {
              this.rmsService.placeOrderMenuItemReq(data).subscribe((res: any) => {
                this.editModeCheck = true;
                this.order_id = res.data.order.order_id;
                this.toaster.success(res.message, "", {
                  positionClass: 'toast-center-center',
                  timeOut: 8000
                });
                this.rmsService.OnOrderListEmpty.next(true);
              });
            }
            // this.editMode = false;
          }
        }

      },
      error: (err) => {
        this.toaster.error(err, "", {
          positionClass: 'toast-center-center',
          timeOut: 8000
        })
      }
    })

  }

  private prepareOrderItemModel(guestName: string, roomId: number, orderItems: OrderItem[]): OrderItemModel {
    return {
      order_id: this.order_id || 0,
      guest_name: guestName,
      room_no_or_table_id: roomId.toString(),
      property_id: this.propertyId,
      special_requests: this.special_request || "",
      order_served_in: "Room",
      order_status: this.editMode ? "Modified" : "Confirmed",
      total: this.calculateSubTotal(),
      order_items: orderItems
    };
  }

  private prepareOrderItems(itemCards: PlaceOrderItem[]): OrderItem[] {
    return itemCards.map((x) => {
      const taxAmount = x.shop.tax_inclusive == "true" ? 0 : (x.selling_price || x.deal_selling_price) * (x.shop.tax_per / 100);
      return {
        id: x.id || 0,
        article_id: x.shop_menu_id || x.shop_deal_id,
        amount: (this.calculateTaxAmount(x) + taxAmount),
        quantity: x.count,
        shop_id: x.shop.shop_id,
        type: x.type || x.shop_deal_id ? 'deal' : 'product',
        tax_per: x.shop.tax_per,
        tax_inclusive: x.shop.tax_inclusive,
      };
    });
  }

  sideMenu(id1: string) {
    let a = <HTMLDivElement>document.getElementById(id1);

    if (a.style.right == '0px') {
      a.style.right = "-300px";
      this.changeArrow = false;
    } else {
      this.changeArrow = true;
      a.style.right = "0px";
    }
  }

  removeItem(i) {
    this.itemCards.splice(i, 1)
    this.rmsService.OnOrderListEmpty.next(!(this.itemCards.length > 0))
  }

  decreaseItem(itemCard: PlaceOrderItem) {
    if (itemCard.count <= 1) {
      itemCard.count = 1;
    }
    else {
      itemCard.count--;
    }
  }

  increaseItem(itemCard: PlaceOrderItem) {
    itemCard.count++;
  }

  guestNameShow() {
    if (this.room_num.length > 0) {
      this.showGuestName = true;
    } else {
      this.showGuestName = false;
    }
  }

  focusGuestNameShow() {
    if (this.room_num.length == null || this.room_num.length == 0 || this.room_num == '') {
      this.showGuestName = false;
    }
  }

  tableNoShow(val: any) {
    if (val.value.length > 0) {
      this.showTableNo = true;
      this.tableNum = val.value;
    } else {
      this.showTableNo = false;
    }
  }

  focusTableNoShow(val: any) {
    if (val.value.length == null || val.value.length == 0 || val.value == '') {
      this.showTableNo = false;
    }
  }

  toggleClass() {
    this.isActive = !this.isActive;
    this.showGuestName = false;
    this.showTableNo = false;
  }

  calculateSubTotal(): number {
    let subTotal: number = 0
    this.itemCards.forEach(e => {
      const unitPrice = (e.selling_price || e.deal_selling_price);
      const taxAmount = e.shop.tax_inclusive == "true" ? 0 : unitPrice * (e.shop.tax_per / 100);
      subTotal += e.count * (unitPrice + taxAmount);
    });
    return subTotal
  }

  // dark mode
  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  checkOut() {
    this.onClickCheckout.next(this.itemCards);
  }

  calculateTaxAmount(x: PlaceOrderItem) {
    const unitPrice = ((x.selling_price || x.deal_selling_price));
    const amount = unitPrice * x.count;
    return amount
  }

  getOrderListItem() {
    this.rmsService.orderListItem$.subscribe((res: any) => {
      this.editModeCheck = false;
      this.editMode = true;

      res.orders.order_items.forEach((orderItem: OrderItems, index: number) => {
        const itemCard: any = {};

        itemCard.id = orderItem.id;
        itemCard.type = orderItem.type;
        // itemCard.shop_menu_id = orderItem.item_id;
        itemCard.item_id = orderItem.article_id;
        itemCard.category_id = orderItem.product ? orderItem.product.category_id : orderItem.deal.shop_deal_id;
        itemCard.shop_id = orderItem.shop_id;
        itemCard.selling_price = orderItem.amount / orderItem.quantity;
        itemCard.image = orderItem.product ? orderItem.product.image : orderItem.deal.image;
        itemCard.shop = orderItem.shop ? {
          shop_id: orderItem.shop.shop_id,
          tax_per: orderItem.shop.tax_per,
          tax_inclusive: orderItem.shop.tax_inclusive
        } : null;
        itemCard.category = orderItem.product ? orderItem.product.category_id : null;
        itemCard.item = orderItem.product ? orderItem.product : null;
        itemCard.count = orderItem.quantity;
        this.order_id = orderItem.order_id;
        this.room_num = res.orders.room_number.room_number;
        this.special_request = res.orders.special_requests;
        this.selectedRoomName$.next(this.room_num)
        this.itemCards[index] = itemCard;
      });
    });
  }

  handleRoomSelected(result: string) {
    console.log(result);
    
    this.selectedRoomName$.next(result)
  }


}
