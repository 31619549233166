<form class="form-horizontal back-form" [formGroup]="create_package_form" (ngSubmit)="onSubmit()">
  <div class="SS" style="padding-right: 5px;" id="SS">
    <div class="alert alert-danger breadcrum mb-breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close" (click)="onClose()">RATES & PACKAGES</a>
      <a routerLink="setup_package" class="btn btn-link active">
        <span class="abc">SETUP RATE PLAN</span>
      </a>
    </div>
    <div class="no_scroll scroll">
      <div class="row">
        <div class="col-md-12">

          <div class="col-md-4" style="padding-right: 2px;">
            <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <div class="border-style color-5">
                <input type="text" class="input form-control height40" placeholder="Managed Season One"
                  style="outline: none;" formControlName="season_name"
                  [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">

                <span class="info">
                  <i class="fas fa-info-circle i" style="font-size: 15px; margin-right: -2px;"
                    [ngClass]="darkMode ? 'i_dark' : 'i'">
                    <span class="tooltiptext3" [ngClass]="darkMode ? 'tool_dark' : 'tooltiptext3'">
                      <p class="small   no-margin" style=" padding: 0px !important; margin: 0px !important;">
                        Seasons to Manage your Rooms Rates, Avilability and Inventory.
                      </p>
                    </span>
                  </i>
                </span>
              </div>
              <div *ngIf="submitted && f.season_name.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.season_name.errors.required">Season Name is Required</strong>
              </div>
            </div>
          </div>

          <div class="col-md-4" style="padding-left: 3px; padding-right: 2px;">
            <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <div class="border-style color2" style="padding: 0px"
                [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">

                <span class="input-group-addon icoColor" [ngClass]="darkMode ? 'icoColorDark' : 'icoColor'"
                  style="background: transparent; border: none;padding-left: 20px;font-size: 24px;color: #00205b; display: inline">
                  <i class="fa fa-calendar-alt"></i>
                </span>

                <input type="hidden" formControlName="date_to" name="date_to" [(ngModel)]="date_to">
                <input type="hidden" formControlName="date_from" name="date_from" [(ngModel)]="date_from">
                <input type="text" ngxDaterangepickerMd [closeOnAutoApply]="false"
                  class="date_range_picker height40" [autoApply]="true" (change)="choose_date($event)"
                  placeholder=" Season Life" formControlName="date" [(ngModel)]="date"
                  [locale]="{format: 'DD MMM YYYY', separator: '     To     '}"
                  style="display: inline-block; width: 80%; padding: 5px; height: 40px;"
                  [ngClass]="darkMode ? 'whiteColDark' : 'null'">
                <span class="info1"><i class="fas fa-info-circle i" style="font-size: 15px;"
                    [ngClass]="darkMode ? 'i_dark' : 'i'">
                    <span class="tooltiptext3" [ngClass]="darkMode ? 'tool_dark' : 'tooltiptext3'"
                      style="width: 395px;">
                      <p class="small   no-margin" style=" padding: 0px !important; margin: 0px !important;">
                        Season Start and End Date. Make Sure Dates should not confilict with other seasons.
                      </p>
                    </span>
                  </i>
                </span>
              </div>
              <div *ngIf="submitted && f.date_from.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.date_from.errors.required">Start Date is Required</strong>
              </div>
              <div *ngIf="submitted && f.date_to.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.date_to.errors.required">End Date is Required</strong>
              </div>
            </div>
          </div>

          <div class="col-md-4" style="padding-left: 3px;">
            <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
              <div class="border-style color-jj" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">

                <div class="" [ngClass]="darkMode?'radio-box_dark':'radio-box'">
                  <label data-toggle="tooltip" title="Peak" class="control-label radio-style chbx ellipses hovbulit"
                    [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('radio1','radio1T')"
                    (mouseout)="colorRadio('radio1','radio1T')">
                    <input type="radio" class="pull-left radio" name="demand_type" value="Peak"
                      formControlName="demand_type">
                    <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                      id="radio1"></span>
                    <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="radio1T">Peak</span>
                  </label>
                </div>

                <div class="" [ngClass]="darkMode?'radio-box_dark':'radio-box'">
                  <label data-toggle="tooltip" title="High" class="control-label radio-style chbx ellipses  hovbulit"
                    [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('radio2','radio2T')"
                    (mouseout)="colorRadio('radio2','radio2T')">
                    <input type="radio" class="pull-left radio" name="demand_type" value="High"
                      formControlName="demand_type">
                    <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                      id="radio2"></span>
                    <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="radio2T">High</span>
                  </label>
                </div>

                <div class="" [ngClass]="darkMode?'radio-box_dark':'radio-box'">
                  <label data-toggle="tooltip" title="Low" class="control-label radio-style chbx ellipses hovbulit"
                    [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('radio3','radio3T')"
                    (mouseout)="colorRadio('radio3','radio3T')" b>
                    <input type="radio" class="pull-left radio" name="demand_type" value="Low"
                      formControlName="demand_type">
                    <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                      id="radio3"></span>
                    <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="radio3T">
                      Low
                    </span>
                  </label>
                </div>

              </div>
              <div *ngIf="submitted && f.demand_type.errors" class="small text-danger no-margin invalid-feedback">
                <strong *ngIf="f.demand_type.errors.required">Demand Type is Required</strong>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="package-name margin-col-1" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
              Room Type Available
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">

          <div class="col-md-12" style="padding-left: 5px;">


            <div class="col-md-6" *ngFor="let a of addRooms;  let si = index;"
              style="padding-right: 0px;padding-left: 5px;">
              <div class="card margin-bottom" style="height: 50px !important;"
                [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

                <div class="row d-flex" style="padding: 0px 1px 0 10px;">
                  <div class="col-md-10 selectroomItem">
                    <div class="border-style country-shade dropdown-pd" style="display: inline-block;width:100%;"
                      [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
                      <div class="form-control input_2 pkg_rooms"
                        style="vertical-align: middle;line-height: 40px;color: #00205b;background: #f3f3f3;border-radius: 0;border: none;"
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">{{a.display_name || a.roomtype}}

                        <span style="float: right;">
                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                            title="Allowed" *ngIf="a.pets === 'true'"
                            [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                            title="Not Allowed" *ngIf="a.pets === 'false'"
                            [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                        </span>

                        <span style="float: right;">
                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                            title="Not Allowed" *ngIf="a.wheelchairaccessibility === 'false'"
                            [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />

                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                            title="Allowed" *ngIf="a.wheelchairaccessibility === 'true'"
                            [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                        </span>

                        <span style="float: right;">
                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                            title="Not Allowed" *ngIf="a.smoking === 'false'"
                            [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}" />

                          <img
                            src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                            title="Allowed" *ngIf="a.smoking === 'true'"
                            [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1" style="padding: 0px; width: 60px !important;">
                    <div class="div"
                      style="margin-left: 5px;height: 40px; width: 40px; background: linear-gradient(#30319a, #062460); border-radius: 10px;">
                      <span class="btn-primary btn-hov" style="
                    justify-content: center;
                    display: flex;
                    /* text-align: center; */
                    align-items: center;
                    height: 100%;
                    border-radius: 9px;
                    font-size: 14px;
                    font-weight: 800;
                    border: none;" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"
                        [ngClass]="darkMode ? 'card-dark-top-nav-icon' : ' card-null '">{{a.room_count}} </span>
                    </div>
                  </div>
                  <div class="col-md-1" style="padding: 0px; width: 40px !important;">
                    <!-- <a class="btn btn-primary btn-hov" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"
                  style="height: 40px;"
                     [ngClass]="darkMode ? 'card-dark-top-nav-icon' : ' card-null '">{{a.room_count}} </a> -->
                    <label for="allS{{a.id}}">

                      <input class="checkbox" id="allS{{a.id}}" name="allSelect" type="checkbox"
                        (change)="checkAllChannel($event)" value="{{a.id}}" />
                      <div class="button red"></div>
                    </label>
                  </div>
                </div>

                <!-- <div class="border-style country-shade dropdown-pd"  style="display: inline-block;width: 92.9%;" [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
                  <div class="form-control input_2 pkg_rooms" style="vertical-align: middle;line-height: 40px;color: #00205b;background: #f3f3f3;border-radius: 0;border: none;" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">{{a.roomtype}}</div>
                </div>
                <a class="btn btn-danger" style="margin-left: 6.3px;margin-top: -3px;height: 40px;padding: 10px 16px;" (click)="removeType(si)" [ngClass]="darkMode ? 'card-dark-top-nav-icon' : ' card-null '"><i class="fa fa-times"></i></a> -->
              </div>
            </div>

            <div *ngIf="edit == true;">
              <div class="col-md-6" *ngFor="let a of editRooms;  let ei = index;"
                style="padding-right: 0px;padding-left: 5px;">
                <div class="card margin-bottom" style="height: 50px !important;"
                  [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

                  <div class="row" style="padding: 0px 1px 0 10px;">
                    <div class="col-md-10" style="padding-right: 0px; padding-left: 5px; width: 89.8% !important;">
                      <div class="border-style country-shade dropdown-pd" style="display: inline-block;width: 100%;"
                        [ngClass]="darkMode ? 'card-dark-top-cut' : ' card-null '">
                        <div class="form-control input_2 pkg_rooms"
                          style="vertical-align: middle;line-height: 40px;color: #00205b;background: #f3f3f3; border-radius: 0;border: none;"
                          [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">{{a.display_name ||
                          a.roomtype.name}}
                          <span style="float: right;">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                              title="Allowed" *ngIf="a.pets === 'true'"
                              [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                              title="Not Allowed" *ngIf="a.pets === 'false'"
                              [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                          </span>

                          <span style="float: right;">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                              title="Not Allowed" *ngIf="a.wheelchairaccessibility === 'false'"
                              [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />

                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                              title="Allowed" *ngIf="a.wheelchairaccessibility === 'true'"
                              [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px', 'height': '35px'} : {'margin': '-10px 5px -5px 0px','height': '20px'}" />
                          </span>

                          <span style="float: right;">
                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                              title="Not Allowed" *ngIf="a.smoking === 'false'"
                              [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}" />

                            <img
                              src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                              title="Allowed" *ngIf="a.smoking === 'true'"
                              [ngStyle]="darkMode ? {'margin' : '-15px 5px -5px 0px', 'height': '35px'} : {'margin': '-15px 5px -5px 0px','height': '20px'}" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-1" style="padding: 0px;">
                      <a class="btn btn-danger" style="margin-left: 5px;height: 40px;padding: 10px 17px 10px 18px;"
                        (click)="removeEditType(ei)" [ngClass]="darkMode ? 'card-dark-top-nav-icon' : ' card-null '"><i
                          class="fa fa-times"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div *ngIf="showErr == true" style="font-size: 80%; color: #dc3545; margin-left:30px;font-weight: 700;">Please
          Select at least one Room Type</div>
      </div>
    </div>



  </div>
  <div class="modal-footer text-right" id="ModalFooter" style="bottom: -50px;right: 10px!important">
    <button class="btn btn-info btn-lg" [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">Save</button>
    <button class="btn btn-danger btn-lg" aria-label="Close" data-dismiss="modal" type="button" (click)="onClose()"
      [ngClass]="darkMode ? 'card-dark-top-nav-close' : ' card-null '">Cancel</button>
  </div>
</form>