import { Injectable } from '@angular/core';
import { ReportByNewRoomResponse } from "../common/reports/ReportByNewRoomModel";
import { GetRoomGraphDataResponse } from "../common/reports/GetRoomGraphDataModel";
import { SortByNewRoomResponse } from "../common/reports/SortByNewRoomModel";
import { GraphDataResponse } from "../common/reports/GraphDataModel";
import { ReportsBarAndListResponse } from "../common/reports/ReportsBarAndListModel";
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private ReportByRoomNewEndpoint: string = '/api/report/report_by_room_new';
  private ReportBarAndListEndpoint: string = '/api/report/report_bar_and_list';
  private ReportByRoomNewBySortEndpoint: string = '/api/report/report_by_room_new_by_sort';
  private GetRoomGraphDataEndpoint: string = '/api/report/get_room_graph_data';
  private GetGraphDataEndpoint: string = '/api/report/get_graph_data';
  private ChannelPerformanceEndpoint: string = '/api/report/channel_performance';

  constructor(private api: ApiService, private http: HttpClient) { }

  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set("Authorization", bearer_token);
    return header

  }
  reportByNewRoom(payload: { property_id: string, date_departure_from: string, date_departure_to: string }): Observable<ReportByNewRoomResponse> {
    return this.http.post<ReportByNewRoomResponse>(this.api.base_url + this.ReportByRoomNewEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    })
  }

  getRoomGraphData(payload: { property_id: string, date_departure_from: string, date_departure_to: string }): Observable<GetRoomGraphDataResponse> {
    return this.http.post<GetRoomGraphDataResponse>(this.api.base_url + this.GetRoomGraphDataEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    })
  }

  getGraphData(payload: { property_id: string, date_departure_from: string, date_departure_to: string }): Observable<GraphDataResponse> {
    return this.http.post<GraphDataResponse>(this.api.base_url + this.GetGraphDataEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    })
  }

  sortByNewRoom(payload: { property_id: string, date_departure_from: string, date_departure_to: string }): Observable<SortByNewRoomResponse> {
    return this.http.post<SortByNewRoomResponse>(this.api.base_url + this.ReportByRoomNewBySortEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    })
  }

  reportsBarAndList(payload: { type: string, property_id: string, date_departure_from: string, date_departure_to: string }): Observable<ReportsBarAndListResponse> {
    return this.http.post<ReportsBarAndListResponse>(this.api.base_url + this.ReportBarAndListEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    })
  }

}
