import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { Router, ActivatedRoute } from "@angular/router";
@Injectable()
export class AuthService {

  constructor(
    private router: Router,
    private api: ApiService
  ) { }

  auth_token;

  authenticate(user: string, pass: string) {
    return this.api.post("accessToken", {
      username: user,
      password: pass,
      scope: "*",
      grant_type: "password",
      client_id: 9,
      client_secret: "pi8vKIAqefCr7KadJwr3uFRO8MdPl6Y104ybiQNZ"
    }, false);
  }

  forgot(email: string) {
    return this.api.post("api/helper/forget_password", {
      'email': email
    }, false);
  }

  reset_password(pass: string, email: string, token: string) {
    return this.api.post("api/helper/update_password", {
      'email': email,
      'token': token,
      'password': pass
    }, false);
  }

  get check_auth(): boolean {
    let check = localStorage.getItem('pms_auth_token');
    return check != null;
  }

  logout() {
    localStorage.clear();
    return this.router.navigateByUrl('/');
  }

}
