<div id="createrateDiv">

  <div class="alert alert-info alert-dismissible show" role="alert">
    {{ service.is_edit == true ? "Edit Internal Package" : "Create Internal Package" }}
    <button type="button" class="close op-adj" data-dismiss="modal" aria-label="Close">
      <strong id="cross">&#x2716;</strong>
    </button>
  </div>

  <div class="alert alert-danger breadcrum">
    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
    <a routerLink="/app/rates_packages/setup_rate" class="btn btn-link active"> <span class="abc">{{ service.is_edit ? "EDIT" : "SETUP" }}
        RATE</span></a>
  </div>

  <div class="row grid_20">
    <ul class="nav nav-tabs">
      <li class="col-md-6 direct_input" [ngClass]="{'active': package_devried == 'No'}"
        (click)="package_devried = 'No'"><a data-toggle="tab" href="#linked">By Direct Input</a></li>
      <li class="col-md-6 selecting_package" [ngClass]="{'active': package_devried == 'Yes'}"
        (click)="package_devried = 'Yes'"><a data-toggle="tab" href="#internal">By Preset Package</a></li>
    </ul>
  </div>

  <!-- <div class="my-div" style="padding-right: 5px"> 
    <div class="gradient-bar adj">
      <h4>Input Room Rate</h4>
    </div>
  </div> -->

  <div class="tab-content">



    <div id="linked" class="tab-pane fade" [ngClass]="{'in active': package_devried == 'No'}">
      <form [formGroup]="setup_rate_form" class="form-horizontal back-form pr">
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="row field-space">
                <div class="col-md-12">
                  <div class="col-md-6">
                    <div class="border-style enter-room-shade">
                      <input type="number" formControlName="room_rate" class="form-control"
                        placeholder="Please Enter Room Rate" [(ngModel)]="room_rate">
                    </div>
                    <div *ngIf="submitted && f.room_rate.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.room_rate.errors.required">Room rate should not be empty</strong>
                    </div>
                  </div>
                  <!-- <div class="col-md-6" style="padding-left: 25px;padding-top: 15px">
  
                    <input type="checkbox" id="test1" />
                    <label for="test1" style="margin-right: 15px">Input Daily Rates</label>
  
                    <input type="checkbox" id="test2"  />
                    <label for="test2">Acquire frm another package</label>
                    
                  </div> -->
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- <div class="gradient-bar adj">
            <h4>Select from Rate Package</h4>
          </div>
  
          <div class="card">
              <div class="row">
                <div class="col-md-12">
                  <div class="row field-space">
                    <div class="col-md-12">
                      <div class="col-md-6" style="padding-right: 0px;">
                        <div class="border-style daily-rate-shade" style="border-color:#0095c4 !important ">
                          <ng-select  placeholder="Please Select a Rate Plane" >
                            <ng-option >Rate Package - 1</ng-option>
                            <ng-option >Rate Package - 2</ng-option>
                            <ng-option >Rate Package - 3</ng-option>
                            <ng-option >Rate Package - 4</ng-option>
                            <ng-option >Standard Rate Package</ng-option>
                            
                          </ng-select>
                        </div>
                       
                      </div>
                      
                     
                     
                    </div>
                  </div>
                </div>
              </div>
      
            </div> -->



        <div class="gradient-bar adj">
          <h4>Manage Daily Rates By</h4>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="row field-space">
                <div class="col-md-12">
                  <div class="col-md-6" style="padding-right: 0px;">
                    <div class="border-style daily-rate-shade">
                      <ng-select formControlName="manage_daily_rates" placeholder="Please Select a Value"
                        [(ngModel)]="manage_daily_rates">
                        <ng-option value="%">%</ng-option>
                        <ng-option value="$">$</ng-option>
                        <ng-option value="$ + %">$ + %</ng-option>
                        <ng-option value="% + $">% + $</ng-option>
                        <!-- <ng-option *ngFor="let country of countries" [value]="country"> {{ country }} </option> -->
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && check_form.get('manage_daily_rates').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please select a value from from manage daily
                        rates</strong>
                    </div>
                  </div>
                  <div class="col-md-3 p-l" *ngIf="manage_daily_rates == '$' || manage_daily_rates == '$ + %'" style="padding-left: 5px;
                  padding-right: 0px;">
                    <div class="incremental">
                      <div class="border-style per-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" name="manage_dollar" placeholder="$" min="0"
                            formControlName="manage_dollar" [(ngModel)]="manage_dollar" id="manage_dollar">
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('+','manage_dollar')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('-','manage_dollar')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="submitted && manage_dollar_error" class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="manage_dollar_error">Dollar amount is required</strong>
                                </div> -->
                    </div>

                  </div>
                  <div class="col-md-3 p-l" *ngIf="manage_daily_rates == '%' || manage_daily_rates == '$ + %'" style="padding-left: 5px;
                  padding-right: 10px;">
                    <div class="incremental">
                      <div class="border-style per-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" name="manage_percentage" placeholder="%" min="0"
                            formControlName="manage_percentage" [(ngModel)]="manage_percentage" id="manage_percentage">
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('+','manage_percentage')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('-','manage_percentage')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="submitted && manage_dollar_error" class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="manage_dollar_error">Dollar amount is required</strong>
                                </div> -->
                    </div>

                  </div>
                  <div *ngIf="manage_daily_rates == '% + $'">
                    <div class="col-md-3 p-l" style="padding-left: 5px;
                    padding-right: 0px;">
                      <div class="incremental">
                        <div class="border-style per-shade">
                          <div class="input-group">
                            <input type="number" class="form-control" name="manage_percentage" placeholder="%" min="0"
                              formControlName="manage_percentage" [(ngModel)]="manage_percentage"
                              id="manage_percentage">
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('+','manage_percentage')"><i class="fa fa-plus"></i></a>
                            </div>
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('-','manage_percentage')"><i
                                  class="fa fa-minus"></i></a>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="submitted && manage_percentage_error" class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="manage_percentage_error">Percentage is required</strong>
                                  </div> -->
                      </div>
                    </div>
                    <div class="col-md-3 p-l" style="padding-left: 5px;
                    padding-right: 10px;">
                      <div class="incremental">
                        <div class="border-style per-shade">
                          <div class="input-group">
                            <input type="number" class="form-control" name="manage_dollar" placeholder="$" min="0"
                              formControlName="manage_dollar" [(ngModel)]="manage_dollar" id="manage_dollar">
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('+','manage_dollar')"><i class="fa fa-plus"></i></a>
                            </div>
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('-','manage_dollar')"><i class="fa fa-minus"></i></a>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="submitted && manage_percentage_error" class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="manage_percentage_error">Percentage is required</strong>
                                  </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div class="modal-footer setup-r-1" style="bottom: -55px !important;left: -12px!important;">
        <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit()">Save</button>
        <button class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</button>
      </div>
    </div>

    <div id="internal" class="tab-pane fade" [ngClass]="{'in active': package_devried == 'Yes'}">
      <form [formGroup]="check_form" class="form-horizontal back-form pr">
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="row field-space">
                <div class="col-md-12">
                  <div class="col-md-6" style="padding-right: 0px;">
                    <div class="border-style rate-package">
                      <ng-select formControlName="rate_plan_id" placeholder="Please Select a Rate Plan"
                        [items]="packages" bindLabel="package_name" bindValue="id"
                        (change)="package_select($event)"></ng-select>
                    </div>
                    <div *ngIf="submitted && check_form.get('rate_plan_id').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Select any package</strong>
                    </div>
                  </div>

                  <div class="col-md-6" style="padding-left: 5px;padding-right: 10px;">
                    <div class="border-style enter-room-shade">
                      <input type="number" formControlName="room_rate" class="form-control"
                        placeholder="Please Enter Room Rate" [(ngModel)]="room_rate">
                    </div>
                    <div *ngIf="submitted && f.room_rate.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.room_rate.errors.required">Room rate should not be empty</strong>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="gradient-bar adj">
          <h4>Manage Daily Rates By</h4>
        </div>
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="row field-space">
                <div class="col-md-12">
                  <div class="col-md-6" style="padding-right: 0px;">
                    <div class="border-style daily-rate-shade">
                      <ng-select formControlName="manage_daily_rates" id="checkChange"
                        placeholder="Please Select a Value" [(ngModel)]="manage_daily_rates">
                        <ng-option value="%">%</ng-option>
                        <ng-option value="$">$</ng-option>
                        <ng-option value="$ + %">$ + %</ng-option>
                        <ng-option value="% + $">% + $</ng-option>
                      </ng-select>
                    </div>
                    <div *ngIf="submitted && check_form.get('manage_daily_rates').errors?.required"
                      class="small text-danger no-margin invalid-feedback">
                      <strong>Please enter charge amount</strong>
                    </div>
                  </div>
                  <div class="col-md-3 p-l" *ngIf="manage_daily_rates == '$' || manage_daily_rates == '$ + %'" style="padding-left: 5px;
                  padding-right: 0px;">
                    <div class="incremental">
                      <div class="border-style per-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" name="manage_dollar" placeholder="$" min="0"
                            formControlName="manage_dollar" [(ngModel)]="manage_dollar" id="manage_dollars">
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('+','manage_dollars')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('-','manage_dollars')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="submitted && manage_dollar_error" class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="manage_dollar_error">Dollar amount is required</strong>
                                </div> -->
                    </div>

                  </div>
                  <div class="col-md-3 p-l" *ngIf="manage_daily_rates == '%' || manage_daily_rates == '$ + %'"
                    style="padding-left: 5px;padding-right: 10px;">
                    <div class="incremental">
                      <div class="border-style per-shade">
                        <div class="input-group">
                          <input type="number" class="form-control" name="manage_percentage" placeholder="%" min="0"
                            formControlName="manage_percentage" [(ngModel)]="manage_percentage" id="manage_percentages">
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('+','manage_percentages')"><i class="fa fa-plus"></i></a>
                          </div>
                          <div class="input-group-addon addon-border addon-borders">
                            <a class="btn" (click)="calculate('-','manage_percentages')"><i class="fa fa-minus"></i></a>
                          </div>
                        </div>
                      </div>
                      <!-- <div *ngIf="submitted && manage_dollar_error" class="small text-danger no-margin invalid-feedback">
                                  <strong *ngIf="manage_dollar_error">Dollar amount is required</strong>
                                </div> -->
                    </div>

                  </div>
                  <div *ngIf="manage_daily_rates == '% + $'">
                    <div class="col-md-3 p-l" style="padding-left: 5px;padding-right: 0px;">
                      <div class="incremental">
                        <div class="border-style per-shade">
                          <div class="input-group">
                            <input type="number" class="form-control" name="manage_percentage" placeholder="%" min="0"
                              formControlName="manage_percentage" [(ngModel)]="manage_percentage"
                              id="manage_percentages">
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('+','manage_percentages')"><i
                                  class="fa fa-plus"></i></a>
                            </div>
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('-','manage_percentages')"><i
                                  class="fa fa-minus"></i></a>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="submitted && manage_percentage_error" class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="manage_percentage_error">Percentage is required</strong>
                                  </div> -->
                      </div>
                    </div>
                    <div class="col-md-3 p-l" style="padding-left: 5px;padding-right: 10px;">
                      <div class="incremental">
                        <div class="border-style per-shade">
                          <div class="input-group">
                            <input type="number" class="form-control" name="manage_dollar" placeholder="$" min="0"
                              formControlName="manage_dollar" [(ngModel)]="manage_dollar" id="manage_dollars">
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('+','manage_dollars')"><i class="fa fa-plus"></i></a>
                            </div>
                            <div class="input-group-addon addon-border addon-borders">
                              <a class="btn" (click)="calculate('-','manage_dollars')"><i class="fa fa-minus"></i></a>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="submitted && manage_percentage_error" class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="manage_percentage_error">Percentage is required</strong>
                                  </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div class="modal-footer setup-r-0" style="bottom: -55px !important;left: -12px!important;">
        <button type="submit" class="btn btn-primary btn-lg" (click)="onSubmit_check()">Save</button>
        <button class="btn btn-danger btn-lg" data-dismiss="modal" aria-label="Close">Cancel</button>
      </div>
    </div>


  </div>

</div>