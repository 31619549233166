<div class="Croom">


    <!-- <div class="alert alert-info alert-dismissible show" role="alert">

        You've got 3 notifications and 2 keynotes, everything else looks smooth
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <strong id="cross">&#x2716;</strong>
        </button>
    </div> -->

    <div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'dark_bredcrum' : ' breadcrum'">
        <a href="" data-dismiss="modal" aria-label="Close" class="btn btn-link">BACK OFFICE</a>
        <a href="" class="btn btn-link active">CREATE ROOMS</a>
    </div>
    <div class="scroll no_scroll">
        <form class="form-horizontal back-form" [formGroup]="create_room_form">
            <!-- <form class="form-horizontal back-form" [formGroup]="create_room_form" (ngSubmit)="onSubmit(create_room_form.value)"> -->

            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row field-space">
                            <div class="col-md-4 grid_20">
                                <div class="border-style b-1 roomtype-shade1">
                                    <ng-select [items]="roomtypes" bindLabel="name" bindValue="id"
                                        formControlName="room_type_id" [(ngModel)]="room_type"
                                        placeholder="Select Room Type" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.room_type_id.errors"
                                    class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="f.room_type_id.errors.required">Required</strong>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">

                                <div>
                                    <div class="border-style roomsize-shade"
                                        style="width: 100%; display: inline-block;margin-right: 5px;border-color:#9C27B0;">
                                        <input type="text" class="form-control" formControlName="room_count"
                                            placeholder="Room count" />
                                    </div>
                                    <div *ngIf="submitted && f.room_count.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.room_count.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div>
                                    <div class="border-style roomsize-shade2"
                                        style="width: 100%; display: inline-block;border-color: #00ff1f;">
                                        <!-- <ng-select [items]="roomclass" bindLabel="name" bindValue="id" formControlName="room_type_class" [(ngModel)]="room_type_class" placeholder="Select Room Type Class">
                                  </ng-select> -->
                                        <ng-select formControlName="room_type_class" [(ngModel)]="room_classs"
                                            placeholder="Select Room Type Class"
                                            [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                            <ng-option *ngFor="let re of roomclass"
                                                value="{{re.id}}">{{re.name}}</ng-option>

                                        </ng-select>
                                    </div>
                                    <div *ngIf="submitted && f.room_type_class.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.room_type_class.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row field-space">
                            <div class="col-md-4 grid_20">
                                <div class="border-style b-1 roomtype-shade2">
                                    <ng-select [items]="roomView" bindLabel="name" bindValue="id"
                                        formControlName="room_view_id" [(ngModel)]="room_view"
                                        placeholder="Select Room View" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.room_view_id.errors"
                                    class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="f.room_view_id.errors.required">Required</strong>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div>
                                    <div class="border-style roomsize-shade"
                                        style="width: 100%; display: inline-block;margin-right: 5px;border-color: #03A9F4;">
                                        <input type="text" class="form-control" formControlName="room_size"
                                            placeholder="Room Size" />
                                    </div>
                                    <div *ngIf="submitted && f.room_size.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.room_size.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div>
                                    <div class="border-style roomsize-shade2"
                                        style="width: 100%; display: inline-block;border-color: #ff51f3;">
                                        <ng-select placeholder="Select Area Type" formControlName="room_size_type"
                                            [(ngModel)]="room_Size_Type" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                            <ng-option value="meters">Square Meters</ng-option>
                                            <ng-option value="square_feet">Square Feet</ng-option>
                                        </ng-select>
                                    </div>
                                    <div *ngIf="submitted && f.room_size_type.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.room_size_type.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row field-space">
                            <div class="col-md-4 grid_20">
                                <div class="border-style b-4 bedType-shade1">
                                    <ng-select [items]="bedtypes" bindLabel="name" bindValue="id"
                                        formControlName="bed_type_id" placeholder="Select Bed Type"
                                        [(ngModel)]="bed_types" (change)="bedChange($event, bed_types)"
                                        [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                    </ng-select>
                                </div>
                                <div *ngIf="submitted && f.bed_type_id.errors"
                                    class="small text-danger no-margin invalid-feedback">
                                    <strong *ngIf="f.bed_type_id.errors.required">Required</strong>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div style="float: left;width: 100%;">
                                    <div class="border-style no-of-shade"
                                        style="width: 100%; display: inline-block;margin-right: 5px;border-color: #E91E63;">
                                        <input type="text" class="form-control" formControlName="no_of_beds"
                                            placeholder="No of beds" />
                                    </div>
                                    <div *ngIf="submitted && f.no_of_beds.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.no_of_beds.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20" style="padding-right: 0px;">
                                <div style="float: right;width: 100%;">
                                    <div class="border-style roomsize-shade2"
                                        style="width: 100%; display: inline-block;border-color: #057071;">
                                        <ng-select [items]="bedSize" bindLabel="name" bindValue="name"
                                            formControlName="bedsize" [(ngModel)]="bedsize"
                                            placeholder="Select Bed Size" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                        </ng-select>
                                    </div>
                                    <div *ngIf="submitted && f.bedsize.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.bedsize.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 grid_20">
                        <div style="float: right;width: 100%;">
                            <div class="border-style roomsize-shade"
                                style="width: 100%; display: inline-block;margin-right: 5px;border-color: #03A9F4;">
                                <input type="text" class="form-control" formControlName="display_name"
                                    placeholder="Room Display Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12 dFlex">
                        <div class="col-md-6 grid_20">
                            <div class="border-style b-4 bedType-shade2">
                                <ng-select class="addInclud" [items]="addInclu" bindLabel="name" bindValue="id"
                                    formControlName="value_add_inclusion" [(ngModel)]="value_add_inclusions"
                                    placeholder="Select Value Added Inclusion" [multiple]="true"
                                    [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                            </div>
                            <div *ngIf="submitted && f.value_add_inclusion.errors"
                                class="small text-danger no-margin invalid-feedback">
                                <strong *ngIf="f.value_add_inclusion.errors.required">Required</strong>
                            </div>
                        </div>
                        <div class="col-md-6 grid_20">
                            <!-- <div class="smoker">
                              <label class="control-label radio-style">
                                  <input type="radio" class="radio" name="smoking" value="Allowed" formControlName="smoking">
                                  <img class="img-responsive" src="assets/img/create-rooms/smoking.png">
                                  <span class="smoke-btn-txt">Smoker's Room</span>
                              </label>
                          </div>
                          <div class="smoker">
                              <label class="control-label radio-style">
                                  <input type="radio" class="radio" name="smoking" value="Not_Allowed" formControlName="smoking">
                                  <img class="img-responsive" src="assets/img/create-rooms/Asset_8.png">
                                  <span class="smoke-btn-txt">Non Smoker's Room</span>
                              </label>
                          </div>
                          <div *ngIf="submitted && f.smoking.errors" class="small text-danger no-margin invalid-feedback">
                              <strong *ngIf="f.smoking.errors.required">Smoking / No Smoking is required</strong>
                            </div> -->
                            <div class="row" style="margin-left: -30px;">
                                <div class="col-md-4 zero-padding" style="height: 40px;">

                                    <div class="smoker" style="width: 100%; padding-left: 10px;" *ngIf="smoke == false"
                                        (click)="smokey()" [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px;"> <img
                                                    class="img-responsive icn-size"
                                                    src="assets/img/back-office/cigno.svg">
                                            </div>
                                            <div class="chemms"> <span class="" style="font-size: 12px;">Non
                                                    Smoking</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="smoker" style="width: 100%;" *ngIf="smoke == true" (click)="smokey()"
                                        [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px; margin-left: 10px;">
                                                <img class="img-responsive icn-size"
                                                    src="assets/img/back-office/cigyes.svg" style="opacity: 1;">
                                            </div>
                                            <div style="width: 50%;float: left;padding-top:10px;padding-right: 2px;">
                                                <span class="" style="font-size: 12px;opacity: 1;">Smoking</span>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                                <div class="col-md-4 zero-padding" style="height: 40px;">

                                    <div class="smoker" style="width: 100%;" *ngIf="petsA == false"
                                        (click)="petsAllowed()" [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px;"> <img
                                                    class="img-responsive icn-size"
                                                    src="assets/img/back-office/dogno.svg">
                                            </div>
                                            <div class="dogie"> <span class="" style="font-size: 12px;">Pet Not
                                                    Allowed</span> </div>
                                        </label>
                                    </div>
                                    <div class="smoker" style="width: 100%;" *ngIf="petsA == true"
                                        (click)="petsAllowed()" [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px;"> <img
                                                    class="img-responsive icn-size"
                                                    src="assets/img/back-office/dogyes.svg" style="opacity: 1"> </div>
                                            <div class="heuheuheu"> <span class=""
                                                    style="font-size: 12px;opacity: 1;">Pet
                                                    Allowed</span> </div>
                                        </label>
                                    </div>

                                </div>
                                <div class="col-md-4 zero-padding" style="height: 40px;">

                                    <div class="smoker" style="width: 100%;" *ngIf="wheelc == false"
                                        (click)="wheelchair()" [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px;"> <img
                                                    class="img-responsive icn-size"
                                                    src="assets/img/back-office/wheelno.svg"> </div>
                                            <div style="width: 50%;float: left;padding-top: 10px;padding-right: 12px;"
                                                class="wheel">
                                                <span class="" style="font-size: 12px;" class="wheel">Wheel chair</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div class="smoker" style="width: 100%;" *ngIf="wheelc == true"
                                        (click)="wheelchair()" [ngClass]="darkMode ? 'dark_smoker' : 'null'">
                                        <label class="control-label radio-style">
                                            <div style="width: 33%;float: left;margin-top: -4px;"> <img
                                                    class="img-responsive icn-size"
                                                    src="assets/img/back-office/wheelyes.svg" style="opacity: 1"> </div>
                                            <div style="width: 50%;float: left;padding-top: 10px;padding-right: 12px;"
                                                class="wheel">
                                                <span class="" style="font-size: 12px;opacity: 1;" class="wheel">Wheel
                                                    chair</span>
                                            </div>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="cribsBar cr1">
                            <div class="row">
                                <div class="col-md-6 text-left">
                                    <div class="cribsHeading">Add extra to this room.</div>
                                </div>
                                <div class="col-md-6 text-right">
                                    <div class="cribsDrop">
                                        <a data-toggle="collapse" class="nul" href="#collapse100121">
                                            <img src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                                id="rotate180" class="imagedrop rotate" height="20" width="20" alt=""
                                                (click)="chandrop('rotate180');changeCrib() " /> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapse100121" class="text panel-collapse collapse ">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="CribsInfants">
                                        <div class="cribar frst cr2">
                                            <div class="inp oneH"> Person | Child </div>
                                        </div>
                                        <!-- <div class="cribar cr3">
                                              <div class="inp">
                                                  <ng-select placeholder="How Many?" class="CribsSlct" [items]="personNum" [(ngModel)]="pQuantity"  [ngModelOptions]="{standalone: true}" bindLabel="num" bindValue="num" [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                                              </div>
                                          </div> -->
                                        <!-- <div class="cribar cr4">
                                              <div class="inp">
                                                  <ng-select placeholder="Cost?" class="CribsSlct" (change)="changeFn3(pSurcharge_type)" [(ngModel)]="pSurcharge_type"  [ngModelOptions]="{standalone: true}"  [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                      <ng-option value="Free">Free</ng-option>
                                                      <ng-option value="Surcharge">Surcharge</ng-option>
                                                  </ng-select>
                                              </div>
                                          </div> -->
                                        <div class="cribar cr5" style="width: 40% !important;">
                                            <div class="inp">
                                                <input type="text" placeholder="Amount" [(ngModel)]="pAmount"
                                                    [ngModelOptions]="{standalone: true}" class="form-control"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                            </div>
                                        </div>
                                        <!-- <div class="cribar cr6" *ngIf="costCrib3 == 'Surcharge'" [ngClass]="darkMode ? 'dark-drop' : 'null'" >
                                              <div class="inp">
                                                  <ng-select placeholder="Select Type" class="CribsSlct" [(ngModel)]="cSurcharge_amount_type"  [ngModelOptions]="{standalone: true}" [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                      <ng-option value="per day">Per Day</ng-option>
                                                      <ng-option value="per night">Per Night</ng-option>
                                                      <ng-option value="per stay">Per Stay</ng-option>
                                                      <ng-option value="per week">Per Week</ng-option>
                                                  </ng-select>
                                              </div>
                                          </div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="CribsInfants">
                                        <div class="cribar frst cr2">
                                            <div class="inp oneH"> Cribs | Infant beds </div>
                                        </div>
                                        <div class="cribar cr3">
                                            <div class="inp">
                                                <ng-select placeholder="How Many?" class="CribsSlct" [items]="cribsNum"
                                                    [(ngModel)]="cQuantity" [ngModelOptions]="{standalone: true}"
                                                    bindLabel="num" bindValue="num"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                                            </div>
                                        </div>
                                        <div class="cribar cr4">
                                            <div class="inp">
                                                <ng-select placeholder="Cost?" class="CribsSlct"
                                                    (change)="changeFn(cSurcharge_type)" [(ngModel)]="cSurcharge_type"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                    <ng-option value="Free">Free</ng-option>
                                                    <ng-option value="Surcharge">Surcharge</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="cribar cr5" *ngIf="costCrib == 'Surcharge'">
                                            <div class="inp">
                                                <input type="text" placeholder="Amount" [(ngModel)]="cAmount"
                                                    [ngModelOptions]="{standalone: true}" class="form-control">
                                            </div>
                                        </div>
                                        <div class="cribar cr6" *ngIf="costCrib == 'Surcharge'">
                                            <div class="inp">
                                                <ng-select placeholder="Select Type" class="CribsSlct"
                                                    [(ngModel)]="cSurcharge_amount_type"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                    <ng-option value="per day">Per Day</ng-option>
                                                    <ng-option value="per night">Per Night</ng-option>
                                                    <ng-option value="per stay">Per Stay</ng-option>
                                                    <ng-option value="per week">Per Week</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="CribsInfants">
                                        <div class="cribar frst cr7">
                                            <div class="inp oneH"> Rollaway | Extra Beds </div>
                                        </div>
                                        <div class="cribar cr8">
                                            <div class="inp">
                                                <!-- <ng-select placeholder="How Many?" class="CribsSlct" [items]="RollWayNum" bindLabel="num" bindVale="num" (change)="val(rQuantity)" [(ngModel)]="rQuantity"></ng-select> -->
                                                <ng-select placeholder="How Many?" class="CribsSlct"
                                                    [items]="RollWayNum" (change)="val(rQuantity)"
                                                    [(ngModel)]="rQuantity" [ngModelOptions]="{standalone: true}"
                                                    bindLabel="num" bindValue="num"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'"></ng-select>
                                            </div>
                                        </div>
                                        <div class="cribar cr9">
                                            <div class="inp">
                                                <ng-select placeholder="Cost?" class="CribsSlct"
                                                    (change)="changeFn2(rSurcharge_type)" [(ngModel)]="rSurcharge_type"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                    <ng-option value="Free">Free</ng-option>
                                                    <ng-option value="Surcharge">Surcharge</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="cribar cr10" *ngIf="costCrib2 == 'Surcharge'">
                                            <div class="inp">
                                                <input type="text" placeholder="Amount" [(ngModel)]="rAmount"
                                                    class="form-control" [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                            </div>
                                        </div>
                                        <div class="cribar cr11" *ngIf="costCrib2 == 'Surcharge'">
                                            <div class="inp">
                                                <ng-select placeholder="Select Type" class="CribsSlct"
                                                    [(ngModel)]="rSurcharge_amount_type"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [ngClass]="darkMode ? 'dark-drop' : 'null'">
                                                    <ng-option value="per day">Per Day</ng-option>
                                                    <ng-option value="per night">Per Night</ng-option>
                                                    <ng-option value="per stay">Per Stay</ng-option>
                                                    <ng-option value="per week">Per Week</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div class="gradient-bar" [ngClass]="darkMode ? 'dark_bredcrum' : 'null'">
                <div class="">
                    <h4>
                        Ages
                        <i class="fa fa-info-circle">
                            <span class="tooltip" [ngClass]="darkMode ? 'dark' : 'light'">
                                <p>Age ranges should be selected and supported by otas during ota setup.</p>
                            </span>
                        </i>
                    </h4>
                </div>
            </div>

            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row field-space">
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-7 adults-shade1">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="adultAge"
                                                placeholder="Adults" min="0" id="adultAge" [(ngModel)]="adultAge"
                                                formControlName="adults_age">
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('+','adultAge')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','adultAge')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.adults_age.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.adults_age.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-8 children-shade1">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="childrenAge"
                                                placeholder="Children" id="childrenAge" [(ngModel)]="childrenAge"
                                                formControlName="children_age">
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('+','childrenAge')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','childrenAge')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.children_age.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.children_age.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-9 infants-shade">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="infantsAge"
                                                placeholder="Infants" id="infantsAge" [(ngModel)]="infantsAge"
                                                formControlName="infants_age">
                                            <div class="input-group-addon addon-border">
                                                <!-- <a class="btn" (click)="infants = infants + 1"><i class="fa fa-plus"></i></a> -->
                                                <a class="btn" (click)="calculate('+','infantsAge')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','infantsAge')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.infants_age.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.infants_age.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="gradient-bar gradient-bar1" [ngClass]="darkMode ? 'dark_bredcrum' : 'gradient-bar1'">
                <div class="">
                    <h4>Sleeps</h4>
                </div>
            </div>
            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row field-space">
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-7 adults-shade">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="adults" placeholder="Adults"
                                                formControlName="adults" min="0" id="adults" [(ngModel)]="adults">
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('+','adults')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','adults')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.adults.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.adults.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-8 children-shade">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="children"
                                                placeholder="Children" formControlName="children" id="children"
                                                [(ngModel)]="children">
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('+','children')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','children')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.children.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.children.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 grid_20">
                                <div class="incremental">
                                    <div class="border-style b-9 infants-shade">
                                        <div class="input-group">
                                            <input type="number" class="form-control" name="infants"
                                                placeholder="Infants" formControlName="infants" id="infants"
                                                [(ngModel)]="infants">
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('+','infants')"><i
                                                        class="fa fa-plus"></i></a>
                                            </div>
                                            <div class="input-group-addon addon-border">
                                                <a class="btn" (click)="calculate('-','infants')"><i
                                                        class="fa fa-minus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.infants.errors"
                                        class="small text-danger no-margin invalid-feedback">
                                        <strong *ngIf="f.infants.errors.required">Required</strong>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="card desCard" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <!-- <div class="row">
                  <div class="col-md-12">
                      <div class="border-style b-6 description-shade">
                              <textarea name=""  class="form-control" id="" cols="8" rows="4" placeholder="Description" formControlName="description"></textarea>
                      </div>
                      <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback">
                          <strong *ngIf="f.description.errors.required">Description is required</strong>
                        </div>
                  </div>
              </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="border-style b-6 description-shade">
                            <textarea name="" class="form-control txtdes" [ngClass]="darkMode ? 'txtdes_dark' : 'null'"
                                id="" cols="8" rows="4" placeholder="Description"
                                formControlName="description"></textarea>
                        </div>
                        <!-- <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback">
                      <strong *ngIf="f.description.errors.required">Required</strong>
                    </div> -->
                    </div>
                </div>
            </div>

            <!-- <div class="gradient-bar gradient-bar1">
              <div class="">
                <h4>Photos</h4>
              </div>
          </div> -->
            <div class="card imageCard" [ngClass]="darkMode ? 'card_dark' : 'card'"
                style="padding-right: 0px !important;">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row photo-box-wrapper">
                            <div class="col-md-12">
                                <div class="col-md-3 imageCol" *ngFor="let img of photo_list; let i = index">
                                    <div class="photo-box">
                                        <a class="" (click)="remove_photo(i)">
                                            <img width="25px" src="assets/img/manage-rooms/delete.png">
                                        </a>
                                        <span *ngIf="i == 1" class="pull-left main">Main</span>
                                        <img class="uploadedImg" src="{{ img.url }}" width="100%">
                                    </div>
                                </div>
                                <div class="col-md-3 imageCol" style="padding-right: 0px;margin-right: 0px;">
                                    <label class="btn btn-primary photo-style hov-add" (mouseover)="plusHover = true"
                                        (mouseout)="plusHover = false" style="height:133px;margin-bottom: 5px;">
                                        <input #photo_input type="file" name="photo" class="form-control"
                                            (change)="onFileChange($event)" multiple="true">
                                        <img src="assets/img/create-rooms/Asset_10.png" alt="" class="plusImg"
                                            *ngIf="plusHover == false">
                                        <img src="assets/img/back-office/add-image-yellow.svg" alt="" class="plusImg"
                                            *ngIf="plusHover == true">
                                        <br />
                                        <p> Add More Photos</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row field-space photo-box-wrapper">
                      <div class="col-md-12">
                          <div class="col-md-3 imageCol" style="padding-right: 0px">
                              <label class="btn btn-primary photo-style hov-add" (mouseover)="plusHover = true" (mouseout)="plusHover = false">
                                <input #photo_input type="file" name="photo" class="form-control" (change)="onFileChange($event)" multiple="true">
                                <img src="assets/img/create-rooms/Asset_10.png" alt="" class="plusImg" *ngIf="plusHover == false">
                                <img src="assets/img/back-office/add-image-yellow.svg" alt="" class="plusImg" *ngIf="plusHover == true">
                                <br/>
                                <p> Add More Photos</p>
                              </label>
                          </div>
                      </div>
                  </div> -->
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="submitted && f.room_name_images.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.room_name_images.errors.required">Required</strong>
            </div> -->

            <div *ngIf="enabled">

                <!-- <div class="gradient-bar">
                          <div class="">
                              <div class="col-md-11" style="padding-left: 0px"> <h4>Assign Room</h4> </div>
                              <div class="col-md-1 text-right" style="padding-right: 10px ; padding-top: 10px; padding-left: 60px">  </div>
                          </div>
                  </div> -->


                <!----   <div class="card" style="padding-top: 0px !important">

                              <div class="row">



                              <div class="col-md-12">

                                  <div class="card-2">


                                      <div class="row">
                                          <div class="col-md-12" >

                                                  <div class="col-md-4 " style="padding-left: 0px ; padding-right: 5px;">
                                                          <div class="border-style b-10 roomcount-shade">
                                                          <input type="text" class="form-control" placeholder="Building Name" formControlName="Building_name" style="background-color: white">
                                                          </div>

                                                      </div>

                                                      <div class="col-md-5 " style="padding-left: 0px ; padding-right: 95px">
                                                              <div class="border-style b-11 roomcount-shade">
                                                              <input type="text" class="form-control" placeholder="Inital" formControlName="Initial" style="background-color: white">
                                                              </div>

                                                          </div>

                                                  <div class="col-md-3" style="padding-left: 105px ; padding-right: 0px">
                                                      <button class="btn btn-block add-floor" > + Add Floor </button>
                                                  </div>


                                              <div class="col-md-2" style="padding-left: 0px;padding-right: 5px;margin-top: 5px ">
                                                  <div class="border-style b-12 roomcount-shade">
                                                          <input type="Number" class="form-control" placeholder="Floor No" formControlName="Floor_no" style="background-color: white">
                                                          </div>

                                                  <div class="border-style b-13 roomcount-shade" style="margin-top: 5px">
                                                                  <input type="Number" class="form-control" placeholder="Floor No" formControlName="Floor_no" style="background-color: white">
                                                                  </div>

                                          </div>
                                          <div class="col-md-10" style="padding-left: 0px ; padding-right: 0px;margin-top: 5px">
                                                  <div class="border-style b-14 roomcount-shade">
                                                          <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="background-color: white">
                                                          </div>

                                                  <div class="border-style b-15 roomcount-shade" style="margin-top: 5px">
                                                                  <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="background-color: white">
                                                                  </div>

                                          </div>

                                          </div>
                                      </div>


                                  </div>

                              </div>






                              <div class="col-md-12">

                                      <div class="card-2">


                                          <div class="row">
                                              <div class="col-md-12" >

                                                      <div class="col-md-4 " style="padding-left: 0px ; padding-right: 5px;">
                                                              <div class="border-style b-16 roomcount-shade">
                                                              <input type="text" class="form-control" placeholder="Building Name" formControlName="Building_name" style="background-color: white">
                                                              </div>

                                                          </div>

                                                          <div class="col-md-5 " style="padding-left: 0px ; padding-right: 95px">
                                                                  <div class="border-style b-17 roomcount-shade">
                                                                  <input type="text" class="form-control" placeholder="Inital" formControlName="Initial" style="background-color: white">
                                                                  </div>

                                                              </div>

                                                      <div class="col-md-3" style="padding-left: 105px ; padding-right: 0px">
                                                          <button class="btn btn-block add-floor" > + Add Floor </button>
                                                      </div>


                                                  <div class="col-md-2" style="padding-left: 0px;padding-right: 5px;margin-top: 5px ">
                                                      <div class="border-style b-18 roomcount-shade">
                                                              <input type="Number" class="form-control" placeholder="Floor No" formControlName="Floor_no" style="background-color: white">
                                                              </div>

                                                      <div class="border-style b-19 roomcount-shade" style="margin-top: 5px">
                                                                      <input type="Number" class="form-control" placeholder="Floor No" formControlName="Floor_no" style="background-color: white">
                                                                      </div>

                                              </div>
                                              <div class="col-md-10" style="padding-left: 0px ; padding-right: 0px;margin-top: 5px">
                                                      <div class="border-style b-20 roomcount-shade">
                                                              <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="background-color: white">
                                                              </div>

                                                      <div class="border-style b-21 roomcount-shade" style="margin-top: 5px">
                                                                      <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="background-color: white">
                                                                      </div>

                                              </div>

                                              </div>
                                          </div>


                                      </div>

                                  </div>




                              </div>

                          </div> -->
                <!-- <div  *ngFor="let cards of building_detail; let bi = index; ">
                          <div class="card" *ngIf="cards"  style="padding-bottom: 0px !important">

                              <div class="row">
                                  <div class="col-md-12">
                                                  <div class="row">
                                                      <div class="col-md-12" >
                                                      <div class="col-md-12" style="padding: 0px;margin-bottom: 5px">
                                                          <div class="col-md-2 " style="padding-left: 0px ; padding-right: 5px;">
                                                              <div class="border-style  roomcount-shade" [style.border-color]="cards.buildingroom">
                                                                      <ng-select  class="form-control input_2 padding-holder custom"  placeholder="Building Name" style="padding: 0px;border: none">
                                                                  </ng-select>
                                                                  <input type="text" [value]="cards.name" class="form-control"   placeholder="Building Name" id="car" disabled="true">
                                                              </div>
                                                                  <div class="border-style  roomcount-shade" style="margin-top: 5px" [style.border-color]="cards.buildingFloor">
                                                                      <ng-select  class="form-control input_2 padding-holder custom"  placeholder="Floor No" style="padding: 0px;border: none" (change)="onChange($event)" >
                                                                          <ng-option *ngFor="let f_no of cards.floors" value="{{f_no.id}},{{cards.id}},{{cards.name}},{{f_no.floor_no }}">{{f_no.floor_no }}</ng-option>
                                                                          </ng-select>

                                                                  </div>
                                                          </div>
                                                          <div class="col-md-10" style="padding-left: 0px ; padding-right: 0px;">
                                                              <div class="border-style  roomcount-shade room-d " [style.border-color]="cards.buildingcolor" >
                                                                      <div class="input-group" style="width: 100%;height:100%">

                                                                          Show Added Rooms
                                                                          <div *ngIf="add_floor_card.length > 0" >
                                                                              <span *ngIf="add_floor_card[bi] != undefined">
                                                                                  <div *ngFor="let dd of add_floor_card[bi].f_d; let fi = index;" class="new-chip"> <span (click)="rmFloor(fi, bi)" class="cross">X</span> <span class="slash">|</span> {{dd.room_numbers}}</div>
                                                                              </span>
                                                                          </div>
                                                                          Show Added Rooms END

                                                                          <div class="inp">
                                                                                  <input type="text" class="form-control" placeholder="Enter Room Number For Selected Floor"  id="{{cards.id}}" style="background-color: #f1f1f1;border-radius: 8px;height: 30px;padding:0px 10px; " maxlength="7" (keyup.enter)="onplus(cards.id)">
                                                                          </div>
                                                                          <div class="input-group-btn">
                                                                              <a class="btn btn-default bsr" (click)="onplus(cards.id)" style="background-color: #f1f1f1">
                                                                              <i class="glyphicon glyphicon-plus"></i>
                                                                              </a>
                                                                          </div>
                                                                      </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      </div>
                                                  </div>
                                          </div>
                              </div>
                      </div>
                      </div> -->
                <div>
                    <details style="outline: none" *ngFor="let cards of building_detail; let bi = index; " open>
                        <summary style="outline: none" (click)="changeCaret1(cards.id)">
                            <div class="gradient-bar" [ngClass]="bi%2 == 0 ? 'bl2B' : 'bl2R'">
                                <div class="">
                                    <div class="col-md-11" style="padding-left: 0px">
                                        <h4>{{cards.name}}</h4>
                                    </div>
                                    <div class="col-md-1 text-right caretIco"> <img
                                            src="assets/img/reports/{{darkMode ? 'button_down_dark' : 'button_down'}}.svg"
                                            class="imagedropp" height="20" width="20" alt="" id="rotate180{{bi}}"
                                            (click)="chandrop('rotate180'+bi)"> </div>
                                    <!-- <img src="assets/img/create-rooms/plus-4.png" class="img-responsive assign-plus" height="30px" width="30px" > -->
                                </div>
                            </div>
                        </summary>

                        <div class="card" style="padding-right: 0px!important;padding-bottom: 0px !important"
                            [ngClass]="darkMode ? 'card_dark' : 'card'">
                            <div class="row" style="padding-left: 15px; padding-right: 15px">
                                <div class="col-md-2 zero-padding wCard" style="padding-right: 5px;"
                                    *ngFor="let floor_No of cards.floors; let fi = index">
                                    <div class="col-md-12 zero-padding f-card "
                                        [ngClass]="darkMode ? 'f-card_dark' : ''">
                                        <details style="outline: none">
                                            <summary style="outline: none" (click)="changeCaret2(floor_No.id)">
                                                <div class="col-md-12 zero-padding"
                                                    style="padding: 0px 5px;outline: none">
                                                    <div class="col-md-6 zero-padding f-name"> <span
                                                            class="fl">FL</span>
                                                        <span class="s-line">|</span> <span
                                                            class="f-nm">{{floor_No.floor_no}}</span>
                                                    </div>
                                                    <div class="col-md-6 zero-padding text-right"
                                                        style="margin-top: 2px">
                                                        <i class="fa fa-caret-down down1" id="{{floor_No.id}}"></i>
                                                    </div>
                                                </div>
                                            </summary>

                                            <div class="col-md-12 zero-padding main-rooms">
                                                <div class="col-md-12 zero-padding"
                                                    style="padding: 0px 5px;margin-top: 5px"
                                                    *ngFor="let r of floor_No.roomnums; let ri = index">
                                                    <div class="col-md-6 zero-padding f-name"> <span class="r-no"
                                                            [ngClass]="darkMode ? 'rno-dark' :'null'">{{r.room_number}}</span>
                                                    </div>
                                                    <div class="col-md-6 zero-padding text-right"> <img
                                                            src="assets/img/hotel-setup/trash.png" class="trashImg"
                                                            (click)="roomRemove(ri, fi, bi);deleteRoomNo(r.id)"> </div>
                                                </div>
                                            </div>


                                            <div class="col-md-12 zero-padding add-room" id="{{bi+'add'+fi}}"
                                                (click)="hideAdd(fi, bi)">+Add New</div>


                                            <div class="col-md-12 zero-padding mt-5 roomH" id="{{bi+'input'+fi}}">
                                                <input type="text" class="form-control addRoomInput"
                                                    id="{{bi+'null'+fi}}" formContrlName="r_add"
                                                    [(ngModel)]="floor_No[fi]" [ngModelOptions]="{standalone: true}">
                                                <div class="col-md-12 zero-padding ">
                                                    <a class="btn btn btn-xs pull-right"
                                                        style="padding: 0px 0px;margin: 5px 0px 0px 0px;"
                                                        (click)="hideinput(fi, bi)"> <img
                                                            src="assets/img/hotel-setup/wrong.png"></a>
                                                    <a class="btn btn btn-xs pull-right"
                                                        style="padding: 0px 0px;margin: 5px 3px 0px 0px;"
                                                        (click)="addRoom(floor_No[fi], fi, bi)"><img
                                                            src="assets/img/hotel-setup/right.png"></a>
                                                </div>
                                            </div>


                                        </details>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </details>
                </div>

            </div>


            <!-- <div class="card">

              <div class="row">
                    <div class="col-md-12">




                                  <div class="row">
                                      <div class="col-md-12" >

                                            <div class="col-md-4 " style="padding-left: 0px ; padding-right: 5px;">
                                                    <div class="border-style b-16 roomcount-shade">

                                                    <ng-select  class="form-control input_2 padding-holder custom"  placeholder="Building Name" style="padding: 0px;border: none" formControlName="Building_name">

                                                    </ng-select>
                                                    </div>

                                                </div>

                                                <div class="col-md-5 " style="padding-left: 0px ; padding-right: 95px">


                                                  </div>

                                            <div class="col-md-3" style="padding-left: 105px ; padding-right: 0px">
                                                <button class="btn btn-block add-floor" > + Add Floor </button>
                                            </div>


                                        <div class="col-md-2" style="padding-left: 0px;padding-right: 5px;margin-top: 5px ">
                                            <div class="border-style b-18 roomcount-shade">
                                              <ng-select  class="form-control input_2 padding-holder custom"  placeholder="Floor No" style="padding: 0px;border: none" formControlName="Floor_no">

                                              </ng-select>
                                                    </div>

                                            <div class="border-style b-19 roomcount-shade" style="margin-top: 5px">
                                              <ng-select  class="form-control input_2 padding-holder custom"  placeholder="Floor No" style="padding: 0px;border: none" formControlName="Floor_no">

                                              </ng-select>
                                                            </div>

                                    </div>
                                    <div class="col-md-10" style="padding-left: 0px ; padding-right: 0px;margin-top: 5px">
                                            <div class="border-style b-20 roomcount-shade">
                                                    <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="">
                                                    </div>

                                            <div class="border-style b-21 roomcount-shade" style="margin-top: 5px">
                                                            <input type="text" class="form-control" placeholder="Room No" formControlName="Room_no" style="">
                                                            </div>

                                    </div>

                                      </div>
                                  </div>




                            </div>

              </div>

        </div> -->


            <!-- <div class="condition" *ngIf="fDetails">

          <div class="gradient-bar">
              <div class="">
                <div class="col-md-11" style="padding-left: 0px"> <h4>Floors & Room Details</h4> </div>
                <div class="col-md-1 text-right" style="padding-right: 10px ; padding-top: 10px; padding-left: 60px">  </div>

              </div>
          </div>

          <div class="card" *ngFor="let buildingD of oldBuilding" style="padding-bottom: 0px !important">

              <div class="row">
                  <div class="col-md-12" >
                      <div class="col-md-2 zero-padding" style="padding-right: 2px">
                          <div class="b-name mi" [style.border-color]="colors[i%5]">
                              <input type="text" class="form-control" value="Building : {{buildingD.name}}" disabled="true">
                          </div>
                      </div>
                      <div class="col-md-10 zero-padding bCard" style="padding-left: 3px" >
                          <div class="col-md-12 zero-padding">
                              <div class="col-md-2 zero-padding" style="padding-right: 2px" *ngFor="let floorN of buildingD.floors">
                                  <div class="col-md-12 b-name" [style.border-color]="colors[i%5]"><input type="text" class="form-control" value="Floor No : {{floorN.floor_no}}" disabled="true"></div>
                                  <div class="col-md-12 b-details mt-5 scroll-room" [style.border-color]="colors[i%5]">
                                      <div class="col-md-12 zero-padding r_no" *ngFor="let rNum of floorN.room_num ">
                                          <div class="col-md-8 zero-padding text-center rrn">{{rNum.room_number}}</div>
                                          <div class="col-md-4 zero-padding text-center" (click)="deleteRoom(rNum.room_number)" data-toggle="modal" data-target="#confirmationModal" style="cursor: pointer">X</div>
                                      </div>

                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>

          </div>

        </div> -->










            <div class="gradient-bar" id="amenityBar">
                <div class="">
                    <h4>Room Amenities</h4>
                </div>
            </div>
            <div class="card" [ngClass]="darkMode ? 'card_dark' : 'card'">
                <div class="room_amenities">
                    <div *ngFor="let amenity of amenities; let i = index">
                        <!-- <p><b>{{ amenities[1+1].title }}</b></p> -->
                        <div class="row" *ngIf="i%4 == 0 || i == 0">
                            <div *ngFor="let a of [0,1,2,3]" class="width-100">
                                <div class="col-md-3 amenity" *ngIf="amenities[i+a] && amenities[i+a].children[0]">
                                    <img src="assets/img/create-rooms/{{ amenities[i+a].icon }}" class="ameniClass"
                                        *ngIf="darkMode == false ">
                                    <img src="assets/img/create-rooms/darkmode/{{  amenities[i+a].icon }}"
                                        class="ameniClass" *ngIf="darkMode == true " style="width: 33px;
                                margin: 2px 5px 5px 5px;">
                                    <!-- <p><b>{{ amenities[i+a].title }}</b></p> -->
                                    <p class="ameniTitle"><b
                                            [ngStyle]="{'color': darkMode ? amenCol[i+a] : '#00205b'}">{{
                                            amenities[i+a].title }}</b></p>
                                    <ul>
                                        <li *ngFor="let childs of amenities[i+a].children">
                                            <label class="control-label checkbox-style"
                                                (mousedown)="chkchkbox('trash-'+childs.id)">
                                                <input type="checkbox" class="checkbox" id="trash-{{ childs.id }}"
                                                    name="most_amenties[]" value="{{ childs.id }}">
                                                <span class="checkbox-checkmark"
                                                    [ngClass]="darkMode ? 'dark-checkmark' : 'null'"></span>
                                                <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'">{{
                                                    childs.title }}</span>
                                            </label>
                                            <!-- <a href="javascript:void(0)" (click)="uncheck('trash-'+childs.id)" class="pull-right"><img class="" src="assets/img/hotel-setup/trash.png"></a> -->
                                        </li>
                                    </ul>
                                    <hr class="bottom-line" *ngIf="amenities[i+a].show_add == false">
                                    <a class="btn add-new" *ngIf="amenities[i+a].show_add == false"
                                        (click)="amenities[i+a].show_add = true">+ Add New</a>
                                    <div class="add-new-box" *ngIf="amenities[i+a].show_add == true">
                                        <input type="text" class="form-control" formControlName="add_most_amenite">
                                        <a class="btn btn-xs pull-right" (click)="amenities[i+a].show_add = false">
                                            <img src="assets/img/hotel-setup/wrong.png">
                                        </a>
                                        <a class="btn btn-xs pull-right" href="javascript:void(0)"
                                            (click)="add_amenitie('other',amenities[i+a].id)"><img
                                                src="assets/img/hotel-setup/right.png"></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer" style="bottom:-7.75%;padding: 0px 18px;">
                <a class="btn btn-primary btn-lg hov-btn" (click)="onSubmit()"
                    [ngClass]="darkMode ? 'btnblue_dark' : 'btn-primary'">Save</a>
                <!-- <a class="btn btn-primary btn-lg hov-btn" (click)="onSubmit()" [ngClass]= "darkMode ? 'btnblue_dark' : 'btn-primary'">{{prevBtn == true ? 'Preview' : 'Save'}}</a> -->
                <!-- <div (click)="goToPreview()">Preview</div> -->
                <a class="btn btn-danger btn-lg hov-btn" data-dismiss="modal" aria-label="Close"
                    [ngClass]="darkMode ? 'btnred_dark' : 'btn-danger'">Close</a>
            </div>
        </form>
    </div>
</div>