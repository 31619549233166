<form class="form-horizontal back-form" [formGroup]="create_package_form" (ngSubmit)="onSubmit()">
  <div class="" style="padding-right: 5px;" id="SP">
    <div class="alert alert-danger breadcrum mb-breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
      <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">
        RATES & PACKAGES
      </a>
      <a [routerLink]="setup_package" class="btn btn-link active">
        <span class="abc">
          {{(service.is_edit ? "EDIT" : "Setup")}}
          Promotions
        </span>
      </a>
    </div>

    <!-- Package Name and Life -->
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-6">
          <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="border-style color-5">
              <input type="text" class="input form-control" placeholder="Enter Promotion Name" style="outline: none;"
                formControlName="package_name" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                list="holiday" (focus)="showList()" (blur)="hideList()">
              <ul *ngIf="slist && suggList.length > 0" [ngClass]="slist ? 'sugg-list' : null">
                <li class="li-list" *ngFor="let h of suggList" (click)="selectHoliday(h.name); hideList();">
                  {{h.name + ' | '}} {{ h.holiday_date | date}}
                <li>
              </ul>
              <ul *ngIf="slist && suggList.length == 0" [ngClass]="slist ? 'sugg-list2' : null">
                <li class="li-list">
                  No Suggestions
                <li>
              </ul>
            </div>
            <div *ngIf="submitted && f.package_name.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.package_name.errors.required">
                Package name is required
                <!-- Promotion name is required -->
              </strong>
            </div>
          </div>
        </div>

        <div class="col-md-6" style="padding-left: 5px;">
          <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="border-style color2" style="padding: 0px"
              [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
              <span class="input-group-addon icoColor" [ngClass]="darkMode ? 'icoColorDark' : 'icoColor'">
                <i class="fa fa-calendar-alt"></i>
              </span>
              <input type="hidden" formControlName="date_to" name="date_to" [(ngModel)]="date_to">
              <input type="hidden" formControlName="date_from" name="date_from" [(ngModel)]="date_from">
              <input type="text" ngxDaterangepickerMd [closeOnAutoApply]="false" class="form-control date_range_picker"
                [autoApply]="true" (change)="choose_date($event)" placeholder="Promotion Life" formControlName="date"
                [(ngModel)]="date" [locale]="{format: 'DD MMM YYYY', separator: '     To     '}" style="display: inline-block; width: 80%; padding: 5px; background: none;
                border: none;height: 40px;" [ngClass]="darkMode ? 'whiteTxtDark' : 'null'">
            </div>
            <div *ngIf="submitted && f.date_from.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.date_from.errors.required">Check in date is required</strong>
            </div>
            <div *ngIf="submitted && f.date_to.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.date_to.errors.required">Check out date is required</strong>
            </div>
          </div>
        </div>
        <span class="info">
          <i class="fas fa-info-circle i" style="font-size: 15px;" [ngClass]="darkMode ? 'i_dark' : 'i'">
            <span class="tooltiptext3" [ngClass]="darkMode ? 'tool_dark' : 'tooltiptext3'">
              <p class="small   no-margin" style=" padding: 0px !important; margin: 0px !important;">
                Select Dates for Season
              </p>
            </span>
          </i>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <div class="col-md-6">
          <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            Room Amenities
            <span class="info">
            </span>
          </div>
        </div>

        <div class="col-md-6">
          <div class="package-name margin-col-1" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            Select Room
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">

        <div class="col-md-6 ">
          <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="row iconst">
              <div class="col-md-4 zero-padding" style="height: 40px;">
                <div class="smoker hovimg" *ngIf="smoke == true" (click)="changeSmoke()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;">
                      <img class="img-responsive hovimg" src="assets/img/newstaticicons/back-office/smoke.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        Smoking
                      </span>
                    </div>
                  </label>
                </div>
                <div class="smoker hovimg" *ngIf="smoke == false" (click)="changeSmoke()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;">
                      <img class="img-responsive " src="assets/img/newstaticicons/back-office/nosmoke.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        No Smoking
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <div class="col-md-4 zero-padding" style="height: 40px;">
                <div class="smoker hovimg" *ngIf="pet == true" (click)="changePet()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;">
                      <img class="img-responsive" src="assets/img/newstaticicons/back-office/pets.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        Pets Allowed
                      </span>
                    </div>
                  </label>
                </div>
                <div class="smoker hovimg" *ngIf="pet == false" (click)="changePet()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;">
                      <img class="img-responsive" src="assets/img/newstaticicons/back-office/nopets.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        Not Allowed
                      </span>
                    </div>
                  </label>
                </div>

              </div>
              <div class="col-md-4 zero-padding" style="height: 40px;">
                <div class="smoker hovimg" *ngIf="wheelT == true" (click)="changeWheel()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;">
                      <img class="img-responsive" src="assets/img/newstaticicons/back-office/wheel.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        Available
                      </span>
                    </div>
                  </label>
                </div>
                <div class="smoker hovimg" *ngIf="wheelT == false" (click)="changeWheel()">
                  <label class="control-label radio-style" style="padding-top: 0px !important;">
                    <div style="width: 40%;float: left;margin-top: -3px;;">
                      <img class="img-responsive" src="assets/img/newstaticicons/back-office/nowheel.svg"
                        style="height:50px !important;">
                    </div>
                    <div style="width: 50%;float: left;line-height: 40px;padding-right: 2px;">
                      <span class="hov" style="font-size: 12px; font-weight: bold;">
                        Unavailable
                      </span>
                    </div>
                  </label>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card margin-bottom" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="border-style country-shade dropdown-pd"
              [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
              <ng-select class="form-control input_2 pkg_rooms" formControlName="roomname" [ngModel]="selectroom"
                placeholder="Select Room" [items]="season_packages_data" bindLabel="Name" bindValue="id"
                style="border-radius: 0px !important; border: 0px !important; padding: 0 !important;"
                id="select_room_type" (change)="getSelectedRoom($event)"
                [ngClass]="darkMode ? 'card-dark-side-end dark-drop' : 'card-null'">
                <!-- <ng-option class="form-control input_2" *ngFor="let b of season_packages_data" value="{{b.id}}">{{b.Name}}</ng-option > -->
              </ng-select>
            </div>
            <div *ngIf="submitted && f.room_type_id.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.room_type_id.errors.required" >
                Room Selection
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
          <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            Connectivity
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12" [ngClass]="darkMode ? 'card-dark-side-1' : ' card-null '">
          <div class="card margin-bottom card-dark-side0" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="border-style color-pt" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
              <div class="" [ngClass]="darkMode?'radio-box_dark one':'radio-box one'">
                <label data-toggle="tooltip" title="Paid" class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('type1','type1T')"
                  (mouseout)="colorRadio('type1','type1T')">
                  <input type="radio" class="pull-left radio" name="package_type" value="OTAs"
                    formControlName="package_type">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type1"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type1T">
                    OTAs
                  </span>
                </label>
              </div>
              <div class="" [ngClass]="darkMode?'radio-box_dark two':'radio-box two'">
                <label data-toggle="tooltip" title="Complimentry"
                  class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('type2','type2T')"
                  (mouseout)="colorRadio('type2','type2T')">
                  <input type="radio" class="pull-left radio" name="package_type" value="Channel"
                    formControlName="package_type">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type2"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type2T">
                    Channels
                  </span>
                </label>
              </div>
              <div class="" [ngClass]="darkMode?'radio-box_dark three':'radio-box three'">
                <label data-toggle="tooltip" title="Exclude" class="control-label radio-style chbx ellipses hovbulit"
                  [ngClass]="darkMode?'hovbulit_dark':'hovbulit'" (mouseover)="colorRadio('type3','type3T')"
                  (mouseout)="colorRadio('type3','type3T')">
                  <input type="radio" class="pull-left radio" name="package_type" value="Loyalty"
                    formControlName="package_type">
                  <span class="radio-checkmark rd" [ngClass]="darkMode ? 'radio-checkmark_dark':'radio-checkmark'"
                    id="type3"></span>
                  <span class="title" [ngClass]="darkMode ? 'dark-title' : 'title'" id="type3T">
                    Loyalty
                  </span>
                </label>
              </div>
            </div>
            <div *ngIf="submitted && f.package_type.errors" class="small text-danger no-margin invalid-feedback">
              <strong *ngIf="f.package_type.errors.required">
                Promotion type is required
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
          <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            Description
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card margin-bottom pl" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
          <div class="border-style color3 " [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
            <textarea class="form-control txtdes" rows="4" formControlName="description"
              placeholder="Promotion Description" [ngClass]="darkMode ? 'whiteTxtDark' : 'null'"></textarea>
          </div>
          <div *ngIf="submitted && f.description.errors" class="small text-danger no-margin invalid-feedback">
            <strong *ngIf="f.description.errors.required">Description is required</strong>
          </div>
          <input type="hidden" value='{{current_user}}' name="current_user" formControlName="current_user"
            [(ngModel)]="current_user">
        </div>
      </div>

    </div>


    <!-- Description End-->

    <!-- Upsell Start -->
    <ng-container>
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
            <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
              Service Add-Ons
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card margin-bottom pl" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <ng-container *ngFor="let service of getFormAddons(); let i=index">
              <div class="row" style="padding-bottom: 5px;" *ngIf="service.type == 'service'">
                <div class="col-md-12">
                  <div class="col-md-6">
                    <div class="border-style" [ngStyle]="{'border-color': colors[i%colors.length]}">
                      <input type="text" class="form-control input" placeholder="Select Service Add-Ons"
                        value="{{ service.name }}" disabled [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                  </div>
                  <div class="col-md-6 d-flex">
                    <div class="border-style minUpsell" [ngStyle]="{'border-color': colors2[i%colors2.length]}">
                      <input type="text" class="form-control input" placeholder="Amount"
                        value="{{ service.price !=null ? service.price : ''}}" disabled
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                    <a class="btn btn-danger" style="margin-left: 6px;margin-top: -3px;height: 40px;padding: 9px 16px;"
                      (click)="remove_upsell(service.name)"
                      [ngClass]="darkMode ? 'card-dark-top-nav-cross' : ' card-null '">
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-6">
                  <div class="border-style" style="border-color: rgb(255, 0, 0); ">
                    <div>
                      <ng-select placeholder="Select Service Add-Ons" [(ngModel)]="serviceItem"
                        formControlName="service_item" [items]="serviceAddons" bindLabel="name" bindValue="id"
                        [ngClass]="darkMode ? 'card-dark-side-end dark-drop' : ' card-null '"
                        (ngModelChange)="getExtrasPrice(serviceItem, 'service')">
                      </ng-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" style="padding-left: 0px;">
                  <div class="btn-plus">
                    <div class="border-style" style="display: inline-block; width: 92.9%; border-color:#4caf50">
                      <input type="text" class="form-control input" placeholder="Amount" [(ngModel)]="servicePrice"
                        formControlName="service_value" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '"
                        readonly>
                    </div>
                    <a class="btn btn-primary btn-hov" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"
                      style="margin-left: 5px;margin-top: 0px;height: 40px;padding: 9px 15px;"
                      (click)="addExtras(serviceItem)" [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                      <i class="fa fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
            <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
              Food | Mini Bar Add-Ons
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card margin-bottom pl" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <ng-container *ngFor="let minibar of  getFormAddons(); let i=index">
              <div class="row" style="padding-bottom: 5px;" *ngIf="minibar.type == 'minibar'">
                <div class="col-md-12">
                  <div class="col-md-6">
                    <div class="border-style" [ngStyle]="{'border-color': colors[i%colors.length]}">
                      <input type="text" class="form-control input" placeholder="Select Service Add-Ons"
                        value="{{ minibar.name}}" disabled [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                  </div>
                  <div class="col-md-6 d-flex">
                    <div class="border-style minUpsell" [ngStyle]="{'border-color': colors2[i%colors2.length]}">
                      <input type="text" class="form-control input" placeholder="Amount"
                        value="{{ minibar.price !=null ? minibar.price : ''}}" disabled
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                    <a class="btn btn-danger" style="margin-left: 6px;margin-top: -3px;height: 40px;padding: 9px 16px;"
                      (click)="remove_upsell(minibar.name)"
                      [ngClass]="darkMode ? 'card-dark-top-nav-cross' : ' card-null '">
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-6">
                  <div class="border-style" style="border-color: rgb(255, 0, 0); ">
                    <div>
                      <ng-select placeholder="Select Mini Bar Add-Ons" [(ngModel)]="minibarItem"
                        formControlName="minibar_item" [items]="minibarAddons" bindLabel="name" bindValue="id"
                        [ngClass]="darkMode ? 'card-dark-side-end dark-drop' : ' card-null '"
                        (change)="getExtrasPrice(minibarItem, 'minibar')">
                      </ng-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" style="padding-left: 0px;">
                  <div class="btn-plus">
                    <div class="border-style" style="display: inline-block; width: 92.9%; border-color:#4caf50">
                      <input type="text" class="form-control input" placeholder="Amount" [(ngModel)]="minibarPrice"
                        formControlName="minibar_value" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" readonly>
                    </div>
                    <a class="btn btn-primary btn-hov" [ngClass]="darkMode ? 'btn-hov_dark':'btn-hov'"
                      style="margin-left: 5px;margin-top: 0px;height: 40px;padding: 9px 15px;"
                      (click)="addExtras(minibarItem)" [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                      <i class="fa fa-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Upsell Start END-->

    <!-- dynamic rates updates start -->

    <!-- <div class="row" *ngIf="selectedRoom"> -->
    <!-- <div class="col-md-12">
        <div class="col-md-12" id="SSS" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">

          <div class="package-name2 margin-col-1 col-md-4"
            style=" width: 38% !important; border-bottom-right-radius: 0px; border-top-right-radius: 0px; padding: 11px 20px;"
            [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
            <span>Dates
            </span>
          </div>

          <div class="col-md-8" style=" width: 62% !important">
            <div class="row" style="    margin-right: -9px;">

              <div class="package-name2 margin-col-1 col-md-4"
                style="    text-align: center;	 margin-top: 5px; border-radius: 0; padding-left: 20px; text-align: center; border-right: 1px solid lightgrey !important; border-left: 1px solid lightgrey !important;"
                [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                <span>BAR for {{ selectedRoom.roomtype }}</span>

              </div>

              <div class="package-name2 margin-col-1 col-md-4"
                style="  text-align: center; padding-left: 20px;  border-radius: 0px; text-align: center; border-right: 1px solid lightgrey !important; "
                [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                <span>Insert Promotion Room Rate</span>

              </div>

              <div class="package-name2 margin-col-1 col-md-4"
                style="  text-align: center; padding-left: 20px; border-bottom-left-radius: 0px; border-top-left-radius: 0px;"
                [ngClass]="darkMode ? 'card-dark-btn-g' : ' card-null '">
                <span>Discount</span>

              </div>

            </div>
          </div>
        </div>
      </div> -->

    <!-- ------ -->
    <!-- promotion values dynamic -->

    <!-- <div class="col-md-12" *ngFor="let it of get_rate_by_checkin_checkout ; index as i ">
        <div class="col-md-12">
          <div class="card" [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
            <div class="row " style="padding: 0px 15px ">
              <div class="col-md-4" style="width:38% !important">
                <div class="row">
                  <div class="col-md-12" style="padding: 0px 23px 0px 16px;">
                    <div class="border-style color-4 bc1">
                      <input type="text" formcontrolname class="input form-control season-date" id="{{it.date}}"
                        readonly value="{{it.date | date: 'EE , d MMMM'}}" placeholder="Promotion Date"
                        style="outline: none;" [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-8" style="width: 62% !important ">
                <div class="row">
                  <div class="col-md-4" style="padding: 0px 3px 0px 2px;">
                    <div class="border-style color-4 bc2">
                      <input type="text" class="input form-control roomrate" placeholder="Room Rate"
                        value="{{it.price}}" style="outline: none;" id="{{it.price}}"
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '">
                    </div>
                  </div>

                  <div class="col-md-4" style="padding: 0px 5px 0px 2px;">
                    <div class="border-style color-4 bc3">
                      <input type="number" class="input form-control newrate" placeholder="Rate" style="outline: none;"
                        value="{{it.package_price}}" id="c{{i}}"
                        [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" (input)="onSearchChange($event,i)">
                    </div>
                  </div>

                  <div class="col-md-4 disbox {{i}}" style="padding: 0px 15px 0px 0px; z-index: 9999">


                    <div class="cardBarsRedGreen">
                      <div class="caret_up">
                        <div *ngIf="it.package_price > it.price">
                          <div class="greenbar" style="background-color: green;">{{it.per}}</div>
                          <i class="fa fa-caret-up" style="margin-top: 1px;"></i>
                        </div>
                        <div *ngIf="it.package_price <= it.price">
                          <div class="greenbar" style="background-color: red;">{{it.per}}</div>
                          <i class="fa fa-caret-down" style="margin-top: 1px;"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->

    <!-- </div> -->
    <!--    promotion start-->
    <div class="row">
      <div class="col-md-12">
        <div class="col-md-12" (mouseover)="dodrag2()" (mouseout)="stopDrag2()">
          <div class="package-name margin-col-2" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
            Promotion rates
          </div>
        </div>
      </div>
    </div>



    <div class="main-rates card d-flex" [ngClass]="darkMode ? 'darkCard' : ''" *ngIf="get_rate_by_checkin_checkout.length != 0">
      <div class="bar-promo-rate-box" *ngFor="let it of get_rate_by_checkin_checkout; index as i">
        <div class="basic_block days season-date" id="{{it.date}}">
          <h3 class="days_month">{{it.date | date: 'MMMM'}}</h3>
          <h3 class="day_number">{{it.date | date: 'd'}}</h3>
          <h3 class="days_day">{{it.date | date: 'EEEE'}}</h3>
        </div>
        <div class="basic_block rates">
          <div class="bar_price d-flex justify-content-between align-items-center">
            <div class="greenbar" style="background: linear-gradient(#7340ce, #300087);">Bar</div>
            <input type="text" class="input form-control roomrate" style="outline: none;"
              [value]="it.price | currency:current_currency:'symbol':'1.2-2'" id="{{it.price}}"
              [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" style="background: none !important;" readonly>
          </div>
          <div class="bar_price d-flex justify-content-between align-items-center">
            <div class="disbox {{i}}">
              <div class="greenbar"
                [ngStyle]="{'background': it.package_price == it.price ? 'linear-gradient(#7340ce, #300087)' : (it.package_price > it.price ? 'linear-gradient(rgb(10, 222, 36), rgb(8, 113, 21))' : 'linear-gradient(rgb(249 25 25), rgb(115 6 35))')}">
                <i class="fa "
                  [ngClass]="{'fa-caret-up': it.package_price > it.price, 'fa-caret-down': it.package_price <= it.price, 'hidden': it.package_price == it.price}"></i>
                {{it.per || 0}}%
              </div>
            </div>
            <input type="text" class="input form-control newrate" style="outline: none;" placeholder="Enter price"
              [value]="it.package_price | currency:'USD':'symbol':'1.2-2'" id="c{{i}}"
              [ngClass]="darkMode ? 'card-dark-side-end' : ' card-null '" (input)="onSearchChange($event,i)"
              style="background: none !important;" (focus)="onFocusChange({event : $event})"
              (blur)="onBlurChange({event : $event})" autocomplete="off">
          </div>
        </div>
      </div>
    </div>

    <!--  dynamic rates updates ends -->


    <!-- Selected Room Detail -->
    <div class="row">
      <div class="col-md-12">
        <div class="card margin-bottom pl" style="margin-top: 5px !important;margin-bottom: 5px !important;"
          [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">
          <div class="row" *ngIf="selectedRoom">
            <div class="col-md-12">
              <div class="col-md-3">
                <img class="img-responsive"
                  src="{{ selectedRoom.images[0]?.image ? api.imgUrl +selectedRoom.images[0].image : 'assets/img/other/no-room.jpg' }}"
                  style="border-radius: 10px;">
              </div>
              <div class="col-md-9" style="padding-right: 20px;">
                <h3 class="card-title">
                  {{ selectedRoom.roomtype }}
                </h3>
                <p class="room-content" [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '"> {{
                  selectedRoom.description }} </p>
                <div class="room-details">
                  <ul class="nav navbar-nav pull-left">
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/bed.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 5px -5px 0px'} : {}"
                          style="margin: -10px 5px -5px 0px;height: 40px;" />
                        <span class="tooltiptext" [ngClass]="darkMode ? 'tool_dark' : 'null'">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            {{ selectedRoom.bedtype }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/sleep.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}">
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            Sleeps Children
                          </p>
                        </span>
                      </a>
                    </li>
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/area.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}">
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            {{ selectedRoom.room_size }} {{selectedRoom.room_size_type == 'meters' ? 'm' :
                            selectedRoom.room_size_type == 'square_feet' ? 'Sqft' : 'null' }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img *ngIf="selectedRoom?.pets == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/pets.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" />
                        <img *ngIf="selectedRoom?.pets == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nopets.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" />
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            Pets
                            {{selectedRoom.pets == 'true' ? 'Allowed' : selectedRoom.pets ==
                            'false' ? 'Not Allowed' : 'null' }}
                          </p>
                        </span>
                      </a>
                    </li>

                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img *ngIf="selectedRoom?.wheelchairaccessibility == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/wheel.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" /> <img
                          *ngIf="selectedRoom?.wheelchairaccessibility == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nowheel.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" />
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            {{selectedRoom.wheelchairaccessibility == 'true' ? 'Available' :
                            selectedRoom.wheelchairaccessibility == 'false' ? 'Not Available' :
                            'null' }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img *ngIf="selectedRoom?.smoking == 'true'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/smoke.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" /> <img
                          *ngIf="selectedRoom?.smoking == 'false'"
                          src="assets/img/newstaticicons/rates-packages/{{darkMode ? 'darkmode' : 'lightmode'}}/nosmoke.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" />
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            {{selectedRoom.smoking == 'true' ? 'Available' :
                            selectedRoom.smoking == 'false' ? 'Not Available' : 'null' }}
                          </p>
                        </span>
                      </a>
                    </li>
                    <li class="bad-1">
                      <a [ngClass]="darkMode ? 'card-dark-txt' : ' card-null '" class="totlo">
                        <img src="assets/img/newstaticicons/rates-packages/lightmode/room-icon.svg"
                          [ngStyle]="darkMode ? {'margin' : '-10px 0px -5px 0px'} : {}" />
                        <span class="tooltiptext">
                          <p class="small" style="padding: 0px !important; margin: 0px !important;">
                            {{selectedRoom.display_name}}
                          </p>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!selectedRoom" style="text-align: center; font-size: 14px">
            No Rooms Selected
          </div>
        </div>
      </div>
    </div>
    <!-- Selected Room Detail END-->
  </div>
  <div class="modal-footer text-right" id="ModalFooterP" style="bottom: -50px;right: 10px!important">
    <button class="btn btn-info btn-lg btn-hov" [ngClass]="darkMode ? 'card-dark-btn-g_save' : ' card-null '">Save
    </button>
    <button class="btn btn-danger btn-lg btn-hov" [ngClass]="darkMode ? 'card-dark-btn-g_dark' : ' card-null '"
      aria-label="Close" data-dismiss="modal" type="button" (click)="onClose()"
      [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">Cancel
    </button>
  </div>
</form>
