<!-- <p>rms-tray works!</p> -->

<!-----------------right tray------------->
<ng-container *ngIf="itemCards.length != 0">
  <div *ngIf="availableRoomNo$ | async as RoomNoList" class="rightD" style="right: 0px;z-index: -1;" id="sideBarDetail"
    data-collapsed="false" [ngClass]="darkMode ? 'card_dark' : ''"
    [ngStyle]="{'right': itemCards.length != 0 ? '-300px' : '0px'}">

    <div class="redbar d-flex align-items-center justify-content-between" [ngClass]="darkMode ? 'darkBar' : ''">
      <input class="form-control rd-input" type="text" *ngIf="isActive" placeholder="Room number" [(ngModel)]="room_num"
      (input)="handleRoomSelected(room_num)">
      <input class="form-control rd-input" type="text" *ngIf="!isActive" #val placeholder="Table number"
        (keyup.enter)="tableNoShow(val)" (keyup.backspace)="focusTableNoShow(val)">

      <span class="switcher switcher-1" (click)="toggleClass()">
        <input type="checkbox" id="switcher-1" checked>
        <label for="switcher-1"></label>
      </span>
    </div>
    <ng-container *ngIf="room_num">
      <div class="blubar" *ngIf="selectedGuest$ | async as guest">
        <label class="" type="text">{{guest.split(',')[0] + ' ' + guest.split(',')[1]}}</label>
      </div>
    </ng-container>

    <div class="blubar" *ngIf="showTableNo">
      <label class="" type="text">Table Number : {{room_num}}</label>
    </div>

    <div class="main-menu">
      <div class="card-div" [style.height]="room_num == '' ? '325px' : '290px'" style="overflow: auto;">
        <ng-container *ngFor="let itemCard of itemCards;index as i">
          <div class="div1">
            <div class="d-flex flex-row-reverse justify-content-between pt-3">
              <button class="btn-add mr-3">
                <i class="fa fa-minus" style="margin: 0px 5px 0px 0px;" (click)="decreaseItem(itemCard)"></i>
                {{ itemCard.count }}
                <i class="fa fa-plus" style="margin: 0px 0px 0px 5px;" (click)="increaseItem(itemCard)"></i>
              </button>
              <div class="img-div" style="margin: -5px 0px 5px 10px;">
                <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+itemCard.image" [alt]="itemCard.image"
                  style="width:73px;height:73px;border-radius: 15px;">
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-end" style="margin: 0px 10px; height: 20px;">
              <button class="btn-remove" (click)="removeItem(i)">Remove</button>
              <div class="d-flex flex-column align-items-end">
                <span class="p-span">
                  {{currency_format}} {{ calculateTaxAmount(itemCard) | number:'1.2-3' }}
                </span>
                <span class="tax">
                  <button class="btn btnTax">
                    {{itemCard.shop.tax_per}}%
                    <span>Tax {{itemCard.shop.tax_inclusive === 'false' ? 'Excl' : 'Incl'}}</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
          <hr
            style="width: 96%; border-color: #9e9e9e;border-top: 2px solid #9e9e9e; margin-top: 5px; margin-bottom: 0px;">
        </ng-container>
      </div>
      <div class="div2" style="position:absolute;bottom:1%;left:5px;">
        <textarea name="" id="" cols="36" rows="5" placeholder="Special requests"
          [(ngModel)]="special_request"></textarea>
        <hr style="width: 100%; border-color: #9e9e9e;border-top: 2px solid #9e9e9e;margin-top:5px;margin-bottom: 5px">
        <div class="d-flex"
          style="display: flex;justify-content: space-between; margin-bottom:10px; color:#3855be;font-size: 15px;font-weight: bold;">
          <div class="sub-total">
            Total <span style="font-size: 10px; color:black;">({{itemCards.length}} Items)</span>
          </div>
          <div class="price">
            {{currency_format}} {{calculateSubTotal() | number:'1.2-3'}}
            <span style="font-size: 10px; color:black;">Tax Incl</span>
          </div>
        </div>
        <div class="button-div" style="display: flex; justify-content: center;">
          <button *ngIf="!editModeCheck" class="btn btn-{{editMode ? 'purple':'orange'}} fadeInOut"
            (click)="checkOut()">{{editMode ? 'Update':'Check Out'}}</button>
          <ng-container *ngIf="editModeCheck">
            <div class="d-flex justify-content-center align-items-center flex-column">
              <div class="check-container">
                <div class="check-background">
                  <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="10" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
                <div class="check-shadow"></div>
              </div>
              <p class="text-success m-0" style="font-size: 12px;">
                Order Successfully {{editMode ? 'Modified' : 'Placed'}}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="roundCurve" (click)="sideMenu('sideBarDetail')">
      <i class="fa fa-caret-{{changeArrow ? 'right' : 'left'}}"></i>
    </div>
  </div>
</ng-container>


<!-----------------right tray end------------->