<div id="settings" class="" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-2 popup-nav popup-nav-padd">
            <ul class="nav flex-column">
              <li class="nav-item active">
                <a class="nav-link">
                  <img src="assets/img/manage_rates/gift.png" />
                  <span>Setup<br /><strong>Package</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <img src="assets/img/manage_rates/money_in_hand.png" />
                  <span>Setup<br /><strong>Rate</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <img src="assets/img/manage_rates/close.png" />
                  <span>Setup<br /><strong>Restriction</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <img src="assets/img/manage_rates/link_ota.png" />
                  <span>Link To<br /><strong>OTA</strong></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <img src="assets/img/manage_rates/search.png" />
                  <span>Preview<br /><strong>Package</strong></span>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-10 pr-10 pl-0">
            <div class="tab-content scrollo pd-scroll">
              <div class="tab-pane active">
                <div class="">
                  <div class="alert alert-info alert-dismissible show pl-2" role="alert">
                    <p>
                      Rate & Package
                    </p>
                    <button aria-label="Close" class="close mr-to" data-dismiss="modal" type="button">
                      <strong>✖</strong>
                    </button>
                  </div>

                  <div class="alert alert-danger breadcrum mb-breadcrum">
                    <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGE</a>
                    <a href="" class="btn btn-link active">
                      <span class="abc"> SETUP PACKAGE</span></a>
                    <a href="" class="btn btn-link active">
                      <span class="abc"> HONEY MOON PACKAGE</span></a>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-6">
                        <div class="package-name margin-col-1">
                          Packages Name
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="package-name margin-col-2">
                          Dates
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-6">
                        <div class="card margin-bottom">
                          <div class="border-style color-5">
                            <input type="text" class="input" placeholder="Penthouse 2 King 4 Queen 360 Panoramic View"
                              style="outline: none !important;" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card margin-bottom">
                          <div class="border-style color2">
                            <input type="text" class="input" placeholder="Select Your Packages Dates" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-6">
                        <div class="package-name margin-col-1">
                          Description
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="package-name margin-col-2">
                          Breakfast
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-6">
                        <div class="card margin-bottom">
                          <div class="">
                            <div class="border-style country-shade dropdown-pd">
                              <ng-select placeholder="Double Deluxe">
                                <ng-option>Test</ng-option>
                                <ng-option>Test 1</ng-option>
                                <ng-option>Test 2</ng-option>
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card margin-bottom">
                          <div class="border-style color-jj">
                            <div class="grid_20_inside">
                              <div class="radio-box">
                                <label class="control-label radio-style chbx">
                                  <input type="radio" class="pull-left radio" name="mode" value="light" />
                                  <span class="radio-checkmark rd"></span>
                                  <span class="title">Include</span>
                                </label>
                              </div>
                            </div>
                            <div class="grid_20_inside ml-radiobtn">
                              <div class="radio-box" style="margin-left: 20px;">
                                <label class="control-label radio-style chbx">
                                  <input type="radio" class="pull-left radio" name="mode" value="dark" />
                                  <span class="radio-checkmark rd"></span>
                                  <span class="title">Exclude</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="card margin-bottom pl">
                        <div class="border-style color3">
                          <div class="opacity">
                            Penthouse 2 King 4 Queen 360 Panoramic View
                            <br />
                            Lorem, ipsum dolor sit amet consectetur adipisicing
                            elit. Fuga facere ullam, pariatur, perspiciatis
                            placeat commodi, aliquid hic expedita optio dolore
                            aperiam. Fuga earum nihil minima ex vero quod
                            tempora iure. Lorem ipsum dolor sit amet consectetur
                            adipisicing elit. Ipsum dolores sit eos
                            exercitationem maiores necessitatibus quos ullam in,
                            esse accusantium nostrum, placeat incidunt
                            perferendis laborum a commodi quisquam rem iste.
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Unde nesciunt nihil est! Officia
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card margin-bottom pl">
                        <div class="row">
                          <div class="col-md-3">
                            <!-- <img class="img-responsive" src="assets/img/manage-rooms/Mask Group 63.png"> -->
                            <img class="img-responsive" src="assets/img/manage-rooms/Mask Group 63.png"
                              style="border-radius: 10px;" />
                          </div>
                          <div class="col-md-7" style="padding-left: 0px;">
                            <h3 class="card-title">
                              Single Room Beach View
                            </h3>
                            <p class="room-content">
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Molestiae aspernatur perspiciatis
                              reprehenderit deserunt, minus nobis quod.
                              Voluptatibus animi sunt, eius delectus non error
                              iure deleniti assumenda, eum, soluta laudantium
                              incidunt. Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Molestiae aspernatur
                              perspiciatis reprehenderit deserunt, minus nobis
                              quod. Voluptatibus animi sunt, eius delectus non
                              error iure deleniti assumenda, eum, soluta
                              laudantium incidunt. deserunt, minus nobis quod.
                              Voluptatibus animi sunt
                            </p>
                            <div class="room-details">
                              <ul class="nav navbar-nav pull-left">
                                <li class="bad-1">
                                  <a><img height="20px" src="assets/img/manage-rooms/bed.png" class="pd-footer" />
                                    Double Bed</a>
                                </li>
                                <li class="bad-1">
                                  <a><img height="20px" src="assets/img/manage-rooms/sleep.png" class="pd-footer" />
                                    Sleeps 2</a>
                                </li>
                                <li class="bad-1">
                                  <a><img height="20px" src="assets/img/manage-rooms/size.png" class="pd-footer" />
                                    450 Sq ft</a>
                                </li>
                                <li class="bad-1">
                                  <a><img height="20px" src="assets/img/manage-rooms/pets.png" class="pd-footer" />
                                    Pets not Allowed</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <!-- <div class="col-md-2">
                                                    <div class="text-right">
                                                        <div class="btn-wrapper">
                                                            <a href="javascript:void(0)" class="" (click)="clone(room.id)"><img width="40px" src="assets/img/manage-rooms/duplicate.png"></a>
                                                            <a routerLink="/app/back_office/create_rooms/{{ room.id }}"><img width="40px" src="assets/img/manage-rooms/edit.png"></a>
                                                            <a href="javascript:void(0)" (click)="delete(room.id,room.roomtype)" class=""  data-toggle="modal" data-target="#confirmationModal"><img width="40px" src="assets/img/manage-rooms/delete.png"></a>
                                                        </div>
                                                        <br><br><br><br>
                                                        <h5 class="room_count_wrapper">Room Count <span class="room_count"></span></h5>
                                                    </div>
                                                </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer text-right">
                  <a class="btn btn-info btn-lg">Save</a>
                  <a class="btn btn-danger btn-lg">Cancel</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>