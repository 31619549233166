<!-- <div [ngClass]="darkMode ? 'card_dark' : 'null'">
    <div class="flex text space-between parent-bar">
      <div>Dashboard</div>
      <a>
        <input type="checkbox" class="form-check-input mr-3"  value="" id="defaultCheck1" name="checkbox"title="Select all"style="cursor: pointer;">
        <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button1"
          style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-dashboard" role="button"
          aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button1')">
      </a>
    </div>
    <div class="collapse" id="collapse-dashboard"[ngClass]="darkMode ? 'card_dark' : 'null'">

        <div class="row">
            <div class="col-md-12">
                <div class="row field-space">
                    <div class="col-md-12">
                        <div class="col-md-12 pr-10 pl-10">
                            <div class="border-style  first-name-shade lab-sty d1">
                              <label> Performance</label>

                               <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"style="margin:0px;cursor: pointer;">
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
  </div> -->

<div [ngClass]="darkMode ? 'card_dark' : 'null'" *ngFor="let permission of permissions;index as i">
  <div class="flex text space-between parent-bar" [ngStyle]="{background: permission.color}">
    <div>{{permission.name}}</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" [id]="'button'+i"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" [attr.href]="'#collapse-dashboard' + i"
        role="button" aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button',i)">
    </a>
  </div>
  <div class="collapse" id="collapse-dashboard{{i}}" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <div class="row">
      <div class="col-md-12">
        <div class="row field-space">
          <ng-container *ngFor="let type of permission.types">
            <div class="col-md-12" [ngClass]="(permission.types.length > 1) ? 'half-field' : 'p-0'">
              <div class="border-style  first-name-shade lab-sty d1" [ngStyle]="{'border-color': type.color}">
                <label> {{type.name}}</label>
                <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
                  style="margin:0px;cursor: pointer;">
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>