import { Component, Input, OnInit } from '@angular/core';
import { PnlService } from 'src/app/services/pnl.service';

@Component({
  selector: 'app-dashboard-permission',
  templateUrl: './dashboard-permission.component.html',
  styleUrls: ['./dashboard-permission.component.css']
})
export class DashboardPermissionComponent implements OnInit {
  darkMode: boolean = false;
  permissions = [];
  constructor(
    private pnlservice: PnlService,
  ) { }

  ngOnInit() {
    this.checkDarkMode();
    this.pnlservice.getPermissions().subscribe((res) => {
      this.permissions = res.data;
      console.log(this.permissions, "permission");
    })
  }

  changeclass(id: any, index: number) {
    let ids = <HTMLElement>document.getElementById(id + index)
    if (ids.classList.contains("rotate")) {
      ids.classList.remove("rotate")
    } else {
      ids.classList.add("rotate")
    }
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }
}
