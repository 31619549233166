<div style="height: 407px;position: relative;">
    <div id="change_format" class="format-img">
        <img src="assets/img/reports/{{format_amount ? 'percentage_dollar_dark' : 'Percentage _dark'}}.svg" />
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <!-- <div class="vr d-flex justify-content-center align-items-center w-50" style="flex: 1 1 auto;">
            <canvas id="opex-breakdown-canvas" class="chartjs-render-monitor opex-breakdown-canvas"></canvas>
            <div id="legend-container" style="margin-left: -100px;"></div>
            <canvas id="opex-polar-canvas"></canvas>
        </div>
        <div class="w-50 expense-chart">
            <canvas id="opex-comparison-canvas" class="chartjs-render-monitor overridecanvas2"></canvas>
            <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="radio d-flex">
                    <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip" title="Var">
                        <input class="pull-left radio" name="var" id="var" type="checkbox" (click)="var_fix_cost('var')"
                            [(ngModel)]="var_cost">
                        <span class="radio-checkmark rd"></span>
                        <span class="title">Var</span></label>
    
                    <label class="control-label radio-style chbx ellipses hovbulit" data-toggle="tooltip" title="Fix">
                        <input class="pull-left radio" name="fix" id="fix" type="checkbox" (click)="var_fix_cost('fix')"
                            [(ngModel)]="fix_cost">
                        <span class="radio-checkmark rd"></span>
                        <span class="title">Fix</span></label>
                </div>
                <canvas id="expense-category-canvas" class="chartjs-render-monitor overridecanvas2"></canvas>
            </div>
            <div id="opex-legend"></div>
        </div> -->

        <div id="opexNestedChart" style="width: 100%;height: 390px;"></div>
    </div>
</div>