import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ErrorHandlingService } from '../../services/error-handling.service';
import { HelperService } from '../../services/helper.service';

declare var $: any;
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SeasonRateIndexResponse } from 'src/app/common/ratesandpackages/SeasonRateIndexModel';

@Injectable({
  providedIn: 'root'
})
export class ManageRatesPackagesService {

  packages = [];
  all_packages;
  package_creating;
  is_edit = false;
  is_preview = false;
  private RateChannelIndexEndpoint: string = '/api/channels/rate_channel_index';
  private RateSeasonIndexEndpoint: string = '/api/season_package/rate_season_index';
  private propertyId: string;
  public update_new_data = new BehaviorSubject<boolean>(false);
  public otaArray = new Subject<any[]>();

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private helper: HelperService,
    private error_handling: ErrorHandlingService
  ) {
    this.propertyId = localStorage.getItem('current_property');
    this.get_packages();
  }

  get_packages() {
    console.log('get packages');

    const property_id = localStorage.getItem('current_property');
    this.api.post('api/package/index', { property_id: property_id }, true).subscribe((res: any) => {
      this.packages = res.body.data;
      this.all_packages = res.body.data;
      // console.log(this.all_packages)
      this.all_packages.forEach(element => {
        const a = element.date_from.split('/');
        const b = element.date_to.split('/');
        element.date_from = moment(a[1] + '/' + a[0] + '/' + a[2].split(' ')[0]).format('DD MMM YY');
        element.date_to = moment(b[1] + '/' + b[0] + '/' + b[2].split(' ')[0]).format('DD MMM YY');
      });

      // this.all_packages.forEach(element => {
      //   element.date_from = moment(element.date_from.split(' ')[0]).format('DD MMM YY');
      //   element.date_to = moment(element.date_to.split(' ')[0]).format('DD MMM YY');
      // });
    }, err => this.error_handling.handle_error(err.status, err.message));
    // console.log(this.packages);
  }

  delete(id) {
    this.api.get('api/package/destory/' + id, true).subscribe((res: any) => {
      this.get_packages();
      this.helper.alert_success('Package has been deleted successfully !');
    }, err => this.error_handling.handle_error(err.status, err.message));
  }

  duplicate_package(id) {
    this.api.get('api/package/clone/' + id, true).subscribe((res: any) => {
      this.get_packages();
    }, err => this.error_handling.handle_error(err.status, err.message));
    this.helper.alert_success('Package has been duplicated successfully !');
  }

  open_tab(id) {
    // $('#'+id).click();
    return false;
  }


  prepareAuthHeaders(): HttpHeaders {
    let auth_token = localStorage.getItem('pms_auth_token');
    let bearer_token = 'Bearer ' + auth_token;

    let header = new HttpHeaders();
    header = header.set('Authorization', bearer_token);
    return header;

  }

  getChannels() {
    const request = {
      property_id: this.propertyId
    };
    return this.http.post(this.api.base_url + this.RateChannelIndexEndpoint, request, {
      headers: this.prepareAuthHeaders()
    });
  }

  rateSeasonIndex(payload: { property_id: string, sort: string }): Observable<SeasonRateIndexResponse> {
    return this.http.post<SeasonRateIndexResponse>(this.api.base_url + this.RateSeasonIndexEndpoint, payload, {
      headers: this.prepareAuthHeaders()
    });
  }
}
