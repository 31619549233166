<!-- Food and Beverages (manage-menu-Page) work start -->

<div class="main-div">
  <div class="filters_row" [ngClass]="darkMode ? 'dark_bredcrum' : ''">
    <div class="row d-flex">
      <div class="column-wrapper d-flex col-md-12">
        <ul class="nav navbar-nav tabsBar" id="tabsBarManageMenu">
          <li *ngFor="let shop of shopNames">
            <a class="btn btn-link" (click)="switchShop(shop.shop_id)"
              [ngClass]="{ 'active': shop.shop_id === activeShopId }">{{shop?.shop_name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-md-12 blueHedaer" [ngClass]="darkMode ? 'dark_blueBar' : ''">
    <div class="row">
      <mat-tab-group [ngClass]="darkMode ? 'darkTab' : ''" disableRipple="true"
        (selectedTabChange)="fetchMenuItem($event)" id="material-tab">
        <mat-tab class="category" label="All">
          <div class="gallery">
            <div class="row flex-wrap">
              <ng-container *ngFor="let productItem of manageProductMenuItems;index as i">
                <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                  <div class="card-body">
                    <div class="stock" *ngIf="productItem.stock_status">Out of Stock</div>
                    <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+productItem.image" alt="category"
                      class="category_img">
                    <div class="card-text">
                      <h4 class="card-title">{{productItem.item.item_name}}</h4>
                      <h6>
                        {{ currency_format }} {{ productItem.selling_price | number:'1.2-3' }}
                        <p>{{productItem.shop.tax_inclusive ? "Tax Inc" : "Tax Exc"}}</p>
                      </h6>
                    </div>
                  </div>
                  <div class="card-footer">
                    <!-- <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit"
                                            class="edit"> -->
                    <label class="toggleLable">
                      <input type="checkbox" class="cB" [(ngModel)]="productItem.stock_status"
                        [checked]="productItem.stock_status" />
                      <div class="cB-outer-div" (click)="outOfStock(i,manageProductMenuItems)">
                        <div class="cB-inner-div"></div>
                      </div>
                    </label>
                    <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="delete"
                      data-toggle="modal" data-target="#confirmationModal-3"
                      (click)="deleteMenuItem(i, manageProductMenuItems)">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngFor="let category of categories" [label]="category.category_name">
          <div class="row flex-wrap">
            <div class="card categoryCard shadow" *ngFor="let category of filteredCategoryItems;index as i"
              [ngClass]="darkMode ? 'card_dark' : ''">
              <div class="card-body">
                <div class="stock" *ngIf="category.stock_status">Out of Stock</div>
                <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+category.image" alt="category" class="category_img">
                <div class="card-text">
                  <h4 class="card-title">{{category.item.item_name}}</h4>
                  <h6>
                    {{ currency_format }} {{ category.selling_price | number:'1.2-3' }}
                    <p>{{category.shop.tax_inclusive ? "Tax Inc" : "Tax Exc"}}</p>
                  </h6>
                </div>
              </div>
              <div class="card-footer">
                <!-- <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit" class="edit"> -->
                <label class="toggleLable">
                  <input type="checkbox" class="cB" [(ngModel)]="category.stock_status"
                    [checked]="category.stock_status" />
                  <div class="cB-outer-div" (click)="outOfStock(i,filteredCategoryItems)">
                    <div class="cB-inner-div"></div>
                  </div>
                </label>
                <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="delete"
                  data-toggle="modal" data-target="#confirmationModal-3"
                  (click)="deleteMenuItem(i, filteredCategoryItems)">
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab class="category" label="Deals">
          <div class="gallery">
            <div class="row flex-wrap">
              <ng-container *ngFor="let dealItem of manageDealMenuItems;index as i">
                <div class="card categoryCard shadow" [ngClass]="darkMode ? 'card_dark' : ''">
                  <div class="card-body">
                    <div class="stock" *ngIf="dealItem.is_active">Out of Stock</div>
                    <img [src]="'https://uat.cgslhcm.com/pmsbackend_8/'+dealItem.image" alt="category"
                      class="category_img">
                    <div class="card-text">
                      <h4 class="card-title">{{dealItem.deal_name}}</h4>
                      <h6>
                        {{ currency_format }} {{ dealItem.deal_selling_price | number:'1.2-3' }}
                        <p>{{dealItem.shop.tax_inclusive ? "Tax Inc" : "Tax Exc"}}</p>
                      </h6>
                    </div>
                  </div>
                  <div class="card-footer">
                    <!-- <img src="../../../../assets/img/create_user_fnb/edit.svg" alt="edit"
                                            class="edit"> -->
                    <label class="toggleLable">
                      <input type="checkbox" class="cB" [(ngModel)]="dealItem.is_active"
                        [checked]="dealItem.is_active" />
                      <div class="cB-outer-div" (click)="isActiveDeal(i)">
                        <div class="cB-inner-div"></div>
                      </div>
                    </label>
                    <img src="../../../../assets/img/create_user_fnb/delete.svg" alt="delete" class="delete"
                      data-toggle="modal" data-target="#confirmationModal-3" (click)="deleteDealItem(i)">
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" [ngClass]="darkMode ? 'btnDark' : ''">Save</button>
  </div>
</div>