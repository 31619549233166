import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { ApiService } from "../../services/api.service";
import { ErrorHandlingService } from "../../services/error-handling.service";
import { HelperService } from "../../services/helper.service";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormArray,
  FormGroupDirective,
} from "@angular/forms";
declare var $: any;
import * as moment from "moment";
import * as dayjs from 'dayjs';
// import Moment from "moment";

import { extendMoment } from "moment-range";
import ScrollBooster from "scrollbooster";
import { from, race } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { AnimationMetadataType } from "@angular/animations";
import { ExtraPersonInfo } from "src/app/common/Inventory/ExtraPersonInfo";
import { AddOnsExtras } from "src/app/common/arrivals/response/AddOns";
import { DatePipe } from "@angular/common";

interface CalendarDate {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Component({
  selector: "app-inventory",
  templateUrl: "./inventory.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./inventory.component.css"],
})
export class InventoryComponent implements OnInit {
  selectedRow: number = 0;

  calendarDropDowns = [];

  colors = [
    "#F98282",
    "#49FC6D",
    "#FF6DBD",
    "#7577FF",
    "#F5A65B",
    "#21E1AD",
    "#A7C957",
    "#D87EFF",
    "#D08C60",
    "#09B562",
    "#D85B5B",
    "#7B89FF",
    "#FF9A36",
    "#C4B534",
    "#BB8565",
    "#FD88FF",
    "#FFA985",
    "#33FFA0",
    "#A6C36F",
    "#F9C74F",
    "#E28D99",
    "#6387FF",
    "#9FFF0D",
    "#25A18E",
    "#2D9DFF",
    "#FF658A",
    "#A779BE",
    "#72BDA3",
    "#C2A363",
    "#277DA1",
  ];
  rateData;
  public room_data: any;
  returnee: string = "New";
  displayStyle = "none";
  reservation_bulk: FormGroup;
  showCalendar: boolean = true;
  scroller;
  index;
  room_index;
  room_type_data;
  package_id = "";
  stop_scroll = false;
  pack;
  guest_name;
  room_type;
  payee_name;
  current_arrival;
  submittedUpodated;
  b;

  totalnights_bulk;
  confirmation_yes: boolean = false;
  taxInclusive;
  taxInclusivee = JSON.parse(localStorage.getItem("property")).taxInclusive;
  currency_format = localStorage.getItem("currency");
  date_departure_bulk;
  action = "flex";
  i;
  indexing;
  b_status = [
    { name: "Confirmed", value: "confirm" },
    { name: "Hold", value: "hold" },
  ];
  //b_status = [{ name: "Confirm" }];

  subtotal;
  room_id;
  Tax;
  bulkreservation = [];
  date_arrival_bulk;
  current_room;
  showsmoke: boolean = true;
  showwheel: boolean = true;
  showpets: boolean = true;
  darkMode: boolean = false;
  resWidth;

  package_dates: any;
  discount;
  date_departure;
  date_arrival;
  totalnights;
  room_number;
  extra_person_fee: any = 0;

  allPackages;
  allPackages2;
  current_season_id;
  selection;
  current_season;
  pricesArray = [];
  bulk_price = [];
  prices_bulk_Array = [];
  todayDate;
  submitted = false;
  allChannels: any;
  Discount = "false";
  allPromo = [];
  promoDiscount = null;
  weather_update = [];
  seperO;
  seperA: boolean = true;
  applyChkInDate;
  applyChkOutDate: boolean = true;
  check_out;
  check_in;
  guest_card_type;
  comaArrayRate = [];
  prico = [];
  packages = [];
  current_property = localStorage.getItem("current_property");
  guest_name_second;

  WHcolors = [
    "#ffc107",
    "#ec1361",
    "#09fb3d",
    "#ff51f3",
    "#48fff3",
    "#9a00ff",
    "#ff6f00",
  ];
  perDayData = [];
  disabledPop = true;
  toolChkData;
  user = JSON.parse(localStorage.getItem("user"));
  taxper = JSON.parse(localStorage.getItem("property")).taxper;
  roomFilter: boolean = true;
  segmonLeft: boolean = true;
  bookingStatus: boolean = true;
  SD: any = moment().format("Y-M-01");
  CD: any = moment().format("Y-M-D");

  minDate: dayjs.Dayjs = dayjs().subtract(270, "days");
  maxDate: dayjs.Dayjs = dayjs().add(8, "month");

  onlyShowBooking = {
    id: null,
    property_id: null,
    discount: null,
    promo: null,
    company_name: null,
    eta: "13:00",
    etd: "12:30",
    channel_id: 1,
    package_id: null,
    season_id: null,
    season_room_type_id: null,
    room_id: null,
    room_number: null,
    reservation_id: null,
    collect_details: 1,
    rr_id: null,
    guest_firstname: null,
    guest_id: null,
    guest_lastname: null,
    guest_city: null,
    guest_phone: null,
    guest_address: null,
    guest_email: null,
    guest_card: null,
    guest_card_type: null,
    guest_card_holder: null,
    comments: "clean",
    inventory: null,
    date_arrival: null,
    date_departure: null,
    pay_date: null,
    check_in: null,
    check_out: null,
    count_adult: null,
    count_child: null,
    count_infants: null,
    child_ages: null,
    total: null,
    currency: null,
    prices: "130,130,120,120,120",
    cashDeposit: null,
    payee_name: null,
    cancellation_date: null,
    personal_id_type: null,
    personal_id_value: null,
    payment_status: null,
    status: null,
    read_status: null,
    privilege: null,
    raw_xml: null,
    same_date: "1",
    RatePlanCode: null,
    RoomTypeCode: null,
    response_xml: null,
    modified_xml: null,
    modified_response_xml: null,
    cancel_xml: null,
    cancel_response_xml: null,
    TaxInclusive: "true",
    Taxper: "13",
    SpecialRequests: null,
    confirmation_number: null,
    total_rooms: null,
    pos: null,
    extra_person_fee: null,
    guest_card_series_code: null,
    guest_card_expiry: null,
    created_at: null,
    updated_at: null,
    testing: null,
    channel_image: "expedia.svg",
    channel_name: "Expedia",
    total_booking_items: 1,
    channel: {
      id: 1,
      name: "Expedia",
      commission: "13",
      image: "expedia.svg",
      website: null,
      type: "External",
      user_id: null,
      created_at: "2019-09-19T06:41:11.000000Z",
      updated_at: "2019-09-19T06:41:11.000000Z",
    },
    bookingitems: [],
  };

  TaxInclusive;
  total;
  xomio: any;
  xom: any;
  confirmation_number;
  start;
  end;
  deposit;
  current_currency;
  // minDate_dep: moment.Moment = moment().subtract(-1, "days");
  minDate_dep: dayjs.Dayjs = dayjs().subtract(270, "days");
  // minDate_dep: moment.Moment = moment()
  dayRstrictionUpdte = {
    room_id: "",
    change_date: "",
    season_id: "",
    package_id: "",
  };
  res_obj = {
    close_rates: false,
    stop_sell: false,
    cta: false,
    ctd: false,
    min_stay: {
      allowed: false,
      value: 1,
    },
    max_stay: {
      allowed: false,
      value: 1,
    },
  };
  building_detail = [];
  showDayNight = true;
  // WHcolors = ['#ffc107','#ec1361','#09fb3d','#ff51f3','#48fff3','#9a00ff','#ff6f00'];
  // colors = [ '#283ed8', '#ec13a0', '#09fb3d', '#a7002d', '#ffc107', '#9a00ff'];
  randomColors = [];

  IdTypes = ["State ID", "Driving License", "Passport"];

  roomNamesInreservationForm = ["Room"];
  roomNoInreservationForm = [""];
  guestNamesInreservationForm = [""];
  discountedAmountReservationPopup = 0;
  addOnsExtras: AddOnsExtras[] = [];
  addOnsDisplayMap: Map<number, AddOnsExtras[]> = new Map();
  roomIndex: number;
  addOnsModal: boolean[] = [];
  checkedItemsMap = new Map<number, Set<number>>();
  newExtrasGroup: FormGroup;
  currentDate: string;
  today: Date = new Date();
  disabled: boolean = false;

  constructor(
    private toastr: ToastrService,
    private api: ApiService,
    private error_handling: ErrorHandlingService,
    private helper: HelperService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private ngZone: NgZone
  ) {

  }

  @HostListener("scroll", ["$event"])
  onTableScroll(event) {
    let gap = Math.floor(event.target.scrollLeft / 180);

    if (
      event.target.scrollLeft + event.target.clientWidth ==
      event.target.scrollWidth
    ) {
      this.calendar_date.endDate = this.calendar_date.endDate.add(8, "days");
      this.get_range();
    }

    if (
      event.target.scrollLeft == 0 &&
      this.current_view.scrollx == event.target.scrollTop &&
      this.current_view.scrolly != event.target.scrollLeft
    ) {
      this.calendar_date.startDate = this.calendar_date.startDate.subtract(
        7,
        "days"
      );
      this.calendar_date.endDate = this.calendar_date.endDate.subtract(
        7,
        "days"
      );
      this.get_range();
      this.current_view.startDate = this.current_view.startDate.subtract(
        8,
        "days"
      );

      this.get_range();
      gap = 1;
      // event.target.scrollLeft = 2;
    }

    // debugger;
    if (gap != this.current_view.gap) {
      let space = gap - this.current_view.gap;
      this.current_view.startDate = this.current_view.startDate.add(
        space,
        "days"
      );
      this.current_view.endDate = this.current_view.endDate.add(space, "days");

      clearTimeout(this.current_view.interval);
      let id = setTimeout(() => {
        this.current_table();
      }, 1200);

      this.current_view.gap = gap;
      this.current_view.interval = id;
    }

    this.current_view.scrollx = event.target.scrollTop;
    this.current_view.scrolly = event.target.scrollLeft;
  }

  ngOnInit() {
    this.get_roomtypes();
    this.checkDarkMode();
    this.get_range();
    this.current_table();
    this.building_detail = [];
    this.createMaintenanceForm();
    this.getseason();
    this.permissions();
    this.RoomIconFilter(true, true);
    this.getChannels();
    this.getWeather();
    this.cash_deposit();
    this.getExpenses();
    // this.bulk_booking();

    this.recievedData();
    $(() => {
      $("table").on("click", ".dropdown-menu > *", function (e) {
        e.stopPropagation();
      });
      $("table").on("click", ".channel-menu > *", function (e) {
        e.stopPropagation();
      });

      $("table").on("click", "#closechannel", function (e) {
        $(this).closest(".otas_connected").toggleClass("open");
      });

      $("#bulk_update").on("show.bs.modal", () => {
        //this.get_bulk_update();
      });

      $("#edit_rate").on("show.bs.modal", () => {
        //this.get_selected_packages();
      });

      $("#bulk_update").draggable();
      $("#bulk_update").draggable("disable");

      $("#showBulkUpdate").draggable();
      $("#showBulkUpdate").draggable("disable");

      $("#housekeeping").draggable();
      $("#housekeeping").draggable("disable");

      $("#channel").draggable();
      $("#channel").draggable("disable");

      $("#bulk_updateTest").draggable();
      $("#bulk_updateTest").draggable("disable");
    });
    $(function () {
      $(".modal").mouseup(function () {
        $(".bluheader").css({ cursor: "grab" });
      });
      $(".modal").mousedown(function () {
        $(".bluheader").css({ cursor: "grabbing" });
      });
    });
    //drag function code--------------------
    $(function () {
      $("#drag-list").dragsort({
        placeHolderTemplate: `<div class='placeHolder' style=''><div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 70px;margin-bottom:5px;'></div></div>`,
        cursor: "move",
      });
    });
    $(function () {
      setTimeout(function () {
        $(".drag_box").dragsort({
          dragSelector: "div",
          dragBetween: true,
          dragEnd: () => {
            var data = $(".drag_box .drag_item")
              .map(function () {
                return $(this).children().html();
              })
              .get();
          },
          placeHolderTemplate:
            "<div class='placeHolder' style='float: left; width: 33%!important; padding-right:10px; padding-bottom:10px'>" +
            "<div style='background-color: #fff !important;border: 1px dashed #000 !important; height: 100%!important'>&nbsp;</div>" +
            "</div>",
          cursor: "move",
        });
      }, 1000);
    });
    // drag function end-------------------
    this.resWidth = window.innerWidth;
    if (this.reservation_bulk && this.reservation_bulk.value.payment_status == "unpaid") {
      this.reservation_bulk.patchValue({ payment_status: "paynow" });
    }

  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  get mf() {
    return this.maintenence_form.controls;
  }
  get rf() {
    return this.reservation_form.controls;
  }

  remove_vowel(str) {
    str = str.split(" ")[0] + (str.split(" ")[1] || "");
    str = str.replace(/[aeiou]/gi, "");
    return str;
  }

  chnageicon1() {
    this.showsmoke = !this.showsmoke;
  }

  chnageicon2() {
    this.showwheel = !this.showwheel;
  }

  chnageicon3() {
    this.showpets = !this.showpets;
  }

  dodrag(id) {
    $("#" + id).draggable("enable");
  }

  dontdrag(id) {
    $("#" + id).draggable("disable");
  }

  rotateDrop(id) {
    let a = document.getElementById(id);
    a.classList.contains("rotate")
      ? a.classList.remove("rotate")
      : a.classList.add("rotate");
  }

  //New Inventory Work
  roomtypes = [];
  selectedRoom = [30, 17];
  inventory_calendar: any[] = [];
  inventory = [];
  calendar_date: CalendarDate = { startDate: moment(), endDate: moment().add(30, "days") };
  calendar_range = [];
  current_view = {
    startDate: moment().subtract(1, "days"),
    endDate: moment().add(8, "days"),
    gap: 0,
    interval: null,
    scrollx: 0,
    scrolly: 0,
  };
  current_range = [];

  get_roomtypes() {
    this.api
      .post(
        "api/roomtype/index?per_page=50",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.roomtypes = res.body.data;

          for (let i = 0; i < 2; i++) {
            //this.selectedRoomType.push(this.roomtypes[i].id)
          }
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  selectTopRoom(selectedRoom) {
    this.selectedRoom = selectedRoom;
    if (selectedRoom.length == 2) {
      this.map_boxes();
    }
  }

  get_range(from_picker = false) {
    let range = [];
    
    let start = this.calendar_date.startDate.toDate();
    let end = this.calendar_date.endDate.toDate();
    
    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }
    
    if (from_picker) {
      document.querySelector(".scroll").scrollLeft = 5;
      while (range.length < 9) {
        range.push(moment(range[range.length - 1]).add("1", "days"));
      }

      this.current_view.startDate = range[0];
      this.current_view.endDate = range[range.length - 1];
      this.current_view.gap = 0;
    }

    this.calendar_range = range;

  }

  current_table(new_start = null, new_end = null) {
    let range = [];
    let start = moment(new_start || this.current_view.startDate).toDate();
    let end = moment(new_end || this.current_view.endDate).toDate();

    while (start < end) {
      range.push(moment(start));
      start = new Date(start.setDate(start.getDate() + 1));
    }

    this.current_range = range;
    this.get_inventory();
  }

  calendar_change(e) {
    if (e.startDate != null && e.endDate != null) {
      this.get_range(true);
      this.current_table();
    }
  }


  get_inventory() {
    this.inventory = [];
    this.map_boxes();
  }

  map_boxes() {
    let a = {
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem("user")).id,
      start_date: this.calendar_date.startDate.format("YYYY-MM-DD"),
      end_date: this.calendar_date.endDate.format("YYYY-MM-DD"),
      //room_type_id: this.selectedRoom.join()
    };

    console.log(this.calendar_date, "calendar_date");
    console.log(this.calendar_range, "calendar_range");

    this.api.post("api/inventory/calendar", a, true).subscribe(
      (res: any) => {
        if (
          res.body.status != undefined &&
          res.body.message == "Data Not Found..."
        ) {
          this.helper.alert_warning(res.body.message);
          return;
        }
        this.inventory_calendar = res.body.data;

        for (let x = 0; x < this.inventory_calendar.length; x++) {
          this.calendarDropDowns.push(false);
        }

        this.ref.markForCheck();
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  week_move(move) {
    let scroll_obj = document.querySelector(".scroll");
    if (move == "next") {
      scroll_obj.scrollLeft += 1440;
    } else {
      scroll_obj.scrollLeft -= 1440;
    }
  }

  table_drag() {
    let viewport = <HTMLElement>document.querySelector(".scroll");
    let content = <HTMLElement>document.querySelector(".grid_calendar");

    this.scroller = new ScrollBooster({
      viewport,
      content,
      direction: "horizontal",
      bounce: false,
      emulateScroll: false,
      inputsFocus: false,
      onUpdate: (state) => {
        viewport.scrollLeft = state.position.x;
      },
    });
  }

  check_pkg(pkg_id, date, e) {
    // if (e.target.checked) {
    //   this.checked_pkg.push({date: date, pkg_id: pkg_id});
    // } else {
    //   this.checked_pkg = this.checked_pkg.filter((val, i) => {
    //     return val.date == date && val.pkg_id == pkg_id ? false : true;
    //   });
    // }
    // this.get_selected_packages();
  }

  change_check_pkg(i, day, e) {
    // let change = false;
    // let chks = this.checked_pkg.map((chk) => chk.pkg_id).filter((item, pos, self) => {
    //   return self.indexOf(item) == pos;
    // });
    // if (e.target.checked) {
    //   chks.forEach(chk => {
    //     let start = moment(this.bulk_dates.startDate).toDate();
    //     let end = moment(this.bulk_dates.endDate).toDate();
    //     while (start < end) {
    //       if(moment(start, 'YYYY-MM-DD').format('dddd') == day){
    //         this.checked_pkg.push({date: moment(start).format('YYYY-MM-DD'), pkg_id: chk});
    //       }
    //       start = new Date(start.setDate(start.getDate() + 1));
    //     }
    //   });
    // } else {
    //   chks.forEach(chk => {
    //     this.checked_pkg = this.checked_pkg.filter((val, i) => {
    //       return moment(val.date, 'YYYY-MM-DD').format('dddd') == day && val.pkg_id == chk ? false : true;
    //     });
    //   });
    // }
    // this.get_selected_packages();
    // this.get_bulk_update();
  }

  change_date_pkg(e) {
    // let start = moment(e.startDate).toDate();
    // let end = moment(e.endDate).toDate();
    // let chks = this.checked_pkg.map((chk) => chk.pkg_id).filter((item, pos, self) => {
    //   return self.indexOf(item) == pos;
    // });
    // this.checked_pkg = [];
    // while (start < end) {
    //   chks.forEach((chk) => {
    //     this.checked_pkg.push({date: moment(start).format('YYYY-MM-DD'), pkg_id: chk});
    //   });
    //   start = new Date(start.setDate(start.getDate() + 1));
    // }
    // this.get_selected_packages();
    // this.get_bulk_update();
  }

  is_pkg_checked(date, id) {
    //return this.checked_pkg.some(e => e.date == date && e.pkg_id == id);
  }

  is_pkg_disabled(id) {
    // if (this.checked_pkg.length != 0) {
    //   let pkg_id = this.checked_pkg[0].pkg_id;
    //   let pkg = this.selected_packages.filter((sp) => sp.id == pkg_id);
    //   return pkg[0].room_type_id == id ? false : true;
    // }
    // return false;
  }

  // check_box_status(res){
  //   console.log(res);
  //   // return res.some(r => r == 'cta' || r == 'ctd');
  //   // return false;
  //   if (res.cta == true && res.ctd == true && res.stop_sell == true) {
  //     return true;
  //   } else if (res.cta == true) {
  //     return true;
  //   } else if (res.ctd == true) {
  //     return true;
  //   } else if (res.stop_sell == true) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  is_box_shown(boxes, date) {
    return boxes.find((b) => b.date == date.format("YYYY-MM-DD")) || false;
  }

  trackByInv(index, item) {
    return index;
  }

  trackByVen(index, item) {
    return index;
  }

  trackByBox(pkg_id, index, item) {
    return index + "_" + pkg_id;
  }

  trigger_click() {
    let obj = <HTMLElement>(
      document.querySelector(".buttons .buttons_input .btn")
    );
    obj.click();

  }

  input_focus($event) {
    $event.target.focus();
  }

  count_availability(date, obj) {
    let counter = 0;
    obj.forEach((o) => {
      o.closed_dates.some((c) => c == date) || counter++;
    });
    return counter;
  }

  scrollNow() {
    let a = document.getElementById("scroll_wrapper");
    a.scrollTop = 1;
  }
  //New Inventory Work END

  box_channels_internal = [];
  box_channels_extarnal = [];
  //New Box Work
  open_channel_box(ch) {
    ch.forEach((e) => {
      if (e.type == "External") {
        this.box_channels_extarnal.push(e);
      } else {
        this.box_channels_internal.push(e);
      }
    });
    $("#channel").modal({ backdrop: "static", keyboard: true });
    $("#channel").draggable();
  }

  selectShow(i) {
    // if (this.selectedRow == i) {
    //   this.selectedRow = undefined;
    // } else {
    //   this.selectedRow = i;
    // }
    this.calendarDropDowns[i] = !this.calendarDropDowns[i];
  }

  invCount(c, d) {
    if (c != undefined && d != undefined) {
      let f = c.filter((e) => e.date == d);
      return f.length == 0 ? 0 : f[0].room_count < 0 ? 0 : f[0].room_count;
    }
    return 0;
  }
  //New Box work END

  //Close for Maintenence
  vendorList = [];
  selectedVendor: any;
  maintenence_form: FormGroup;
  selectedVen: any;
  mSubmit: boolean = false;

  onclickhouse(ven, date) {
    this.selectedVen = ven;
    this.selectedVen.date = date;
    this.getVandorList();
  }

  getVandorList() {
    this.api
      .post("api/user/vendor_list", { vendor_type: "External" }, true)
      .subscribe(
        (res: any) => {
          this.vendorList = res.body.data;
          setTimeout(() => {
            this.maintenence_form.patchValue({
              building_id: this.selectedVen.building_id,
              room_no_id: this.selectedVen.id,
              floor_id: this.selectedVen.floor_id,
              start_date: moment(this.selectedVen.date).format("YYYY-MM-DD"),
            });
          });
          $("#housekeeping").modal("show");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  createMaintenanceForm() {
    this.maintenence_form = this.fb.group({
      //name: ['', Validators.required],
      assigned_by_id: JSON.parse(localStorage.getItem("user")).id,
      vendor_id: [null, Validators.required],
      building_id: ["", Validators.required],
      floor_id: ["", Validators.required],
      room_no_id: ["", Validators.required],
      start_date: ["", Validators.required],
      issue: ["", Validators.required],
    });
  }

  saveCloseMaintenance() {
    this.mSubmit == true;
    if (this.maintenence_form.invalid) {
      return;
    }

    this.api
      .post("api/maintenance/store", this.maintenence_form.value, true)
      .subscribe(
        (res: any) => {
          this.map_boxes();
          this.helper.alert_success("Vendor Assigned Successfully");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  //Close for Maintenence END

  //Reserveation popup WOrk
  reservation_form: FormGroup;
  reservationSubmit: boolean = false;
  room_name;

  RoomIconFilter(condition, pEvents) {
    this.allPackages = this.allPackages2;
    if (condition == true) {
      this.showsmoke = false;
      this.showwheel = false;
      this.showpets = false;
    } else {
      setTimeout(() => {
        if (
          (this.reservation_form.value.date_arrival.endDate != undefined &&
            this.reservation_form.value.date_departure.endDate != undefined) ||
          (this.reservation_form.value.date_arrival.endDate != null &&
            this.reservation_form.value.date_departure.endDate != null)
        ) {
          this.getroomtype(
            this.showsmoke,
            this.showwheel,
            this.showpets,
            condition,
            moment(this.reservation_form.value.date_arrival.endDate._d).format(
              "YYYY-MM-DD"
            ),
            moment(
              this.reservation_form.value.date_departure.endDate._d
            ).format("YYYY-MM-DD")
          );
        } else {
          this.getroomtype(
            this.showsmoke,
            this.showwheel,
            this.showpets,
            condition,
            this.reservation_form.value.date_arrival,
            this.reservation_form.value.date_departure
          );
        }
      }, 200);
    }
    if (!pEvents) {
      // (<HTMLInputElement>document.getElementById('cNone')).style.pointerEvents = 'all';
      setTimeout(() => {
        (<HTMLInputElement>document.getElementById("btnSaveEv")).style.display =
          "block";
      }, 500);
      this.disabledPop = true;
    }
  }

  ResetCheckInCheckoutDates() {
    this.minDate = dayjs().subtract(60, "day");
    this.minDate_dep = dayjs().subtract(60, "day");
  }

  getroomtype(smoking, wheelchair, pets, condition, dArr, dDep) {
    let a = {
      property_id: localStorage.getItem("current_property"),
      start_date: dArr,
      end_date: dDep,
      wheelchairaccessibility: wheelchair.toString(),
      smoking: smoking.toString(),
      pets: pets.toString(),
    };
    let b = {
      property_id: localStorage.getItem("current_property"),
      start_date: dArr,
      end_date: dDep,
    };
    if (dArr != "Invalid date") {
      this.api
        .post(
          "api/booking/reservation_room_types",
          condition == false ? a : b,
          true
        )
        .subscribe(
          (res: any) => {
            this.room_name = res.body.data;
            this.room_data = res.body.data;
            //  console.log(this.room_name)
          },
          (err) => this.error_handling.handle_error(err.status)
        );
    }
  }

  // ******* reservation API *******

  getseason() {
    this.api
      .post(
        "api/season_package/index",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          // let todayDate = mm + '-' + dd + '-' + yyyy;
          this.todayDate = yyyy + "-" + mm + "-" + dd;
          res.body.data.seasons.data.map((val1) => {
            if (
              this.todayDate <= val1.date_from &&
              this.todayDate >= val1.date_to
            ) {
              this.current_season_id = val1.id;
              this.current_season = val1;
            }
          });
          this.createRservationForm();
          this.bulk_booking();
          this.table_drag();
          this.addNewGuest(
            this.reservation_form.controls.booking_items,
            null
          );
          this.reservation_form.patchValue({
            currency: localStorage.getItem("currency"),
          });
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }

  getroomnumber(id, index) {
    let roomIdForApi;
    if (id == undefined) {
      return;
    } else {
      this.room_name.map((val) => {
        if (val.room_id == id.room_id) {
          roomIdForApi = val.room_id;
          val.pets == "true" ? (this.showpets = true) : (this.showpets = false);
          val.wheelchairaccessibility == "true"
            ? (this.showwheel = true)
            : (this.showwheel = false);
          val.smoking == "true"
            ? (this.showsmoke = true)
            : (this.showsmoke = false);
        }
      });
    }

    //add 1 day to current date to fetch data
    let data = {
      date_arrival: moment(this.reservation_form.value.date_arrival.endDate)
        .add(1, "d")
        .format("YYYY-MM-DD"),
      date_departure: moment(
        this.reservation_form.value.date_departure.endDate
      ).format("YYYY-MM-DD"),
      room_id: roomIdForApi,
      property_id: localStorage.getItem("current_property"),
      edit: false,
    };

    this.api
      .post("api/housekeeping/get_room_no_by_room_id", { data }, true)
      .subscribe(
        (res: any) => {
          this.room_number = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
    this.onsubmit_reservation(index);
  }

  reservation() {
    this.api
      .post(
        "api/booking/store",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.roomtypes = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  addNewGuest(control, data) {
    if (data == null) {
      this.roomNamesInreservationForm.push(
        this.roomNamesInreservationForm[
        this.roomNamesInreservationForm.length - 1
        ]
      );
      control.push(
        this.fb.group({
          guest_name: [null, Validators.required],
          room_id: [null, Validators.required],
          room_no_id: null,
          guest_id: "",
          package_id: "",
          season_id: this.current_season_id,
          promotion_id: "",
          no_of_adults: "",
          no_of_childs: "",
          no_of_infants: "",
          // extras: this.fb.array([]),
          extra_persons: this.fb.array([]),
          booking_extras: this.fb.array([]),
          check_in: null,
          check_out: null,
          prices: null,
          status: "Pending",
          pricesjson: null,
          guest_status: null,
        })
      );
    } else {
      let pId;
      if (data.package_id == 0) {
        pId = null;
      } else {
        pId = data.package_id;
      }

      control.push(
        this.fb.group({
          guest_name: data.guest_name,
          room_id: [data.room_id, Validators.required],
          room_no_id: data.room_no_id,
          guest_id: data.guest_id,
          package_id: pId,
          season_id: data.season_id,
          promotion_id: data.promotion_id,
          no_of_adults: [data.no_of_adults, Validators.required],
          no_of_childs: [data.no_of_childs, Validators.required],
          no_of_infants: [data.no_of_infants, Validators.required],
          extra_persons: this.fb.array([]),
          check_in: [
            {
              startDate: moment(data.check_in),
              endDate: moment(data.check_in),
            },
            Validators.required,
          ],
          check_out: [
            {
              startDate: moment(data.check_out),
              endDate: moment(data.check_out),
            },
            Validators.required,
          ],
          prices: data.prices,
        })
      );
    }
  }
  createRservationForm() {
    this.reservation_form = this.fb.group({
      property_id: localStorage.getItem("current_property"),
      channel_id: [null, Validators.required],
      guest_status: [null],
      room_id: "",
      package_id: "",
      season_id: this.current_season_id,
      reservation_id: "",
      date_arrival: [null, Validators.required],
      date_departure: "",
      count_adult: "",
      count_child: "",
      total: [null, Validators.required],
      prices: [null, Validators.required],
      guest_firstname: "",
      guest_lastname: "",
      payment_status: [null, Validators.required],
      status: [null, Validators.required],
      currency: null,
      child_ages: "",
      guest_phone: "",
      confirmation_number: "",
      guest_address: "",
      guest_email: [null, Validators.compose([Validators.email])],
      comments: "clean",
      room_number: "",
      guest_city: "",
      booking_items: this.fb.array([]),
      guest_card: [
        null,
        [
          Validators.pattern(
            "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
          ),
        ],
      ],
      guest_card_type: null, //[null, Validators.required],
      payee_name: null, //[null, Validators.required],
      guest_card_expiry: null, //[null, Validators.required],
      guest_card_series_code: null, //[null, Validators.required],
      privilege: null,
      marketing_email: "",
      eta: [localStorage.getItem("eta"), Validators.required],
      etd: [localStorage.getItem("etd"), Validators.required],
      personal_id_type: null,
      personal_id_value: null,
      discount: this.promoDiscount,
      discount_package: null,
      company_name: "",
      promo: null,
      same_date: null,
      SpecialRequests: null,
      TaxInclusive: null,
      extra_person_fee: null,
      cashDeposit: null,
      Tax: null,
      Taxper: null,
      subtotal: null,
    });
  }

  bulk_booking() {
    this.reservation_bulk = this.fb.group({
      property_id: localStorage.getItem("current_property"),
      channel_id_bulk: [null, Validators.required],
      guest_status: [null, Validators.required],
      room_id: "",
      package_id_bulk: "",
      season_id: this.current_season_id,
      reservation_id: "",
      date_arrival_bulk: [null, Validators.required],
      date_departure_bulk: "",
      count_adult_bulk: "",

      count_child_bulk: "",
      total_bulk: [null, Validators.required],
      prices_bulk: [null, Validators.required],
      guest_firstname_bulk: "",
      guest_lastname_bulk: "",
      payment_status_bulk: [null, Validators.required],
      status_bulk: [null, Validators.required],
      currency_bulk: null,
      child_ages_bulk: "",
      // guest_phone_bulk: [null, Validators.required],
      guest_phone: "",
      guest_address_bulk: "",
      guest_email_bulk: [null, Validators.compose([Validators.email])],
      comments_bulk: "clean",
      room_number_bulk: "",
      totalnights_bulk: "",
      guest_city_bulk: "",
      booking_items_bulk: this.fb.array([]),
      guest_card_bulk: [
        null,
        [
          Validators.pattern(
            "^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35d{3})d{11})$"
          ),
        ],
      ],
      guest_card_type: "",
      guest_card_type_bulk: "",
      payee_name_bulk: "",
      guest_card_expiry_bulk: "",
      guest_card_series_code_bulk: "",
      privilege_bulk: "",
      marketing_email_bulk: "",
      eta_bulk: [localStorage.getItem("eta"), Validators.required],
      etd_bulk: [localStorage.getItem("etd"), Validators.required],
      personal_id_type_bulk: "",
      personal_id_value_bulk: "",
      discount_bulk: this.promoDiscount,
      discount_package_bulk: "",
      company_name_bulk: "",
      promo_bulk: null,
      same_date_bulk: null,
      SpecialRequests_bulk: null,
      TaxInclusive_bulk: null,
    });
  }

  //Reservarion Popup Work END

  chkRadio(id1, id2, id3, id4) {
    let a = <HTMLInputElement>document.getElementById(id1);
    let b = <HTMLInputElement>document.getElementById(id2);
    let c = <HTMLInputElement>document.getElementById(id3);
    let d = <HTMLInputElement>document.getElementById(id4);
    // b.style.color = '#666';
    // d.style.color = '#666';
    // a.checked == true ? b.style.color = 'orange' : b.style.color = '#666'
    // c.checked == true ? d.style.color = 'orange' : d.style.color = '#666'
  }

  getnigths(st, en) {
    if (st != undefined) {
      let current_date = moment().subtract(0, "days");
      let end_date: any = moment(st.endDate);
      let difff = end_date.diff(current_date, "days");
      let difrence = ++difff;
      // this.minDate_dep = moment().subtract(-difrence, "days");
    }
    if (en == undefined || st == undefined) {
      return;
    } else {
      let start = moment(st.endDate);
      let end = moment(en.endDate);
      this.start = moment(st.endDate);
      this.end = moment(en.endDate);

      let current_date = moment().subtract(0, "days");
      let end_date: any = moment(st.endDate);
      let difff = end_date.diff(current_date, "days");
      let difrence = ++difff;
      this.minDate_dep = dayjs().subtract(-difrence, "days");
      let dif = end.diff(start, "days");
      this.totalnights =
        dif == 1
          ? dif + " Night"
          : dif > 1
            ? dif + " Nights"
            : dif == 0
              ? 1 + " Day"
              : 0;

      dif > 0
        ? (this.showDayNight = true)
        : dif == 0
          ? (this.showDayNight = false)
          : (this.showDayNight = true);
      if (
        st != undefined &&
        en != undefined &&
        this.room_type_data != null &&
        st[0] != undefined
      ) {
        this.reagain();
      }

      this.getroomtype(
        this.showsmoke,
        this.showwheel,
        this.showpets,
        true,
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      this.getPackageByDate(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
    }
  }

  calculateprice() {
    // debugger;
    if (this.reservation_form.value.prices != null) {
      if (this.reservation_form.value.discount > 100) {
        return;
      }

      let sum: any = 0;
      var extra: any,
        extra =
          "" == this.extra_person_fee || null == this.extra_person_fee
            ? 0
            : this.extra_person_fee;

      let comaArrayRate = [];
      let g = this.reservation_form.value.prices.split(",");
      let z = g.map((elem) => parseInt(elem, 10));

      for (let i = 0; i < z.length; i++) {
        sum += z[i];
        comaArrayRate.push(z[i]);
      }

      var summ = parseInt(extra) + parseInt(sum);

      let r = summ / 100;
      let discountedTotal = summ - r * this.reservation_form.value.discount;
      var b = JSON.parse(localStorage.getItem("property"));
      let formula: any;
      this.TaxInclusive == "false"
        ? (formula = "0." + b.taxper)
        : (formula = 0);

      this.TaxInclusive = b.taxInclusive;

      // this.TaxInclusive == "false" ? () : "";
      this.subtotal = summ;

      let percantage = discountedTotal * formula;

      this.TaxInclusive == "false" ? (b.taxper = formula) : "";
      this.TaxInclusive == "false" ? (this.Tax = Math.round(percantage)) : "";
      this.discountedAmountReservationPopup = summ - discountedTotal;

      this.reservation_form.patchValue({
        total: (
          discountedTotal +
          percantage +
          this.calculateAddOnsPrice()
        ).toFixed(2),
        season_id: this.current_season_id,
        Taxper: this.taxper,
        subtotal: this.subtotal,
      });
    } else {
      return;
    }
  }

  updateDiscountPercentage() {
    let discountAmount = this.discountedAmountReservationPopup;
    let sum: any = 0;
    var extra: any,
      extra =
        "" == this.extra_person_fee || null == this.extra_person_fee
          ? 0
          : this.extra_person_fee;

    let comaArrayRate = [];
    let g = this.reservation_form.value.prices.split(",");
    let z = g.map((elem) => parseInt(elem, 10));

    for (let i = 0; i < z.length; i++) {
      sum += z[i];
      comaArrayRate.push(z[i]);
    }

    var summ = parseInt(extra) + parseInt(sum);
    this.subtotal = summ;
    let percentage = (discountAmount / summ) * 100;
    let total = summ - discountAmount;

    this.reservation_form.patchValue({
      total: total.toFixed(2),
      discount: percentage.toFixed(3),
    });
  }

  get f() {
    return this.reservation_form.controls;
  }
  submitReserv() {
    this.reservation_form.value.status == "hold"
      ? (this.bookingStatus = false)
      : (this.bookingStatus = true);
    this.submitted = true;
    if (this.applyChkOutDate) {
      for (
        let i = 0;
        i < this.reservation_form.value.booking_items.length;
        i++
      ) {
        this.reservation_form.value.booking_items[i].check_in =
          this.reservation_form.value.date_arrival;
        this.reservation_form.value.booking_items[i].check_out =
          this.reservation_form.value.date_departure;
      }
      this.pricesArray = [];
      this.comaArrayRate = [];
    } else {
      for (
        let i = 0;
        i < this.reservation_form.value.booking_items.length;
        i++
      ) {
        if (this.reservation_form.value.booking_items[i].check_in.endDate) {
          this.reservation_form.value.booking_items[i].check_in = moment(
            this.reservation_form.value.booking_items[i].check_in.endDate
          ).format("YYYY-MM-DD");
        }
        if (this.reservation_form.value.booking_items[i].check_out.endDate) {
          this.reservation_form.value.booking_items[i].check_out = moment(
            this.reservation_form.value.booking_items[i].check_out.endDate
          ).format("YYYY-MM-DD");
        }
      }
    }

    if (this.reservation_form.value.date_arrival.endDate) {
      this.reservation_form.patchValue({
        date_arrival: moment(
          this.reservation_form.value.date_arrival.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    if (this.reservation_form.value.date_departure.endDate) {
      this.reservation_form.patchValue({
        date_departure: moment(
          this.reservation_form.value.date_departure.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    this.reservation_form.patchValue({
      same_date: this.applyChkOutDate,
    });

    this.reservation_form.patchValue({ TaxInclusive: this.taxInclusivee });

    if (this.reservation_form.value.payment_status == "unpaid") {
      this.reservation_form.patchValue({ payment_status: "paynow" });
    }

    if (this.reservation_form.invalid) {
      this.findInvalidControls();
      return this.helper.alert_error("Please fill all required fields");
    }

    const reservationData = this.reservation_form.value;

    // Iterate through booking_items and filter out null values in extras objects
    if (reservationData.booking_items && reservationData.booking_items.length > 0) {
      reservationData.booking_items.forEach((bookingItem: any) => {
        if (bookingItem.booking_extras) {
          const filteredBookingExtras: { [key: string]: any } = {};
          for (const key of Object.keys(bookingItem.booking_extras)) {
            const value = bookingItem.booking_extras[key];
            if (value !== null) {
              filteredBookingExtras[key] = value;
            }
          }
          bookingItem.booking_extras = filteredBookingExtras;
        }
      });
    }

    console.log(this.reservation_form, "form submit");

    // // return;
    this.api
      .post("api/booking/store_bulk", this.reservation_form.value, true)
      .subscribe(
        (res: any) => {
          this.resetForm(this.reservation_form);
          $("#bulk_update").modal("hide");
          this.get_inventory();
          this.helper.alert_success("Reservation made successfully.");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  resetForm(form) {
    this.addOnsModal = this.addOnsModal.fill(false);
    this.minDate = dayjs().subtract(60, "days");
    this.maxDate = dayjs().add(2, "month");
    this.minDate_dep = dayjs().subtract(60, "days");
    // this.calendar_date = { startDate: moment(), endDate: moment().add("30", "days") };
    form.reset();
    form.controls.booking_items.controls.splice(1, 1000);
    this.totalnights = null;
    this.submitted = false;
    this.reservation_form.patchValue({
      discount: this.promoDiscount,
      season_id: this.current_season_id,
      property_id: localStorage.getItem("current_property"),
      currency: "$",
      comments: "clean",
    });
    this.perDayData = [];
    let a = <HTMLInputElement>document.getElementById("sideBarDetail");
    a.style.right = "0%";
  }

  getChannels() {
    this.api
      .post(
        "api/property/active_channels",
        { property_id: localStorage.getItem("current_property") },
        true
      )
      .subscribe(
        (res: any) => {
          this.allChannels = res.body.data;
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  getChannelId(id) {
    if (id.type == "External") {
      this.confirmation_yes = true;
    } else {
      this.confirmation_yes = false;
    }
    this.allPromo = [];
    let a = {
      channel_id: id.id,
      property_id: localStorage.getItem("current_property"),
    };
    this.api
      .post("api/package/promo_search_by_channel", a, true)
      .subscribe((res: any) => {
        this.allPromo = res.body.data;
      });
  }
  getPromo(data) {
    let a = <HTMLInputElement>document.getElementById("discountPromo");
    let c = <HTMLInputElement>document.getElementById("disClick");
    if (data != undefined) {
      a.readOnly = true;
      c.classList.add("uncluck");
      this.reservation_form.patchValue({
        discount: data.promo.discount,
      });
      this.promoDiscount = data.promo.discount;
    } else {
      a.readOnly = false;
      c.classList.remove("uncluck");
      this.reservation_form.patchValue({
        discount: null,
      });
    }
    this.calculateprice();
  }
  chkPackage(data) {
    if (data == undefined) {
      return;
    } else {
      let b = [];
      if (this.allPackages == undefined) {
        return;
      } else {
        this.allPackages.map((val, index) => {
          if (data.room_id == val.room_id) {
            b.push(val);
          }
        });
        let a = <HTMLInputElement>document.getElementById("sideBarDetail");
        a.style.right = "-244px";
      }
      this.allPackages = b;
    }
  }
  getWeather() {
    var cities: any = "";
    var prop;
    cities =
      null != JSON.parse(localStorage.getItem("property"))
        ? [(prop = JSON.parse(localStorage.getItem("property"))).city]
        : ["Toronto"];
    cities.forEach((city) => {
      let req =
        "https://api.openweathermap.org/data/2.5/weather?q=" +
        city +
        "&units=metric&callback=?&APPID=db36769dbdff741d9ad3a80c6659d1b1";
      let forcast =
        "https://api.openweathermap.org/data/2.5/forecast/daily?q=" +
        city +
        "&cnt=7&lang=en&units=metric&APPID=bfab95ebe3bbb8966c54139aefd539b4";
      $.getJSON(req).then((res) => {
        let weather_data = {
          title: res.name,
          code: res.weather[0].id,
          icon: res.weather[0].icon.split(".")[0],
          condition: res.weather[0].main,
          moment: moment(),
          wind: res.wind.speed,
          temperature: res.wind.temp,
          day: new Date().toLocaleTimeString("en-us", { weekday: "short" }),
          min_temp: res.main.temp_min,
          max_temp: res.main.temp_max,
          forcast: [],
        };

        $.getJSON(forcast).then((res) => {
          let fore_arr = [];
          res.list.forEach((element) => {
            var day = new Date(element.dt * 1000).toLocaleTimeString("en-us", {
              weekday: "short",
            });
            var icon = element.weather[0].icon.split(".")[0];
            var min_temp = element.temp.min;
            var max_temp = element.temp.max;
            fore_arr.push({ day, icon, min_temp, max_temp });
          });
          weather_data.forcast = fore_arr;
        });
        this.weather_update.push(weather_data);
      });
    });
  }
  fliBox() {
    $(function () {
      setTimeout(() => {
        $("#flip").flipbox({
          vertical: true,
          autoplay: true,
          autoplayReverse: false,
          autoplayWaitDuration: 5000,
          autoplayPauseOnHover: true,
        });
        setTimeout(() => {
          $("#flip2").flipbox({
            vertical: true,
            autoplay: true,
            autoplayReverse: false,
            autoplayWaitDuration: 5000,
            autoplayPauseOnHover: true,
          });
          setTimeout(() => {
            $("#flip3").flipbox({
              vertical: true,
              autoplay: true,
              autoplayReverse: false,
              autoplayWaitDuration: 5000,
              autoplayPauseOnHover: true,
            });
          }, 400);
        }, 100);
      }, 400);
      $("[data-toggle='tooltip']").tooltip();
    });
    setTimeout(() => {
      let a = <HTMLInputElement>document.getElementById("checkedForSep");
      if (!a.checked) {
        a.click();
      }
    }, 300);
    this.reservation_form.patchValue({
      eta: localStorage.getItem("eta"),
      etd: localStorage.getItem("etd"),
    });
  }

  getValA(data) {
    this.applyChkOutDate = data.target.checked;
    data.target.checked
      ? (this.toolChkData =
        "Uncheck the checkbox to apply seperate dates on each room.")
      : (this.toolChkData =
        "Check the checkbox to apply same dates on all rooms.");
    let a = <FormArray>this.reservation_form.controls["booking_items"];
    a.reset();
    if (!this.applyChkOutDate) {
      for (
        let i = 0;
        i < this.reservation_form.value.booking_items.length;
        i++
      ) {
        a.controls[i].patchValue({
          check_in: {
            startDate: moment(new Date()),
            endDate: moment(new Date()),
          },
          check_out: {
            startDate: moment(new Date()),
            endDate: moment(new Date()),
          },
        });
      }
      this.pricesArray = [];
      this.comaArrayRate = [];
    }
  }

  onsubmit_reservation(index) {
    this.xomio = "";
    this.xom = "";
    this.indexing = index;

    if (
      (this.reservation_form.value.date_arrival.endDate != undefined &&
        this.reservation_form.value.date_departure.endDate != undefined) ||
      (this.reservation_form.value.date_arrival.endDate != null &&
        this.reservation_form.value.date_departure.endDate != null)
    ) {
      this.reservation_form.patchValue({
        date_arrival: moment(
          this.reservation_form.value.date_arrival.endDate._d
        ).format("YYYY-MM-DD"),
        date_departure: moment(
          this.reservation_form.value.date_departure.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    let a = {
      property_id: localStorage.getItem("current_property"),
      user_id: JSON.parse(localStorage.getItem("user")).id,
      start_date: this.reservation_form.value.date_arrival,
      end_date: this.reservation_form.value.date_departure,
    };
    if (a.start_date.endDate === null) {
      // return this.helper.alert_error('Please Select Check-in Date!');
    }
    if (a.end_date.endDate === null) {
      // return this.helper.alert_error('Please Select Check-out Date!');
    }
    if (!this.applyChkOutDate) {
      if (
        this.reservation_form.value.booking_items[index].check_in.endDate !=
        undefined
      ) {
        langArr.controls[index].patchValue({
          check_in: moment(
            this.reservation_form.value.booking_items[index].check_in.endDate._d
          ).format("YYYY-MM-DD"),
          check_out: moment(
            this.reservation_form.value.booking_items[index].check_out.endDate
              ._d
          ).format("YYYY-MM-DD"),
        });
      }
    }

    let b = this.reservation_form.value;

    b.booking_items[index].package_id;

    if (b.date_arrival.endDate === null) {
      // return this.helper.alert_error('Check-in date required!');
    } else if (b.date_departure.endDate === null) {
      // return this.helper.alert_error('Check-out Date required!');
    }
    let pId;
    if (
      b.booking_items[index].package_id == null ||
      b.booking_items[index].package_id == ""
    ) {
      pId = 0;
    } else {
      pId = b.booking_items[index].package_id;
    }

    //DATE CHANGES FOR SAME DAY
    this.xom = {
      start_date: b.date_arrival,
      end_date:
        b.date_arrival == b.date_departure
          ? this.incrementDate(b.date_departure)
          : b.date_departure,
      property_id: localStorage.getItem("current_property"),
      room_id: b.booking_items[index].room_id,
      package_id: pId,
    };
    this.xomio = {
      start_date: b.booking_items[index].check_in,
      end_date: b.booking_items[index].check_out,
      property_id: localStorage.getItem("current_property"),
      room_id: b.booking_items[index].room_id,
      package_id: pId,
    };
    this.room_id = b.booking_items[index].room_id;

    this.api
      .post(
        "api/booking/get_rates_by_checkin_checkout",
        this.applyChkOutDate ? this.xom : this.xomio,
        true
      )
      .subscribe(
        (res: any) => {
          var ar = "";
          if (
            res.body.data[0].display_name == "" ||
            res.body.data[0].display_name == null
          ) {
            ar = res.body.data[0].name;
          } else {
            ar = res.body.data[0].display_name;
          }
          this.perDayData.unshift({
            roomName: ar,
            roomIndex: index,
            rates: res.body.data,
            package_name: res.body.data[0].package_name || "",
          });

          this.perDayData = Array.from(
            new Set(this.perDayData.map((a) => a.roomIndex))
          ).map((roomIndex) => {
            return this.perDayData.find((a) => a.roomIndex === roomIndex);
          });
          langArr.controls[index].patchValue({
            pricesjson: JSON.stringify(this.perDayData[0]),
          });
          this.ref.detectChanges();
          if (this.applyChkOutDate == true) {
            if (b.date_arrival.endDate === null) {
              return this.helper.alert_error("Check-in date required!");
            } else if (b.date_departure.endDate === null) {
              return this.helper.alert_error("Check-out Date required!");
            } else {
              this.pricesArray = [];
              this.perDayData.map((val) => {
                val.rates.map((val2) => {
                  this.pricesArray.push({ roomRate: val2.price });
                });
              });
            }
            let pro = [];
            this.pricesArray.map((pr) => {
              pro.push(pr.roomRate.toString());
            });
            this.reservation_form.patchValue({
              prices: pro.toString(),
            });
            this.calculateprice();
          } else {
            let aNo = index + 1;
            if (
              this.reservation_form.value.booking_items[index].check_in ===
              null ||
              this.reservation_form.value.booking_items[index].check_in
                .endDate === null
            ) {
              return this.helper.alert_error(
                "Please Select Room" + " " + aNo + " " + " Check-in Date!"
              );
            } else if (
              this.reservation_form.value.booking_items[index].check_out ===
              null ||
              this.reservation_form.value.booking_items[index].check_out
                .endDate === null
            ) {
              return this.helper.alert_error(
                "Please Select Room" + " " + aNo + " " + " Check-in Date!"
              );
            } else {
              let a = true;
              let langArr = <FormArray>(
                this.reservation_form.controls["booking_items"]
              );
              this.comaArrayRate = [];
              let b = this.reservation_form.value;
              this.perDayData.map((val) => {
                if (val.roomIndex == index) {
                  val.rates.map((val2) => {
                    this.comaArrayRate.push(val2.price);
                  });
                }
              });
              langArr.controls[index].patchValue({
                prices: this.comaArrayRate.toString(),
              });
              this.pricesArray = [];
              for (
                let i = 0;
                i < this.reservation_form.value.booking_items.length;
                i++
              ) {
                this.pricesArray.push(
                  this.reservation_form.value.booking_items[i].prices
                );
              }
              this.reservation_form.patchValue({
                prices: this.pricesArray.toString(),
              });
            }
          }
          this.calculateprice();
          if (res.body.data[0].barrate != undefined) {
            var barrate = 0;
            var price = 0;
            for (let k = 0; k < res.body.data.length; k++) {
              barrate += res.body.data[k].barrate;
              price += res.body.data[k].price;
            }
            this.reservation_form.value.discount = (
              ((barrate - price) * 100) /
              price
            ).toFixed(2);
            this.reservation_form.patchValue({
              discount_package: (((barrate - price) * 100) / price).toFixed(2),
            });
            this.reservation_form.patchValue({ total: price });
          }
        },
        (err) => this.error_handling.handle_error(err.status, err.message)
      );
  }
  incrementDate(date_str) {
    var date = new Date(date_str);
    date.setDate(date.getDate() + 1);
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  // mapData_boxes(stdate, endate) {
  //   let a = {
  //     property_id: localStorage.getItem("current_property"),
  //     user_id: JSON.parse(localStorage.getItem("user")).id,
  //     start_date: stdate,
  //     end_date: endate,
  //   };

  //   this.api.post("api/inventory/calendar", a, true).subscribe(
  //     (res: any) => {
  //       this.rateData = res.body.data;
  //     },
  //     (err) => this.error_handling.handle_error(err.status, err.message)
  //   );
  // }

  recievedData() {
    if (history.state[0]) {
      if (history.state[0].guest_firstname) {
        setTimeout(() => {
          let a = document.getElementById("fliboxw");
          a.click();
          this.reservation_form.patchValue({
            guest_firstname: history.state[0].guest_firstname,
            guest_phone: history.state[0].guest_phone,
            guest_email: history.state[0].guest_email,
            guest_address: history.state[0].guest_address,
            guest_city: history.state[0].guest_city,
            personal_id_type: history.state[0].personal_id_type,
            personal_id_value: history.state[0].personal_id_value,
            privilege: history.state[0].privilege,
          });
          this.returnee = "Returnee";
        }, 1500);
      }
    } else {
      this.returnee = "New Guest";
    }
  }

  sideMenu(id1) {
    let a = <HTMLInputElement>document.getElementById(id1);
    a.style.right == "0px"
      ? (a.style.right = "-244px")
      : (a.style.right = "0px");
  }

  getPackageByDate(strD, EndD) {
    let a = {
      property_id: localStorage.getItem("current_property"),
      date_to: strD,
      date_from: EndD,
    };

    this.api.post("api/package/get_package_by_date", a, true).subscribe(
      (res: any) => {
        this.allPackages = res.body.data;

        this.allPackages2 = res.body.data;
        this.pack = res.body.data;
      },
      (err) => this.error_handling.handle_error(err.status, err.message)
    );
  }

  cnvertJsonName(data) {
    let a = JSON.parse(data.pricesjson);
    return a;
  }

  getRandomColor() {
    for (let i = 0; i <= 50; i++) {
      this.randomColors.push(
        `#${Math.floor(Math.random() * 16777215).toString(16)}`
      );
    }
    let elements;
    elements = document.getElementsByClassName("dynamoColor");
    setTimeout(() => {
      for (var x = 0; x < 100; x++) {
        elements[x].style.borderColor = this.randomColors[x];
      }
    }, 1000);
  }

  getBookingDetails(data) {
    this.api
      .get("api/booking/show/" + data.booking_id, true)
      .subscribe((res: any) => {
        this.onlyShowBooking = res.body.data;
        if (res.body.data) {
          let a = <HTMLInputElement>document.getElementById("sideBarDetailShow");
          a.style.right = "-244px";
        }
        switch (this.onlyShowBooking.guest_card_type) {
          case "Discover":
            this.guest_card_type = "Discover";
            break;
          case "AX":
            this.guest_card_type = "American Express";
            break;
          case "CB":
            this.guest_card_type = "Carte Blanche";
            break;
          case "DN":
            this.guest_card_type = "Diners Club";
            break;
          case "DS":
            this.guest_card_type = "Discover Card";
            break;
          case "JC":
            this.guest_card_type = "Japan Credit Bureau";
            break;
          case "MC":
            this.guest_card_type = "MasterCard";
            break;
          case "UP":
            this.guest_card_type = "China Union Pay";
            break;
          case "VI":
            this.guest_card_type = "Visa";
            break;
          default:
            this.guest_card_type = "Card Not Found";
            break;
        }

        this.guest_card_type = "";
        this.ref.detectChanges();
      });
  }

  chckChckbox(id, con) {
    let a = <HTMLInputElement>document.getElementById("test1" + id);
    let b = <HTMLInputElement>document.getElementById("test2" + id);
    if (con == "Paid") {
      a.checked = true;
      b.checked = false;
    } else if (con == "Complimentry") {
      b.checked = true;
      a.checked = false;
    } else {
      a.checked = false;
      b.checked = false;
    }
  }

  changeSegment(con, id) {
    let segment = <HTMLInputElement>document.getElementById(id);
    con == "true"
      ? ((segment.style.left = "2%"), (this.segmonLeft = true))
      : con == "false"
        ? ((segment.style.left = "52%"), (this.segmonLeft = false))
        : (segment.style.display = "none");
  }

  removeRoom(index) {
    let langArr = <FormArray>this.reservation_form.controls["booking_items"];
    if (
      this.reservation_form.value.date_arrival.endDate === null ||
      this.reservation_form.value.date_departure.endDate === null
    ) {
      langArr.removeAt(index);
      return;
    }
    this.perDayData.map((valk, i) => {
      if (index == valk.roomIndex) {
        this.perDayData.splice(i, 1);
      }
    });
    langArr.removeAt(index);
    if (this.applyChkOutDate == true) {
      this.pricesArray = [];
      this.perDayData.map((val) => {
        val.rates.map((val2) => {
          this.pricesArray.push({ roomRate: val2.price });
        });
      });

      let pro = [];
      this.pricesArray.map((pr) => {
        pro.push(pr.roomRate.toString());
      });
      this.reservation_form.patchValue({
        prices: pro.toString(),
      });
      this.calculateprice();
    } else {
      let aNo = index + 1;
      let a = true;
      let langArr = <FormArray>this.reservation_form.controls["booking_items"];
      this.comaArrayRate = [];
      let b = this.reservation_form.value;
      this.perDayData.map((val) => {
        if (val.roomIndex == index) {
          val.rates.map((val2) => {
            this.comaArrayRate.push(val2.price);
          });
        }
      });
      langArr.controls[index].patchValue({
        prices: this.comaArrayRate.toString(),
      });
      this.pricesArray = [];
      for (
        let i = 0;
        i < this.reservation_form.value.booking_items.length;
        i++
      ) {
        this.pricesArray.push(
          this.reservation_form.value.booking_items[i].prices
        );
      }
      this.reservation_form.patchValue({
        prices: this.pricesArray.toString(),
      });
      this.calculateprice();
    }
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.reservation_form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  permissions() {
    var data = this.user.permissions;

    for (let i = 0; i < data.length; i++) {
      if (this.user.permissions[i]["app_permission"]["name"] == "Discounts") {
        this.Discount = "true";
      }
    }
  }

  pro(selection) {
    this.onsubmit_reservation(0);
  }

  reagain() {
    this.onsubmit_reservation(0);
  }
  showerror() {
    this.toastr.error("Access Denied");
  }

  bulk_reservation(data, dataa, index) {
    // if (this.bulkreservation.length > 0) {
    //   var last = this.bulkreservation.length - 1;
    //   if (index - this.index != 1) {
    //     alert("Space Found");
    //     return;
    //   }
    // }

    (<HTMLInputElement>document.getElementById(index)).style.backgroundColor =
      "#0080a7";
    this.api
      .post(
        "api/booking/roomscode",
        { room_id: dataa.room_id, room_no_id: data },
        true
      )
      .subscribe(
        (res: any) => {
          if (this.bulkreservation.length != 0) {
            for (let i = 0; i < this.bulkreservation.length; i++) {
              if (data == this.bulkreservation[i].room_no_id) {
                var last = this.bulkreservation.length - 1;
                let a = this.bulkreservation[0].date_departure_bulk.split("-");
                let b = dataa.date.split("-");
                var end = moment(a[0] + "/" + a[1] + "/" + a[2]);
                var start = moment(b[0] + "/" + b[1] + "/" + b[2]);
                var endd = a[0] + "-" + a[1] + "-" + a[2];
                var startt = b[0] + "-" + b[1] + "-" + b[2];
                var date = { startDate: end, endDate: start };
                this.date_departure_bulk = { startDate: start, endDate: end };
                this.date_arrival_bulk = { startDate: end, endDate: start };
                let dif = start.diff(end, "days");
                this.totalnights_bulk = dif;
                this.index = index;
                this.room_index = data;
                this.check_rate(endd, startt, dataa.room_id, 0);
                this.bulk_price.push(this.prices_bulk_Array);
                return;
              }
            }
          }
          this.index = index;
          this.room_index = data;
          var obj = {
            room_name: res.body.data.room.roomtype.name,
            room_number: res.body.data.roomnumber.room_number,
            smoking: res.body.data.room.smoking,
            pets: res.body.data.room.pets,
            wheelchairaccessibility: res.body.data.room.wheelchairaccessibility,
            date_departure_bulk: dataa.date,
            date_arrival_bulk: dataa.date,
            room_no_id: res.body.data.roomnumber.id,
          };
          this.bulkreservation.push(obj);
          var last = this.bulkreservation.length - 1;
          let a = this.bulkreservation[0].date_departure_bulk.split("-");
          let b = this.bulkreservation[last].date_arrival_bulk.split("-");
          var end = moment(a[0] + "/" + a[1] + "/" + a[2]);
          var start = moment(b[0] + "/" + b[1] + "/" + b[2]);
          // var endd = moment(a[1] + "-" + a[0] + "-" + a[2]);
          // var startt = moment(b[1] + "-" + b[0] + "-" + b[2]);
          var date = { startDate: end, endDate: start };
          this.date_departure_bulk = { startDate: start, endDate: end };
          this.date_arrival_bulk = { startDate: end, endDate: start };
          let dif = start.diff(end, "days");
          this.totalnights_bulk = dif;
          let pacakge_data = {
            property_id: localStorage.getItem("current_property"),
            date_to: this.bulkreservation[0].date_departure_bulk,
            date_from: this.bulkreservation[last].date_departure_bulk,
          };

          this.check_rate(
            this.bulkreservation[0].date_departure_bulk,
            this.bulkreservation[last].date_departure_bulk,
            dataa.room_id,
            0
          );
          this.bulk_price.push(this.prices_bulk_Array);

          this.api
            .post("api/package/get_package_by_date", pacakge_data, true)
            .subscribe(
              (res: any) => { },
              (err) => this.error_handling.handle_error(err.status, err.message)
            );

          this.chkPackage(res.body.data);
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }

  get m() {
    return this.reservation_bulk.controls;
  }

  onSubmit() {
    this.reservation_bulk.value.status == "hold"
      ? (this.bookingStatus = false)
      : (this.bookingStatus = true);
    this.submitted = true;
    if (this.applyChkOutDate) {
      for (
        let i = 0;
        i < this.reservation_bulk.value.booking_items_bulk.length;
        i++
      ) {
        this.reservation_bulk.value.booking_items_bulk[i].check_in =
          this.reservation_bulk.value.date_arrival_bulk;
        this.reservation_bulk.value.booking_items_bulk[i].check_out =
          this.reservation_bulk.value.date_departure_bulk;
      }
      // this.prices_bulk_Array = [];
      this.comaArrayRate = [];
    } else {
      for (
        let i = 0;
        i < this.reservation_bulk.value.booking_items_bulk.length;
        i++
      ) {
        if (
          this.reservation_bulk.value.booking_items_bulk[i].check_in.endDate
        ) {
          this.reservation_bulk.value.booking_items_bulk[i].check_in = moment(
            this.reservation_bulk.value.booking_items_bulk[i].check_in.endDate
          ).format("YYYY-MM-DD");
        }
        if (
          this.reservation_bulk.value.booking_items_bulk[i].check_out.endDate
        ) {
          this.reservation_bulk.value.booking_items_bulk[i].check_out = moment(
            this.reservation_bulk.value.booking_items_bulk[i].check_out.endDate
          ).format("YYYY-MM-DD");
        }
      }
    }

    if (this.reservation_bulk.value.date_arrival_bulk.endDate) {
      this.reservation_bulk.patchValue({
        date_arrival: moment(
          this.reservation_bulk.value.date_arrival_bulk.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    if (this.reservation_bulk.value.date_departure_bulk.endDate) {
      this.reservation_bulk.patchValue({
        date_departure_bulk: moment(
          this.reservation_bulk.value.date_departure_bulk.endDate._d
        ).format("YYYY-MM-DD"),
      });
    }
    this.reservation_bulk.patchValue({
      same_date: this.applyChkOutDate,
    });

    this.reservation_bulk.patchValue({ TaxInclusive: this.taxInclusivee });

    if (this.reservation_bulk.value.payment_status == "unpaid") {
      this.reservation_bulk.patchValue({ payment_status: "paynow" });
    }
    //   if (this.reservation_bulk.value.booking_items_bulk == []) {
    //   this.reservation_bulk.patchValue({ payment_status: "paynow" });
    // }
    this.reservation_bulk.value.booking_items_bulk = this.bulkreservation;
    if (this.reservation_form.invalid) {
      this.findInvalidControls();
      return this.helper.alert_error("Please fill all required fields");
    }

    this.api
      .post("api/booking/bulk_booking", this.reservation_bulk.value, true)
      .subscribe(
        (res: any) => {
          this.resetForm(this.reservation_bulk);
          $("#bulk_update").modal("hide");
          this.get_inventory();
          this.helper.alert_success("Reservation made successfully.");
        },
        (err) => this.error_handling.handle_error(err.status)
      );
  }
  check_rate(start_date, end_date, room_id, pId) {
    this.xomio = {
      start_date: start_date,
      end_date: end_date,
      property_id: localStorage.getItem("current_property"),
      room_id: room_id,
      package_id: pId,
    };

    this.api
      .post(
        "api/booking/get_rates_by_checkin_checkout",
        this.applyChkOutDate ? this.xom : this.xomio,
        true
      )
      .subscribe((res: any) => {
        this.prices_bulk_Array = res.body.data;

        // return res.body.data;
        (err) => this.error_handling.handle_error(err.status);
      });
  }

  currency() {
    this.current_currency = localStorage.getItem("current_currency");
  }

  check_adults($event) {
    if ($event == null) {
      return;
    }
    this.current_room["adults"] > $event
      ? (this.action = "none")
      : (this.action = "flex");
    document.getElementById("extraPerson").style.display = this.action;
  }
  reagain_date(st, en) {
    let current_date = moment().subtract(0, "days");
    let end_date: any = moment(st.endDate);
    let difff = end_date.diff(current_date, "days");
    let difrence = ++difff;

    this.minDate_dep = dayjs().subtract(-difrence, "days");
    if (en == undefined || st == undefined) {
      return;
    }

    let start: any = moment(st.endDate);
    let end: any = moment(en.endDate);
    if (start != undefined && end != undefined && this.room_id != undefined) {
      this.getnigths(st, en);

      this.onsubmit_reservation(this.indexing);
      // this.onsubmit_reservation(1)
    }
  }
  sub_total(a: any, b: any) {
    let c = b == null ? 0 : b;
    var strArr = a.split(",");
    var sum = strArr.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
    return parseInt(sum) + parseInt(c);
    //  return sum;
  }
  tax_per(a: any, b: any, tax: any) {
    let c = b == null ? 0 : b;
    var strArr = a.split(",");
    var sum = strArr.reduce((acc, cur) => parseInt(acc) + parseInt(cur), 0);
    var texes: any = `0.${tax}`;
    var sumes = parseInt(sum) + parseInt(c);
    var final = sumes * texes;
    return final.toFixed(2);

    //  return sum;
  }
  // chnage_status(a) {
  //   this.b_status = [
  //     { name: "Paid" },
  //     { name: "Due" },
  //     { name: "Deposite" },
  //   ];

  //   // let paid = [{name:"Confirm"}];
  //   // let unpaid = [{name:"Hold"}];
  //   // let Deposite = [{name:"Deposit"}];

  //   // if(a == 'paid'){
  //   //   this.b_status = paid
  //   // }
  //   // if(a == 'unpaid'){
  //   //   this.b_status = unpaid
  //   // }
  //   // if(a == 'Deposite'){
  //   //   this.b_status = paid
  //   // }
  // }

  cash_deposit() {
    // let current_date = this.SD;
    // let end_date: any = moment(this.CD);
    // let difff = end_date.diff(current_date, "days");
    // this.minDate = moment().subtract(difff, "days");
    // this.minDate_dep = moment().subtract(difff, "days");
  }

  guestInfo(e) {
    if (e.target.checked) {
      if (this.reservation_form.value.booking_items[0].guest_name != null) {
        this.reservation_form.patchValue({
          guest_firstname:
            this.reservation_form.value.booking_items[0].guest_name,
        });
      }
    } else {
      this.reservation_form.patchValue({
        guest_firstname: "",
      });
    }

    this.whenBothChecksTrue();
  }

  updateBookedByInfo(e) {
    let val = (<FormArray>this.reservation_form.controls.booking_items).at(
      0
    ) as FormGroup;
    if (e.target.checked) {
      if (this.reservation_form.value.guest_firstname != null) {
        val.patchValue({
          guest_name: this.reservation_form.value.guest_firstname,
        });
      }
    } else {
      val.patchValue({
        guest_name: "",
      });
    }

    this.whenBothChecksTrue();
  }

  whenBothChecksTrue() {
    var chk1 = <HTMLInputElement>document.getElementById("checkedForSep");
    var chk2 = <HTMLInputElement>(
      document.getElementById("updateBookedByInfo-checkbox")
    );

    if (
      chk1.checked &&
      chk2.checked &&
      this.reservation_form.value.guest_firstname != null
    ) {
      let items = (<FormArray>(
        this.reservation_form.controls.booking_items
      )) as FormArray;
      items.controls.forEach((element) => {
        (element as FormGroup).patchValue({
          guest_name: this.reservation_form.value.guest_firstname,
        });
      });
    }
  }

  addguest(i: number) {
    this.getExtraPersons(i).push(this.newExtraPerson());
  }

  getExtraPersons(bookingItemIndex) {
    return (this.reservation_form.get("booking_items") as FormArray)
      .at(bookingItemIndex)
      .get("extra_persons") as FormArray;
  }

  removeGuest(bookingItemIndex: number, extraPersonIndex) {
    this.getExtraPersons(bookingItemIndex).removeAt(extraPersonIndex);
  }

  newExtraPerson(): FormGroup {
    return this.fb.group({
      name: " ",
      id_type: " ",
      id_number: " ",
    });
  }
  // negative(){
  //   // alert("negative")

  //   let number = document.getElementById('number');

  //   // Listen for input event on numInput.
  //   number.onkeydown = function(e) {
  //   if(!((e.keyCode > 95 && e.keyCode < 106)
  //     || (e.keyCode > 47 && e.keyCode < 58)
  //     || e.keyCode === 8)) {
  //       return false;
  //     }
  //   }
  // }

  updateRoomCardLabel(e, i) {
    this.roomNamesInreservationForm[i] = e.name;
  }

  countDaysAndNights(data) {
    let date_arrival = moment(data.date_arrival);
    let date_departure = moment(data.date_departure);

    let dif =
      data.date_departure &&
      data.date_departure != null &&
      date_departure.diff(date_arrival, "days");
    let totalDifference =
      dif == 1
        ? dif + " Night"
        : dif > 1
          ? dif + " Nights"
          : dif == 0
            ? 1 + " Day"
            : 0;
    return totalDifference;
  }

  checkPaymentStatus(event: any) {
    if (event === "unpaid") {
      this.b_status = [{ name: "Hold", value: "hold" }];
    } else {
      this.b_status = [
        { name: "Confirmed", value: "confirm" },
        { name: "Hold", value: "hold" },
      ];
    }
  }

  getExpenses() {
    this.api
      .get(`api/property/get_extras/${localStorage.getItem("current_property")}`, true)
      .subscribe(
        (res: any) => {
          this.addOnsExtras = res.body.data
            .filter((addOn: AddOnsExtras) => addOn.type === "service" && addOn.name !== "")
            .map((addOn: AddOnsExtras) => ({ ...addOn, count: 0 }));

          this.addOnsDisplayMap.set(0, this.addOnsExtras)

        },
        (err) => this.error_handling.handle_error(err)
      );
  }


  openAddOnsModal(index: number) {
    this.addOnsModal[index] = !this.addOnsModal[index];
    this.addOnsDisplayMap.set(index, this.addOnsExtras)
  }

  // ^ update addons count by room index
  updateCount(addOnExtras: AddOnsExtras, change: number, roomIndex: number) {
    const bookingItemsArray = this.reservation_form.get('booking_items') as FormArray;
    const extrasArray = bookingItemsArray.at(roomIndex).get('booking_extras') as FormArray;

    const index = extrasArray.controls.findIndex(
      (control) => control.value.extras_item_id === addOnExtras.id
    );

    let count = this.getBookingExtraQuantity(addOnExtras, roomIndex) as number;
    count += change;

    if (count <= 0 && index !== -1) {
      count = 0;
      extrasArray.removeAt(index);
    } else if (count > 0) {
      if (index !== -1) {
        extrasArray.controls[index].get('item_count').setValue(count);
      } else {
        extrasArray.push(this.createExtrasGroup(addOnExtras, count));
      }
    }

    // this.calculateAddonsPriceByRoomIndex(roomIndex)
    this.calculateprice();
    console.log(this.reservation_form.value, 'reservation_form.value');
  }


  getBookingExtraQuantity(bookingExtra: AddOnsExtras, roomIndex: number) {
    const bookingItemsArray = this.reservation_form.get('booking_items') as FormArray;
    const extrasArray = bookingItemsArray.at(roomIndex).get('booking_extras') as FormArray;
    let count = 0;
    extrasArray.controls.forEach((v, i) => {
      if (v.get("extras_item_id").value == bookingExtra.id) {
        count = v.get("item_count").value;
      }
    })
    return count;
  }

  // ^ create extras group
  createExtrasGroup(addOn: AddOnsExtras, count: number): FormGroup {
    return this.fb.group({
      property_id: parseInt(this.current_property),
      extras_item_id: addOn.id,
      item_count: count,
    });
  }

  addOnsExtrasDisplay(extras: any[]) {
    const filteredAddOnsExtras = this.addOnsExtras.filter(extra => {
      const matchingAddon = extras.find(addon => addon.property_extras_item_id === extra.id);
      if (matchingAddon) {
        extra.count = matchingAddon.item_count;
        console.log(matchingAddon, extra, "matcccc")
      }
      return matchingAddon !== undefined;
    });

    return filteredAddOnsExtras;
  }

  calculateAddonsPriceByRoom(extras: any[]) {
    const addons = this.addOnsExtrasDisplay(extras);
    const totalAddonsPrice = addons.reduce((accumulator, currentValue) => {
      const item_count = extras.find(extra => extra.property_extras_item_id === currentValue.id).item_count || 0;
      console.log(item_count, "item_count")
      return accumulator + (parseInt(currentValue.price_after_tax) * item_count);
    }, 0);
    console.log(totalAddonsPrice, "totalAddonsPrice")
    return totalAddonsPrice;
  }

  // ^ calculate addons extras price
  calculateAddOnsPrice(): number {
    const bookingItemsArray = this.reservation_form.get("booking_items") as FormArray;

    const totalExtrasPrice = bookingItemsArray.controls.reduce((total, bookingItem, index) => {
      const extrasArray = (bookingItem as FormGroup).get("booking_extras") as FormArray;
      let addOnsToDisplay = this.addOnsDisplayMap.get(index);

      const bookingItemPrice = extrasArray.controls.reduce((subtotal, control) => {
        const extrasItemId = control.value.extras_item_id;

        if (addOnsToDisplay) {
          const matchingAddon = addOnsToDisplay.find(addon => addon.id === extrasItemId);

          if (matchingAddon) {
            const addonCount = this.getBookingExtraQuantity(matchingAddon, index) as number;
            return subtotal + (parseInt(matchingAddon.price_after_tax) * addonCount);
          } else {
            return subtotal;
          }
        }
      }, 0);

      return total + bookingItemPrice;
    }, 0);

    return totalExtrasPrice;
  }

  // ^ calculate addons price by room index
  calculateAddonsPriceByRoomIndex(roomIndex: number): number {
    const bookingItemsArray = this.reservation_form.get("booking_items") as FormArray;
    let totalExtrasPrice = 0;
    const taxper = parseFloat(JSON.parse(localStorage.getItem("property")).taxper);
    const taxInclusive = JSON.parse(localStorage.getItem("property")).taxInclusive;

    let addOnsToDisplay = this.addOnsDisplayMap.get(roomIndex);

    if (roomIndex >= 0 && roomIndex < bookingItemsArray.length) {
      const extrasArray = (bookingItemsArray.at(roomIndex) as FormGroup).get("booking_extras") as FormArray;

      if (extrasArray && extrasArray.length > 0) {
        extrasArray.controls.forEach(control => {
          const extrasItemId = control.value.extras_item_id;
          const matchingAddon = addOnsToDisplay.find(addon => addon.id === extrasItemId);

          if (matchingAddon) {
            const addonPrice = parseFloat(matchingAddon.price_after_tax);
            const addonCount = this.getBookingExtraQuantity(matchingAddon, roomIndex) as number;
            totalExtrasPrice += taxInclusive
              ? addonPrice * addonCount
              : (addonPrice + (addonPrice * taxper / 100)) * addonCount;
          }
        });
      }
    }

    return totalExtrasPrice;
  }

  // * calculate all addons price 
  calculateAllAddOnsPrice(): number {
    return this.onlyShowBooking.bookingitems
      .map(bookingItem => bookingItem.booking_extras)
      .reduce((totalPrice, bookingExtras) => {
        if (bookingExtras && bookingExtras.length > 0) {
          bookingExtras.forEach(extraItem => {
            const extrasItemId = extraItem.property_extras_item_id;
            const matchingAddon = this.addOnsExtras.find(addon => addon.id === extrasItemId);
            if (matchingAddon) {
              const addonPrice = parseInt(matchingAddon.price_after_tax);
              const addonTotalPrice = addonPrice * extraItem.item_count;
              totalPrice += addonTotalPrice;
            }
          });
        }
        return totalPrice;
      }, 0);
  }

  getEmptyElements(count: number) {
    return new Array(count).map(x => '');
  }

  changeRoomNumber(event, index: number) {
    this.roomNoInreservationForm[index] = event.room_number;
    console.log(event, "change room no");
  }

  changeGuestName(event: any, index: number) {
    this.guestNamesInreservationForm[index] = event.target.value;
  }

  viewBtn() { }
  open_restriction_box2(box, date, ven) { }
}
