<div [ngClass]="darkMode ? 'card_dark' : 'null'">
  <div class="flex text space-between parent-bar">
    <div>Backoffice</div>
    <a>
      <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck7" name="checkbox"
        title="Select all" style="cursor: pointer;">
      <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button7"
        style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-backoffice" role="button"
        aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button7')">
    </a>
  </div>
  <div class="collapse" id="collapse-backoffice" [ngClass]="darkMode ? 'card_dark' : 'null'">
    <!-----1stColumnchildCollapse-->
    <div class="col-md-6 pr-0 pl-0">
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb1" style="margin:0px">
          <div>My Account</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>
      </div>

      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb2" style="margin:0px">
          <div>Logs</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>

      </div>

      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb3" style="margin:0px">
          <div>Marketing</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>
      </div>
      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb17" style="margin:0px">
          <div>Communication</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>
      </div>
      <!-----SubChildCollapse.1-->

      <div class="flex text space-between child-bar cb4">
        <div>Hotel Setup</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button28"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-setup" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button28')">
        </a>

      </div>

      <div class="collapse" id="collapse-setup">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hs-1">
            <label>Create Users</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hs-2">
            <label>Manage Users</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hs-3">
            <label>Create Rooms</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
      </div>
      <!------------------------>
      <!-----SubChildCollapse.2-->

      <div class="flex text space-between child-bar cb5">
        <div>Manage Rooms</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button29"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-manage" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button29')">
        </a>
      </div>

      <div class="collapse" id="collapse-manage">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r1">
            <label>Create New Room</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r2">
            <label>View Amenities</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r3">
            <label>View Channels</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r4">
            <label>View Room</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r5">
            <label>Edit</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r6">
            <label>Delete</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-r7">
            <label>Push</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
      </div>
      <!------------------------>
      <!-----SubChildCollapse.3-->

      <div class="flex text space-between child-bar cb6">
        <div>Manage Hotels</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button30"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-managehotels" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button30')">
        </a>
      </div>

      <div class="collapse" id="collapse-managehotels">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-h1">
            <label>View Amenities</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-h2">
            <label>Connectivity Channels</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-h3">
            <label>Connectivity OTA's</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-h4">
            <label>Edit</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty m-h5">
            <label>Push</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
      </div>
      <!------------------------>
      <!-----SubChildCollapse.4-->

      <div class="flex text space-between child-bar cb7">
        <div>Room Service</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button19"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-service" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button19')">
        </a>
      </div>

      <div class="collapse" id="collapse-service">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-1">
            <label>Cafe</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-2">
            <label>Room Service</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-3">
            <label>Restaurant</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-4">
            <label>Event</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-5">
            <label>Cigar Lounge</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty rm-6">
            <label>Order List</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
      </div>
      <!------------------------>
      <!-----SubChildCollapse.5-->

      <div class="flex text space-between child-bar cb8">
        <div>Payroll Calculator</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button20"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-calculator" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button20')">
        </a>
      </div>

      <div class="collapse" id="collapse-calculator">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty cal-1">
            <label>Employee Account</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty cal-2">
            <label>Govt Deductions</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty cal-3">
            <label>Voluntary Deductions</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty cal-4">
            <label>Tutions & Training Cost</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty cal-5">
            <label>Employee List</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
      </div>
      <!------------------------>

    </div>
    <!----- 2ndColumnchildCollapse-->
    <div class="col-md-6 pr-0 pl-2">

      <div class="col-md-12 pr-0 pl-0 pb-2 ">
        <div class="flex text space-between child-bar cb9" style="margin:0px">
          <div>Billing</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>
        <!-- <div class="border-style  first-name-shade lab-sty b1">
                      <label>Billing</label>

                       <input type="checkbox" class="form-check-input mr-3"  value="" id="defaultCheck1" name="checkbox"style="margin:0px;">
                    </div> -->
      </div>

      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb10" style="margin:0px">
          <div>Select Hotel</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor: pointer;">
          </a>
        </div>
        <!-- <div class="border-style  first-name-shade lab-sty sel-h">
                      <label>Select Hotel</label>

                       <input type="checkbox" class="form-check-input mr-3"  value="" id="defaultCheck1" name="checkbox"style="margin:0px;">
                    </div> -->
      </div>

      <div class="col-md-12 pr-0 pl-0 pb-2">
        <div class="flex text space-between child-bar cb11" style="margin:0px">
          <div>Vendor & Supplier</div>
          <a>
            <input type="checkbox" class="form-check-input mr-0" value="" id="defaultCheck3" name="checkbox"
              style="cursor:pointer;">
          </a>
        </div>
      </div>

      <!-----SubChildCollapse.1 of 2ndchild----->

      <div class="flex text space-between child-bar cb12">
        <div>BP & TA</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor:pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button31"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-bp" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button31')">
        </a>
      </div>

      <div class="collapse" id="collapse-bp">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty bp-1">

            <label>Create Account</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor:pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty bp-2">

            <label>Group Reservation</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor:pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty bp-3">

            <label>Buy Now</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor:pointer;">
          </div>
        </div>

      </div>
      <!------------------------>
      <!-----SubChildCollapse.2 of 2ndchild----->

      <div class="flex text space-between child-bar cb13">
        <div>Hotel Users</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor:pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button32"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-users" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button32')">
        </a>
      </div>

      <div class="collapse" id="collapse-users">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hu-1">
            <label>Permission</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px; cursor:pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hu-2">
            <label> Pending</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor:pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hu-3">
            <label>Edit Permission</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor:pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty hu-4">
            <label>Delete</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

      </div>
      <!------------------------>
      <!-----SubChildCollapse.3 of 2ndchild----->

      <div class="flex text space-between child-bar cb14">
        <div>Contact Support</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button33"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-contact" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button33')">
        </a>
      </div>

      <div class="collapse" id="collapse-contact">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty con-1">
            <label>Email</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty con-2">
            <label>Submit</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

      </div>
      <!------------------------>
      <!-----SubChildCollapse.4 of 2ndchild------>

      <div class="flex text space-between child-bar cb15">
        <div>Financials</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button24"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-finance" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button24')">
        </a>
      </div>

      <div class="collapse" id="collapse-finance">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty f1">
            <label>Revenue</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty f2">
            <label>Cost Price</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty f3">
            <label>Selling Price</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty f4">
            <label>Expenses</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty f5">
            <label>Financials</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

      </div>
      <!------------------------>
      <!-----SubChildCollapse.5 of 2ndchild------>

      <div class="flex text space-between child-bar cb16">
        <div>Food & Beverages</div>
        <a>
          <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
            title="Select all" style="cursor: pointer;">
          <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button25"
            style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-fnb" role="button"
            aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button25')">
        </a>
      </div>

      <div class="collapse" id="collapse-fnb">

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-1">
            <label>Create Shop</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-2">
            <label>Setup Shop</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-3">
            <label>Create Menu</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-4">
            <label>Create Users</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-5">
            <label>Manage Menu</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty fnb-6">
            <label>Manage Shop</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>

      </div>
      <!------------------------>
    </div>
    <!-------collapse-mob----------------->
    <div class="col-md-12 flex text space-between child-bar cb18">
      <div>Mobile App</div>
      <a>
        <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck3" name="checkbox"
          title="Select all" style="cursor: pointer;">
        <img src="assets/img/reports/button_down_dark.svg" class="mR10" id="button26"
          style="margin-top: -5px;height: 28px;" data-toggle="collapse" href="#collapse-mob" role="button"
          aria-expanded="false" aria-controls="collapse-panel" (click)="changeclass('button26')">
      </a>
    </div>

    <div class="collapse" id="collapse-mob">

      <!------1st column------>
      <div class="col-md-6 pr-0 pl-0">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-1">
            <label>Create Reservation</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-2">
            <label>Guest Lookup</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-3">
            <label>My Account</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-4">
            <label> Hotel Setup</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-5">
            <label>Manage Rooms</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-6">
            <label> Financials</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-7">
            <label>Payroll Calculator</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-8">
            <label>Marketing</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-9">
            <label>Hotel Users</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-10">
            <label>Contact Support</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-11">
            <label>BP & TA</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>
        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-12">
            <label>Communication</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>

      </div>
      <!------2nd column------>
      <div class="col-md-6 pr-0 pl-2">
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-13">
            <label>View Reservation</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-14">
            <label>Send Promotion</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-15">
            <label>Billing</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-16">
            <label>Select Hotel</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-17">
            <label>Performance</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-18">
            <label>Manage Hotels</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-19">
            <label>Logs</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-20">
            <label>Vendor & Supplier</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-21">
            <label>Room Service</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-22">
            <label>Payroll Calculator</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <div class="col-md-12 pr-0 pl-0 pb-2">
          <div class="border-style  first-name-shade lab-sty mob-23">
            <label>Food & Bevegares</label>

            <input type="checkbox" class="form-check-input mr-3" value="" id="defaultCheck1" name="checkbox"
              style="margin:0px;cursor: pointer;">
          </div>

        </div>
        <!-- <div class="col-md-12 pr-0 pl-0 pb-2">
                      <div class="border-style  first-name-shade lab-sty mob-24">
                        <label>Schedule</label>

                        <input type="checkbox" class="form-check-input mr-3"  value="" id="defaultCheck1" name="checkbox"style="margin:0px;">
                      </div>

                  </div> -->


      </div>

    </div>
    <!-------------------->

  </div>
</div>