<div id="Csss">
</div>
<div class="alert alert-danger breadcrum" [ngClass]="darkMode ? 'card-dark-top-nav' : ' card-null '">
  <a href="" class="btn btn-link" data-dismiss="modal" aria-label="Close">RATES & PACKAGES</a>
  <a href="" class="btn btn-link active" routerLink="link_to_ota">SELECT OTA</a>

  <div class="col-md-1 select-all">
    <label class="control-label checkbox-style" style="margin-left: 6px">
      <input type="checkbox" class="checkbox" id="allS" (click)="selectAll($event)">
      <span class="checkbox-checkmark"></span>
      <span class="permission-title">Select All</span>
    </label>
  </div>

</div>
<div class="no_scroll scroll">
  <form [formGroup]="create_package_form" class="form-horizontal back-form pr">
    <div class="white_box channels w-clearfix padding-adj" id="SS"
      [ngClass]="darkMode ? 'card-dark-side' : ' card-null '">

      <div class="col-md-12" style="padding-right: 5px;padding-top: 0px; padding-bottom: 0px;padding-left: 0px;">
        <div *ngIf="pricing_modell =='PerDayPricing' || pricing_modell == 'Both' ">
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="price-heading">
                  Per Day Pricing
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let channel of channels">
            <div class="col-md-4 csw" style="padding-right: 0px" *ngIf="channel?.pricing_model == 'PerDayPricing'">
              <div class="channel_container {{ channel.name }} w-clearfix" style="display: flex;"
                [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                <div class="form_container">
                  <div class="form-block w-clearfix">
                    <div class="form">
                      <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                        <input type="checkbox" id="check-{{ channel.id }}" value="{{ channel.id }}" name="channel"
                          data-name="Channel Icon" class="channels_checkbox w-checkbox-input checkbox chkbx selectAll"
                          [checked]="channel?.is_checked">
                      </label>
                    </div>
                    <div class="w-form-done"></div>
                    <div class="w-form-fail"></div>
                  </div>
                </div>
                <img
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(channel.image) : imgr(channel.image) }}.svg"
                  alt="" class="tripadvisor ota-icon {{ channel.name }}">
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="pricing_modell =='occupancy' || pricing_modell == 'Both' ">
          <div class="row">
            <div class="col-md-12">
              <div class="col-md-12">
                <div class="price-heading">
                  Occupancy Base Pricing
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let channel of channels">
            <div class="col-md-4 csw" style="padding-right: 0px" *ngIf="channel?.pricing_model != 'PerDayPricing'">
              <div class="channel_container {{ channel.name }} w-clearfix" style="display: flex;"
                [ngClass]="darkMode ? 'card-dark-side-end-c' : ' card-null '">
                <div class="form_container">
                  <div class="form-block w-clearfix">
                    <div class="form">
                      <label class="" [ngClass]="darkMode ? 'box':'box_light'">
                        <input type="checkbox" id="check-{{ channel.id }}" value="{{ channel.id }}" name="channel"
                          data-name="Channel Icon" class="channels_checkbox w-checkbox-input checkbox chkbx selectAll"
                          [checked]="channel?.is_checked">
                      </label>
                    </div>
                    <div class="w-form-done"></div>
                    <div class="w-form-fail"></div>
                  </div>
                </div>
                <img
                  src="assets/img/svgicons/otas/{{darkMode ? 'ota_dark/' + imgr(channel.image) : imgr(channel.image) }}.svg"
                  alt="" class="tripadvisor ota-icon {{ channel.name }}">
              </div>
            </div>
          </ng-container>
        </div>


      </div>
    </div>

    <div class="modal-footer" id="ModalFooter" style="right: 10px!important;bottom: -50px;">
      <a class="btn btn-primary btn-lg" type="button" (click)="onSubmit()"
        [ngClass]="darkMode ? 'card-dark-btn-g hovoo' : ' card-null '">Save</a>
      <a class="btn btn-danger btn-lg" type="button" data-dismiss="modal" aria-label="Close" (click)="onClose()"
        [ngClass]="darkMode ? 'card-dark-top-nav hovoo' : ' card-null '">Cancel</a>
    </div>
  </form>
</div>