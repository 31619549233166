import { Component, OnInit, ChangeDetectorRef, HostBinding } from '@angular/core';
import { ConfirmationAlertService } from "../../../common/confirmation-alert/confirmation-alert.service";
import { ApiService } from "../../../services/api.service";
import { ErrorHandlingService } from "../../../services/error-handling.service";
import { FormBuilder, FormGroup, Validators, ValidationErrors } from "@angular/forms";
import { HelperService } from "../../../services/helper.service";
import { Router } from '@angular/router';
declare var $: any

@Component({
  selector: 'app-email-directory',
  templateUrl: './email-directory.component.html',
  styleUrls: ['./email-directory.component.css']
})
export class EmailDirectoryComponent implements OnInit {
  darkMode: boolean = false;
  @HostBinding('class') colorClass = `${JSON.parse(localStorage.getItem('user')).mode}Mode`;
  constructor(
    private confirm: ConfirmationAlertService,
    private error_handling: ErrorHandlingService,
    private api: ApiService,
    private fb: FormBuilder,
    private helper: HelperService,
    private _router: Router,
    private ref: ChangeDetectorRef,
  ) { }

  guest_templates;
  showhide;
  colorArr = ['#2DABD2', '#FFAA00', '#059800', '#CF0101', '#000000', '#CD89FB', '#8202AF', '#0055FF', '#72FF7C']
  g_templates = [];
  g_directory = [];
  sent_items: any;

  sConfig = {
    placeholder: '',
    tabsize: 2,
    height: 380,
    codemirror: {
      theme: 'cosmo'
    },
    theme: 'cosmo',
    //uploadImagePath: '/api/upload',
    toolbar: [
      ['style', ['bold', 'italic', 'underline']],
      ['fontsize', ['fontname', 'fontsize']],
      ['para', ['paragraph']],
      ['insert', ['picture']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  param;
  query;
  async ngOnInit() {
    this.ref.detectChanges();
    await this.checkDarkMode();
    await this.getGroups();
    $(document).ready(function () {
      $('#plus-search').css("visibility", "hidden");
      $('.plus-img').click(function () {
        $('#plus-search').css({ "visibility": "visible" })
      });
      $('#p-1').click(function () {
        $('#plus-search').css("visibility", "hidden")
      })

      $('#summernote').summernote({
        styleTags: [
          'div',
          { title: 'Blockquote', tag: 'blockquote', className: 'blockquote', value: 'blockquote' }
        ],
      });

    });


    $("#myModal-2").draggable();
    $("#myModal-2").draggable('disable');

    $(function () {
      $(".modal").mouseup(function () {
        $(".dargmd").css({ "cursor": "grab" })
      });
      $(".modal").mousedown(function () {
        $(".dargmd").css({ "cursor": "grabbing" })
      });
    });

  }


  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem('user')).mode;
    if (mode == 'dark') {
      this.darkMode = true;
    }
  }

  dodrag(id) {
    $("#" + id).draggable('enable');
  }

  dontdrag(id) {
    $("#" + id).draggable('disable');
  }

  getGroups() {
    this.api.post('api/email_template/get_send_logs', { property_id: localStorage.getItem('current_property') }, true).subscribe((res: any) => {
      this.g_templates = res.body.data.data;
      console.log(this.g_templates)
    })
  }

  getLogsTem(data) {
    if (data.logs.length > 1) {
      return 'Group'
    }
    else {
      return data.logs[0].receiver_email
    }
  }

}

