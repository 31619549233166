import { Component, OnInit } from '@angular/core';
import { InventoryCalendarService } from 'src/app/services/inventory-calendar.service';
import { CommonModule } from '@angular/common';
import { JsonPipe } from '@angular/common'
import { RoomInfo } from 'src/app/common/Inventory/TotalRoomsInSeasonRespose';
import { DateRoomId } from 'src/app/common/Inventory/DateRoomTypePair';
import * as moment from 'moment';
import { ReservationService } from 'src/app/services/reservation.service';
import { CalendarCellModel } from 'src/app/common/Inventory/CalendarCellModel';
import { BulkReservationSelectionRange } from 'src/app/common/Inventory/Reservation/BulkReservationSelectionRange';

@Component({
  selector: 'app-new-inventory',
  templateUrl: './new-inventory.component.html',
  styleUrls: ['./new-inventory.component.css']
})
export class NewInventoryComponent implements OnInit {
  darkMode: boolean = false;
  public calendarWeekDateRange: { startDate: moment.Moment, endDate: moment.Moment } = { startDate: moment(), endDate: moment().add(7, 'days') };

  private roomBookings$ = this.inventoryService.roomBookings$
  private totalRoomsInSeason$ = this.inventoryService.totalRoomsInSeason$

  roomsSet: RoomInfo[] = []
  roomAvailablilityMapping: Map<DateRoomId, number> = new Map()

  calendarRange = []
  calendarDateRange = this.inventoryService.calendarDateRange$

  startDate: moment.Moment = moment();
  endDate: moment.Moment = moment().add(7, 'days');

  constructor(
    private inventoryService: InventoryCalendarService,
    private reservationService: ReservationService
  ) {

  }


  ngOnInit() {
    this.checkDarkMode()
    this.calendarDateRange.subscribe({
      next: (data) => {
        console.log(data, "date data");

        if (data === undefined) return;
        this.calendarRange = []
        let startDate = data.startDate
        let endDate = data.endDate
        while (startDate.isSameOrBefore(endDate)) {
          this.calendarRange.push(startDate)
          startDate = startDate.clone().add(1, 'days')
        }
        this.getData()
      }
    })
  }

  checkDarkMode() {
    let mode = JSON.parse(localStorage.getItem("user")).mode;
    if (mode == "dark") {
      this.darkMode = true;
    }
  }

  getData() {
    let v = this.totalRoomsInSeason$.subscribe({
      next: (data) => {
        data.seasons.forEach((season, i) => {
          season.room_info.forEach((roomInfo) => {
            this.roomsSet.push(roomInfo)
          })
        })

        this.roomAvailablilityMapping = new Map();
        this.roomsSet.forEach((roomInfo) => {
          this.calendarRange.forEach((date) => {
            let dateRoomType: DateRoomId = {
              date: date.format('YYYY-MM-DD'),
              roomId: roomInfo.room_id
            }
            this.roomAvailablilityMapping.set(dateRoomType, parseInt(roomInfo.room_count))
          })
        })
      },
      error: (err) => {
      }
    })
  }

  reservationPopupOpened() {
    this.reservationService.reservationPopupOpen()
  }

  calendarDateChange($event) {
    console.log($event);
    this.calendarWeekDateRange
  }

  updateDate() {
    this.inventoryService.setCalendarDateRange(this.calendarWeekDateRange.startDate.subtract(14, 'days'), this.calendarWeekDateRange.endDate.add(14, 'days'))
  }

}
