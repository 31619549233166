export const InventoryCalendarColors = [
  "#F98282",
  "#49FC6D",
  "#FF6DBD",
  "#7577FF",
  "#F5A65B",
  "#21E1AD",
  "#A7C957",
  "#D87EFF",
  "#D08C60",
  "#09B562",
  "#D85B5B",
  "#7B89FF",
  "#FF9A36",
  "#C4B534",
  "#BB8565",
  "#FD88FF",
  "#FFA985",
  "#33FFA0",
  "#A6C36F",
  "#F9C74F",
  "#E28D99",
  "#6387FF",
  "#9FFF0D",
  "#25A18E",
  "#2D9DFF",
  "#FF658A",
  "#A779BE",
  "#72BDA3",
  "#C2A363",
  "#277DA1",
];

// export const InventoryCalendarColors = [
//   "#B7EB6C",
//   "#FF9576",
//   "#54B681",
//   "#EB9CC0",
//   "#EF788F",
//   "#FFC080",
//   "#5898AA",
//   "#95E071",
// ];